const styles = () => ({
  table: {
    fontSize: '14px',
    lineHeight: '22px',
    '& .MuiTableCell-head': {
      color: '#FFF',
      backgroundColor: 'rgb(59, 60, 78)'
    },
    '& .MuiTableSortLabel-root:hover': {
      color: '#01847A'
    },
    '& .MuiTableSortLabel-root.MuiTableSortLabel-active': {
      color: '#ffffff'
    },
    '& .MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon': {
      color: '#ffffff'
    },
    '& .MuiTableCell-body': {
      color: '#FFF'
    },
    '& .MuiTableBody-root .MuiTableCell-root': {
      borderBottom: '1px solid rgba(234, 234, 234, 0.2)'
    }
  },
  table_th: {
    backgroundColor: '#F9F9FA',
    color: '#292929'
  },
  Table_td_link: {
    color: '#01847A',
    fontWeight: '700'
  },
  TableCell: {
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px',
    // borderBottom: '1px solid #A8A8A8',
    height: '56px',
    minHeight: '56px',
    backgroundColor: '#F9F9FA'
  },
  Table_td: {
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '22px',
    position: 'relative',
    padding: '12px 15px 13px 15px',
    height: '56px',
    minHeight: '56px'
  },
  TbActionBtn: {
    textTransform: 'capitalize',
    color: '#7F7F7F',
    fontSize: '12px'
  },
  PercentageFormControl: {
    color: '#292929',
    border: '1px solid #a9a9a9',
    padding: '8px 15px 8px',
    fontSize: '16px',
    maxHeight: '48px',
    width: 'auto',
    minWidth: '75px',
    maxWidth: '80px',
    '&.MuiInput-underline.Mui-error': {
      border: '1px solid #f44336'
    },
    '&.MuiInput-underline.Mui-error input[type="text"]': {
      border: '0px solid #f44336'
    },
    '& .MuiInput-underline.Mui-disabled:before': {
      borderBottomStyle: 'none'
    }
  },
  cutom_Table_Label: {
    fontSize: '14px',
    fontWeight: 'normal',
    color: '#263238'
  },
  TbBtn: {
    padding: '0',
    textAlign: 'center'
  },
  CurrencyBox: {
    width: 'auto',
    display: 'inline-block',
    '& .MuiFormControl-fullWidth': {
      width: 'auto'
    }
  },
  activePlan: {
    backgroundColor: 'rgb(41 41 41 / 10%)',
    '& td': {
      fontWeight: '600'
    }
  }
});
export default styles;
