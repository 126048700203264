/* eslint-disable arrow-parens */
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link, Route, Switch } from 'react-router-dom';
import {
  Grid,
  Button,
  Breadcrumbs,
  Typography,
  Tabs,
  Tab,
  AppBar,
  Box
} from '@material-ui/core';
import WODFav from 'src/views/wod/List/WODFav';
import ListWOD from 'src/views/wod/List/wodList';
import { ROUTES } from '../../../constant/Constants';
import Plusicon from '../../../assets/image/plusicon.png';
import styles from './ListView.style';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}
class Listview extends React.PureComponent {
  render() {
    const { classes, location } = this.props;
    const Tabslocation = location.pathname === ROUTES.WORKOUT_LIST_STARRED ? 1 : 0;

    return (
      <div className={classes.root}>
        <div className="title-bk">
          <div className={classes.new_programs} style={{ marginBottom: 15 }}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={0}
            >
              <Grid item xs={6}>
                <Breadcrumbs aria-label="breadcrumb">
                  <Link color="inherit" to={ROUTES.WORKOUT_LIST}>
                    Workouts
                  </Link>
                  <Link color="inherit" to={ROUTES.WORKOUT_LIST}>
                    List
                  </Link>
                  <Typography variant="h3" color="primary">
                    {!Tabslocation ? (
                      <span>This Week & Future</span>
                    ) : (
                      <span>Starred</span>
                    )}
                  </Typography>
                </Breadcrumbs>
              </Grid>
              <Grid
                item
                xs={6}
                // alignItems="center"
              >
                <Button
                  color="primary"
                  className={classes.button}
                  component={Link}
                  to={ROUTES.WORKOUT_CREATE_VIEW}
                >
                  <img
                    alt="Plusicon"
                    src={Plusicon}
                    style={{ marginRight: 10 }}
                  />
                  <span>new Workout</span>
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
        <Box
          className={classes.mainCard}
          css={{
            overflowY: 'auto'
          }}
        >
          <AppBar
            position="static"
            style={{
              backgroundColor: 'transparent',
              boxShadow: 'none'
            }}
          >
            <Tabs
              value={Tabslocation}
              indicatorColor="primary"
              textColor="primary"
              color="primary"
              // fullWidth
            >
              <Tab
                label="This Week & Future"
                component={Link}
                to={ROUTES.WORKOUT_LIST}
                className={classes.p_tabs}
                {...a11yProps(0)} // eslint-disable-line
              />
              <Tab
                label="Starred"
                component={Link}
                to={ROUTES.WORKOUT_LIST_STARRED}
                className={classes.p_tabs}
                {...a11yProps(1)} // eslint-disable-line
              />
            </Tabs>
          </AppBar>
          <Switch>
            <Route
              exact
              path={ROUTES.WORKOUT_LIST_STARRED}
              component={WODFav}
            />
            <Route path={ROUTES.WORKOUT_LIST} component={ListWOD} />
          </Switch>
        </Box>
      </div>
    );
  }
}

Listview.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.any.isRequired,
  location: PropTypes.any.isRequired
};

export default withStyles(styles, { withTheme: true })(Listview);
