import React, { useState, useContext } from 'react';
import PropType from 'prop-types';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { AlliesProfile } from 'src/Context/AlliesContext';
import {
  setDiscountExpireType,
  setDiscountEndDate,
  setDiscountMonth
} from 'src/Context/MasterHQAllies/masterhqallies.actions';
import { InputLabel, withStyles, Grid, ThemeProvider } from '@material-ui/core';
import { getMinDate } from 'src/utils/someCommon';
import JBDatePicker from 'src/views/common/FormControl/JBDatePicker';
import { TobeFilter, isNotData } from './static.Data';
import CustomAutoComplete from '../../../../../common/CustomAutocomplete/commonAutocomplete';
import styles from './filters.style';

const SubscriptionFilters = (props) => {
  const { classes } = props;
  const [data] = useState(null);
  const [state, dispatch] = useContext(AlliesProfile);
  const {
    subscription_details: { discount }
  } = state;

  const ApplyFilterStyle = {
    overrides: {
      MuiSvgIcon: {
        root: {
          color: '#ffffff'
        }
      },
      MuiAutocomplete: {
        root: {
          color: '#ffffff',
          background: 'rgba(255, 255, 255, 0.1)',
          borderRadius: '2px',
          '& .cutom_select div input': {
            color: '#ffffff !important',
            fontWeight: 'bold',
            '&::placeholder': {
              color: '#ffffff !important',
              fontSize: '14px'
            }
          }
        }
      },
      MuiPaper: {
        root: {
          backgroundColor: '#3c3d4f',
          color: '#ffffff'
        }
      }
    }
  };

  const DatePickerStyle = {
    overrides: {
      MuiPaper: {
        root: {
          backgroundColor: '#fff',
          color: '#000'
        }
      },
      MuiInput: {
        root: {
          margin: '0 0 0 0 !important'
        }
      }
    }
  };

  return (
    <Grid
      xs={3}
      style={{
        maxWidth: '330px',
        marginBottom: '15px'
      }}
    >
      <div>
        <ThemeProvider theme={ApplyFilterStyle}>
          <CustomAutoComplete
            holder="Select options"
            style={{ marginBottom: '15px' }}
            value={TobeFilter}
            Options="filter"
            data={discount.discount_valid_type}
            Change={(val) => dispatch(setDiscountExpireType(val))}
          />

          <div>
            {(() => {
              switch (discount.discount_valid_type?.id) {
                case 1:
                  return (
                    <div>
                      <InputLabel
                        htmlFor="age-required"
                        className={classes.cutom_select_Label}
                        style={{ margin: '0px 0 5px' }}
                      >
                        Select Months
                      </InputLabel>
                      <CustomAutoComplete
                        holder="Select Months"
                        Options="name"
                        data={discount.no_of_month}
                        value={isNotData}
                        Change={(val) => dispatch(setDiscountMonth(val))}
                      />
                      {/* {selectis && (
                    <CustomAutoComplete
                      holder="Select Source"
                      Options="label"
                      Change={(val) => setData(val)}
                      value={leadSrc}
                      data={data || null}
                      style={{ marginBottom: '15px', marginTop: '15px' }}
                    />
                  )} */}
                    </div>
                  );
                case 2:
                  return (
                    <div
                      // className={classes.cutom_Date}
                      className={clsx(classes.cutom_Date, 'Datefilter')}
                    >
                      <InputLabel
                        htmlFor="age-required"
                        className={classes.cutom_select_Label}
                        style={{ margin: '0px 0 5px' }}
                      >
                        End Date
                      </InputLabel>
                      <ThemeProvider theme={DatePickerStyle}>
                        <JBDatePicker
                          label=""
                          minDate={
                            data ? getMinDate(data) : getMinDate(new Date())
                          }
                          value={discount.end_date}
                          onChange={(val) => dispatch(setDiscountEndDate(val))}
                          style={{
                            margin: '0 0 !important'
                          }}
                        />
                      </ThemeProvider>
                    </div>
                  );
                default:
                  return null;
              }
            })()}
          </div>

          {/* <JBButton
        type="primary"
        style={{
          width: '100%',
          minHeight: '22px',
          padding: '7px 25px',
          marginTop: '10px'
        }}
        // disabled={!data}
        // disabled={checkData(filter, data, selectis)}
        onClick={() => {
          // getFilData(
          //   getFilter(filter, data, selectis, endDate),
          //   getfilterLabel(filter, data, selectis, endDate)
          // );
          setOpen(!open);
        }} // eslint-disable-line
      >
        Apply Filter
      </JBButton> */}
        </ThemeProvider>
      </div>
    </Grid>
  );
};
SubscriptionFilters.propTypes = {
  classes: PropType.object.isRequired
  // memFilter: PropType.func.isRequired
};
const mapToState = (state) => {
  return {
    leadSrc: state.LeadReducer.lead_source.data,
    leadStatus: state.LeadReducer.lead_status.data,
    leadTags: state.LeadReducer.tags.data
  };
};
export default connect(mapToState)(withStyles(styles)(SubscriptionFilters));
