/* eslint-disable operator-linebreak */
import React from 'react';
import PropType from 'prop-types';
// import { ROUTES } from 'src/constant/Constants';
// import { Link } from 'react-router-dom';
import filterType from 'src/Redux/Reducer.constants';
import {
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  TableSortLabel,
  Table,
  withStyles
} from '@material-ui/core';
// import CalendarSmallIcon from '../../../../../assets/image/calendarSmall.svg';
import CheckIcon from 'src/assets/image/check.png';

const styles = () => ({
  table: {
    minWidth: '100%',
    wordBreak: 'break-word',
    '& td': {
      padding: '10px'
    },
    '@media (max-width: 1366px)': {
      '& td': {
        fontSize: '13px'
      }
    },
    '& .MuiTableCell-head': {
      color: '#FFF'
    },
    '& .MuiTableSortLabel-root:hover': {
      color: '#01847A'
    },
    '& .MuiTableSortLabel-root.MuiTableSortLabel-active': {
      color: '#ffffff'
    },
    '& .MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon': {
      color: '#ffffff'
    },
    '& .MuiTableCell-body': {
      color: '#FFF'
    },
    '& .MuiTableBody-root .MuiTableCell-root': {
      borderBottom: '1px solid rgba(234, 234, 234, 0.2)'
    }
  },
  tableLink: {
    color: '#01847A',
    fontWeight: '700'
  },
  TableCell: {
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px',
    height: '56px',
    minHeight: '56px',
    padding: '10px',
    '@media (max-width: 1366px)': {
      fontSize: '13px',
      wordBreak: 'keep-all'
    }
  },
  Tablemian: {}
});

const MembershipDetailsTable = (props) => {
  const { values, classes, setDirection, isActive, setSort } = props;
  const {
    sort_members,
    sort_payment_method_name,
    sort_card_expiry,
    sort_default_payment_method
  } = filterType;
  return (
    <TableContainer
      component={Paper}
      elevation={0}
      className={classes.Tablemian}
      style={{ borderRadius: '0', backgroundColor: 'transparent' }}
    >
      <Table className={classes.table} aria-label="simple table">
        <TableHead style={{ background: '#3B3C4E' }}>
          <TableRow>
            <TableCell className={classes.TableCell}>
              <TableSortLabel
                direction={setDirection(sort_members)}
                active={isActive(sort_members)}
                onClick={() => setSort(sort_members)}
              >
                Ally
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell}>
              <TableSortLabel
                direction={setDirection(sort_payment_method_name)}
                active={isActive(sort_payment_method_name)}
                onClick={() => setSort(sort_payment_method_name)}
              >
                Payment method name
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell} align="right">
              <TableSortLabel
                direction={setDirection(sort_card_expiry)}
                active={isActive(sort_card_expiry)}
                onClick={() => setSort(sort_card_expiry)}
              >
                Expiration date
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell} align="center">
              <TableSortLabel
                direction={setDirection(sort_default_payment_method)}
                active={isActive(sort_default_payment_method)}
                onClick={() => setSort(sort_default_payment_method)}
              >
                Default payment method
              </TableSortLabel>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {values &&
            values.map((row) => (
              <TableRow
                hover
                tabIndex={-1}
                key={row.id}
                style={{
                  backgroundColor: '#26273B'
                }}
              >
                <TableCell
                  style={{
                    width: '9.0909%'
                  }}
                >
                  {row.allies_name}
                </TableCell>
                <TableCell
                  style={{
                    width: '9.0909%'
                  }}
                  className={classes.tableLink}
                >
                  {row.payment_method_str}
                </TableCell>
                <TableCell
                  style={{
                    width: '7%'
                  }}
                  align="right"
                >
                  {row.expiration_date}
                </TableCell>

                <TableCell
                  style={{
                    width: '9%'
                  }}
                  align="center"
                >
                  {/* {row.history} */}
                  {row.default_payment_method === 1 && (
                    <img src={CheckIcon} alt="img" />
                  )}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      {values.length === 0 && (
        <p
          style={{
            margin: '10px 15px',
            color: '#fff'
          }}
        >
          No Members to show...
        </p>
      )}
    </TableContainer>
  );
};
MembershipDetailsTable.propTypes = {
  values: PropType.array.isRequired,
  classes: PropType.object.isRequired,
  setDirection: PropType.func.isRequired,
  isActive: PropType.func.isRequired,
  setSort: PropType.func.isRequired
};

export default withStyles(styles)(MembershipDetailsTable);
