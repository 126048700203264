import { getAllySteps } from 'src/services/user.service';
import { getErrorMsg } from 'src/utils/ErrorHandling';
import Types from './GymSetup.types';

const fetchSteps = (type) => ({
  type,
  actionType: Types.isFetching
});
const errorFetch = (type, payload) => ({
  type,
  actionType: Types.error,
  payload
});
const fetchSuccess = (type, payload) => ({
  type,
  actionType: Types.isCompleted,
  payload
});

export const getGymSetup = () => {
  const type = Types.getSteps;
  return (dispatch) => {
    dispatch(fetchSteps(type));
    getAllySteps()
      .then((res) => {
        dispatch(fetchSuccess(type, res));
      })
      .catch((err) => dispatch(errorFetch(type, getErrorMsg(err))));
  };
};

export const setHidden = (payload) => ({
  type: Types.isHidden,
  payload
});
