/* eslint-disable indent */
/* eslint-disable default-case */
/* eslint-disable object-shorthand */
import React, { useState, useContext, useEffect } from 'react';
import PropType from 'prop-types';
import { VanguardMem } from 'src/Context/VanguardMembershipContext';
import { connect } from 'react-redux';
import { openNoti } from 'src/Redux/global/global.actions';
import { ROUTES } from 'src/constant/Constants';
import { useHistory, Link } from 'react-router-dom';
import { createVanguardUser } from 'src/services/vanguard.services';
import {
  createusermemship,
  createPaymentMet,
  createStripeCustomer
} from 'src/services/user.service';
import {
  NextStep,
  StepBack,
  ResetStep,
  setUser,
  setUserContract,
  skipStep,
  // skipStep,
  resetData
} from 'src/Context/VanguardMembership/VanguardMembership.actions';
import { updateUserContract } from 'src/services/Contracts.services';
import {
  Button,
  AppBar,
  Toolbar,
  IconButton,
  withStyles,
  Card,
  Box
} from '@material-ui/core';
import {
  useElements,
  useStripe,
  CardElement,
  AuBankAccountElement
} from '@stripe/react-stripe-js';
import { getErrorMsg } from 'src/utils/ErrorHandling';
import useChangeTitle from 'src/views/common/CustomHooks/useTitleChange';
import { ReactComponent as BackArrow } from '../../../assets/image/Vanguard/icon_back_vanguard.svg';

import LeavePagePopup from '../LeavepagePopup';
import styles from './membershipIndex.style';
import JBVanguardStepper from '../../common/JBVanguardStepper';
import PersonalInfo from '../personalInfo';
import SelectMembership from './selectMembership';
// import Terms from './terms';
// import Questionnaire from './questionnaire';
// import Sign from './sign';
import SuccessPopup from '../successPopup';
import PlanConfigureMembership from './Plan/ConfigureMembership';
import {
  validStep2,
  validStep3,
  validStep4,
  validStep5,
  // verifyInfo,
  validStep6,
  getUserData,
  getMembershipData,
  stripeData,
  validStep7,
  validStep9,
  getContractData,
  planStep5
} from './Vanguard.utills';
import SessionConfigureMembership from './Session/ConfigureMembership';
import DiscountsPricing from './Session/DiscountsPricing';
import PaymentAutoRenew from './Plan/PaymentAutoRenew';
import ProgramAccess from './programAccess';
import AddPaymentMethod from './PaymentMethod/AddPaymentMethod';
import PaymentPlanTerms from './PaymentPlanTerms';
import SessionPlanTerms from './SessionPlanTerms';
import Membershipagreement from './MembershipAgreement';
import Sign from '../sign';

function getSteps() {
  return ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];
}

const MembershipNew = ({ classes, openNotify }) => {
  const history = useHistory();
  useChangeTitle('New Create Membership | Jungle Alliance');
  const [LeavePageopen, setLeavePageopen] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const [state, dispatch] = useContext(VanguardMem);
  const {
    active_Step,
    membership_type,
    user_id,
    personal_info: { user_details, error, error_msg },
    memebership_data: { membershipdata }
  } = state;
  const steps = getSteps();
  const [Sussopen, setSussopen] = useState(false);
  const [processing, setProcessing] = useState(false);

  const handleNext = async () => {
    if (active_Step === 0) {
      setProcessing(true);
      createVanguardUser(getUserData(user_details))
        .then((res) => {
          dispatch(setUser(res.id));
          dispatch(NextStep());
          openNotify('Profile created');
          setProcessing(false);
        })
        .catch((err) => {
          setProcessing(false);
          openNotify(getErrorMsg(err), 'error');
        });
    } else if (active_Step === 4) {
      setProcessing(true);
      createusermemship(getMembershipData(state), state.user_id)
        .then((res) => {
          dispatch(setUserContract(res.user_contract_id));
          openNotify('Membership created successfully');
          if (res.payment_method_exists) {
            dispatch(skipStep());
          } else {
            dispatch(NextStep());
          }
          setProcessing(false);
        })
        .catch((err) => {
          setProcessing(false);
          openNotify(getErrorMsg(err), 'error');
        });
    } else if (active_Step === 5) {
      setProcessing(true);
      if (!elements || !stripe) return;
      const billling = stripeData(user_details);
      try {
        if (state.paymentMeTab === 0) {
          const value = {
            payment_method: 1,
            user_id: user_id,
            payment_address_meta: JSON.stringify({ billing_details: billling })
          };

          const res = await createStripeCustomer(value);

          const payload = await stripe.confirmCardSetup(res?.client_secret, {
            payment_method: {
              card: elements.getElement(CardElement),
              billing_details: billling
            }
          });
          const {
            setupIntent: { payment_method }
          } = payload;

          const val = {
            user_id: user_id,
            payment_method_id: payment_method,
            default_method: membershipdata.payment_data.is_default ? 1 : 0,
            payment_method: state.paymentMeTab + 1,
            user_payment_method_id: res?.user_payment_method_id
          };
          createPaymentMet(val)
            .then(() => {
              openNotify('Payment method added successfully');
              if (membershipdata.membership_contract === 'send_contract') {
                setSussopen(true);
                setTimeout(() => setSussopen(false), 4000);
                setTimeout(() => history.push(ROUTES.VANGUARD), 3000);
              } else {
                dispatch(NextStep());
              }
              setProcessing(false);
            })
            .catch((err) => {
              setProcessing(false);
              openNotify(getErrorMsg(err), 'error');
            });
        } else if (state.paymentMeTab === 1) {
          const val = {
            user_id: user_id,
            default_method: membershipdata.payment_data.is_default ? 1 : 0,
            payment_method: state.paymentMeTab + 1
          };
          createPaymentMet(val)
            .then(() => {
              openNotify('Payment method added successfully');
              if (membershipdata.membership_contract === 'send_contract') {
                setSussopen(true);
                setTimeout(() => setSussopen(false), 4000);

                setTimeout(() => history.push(ROUTES.VANGUARD), 3000);
              } else {
                dispatch(NextStep());
              }
              setProcessing(false);
            })
            .catch((err) => {
              setProcessing(false);
              openNotify(getErrorMsg(err), 'error');
            });
        } else if (state.paymentMeTab === 2) {
          // for stripe bank account element added

          const value = {
            payment_method: 3,
            user_id: user_id,
            payment_address_meta: JSON.stringify({ billing_details: billling })
          };

          const res = await createStripeCustomer(value);

          const payload = await stripe.confirmAuBecsDebitSetup(
            res?.client_secret,
            {
              payment_method: {
                au_becs_debit: elements.getElement(AuBankAccountElement),
                billing_details: billling
              }
              // type: 'au_becs_debit',
            }
          );

          const {
            setupIntent: { payment_method }
          } = payload;

          const val = {
            user_id: user_id,
            default_method: membershipdata.payment_data.is_default ? 1 : 0,
            payment_method: state.paymentMeTab + 1,
            payment_method_id: payment_method,
            // account_number:
            //   membershipdata.payment_data.bank_details.account_number,
            // bsb_number: membershipdata.payment_data.bank_details.bsb_number,
            account_type: 'Saving account',
            payment_address_meta: JSON.stringify({ billing_details: billling }),
            user_payment_method_id: res?.user_payment_method_id
          };
          createPaymentMet(val)
            .then(() => {
              openNotify('Payment method added successfully');
              if (membershipdata.membership_contract === 'send_contract') {
                setSussopen(true);
                setTimeout(() => setSussopen(false), 4000);
                setTimeout(() => history.push(ROUTES.VANGUARD), 3000);
              } else {
                dispatch(NextStep());
              }
              setProcessing(false);
            })
            .catch((err) => {
              setProcessing(false);
              openNotify(getErrorMsg(err), 'error');
            });
        }
      } catch (err) {
        setProcessing(false);
        openNotify(error_msg?.message, 'error');
      }
    } else if (active_Step === 7) {
      if (
        // eslint-disable-next-line operator-linebreak
        membershipdata.user_contract.sign_initials !==
        membershipdata.user_contract.policy_initials
      ) {
        openNotify('Initials must be the same', 'error');
      } else {
        dispatch(NextStep());
      }
    } else if (active_Step === 8) {
      setProcessing(true);
      updateUserContract(
        membershipdata.user_contract.id,
        getContractData(user_details, membershipdata.user_contract)
      )
        .then(() => {
          openNotify('Contract completed successfully');
          setSussopen(true);
          setTimeout(() => setSussopen(false), 4000);

          setTimeout(() => history.push(ROUTES.VANGUARD), 3000);
          setProcessing(false);
        })
        .catch((err) => {
          setProcessing(false);
          openNotify(getErrorMsg(err), 'error');
        });
    } else {
      dispatch(NextStep());
    }
  };
  const planNext = async () => {
    if (active_Step === 0) {
      setProcessing(true);
      createVanguardUser(getUserData(user_details))
        .then((res) => {
          dispatch(setUser(res.id));
          dispatch(NextStep());
          openNotify('Profile created');
          setProcessing(false);
        })
        .catch((err) => {
          setProcessing(false);
          openNotify(getErrorMsg(err), 'error');
        });
    } else if (active_Step === 5) {
      setProcessing(true);
      createusermemship(getMembershipData(state), state.user_id)
        .then((res) => {
          dispatch(setUserContract(res.user_contract_id));
          openNotify('Membership created successfully');
          if (res.payment_method_exists) {
            dispatch(skipStep());
          } else {
            dispatch(NextStep());
          }
          setProcessing(false);
        })
        .catch((err) => {
          setProcessing(false);
          openNotify(getErrorMsg(err), 'error');
        });
    } else if (active_Step === 6) {
      setProcessing(true);
      if (!elements || !stripe) return;
      try {
        const billling = stripeData(user_details);
        if (state.paymentMeTab === 0) {
          const value = {
            payment_method: 1,
            user_id: user_id,
            payment_address_meta: JSON.stringify({ billing_details: billling })
          };

          const res = await createStripeCustomer(value);

          const payload = await stripe.confirmCardSetup(res?.client_secret, {
            payment_method: {
              card: elements.getElement(CardElement),
              billing_details: billling
            }
          });
          const {
            setupIntent: { payment_method }
          } = payload;

          const val = {
            user_id: user_id,
            payment_method_id: payment_method,
            default_method: membershipdata.payment_data.is_default ? 1 : 0,
            payment_method: state.paymentMeTab + 1,
            user_payment_method_id: res?.user_payment_method_id
          };
          createPaymentMet(val)
            .then(() => {
              openNotify('Payment method added successfully');
              if (membershipdata.membership_contract === 'send_contract') {
                setSussopen(true);
                setTimeout(() => setSussopen(false), 4000);
                setTimeout(() => history.push(ROUTES.VANGUARD), 3000);
              } else {
                dispatch(NextStep());
              }
              setProcessing(false);
            })
            .catch((err) => {
              setProcessing(false);
              openNotify(getErrorMsg(err), 'error');
            });
        } else if (state.paymentMeTab === 1) {
          const val = {
            user_id: user_id,
            default_method: membershipdata.payment_data.is_default ? 1 : 0,
            payment_method: state.paymentMeTab + 1
          };
          createPaymentMet(val)
            .then(() => {
              openNotify('Payment method added successfully');
              if (membershipdata.membership_contract === 'send_contract') {
                setSussopen(true);
                setTimeout(() => setSussopen(false), 4000);

                setTimeout(() => history.push(ROUTES.VANGUARD), 3000);
              } else {
                dispatch(NextStep());
              }
              setProcessing(false);
            })
            .catch((err) => {
              setProcessing(false);
              openNotify(getErrorMsg(err), 'error');
            });
        } else if (state.paymentMeTab === 2) {
          // for stripe bank account element added
          const value = {
            payment_method: 3,
            user_id: user_id,
            payment_address_meta: JSON.stringify({ billing_details: billling })
          };

          const res = await createStripeCustomer(value);

          const payload = await stripe.confirmAuBecsDebitSetup(
            res?.client_secret,
            {
              payment_method: {
                au_becs_debit: elements.getElement(AuBankAccountElement),
                billing_details: billling
              }
              // type: 'au_becs_debit',
            }
          );

          const {
            setupIntent: { payment_method }
          } = payload;
          const val = {
            user_id: user_id,
            default_method: membershipdata.payment_data.is_default ? 1 : 0,
            payment_method: state.paymentMeTab + 1,
            payment_method_id: payment_method,
            // account_number:
            //   membershipdata.payment_data.bank_details.account_number,
            // bsb_number: membershipdata.payment_data.bank_details.bsb_number,
            account_type: 'Saving account',
            payment_address_meta: JSON.stringify({ billing_details: billling }),
            user_payment_method_id: res?.user_payment_method_id
          };
          createPaymentMet(val)
            .then(() => {
              openNotify('Payment method added successfully');
              if (membershipdata.membership_contract === 'send_contract') {
                setSussopen(true);
                setTimeout(() => setSussopen(false), 4000);
                setTimeout(() => history.push(ROUTES.VANGUARD), 3000);
              } else {
                dispatch(NextStep());
              }
              setProcessing(false);
            })
            .catch((err) => {
              setProcessing(false);
              openNotify(getErrorMsg(err), 'error');
            });
        }
      } catch (err) {
        setProcessing(false);
        openNotify(error_msg?.message, 'error');
      }
    } else if (active_Step === 8) {
      if (
        // eslint-disable-next-line operator-linebreak
        membershipdata.user_contract.sign_initials !==
        membershipdata.user_contract.policy_initials
      ) {
        openNotify('Initials must be the same', 'error');
      } else {
        dispatch(NextStep());
      }
    } else if (active_Step === 9) {
      setProcessing(true);
      updateUserContract(
        membershipdata.user_contract.id,
        getContractData(user_details, membershipdata.user_contract)
      )
        .then(() => {
          openNotify('Contract completed successfully');
          setSussopen(true);
          setTimeout(() => setSussopen(false), 4000);
          setTimeout(() => history.push(ROUTES.VANGUARD), 3000);
          setProcessing(false);
        })
        .catch((err) => {
          setProcessing(false);
          openNotify(getErrorMsg(err), 'error');
        });
    } else {
      dispatch(NextStep());
    }
  };
  const handleReset = () => {
    dispatch(ResetStep());
  };
  const handleBack = () => {
    if (active_Step === 0) {
      history.goBack();
    }
    dispatch(StepBack());
  };
  useEffect(() => {
    dispatch(resetData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    document.getElementsByClassName('sp-text')[0]?.scrollTo(0, 0);
  }, [active_Step]);

  function getStepContent(step) {
    switch (true) {
      case step === 0:
        return <PersonalInfo />;
      case step === 1:
        return <SelectMembership TabValue={membership_type} />;
      case step > 1 && step <= 9:
        if (membershipdata.payment_data.membership.membership_type === 'plan') {
          switch (step) {
            case 2:
              return <PlanConfigureMembership />;
            case 3:
              return <PaymentAutoRenew />;
            case 4:
              return (
                <DiscountsPricing
                  title="Select the discounts you want to apply to this plan membership."
                  subtitle=" The final pricing details for this member plan."
                />
              );
            case 5:
              return <ProgramAccess />;
            case 6:
              return <AddPaymentMethod />;
            case 7:
              return <PaymentPlanTerms />;
            case 8:
              return <Membershipagreement />;
            case 9:
              return <Sign />;
          }
        } else {
          switch (step) {
            case 2:
              return <SessionConfigureMembership />;
            case 3:
              return (
                <DiscountsPricing
                  title="Select the discounts you want to apply to this sessions membership."
                  subtitle=" The final pricing details for this sessions membership."
                />
              );
            case 4:
              return <ProgramAccess />;
            case 5:
              return <AddPaymentMethod />;
            case 6:
              return <SessionPlanTerms />;
            case 7:
              return <Membershipagreement />;
            case 8:
              return <Sign />;
          }
        }
      // eslint-disable-next-line no-fallthrough
      default:
        return 'Unknown step';
    }
  }
  return (
    <Box>
      <AppBar className={classes.appBar}>
        <Toolbar className={classes.appToolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="close"
            onClick={() => {
              setLeavePageopen(true);
            }}
          >
            <BackArrow />
          </IconButton>
          <h5 className={classes.headerTitle}>Create Membership</h5>
          <Button
            color="inherit"
            className={classes.HomeButton}
            component={Link}
            to={ROUTES.VANGUARD}
          >
            Home
          </Button>
        </Toolbar>
      </AppBar>

      <Card className={classes.MainCard}>
        <JBVanguardStepper
          steps={membership_type === 0 ? steps : steps.slice(0, -1)}
          activeStep={active_Step}
          handleBack={handleBack}
          handleNext={membership_type === 1 ? handleNext : planNext}
          handleReset={handleReset}
          // eslint-disable-next-line react/jsx-no-bind
          handleStepContent={getStepContent}
          validateBtn={
            membership_type === 1
              ? [
                  !Object.values(error).every((l) => l === null),
                  validStep2(state),
                  validStep3(state, membershipdata),
                  validStep4(membershipdata.payment_data, membership_type),
                  validStep5(membershipdata),
                  state.paymentMeTab === 1
                    ? false
                    : validStep6(user_details, membershipdata.payment_data),
                  validStep7(membershipdata.user_contract),
                  validStep7(membershipdata.user_contract),
                  validStep9(membershipdata.user_contract)
                ]
              : [
                  !Object.values(error).every((l) => l === null),
                  validStep2(state),
                  validStep3(state, membershipdata),
                  validStep4(membershipdata.payment_data, membership_type),
                  planStep5(membershipdata.payment_data),
                  validStep5(membershipdata),
                  state.paymentMeTab === 1
                    ? false
                    : validStep6(user_details, membershipdata.payment_data),
                  validStep7(membershipdata.user_contract),
                  validStep7(membershipdata.user_contract),
                  validStep9(membershipdata.user_contract)
                ]
          }
          isLoading={false}
          cancelBack={membership_type === 1 ? [1, 5, 6] : [1, 6, 7]}
          hasErrored={false}
          backLabel="Back"
          prevLabel="Sign"
          processing={processing}
          nextLabel={
            active_Step === 0
              ? 'Continue'
              : membership_type === 0
              ? active_Step === 6
                ? 'Add & continue'
                : 'Next'
              : active_Step === 5
              ? 'Add & continue'
              : 'Next'
          }
        />
      </Card>
      <LeavePagePopup
        setOpen={LeavePageopen}
        handleClose={() => setLeavePageopen(false)}
        // handleHistory={ROUTES.VANGUARD}
      />
      <SuccessPopup
        Dialogtitle="Membership created successfully"
        setOpen={Sussopen}
        handleClose={() => setSussopen(false)}
      />
    </Box>
  );
};
const mapToDispatch = (dispatch) => ({
  openNotify: (msg, type) => dispatch(openNoti(msg, type))
});
MembershipNew.propTypes = {
  classes: PropType.object.isRequired,
  openNotify: PropType.func.isRequired
};
export default connect(null, mapToDispatch)(withStyles(styles)(MembershipNew));
