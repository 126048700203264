import { checkValidString } from '../../../../utils/validations';

export const validStep1 = (data, arr, index) => {
  if (checkValidString(data.name)) {
    return { error: 'Name is not valid' };
  }
  for (let i = 0; i < arr.length; i += 1) {
    if (i === index) {
      i += 1;
    } else if (data.name === arr[i].name) {
      return { error: 'Name is already taken' };
    }
  }
  if (!data.tax) {
    return { error: 'Please specify the service tax' };
  }
  return { value: data };
};

export const validStep2 = (data) => {
  if (
    String(data.initial_commitment_pricing.full_membership_fees).length === 0
    || typeof data.initial_commitment_pricing.full_membership_fees !== 'number'
  ) {
    return { error: 'Please specify Membership fee' };
  }
  return { value: data };
};
