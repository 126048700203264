import React, { createContext, useReducer } from 'react';
import PropType from 'prop-types';
import {
  initialValue,
  VanguardMembershipReducer
} from './VanguardMembership/VanguardMembership.reducer';

export const VanguardMem = createContext();

export const VanguardMembershipContext = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(VanguardMembershipReducer, initialValue);
  return (
    <VanguardMem.Provider value={[state, dispatch]}>
      {children}
    </VanguardMem.Provider>
  );
};
VanguardMembershipContext.propTypes = {
  children: PropType.node.isRequired
};
