import { getUpdatedValueObj } from 'src/Redux/reducer.default.data';
import {
  getAffiliateInvoice,
  getAffiliateInvoiceSettings
} from 'src/services/masterHQ.services';
import { getErrorMsg } from 'src/utils/ErrorHandling';
import {
  ACTION_TYPES,
  MASTER_INVOICE_FIL,
  MASTER_INVOICE,
  INVOICE_SETTINGS
} from './financial.reducer.types';
import { getInvoiceState } from './financial.reducer.utils';

const fetchingInvoices = (type) => ({
  type,
  actionType: ACTION_TYPES.isFetching
});

const fetchComplete = (type, payload) => ({
  type,
  actionType: ACTION_TYPES.fetchSucess,
  payload
});

const ErrorFetching = (type, payload) => ({
  type,
  actionType: ACTION_TYPES.Errorfetch,
  payload
});

const InvoiceFilter = (filter, payload) => ({
  type: MASTER_INVOICE_FIL,
  filter,
  payload
});

export const toggleAreAllSelected = (payload) => ({
  type: ACTION_TYPES.setAreAllSelected,
  payload
});

const getInvoices = (values) => {
  const type = MASTER_INVOICE;
  return (dispatch) => {
    dispatch(fetchingInvoices(type));
    getAffiliateInvoice(values)
      .then((res) => dispatch(fetchComplete(type, res)))
      .catch((err) => dispatch(ErrorFetching(type, getErrorMsg(err))));
  };
};

// eslint-disable-next-line import/prefer-default-export
export const getMasterInvoices = (filter, data) => {
  return (dispatch, getState) => {
    dispatch(InvoiceFilter(filter, data));
    const updatedValues = getUpdatedValueObj(getInvoiceState(getState));
    dispatch(getInvoices(updatedValues));
  };
};
export const getInvoiceSettings = () => {
  const type = INVOICE_SETTINGS;
  return (dispatch) => {
    dispatch(fetchingInvoices(type));
    getAffiliateInvoiceSettings()
      .then((res) => dispatch(fetchComplete(type, res)))
      .catch((err) => dispatch(ErrorFetching(type, getErrorMsg(err))));
  };
};
