import React, { useContext, useState } from 'react';
import { ROUTES } from 'src/constant/Constants';
import { VanguardMem } from 'src/Context/VanguardMembershipContext';
import { setContract } from 'src/Context/VanguardMembership/VanguardMembership.actions';
import { Link, useLocation } from 'react-router-dom';
import {
  AppBar,
  Box,
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  makeStyles,
  Radio,
  RadioGroup,
  Toolbar,
  Typography
} from '@material-ui/core';
// import { getUnsignedContracts } from 'src/services/vanguard.services';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import LoadingView from 'src/views/common/loading';
import CheckIcon from '@material-ui/icons/Check';
import { ReactComponent as BackArrow } from '../../../../assets/image/Vanguard/icon_back_vanguard.svg';
import LeavePagePopup from '../../LeavepagePopup';
import JBVanguardButton from '../../../common/JBVanguardButton/JBButton';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    background: 'rgba(29, 29, 29, 0.94)',
    boxShadow: '0px 0.33333px 0px rgb(255 255 255 / 15%)',
    backdropFilter: 'blur(54.3656px)'
  },
  headerTitle: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '26px'
  },
  appToolbar: {
    minHeight: '75px',
    justifyContent: 'space-between'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
  mainTitle: {
    fontWeight: '500',
    fontSize: '48px',
    lineHeight: '44px',
    color: '#FFFFFF',
    fontFamily: 'DDC Hardware',
    textAlign: 'center',
    margin: '38px 0 0 0',
    '@media (max-width: 992px)': {
      fontSize: '35px'
    }
  },
  MainCard: {
    height: 'calc(100vh - 75px )',
    background:
      'linear-gradient(180deg, rgba(41, 41, 41, 0.95) 0%, rgba(4, 4, 4, 0.95) 100%)',
    borderRadius: '0',
    flex: '1 1 auto',
    // height: '100%',
    overflow: 'auto'
  },
  HomeButton: {
    fontFamily: 'Lato',
    color: '#2CE9DA',
    fontWeight: 'bold',
    fontSize: '22px',
    lineHeight: '32px',
    textTransform: 'capitalize'
  },
  cutom_select_Label: {
    fontFamily: 'DDC Hardware',
    fontStyle: 'normal',
    fontWeight: '500;',
    fontSize: '22px',
    lineHeight: '32px',
    letterSpacing: '0.2px',
    color: '#FFFFFF',
    marginBottom: '15px',
    '&:focus ': {
      outline: '0'
    },
    '& .MuiFormLabel-asterisk': {
      color: 'transparent'
    },
    '& .MuiFormLabel-asterisk:after': {
      content: '""',
      background: '#2CE9DA',
      display: 'inline-block',
      position: 'relative',
      width: '8px',
      height: '8px',
      borderRadius: '50%',
      top: '-14px',
      right: '10px'
    },
    '@media (max-width: 992px)': {
      fontSize: '19px'
    }
  },
  cutom_vanguard_FormControl: {
    '& .fd-input input': {
      background: 'rgba(196, 196, 196, 0.05)',
      color: '#fff',
      borderRadius: '6px',
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '20px',
      height: '45px',
      border: '2px solid #C8C8C8',
      padding: '8px 20px 8px',
      '&::placeholder ': {
        color: '#fff',
        fontWeight: 'bold',
        fontSize: '15px'
      }
    }
  },
  cutom_vanguard_Autocomplete: {
    background: 'rgba(196, 196, 196, 0.05)',
    border: '2px solid #C8C8C8',
    color: '#fff',
    borderRadius: '6px',
    '& .cutom_select div': {
      minHeight: '62px'
    },
    '& .cutom_select div input ': {
      color: '#ffffff !important',
      fontWeight: 'bold',
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontSize: '20px'
    },
    '& .MuiAutocomplete-endAdornment': {
      minHeight: ' auto !important',
      top: 'calc(50% - 12px)'
    }
  },
  customRadio: {
    '& .MuiFormControlLabel-root': {
      background: 'rgba(196, 196, 196, 0.05)',
      border: '2px solid #7B7B7B',
      borderRadius: '6px',
      height: '70px',
      margin: '0 0 15px'
    },
    '& .MuiFormControlLabel-root.checked': {
      background: 'rgba(44, 233, 218, 0.15)',
      border: '2px solid #2CE9DA',
      borderRadius: '6px'
    },
    '& .MuiFormControlLabel-root svg.MuiSvgIcon-root': {
      width: '30px',
      height: '30px',
      fill: 'none',
      borderRadius: '50%',
      border: '2px solid #4B4B4B'
    },
    '& .MuiFormControlLabel-root.checked svg.MuiSvgIcon-root': {
      background: '#67e9da',
      padding: '4px',
      fill: '#000',
      color: '#060606',
      border: '0px solid #4B4B4B'
    },
    '& .MuiRadio-root': {
      padding: '20px'
    },
    '& .MuiFormControlLabel-label': {
      color: '#fff',
      fontSize: '20px',
      lineHeight: '26px',
      fontWeight: 'bold'
    }
  },
  actionBt: {
    background: '#101010',
    height: '118px',
    alignItems: 'center',
    display: 'flex',
    position: 'fixed',
    width: '100%',
    bottom: '0'
  }
}));

const SelectContract = () => {
  const classes = useStyles();
  const [LeavePageopen, setLeavePageopen] = useState(false);
  const location = useLocation();
  const [state, dispatch] = useContext(VanguardMem);
  const contracts = location.state.contracts;
  const {
    memebership_data: {
      membershipdata: { user_contract }
    }
  } = state;

  return (
    <div>
      <AppBar className={classes.appBar}>
        <Toolbar className={classes.appToolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="close"
            onClick={() => {
              setLeavePageopen(true);
            }}
          >
            <BackArrow />
          </IconButton>
          <h5 className={classes.headerTitle}>Contract</h5>
          <Button
            color="inherit"
            className={classes.HomeButton}
            component={Link}
            to={ROUTES.VANGUARD}
          >
            Home
          </Button>
        </Toolbar>
      </AppBar>
      <Typography variant="h3" className={classes.mainTitle}>
        Select CONTRACT
      </Typography>
      <Grid
        container
        xs={10}
        justify="center"
        style={{
          margin: '45px auto'
        }}
      >
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={2}
          // style={{ margin: '20px 0 20px' }}
        >
          <Grid xs={12}>
            <InputLabel
              htmlFor="age-required"
              // required
              className={classes.cutom_select_Label}
              // style={{ margin: '0px 0 5px' }}
            >
              UNSIGNED CONTRACTS
            </InputLabel>
            <RadioGroup
              value={String(user_contract.id)}
              onChange={(e) => {
                dispatch(
                  setContract(
                    contracts.filter((i) => String(i.id) === e.target.value)[0]
                  )
                );
              }}
              className={classes.customRadio}
            >
              {!contracts ? (
                <LoadingView />
              ) : (
                contracts?.map((item) => (
                  <FormControlLabel
                    value={String(item.id)}
                    control={
                      <Radio
                        color="primary"
                        checkedIcon={
                          <CheckIcon
                            htmlColor="#2CE9DA"
                            width="30px"
                            height="30px"
                          />
                        }
                        icon={
                          <RadioButtonUncheckedIcon
                            width="30px"
                            height="30px"
                          />
                        }
                      />
                    }
                    label={item.contract_name}
                    // checked
                    className={`${
                      item.id === user_contract.id ? 'checked' : ''
                    }`}
                  />
                ))
              )}
            </RadioGroup>
          </Grid>
        </Grid>
        <Box className={classes.actionBt}>
          <Grid
            container
            xs={10}
            justify="space-between"
            style={{
              margin: '0px auto'
            }}
          >
            <JBVanguardButton
              type="outlined"
              style={{
                width: '240px',
                minWidth: '240px',
                height: '70px'
              }}
              component={Link}
              to={ROUTES.VANGUARD}
            >
              Back
            </JBVanguardButton>
            <JBVanguardButton
              type="primary"
              style={{
                width: '240px',
                minWidth: '240px',
                height: '70px'
              }}
              component={Link}
              to={ROUTES.VANGUARD_MEMBERSHIP_SIGN_CONTRACT}
            >
              Next
            </JBVanguardButton>
          </Grid>
        </Box>
      </Grid>
      <LeavePagePopup
        setOpen={LeavePageopen}
        handleClose={() => setLeavePageopen(false)}
        handleHistory={ROUTES.VANGUARD}
      />
    </div>
  );
};

export default SelectContract;
