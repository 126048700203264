import React, { Suspense } from 'react';
import LoadingView from 'src/views/common/loading';

const Loadable = (Component) => (props) => {
  return (
    <Suspense fallback={<LoadingView />}>
      {/* eslint-disable-next-line */}
      <Component {...props} />
    </Suspense>
  );
};

export default Loadable;
