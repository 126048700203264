import {
  getAllCountries,
  getAllGymloc,
  getAllGymSet,
  getAllInvoiceSet,
  getAllTax,
  getContracts,
  getRevenSet
} from 'src/Redux/SettingReducer/SettingReducer.actions';
import { fetchProgramsAsync } from 'src/Redux/programReducers/progranReducer.actions';
import { getAllActiveMem } from 'src/Redux/MemberReducer/memberReducer.actions';
import { getGymSetup } from 'src/Redux/GymSetupReducer/GymSetup.actions';

const { SettingReducer, allprograms, GymSetupReducer } = JSON.parse(
  localStorage.getItem('persist:root') || '{}'
);
const setting = JSON.parse(SettingReducer || null);
const programsredux = JSON.parse(allprograms || null);
const gymSetupredux = JSON.parse(GymSetupReducer || null);
// const classroot = JSON.parse(classReducer);

export default [
  {
    loc: setting?.allGymloc?.data,
    func: getAllGymloc
  },
  {
    loc: programsredux?.programs,
    func: fetchProgramsAsync
  },
  {
    loc: setting?.taxRates?.data,
    func: getAllTax
  },
  {
    loc: setting?.revenueCtgry?.data,
    func: getRevenSet
  },
  {
    loc: setting?.pubContracts?.data,
    func: getContracts
  },
  {
    loc: setting?.invoice_footer?.data,
    func: getAllInvoiceSet
  },
  {
    loc: false, //  due to security reason we dont have to store members data as persist,
    func: getAllActiveMem
  },
  {
    loc: setting?.currency?.data,
    func: getAllGymSet
  },
  {
    loc: setting?.countries?.data,
    func: getAllCountries
  },
  {
    loc: gymSetupredux?.data,
    func: getGymSetup
  }
];
