import {
  getMembers,
  getActiveMem as getAllActivs
} from 'src/services/Members.module';
import { getErrorMsg } from 'src/utils/ErrorHandling';
import { store } from '../rootReducer';
import types from './member.Types';

const activeMemberFilter = (filter, payload) => ({
  type: types.setActiveFilter,
  filter,
  payload
});

const AllMembersFilter = (filter, payload) => ({
  type: types.setAllFilter,
  filter,
  payload
});

const inActiveMemberFilter = (filter, payload) => ({
  type: types.setInActiveFilter,
  filter,
  payload
});

// export const getoneTimeClass = (val) => {
//   // eslint-disable-next-line
//   return new Promise((res, _) => {
//     setTimeout(() => {
//       return res({ ...val });
//     }, 4000);
//   });
// };

const fetchingmembers = (type) => ({
  type,
  actionType: types.isFetching
});

const fetchComplete = (type, payload) => ({
  type,
  actionType: types.fetchSucess,
  payload
});

const ErrorFetching = (type, payload) => ({
  type,
  actionType: types.Errorfetch,
  payload
});

export const toggleSelectAll = (payload) => ({
  type: types.toggleSelect,
  payload
});

// get Classes function
const getactiveMembers = (val) => {
  const type = types.getActiveMem;
  return (dispatch) => {
    dispatch(fetchingmembers(type));
    getMembers(val)
      .then((resp) => dispatch(fetchComplete(type, resp)))
      .catch((err) => dispatch(ErrorFetching(type, getErrorMsg(err))));
  };
};

const getAllMembers = (val) => {
  const type = types.getAllMem;
  return (dispatch) => {
    dispatch(fetchingmembers(type));
    getMembers(val)
      .then((res) => dispatch(fetchComplete(type, res)))

      .catch((err) => dispatch(ErrorFetching(type, getErrorMsg(err))));
  };
};

const getInActiveMembers = (val) => {
  const type = types.getInActiveMem;
  return (dispatch) => {
    dispatch(fetchingmembers(type));
    getMembers(val)
      .then((res) => dispatch(fetchComplete(type, res)))
      .catch((err) => dispatch(ErrorFetching(type, getErrorMsg(err))));
  };
};

export const getAllActiveMem = () => {
  const type = types.getAllActiveMem;
  return (dispatch) => {
    dispatch(fetchingmembers(type));
    getAllActivs()
      .then((res) => dispatch(fetchComplete(type, res)))
      .catch((err) => dispatch(ErrorFetching(type, getErrorMsg(err))));
  };
};

// filter Async
export const getActiveMem = (filter, data) => {
  return (dispatch) => {
    dispatch(activeMemberFilter(filter, data));
    const filData = store.getState().memberReducer.actMemFilter;
    let updatedValue = {
      page: filData.page,
      ...filData.sorting,
      ...filData.default
    };
    if (filData.search.length > 0) {
      updatedValue = {
        ...updatedValue,
        search: filData.search
      };
    }
    if (Object.keys(filData.customFilter).length > 0) {
      updatedValue = {
        ...updatedValue,
        ...filData.customFilter
      };
    }
    dispatch(getactiveMembers(updatedValue));
  };
};

export const getInactivemem = (filter, data) => {
  return (dispatch) => {
    dispatch(inActiveMemberFilter(filter, data));
    const filData = store.getState().memberReducer.InactMemFilter;
    let updatedValue = {
      page: filData.page,
      ...filData.default,
      ...filData.sorting
    };
    if (filData.search.length > 0) {
      updatedValue = {
        ...updatedValue,
        search: filData.search
      };
    }
    if (Object.keys(filData.customFilter).length > 0) {
      updatedValue = {
        ...updatedValue,
        ...filData.customFilter
      };
    }
    dispatch(getInActiveMembers(updatedValue));
  };
};

export const getAllMem = (filter, data) => {
  return (dispatch) => {
    dispatch(AllMembersFilter(filter, data));
    const filData = store.getState().memberReducer.allMemFilter;
    let updatedValue = {
      page: filData.page,
      ...filData.sorting
    };
    if (filData.search.length > 0) {
      updatedValue = {
        ...updatedValue,
        search: filData.search
      };
    }
    if (Object.keys(filData.customFilter).length > 0) {
      updatedValue = {
        ...updatedValue,
        ...filData.customFilter
      };
    }
    dispatch(getAllMembers(updatedValue));
  };
};
