import React from 'react';
import PropType from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import Typography from '@material-ui/core/Typography';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import JBButton from '../../common/JBButton/JBButton';
import X from '../../../assets/image/x.png';

const styles = () => ({
  Deletebtn: {
    background: '#FB4E4E',
    boxShadow: '0px 8px 16px rgba(251, 78, 78, 0.16)',
    borderRadius: '2px',
    height: '48px',
    padding: '10px 15px',
    color: '#fff',
    fontWeight: 'bold',
    '&:hover': {
      background: '#FB4E4E'
    }
  }
});

const DeleteDialog = React.memo(
  ({ filteredMembers, classes, handleDelete, selectedMembers }) => {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };

    return (
      <div>
        <JBButton
          type="delete"
          onClick={handleClickOpen}
          style={{ float: 'right' }}
          disabled={
            filteredMembers.length === 0 || selectedMembers.length === 0
          }
        >
          Remove programs from plan
        </JBButton>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div style={{ width: 394 }}>
            <DialogTitle id="alert-dialog-title">
              <img
                alt="Plusicon"
                src={X}
                style={{
                  display: 'inline-block',
                  verticalAlign: 'sub',
                  position: 'absolute'
                }}
              />
              <Typography
                color="#000"
                variant="h5"
                component="div"
                className={classes.title}
                style={{ display: 'inline-block', marginLeft: '30px' }}
              >
                Remove programs from members memberships
              </Typography>
            </DialogTitle>
            <DialogContent>
              <p
                style={{
                  fontWeight: 'normal',
                  fontSize: '14px',
                  lineHeight: '18px',
                  letterSpacing: '0.002em',
                  color: '#7F7F7F',
                  padding: '10px 0'
                }}
              >
                You are going to remove programs from members memberships. Are
                you sure?
              </p>
            </DialogContent>
            <DialogActions
              style={{
                padding: '20px 20px'
              }}
            >
              <Button
                variant="outlined"
                onClick={handleClose}
                style={{
                  height: '48px',
                  color: '#7F7F7F',
                  fontWeight: 'bold'
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={handleDelete}
                autoFocus
                className={classes.Deletebtn}
              >
                REMOVE
              </Button>
            </DialogActions>
          </div>
        </Dialog>
      </div>
    );
  }
);

DeleteDialog.propTypes = {
  filteredMembers: PropType.array.isRequired,
  selectedMembers: PropType.array.isRequired,
  classes: PropType.object.isRequired,
  handleDelete: PropType.func.isRequired
};
export default withStyles(styles)(DeleteDialog);
