import { SignTypes } from './SignContract.reducer';
import { validMobile, validSign } from '../../../utils/validations';

export const fetchSignCont = (payload) => ({
  type: SignTypes.getData,
  payload
});

export const setpayInitials = (payload) => ({
  type: SignTypes.setpaymentIntials,
  payload: validSign(payload)
});

export const setpolicyInitials = (payload) => ({
  type: SignTypes.setpolicyInitials,
  payload: validSign(payload)
});

export const setSignStatus = () => ({
  type: SignTypes.setSignStatus
});

export const setFistname = (payload) => ({
  type: SignTypes.setFistName,
  payload
});

export const setlastName = (payload) => ({
  type: SignTypes.setlasttName,
  payload
});

export const setSignEmail = (payload) => ({
  type: SignTypes.setemail,
  payload
});

export const setSignGender = (payload) => ({
  type: SignTypes.setGender,
  payload
});

export const setSignAddress = (payload) => ({
  type: SignTypes.setAddress,
  payload
});

export const setSignCity = (payload) => ({
  type: SignTypes.setCity,
  payload
});

export const setSignProvince = (payload) => ({
  type: SignTypes.setState,
  payload
});
export const setSignCountry = (payload) => ({
  type: SignTypes.setCountry,
  payload
});

export const setSignPhone = (payload) => ({
  type: SignTypes.setPhone,
  payload: validMobile(payload)
});

export const setSignPostal = (payload) => ({
  type: SignTypes.setPostalCode,
  payload
});

export const setSignDOB = (payload) => ({
  type: SignTypes.setDOB,
  payload
});
