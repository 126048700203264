import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750
  },
  table_th: {
    backgroundColor: '#F9F9FA',
    color: '#292929'
  },
  HeadCell: {
    borderBottom: '1px solid rgb(168, 168, 168)',
    fontWeight: 'bold'
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  },
  TabCell: {
    color: '#000',
    fontWeight: 'bold',
    borderBottom: '1px solid rgb(168, 168, 168)'
  },
  Table_td_link: {
    color: '#01847A',
    fontWeight: '700',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  searchInput: {
    padding: '6px 20px 6px !important;',
    paddingRight: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    height: '35px',
    border: '1px solid #a9a9a9'
  },
  DTags: {
    marginTop: '20px',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px',
    color: '#292929',
    '& label': {
      background: '#EAEAEA',
      borderRadius: '10px',
      padding: '8px 16px',
      display: 'block'
    }
  },
  DTagsClose: {
    color: '#7F7F7F',
    marginLeft: '5px',
    cursor: 'pointer',
    border: '1px solid #7F7F7F',
    borderRadius: '50%',
    fontSize: '14px',
    padding: '0px 4.5px',
    width: '20px',
    height: '20px',
    lineHeight: 'normal',
    fontWeight: 'bolder',
    maxWidth: '20px',
    maxHeight: '20px',
    display: 'inline-block'
  },
  selected: {
    backgroundColor: '#E6F3F2 !important'
  }
}));
export default useStyles;
