export const ACTION_TYPES = {
  isFetching: 'ALLIES_DATA_FETHING',
  fetchSucess: 'ALLIES_DATA_FETCHED',
  Errorfetch: 'ALLIES_DATA_FETCHED_ERROR'
};

export const ALLIES_DETAILS = 'ALLIES_DETAILS';
export const CHANGE_ACTIVE_TAB = 'CHANGE_ACTIVE_TAB';
export const PAST_INVOICE = 'ALLIES_PAST_INVOICE';
export const PAST_INVOICE_FILTER = 'PAST_INVOICE_FILTER';
export const PLAN_DETAILS = 'ALLIES_PLAN_DETAILS';
export const DISCOUNTS = 'DISCOUNTS';
export const DISCOUNT_EXPIRE_TYPE = 'DISCOUNT_EXPIRE_TYPE';
export const DISCOUNT_MONTHS = 'DISCOUNT_MONTHS';
export const DISCOUNT_END_DATE = 'DISCOUNT_END_DATE';
export const DISCOUNT_TYPE = 'DISCOUNT_TYPE';
export const DISCOUNT_AMOUNT = 'DISCOUNT_AMOUNT';
export const DISCOUNT_VALUE = 'DISCOUNT_VALUE';
export const TRANSACTION_CHARGES = 'TRANSACTION_CHARGES';
export const RESET_VALUES = 'RESET';
