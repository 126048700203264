import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Box, Grid, Typography } from '@material-ui/core';
import Page from 'src/components/Page';
import Dialog from '@material-ui/core/Dialog';
import Logo from 'src/components/Logo';
import queryString from 'query-string';
// import Alert from '@material-ui/lab/Alert';
import { ROUTES } from 'src/constant/Constants';
import LoginForm from './LoginForm/form';
import loginBg from '../../assets/image/login_bg.png';
import ResetPassDialog from './ResetPass/resetPass.dialogbox';
import ResetPassForm from './ResetPass/ResetPassForm';

const styles = (theme) => ({
  root: {
    backgroundColor: '#f9f9fa',
    height: '100%',
    '@media (max-width: 1024px)': {
      display: 'flex',
      height: 'auto',
      '& .ipad-LogniImg': {
        position: 'absolute',
        top: '0',
        zIndex: '0',
        margin: '0 auto',
        left: '0',
        right: '0',
        height: '100vh'
      }
    }
  },
  rootBox: {
    maxWidth: '1400px',
    margin: '0 auto',
    overflow: 'hidden',
    '@media (max-width: 1024px)': {
      display: 'flex',
      flexDirection: 'column'
      // justifyContent: 'end'
    }
  },
  loginbox: {
    height: '100vh',
    padding: '81px 110px',
    [theme.breakpoints.down('lg')]: {
      padding: '30px 110px'
    },
    '@media (max-width: 1024px)': {
      zIndex: '1',
      position: 'absolute',
      padding: '30px 35px',
      margin: '0 60px',
      opacity: '0.9',
      height: 'auto',
      bottom: '43px',
      left: '0',
      right: '0',
      borderRadius: '10px'
    }
  },
  Logni_img: {
    width: '100%',
    height: 'calc(100vh - 0px)',
    objectFit: 'cover',
    display: 'block'
  },
  logintext: {
    fontWeight: '900',
    fontSize: '44px',
    lineHeight: '54px',
    letterSpacing: '0.5px',
    color: '#01847A',
    marginTop: '96px',
    [theme.breakpoints.down('lg')]: {
      marginTop: '25px'
    }
  },
  releaseV: {
    position: 'absolute',
    left: '10px',
    display: 'inline-block',
    bottom: '10px',
    color: '#37847a'
  }
});

const LoginView = (props) => {
  const { classes, location, history } = props;
  const [open, setOpen] = useState(false);
  const { token, email } = queryString.parse(location.search);
  const [resetopen, setresetopen] = useState(() => Boolean(token && email));

  const handleresetemail = () => {
    setOpen(false);
    history.push(ROUTES.APP_LOGIN);
  };

  const handleresetpass = () => {
    setresetopen(false);
    history.push(ROUTES.APP_LOGIN);
  };

  return (
    <Page className={classes.root} title="Login">
      <Box className={classes.rootBox}>
        {/* <Container> */}
        <Grid
          container
          justify="center"
          alignItems="center"
          className="grid-container"
        >
          <Grid
            item
            lg={6}
            xs={12}
            xl={6}
            // md={6}
            className={('grid-column', classes.loginbox)}
            style={{
              backgroundColor: '#F9F9FA'
              // justifyContent: 'center',
              // display: 'flex',
              // flexDirection: 'column'
            }}
          >
            <RouterLink to={ROUTES.APP_LOGIN}>
              <Logo />
            </RouterLink>
            <Box mb={3}>
              <Typography
                color="textPrimary"
                variant="h2"
                className={classes.logintext}
              >
                Sign in to the Jungle Dashboard
              </Typography>
            </Box>
            <LoginForm openReset={() => setOpen(true)} />
            {/* ============Reset Password============================ */}
            <Dialog
              open={open}
              onClose={handleresetemail}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              className={classes.LgDialog}
            >
              <ResetPassDialog closeDialog={handleresetemail} />
            </Dialog>
            {resetopen && (
              <Dialog
                open={resetopen}
                onClose={handleresetpass}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className={classes.LgDialog}
              >
                <ResetPassForm
                  closeDialog={handleresetpass}
                  data={{ token, email }}
                />
              </Dialog>
            )}

            {/* {open && (
                <Alert
                  variant="outlined"
                  severity="success"
                  style={{
                    color: '#1AB759',
                    background: 'rgb(42 199 105 / 0.04)',
                    position: 'absolute',
                    bottom: '35px',
                    fontWeight: 'bold',
                    fontSize: '14px',
                    lineHeight: '18px',
                    maxWidth: '320px'
                  }}
                >
                  Password has been changed succesfully
                </Alert>
              )} */}
          </Grid>
          <Grid
            item
            xs={12}
            lg={6}
            xl={6}
            className="grid-column ipad-LogniImg"
          >
            <img alt="img" src={loginBg} className={classes.Logni_img} />
          </Grid>
        </Grid>
      </Box>
      <Box className={classes.releaseV}>
        <p>v 1.6.2</p>
      </Box>
    </Page>
  );
};

LoginView.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default withStyles(styles)(LoginView);
