/* eslint-disable operator-linebreak */
/* eslint-disable indent */
import React, { memo, useState } from 'react';
import Button from '@material-ui/core/Button';
import { useDispatch } from 'react-redux';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropType from 'prop-types';
import { Grid, Box, Avatar } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import LinearProgress from '@material-ui/core/LinearProgress';
import JBButton from 'src/views/common/JBButton/JBButton';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import dcloseicon from 'src/assets/image/closeicon.png';
import arrowleft2 from 'src/assets/image/arrowleft_v2.png';
import UserIcon from 'src/assets/image/user.png';
import { openNoti } from 'src/Redux/global/global.actions';
import { getErrorMsg } from 'src/utils/ErrorHandling';
import { updateSigClass } from 'src/services/classModule.services';
import {
  AllClassdata,
  futureddata,
  reccurdata
} from 'src/Redux/ClassReducer/classReducer.actions';
import { getCalenderData } from 'src/Redux/CalenderReducer/Calender.actions';
import styles from './Dialog.style';
import { getUpdateDiag } from './Dialog.utils';

function ReservDialog(props) {
  const { classes, goBack, onClose, data, getUpdate } = props;
  const [value, setValue] = useState(data);
  // eslint-disable-next-line operator-linebreak
  // const isEditable =
  //   data.start_time.getTime() > new Date().getTime() && data.status === 1;
  const [processing, setProcessing] = useState(false);

  const dispatch = useDispatch();
  const handleSave = (e) => {
    e.stopPropagation();
    const updatedValues = getUpdateDiag(value);
    if (!updatedValues) {
      dispatch(openNoti('Please provide valid data', 'error'));
    } else {
      setProcessing(() => true);
      updateSigClass(updatedValues, data.id)
        .then(() => getUpdate(value))
        .then(() => dispatch(openNoti('Successfully saved the class')))
        .then(() => {
          dispatch(getCalenderData());
          dispatch(AllClassdata());
          dispatch(futureddata());
          dispatch(reccurdata());
        })
        .catch((err) => dispatch(openNoti(getErrorMsg(err), 'error')))
        .finally(() => setProcessing(() => false));
    }
  };

  return (
    <div>
      <div style={{ width: '280px' }} className={classes.dmainbk}>
        <Grid alignItems="center" container direction="row">
          <Grid item xs style={{ cursor: 'pointer' }}>
            <Button
              className={classes.btns}
              style={{
                display: 'inline-block',
                marginLeft: '11px',
                marginRight: '0',
                '&.MuiTouchRipple-root': {
                  display: 'none '
                }
              }}
              onClick={() => goBack()}
            >
              <img alt="img" src={arrowleft2} />
            </Button>
            <DialogTitle
              id="alert-dialog-title"
              color="primary"
              className={classes.titlebk}
              onClick={() => goBack()}
              style={{
                display: 'inline-block',
                cursor: 'pointer'
              }}
            >
              <h4 className={classes.dtitle}>Back to Settings</h4>
            </DialogTitle>
          </Grid>

          <Grid item style={{ textAlign: 'right', marginRight: '10px' }}>
            <Button className={classes.btns} onClick={() => onClose()}>
              <img alt="img" src={dcloseicon} />
            </Button>
          </Grid>
        </Grid>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Box display="block" mt={1} mb={1}>
              <Box className={classes.reservationsbk}>
                <h3 className={classes.dh3} style={{ color: '#01847A' }}>
                  {
                    value.get_attendance?.filter(
                      (item) => item.status === 'reserved'
                    ).length
                  }{' '}
                  Reservations
                </h3>
                <ButtonGroup>
                  <Button
                    aria-label="reduce"
                    color="primary"
                    style={{
                      border: '0px solid rgba(0, 0, 0, 0.23)',
                      padding: '5px 5px'
                    }}
                    // disabled={
                    //   data.class_limit >= value.class_limit || !data.is_limit
                    // }
                    disabled
                    onClick={() => {
                      setValue(() => ({
                        ...value,
                        class_limit: value.class_limit - 1
                      }));
                    }}
                  >
                    <RemoveIcon fontSize="small" />
                  </Button>
                  <Button
                    aria-label="increase"
                    color="primary"
                    style={{
                      border: '0px solid rgba(0, 0, 0, 0.23)',
                      padding: '5px 5px'
                    }}
                    // disabled={!data.is_limit || !isEditable}
                    disabled
                    onClick={() => {
                      setValue(() => ({
                        ...value,
                        class_limit: value.class_limit + 1
                      }));
                    }}
                  >
                    <AddIcon fontSize="small" />
                  </Button>
                </ButtonGroup>
              </Box>

              <LinearProgress
                variant="determinate"
                value={
                  !data.is_limit
                    ? 0
                    : (value.get_attendance.length / value.class_limit) * 100 >
                      100
                    ? 100
                    : (value.get_attendance.length / value.class_limit) * 100
                }
                className={classes.dprogress}
                style={{ marginTop: '6px' }}
              />
              {data.class_limit < value.class_limit && (
                <Link
                  component="button"
                  className={classes.dLink}
                  style={{
                    margin: '5px 0 15px',
                    padding: '0px 0',
                    color: '#3B827A'
                  }}
                >
                  Remove limits
                </Link>
              )}
            </Box>

            <Box className={classes.duserlist}>
              {value.get_attendance.map((row, index) => (
                <React.Fragment>
                  <Box>
                    <Box className={classes.media}>
                      <Box display="flex">
                        {row.member_profile ? (
                          <img
                            src={row.member_profile || UserIcon}
                            alt="UserIcon"
                            style={{
                              verticalAlign: 'sub',
                              width: '40px',
                              height: '40px',
                              borderRadius: '50%'
                            }}
                          />
                        ) : (
                          <Avatar
                            className={classes.Avataruname}
                            style={{
                              fontSize: '0.9rem'
                            }}
                          >
                            {row.first_name[0] + ' ' + row.last_name[0]}
                          </Avatar>
                        )}
                      </Box>
                      <Box className={classes.mediabody}>
                        <Box
                          className={classes.uName}
                          style={{
                            textDecoration:
                              row.status === 'cancelled'
                                ? 'line-through'
                                : 'auto'
                          }}
                        >
                          {row.member_name}
                        </Box>
                        <Box
                          className={classes.ustatus}
                          style={{
                            textDecoration:
                              row.status === 'cancelled'
                                ? 'line-through'
                                : 'auto'
                          }}
                        >
                          {row.is_waitlist ? 'In waitlist' : row.status}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  {index !== value.get_attendance.length - 1 && (
                    <div className={classes.hr} />
                  )}
                </React.Fragment>
              ))}
            </Box>
            <JBButton
              type="outlined"
              disabled={data.class_limit === value.class_limit || processing}
              onClick={handleSave}
            >
              {processing ? 'Saving...' : 'Save Changes'}
            </JBButton>
          </DialogContentText>
        </DialogContent>
      </div>
    </div>
  );
}

ReservDialog.propTypes = {
  classes: PropType.object.isRequired,
  onClose: PropType.func.isRequired,
  goBack: PropType.func.isRequired,
  data: PropType.object.isRequired,
  getUpdate: PropType.func.isRequired
};

export default withStyles(styles)(memo(ReservDialog));
