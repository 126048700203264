/* eslint-disable operator-linebreak */
import React, { useState } from 'react';
import PropType from 'prop-types';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  TableContainer,
  TableSortLabel,
  Button
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import EditIcon from 'src/assets/image/editIcon.png';
import DeleteIcon from 'src/assets/image/deleteIcon.png';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import DeleteReasonDialog from './DeleteReasonDialog';
import EditReason from './EditReasonDialog';
import styles from './table.style';

const ClassTable = ({
  classes,
  values,
  tableHead,
  handleEdit,
  handleDelete
}) => {
  const [openDial, setOpenDial] = useState(false);
  const [id, setid] = useState(null);
  const [openDelDial, setDelDial] = useState(false);
  const [editlabel, seteditlabel] = useState('');
  const data = values;
  return (
    <Paper elevation={0}>
      <TableContainer
        style={{ border: '1px solid #A8A8A8', borderRadius: '5px' }}
      >
        <Table>
          <TableHead className={classes.table_th}>
            <TableRow>
              {tableHead.map((headCell) => (
                <TableCell
                  style={{ width: '10%' }}
                  className={classes.TabCell}
                  key={headCell.id}
                >
                  <TableSortLabel>{headCell.label}</TableSortLabel>
                </TableCell>
              ))}
              <TableCell style={{ width: '6%' }} className={classes.TabCell}>
                &nbsp;
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={classes.Table_Body}>
            {data &&
              values.map((row) => (
                <TableRow key={row.Classname}>
                  {tableHead.map((cells) => {
                    return cells.href ? (
                      <TableCell
                        scope="row"
                        key={cells.id}
                        className={classes.Table_td}
                      >
                        <Link
                          to={`${cells.href}`}
                          className={classes.Table_td_link}
                        >
                          {row[cells.cellName]}
                        </Link>
                      </TableCell>
                    ) : (
                      <TableCell
                        scope="row"
                        className={classes.Table_td_link}
                        key={cells.id}
                      >
                        {row[cells.cellName]}
                      </TableCell>
                    );
                  })}
                  <TableCell
                    scope="row"
                    align="right"
                    className={classes.Table_td}
                  >
                    {row.status === 1 ? (
                      <>
                        <Button
                          className={classes.actionbtn}
                          onClick={() => {
                            setid(row.id);
                            seteditlabel(row.label);
                            setOpenDial(true);
                          }}
                        >
                          <img alt="EditIcon" src={EditIcon} />
                        </Button>

                        <Button
                          className={classes.actionbtn}
                          onClick={() => {
                            setid(row.id);
                            seteditlabel(row.label);
                            setDelDial(true);
                          }}
                        >
                          <img alt="DeleteIcon" src={DeleteIcon} />
                        </Button>
                      </>
                    ) : null}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <EditReason
          setOpen={openDial}
          values={{ editlabel, id }}
          editlabel={editlabel}
          seteditlabel={seteditlabel}
          handleClose={() => setOpenDial(false)}
          handleEdit={handleEdit}
        />
        <DeleteReasonDialog
          setOpen={openDelDial}
          handleDelete={() => {
            handleDelete(editlabel, id);
            // OpenNoti('Delete Successfully', 'info');
            // reduxDispatch(openNoti('Delete Successfully'));
            setDelDial(false);
          }}
          handleClose={() => {
            setDelDial(false);
          }}
        />
      </TableContainer>
    </Paper>
  );
};

ClassTable.propTypes = {
  classes: PropType.object.isRequired,
  values: PropType.array.isRequired,
  tableHead: PropType.array.isRequired,
  handleDelete: PropType.func.isRequired,
  handleEdit: PropType.func.isRequired
};

export default connect(null, null)(withStyles(styles)(ClassTable));
