import React, { useState } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Form, Formik } from 'formik';
import PropType from 'prop-types';
import { openNoti } from 'src/Redux/global/global.actions';
import { setHidden } from 'src/Redux/GymSetupReducer/GymSetup.actions';
import { getLogin } from 'src/services/auth.services';
import {
  Box,
  Button,
  Checkbox,
  Grid,
  Link,
  withStyles
} from '@material-ui/core';
import FormControl from 'src/views/common/FormControl/formControl';
import JBReCaptcha from 'src/views/common/JBReCaptcha/JBrecaptcha';
import { ROUTES } from 'src/constant/Constants';
import { getErrorMsg } from 'src/utils/ErrorHandling';
import { initialValues, validationSchema } from './formControls';

const styles = () => ({
  Checkboxlabel: { color: '#000', fontWeight: 'bold' }
});
const LoginForm = (props) => {
  const { classes, openReset, history, setHide } = props;
  const [inValidToken, setValidToken] = useState(true);
  const onSubmitting = async (loginValues, SubmitProps) => {
    try {
      const { role_id } = await getLogin(loginValues);
      setHide(false);
      history.push(role_id <= 2 ? ROUTES.WORKOUT_LIST : ROUTES.CLASSES_LIST);
    } catch (err) {
      const { OpenNotiDisp } = props;
      SubmitProps.setSubmitting(false);
      if (!err.response) {
        OpenNotiDisp('Something Went Wrong', 'error');
      } else if (err.response.status === 422 || err.response.status === 401) {
        SubmitProps.setFieldError('email', 'Username and Password Not Valid');
      } else {
        OpenNotiDisp(getErrorMsg(err), 'error');
      }
    }
  };
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmitting}
      validationSchema={validationSchema}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        touched,
        isSubmitting,
        isValid,
        values
      }) => (
        <Form>
          <FormControl
            control="input"
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.email}
            label="Email"
            name="email"
            placeholder="Email Address"
            type="email"
          />
          <FormControl
            control="input"
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
            onBlur={handleBlur}
            onChange={handleChange}
            // value={values.password}
            label="Password"
            id="standard-password-input"
            type="password"
            autoComplete="current-password"
            name="password"
            placeholder="Password"
            maxLength={20}
          />
          <Grid
            container
            justify="center"
            alignItems="center"
            className="grid-container"
            style={{ margin: '20px 0px 20px 0px' }}
          >
            <Grid item xs={6}>
              <Checkbox
                color="primary"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
                style={{ padding: '0px 10px 0 0px' }}
              />
              <label className={classes.Checkboxlabel}>
                Keep me signed in.
              </label>
            </Grid>
            <Grid item xs={6}>
              <Link
                component={RouterLink}
                to="?forgotpassword"
                style={{
                  color: '#01847A',
                  fontWeight: 'bold',
                  textAlign: 'right',
                  display: 'block'
                }}
                onClick={() => openReset()}
              >
                Forgot password?
              </Link>
            </Grid>
          </Grid>

          <Box my={2}>
            <Button
              color="primary"
              disabled={!isValid || isSubmitting || inValidToken}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              style={{
                padding: '15px 24px',
                background: '#01847A',
                boxShadow: '0px 8px 16px rgba(0, 126, 255, 0.16)',
                borderRadius: '2px',
                width: '255px',
                marginTop: '30px'
              }}
            >
              {isSubmitting ? 'Signing in...' : 'Sign in'}
            </Button>
            <JBReCaptcha setValidToken={() => setValidToken(false)} />
          </Box>
        </Form>
      )}
    </Formik>
  );
};
LoginForm.propTypes = {
  classes: PropType.object.isRequired,
  openReset: PropType.func.isRequired,
  history: PropType.any.isRequired,
  OpenNotiDisp: PropType.func.isRequired,
  setHide: PropType.func.isRequired
};

const mapToDispatch = (dispatch) => ({
  OpenNotiDisp: (msg, type) => dispatch(openNoti(msg, type)),
  setHide: (payload) => dispatch(setHidden(payload))
});

export default connect(
  null,
  mapToDispatch
)(withRouter(withStyles(styles)(LoginForm)));
