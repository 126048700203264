/* eslint-disable operator-linebreak */
import { SettingReducerTypes } from './classSetting.reducer';

const emailSubTypes = {
  lateEmailvisible: 'LATE_EMAIL_VISIBILTY',
  lateEmailSub: 'LATE_EMAIL_SUBJECT',
  lateEmailText: 'LATE_EMAIL_TEXT',
  nowShowvisible: 'NO_SHOW_EMAIL_VISIBLE',
  noShowSub: 'NO_SHOW_SUBJECT',
  noShowText: 'NO_SHOW_TEXT'
};

function checkOtherSettingState(state, type) {
  if (
    !state.data.default_reservation_settings.res_open_time &&
    type !== SettingReducerTypes.resOpenTime
  ) {
    return { isError: 'Please put valid reservation open' };
  }
  if (
    !state.data.default_reservation_settings.res_open_unit &&
    type !== SettingReducerTypes.resOpenUnit
  ) {
    return { isError: 'Please put valid reservation open unit' };
  }
  if (
    !state.data.default_reservation_settings.res_close_time &&
    type !== SettingReducerTypes.resCloseTime
  ) {
    return { isError: 'Please put valid reservation close time' };
  }
  if (
    !state.data.default_reservation_settings.res_close_unit &&
    type !== SettingReducerTypes.resCloseUnit
  ) {
    return { isError: 'Please put valid reservation close unit' };
  }
  if (
    !state.data.default_reservation_settings.res_cancel_time &&
    type !== SettingReducerTypes.resCancelTime
  ) {
    return { isError: 'Please put valid reservation cancel time' };
  }
  if (
    !state.data.default_reservation_settings.res_cancel_unit &&
    type !== SettingReducerTypes.resCancelUnit
  ) {
    return { isError: 'Please put valid reservation cancel unit' };
  }
  if (
    !state.data.default_reservation_settings.login_open_time &&
    type !== SettingReducerTypes.loginOpenTime
  ) {
    return { isError: 'Please put valid reservation login time' };
  }
  if (
    !state.data.default_reservation_settings.login_open_unit &&
    type !== SettingReducerTypes.loginOpenUnit
  ) {
    return { isError: 'Please put valid reservation login time unit' };
  }
  if (
    typeof state.data.late_cancel_settings !== 'number' &&
    type !== SettingReducerTypes.lateCancel
  ) {
    return { isError: 'Late Setting type is invalid' };
  }
  if (
    typeof state.data.late_cancel_email_template?.visibility !== 'number' &&
    type !== emailSubTypes.lateEmailvisible
  ) {
    return { isError: 'Please put valid Late Email visibility' };
  }
  if (
    state.data.late_cancel_email_template?.subject?.trim().length === 0 &&
    type !== emailSubTypes.lateEmailSub
  ) {
    return { isError: 'Please put valid Late Email Subject' };
  }
  if (
    state.data.late_cancel_email_template?.text?.trim().length === 0 &&
    type !== emailSubTypes.lateEmailText
  ) {
    return { isError: 'Please put valid Late Email text' };
  }
  if (
    typeof state.data.no_show_settings !== 'number' &&
    type !== SettingReducerTypes.noShow
  ) {
    return { isError: 'Please put valid No show setting' };
  }
  if (
    typeof state.data.no_show_email_template?.visibility !== 'number' &&
    type !== emailSubTypes.nowShowvisible
  ) {
    return { isError: 'Please put valid no show Email visibility' };
  }
  if (
    state.data.no_show_email_template?.subject?.trim().length === 0 &&
    type !== emailSubTypes.noShowSub
  ) {
    return { isError: 'Please put valid no show Email Subject' };
  }
  if (
    state.data.no_show_email_template?.text?.trim().length === 0 &&
    type !== emailSubTypes.noShowText
  ) {
    return { isError: 'Please put valid no show Email text' };
  }
  return { data: true };
}

function getDefaultReservationSetting(state, payload) {
  return {
    ...state.data.default_reservation_settings,
    ...payload,
    login_open_unit:
      state.data.default_reservation_settings.login_open_unit?.id,
    res_open_unit: state.data.default_reservation_settings.res_open_unit?.id,
    res_close_unit: state.data.default_reservation_settings.res_close_unit?.id,
    res_cancel_unit: state.data.default_reservation_settings.res_cancel_unit?.id
  };
}

// eslint-disable-next-line import/prefer-default-export
export function classSettingValidData(state, action) {
  switch (action.type) {
    case SettingReducerTypes.reservation: {
      const setData = () => {
        switch (action.subType) {
          case SettingReducerTypes.resOpenTime: {
            if (typeof action.payload !== 'number' || !action.payload) {
              return { error: 'Please put valid reservation open' };
            }
            const { isError } = checkOtherSettingState(
              state,
              SettingReducerTypes.resOpenTime
            );
            if (isError) {
              return { error: isError };
            }
            return {
              data: {
                ...state.data,
                default_reservation_settings: getDefaultReservationSetting(
                  state,
                  { res_open_time: action.payload }
                )
              }
            };
          }
          case SettingReducerTypes.resOpenUnit: {
            if (!action.payload) {
              return { error: 'Please put valid reservation open unit' };
            }
            const { isError } = checkOtherSettingState(
              state,
              SettingReducerTypes.resOpenUnit
            );
            if (isError) {
              return { error: isError };
            }
            return {
              data: {
                ...state.data,
                default_reservation_settings: {
                  ...state.data.default_reservation_settings,
                  res_open_unit: action.payload?.id,
                  res_close_unit:
                    state.data.default_reservation_settings.res_close_unit?.id,
                  res_cancel_unit:
                    state.data.default_reservation_settings.res_cancel_unit?.id,
                  login_open_unit: action.payload?.id
                }
              }
            };
          }
          case SettingReducerTypes.resCloseTime: {
            if (typeof action.payload !== 'number' || !action.payload) {
              return { error: 'Please put valid reservation close time' };
            }
            const { isError } = checkOtherSettingState(
              state,
              SettingReducerTypes.resCloseTime
            );
            if (isError) {
              return { error: isError };
            }
            return {
              data: {
                ...state.data,
                default_reservation_settings: getDefaultReservationSetting(
                  state,
                  { res_close_time: action.payload }
                )
              }
            };
          }

          case SettingReducerTypes.resCloseUnit: {
            if (!action.payload) {
              return { error: 'Please put valid reservation close Unit' };
            }
            const { isError } = checkOtherSettingState(
              state,
              SettingReducerTypes.resCloseUnit
            );
            if (isError) {
              return { error: isError };
            }
            return {
              data: {
                ...state.data,
                default_reservation_settings: {
                  ...state.data.default_reservation_settings,
                  res_close_unit: action.payload?.id,
                  res_open_unit:
                    state.data.default_reservation_settings.res_open_unit?.id,
                  res_cancel_unit:
                    state.data.default_reservation_settings.res_cancel_unit?.id,
                  login_open_unit: action.payload?.id
                }
              }
            };
          }
          case SettingReducerTypes.resCancelTime: {
            if (typeof action.payload !== 'number' || !action.payload) {
              return { error: 'Please put valid reservation cancel time' };
            }
            const { isError } = checkOtherSettingState(
              state,
              SettingReducerTypes.resCancelTime
            );
            if (isError) {
              return { error: isError };
            }
            return {
              data: {
                ...state.data,
                default_reservation_settings: getDefaultReservationSetting(
                  state,
                  { res_cancel_time: action.payload }
                )
              }
            };
          }
          case SettingReducerTypes.resCancelUnit: {
            if (!action.payload) {
              return { error: 'Please put valid reservation cancel unit' };
            }
            const { isError } = checkOtherSettingState(
              state,
              SettingReducerTypes.resCancelUnit
            );
            if (isError) {
              return { error: isError };
            }
            return {
              data: {
                ...state.data,
                default_reservation_settings: {
                  ...state.data.default_reservation_settings,
                  res_cancel_unit: action.payload?.id,
                  res_open_unit:
                    state.data.default_reservation_settings.res_open_unit?.id,
                  res_close_unit:
                    state.data.default_reservation_settings.res_close_unit?.id,
                  login_open_unit: action.payload?.id
                }
              }
            };
          }
          case SettingReducerTypes.loginOpenTime: {
            if (typeof action.payload !== 'number' || !action.payload) {
              return { error: 'Please put valid login time' };
            }
            const { isError } = checkOtherSettingState(
              state,
              SettingReducerTypes.loginOpenTime
            );
            if (isError) {
              return { error: isError };
            }
            return {
              data: {
                ...state.data,
                default_reservation_settings: getDefaultReservationSetting(
                  state,
                  { login_open_time: action.payload }
                )
              }
            };
          }
          case SettingReducerTypes.loginOpenUnit: {
            if (!action.payload) {
              return { error: 'Please put valid login time unit' };
            }
            const { isError } = checkOtherSettingState(
              state,
              SettingReducerTypes.loginOpenUnit
            );
            if (isError) {
              return { error: isError };
            }
            return {
              data: {
                ...state.data,
                default_reservation_settings: {
                  ...state.data.default_reservation_settings,
                  login_open_unit: action.payload?.id,
                  res_open_unit:
                    state.data.default_reservation_settings.res_open_unit?.id,
                  res_close_unit:
                    state.data.default_reservation_settings.res_close_unit?.id,
                  res_cancel_unit:
                    state.data.default_reservation_settings.res_cancel_unit?.id
                }
              }
            };
          }
          default:
            return { data: state?.data };
        }
      };
      return setData();
    }
    case SettingReducerTypes.lateCancel: {
      if (typeof action.payload !== 'number') {
        return { error: 'Invalid Late Setting type' };
      }
      const { isError } = checkOtherSettingState(
        state,
        SettingReducerTypes.lateCancel
      );
      if (isError) {
        return { error: isError };
      }
      return {
        data: {
          ...state.data,
          late_cancel_settings: action.payload,
          default_reservation_settings: getDefaultReservationSetting(state)
        }
      };
    }
    case SettingReducerTypes.lateEmail: {
      const getData = () => {
        switch (action.subType) {
          case SettingReducerTypes.enableEmail: {
            if (typeof action.payload !== 'number') {
              return { error: 'Please put valid late email visibility' };
            }
            const { isError } = checkOtherSettingState(
              state,
              emailSubTypes.lateEmailvisible
            );
            if (isError) {
              return { error: isError };
            }
            return {
              data: {
                ...state.data,
                late_cancel_email_template: {
                  ...state.data.late_cancel_email_template,
                  visibility: action.payload
                },
                default_reservation_settings: getDefaultReservationSetting(
                  state
                )
              }
            };
          }
          case SettingReducerTypes.emailSub: {
            if (action.payload?.trim().length === 0) {
              return {
                error: 'Please put late Email subject, its required fields'
              };
            }

            const { isError } = checkOtherSettingState(
              state,
              emailSubTypes.lateEmailSub
            );
            if (isError) {
              return { error: isError };
            }

            return {
              data: {
                ...state.data,
                late_cancel_email_template: {
                  ...state.data.late_cancel_email_template,
                  subject: action.payload
                },
                default_reservation_settings: getDefaultReservationSetting(
                  state
                )
              }
            };
          }
          case SettingReducerTypes.emailText: {
            if (action.payload?.trim().length === 0) {
              return {
                error: 'Please put late Email Text, its required fields'
              };
            }

            const { isError } = checkOtherSettingState(
              state,
              emailSubTypes.lateEmailText
            );
            if (isError) {
              return { error: isError };
            }

            return {
              data: {
                ...state.data,
                late_cancel_email_template: {
                  ...state.data.late_cancel_email_template,
                  text: action.payload
                },
                default_reservation_settings: getDefaultReservationSetting(
                  state
                )
              }
            };
          }
          default:
            return { data: state?.data };
        }
      };
      return getData();
    }
    case SettingReducerTypes.noShow: {
      if (typeof action.payload !== 'number') {
        return { error: 'Please put valid no show email' };
      }
      const { isError } = checkOtherSettingState(
        state,
        SettingReducerTypes.noShow
      );
      if (isError) {
        return { error: isError };
      }
      return {
        data: {
          ...state.data,
          no_show_settings: action.payload,
          default_reservation_settings: getDefaultReservationSetting(state)
        }
      };
    }
    case SettingReducerTypes.noShowEmail: {
      const getData = () => {
        switch (action.subType) {
          case SettingReducerTypes.enableEmail: {
            if (typeof action.payload !== 'number') {
              return { error: 'Please put valid late email visibility' };
            }
            const { isError } = checkOtherSettingState(
              state,
              emailSubTypes.nowShowvisible
            );
            if (isError) {
              return { error: isError };
            }

            return {
              data: {
                ...state.data,
                no_show_email_template: {
                  ...state.data.no_show_email_template,
                  visibility: action.payload
                },
                default_reservation_settings: getDefaultReservationSetting(
                  state
                )
              }
            };
          }
          case SettingReducerTypes.emailSub: {
            if (action.payload?.trim().length === 0) {
              return {
                error: 'Please put no show Email subject, its required fields'
              };
            }

            const { isError } = checkOtherSettingState(
              state,
              emailSubTypes.noShowSub
            );
            if (isError) {
              return { error: isError };
            }

            return {
              data: {
                ...state.data,
                no_show_email_template: {
                  ...state.data.no_show_email_template,
                  subject: action.payload
                },
                default_reservation_settings: getDefaultReservationSetting(
                  state
                )
              }
            };
          }
          case SettingReducerTypes.emailText: {
            if (action.payload?.trim().length === 0) {
              return {
                error: 'Please put now show Email Text, its required fields'
              };
            }

            const { isError } = checkOtherSettingState(
              state,
              emailSubTypes.noShowText
            );
            if (isError) {
              return { error: isError };
            }

            return {
              data: {
                ...state.data,
                no_show_email_template: {
                  ...state.data.no_show_email_template,
                  text: action.payload
                },
                default_reservation_settings: getDefaultReservationSetting(
                  state
                )
              }
            };
          }
          default:
            return { data: state?.data };
        }
      };
      return getData();
    }
    default:
      return { data: state?.data };
  }
}
