import { backendDate, getValuefromObject } from 'src/utils/someCommon';

export const seeFilter = (id, data, endDate) => {
  switch (id) {
    case 1:
      return {
        filter_by_program: getValuefromObject(data),
        program_operator: 1
      };
    case 2:
      return {
        filter_by_date: backendDate(data) + '-' + backendDate(endDate),
        date_operator: 1
      };
    case 3:
      return {
        filter_by_star: getValuefromObject(data),
        star_operator: 1
      };
    case 4:
      return {
        filter_by_tag: getValuefromObject(data),
        tag_operator: 1
      };
    default:
      return null;
  }
};

export const seeAllFilters = (id, data, endDate) => {
  switch (id) {
    case 1:
      return {
        id,
        name: `Program is ${Object.values(data)[1]}`,
        filter: 'filter_by_program',
        connetor: 'program_operator'
      };
    case 2:
      return {
        id,
        name: `Custom Date is ${backendDate(data)
          + '-'
          + backendDate(endDate)}`,
        filter: 'filter_by_date',
        connetor: 'date_operator'
      };
    case 3:
      return {
        id,
        name: `Star is ${Object.values(data)[1]}`,
        filter: 'filter_by_star',
        connetor: 'star_operator'
      };
    case 4:
      return {
        id,
        name: `Tags is ${getValuefromObject(data)}`,
        filter: 'filter_by_tag',
        connetor: 'tag_operator'
      };
    default:
      return null;
  }
};

export const checkData = (filter, data, endDate) => {
  if (!filter || !data || String(data).includes('Invalid')) {
    return true;
  }
  if (filter === 2 && String(endDate).includes('Invalid')) {
    return true;
  }
  return false;
};
