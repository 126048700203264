/* eslint-disable indent */
import React, { Fragment, useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Grid,
  withStyles,
  Card,
  Breadcrumbs,
  Typography,
  Box,
  InputBase,
  FormControlLabel,
  Paper
} from '@material-ui/core';
import { useSelector, connect } from 'react-redux';
import { debounce } from 'lodash';
import StarIcon from '@material-ui/icons/Star';
import filterType from 'src/Redux/Reducer.constants';
import IOSSwitch from 'src/views/common/FormControl/IOSSwitch';
import Pagination from '@material-ui/lab/Pagination';
import LoadingView from 'src/views/common/loading';
import { lastAttendanceReports } from 'src/Redux/ReportsReducer/reports.reducer.actions';
import { exportLast_attendance } from 'src/services/reports.services';
import CancelDialogTwo from 'src/views/common/CommonDialogBox/CancelDialogTwo';
import useChangeTitle from 'src/views/common/CustomHooks/useTitleChange';
import JBButton from '../../common/JBButton/JBButton';
import ImportIcon from '../../../assets/image/upload.png';
import CustomAutoComplete from '../../common/CustomAutocomplete/commonAutocomplete';
import MembershipDetailsTable from './AllTables/LastAttendanceTable/LastAttendanceTable';
import styles from './AttendanceReports.style';

const LastAttendance = (props) => {
  const {
    AttendanceSearch,
    AttendanceFilter,
    AttendanceSort,
    classes,
    allprogramsData,
    programSelected,
    customFilter
  } = props;
  useChangeTitle('Members Last attendance  | Jungle Alliance');
  const { isFetching, data, error } = useSelector(
    (state) => state.reportsReducer.attendanceReports?.lastAttendance
  );
  const { data: data_reports } = useSelector(
    (state) => state.reportsReducer.reportList
  );
  const [prodata, setproData] = useState(
    () => programSelected.filter((l) => l.filter === 'program')[0]
  );
  const [search, setSearch] = useState(AttendanceSearch);
  const [activeuser, setactiveuser] = useState(
    () => !programSelected.filter((l) => l.filter === 'is_active')[0]
  );
  const [open, setOpen] = useState(false);
  const isStarred = data_reports?.attendance_reports.filter(
    (report) => report.id === 8
  )[0].is_starred;
  const SearchQuery = useCallback(
    debounce((q) => AttendanceFilter(filterType.search, q), 1500),
    []
  );

  const ProgramSelect = useCallback(
    debounce(
      // eslint-disable-next-line no-confusing-arrow
      (q, tag) =>
        q
          ? AttendanceFilter(filterType.addcustom, [
              { program: q.id },
              {
                id: 100000035,
                name: `Program is ${q.name}`,
                filter: 'program'
              }
            ])
          : AttendanceFilter(filterType.removecustom, tag),
      1000
    ),
    []
  );

  const userSelect = useCallback(
    debounce(
      // eslint-disable-next-line no-confusing-arrow
      (q) => {
        if (q) {
          // AttendanceFilter(filterType.removecustom, tag);
          AttendanceFilter(filterType.addcustom, [
            { is_active: true },
            {
              id: 1000000000001,
              name: 'Members is active',
              filter: 'is_active'
            }
          ]);
        } else {
          AttendanceFilter(filterType.addcustom, [
            { is_active: false },
            {
              id: 1000000000001,
              name: 'Members is inactive',
              filter: 'is_active'
            }
          ]);
        }
      },
      1000
    ),
    []
  );

  const setDirection = (name) => {
    if (AttendanceSort.sort_name === name) {
      return AttendanceSort.sort_by === 'asc' ? 'asc' : 'dsc';
    }
    return 'asc';
  };

  const isActive = (sortlabel) => {
    if (AttendanceSort.sort_name === sortlabel) return true;
    return false;
  };

  const setSort = (sortlabel) => {
    AttendanceFilter(filterType.sorting, sortlabel);
  };

  const Paginate = (val) => {
    if (val !== data.current_page) {
      return AttendanceFilter(filterType.page, val);
    }
    return null;
  };

  const handleReset = () => {
    AttendanceFilter(filterType.resetFilter);
    setactiveuser(
      () => !programSelected.filter((l) => l.filter === 'is_active')[0]
    );
    setSearch('');
    setproData(null);
  };
  useEffect(() => {
    return () => {
      handleReset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (!data_reports || !data) return <LoadingView />;

  return (
    <div className={classes.root}>
      <div className="title-bk">
        <Box css={{ marginBottom: '20px' }}>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={0}
          >
            <Grid item xs={9}>
              <Box display="inline-flex">
                <Breadcrumbs aria-label="breadcrumb">
                  <div color="inherit">Reports</div>
                  <div
                    color="inherit"
                    onClick={() => setOpen(true)}
                    style={{
                      cursor: 'pointer'
                    }}
                  >
                    attendance reports
                  </div>
                  <Typography variant="h3" color="Primary">
                    <span>Members Last attendance </span>
                  </Typography>
                </Breadcrumbs>
              </Box>
              <Box
                display="inline-flex"
                ml="10px"
                css={{ verticalAlign: 'text-bottom' }}
              >
                {isStarred === 1 && (
                  <StarIcon
                    style={{
                      color: '#FFC700'
                    }}
                  />
                )}
              </Box>
            </Grid>
            <Grid
              item
              xs={3}
              style={{
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              <JBButton
                type="outlined"
                isImg={ImportIcon}
                component={Link}
                style={{ marginLeft: '10px' }}
                disabled={data.data.length === 0}
                onClick={(e) => {
                  e.preventDefault();
                  exportLast_attendance({
                    search: AttendanceSearch,
                    sort_by: AttendanceSort.sort_by,
                    sort_name: AttendanceSort.sort_name,
                    ...customFilter
                  });
                }}
              >
                Export
              </JBButton>
            </Grid>
          </Grid>
        </Box>
      </div>

      <Card className={classes.mainCard}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={0}
          style={{
            marginBottom: '15px'
          }}
        >
          <Grid item xs={6}>
            <Grid container direction="row" alignItems="center" spacing={0}>
              <Grid item xs={9}>
                <div className={classes.search}>
                  <InputBase
                    fullWidth
                    placeholder="Search…"
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                      SearchQuery(e.target.value);
                    }}
                    classes={{
                      root: classes.inputRoot,
                      input: classes.searchInput
                    }}
                    InputProps={{
                      'aria-label': 'search'
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs>
                <JBButton
                  type="outlined"
                  style={{ marginLeft: '10px' }}
                  onClick={() => handleReset()}
                >
                  reset
                </JBButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} justify="flex-end" className="MuiGrid-container">
            <Box
              mr="20px"
              css={{
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                marginRight: '20px'
              }}
            >
              <FormControlLabel
                control={
                  <IOSSwitch
                    checked={activeuser}
                    onChange={(e) => {
                      setactiveuser(e.target.checked);
                      userSelect(
                        e.target.checked,
                        programSelected.filter(
                          (l) => l.filter === 'is_active'
                        )[0]
                      );
                    }}
                  />
                }
                label="Active members only"
                style={{ margin: '0 0' }}
                // onChange={() =>
                //   dispatch({
                //     type: reducerType.lateCancel,
                //     payload: !late_cancel_settings
                //   })
                // // } // eslint-disable-line
              />
            </Box>
            <Box css={{ width: '240px' }}>
              <CustomAutoComplete
                holder="Select Program"
                Options="name"
                data={prodata || null}
                value={[...allprogramsData, { id: 0, name: 'All' }]}
                Change={(val) => {
                  setproData(val);
                  ProgramSelect(
                    val,
                    programSelected.filter((l) => l.filter === 'program')[0]
                  );
                }}
                style={{ marginTop: '0px' }}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <div>
              {programSelected
                .filter((tags) => tags.filter !== 'is_active')
                .map((tags) => (
                  <div
                    className={classes.DTags}
                    key={tags.id}
                    style={{ display: 'inline-block' }}
                  >
                    <Box display="inline-block" mr={1}>
                      <label>
                        {tags.name}
                        <span
                          className={classes.DTagsClose}
                          onClick={() => {
                            AttendanceFilter(filterType.removecustom, tags);
                            setproData(null);
                          }}
                        >
                          ×
                        </span>
                      </label>
                    </Box>
                  </div>
                ))}
            </div>
          </Grid>
        </Grid>

        {isFetching || !data ? (
          <LoadingView />
        ) : error ? (
          <p>{error}</p>
        ) : (
          <Fragment>
            <Grid item xs={12}>
              <Box mt="25px" />
              <MembershipDetailsTable
                values={data?.data}
                setDirection={setDirection}
                isActive={isActive}
                setSort={setSort}
              />
            </Grid>
            {data?.data.length !== 0 && (
              <Grid
                item
                xs={12}
                container
                direction="row"
                alignItems="center"
                style={{ marginTop: 24 }}
              >
                <Grid item xs={6} justify="flex-start">
                  {data.from && data.to > 1 ? (
                    <Paper elevation={0} style={{ color: '#A9A9A9' }}>
                      {`${data.from} - ${data.to} of ${data.total}`}
                    </Paper>
                  ) : null}
                </Grid>
                <Grid
                  item
                  xs={6}
                  justify="flex-end"
                  style={{ display: 'flex' }}
                >
                  <Pagination
                    count={data.last_page}
                    color="primary"
                    variant="outlined"
                    page={data.current_page}
                    // onChange={(_, val) => console.log(val)}
                    onChange={(__, val) => Paginate(val)}
                    shape="rounded"
                  />
                </Grid>
              </Grid>
            )}
          </Fragment>
        )}
      </Card>
      <CancelDialogTwo setOpen={open} handleClose={() => setOpen(false)} />
    </div>
  );
};

LastAttendance.propTypes = {
  classes: PropTypes.object.isRequired,
  AttendanceFilter: PropTypes.func.isRequired,
  AttendanceSearch: PropTypes.string.isRequired,
  AttendanceSort: PropTypes.object.isRequired,
  allprogramsData: PropTypes.any.isRequired,
  programSelected: PropTypes.array.isRequired,
  customFilter: PropTypes.object.isRequired
};

const mapToState = (state) => ({
  // AllListData: state.LeadReducer.AllLead,
  AttendanceSearch:
    state.reportsReducer.attendanceReports?.lastAttendanceFil.search,
  AttendanceSort:
    state.reportsReducer.attendanceReports?.lastAttendanceFil.sorting,
  programSelected:
    state.reportsReducer.attendanceReports?.lastAttendanceFil.tags,
  customFilter:
    state.reportsReducer.attendanceReports.lastAttendanceFil.customFilter,
  allprogramsData: state.allprograms.programs
});

const mapToDispatch = (dispatch) => ({
  AttendanceFilter: (filter, data) =>
    dispatch(lastAttendanceReports(filter, data))
});

export default connect(
  mapToState,
  mapToDispatch
)(withStyles(styles)(LastAttendance));
