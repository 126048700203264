import React, { memo, Fragment, useCallback, useState, useEffect } from 'react';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Grid,
  withStyles,
  Card,
  Breadcrumbs,
  Typography,
  Box,
  InputBase,
  Paper,
  ThemeProvider
} from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
import Pagination from '@material-ui/lab/Pagination';
import { getexpireMembershipReports } from 'src/Redux/ReportsReducer/reports.reducer.actions';
import { exportMembersExpiring } from 'src/services/reports.services';
import filterType from 'src/Redux/Reducer.constants';
import ImportIcon from 'src/assets/image/upload.png';
import { useSelector, connect } from 'react-redux';
import CancelDialogTwo from 'src/views/common/CommonDialogBox/CancelDialogTwo';
import LoadingView from 'src/views/common/loading';
import useChangeTitle from 'src/views/common/CustomHooks/useTitleChange';
import JBButton from '../../common/JBButton/JBButton';
import CustomAutoComplete from '../../common/CustomAutocomplete/commonAutocomplete';
import ExpiringMembershipsTable from './AllTables/ExpiringMembershipsTable/ExpiringMembershipsTable';
import styles from './MembersReports.style';
// import { Filters } from './MemberRepotrsUtils/ExpiringMembership.utils';

const themeInstance = {
  overrides: {
    MuiBreadcrumbs: {
      root: {
        color: '#949494',
        textTransform: 'uppercase',
        fontSize: '20px',
        lineHeight: '24px',
        fontWeight: '700'
      },
      li: {
        '& a': {
          color: '#949494',
          textTransform: 'uppercase',
          fontSize: '20px',
          lineHeight: '24px'
        },
        '@media (max-width: 1440px)': {
          '& a': {
            fontSize: '18px'
          },
          '& h3': {
            fontSize: '18px'
          }
        },
        '& h3': {
          margin: '0 0'
        }
      },
      separator: {
        marginLeft: '12px',
        marginRight: '12px'
      }
    }
  }
};

const customFilterOptions = [
  { name: 'Next 7 days', id: 7 },
  { name: 'Next 30 days', id: 30 },
  { name: 'Next 60 days', id: 60 }
];

const ExpiringMemberships = memo((props) => {
  const {
    ExpiringMembershipsSearch,
    FilterData,
    ExpiringMembershipSort,
    expiresInNextDays,
    classes,
    customFilter
  } = props;
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState(ExpiringMembershipsSearch);
  const { isFetching, error, data } = useSelector((state) => {
    return state.reportsReducer.membersReports?.expireMemberships;
  });
  useChangeTitle('Members with Expiring Memberships | Jungle Alliance');
  const [customFilterData, setCustomFilterData] = useState(
    () => expiresInNextDays.filter((l) => l.filter === 'next_day')[0]
  );

  const { data: data_reports } = useSelector(
    (state) => state.reportsReducer.reportList
  );

  const SearchQuery = useCallback(
    debounce((q) => FilterData(filterType.search, q), 1500),
    []
  );

  const isStarred = data_reports?.members_report.filter(
    (report) => report.id === 2
  )[0].is_starred;

  const setDirection = (name) => {
    if (ExpiringMembershipSort.sort_name === name) {
      return ExpiringMembershipSort.sort_by === 'asc' ? 'asc' : 'dsc';
    }
    return 'asc';
  };

  const isActive = (sortlabel) => {
    if (ExpiringMembershipSort.sort_name === sortlabel) return true;
    return false;
  };

  const setSort = (sortlabel) => {
    FilterData(filterType.sorting, sortlabel);
  };

  const addCustomFilter = useCallback(
    debounce(
      /*eslint-disable */

      (q, tag) =>
        q
          ? FilterData(filterType.addcustom, [
              { next_day: q?.id },
              {
                id: 10000001,
                name: `Expires ${q?.name}`,
                filter: 'next_day'
              }
            ])
          : FilterData(filterType.removecustom, tag),
      1000
    ),
    []
  );
  /* eslint-enable */

  const Paginate = (val) => {
    if (val !== data.current_page) {
      return FilterData(filterType.page, val);
    }
    return null;
  };

  const handleReset = () => {
    FilterData(filterType.resetFilter);
    setSearch('');
    setCustomFilterData(null);
  };
  useEffect(() => {
    return () => {
      handleReset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (!data_reports || !data) return <LoadingView />;

  return (
    <div className={classes.root}>
      <div className="title-bk">
        <Box css={{ marginBottom: '20px' }}>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={0}
          >
            <Grid item xs={9}>
              <Box display="inline-flex">
                <ThemeProvider theme={themeInstance}>
                  <Breadcrumbs aria-label="breadcrumb">
                    <div color="inherit">Reports</div>
                    <div
                      color="inherit"
                      onClick={() => setOpen(true)}
                      style={{
                        cursor: 'pointer'
                      }}
                    >
                      members reports
                    </div>
                    <Typography variant="h3" color="Primary">
                      <span>Members with Expiring Memberships</span>
                    </Typography>
                  </Breadcrumbs>
                </ThemeProvider>
              </Box>
              <Box
                display="inline-flex"
                ml="10px"
                css={{ verticalAlign: 'text-bottom' }}
              >
                {isStarred === 1 && (
                  <StarIcon
                    style={{
                      color: '#FFC700'
                    }}
                  />
                )}
              </Box>
            </Grid>
            <Grid
              item
              xs={3}
              style={{
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              <JBButton
                type="outlined"
                isImg={ImportIcon}
                component={Link}
                style={{ marginLeft: '10px' }}
                disabled={data.data.length === 0}
                onClick={(e) => {
                  e.preventDefault();
                  exportMembersExpiring({
                    search: ExpiringMembershipsSearch,
                    sort_name: ExpiringMembershipSort.sort_name,
                    sort_by: ExpiringMembershipSort.sort_by,
                    ...customFilter
                  });
                }}
              >
                Export
              </JBButton>
            </Grid>
          </Grid>
        </Box>
      </div>

      <Card className={classes.mainCard}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={0}
        >
          <Grid item xs={6}>
            <Grid container direction="row" alignItems="center" spacing={0}>
              <Grid item xs={9}>
                <div className={classes.search}>
                  <InputBase
                    fullWidth
                    placeholder="Search…"
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                      SearchQuery(e.target.value);
                    }}
                    classes={{
                      root: classes.inputRoot,
                      input: classes.searchInput
                    }}
                    InputProps={{
                      'aria-label': 'search'
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs>
                <JBButton
                  type="outlined"
                  style={{ marginLeft: '10px' }}
                  onClick={handleReset}
                >
                  reset
                </JBButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} justify="flex-end" className="MuiGrid-container">
            <Box css={{ width: '240px' }}>
              <CustomAutoComplete
                holder="Expires: next 30 days"
                Options="name"
                data={customFilterData || null}
                value={customFilterOptions}
                Change={(val) => {
                  setCustomFilterData(val);
                  addCustomFilter(
                    val,
                    expiresInNextDays.filter((l) => l.filter === 'next_day')[0]
                  );
                }}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <div>
            {expiresInNextDays?.map((tags) => (
              <div
                className={classes.DTags}
                key={tags.id}
                style={{ display: 'inline-block' }}
              >
                <Box display="inline-block" mr={1}>
                  <label>
                    {tags.name}
                    <span
                      className={classes.DTagsClose}
                      onClick={() => {
                        FilterData(filterType.removecustom, tags);
                        setCustomFilterData(null);
                      }}
                    >
                      ×
                    </span>
                  </label>
                </Box>
              </div>
            ))}
          </div>
        </Grid>
        {isFetching ? (
          <LoadingView />
        ) : error ? (
          <p>{error}</p>
        ) : (
          <Fragment>
            <Grid item xs={12}>
              <Box mt="25px" />
              <ExpiringMembershipsTable
                values={data?.data}
                setDirection={setDirection}
                isActive={isActive}
                setSort={setSort}
              />
            </Grid>
            {data?.data.length !== 0 && (
              <Grid
                item
                xs={12}
                container
                direction="row"
                alignItems="center"
                style={{ marginTop: 24 }}
              >
                <Grid item xs={6} justify="flex-start">
                  <Paper elevation={0} style={{ color: '#A9A9A9' }}>
                    {data?.from && data?.to > 1 ? (
                      <Paper elevation={0} style={{ color: '#A9A9A9' }}>
                        {`${data?.from} - ${data?.to} of ${data?.total}`}
                      </Paper>
                    ) : null}
                  </Paper>
                </Grid>
                <Grid
                  item
                  xs={6}
                  justify="flex-end"
                  style={{ display: 'flex' }}
                >
                  <Pagination
                    count={data?.last_page}
                    color="primary"
                    variant="outlined"
                    page={data?.current_page}
                    onChange={(_, val) => Paginate(val)}
                    shape="rounded"
                  />
                </Grid>
              </Grid>
            )}
          </Fragment>
        )}
      </Card>
      <CancelDialogTwo setOpen={open} handleClose={() => setOpen(false)} />
    </div>
  );
});

ExpiringMemberships.propTypes = {
  classes: PropTypes.object.isRequired,
  ExpiringMembershipsSearch: PropTypes.string.isRequired,
  FilterData: PropTypes.func.isRequired,
  ExpiringMembershipSort: PropTypes.object.isRequired,
  expiresInNextDays: PropTypes.array.isRequired,
  customFilter: PropTypes.object.isRequired
};

const mapDispatchToProps = (dispatch) => ({
  FilterData: (filter, data) =>
    dispatch(getexpireMembershipReports(filter, data))
});

const mapStateToProps = (state) => ({
  ExpiringMembershipsSearch:
    state.reportsReducer.membersReports?.expireMembershipFil.search,
  ExpiringMembershipSort:
    state.reportsReducer.membersReports?.expireMembershipFil.sorting,
  expiresInNextDays:
    state.reportsReducer.membersReports?.expireMembershipFil.tags,
  customFilter:
    state.reportsReducer.membersReports.expireMembershipFil.customFilter
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ExpiringMemberships));
