/* eslint-disable function-paren-newline */
/* eslint-disable arrow-parens */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles, Typography, Box, Paper } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { getErrorMsg } from 'src/utils/ErrorHandling';
import { openNoti } from 'src/Redux/global/global.actions';
import Pagination from '@material-ui/lab/Pagination';
import { USER_INVOICE_PAGE_SIZE } from 'src/constant/Constants';
import { StripeLinkSend } from 'src/services/masterhq.allies.services';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import LoadingView from 'src/views/common/loading';
import { getAffiliatePayments } from 'src/services/gym.services';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import _ from 'lodash';
import VisaCardIcon from '../../../../../assets/image/visacard.png';
import JBButton from '../../../../common/JBButton/JBButton';
import PaymentBillingTable from './PaymentBillingTable/PaymentBillingTable';
// import { DummyValues } from './PaymentBillingTable/dummyData';
import styles from './PaymentBilling.style';
import PaymentBillingPaginate from './PaymentBillingTable/PaymentBilling.paginate';
import PaymentMethod from './PaymentMethod';

const PaymentBilling = ({ classes }) => {
  const location = useLocation();
  const { affiliateId } = queryString.parse(location.search);
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const [order, setOrder] = useState('asc');
  const [sortName, setSortName] = useState(null);
  const [data, setData] = useState(() => ({
    isFetching: true,
    data: null,
    error: null
  }));

  useEffect(() => {
    getAffiliatePayments(Number(affiliateId))
      .then((res) =>
        setData(() => ({
          isFetching: false,
          data: {
            ...res,
            invoice_list_paginate: PaymentBillingPaginate(
              1,
              res.invoice_list,
              USER_INVOICE_PAGE_SIZE
            )
          },
          error: null
        }))
      )
      .catch((err) =>
        setData(() => ({
          isFetching: false,
          data: null,
          error: getErrorMsg(err)
        }))
      );
  }, [affiliateId]);
  useEffect(() => {
    if (!data.isFetching) {
      setData((prev) => {
        return {
          ...prev,
          data: {
            ...prev.data,
            invoice_list: _.orderBy(
              prev.data.invoice_list,
              [sortName],
              [order]
            ),
            invoice_list_paginate: PaymentBillingPaginate(
              currentPage,
              _.orderBy(prev.data.invoice_list, [sortName], [order]),
              USER_INVOICE_PAGE_SIZE
            )
          }
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortName, order]);
  const isActive = (name) => {
    if (name === sortName) return true;
    return false;
  };
  const Paginate = (val) => {
    if (val !== currentPage) {
      setCurrentPage(() => val);
      setData(() => ({
        ...data,
        data: {
          ...data.data,
          invoice_list_paginate: PaymentBillingPaginate(
            val,
            data.data.invoice_list,
            USER_INVOICE_PAGE_SIZE
          )
        }
      }));
    }
  };

  const sendLink = async (e) => {
    e.stopPropagation();
    try {
      await StripeLinkSend(affiliateId);
      dispatch(openNoti('Successfully sent email'));
    } catch (err) {
      dispatch(openNoti(getErrorMsg(err), 'error'));
    }
  };

  if (data.isFetching) {
    return <LoadingView />;
  }
  if (data.error) {
    return <p>{data.error}</p>;
  }

  return (
    <div>
      <Grid
        container
        direction="row"
        spacing={0}
        style={{ margin: '10px 0 0px' }}
      >
        <Grid item xs={12}>
          <Box mt="15px" mb="15px">
            <Typography
              color="#000"
              variant="h5"
              component="div"
              className={classes.bktitle}
            >
              Stripe Connect Account
            </Typography>
          </Box>
          <Box className={classes.Paybk}>
            <span>Status:</span>
            {data.data?.account_details?.account_activated ? (
              <label>
                Verified{' '}
                <CheckCircleOutlineIcon
                  color="primary"
                  style={{
                    verticalAlign: 'sub'
                  }}
                />
              </label>
            ) : (
              <label>
                Pending{' '}
                <ErrorOutlineIcon
                  htmlColor="#9e9e9e"
                  style={{
                    verticalAlign: 'sub'
                  }}
                />
              </label>
            )}
          </Box>
          {data.data?.account_details?.account_activated === 0 && (
            <Box>
              <span
                style={{
                  color: '#949494',
                  marginRight: '4px',
                  display: 'inline-block',
                  fontSize: '14px',
                  fontWeight: 'bold'
                }}
              >
                Stripe Account Connection Link:
              </span>
              <JBButton
                type="primary"
                className={classes.smallBtn}
                onClick={sendLink}
                disabled={data.data.account_details.ally_status === 0}
              >
                Send
              </JBButton>
            </Box>
          )}
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        spacing={0}
        style={{ margin: '10px 0 0px' }}
      >
        <Grid item xs={12}>
          <Box mt="15px" mb="15px">
            <Typography
              color="#000"
              variant="h5"
              component="div"
              className={classes.bktitle}
            >
              Payment Methods
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <PaymentMethod dataval={data} />
      {/* eslint-disable */}
      {data.data.payment_method.length > 0
        ? data.data.payment_method.map((paymentMethod) => (
            <Box
              key={paymentMethod.id}
              style={{
                border: '1px solid #EAEAEA',
                borderRadius: '8px',
                display: 'none'
              }}
              p="15px"
            >
              <Grid
                container
                spacing={2}
                justify="flex-start"
                alignItems="center"
              >
                <Grid item xs={6}>
                  <Box alignItems="center" display="inline-flex">
                    <Box className={classes.cardimage}>
                      <img src={VisaCardIcon} draggable="false" alt="card" />
                    </Box>
                    <Box
                      className={classes.cardnum}
                      display="inline-flex"
                      ml="15px"
                    >
                      <span className={classes.cardnumDots} />
                      <span className={classes.cardnumDots} />
                      <span className={classes.cardnumDots} />
                      <label>{paymentMethod.card_number}</label>
                    </Box>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={6}
                  justify="flex-end"
                  className="MuiGrid-container"
                >
                  <Box className={classes.Payinfobk}>
                    <Box className={classes.Paybk}>
                      <span>Exp:</span>
                      <label>{paymentMethod.card_expiry}</label>
                    </Box>
                    <Box className={classes.Paybk}>
                      <span>Status:</span>
                      <label>
                        {paymentMethod.default_method === 1 ? 'Default' : '/'}
                      </label>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          ))
        : null
      // <p
      //   style={{
      //     color: '#27847a'
      //   }}
      // >
      //   No payment method added
      // </p>
      }
      {/* eslint-enable */}
      <Grid
        container
        direction="row"
        spacing={0}
        style={{ margin: '25px 0 0px' }}
      >
        <Grid item xs={12}>
          <Box mt="15px" mb="20px">
            <Typography
              color="#000"
              variant="h5"
              component="div"
              className={classes.bktitle}
            >
              Past Invoices
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <PaymentBillingTable
            values={data.data.invoice_list_paginate || []}
            setSortName={setSortName}
            setOrder={setOrder}
            isActive={isActive}
            direction={order}
          />
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        container
        direction="row"
        alignItems="center"
        style={{ marginTop: 24 }}
      >
        <Grid item xs={6} justify="flex-start" className={classes.PaginateData}>
          <Paper elevation={0} style={{ color: '#A9A9A9' }}>
            {/* eslint-disable */}
            {currentPage > 1
              ? `${USER_INVOICE_PAGE_SIZE * (currentPage - 1) + 1} - ${
                  data?.data.invoice_list.length >
                  USER_INVOICE_PAGE_SIZE * currentPage
                    ? USER_INVOICE_PAGE_SIZE * currentPage
                    : data?.data.invoice_list.length
                } of ${data?.data.invoice_list.length}`
              : `1 - ${
                  data?.data.invoice_list.length >
                  USER_INVOICE_PAGE_SIZE * currentPage
                    ? USER_INVOICE_PAGE_SIZE * currentPage
                    : data?.data.invoice_list.length
                } of ${data?.data.invoice_list.length}`}
          </Paper>
          {/*eslint-enabled */}
        </Grid>
        <Grid
          item
          xs={6}
          className={classes.Pagination}
          justify="flex-end"
          style={{ display: 'flex' }}
        >
          <Pagination
            count={Math.ceil(
              data?.data.invoice_list.length / USER_INVOICE_PAGE_SIZE
            )}
            color="primary"
            variant="outlined"
            page={currentPage}
            onChange={(_, val) => Paginate(val)}
            shape="rounded"
          />
        </Grid>
      </Grid>
    </div>
  );
};

PaymentBilling.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(PaymentBilling);
