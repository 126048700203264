import filterlist from 'src/Redux/Reducers.utils';
import Types from './InvoiceReducer.types';
import getInvoices from './InvoiceReducer.utils';

const initialValue = {
  unpaid: { isFetching: false, data: null, error: null },
  paid: { isFetching: false, data: null, error: null },
  overdue: { isFetching: false, data: null, error: null },
  all: { isFetching: false, data: null, error: null },
  processing: { isFetching: false, data: null, error: null },
  failed: { isFetching: false, data: null, error: null },
  unpaidFilter: {
    page: 1,
    search: '',
    customFilter: {},
    tags: [],
    sorting: {},
    default: { tab: 1 }
  },
  paidFilter: {
    page: 1,
    search: '',
    customFilter: {},
    tags: [],
    sorting: {},
    default: { tab: 2 }
  },
  overdueFilter: {
    page: 1,
    search: '',
    customFilter: {},
    tags: [],
    sorting: {},
    default: { tab: 3 }
  },
  processingFilter: {
    page: 1,
    search: '',
    customFilter: {},
    tags: [],
    sorting: {},
    default: { tab: 4 }
  },
  failedFilter: {
    page: 1,
    search: '',
    customFilter: {},
    tags: [],
    sorting: {},
    default: { tab: 5 }
  },
  AllFilter: {
    page: 1,
    search: '',
    customFilter: {
      filter_by_this_month: 1,
      filter_by_this_year: 1
    },
    tags: [
      { id: 1000000011, name: 'This Month', filter: 'filter_by_this_month' },
      { id: 1000000012, name: 'This Year', filter: 'filter_by_this_year' }
    ],
    sorting: {},
    default: { tab: 0 }
  },
  areAllSelected: false
};

export default (state = initialValue, action) => {
  switch (action.type) {
    case Types.getUnpaid:
      return {
        ...state,
        unpaid: getInvoices(
          Types.getUnpaid,
          action.actionType,
          state.unpaid,
          action.payload
        )
      };
    case Types.getpaid:
      return {
        ...state,
        paid: getInvoices(
          Types.getpaid,
          action.actionType,
          state.paid,
          action.payload
        )
      };
    case Types.getallIn:
      return {
        ...state,
        all: getInvoices(
          Types.getallIn,
          action.actionType,
          state.all,
          action.payload
        )
      };
    case Types.getoverdue:
      return {
        ...state,
        overdue: getInvoices(
          Types.getoverdue,
          action.actionType,
          state.overdue,
          action.payload
        )
      };
    case Types.getprocessingIn:
      return {
        ...state,
        processing: getInvoices(
          Types.getprocessingIn,
          action.actionType,
          state.processing,
          action.payload
        )
      };
    case Types.getfailedIn:
      return {
        ...state,
        failed: getInvoices(
          Types.getfailedIn,
          action.actionType,
          state.failed,
          action.payload
        )
      };
    case Types.setunpaidFil:
      return {
        ...state,
        unpaidFilter: filterlist(
          state.unpaidFilter,
          action.filter,
          action.payload
        )
      };
    case Types.setpaidFil:
      return {
        ...state,
        paidFilter: filterlist(state.paidFilter, action.filter, action.payload)
      };
    case Types.setDueFil:
      return {
        ...state,
        overdueFilter: filterlist(
          state.overdueFilter,
          action.filter,
          action.payload
        )
      };
    case Types.setAllFil:
      return {
        ...state,
        AllFilter: filterlist(state.AllFilter, action.filter, action.payload)
      };
    case Types.setProcessFil:
      return {
        ...state,
        processingFilter: filterlist(
          state.processingFilter,
          action.filter,
          action.payload
        )
      };
    case Types.setFailedFil:
      return {
        ...state,
        failedFilter: filterlist(
          state.failedFilter,
          action.filter,
          action.payload
        )
      };
    case Types.setAreAllSelected:
      return {
        ...state,
        areAllSelected: action.payload
      };
    default:
      return state;
  }
};
