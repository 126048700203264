import React, { useState, useContext, useEffect } from 'react';
import PropType from 'prop-types';
import JBButton from 'src/views/common/JBButton/JBButton';
import JBDatePicker from 'src/views/common/FormControl/JBDatePicker';
import { InputLabel, withStyles, ThemeProvider } from '@material-ui/core';
import { FilterContext } from 'src/views/common/ApplyFilter/applyFilter';
import { getMinDate } from 'src/utils/someCommon';
import CustomAutoComplete from '../../../../common/CustomAutocomplete/commonAutocomplete';
import styles from './filters.style';
import {
  getFilter,
  getfilterLabel,
  NextdayFilter,
  tobeFilters,
  checkData
} from './Filters.utils';

const AllInvoicesFilters = ({ classes, getFilData }) => {
  const [data, setData] = useState(null);
  const [revenueFilter, setRevenueFilter] = useState(null);
  const [open, setOpen] = useContext(FilterContext);
  const [filter, setFilter] = useState(null);
  const [endDate, setendDate] = useState(() => getMinDate(new Date()));
  useEffect(() => {
    setData(null);
  }, [revenueFilter]);

  const ApplyFilterStyle = {
    overrides: {
      MuiPaper: {
        root: {
          backgroundColor: '#3c3d4f',
          color: '#ffffff'
        }
      }
    }
  };

  return (
    <div>
      <ThemeProvider theme={ApplyFilterStyle}>
        <CustomAutoComplete
          holder="Select Filter"
          style={{ marginBottom: '15px' }}
          value={tobeFilters}
          Options="filter"
          data={revenueFilter}
          Change={(val) => {
            setRevenueFilter(val);
          }}
        />
        <div>
          {(() => {
            switch (revenueFilter?.id) {
              case 1:
                return (
                  <>
                    <InputLabel
                      htmlFor="age-required"
                      className={classes.cutom_select_Label}
                      style={{ margin: '0px 0 5px' }}
                    >
                      Payment Due
                    </InputLabel>
                    <CustomAutoComplete
                      holder="This Month"
                      Options="filter"
                      value={NextdayFilter}
                      data={filter}
                      Change={(val) => setFilter(val)}
                      style={{ marginBottom: '15px' }}
                    />
                    {filter?.id === 'custom' && (
                      <>
                        <InputLabel
                          htmlFor="age-required"
                          className={classes.cutom_select_Label}
                          style={{ margin: '5px 0' }}
                        >
                          From
                        </InputLabel>
                        <JBDatePicker
                          label="Start Date"
                          value={data}
                          onChange={(val) => {
                            setData(val);
                            setendDate(getMinDate(val));
                          }}
                        />
                        <InputLabel
                          htmlFor="age-required"
                          className={classes.cutom_select_Label}
                          style={{ padding: '20px 0px 5px' }}
                        >
                          To
                        </InputLabel>
                        <JBDatePicker
                          label="End Date"
                          minDate={
                            data ? getMinDate(data) : getMinDate(new Date())
                          }
                          value={endDate}
                          onChange={(val) => setendDate(val)}
                        />
                      </>
                    )}
                  </>
                );
              default:
                return null;
            }
          })()}
        </div>
      </ThemeProvider>
      <JBButton
        type="primary"
        onClick={() => {
          getFilData(
            getFilter(revenueFilter, filter, data, endDate),
            getfilterLabel(revenueFilter, filter, data, endDate)
          );
          setOpen(!open);
        }}
        disabled={checkData(revenueFilter, data, filter, endDate)}
        style={{
          width: '100%',
          // minHeight: '22px',
          padding: '7px 25px',
          marginTop: '10px'
        }}
      >
        Apply filter
      </JBButton>
    </div>
  );
};
AllInvoicesFilters.propTypes = {
  classes: PropType.object.isRequired,
  getFilData: PropType.func.isRequired
};

export default withStyles(styles)(AllInvoicesFilters);
