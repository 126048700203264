import React from 'react';
import PropType from 'prop-types';
import { ThemeProvider, Tooltip, withStyles } from '@material-ui/core';
// import { Link } from 'react-router-dom';
import { ReactComponent as InfoIcon } from '../../assets/image/info.svg';

const styles = () => ({
  TooltipLink: {
    '& svg path': {
      stroke: '#949494'
    },
    '&:hover': {
      '& svg path': {
        stroke: '#01847A'
      }
    }
  }
});

const tooltiptheme = {
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: '14px',
        fontWeight: '700',
        color: '#FFF',
        backgroundColor: '#01847A',
        borderRadius: '2px',
        padding: '10px 8px'
      },
      arrow: {
        color: '#01847A'
      }
    }
  }
};

const InfoTooltip = ({ classes }) => {
  return (
    <ThemeProvider theme={tooltiptheme}>
      <Tooltip
        title="To add an initial box, enable this in the terms and the member will have to add initial that section."
        arrow
        placement="top"
        style={{ verticalAlign: 'middle', display: 'flex' }}
      >
        <span className={classes.TooltipLink}>
          <InfoIcon />
        </span>
      </Tooltip>
    </ThemeProvider>
  );
};

InfoTooltip.propTypes = {
  classes: PropType.object.isRequired
};

export default withStyles(styles)(InfoTooltip);
