const styles = () => ({
  cutom_select_Label: {
    fontSize: '12px',
    '&:focus ': {
      outline: '0'
    }
  },
  FiltersWrapper: {
    background: '#fff'
  }
});
export default styles;
