import { filterProgram, getData, sortPrograms } from './program.Reducer.utils';
import types from './programactiontypes';
import filterType from './programReducer.constants';

const initialValue = {
  programs: null,
  isFetching: false,
  ListPrograms: null,
  errorMessage: undefined,
  programList: { isFetching: false, data: null, error: null },
  programListFilter: { page: 1, search: '', sorting: {}, status: 1 },
  programInActiveList: { isFetching: false, data: null, error: null },
  programListInActiveFilter: { page: 1, search: '', sorting: {}, status: 0 }
};

const programReducer = (state = initialValue, action) => {
  switch (action.type) {
    case types.fetchprogramstart:
      return {
        ...state,
        isFetching: true
      };
    case types.fecthprogramsuccess:
      return {
        ...state,
        isFetching: false,
        programs: sortPrograms(action.payload)
      };
    case types.fetchprogramfailure:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload
      };
    case types.getListPrograms:
      return {
        ...state,
        ListPrograms: action.payload
      };
    case types.activeFilter:
      return {
        ...state,
        programListFilter: filterProgram(
          state.programListFilter,
          action.filter,
          action.data
        )
      };
    case types.inactiveFilter:
      return {
        ...state,
        programListInActiveFilter: filterProgram(
          state.programListInActiveFilter,
          action.filter,
          action.data
        )
      };
    case types.getActiveProgram:
      return {
        ...state,
        programList: getData(
          filterType.activeProgram,
          action.actionType,
          state.allClass,
          action.payload
        )
      };
    case types.getInActiveProgram:
      return {
        ...state,
        programInActiveList: getData(
          filterType.inActiveProgram,
          action.actionType,
          state.allClass,
          action.payload
        )
      };
    default:
      return state;
  }
};
export default programReducer;
