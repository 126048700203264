import React, { useState, useEffect } from 'react';
import PropType from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import QueryString from 'query-string';
import clsx from 'clsx';
import {
  Grid,
  Card,
  Typography,
  Box,
  Breadcrumbs,
  withStyles,
  Button
} from '@material-ui/core';
import { ROUTES } from 'src/constant/Constants';
import moment from 'moment';
import { openNoti } from 'src/Redux/global/global.actions';
import { getErrorMsg } from 'src/utils/ErrorHandling';
import TextareaControl from 'src/views/common/FormControl/TextareaControl';
import useGymSetting from 'src/views/common/CustomHooks/useGymSettingRefresh';
import {
  getInvoice,
  updateInvoice
} from 'src/services/masterHQ.invoices.services';
import GetPrice from 'src/views/common/CurrencySymbol/GetPrice';
import LoadingView from 'src/views/common/loading';
import CancelDialog from 'src/views/common/CommonDialogBox/CancelDialog';
import useChangeTitle from 'src/views/common/CustomHooks/useTitleChange';
import JBButton from '../../../common/JBButton/JBButton';
import {
  getInvoiceStatus,
  getCreatedOn,
  getUpdatedOn,
  getInvoiceData
} from './InvoiceView.utils';
import { ReactComponent as SearchIcon } from '../../../../assets/image/searchIcon.svg';
import Actions from './Action';
import styles from './InvoiceView.style';
import InvoiceTable from './InvoiceTable/InvoiceTable';
// import { DummyValues } from './InvoiceTable/dummyData';
import TransactionDiag from './TransactionsInvoiceDialog';

const PaindInvoiceView = (props) => {
  const { classes, location, OpenNoti, InvoiceSettings } = props;
  const [TransInvoiceDialog, setTransInvoiceDialog] = useState(false);
  const { dateformat, timeformat } = useGymSetting();
  const { Invoice } = QueryString.parse(location.search);
  const [processing, setProcessing] = useState(false);
  const [loading, setLoading] = useState(true);
  const [, setErrorFetch] = useState(false);
  const [data, setData] = useState(null);
  useChangeTitle('Edit Invoice | Jungle Alliance');
  const handleSave = () => {
    setProcessing(true);
    const value = {
      auto_bill: data.auto_bill ? 1 : 0,
      due_date: moment(data.due_date, dateformat).format('YYYY-MM-DD'),
      notes: data.notes,
      thanks_msg: data.thanks_msg
    };
    updateInvoice(atob(Invoice), value)
      .then((res) => {
        setProcessing(false);
        setData(res);
        OpenNoti('Successfully Saved your Invoice', 'info');
      })
      .catch((err) => {
        setProcessing(false);
        OpenNoti(getErrorMsg(err), 'error');
      });
  };
  useEffect(() => {
    getInvoice(atob(Invoice))
      .then((res) => {
        setData(res);
        setLoading(false);
      })
      .catch(() => setErrorFetch(true));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (loading || !data) return <LoadingView />;

  return (
    <div className={classes.root}>
      <div className="title-bk">
        <Breadcrumbs aria-label="breadcrumb">
          <div color="inherit">financial</div>
          <Typography variant="h3" color="Primary">
            <span>invoices</span>
          </Typography>
        </Breadcrumbs>
      </div>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        spacing={0}
        style={{ margin: '20px 0 20px' }}
      >
        <Grid item xs={6}>
          <h1 style={{ margin: '0 0', color: '#fff' }}>
            Edit Invoice ”{String(data.invoice_no).padStart(7, '0')}”
          </h1>
        </Grid>
        <Grid
          item
          xs={6}
          className={classes.btnBlock}
          style={{ justifyContent: 'flex-end', display: 'flex' }}
        >
          <Actions
            data={data}
            updateData={(val) => setData(getInvoiceData(val))}
          />

          <Box ml="15px" />
          <CancelDialog />
        </Grid>
      </Grid>
      <div>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={0}
          style={{ margin: '15px 0 15px' }}
        >
          <Grid item xs={12}>
            <Box className={classes.invoicehead}>
              <Box className={classes.invoiceheadBox}>
                <span>Tax Invoice #</span>
                <label>{String(data.invoice_no).padStart(7, '0')}</label>
              </Box>
              <Box className={classes.invoiceheadBox}>
                <span>Invoice Status</span>
                <label
                  style={{
                    display: 'inline-block'
                  }}
                >
                  {getInvoiceStatus(data.status)}
                </label>
                <Button
                  onClick={() => setTransInvoiceDialog(true)}
                  className={classes.SearchTrans}
                >
                  <SearchIcon />
                </Button>
              </Box>
              <Box className={classes.invoiceheadBox}>
                <span>Ally</span>
                <label>
                  <Link
                    to={{
                      pathname: ROUTES.MASTER_HQ_AFFILIATES_PROFILE,
                      search: `affiliateId=${data.affiliate_id}`
                    }}
                    style={{ color: '#01847a', fontWeight: '600' }}
                  >
                    {data.gym_name}
                  </Link>
                </label>
              </Box>
              <Box className={classes.invoiceheadBox}>
                <span>Auto bill</span>
                {data.auto_bill ? <label>Yes</label> : <label>No</label>}
              </Box>

              <Box className={classes.invoiceheadBox}>
                <span>Payment due</span>
                <label>
                  {moment(data.due_date).isValid()
                    ? moment(data.due_date).format(dateformat)
                    : data.due_date}
                </label>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Card className={clsx(classes.mainCard, classes.InvoiceCard)}>
          <Grid container direction="row" alignItems="center" spacing={0}>
            <Grid
              item
              xs={12}
              style={{
                marginBottom: '20px'
              }}
            >
              <InvoiceTable
                values={data}
                statuscode={data.status}
                // deleteItem={(val) => setData(getInvoiceData(val[0]))}
              />
            </Grid>
          </Grid>

          <Grid
            container
            direction="row"
            // justify="flex-end"
            alignItems="center"
            className={classes.totalItem}
          >
            <Grid
              item
              xs={10}
              // onClick={() =>
              //   setDisDiag({
              //     data: {
              //       discount_type:
              //         data.discount_type === 'percentage' ? '2' : '1',
              //       discount: data.invoice_discount
              //     },
              //     show: true
              //   })
              // }
            >
              <Typography variant="h6">
                <span>Invoice Discount</span>
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="h6" align="center">
                <GetPrice value={data.final_discount} />
              </Typography>
            </Grid>

            <Grid
              container
              direction="row"
              alignItems="center"
              className={classes.totalDue}
            >
              <Grid item xs={10}>
                <Typography variant="h6">
                  <span>Total Due</span>
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="h6" align="center">
                  {data.status === 5 ? (
                    <GetPrice value={0} />
                  ) : (
                    <GetPrice value={data.final_subtotal} />
                  )}
                </Typography>
              </Grid>
            </Grid>

            <Grid item xs={10}>
              <Typography variant="h6">
                <span>GST Paid</span>
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="h6" align="center">
                <GetPrice value={data.final_gst} />
              </Typography>
            </Grid>
            {!data.refunded_amount ? null : (
              <>
                <Grid item xs={10}>
                  <Typography variant="h6">
                    <span>Refunded w/Tax</span>
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="h6" align="center">
                    <div className={classes.refunded_amount}>
                      <GetPrice value={data.refunded_amount} valuetype="neg" />
                    </div>
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>

          <Grid
            container
            direction="row"
            alignItems="center"
            style={{
              margin: '15px 0'
            }}
          >
            <Grid item xs={6}>
              <TextareaControl
                placeholder="Add note"
                value={data.notes}
                className={classes.TextareaControlNots}
                onChange={(e) => setData({ ...data, notes: e.target.value })}
              />
              <Box mb="20px" />
              <TextareaControl
                placeholder="Add ”Thank You” message"
                value={data.thanks_msg}
                className={classes.TextareaControlNots}
                onChange={(e) =>
                  setData({ ...data, thanks_msg: e.target.value })
                }
              />
            </Grid>
            <Grid item xs={6} justify="flex-end" className="MuiGrid-container">
              <Box
                css={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex',
                  width: '150px',
                  textAlign: 'right'
                }}
              >
                <p className={classes.ptext}>
                  {InvoiceSettings.data?.invoice_footer}
                </p>
              </Box>
            </Grid>
          </Grid>

          <Grid container direction="row" alignItems="center">
            <Grid item xs={6}>
              <Box mt="25px">
                <Box className={classes.invoicestatus}>
                  <span>Created:</span>
                  <label>{getCreatedOn(data, timeformat)}</label>
                </Box>
                <Box className={classes.invoicestatus}>
                  <span>Updated:</span>
                  <label>{getUpdatedOn(data, timeformat)}</label>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={6}
              justify="flex-end"
              alignItems="center"
              className="MuiGrid-container"
            >
              <JBButton
                type="primary"
                disabled={processing}
                onClick={handleSave}
              >
                Save Note
              </JBButton>
            </Grid>
          </Grid>
          {TransInvoiceDialog && (
            <TransactionDiag
              setOpen={TransInvoiceDialog}
              id={atob(Invoice)}
              handleClose={() => setTransInvoiceDialog(false)}
            />
          )}
        </Card>
      </div>
    </div>
  );
};
const mapToDispatch = (dispatch) => ({
  OpenNoti: (msg, type) => dispatch(openNoti(msg, type))
});
const mapToState = (state) => ({
  InvoiceSettings: state.masterHqReducer.financialReducer.Settings
});
PaindInvoiceView.propTypes = {
  classes: PropType.object.isRequired,
  OpenNoti: PropType.func.isRequired,
  InvoiceSettings: PropType.object.isRequired,
  location: PropType.any.isRequired
};

export default connect(
  mapToState,
  mapToDispatch
)(withStyles(styles)(PaindInvoiceView));
