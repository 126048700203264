import React from 'react';
import PropType from 'prop-types';
import { connect } from 'react-redux';
import {
  Grid,
  InputLabel,
  withStyles,
  FormControlLabel
} from '@material-ui/core';
import FormControl from 'src/views/common/FormControl/formControl';
import IOSSwitch from 'src/views/common/FormControl/IOSSwitch';
import CustomAutoComplete from '../../calendar/commonAutocomplete';
import styles from './PaymentPlan.style';
import {
  changeName,
  changeTax,
  changeLoc,
  initialComminit,
  initialCommitbill,
  changeAutorenew,
  changerenewrenewal,
  changerenewbill
} from './paymentReducer.actions';
import { EverWeekData } from './PaymentPlan.data';
import { validateInt } from '../../../utils/validations';

function PlanBasicDetails(props) {
  const {
    classes,
    allGym: { data: allGymLoc },
    allSerTaxes: { data: allTaxes },
    reducer: [state, dispatch]
  } = props;

  return (
    <div className={classes.PlanBasicDetails}>
      <h3>Name</h3>
      <p className={classes.ptext}>
        Describe this payment plan. This name will be displayed on members'
        contracts.
      </p>
      <Grid container spacing={0} direction="column">
        <Grid item xs={12}>
          <FormControl
            control="input"
            label="Plan Name"
            placeholder="e.g. 3 month Plan or Annual Commitment"
            required
            value={state.name}
            onChange={(e) => dispatch(changeName(e.target.value))}
          />
        </Grid>
        <Grid item xs={12} style={{ marginTop: '20px' }}>
          <h3>Locations of Sale</h3>
          <p className={classes.ptext}>
            Chose the location from which this payment plan may be sold. The
            location selected when selling a membership will drive it's revenue
            reporting.
          </p>
        </Grid>

        <Grid
          container
          spacing={0}
          xs={12}
          disableGutters
          direction="row"
          style={{ marginTop: '20px', marginbottom: '20px' }}
        >
          <Grid container spacing={0} xs={12} disableGutters direction="row">
            <Grid
              item
              xs={6}
              className={classes.time_box}
              style={{ marginTop: '0px', paddingRight: '15px' }}
            >
              <InputLabel
                htmlFor="age-required"
                className={classes.cutom_select_Label}
              >
                Location(s)
              </InputLabel>
              <CustomAutoComplete
                holder="Select Location"
                value={allGymLoc}
                data={state.location}
                Options="gym_name"
                style={{ marginbottom: '0' }}
                Change={(val) => dispatch(changeLoc(val))}
              />
            </Grid>
            <Grid
              item
              xs={6}
              className={classes.time_box}
              style={{ marginTop: '0px' }}
            >
              <InputLabel
                htmlFor="age-required"
                className={classes.cutom_select_Label}
              >
                &nbsp;
              </InputLabel>
              <CustomAutoComplete
                holder="Select Service Tax"
                value={allTaxes}
                data={state.tax}
                Options="name"
                style={{ marginbottom: '0' }}
                Change={(val) => dispatch(changeTax(val))}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} style={{ marginTop: '20px' }}>
          <h3>Initial Commitment</h3>
          <p className={classes.ptext}>
            Set the length of the initial commitment for this membership. For
            example, for a week-to-week, enter 1 week. For 6-week commitment,
            enter 6 weeks.
          </p>
        </Grid>

        <Grid container spacing={0} xs={12} disableGutters direction="row">
          <Grid
            item
            xs={6}
            className={classes.time_box}
            style={{ marginTop: '0px', paddingRight: '15px' }}
          >
            <FormControl
              control="input"
              label="Initial commitment"
              placeholder="Initial commitment"
              required
              value={state.initial_commitment.initial}
              onChange={(e) =>
                dispatch(initialComminit(validateInt(e.target.value)))
              } // eslint-disable-line
            />
          </Grid>
          <Grid
            item
            xs={1}
            className={classes.time_box}
            style={{
              marginTop: '0px',
              textAlign: 'center',
              padding: '21px 0px 0',
              fontWeight: 'bold'
            }}
          >
            <InputLabel
              htmlFor="age-required"
              className={classes.cutom_select_Label}
            >
              &nbsp;
            </InputLabel>
            <label style={{ color: '#000' }}>weeks</label>
          </Grid>
        </Grid>

        <Grid container spacing={0} xs={12} disableGutters direction="row">
          <Grid
            item
            xs={6}
            className={classes.time_box}
            style={{ marginTop: '0px', paddingRight: '15px' }}
          >
            <InputLabel
              htmlFor="age-required"
              required
              className={classes.cutom_select_Label}
            >
              Member is billed every
            </InputLabel>
            <CustomAutoComplete
              holder="Select Week"
              value={EverWeekData}
              data={state.initial_commitment.billed_every}
              Options="name"
              Change={(val) => dispatch(initialCommitbill(val))}
            />
          </Grid>
          <Grid
            item
            xs={1}
            className={classes.time_box}
            style={{
              marginTop: '0px',
              textAlign: 'center',
              padding: '15px 0px 0',
              fontWeight: 'bold'
            }}
          >
            <InputLabel
              htmlFor="age-required"
              className={classes.cutom_select_Label}
            >
              &nbsp;
            </InputLabel>
            <label style={{ color: '#000' }}>weeks</label>
          </Grid>
        </Grid>

        <Grid item xs={12} style={{ marginTop: '20px' }}>
          <h3>Auto-Renew</h3>
          <p className={classes.ptext}>
            Would you like this membership to automatically renew after the
            initial commitment ends?
          </p>
          <FormControlLabel
            control={
              <IOSSwitch
                checked={state.auto_renew}
                onChange={() => dispatch(changeAutorenew())}
              />
            }
            label="Enable Auto-Renew"
            style={{ margin: '10px auto 0' }}
          />
          {state.auto_renew && (
            <div>
              <Grid
                container
                spacing={0}
                xs={12}
                disableGutters
                direction="row"
              >
                <Grid
                  item
                  xs={6}
                  className={classes.time_box}
                  style={{ marginTop: '0px', paddingRight: '15px' }}
                >
                  <FormControl
                    control="input"
                    label="Renewal  commitment"
                    placeholder="Renewal  commitment"
                    value={state.auto_renew_meta.renewal}
                    required
                    onChange={(e) =>
                      dispatch(changerenewrenewal(validateInt(e.target.value)))
                    } // eslint-disable-line
                  />
                </Grid>
                <Grid
                  item
                  xs={1}
                  className={classes.time_box}
                  style={{
                    marginTop: '0px',
                    textAlign: 'center',
                    padding: '21px 0px 0',
                    fontWeight: 'bold'
                  }}
                >
                  <InputLabel
                    htmlFor="age-required"
                    className={classes.cutom_select_Label}
                  >
                    &nbsp;
                  </InputLabel>
                  <label style={{ color: '#000' }}>weeks</label>
                </Grid>
              </Grid>

              <Grid
                container
                spacing={0}
                xs={12}
                disableGutters
                direction="row"
              >
                <Grid
                  item
                  xs={6}
                  className={classes.time_box}
                  style={{ marginTop: '0px', paddingRight: '15px' }}
                >
                  <InputLabel
                    htmlFor="age-required"
                    required
                    className={classes.cutom_select_Label}
                  >
                    Member is billed every
                  </InputLabel>
                  <CustomAutoComplete
                    holder="Select the every week"
                    value={EverWeekData}
                    data={state.auto_renew_meta.billed_every}
                    Options="name"
                    Change={(val) => dispatch(changerenewbill(val))}
                  />
                </Grid>

                <Grid
                  item
                  xs={1}
                  className={classes.time_box}
                  style={{
                    marginTop: '0px',
                    textAlign: 'center',
                    padding: '15px 0px 0',
                    fontWeight: 'bold'
                  }}
                >
                  <InputLabel
                    htmlFor="age-required"
                    className={classes.cutom_select_Label}
                  >
                    &nbsp;
                  </InputLabel>
                  <label style={{ color: '#000' }}>weeks</label>
                </Grid>
              </Grid>
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

PlanBasicDetails.propTypes = {
  classes: PropType.object.isRequired,
  allGym: PropType.array.isRequired,
  allSerTaxes: PropType.array.isRequired,
  reducer: PropType.array.isRequired
};

const mapToState = (state) => ({
  allGym: state.SettingReducer.allGymloc,
  allSerTaxes: state.SettingReducer.taxRates
});

export default connect(mapToState)(withStyles(styles)(PlanBasicDetails));
