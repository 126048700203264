import React, { useState, useEffect } from 'react';
import PropType from 'prop-types';
import { connect } from 'react-redux';
import QueryString from 'query-string';
import clsx from 'clsx';
import {
  Grid,
  Card,
  Typography,
  Box,
  Breadcrumbs,
  InputLabel,
  withStyles,
  Button,
  FormControlLabel
} from '@material-ui/core';
import IOSSwitch from 'src/views/common/FormControl/IOSSwitch';
import TextareaControl from 'src/views/common/FormControl/TextareaControl';
import useGymSetting from 'src/views/common/CustomHooks/useGymSettingRefresh';
import LoadingView from 'src/views/common/loading';
import CancelDialog from 'src/views/common/CommonDialogBox/CancelDialog';
import JBDatePicker from 'src/views/common/FormControl/JBDatePicker';
import {
  getInvoice,
  updateInvoice
} from 'src/services/masterHQ.invoices.services';
import GetPrice from 'src/views/common/CurrencySymbol/GetPrice';
// import LoadingView from 'src/views/common/loading';
import { openNoti } from 'src/Redux/global/global.actions';
import { getErrorMsg } from 'src/utils/ErrorHandling';
import moment from 'moment';
import useChangeTitle from 'src/views/common/CustomHooks/useTitleChange';
import { ReactComponent as SearchIcon } from '../../../../assets/image/searchIcon.svg';
import {
  getInvoiceStatus,
  getCreatedOn,
  getUpdatedOn,
  getInvoiceData
} from './InvoiceView.utils';
import JBButton from '../../../common/JBButton/JBButton';
import CustomAutoComplete from '../../../common/CustomAutocomplete/commonAutocomplete';
import Actions from './Action';
import styles from './InvoiceView.style';
import InvoiceTable from './InvoiceTable/InvoiceTable';
import TransactionInvoiceDialog from './TransactionsInvoiceDialog';
// import { DummyValues } from './InvoiceTable/dummyData';

const InvoiceView = (props) => {
  const { classes, location, OpenNoti, InvoiceSettings } = props;
  const { Invoice } = QueryString.parse(location.search);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [, setErrorFetch] = useState(false);
  const [transInvoiceDialog, setTransInvoiceDialog] = useState(false);
  const { timeformat, dateformat } = useGymSetting();
  useChangeTitle('Edit Invoice | Jungle Alliance');
  const handleSave = () => {
    setProcessing(true);
    const value = {
      auto_bill: data.auto_bill ? 1 : 0,
      due_date: moment(data.due_date).format('YYYY-MM-DD'),
      notes: data.notes,
      thanks_msg: data.thanks_msg
    };
    updateInvoice(atob(Invoice), value)
      .then((res) => {
        setProcessing(false);
        setData({ ...res, due_date: moment(res.due_date, dateformat) });
        OpenNoti('Successfully Saved your Invoice', 'info');
      })
      .catch((err) => {
        setProcessing(false);
        OpenNoti(getErrorMsg(err), 'error');
      });
  };

  useEffect(() => {
    getInvoice(atob(Invoice))
      .then((res) => {
        setData({
          ...res,
          due_date: moment(res.due_date, dateformat)
        });
        setLoading(false);
      })
      .catch(() => setErrorFetch(true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <LoadingView />;
  return (
    <div className={classes.root}>
      <div className="title-bk">
        <Breadcrumbs aria-label="breadcrumb">
          <div color="inherit">financial</div>
          <Typography variant="h3" color="Primary">
            <span>invoices</span>
          </Typography>
        </Breadcrumbs>
      </div>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        spacing={0}
        style={{ margin: '20px 0 20px' }}
      >
        <Grid item xs={6}>
          <h1 style={{ margin: '0 0', color: '#fff' }}>
            Edit Invoice ”{String(data.invoice_no).padStart(7, '0')}”
          </h1>
        </Grid>
        <Grid
          item
          xs={6}
          className={classes.btnBlock}
          style={{ justifyContent: 'flex-end', display: 'flex' }}
        >
          <Actions
            data={data}
            updateData={(val) => setData(getInvoiceData(val))}
          />

          <Box ml="15px" />
          <CancelDialog />
        </Grid>
      </Grid>
      {/* {data ? ( */}
      <div>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={0}
          style={{ margin: '15px 0 15px' }}
        >
          <Grid item xs={12}>
            <Box className={classes.invoicehead}>
              <Box className={classes.invoiceheadBox}>
                <span>Tax Invoice #</span>
                <label>{String(data.invoice_no).padStart(7, '0')}</label>
              </Box>
              <Box className={classes.invoiceheadBox}>
                <span>Invoice Status:</span>
                <label style={{ display: 'inline-block' }}>
                  {getInvoiceStatus(data.status)}
                </label>
                {data.status === 3 && (
                  <Button
                    onClick={() => setTransInvoiceDialog(true)}
                    className={classes.SearchTrans}
                  >
                    <SearchIcon />
                  </Button>
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Card className={clsx(classes.mainCard, classes.InvoiceCard)}>
          <Grid
            container
            direction="row"
            alignItems="center"
            spacing={0}
            style={{ margin: '0px 0 20px' }}
          >
            <Box
              css={{
                width: '300px'
              }}
              mr="15px"
              className={classes.masterhqAutocomplete}
            >
              <InputLabel
                htmlFor="age-required"
                className={classes.cutom_select_Label}
                style={{ margin: '0px 0px 5px', padding: '0 0' }}
              >
                Ally
              </InputLabel>

              <CustomAutoComplete
                data={data}
                Options="gym_name"
                value={[]}
                disabled
              />
            </Box>
            <Box
              mr="15px"
              css={{
                width: '300px'
              }}
              className={classes.cutom_Payment_date}
            >
              <InputLabel
                htmlFor="age-required"
                className={classes.cutom_select_Label}
                style={{ margin: '0px 0px 5px', padding: '0 0' }}
              >
                Payment due
              </InputLabel>

              <JBDatePicker
                holder="Select a Date"
                value={data.due_date}
                onChange={(val) => setData({ ...data, due_date: val })}
                style={{
                  margin: '0 0 !important'
                }}
                className={classes.cutom_date_css}
                disabled={data.status !== 1}
              />
            </Box>
            <Box
              ml="5px"
              mt="15px"
              css={{
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex'
              }}
              className={classes.AutoBill}
            >
              <FormControlLabel
                checked={data.auto_bill}
                onChange={(event) =>
                  setData({ ...data, auto_bill: event.target.checked ? 1 : 0 })
                }
                control={<IOSSwitch />}
                label="Auto bill"
                style={{ margin: '0 0' }}
                disabled={data.status === 3}
              />
            </Box>
          </Grid>
          <Grid container direction="row" alignItems="center" spacing={0}>
            <Grid
              item
              xs={12}
              style={{
                marginBottom: '20px'
              }}
            >
              <InvoiceTable
                values={data}
                // deleteItem={(val) => setData(getInvoiceData(val[0]))}
              />
            </Grid>
          </Grid>

          <Grid
            container
            direction="row"
            // justify="flex-end"
            alignItems="center"
            className={classes.totalItem}
          >
            <Grid
              item
              xs={10}
              // onClick={() =>
              //   setDisDiag({
              //     data: {
              //       discount_type:
              //         data.discount_type === 'percentage' ? '2' : '1',
              //       discount: data.invoice_discount
              //     },
              //     show: true
              //   })
              // }
            >
              <Typography variant="h6">
                <span>Invoice Discount</span>
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="h6" align="center">
                <GetPrice value={data.final_discount} />
              </Typography>
            </Grid>

            <Grid
              container
              direction="row"
              alignItems="center"
              className={classes.totalDue}
            >
              <Grid item xs={10}>
                <Typography variant="h6">
                  <span>Total Due</span>
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="h6" align="center">
                  <GetPrice value={data.final_subtotal} />
                </Typography>
              </Grid>
            </Grid>

            <Grid item xs={10}>
              <Typography variant="h6">
                <span>GST Paid</span>
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="h6" align="center">
                <GetPrice value={data.final_gst} />
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            direction="row"
            alignItems="center"
            style={{
              margin: '15px 0'
            }}
          >
            <Grid item xs={6}>
              <TextareaControl
                placeholder="Add note"
                value={data.notes}
                className={classes.TextareaControlNots}
                onChange={(e) => setData({ ...data, notes: e.target.value })}
              />
              <Box mb="20px" />
              <TextareaControl
                placeholder="Add ”Thank You” message"
                value={data.thanks_msg}
                className={classes.TextareaControlNots}
                onChange={(e) =>
                  setData({ ...data, thanks_msg: e.target.value })
                }
              />
            </Grid>
            <Grid item xs={6} justify="flex-end" className="MuiGrid-container">
              <Box
                css={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex',
                  width: '150px',
                  textAlign: 'right'
                }}
              >
                <p className={classes.ptext}>
                  {/* Account details for EFT: Jungle Brothers Pty Ltd BSB 062 165
                  ACC 1017 2745 */}
                  {InvoiceSettings.data?.invoice_footer}
                </p>
              </Box>
            </Grid>
          </Grid>

          <Grid container direction="row" alignItems="center">
            <Grid item xs={6}>
              <Box mt="25px">
                <Box className={classes.invoicestatus}>
                  <span>Created:</span>
                  <label>{getCreatedOn(data, timeformat)}</label>
                </Box>
                <Box className={classes.invoicestatus}>
                  <span>Updated:</span>
                  <label>{getUpdatedOn(data, timeformat)}</label>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={6}
              justify="flex-end"
              alignItems="center"
              className="MuiGrid-container"
            >
              <JBButton
                type="primary"
                disabled={processing}
                onClick={handleSave}
              >
                Save
              </JBButton>
            </Grid>
          </Grid>
          {transInvoiceDialog && (
            <TransactionInvoiceDialog
              setOpen={transInvoiceDialog}
              handleClose={() => setTransInvoiceDialog(false)}
              id={atob(Invoice)}
            />
          )}
        </Card>
      </div>
    </div>
  );
};
const mapToState = (state) => ({
  InvoiceSettings: state.masterHqReducer.financialReducer.Settings
});
const mapToDispatch = (dispatch) => ({
  OpenNoti: (msg, type) => dispatch(openNoti(msg, type))
});
InvoiceView.propTypes = {
  classes: PropType.object.isRequired,
  InvoiceSettings: PropType.object.isRequired,
  location: PropType.any.isRequired,
  OpenNoti: PropType.func.isRequired
};

export default connect(
  mapToState,
  mapToDispatch
)(withStyles(styles)(InvoiceView));
