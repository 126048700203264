import { getReducerData } from 'src/Redux/reducer.common.utils';
import filterList from 'src/Redux/Reducers.utils';
import initialState from './allies.reducer.initialstate';
import {
  ACTIVE_ALLIES,
  INACTIVE_ALLIES,
  ALL_ALLIES,
  ACTIVE_ALLIES_FIL,
  INACTIVE_ALLIES_FIL,
  ALL_ALLIES_FIL,
  ALLIES_ACTIONTYPE
} from './allies.reducer.types';

export default function alliesReducer(state = initialState, action) {
  const { type, actionType, payload, filter } = action;
  switch (type) {
    case ACTIVE_ALLIES:
      return {
        ...state,
        activeAllies: getReducerData(
          actionType,
          state.activeAllies,
          payload,
          ALLIES_ACTIONTYPE
        )
      };

    case INACTIVE_ALLIES:
      return {
        ...state,
        inActiveAllies: getReducerData(
          actionType,
          state.activeAllies,
          payload,
          ALLIES_ACTIONTYPE
        )
      };

    case ALL_ALLIES:
      return {
        ...state,
        allAllies: getReducerData(
          actionType,
          state.allAllies,
          payload,
          ALLIES_ACTIONTYPE
        )
      };

    case ACTIVE_ALLIES_FIL:
      return {
        ...state,
        activeAlliesFilters: filterList(
          state.activeAlliesFilters,
          filter,
          payload
        )
      };

    case INACTIVE_ALLIES_FIL:
      return {
        ...state,
        inActiveAlliesFilters: filterList(
          state.inActiveAlliesFilters,
          filter,
          payload
        )
      };

    case ALL_ALLIES_FIL:
      return {
        ...state,
        allAlliesFilters: filterList(state.allAlliesFilters, filter, payload)
      };
    default:
      return state;
  }
}
