import {
  validStringRegEx,
  validEmailRegEx,
  noSpecialCharsRegEx,
  // UrlRegex,
  isPhoneNumberInvalid,
  postalRegEx
} from 'src/utils/validations';

// eslint-disable-next-line import/prefer-default-export
export const validateGymLocationData = (data) => {
  if (data?.gym_name != null && data?.gym_name.length <= 0) {
    throw Error('Gym name is Required');
  } else if (!validStringRegEx.test(data?.gym_name)) {
    throw Error('Please specify valid Gym name');
  } else if (data?.email != null && data?.email.length <= 0) {
    throw Error('Email is Required');
  } else if (!validEmailRegEx.test(data?.email)) {
    throw Error('Please provide a valid Email Address');
  } else if (data?.address != null && data?.address.length <= 0) {
    throw Error('Address is Required');
  } else if (!noSpecialCharsRegEx.test(data?.address)) {
    throw Error('Please specify valid Address');
  } else if (data?.city != null && data?.city.length <= 0) {
    throw Error('City is Required');
  } else if (isPhoneNumberInvalid(data?.phone)) {
    throw Error('Phone number must be between 10 and 15 digits');
  } else if (!validStringRegEx.test(data?.city)) {
    throw Error('Please specify valid city');
  } else if (data?.state != null && data?.state.length <= 0) {
    throw Error('Province is Required');
  } else if (!validStringRegEx.test(data?.state)) {
    throw Error('Please specify valid province');
  } else if (!data?.country) {
    throw Error('Country is Required');
  } else if (data?.zipcode.length <= 0) {
    throw Error('Postal code is Required');
  } else if (!postalRegEx.test(data?.zipcode)) {
    throw Error('Please enter a valid postal code');
  } else if (!data?.timezone) {
    throw Error('Timezone is Required');
  }
};
