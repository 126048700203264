/* eslint-disable no-dupe-keys */
const styles = () => ({
  dmainbk: {
    borderRadius: '4px',
    boxShadow: '1px 0px 51px rgba(37, 40, 43, 0.08)',
    background: '#FFFFFF',
    '& .fd-input input': {
      color: '#292929',
      fontWeight: 'bold',
      fontSize: '14px'
    },
    '@media (max-width: 1440px)': {
      '& .MuiDialogContent-root': {
        overflowY: 'auto',
        height: '100%',
        maxHeight: '411px'
      }
    }
  },
  dtitle: {
    color: '#01847A',
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '26px'
  },
  titlebk: {
    padding: '16px 15px'
  },
  dh3: {
    color: '#000',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px'
  },
  dlabel: {
    color: '#7F7F7F',
    fontWeight: 'bold',
    fontSize: '12px',
    lineHeight: '16px'
  },
  btns: {
    padding: '0 0',
    borderRadius: '50%',
    minWidth: 'auto',
    margin: '0 5px'
  },
  dLink: {
    display: 'inline-block',
    float: 'right',
    fontSize: '12px',
    margin: '0 0',
    padding: '6px 0 0',
    fontWeight: 700
  },
  dprogress: {
    borderRadius: '4px',
    marginTop: '10px'
  },
  warningInfo: {
    textAlign: 'center',
    '& p': {
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '22px',
      '& span': {
        color: 'red',
        display: 'block',
        textAlign: 'center'
      }
    }
  },
  d_button: {
    '& Button': {
      padding: '11px 0',
      fontWeight: 700
    }
  },
  d_button_delete: {
    color: '#FB4E4E',
    borderColor: 'inherit',
    fontWeight: 700
  },
  weekButtons: {
    '& Button': {
      marginRight: '6px',
      marginBottom: '6px',
      fontWeight: 700
    }
  },
  ptag: {
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.2px',
    color: '#292929',
    borderTop: '1px solid rgb(196 196 196 / 0.3)',
    marginTop: '15px',
    padding: '10px 0'
  },
  reservationsbk: {
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'center'
  },
  hr: {
    border: '1px solid rgb(196 196 196 / 0.4)',
    display: 'inline-block',
    width: '100%',
    margin: '9px 0'
  },
  media: {
    display: '-ms-flexbox',
    display: 'flex',
    msFlexSlign: 'start',
    alignItems: 'flex-start'
  },
  mediabody: {
    flex: '1',
    fontSize: '14px',
    marginLeft: '15px'
  },
  uName: {
    fontWeight: '700',
    color: '#292929',
    fontSize: '14px',
    lineHeight: '22px'
  },
  ustatus: {
    fontWeight: '700',
    color: 'rgb(41 41 41 / 0.6)',
    fontSize: '14px',
    lineHeight: '22px'
  },
  duserlist: {
    display: 'block',
    clear: 'both',
    overflowY: 'auto',
    height: '300px',
    marginTop: '15px'
  },
  Avataruname: {
    fontWeight: 'bold',
    backgroundColor: '#CCE6E4',
    color: '#37847a',
    textTransform: 'capitalize'
  }
});

export default styles;
