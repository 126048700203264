import React from 'react';
import PropType from 'prop-types';
import { Box, withStyles } from '@material-ui/core';

const styles = () => ({
  Backdrop: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    zIndex: '9999',
    top: '0',
    left: '0'
  }
});

const Backdrop = ({ show, clicked, classes }) => {
  return show ? (
    <Box onClick={clicked} Backdrop className={classes.Backdrop} />
  ) : null;
};

Backdrop.propTypes = {
  show: PropType.any.isRequired,
  clicked: PropType.any.isRequired,
  classes: PropType.object.isRequired
};
export default withStyles(styles)(Backdrop);
