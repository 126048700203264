export default {
  setIsLoading: 'SET_IS_LOADING',
  ResetStep: 'RESET_STEP',
  NextStep: 'NEXT_STEP',
  StepBack: 'STEP_BACK',
  ResetData: 'RESET_DATA',
  SetActiveStep: 'SET_ACTIVE_STEP',
  SetMember: 'SET_MEMBER',
  setIsSelectedMember: 'SET_IS_SELECTED_MEMBER',
  clearIsSelectedMember: 'CLEAR_IS_SELECTED_MEMBER',
  SetSelectedWaiver: 'SET_SELECTED_WAIVER',
  toggleSignStatus: 'TOGGLE_SIGN_STATUS',
  setSignStatus: 'SET_SIGN_STATUS',
  setSignInitials: 'SET_SIGN_INITIALS',
  setQuestionnaire: 'SET_QUESTIONNAIRE',
  setSignature: 'SET_SIGNATURE',
  setIsSigned: 'SET_IS_SIGNED',
  setWaiverInitialBox: 'SET_WAIVER_INITIAL_BOX'
};
