import React from 'react';
import PropType from 'prop-types';
import { Box, withStyles } from '@material-ui/core';
import Backdrop from './Backdrop';

const styles = () => ({});

const ModalColor = (props) => {
  const { show, modalClose, children } = props;
  return (
    <React.Fragment>
      <Backdrop show={show} clicked={modalClose} />
      <Box
        style={{
          position: 'absolute',
          zIndex: '9999',
          minWidth: 'auto',
          borderRadius: '5px',
          // left: '50%',
          // top: `${show ? '50%' : '-50%'}`,
          opacity: `${show ? '1' : '0'}`,
          backgroundColor: 'white',
          transform: `${show ? 'translateY(0)' : 'translateY(-10px)'}`
          // transition: 'all 1s linear'
        }}
      >
        {children}
      </Box>
    </React.Fragment>
  );
};

ModalColor.propTypes = {
  show: PropType.any.isRequired,
  modalClose: PropType.any.isRequired,
  children: PropType.node.isRequired
};

export default withStyles(styles)(ModalColor);
