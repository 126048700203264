import 'date-fns';
import React, { memo } from 'react';
import PropType from 'prop-types';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  DatePicker,
  KeyboardDatePicker
} from '@material-ui/pickers';
import { withStyles } from '@material-ui/core/styles';
// import { IconButton, InputAdornment } from '@material-ui/core';
import DateRangeIcon from '@material-ui/icons/DateRange';
import useGymSettingReducer from '../CustomHooks/useGymSettingRefresh';
// import { ReactComponent as DownIcon } from '../../../assets/image/Vanguard/downIcon.svg';

const styles = () => ({
  input: {
    color: '#7F7F7F',
    border: '1px solid #a9a9a9',
    height: '48px',
    padding: '0px 9px 0px',
    marginTop: '23px !important',
    '& svg': {
      color: '#2ce9da'
    },
    '& ::placeholder': {
      color: '#7F7F7F',
      fontSize: '20px'
    }
  }
});

function MaterialUIPickers({ label, classes, value, vanguard, ...props }) {
  const { pickerDate } = useGymSettingReducer();
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container justify="space-around">
        {vanguard ? (
          <KeyboardDatePicker
            placeholder="DD/MM/YYYY"
            variant="inline"
            views={['year', 'month', 'date']}
            autoOk
            id="date-picker-dialog"
            label={label}
            fullWidth
            format={pickerDate}
            value={value}
            // showTodayButton
            // KeyboardButtonProps={{
            //   'aria-label': 'change date'
            // }}
            keyboardIcon={<DateRangeIcon />}
            InputProps={{
              disableUnderline: true,
              className: classes.input
            }}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            inputProps={{
              inputMode: 'numeric'
            }}
            InputLabelProps={{
              shrink: true
            }}
            {...props} // eslint-disable-line
          />
        ) : (
          <DatePicker
            variant="inline"
            views={['year', 'month', 'date']}
            autoOk
            id="date-picker-dialog"
            label={label}
            fullWidth
            format={pickerDate}
            value={value}
            // showTodayButton
            // KeyboardButtonProps={{
            //   'aria-label': 'change date'
            // }}
            InputProps={{
              disableUnderline: true,

              className: classes.input
            }}
            InputLabelProps={{
              shrink: true
            }}
            {...props} // eslint-disable-line
          />
        )}
      </Grid>
    </MuiPickersUtilsProvider>
  );
}

MaterialUIPickers.defaultProps = {
  value: null,
  vanguard: false
};

MaterialUIPickers.propTypes = {
  classes: PropType.object.isRequired,
  label: PropType.string.isRequired,
  value: PropType.any,
  vanguard: PropType.bool
  // value: PropType.any.isRequired
  // value: PropType.string.isRequired
};
export default withStyles(styles)(memo(MaterialUIPickers));
