/* eslint-disable operator-linebreak */
import moment from 'moment';
import {
  VanguardMembershipType,
  Expirationtypes,
  AttendanceTypes,
  AllDayType,
  billedWeek,
  Genders
} from './VanguardMemership.data';
import types from './VanguardMembership.constants';

const getTouched = (state, name) => {
  const res = state;
  res[name] = true;
  return res;
};
// const getError = (state, name) => {
//   const res = state;
//   res[name] = `please enter a valid ${name}`;
//   return res;
// };
const setUser = (state, name, val) => {
  const res = state;
  res[name] = val;
  return res;
};
const setBank = (state, name, val) => {
  const res = state;
  res[name] = val;
  return res;
};
const getUserData = (data) => {
  const result = {
    first_name: data.first_name,
    last_name: data.last_name,
    email: data.email,
    gender: Genders.filter((i) => i.label.toLowerCase() === data.gender)[0],
    phone_num: data.mobile_no,
    date: data.dob,
    address: data.address,
    city: data.city,
    province: data.province,
    postal: data.postal_code,
    country: data.country,
    eme_contact: data.eme_contact_name,
    eme_phone: data.eme_contact_phone,
    lead_source: data.lead_source,
    profile_pic: data.profile_pic
  };
  return result;
};
const handlePrograms = (id, data) => {
  const selectedIndex = data.indexOf(id);
  let newSelected = [];
  if (selectedIndex === -1) {
    newSelected = newSelected.concat(data, id);
  } else if (selectedIndex === 0) {
    newSelected = newSelected.concat(data.slice(1));
  } else if (selectedIndex === data.length - 1) {
    newSelected = newSelected.concat(data.slice(0, -1));
  } else if (selectedIndex > 0) {
    newSelected = newSelected.concat(
      data.slice(0, selectedIndex),
      data.slice(selectedIndex + 1)
    );
  }
  return newSelected;
};
const selectAllPrograms = (values, checked) => {
  let newSelecteds = [];
  if (checked) {
    newSelecteds = values.map((n) => n.id);
    return newSelecteds;
  }
  return newSelecteds;
};
const paymentdata = {
  id: 78,
  membership_id: 37,
  name: '',
  location_id: null,
  tax_id: 1,
  is_default: 0,
  card_name: '',
  bank_details: {
    account_name: '',
    bsb_number: '',
    account_number: ''
  },
  initial_commitment: {
    initial: 10,
    billed_every: { id: 0, name: '2' }
  },
  paymentOpt: 0,
  renewOpt: 0,
  auto_renew: 0,
  auto_renew_meta: {
    billed_every: null,
    renewal: null
  },
  initial_commitment_pricing: {
    full_setup_fees: 0,
    full_membership_fees: 5,
    full_total: 5,
    full_tax_amount: 0
  },
  auto_renewal_pricing: {},
  status: 1,
  programes: [],
  membership: {
    id: 37,
    contract_id: null,
    reve_cat_id: 1,
    name: '',
    description: '',
    membership_type: '',
    attendance_limited_meta: { uses: '', type: '' },
    attendance_limit: {},
    no_of_sessions: 0,
    status: 1,
    program_ids: ''
  }
};
const contract = {
  id: null,
  sign_initials: null,
  policy: '',
  policy_initials: null,
  sign_status: 0,
  signature: null
};
const discounts = {
  initial: {
    amount: 0,
    type: 'percentage'
  },
  setup: {
    amount: 0,
    type: 'percentage'
  },
  renewal: {
    amount: 0,
    type: 'percentage'
  }
};

export const initialValue = {
  signInDialog: false,
  isSignIn: false,
  active_Step: 0,
  personal_info: {
    touched: {
      first_name: false,
      last_name: false,
      email: false,
      address: false,
      city: false,
      province: false,
      postal: false,
      phone_num: false,
      eme_contact: false,
      eme_phone: false
    },
    user_details: {
      first_name: '',
      last_name: '',
      email: '',
      gender: null,
      phone_num: '',
      date: null,
      address: '',
      address2: '',
      city: '',
      province: '',
      postal: '',
      country: null,
      eme_contact: '',
      eme_phone: ''
    },
    error: {
      first_name: '',
      last_name: '',
      email: '',
      gender: '',
      phone_num: '',
      date: '',
      address: '',
      city: '',
      province: '',
      postal: '',
      country: '',
      eme_contact: '',
      eme_phone: ''
    },
    error_msg: ''
  },
  membership_type: VanguardMembershipType.plan,
  membership_id: null,
  user_id: null,
  membership_payment_id: null,
  paymentMeTab: 0,
  error: null,
  payments: {
    isFetching: false,
    all_payments: [],
    error: null
  },
  memebership_data: {
    isFetching: false,
    membershipdata: {
      start_date: null,
      initial_pricing: null,
      renewal_pricing: null,
      usedsession: 0,
      discount: discounts,
      expires_date: null,
      program_access: [],
      payment_data: paymentdata,
      membership_contract: null,
      user_contract: contract
    },
    error: null
  },
  selectedWaiver: null,
  questionnaire_meta: [],
  signStatus: false,
  isSigned: false,
  waiver_terms_initial: {
    sign_initials: '',
    initial_box: null
  },
  dropIn_data: {
    signature: ''
  }
};

export function VanguardMembershipReducer(state = initialValue, action) {
  const { type, payload } = action;
  switch (type) {
    case 'temp':
      return state;
    case types.ResetStep:
      return {
        ...state,
        active_Step: 0
      };
    case types.NextStep:
      return {
        ...state,
        active_Step: state.active_Step + 1
      };
    case types.StepBack:
      return {
        ...state,
        active_Step: state.active_Step - 1
      };
    case types.skipStep:
      return {
        ...state,
        active_Step: state.active_Step + 2
      };
    case types.setErrorMsg:
      return {
        ...state,
        personal_info: {
          ...state.personal_info,
          error_msg: payload
        }
      };
    case types.SetMembershiptype:
      return {
        ...state,
        membership_type: payload
      };
    case types.setUser:
      return {
        ...state,
        user_id: payload
      };
    case types.setPaymentMeTab:
      return {
        ...state,
        paymentMeTab: payload
      };
    case types.resetUser:
      return {
        ...state,
        user_id: null
      };
    case types.resetData:
      return {
        ...state,

        personal_info: {
          ...state.personal_info,
          error: {
            ...initialValue.personal_info.error
          },
          touched: {
            ...initialValue.personal_info.touched
          },
          user_details: {
            ...initialValue.personal_info.user_details
          }
        },
        memebership_data: {
          ...state.memebership_data,
          membershipdata: {
            ...state.memebership_data.membershipdata,
            payment_data: {
              ...initialValue.memebership_data.membershipdata.payment_data,
              bank_details: {
                ...initialValue.memebership_data.membershipdata.payment_data
                  .bank_details
              }
            }
          }
        }
      };
    case types.setPolicyInitials:
      return {
        ...state,
        memebership_data: {
          ...state.memebership_data,
          membershipdata: {
            ...state.memebership_data.membershipdata,
            user_contract: {
              ...state.memebership_data.membershipdata.user_contract,
              policy_initials: payload
            }
          }
        }
      };
    case types.setUserInfo:
      return {
        ...state,
        personal_info: {
          ...state.personal_info,
          user_details: setUser(
            state.personal_info.user_details,
            payload.name,
            payload.info
          )
        }
      };
    case types.setBankData:
      return {
        ...state,
        memebership_data: {
          ...state.memebership_data,
          membershipdata: {
            ...state.memebership_data.membershipdata,
            payment_data: {
              ...state.memebership_data.membershipdata.payment_data,
              bank_details: setBank(
                state.memebership_data.membershipdata.payment_data.bank_details,
                payload.name,
                payload.info
              )
            }
          }
        }
      };
    case types.setSignature:
      return {
        ...state,
        memebership_data: {
          ...state.memebership_data,
          membershipdata: {
            ...state.memebership_data.membershipdata,
            user_contract: {
              ...state.memebership_data.membershipdata.user_contract,
              signature: payload
            }
          }
        }
      };
    case types.clearSignature:
      return {
        ...state,
        memebership_data: {
          ...state.memebership_data,
          membershipdata: {
            ...state.memebership_data.membershipdata,
            user_contract: {
              ...state.memebership_data.membershipdata.user_contract,
              signature: null
            }
          }
        }
      };
    case types.setSelectedUser:
      return {
        ...state,
        user_id: payload.id,
        personal_info: {
          ...state.personal_info,
          user_details: {
            ...state.personal_info.user_details,
            ...getUserData(payload)
          }
        }
      };
    case types.resetSearch:
      return {
        ...state,
        user_id: null
      };
    case types.setDefaultPaymentMethode:
      return {
        ...state,
        memebership_data: {
          ...state.memebership_data,
          membershipdata: {
            ...state.memebership_data.membershipdata,
            payment_data: {
              ...state.memebership_data.membershipdata.payment_data,
              is_default: payload
            }
          }
        }
      };
    case types.setCardName:
      return {
        ...state,
        memebership_data: {
          ...state.memebership_data,
          membershipdata: {
            ...state.memebership_data.membershipdata,
            payment_data: {
              ...state.memebership_data.membershipdata.payment_data,
              card_name: payload
            }
          }
        }
      };
    case types.setMembershipFee:
      return {
        ...state,
        memebership_data: {
          ...state.memebership_data,
          membershipdata: {
            ...state.memebership_data.membershipdata,
            payment_data: {
              ...state.memebership_data.membershipdata.payment_data,
              initial_commitment_pricing: {
                ...state.memebership_data.membershipdata.payment_data
                  .initial_commitment_pricing,
                full_membership_fees: payload
              }
            }
          }
        }
      };

    case types.setSetupFee:
      return {
        ...state,
        memebership_data: {
          ...state.memebership_data,
          membershipdata: {
            ...state.memebership_data.membershipdata,
            payment_data: {
              ...state.memebership_data.membershipdata.payment_data,
              initial_commitment_pricing: {
                ...state.memebership_data.membershipdata.payment_data
                  .initial_commitment_pricing,
                full_setup_fees: payload
              }
            }
          }
        }
      };
    case types.setRenewalFee:
      return {
        ...state,
        memebership_data: {
          ...state.memebership_data,
          membershipdata: {
            ...state.memebership_data.membershipdata,
            payment_data: {
              ...state.memebership_data.membershipdata.payment_data,
              auto_renewal_pricing: {
                ...state.memebership_data.membershipdata.payment_data
                  .auto_renewal_pricing,
                full_membership_fees: payload
              }
            }
          }
        }
      };
    case types.setRenewalWeek:
      return {
        ...state,
        memebership_data: {
          ...state.memebership_data,
          membershipdata: {
            ...state.memebership_data.membershipdata,
            payment_data: {
              ...state.memebership_data.membershipdata.payment_data,
              auto_renewal_pricing: {
                ...state.memebership_data.membershipdata.payment_data
                  .auto_renewal_pricing,
                week_membership_fees: payload
              }
            }
          }
        }
      };
    case types.setWeekFee:
      return {
        ...state,
        memebership_data: {
          ...state.memebership_data,
          membershipdata: {
            ...state.memebership_data.membershipdata,
            payment_data: {
              ...state.memebership_data.membershipdata.payment_data,
              initial_commitment_pricing: {
                ...state.memebership_data.membershipdata.payment_data
                  .initial_commitment_pricing,
                week_membership_fees: payload
              }
            }
          }
        }
      };
    case types.setWeekSetup:
      return {
        ...state,
        memebership_data: {
          ...state.memebership_data,
          membershipdata: {
            ...state.memebership_data.membershipdata,
            payment_data: {
              ...state.memebership_data.membershipdata.payment_data,
              initial_commitment_pricing: {
                ...state.memebership_data.membershipdata.payment_data
                  .initial_commitment_pricing,
                week_setup_fees: payload
              }
            }
          }
        }
      };
    case types.setDiscountType:
      return {
        ...state,
        memebership_data: {
          ...state.memebership_data,
          membershipdata: {
            ...state.memebership_data.membershipdata,
            discount: {
              ...state.memebership_data.membershipdata.discount,
              [payload.name]: {
                ...state.memebership_data.membershipdata.discount[payload.name],
                type: payload.value
              }
            }
          }
        }
      };
    case types.setDiscountAmount:
      return {
        ...state,
        memebership_data: {
          ...state.memebership_data,
          membershipdata: {
            ...state.memebership_data.membershipdata,
            discount: {
              ...state.memebership_data.membershipdata.discount,
              [payload.name]: {
                ...state.memebership_data.membershipdata.discount[payload.name],
                amount: payload.value
              }
            }
          }
        }
      };
    case types.setSessionDate:
      return {
        ...state,
        memebership_data: {
          ...state.memebership_data,
          membershipdata: {
            ...state.memebership_data.membershipdata,
            start_date: payload,
            expires_date:
              state.memebership_data.membershipdata.payment_data.membership
                .attendance_limit.id &&
              moment(payload)
                .add(
                  state.memebership_data.membershipdata.payment_data.membership
                    .attendance_limited_meta.uses,
                  // eslint-disable-next-line max-len
                  state.memebership_data.membershipdata.payment_data.membership.attendance_limited_meta.type.name.toLowerCase()
                )
                .toDate()
          }
        }
      };
    case types.setUserContract:
      return {
        ...state,
        memebership_data: {
          ...state.memebership_data,
          membershipdata: {
            ...state.memebership_data.membershipdata,
            user_contract: {
              ...state.memebership_data.membershipdata.user_contract,
              id: payload
            }
          }
        }
      };
    case types.setContract:
      return {
        ...state,
        memebership_data: {
          ...state.memebership_data,
          membershipdata: {
            ...state.memebership_data.membershipdata,
            user_contract: {
              ...state.memebership_data.membershipdata.user_contract,
              // eslint-disable-next-line react/destructuring-assignment
              ...action.payload
            }
          }
        }
      };
    case types.setSignInitials:
      return {
        ...state,
        memebership_data: {
          ...state.memebership_data,
          membershipdata: {
            ...state.memebership_data.membershipdata,
            user_contract: {
              ...state.memebership_data.membershipdata.user_contract,
              sign_initials: payload
            }
          }
        }
      };
    case types.addProgram:
      return {
        ...state,
        memebership_data: {
          ...state.memebership_data,
          membershipdata: {
            ...state.memebership_data.membershipdata,
            program_access: handlePrograms(
              payload,
              state.memebership_data.membershipdata.program_access
            )
          }
        }
      };
    case types.selectAllPrograms:
      return {
        ...state,
        memebership_data: {
          ...state.memebership_data,
          membershipdata: {
            ...state.memebership_data.membershipdata,
            program_access: selectAllPrograms(payload.programs, payload.checked)
          }
        }
      };
    case types.setPolicy:
      return {
        ...state,
        memebership_data: {
          ...state.memebership_data,
          membershipdata: {
            ...state.memebership_data.membershipdata,
            user_contract: {
              ...state.memebership_data.membershipdata.user_contract,
              policy: payload
            }
          }
        }
      };
    case types.setUserTouched:
      return {
        ...state,
        personal_info: {
          ...state.personal_info,
          touched: getTouched(state.personal_info.touched, payload)
        }
      };
    case types.changeSignStatus:
      return {
        ...state,
        memebership_data: {
          ...state.memebership_data,
          membershipdata: {
            ...state.memebership_data.membershipdata,
            user_contract: {
              ...state.memebership_data.membershipdata.user_contract,
              sign_status: payload
            }
          }
        }
      };
    case types.setNoOfSessions:
      return {
        ...state,
        memebership_data: {
          ...state.memebership_data,
          membershipdata: {
            ...state.memebership_data.membershipdata,
            payment_data: {
              ...state.memebership_data.membershipdata.payment_data,
              membership: {
                ...state.memebership_data.membershipdata.payment_data
                  .membership,
                no_of_sessions: payload
              }
            }
          }
        }
      };
    case types.setUsedSessions:
      return {
        ...state,
        memebership_data: {
          ...state.memebership_data,
          membershipdata: {
            ...state.memebership_data.membershipdata,
            usedsession: payload
          }
        }
      };
    case types.setAutoRenew:
      return {
        ...state,
        memebership_data: {
          ...state.memebership_data,
          membershipdata: {
            ...state.memebership_data.membershipdata,
            payment_data: {
              ...state.memebership_data.membershipdata.payment_data,
              auto_renew: payload
            }
          }
        }
      };
    case types.setRenewal:
      return {
        ...state,
        memebership_data: {
          ...state.memebership_data,
          membershipdata: {
            ...state.memebership_data.membershipdata,
            payment_data: {
              ...state.memebership_data.membershipdata.payment_data,
              auto_renew_meta: {
                ...state.memebership_data.membershipdata.payment_data
                  .auto_renew_meta,
                renewal: payload
              }
            }
          }
        }
      };
    case types.setRenewalBilled:
      return {
        ...state,
        memebership_data: {
          ...state.memebership_data,
          membershipdata: {
            ...state.memebership_data.membershipdata,
            payment_data: {
              ...state.memebership_data.membershipdata.payment_data,
              auto_renew_meta: {
                ...state.memebership_data.membershipdata.payment_data
                  .auto_renew_meta,
                billed_every: payload
              }
            }
          }
        }
      };
    case types.setPytOpt:
      return {
        ...state,
        memebership_data: {
          ...state.memebership_data,
          membershipdata: {
            ...state.memebership_data.membershipdata,
            payment_data: {
              ...state.memebership_data.membershipdata.payment_data,
              paymentOpt: payload
            }
          }
        }
      };
    case types.setRenewOpt:
      return {
        ...state,
        memebership_data: {
          ...state.memebership_data,
          membershipdata: {
            ...state.memebership_data.membershipdata,
            payment_data: {
              ...state.memebership_data.membershipdata.payment_data,
              renewOpt: payload
            }
          }
        }
      };
    case types.setUserError:
      return {
        ...state,
        personal_info: {
          ...state.personal_info,
          error: {
            ...state.personal_info.error,
            [payload.name]: payload.error
          }
        }
      };
    case types.setMembership:
      return {
        ...state,
        membership_id: payload.id,
        membership_payment_id: null,
        payments: {
          ...state.payments,
          all_payments: []
        },
        memebership_data: {
          ...state.memebership_data,
          membershipdata: {
            ...state.memebership_data.membershipdata,
            payment_data: {
              ...state.memebership_data.membershipdata.payment_data,
              membership: {
                ...state.memebership_data.membershipdata.payment_data
                  .membership,
                membership_type: payload.membership_type
              }
            },
            start_date: null,
            expires_date: null
          }
        }
      };
    case types.setMemDate:
      return {
        ...state,
        memebership_data: {
          ...state.memebership_data,
          membershipdata: {
            ...state.memebership_data.membershipdata,
            start_date: payload,
            expires_date: moment(payload)
              .add(
                state.memebership_data.membershipdata.payment_data
                  .initial_commitment.initial,
                'weeks'
              )
              .toDate()
          }
        }
      };
    case types.setMemInitial:
      return {
        ...state,
        memebership_data: {
          ...state.memebership_data,
          membershipdata: {
            ...state.memebership_data.membershipdata,
            expires_date: moment(
              state.memebership_data.membershipdata.start_date
            )
              .add(payload, 'weeks')
              .toDate(),
            payment_data: {
              ...state.memebership_data.membershipdata.payment_data,
              initial_commitment: {
                ...state.memebership_data.membershipdata.payment_data
                  .initial_commitment,
                initial: payload
              }
            }
          }
        }
      };
    case types.setBilledEvery:
      return {
        ...state,
        memebership_data: {
          ...state.memebership_data,
          membershipdata: {
            ...state.memebership_data.membershipdata,
            payment_data: {
              ...state.memebership_data.membershipdata.payment_data,
              initial_commitment: {
                ...state.memebership_data.membershipdata.payment_data
                  .initial_commitment,
                billed_every: payload
              }
            }
          }
        }
      };
    case types.setMemAttendance:
      return {
        ...state,
        memebership_data: {
          ...state.memebership_data,
          membershipdata: {
            ...state.memebership_data.membershipdata,
            payment_data: {
              ...state.memebership_data.membershipdata.payment_data,
              membership: {
                ...state.memebership_data.membershipdata.payment_data
                  .membership,
                attendance_limit: payload,
                attendance_limited_meta: {
                  ...state.memebership_data.membershipdata.payment_data
                    .membership.attendance_limited_meta,
                  type: payload.id === 1 ? AllDayType[0] : null
                }
              }
            }
          }
        }
      };
    case types.setAttendanceUses:
      return {
        ...state,
        memebership_data: {
          ...state.memebership_data,
          membershipdata: {
            ...state.memebership_data.membershipdata,
            payment_data: {
              ...state.memebership_data.membershipdata.payment_data,
              membership: {
                ...state.memebership_data.membershipdata.payment_data
                  .membership,
                attendance_limited_meta: {
                  ...state.memebership_data.membershipdata.payment_data
                    .membership.attendance_limited_meta,
                  uses: payload
                }
              }
            }
          }
        }
      };
    case types.setSessionUses:
      return {
        ...state,
        memebership_data: {
          ...state.memebership_data,
          membershipdata: {
            ...state.memebership_data.membershipdata,
            expires_date: moment(
              state.memebership_data.membershipdata.start_date
            )
              .add(
                payload,
                // eslint-disable-next-line max-len
                state.memebership_data.membershipdata.payment_data.membership.attendance_limited_meta.type.name.toLowerCase()
              )
              .toDate(),
            payment_data: {
              ...state.memebership_data.membershipdata.payment_data,
              membership: {
                ...state.memebership_data.membershipdata.payment_data
                  .membership,
                attendance_limited_meta: {
                  ...state.memebership_data.membershipdata.payment_data
                    .membership.attendance_limited_meta,
                  uses: payload
                }
              }
            }
          }
        }
      };
    case types.setUsesType:
      return {
        ...state,
        memebership_data: {
          ...state.memebership_data,
          membershipdata: {
            ...state.memebership_data.membershipdata,
            payment_data: {
              ...state.memebership_data.membershipdata.payment_data,
              membership: {
                ...state.memebership_data.membershipdata.payment_data
                  .membership,
                attendance_limited_meta: {
                  ...state.memebership_data.membershipdata.payment_data
                    .membership.attendance_limited_meta,
                  type: payload
                }
              }
            }
          }
        }
      };
    case types.setSessionUsesType:
      return {
        ...state,
        memebership_data: {
          ...state.memebership_data,
          membershipdata: {
            ...state.memebership_data.membershipdata,
            expires_date: moment(
              state.memebership_data.membershipdata.start_date
            )
              .add(
                state.memebership_data.membershipdata.payment_data.membership
                  .attendance_limited_meta.uses,
                payload.name.toLowerCase()
              )
              .toDate(),
            payment_data: {
              ...state.memebership_data.membershipdata.payment_data,
              membership: {
                ...state.memebership_data.membershipdata.payment_data
                  .membership,
                attendance_limited_meta: {
                  ...state.memebership_data.membershipdata.payment_data
                    .membership.attendance_limited_meta,
                  type: payload
                }
              }
            }
          }
        }
      };
    case types.setMembershipPayment:
      return {
        ...state,
        membership_payment_id: payload.id,
        memebership_data: {
          ...state.memebership_data,
          membershipdata: {
            ...state.memebership_data.membershipdata,
            payment_data: {
              ...state.memebership_data.membershipdata.payment_data,
              ...payload.paymentdata,
              auto_renew_meta: payload.paymentdata.auto_renew_meta && {
                ...state.memebership_data.membershipdata.payment_data
                  .auto_renew_meta,
                renewal: payload.paymentdata.auto_renew_meta.renewal,
                billed_every: billedWeek.filter(
                  (i) =>
                    // eslint-disable-next-line eqeqeq
                    i.name == payload.paymentdata.auto_renew_meta.billed_every
                )[0]
              },
              initial_commitment: payload.paymentdata.initial_commitment && {
                ...state.memebership_data.membershipdata.payment_data
                  .initial_commitment,
                initial: payload.paymentdata.initial_commitment.initial,
                billed_every: billedWeek.filter(
                  (i) =>
                    // eslint-disable-next-line eqeqeq
                    i.name ==
                    payload.paymentdata.initial_commitment.billed_every
                )[0]
              }
            }
          }
        }
      };

    case types.fetchMembershipPlan:
      return {
        ...state,
        memebership_data: {
          ...state.memebership_data,
          isFetching: true
        }
      };
    case types.changeSignContract:
      return {
        ...state,
        memebership_data: {
          ...state.memebership_data,
          membershipdata: {
            ...state.memebership_data.membershipdata,
            membership_contract: payload
          }
        }
      };
    case types.getMembershipPlan:
      return {
        ...state,
        payments: {
          ...state.payments,
          all_payments: payload.membership_payment,
          isFetching: false
        },
        memebership_data: {
          ...state.memebership_data,
          isFetching: false,
          membershipdata: {
            ...state.memebership_data.membershipdata,
            program_access: payload.program_ids.split(',').map(Number),
            payment_data: {
              ...state.memebership_data.membershipdata.payment_data,
              membership: {
                ...state.memebership_data.membershipdata.payment_data
                  .membership,
                contract_id: payload.contract_id,
                reve_cat_id: payload.reve_cat_id,
                name: payload.name,
                attendance_limit: AttendanceTypes.filter(
                  (i) => i.id === payload.attendance_limit
                )[0],
                attendance_limited_meta: {
                  uses: payload.attendance_limited_meta?.uses,
                  type: AllDayType.filter(
                    (i) => i.id === payload.attendance_limited_meta?.type
                  )[0]
                }
              }
            }
          }
        }
      };
    case types.getMemSession:
      return {
        ...state,
        payments: {
          ...state.payments,
          all_payments: payload.membership_payment,
          isFetching: false
        },
        memebership_data: {
          ...state.memebership_data,
          isFetching: false,
          membershipdata: {
            ...state.memebership_data.membershipdata,
            program_access: payload.program_ids.split(',').map(Number),
            payment_data: {
              ...state.memebership_data.membershipdata.payment_data,
              membership: {
                ...state.memebership_data.membershipdata.payment_data
                  .membership,
                name: payload.name,
                contract_id: payload.contract_id,
                reve_cat_id: payload.reve_cat_id,
                no_of_sessions: payload.session,
                attendance_limit: Expirationtypes.filter(
                  (i) => i.id === payload.attendance_limit
                )[0],
                attendance_limited_meta: {
                  uses: payload.attendance_limited_meta?.uses,
                  type: AllDayType.filter(
                    (i) => i.id === payload.attendance_limited_meta?.type
                  )[0]
                }
              }
            }
          }
        }
      };

    case types.SetSelectedWaiver:
      return {
        ...state,
        selectedWaiver: action.payload
      };

    case types.setQuestionnaire:
      return {
        ...state,
        questionnaire_meta: action.payload
      };
    case types.SetActiveStep:
      return {
        ...state,
        active_Step: action.payload
      };
    case types.toggleSignStatus:
      return {
        ...state,
        signStatus: !state.signStatus
      };
    case types.setWaiverSignInitials:
      return {
        ...state,
        waiver_terms_initial: {
          ...state.waiver_terms_initial,
          sign_initials: action.payload
        }
      };
    case types.setIsSigned:
      return {
        ...state,
        isSigned: action.payload
      };

    case types.setDropInSignature:
      return {
        ...state,
        dropIn_data: {
          ...state.dropIn_data,
          signature: action.payload
        }
      };

    case types.setSignStatus:
      return {
        ...state,
        signStatus: action.payload
      };
    case types.getDropInPlan:
      return {
        ...state,
        payments: {
          ...state.payments,
          all_payments: payload.membership_payment,
          isFetching: false
        },
        membership_payment_id: payload.membership_payment[0].id,
        memebership_data: {
          ...state.memebership_data,
          membershipdata: {
            ...state.memebership_data.membershipdata,
            program_access: payload.program_ids.split(',').map(Number),
            payment_data: {
              ...state.memebership_data.membershipdata.payment_data,
              initial_commitment_pricing:
                payload.membership_payment[0].initial_commitment_pricing
            }
          }
        }
      };
    case types.setStartDateDropIn:
      return {
        ...state,
        memebership_data: {
          ...state.memebership_data,
          membershipdata: {
            ...state.memebership_data.membershipdata,
            start_date: payload
          }
        }
      };

    case types.setWaiverInitialBox:
      return {
        ...state,
        waiver_terms_initial: {
          ...state.waiver_terms_initial,
          initial_box: action.payload.initial
        }
      };

    default:
      return state;
  }
}
