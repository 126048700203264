import React, { useState } from 'react';
import PropType from 'prop-types';
import {
  // Typography,
  makeStyles,
  Grid,
  InputLabel,
  Box,
  FormControlLabel,
  Checkbox,
  Avatar,
  Button
} from '@material-ui/core';
import clsx from 'clsx';
import { ReactComponent as EdiIcon } from 'src/assets/image/edi-icon.svg';
import CheckIcon from '@material-ui/icons/Check';
import {
  validateString,
  validMobile,
  isPhoneNumberInvalid,
  checkEmail
} from 'src/utils/validations';
import JBDatePicker from 'src/views/common/FormControl/JBDatePicker';
import { ReactComponent as DownIcon } from '../../../../assets/image/Vanguard/downIcon.svg';
import FormControl from '../../../common/FormControl/formControl';
import CustomAutocomplete from '../../../common/CustomAutocomplete/commonAutocomplete';
import imgplusicon from '../../../../assets/image/imgplusicon.png';
import DeletePhotoDia from './ImageUpload/DeletePhotoDia';
import ImageUpload from './ImageUpload/ImageUpload';

const useStyles = makeStyles(() => ({
  mainTitle: {
    fontWeight: '500',
    fontSize: '48px',
    lineHeight: '44px',
    color: '#FFFFFF',
    fontFamily: 'DDC Hardware',
    textAlign: 'center',
    margin: '38px 0 0 0',
    '@media (max-width: 992px)': {
      fontSize: '35px'
    }
  },

  cutom_select_Label: {
    fontFamily: 'DDC Hardware',
    fontStyle: 'normal',
    fontWeight: '500;',
    fontSize: '22px',
    lineHeight: '32px',
    letterSpacing: '0.2px',
    color: '#FFFFFF',
    '&:focus ': {
      outline: '0'
    },
    '& .MuiFormLabel-asterisk': {
      color: 'transparent'
    },
    '& .MuiFormLabel-asterisk:after': {
      content: '""',
      background: '#2CE9DA',
      display: 'inline-block',
      position: 'relative',
      width: '8px',
      height: '8px',
      borderRadius: '50%',
      top: '-14px',
      right: '10px'
    }
  },
  cutom_vanguard_FormControl: {
    '& .fd-input input': {
      background: 'rgba(196, 196, 196, 0.05)',
      color: '#fff',
      borderRadius: '6px',
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '20px',
      height: '45px',
      border: '2px solid #C8C8C8',
      padding: '8px 20px 8px',
      '&::placeholder ': {
        color: '#fff',
        fontWeight: 'bold',
        fontSize: '15px'
      }
    },
    '&  .MuiPaper-root': {
      backgroundColor: '#2d2d2d',
      marginTop: '0',
      marginBottom: '0',
      border: '2px solid #C8C8C8',
      borderTop: 'transparent',
      boxShadow: 'none',
      // borderRadius: '0',
      borderTopLeftRadius: '0',
      borderTopRightRadius: '0'
    },
    '& .MuiAutocomplete-option': {
      color: '#fff',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '20px',
      height: '66px'
    },
    '& .MuiAutocomplete-popper': {
      width: '100% !important',
      marginTop: '-2px'
    },
    '& .SelectDrop[aria-expanded="true"]': {
      borderBottom: '2px solid transparent',
      borderBottomLeftRadius: '0',
      borderBottomRightRadius: '0'
    }
  },
  cutom_vanguard_Drop: {
    '& .MuiAutocomplete-popper ': {
      width: 'calc(100% - 16px) !important'
    }
  },
  cutom_vanguard_Autocomplete: {
    background: 'rgba(196, 196, 196, 0.05)',
    border: '2px solid #C8C8C8',
    color: '#fff',
    borderRadius: '6px',
    '& .cutom_select div': {
      minHeight: '62px'
    },
    '& .cutom_select div input ': {
      color: '#ffffff !important',
      fontWeight: 'bold',
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontSize: '20px'
    },
    '& .MuiAutocomplete-endAdornment': {
      minHeight: ' auto !important',
      top: 'calc(50% - 12px)'
    }
  },
  VanguardCutom_date: {
    '& .MuiInput-root': {
      color: '#fff',
      height: '65px',
      fontWeight: '600',
      fontSize: '20px',
      lineHeight: '18px',
      marginTop: '5px !important',
      border: '2px solid #C8C8C8',
      borderRadius: '6px'
    }
  },
  VanCustomcheckbox: {
    '& .MuiFormControlLabel-root svg.MuiSvgIcon-root': {
      width: '30px',
      height: '30px',
      fill: 'none',
      borderRadius: '6px',
      border: '2px solid #4B4B4B'
    },
    '& .MuiFormControlLabel-root.checked svg.MuiSvgIcon-root': {
      background: '#67e9da',
      padding: '4px',
      fill: '#000',
      color: '#060606',
      border: '0px solid #4B4B4B'
    },
    '& .MuiTypography-root': {
      padding: '20px 15px'
    },
    '& .MuiFormControlLabel-label': {
      color: '#fff',
      fontSize: '20px',
      lineHeight: '26px',
      fontWeight: 'bold'
    }
  },
  proimage: {
    position: 'relative',
    '&:hover .editimageup , &:hover .imgDelBtnup , &:hover .EditImageBk': {
      display: 'block'
    },
    '& img': {
      width: '80px',
      height: '80px',
      borderRadius: '50%'
    }
  },
  Avataruname: {
    width: '80px',
    height: '80px',
    fontSize: '24px',
    fontWeight: 'bold',
    lineHeight: '16px',
    backgroundColor: '#CCE6E4',
    color: '#37847a'
  }
}));

// export const Data = [
//   { id: 0, label: 'Male' },
//   { id: 1, label: 'Female' }
// ];

export const Data = [
  { id: 0, label: 'Male', name: 'Male' },
  { id: 1, label: 'Female', name: 'Female' },
  { id: 2, label: 'Non_binary', name: 'Non Binary' },
  { id: 3, label: 'Prefer_not_to_say', name: 'Prefer Not To Say' }
];

const PersonalInfo = (props) => {
  const classes = useStyles();
  const { data, setData, UserId } = props;
  const [editImage, seteditImage] = useState(false);
  const [touched, setTouched] = useState({
    first_name: false,
    last_name: false,
    phone_num: false,
    email: false
  });
  const handleBlur = (event) => {
    const { name } = event.target;
    setTouched({ ...touched, [name]: true });
  };

  const handleErrorImg = (e) => {
    // eslint-disable-next-line operator-linebreak
    e.target.src =
      'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs%3D';
  };

  return (
    <div>
      {/* <Typography variant="h3" className={classes.mainTitle}>
        {Maintitle}
      </Typography> */}
      <Grid
        container
        xs={10}
        justify="center"
        style={{
          margin: '45px auto'
        }}
      >
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={2}
          // style={{ margin: '20px 0 20px' }}
        >
          <Grid
            item
            xs={12}
            style={{
              justifyContent: 'center',
              display: 'flex'
            }}
          >
            <Box
              display="inline-block"
              className={classes.proimage}
              mb="15px"
              css={{
                margin: '0 auto'
              }}
            >
              <InputLabel
                htmlFor="age-required"
                // required
                className={classes.cutom_select_Label}
                style={{ margin: '0 0 10px' }}
              >
                Profile photo
              </InputLabel>
              {/* <ImageUpload /> */}
              {/* {editImage && ( */}

              <Box
                css={{
                  position: 'relative',
                  margin: '0 auto',
                  width: data.profile_pic ? '150px' : null
                }}
              >
                <Box
                  css={{
                    right: '0',
                    position: 'absolute',
                    zIndex: '9'
                  }}
                  className="EditImageBk"
                >
                  <Button
                    color="primary"
                    onClick={() => seteditImage(true)}
                    style={{
                      minWidth: 'auto',
                      padding: '0'
                    }}
                    css={{
                      height: '150px',
                      width: '150px',
                      border: '2px dashed #a9a9a9',
                      minHeight: '150px',
                      borderRadius: '8px',
                      position: 'relative',
                      cursor: 'pointer'
                    }}
                  >
                    <EdiIcon fontSize="small" />
                  </Button>
                </Box>
                {data.profile_pic ? null : (
                  <Avatar className={classes.Avataruname}>
                    {data.first_name[0] + ' ' + data.last_name[0]}
                  </Avatar>
                )}
                {data.profile_pic ? (
                  <Box
                    onClick={() => seteditImage(true)}
                    css={{
                      height: '150px',
                      width: '150px',
                      border: '2px dashed #a9a9a9',
                      minHeight: '150px',
                      borderRadius: '8px',
                      position: 'relative',
                      cursor: 'pointer'
                    }}
                  >
                    <>
                      <img
                        src={data.profile_pic}
                        onError={handleErrorImg}
                        alt="UserIcon"
                        style={{
                          verticalAlign: 'sub',
                          height: '148px',
                          width: '148px',
                          borderRadius: '8px'
                        }}
                        className={classes.avt}
                      />
                    </>
                    {!editImage ? (
                      <span
                        className="Clicktextinfo"
                        style={{
                          width: '100%',
                          color: '#fff',
                          fontSize: '15px',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)'
                        }}
                      >
                        <img
                          src={imgplusicon}
                          alt="img"
                          style={{
                            display: 'block',
                            margin: '0 auto',
                            cursor: 'pointer',
                            objectFit: 'contain',
                            width: 'auto',
                            height: 'auto'
                          }}
                        />
                        Upload photo
                      </span>
                    ) : null}
                  </Box>
                ) : null}
                <ImageUpload
                  open={editImage}
                  handleClose={() => seteditImage(false)}
                  profile_pic={data.profile_pic}
                  userId={UserId}
                  handleSave={(val) => {
                    setData({ ...data, profile_pic: val });
                  }}
                />
                {/* )} */}
                {/* {data.profile_pic && <DeletePhotoDia />} */}
                {data.profile_pic && (
                  <DeletePhotoDia
                    handleSave={(val) => {
                      setData({ ...data, profile_pic: val });
                    }}
                    userId={data.id}
                  />
                )}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6} className={classes.cutom_vanguard_FormControl}>
            <InputLabel
              htmlFor="age-required"
              required
              className={classes.cutom_select_Label}
              // style={{ margin: '0px 0 5px' }}
            >
              FIRST Name
            </InputLabel>
            <FormControl
              control="input"
              value={data.first_name}
              name="first_name"
              onBlur={handleBlur}
              error={touched.first_name && !data.first_name.trim().length}
              helperText={
                touched.first_name && !data.first_name.trim().length
                  ? 'please enter First name'
                  : ''
              }
              onChange={(e) => {
                setData({
                  ...data,
                  first_name: validateString(e.target.value)
                });
              }}
              maxLength={50}
              style={{
                margin: '0 0'
              }}
            />
          </Grid>
          <Grid item xs={6} className={classes.cutom_vanguard_FormControl}>
            <InputLabel
              htmlFor="age-required"
              required
              className={classes.cutom_select_Label}
              // style={{ margin: '0px 0 5px' }}
            >
              LAST Name
            </InputLabel>
            <FormControl
              control="input"
              value={data.last_name}
              name="last_name"
              onBlur={handleBlur}
              error={touched.last_name && !data.last_name.trim().length}
              helperText={
                touched.last_name && !data.last_name.trim().length
                  ? 'please enter a valid name'
                  : ''
              }
              onChange={(e) =>
                setData({ ...data, last_name: validateString(e.target.value) })
              }
              maxLength={50}
              style={{
                margin: '0 0'
              }}
            />
          </Grid>
          <Grid
            item
            xs={6}
            className={classes.cutom_vanguard_FormControl}
            style={{
              margin: '0px 0 0px'
            }}
          >
            <InputLabel
              htmlFor="age-required"
              required
              className={classes.cutom_select_Label}
              // style={{ margin: '0px 0 5px' }}
            >
              Email
            </InputLabel>
            <FormControl
              control="input"
              name="email"
              value={data.email}
              onBlur={handleBlur}
              error={touched.email && checkEmail(data.email)}
              helperText={
                touched.email && checkEmail(data.email)
                  ? 'please enter a valid email'
                  : ''
              }
              onChange={(e) => setData({ ...data, email: e.target.value })}
              maxLength={50}
              style={{
                margin: '0 0'
              }}
            />
          </Grid>
          <Grid
            item
            xs={6}
            className={clsx(
              classes.cutom_vanguard_FormControl,
              classes.cutom_vanguard_Drop
            )}
            style={{
              position: 'relative'
            }}
          >
            <InputLabel
              htmlFor="age-required"
              required
              className={classes.cutom_select_Label}
              style={{ margin: '0px 0 6px' }}
            >
              GENDER
            </InputLabel>
            <CustomAutocomplete
              // holder="Lead source"
              disablePortal
              value={Data}
              // Options="label"
              Options="name"
              data={data.gender || null}
              popupIcon={<DownIcon />}
              Change={(val) => setData({ ...data, gender: val })}
              disableClearable
              // className={classes.cutom_vanguard_Autocomplete}
              className={clsx(
                classes.cutom_vanguard_Autocomplete,
                'SelectDrop'
              )}
              // Change={(val) => {
              //   console.log(val);
              //   setData1({ ...data1, lead_source: val });
              // }}
            />
          </Grid>
          <Grid
            item
            xs={6}
            className={classes.cutom_vanguard_FormControl}
            style={{
              margin: '10px 0'
            }}
          >
            <InputLabel
              htmlFor="age-required"
              className={classes.cutom_select_Label}
              required
              // style={{ margin: '0px 0 5px' }}
            >
              Phone Number
            </InputLabel>
            <FormControl
              control="input"
              value={data.phone_num}
              name="phone_num"
              inputMode="numeric"
              onBlur={handleBlur}
              error={touched.phone_num && isPhoneNumberInvalid(data.phone_num)}
              helperText={
                touched.phone_num && isPhoneNumberInvalid(data.phone_num)
                  ? 'phone number must be between 10 and 15 '
                  : ''
              }
              onChange={(e) =>
                setData({ ...data, phone_num: validMobile(e.target.value) })
              }
              maxLength={50}
              style={{
                margin: '0 0'
              }}
            />
          </Grid>
          <Grid
            item
            xs={6}
            className={classes.cutom_vanguard_FormControl}
            style={{
              margin: '10px 0'
            }}
          >
            <InputLabel
              htmlFor="age-required"
              className={classes.cutom_select_Label}
              required
              // style={{ margin: '0px 0 5px' }}
            >
              DATE OF BIRTH
            </InputLabel>
            <JBDatePicker
              required
              vanguard
              openTo="year"
              value={data.date}
              maxDate={new Date()}
              onChange={(val) => setData({ ...data, date: val })}
              className={classes.VanguardCutom_date}
            />
          </Grid>
        </Grid>

        <Grid container direction="row">
          <Box mt="10px" className={classes.VanCustomcheckbox}>
            <FormControlLabel
              // onChange={(e) => {
              //   setData({ ...data, value: e.target.value });
              // }}
              control={
                <Checkbox
                  checked={data.member_login}
                  onChange={() =>
                    setData({ ...data, member_login: !data.member_login })
                  }
                  name="checkedB"
                  color="primary"
                  checkedIcon={
                    <CheckIcon htmlColor="#2CE9DA" width="30px" height="30px" />
                  }
                />
              }
              label="Dependent member? (No login)"
              className={`${data.member_login === true ? 'checked' : ''}`}
            />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

PersonalInfo.defaultProps = {
  // Maintitle: 'PERSONAL INFORMATION'
};
PersonalInfo.propTypes = {
  // Maintitle: PropType.string,
  data: PropType.object.isRequired,
  setData: PropType.object.isRequired,
  UserId: PropType.number.isRequired
};

export default PersonalInfo;
