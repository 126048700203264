import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropType from 'prop-types';
import { Grid, InputLabel, withStyles } from '@material-ui/core';
import CurrencyInputs from 'src/views/common/CurrencyControlForm/CurrencyInputs';
import styles from '../PaymentPlan.style';
import {
  chngeInitialFull,
  chngeInitialFullmem
} from '../paymentReducer.actions';
import { validateFloat, validPrice } from '../../../../utils/validations';

function PlanPricing(props) {
  const {
    classes,
    reducer: [state, dispatch],
    currencyrates: { data: currencies, error }
  } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (error) return <p>Something went wrong</p>;

  const {
    full_setup_fees,
    full_membership_fees
  } = state.initial_commitment_pricing;

  return (
    <div>
      <h3 style={{ marginBottom: '10px' }}>Initial Commitment</h3>
      <div className={classes.hr} />
      <Grid
        container
        spacing={0}
        xs={12}
        disableGutters
        direction="row"
        style={{
          margin: '15px 0'
        }}
      >
        <Grid
          item
          xs={2}
          style={{
            marginTop: '0px',
            paddingRight: '15px',
            display: 'flex'
          }}
        >
          <h4 style={{ alignSelf: 'center' }}>When paid in Full</h4>
        </Grid>
        <Grid
          item
          xs={3}
          className={classes.time_box}
          style={{ marginTop: '0px', paddingRight: '15px' }}
        >
          <InputLabel
            htmlFor="age-required"
            className={classes.cutom_select_Label}
            style={{ padding: '0px 0 10px' }}
          >
            One-time setup fee
          </InputLabel>
          <CurrencyInputs
            currencySymbol={currencies.currency_symbol}
            value={full_setup_fees}
            style={{
              margin: '0 0'
            }}
            onChange={(e) =>
              dispatch(
                chngeInitialFull(validPrice(validateFloat(e.target.value)))
              )
            } // eslint-disable-line
          />
        </Grid>
        <Grid
          item
          xs={3}
          className={classes.time_box}
          style={{ marginTop: '0px', paddingRight: '15px' }}
        >
          <InputLabel
            htmlFor="age-required"
            className={classes.cutom_select_Label}
            style={{ padding: '0px 0 10px' }}
            required
          >
            Full membership fee
          </InputLabel>
          <CurrencyInputs
            currencySymbol={currencies.currency_symbol}
            value={full_membership_fees}
            style={{
              margin: '0 0'
            }}
            onChange={(e) =>
              dispatch(
                chngeInitialFullmem(validPrice(validateFloat(e.target.value)))
              )
            } // eslint-disable-line
          />
        </Grid>
        <Grid
          item
          xs={3}
          className={classes.time_box}
          style={{
            marginTop: '20px',
            display: 'flex',
            flexDirection: 'column',
            alignSelf: 'center'
          }}
        >
          <h3 style={{ marginBottom: '0px' }}>
            {currencies.currency_symbol
              + ' '
              + parseFloat(
                parseFloat(full_setup_fees) + parseFloat(full_membership_fees)
              ).toLocaleString(undefined, {
                minimumFractionDigits: currencies.decimals
              })}
          </h3>
          <InputLabel
            htmlFor="age-required"
            className={classes.cutom_select_Label}
          >
            Total cost (including tax)
          </InputLabel>
        </Grid>
      </Grid>
    </div>
  );
}

PlanPricing.propTypes = {
  classes: PropType.object.isRequired,
  reducer: PropType.array.isRequired,
  currencyrates: PropType.object.isRequired
};

const mapToState = (state) => ({
  currencyrates: state.SettingReducer.currency
});

export default connect(mapToState)(withStyles(styles)(PlanPricing));
