const styles = () => ({
  root: {
    marginTop: 0,
    margin: '0px 20px 20px',
    padding: '0px 20px 20px'
  },
  mainCard: {
    boxShadow: '4px 0px 16px rgba(37, 40, 43, 0.08)',
    borderRadius: '5px',
    background: '#FFFF',
    padding: 20
  },
  ptext: {
    fontSize: '14px',
    lineHeight: '22px',
    color: '#7F7F7F',
    fontWeight: 'normal'
  },
  cutom_select_Label: {
    fontSize: '12px',
    '&:focus ': {
      outline: '0'
    }
  },
  invoiceheadBox: {
    display: 'inline-block;',
    marginRight: '40px',
    '& span': {
      fontWeight: 'bold',
      fontSize: '14px',
      lineHeight: '18px',
      color: '#949494',
      display: 'block',
      letterSpacing: '0.5px',
      marginBottom: '4px'
    },
    '& label': {
      fontWeight: 'bold',
      fontSize: '14px',
      lineHeight: '18px',
      color: '#000000',
      display: 'block',
      letterSpacing: '0.5px',
      marginBottom: '4px'
    }
  },
  totalItem: {
    '& span': {
      fontWeight: 'bold',
      fontSize: '14px',
      lineHeight: '18px',
      color: '#292929',
      display: 'block',
      letterSpacing: '0.5px',
      padding: '11px 15px'
    }
  },
  totalDue: {
    backgroundColor: '#E6F3F2',
    borderRadius: '8px',
    '& span': {
      fontWeight: '900',
      fontSize: '17px',
      padding: '17px 15px'
    }
  },
  TextareaControlNots: {
    '& .MuiInput-root': {
      margin: '0 0 !important',
      padding: '13.5px 15px 13.5px'
    },
    '& textarea': {
      width: '100%',
      resize: 'auto',
      maxHeight: '100px',
      overflowY: 'auto !important',
      color: '#000'
    },
    '& textarea::placeholder': {
      color: '#949494 !important',
      fontWeight: '500',
      fontSize: '14px'
    }
  },
  InvoiceCard: {
    padding: '30px'
  },
  invoicestatus: {
    display: 'block;',
    marginBottom: '15px',
    '& span': {
      fontWeight: 'bold',
      fontSize: '14px',
      lineHeight: '18px',
      color: '#949494',
      display: 'inline-block',
      letterSpacing: '0.5px',
      marginBottom: '4px',
      marginRight: '4px'
    },
    '& label': {
      fontWeight: 'bold',
      fontSize: '14px',
      lineHeight: '18px',
      color: '#000000',
      display: 'inline-block',
      letterSpacing: '0.5px',
      marginBottom: '4px'
    }
  },
  SearchTrans: {
    padding: '2px 6px 0',
    minWidth: 'auto',
    '& span': {
      marginBottom: '0px'
    }
  },
  cutom_date_css: {
    '& .MuiInput-root': {
      marginTop: '0px !important'
    }
  },
  refunded_amount: {
    display: 'inline-flex',
    alignItems: 'center',
    '& span': {
      paddingLeft: '0',
      paddingRight: '0'
    }
  }
});
export default styles;
