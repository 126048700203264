/* eslint-disable no-unused-vars */
/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepButton from '@material-ui/core/StepButton';
import { Grid, ThemeProvider } from '@material-ui/core';
import '../JBStepper/stepper.css';
import StepcompIcon from '../../../assets/image/Vanguard/stepcompIcon.png';

const styles = (theme) => ({
  root: {
    width: '90%',
    '& .circle': {
      border: '2px solid red',
      backgroundColor: '#FFFFFF',
      height: '100px',
      borderRadius: '50%',
      width: '100px'
    }
  },
  stepperRoot: {
    // padding: '0'
  },
  actionsContainer: {
    marginBottom: theme.spacing(1) * 2
  },
  resetContainer: {
    padding: theme.spacing(1) * 3
  },
  errorDiv: {
    color: theme.palette.error.main
  },
  noWrap: {
    whiteSpace: 'nowrap',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px'
  }
});

const JBStepper = (props) => {
  const {
    activeStep,
    classes,
    steps,
    IconComp,
    profileLead,
    profileActiveStep
  } = props;

  // const backBtnDisabled = !!(activeStep === 0 || isLoading);
  // const nextBtnDisabled = !!(shouldDisableBtn || isLoading);
  // const lsErrorMessage = 'Sorry.We hit a snag.';

  const themeInstance = {
    overrides: {
      MuiStepper: {
        horizontal: {
          justifyContent: 'center',
          padding: '24px 0',
          backgroundColor: '#000'
        }
      },
      MuiStep: {
        horizontal: {
          paddingLeft: '4px',
          paddingRight: '4px',
          // textAlign: 'center'
          '@media (max-width: 850px)': {
            paddingLeft: '1px',
            paddingRight: '1px'
          }
        }
      },
      MuiStepLabel: {
        iconContainer: {
          paddingRight: '0px'
        }
      },
      MuiStepIcon: {
        root: {
          color: 'rgb(51,51,51)',
          // height: '64px',
          // width: '64px',
          height: steps.length > 9 ? '52px' : '64px',
          width: steps.length > 9 ? '52px' : '64px',
          '@media (max-width: 991px)': {
            height: steps.length > 9 ? '48px' : '55px',
            width: steps.length > 9 ? '48px' : '55px'
          }
        },
        active: {
          color: '#2CE9DA !important',
          '& text': {
            fill: '#000 !important'
          }
        },
        text: {
          fill: 'rgb(255 255 255 / 40%)',
          fontWeight: 'bold'
        },
        completed: {
          color: '#1e1e1e !important',
          borderRadius: '50%',
          border: '3px solid #2CE9DA',
          background: `#000 url('${StepcompIcon}')`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          fill: 'none'
        }
      },
      MuiStepConnector: {
        horizontal: {
          padding: '0 0',
          flex: '24px 0 0'
        },
        lineHorizontal: {
          borderTopStyle: 'dotted',
          borderTopWidth: '2px',
          maxWidth: '24px'
        }
        // alternativeLabel: {
        //   top: 10,
        //   left: 'calc(-50% + 16px)',
        //   right: 'calc(50% + 16px)'
        // },
        // line: {
        //   borderColor: 'red',
        //   borderTopWidth: 3,
        //   borderRadius: 1
        // }
      }
    }
  };

  return (
    <div>
      <ThemeProvider theme={themeInstance}>
        {profileLead ? (
          <Stepper
            classes={{
              root: classes.stepperRoot // className, e.g. `OverridesClasses-root-X`
            }}
            activeStep={activeStep}
            orientation="horizontal"
            // alternativeLabel
          >
            {steps.map((step, index) => {
              // eslint-disable-line no-debugger
              return (
                <Step>
                  <StepLabel
                    StepIconComponent={IconComp}
                    className="sp-stepLabel"
                    style={{ flexDirection: 'column', cursor: 'pointer' }}
                    // eslint-disable-next-line no-unused-vars
                    onClick={(e) => profileActiveStep(index)}
                  >
                    <Grid item xs>
                      <div className={classes.noWrap}>{step}</div>
                    </Grid>
                  </StepLabel>
                </Step>
              );
            })}
          </Stepper>
        ) : (
          <Stepper
            classes={{
              root: classes.stepperRoot // className, e.g. `OverridesClasses-root-X`
            }}
            activeStep={activeStep}
            orientation="horizontal"
            // alternativeLabel
          >
            {steps.map((step, index) => {
              // eslint-disable-line no-debugger
              return (
                <Step>
                  <StepButton onClick={() => activeStep}>
                    <StepLabel
                      StepIconComponent={IconComp}
                      className="sp-stepLabel"
                      style={{ flexDirection: 'column' }}
                    >
                      <Grid item xs>
                        {/* <div className={classes.noWrap}>{step}</div> */}
                      </Grid>
                    </StepLabel>
                  </StepButton>
                </Step>
              );
            })}
          </Stepper>
        )}
      </ThemeProvider>
    </div>
  );
};
JBStepper.defaultProps = {
  // handleReset: null
  // OnCloseRequest: null,
  // shouldDisableBtn: false,
  profileLead: false
};

JBStepper.propTypes = {
  classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  steps: PropTypes.arrayOf(PropTypes.object).isRequired,
  activeStep: PropTypes.number.isRequired,
  IconComp: PropTypes.element,
  profileLead: PropTypes.bool,
  profileActiveStep: PropTypes.func
  // hasErrored: PropTypes.bool.isRequired,
  // isLoading: PropTypes.bool.isRequired,
  // OnCloseRequest: PropTypes.func,
  // shouldDisableBtn: PropTypes.bool
};

export default withStyles(styles)(JBStepper);
