import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import Invoices from 'src/views/financial/invoices/invoices';
import EditInvoiceView from 'src/views/financial/invoices/Invoice/EditInvoiceView';
import InvoiceView from 'src/views/financial/invoices/Invoice/InvoiceView';
import FinancialSettings from 'src/views/financial/settings/FinancialSettings';
import LoadingView from 'src/views/common/loading';
// import {
//   getAllInvoices,
//   getFailedInvoices,
//   getOverdueInvs,
//   getPaidInvs,
//   getProcessingInvoices,
//   getUnpaidInvs
// } from 'src/Redux/InvoiceReducer/InvoiceReducer.actions';

class FinancialContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fetched: false
    };
  }

  componentDidMount() {
    // const {
    //   getAllInvs,
    //   getPaidInv,
    //   getunpaids,
    //   getoverdues,
    //   getProcessInvs,
    //   getFailedInvs
    // } = this.props;
    // getAllInvs();
    // getPaidInv();
    // getunpaids();
    // getoverdues();
    // getProcessInvs();
    // getFailedInvs();
    this.setState({ fetched: true });
  }

  render() {
    const { fetched } = this.state;
    if (!fetched) return <LoadingView />;
    const {
      match: { url }
    } = this.props;
    return (
      <Switch>
        <Route path={`${url}/invoice/edit`} component={EditInvoiceView} />
        <Route path={`${url}/invoice/view`} component={InvoiceView} />
        <Route path={`${url}/settings`} component={FinancialSettings} />
        <Route path={`${url}/`} component={Invoices} />
      </Switch>
    );
  }
}
FinancialContainer.propTypes = {
  match: PropTypes.object.isRequired
  // getAllInvs: PropTypes.func.isRequired,
  // getPaidInv: PropTypes.func.isRequired,
  // getunpaids: PropTypes.func.isRequired,
  // getoverdues: PropTypes.func.isRequired,
  // getProcessInvs: PropTypes.func.isRequired,
  // getFailedInvs: PropTypes.func.isRequired
};

// const mapToDispatch = (dispatch) => ({
//   // getAllInvs: () => dispatch(getAllInvoices()),
//   // getPaidInv: () => dispatch(getPaidInvs()),
//   // getunpaids: () => dispatch(getUnpaidInvs()),
//   // getoverdues: () => dispatch(getOverdueInvs()),
//   // getProcessInvs: () => dispatch(getProcessingInvoices()),
//   // getFailedInvs: () => dispatch(getFailedInvoices())
// });

export default connect(null, null)(FinancialContainer);
