import React, { useState } from 'react';
import PropType from 'prop-types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField, withStyles } from '@material-ui/core';

const styles = () => ({
  cutom_select_border: {
    border: '1px solid #a9a9a9'
  }
});

const CustomAutocomplete = ({
  classes,
  holder,
  value,
  Options,
  Change,
  data,
  seterrors,
  seterrortext,
  id,
  ...props
}) => {
  const [refs, setRefs] = useState(null);
  const Date = [
    {
      date: '1'
    },
    {
      date: '0'
    }
  ];
  return (
    <Autocomplete
      id={id}
      options={value || Date}
      value={data || ''}
      disabled={!value}
      onChange={(event, val) => {
        Change(val, event);
      }}
      getOptionLabel={(option) =>
        (Options ? option[Options] || '' : option.date)
      }
      className={classes.cutom_select_border}
      renderInput={(params) => (
        <TextField
          placeholder={holder}
          InputLabelProps={{
            shrink: true
          }}
          className="cutom_select"
          onFocus={() => setRefs(true)}
          error={refs && seterrors}
          helperText={refs && seterrors ? seterrortext : null}
          {...params}
        />
      )}
      {...props}
    />
  );
};
CustomAutocomplete.defaultProps = {
  seterrors: false,
  seterrortext: null,
  data: null,
  id: 'CustomAutocomplete'
};

CustomAutocomplete.propTypes = {
  id: PropType.string,
  classes: PropType.object.isRequired,
  holder: PropType.string.isRequired,
  value: PropType.array.isRequired,
  Change: PropType.func.isRequired,
  Options: PropType.any.isRequired,
  seterrors: PropType.bool,
  seterrortext: PropType.string,
  data: PropType.any
};

export default withStyles(styles)(CustomAutocomplete);
