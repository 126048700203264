import React, { useState, useEffect } from 'react';
// import { makeStyles } from '@material-ui/styles';
import PropType from 'prop-types';
import Button from '@material-ui/core/Button';
import {
  Grid,
  InputLabel,
  Box,
  Card,
  Typography,
  Breadcrumbs
} from '@material-ui/core';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import JBDatePicker from 'src/views/common/FormControl/JBDatePicker';
import { changeDatereverse } from 'src/utils/someCommon';
import FormControl from 'src/views/common/FormControl/formControl';
import { withStyles } from '@material-ui/core/styles';
import CancelDialog from 'src/views/common/CommonDialogBox/CancelDialog';
import LoadingView from 'src/views/common/loading';
import { getClassWOD, createCopyWOD } from 'src/services/WODmodule.services';
import { checkValidString } from 'src/utils/validations';
import JBCKEditor from 'src/views/common/JBCKEditor';
import { getErrorMsg } from 'src/utils/ErrorHandling';
import NotFoundView from 'src/views/errors/NotFoundView';
import useChangeTitle from 'src/views/common/CustomHooks/useTitleChange';
import {
  checkCopyWOD,
  getCopyData,
  getUnisuggest,
  setPriTags,
  setSecTags
} from '../WOD.utils';
import { openNoti } from '../../../Redux/global/global.actions';
import { getWOD } from '../../../Redux/WODReducers/wodReducer.actions';
import Table from '../Table';
import { ROUTES } from '../../../constant/Constants';
import CustomTags from '../../common/CustomTags/CustomTags';
import CustomAutoComplete from '../../calendar/commonAutocomplete';
import CancelDialogTwo from '../../common/CommonDialogBox/CancelDialogTwo';
import styles from '../EditView/detailView.style';
import '../style.css';

const CopyWod = (props) => {
  const {
    classes,
    program: { isFetching, programs },
    location: { state },
    history,
    OpenNotify,
    reloadWOD,
    wodTags
  } = props;

  const [date, setDate] = useState(null);
  const [cancelDialog, setcancelDialog] = useState(false);
  const [pid, setPid] = useState(state ? state.program : null);
  const [data, setData] = useState(state);
  const [classe, setClasses] = useState({
    data: null,
    err: false
  });
  useChangeTitle('Copy Workout | Jungle Alliance');
  useEffect(() => {
    if (date && pid) {
      getClassWOD(pid.id, changeDatereverse(date))
        .then((res) => setClasses({ data: res }))
        .catch(() => setClasses({ err: true }));
    }
  }, [date, pid]);

  const handleSubmit = () => {
    if (!checkCopyWOD(data, pid, date)) {
      if (!date) {
        OpenNotify('Please Select Date First', 'error');
      } else if (!data.class_list || data.class_list.length === 0) {
        OpenNotify('Please Select Class', 'error');
      } else OpenNotify('Please Fill all the fields', 'error');
    } else if (checkValidString(data.name)) {
      OpenNotify('Please enter valid Workout name', 'error');
    } else {
      const value = getCopyData(data, pid, date);
      createCopyWOD(value)
        .then(() => reloadWOD())
        .then(() => history.push(ROUTES.WORKOUT_LIST))
        .then(() => OpenNotify('Workout created Sucessfully', 'info'))
        .catch((err) => OpenNotify(getErrorMsg(err), 'error'));
    }
  };
  if (!state) return <NotFoundView />;
  if (isFetching) return <LoadingView />;

  const handleAddition = (values, type) => {
    if (type === 'primary') {
      const { error, data: tagsdata } = setPriTags(
        data.tags,
        data.secondary_tags,
        values
      );
      if (error) {
        OpenNotify(error, 'error');
      } else {
        setData({
          ...data,
          tags: tagsdata
        });
      }
    } else {
      const { error, data: tagsdata } = setSecTags(
        data.secondary_tags,
        data.tags,
        values
      );
      if (error) {
        OpenNotify(error, 'error');
      } else {
        setData({
          ...data,
          secondary_tags: tagsdata
        });
      }
    }
  };

  const handleDeletion = (i, type) => {
    if (type === 'primary') {
      setData({
        ...data,
        tags: data.tags.filter((tag, index) => index !== i)
      });
    } else {
      setData({
        ...data,
        secondary_tags: data.secondary_tags.filter((tag, index) => index !== i)
      });
    }
  };

  const { coach_notes, name, workout_details, class_list, tags } = data; // eslint-disable-line
  return (
    <div className={classes.root}>
      <div className="title-bk">
        <div style={{ marginBottom: 15 }}>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={0}
          >
            <Grid item xs={12}>
              {/* <h3 style={{ marginBottom: 0 }}>
                Workouts <label>/</label> List
              </h3> */}
              <Breadcrumbs aria-label="breadcrumb">
                <div color="inherit">Workouts</div>
                <div
                  color="inherit"
                  onClick={() => setcancelDialog(true)}
                  style={{
                    cursor: 'pointer'
                  }}
                >
                  List
                </div>
                <Typography variant="h3" color="primary">
                  <span>{data.name}</span>
                </Typography>
              </Breadcrumbs>
            </Grid>

            <Grid item xs={6} className={classes.btnBlock} />
          </Grid>
        </div>
        <h1>Copy Workout</h1>
      </div>

      <Card className={classes.mainCard}>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
        >
          <Grid item xs>
            <Grid
              container
              direction="row"
              spacing={2}
              alignItems="center"
              style={{ marginBottom: '8px' }}
            >
              <Grid item xs={6} style={{ marginleft: '0px' }}>
                <FormControl
                  control="input"
                  label="Workout name"
                  placeholder="Workout"
                  value={name}
                  seterrors={checkValidString(name) || name.length === 0}
                  seterrortext="Please enter the valid workout name"
                  onChange={(e) => setData({ ...data, name: e.target.value })}
                  required
                />
              </Grid>
              <Grid item xs={3} style={{ marginTop: '7px' }}>
                <JBDatePicker
                  label="Date"
                  disablePast
                  required
                  placeholder="Please Select the Date"
                  value={date}
                  onChange={(val) => setDate(val)}
                />
              </Grid>
              <Grid item xs={3} style={{ marginTop: '3px' }}>
                <InputLabel
                  htmlFor="age-required"
                  className={classes.cutom_select_Label}
                  required
                >
                  Program
                </InputLabel>
                <CustomAutoComplete
                  holder="Select Program"
                  value={programs}
                  data={pid}
                  Options="name"
                  Change={(val) => setPid(val)}
                  style={{ marginbottom: '0' }}
                  seterrors={!pid}
                  seterrortext="Please Select the Program"
                />
              </Grid>
            </Grid>
            {classe.data && (
              <Table
                values={classe.data.data}
                getSelected={(val) => setData({ ...data, class_list: val })}
              />
            )}
            {classe.err && (
              <Grid item xs={12} style={{ marginTop: '30px' }}>
                <h3>There are no classes for this program for this date</h3>
                <Button
                  variant="outlined"
                  color="primary"
                  component={Link}
                  to={ROUTES.CLASSES_CREATE_NEW_CLS}
                  style={{
                    width: '131px',
                    height: '48px',
                    marginTop: '30px'
                  }}
                >
                  Create class
                </Button>
              </Grid>
            )}
            <Grid
              item
              xs={12}
              style={{ marginTop: '20px', marginBottom: '20px' }}
            >
              <InputLabel
                htmlFor="age-required"
                className={classes.cutom_select_Label}
              >
                Primary tags
              </InputLabel>
              <CustomTags
                tags={data.tags}
                autofocus
                suggestions={getUnisuggest(wodTags, tags, data.secondary_tags)}
                handleAddition={(val) => handleAddition(val, 'primary')} // eslint-disable-line
                handleDelete={(i) => handleDeletion(i, 'primary')}
              />
            </Grid>
            <Grid item xs={12} style={{ marginBottom: '20px' }}>
              <InputLabel
                htmlFor="age-required"
                className={classes.cutom_select_Label}
              >
                Secondary tags
              </InputLabel>
              <CustomTags
                autofocus={false}
                tags={data.secondary_tags}
                suggestions={getUnisuggest(wodTags, tags, data.secondary_tags)}
                handleAddition={(val) => handleAddition(val, 'secondary')} // eslint-disable-line
                handleDelete={(i) => handleDeletion(i, 'secondary')} // eslint-disable-line
              />
            </Grid>

            <Grid item xs={12} style={{ marginBottom: '20px' }}>
              <Box display="block" mt={3}>
                {/* <Typography
                  color="#000"
                  variant="h5"
                  component="div"
                  className={classes.title}
                  style={{ marginBottom: '20px' }}
                >
                  Workout details
                </Typography> */}
              </Box>
              <InputLabel
                htmlFor="age-required"
                required
                className={classes.cutom_select_Label}
              >
                Workout details
              </InputLabel>
              <JBCKEditor
                data={workout_details}
                onChange={(e) =>
                  setData({ ...data, workout_details: e.editor.getData() })
                }
              />
            </Grid>
            <Grid item xs={12} style={{ marginBottom: '20px' }}>
              <InputLabel
                htmlFor="age-required"
                className={classes.cutom_select_Label}
              >
                Coaches note
              </InputLabel>
              <JBCKEditor
                data={coach_notes}
                onChange={(e) =>
                  setData({ ...data, coach_notes: e.editor.getData() })
                }
              />
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
          >
            <CancelDialog />
            <Button
              color="primary"
              className={classes.button}
              type="submit"
              onClick={handleSubmit}
            >
              <span>Save</span>
            </Button>
          </Grid>
        </Grid>
        <CancelDialogTwo
          setOpen={cancelDialog}
          handleHistory={ROUTES.WORKOUT_LIST}
          handleClose={() => setcancelDialog(false)}
        />
      </Card>
    </div>
  );
};

CopyWod.defaultProps = {
  wodTags: []
};

CopyWod.propTypes = {
  classes: PropType.object.isRequired,
  program: PropType.object.isRequired,
  location: PropType.any.isRequired,
  history: PropType.any.isRequired,
  OpenNotify: PropType.func.isRequired,
  wodTags: PropType.array,
  reloadWOD: PropType.func.isRequired
};

const mapToState = (state) => ({
  program: state.allprograms,
  wodTags: state.wodList.tags
});

const mapToDispatch = (dispatch) => ({
  OpenNotify: (msg, type) => dispatch(openNoti(msg, type)),
  reloadWOD: () => dispatch(getWOD())
});
export default connect(mapToState, mapToDispatch)(withStyles(styles)(CopyWod));
