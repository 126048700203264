import * as Yup from 'yup';

export const initialValues = {
  email: '',
  password: ''
};

export const validationSchema = Yup.object({
  email: Yup.string()
    .email('Must be a valid email')
    .max(60)
    .required('Email is required'),
  password: Yup.string()
    .max(20)
    .min(6)
    .required('Password is required')
});
