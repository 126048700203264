import React, { useState } from 'react';
import PropType from 'prop-types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField, withStyles } from '@material-ui/core';

const styles = () => ({
  cutom_select_border: {
    border: '1px solid #a9a9a9'
  }
});

const CustomAutocomplete = ({
  classes,
  holder,
  value,
  Options,
  Change,
  data,
  seterrors,
  seterrortext,
  ...props
}) => {
  const [refs, setRefs] = useState(null);
  const Date = [
    {
      date: '1'
    },
    {
      date: '0'
    }
  ];
  return (
    <Autocomplete
      options={value || Date}
      value={data || ''}
      onChange={(_, val) => Change(val)}
      // getOptionLabel={(option) => (Options ? option[Options] : option.date)}
      // eslint-disable-next-line no-confusing-arrow
      getOptionLabel={(option) =>
        Options ? option[Options] || '' : option.date
      }
      className={classes.cutom_select_border}
      renderInput={(params) => (
        <TextField
          placeholder={holder}
          InputLabelProps={{
            shrink: true
          }}
          className="cutom_select"
          onFocus={() => setRefs(true)}
          error={refs && seterrors}
          helperText={refs && seterrors ? seterrortext : null}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
        />
      )}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
};

CustomAutocomplete.defaultProps = {
  classes: null,
  holder: '',
  seterrors: false,
  seterrortext: '',
  data: null
};

CustomAutocomplete.propTypes = {
  classes: PropType.object,
  holder: PropType.string,
  value: PropType.array.isRequired,
  Change: PropType.func.isRequired,
  Options: PropType.any.isRequired,
  seterrors: PropType.bool,
  seterrortext: PropType.string,
  // data: PropType.string // change any to object as per discuss with mukul
  // data: PropType.object // change any to object as per discuss with mukul
  data: PropType.any
};

export default withStyles(styles)(CustomAutocomplete);
