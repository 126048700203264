import React, { useEffect, useState } from 'react';
import PropType from 'prop-types';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  withStyles,
  Grid
} from '@material-ui/core';
import { getTransactionHistory } from 'src/services/masterHQ.invoices.services';
import LoadingView from 'src/views/common/loading';
import dcloseicon from 'src/assets/image/closeicon.png';
import TransactionsTable from './TransactionsTable/TransactionsTable';

const styles = (theme) => ({
  dtitle: {
    color: '#fff',
    fontWeight: 'bold',
    fontSize: '33px',
    lineHeight: '26px'
  },
  cutom_select_Label: {
    fontSize: '12px',
    padding: '7px 0'
  },
  Discountinput: {
    margin: '0px',
    display: 'inline-flex',
    width: '97px',
    marginLeft: '15px',
    border: '1px solid #a9a9a9',
    height: '48px',
    padding: '9px 15px 7px'
    // '&:focus': {
    //   border: '1px solid #01847a'
    // }
  },
  DiscounFormControl: {
    width: '95px',
    height: '48px',
    marginLeft: '20px',
    '& .MuiOutlinedInput-input': {
      padding: '14.5px 8px'
    }
  },
  Discounsign: {
    '& p': {
      color: 'rgb(41 41 41 / 0.4)'
    }
  },
  LabelControl: {
    width: '130px',
    marginRight: '0'
  },
  finalCharge: {
    width: '333px',
    height: '40px',
    background: '#F9F9F9',
    float: 'right',
    display: 'flex',
    alignItems: 'center',
    padding: '7px 16px',
    color: '#292929',
    '& h6': {
      fontWeight: '700',
      fontSize: '14px',
      lineHeight: '18px',
      width: '100%'
    }
  },
  Totalcharge: {
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '22px'
  },
  DialogPopup: {
    '& .MuiPaper-root': {
      background: theme.masterhq.mainCard.background
    }
    // '& .MuiButton-contained.Mui-disabled': {
    //   color: 'rgb(0 0 0 / 38%)',
    //   boxShadow: 'none',
    //   backgroundColor: 'rgb(59 60 78)'
    // },
    // '& .fd-input input': {
    //   color: '#ffffff'
    // }
  }
});

function TransactionDiag(props) {
  const { classes, handleClose, setOpen, id } = props;
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getTransactionHistory(id)
      .then((res) => {
        setData(res);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.warn(err);
      });
  }, [id]);
  return (
    <div>
      <Dialog
        open={setOpen}
        onClose={handleClose}
        fullWidth
        maxWidth="lg"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick
        className={classes.DialogPopup}
      >
        <div>
          <Grid alignItems="center" container direction="row">
            <Grid item xs>
              <DialogTitle
                id="alert-dialog-title"
                className={classes.titlebk}
                style={{
                  display: 'inline-block',
                  padding: '25px 24px'
                }}
              >
                <h4 className={classes.dtitle}>
                  Transactions for Invoice #{String(id).padStart(7, '0')}
                </h4>
              </DialogTitle>
            </Grid>
            <Grid
              item
              xs={2}
              style={{ textAlign: 'right', marginRight: '10px' }}
            >
              <Button className={classes.btns} onClick={handleClose}>
                <img alt="img" src={dcloseicon} />
              </Button>
            </Grid>
          </Grid>

          <DialogContent>
            {/* eslint-disable-next-line no-nested-ternary */}

            <Grid container style={{ marginTop: '5px' }}>
              <Grid
                item
                xs={12}
                style={{
                  margin: '5px 0 10px'
                }}
              >
                {loading ? (
                  <LoadingView />
                ) : (
                  <TransactionsTable values={data} />
                )}
              </Grid>
            </Grid>
          </DialogContent>
        </div>
      </Dialog>
    </div>
  );
}

TransactionDiag.propTypes = {
  classes: PropType.object.isRequired,
  setOpen: PropType.bool.isRequired,
  handleClose: PropType.func.isRequired,
  id: PropType.number.isRequired
};
export default withStyles(styles)(TransactionDiag);
