/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import PropType from 'prop-types';
import ClassContainer from 'src/hocContainers/ClassContainer';
import ProgramContainer from 'src/hocContainers/ProgramContainer';
import ClassesSettings from 'src/views/classessettings/ClassesSettings';
import PeopleContainer from 'src/hocContainers/PeopleContainer';
import EditSessionTemplate from 'src/views/memberships/EditSessionTemplate/EditSessionTemplate';
import FinancialContainer from 'src/hocContainers/FinancialContainer';
import CommunicationContainer from 'src/hocContainers/CommunicationContainer';
import ReportsContainer from 'src/hocContainers/ReportsContainer';
import { ROUTES } from 'src/constant/Constants';
import { MembershipContext } from 'src/Context/MembershipTemplate/MembershipTemplateContext';
import { SessionContext } from 'src/Context/MembershipTemplate/SessionTemplate.Context';
import { allFormRoutes } from 'src/views/common/SearchBox/SearchBoxConstants';
import { get } from 'js-cookie';
import ErrorUserView from 'src/views/errorUserPermission/errorUserView';
import DropinTemplate from 'src/views/memberships/MembershipTemplate/DropinTemplate/DropinTemplate';
import EditDropinTemplate from 'src/views/memberships/EditDropinTemplate/EditDropinTemplate';
import AccountView from '../../views/account/AccountView';
import CustomerListView from '../../views/customer/CustomerListView';
import DashboardView from '../../views/reports/DashboardView';
import SettingsView from '../../views/settings/SettingsView';
import WODContainer from '../../hocContainers/WorkoutContainer';
import WodSettings from '../../views/wod/WodSettings';
import ProductListView from '../../views/product/ProductListView';
import MembershipsList from '../../views/memberships/List/MembershipsList';
import MembershipTemplate from '../../views/memberships/MembershipTemplate/MembershipTemplate';
import EditMembership from '../../views/people/Members/MemberProfile/EditUserMembership/EditMembership';
import EditPlanTemplate from '../../views/memberships/EditPlanTemplate/EditPlanTemplate';
import SessionTemplate from '../../views/memberships/MembershipTemplate/SessionTemplates/SessionTemplate';
import ContractTemplates from '../../views/Documents/ContractTemplates';
import NewContract from '../../views/Documents/NewContract';
import GeneralInfo from '../../views/gym/generalinfo/GeneralInfo';
import GymSettings from '../../views/gym/settings/GymSettings';
import NotFoundView from '../../views/errors/NotFoundView';
import DocumentContainer from '../../hocContainers/DocumentContainer';

const MainRoutes = (props) => {
  const {
    match: { url },
    location: { pathname }
  } = props;
  const roleid = JSON.parse(get('jbrole') || '{}')?.role_id || 3;

  if (allFormRoutes.some((route) => route.path === pathname)) {
    localStorage.setItem('formcomponent', true);
  } else if (localStorage.getItem('formcomponent') !== null) {
    localStorage.removeItem('formcomponent');
  }
  return (
    <Switch>
      <Route path={`${url}/account`} component={AccountView} />
      <Route path={`${url}/customers`} component={CustomerListView} />
      <Route path={`${url}/dashboard`} component={DashboardView} />
      <Route path={`${url}/products`} component={ProductListView} />
      <Route path={`${url}/program`} component={ProgramContainer} />
      <Route path={`${url}/settings`} component={SettingsView} />
      <Route
        path={`${url}/class`}
        render={() => (
          <ClassContainer onCalendarClick={props.onCalendarClick} />
        )}
      />
      <Route path={`${url}/class/setting`} component={ClassesSettings} />
      <Route path={`${url}/people`} component={PeopleContainer} />
      <Route path={`${url}/memberships`} component={MembershipsList} />
      <Route
        path={`${url}/membership/create`}
        render={(prop) => {
          if (roleid === 1) {
            return (
              <MembershipContext>
                <MembershipTemplate {...prop} />
              </MembershipContext>
            );
          }
          return <ErrorUserView />;
        }}
      />
      <Route
        path={`${url}/membership/edit`}
        render={(prop) => {
          if (roleid === 1) {
            return <EditPlanTemplate {...prop} />;
          }
          return <ErrorUserView />;
        }}
      />
      <Route path={`${url}/newtemplate`} component={EditMembership} />
      <Route
        path={`${url}/session/create`}
        render={(prop) => {
          if (roleid === 1) {
            return (
              <SessionContext>
                <SessionTemplate {...prop} />
              </SessionContext>
            );
          }
          return <ErrorUserView />;
        }}
      />
      <Route
        path={`${url}/session/edit`}
        render={(prop) => {
          if (roleid === 1) {
            return <EditSessionTemplate {...prop} />;
          }
          return <ErrorUserView />;
        }}
      />

      {/* Add */}
      <Route
        path={`${url}/dropin/create`}
        render={(prop) => {
          if (roleid === 1) {
            return (
              // <SessionContext>
              <MembershipContext>
                <DropinTemplate {...prop} />
              </MembershipContext>
              // </SessionContext>
            );
          }
          return <ErrorUserView />;
        }}
      />
      <Route
        path={`${url}/dropin/edit`}
        render={(prop) => {
          if (roleid === 1) {
            return <EditDropinTemplate {...prop} />;
          }
          return <ErrorUserView />;
        }}
      />

      <Route
        path={`${url}/wod`}
        render={(prop) => {
          if (roleid <= 2) {
            return <WODContainer {...prop} />;
          }
          return <ErrorUserView />;
        }}
      />
      <Route
        path={`${url}/wod/setting/`}
        render={() => {
          if (roleid <= 2) {
            return <WodSettings />;
          }
          return <ErrorUserView />;
        }}
      />
      {/* <Route path={`${url}/newmembership`} component={NewMembership} /> */}

      <Route path={`${url}/documents`} component={DocumentContainer} />
      <Route path={`${url}/contracttemplates`} component={ContractTemplates} />
      <Route
        path={`${url}/newcontract`}
        render={(prop) => (
          <NewContract
            goToHistory={ROUTES.DOCUMENTS_CONTRACTS_TEMPLATES}
            {...prop}
          />
        )}
      />
      <Route path={`${url}/financial`} component={FinancialContainer} />
      <Route path={`${url}/reports/`} component={ReportsContainer} />
      <Route path={`${url}/communication`} component={CommunicationContainer} />

      <Route path={`${url}/gym/settings`} component={GymSettings} />
      <Route
        path={`${url}/gym`}
        render={(prop) => {
          if (roleid === 1) {
            return <GeneralInfo {...prop} />;
          }
          return <ErrorUserView />;
        }}
      />
      <Route path={`${url}/*`} component={NotFoundView} />
    </Switch>
  );
};
MainRoutes.propTypes = {
  match: PropType.any.isRequired,
  onCalendarClick: PropType.func.isRequired,
  location: PropType.any.isRequired
};
export default withRouter(MainRoutes);
