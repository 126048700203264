/* eslint-disable operator-linebreak */
import { setHours, setMinutes } from 'date-fns';
// import moment from 'moment';

export const getValuefromObject = (val) => {
  if (!val) {
    return null;
  }
  const result = Object.values(val)[0];
  return result;
};

// calender date format (Full Calender) events
export const getDate = () => {
  const date = '2020-08-20';
  const time = '10:10:00';
  const d = date.split('-');
  const t = time.split(':');
  return new Date(d[0], d[1] - 1, d[2], t[0], t[1], t[2]);
};

// for backend Date format
export const backendDate = (date) => {
  // const d = new Date(date);
  let month = '' + (date.getMonth() + 1);
  let day = '' + date.getDate();
  const year = date.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('/');
};

export const changeDatereverse = (date) => {
  // const d = new Date(date);
  let month = '' + (date.getMonth() + 1);
  let day = '' + date.getDate();
  const year = date.getFullYear();
  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;
  return [year, month, day].join('-');
};

export const recieveTags = (arr) => {
  const data = arr.map((list) => Object.values(list)[0]).join(',');
  return data;
};

export const wodDate = (date) => {
  let d;
  if (date.includes('-')) d = date.split('-');
  else if (date.includes('/')) d = date.split('/');
  else return new Date();
  const data = new Date(d[2], d[1] - 1, d[0]);
  return data;
};

export const setBackDate = (date) => {
  const d = date.split('-');
  return new Date(d[0], d[1] - 1, d[2]);
};

export const removetag = (s, id) => {
  const s1 = s.split(',');
  const result = s1.filter((_, i) => i !== id).join(',');
  return result;
};

export const getIdfromArr = (arr) => {
  const result = arr.map((list) => Object.values(list)[0]).join(',');
  return result;
};

export const getIDfromClass = (arr) => {
  return arr.map((list) => parseInt(Object.values(list)[0], 10));
};

export const checkValid = (data) => {
  let isValid = true;
  const len = Object.values(data).length;
  for (let i = 0; i < len; i += 1) {
    if (
      !Object.values(data)[i] ||
      Object.values(data)[i].length === 0 ||
      String(Object.values(data)[i]) === 'Invalid Date'
    ) {
      isValid = false;
      return isValid;
    }
  }
  return isValid;
};

export const wodDateBack = (date) => {
  const d = date.split('-');
  const data = [d[0], d[1], d[2]].join('/');
  return data;
};

export function getTags(selected, data) {
  const result = [];
  for (let i = 0; i < selected.length; i += 1) {
    for (let j = 0; j < data.length; j += 1) {
      if (data[j].id === selected[i]) {
        data[j].tags && result.push(data[j].tags.split(',')); // eslint-disable-line
      }
    }
  }
  return [...new Set(result.flat())];
}

export const checkQuillValid = (data) => {
  if (data.replace(/<(.|\n)*?>/g, '').trim().length === 0) return false;
  return true;
};

export const getRouteIndex = (arr, path) => {
  for (let i = 0; i < arr.length; i += 1) {
    for (let j = 0; j < arr[i].nested.length; j += 1) {
      if (arr[i].nested[j].href === path) {
        return arr[i].id;
      }
    }
  }
  return null;
};

export const getTime = (date) => {
  const newDate = new Date(date);
  let hours =
    newDate.getHours() !== 12 ? newDate.getHours() % 12 : newDate.getHours();
  let minutes = newDate.getMinutes();
  const format = newDate.getHours() < 12 ? ' AM' : ' PM';
  if (String(hours).length < 2) hours = '0' + hours;
  if (String(minutes).length < 2) minutes = '0' + minutes;
  return hours + ':' + minutes + format;
};

export const ArrToObj = (arr) => {
  const result = {};
  for (let i = 0; i < arr.length; i += 1) {
    let b;
    const c = Object.keys(arr[i]);
    // eslint-disable-next-line
    for (let key in arr[i]) {
      b = { ...arr[i][key] };
    }
    result[c] = { ...b };
  }
  return result;
};

export const setMinMins = (date) => {
  const upDate = new Date(date);
  const result = new Date(upDate.setHours(upDate.getHours() + 1));
  result.setMinutes(upDate.getMinutes());
  return result;
};

export const getMinDate = (date) => {
  const upDate = new Date(date);
  const result = new Date(new Date().setDate(upDate.getDate() + 1));
  result.setMonth(upDate.getMonth());
  result.setFullYear(upDate.getFullYear());
  return result;
};

export const BacktoFeTime = (time) => {
  const startTime = new Date();
  const parts = time.match(/(\d+):(\d+) (AM|PM)/);
  if (parts) {
    let hours = parseInt(parts[1]); // eslint-disable-line
    const minutes = parseInt(parts[2]); // eslint-disable-line
    const tt = parts[3];
    if (tt === 'PM' && hours < 12) hours += 12;
    startTime.setHours(hours, minutes, 0, 0);
  }
  return startTime;
};

export const getmaxDate = (date) => {
  let data = new Date(date);
  if (!date) {
    data = new Date();
  }
  const result = new Date(new Date().setDate(data.getDate() - 1));
  result.setMonth(data.getMonth());
  result.setFullYear(data.getFullYear() + 1);
  return result;
};

export const checkMinTime = (endTime, startTime) => {
  const end_time = new Date(endTime);
  const start_time = new Date(startTime);
  if (
    !endTime ||
    !startTime ||
    String(startTime).includes('Invalid') ||
    String(endTime).includes('Invalid') ||
    String(end_time).includes('Invalid') ||
    String(start_time).includes('Invalid')
  ) {
    return true;
  }
  // const d3 = (d1.getTime() - d2.getTime()) / 1000 / 60 / 60;
  if (
    end_time.getTime() <= start_time.getTime() ||
    new Date().getTime() >= start_time.getTime()
  ) {
    return true;
  }
  return false;
};

export const checkMaxDate = (d1, diff) => {
  const d2 = new Date(d1);
  if (!d2 || String(d2).includes('Invalid')) {
    return true;
  }
  const maxDate = new Date(
    new Date().setFullYear(new Date().getFullYear() + (diff || 1))
  );
  const dateDiff = Math.floor(
    (maxDate.getTime() - d2.getTime()) / 1000 / 60 / 60 / 24
  );
  if (dateDiff > 365 * diff) {
    return true;
  }
  return false;
};

export const checkOneDayDiff = (start, end) => {
  const startDate = new Date(start);
  const endDate = new Date(end);
  if (
    !startDate ||
    !endDate ||
    String(startDate).includes('Invalid') ||
    String(endDate).includes('Invalid')
  ) {
    return false;
  }
  const diff = (startDate.getTime() - endDate.getTime()) / 1000 / 60 / 60 / 24;
  if (diff < 0.1) {
    return false;
  }
  return true;
};

export const time24to12 = (t1) => {
  let time = t1;
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time
  ];

  if (time.length > 1) {
    time = time.slice(1);
    time[5] = +time[0] < 12 ? 'AM' : 'PM';
    time[0] = +time[0] % 12 || 12;
  }
  time = time[0] + time[1] + time[2] + ' ' + time[5];
  return time;
};

export const checkCalDateRange = (date, defaultDate) => {
  // const from = moment(defaultDate)
  // .subtract(7, 'days')
  // .toDate();
  // const to = moment(defaultDate)
  //   .add(6, 'days')
  //   .toDate();
  // const maxdiff = (to.getTime() - date.getTime()) / 1000 / 60 / 60 / 24 / 7;
  // const mindiff = (date.getTime() - from.getTime()) / 1000 / 60 / 60 / 24 / 7;
  if (date.getTime() !== defaultDate.getTime()) {
    return true;
  }
  // if (maxdiff < 0 || mindiff <= 0) {
  //   return true;
  // }
  return false;
};

export const setMinStartTime = (date) => {
  const d = new Date();
  const diff = (date.getTime() - d.getTime()) / 1000 / 60 / 60 / 24;
  if (diff < 1 && d.getDate() === date.getDate()) {
    return d;
  }
  return setHours(setMinutes(new Date(), 0), 4);
};

export function checkClassName(className, programName) {
  let program_name = programName;
  if (typeof programName === 'object') {
    program_name = programName.name;
  }
  if (className.length === 0) {
    return true;
  }
  const Timregex = '(1[0-2]|0?[1-9]):[0-5][0-9] (AM|PM)';
  const regex = new RegExp(program_name + ' ' + Timregex, 'i');
  if (regex.test(className)) {
    return true;
  }
  return false;
}

export function getAge(d1, d2) {
  const d = d2 || new Date();
  const diff = d.getTime() - d1.getTime();
  return Math.floor(diff / (1000 * 60 * 60 * 24 * 365.25));
}

export const checkEndTime = (startTime, endTime) => {
  const strt = new Date(startTime);
  const end = new Date(endTime);
  if (strt.getTime() >= end.getTime()) {
    return true;
  }
  return false;
};

export const checkStartTime = (startTime) => {
  const defaults = new Date();
  const strt = new Date(startTime);
  if (strt.getTime() <= defaults.getTime()) {
    return true;
  }
  return false;
};

export const getMinTimeErr = (startTime, endTime) => {
  const end = new Date(endTime);
  const strt = new Date(startTime);
  if (strt.getTime() >= end.getTime()) {
    return { error: 'End Time cannot equal or less than Start Time' };
  }
  if (strt.getTime() <= new Date().getTime()) {
    return { error: "Can't create the Class of past date" };
  }
  return { value: true };
};

export const traverseObj = (o) => {
  const type = typeof o;
  if (type === 'object') {
    // eslint-disable-next-line no-restricted-syntax
    for (const key in o) {
      if (typeof o[key] === 'object') {
        traverseObj(o[key]);
      } else {
        console.warn(key, o[key]);
      }
    }
  } else {
    console.warn('what happen', o);
  }
};

export const diffinObject = (o, base, result) => {
  const type = typeof o;
  if (type === 'object') {
    // eslint-disable-next-line no-restricted-syntax
    for (const key in o) {
      if (typeof o[key] === 'object') {
        diffinObject(o[key], base[key], result);
      } else {
        // eslint-disable-next-line no-lonely-if
        if (o[key] !== base[key]) {
          result.push(key);
        }
      }
    }
  }
  return result;
};

export const getDays = (start, end) => {
  const startdate = new Date(start);
  const enddate = new Date(end);
  if (
    startdate instanceof Date &&
    // eslint-disable-next-line no-restricted-globals
    !isNaN(startdate) &&
    enddate instanceof Date &&
    // eslint-disable-next-line no-restricted-globals
    !isNaN(enddate)
  ) {
    const days = Math.floor(
      (enddate.getTime() - startdate.getTime()) / 1000 / 60 / 60 / 24
    );
    return days;
  }
  return null;
};
export const isJson = (item) => {
  let item1 = typeof item !== 'string' ? JSON.stringify(item) : item;

  try {
    item1 = JSON.parse(item1);
  } catch (e) {
    return false;
  }

  if (typeof item1 === 'object' && item1 !== null) {
    return true;
  }

  return false;
};

export function toCapitalize(validString) {
  if (!validString || validString?.length <= 1) {
    return validString;
  }
  return validString.charAt(0).toUpperCase() + validString.slice(1);
}
