/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles
} from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import MenuIcon from '@material-ui/icons/Menu';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import { getlogOut } from 'src/services/auth.services';
import { ReactComponent as LogoIcon } from '../../assets/image/Jungle-Alliance_Primary.svg';
// import Junglelogo from '../../assets/image/Vanguard/Jungle-Alliance-logo.png';
import Vlogo from '../../assets/image/Vanguard/V.png';

import { ReactComponent as DropdownIcon } from '../../assets/image/Vanguard/icon/drop-down.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    color: '#fff',
    backgroundColor: '#1D1D1DF0',
    left: 'auto',
    maxWidth: '1024px',
    right: 'auto',
    '& .user-info h4': {
      margin: '0 7px',
      fontWeight: '700'
    }
  },
  avatar: {
    width: 40,
    height: 40,
    cursor: 'pointer'
  },
  search: {
    position: 'relative',
    height: '48px',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#EAEAEA',
    '&:hover': {
      backgroundColor: '#EAEAEA'
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto'
    }
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    right: '0',
    color: '#01847A'
  },
  inputRoot: {
    color: 'inherit',
    height: 'inherit'
  },
  inputInput: {
    padding: '6px 20px 6px !important;',
    // vertical padding + font size from searchIcon
    paddingRight: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    minWidth: '285px',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px',
    '&::placeholder': {
      color: '#545454',
      fontSize: '14px',
      fontWeight: '700',
      opacity: '1'
    },
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        // width: '20ch',
      }
    }
  },
  MenuListItem: {
    listStyle: 'none',
    color: '#fff',
    width: 'auto',
    overflow: 'hidden',
    fontSize: '14px',
    boxSizing: 'border-box',
    minHeight: '40px',
    fontFamily: 'Lato',
    fontWeight: '700',
    lineHeight: '1.5',
    whiteSpace: 'nowrap',
    padding: '10px 15px 10px',
    cursor: 'pointer',
    '&:hover': {
      color: '#f9f9fa',
      backgroundColor: 'rgb(30 30 30)',
      borderRadius: '6px'
    }
  },
  Avataruname: {
    fontWeight: 'bold',
    backgroundColor: '#CCE6E4',
    color: '#37847a',
    fontSize: '1.1rem',
    cursor: 'pointer'
  },
  Vlogo: {
    marginRight: '10px'
  }
}));

const VanguardTopBar = ({
  className,
  onMobileNavOpen,
  currentUser,
  ...rest
}) => {
  const classes = useStyles();
  // const [notifications] = useState([]);
  const [open, setOpen] = React.useState(false);

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleLogout = () => {
    setOpen(false);
    getlogOut()
      .then(() => {
        return (window.location = '/login'); // eslint-disable-line
      })
      .catch(() => {
        const token = localStorage.getItem('x-token');
        const refresh = localStorage.getItem('qid');
        if (!token || refresh) {
          window.location = '/login';
        } else {
          console.warn('Something went wrong');
        }
      });
  };
  return (
    <AppBar className={clsx(classes.root)} elevation={0} {...rest}>
      <Toolbar
        style={{
          paddingRight: '0',
          minHeight: '75px'
        }}
      >
        <RouterLink to="/">
          {/* <Junglelogo /> */}
          {/* <img src={Junglelogo} alt="logo" /> */}
          <LogoIcon className="main-logoVg" />
        </RouterLink>
        <Box flexGrow={1} />
        <Hidden xsDown>
          <div className="user-info">
            <img src={Vlogo} alt="v" className={classes.Vlogo} />
            {currentUser.profile_pic ? (
              <Avatar
                alt={currentUser.first_name}
                onClick={handleToggle}
                src={currentUser.profile_pic}
                className={classes.avatar}
              />
            ) : (
              <Avatar className={classes.Avataruname} onClick={handleToggle}>
                {currentUser.first_name[0] + ' ' + currentUser.last_name[0]}
              </Avatar>
            )}
            <h4 onClick={handleToggle}>Welcome, {currentUser.first_name}</h4>
            <DropdownIcon onClick={handleToggle} />
            <div style={{ position: 'relative' }}>
              <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                style={{
                  top: '20px',
                  left: 'auto',
                  right: '15px',
                  minWidth: '130px',
                  position: 'absolute'
                  // background: '#2F2F2F',
                  // border: '2px solid #606060',
                  // borderRadius: '6px'
                }}
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps} // eslint-disable-line
                    style={{
                      transformOrigin:
                        placement === 'bottom' ? 'center top' : 'center bottom'
                    }}
                  >
                    <Paper
                      style={{
                        background: '#2F2F2F',
                        border: '2px solid #606060',
                        borderRadius: '6px'
                      }}
                    >
                      <ul
                        autoFocusItem={open}
                        id="menu-list-grow"
                        onKeyDown={handleListKeyDown}
                      >
                        <li
                          onClick={handleLogout}
                          className={classes.MenuListItem}
                        >
                          Logout
                        </li>
                      </ul>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </div>
          </div>
        </Hidden>
        <Hidden xsUp>
          <IconButton color="inherit" onClick={onMobileNavOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

VanguardTopBar.propTypes = {
  className: PropTypes.string.isRequired,
  onMobileNavOpen: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired
};

export default VanguardTopBar;
