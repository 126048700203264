import React from 'react';
import PropTypes from 'prop-types';
import { Grid, AppBar, Tabs, Tab } from '@material-ui/core';
import {
  Link,
  Route,
  Switch,
  useRouteMatch,
  withRouter
} from 'react-router-dom';
import { ROUTES } from '../../../../constant/Constants';
import TodayFuture from './TodayFuture';
import useStyles from './ListTabs.style';
import AllClassList from './AllClassList';

const ClassesTabs = () => {
  const classes = useStyles();
  // const [value, changeTab] = useState(0);
  const match = useRouteMatch();

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    };
  }

  return (
    <div className={classes.root}>
      {/* {(() => {
        switch (tabValue) {
          case 0:
            return <TodayFuture />;
          case 1:
            return <AllClassList />;

          default:
            return null;
        }
      })()} */}

      <Grid container>
        <AppBar
          position="static"
          style={{
            backgroundColor: 'transparent',
            boxShadow: 'none'
          }}
        >
          <Tabs
            value={match.isExact ? 0 : 1}
            indicatorColor="primary"
            textColor="primary"
            fullWidth
            // onChange={(val) => changeTab(val)}
          >
            <Tab
              label="today & future"
              component={Link}
              to={ROUTES.CLASSES_LIST}
              {...a11yProps(0)}
              className={classes.p_tabs}
            />
            <Tab
              label="All"
              component={Link}
              to={ROUTES.CLASSES_LIST_All}
              {...a11yProps(1)}
              className={classes.p_tabs}
            />
          </Tabs>
        </AppBar>
        <Switch>
          <Route
            exact
            path={ROUTES.CLASSES_LIST_All}
            component={AllClassList}
          />
          <Route path={ROUTES.CLASSES_LIST} component={TodayFuture} />
        </Switch>
      </Grid>
    </div>
  );
};

ClassesTabs.propTypes = {
  match: PropTypes.any.isRequired
};

export default withRouter(ClassesTabs);
