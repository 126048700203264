/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import PropType from 'prop-types';
import Button from '@material-ui/core/Button';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import getBulkAction from 'src/views/common/JBTables/bulkActions.utils';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import TagsDialog from './TagsDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  paper: {
    marginRight: theme.spacing(2)
  },
  BulkActionsbtn: {
    borderRadius: '10px',
    color: '#545454',
    border: '1px solid #A9A9A9',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px',
    textTransform: 'capitalize',
    letterSpacing: '0.2px'
  },
  BulkActionsList: {
    listStyle: 'none',
    color: '#545454',
    width: 'auto',
    overflow: 'hidden',
    fontSize: '14px',
    boxSizing: 'border-box',
    minHeight: '40px',
    fontFamily: 'Lato',
    fontWeight: '700',
    lineHeight: '1.5',
    whiteSpace: 'nowrap',
    padding: '10px 15px 10px',
    cursor: 'pointer',
    '&:hover': {
      color: '#01847A',
      backgroundColor: 'rgba(1 132 122 / 0.1)'
    }
  }
}));

const BulkActions = (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [openDiag, setDiag] = React.useState(false);
  const [openRemDiag, setRemDiag] = React.useState(false);

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }
  const handleClickAway = () => {
    setOpen(false);
  };
  const { wodsSelected, selectedTags, module } = props;
  const tags = selectedTags.map((wod) => {
    return {
      id: wod,
      text: wod
    };
  });
  return (
    <div className={classes.root}>
      <div>
        <Button
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          // onClick={() => setOpen(!open)}
          variant="outlined"
          className={classes.BulkActionsbtn}
        >
          <div onClick={() => setOpen(!open)}>
            Bulk Actions
            {open ? (
              <ExpandLess style={{ verticalAlign: 'middle' }} />
            ) : (
              <ExpandMore style={{ verticalAlign: 'middle' }} />
            )}
          </div>
        </Button>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps} // eslint-disable-line
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom'
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClickAway}>
                  {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
                  <ul
                    autoFocusItem={open}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                  >
                    <li
                      className={classes.BulkActionsList}
                      onClick={() => {
                        setDiag(true);
                      }}
                    >
                      <div>Add workout tags</div>
                    </li>
                    {openDiag && (
                      <TagsDialog
                        Add
                        wods={wodsSelected.join(',')}
                        open={openDiag}
                        tags={tags}
                        module={module}
                        handleClose={() => {
                          setDiag(false);
                          setOpen(false);
                        }}
                      />
                    )}
                    <li
                      className={classes.BulkActionsList}
                      onClick={() => setRemDiag(true)}
                    >
                      <div>Remove workout tags</div>
                    </li>
                    {openRemDiag && (
                      <TagsDialog
                        open={openRemDiag}
                        wods={wodsSelected.join(',')}
                        tags={tags}
                        module={module}
                        handleClose={() => {
                          setOpen(false);
                          setRemDiag(false);
                        }}
                      />
                    )}
                    <li
                      className={classes.BulkActionsList}
                      onClick={() => {
                        getBulkAction('exportFile', module, wodsSelected, null);
                        // setOpen(false);
                      }}
                    >
                      <div>Export</div>
                    </li>
                  </ul>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
};
BulkActions.defaultProps = {
  module: 'WOD'
};

BulkActions.propTypes = {
  wodsSelected: PropType.array.isRequired,
  selectedTags: PropType.array.isRequired,
  module: PropType.string
};

export default BulkActions;
