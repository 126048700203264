import React, { useState, useCallback } from 'react';
import {
  withStyles,
  InputLabel,
  Grid,
  Typography,
  Box
} from '@material-ui/core';
import PropType from 'prop-types';
// import TextareaControl from 'src/views/common/FormControl/TextareaControl';
import FormControl from 'src/views/common/FormControl/formControl';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import { openNoti } from 'src/Redux/global/global.actions';
import { getGymSetup } from 'src/Redux/GymSetupReducer/GymSetup.actions';
import { updateSettingsByModule } from 'src/services/Setting.services';
import {
  createNewTax,
  getAllInvoiceSet
} from 'src/Redux/SettingReducer/SettingReducer.actions';
import { validateFloat } from 'src/utils/validations';
import LoadingView from 'src/views/common/loading';
import NotFoundView from 'src/views/errors/NotFoundView';
import JBCKEditor from 'src/views/common/JBCKEditor';
import { getErrorMsg } from 'src/utils/ErrorHandling';
import useChangeTitle from 'src/views/common/CustomHooks/useTitleChange';
import CustomAutoComplete from '../../common/CustomAutocomplete/commonAutocomplete';
import Plusicon from '../../../assets/image/plusicon.png';
import {
  getCurrencyVal,
  Memberinvoicedate,
  InvoiceAutoBill,
  getInvoiceData
} from './FinancialSettings.utils';
import JBButton from '../../common/JBButton/JBButton';
import DeleteTaxDialog from './DeleteTaxDialog';
import styles from './FinancialSettings.style';

function InvoiceConfiguration({
  classes,
  invoiceConfig,
  newTax,
  fetchInvoiceSet,
  openNotify,
  getGymSteps,
  gymStep
}) {
  const { currency, invoice_footer, invoice_setting, taxRates } = invoiceConfig;
  const { invoice_auto_bill, member_invoice_date } = invoice_setting.data;
  const [data, setData] = useState(() => ({
    currency_config: currency.data,
    invoice_footer: invoice_footer.data,
    member_invoice_date: Memberinvoicedate.filter(
      (l) => l.value === member_invoice_date
    )[0],
    invoice_auto_bill: InvoiceAutoBill.filter(
      // eslint-disable-next-line eqeqeq
      (l) => l.id == invoice_auto_bill
    )[0]
  }));
  const [Taxedata, setTaxedata] = useState({ name: '', rate: null, status: 1 });
  const [edit, setEdit] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [error, seterror] = useState(false);
  useChangeTitle('Invoice Configuration | Jungle Alliance');
  /* useEffect(() => {
    setData({
      currency_config: currency.data,
      invoice_footer: invoice_footer.data,
      member_invoice_date,
      invoice_auto_bill
    });
  }, []); */
  const addTax = () => {
    if (!Taxedata.name || !Taxedata.rate) {
      setEdit(false);
      return;
    }
    newTax(Taxedata);
    setEdit(false);
  };
  const updateApi = useCallback(
    debounce((state) => {
      const invoiceData = getInvoiceData(state);
      updateSettingsByModule('invoice', invoiceData)
        .then(() => {
          fetchInvoiceSet();
          openNotify('Invoice settings updated');
        })
        .then(() => {
          if (gymStep.process_type.invoice_setting === 0) {
            getGymSteps();
          }
        })
        .catch((err) => openNotify(getErrorMsg(err), 'error'));
    }, 1400),
    []
  );

  if (error) return <NotFoundView />;
  if (!data) return <LoadingView />;

  return (
    <Box p="15px">
      <Grid container direction="row" spacing={0}>
        <Grid
          container
          direction="row"
          spacing={0}
          style={{ margin: '10px 0 20px' }}
        >
          <Grid item xs={7}>
            <Box mt="15px" mb="15px">
              <Typography
                color="#000"
                variant="h5"
                component="div"
                className={classes.bktitle}
              >
                Member Invoice Date
              </Typography>
              <p className={classes.ptext}>
                Select a default billing date for member memberships. This
                default billing date can be changed when adding an individual
                membership.
              </p>
            </Box>
          </Grid>
          <Grid item xs={5}>
            <Box mt="15px" mb="15px" justifyContent="flex-end" display="flex">
              <Grid item xs={10}>
                <InputLabel
                  htmlFor="age-required"
                  className={classes.cutom_select_Label}
                  style={{ padding: '5px 0' }}
                  required
                >
                  Member invoice date
                </InputLabel>
                <CustomAutoComplete
                  holder="Membership start date"
                  data={data.member_invoice_date}
                  value={Memberinvoicedate}
                  Options="value"
                  Change={(val) => {
                    setData({ ...data, member_invoice_date: val });
                    updateApi({ ...data, member_invoice_date: val });
                  }}
                />
              </Grid>
            </Box>
          </Grid>
        </Grid>

        <div className={classes.hr} />
        <Grid
          container
          direction="row"
          spacing={0}
          style={{ margin: '10px 0 20px' }}
        >
          <Grid item xs={7}>
            <Box mt="15px" mb="15px">
              <Typography
                color="#000"
                variant="h5"
                component="div"
                className={classes.bktitle}
              >
                Invoice Auto Bill
              </Typography>
              <p className={classes.ptext}>
                Every time an invoice payment fails, we retry to collect
                payment. This process has a payment processor fee associated.
                You can choose how many times to attempt to collect payment.
                Once the number of attempts defined is reached, auto bill is
                automatically set to off.
              </p>
            </Box>
          </Grid>
          <Grid item xs={5}>
            <Box mt="15px" mb="15px" justifyContent="flex-end" display="flex">
              <Grid item xs={10}>
                <InputLabel
                  htmlFor="age-required"
                  className={classes.cutom_select_Label}
                  style={{ padding: '5px 0' }}
                >
                  Number of Auto-bill payment attempts (credit card)
                </InputLabel>
                <CustomAutoComplete
                  holder="3 times"
                  data={data.invoice_auto_bill}
                  value={InvoiceAutoBill}
                  Options="value"
                  Change={(val) => {
                    setData({ ...data, invoice_auto_bill: val });
                    updateApi({ ...data, invoice_auto_bill: val });
                  }}
                />
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <div className={classes.hr} />

        <Grid
          container
          direction="row"
          spacing={0}
          style={{ margin: '10px 0 20px' }}
        >
          <Grid item xs={7}>
            <Box mt="15px" mb="5px">
              <Typography
                color="#000"
                variant="h5"
                component="div"
                className={classes.bktitle}
              >
                Invoice Footer
              </Typography>
              <p className={classes.ptext}>
                Add a custom message to your invoice receipts.
              </p>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <JBCKEditor
              data={data.invoice_footer}
              onChange={(e) => {
                setData({
                  ...data,
                  invoice_footer: e.editor.getData()
                });
                updateApi({
                  ...data,
                  invoice_footer: e.editor.getData()
                });
              }}
            />
          </Grid>
        </Grid>
        <div className={classes.hr} />
        <Grid
          container
          direction="row"
          spacing={0}
          style={{ margin: '10px 0 20px' }}
        >
          <Grid item xs={7}>
            <Box mt="15px" mb="15px">
              <Typography
                color="#000"
                variant="h5"
                component="div"
                className={classes.bktitle}
              >
                Currency Configuration
              </Typography>
              <p className={classes.ptext}>
                Select currency symbol, precision, decimal symbol, and
                separator.
              </p>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Grid container direction="row" spacing={2}>
              <Grid item xs>
                <FormControl
                  control="input"
                  label="Currency symbol"
                  required
                  value={data.currency_config.currency_symbol}
                  onChange={(val) => {
                    setData({
                      ...data,
                      currency_config: {
                        ...data.currency_config,
                        currency_symbol: val.target.value
                      }
                    });
                    updateApi({
                      ...data,
                      currency_config: {
                        ...data.currency_config,
                        currency_symbol: val.target.value
                      }
                    });
                  }}
                />
              </Grid>
              <Grid item xs>
                <FormControl
                  control="input"
                  label="Digits after the decimal"
                  value={data.currency_config.decimals}
                  onChange={(val) => {
                    if (
                      // eslint-disable-next-line operator-linebreak
                      val.target.value === '' ||
                      /\b(0*(?:[0-9]|10))\b/gm.test(val.target.value)
                    ) {
                      setData({
                        ...data,
                        currency_config: {
                          ...data.currency_config,
                          decimals: val.target.value
                        }
                      });
                      updateApi({
                        ...data,
                        currency_config: {
                          ...data.currency_config,
                          decimals: val.target.value
                        }
                      });
                    }
                  }}
                />
              </Grid>
              <Grid item xs>
                <FormControl
                  control="input"
                  label="Decimal symbol"
                  value={data.currency_config.decimal_symbol}
                  onChange={(val) => {
                    setData({
                      ...data,
                      currency_config: {
                        ...data.currency_config,
                        decimal_symbol: val.target.value
                      }
                    });
                    updateApi({
                      ...data,
                      currency_config: {
                        ...data.currency_config,
                        decimal_symbol: val.target.value
                      }
                    });
                  }}
                />
              </Grid>
              <Grid item xs>
                <FormControl
                  control="input"
                  label="Group separator"
                  value={data.currency_config.group_seperator}
                  onChange={(val) => {
                    setData({
                      ...data,
                      currency_config: {
                        ...data.currency_config,
                        group_seperator: val.target.value
                      }
                    });
                    updateApi({
                      ...data,
                      currency_config: {
                        ...data.currency_config,
                        group_seperator: val.target.value
                      }
                    });
                  }}
                />
              </Grid>
              <Grid item xs>
                {/* if input is disabled  */}
                <Box className={classes.inputdisabled}>
                  <FormControl
                    control="input"
                    label="Example"
                    value={getCurrencyVal(
                      1000,
                      data.currency_config,
                      currency.data
                    )}
                    disabled
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <div className={classes.hr} />
        <Grid
          container
          direction="row"
          spacing={0}
          style={{ margin: '10px 0 20px' }}
        >
          <Grid item xs={7}>
            <Box mt="15px" mb="15px">
              <Typography
                color="#000"
                variant="h5"
                component="div"
                className={classes.bktitle}
              >
                Tax Rates
              </Typography>
              <p className={classes.ptext}>
                Customize the existing products and services tax rates and
                create new custom rates for a specific service.
              </p>
            </Box>
          </Grid>
          <Grid item xs={8}>
            <div>
              {taxRates.data.map((row) => (
                <Grid
                  container
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  key={row.id}
                >
                  <Grid item xs>
                    <FormControl
                      control="input"
                      margin="dense"
                      label="Tax rate name"
                      required
                      value={row.name.split(/\d+/)[0]}
                      maxLength={50}
                    />
                  </Grid>
                  <Grid item xs>
                    <FormControl
                      control="input"
                      margin="dense"
                      label="Tax rate (%)"
                      required
                      value={row.rate}
                    />
                  </Grid>
                  {row.lock === 0 && row.id > 3 ? (
                    <Box className={classes.BoxBtnDel}>
                      <DeleteTaxDialog item={row} />
                    </Box>
                  ) : (
                    <Box className={classes.BoxBtnDel}>&nbsp;</Box>
                  )}
                </Grid>
              ))}
              {edit ? (
                <Grid container direction="row" spacing={2} alignItems="center">
                  <Grid item xs>
                    <FormControl
                      control="input"
                      margin="dense"
                      label="Tax rate name"
                      required
                      onChange={(e) =>
                        setTaxedata({ ...Taxedata, name: e.target.value })
                      }
                      value={Taxedata.name}
                      maxLength={50}
                    />
                  </Grid>
                  <Grid item xs>
                    <FormControl
                      control="input"
                      margin="dense"
                      label="Tax rate (%)"
                      required
                      onChange={(e) =>
                        setTaxedata({
                          ...Taxedata,
                          rate: validateFloat(e.target.value)
                        })
                      }
                      value={Taxedata.rate}
                    />
                  </Grid>
                  <Box className={classes.BoxBtnDel}>&nbsp;</Box>
                </Grid>
              ) : null}
              <Box mt="25px">
                {edit ? (
                  <JBButton type="primary" onClick={() => addTax()}>
                    Save
                  </JBButton>
                ) : (
                  <JBButton
                    type="primary"
                    isImg={Plusicon}
                    disabled
                    // onClick={() => setEdit(true)}
                  >
                    Add tax rate
                  </JBButton>
                )}
              </Box>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
const mapToState = (state) => ({
  invoiceConfig: state.SettingReducer,
  gymStep: state.GymSetupReducer.data
});

const mapToDispatch = (dispatch) => {
  return {
    newTax: (values) => dispatch(createNewTax(values)),
    fetchInvoiceSet: () => dispatch(getAllInvoiceSet()),
    openNotify: (message, type) => dispatch(openNoti(message, type)),
    getGymSteps: () => dispatch(getGymSetup())
  };
};

InvoiceConfiguration.propTypes = {
  classes: PropType.object.isRequired,
  invoiceConfig: PropType.object.isRequired,
  newTax: PropType.func.isRequired,
  fetchInvoiceSet: PropType.func.isRequired,
  openNotify: PropType.func.isRequired,
  getGymSteps: PropType.func.isRequired,
  gymStep: PropType.number.isRequired
};

export default connect(
  mapToState,
  mapToDispatch
)(withStyles(styles)(InvoiceConfiguration));
