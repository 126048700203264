import React from 'react';
import PropType from 'prop-types';
import ReducerTypes from 'src/Redux/Reducer.constants';
import {
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  withStyles,
  TableSortLabel
} from '@material-ui/core';

const styles = () => ({
  table: {
    minWidth: '100%',
    wordBreak: 'break-word',
    '& td': {
      padding: '15px'
    }
  },
  tableLink: {
    color: '#01847A',
    fontWeight: '700'
  },
  TableCell: {
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px',
    height: '56px',
    minHeight: '56px',
    padding: '15px',
    backgroundColor: '#F9F9FA'
  },
  Tablemian: {
    maxHeight: '400px'
  }
});

const ClassHistoryTable = (props) => {
  const { values, classes, isActive, setDirection, setSort } = props;
  const {
    sortbyprogram,
    sort_class_date,
    sortlocation,
    sortstarttime,
    sort_class
  } = ReducerTypes;
  return (
    <TableContainer
      component={Paper}
      elevation={0}
      className={classes.Tablemian}
    >
      <Table className={classes.table} stickyHeader aria-label="simple table">
        <TableHead
          style={{ background: '#F9F9FA', backgroundColor: '#F9F9FA' }}
        >
          <TableRow>
            <TableCell className={classes.TableCell}>
              <TableSortLabel
                active={isActive(sort_class_date)}
                direction={setDirection(sort_class_date)}
                onClick={() => setSort(sort_class_date)}
              >
                Class Date
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell}>
              <TableSortLabel
                active={isActive(sortlocation)}
                direction={setDirection(sortlocation)}
                onClick={() => setSort(sortlocation)}
              >
                Location
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell}>
              {' '}
              <TableSortLabel
                active={isActive(sortbyprogram)}
                direction={setDirection(sortbyprogram)}
                onClick={() => setSort(sortbyprogram)}
              >
                Program
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell}>
              {' '}
              <TableSortLabel
                active={isActive(sort_class)}
                direction={setDirection(sort_class)}
                onClick={() => setSort(sort_class)}
              >
                Class
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell}>
              {' '}
              <TableSortLabel
                active={isActive(sortstarttime)}
                direction={setDirection(sortstarttime)}
                onClick={() => setSort(sortstarttime)}
              >
                Start Time
              </TableSortLabel>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {values.map((row) => (
            <TableRow
              hover
              tabIndex={-1}
              key={row.id}
              style={{
                backgroundColor: '#FFF'
              }}
            >
              <TableCell
                style={{
                  width: '9.0909%'
                }}
              >
                {row.class_date}
              </TableCell>
              <TableCell
                style={{
                  width: '9.0909%'
                }}
              >
                {row.location}
              </TableCell>
              <TableCell
                style={{
                  width: '9.0909%'
                }}
              >
                {row.program}
              </TableCell>

              <TableCell
                style={{
                  width: '9.0909%'
                }}
              >
                {row.class}
              </TableCell>
              <TableCell
                style={{
                  width: '9.0909%'
                }}
              >
                {row.start_time}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {values.length === 0 && (
        <p
          style={{
            margin: '10px 15px'
          }}
        >
          No Class History to show...
        </p>
      )}
    </TableContainer>
  );
};
ClassHistoryTable.propTypes = {
  values: PropType.array.isRequired,
  classes: PropType.object.isRequired
};

export default withStyles(styles)(ClassHistoryTable);
