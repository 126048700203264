import React from 'react';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import JBButton from 'src/views/common/JBButton/JBButton';
import PropType from 'prop-types';
import { Grid, Box, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import dcloseicon from '../../../assets/image/closeicon.png';
import styles from './Dialog.style';

function CancelledDiag({ classes, onClose }) {
  // Edit Dialog Box
  return (
    <div>
      <div style={{ width: '280px' }} className={classes.dmainbk}>
        <Grid alignItems="center" container direction="row">
          <Grid item xs>
            <DialogTitle
              id="alert-dialog-title"
              color="primary"
              className={classes.titlebk}
            >
              <h4 className={classes.dtitle}>Cancelled Class</h4>
            </DialogTitle>
          </Grid>

          <Grid item xs={2} style={{ textAlign: 'right', marginRight: '10px' }}>
            <Button className={classes.btns} onClick={() => onClose()}>
              <img alt="img" src={dcloseicon} />
            </Button>
          </Grid>
        </Grid>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {/* <Box display="block">
              <h2>Cancelled Class</h2>
            </Box> */}
            <Typography
              variant="h5"
              gutterBottom
              style={{ color: '#000', textAlign: 'center' }}
            >
              Cancelled Class
            </Typography>
            <div className={classes.warningInfo}>
              <Box display="block">
                <p>Only this class is Cancelled</p>
              </Box>
            </div>
            <div className={classes.d_button}>
              <Box display="block" mt="40px" mb={1}>
                <JBButton type="outlined" fullWidth onClick={() => onClose()}>
                  Close
                </JBButton>
              </Box>
            </div>
          </DialogContentText>
        </DialogContent>
      </div>
    </div>
  );
}

CancelledDiag.propTypes = {
  classes: PropType.object.isRequired,
  onClose: PropType.func.isRequired
};

export default withStyles(styles)(CancelledDiag);
