import { filterList, getData } from './wodReducer.utils';
import WodType from './wod.reducer.constants';
import types from './wod.actionTypes';

const initialValue = {
  tags: null,
  workoutsFilter: {
    page: 1,
    search: '',
    sorting: {},
    customFilter: {},
    tags: [],
    default: {
      default: 1
    }
  },
  workoutsFavFilter: {
    page: 1,
    search: '',
    sorting: {},
    customFilter: {},
    tags: [],
    default: {
      filter_by_star: 1,
      star_connector: 1
    }
  },
  workoutFav: { isFetching: false, data: null, error: null },
  workout: { isFetching: false, data: null, error: null },
  areAllSelected: false
};

const workoutReducer = (state = initialValue, action) => {
  switch (action.type) {
    case types.receiveTags:
      return {
        ...state,
        tags: action.payload
      };

    case types.workoutsfilter:
      return {
        ...state,
        workoutsFilter: filterList(
          state.workoutsFilter,
          action.filter,
          action.data
        )
      };
    case types.workoutsFavfilter:
      return {
        ...state,
        workoutsFavFilter: filterList(
          state.workoutsFavFilter,
          action.filter,
          action.data
        )
      };
    case types.workouts:
      return {
        ...state,
        workout: getData(
          WodType.WODLIST,
          action.actionType,
          state.workout,
          action.payload
        )
      };
    case types.workoutsFav:
      return {
        ...state,
        workoutFav: getData(
          WodType.WODLISTFAV,
          action.actionType,
          state.workoutFav,
          action.payload
        )
      };
    case types.getTags:
      return {
        ...state,
        tags: action.payload
      };
    case types.selectAll:
      return {
        ...state,
        areAllSelected: action.payload
      };
    default:
      return state;
  }
};
export default workoutReducer;
