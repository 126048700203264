import React from 'react';
import PropTypes from 'prop-types';
import { Pie } from 'react-chartjs-2';
import clsx from 'clsx';
import { Box, withStyles } from '@material-ui/core';

const styles = () => ({
  // root: {
  //   height: '100%'
  // }
});

const options = {
  // animation: {
  //   onProgress(animation) {
  //     data.value =
  //       animation.animationObject.currentStep /
  //       animation.animationObject.numSteps;
  //   }
  // },
  labels: {
    render: 'labels',
    position: 'outside'
  },
  layout: { padding: 0 },
  legend: {
    display: false
  },
  maintainAspectRatio: false,
  responsive: true
};

const PieChart = ({ classes, data }) => (
  <Box
    height={300}
    width={300}
    position="relative"
    display="inline-block"
    className={clsx(classes.root)}
  >
    <Pie data={data} options={options} />
  </Box>
);

PieChart.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired
};

export default withStyles(styles)(PieChart);
