import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import PropType from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  withStyles,
  Grid
} from '@material-ui/core';
import queryString from 'query-string';
import { openNoti } from 'src/Redux/global/global.actions';
import { getErrorMsg } from 'src/utils/ErrorHandling';
import dcloseicon from '../../../../../assets/image/closeicon.png';
import JBButton from '../../../../common/JBButton/JBButton';
import Userimage from '../../../../common/Userimage/userimage';
import { ChangeProfilePic } from '../../../../../services/Leadmodule.services';

const styles = () => ({
  dtitle: {
    color: '#292929',
    fontWeight: 'bold',
    fontSize: '33px',
    lineHeight: '26px'
  },
  cutom_select_Label: {
    fontSize: '12px',
    padding: '7px 0'
  },
  Ptext: {
    color: '#7F7F7F',
    fontSize: '14px',
    fontWeight: 'normal',
    lineHeight: '18px',
    letterSpacing: '0.002em',
    margin: '20px 0'
  },
  Imageuploadbk: {
    color: '#292929',
    '& .wrapperimgBox': {
      height: 'auto',
      width: '100%'
    },
    '& .imageUploaderBox': {
      height: '100%',
      minHeight: '360px',
      width: '100%'
    },
    '& .Clicktextinfo': {
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)'
    }
  },
  imgEditBtn: {
    position: 'absolute',
    right: '-2px',
    cursor: 'pointer',
    minWidth: '20px',
    padding: '0',
    borderRadius: '50%',
    marginTop: '2px',
    zIndex: '99',
    color: '#000'
  }
});

function ImageUpload(props) {
  const [cropedImage, setCropedImage] = useState(null);
  const [isprocessing, setprocessing] = useState(false);
  const { classes, handleSave, handleClose, open, userId } = props;
  const location = useLocation();
  const { leadId } = queryString.parse(location.search);

  let NewUserID;
  if (!leadId) {
    NewUserID = userId;
  } else {
    NewUserID = atob(leadId);
  }

  const handleUpload = () => {
    const { OpenNoti } = props;
    setprocessing(true);
    const formData = new FormData();
    formData.append('user_id', NewUserID);
    formData.append('profile_pic', cropedImage);
    ChangeProfilePic(formData)
      .then((res) => {
        handleClose();
        handleSave(res.profile_pic);
        OpenNoti('Successfully Upload the photo', 'info');
        setprocessing(false);
      })
      .catch((err) => {
        setprocessing(false);
        OpenNoti(getErrorMsg(err), 'error');
      });
  };

  return (
    <div className="editimageup">
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick
      >
        <div style={{ width: '548px' }} className={classes.Imageuploadbk}>
          <Grid alignItems="center" container direction="row">
            <Grid item xs>
              <DialogTitle
                id="alert-dialog-title"
                className={classes.titlebk}
                style={{
                  display: 'inline-block',
                  padding: '25px 24px 15px'
                }}
              >
                <h4 className={classes.dtitle}> Image upload</h4>
              </DialogTitle>
            </Grid>
            <Grid
              item
              xs={2}
              style={{ textAlign: 'right', marginRight: '10px' }}
            >
              <Button onClick={handleClose} className={classes.btns}>
                <img alt="img" src={dcloseicon} />
              </Button>
            </Grid>
          </Grid>

          <DialogContent>
            <Grid container style={{ marginTop: '5px' }}>
              <Grid item xs={12}>
                <Userimage
                  className={classes.Imageupload}
                  setImage={(e) => setCropedImage(e)}
                  // imageRef={image}
                  // isuploaded={image !== null}
                />
              </Grid>
            </Grid>
            <p className={classes.Ptext}>
              You can upload a JPG or PNG file with less than 20MB
            </p>
          </DialogContent>
          {cropedImage && (
            <DialogActions
              style={{
                padding: '10px 20px 20px'
              }}
            >
              <JBButton
                type="primary"
                disabled={isprocessing || !cropedImage}
                onClick={handleUpload}
              >
                {isprocessing ? 'Uploading..' : 'Save'}
              </JBButton>
            </DialogActions>
          )}
        </div>
      </Dialog>
    </div>
  );
}

ImageUpload.propTypes = {
  classes: PropType.object.isRequired,
  handleSave: PropType.func.isRequired,
  OpenNoti: PropType.func.isRequired,
  open: PropType.bool.isRequired,
  handleClose: PropType.func.isRequired,
  userId: PropType.string.isRequired
};

const mapDispatchToProps = (dispatch) => ({
  OpenNoti: (msg, type) => dispatch(openNoti(msg, type))
});

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(ImageUpload));
