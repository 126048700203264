// eslint-disable-next-line
export const Diags = {
  EDIT_CLASS: 0,
  TIME_CHANGE: 1,
  DELETE_CLASS: 2,
  DELETED_CLASS: 3,
  CANCEL_CLASS: 4,
  COACH_SETTING: 5,
  RESERVATION_SETTING: 6
};
