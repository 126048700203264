import { backendDate } from 'src/utils/someCommon';

export const tobeFilters = [
  { id: 1, filter: 'Date Range' },
  { id: 2, filter: 'Program' }
];
export const NextdayFilter = [
  { id: 'all_time', filter: 'All Time' },
  { id: 'custom', filter: 'Custom' },
  { id: 'thisyear', filter: 'This Year' },
  { id: 'lastyear', filter: 'Last Year' },
  { id: 'thismonth', filter: 'This Month' },
  { id: 'lastmonth', filter: 'Last Month' },
  { id: 'thisweek', filter: 'This Week' },
  { id: 'lastweek', filter: 'Last Week' },
  { id: 'today', filter: 'Today' },
  { id: 'yesterday', filter: 'Yesterday' },
  { id: 'last30day', filter: 'Last 30 Days' },
  { id: 'last60day', filter: 'Last 60 Days' }
];

export const checkData = (filter, data, paymentdue, endDate) => {
  if (!filter) {
    return true;
  }
  if (filter?.id === 2 && !data) {
    return true;
  }
  if (filter?.id === 1) {
    if (!paymentdue) {
      return true;
    }
    if (paymentdue?.id === 'custom' && !data) return true;
    if (paymentdue?.id === 'custom' && !endDate) {
      return true;
    }
  }
  return false;
};

export const getFilter = (filter, dateFilter, data, endDate) => {
  switch (filter.id) {
    case 1:
      if (dateFilter.id === 'custom') {
        return {
          date_range: 'custom',
          custom: backendDate(data) + '-' + backendDate(endDate)
        };
      }
      return {
        date_range: dateFilter.id
      };

    case 2:
      return {
        programe_id: data.id
      };
    default:
      return null;
  }
};
export const getfilterLabel = (filter, dateFilter, data, endDate) => {
  switch (filter.id) {
    case 1:
      if (dateFilter?.id === 'custom') {
        return {
          id: filter?.id,
          name: `Date range is ${backendDate(data)
            + '-'
            + backendDate(endDate)}`,
          filter: 'date_range'
        };
      }
      return {
        id: filter?.id,
        name: `Date range is ${dateFilter.filter}`,
        filter: 'date_range'
      };
    case 2:
      return {
        id: filter.id,
        name: `Program is ${data.name}`,
        filter: 'programe_id'
      };

    default:
      return null;
  }
};
