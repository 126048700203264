import Types from './SettingReducer.types';

// eslint-disable-next-line
export const getSetting = (type, action, state, data) => {
  switch (type) {
    case Types.getTaxRates: {
      const Data = () => {
        switch (action) {
          case Types.isFetching:
            return {
              ...state,
              isFetching: true
            };
          case Types.fetchSucess:
            return {
              ...state,
              error: null,
              data
            };
          case Types.Errorfetch:
            return {
              ...state,
              error: data
            };
          default:
            return state;
        }
      };
      return Data();
    }
    case Types.getInvoiceSetting: {
      const Data = () => {
        switch (action) {
          case Types.isFetching:
            return {
              ...state,
              isFetching: true
            };
          case Types.fetchSucess:
            return {
              ...state,
              error: null,
              data
            };
          case Types.Errorfetch:
            return {
              ...state,
              error: data
            };
          default:
            return state;
        }
      };
      return Data();
    }
    case Types.getDateformat: {
      const Data = () => {
        switch (action) {
          case Types.isFetching:
            return {
              ...state,
              isFetching: true
            };
          case Types.fetchSucess:
            return {
              ...state,
              error: null,
              data
            };
          case Types.Errorfetch:
            return {
              ...state,
              error: data
            };
          default:
            return state;
        }
      };
      return Data();
    }
    case Types.getCurrencyRates: {
      const Data = () => {
        switch (action) {
          case Types.isFetching:
            return {
              ...state,
              isFetching: true
            };
          case Types.fetchSucess:
            return {
              ...state,
              error: null,
              data
            };
          case Types.Errorfetch:
            return {
              ...state,
              error: data
            };
          default:
            return state;
        }
      };
      return Data();
    }
    case Types.getContracts: {
      const Data = () => {
        switch (action) {
          case Types.isFetching:
            return {
              ...state,
              isFetching: true
            };
          case Types.fetchSucess:
            return {
              ...state,
              error: null,
              data
            };
          case Types.Errorfetch:
            return {
              ...state,
              error: data
            };
          default:
            return state;
        }
      };
      return Data();
    }
    case Types.getRevCtgry: {
      const Data = () => {
        switch (action) {
          case Types.isFetching:
            return {
              ...state,
              isFetching: true
            };
          case Types.fetchSucess:
            return {
              ...state,
              error: null,
              data
            };
          case Types.Errorfetch:
            return {
              ...state,
              error: data
            };
          default:
            return state;
        }
      };
      return Data();
    }
    case Types.getGymloc: {
      const Data = () => {
        switch (action) {
          case Types.isFetching:
            return {
              ...state,
              isFetching: true
            };
          case Types.fetchSucess:
            return {
              ...state,
              error: null,
              data
            };
          case Types.Errorfetch:
            return {
              ...state,
              error: data
            };
          default:
            return state;
        }
      };
      return Data();
    }
    case Types.getinvoicefooter: {
      const Data = () => {
        switch (action) {
          case Types.isFetching:
            return {
              ...state,
              isFetching: true
            };
          case Types.fetchSucess:
            return {
              ...state,
              error: null,
              data
            };
          case Types.Errorfetch:
            return {
              ...state,
              error: data
            };
          default:
            return state;
        }
      };
      return Data();
    }

    case Types.getAllCountries: {
      const Data = () => {
        switch (action) {
          case Types.isFetching:
            return {
              ...state,
              isFetching: true
            };
          case Types.fetchSucess:
            return {
              ...state,
              error: null,
              data
            };
          case Types.Errorfetch:
            return {
              ...state,
              error: data
            };
          default:
            return state;
        }
      };
      return Data();
    }
    case Types.getPaymentPlans: {
      const Data = () => {
        switch (action) {
          case Types.isFetching:
            return {
              ...state,
              isFetching: true
            };
          case Types.fetchSucess:
            return {
              ...state,
              error: null,
              data
            };
          case Types.Errorfetch:
            return {
              ...state,
              error: data
            };
          default:
            return state;
        }
      };
      return Data();
    }

    case Types.getRoles: {
      const Data = () => {
        switch (action) {
          case Types.isFetching:
            return {
              ...state,
              isFetching: true
            };
          case Types.fetchSucess:
            return {
              ...state,
              error: null,
              data: data.data,
              isFetching: false
            };
          case Types.Errorfetch:
            return {
              ...state,
              error: data
            };
          default:
            return state;
        }
      };
      return Data();
    }
    default:
      return state;
  }
};
