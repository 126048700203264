import React, { useState, useContext } from 'react';
import { FilterContext } from 'src/views/common/ApplyFilter/applyFilter';
import PropType from 'prop-types';
import { connect } from 'react-redux';
import { ThemeProvider, withStyles } from '@material-ui/core';
import JBButton from 'src/views/common/JBButton/JBButton';
import { getValuefromObject } from 'src/utils/someCommon';
import CustomAutoComplete from 'src/views/common/CustomAutocomplete/commonAutocomplete';
import { TobeFilter, ReccFilter } from './static.Data';
import { getFilter, getfilterLabel } from './classFilters.utils';

const themeInstance = {
  overrides: {
    // '& .filterAutoComplete': {
    //   minHeight: '38px'
    // }
  }
};

const styles = () => ({
  filterAutoComplete: {
    minHeight: '38px'
  }
});

const ReccurFilters = (props) => {
  const { getPrograms, getFilData } = props;
  const [data, setData] = useState(null);
  const [filter, setFilter] = useState(null);
  const [open, setOpen] = useContext(FilterContext);
  const filteredData = TobeFilter.filter((fil) => fil.id === filter)[0];

  return (
    <div>
      <ThemeProvider theme={themeInstance}>
        <CustomAutoComplete
          holder="Select Filter"
          value={ReccFilter}
          Options="filter"
          data={filteredData}
          Change={(val) => setFilter(getValuefromObject(val))}
          // className={classes.filterAutoComplete}
          style={{
            marginBottom: '15px'
            // borderRadius: '10px'
            // minHeight: '38px'
            // '& div': {
            //   minHeight: '38px'
            // }
          }}

          // className="filterAutoComplete"
        />
        <div>
          {(() => {
            switch (filter) {
              case 3:
                return (
                  <CustomAutoComplete
                    holder="Select Program"
                    value={getPrograms}
                    data={data || null}
                    Options="name"
                    Change={(val) => setData(val)}
                    style={{ marginBottom: '15px' }}
                  />
                );

              default:
                return null;
            }
          })()}
        </div>
        <JBButton
          type="primary"
          disabled={!filter || !data}
          onClick={() => {
            getFilData(getFilter(filter, data), getfilterLabel(filter, data));
            setOpen(!open);
          }} // eslint-disable-line
          style={{
            width: '100%',
            minHeight: '22px',
            padding: '7px 25px',
            marginTop: '10px'
          }}
        >
          Apply Filter
        </JBButton>
      </ThemeProvider>
    </div>
  );
};
ReccurFilters.propTypes = {
  getPrograms: PropType.array.isRequired,
  getFilData: PropType.func.isRequired
};

const mapToState = (state) => ({
  getPrograms: state.allprograms.programs
});
export default withStyles(styles)(connect(mapToState)(ReccurFilters));
