/* eslint-disable indent */
/* eslint-disable no-unused-expressions */
import React, { useEffect, useReducer, memo, useCallback } from 'react';
import { ROUTES } from 'src/constant/Constants';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Grid,
  withStyles,
  Card,
  Breadcrumbs,
  Typography,
  Box,
  InputLabel,
  FormControlLabel
} from '@material-ui/core';
import TimeFormats from 'src/constant/timeformats';
import IOSSwitch from 'src/views/common/FormControl/IOSSwitch';
import { useDispatch, useSelector } from 'react-redux';
import FormControl from 'src/views/common/FormControl/formControl';
import { debounce } from 'lodash';
import { openNoti } from 'src/Redux/global/global.actions';
import { updateSettingsByModule } from 'src/services/Setting.services';
import { getAllGymSet } from 'src/Redux/SettingReducer/SettingReducer.actions';
import { getErrorMsg } from 'src/utils/ErrorHandling';
import { getGymSetup } from 'src/Redux/GymSetupReducer/GymSetup.actions';
import useChangeTitle from 'src/views/common/CustomHooks/useTitleChange';
import {
  GymdateFormat as dateFormat,
  Gymagelimit as agelimit,
  GymweekDay as weekDay,
  GymTimeFormat
} from './GymSetting.data';
import CustomAutoComplete from '../../common/CustomAutocomplete/commonAutocomplete';
import styles from './GymSettings.style';
import { GymReducer, initialState, GymReducerType } from './GymReducer';
import { GetGymData, validateGymData } from './GymSettings.utils';
import {
  updateGymData,
  updateGymDate,
  updateGymTime,
  updateGymTimezone,
  updateGymAge,
  updateGymWeek
} from './GymReducer.actions';

const GymSettings = ({ classes }) => {
  const reduxState = useSelector((state) => state.SettingReducer.date);
  const reduxDispatch = useDispatch();
  useChangeTitle('Gym Settings | Jungle Alliance');
  const [state, dispatch] = useReducer(GymReducer, initialState);
  const {
    data: {
      date_format,
      hours_format,
      time_zone,
      hours_value,
      week_day,
      age_limit
    }
  } = state;

  useEffect(() => {
    if (reduxState.data) {
      dispatch(updateGymData(GetGymData(reduxState)));
    }
  }, [reduxState]);

  const updateAPI = useCallback(
    debounce((val, type, statedata) => {
      const { error, data } = validateGymData(val, type, statedata);
      if (error) {
        reduxDispatch(openNoti(error, 'error'));
      } else {
        updateSettingsByModule('gym', data)
          .then(() => {
            reduxDispatch(openNoti('Successfully Saved your settings'));
            reduxDispatch(getAllGymSet());
          })
          .then(() => reduxDispatch(getGymSetup()))
          .catch((err) => reduxDispatch(openNoti(getErrorMsg(err), 'error')));
      }
    }, 400),
    []
  );

  const handleChange = (type, func, value) => {
    dispatch(func(value));
    updateAPI(value, type, state);
  };

  return (
    <div className={classes.root}>
      <div className="title-bk">
        <div style={{ marginBottom: '15px' }}>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={0}
          >
            <Grid item xs={12}>
              <Breadcrumbs aria-label="breadcrumb">
                <Link
                  color="inherit"
                  to={ROUTES.GYM_GENERAL_INFO_INFOLOCATIONS}
                >
                  Gym
                </Link>
                <Typography variant="h3" color="Primary">
                  <span>Settings</span>
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
        </div>
      </div>

      <Card className={classes.mainCard}>
        <Grid
          container
          direction="row"
          spacing={0}
          justify="flex-start"
          alignItems="center"
        >
          <Grid item xs={3} alignItems="flex-end">
            <Box>
              <Typography
                color="#000"
                variant="h5"
                component="h5"
                className={classes.bktitle}
                style={{ margin: '0 0', padding: '15px 0 0' }}
              >
                Date & Time Format
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={9}>
            <Grid
              container
              direction="row"
              spacing={2}
              justify="flex-start"
              alignItems="center"
            >
              <Grid item xs={3}>
                <InputLabel
                  htmlFor="age-required"
                  className={classes.cutom_select_Label}
                  style={{ padding: '0px 0px 6px' }}
                >
                  Date format
                </InputLabel>
                <CustomAutoComplete
                  data={date_format}
                  value={dateFormat}
                  Change={(val) =>
                    handleChange(
                      GymReducerType.updateDateFmt,
                      updateGymDate,
                      val
                    )
                  }
                  Options="value"
                  disableClearable
                />
              </Grid>
              <Grid item xs={3} alignItems="flex-end">
                <Box mt="15px">
                  <FormControlLabel
                    control={
                      <IOSSwitch
                        checked={hours_format === GymTimeFormat.twentyfour}
                        onChange={() =>
                          handleChange(
                            GymReducerType.updateTimeFmt,
                            updateGymTime,
                            hours_format
                          )
                        }
                      />
                    }
                    label="Use 24 hour time"
                    style={{ margin: '0 0' }}
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                {/* if input is disabled  */}
                <Box className={classes.inputdisabled}>
                  <FormControl
                    margin="dense"
                    control="input"
                    label="Example"
                    value={hours_value}
                    disabled
                    style={{ margin: '0 0' }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <div className={classes.hr} />
        <Grid
          container
          direction="row"
          spacing={0}
          style={{ margin: '10px 0 0px' }}
        >
          <Grid item xs={7}>
            <Box mt="5px" mb="15px">
              <Typography
                color="#000"
                variant="h5"
                component="h5"
                className={classes.bktitle}
              >
                Timezone
              </Typography>
              <p className={classes.ptext}>
                Set the timezone for your environment. Members can select a
                different timezone for their individual account.
              </p>
            </Box>
          </Grid>
          <Grid item xs={5} className={classes.TimezoneGrid}>
            <Grid
              container
              direction="row"
              spacing={0}
              justify="flex-end"
              alignItems="center"
            >
              <Grid item xs={11} lg={11}>
                <InputLabel
                  htmlFor="age-required"
                  className={classes.cutom_select_Label}
                  style={{ padding: '0px 0px 6px' }}
                  required
                >
                  Time zone
                </InputLabel>
                <CustomAutoComplete
                  data={time_zone}
                  value={TimeFormats}
                  Change={(val) =>
                    handleChange(
                      GymReducerType.updateTimeZone,
                      updateGymTimezone,
                      val
                    )
                  }
                  Options="name"
                  disableClearable
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <div className={classes.hr} />
        <Grid
          container
          direction="row"
          spacing={0}
          style={{ margin: '10px 0 0px' }}
        >
          <Grid item xs={7}>
            <Box mt="5px" mb="15px">
              <Typography
                color="#000"
                variant="h5"
                component="h5"
                className={classes.bktitle}
              >
                Start Week On
              </Typography>
              <p className={classes.ptext}>
                Select the first day of the week to be displayed on all
                calendars. This selection will not change when members are
                billed.
              </p>
            </Box>
          </Grid>
          <Grid item xs={5}>
            <Grid
              container
              direction="row"
              justify="flex-end"
              alignItems="center"
              spacing={0}
            >
              <Grid
                item
                xs
                style={{
                  maxWidth: '186px'
                }}
              >
                <InputLabel
                  htmlFor="age-required"
                  className={classes.cutom_select_Label}
                  style={{ padding: '0px 0px 6px' }}
                  required
                >
                  First day of week
                </InputLabel>
                <CustomAutoComplete
                  data={week_day}
                  value={weekDay}
                  Change={(val) =>
                    handleChange(
                      GymReducerType.updateweekday,
                      updateGymWeek,
                      val
                    )
                  }
                  Options="value"
                  disableClearable
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <div className={classes.hr} />

        <Grid
          container
          direction="row"
          spacing={0}
          style={{ margin: '10px 0 0px' }}
        >
          <Grid item xs={7}>
            <Box mt="5px" mb="15px">
              <Typography
                color="#000"
                variant="h5"
                component="h5"
                className={classes.bktitle}
              >
                Age of Majority
              </Typography>
              <p className={classes.ptext}>
                This is the age when member can sign a contract or waiver
                without a parent/guardian.
              </p>
            </Box>
          </Grid>
          <Grid item xs={5}>
            <Grid
              container
              direction="row"
              justify="flex-end"
              alignItems="center"
              spacing={0}
            >
              <Grid
                item
                xs
                style={{
                  maxWidth: '186px'
                }}
              >
                <InputLabel
                  htmlFor="age-required"
                  className={classes.cutom_select_Label}
                  style={{ padding: '0px 0px 6px' }}
                  required
                >
                  Age
                </InputLabel>
                <CustomAutoComplete
                  data={age_limit}
                  value={agelimit}
                  Change={(val) =>
                    handleChange(
                      GymReducerType.updateageMature,
                      updateGymAge,
                      val
                    )
                  }
                  Options="value"
                  disableClearable
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};

GymSettings.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(memo(GymSettings));
