/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  Hidden,
  Button,
  // IconButton,
  Toolbar,
  makeStyles,
  ListItem
} from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
// import MenuIcon from '@material-ui/icons/Menu';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import Logo from 'src/components/Logo';
import { getlogOut } from 'src/services/auth.services';
import { ReactComponent as MenuIconleft } from 'src/assets/image/arrow-left.svg';
import { ReactComponent as MenuIcon } from 'src/assets/image/menu.svg';
import { ROUTES } from 'src/constant/Constants';

const TopBar = ({
  toggleMenu,
  MiniActive,
  className,
  currentUser,
  ...rest
}) => {
  const Location = useLocation();
  const useStyles = makeStyles((theme) => ({
    root: {
      padding: 20,
      color: Location.pathname.includes('masterhq') ? '#fff' : '#000',
      backgroundColor: Location.pathname.includes('masterhq')
        ? theme.masterhq.root.backgroundColor
        : '#f9f9fa'
    },
    avatar: {
      width: 40,
      height: 40,
      cursor: 'pointer'
    },
    search: {
      position: 'relative',
      height: '48px',
      border: Location.pathname.includes('masterhq')
        ? '1px solid #A9A9A9'
        : '0',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: Location.pathname.includes('masterhq')
        ? 'rgba(255, 255, 255, 0.1)'
        : '#EAEAEA',
      '&:hover': {
        backgroundColor: Location.pathname.includes('masterhq')
          ? 'rgba(255, 255, 255, 0.1)'
          : '#EAEAEA'
      },
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto'
      }
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      right: '0',
      color: '#01847A'
    },
    inputRoot: {
      color: 'inherit',
      height: 'inherit'
    },
    inputInput: {
      padding: '6px 20px 6px !important;',
      // vertical padding + font size from searchIcon
      paddingRight: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      width: '100%',
      minWidth: '285px',
      fontWeight: 'bold',
      fontSize: '14px',
      lineHeight: '18px',
      '&::placeholder': {
        color: '#545454',
        fontSize: '14px',
        fontWeight: '700',
        opacity: '1'
      },
      [theme.breakpoints.up('sm')]: {
        width: '12ch',
        '&:focus': {
          // width: '20ch',
        }
      }
    },
    MenuListItem: {
      listStyle: 'none',
      color: '#545454',
      width: 'auto',
      overflow: 'hidden',
      fontSize: '14px',
      boxSizing: 'border-box',
      minHeight: '40px',
      fontFamily: 'Lato',
      fontWeight: '700',
      lineHeight: '1.5',
      whiteSpace: 'nowrap',
      padding: '10px 15px 10px',
      cursor: 'pointer',
      '&:hover': {
        color: '#01847A',
        backgroundColor: 'rgba(1 132 122 / 0.1)'
      }
    },
    Avataruname: {
      fontWeight: 'bold',
      backgroundColor: '#CCE6E4',
      color: '#37847a',
      fontSize: '1.1rem',
      cursor: 'pointer'
    },
    MenuMiniBtn: {
      position: 'absolute',
      left: '5px',
      width: '48px',
      minWidth: '48px',
      height: '48px',
      background: 'rgb(1, 132, 122)',
      boxShadow: 'rgba(0, 126, 255, 0.16) 0px 8px 16px',
      borderRadius: '50%',
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      '&:hover': {
        textDecoration: 'none',
        backgroundColor: 'rgb(1, 132, 122)'
      }
    }
  }));

  const classes = useStyles();
  // const [notifications] = useState([]);
  const [open, setOpen] = useState(false);
  // const [isMiniActive, setMiniActive] = useState(true);
  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }
  const anchorRef = React.useRef(null);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };
  const handleLogout = () => {
    setOpen(false);
    getlogOut()
      .then(() => {
        return (window.location = '/login'); // eslint-disable-line
      })
      .catch(() => {
        const token = localStorage.getItem('x-token');
        const refresh = localStorage.getItem('qid');
        if (!token || refresh) {
          window.location = '/login';
        } else {
          console.warn('Something went wrong');
        }
      });
  };

  return (
    <AppBar className={clsx(classes.root)} elevation={0} {...rest}>
      <Toolbar>
        {Location.pathname.includes('chalkboard') ? null : (
          <Button
            onClick={toggleMenu}
            variant="outlined"
            className={classes.MenuMiniBtn}
          >
            {MiniActive ? <MenuIconleft /> : <MenuIcon />}
          </Button>
        )}

        <RouterLink
          to="/"
          style={{
            marginLeft: Location.pathname.includes('chalkboard') ? null : '40px'
          }}
        >
          <Logo
            style={{
              filter: Location.pathname.includes('masterhq')
                ? 'invert(1)'
                : null
            }}
          />
        </RouterLink>
        <Box flexGrow={1} />
        <Hidden xsDown>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput
              }}
              inputProps={{ 'aria-label': 'search' }}
            />
          </div>
          <div className="user-info">
            <h4>{currentUser?.first_name}</h4>
            {/* <Avatar
              alt={currentUser.first_name}
              onClick={handleToggle}
              src={currentUser.profile_pic}
              className={classes.avatar}
            /> */}
            {currentUser.profile_pic ? (
              <Avatar
                alt={currentUser?.first_name}
                onClick={handleToggle}
                src={currentUser?.profile_pic}
                className={classes.avatar}
              />
            ) : (
              <Avatar className={classes.Avataruname} onClick={handleToggle}>
                {currentUser?.first_name[0] + ' ' + currentUser?.last_name[0]}
              </Avatar>
            )}
            <div style={{ position: 'relative' }}>
              <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                onMouseLeave={() => setOpen(false)}
                transition
                disablePortal
                style={{
                  top: '75px',
                  right: '60px',
                  left: 'auto',
                  minWidth: '130px'
                }}
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps} // eslint-disable-line
                    style={{
                      transformOrigin:
                        placement === 'bottom' ? 'center top' : 'center bottom'
                    }}
                  >
                    <Paper>
                      <ul
                        autoFocusItem={open}
                        id="menu-list-grow"
                        onKeyDown={handleListKeyDown}
                      >
                        <ListItem
                          onClick={handleClose}
                          className={classes.MenuListItem}
                          component={RouterLink}
                          to={{
                            pathname: ROUTES.PEOPLE_MEMBER_PROFILE,
                            search: `memberId=${btoa(currentUser.id)}`
                          }}
                        >
                          My Profile
                        </ListItem>
                        <li
                          onClick={handleLogout}
                          className={classes.MenuListItem}
                        >
                          Logout
                        </li>
                      </ul>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </div>
          </div>
        </Hidden>
        <Hidden xsUp>
          {/* <IconButton color="inherit" onClick={onMobileNavOpen}>
            <MenuIcon />
          </IconButton> */}
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string.isRequired,
  // onMobileNavOpen: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  MiniActive: PropTypes.bool.isRequired,
  toggleMenu: PropTypes.func.isRequired
};

export default TopBar;
