import React, { useState } from 'react';
import PropType from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  DialogTitle,
  withStyles
} from '@material-ui/core';
import X from 'src/assets/image/x.png';
import { refundInvoice } from 'src/services/masterHQ.invoices.services';
import { useDispatch } from 'react-redux';
import { openNoti } from 'src/Redux/global/global.actions';
import { getErrorMsg } from 'src/utils/ErrorHandling';

const styles = (theme) => ({
  Deletebtn: {
    background: '#FB4E4E',
    boxShadow: '0px 8px 16px rgba(251, 78, 78, 0.16)',
    borderRadius: '2px',
    height: '48px',
    padding: '10px 15px',
    color: '#fff',
    fontWeight: '700',
    '&:hover': {
      background: '#FB4E4E'
    }
  },
  bodytxt: {
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '0.002em',
    color: '#7F7F7F',
    padding: '10px 0'
  },
  titlebk: {
    padding: '16px 24px 5px',
    color: '#fff'
  },
  DialogPopup: {
    '& .MuiPaper-root': {
      background: theme.masterhq.mainCard.background
    }
  }
});

function RefundInvoiceDialog(props) {
  const {
    classes,
    setOpen,
    handleClose,
    amount,
    paymentMeth,
    updateData
  } = props;
  const [processing, setprocessing] = useState(false);
  const dispatch = useDispatch();
  const defautltMethod = paymentMeth?.filter(
    (p) => p.default_method === 1 && p.payment_method === 1
  )[0];
  const handleRefund = () => {
    const { id } = props;
    setprocessing(true);
    refundInvoice(id)
      .then((res) => {
        updateData(res);
        dispatch(openNoti('Successfully refunded the amount'));
        setprocessing(false);
      })
      .then(() => handleClose())
      .catch((err) => {
        dispatch(openNoti(getErrorMsg(err), 'error'));
        setprocessing(false);
      });
  };
  return (
    <div>
      <Dialog
        open={setOpen}
        onClose={() => handleClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.DialogPopup}
      >
        <div style={{ width: 394 }}>
          <DialogTitle id="alert-dialog-title" className={classes.titlebk}>
            <img
              alt="Plusicon"
              src={X}
              style={{ display: 'inline-block', verticalAlign: 'sub' }}
            />
            <Typography
              color="#000"
              variant="h5"
              component="div"
              className={classes.title}
              style={{ display: 'inline-block', marginLeft: '10px' }}
            >
              Refund invoice {amount}{' '}
              {defautltMethod?.card_view
                ? `(${defautltMethod.card_view})`
                : null}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <p className={classes.bodytxt}>
              Are you sure you want to refund invoice?
            </p>
          </DialogContent>
          <DialogActions
            style={{
              padding: '5px 20px 20px'
            }}
          >
            <Button
              variant="outlined"
              onClick={handleClose}
              style={{
                height: '48px',
                color: '#7F7F7F',
                fontWeight: 'bold'
              }}
            >
              Cancel
            </Button>
            <Button
              className={classes.Deletebtn}
              onClick={handleRefund}
              disabled={processing}
            >
              {processing ? 'Refunding..' : 'Refund'}
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}

RefundInvoiceDialog.propTypes = {
  classes: PropType.object.isRequired,
  setOpen: PropType.bool.isRequired,
  handleClose: PropType.func.isRequired,
  id: PropType.number.isRequired,
  amount: PropType.number.isRequired,
  updateData: PropType.func.isRequired,
  paymentMeth: PropType.array.isRequired
};
export default withStyles(styles)(RefundInvoiceDialog);
