// eslint-disable-next-line
import { checkValidString } from 'src/utils/validations';
export const deleteMcqAns = (arr, arrid, id) => {
  const result = [];
  for (let i = 0; i < arr.length; i += 1) {
    if (arr[i].id === arrid) {
      const loc = [];
      for (let j = 0; j < arr[i].answer_choices.length; j += 1) {
        if (arr[i].answer_choices[j].id !== id) {
          loc.push(arr[i].answer_choices[j]);
        }
      }
      const fin = { ...arr[i], answer_choices: loc };
      result.push(fin);
    } else {
      result.push(arr[i]);
    }
  }
  return result;
};

export const jsonToHtml = (questions) => {
  let final_string = '';
  const div = (element) => {
    return '<div>' + element + '</div>';
  };
  for (let i = 0; i < questions.length; i += 1) {
    switch (questions[i].que_type) {
      case 'Yes/No':
        if (questions[i].required === 1) {
          if (questions[i].ans === 1) {
            final_string += div(
              '<p>'
                + questions[i].que
                + '*</p><input type="radio" checked><label >Yes</label><br><input type="radio"><label >No</label><br>'
            );
          } else {
            final_string += div(
              '<p>'
                + questions[i].que
                + '*</p><input type="radio"><label >Yes</label><br><input checked type="radio"><label >No</label><br>'
            );
          }
        } else if (questions[i].ans === 1) {
          final_string += div(
            '<p>'
              + questions[i].que
              + '</p><input type="radio" checked><label >Yes</label><br><input type="radio"><label >No</label><br>'
          );
        } else if (questions[i].ans === 0) {
          final_string += div(
            '<p>'
              + questions[i].que
              + '</p><input type="radio"><label >Yes</label><br><input checked type="radio"><label >No</label><br>'
          );
        } else {
          final_string += div(
            '<p>'
              + questions[i].que
              + '</p><input type="radio"><label >Yes</label><br><input type="radio"><label >No</label><br>'
          );
        }

        break;
      case 'Freeform Answer Box':
        if (questions[i].required === 1) {
          final_string += div(
            '<p>'
              + questions[i].que
              + '*</p><textarea>'
              + questions[i].ans
              + '</textarea>'
          );
        } else {
          final_string += div(
            '<p>'
              + questions[i].que
              + '</p><textarea>'
              + questions[i].ans
              + '</textarea>'
          );
        }

        break;
      case 'Multiple Choice':
        if (questions[i].required === 1) {
          final_string = final_string + '<p>' + questions[i].que + '*</p>';
        } else {
          final_string = final_string + '<p>' + questions[i].que + '</p>';
        }

        // eslint-disable-next-line no-case-declarations
        let local = '';
        //  eslint-disable-next-line no-case-declarations
        let not = 0;
        if (questions[i].allow_multiple_ans === 0) {
          for (let j = 0; j < questions[i].answer_choices.length; j += 1) {
            if (questions[i].answer_choices[j].id === questions[i].ans) {
              console.warn(questions[i]);
              local = local
                + '<input type="checkbox" checked><label >'
                + questions[i].answer_choices[j].value
                + '</label><br>';
            } else {
              local = local
                + '<input type="checkbox" ><label >'
                + questions[i].answer_choices[j].value
                + '</label><br>';
            }
          }
          final_string += local;
        } else {
          for (let k = 0; k < questions[i].answer_choices.length; k += 1) {
            for (let j = 0; j < questions[i].ans.length; j += 1) {
              console.warn(
                questions[i].ans.length,
                questions[i].answer_choices[k].id === questions[i].ans[j],
                questions[i].answer_choices[k].id,
                questions[i].ans[j]
              );
              if (questions[i].answer_choices[k].id === questions[i].ans[j]) {
                local = local
                  + '<input type="checkbox" checked><label >'
                  + questions[i].answer_choices[k].value
                  + '</label><br>';
                not = 1;
                break;
              } else not = 0;
            }
            if (not === 0) {
              local = local
                + '<input type="checkbox" ><label >'
                + questions[i].answer_choices[k].value
                + '</label><br>';
              not = 0;
            }
          }
          final_string += local;
        }
        break;
      case 'Yes/No with follow-up':
        if (questions[i].required === 1) {
          if (questions[i].ans === 1) {
            final_string += div(
              '<p>'
                + questions[i].que
                + '*</p><input type="radio"  checked><label >Yes</label><br><input type="radio"><label >No</label><br>'
                + div(
                  '<p>'
                    + questions[i].follow_up
                    + '*</p><textarea>'
                    + questions[i].followup_ans
                    + '</textarea>'
                )
            );
          } else {
            final_string += div(
              '<p>'
                + questions[i].que
                + '</p><input type="radio"><label >Yes</label><br><input checked type="radio"><label >No</label><br>'
                + div(
                  '<p>'
                    + questions[i].follow_up
                    + '</p><textarea>'
                    + questions[i].followup_ans
                    + '</textarea>'
                )
            );
          }
        } else if (questions[i].ans === 1) {
          final_string += div(
            '<p>'
              + questions[i].que
              + '</p><input type="radio"  checked><label >Yes</label><br><input type="radio"><label >No</label><br>'
              + div(
                '<p>'
                  + questions[i].follow_up
                  + '</p><textarea>'
                  + questions[i].followup_ans
                  + '</textarea>'
              )
          );
        } else if (questions[i].ans === 0) {
          final_string += div(
            '<p>'
              + questions[i].que
              + '</p><input type="radio"><label >Yes</label><br><input checked type="radio"><label >No</label><br>'
              + div(
                '<p>'
                  + questions[i].follow_up
                  + '</p><textarea>'
                  + questions[i].followup_ans
                  + '</textarea>'
              )
          );
        } else {
          final_string += div(
            '<p>'
              + questions[i].que
              + '</p><input type="radio"><label >Yes</label><br><input type="radio"><label >No</label><br>'
              + div(
                '<p>'
                  + questions[i].follow_up
                  + '</p><textarea>'
                  + questions[i].followup_ans
                  + '</textarea>'
              )
          );
        }

        break;
      default:
        break;
    }
  }
  return final_string;
};

export const getValidStep1 = (data) => {
  if (!data.name) {
    return true;
  }
  return false;
};

export const getValidStep2 = (data) => {
  if (!data.waiver_terms_meta.terms) {
    return true;
  }
  return false;
};

export const getValidStep4 = (data) => {
  if (!data.email_template.subject || !data.email_template.body) {
    return true;
  }
  return false;
};
