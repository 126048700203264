import { checkValidString } from '../../../utils/validations';
import { validateMeta } from '../MembershipTemplate/MemTemplate.utils';

// eslint-disable-next-line
export const editPlanValidate = (data) => {
  if (checkValidString(data.name)) {
    return { error: 'Please specify valid name' };
  }
  if (!data.attendance_limit) {
    return { error: 'Please select attendance limit' };
  }
  if (data.attendance_limit && data.attendance_limit.id === 1) {
    if (
      data.attendance_limited_meta.uses.length === 0
      || !data.attendance_limited_meta.uses
    ) {
      return { error: 'Please specify valid attendance limit uses' };
    }
    if (!data.attendance_limited_meta.type) {
      return { error: 'Please select attendance limit type' };
    }
  }
  if (!data.revCat) {
    return { error: 'Please select revenue category' };
  }
  if (data.desc && data.desc.trim().length > 0 && checkValidString(data.desc)) {
    return { error: 'Please specify valid Desciption' };
  }
  if (data.programs.length === 0) {
    return { error: 'Please Select atleast one Program' };
  }
  if (data.payments.length === 0) {
    return { error: 'Please create atleast one payment plan' };
  }
  if (data.contract_id.length === 0) {
    return { error: 'Please Select the Contract' };
  }
  return { value: data };
};

export const getUpdateData = (data) => {
  let value = {
    membership_type: 'plan',
    name: data.name,
    reve_cat_id: data.revCat.id,
    attendance_limit: data.attendance_limit.id,
    program_ids: data.programs.join(','),
    meta: JSON.stringify(validateMeta(data.payments)),
    contract_id: data.contract_id,
    description: null
  };
  if (data.desc && data.desc.trim().length > 0) {
    value = {
      ...value,
      description: data.desc
    };
  }
  if (data.attendance_limit.id === 1) {
    value = {
      ...value,
      attendance_limited_meta: JSON.stringify({
        ...data.attendance_limited_meta,
        type: data.attendance_limited_meta.type.id
      })
    };
  }
  return value;
};
