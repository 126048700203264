import React from 'react';
import PropType from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import DoneIcon from '@material-ui/icons/Done';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import { ROUTES } from 'src/constant/Constants';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  root: {
    width: 'calc(100% - 5%)',
    padding: '15px',
    margin: '-60px auto 0',
    '& .MuiAccordion-root': {
      margin: '0 0 10px'
    },
    '& .MuiAccordionSummary-root.Mui-expanded': {
      minHeight: '48px'
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: '0px 0'
    },
    '& .MuiAccordion-root.Mui-expanded:first-child': {
      marginTop: '0px'
    },
    '& .MuiAccordion-root:before': {
      display: 'none'
    },
    '& .MuiAccordion-rounded': {
      borderRadius: '5px'
    },
    '& .MuiAccordion-root.Mui-expanded:last-child': {
      '& .MuiAccordionSummary-root.Mui-expanded': {
        minHeight: '64px'
      }
    }
  },
  heading: {
    fontSize: '13px',
    fontWeight: '600',
    marginLeft: '30px',
    '& label': {
      backgroundColor: 'rgb(39 132 122)',
      width: '20px',
      height: '20px',
      borderRadius: '50%',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '13px',
      lineHeight: '20px',
      textAlign: 'center',
      color: 'rgb(255 255 255)',
      marginRight: '10px',
      display: 'inline-block',
      position: 'absolute',
      left: '15px'
    }
  },
  Accordionroot: {
    // root: {
    //   margin: '16px 0'
    // },
    '& .MuiAccordionDetails-root': {
      display: 'flex',
      padding: '8px 16px 8px',
      flexDirection: 'column'
    }
  },
  AcLink: {
    color: '#27847a',
    display: 'inline-block',
    padding: '5px 10px 0',
    fontSize: '14px',
    textAlign: 'right',
    float: 'right',
    cursor: 'pointer'
  },
  tickmarkIcon: {
    width: '17px',
    height: '20px',
    '& .MuiSvgIcon-root': {
      width: '17px',
      height: '20px'
    }
  }
}));

export default function ProcessAccordion({ handleClose, data }) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const history = useHistory();

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className={classes.root}>
      <Accordion
        expanded={expanded === 'panel1'}
        onChange={handleChange('panel1')}
        className={classes.Accordionroot}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>
            <label>
              {data.basic_info > 0 ? (
                <DoneIcon htmlColor="#fff" className={classes.tickmarkIcon} />
              ) : (
                '1'
              )}
            </label>
            Basic info & settings
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Make sure your basic business info is up to date and confirm your
            app settings are correct.
          </Typography>
          <span
            className={classes.AcLink}
            onClick={() => {
              history.push(ROUTES.GYM_GENERAL_INFO_INFOLOCATIONS);
              handleClose();
            }}
          >
            Add Basic info & settings
            <TrendingFlatIcon
              style={{
                verticalAlign: 'middle',
                marginLeft: '5px'
              }}
            />
          </span>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === 'panel2'}
        onChange={handleChange('panel2')}
        className={classes.Accordionroot}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>
            <label>
              {data.payment_info > 0 ? (
                <DoneIcon htmlColor="#fff" className={classes.tickmarkIcon} />
              ) : (
                '2'
              )}
            </label>
            Add payment info
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Add your payment details so the app can run at it’s full power.
          </Typography>
          <span
            className={classes.AcLink}
            onClick={() => {
              history.push(ROUTES.GYM_GENERAL_INFO_PAYMENT_BILLING);
              handleClose();
            }}
          >
            Add Payment Info
            <TrendingFlatIcon
              style={{
                verticalAlign: 'middle',
                marginLeft: '5px'
              }}
            />
          </span>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === 'panel3'}
        onChange={handleChange('panel3')}
        // disabled
        className={classes.Accordionroot}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography className={classes.heading}>
            {/* Disabled Accordion */}
            <label>
              {data.gym_setting > 0 ? (
                <DoneIcon htmlColor="#fff" className={classes.tickmarkIcon} />
              ) : (
                '3'
              )}
            </label>
            Gym Settings
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Customise your gym settings such as Date & Time format, Timezone,
            etc.
          </Typography>
          <span
            className={classes.AcLink}
            onClick={() => {
              history.push(ROUTES.GYM_SETTINGS);
              handleClose();
            }}
          >
            Add Gym Settings
            <TrendingFlatIcon
              style={{
                verticalAlign: 'middle',
                marginLeft: '5px'
              }}
            />
          </span>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === 'panel4'}
        onChange={handleChange('panel4')}
        // disabled
        className={classes.Accordionroot}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4a-content"
          id="panel4a-header"
        >
          <Typography className={classes.heading}>
            <label>
              {data.invoice_setting > 0 ? (
                <DoneIcon htmlColor="#fff" className={classes.tickmarkIcon} />
              ) : (
                '4'
              )}
            </label>
            Customise Invoice Settings
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Make sure your automated invoices send-out exactly as you want them
            to.
          </Typography>
          <span
            className={classes.AcLink}
            onClick={() => {
              history.push(ROUTES.FINANCIAL_SETTINGS);
              handleClose();
            }}
          >
            Customise Invoice Settings
            <TrendingFlatIcon
              style={{
                verticalAlign: 'middle',
                marginLeft: '5px'
              }}
            />
          </span>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === 'panel5'}
        onChange={handleChange('panel5')}
        // disabled
        className={classes.Accordionroot}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5a-content"
          id="panel5a-header"
        >
          <Typography className={classes.heading}>
            <label>
              {data.gym_info > 0 ? (
                <DoneIcon htmlColor="#fff" className={classes.tickmarkIcon} />
              ) : (
                '5'
              )}
            </label>
            Customise Auto-Emails
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            To make sure your automated emails actually sound like you! (and
            include all the info your tribe needs)
          </Typography>
          <span
            className={classes.AcLink}
            onClick={() => {
              history.push(ROUTES.COMMUNICATION_SETTINGS);
              handleClose();
            }}
          >
            Customise Auto-Emails
            <TrendingFlatIcon
              style={{
                verticalAlign: 'middle',
                marginLeft: '5px'
              }}
            />
          </span>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === 'panel6'}
        onChange={handleChange('panel6')}
        // disabled
        className={classes.Accordionroot}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel6a-content"
          id="panel6a-header"
        >
          <Typography className={classes.heading}>
            <label>
              {data.contract > 0 ? (
                <DoneIcon htmlColor="#fff" className={classes.tickmarkIcon} />
              ) : (
                '6'
              )}
            </label>
            Create Contract Template
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Add custom contracts for your various membership options (simply cut
            and paste if you already have these on file!)
          </Typography>
          <span
            className={classes.AcLink}
            onClick={() => {
              history.push(ROUTES.DOCUMENTS_NEW_CONTRACTS);
              handleClose();
            }}
          >
            Create Contract Template
            <TrendingFlatIcon
              style={{
                verticalAlign: 'middle',
                marginLeft: '5px'
              }}
            />
          </span>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === 'panel7'}
        onChange={handleChange('panel7')}
        // disabled
        className={classes.Accordionroot}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel7a-content"
          id="panel7a-header"
        >
          <Typography className={classes.heading}>
            <label>
              {data.waiver > 0 ? (
                <DoneIcon htmlColor="#fff" className={classes.tickmarkIcon} />
              ) : (
                '7'
              )}
            </label>
            Create Waiver Template
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Build out your electronic waiver. Add any custom terms and questions
            you like!
          </Typography>
          <span
            className={classes.AcLink}
            onClick={() => {
              history.push(ROUTES.DOCUMENTS_WAIVERS_NEW);
              handleClose();
            }}
          >
            Create Waiver Template
            <TrendingFlatIcon
              style={{
                verticalAlign: 'middle',
                marginLeft: '5px'
              }}
            />
          </span>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === 'panel8'}
        onChange={handleChange('panel8')}
        // disabled
        className={classes.Accordionroot}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel8a-content"
          id="panel8a-header"
        >
          <Typography className={classes.heading}>
            <label>
              {data.program > 0 ? (
                <DoneIcon htmlColor="#fff" className={classes.tickmarkIcon} />
              ) : (
                '8'
              )}
            </label>
            Build Class Programs
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Create your deluxe suite of various class programs (you can even
            give them custom colours if you like!)
          </Typography>
          <span
            className={classes.AcLink}
            onClick={() => {
              history.push(ROUTES.CLASSES_PROGRAMS_CREATE_NEW);
              handleClose();
            }}
          >
            Build Class Programs
            <TrendingFlatIcon
              style={{
                verticalAlign: 'middle',
                marginLeft: '5px'
              }}
            />
          </span>
          {/* <span
            className={classes.AcLink}
            onClick={() => {
              history.push(ROUTES.CLASSES_MEMBERSHIPS);
              handleClose();
            }}
          >
            Create Membership Template
            <TrendingFlatIcon
              style={{
                verticalAlign: 'middle',
                marginLeft: '5px'
              }}
            />
          </span> */}
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === 'panel9'}
        onChange={handleChange('panel9')}
        // disabled
        className={classes.Accordionroot}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel9a-content"
          id="panel9a-header"
        >
          <Typography className={classes.heading}>
            <label>
              {data.membership > 0 ? (
                <DoneIcon htmlColor="#fff" className={classes.tickmarkIcon} />
              ) : (
                '9'
              )}
            </label>
            Create Membership Template
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Create plan or session membership templates offered by your gym for
            the members.
          </Typography>
          <span
            className={classes.AcLink}
            onClick={() => {
              history.push(ROUTES.CLASSES_MEMBERSHIPS);
              handleClose();
            }}
          >
            Create Membership Template
            <TrendingFlatIcon
              style={{
                verticalAlign: 'middle',
                marginLeft: '5px'
              }}
            />
          </span>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

ProcessAccordion.propTypes = {
  handleClose: PropType.func.isRequired,
  data: PropType.number.isRequired
};
