import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import usePrevious from 'src/views/common/CustomHooks/usePrevious';
import JBButton from 'src/views/common/JBButton/JBButton';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropType from 'prop-types';
import {
  AllClassdata,
  futureddata,
  reccurdata
} from 'src/Redux/ClassReducer/classReducer.actions';
import { Grid, Box } from '@material-ui/core';
import { getCalenderData } from 'src/Redux/CalenderReducer/Calender.actions';
import { withStyles } from '@material-ui/core/styles';
import { openNoti } from 'src/Redux/global/global.actions';
import JBSelect from 'src/views/common/JBSelect/JBSelect';
import { updateSigClass } from 'src/services/classModule.services';
import { getErrorMsg } from 'src/utils/ErrorHandling';
import dcloseicon from '../../../assets/image/closeicon.png';
import styles from './Dialog.style';
import arrowleft2 from '../../../assets/image/arrowleft_v2.png';
import { getUpdateDiag } from './Dialog.utils';
// import Select from 'react-select';

function CoachSetDiag(props) {
  const { classes, values, goBack, allCoaches, getUpdate, onClose } = props;
  const [data, setData] = useState(values);
  const [saved, setSaved] = useState(false);
  const prevData = usePrevious(data);
  useEffect(() => {
    if (prevData) {
      setSaved(true);
    }
  }, [data]); // eslint-disable-line

  const handleSave = () => {
    const {
      getFuture,
      getAllClass,
      getReccClass,
      reloadCalender,
      OpenNotiDisp
    } = props;
    const value = getUpdateDiag(data);
    if (!value) {
      OpenNotiDisp('Please provide valid data', 'error');
    } else {
      updateSigClass(value, data.id)
        .then(() => reloadCalender())
        .then(() => getFuture())
        .then(() => getAllClass())
        .then(() => getReccClass())
        .then(() => {
          getUpdate(data);
          setSaved(false);
        })
        .then(() => OpenNotiDisp('Successfully updated the class', 'info'))
        .catch((err) => OpenNotiDisp(getErrorMsg(err), 'error'));
    }
  };
  const isEditable = data.start_time.getTime() > new Date().getTime() && data.status === 1;
  return (
    <div>
      <div style={{ width: '280px' }} className={classes.dmainbk}>
        <Grid alignItems="center" container direction="row">
          <Grid item xs style={{ cursor: 'pointer' }} onClick={() => goBack()}>
            <Button
              className={classes.btns}
              style={{
                display: 'inline-block',
                marginLeft: '11px',
                marginRight: '0',
                '&.MuiTouchRipple-root': {
                  display: 'none '
                }
              }}
            >
              <img alt="img" src={arrowleft2} />
            </Button>
            <DialogTitle
              id="alert-dialog-title"
              color="primary"
              className={classes.titlebk}
              style={{
                display: 'inline-block'
              }}
            >
              <h4 className={classes.dtitle}>Back to Settings</h4>
            </DialogTitle>
          </Grid>

          <Grid item style={{ textAlign: 'right', marginRight: '10px' }}>
            <Button className={classes.btns} onClick={() => onClose()}>
              <img alt="img" src={dcloseicon} />
            </Button>
          </Grid>
        </Grid>

        <DialogContent
          style={{
            overflowY: 'visible'
          }}
        >
          <DialogContentText id="alert-dialog-description">
            <Box display="block" mt={1} mb={1}>
              <label className={classes.dlabel}>Coach</label>
              <JBSelect
                options={
                  data.co_coach
                    ? allCoaches.filter((list) => list.id !== data.co_coach.id)
                    : allCoaches
                }
                value={data.head_coach}
                isSearchable
                onChange={(val) => setData({ ...data, head_coach: val })}
                isDisabled={!isEditable}
              />
            </Box>
            <Box display="block" mt={1} mb={1}>
              <label className={classes.dlabel}>Co-coach</label>
              <JBSelect
                options={
                  data.head_coach
                    ? allCoaches.filter(
                        (list) => list.id !== data.head_coach.id // eslint-disable-line
                      ) // eslint-disable-line
                    : allCoaches
                }
                value={data.co_coach}
                isSearchable
                onChange={(val) => setData({ ...data, co_coach: val })}
                isDisabled={!isEditable}
              />
            </Box>
            {saved && (
              <JBButton type="outined" fullWidth onClick={handleSave}>
                Save Changes
              </JBButton>
            )}
          </DialogContentText>
        </DialogContent>
      </div>
    </div>
  );
}

CoachSetDiag.propTypes = {
  classes: PropType.object.isRequired,
  values: PropType.object.isRequired,
  goBack: PropType.func.isRequired,
  allCoaches: PropType.array.isRequired,
  getUpdate: PropType.func.isRequired,
  reloadCalender: PropType.func.isRequired,
  getReccClass: PropType.func.isRequired,
  getAllClass: PropType.func.isRequired,
  getFuture: PropType.func.isRequired,
  OpenNotiDisp: PropType.func.isRequired,
  onClose: PropType.func.isRequired
};

const mapToState = (state) => ({
  allCoaches: state.classReducer.coaches
});

const mapToDispatch = (dispatch) => ({
  OpenNotiDisp: (msg, type) => dispatch(openNoti(msg, type)),
  reloadCalender: () => dispatch(getCalenderData()),
  getFuture: () => dispatch(futureddata()),
  getAllClass: () => dispatch(AllClassdata()),
  getReccClass: () => dispatch(reccurdata())
});

export default connect(
  mapToState,
  mapToDispatch
)(withStyles(styles)(CoachSetDiag));
