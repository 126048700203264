/* eslint-disable operator-linebreak */
import { getValuefromObject, backendDate } from 'src/utils/someCommon';
import moment from 'moment';

export const getFilter = (id, data, endDate) => {
  switch (id) {
    case 2:
      return {
        is_active: getValuefromObject(data)
        // status_operator: getValuefromObject(selected)
      };
    case 1:
      return {
        affiliate_id: getValuefromObject(data)
        // contract_operator: getValuefromObject(selected)
      };
    case 3:
      return {
        filter_by_status: getValuefromObject(data)
        // membership_operator: getValuefromObject(selected)
      };
    case 4:
      return {
        date_range: backendDate(data) + '-' + backendDate(endDate)
      };

    default:
      return null;
  }
};

export const getfilterLabel = (id, data, endDate, selected, dateformat) => {
  switch (id) {
    case 2:
      return {
        id,
        name: `Ally is  ${Object.values(selected)[1]} ${
          Object.values(data)[1]
        }`,
        filter: 'is_active'
        // connetor: 'status_operator'
      };
    case 1:
      return {
        id,
        name: `Ally ${Object.values(selected)[1]} ${Object.values(data)[1]}`,
        filter: 'affiliate_id'
        // connetor: 'contract_operator'
      };
    case 3:
      return {
        id,
        name: `Status ${Object.values(selected)[1]} ${Object.values(data)[1]}`,
        filter: 'filter_by_status'
        // connetor: 'membership_operator'
      };
    case 4:
      return {
        id,
        name: `Date is ${moment(data).format(dateformat) +
          '-' +
          moment(endDate).format(dateformat)}`,
        filter: 'date_range'
      };
    default:
      return null;
  }
};

export const checkData = (filter, data) => {
  if (!filter || !data || String(data).includes('Invalid')) {
    return true;
  }
  return false;
};
