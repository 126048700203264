// eslint-disable-next-line
export const getViewValue = (view) => {
  switch (view) {
    case 'week':
      return 0;
    case 'day':
      return 1;
    case 'agenda':
      return 2;
    default:
      return 1;
  }
};
