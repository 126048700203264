import { getValuefromObject } from 'src/utils/someCommon';

export const getFilter = (filter, data) => {
  switch (filter?.id) {
    case 1:
      if (data.id === 0) {
        return {
          trend: ''
        };
      }

      return {
        trend: getValuefromObject(data)
      };

    case 2: {
      return {
        weekly_attendance: data
      };
    }
    default:
      return null;
  }
};

export const getfilterLabel = (filter, data) => {
  switch (filter?.id) {
    case 1:
      return {
        id: filter.id,
        name: `Trend is ${Object.values(data)[1]}`,
        filter: 'trend'
      };

    case 2: {
      return {
        id: filter.id,
        name: `Average Weekly Attendance is ${data}`,
        filter: 'weekly_attendance'
      };
    }
    default:
      return null;
  }
};

export const checkData = (filter, data) => {
  if (!filter || !data || String(data) === 'Invalid') return true;
  return false;
};
