/* eslint-disable indent */
/* eslint-disable consistent-return */
/* eslint-disable default-case */
import React, { useContext } from 'react';
// import PropType from 'prop-types';
import { VanguardMem } from 'src/Context/VanguardMembershipContext';
import { setContractInitials } from 'src/Context/VanguardMembership/VanguardMembership.actions';
import GetPrice from 'src/views/common/CurrencySymbol/GetPrice';
import { useSelector } from 'react-redux';
import moment from 'moment';
import useGymSetting from 'src/views/common/CustomHooks/useGymSettingRefresh';
import {
  Typography,
  makeStyles,
  Grid,
  // RadioGroup,
  // Radio,
  // FormControlLabel,
  Box,
  TableRow,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  InputLabel
} from '@material-ui/core';
// import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
// import CheckIcon from '@material-ui/icons/Check';
import FormControl from 'src/views/common/FormControl/formControl';
import {
  // getmemdiscount,
  // getsetupdiscount,
  // getTaxTotal,
  // getTotal,
  getFeeTax,
  getSetupTax,
  setupTotal,
  CommitmentTotal,
  Commitmentcost,
  RenewalCost
} from './Vanguard.utills';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    background: 'rgba(29, 29, 29, 0.94)',
    boxShadow: '0px 0.33333px 0px rgb(255 255 255 / 15%)',
    backdropFilter: 'blur(54.3656px)'
  },
  appToolbar: {
    minHeight: '75px',
    justifyContent: 'space-between'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
  mainTitle: {
    fontWeight: '500',
    fontSize: '48px',
    lineHeight: '44px',
    color: '#FFFFFF',
    fontFamily: 'DDC Hardware',
    textAlign: 'center',
    margin: '38px 0 0 0',
    '@media (max-width: 992px)': {
      fontSize: '35px'
    }
  },
  MainCard: {
    height: 'calc(100vh - 75px )',
    background:
      'linear-gradient(180deg, rgba(41, 41, 41, 0.95) 0%, rgba(4, 4, 4, 0.95) 100%)',
    borderRadius: '0',
    flex: '1 1 auto',
    // height: '100%',
    overflow: 'auto'
  },
  HomeButton: {
    fontFamily: 'Lato',
    color: '#2CE9DA',
    fontWeight: 'bold',
    fontSize: '22px',
    lineHeight: '32px',
    textTransform: 'capitalize'
  },
  cutom_select_Label: {
    fontFamily: 'DDC Hardware',
    fontStyle: 'normal',
    fontWeight: '500;',
    fontSize: '22px',
    lineHeight: '32px',
    letterSpacing: '0.2px',
    color: '#FFFFFF',
    '&:focus ': {
      outline: '0'
    },
    '& .MuiFormLabel-asterisk': {
      color: 'transparent'
    },
    '& .MuiFormLabel-asterisk:after': {
      content: '""',
      background: '#2CE9DA',
      display: 'inline-block',
      position: 'relative',
      width: '8px',
      height: '8px',
      borderRadius: '50%',
      top: '-14px',
      right: '10px'
    }
  },
  cutom_vanguard_FormControl: {
    '& .fd-input input': {
      background: 'rgba(196, 196, 196, 0.05)',
      color: '#fff',
      borderRadius: '6px',
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '20px',
      height: '45px',
      border: '2px solid #C8C8C8',
      padding: '8px 20px 8px',
      '&::placeholder ': {
        color: '#fff',
        fontWeight: 'bold',
        fontSize: '15px'
      }
    }
  },
  cutom_vanguard_Autocomplete: {
    background: 'rgba(196, 196, 196, 0.05)',
    border: '2px solid #C8C8C8',
    color: '#fff',
    borderRadius: '6px',
    '& .cutom_select div': {
      minHeight: '62px'
    },
    '& .cutom_select div input ': {
      color: '#ffffff !important',
      fontWeight: 'bold',
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontSize: '20px'
    },
    '& .MuiAutocomplete-endAdornment': {
      minHeight: ' auto !important',
      top: 'calc(50% - 12px)'
    }
  },
  customRadio: {
    '& .MuiFormControlLabel-root': {
      background: 'rgba(196, 196, 196, 0.05)',
      border: '2px solid #7B7B7B',
      borderRadius: '6px',
      height: '70px',
      margin: '0 0 15px'
    },
    '& .MuiFormControlLabel-root.checked': {
      background: 'rgba(44, 233, 218, 0.15)',
      border: '2px solid #2CE9DA',
      borderRadius: '6px'
    },
    '& .MuiFormControlLabel-root svg.MuiSvgIcon-root': {
      width: '30px',
      height: '30px',
      fill: 'none',
      borderRadius: '50%',
      border: '2px solid #4B4B4B'
    },
    '& .MuiFormControlLabel-root.checked svg.MuiSvgIcon-root': {
      background: '#67e9da',
      padding: '4px',
      fill: '#000',
      color: '#060606',
      border: '0px solid #4B4B4B'
    },
    '& .MuiRadio-root': {
      padding: '20px'
    },
    '& .MuiFormControlLabel-label': {
      color: '#fff',
      fontSize: '20px',
      lineHeight: '26px',
      fontWeight: 'bold'
    }
  },
  subTitle: {
    fontWeight: 'bold',
    fontSize: '24px',
    lineHeight: '38px',
    color: '#FFFFFF',
    fontFamily: 'Lato',
    textAlign: 'center',
    margin: '38px 0 0 0',
    letterSpacing: '0.1px'
  },
  PlanTerms: {
    border: '2px solid #7B7B7B',
    borderRadius: '6px',
    marginBottom: '15px',
    '& tr': {
      '& td': {
        fontSize: '20px',
        color: 'rgb(255 255 255 / 60%)',
        lineHeight: '32px',
        letterSpacing: '0.2px',
        borderBottom: '0',
        '&:nth-of-type(odd)': {
          color: '#ffffff',
          minWidth: '250px',
          fontWeight: '700',
          width: 'calc(100% - 55%)'
        }
      }
    }
  },
  termsagree: {
    width: '100%'
  }
}));

const SessionPlanTerms = () => {
  const { dateformat } = useGymSetting();
  const [state, dispatch] = useContext(VanguardMem);
  // eslint-disable-next-line no-shadow
  const allPrograms = useSelector((state) => state.allprograms.programs);
  // eslint-disable-next-line no-shadow
  const taxRates = useSelector((state) => state.SettingReducer.taxRates.data);
  const {
    memebership_data: { membershipdata }
  } = state;
  const tax = taxRates.filter(
    (i) => i.id === membershipdata.payment_data.tax_id
  );
  // const renewalPricing = () => {
  //   if (!membershipdata.payment_data.auto_renew) return 0;
  //   // eslint-disable-next-line no-else-return
  //   else {
  //     return membershipdata.payment_data.paymentOpt === 1
  //       ? membershipdata.payment_data.auto_renewal_pricing.full_total
  //       : membershipdata.payment_data.auto_renewal_pricing.week_total;
  //   }
  // };
  const classes = useStyles();
  const getPrograms = () => {
    let result = [];
    result = allPrograms.filter((program) =>
      membershipdata.program_access.includes(program.id));
    result = result.map((i) => i.name);
    return result;
  };
  const handleChange = (e) => e.preventDefault();
  return (
    <div>
      <Typography variant="h3" className={classes.mainTitle}>
        Payment Plan Terms
      </Typography>
      <Typography variant="h5" className={classes.subTitle}>
        Here are the payment plan terms for your membership.
      </Typography>
      <Grid
        container
        xs={10}
        justify="center"
        style={{
          margin: '45px auto'
        }}
      >
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={0}
          // style={{ margin: '20px 0 20px' }}
        >
          {/* <Grid container spacing={0} style={{ margin: '20px 0' }}> */}
          <Grid container spacing={0} style={{ alignItems: 'center' }}>
            <TableContainer className={classes.PlanTerms}>
              <Table className={classes.table}>
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.TableCell}>
                      Name of membership
                    </TableCell>
                    <TableCell className={classes.TableCell}>
                      {membershipdata.payment_data.membership.name}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.TableCell}>
                      Number of Sessions
                    </TableCell>
                    <TableCell className={classes.TableCell}>
                      {membershipdata.payment_data.membership.no_of_sessions}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.TableCell}>
                      Expiration
                    </TableCell>
                    <TableCell className={classes.TableCell}>
                      {membershipdata.payment_data.membership.attendance_limit
                        .id === 0
                        ? 'Never'
                        : `${membershipdata.payment_data.membership.attendance_limited_meta.uses} ${membershipdata.payment_data.membership.attendance_limited_meta.type.name}`}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.TableCell}>
                      Programs
                    </TableCell>
                    <TableCell className={classes.TableCell}>
                      {getPrograms().join()}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.TableCell}>
                      Discount
                    </TableCell>
                    <TableCell className={classes.TableCell}>
                      {' '}
                      Setup:
                      {membershipdata.discount.setup.type === 'percentage' ? (
                        `${membershipdata.discount.setup.amount}%`
                      ) : (
                        <GetPrice
                          value={membershipdata.discount.setup.amount}
                        />
                      )}
                      {', '} Initial:
                      {membershipdata.discount.initial.type === 'percentage' ? (
                        `${membershipdata.discount.initial.amount}%`
                      ) : (
                        <GetPrice
                          value={membershipdata.discount.initial.amount}
                        />
                      )}
                      {membershipdata.payment_data.auto_renew === 1 && (
                        <>
                          Renewal:
                          {membershipdata.discount.renewal.type
                          === 'percentage' ? (
                            `${membershipdata.discount.renewal.amount}%`
                          ) : (
                            <GetPrice
                              value={membershipdata.discount.renewal.amount}
                            />
                          )}
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.TableCell}>
                      Auto-Renew
                    </TableCell>
                    <TableCell className={classes.TableCell}>
                      {membershipdata.payment_data.auto_renew ? 'Yes' : 'No'}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

            <TableContainer className={classes.PlanTerms}>
              <Table className={classes.table}>
                <TableBody>
                  <TableRow>
                    <TableCell>Initial commitment</TableCell>
                    <TableCell>
                      {membershipdata.payment_data.initial_commitment
                        ? `${membershipdata.payment_data.initial_commitment.initial} Weeks`
                        : '-'}{' '}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Payment option</TableCell>
                    <TableCell>
                      {membershipdata.payment_data.paymentOpt === 1
                        ? 'Pay in full'
                        : 'Weekly'}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Start date</TableCell>
                    <TableCell>
                      {moment(membershipdata.start_date).format(dateformat)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Expiration date</TableCell>
                    <TableCell>
                      {!membershipdata.expires_date
                        ? '-'
                        : moment(membershipdata.expires_date).format(
                            dateformat
                          )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

            <TableContainer className={classes.PlanTerms}>
              <Table className={classes.table}>
                {(() => {
                  switch (membershipdata.payment_data.paymentOpt) {
                    case 1:
                      return (
                        <TableBody>
                          <TableRow>
                            <TableCell>Setup cost (Including Tax)</TableCell>
                            <TableCell>
                              <GetPrice
                                value={
                                  membershipdata.payment_data
                                    .initial_commitment_pricing.full_setup_fees
                                }
                              />
                              -
                              {membershipdata.discount.setup.type
                              === 'percentage' ? (
                                `${membershipdata.discount.setup.amount}%`
                              ) : (
                                <GetPrice
                                  value={membershipdata.discount.setup.amount}
                                />
                              )}
                              ={' '}
                              <GetPrice
                                value={setupTotal(
                                  membershipdata.payment_data,
                                  membershipdata.discount
                                )}
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Setup tax</TableCell>
                            <TableCell>
                              <GetPrice
                                value={getSetupTax(
                                  membershipdata.payment_data,
                                  tax[0],
                                  membershipdata.discount
                                )}
                              />
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      );
                    case 2:
                      return (
                        <TableBody>
                          <TableRow>
                            <TableCell>Setup cost (Including Tax)</TableCell>
                            <TableCell>
                              <GetPrice
                                value={
                                  membershipdata.payment_data
                                    .initial_commitment_pricing.week_setup_fees
                                }
                              />
                              -
                              {membershipdata.discount.setup.type
                              === 'percentage' ? (
                                `${membershipdata.discount.setup.amount}%`
                              ) : (
                                <GetPrice
                                  value={membershipdata.discount.setup.amount}
                                />
                              )}
                              =
                              <GetPrice
                                value={setupTotal(
                                  membershipdata.payment_data,
                                  membershipdata.discount
                                )}
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Setup tax</TableCell>
                            <TableCell>
                              <GetPrice
                                value={getSetupTax(
                                  membershipdata.payment_data,
                                  tax[0],
                                  membershipdata.discount
                                )}
                              />
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      );
                  }
                })()}
              </Table>
            </TableContainer>
            <TableContainer className={classes.PlanTerms}>
              {(() => {
                switch (membershipdata.payment_data.paymentOpt) {
                  case 1:
                    return (
                      <Table className={classes.table}>
                        <TableBody>
                          <TableRow>
                            <TableCell>
                              Commitment cost (Including Tax)
                            </TableCell>
                            <TableCell>
                              <GetPrice
                                value={
                                  membershipdata.payment_data
                                    .initial_commitment_pricing
                                    .full_membership_fees
                                }
                              />
                              -
                              {membershipdata.discount.initial.type
                              === 'percentage' ? (
                                `${membershipdata.discount.initial.amount}%`
                              ) : (
                                <GetPrice
                                  value={membershipdata.discount.initial.amount}
                                />
                              )}
                              =
                              <GetPrice
                                value={Commitmentcost(
                                  membershipdata.payment_data,
                                  membershipdata.discount
                                )}
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Commitment tax</TableCell>
                            <TableCell>
                              <GetPrice
                                value={getFeeTax(
                                  membershipdata.payment_data,
                                  tax[0],
                                  membershipdata.discount
                                )}
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              Commitment total (Including Tax)
                            </TableCell>
                            <TableCell>
                              <GetPrice
                                value={setupTotal(
                                  membershipdata.payment_data,
                                  membershipdata.discount
                                )}
                              />
                              +
                              <GetPrice
                                value={CommitmentTotal(
                                  membershipdata.payment_data,
                                  membershipdata.discount
                                )}
                              />
                              =
                              <GetPrice
                                value={
                                  setupTotal(
                                    membershipdata.payment_data,
                                    membershipdata.discount
                                  )
                                  + CommitmentTotal(
                                    membershipdata.payment_data,
                                    membershipdata.discount
                                  )
                                }
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Billed on</TableCell>
                            <TableCell>
                              {moment(membershipdata.start_date).format(
                                dateformat
                              )}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    );
                  case 2:
                    return (
                      <Table className={classes.table}>
                        <TableBody>
                          <TableRow>
                            <TableCell>
                              Commitment cost (Including Tax)
                            </TableCell>
                            <TableCell>
                              <GetPrice
                                value={
                                  membershipdata.payment_data
                                    .initial_commitment_pricing
                                    .week_membership_fees
                                }
                              />
                              -
                              {membershipdata.discount.initial.type
                              === 'percentage' ? (
                                `${membershipdata.discount.initial.amount}%`
                              ) : (
                                <GetPrice
                                  value={membershipdata.discount.initial.amount}
                                />
                              )}
                              =
                              <GetPrice
                                value={Commitmentcost(
                                  membershipdata.payment_data,
                                  membershipdata.discount
                                )}
                              />
                              (every{' '}
                              {
                                membershipdata.payment_data.initial_commitment
                                  ?.billed_every.name
                              }{' '}
                              weeks)
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Commitment tax</TableCell>
                            <TableCell>
                              <GetPrice
                                value={getFeeTax(
                                  membershipdata.payment_data,
                                  tax[0],
                                  membershipdata.discount
                                )}
                              />{' '}
                              (every{' '}
                              {
                                membershipdata.payment_data.initial_commitment
                                  ?.billed_every.name
                              }{' '}
                              weeks)
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              Commitment total (Including Tax)
                            </TableCell>
                            <TableCell>
                              <GetPrice
                                value={setupTotal(
                                  membershipdata.payment_data,
                                  membershipdata.discount
                                )}
                              />
                              +
                              <GetPrice
                                value={CommitmentTotal(
                                  membershipdata.payment_data,
                                  membershipdata.discount
                                )}
                              />
                              =
                              <GetPrice
                                value={
                                  setupTotal(
                                    membershipdata.payment_data,
                                    membershipdata.discount
                                  )
                                  + CommitmentTotal(
                                    membershipdata.payment_data,
                                    membershipdata.discount
                                  )
                                }
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Billed on</TableCell>
                            <TableCell>
                              {moment(membershipdata.start_date).format(
                                dateformat
                              )}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    );
                }
              })()}
            </TableContainer>
            {membershipdata.payment_data.auto_renew === 1 && (
              <TableContainer className={classes.PlanTerms}>
                {(() => {
                  switch (membershipdata.payment_data.renewOpt) {
                    case 1:
                      return (
                        <Table className={classes.table}>
                          <TableBody>
                            <TableRow>
                              <TableCell>Renewal commitment</TableCell>
                              <TableCell>
                                {
                                  membershipdata.payment_data.auto_renew_meta
                                    .renewal
                                }{' '}
                                weeks
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Payment option</TableCell>
                              <TableCell>Pay in full</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Renewal cost</TableCell>
                              <TableCell>
                                <GetPrice
                                  value={
                                    membershipdata.payment_data
                                      .auto_renewal_pricing.full_membership_fees
                                  }
                                />
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Renewal tax</TableCell>
                              <TableCell>
                                <GetPrice
                                  value={
                                    membershipdata.payment_data
                                      .auto_renewal_pricing.full_tax_amount
                                  }
                                />
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Renewal total</TableCell>
                              <TableCell>
                                <GetPrice
                                  value={
                                    membershipdata.payment_data
                                      .auto_renewal_pricing.full_total
                                  }
                                />
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Billed on</TableCell>
                              <TableCell>
                                {moment(membershipdata.expires_date).format(
                                  dateformat
                                )}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      );
                    case 2:
                      return (
                        <Table className={classes.table}>
                          <TableBody>
                            <TableRow>
                              <TableCell>Renewal commitment</TableCell>
                              <TableCell>
                                {
                                  membershipdata.payment_data.auto_renew_meta
                                    .renewal
                                }{' '}
                                weeks
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Payment option</TableCell>
                              <TableCell>Weekly</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Renewal cost</TableCell>
                              <TableCell>
                                <GetPrice
                                  value={
                                    membershipdata.payment_data
                                      .auto_renewal_pricing.week_membership_fees
                                  }
                                />
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Renewal tax</TableCell>
                              <TableCell>
                                <GetPrice
                                  value={
                                    membershipdata.payment_data
                                      .auto_renewal_pricing.week_tax_amount
                                  }
                                />
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Renewal total</TableCell>
                              <TableCell>
                                <GetPrice
                                  value={
                                    membershipdata.payment_data
                                      .auto_renewal_pricing.week_total
                                  }
                                />
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Billed on</TableCell>
                              <TableCell>
                                {moment(membershipdata.expires_date).format(
                                  dateformat
                                )}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      );
                  }
                })()}
              </TableContainer>
            )}
            <TableContainer className={classes.PlanTerms}>
              <Table className={classes.table}>
                <TableBody>
                  <TableRow>
                    <TableCell>Total payment (Including Tax)</TableCell>
                    <TableCell>
                      <span style={{ color: '#2CE9DA', fontWeight: 'bold' }}>
                        <GetPrice
                          value={
                            Commitmentcost(
                              membershipdata.payment_data,
                              membershipdata.discount
                            )
                            + setupTotal(
                              membershipdata.payment_data,
                              membershipdata.discount
                            )
                          }
                        />
                      </span>
                    </TableCell>
                  </TableRow>
                  {membershipdata.payment_data.auto_renew === 1 && (
                    <TableRow>
                      <TableCell>Renewal payments</TableCell>
                      <TableCell>
                        <span style={{ color: '#2CE9DA', fontWeight: 'bold' }}>
                          <GetPrice
                            value={RenewalCost(
                              membershipdata.payment_data,
                              membershipdata.discount
                            )}
                          />
                        </span>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <Box className={classes.termsagree}>
              <Grid
                container
                direction="row"
                style={{
                  justifyContent: 'flex-end'
                }}
              >
                <Grid
                  item
                  className={classes.cutom_vanguard_FormControl}
                  style={{
                    margin: '10px 0',
                    width: '185px'
                  }}
                >
                  <InputLabel
                    htmlFor="age-required"
                    required
                    className={classes.cutom_select_Label}
                    // style={{ margin: '0px 0 5px' }}
                  >
                    INITIALS HERE
                  </InputLabel>
                  <FormControl
                    control="input"
                    value={membershipdata.user_contract.sign_initials}
                    onCut={handleChange}
                    onPaste={handleChange}
                    onCopy={handleChange}
                    onChange={(e) =>
                      dispatch(setContractInitials(e.target.value))
                    }
                    maxLength={3}
                    style={{
                      margin: '0 0'
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      {/* </Grid> */}
    </div>
  );
};

SessionPlanTerms.propTypes = {
  // setOpen: PropType.bool.isRequired,
  // handleClose: PropType.func.isRequired
  // handleBack: PropType.func,
  // handleHistory: PropType.string
};

export default SessionPlanTerms;
