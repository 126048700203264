import React, { useEffect, useState, memo } from 'react';
import { connect } from 'react-redux';
import useGymSettingReducer from 'src/views/common/CustomHooks/useGymSettingRefresh';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropType from 'prop-types';
import { addHours, setHours, setMinutes } from 'date-fns';
import {
  checkClassName,
  getMinTimeErr,
  setMinStartTime
} from 'src/utils/someCommon';
import moment from 'moment';
import {
  AllClassdata,
  futureddata,
  reccurdata
} from 'src/Redux/ClassReducer/classReducer.actions';
import usePrevious from 'src/views/common/CustomHooks/usePrevious';
import JBButton from 'src/views/common/JBButton/JBButton';
import { Grid, Box } from '@material-ui/core';
import { updateSigClass } from 'src/services/classModule.services';
import { getErrorMsg } from 'src/utils/ErrorHandling';
import { getCalenderData } from 'src/Redux/CalenderReducer/Calender.actions';
import { openNoti } from 'src/Redux/global/global.actions';
import { withStyles } from '@material-ui/core/styles';
import TimePicker from './TimePicker';
import DatePicker from './DatePicker';
import dcloseicon from '../../../assets/image/closeicon.png';
import arrowleft2 from '../../../assets/image/arrowleft_v2.png';
import styles from './Dialog.style';
import { getRepeatEditStr, getUpdateDiag } from './Dialog.utils';

function TimeChangeDiag(props) {
  const { classes, backtoClass, values, onClose, getUpdate } = props;
  const [data, setData] = useState(values);
  const [saved, setSaved] = useState(false);
  // Edit Dialog Box
  const prev = usePrevious(data);
  const { dateformat } = useGymSettingReducer();

  useEffect(() => {
    if (prev) setSaved(true);
  }, [data]); // eslint-disable-line

  const handleSave = () => {
    const {
      getReccClass,
      getFuture,
      getAllClass,
      reloadCalender,
      OpenNotiDisp
    } = props;
    const value = getUpdateDiag(data);
    if (!value) {
      OpenNotiDisp('Please provide valid data', 'error');
    } else {
      updateSigClass(value, data.id)
        .then(() => reloadCalender())
        .then(() => getFuture())
        .then(() => getAllClass())
        .then(() => getReccClass())
        .then(() => {
          getUpdate(data);
          setSaved(false);
        })
        .then(() => OpenNotiDisp('Successfully updated the class', 'info'))
        .catch((err) => OpenNotiDisp(getErrorMsg(err), 'error'));
    }
  };

  let TimeErr = null;
  const { error } = getMinTimeErr(data.start_time, data.end_time);
  if (error) {
    TimeErr = error;
  }

  return (
    <div>
      <div style={{ width: '280px' }} className={classes.dmainbk}>
        <Grid alignItems="center" container direction="row">
          <Grid
            item
            xs
            style={{ cursor: 'pointer' }}
            onClick={() => backtoClass()}
          >
            <Button
              className={classes.btns}
              style={{
                display: 'inline-block',
                marginLeft: '11px',
                marginRight: '0',
                '&.MuiTouchRipple-root': {
                  display: 'none '
                }
              }}
            >
              <img alt="img" src={arrowleft2} />
            </Button>
            <DialogTitle
              id="alert-dialog-title"
              color="primary"
              className={classes.titlebk}
              style={{
                display: 'inline-block'
              }}
            >
              <h4 className={classes.dtitle}>Back to Settings</h4>
            </DialogTitle>
          </Grid>
          <Grid item xs={2} style={{ textAlign: 'right', marginRight: '10px' }}>
            <Button className={classes.btns} onClick={() => onClose()}>
              <img alt="img" src={dcloseicon} />
            </Button>
          </Grid>
        </Grid>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Grid alignItems="center" spacing={1} container direction="row">
              <Grid item xs={6}>
                <Box display="block">
                  <label className={classes.dlabel}>Start date</label>
                  <DatePicker
                    value={data.start_time}
                    onChange={(val) =>
                      setData({
                        ...data,
                        start_time: val,
                        end_time: addHours(val, 1)
                      })
                    } // eslint-disable-line
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box display="block">
                  <label className={classes.dlabel}>End date</label>
                  <h3
                    className={classes.dh3}
                    style={{ display: 'inline-block' }}
                  >
                    {moment(data.start_time).format(`${dateformat}`)}
                  </h3>
                </Box>
              </Grid>
            </Grid>

            <Grid alignItems="center" spacing={2} container direction="row">
              <Grid item xs={6}>
                <Box display="block">
                  <label className={classes.dlabel}>Start time</label>
                  <TimePicker
                    value={data.start_time}
                    minTime={setMinStartTime(data.start_time)}
                    maxTime={setHours(setMinutes(data.start_time, 0), 22)}
                    Change={(val) => {
                      if (checkClassName(data.class_name, data.program_name)) {
                        setData({
                          ...data,
                          start_time: val,
                          end_time: addHours(val, 1),
                          class_name:
                            data.program_name + ' ' + moment(val).format('LT')
                        });
                      } else {
                        setData({
                          ...data,
                          start_time: val,
                          end_time: addHours(val, 1)
                        });
                      }
                    }} // eslint-disable-line
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box display="block">
                  <label className={classes.dlabel}>End time</label>
                </Box>
                <TimePicker
                  value={data.end_time}
                  minTime={data.start_time}
                  maxTime={setHours(setMinutes(data.end_time, 0), 23)}
                  Change={(val) => setData({ ...data, end_time: val })}
                />
              </Grid>
            </Grid>
            {TimeErr && (
              <p style={{ color: 'red', fontSize: '12px' }}>{TimeErr}</p>
            )}
            <Box className={classes.ptag}>
              {data.is_recurring ? <p>{getRepeatEditStr(data)}</p> : null}
            </Box>
            {saved && (
              <Box display="block" mt={2} mb={1}>
                <JBButton type="outlined" fullWidth onClick={handleSave}>
                  Save Changes
                </JBButton>
              </Box>
            )}
          </DialogContentText>
        </DialogContent>
      </div>
    </div>
  );
}

TimeChangeDiag.propTypes = {
  classes: PropType.object.isRequired,
  backtoClass: PropType.func.isRequired,
  values: PropType.object.isRequired,
  onClose: PropType.func.isRequired,
  getUpdate: PropType.func.isRequired,
  reloadCalender: PropType.func.isRequired,
  getReccClass: PropType.func.isRequired,
  getAllClass: PropType.func.isRequired,
  getFuture: PropType.func.isRequired,
  OpenNotiDisp: PropType.func.isRequired
};

const mapToDispatch = (dispatch) => ({
  OpenNotiDisp: (msg, type) => dispatch(openNoti(msg, type)),
  reloadCalender: () => dispatch(getCalenderData()),
  getFuture: () => dispatch(futureddata()),
  getAllClass: () => dispatch(AllClassdata()),
  getReccClass: () => dispatch(reccurdata())
});

export default connect(
  null,
  mapToDispatch
)(withStyles(styles)(memo(TimeChangeDiag)));
