/* eslint-disable arrow-parens */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Breadcrumbs, Grid, Typography, withStyles } from '@material-ui/core';
import { get } from 'js-cookie';
import JBButton from '../../../common/JBButton/JBButton';
import { ROUTES } from '../../../../constant/Constants';
import Plusicon from '../../../../assets/image/plusicon.png';
import styles from './ClassesList.style';
import ListTabs from './ListTabs';

class List extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      oneTimeClass: 0
    };
  }

  render() {
    const { classes, match, location } = this.props;
    const { value, oneTimeClass } = this.state;
    const recurrlocation = location.pathname === ROUTES.CLASSES_RECURRING;

    const roleid = JSON.parse(get('jbrole') || '{}').role_id || 3;
    return (
      <div className={classes.root}>
        <div className="title-bk">
          <div className={classes.new_programs} style={{ marginBottom: 15 }}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={0}
            >
              <Grid item xs={6}>
                <Breadcrumbs aria-label="breadcrumb">
                  <div color="inherit">List</div>
                  {!recurrlocation ? (
                    <Link color="inherit" to={ROUTES.CLASSES_LIST}>
                      Classes
                    </Link>
                  ) : null}

                  <Typography variant="h3" color="Primary">
                    {!recurrlocation ? (
                      <Fragment>
                        {match.isExact ? (
                          <span>Today & Future</span>
                        ) : (
                          <span>ALL</span>
                        )}
                      </Fragment>
                    ) : (
                      <span>Recurring</span>
                    )}
                  </Typography>
                </Breadcrumbs>
              </Grid>
              <Grid item xs={6}>
                {roleid <= 2 && (
                  <JBButton
                    type="primary"
                    isImg={Plusicon}
                    component={Link}
                    to="/app/class/create"
                    style={{ float: 'right' }}
                  >
                    New Class
                  </JBButton>
                )}
              </Grid>
            </Grid>
          </div>
        </div>

        <ListTabs
          tabValue={oneTimeClass}
          Recurr={value}
          changeRecurr={(e) => this.setState({ value: e })}
          changeTab={(e) => this.setState({ oneTimeClass: e })}
        />
      </div>
    );
  }
}

List.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.any.isRequired,
  location: PropTypes.any.isRequired
};

export default withStyles(styles, { withTheme: true })(List);
