const BASE_PATH = process.env.NODE_ENV === 'production' ? '' : '';
if (
  // eslint-disable-next-line operator-linebreak
  window.location.protocol === 'http:' &&
  process.env.NODE_ENV === 'production'
) {
  window.location.protocol = 'https:';
}

export const BASE_URL = process.env.REACT_APP_BASE_URL;

// API KEY
export const API_KEY = process.env.REACT_APP_GOGGLEMAP_API;
// Default Page Size
export const USER_INVOICE_PAGE_SIZE = 10;

// Stripe Test KEY
export const STRIPE_TEST_KEY = process.env.REACT_APP_STRIPE_TEST_KEY;

// Stripe Secret KEY

// Stripe Account_id
// export const STRIPE_ACCOUNT_ID = 'acct_1Jw2sU2QiUkFLKyS';
export const STRIPE_ACCOUNT_ID = process.env.REACT_APP_STRIPE_ACCOUNT;

// ROUTES
export const ROUTES = {
  // WORKOUT
  APP_REGISTER: `${BASE_PATH}/register`,
  APP_LOGIN: `${BASE_PATH}/login`,
  WORKOUT_LIST: `${BASE_PATH}/app/wod`,
  WORKOUT_LIST_STARRED: `${BASE_PATH}/app/wod/starred`,
  WORKOUT_DETAIL_VIEW: `${BASE_PATH}/app/wod/detailview`,
  WORKOUT_CREATE_VIEW: `${BASE_PATH}/app/wod/create`,
  WORKOUT_COPY_VIEW: `${BASE_PATH}/app/wod/copywod`,
  WORKOUT_EDIT_VIEW: `${BASE_PATH}/app/wod/editview`,
  WORKOUT_SETTINGS: `${BASE_PATH}/app/wod/setting`,
  // PEOPLE
  PEOPLE_LIST: `${BASE_PATH}/app/people/leads`,
  PEOPLE_LEAD_PROFILE: `${BASE_PATH}/app/people/lead/profile`,
  PEOPLE_ARCHIVED_LEAD: `${BASE_PATH}/app/people/lead/archived`,
  PEOPLE_LEAD_IMPORT: `${BASE_PATH}/app/people/lead/import`,
  PEOPLE_EDIT: `${BASE_PATH}/app/people/lead/profile/edit`,
  PEOPLE_CREATE_LEAD: `${BASE_PATH}/app/people/lead/new`,
  PEOPLE_MEMBERS: `${BASE_PATH}/app/people/members`,
  PEOPLE_MEMBERS_INACTIVE: `${BASE_PATH}/app/people/members/inactive`,
  PEOPLE_MEMBERS_All: `${BASE_PATH}/app/people/members/all`,
  PEOPLE_CREATE_MEMBER: `${BASE_PATH}/app/people/member/create`,
  PEOPLE_MEMBER_PROFILE: `${BASE_PATH}/app/people/member/profile`,
  PEOPLE_MEMBER_IMPORT: `${BASE_PATH}/app/people/member/import`,
  PEOPLE_CREATE_NEW_MEMBERSHIPS: `${BASE_PATH}/app/people/membership/new`,
  PEOPLE_EDIT_USER_MEMBERSHIPS: `${BASE_PATH}/app/people/membership/edit`,
  PEOPLE_SETTINGS: `${BASE_PATH}/app/people/setting`,
  // CLASSES
  CLASSES_CALENDAR: `${BASE_PATH}/app/class/calendar`,
  CLASSES_CREATE_NEW_CLS: `${BASE_PATH}/app/class/create`,
  CLASSES_LIST: `${BASE_PATH}/app/class`,
  CLASSES_LIST_All: `${BASE_PATH}/app/class/allclasses`,
  CLASSES_LIST_TODAYFUTURE: `${BASE_PATH}/app/class/todayFuture`,
  CLASSES_RECURRING: `${BASE_PATH}/app/class/recurring`,
  CLASSES_EDIT: `${BASE_PATH}/app/class/edit`,
  CLASSES_PROGRAMS: `${BASE_PATH}/app/program`,
  CLASSES_PROGRAMS_INACTIVE: `${BASE_PATH}/app/program/inactive`,
  CLASSES_PROGRAMS_CREATE_NEW: `${BASE_PATH}/app/program/createnew`,
  CLASSES_PROGRAMS_EDIT: `${BASE_PATH}/app/program/edit`,
  CLASSES_MEMBERSHIPS: `${BASE_PATH}/app/memberships`,
  CLASSES_PLAN_MEMBERSHIP_CREATE: `${BASE_PATH}/app/membership/create`,
  CLASSES_PLAN_MEMBERSHIP_EDIT: `${BASE_PATH}/app/membership/edit`,
  CLASSES_SESSION_TEMPLATE: `${BASE_PATH}/app/memberships/session`,

  // add
  CLASSES_DROPIN: `${BASE_PATH}/app/memberships/dropin`,
  CLASSES_DROPIN_CREATE: `${BASE_PATH}/app/dropin/create`,
  CLASSES_DROPIN_EDIT: `${BASE_PATH}/app/dropin/edit`,

  CLASSES_PLAN_SESSION_CREATE: `${BASE_PATH}/app/session/create`,
  CLASSES_PLAN_SESSION_EDIT: `${BASE_PATH}/app/session/edit`,
  CLASSES_SETTINGS_MEMBERSHIP: `${BASE_PATH}/app/class/setting/membership`,
  CLASSES_SETTINGS: `${BASE_PATH}/app/class/setting`,
  // CLASSES_SETTINGS_MEMBERSHIP_ATTENDANCE: `${BASE_PATH}/app/class/setting/membership/attendance`,
  CLASSES_SETTINGS_MEMBERSHIP_MASS_UPDATE: `${BASE_PATH}/app/class/setting/membership/massupdate`,
  CLASSES_SETTINGS_MEMBERSHIP_HOLD_TYPES: `${BASE_PATH}/app/class/setting/membership/holdtypes`,

  // COMMUNICATION
  COMMUNICATION: `${BASE_PATH}/app/communication`,
  COMMUNICATION_AUTOMATED_EMAILS: `${BASE_PATH}/app/communication/automatedemails`,
  COMMUNICATION_AUTOMATED_EMAILS_MEMBER_PAYMENTS: `${BASE_PATH}/app/communication/automatedemails/general/memberpayments`,
  COMMUNICATION_AUTOMATED_EMAILS_CREDIT_CARD_EXP: `${BASE_PATH}/app/communication/automatedemails/general/creditcardexpiring`,
  COMMUNICATION_AUTOMATED_EMAILS_FAILED_PAYMENTS: `${BASE_PATH}/app/communication/automatedemails/general/failedpayments`,
  COMMUNICATION_AUTOMATED_EMAILS_RENEWAL_REMINDER: `${BASE_PATH}/app/communication/automatedemails/general/renewalreminder`,
  COMMUNICATION_AUTOMATED_EMAILS_PAYMENT_INFO_MISSING: `${BASE_PATH}/app/communication/automatedemails/general/paymentinformationmissing`,
  COMMUNICATION_AUTOMATED_EMAILS_WELCOME_EMAIL: `${BASE_PATH}/app/communication/automatedemails/general/welcomeemail`,
  COMMUNICATION_AUTOMATED_EMAILS_CLASSES: `${BASE_PATH}/app/communication/automatedemails/classes`,
  COMMUNICATION_AUTOMATED_EMAILS_CLASSES_CLASSREINSTATED: `${BASE_PATH}/app/communication/automatedemails/classes/classreinstated`,
  COMMUNICATION_AUTOMATED_EMAILS_CLASSES_CLASSCANCELLED: `${BASE_PATH}/app/communication/automatedemails/classes/classcancelled`,
  COMMUNICATION_AUTOMATED_EMAILS_CLASSES_EXPIRINGPLANS: `${BASE_PATH}/app/communication/automatedemails/classes/expiringplans`,
  COMMUNICATION_AUTOMATED_EMAILS_CLASSES_EXPIRINGSESSIONS: `${BASE_PATH}/app/communication/automatedemails/classes/expiringsessions`,
  COMMUNICATION_AUTOMATED_EMAILS_CLASSES_MISSING_MEMBER: `${BASE_PATH}/app/communication/automatedemails/classes/missingmember`,
  COMMUNICATION_SETTINGS: `${BASE_PATH}/app/communication/setting`,

  // Dashboard
  DASHBOARD: `${BASE_PATH}/app/dashboard`,
  // DOCUMENTS
  DOCUMENTS_NEW_CONTRACTS: `${BASE_PATH}/app/newcontract`,
  DOCUMENTS_EDIT_CONTRACTS: `${BASE_PATH}/app/contracttemplates`,
  DOCUMENTS_CONTRACTS: `${BASE_PATH}/app/documents`,
  DOCUMENTS_CONTRACTS_MISSING: `${BASE_PATH}/app/documents/missing`,
  DOCUMENTS_CONTRACTS_INACTIVE: `${BASE_PATH}/app/documents/missing/inactive`,
  DOCUMENTS_CONTRACTS_ALL: `${BASE_PATH}/app/documents/missing/all`,
  DOCUMENTS_CONTRACTS_SIGNED: `${BASE_PATH}/app/documents/signed`,
  DOCUMENTS_CONTRACTS_TEMPLATES: `${BASE_PATH}/app/documents/templates`,
  DOCUMENTS_CONTRACTS_NEW: `${BASE_PATH}/app/documents/new`,
  DOCUMENTS_WAIVERS_SIGNED: `${BASE_PATH}/app/documents/waiver/signed`,
  DOCUMENTS_WAIVERS_NEW: `${BASE_PATH}/app/documents/waiver/new`,
  DOCUMENTS_WAIVERS_SIGNED_DETAIL: `${BASE_PATH}/app/documents/waiver/detail`,
  DOCUMENTS_WAIVERS_TEMPLATES: `${BASE_PATH}/app/documents/waiver/templates`,
  DOCUMENTS_WAIVERS_TEMPLATES_DETAIL: `${BASE_PATH}/app/documents/waiver/templates/detail`,
  DOCUMENTS_WAIVERS: `${BASE_PATH}/app/documents/waiver`,
  DOCUMENTS_MEMBERSHIP_CONTRACT: `${BASE_PATH}/app/documents/membership/contract`,

  // FINANCIAL
  FINANCIAL_INVOICES: `${BASE_PATH}/app/financial/`,
  FINANCIAL_INVOICES_PAID: `${BASE_PATH}/app/financial/invoices/paid`,
  FINANCIAL_INVOICES_OVERDUE: `${BASE_PATH}/app/financial/invoices/overdue`,
  FINANCIAL_INVOICES_PROCESSING: `${BASE_PATH}/app/financial/invoices/processing`,
  FINANCIAL_INVOICES_FAILED: `${BASE_PATH}/app/financial/invoices/failed`,
  FINANCIAL_INVOICES_ALL: `${BASE_PATH}/app/financial/invoices/all`,
  FINANCIAL_INVOICES_VIEW: `${BASE_PATH}/app/financial/invoice/view`,
  FINANCIAL_INVOICES_EDIT: `${BASE_PATH}/app/financial/invoice/edit`,
  FINANCIAL: `${BASE_PATH}/app/financial`,
  FINANCIAL_SETTINGS: `${BASE_PATH}/app/financial/settings`,
  FINANCIAL_SETTINGS_REVENUECATEGORY: `${BASE_PATH}/app/financial/settings/revenuecategory`,

  // REPORTS
  REPORTS: `${BASE_PATH}/app/reports`,
  REPORTS_STARRED: `${BASE_PATH}/app/reports/starred`,
  REPORTS_MEMBERS: `${BASE_PATH}/app/reports/members`,
  REPORTS_MEMBERS_MEMBERSHIP_DETAILS: `${BASE_PATH}/app/reports/members/membersdetails`,
  REPORTS_MEMBERS_EXPIRING_MEMBERSHIPS: `${BASE_PATH}/app/reports/members/expiringmemberships`,
  REPORTS_MEMBERS_MEMBERSHIP_TYPE: `${BASE_PATH}/app/reports/members/membershiptype`,
  REPORTS_MEMBERS_PAYMENT_PLAN: `${BASE_PATH}/app/reports/members/paymentplan`,
  REPORTS_MEMBERS_MEMBERSHIPS_ON_HOLD: `${BASE_PATH}/app/reports/members/membershipsonhold`,
  REPORTS_ATTENDANCE: `${BASE_PATH}/app/reports/attendance`,
  REPORTS_ATTENDANCE_LAST_ATTENDANCE: `${BASE_PATH}/app/reports/attendance/lastattendance`,
  REPORTS_ATTENDANCE_ATTENDANCE_SUMMARY: `${BASE_PATH}/app/reports/attendance/attendancesummary`,
  REPORTS_ATTENDANCE_ATTENDANCE_HISTORY: `${BASE_PATH}/app/reports/attendance/attendancehistory`,
  REPORTS_ATTENDANCE_ATTENDANCE_TREND: `${BASE_PATH}/app/reports/attendance/attendancetrend`,
  REPORTS_FINANCIAL: `${BASE_PATH}/app/reports/financial`,
  REPORTS_FINANCIAL_EXPIRING_CREDIT_CARDS: `${BASE_PATH}/app/reports/financial/expiringcreditcards`,
  REPORTS_FINANCIAL_ALLINVOICES: `${BASE_PATH}/app/reports/financial/allinvoices`,
  REPORTS_FINANCIAL_REVENUE_CATEGORY: `${BASE_PATH}/app/reports/financial/revenuecategory`,
  REPORTS_FINANCIAL_VALUEMEMBER: `${BASE_PATH}/app/reports/financial/valuemember`,
  REPORTS_FINANCIAL_OVERDUE_ACCOUNT_RECEIVABLE: `${BASE_PATH}/app/reports/financial/overdueaccount`,
  REPORTS_FINANCIAL_REVENUE_MEMBERSHIP: `${BASE_PATH}/app/reports/financial/revenuemembership`,
  REPORTS_LEAD: `${BASE_PATH}/app/reports/lead`,
  REPORTS_LEAD_INSIGHTS: `${BASE_PATH}/app/reports/lead/leadinsights`,

  // GYM
  GYM_GENERAL_INFO: `${BASE_PATH}/app/gym`,
  GYM_GENERAL_INFO_INFOLOCATIONS: `${BASE_PATH}/app/gym/infolocations`,
  GYM_GENERAL_INFO_PAYMENT_BILLING: `${BASE_PATH}/app/gym/paymentbilling`,
  GYM_GENERAL_INFO_WEBINTEGRATIONS: `${BASE_PATH}/app/gym/webintegrations`,
  GYM_SETTINGS: `${BASE_PATH}/app/gym/settings`,

  // CHALKBOARD
  CHALKBOARD: `${BASE_PATH}/app/chalkboard`,
  CHALKBOARD_WORKOUT: `${BASE_PATH}/app/chalkboard/workout`,

  chalkboard: {
    CHALKBOARD: `${BASE_PATH}/app/chalkboard`,
    CHALKBOARD_WORKOUT: `${BASE_PATH}/app/chalkboard/workout`
  },

  // MASTER HQ
  MASTER_HQ: `${BASE_PATH}/app/masterhq`,
  MASTER_HQ_AFFILIATES: `${BASE_PATH}/app/masterhq/affiliates`,
  MASTER_HQ_AFFILIATES_INACTIVE: `${BASE_PATH}/app/masterhq/affiliates/inactive`,
  MASTER_HQ_AFFILIATES_ALL: `${BASE_PATH}/app/masterhq/affiliates/all`,
  MASTER_HQ_AFFILIATES_CREATE: `${BASE_PATH}/app/masterhq/affiliates/newaffiliates`,
  MASTER_HQ_AFFILIATES_PROFILE: `${BASE_PATH}/app/masterhq/affiliates/profile`,
  MASTER_HQ_FINANCIAL_INVOICES: `${BASE_PATH}/app/masterhq/financial/invoices`,
  MASTER_HQ_FINANCIAL_SETTINGS: `${BASE_PATH}/app/masterhq/financial/settings`,
  MASTER_HQ_FINANCIAL_INVOICES_VIEW: `${BASE_PATH}/app/masterhq/financial/invoice/view`,
  MASTER_HQ_FINANCIAL_INVOICES_PAIND_VIEW: `${BASE_PATH}/app/masterhq/financial/invoice/paid/view`,
  MASTER_HQ_FINANCIAL: `${BASE_PATH}/app/masterhq/financial`,
  MASTER_HQ_REPORTS: `${BASE_PATH}/app/masterhq/reports`,
  MASTER_HQ_REPORTS_STARRED: `${BASE_PATH}/app/masterhq/reports/starred`,
  MASTER_HQ_REPORTS_FINANCIAL: `${BASE_PATH}/app/masterhq/reports/financial`,
  MASTER_HQ_REPORTS_FINANCIAL_ALLINVOICES: `${BASE_PATH}/app/masterhq/reports/financial/allinvoices`,
  MASTER_HQ_REPORTS_FINANCIAL_EXPIRED_CARDS: `${BASE_PATH}/app/masterhq/reports/financial/expiringcreditcards`,
  MASTER_HQ_REPORTS_FINANCIAL_OVERDUE_ACCOUNT_RECEIVABLE: `${BASE_PATH}/app/masterhq/reports/financial/overdueaccount`,
  MASTER_HQ_REPORTS_FINANCIAL_REVENUE_PER_MONTH: `${BASE_PATH}/app/masterhq/reports/financial/revenuecategory`,

  // VANGUARD
  VANGUARD: `${BASE_PATH}/app/vanguard`,
  VANGUARD_INDEX: `${BASE_PATH}/app/vanguard/index`,
  VANGUARD_WAIVER: `${BASE_PATH}/app/vanguard/waiver`,
  VANGUARD_WAIVER_NEW: `${BASE_PATH}/app/vanguard/waiver/new`,
  VANGUARD_WAIVER_RETURNING: `${BASE_PATH}/app/vanguard/waiver/returning`,
  VANGUARD_MEMBERSHIP: `${BASE_PATH}/app/vanguard/membership`,
  VANGUARD_MEMBERSHIP_SELECT_CONTRACT: `${BASE_PATH}/app/vanguard/membership/selectcontract`,
  VANGUARD_MEMBERSHIP_SIGN_CONTRACT: `${BASE_PATH}/app/vanguard/membership/signcontract`,
  VANGUARD_CONVERT_MEMBER: `${BASE_PATH}/app/vanguard/convertmember`,
  VANGUARD_MEMBERSHIP_NEW: `${BASE_PATH}/app/vanguard/membership/new`,
  VANGUARD_MEMBERSHIP_RETURNING: `${BASE_PATH}/app/vanguard/membership/returning`,
  VANGUARD_MEMBERSHIP_PLAN: `${BASE_PATH}/app/vanguard/membership/plan`,
  VANGUARD_MEMBERSHIP_SESSION: `${BASE_PATH}/app/vanguard/membership/session`,
  VANGUARD_CHALKBOARD: `${BASE_PATH}/app/vanguard/chalkboard`,
  VANGUARD_CHALKBOARD_WORKOUT: `${BASE_PATH}/app/vanguard/chalkboard/workout`,
  VANGUARD_CHALKBOARD_WORKOUT_SIGN_IN_MEMBER: `${BASE_PATH}/app/vanguard/chalkboard/workout/signinmember`,

  // add
  VANGUARD_MEMBERSHIP_DROPIN: `${BASE_PATH}/app/vanguard/membership/dropin`,
  // ALLY-HUB
  ALLYHUB: `${BASE_PATH}/app/allyhub`
};

// NOTIFICATION POSITION
export const NOTI = {
  POSITION_TOP_CENTER: 'TOP-CENTER',
  POSITION_TOP_RIGHT: 'TOP-RIGHT',
  POSITION_TOP_LEFT: 'TOP-LEFT',
  POSITION_BOTTOM_CENTER: 'BOTTOM-CENTER',
  POSITION_BOTTOM_RIGHT: 'BOTTOM-RIGHT',
  POSITION_BOTTOM_LEFT: 'BOTTOM-LEFT'
};
