import React, { Fragment } from 'react';
import Box from '@material-ui/core/Box';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/styles';

// Renders the amount of Rows with Loading View
const items = [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }];

const useStyles = makeStyles({
  root: {
    backgroundColor: 'rgba(38, 50, 56, 0.30)'
  }
});

export default function VanguardListLoadingView() {
  const classes = useStyles();
  return (
    <Fragment>
      {items.map((item) => (
        <Box display="flex" width="100%" key={item.id}>
          <Box width="10%" marginRight={1}>
            <Skeleton
              width="100%"
              height={100}
              classes={{ root: classes.root }}
            />
          </Box>
          <Box width="70%" marginRight={1}>
            <Skeleton
              width="100%"
              height={100}
              classes={{ root: classes.root }}
            />
          </Box>
          <Box width="20%">
            <Skeleton
              width="100%"
              height={100}
              classes={{ root: classes.root }}
            />
          </Box>
        </Box>
      ))}
    </Fragment>
  );
}
