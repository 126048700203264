import React from 'react';
import PropType from 'prop-types';
import {
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  withStyles
} from '@material-ui/core';
import GetPrice from 'src/views/common/CurrencySymbol/GetPrice';

const styles = () => ({
  table: {
    minWidth: '100%',
    wordBreak: 'break-word',
    '& td': {
      padding: '10px'
    },
    '@media (max-width: 1366px)': {
      '& td': {
        fontSize: '13px'
      }
    }
  },
  tableLink: {
    color: '#01847A',
    fontWeight: '700'
  },
  TableCell: {
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px',
    height: '56px',
    minHeight: '56px',
    padding: '10px',
    '@media (max-width: 1366px)': {
      fontSize: '13px',
      wordBreak: 'keep-all'
    }
  },
  Tablemian: {},
  SummaryCell: {
    color: '#292929',
    fontWeight: 'bold'
  }
});

const RevenueMembershipTable = (props) => {
  const { values, classes, Summary } = props;
  return (
    <TableContainer
      component={Paper}
      elevation={0}
      className={classes.Tablemian}
    >
      <Table className={classes.table} aria-label="simple table">
        <TableHead
          style={{ background: '#F9F9FA', backgroundColor: '#F9F9FA' }}
        >
          <TableRow>
            <TableCell className={classes.TableCell}>Membership name</TableCell>
            <TableCell className={classes.TableCell}>
              Payment plan name
            </TableCell>
            <TableCell className={classes.TableCell}>Membership type</TableCell>
            <TableCell className={classes.TableCell} align="right">
              Number of memberships
            </TableCell>
            <TableCell className={classes.TableCell} align="right">
              Net Revenue
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell
              style={{
                width: '9.0909%'
              }}
              className={classes.SummaryCell}
            >
              Summary
            </TableCell>
            <TableCell
              style={{
                width: '9.0909%'
              }}
              className={classes.SummaryCell}
            >
              &nbsp;
            </TableCell>
            <TableCell
              style={{
                width: '9%'
              }}
              className={classes.SummaryCell}
            >
              &nbsp;
            </TableCell>

            <TableCell
              style={{
                width: '9%'
              }}
              align="right"
              className={classes.SummaryCell}
            >
              {Summary.total_records}
            </TableCell>

            <TableCell
              style={{
                width: '9%'
              }}
              align="right"
              className={classes.SummaryCell}
            >
              <GetPrice value={Summary.summary} />
            </TableCell>
          </TableRow>
          {values.map((row) => (
            <TableRow
              hover
              tabIndex={-1}
              key={row.membership_id}
              style={{
                backgroundColor: '#FFF'
              }}
            >
              <TableCell
                style={{
                  width: '9.0909%'
                }}
              >
                {row.membership_name}
              </TableCell>
              <TableCell
                style={{
                  width: '9%'
                }}
              >
                {row.payment_plan_name}
              </TableCell>
              <TableCell
                style={{
                  width: '9%'
                }}
              >
                {row.membership_type === 'drop_in'
                  ? 'drop in'
                  : row.membership_type}
              </TableCell>
              <TableCell
                style={{
                  width: '9%'
                }}
                align="right"
              >
                {row.number_of_membership}
              </TableCell>

              <TableCell
                style={{
                  width: '9%'
                }}
                align="right"
              >
                <GetPrice value={row.net_revenue} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {values.length === 0 && (
        <p
          style={{
            margin: '10px 15px 10px 0'
          }}
        >
          No revenue to show...
        </p>
      )}
    </TableContainer>
  );
};
RevenueMembershipTable.propTypes = {
  values: PropType.array.isRequired,
  classes: PropType.object.isRequired,
  Summary: PropType.object.isRequired
  // netRevenue: PropType.object.isRequired,
};

export default withStyles(styles)(RevenueMembershipTable);
