import moment from 'moment';
import {
  getpaymentvalue,
  getrenpaymentvalue
} from '../Memberships/NewMembership/AllTable/FinalPrice.utils';

export const paymentOpt = (data) => {
  if (typeof data.start_date !== 'object') {
    return { error: 'Please specify valid Start date' };
  }
  if (!data.payment_data.membership.attendance_limit) {
    return { error: 'Please specify valid Attendance limit' };
  }
  if (
    data.payment_data.membership.attendance_limit
    && data.payment_data.membership.attendance_limit.id
  ) {
    if (
      typeof data.payment_data.membership.attendance_limited_meta.uses
        !== 'number'
      || !data.payment_data.membership.attendance_limited_meta.uses
    ) {
      return { error: 'Please use valid attendance uses' };
    }
    if (!data.payment_data.membership.attendance_limited_meta.type) {
      return { error: 'Please specify valid attendance limit type' };
    }
  }
  if (data.type === 'plan') {
    if (
      typeof data.payment_data.initial_commitment.initial !== 'number'
      || !data.payment_data.initial_commitment.initial
      || data.payment_data.initial_commitment.initial > 52
    ) {
      return { error: 'Please specify valid initial commitment' };
    }
    if (data.payment_data.auto_renew) {
      if (
        typeof data.payment_data.auto_renew_meta.renewal !== 'number'
        || !data.payment_data.auto_renew_meta.renewal
      ) {
        return { error: 'Please specify auto renewal commitment' };
      }
      if (
        data.payment_data.auto_renew_meta
        && (typeof data.payment_data.auto_renew_meta.renewal !== 'number'
          || !data.payment_data.auto_renew_meta.renewal)
      ) {
        return { error: 'Please specify valid auto renew commitment' };
      }
      if (!data.renewal_pricing) {
        return { error: 'Please select renew payment option' };
      }
      if (data.renewal_pricing === 'weekly') {
        if (!data.payment_data.auto_renew_meta.billed_every) {
          return { error: 'Please select auto renew billed' };
        }
        if (
          data.payment_data?.auto_renew_meta?.renewal
          < data.payment_data?.auto_renew_meta?.billed_every?.id
        ) {
          return {
            error:
              "Membership renewal commitment should not be less than member's billing cycle"
          };
        }
      }
    }
  }
  if (data.initial_pricing === 'weekly') {
    if (!data.payment_data.initial_commitment.billed_every) {
      return { error: 'Please specify valid initial commmit billed' };
    }
    if (
      data.payment_data?.initial_commitment?.initial
      < data.payment_data?.initial_commitment?.billed_every?.id
    ) {
      return {
        error:
          "Membership initial commitment should not be less than member's billing cycle"
      };
    }
  }
  if (data.type === 'session') {
    if (
      typeof data.payment_data.membership.no_of_sessions !== 'number'
      || !data.payment_data.membership.no_of_sessions
    ) {
      return { error: 'Please specify valid no of sessions' };
    }

    if (typeof data.usedsession !== 'number') {
      return { error: 'Please specify valid used session' };
    }
    if (data.usedsession > data.payment_data.membership.no_of_sessions) {
      return { error: 'Used Session cant be greater than no of session' };
    }
  }

  if (
    data.type === 'plan'
    && data.is_editable
    && moment(data.start_date)
      .add(data.payment_data.initial_commitment.initial, 'weeks')
      .isBefore(moment().subtract(1, 'day'))
  ) {
    return {
      error: 'Please specify valid start date as membership is already passed'
    };
  }
  if (
    data.type === 'session'
    && data.is_editable
    && data.payment_data.membership.attendance_limited_meta?.id === 1
    && moment(data.start_date)
      .add(
        data.payment_data.membership.attendance_limited_meta.uses,
        data.payment_data.membership.attendance_limited_meta.type?.name
      )
      .isBefore(moment().subtract(1, 'day'))
  ) {
    return {
      error: 'Please specify valid start date as membership is already passed'
    };
  }

  return { data };
};

export const discountsvalid = (data) => {
  const initailsvals = getpaymentvalue(data);
  if (typeof data.discount.initial.amount !== 'number') {
    return { error: 'Please specify valid initial discount' };
  }
  if (typeof data.discount.initial.amount !== 'number') {
    return { error: 'Please speccify valid inital Discount' };
  }
  if (typeof data.discount.setup.amount !== 'number') {
    return { error: 'Please specify valid setup discount' };
  }
  if (
    data.payment_data.auto_renew
    && typeof data.discount.renewal.amount !== 'number'
  ) {
    return { error: 'Please specify valid rewal discount' };
  }
  // if (
  //   data.discount.initial.type === 'percentage' &&
  //   data.discount.initial.amount > 100
  // ) {
  //   return { error: 'Initial Percentage cant be greater than 100' };
  // }
  // if (
  //   data.discount.initial.type === 'fixed' &&
  //   data.discount.initial.amount > initailsvals.full_fee
  // ) {
  //   return { error: 'Initial Discount cant be greater than initial fee' };
  // }
  // if (
  //   data.discount.setup.type === 'percentage' &&
  //   data.discount.setup.amount > 100
  // ) {
  //   return { error: 'Setup Discount Percentage cant be greater than 100' };
  // }
  // if (
  //   data.discount.setup.type === 'fixed' &&
  //   data.type === 'plan' &&
  //   data.discount.setup.amount > initailsvals.setup_fee
  // ) {
  //   return { error: 'Setup Discount cant be greater than Setup fee' };
  // }
  // if (data.payment_data.auto_renew) {
  //   const renwvals = getrenpaymentvalue(data);
  //   if (
  //     data.discount.renewal.type === 'percentage' &&
  //     data.discount.renewal.amount > 100
  //   ) {
  //     return { error: 'Renew Discount percentage cant be greater than 100' };
  //   }
  //   if (
  //     data.discount.renewal.type === 'fixed' &&
  //     data.discount.renewal.amount > renwvals.full_fee
  //   ) {
  //     return { error: 'Renew Discount cant be greater than initial fee' };
  //   }
  // }
  if (
    typeof initailsvals.full_fee !== 'number'
    // initailsvals.full_fee === 0
  ) {
    return { error: 'Please specify valid initial membership fee' };
  }
  if (data.payment_data.auto_renew) {
    const renwvals = getrenpaymentvalue(data);
    if (typeof renwvals.full_fee !== 'number' && data.payment_data.auto_renew) {
      return { error: 'please specify valid renew fee' };
    }
  }
  return { data };
};

export const ProgaccessValid = (data) => {
  if (!Array.isArray(data.program_access) || data.program_access.length === 0) {
    return { error: 'Please select atleast one Program' };
  }
  if (!data.membership_contract) {
    return { error: 'Please select membership contract' };
  }
  return { data };
};
