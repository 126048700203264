import { checkValidString } from '../../../utils/validations';

export const validStep1 = (data, arr, index) => {
  if (checkValidString(data.name)) {
    return { error: 'Name is not valid' };
  }
  for (let i = 0; i < arr.length; i += 1) {
    if (i === index) {
      i += 1;
    } else if (data.name === arr[i].name) {
      return { error: 'Name is already taken' };
    }
  }
  if (!data.tax) {
    return { error: 'Please specify the service tax' };
  }
  if (
    String(data.initial_commitment.initial).length === 0
    || parseFloat(data.initial_commitment.initial) <= 0
    || parseFloat(data.initial_commitment.initial) > 52
  ) {
    return { error: 'Please specify valid initial week' };
  }
  if (!data.initial_commitment.billed_every) {
    return { error: 'Please do enter the weeks to be billed every week' };
  }
  if (
    data.initial_commitment?.initial < data.initial_commitment.billed_every?.id
  ) {
    return {
      error:
        "Membership initial commitment should not be less than member's billing cycle"
    };
  }
  if (data.auto_renew) {
    if (
      String(data.auto_renew_meta.renewal).length === 0
      || parseFloat(data.auto_renew_meta.renewal) <= 0
      || parseFloat(data.auto_renew_meta.renewal) > 52
    ) {
      return { error: 'Please enter the valid auto renew week' };
    }
    if (!data.auto_renew_meta.billed_every) {
      return { error: 'Please do specify auto renew billed week' };
    }
    if (
      data.auto_renew_meta?.renewal < data.auto_renew_meta?.billed_every?.id
    ) {
      return {
        error:
          "Membership renewal commitment should not be less than member's billing cycle"
      };
    }
  }
  return { value: data };
};

export const validStep2 = (data) => {
  const {
    week_membership_fees,
    full_membership_fees
  } = data.initial_commitment_pricing;
  if (
    String(week_membership_fees).length === 0
    || typeof week_membership_fees !== 'number'
  ) {
    return { error: 'Please specify Week Membership fee' };
  }
  if (
    String(full_membership_fees).length === 0
    || typeof full_membership_fees !== 'number'
  ) {
    return { error: 'Please specify Full Membership fee' };
  }
  if (data.auto_renew) {
    if (
      String(data.auto_renewal_pricing.week_membership_fees).length === 0
      || typeof data.auto_renewal_pricing.week_membership_fees !== 'number'
    ) {
      return { error: 'Please do specify  auto renew week membership fee' };
    }
    if (
      String(data.auto_renewal_pricing.full_membership_fees).length === 0
      || typeof data.auto_renewal_pricing.full_membership_fees !== 'number'
    ) {
      return { error: 'Please Specify auto renew full renewal membership fee' };
    }
  }
  return { value: data };
};

export const initialPayment = (data) => {
  const tax = 0;
  // if (data.tax) {
  //   tax = data.tax.rate;
  // }
  if (
    data.initial_commitment.initial === ''
    || data.initial_commitment_pricing.week_membership_fees === ''
    || data.initial_commitment_pricing.week_setup_fees === ''
  ) {
    return 0;
  }
  let result = 0;
  const totalweek = parseFloat(data.initial_commitment.initial)
    / data.initial_commitment.billed_every.id;
  const memfee = parseFloat(data.initial_commitment_pricing.week_membership_fees)
    * totalweek;
  const total = memfee + parseFloat(data.initial_commitment_pricing.week_setup_fees);
  if (tax > 0) {
    const interest = total * (tax / 100);
    result = total + interest;
  } else {
    result = total;
  }
  return result;
};
export const autorenewpayment = (data) => {
  const tax = 0;
  // if (data.tax) {
  //   tax = data.tax.rate;
  // }
  if (
    data.auto_renew_meta.renewal === ''
    || data.auto_renewal_pricing.week_membership_fees === ''
  ) {
    return 0;
  }
  let result = 0;
  const totalweek = parseFloat(data.auto_renew_meta.renewal)
    / data.auto_renew_meta.billed_every.id;
  const memfee = parseFloat(data.auto_renewal_pricing.week_membership_fees) * totalweek;
  const total = memfee;
  if (tax > 0) {
    const interest = total * (tax / 100);
    result = total + interest;
  } else {
    result = total;
  }
  return result;
};

export const StartCost = (data, symbol) => {
  const memberCost = initialPayment(data);

  const TotalCost = parseFloat(data.initial_commitment_pricing.full_setup_fees)
    + parseFloat(data.initial_commitment_pricing.full_membership_fees);
  if (TotalCost !== memberCost) {
    const minCost = Math.min(memberCost, TotalCost);
    return `From ${symbol || 'A$'}` + minCost.toFixed(2);
  }
  return symbol + memberCost;
};

export const Delpayment = (arr, index) => {
  const result = arr.filter((ar, i) => i !== index);
  return result;
};

export const Editpayment = (arr, index, data) => {
  const arr1 = [...arr];
  arr1[index] = data;
  return arr1;
};
