import React, { useContext } from 'react';
import { connect } from 'react-redux';
import { Grid, InputLabel, withStyles } from '@material-ui/core';
import PropType from 'prop-types';
import { MembershipTemplate as MembershipContext } from 'src/Context/MembershipTemplate/MembershipTemplateContext';
import {
  templatechangename,
  changeattlmt,
  changeattlmtuses,
  changeattlmttype,
  changerevencat,
  changetempdesc
} from 'src/Context/MembershipTemplate/membershiptemplate.actions';
import TextareaControl from 'src/views/common/FormControl/TextareaControl';
import FormControl from 'src/views/common/FormControl/formControl';
import { validateInt } from 'src/utils/validations';
import CustomAutoComplete from '../../../calendar/commonAutocomplete';
import styles from '../MembershipTemplate.style';
import { AllAttendanceLmt, AllDayType } from '../Membership.data';
import '../CutomSelect.css';

const PlanDetailsStep = (props) => {
  const {
    classes,
    allrevenCat: { data: revenCats }
  } = props;
  const [state, dispatch] = useContext(MembershipContext);
  const activeRevenCat = revenCats.filter((cat) => cat.status === 1);
  return (
    <div>
      <h3>Name this plan, set the attendance limit and revenue category.</h3>
      <Grid container spacing={0} direction="column">
        <Grid item xs={12}>
          <FormControl
            control="input"
            label="Plan Name"
            placeholder="e.g. Unlimited or 3x a Week"
            required
            value={state?.name}
            onChange={(e) => dispatch(templatechangename(e.target.value))}
            // setErrText="Please provide valid name"
          />
        </Grid>
        <Grid
          container
          spacing={0}
          xs={12}
          disableGutters
          direction="row"
          style={{ marginbottom: '20px' }}
        >
          <Grid container spacing={0} xs={12} disableGutters direction="row">
            <Grid
              item
              xs={6}
              className={classes.time_box}
              style={{
                marginTop: '5px',
                paddingRight:
                  state?.attLmt && state?.attLmt.id === 1 ? null : '15px'
              }}
            >
              <InputLabel
                htmlFor="age-required"
                className={classes.cutom_select_Label}
                required
              >
                Attendance limit
              </InputLabel>
              <CustomAutoComplete
                holder="Select Attendance Limit"
                value={AllAttendanceLmt}
                data={state?.attLmt}
                Options="name"
                style={{ marginbottom: '0' }}
                Change={(val) => dispatch(changeattlmt(val))}
              />
            </Grid>
            {state?.attLmt?.id === 1 && (
              <Grid
                item
                xs={6}
                style={{
                  paddingLeft: '15px',
                  marginTop: '5px'
                }}
              >
                <Grid container spacing={0}>
                  {/* <Grid item xs={6}> */}
                  <Grid
                    item
                    xs={5}
                    className={classes.time_box}
                    style={{ marginTop: '4px', paddingRight: '0px' }}
                  >
                    <FormControl
                      control="input"
                      label=""
                      placeholder="0"
                      required
                      maxLength={10}
                      value={state?.attendance_limited_meta.uses}
                      onChange={(e) =>
                        dispatch(changeattlmtuses(validateInt(e.target.value)))
                      }
                    />
                  </Grid>

                  <Grid
                    item
                    xs={2}
                    className={classes.time_box}
                    style={{
                      marginTop: '0px',
                      textAlign: 'center',
                      padding: '11px 0',
                      fontWeight: 'bold'
                    }}
                  >
                    <InputLabel
                      htmlFor="age-required"
                      className={classes.cutom_select_Label}
                    >
                      &nbsp;
                    </InputLabel>
                    <label style={{ color: '#000' }}>times</label>
                  </Grid>
                  <Grid
                    item
                    xs={5}
                    className={classes.time_box}
                    style={{ marginTop: '0px' }}
                  >
                    <InputLabel
                      htmlFor="age-required"
                      className={classes.cutom_select_Label}
                    >
                      &nbsp;
                    </InputLabel>
                    <CustomAutoComplete
                      holder="Select Type"
                      value={AllDayType}
                      data={state.attendance_limited_meta?.type}
                      Options="name"
                      style={{ marginbottom: '0' }}
                      Change={(val) => dispatch(changeattlmttype(val))}
                    />
                  </Grid>
                  {/* </Grid> */}
                </Grid>
              </Grid>
            )}

            <Grid
              item
              xs={6}
              className={classes.time_box}
              style={{
                marginTop: '5px'
              }}
            >
              <InputLabel
                htmlFor="age-required"
                className={classes.cutom_select_Label}
                required
              >
                Revenue category
              </InputLabel>
              <CustomAutoComplete
                holder="Select Revenue Category"
                value={activeRevenCat || []}
                Options="label"
                data={state?.reveCat}
                style={{ marginbottom: '0' }}
                Change={(val) => dispatch(changerevencat(val))}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={0}
          xs={12}
          disableGutters
          direction="row"
          className="stap-3"
        >
          <Grid
            item
            xs
            className={classes.time_box}
            style={{ marginTop: '8px' }}
          >
            <TextareaControl
              rows={8}
              value={state?.desc}
              onChange={(e) => dispatch(changetempdesc(e.target.value))}
              label="Brief description (may be used for public sales)"
              placeholder="e.g. Unlimited access to all classes and programs"
              className={classes.TextareaControlBox}
              inputProps={{
                maxlength: 1000
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

PlanDetailsStep.propTypes = {
  classes: PropType.object.isRequired,
  allrevenCat: PropType.object.isRequired
};

const mapToState = (state) => ({
  allrevenCat: state.SettingReducer.revenueCtgry
});

export default connect(mapToState)(withStyles(styles)(PlanDetailsStep));
