// eslint-disable-next-line import/prefer-default-export
export const setDefault = (res) => {
  // const membershipDetails = [];
  let result = {
    id: res.id,
    name: res.name,
    membership_ids: '',
    visibility: res.visibiility,
    status: res.status,
    store_payment_info: res.store_payment_info,
    total_signed_contract_users: res.total_signed_contract_users,
    getMemberships: res.getMemberships,
    membership_policy: {
      policy: null,
      initial: 0
    },
    email_template: { subject: null, body: null }
  };
  if (res.membership_ids) {
    result = {
      ...result,
      membership_ids: res.membership_ids
    };
  }
  if (res.membership_policy) {
    result = {
      ...result,
      membership_policy: {
        policy: res.membership_policy?.policy?.replace(/\\/g, ''),
        initial: res.membership_policy?.initial
      }
    };
  }
  if (res.email_template) {
    result = {
      ...result,
      email_template: {
        subject: res.email_template.subject,
        body: res.email_template.body
      }
    };
  }
  return result;
};
