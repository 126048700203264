// eslint-disable-next-line import/prefer-default-export
export const getMemberships = (val, memberships) => {
  let result = [];
  if (val) {
    result = memberships?.filter((i) => i.membership_type === 'session');
  } else {
    result = memberships?.filter((i) => i.membership_type === 'plan');
  }
  return result;
};
