/* eslint-disable operator-linebreak */
import React from 'react';
import PropType from 'prop-types';
import { ROUTES } from 'src/constant/Constants';
import { Link } from 'react-router-dom';
import {
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableSortLabel,
  TableBody,
  Table,
  withStyles
} from '@material-ui/core';
import GetPrice from 'src/views/common/CurrencySymbol/GetPrice';
import filterType from 'src/Redux/Reducer.constants';

const styles = () => ({
  table: {
    minWidth: '100%',
    wordBreak: 'break-word',
    '& td': {
      padding: '10px'
    },
    '@media (max-width: 1366px)': {
      '& td': {
        fontSize: '13px'
      }
    },
    '& .MuiTableCell-head': {
      color: '#FFF'
    },
    '& .MuiTableSortLabel-root:hover': {
      color: '#01847A'
    },
    '& .MuiTableSortLabel-root.MuiTableSortLabel-active': {
      color: '#ffffff'
    },
    '& .MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon': {
      color: '#ffffff'
    },
    '& .MuiTableCell-body': {
      color: '#FFF'
    },
    '& .MuiTableBody-root .MuiTableCell-root': {
      borderBottom: '1px solid rgba(234, 234, 234, 0.2)'
    }
  },
  tableLink: {
    color: '#01847A',
    fontWeight: '700'
  },
  TableCell: {
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px',
    height: '56px',
    minHeight: '56px',
    padding: '10px',
    '@media (max-width: 1366px)': {
      fontSize: '13px',
      wordBreak: 'keep-all'
    }
  },
  Tablemian: {},
  SummaryCell: {
    color: '#292929',
    fontWeight: 'bold'
  }
});

const RevenueCategoryTable = (props) => {
  const { values, classes, isActive, setDirection, setSort, summary } = props;
  const {
    sort_revenue_category,
    sort_location_name,
    sort_members,
    sort_payment_due
  } = filterType;
  return (
    <TableContainer
      component={Paper}
      elevation={0}
      className={classes.Tablemian}
      style={{ borderRadius: '0', backgroundColor: 'transparent' }}
    >
      <Table className={classes.table} aria-label="simple table">
        <TableHead style={{ background: '#3B3C4E' }}>
          <TableRow>
            <TableCell className={classes.TableCell}>
              <TableSortLabel
                direction={setDirection(sort_revenue_category)}
                active={isActive(sort_revenue_category)}
                onClick={() => setSort(sort_revenue_category)}
              >
                Ally Name
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell}>
              <TableSortLabel
                direction={setDirection(sort_location_name)}
                active={isActive(sort_location_name)}
                onClick={() => setSort(sort_location_name)}
              >
                Tier
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell}>
              <TableSortLabel
                direction={setDirection(sort_payment_due)}
                active={isActive(sort_payment_due)}
                onClick={() => setSort(sort_payment_due)}
              >
                Over Max Active Users
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell} align="right">
              <TableSortLabel
                direction={setDirection(sort_members)}
                active={isActive(sort_members)}
                onClick={() => setSort(sort_members)}
              >
                Net Revenue
              </TableSortLabel>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            <>
              <TableRow
                style={{
                  backgroundColor: '#26273B'
                }}
              >
                <TableCell
                  style={{
                    width: '9%'
                  }}
                  className={classes.SummaryCell}
                >
                  Summary
                </TableCell>
                <TableCell
                  style={{
                    width: '9%'
                  }}
                  className={classes.SummaryCell}
                >
                  &nbsp;
                </TableCell>
                <TableCell
                  style={{
                    width: '9%'
                  }}
                  className={classes.SummaryCell}
                >
                  {/* {summary.tot_cnt} */}
                </TableCell>
                <TableCell
                  style={{
                    width: '9%'
                  }}
                  className={classes.SummaryCell}
                  align="right"
                >
                  <GetPrice value={summary.tot_revenue} />
                </TableCell>
              </TableRow>
            </>
          }
          {values &&
            values.data &&
            values.data.map((row) => (
              <TableRow
                hover
                tabIndex={-1}
                key={row.affiliate_id}
                style={{
                  backgroundColor: '#26273B'
                }}
              >
                <TableCell
                  style={{
                    width: '9%'
                  }}
                >
                  <Link
                    className={classes.tableLink}
                    to={{
                      pathname: ROUTES.MASTER_HQ_AFFILIATES_PROFILE,
                      search: `affiliateId=${row.affiliate_id}`
                    }}
                  >
                    {row.ally_name}
                  </Link>
                </TableCell>
                <TableCell
                  style={{
                    width: '9.0909%'
                  }}
                >
                  {row.tier}
                </TableCell>
                <TableCell
                  style={{
                    width: '9%'
                  }}
                >
                  {row.over_max_active_users}
                </TableCell>

                <TableCell
                  style={{
                    width: '9%'
                  }}
                  align="right"
                >
                  <GetPrice value={row.net_revenue} />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      {values.data.length === 0 && (
        <p
          style={{
            margin: '10px 15px',
            color: '#fff'
          }}
        >
          No revenue to show..
        </p>
      )}
    </TableContainer>
  );
};
RevenueCategoryTable.propTypes = {
  values: PropType.array.isRequired,
  classes: PropType.object.isRequired,
  // summary: PropType.array.isRequired,
  setDirection: PropType.func.isRequired,
  isActive: PropType.func.isRequired,
  setSort: PropType.func.isRequired,
  summary: PropType.array.isRequired
};

export default withStyles(styles)(RevenueCategoryTable);
