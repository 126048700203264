import moment from 'moment';
import { AllGender } from '../../CreateMember/CreateMember.data';

export const getProfileData = (data, dateformat) => {
  const values = {
    ...data,
    is_coach: Boolean(data.role_id === 1 && data.is_coach),
    dob: data.dob
      ? moment(data.dob, dateformat).isValid()
        ? moment(data.dob, dateformat).toDate()
        : data.dob
      : null,
    // status: ALlStatus.filter((list) => list.id === data.status)[0],
    gender: AllGender.filter((list) => list.id === data.gender)[0],
    member_since: moment(data.member_since, dateformat).toDate()
  };
  return values;
};

export const getFullAddress = (data) => {
  let { country } = data;
  country = '';
  if (typeof data.country === 'object' && data.country) {
    country = data.country.name;
  }
  const updated = {
    address: data.address,
    address2: data.address2,
    city: data.city,
    province: data.province,
    postal_code: data.postal_code,
    country
  };
  const value = Object.values(updated)
    .filter((l) => l)
    .join(', ');
  return value;
};

export const getEditUser = (data) => {
  let values = {
    role_id: data.role_id || 4,
    is_coach: data.role_id === 1 && data.is_coach ? 1 : 0,
    first_name: data.first_name,
    last_name: data.last_name,
    dob: moment(data.dob).format('YYYY-MM-DD'),
    status: data.status,
    email: data.email,
    gender: data.gender.id,
    address: data.address,
    address2: data.address2,
    city: data.city,
    province: data.province,
    country: data.country.code,
    time_zone: data.time_zone.id,
    postal_code: data.postal_code,
    location_id: data.location_id.id,
    mobile_no: data.mobile_no,
    eme_contact_name: data.eme_contact_name,
    eme_contact_phone: data.eme_contact_phone,
    lead_source: data.lead_source ? data.lead_source.id : null,
    member_login: data.member_login
  };
  if (data.member_login === 1) {
    values = {
      ...values,
      guardian_id: data.guardian_id
    };
  }
  return values;
};
