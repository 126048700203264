import React from 'react';
import { connect } from 'react-redux';
import { Button } from '@material-ui/core';
import PropType from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import FormControl from 'src/views/common/FormControl/formControl';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { openNoti } from 'src/Redux/global/global.actions';
import { sendEmail } from 'src/services/auth.services';
import { getErrorMsg } from 'src/utils/ErrorHandling';

const ResetPassDialog = ({ closeDialog, OpenNotiDisp }) => {
  const initialValues = {
    email: ''
  };
  const validationSchema = Yup.object({
    email: Yup.string()
      .email('Enter valid Email')
      .required('Please Enter Your Email')
  });
  const onSubmitting = async (val, submitProp) => {
    try {
      await sendEmail(val);
      OpenNotiDisp('Successfully sent the email', 'info');
      submitProp.setSubmitting(false);
      closeDialog();
    } catch (err) {
      OpenNotiDisp(getErrorMsg(err), 'error');
      submitProp.setSubmitting(false);
    }
  };
  return (
    <div style={{ width: 540 }}>
      <IconButton
        color="#01847A"
        onClick={() => closeDialog()}
        aria-label="Close"
        style={{ float: 'right', padding: '20px' }}
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle id="alert-dialog-title">
        <h2
          style={{
            textAlign: 'center',
            fontWeight: 'bold',
            fontSize: '32px'
          }}
        >
          Reset Password
        </h2>
      </DialogTitle>
      <p
        style={{
          fontWeight: 'normal',
          fontSize: '14px',
          lineHeight: '18px',
          letterSpacing: '0.002em',
          color: '#7F7F7F',
          padding: '25px 0',
          textAlign: 'center'
        }}
      >
        Don&#39;t worry, happens to the best of us.
      </p>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmitting}
        validationSchema={validationSchema}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          touched,
          isSubmitting,
          isValid,
          values
        }) => (
          <Form>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <FormControl
                  control="input"
                  label="Please enter your email to reset your password"
                  name="email"
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                />
              </DialogContentText>
              <Button
                color="primary"
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                style={{
                  padding: '15px 24px',
                  background: '#01847A',
                  boxShadow: '0px 8px 16px rgba(0, 126, 255, 0.16)',
                  borderRadius: '2px',
                  margin: '30px 0px'
                }}
                disabled={isSubmitting || !isValid}
              >
                send email
              </Button>
            </DialogContent>
          </Form>
        )}
      </Formik>
    </div>
  );
};
ResetPassDialog.propTypes = {
  closeDialog: PropType.func.isRequired,
  OpenNotiDisp: PropType.func.isRequired
};

const mapToDispatch = (dispatch) => ({
  OpenNotiDisp: (msg, type) => dispatch(openNoti(msg, type))
});

export default connect(null, mapToDispatch)(ResetPassDialog);
