export const TobeFilter = [
  { id: 1, filter: 'Auto bill' },
  { id: 2, filter: 'Member' },
  { id: 3, filter: 'Status' },
  { id: 4, filter: 'Date Range' }
];

export const FilterAutobill = [
  { id: 0, name: 'False' },
  { id: 1, name: 'True' }
];

export const FilterStatus = [
  { id: 1, name: 'Unpaid' },
  { id: 2, name: 'Paid' },
  { id: 3, name: 'Voided' },
  { id: 5, name: 'Refunded' },
  { id: 6, name: 'Processing' },
  { id: 7, name: 'Failed' },
  { id: 8, name: 'Required Action' },
  { id: 9, name: 'Refund Pending' }
];

export const isNotData = [
  { id: 0, name: 'is Not' },
  { id: 1, name: 'is' }
];
