import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useRouteMatch } from 'react-router-dom';
import { ChalkBoardContext } from 'src/Context/chalkboardContext';
import ChalkboardSigninFilter from 'src/views/chalkboard/ChalkboardSigninFilter';
import ChalkboardTabs from '../../views/chalkboard/ChalkboardTabs';
import ChalkboardFilter from '../../views/chalkboard/ChalkboardFilter';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#f9f9fa',
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 75,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 286
    },
    [theme.breakpoints.down('md')]: {
      paddingLeft: 286,
      paddingTop: 85
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 286,
      paddingTop: 85
    }
  },
  wrapperLeft: {
    display: 'flex',
    // overflow: 'hidden',
    paddingTop: 140,
    wordBreak: 'break-all',
    width: '285px',
    // height: 'calc(100% - 195px)',
    minHeight: '200px',
    borderRight: '0',
    backgroundColor: '#f9f9fa',
    position: 'fixed',
    [theme.breakpoints.up('lg')]: {
      // paddingLeft: 272
    }
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}));

const ChalkboardLayout = () => {
  const classes = useStyles();
  const match = useRouteMatch();
  return (
    <ChalkBoardContext>
      <div className={classes.root}>
        <div className={classes.wrapperLeft}>
          {!match.isExact ? <ChalkboardFilter /> : <ChalkboardSigninFilter />}
        </div>

        <div className={classes.wrapper}>
          <div className={classes.contentContainer}>
            <div className={classes.content}>
              <ChalkboardTabs />
            </div>
          </div>
        </div>
      </div>
    </ChalkBoardContext>
  );
};

export default ChalkboardLayout;
