import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropType from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import {
  Checkbox,
  Link,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel
} from '@material-ui/core';
import { ROUTES } from 'src/constant/Constants';
import filterType from 'src/Redux/Reducer.constants';
import { openNoti } from 'src/Redux/global/global.actions';
import { ContractEmail } from 'src/services/Contracts.services';
import { get } from 'js-cookie';
import ClassTableToolbar from './MissingTableHead';
import EmailIcon from '../../assets/image/mail.png';
import SignContractDialog from './SignContract/SignContractDialog';

const useStyles = makeStyles({
  table: {
    wordBreak: 'break-word'
  },
  tableLink: {
    color: '#01847A',
    fontWeight: '700'
  },
  dayofweek: {
    background: '#A9BFEF',
    borderRadius: '50px',
    padding: '8px',
    color: '#FFF'
  },
  TooltipLink: {
    '&:hover': {
      '& svg path': {
        stroke: '#01847A'
      }
    }
  },
  TableCell: {
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px',
    borderBottom: '1px solid #A8A8A8',
    height: '56px',
    minHeight: '56px'
  },
  selected: {
    backgroundColor: '#E6F3F2 !important'
  }
});

const Missingtable = (props) => {
  const classes = useStyles();
  const { values, isActive, setDirection, setSort, openNotify } = props;
  const roleid = JSON.parse(get('jbrole') || '{}')?.role_id || 3;

  const {
    sort_member,
    sort_membership,
    sort_date_issued,
    sort_member_status,
    sort_contract
  } = filterType;

  const [selected, setSelected] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({
    open: false,
    id: null
  });

  const handleClick = (name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = values.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  const ClearCheckbox = () => {
    setSelected([]);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleEmailSend = (dataval) => {
    setIsLoading(true);
    ContractEmail(dataval)
      .then(() => {
        openNotify('Email send successfully');
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  return (
    <div>
      <TableContainer component={Paper} elevation={0}>
        {selected.length > 0 && (
          <ClassTableToolbar
            numSelected={selected}
            selectedTags={[]}
            ClearChecks={ClearCheckbox}
            module="class"
          />
        )}
        <Table className={classes.table} aria-label="simple table">
          {selected.length === 0 && (
            <TableHead>
              <TableRow style={{ background: '#F9F9FA' }}>
                <TableCell padding="checkbox" className={classes.TableCell}>
                  <Checkbox
                    color="primary"
                    onChange={handleSelectAllClick}
                    inputProps={{ 'aria-labelledby': 'IDN' }}
                  />
                </TableCell>
                <TableCell
                  style={{ width: '20%' }}
                  className={classes.TableCell}
                >
                  <TableSortLabel
                    active={isActive(sort_member)}
                    direction={setDirection(sort_member)}
                    onClick={() => setSort(sort_member)}
                  >
                    Member
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  className={classes.TableCell}
                  style={{ width: '20%' }}
                >
                  <TableSortLabel
                    active={isActive(sort_contract)}
                    direction={setDirection(sort_contract)}
                    onClick={() => setSort(sort_contract)}
                  >
                    Contract
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  className={classes.TableCell}
                  style={{ width: '18%' }}
                >
                  <TableSortLabel
                    active={isActive(sort_membership)}
                    direction={setDirection(sort_membership)}
                    onClick={() => setSort(sort_membership)}
                  >
                    Membership
                  </TableSortLabel>
                </TableCell>
                <TableCell className={classes.TableCell}>
                  <TableSortLabel
                    active={isActive(sort_member_status)}
                    direction={setDirection(sort_member_status)}
                    onClick={() => setSort(sort_member_status)}
                  >
                    Member Status
                  </TableSortLabel>
                </TableCell>
                <TableCell className={classes.TableCell}>
                  <TableSortLabel
                    active={isActive(sort_date_issued)}
                    direction={setDirection(sort_date_issued)}
                    onClick={() => setSort(sort_date_issued)}
                  >
                    Date Issued
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  align="center"
                  className={classes.TableCell}
                  style={{ width: '10%' }}
                >
                  Send
                </TableCell>
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {values.map((row) => (
              <TableRow
                hover
                // role="checkbox"
                aria-checked={isSelected(row.id)}
                tabIndex={-1}
                key={row.id}
                selected={isSelected(row.id)}
                classes={{ selected: classes.selected }}
                style={{
                  backgroundColor: '#FFF'
                }}
              >
                <TableCell padding="checkbox">
                  <Checkbox
                    onClick={() => handleClick(row.id)}
                    color="primary"
                    checked={isSelected(row.id)}
                    inputProps={{ 'aria-labelledby': 'IDN' }}
                  />
                </TableCell>

                <TableCell
                  style={{
                    position: 'relative',
                    color: '#01847A',
                    fontWeight: '700',
                    width: '20%'
                  }}
                >
                  {roleid === 1 ? (
                    <RouterLink
                      className={classes.tableLink}
                      to={{
                        pathname: ROUTES.PEOPLE_MEMBER_PROFILE,
                        search: `memberId=${btoa(row.user_id)}`
                      }}
                    >
                      {row.member_name}
                    </RouterLink>
                  ) : (
                    <span className={classes.tableLink}>{row.member_name}</span>
                  )}
                </TableCell>
                <TableCell
                  style={{
                    width: '20%'
                  }}
                >
                  <Link
                    className={classes.tableLink}
                    onClick={() => setData({ open: true, id: row.id })}
                    style={{ cursor: 'pointer' }}
                  >
                    {row.contract_name}
                  </Link>
                </TableCell>
                <TableCell>{row.membership_name}</TableCell>
                <TableCell>
                  {row.member_status === 1 ? 'Active' : 'InActive'}
                </TableCell>
                <TableCell>{row.date_issued}</TableCell>
                <TableCell align="center">
                  <span
                    onClick={() => {
                      if (!isLoading) {
                        handleEmailSend(row.id);
                      }
                    }}
                    style={{
                      cursor: 'pointer',
                      display: 'inline-block',
                      pointerEvents: isLoading ? 'none' : '',
                      opacity: isLoading ? '0.6' : ''
                    }}
                  >
                    <img src={EmailIcon} alt="img" />
                  </span>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {values.length === 0 && (
          <p
            style={{
              margin: '10px 15px'
            }}
          >
            No Contracts to show...
          </p>
        )}
      </TableContainer>
      {data.id && data.open ? (
        <SignContractDialog
          id={data.id}
          open={data.open}
          setClose={() => setData({ id: null, open: false })}
          handleHistory={ROUTES.DOCUMENTS_CONTRACTS_SIGNED}
        />
      ) : null}
    </div>
  );
};
Missingtable.propTypes = {
  values: PropType.array.isRequired,
  isActive: PropType.func.isRequired,
  setDirection: PropType.func.isRequired,
  setSort: PropType.func.isRequired,
  openNotify: PropType.func.isRequired
};

const mapToDispatch = (dispatch) => ({
  openNotify: (msg, type) => dispatch(openNoti(msg, type))
});
// export default Missingtable;
export default connect(null, mapToDispatch)(Missingtable);
