import Types from './ContractReducer.types';
import getContracts from './ContractReducer.utils';
import filterlist from '../Reducers.utils';

const initialValue = {
  ActiveMissing: { isFetching: false, data: null, error: null },
  InActiveMissing: { isFetching: false, data: null, error: null },
  AllMissing: { isFetching: false, data: null, error: null },
  Signed: { isFetching: false, data: null, error: null },
  Templates: { isFetching: false, data: null, error: null },
  tempFilter: {
    sorting: {},
    default: {
      filter_by_status: 1,
      status_operator: 1
    }
  },
  ActiveMisFilter: {
    page: 1,
    search: '',
    sorting: {},
    customFilter: {},
    tags: [],
    default: {
      sign_status: 0,
      user_status: 1
    }
  },
  inActiveMisFilter: {
    page: 1,
    search: '',
    sorting: {},
    customFilter: {},
    tags: [],
    default: {
      sign_status: 0,
      user_status: 0
    }
  },
  AllMisFilter: {
    page: 1,
    search: '',
    sorting: {},
    tags: [],
    customFilter: {},
    default: {
      sign_status: 0
    }
  },
  SignedFilters: {
    page: 1,
    search: '',
    sorting: {},
    customFilter: {},
    tags: [],
    default: {
      sign_status: 1
    }
  }
};

export default (state = initialValue, action) => {
  switch (action.type) {
    case Types.activeMiss:
      return {
        ...state,
        ActiveMissing: getContracts(
          Types.activeMiss,
          action.actionType,
          state.ActiveMissing,
          action.payload
        )
      };
    case Types.inactiveMiss:
      return {
        ...state,
        InActiveMissing: getContracts(
          Types.inactiveMiss,
          action.actionType,
          state.InActiveMissing,
          action.payload
        )
      };
    case Types.allMiss:
      return {
        ...state,
        AllMissing: getContracts(
          Types.inactiveMiss,
          action.actionType,
          state.AllMissing,
          action.payload
        )
      };
    case Types.Signed:
      return {
        ...state,
        Signed: getContracts(
          Types.inactiveMiss,
          action.actionType,
          state.Signed,
          action.payload
        )
      };
    case Types.contTemp:
      return {
        ...state,
        Templates: getContracts(
          Types.contTemp,
          action.actionType,
          state.Templates,
          action.payload
        )
      };
    case Types.contTempFil:
      return {
        ...state,
        tempFilter: filterlist(state.tempFilter, action.filter, action.payload)
      };
    case Types.activeMissFilter:
      return {
        ...state,
        ActiveMisFilter: filterlist(
          state.ActiveMisFilter,
          action.filter,
          action.payload
        )
      };
    case Types.inactiveMissFilter:
      return {
        ...state,
        inActiveMisFilter: filterlist(
          state.inActiveMisFilter,
          action.filter,
          action.payload
        )
      };
    case Types.allMissFilter:
      return {
        ...state,
        AllMisFilter: filterlist(
          state.AllMisFilter,
          action.filter,
          action.payload
        )
      };
    case Types.SignedFilter:
      return {
        ...state,
        SignedFilters: filterlist(
          state.SignedFilters,
          action.filter,
          action.payload
        )
      };
    default:
      return state;
  }
};
