import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {},
  mobileDrawer: {
    width: 256
  },
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  navitems: {
    '& .list-items.Mui-selected': {
      backgroundColor: 'transparent'
    },
    '& .list-items.Mui-selected .MuiButton-label span': {
      color: '#ffffff'
    },
    '& .list-items.Mui-selected .MuiButton-label svg path': {
      stroke: '#01847A'
    },
    '& .list-items.Mui-selected .SvgStyle .MuiButton-label svg path': {
      fill: '#01847A',
      stroke: 'none'
    },
    // hover css
    '& .list-items:hover .MuiButton-label span': {
      color: '#fff'
    },
    '& .list-items:hover .MuiButton-label svg path': {
      stroke: '#01847A'
    },
    '& .list-items:hover .SvgStyle .MuiButton-label svg path': {
      fill: '#01847A',
      stroke: 'none'
    }
  },
  button: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'flex-start',
    letterSpacing: 0,
    padding: '14px 8px',
    textTransform: 'none',
    width: '100%'
  },
  desktopDrawer: {
    width: 285,
    top: 104,
    height: 'calc(100% - 195px)',
    backgroundColor: theme.masterhq.root.backgroundColor,
    overflow: 'auto',
    minHeight: '200px',
    borderRight: '0'
  },
  listName: {
    paddingLeft: theme.spacing(1),
    fontFamily: 'Lato',
    fontWeight: 900,
    color: '#7F7F7F',
    textTransform: 'uppercase',
    lineHeight: '18px',
    letterSpacing: '0.5px'
  },
  collapselistName: {
    paddingLeft: theme.spacing(2),
    textTransform: 'uppercase',
    fontSize: '14'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  },
  active: {
    color: '#01847A',
    '& $title': {
      fontWeight: theme.typography.fontWeightMedium
    },
    '& $icon': {
      color: '#01847A'
    },
    background: '#EFF4F5',
    borderRadius: 0,
    '&::before': {
      content: '""',
      width: 2,
      height: 32,
      background: '#01847A',
      position: 'absolute',
      left: 0
    }
  },
  myactive: {
    color: '#01847A',
    '&::before': {
      content: '""',
      left: 0,
      right: 0,
      width: 60,
      height: 2,
      position: 'absolute',
      background: '#01847A',
      bottom: 2,
      textAlign: 'center',
      margin: '0 auto',
      borderTopLeftRadius: 2,
      borderTopRightRadius: 2
    },
    '& svg path': {
      fill: '#01847A'
    }
  },
  nested: {
    padding: 0,
    '& svg': {
      display: 'block',
      textAlign: 'center',
      margin: '0 auto 5px'
    }
  },
  othermenu: {
    // padding: '0',
    background: 'white',
    boxShadow: 'rgb(37 40 43 / 8%) 0px 4px 16px',
    borderRadius: '5px',
    width: '260px',
    fontWeight: '700',
    '& .sub-nested-menu a': {
      fontWeight: '700',
      color: '#292929',
      borderRadius: '0',
      backgroundColor: '#fff'
    },
    '& .sub-nested-menu': {
      borderBottom: '1px solid rgb(196 196 196 / 30%)'
    },
    '& .sub-nested-menu:last-child': {
      borderBottom: '0px solid rgb(196 196 196 / 30%)'
    },
    '& a.sub-active-menu': {
      fontWeight: '700',
      color: '#01847A',
      '&::before': {
        content: '""',
        left: '0',
        right: 'auto',
        width: '2px',
        height: '32px',
        position: 'absolute',
        background: '#01847A',
        bottom: 'auto',
        textAlign: 'center',
        margin: '0 auto',
        borderTopLeftRadius: 2,
        borderTopRightRadius: 2
      }
      // '& svg path': {
      //   fill: '#01847A'
      // }
    },
    '& svg': {
      margin: '0 14px 0px 5px'
    },
    '&.sub-othermenu li:hover span': {
      color: '#01847a'
    },
    '&.sub-othermenu li:hover span svg path': {
      fill: '#01847A'
    }
  },
  othermenuList: {
    top: '-10px',
    '& .MuiPaper-root': {
      height: '115px',
      overflowY: 'hidden',
      background: 'transparent',
      boxShadow: 'none'
    },
    '& .MuiPaper-root::before': {
      content: '""',
      position: 'absolute',
      width: '9px',
      height: '9px',
      left: '224.36px',
      background: '#FFFFFF',
      transform: 'rotate(45deg)',
      bottom: '6px'
    }
  }
}));

export default useStyles;
