/* eslint-disable function-paren-newline */
/* eslint-disable operator-linebreak */
/* eslint-disable no-confusing-arrow */
/* eslint-disable indent */
import React, { useState, useEffect } from 'react';
import PropType from 'prop-types';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { getMemSetting } from 'src/services/Membersip.services';
import {
  Grid,
  Card,
  Typography,
  Box,
  Breadcrumbs,
  InputLabel,
  withStyles,
  FormControlLabel,
  Radio,
  RadioGroup,
  Button
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import FormControl from 'src/views/common/FormControl/formControl';
// import JBquillEditor from 'src/views/common/JBQuillEditor/JBquillEditor';
import IOSSwitch from 'src/views/common/FormControl/IOSSwitch';
import JBStepper from 'src/views/common/JBGridStepper';
import ColorlibStepIcon from 'src/views/common/JBGridStepper/ColorlibStepIcon';
import CancelDialog from 'src/views/common/CommonDialogBox/CancelDialog';
import AddIcon from '@material-ui/icons/Add';
import { ROUTES } from 'src/constant/Constants';
import { getErrorMsg } from 'src/utils/ErrorHandling';

import {
  createNewWaiver,
  getWaiverName
} from 'src/services/Contracts.services';
import { connect } from 'react-redux';
import JBCKEditor from 'src/views/common/JBCKEditor';
import { checkValidString, isValidQuill } from 'src/utils/validations';
import { checkQuillValid } from 'src/utils/someCommon';
import { openNoti } from 'src/Redux/global/global.actions';
import { getGymSetup } from 'src/Redux/GymSetupReducer/GymSetup.actions';
import useChangeTitle from 'src/views/common/CustomHooks/useTitleChange';
import CustomAutoComplete from '../../common/CustomAutocomplete/commonAutocomplete';
import { ReactComponent as BasicInformation } from '../../../assets/image/JBSteppericon/BasicInformation.svg';
import { ReactComponent as BookopenIcon } from '../../../assets/image/JBSteppericon/bookopen.svg';
import { ReactComponent as Questionmark } from '../../../assets/image/JBSteppericon/questionmark.svg';
import { ReactComponent as EmailIcon } from '../../../assets/image/JBSteppericon/email.svg';
import { ReactComponent as Btnup } from '../../../assets/image/Btnup.svg';
import { ReactComponent as Btndown } from '../../../assets/image/Btndown.svg';
import { ReactComponent as Btnclose } from '../../../assets/image/Btnclose.svg';
import DeleteBtn from '../../../assets/image/dDelete.png';
import JBButton from '../../common/JBButton/JBButton';
import WaiverTooltip from './WaiverTooltip';
import styles from './Waivers.style';
import { dummyDataNewWaiver, AllQue } from './NewWaiver.data';
import {
  deleteMcqAns,
  getValidStep1,
  getValidStep2,
  getValidStep4
} from './newWaiver.utils';
import { getTempWav } from '../../../Redux/WaiverReducer/WaiverReducer.actions';

function getSteps() {
  return [
    'Basic Details',
    'Terms',
    'Questionnaire',
    'Email Template & Visibility'
  ];
}

const NewWaiver = ({
  classes,
  openNotify,
  WaivFilter,
  getGymSteps,
  gymSteps
}) => {
  const steps = getSteps();
  const [activeStep, setActiveStep] = useState(0);
  const [data, setData] = useState(dummyDataNewWaiver);
  const [que, setQue] = useState('');
  const [que_id, setId] = useState(1);
  // const [, setMemSetting] = useState(null);
  const [mcqOption, setMcqOption] = useState(3);
  const history = useHistory();
  const [isprocessing, setprocessing] = useState(false);
  const [WaiverEnforcement, setWaiverEnforcement] = useState(0);
  useChangeTitle('New Waiver Template | Jungle Alliance');
  const [isWaiLoading, setWaiIsLoading] = useState(true);

  // const [touched, setTouched] = useState({
  //   questionnaire_meta: false
  // });

  useEffect(() => {
    getMemSetting()
      .then((res) => {
        setWaiIsLoading(false);
        setWaiverEnforcement(res.waiver_enforcement);
      })
      .catch((err) => console.warn(err));
  }, []);
  const handleQue = () => {
    if (que) {
      if (que === 'Yes/No with follow-up') {
        setData({
          ...data,
          questionnaire_meta: [
            ...data.questionnaire_meta,
            {
              id: que_id,
              que_type: que,
              que: null,
              required: 1,
              touchedfield: false,
              follow_up: null,
              touchedfieldfollow: false
            }
          ]
        });
      } else if (que === 'Multiple Choice') {
        setData({
          ...data,
          questionnaire_meta: [
            ...data.questionnaire_meta,
            {
              id: que_id,
              que_type: que,
              que: null,
              required: 1,
              touchedfield: false,
              answer_choices: [
                { id: 1, value: null, touchedfield: false },
                { id: 2, value: null, touchedfield: false }
              ],
              allow_multiple_ans: 0
            }
          ]
        });
      } else {
        setData({
          ...data,
          questionnaire_meta: [
            ...data.questionnaire_meta,
            {
              id: que_id,
              que_type: que,
              que: '',
              touchedfield: false,
              required: 1
            }
          ]
        });
      }
      setId(que_id + 1);
    }
  };

  const validateQues = (validateArray) => {
    let flag = 0;
    const result = validateArray;
    for (let i = 0; i < validateArray.length; i += 1) {
      if (!validateArray[i].que || checkValidString(validateArray[i].que)) {
        flag = 1;
        result[i].touchedfield = true;
        document
          .getElementById(`waiversfieldform${validateArray[i].id}`)
          .focus();
      }
      if (typeof validateArray[i].follow_up !== 'undefined') {
        if (checkValidString(validateArray[i]?.follow_up)) {
          flag = 1;
          result[i].touchedfieldfollow = true;
          document
            .getElementById(`waiversfieldform${validateArray[i].id}`)
            .focus();
        }
      }
      if (validateArray[i].que_type === 'Multiple Choice') {
        for (let j = 0; j < validateArray[i].answer_choices.length; j += 1) {
          if (
            !validateArray[i].answer_choices[j].value ||
            validateArray[i].answer_choices[j].value.trim().length === 0
          ) {
            flag = 1;
            result[i].answer_choices[j].touchedfield = true;
            document
              .getElementById(
                `waiversmcqfield${validateArray[i].id}form${validateArray[i].answer_choices[j].id}`
              )
              .focus();
          }
        }
      }
    }
    if (flag === 1) {
      setData({ ...data, questionnaire_meta: result });
      return false;
    }
    return true;
  };
  const createMcqOption = (index) => {
    const newArray = [...data.questionnaire_meta];
    let createMcq = [...data.questionnaire_meta[index].answer_choices];

    createMcq = [
      ...createMcq,
      {
        id: mcqOption,
        value: null,
        touchedfield: false
      }
    ];
    newArray[index] = {
      ...newArray[index],
      answer_choices: createMcq
    };
    setData({
      ...data,
      questionnaire_meta: newArray
    });
    setMcqOption(mcqOption + 1);
  };

  const createValidateWaiver = (validateData) => {
    let result = validateData;
    if (result.name === null) {
      openNotify('Waiver name is empty', 'error');
    }
    result = {
      ...result,
      // waiver_terms_meta: JSON.stringify(validateData.waiver_terms_meta),
      terms: isValidQuill(validateData.waiver_terms_meta.terms)
        ? validateData.waiver_terms_meta.terms
        : `<p>${validateData.waiver_terms_meta.terms}</p>`,
      initial: validateData.waiver_terms_meta.initial,
      questionnaire_meta: JSON.stringify(validateData.questionnaire_meta),
      email_template: JSON.stringify(validateData.email_template)
    };
    return result;
  };
  const handleCreateWaiver = () => {
    const validateData = createValidateWaiver(data);
    setprocessing(true);
    createNewWaiver(validateData)
      .then(() => {
        WaivFilter();
        setprocessing(false);
        history.push(ROUTES.DOCUMENTS_WAIVERS_TEMPLATES);
      })
      // eslint-disable-next-line no-confusing-arrow
      .then(() =>
        validateData.is_published === 1
          ? openNotify(`Waiver "${validateData.name}" created successfully`)
          : openNotify(`Waiver "${validateData.name}" saved as a draft`)
      )
      .then(() => {
        if (gymSteps.process_type.waiver === 0) {
          getGymSteps();
        }
      })
      .catch((err) => {
        openNotify(getErrorMsg(err), 'error');
        setprocessing(false);
      });
  };

  const handleNext = () => {
    if (activeStep === 0) {
      if (!data.name) {
        openNotify('Waiver name is empty', 'error');
      } else if (checkValidString(data.name)) {
        openNotify('Please provide valid waiver name', 'error');
      } else {
        getWaiverName({ waiver_name: data.name })
          .then(() => setActiveStep((prevActiveStep) => prevActiveStep + 1))
          .catch(() =>
            openNotify('The waiver name has already been taken', 'error')
          );
      }
    } else if (
      activeStep === 1 &&
      (!data.waiver_terms_meta.terms ||
        !checkQuillValid(data.waiver_terms_meta.terms))
    ) {
      openNotify('Membership Policy is required field', 'error');
    } else if (!validateQues(data.questionnaire_meta) && activeStep === 2) {
      openNotify('You may have an empty cell.', 'error');
    } else if (activeStep === 3) {
      if (data.is_published) {
        if (data.email_template.subject && data.email_template.body) {
          if (
            checkValidString(data.email_template.subject) ||
            checkValidString(data.email_template.body)
          ) {
            openNotify('Email subject and body is not valid ', 'error');
          } else {
            handleCreateWaiver();
          }
        } else {
          openNotify('Email subject and body is required field', 'error');
        }
      } else if (data.email_template.subject && data.email_template.body) {
        if (
          !checkValidString(data.email_template.subject) &&
          !checkValidString(data.email_template.body)
        ) {
          handleCreateWaiver();
        } else {
          openNotify('Email subject and body is not valid ', 'error');
        }
      } else if (data.email_template.subject && !data.email_template.body) {
        if (checkValidString(data.email_template.subject)) {
          openNotify('Email subject is not valid', 'error');
        } else {
          handleCreateWaiver();
        }
      } else if (!data.email_template.subject && data.email_template.body) {
        if (checkValidString(data.email_template.body)) {
          openNotify('Email body is not valid', 'error');
        } else {
          handleCreateWaiver();
        }
      } else if (!data.email_template.subject && !data.email_template.body) {
        handleCreateWaiver();
      }
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };
  const handleRequired = (index) => {
    const newArray = [...data.questionnaire_meta];
    newArray[index] = {
      ...newArray[index],
      required: !data.questionnaire_meta[index].required * 1
    };
    setData({
      ...data,
      questionnaire_meta: newArray
    });
  };
  const handleDelete = (index) => {
    setData({
      ...data,
      questionnaire_meta: [...data.questionnaire_meta].filter(
        (list) => list.id !== index
      )
    });
  };
  const handleUp = (index) => {
    const newArray = [...data.questionnaire_meta];
    const newIndex = newArray.findIndex((x) => x.id === index);
    if (newIndex > 0) {
      newArray[newIndex] = {
        ...newArray[newIndex],
        id: index - 1
      };
      newArray[newIndex - 1] = {
        ...newArray[newIndex - 1],
        id: index
      };

      newArray.sort((a, b) => {
        return a.id - b.id;
      });

      setData({
        ...data,
        questionnaire_meta: newArray
      });
    }
  };
  const handleDown = (index) => {
    const newArray = [...data.questionnaire_meta];
    const newIndex = newArray.findIndex((x) => x.id === index);
    if (newIndex < data.questionnaire_meta.length - 1) {
      newArray[newIndex] = {
        ...newArray[newIndex],
        id: index + 1
      };
      newArray[newIndex + 1] = {
        ...newArray[newIndex + 1],
        id: index
      };

      newArray.sort((a, b) => {
        return a.id - b.id;
      });
      setData({
        ...data,
        questionnaire_meta: newArray
      });
    }
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleReset = () => {
    setActiveStep(0);
  };

  const handleBlur = (id) => {
    setData({
      ...data,
      questionnaire_meta: data.questionnaire_meta.map((ques) =>
        ques.id === id
          ? { ...ques, touchedfield: true, touchedfieldfollow: true }
          : ques
      )
    });
  };

  const handleMCQBlur = (typeid, id) => {
    setData({
      ...data,
      questionnaire_meta: data.questionnaire_meta.map((ques) =>
        ques.id === typeid
          ? {
              ...ques,
              answer_choices: ques.answer_choices.map((subque) =>
                subque.id === id ? { ...subque, touchedfield: true } : subque
              )
            }
          : ques
      )
    });
  };
  // useEffect(() => {
  //   getMemSetting().then((res) => setMemSetting(res));
  // }, []);
  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <div>
            <p className={classes.contrP}>
              Name this waiver. All information (Full Name, Home Address, Email
              Address, Date of Birth, Gender, Phone Number and Emergency Contact
              Name & Number) will be automatically fetched from members profile.
            </p>
            <Box mb="18px" />
            <FormControl
              control="input"
              label="Waiver name"
              placeholder=""
              required
              value={data.name}
              onChange={(e) => {
                setData({ ...data, name: e.target.value });
              }}
              maxLength={50}
              style={{ margin: '0 0' }}
            />
            <Box mb="50px" />
          </div>
        );
      case 1:
        return (
          <div>
            <p className={classes.contrP}>
              Type or paste your current waiver terms into the text area below
              and format it appropriately.
            </p>
            <Box mb="18px" />
            <InputLabel
              htmlFor="age-required"
              className={classes.cutom_select_Label}
            >
              Waiver Terms
            </InputLabel>
            <JBCKEditor
              data={data.waiver_terms_meta?.terms}
              onChange={(e) =>
                setData({
                  ...data,
                  waiver_terms_meta: {
                    ...data.waiver_terms_meta,
                    terms: e.editor.getData()
                  }
                })
              }
            />
            {/* <JBquillEditor
              value={data.waiver_terms_meta.terms}
              theme="snow"
              onChange={(value) => {
                setData({
                  ...data,
                  waiver_terms_meta: {
                    ...data.waiver_terms_meta,
                    terms: value
                  }
                });
              }}
              ErrorText="Contract details must be fill valid values"
            /> */}
            <Box className={classes.Initialbox}>
              <FormControlLabel
                control={
                  <IOSSwitch
                    checked={data.waiver_terms_meta.initial}
                    onChange={() => {
                      setData({
                        ...data,
                        waiver_terms_meta: {
                          ...data.waiver_terms_meta,
                          initial: !data.waiver_terms_meta.initial * 1
                        }
                      });
                    }}
                  />
                }
                style={{ display: 'inline-block', margin: '0 0' }}
              />

              <InputLabel
                htmlFor="age-required"
                className={classes.cutom_Switch_Label}
              >
                Initial box
              </InputLabel>
              <WaiverTooltip style={{ display: 'inline-block' }} />
            </Box>
            <Box mb="30px" />
          </div>
        );
      case 2:
        return (
          <div>
            <p className={classes.contrP}>
              Create custom questions for your member to answer in regards to
              health history, exercise habits, injuries, etc.
            </p>
            <Box mb="25px" />
            <Grid item xs={12}>
              {data.questionnaire_meta.map((type, index) => {
                if (type.que_type === 'Yes/No') {
                  return (
                    <Box className={classes.QuestionBox}>
                      <Box className={classes.QueBtnWrapper}>
                        <h5>Yes/No</h5>
                        <Box className={classes.QueBtnBk}>
                          <Button onClick={() => handleUp(type.id)}>
                            <Btnup />
                          </Button>
                          <Button onClick={() => handleDown(type.id)}>
                            <Btndown />
                          </Button>
                          <Button onClick={() => handleDelete(type.id)}>
                            <Btnclose />
                          </Button>
                        </Box>
                      </Box>
                      <FormControl
                        control="input"
                        label="Type a question"
                        placeholder=""
                        id={`waiversfieldform${type.id}`}
                        value={type.que}
                        onBlur={() => handleBlur(type.id)}
                        error={Boolean(
                          type.touchedfield && checkValidString(type.que)
                        )}
                        helperText={
                          type.touchedfield && checkValidString(type.que)
                            ? 'Please enter valid question'
                            : null
                        }
                        onChange={(e) => {
                          const newArray = [...data.questionnaire_meta];
                          newArray[index] = {
                            ...newArray[index],
                            que: e.target.value
                          };
                          setData({ ...data, questionnaire_meta: newArray });
                        }}
                        style={{ margin: '0 0' }}
                        // error={Boolean(touched.questionnaire_meta)}
                        maxLength={500}
                        seterrors={!type.que}
                      />
                      <Box mt="25px" />
                      <Box>
                        <FormControlLabel
                          control={
                            <IOSSwitch
                              checked={data.questionnaire_meta[index].required}
                              onChange={() => handleRequired(index)}
                            />
                          }
                          style={{ display: 'inline-block', margin: '0 0' }}
                        />
                        <InputLabel
                          htmlFor="age-required"
                          className={classes.cutom_Switch_Label}
                        >
                          Required
                        </InputLabel>
                      </Box>
                    </Box>
                  );
                }
                if (type.que_type === 'Freeform Answer Box') {
                  return (
                    <Box className={classes.QuestionBox}>
                      <Box className={classes.QueBtnWrapper}>
                        <h5>Freeform Answer Box</h5>
                        <Box className={classes.QueBtnBk}>
                          <Button onClick={() => handleUp(type.id)}>
                            <Btnup />
                          </Button>
                          <Button onClick={() => handleDown(type.id)}>
                            <Btndown />
                          </Button>
                          <Button onClick={() => handleDelete(type.id)}>
                            <Btnclose />
                          </Button>
                        </Box>
                      </Box>
                      <FormControl
                        control="input"
                        label="Type a question"
                        placeholder=""
                        id={`waiversfieldform${type.id}`}
                        value={type.que}
                        onBlur={() => handleBlur(type.id)}
                        error={Boolean(
                          type.touchedfield && checkValidString(type.que)
                        )}
                        helperText={
                          type.touchedfield && checkValidString(type.que)
                            ? 'Please enter valid question'
                            : null
                        }
                        onChange={(e) => {
                          const newArray = [...data.questionnaire_meta];
                          newArray[index] = {
                            ...newArray[index],
                            que: e.target.value
                          };
                          setData({ ...data, questionnaire_meta: newArray });
                        }}
                        style={{ margin: '0 0' }}
                        maxLength={500}
                        seterrors={!type.que}
                      />
                      <Box mt="25px" />
                      <Box>
                        <FormControlLabel
                          control={
                            <IOSSwitch
                              checked={data.questionnaire_meta[index].required}
                              onChange={() => handleRequired(index)}
                            />
                          }
                          style={{ display: 'inline-block', margin: '0 0' }}
                        />
                        <InputLabel
                          htmlFor="age-required"
                          className={classes.cutom_Switch_Label}
                        >
                          Required
                        </InputLabel>
                      </Box>
                    </Box>
                  );
                }
                if (type.que_type === 'Multiple Choice') {
                  return (
                    <Box className={classes.QuestionBox}>
                      <Box className={classes.QueBtnWrapper}>
                        <h5>Multiple Choice</h5>
                        <Box className={classes.QueBtnBk}>
                          <Button onClick={() => handleUp(type.id)}>
                            <Btnup />
                          </Button>
                          <Button onClick={() => handleDown(type.id)}>
                            <Btndown />
                          </Button>
                          <Button onClick={() => handleDelete(type.id)}>
                            <Btnclose />
                          </Button>
                        </Box>
                      </Box>
                      <Box>
                        <FormControl
                          control="input"
                          label="Type a question"
                          placeholder=""
                          value={type.que}
                          id={`waiversfieldform${type.id}`}
                          onBlur={() => handleBlur(type.id)}
                          error={Boolean(
                            type.touchedfield && checkValidString(type.que)
                          )}
                          helperText={
                            type.touchedfield && checkValidString(type.que)
                              ? 'Please enter valid question'
                              : null
                          }
                          onChange={(e) => {
                            const newArray = [...data.questionnaire_meta];
                            newArray[index] = {
                              ...newArray[index],
                              que: e.target.value
                            };
                            setData({ ...data, questionnaire_meta: newArray });
                          }}
                          style={{ margin: '0 0' }}
                          maxLength={500}
                          seterrors={!type.que}
                        />
                      </Box>
                      {type.answer_choices.map((blank, index2) => {
                        return (
                          <Box display="flex" alignItems="center">
                            <FormControl
                              control="input"
                              label=""
                              value={blank.value}
                              id={`waiversmcqfield${type.id}form${blank.id}`}
                              onBlur={() => handleMCQBlur(type.id, blank.id)}
                              error={Boolean(
                                blank.touchedfield &&
                                  checkValidString(blank.value)
                              )}
                              helperText={
                                blank.touchedfield &&
                                checkValidString(blank.value)
                                  ? 'Please enter valid answers'
                                  : null
                              }
                              onChange={(e) => {
                                const createMcq = [
                                  ...data.questionnaire_meta[index]
                                    .answer_choices
                                ];
                                createMcq[index2] = {
                                  ...createMcq[index2],
                                  value: e.target.value
                                };
                                const newArray = [...data.questionnaire_meta];
                                newArray[index] = {
                                  ...newArray[index],
                                  answer_choices: createMcq
                                };
                                setData({
                                  ...data,
                                  questionnaire_meta: newArray
                                });
                              }}
                              placeholder="Type answer here"
                              style={{ margin: '15px 0 0' }}
                              maxLength={500}
                              seterrors={!blank.value}
                            />

                            {type.answer_choices &&
                            type.answer_choices.length > 2 ? (
                              <Button
                                onClick={() => {
                                  setData({
                                    ...data,
                                    questionnaire_meta: deleteMcqAns(
                                      data.questionnaire_meta,
                                      type.id,
                                      blank.id
                                    )
                                  });
                                }}
                                className={classes.QueBtnDel}
                              >
                                <img src={DeleteBtn} alt="img" />
                              </Button>
                            ) : (
                              <></>
                            )}
                          </Box>
                        );
                      })}

                      <Box mt="20px" />
                      <JBButton
                        onClick={() => createMcqOption(index)}
                        type="outlined"
                      >
                        <AddIcon
                          className={classes.BtnAddIcon}
                          fontSize="small"
                        />
                        add an answer
                      </JBButton>
                      <Box mt="25px" />
                      <Box>
                        <FormControlLabel
                          control={
                            <IOSSwitch
                              checked={
                                data.questionnaire_meta[index]
                                  .allow_multiple_ans
                              }
                              onChange={() => {
                                const newArray = [...data.questionnaire_meta];
                                newArray[index] = {
                                  ...newArray[index],
                                  allow_multiple_ans:
                                    !data.questionnaire_meta[index]
                                      .allow_multiple_ans * 1
                                };
                                setData({
                                  ...data,
                                  questionnaire_meta: newArray
                                });
                              }}
                            />
                          }
                          style={{ display: 'inline-block', margin: '0 0' }}
                        />
                        <InputLabel
                          htmlFor="age-required"
                          className={classes.cutom_Switch_Label}
                        >
                          Allow multiple answers
                        </InputLabel>
                      </Box>
                      <Box>
                        <FormControlLabel
                          control={
                            <IOSSwitch
                              checked={data.questionnaire_meta[index].required}
                              onChange={() => {
                                const newArray = [...data.questionnaire_meta];
                                newArray[index] = {
                                  ...newArray[index],
                                  required:
                                    !data.questionnaire_meta[index].required * 1
                                };
                                setData({
                                  ...data,
                                  questionnaire_meta: newArray
                                });
                              }}
                            />
                          }
                          style={{ display: 'inline-block', margin: '0 0' }}
                        />
                        <InputLabel
                          htmlFor="age-required"
                          className={classes.cutom_Switch_Label}
                        >
                          Required
                        </InputLabel>
                      </Box>
                    </Box>
                  );
                }
                if (type.que_type === 'Yes/No with follow-up') {
                  return (
                    <Box className={classes.QuestionBox}>
                      <Box className={classes.QueBtnWrapper}>
                        <h5>Yes/No with follow-up</h5>
                        <Box className={classes.QueBtnBk}>
                          <Button onClick={() => handleUp(type.id)}>
                            <Btnup />
                          </Button>
                          <Button onClick={() => handleDown(type.id)}>
                            <Btndown />
                          </Button>
                          <Button onClick={() => handleDelete(type.id)}>
                            <Btnclose />
                          </Button>
                        </Box>
                      </Box>
                      <Box>
                        <FormControl
                          control="input"
                          label="Type a question"
                          value={type.que}
                          id={`waiversfieldform${type.id}`}
                          onBlur={() => handleBlur(type.id)}
                          error={Boolean(
                            type.touchedfield && checkValidString(type.que)
                          )}
                          helperText={
                            type.touchedfield && checkValidString(type.que)
                              ? 'Please enter valid question'
                              : null
                          }
                          onChange={(e) => {
                            const newArray = [...data.questionnaire_meta];
                            newArray[index] = {
                              ...newArray[index],
                              que: e.target.value
                            };
                            setData({ ...data, questionnaire_meta: newArray });
                          }}
                          placeholder=""
                          style={{ margin: '0 0' }}
                          maxLength={500}
                          seterrors={!type.que}
                        />
                      </Box>
                      <Box>
                        <FormControl
                          control="input"
                          label="Type a follow-up question"
                          placeholder="Type answer here"
                          value={type.follow_up}
                          id={`waiversfieldform${type.id}`}
                          onBlur={() => handleBlur(type.id)}
                          error={Boolean(
                            type.touchedfield &&
                              checkValidString(type.follow_up)
                          )}
                          helperText={
                            type.touchedfield &&
                            checkValidString(type.follow_up)
                              ? 'Please enter valid FollowUps'
                              : null
                          }
                          onChange={(e) => {
                            const newArray = [...data.questionnaire_meta];
                            newArray[index] = {
                              ...newArray[index],
                              follow_up: e.target.value
                            };
                            setData({ ...data, questionnaire_meta: newArray });
                          }}
                          style={{ margin: '15px 0 0' }}
                          maxLength={500}
                          seterrors={!type.follow_up}
                        />
                      </Box>
                      <Box mt="25px" />
                      <Box>
                        <FormControlLabel
                          control={
                            <IOSSwitch
                              checked={data.questionnaire_meta[index].required}
                              onChange={() => {
                                const newArray = [...data.questionnaire_meta];
                                newArray[index] = {
                                  ...newArray[index],
                                  required:
                                    !data.questionnaire_meta[index].required * 1
                                };
                                setData({
                                  ...data,
                                  questionnaire_meta: newArray
                                });
                              }}
                            />
                          }
                          style={{ display: 'inline-block', margin: '0 0' }}
                        />
                        <InputLabel
                          htmlFor="age-required"
                          className={classes.cutom_Switch_Label}
                        >
                          Required
                        </InputLabel>
                      </Box>
                    </Box>
                  );
                }
                return null;
              })}
            </Grid>
            <Box mt="30px" />
            <Grid item xs={12}>
              <Box className={classes.AddQuestion}>
                <InputLabel
                  htmlFor="age-required"
                  className={classes.cutom_select_Label}
                >
                  Add question
                </InputLabel>

                <CustomAutoComplete
                  holder={que || 'Add question type'}
                  data={que}
                  value={AllQue}
                  Options="name"
                  Change={(e) => {
                    // eslint-disable-next-line no-unused-expressions
                    e ? setQue(e.name) : setQue(null);
                  }}
                />
              </Box>
              <JBButton type="primary" onClick={handleQue}>
                Add
              </JBButton>
            </Grid>
            {/* <Box mb="30px" /> */}
          </div>
        );
      case 3:
        return (
          <div>
            <p className={classes.contrP}>
              Customize the email template that will be sent to the members.
            </p>
            <Box mb="18px" />
            <FormControl
              control="input"
              label="Email subject"
              placeholder=""
              value={data.email_template.subject}
              onChange={(e) => {
                setData({
                  ...data,
                  email_template: {
                    ...data.email_template,
                    subject: e.target.value
                  }
                });
              }}
              maxLength={500}
              style={{ margin: '0 0' }}
            />
            <Box mt="15px" />
            {/* <TextareaControl
              rows={8}
              label="Text"
              value={data.email_template.body}
              onChange={(e) => {
                setData({
                  ...data,
                  email_template: {
                    ...data.email_template,
                    body: e.target.value
                  }
                });
              }}
              className={classes.TextareaControlBox}
            /> */}
            <InputLabel
              htmlFor="age-required"
              className={classes.cutom_select_Label}
            >
              Text
            </InputLabel>
            <JBCKEditor
              data={data.email_template.body}
              onChange={(e) => {
                setData({
                  ...data,
                  email_template: {
                    ...data.email_template,
                    body: e.editor.getData()
                  }
                });
              }}
            />
            <Grid item xs={12}>
              <Box mt="30px">
                <p className={clsx(classes.contrP)}>Visibility</p>

                <RadioGroup
                  value={String(data.is_published)}
                  onChange={(e) => {
                    setData({ ...data, is_published: e.target.value * 1 });
                  }}
                >
                  <FormControlLabel
                    value="1"
                    control={<Radio color="primary" />}
                    label="Published"
                  />
                  <FormControlLabel
                    value="0"
                    control={<Radio color="primary" />}
                    label="Draft (not available for members to sign)"
                  />
                </RadioGroup>
              </Box>
            </Grid>
            <Box mb="30px" />
          </div>
        );
      default:
        return 'Unknown step';
    }
  };

  return (
    <div className={classes.root}>
      <div className="title-bk">
        <Breadcrumbs aria-label="breadcrumb">
          <div color="inherit">documents</div>
          <Typography variant="h3" color="Primary">
            <span>waivers</span>
          </Typography>
        </Breadcrumbs>
      </div>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="flex-end"
        spacing={0}
        style={{ margin: '20px 0 15px' }}
      >
        <Grid item xs={8}>
          <h1 style={{ margin: '0 0' }}>New Waiver Template</h1>
        </Grid>
        <Grid
          item
          xs={4}
          className={classes.btnBlock}
          style={{ justifyContent: 'flex-end', display: 'flex' }}
        >
          <JBButton
            type="primary"
            onClick={handleCreateWaiver}
            style={{ marginRight: '10px' }}
          >
            save as a Draft
          </JBButton>
          <CancelDialog />
        </Grid>
      </Grid>
      <Card className={classes.contractCard} elevation={0}>
        <JBStepper
          steps={steps}
          activeStep={activeStep}
          handleBack={handleBack}
          handleNext={handleNext}
          handleReset={handleReset}
          handleStepContent={getStepContent}
          isLoading={false}
          hasErrored={false}
          validateBtn={[
            getValidStep1(data),
            getValidStep2(data),
            false,
            getValidStep4(data)
          ]}
          prevLabel="CREATE"
          nextLabel="NEXT STEP"
          isprocessing={isprocessing}
          processingLabel="Creating..."
          CustComp={(prop) => (
            <ColorlibStepIcon
              FirstIcon={BasicInformation}
              SecondIcon={BookopenIcon}
              ThirdIcon={Questionmark}
              FourthIcon={EmailIcon}
              {...prop} // eslint-disable-line
            />
          )}
        />
      </Card>
      {isWaiLoading === true
        ? null
        : WaiverEnforcement === 0 && (
            // eslint-disable-next-line react/jsx-indent
            <div className={classes.contractDisabled}>
              <Alert
                severity="warning"
                icon={false}
                className={classes.contractAlert}
              >
                <Grid container direction="row">
                  <Grid item xs={9}>
                    <AlertTitle className={classes.contractAlertTitle}>
                      Waiver Enforcement Disabled
                    </AlertTitle>
                    <p>
                      Your waiver isn`t being enforced. Turn on Waiver
                      Enforcement to prevent members or leads from reserving or
                      signing in to class if they have a missing waiver.
                    </p>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end'
                    }}
                  >
                    <Button
                      className={classes.contractAlertbtn}
                      onClick={() =>
                        history.push(ROUTES.CLASSES_SETTINGS_MEMBERSHIP)
                      }
                    >
                      Update settings
                    </Button>
                  </Grid>
                </Grid>
              </Alert>
            </div>
          )}
    </div>
  );
};

NewWaiver.propTypes = {
  classes: PropType.object.isRequired,
  history: PropType.any.isRequired,
  WaivFilter: PropType.func.isRequired,
  openNotify: PropType.func.isRequired,
  getGymSteps: PropType.func.isRequired,
  gymSteps: PropType.number.isRequired
};
const mapToState = (state) => ({
  gymSteps: state.GymSetupReducer.data
});
const mapToDispatch = (dispatch) => ({
  openNotify: (msg, type) => dispatch(openNoti(msg, type)),
  WaivFilter: (filter, data) => dispatch(getTempWav(filter, data)),
  getGymSteps: () => dispatch(getGymSetup())
});
export default connect(
  mapToState,
  mapToDispatch
)(withStyles(styles)(NewWaiver));
