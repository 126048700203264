const styles = (theme) => ({
  root: {
    marginTop: 0,
    margin: '0px 20px 20px',
    padding: '0px 20px 20px'
  },
  mainCard: {
    boxShadow: '4px 0px 16px rgba(37, 40, 43, 0.08)',
    borderRadius: '5px',
    background: theme.masterhq.mainCard.background,
    padding: 20
  },
  ptext: {
    fontSize: '14px',
    lineHeight: '22px',
    color: '#7F7F7F',
    fontWeight: 'normal'
  },
  cutom_select_Label: {
    fontSize: '12px',
    '&:focus ': {
      outline: '0'
    }
  },
  invoiceheadBox: {
    display: 'inline-block;',
    marginRight: '40px',
    '& span': {
      fontWeight: 'bold',
      fontSize: '14px',
      lineHeight: '18px',
      color: '#949494',
      display: 'block',
      letterSpacing: '0.5px',
      marginBottom: '4px'
    },
    '& label': {
      fontWeight: 'bold',
      fontSize: '14px',
      lineHeight: '18px',
      color: '#fff',
      display: 'block',
      letterSpacing: '0.5px',
      marginBottom: '4px'
    }
  },
  totalItem: {
    '& span': {
      fontWeight: 'bold',
      fontSize: '14px',
      lineHeight: '18px',
      color: '#fff',
      display: 'block',
      letterSpacing: '0.5px',
      padding: '11px 15px'
    }
  },
  totalDue: {
    backgroundColor: '#3b3c4e',
    borderRadius: '8px',
    '& span': {
      fontWeight: '900',
      fontSize: '17px',
      padding: '17px 15px'
    }
  },
  TextareaControlNots: {
    '& .MuiInput-root': {
      margin: '0 0 !important',
      padding: '13.5px 15px 13.5px'
    },
    '& textarea': {
      width: '100%',
      resize: 'auto',
      maxHeight: '100px',
      overflowY: 'auto !important',
      color: '#fff'
    },
    '& textarea::placeholder': {
      color: '#fff !important',
      fontWeight: '500',
      fontSize: '14px'
    }
  },
  InvoiceCard: {
    padding: '30px'
  },
  invoicestatus: {
    display: 'block;',
    marginBottom: '15px',
    '& span': {
      fontWeight: 'bold',
      fontSize: '14px',
      lineHeight: '18px',
      color: '#949494',
      display: 'inline-block',
      letterSpacing: '0.5px',
      marginBottom: '4px',
      marginRight: '4px'
    },
    '& label': {
      fontWeight: 'bold',
      fontSize: '14px',
      lineHeight: '18px',
      color: '#fff',
      display: 'inline-block',
      letterSpacing: '0.5px',
      marginBottom: '4px'
    }
  },
  SearchTrans: {
    padding: '2px 6px 0',
    minWidth: 'auto',
    '& span': {
      marginBottom: '0px'
    },
    '& svg g path': {
      stroke: '#fff'
    }
  },
  cutom_date_css: {
    '& .MuiInput-root': {
      marginTop: '0px !important',
      background: '#323345',
      borderRadius: '2px'
    }
  },
  refunded_amount: {
    display: 'inline-flex',
    alignItems: 'center',
    '& span': {
      paddingLeft: '0',
      paddingRight: '0'
    }
  },
  masterhqAutocomplete: {
    '& .MuiAutocomplete-root': {
      color: '#ffffff',
      background: 'rgba(255, 255, 255, 0.1)',
      borderRadius: '2px',
      '& .MuiIconButton-root': {
        color: 'rgb(255 255 255 / 100%)'
      }
    }
  },
  AutoBill: {
    '& .MuiFormControlLabel-label': {
      color: '#ffffff'
    }
  },
  cutom_Payment_date: {
    '& .MuiInputBase-root.Mui-disabled': {
      color: 'rgb(84 83 83)'
    }
  }
});
export default styles;
