import React, { useContext } from 'react';
import PropType from 'prop-types';
import clsx from 'clsx';
import { AlliesProfile } from 'src/Context/AlliesContext';
import {
  setDiscountAmount,
  setDiscountType,
  setDiscountValue,
  resetValues
} from 'src/Context/MasterHQAllies/masterhqallies.actions';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  DialogTitle,
  withStyles,
  Box,
  RadioGroup,
  Input,
  InputAdornment,
  Radio,
  FormControlLabel,
  Grid
} from '@material-ui/core';
import JBButton from 'src/views/common/JBButton/JBButton';
import CurrencyInputs from 'src/views/common/CurrencyControlForm/CurrencyInputs';
import PercentageFormControl from '@material-ui/core/FormControl';
import { validateFloat, validPrice } from 'src/utils/validations';
// import questionmark from '../../../../../assets/image/questionmark.png';
import FollowupFilters from './Filters/Filters';

const styles = (theme) => ({
  Dialogtitle: {
    padding: '16px 24px 0',
    color: '#fff'
  },
  DialogActionsBk: {
    padding: '5px 20px 20px'
  },
  PercentageFormControl: {
    color: '#292929',
    border: '1px solid #a9a9a9',
    padding: '8px 15px 8px',
    fontSize: '16px',
    maxHeight: '48px',
    width: 'auto',
    minWidth: '75px',
    maxWidth: '80px',
    '&.MuiInput-underline.Mui-error': {
      border: '1px solid #f44336'
    },
    '&.MuiInput-underline.Mui-error input[type="text"]': {
      border: '0px solid #f44336'
    },
    '& .MuiInput-underline.Mui-disabled:before': {
      borderBottomStyle: 'none'
    }
  },
  TranCharge: {
    '& label': {
      fontWeight: '700',
      marginBottom: '5px',
      display: 'block',
      fontSize: '14px'
    }
  },
  DialogPopup: {
    '& .MuiPaper-root': {
      background: theme.masterhq.mainCard.background
    },
    '& .MuiFormControlLabel-label': {
      color: '#fff'
    },
    '& .DiscountBlock .MuiInput-root': { color: '#fff !important' },
    '& .DiscountBlock .MuiInputAdornment-root': { color: '#fff !important' },
    '& .DiscountBlock .MuiInputBase-input': { color: '#fff' }
  }
});

function ApplyDialog({ classes, handleClose, setOpen, confirmApply }) {
  const [state, dispatch] = useContext(AlliesProfile);
  const {
    subscription_details: { discount }
  } = state;
  const handleChange = (event) => {
    dispatch(setDiscountType(event.target.value));
  };
  let buttondis = true;
  if (discount.discount_valid_type?.id === 3) {
    if (discount.discount_type === 'percentage') {
      if (discount.discount_value > 0) {
        buttondis = false;
      }
    } else if (discount.discount_amount > 0) {
      buttondis = false;
    }
  } else if (discount.discount_valid_type?.id === 2) {
    if (discount.discount_type === 'percentage') {
      if (discount.discount_value > 0 && discount.end_date) {
        buttondis = false;
      }
    } else if (discount.discount_amount > 0 && discount.end_date) {
      buttondis = false;
    }
  } else if (discount.discount_type === 'percentage') {
    if (discount.discount_value > 0 && discount.no_of_month) {
      buttondis = false;
    }
  } else if (discount.discount_amount > 0 && discount.no_of_month) {
    buttondis = false;
  }

  return (
    <div>
      <Dialog
        open={setOpen}
        onClose={() => {
          handleClose();
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick
        className={classes.DialogPopup}
      >
        <div style={{ width: '400px' }}>
          <DialogTitle id="alert-dialog-title" className={classes.Dialogtitle}>
            {/* <img
              alt="Plusicon"
              src={questionmark}
              style={{ display: 'inline-block', verticalAlign: 'sub' }}
            /> */}
            <Typography
              color="#fff"
              variant="h5"
              component="div"
              className={classes.title}
              style={{ display: 'inline-block', marginLeft: '0px' }}
            >
              Apply discount
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Grid item xs={12}>
              <Box
                css={{
                  display: 'flow-root',
                  alignItems: 'center'
                }}
                className={classes.TranCharge}
              >
                <Typography
                  color="#fff"
                  variant="h6"
                  component="label"
                  style={{
                    fontSize: '16px',
                    marginRight: '15px',
                    color: '#fff'
                  }}
                >
                  Discount
                </Typography>
                <Box
                  css={{
                    display: 'inline-flex',
                    backgroundColor: 'rgb(59, 60, 78)',
                    padding: '20px 20px',
                    borderRadius: '8px',
                    width: 'auto',
                    alignItems: 'center'
                  }}
                  className="DiscountBlock"
                >
                  <Box className="RadioGroupBk">
                    <RadioGroup
                      style={{
                        flexDirection: 'row',
                        justifyContent: 'left',
                        flexWrap: 'nowrap'
                      }}
                      onChange={handleChange}
                      value={discount.discount_type}
                    >
                      <FormControlLabel
                        value="percentage"
                        label="Percentage"
                        control={<Radio color="primary" />}
                        style={{ margin: '0 0' }}
                      />
                      <FormControlLabel
                        value="fixed"
                        label="Fixed"
                        control={<Radio color="primary" />}
                        style={{ margin: '0px 0px 0px 5px' }}
                      />
                    </RadioGroup>
                  </Box>

                  {discount.discount_type === 'fixed' ? (
                    <CurrencyInputs
                      // placeholder={currencies.currency_symbol}
                      // value={week_setup_fees}
                      placeholder=""
                      value={discount.discount_amount}
                      onChange={(e) =>
                        dispatch(
                          setDiscountAmount(
                            validPrice(validateFloat(e.target.value))
                          )
                        )
                      }
                      maxLength={10}
                      currencySymbol="A$"
                      style={{
                        margin: '0 0',
                        width: '80px',
                        maxWidth: '80px'
                      }}
                    />
                  ) : (
                    <PercentageFormControl
                      className={clsx(
                        classes.PercentageFormControl,
                        discount.discount_value <= 100
                          ? null
                          : 'MuiInput-underline Mui-error'
                      )}
                      style={{
                        width: 'auto'
                      }}
                    >
                      <Input
                        id="standard-adornment-weight"
                        value={discount.discount_value}
                        onChange={(e) => {
                          dispatch(
                            setDiscountValue(
                              validPrice(validateFloat(e.target.value))
                            )
                          );
                        }}
                        endAdornment={
                          <InputAdornment
                            position="end"
                            style={{
                              color: '#292929',
                              margin: '-1px 0 0 0px'
                            }}
                          >
                            <span>%</span>
                          </InputAdornment>
                        }
                        aria-describedby="standard-weight-helper-text"
                        inputProps={{
                          'aria-label': 'weight'
                        }}
                        style={{
                          color: '#292929'
                        }}
                      />
                    </PercentageFormControl>
                  )}
                </Box>

                <Typography
                  color="#fff"
                  variant="h6"
                  component="label"
                  style={{
                    fontSize: '16px',
                    margin: '10px 0 5px',
                    color: '#fff'
                  }}
                >
                  Discount Valid Till
                </Typography>
                <FollowupFilters />
              </Box>
            </Grid>
          </DialogContent>
          <DialogActions className={classes.DialogActionsBk}>
            <Button
              variant="outlined"
              onClick={() => {
                dispatch(resetValues());
                handleClose();
              }}
              style={{
                height: '48px',
                color: '#7F7F7F',
                fontWeight: 'bold'
              }}
            >
              Cancel
            </Button>
            <JBButton
              type="primary"
              onClick={confirmApply}
              disabled={buttondis}
            >
              Apply
            </JBButton>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}

ApplyDialog.propTypes = {
  classes: PropType.object.isRequired,
  setOpen: PropType.bool.isRequired,
  handleClose: PropType.func.isRequired,
  confirmApply: PropType.func.isRequired
};
export default withStyles(styles)(ApplyDialog);
