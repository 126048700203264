import React from 'react';
import {
  Grid,
  withStyles,
  RadioGroup,
  Radio,
  FormControlLabel,
  Box
} from '@material-ui/core';
import PropType from 'prop-types';
import ProgramTable from './ProgramTable';
import { DummyDataCB } from './staticData';
import styles from './NewTemplate.style';
import {
  changeprogacess,
  chngesigncontract
} from '../Memberships/NewMembership/NewMembership.actions';

const Programs = ({ classes, reducer, changes }) => {
  const [state, dispatch] = reducer;

  return (
    <div>
      <Grid
        container
        spacing={0}
        xs={12}
        disableGutters
        direction="row"
        style={{
          margin: '30px 0'
        }}
      >
        <Grid
          item
          xs={3}
          style={{
            marginTop: '0px',
            paddingLeft: '0px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start'
          }}
        >
          <h3>Program Access</h3>
          <p className={classes.ptext}>
            Select the programs included with this membership.
          </p>
        </Grid>
        <Grid item xs={9}>
          <Grid
            container
            direction="row"
            spacing={0}
            style={{
              alignItems: 'center'
            }}
            className={classes.checkBoxTable}
          >
            <ProgramTable
              headNames={DummyDataCB}
              values={state.data.payment_data.programes}
              initialSelected={state.data.program_access}
              getSelected={(e) => dispatch(changeprogacess(e))}
              isDisabled={Boolean(state.data.status === 2)}
            />
          </Grid>
        </Grid>
      </Grid>

      {state.data.type === 'drop_in' ? (
        ''
      ) : (
        <>
          {changes.length > 0 ? (
            <Grid
              container
              spacing={0}
              xs={12}
              disableGutters
              direction="row"
              style={{
                margin: '30px 0'
              }}
            >
              <Grid
                item
                xs={3}
                style={{
                  marginTop: '0px',
                  paddingLeft: '0px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start'
                }}
              >
                <h3>Contract</h3>
                <p className={classes.ptext}>
                  Sign in this member plan contract now, email to be signed
                  later, or keep the current.
                </p>
              </Grid>
              <Grid item xs={9}>
                <Grid
                  container
                  direction="row"
                  spacing={0}
                  style={{
                    alignItems: 'center'
                  }}
                  className={classes.checkBoxTable}
                >
                  {changes && changes.length > 0 ? (
                    <Grid item xs={12}>
                      <p>
                        You are updating sections of this membership that are
                        listed &nbsp;
                        {/* eslint-disable-next-line react/no-unescaped-entities */}
                        on the member's contract:
                      </p>
                      <Box>
                        <ul className={classes.UpdateInfo}>
                          {changes.map((list, i) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <li key={i}>{list}</li>
                          ))}
                        </ul>
                      </Box>
                    </Grid>
                  ) : null}

                  <Grid
                    item
                    xs={12}
                    style={{ marginTop: '15px', marginBottom: '20px' }}
                  >
                    <p
                      style={{
                        marginBottom: '20px'
                      }}
                    >
                      You may want your member to sign a new contract that
                      reflects the updates. Generating a new contract will
                      invalidate the current contract.
                    </p>
                    <RadioGroup
                      value={state.data.membership_contract}
                      onChange={(e) =>
                        dispatch(chngesigncontract(e.target.value))
                      }
                    >
                      <FormControlLabel
                        value="sign_contract"
                        label="Sign new contract now"
                        control={<Radio color="primary" />}
                        style={{ margin: '0 0' }}
                      />
                      <FormControlLabel
                        value="send_contract"
                        label="Send new contract"
                        control={<Radio color="primary" />}
                        style={{ margin: '0 0' }}
                      />
                      <FormControlLabel
                        value="keep_contract"
                        label="Keep current contract"
                        control={<Radio color="primary" />}
                        style={{ margin: '0 0' }}
                      />
                    </RadioGroup>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : null}
        </>
      )}

      {/* <Grid>
        <ul>
          {updates.map((list, i) => (
            <li key={i}>{list}</li>
          ))}
        </ul>
      </Grid> */}
    </div>
  );
};

Programs.propTypes = {
  classes: PropType.object.isRequired,
  reducer: PropType.array.isRequired,
  changes: PropType.array.isRequired
};

export default withStyles(styles)(Programs);
