export default {
  SetProgram: 'SET_NEW_PROGRAM',
  SetDate: 'SET_NEW_DATE',
  getClassOpt: 'GET_CLASS_OTIONS',
  SetClass: 'SET_YOUR_CLASS',
  SetWorkoutData: 'SET_WORKOUT_DATA',
  SetError: 'SET_ERROR',
  setSignProgram: 'SET_SIGN_IN_PROGRAM',
  setSignDate: 'SET_SIGN_IN_DATE',
  setSignClassOpt: 'SET_SIGN_IN_CLASS_OPTIONS',
  setSignClass: 'SET_SIGN_IN_CLASS',
  setSignError: 'SET_SIGN_IN_ERROR',
  setSignMember: 'SET_SIGN_IN_MEMBER',
  setLoginedUser: 'SET_SIGN_IN_LOGINED_USER',
  setSignDialog: 'SET_SIGN_IN_DIALOG',
  setDialogMsg: 'SET_SIGN_IN_DIALOG_MSG',
  setSignProcessing: 'SET_SIGN_IN_PROCESSING',
  setSignButton: 'SET_SIGN_IN_BUTTON',
  setlogineduserbyClass: 'SET_SIGN_IN_LOGIN_USER_BY_CLASS',
  removeLoginedUser: 'SET_SIGN_IN_REMOVE_LOGINED_USER',
  removeReservedMembers: 'SET_SIGN_IN_REMOVE_RESERVED_MEMBERS'
};
