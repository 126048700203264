import filterType from 'src/Redux/ClassReducer/class.constant';
import { store } from 'src/Redux/rootReducer';

const Paginate = (val, data, actionfunc) => {
  // eslint-disable-next-line
  if (data.current_page !== val)
    store.dispatch(actionfunc(filterType.page, val)); //eslint-disable-line
};

export default Paginate;

export function getCoaches(selected, data) {
  const result = [];
  const selectedData = [];
  for (let i = 0; i < selected.length; i++) {
    for (let j = 0; j < data.length; j++) {
      if (data[j].id === selected[i]) {
        selectedData.push(data[j]);
      }
    }
  }
  for (let i = 0; i < selectedData.length; i++) {
    if (
      i < selectedData.length - 1
      && selectedData[i].program_id !== selectedData[i + 1].program_id
    ) {
      return { error: true };
    }
    const b = {
      id: selectedData[i].head_coach,
      name: selectedData[i].head_coach_name
    };
    result.push(b);
  }
  return { data: result };
}
