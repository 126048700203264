const styles = () => ({
  cutom_select_Label: {
    fontSize: '12px !important',
    '&:focus ': {
      outline: '0'
    }
  },
  FiltersWrapper: {
    background: '#fff'
  },
  cutom_Date: {
    background: '#26273b',
    '& .MuiInput-root': {
      margin: '0 0 !important'
    },
    '&.Datefilter div div:nth-child(1)': { marginTop: '0 !important' }
  }
});
export default styles;
