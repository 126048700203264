/* eslint-disable operator-linebreak */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Grid,
  Card,
  InputLabel,
  withStyles,
  Box,
  Dialog,
  Breadcrumbs,
  Typography
} from '@material-ui/core';
import PropType from 'prop-types';
import TextareaControl from 'src/views/common/FormControl/TextareaControl';
import queryString from 'query-string';
import { getContracts } from 'src/Redux/SettingReducer/SettingReducer.actions';
import FormControl from 'src/views/common/FormControl/formControl';
import CustomAutoComplete from 'src/views/common/CustomAutocomplete/commonAutocomplete';
import useChangeTitle from 'src/views/common/CustomHooks/useTitleChange';
import CheckBoxTable from '../../common/CheckBoxTable/checkBoxTable';
import RadioButtonTable from '../RadioTable';
import { RadiotableHead } from './staticDataRadio';
import { DummyDataCB } from './staticData';
import styles from './EditPlanTemplate.style';
import JBButton from '../../common/JBButton/JBButton';
import DeleteDialog from '../DeleteDialog';
import '../CutomSelect.css';
import CancelDialog from '../../common/CommonDialogBox/CancelDialog';
import LoadingView from '../../common/loading';
import NotFoundView from '../../errors/NotFoundView';
import {
  getMemberDetail,
  updateMemTemp
} from '../../../services/Membersip.services';
import Plusicon from '../../../assets/image/plusicon.png';
import PaymentPlan from '../PaymentPlan/PaymentPlan';
import { getEditTemp } from './EditPlanTemplate.utils';
import PaymentPlanTable from '../MembershipTemplate/PlanTemplates/paymentplanTable';
import {
  AllAttendanceLmt,
  AllDayType
} from '../MembershipTemplate/Membership.data';
import { validateInt } from '../../../utils/validations';
import { Delpayment, Editpayment } from '../PaymentPlan/PaymentPlan.validation';
import { editPlanValidate, getUpdateData } from './editPlanTemp.validation';
import { getErrorMsg } from '../../../utils/ErrorHandling';
import { openNoti } from '../../../Redux/global/global.actions';
import { ROUTES } from '../../../constant/Constants';
import CancelDialogTwo from '../../common/CommonDialogBox/CancelDialogTwo';
import NewContractDialog from '../MembershipTemplate/PlanTemplates/NewContractDialog';

const EditPlanTemplate = (props) => {
  const {
    classes,
    location,
    history,
    getContractsUpdate,
    contracts: { data: allContracts, error: conterror },
    AllGymloc: { data: gyms, error: gymerror },
    RevenCat: { data: revens, error: revenerror },
    TaxRates: { data: taxes, error: taxerror },
    allprograms
  } = props;
  useChangeTitle('Edit Plan Membership Template | Jungle Alliance');
  const { membershipId } = queryString.parse(location.search);
  const [data, setData] = useState(null);
  const [errorFetch, setErrorFetch] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [saved, setsaved] = useState(false);
  const [cancelDialog, setcancelDialog] = useState(false);
  const [openDialogBox, setopenDialogBox] = useState({
    id: null,
    open: false
  });

  useEffect(() => {
    getContractsUpdate();
    if (gyms && revens && taxes) {
      getMemberDetail(membershipId)
        .then((res) => setData(getEditTemp(res, gyms, revens, taxes)))
        .catch((err) => {
          console.warn('HAving Errorrrrrr', err);
          setErrorFetch(true);
        });
    }
  }, [gyms, revens, taxes]); // eslint-disable-line

  useEffect(() => {
    if (!saved) {
      setsaved(true);
    }
  }, [data]); // eslint-disable-line

  if (
    errorFetch ||
    gymerror ||
    revenerror ||
    taxerror ||
    conterror ||
    !membershipId
  ) {
    return <NotFoundView />;
  }
  if (!gyms || !revens || !taxes || !allContracts) return <LoadingView />;

  const handleSave = () => {
    const { value, error } = editPlanValidate(data);
    const { OpenNoti } = props;
    if (error) {
      OpenNoti(error, 'error');
      setsaved(false);
    } else {
      const values = getUpdateData(value);
      updateMemTemp(values, membershipId)
        .then(() => history.push(ROUTES.CLASSES_MEMBERSHIPS))
        .then(() => OpenNoti('Successfully updated the Template', 'info'))
        .catch((err) => OpenNoti(getErrorMsg(err), 'error'));
      setsaved(false);
    }
  };

  const handleReload = () => {
    setopenDialogBox({ id: null, open: false });
    getContractsUpdate();
  };

  return (
    <div className={classes.root}>
      <div className="title-bk">
        {/* <h3>
          Classes <label>/</label> membership <label>/</label>
          <span>edit plan template</span>
        </h3> */}
        <Breadcrumbs
          aria-label="breadcrumb"
          style={{
            margin: '0px 0 21px'
          }}
        >
          <div color="inherit">Classes</div>
          <div
            color="inherit"
            onClick={() => setcancelDialog(true)}
            style={{
              cursor: 'pointer'
            }}
          >
            membership
          </div>
          <Typography variant="h3" color="Primary">
            <span>edit plan template</span>
          </Typography>
        </Breadcrumbs>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={0}
          style={{ margin: '0 0 20px' }}
        >
          <Grid item xs={6}>
            <h1 style={{ margin: '0 0' }}>Edit Plan Membership Template</h1>
          </Grid>
          <Grid item xs={6} className={classes.btnBlock}>
            {data && <DeleteDialog id={membershipId} name="plan" />}
          </Grid>
        </Grid>
      </div>
      {!data ? (
        <LoadingView />
      ) : (
        <Box>
          <Card className={classes.mainCard}>
            <Grid xs={12}>
              <div>
                <h3>
                  Name this plan, set the attendance limit and revenue category.
                </h3>
                <Grid container spacing={0} direction="column">
                  <Grid item xs={12}>
                    <FormControl
                      control="input"
                      label="Plan Name"
                      placeholder="e.g. Unlimited or 3x a Week"
                      required
                      value={data.name}
                      onChange={(e) =>
                        setData({ ...data, name: e.target.value })
                      }
                    />
                  </Grid>
                  <Grid
                    container
                    spacing={0}
                    xs={12}
                    disableGutters
                    direction="row"
                    style={{ marginbottom: '20px' }}
                  >
                    <Grid
                      container
                      spacing={0}
                      xs={12}
                      disableGutters
                      direction="row"
                    >
                      <Grid
                        item
                        xs={6}
                        className={classes.time_box}
                        style={{ marginTop: '0px', paddingRight: '15px' }}
                      >
                        <InputLabel
                          htmlFor="age-required"
                          className={classes.cutom_select_Label}
                          required
                        >
                          Attendance limit
                        </InputLabel>
                        <CustomAutoComplete
                          holder="Attendance Limit"
                          data={data.attendance_limit}
                          value={AllAttendanceLmt}
                          Options="name"
                          style={{ marginbottom: '0' }}
                          Change={(val) =>
                            setData({ ...data, attendance_limit: val })
                          }
                        />
                      </Grid>
                      {data.attendance_limit && data.attendance_limit.id === 1 && (
                        <Grid xs={6}>
                          <Grid
                            container
                            spacing={0}
                            disableGutters
                            direction="row"
                          >
                            <Grid
                              item
                              xs={5}
                              className={classes.time_box}
                              style={{ marginTop: '4px', paddingRight: '0px' }}
                            >
                              <FormControl
                                control="input"
                                label=""
                                required
                                maxLength={10}
                                value={data.attendance_limited_meta.uses}
                                onChange={(e) =>
                                  setData({
                                    ...data,
                                    attendance_limited_meta: {
                                      ...data.attendance_limited_meta,
                                      uses: validateInt(e.target.value)
                                    }
                                  })
                                }
                              />
                            </Grid>
                            <Grid
                              item
                              xs={2}
                              className={classes.time_box}
                              style={{
                                marginTop: '0px',
                                textAlign: 'center',
                                padding: '11px 0',
                                fontWeight: 'bold'
                              }}
                            >
                              <InputLabel
                                htmlFor="age-required"
                                className={classes.cutom_select_Label}
                              >
                                &nbsp;
                              </InputLabel>
                              <label style={{ color: '#000' }}>times</label>
                            </Grid>
                            <Grid
                              item
                              xs={5}
                              className={classes.time_box}
                              style={{ marginTop: '0px' }}
                            >
                              <InputLabel
                                htmlFor="age-required"
                                className={classes.cutom_select_Label}
                              >
                                &nbsp;
                              </InputLabel>
                              <CustomAutoComplete
                                holder="Select Type"
                                data={data.attendance_limited_meta.type}
                                value={AllDayType}
                                Options="name"
                                style={{ marginbottom: '0' }}
                                Change={(val) =>
                                  setData({
                                    ...data,
                                    attendance_limited_meta: {
                                      ...data.attendance_limited_meta,
                                      type: val
                                    }
                                  })
                                }
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                      <Grid
                        item
                        xs={6}
                        className={classes.time_box}
                        style={{ marginTop: '0px', paddingRight: '15px' }}
                      >
                        <InputLabel
                          htmlFor="age-required"
                          className={classes.cutom_select_Label}
                          required
                        >
                          Revenue category
                        </InputLabel>
                        <CustomAutoComplete
                          holder="Select Revenue Category"
                          data={data.revCat}
                          value={revens}
                          Options="label"
                          style={{ marginbottom: '0' }}
                          Change={(val) => setData({ ...data, revCat: val })}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    spacing={0}
                    xs={12}
                    disableGutters
                    direction="row"
                    className="stap-3"
                  >
                    <Grid
                      item
                      xs
                      className={classes.time_box}
                      style={{ marginTop: '5px' }}
                    >
                      <TextareaControl
                        rows={8}
                        label="Brief description (may be used for public sales)"
                        value={data.desc}
                        onChange={(e) =>
                          setData({ ...data, desc: e.target.value })
                        }
                        inputProps={{
                          maxlength: 1000
                        }}
                        className={classes.TextareaControlBox}
                        placeholder="e.g. Unlimited access to all classes and programs"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </div>
              <Box mt={5} mb={1}>
                <h3 style={{ marginBottom: '20px' }}>
                  Select the programs included with this membership
                </h3>

                <Grid
                  xs={12}
                  style={{
                    marginTop: '0',
                    display: 'inline-flex',
                    width: '100%',
                    marginBottom: '30px'
                  }}
                >
                  <Grid
                    item
                    xs
                    style={{ marginTop: '0px' }}
                    className={classes.checkBoxTable}
                  >
                    <CheckBoxTable
                      values={allprograms}
                      headNames={DummyDataCB}
                      initialSelected={data.programs}
                      getSelected={(val) => setData({ ...data, programs: val })}
                    />
                  </Grid>
                </Grid>
              </Box>
              <div>
                <h3 style={{ marginBottom: '20px' }}>
                  Create payment plans for different commitments, payment
                  options and locations
                </h3>

                <Grid
                  container
                  spacing={0}
                  xs={12}
                  disableGutters
                  direction="row"
                >
                  <Grid
                    item
                    xs={12}
                    style={{ marginTop: '0px', marginBottom: '20px' }}
                  >
                    <JBButton
                      type="primary"
                      isImg={Plusicon}
                      onClick={() => setOpenDialog(true)}
                    >
                      new payment plan
                    </JBButton>
                    <Dialog
                      open={openDialog}
                      fullWidth
                      maxWidth="lg"
                      onClose={() => setOpenDialog(false)}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                      disableBackdropClick
                    >
                      <PaymentPlan
                        getData={(val) =>
                          setData({
                            ...data,
                            payments: [...data.payments, val]
                          })
                        }
                        handleClose={() => setOpenDialog(false)}
                        allpayments={data.payments}
                      />
                    </Dialog>
                  </Grid>

                  <Grid item xs={12} style={{ marginTop: '0px' }}>
                    {data.payments.length > 0 && (
                      <PaymentPlanTable
                        values={data.payments}
                        Delpay={(index) =>
                          setData({
                            ...data,
                            payments: Delpayment(data.payments, index)
                          })
                        }
                        Editpay={(val, index) => {
                          setData({
                            ...data,
                            payments: Editpayment(data.payments, index, val)
                          });
                        }}
                      />
                    )}
                  </Grid>
                </Grid>
              </div>
              <Box mt={5} mb={1}>
                <h3 style={{ marginBottom: '20px' }}>
                  Assign a contract template to this membership template
                </h3>

                <Grid
                  container
                  spacing={0}
                  xs={12}
                  disableGutters
                  direction="row"
                >
                  <Grid
                    item
                    xs={12}
                    style={{ marginTop: '0px', marginBottom: '20px' }}
                  >
                    {allContracts.length > 0 ? (
                      <RadioButtonTable
                        values={allContracts}
                        tableHead={RadiotableHead}
                        getData={(val) =>
                          setData({ ...data, contract_id: val })
                        }
                        initial={data.contract_id}
                      />
                    ) : (
                      <div>
                        <JBButton
                          type="primary"
                          isImg={Plusicon}
                          onClick={() =>
                            setopenDialogBox({ id: null, open: true })
                          }
                        >
                          create new contract
                        </JBButton>
                        <NewContractDialog
                          open={openDialogBox.open}
                          setClose={() => {
                            setopenDialogBox({ id: null, open: false });
                          }}
                          reloadFunc={handleReload}
                        />
                      </div>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              style={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              <CancelDialog handleHistory={ROUTES.CLASSES_MEMBERSHIPS} />
              <JBButton
                type="primary"
                style={{ marginLeft: '15px' }}
                disabled={!saved}
                onClick={handleSave}
              >
                Save
              </JBButton>
            </Grid>
          </Card>
          <CancelDialogTwo
            setOpen={cancelDialog}
            handleClose={() => setcancelDialog(false)}
          />
        </Box>
      )}
    </div>
  );
};

EditPlanTemplate.propTypes = {
  classes: PropType.object.isRequired,
  AllGymloc: PropType.object.isRequired,
  RevenCat: PropType.object.isRequired,
  TaxRates: PropType.object.isRequired,
  allprograms: PropType.array.isRequired,
  contracts: PropType.object.isRequired,
  OpenNoti: PropType.func.isRequired,
  location: PropType.object.isRequired,
  history: PropType.object.isRequired,
  getContractsUpdate: PropType.func.isRequired
};

const mapToState = (state) => ({
  AllGymloc: state.SettingReducer.allGymloc,
  RevenCat: state.SettingReducer.revenueCtgry,
  TaxRates: state.SettingReducer.taxRates,
  allprograms: state.allprograms.programs,
  contracts: state.SettingReducer.pubContracts
});

const mapToDispatch = (dispatch) => ({
  OpenNoti: (msg, type) => dispatch(openNoti(msg, type)),
  getContractsUpdate: () => dispatch(getContracts())
});

export default connect(
  mapToState,
  mapToDispatch
)(withStyles(styles)(EditPlanTemplate));
