import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropType from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  DialogTitle,
  withStyles
} from '@material-ui/core';
import { ProgAcDc } from 'src/Redux/programReducers/progranReducer.actions';
import { getErrorMsg } from 'src/utils/ErrorHandling';
import { openNoti } from 'src/Redux/global/global.actions';
import { ROUTES } from '../../constant/Constants';
import JBButton from '../common/JBButton/JBButton';
import X from '../../assets/image/x.png';
import CheckIcon from '../../assets/image/check.png';

const styles = () => ({
  Deletebtn: {
    background: '#FB4E4E',
    boxShadow: '0px 8px 16px rgba(251, 78, 78, 0.16)',
    borderRadius: '2px',
    height: '48px',
    padding: '10px 15px',
    color: '#fff',
    fontWeight: '700',
    '&:hover': {
      background: '#FB4E4E'
    }
  },
  bodytxt: {
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '0.002em',
    color: '#7F7F7F',
    padding: '10px 0'
  },
  titlebk: {
    padding: '16px 24px 5px'
  }
});

function DeactivateprogramDialog(props) {
  const { classes, handleClose, setOpen, data, OpenNotiDisp } = props;
  const history = useHistory();

  const handleActivate = () => {
    if (data.status === 1) {
      ProgAcDc(data.id, 0)
        .then(() => OpenNotiDisp('Successfully Deactivated the program'))
        .then(history.push(ROUTES.CLASSES_PROGRAMS))

        .catch((err) => OpenNotiDisp(getErrorMsg(err), 'error'));
    } else {
      ProgAcDc(data.id, 1)
        .then(() => OpenNotiDisp('Successfully Activated your Program'))
        .then(history.push(ROUTES.CLASSES_PROGRAMS))
        .catch((err) => OpenNotiDisp(getErrorMsg(err), 'error'));
    }
  };
  return (
    <div>
      <Dialog
        open={setOpen}
        onClose={() => handleClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div style={{ width: 394 }}>
          <DialogTitle id="alert-dialog-title" className={classes.titlebk}>
            <img
              alt="Plusicon"
              src={data.status === 1 ? X : CheckIcon}
              style={{ display: 'inline-block', verticalAlign: 'sub' }}
            />
            <Typography
              color="#000"
              variant="h5"
              component="div"
              className={classes.title}
              style={{ display: 'inline-block', marginLeft: '10px' }}
            >
              {data.status === 1 ? 'Deactivate' : 'Activate'} program
            </Typography>
          </DialogTitle>
          <DialogContent>
            <p className={classes.bodytxt}>
              Are you sure you want to{' '}
              {data.status === 1 ? 'deactivate' : 'activate'} this program?
            </p>
          </DialogContent>
          <DialogActions
            style={{
              padding: '5px 20px 20px'
            }}
          >
            <Button
              variant="outlined"
              onClick={handleClose}
              style={{
                height: '48px',
                color: '#7F7F7F',
                fontWeight: 'bold'
              }}
            >
              Cancel
            </Button>
            {data.status === 1 ? (
              <Button className={classes.Deletebtn} onClick={handleActivate}>
                {data.status === 1 ? 'Deactivate' : 'Activate'}
              </Button>
            ) : (
              <JBButton type="primary" onClick={handleActivate}>
                Activate
              </JBButton>
            )}
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}

DeactivateprogramDialog.propTypes = {
  classes: PropType.object.isRequired,
  setOpen: PropType.bool.isRequired,
  handleClose: PropType.func.isRequired,
  data: PropType.object.isRequired,
  OpenNotiDisp: PropType.func.isRequired
};

const mapToDispatch = (dispatch) => ({
  OpenNotiDisp: (msg, type) => dispatch(openNoti(msg, type))
});

export default connect(
  null,
  mapToDispatch
)(withStyles(styles)(DeactivateprogramDialog));
