import {
  changetemplatename,
  changeattendncelmt,
  changeattendancelmtuses,
  changeattendancelmttype,
  changetemplatedesc,
  changetemplaterevecat,
  addtemplatepayment,
  changetemplatepayment,
  changetemplateprogram,
  changetemplatecontract,
  changeNoSession
} from './membershiptemplate.types';

export const initialValue = {
  name: '',
  attLmt: '',
  session: '',
  attendance_limited_meta: {
    uses: '',
    type: null
  },
  reveCat: null,
  desc: '',
  program_ids: [],
  payment_plans: [],
  contract_id: ''
};

const MembershipTemplateReducer = (state = initialValue, action) => {
  switch (action.type) {
    case changetemplatename:
      return {
        ...state,
        name: action.payload
      };

    case changeattendncelmt:
      return {
        ...state,
        attLmt: action.payload
      };

    case changeattendancelmtuses:
      return {
        ...state,
        attendance_limited_meta: {
          ...state.attendance_limited_meta,
          uses: action.payload
        }
      };

    case changeattendancelmttype:
      return {
        ...state,
        attendance_limited_meta: {
          ...state.attendance_limited_meta,
          type: action.payload
        }
      };

    case changetemplatedesc:
      return {
        ...state,
        desc: action.payload
      };

    case changetemplaterevecat:
      return {
        ...state,
        reveCat: action.payload
      };

    case addtemplatepayment:
      return {
        ...state,
        payment_plans: [...state.payment_plans, action.payload]
      };

    case changetemplatepayment:
      return {
        ...state,
        payment_plans: action.payload
      };

    case changetemplateprogram:
      return {
        ...state,
        program_ids: action.payload
      };

    case changetemplatecontract:
      return {
        ...state,
        contract_id: action.payload
      };

    case changeNoSession:
      return {
        ...state,
        session: action.payload
      };
    default:
      return state;
  }
};

export default MembershipTemplateReducer;
