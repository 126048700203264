import React, { useState, useContext } from 'react';
import { ROUTES } from 'src/constant/Constants';
import { Link, useHistory } from 'react-router-dom';
import { VanguardMem } from 'src/Context/VanguardMembershipContext';
import { setSelectedUser } from 'src/Context/VanguardMembership/VanguardMembership.actions';
import { useDispatch } from 'react-redux';
import { openNoti } from 'src/Redux/global/global.actions';
import { convertedLead } from 'src/services/Leadmodule.services';
import {
  makeStyles,
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Card
} from '@material-ui/core';
import clsx from 'clsx';
import JBVanguardStepper from 'src/views/common/JBVanguardStepper';
import { getErrorMsg } from 'src/utils/ErrorHandling';
import useChangeTitle from 'src/views/common/CustomHooks/useTitleChange';
import { getLeadData } from '../Vanguard.utills';
import { validStep1, validStep2 } from './Convert.validation';
import { ReactComponent as BackArrow } from '../../../../assets/image/Vanguard/icon_back_vanguard.svg';
import LeavePagePopup from '../../LeavepagePopup';
import PersonalInfo from './personalInfo';
import LocationInfo from './location';
import ConvertMemberPopup from './ConvertMemberPopup';
import SuccessPopup from './successPopup';

function getSteps() {
  return ['1', '2'];
}

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    background: 'rgba(29, 29, 29, 0.94)',
    boxShadow: '0px 0.33333px 0px rgb(255 255 255 / 15%)',
    backdropFilter: 'blur(54.3656px)'
  },
  headerTitle: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '26px'
  },
  appToolbar: {
    minHeight: '75px',
    justifyContent: 'space-between'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
  mainTitle: {
    fontWeight: '500',
    fontSize: '48px',
    lineHeight: '44px',
    color: '#FFFFFF',
    fontFamily: 'DDC Hardware',
    textAlign: 'center',
    margin: '38px 0 0 0',
    '@media (max-width: 992px)': {
      fontSize: '35px'
    }
  },
  MainCard: {
    height: 'calc(100vh - 75px )',
    background:
      'linear-gradient(180deg, rgba(41, 41, 41, 0.95) 0%, rgba(4, 4, 4, 0.95) 100%)',
    borderRadius: '0',
    flex: '1 1 auto',
    // height: '100%',
    overflow: 'hidden',
    overflowX: 'hidden',
    position: 'relative'
  },
  HomeButton: {
    fontFamily: 'Lato',
    color: '#2CE9DA',
    fontWeight: 'bold',
    fontSize: '22px',
    lineHeight: '32px',
    textTransform: 'capitalize'
  }
}));

const ConvertMember = () => {
  const history = useHistory();
  const classes = useStyles();
  useChangeTitle('Convert Member | Jungle Alliance');
  const [state, dispatch] = useContext(VanguardMem);
  const [processing, setProcessing] = useState(false);
  const reduxDispatch = useDispatch();
  const [LeavePageopen, setLeavePageopen] = useState(false);
  const [data, setData] = useState({
    profile_pic: state.personal_info.user_details.profile_pic || 'null',
    ...state.personal_info.user_details
  });
  const [Sussopen, setSussopen] = useState(false);
  const [Convertopen, setConvertopen] = useState(false);
  const steps = getSteps();
  const [activeStep, setActiveStep] = useState(0);
  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <PersonalInfo
            data={data}
            setData={setData}
            UserId={state.user_id}
            Maintitle="Personal Information"
          />
        );
      case 1:
        return (
          <LocationInfo data={data} setData={setData} Maintitle="LOCATION" />
        );
      default:
        return 'Unknown step';
    }
  }
  const handleReset = () => {
    setActiveStep(0);
  };
  const handleNext = () => {
    if (activeStep === 1) {
      setProcessing(true);
      convertedLead(getLeadData(data), state.user_id)
        .then((res) => {
          dispatch(setSelectedUser(res.data));
          setProcessing(false);
          setSussopen(true);
        })
        .catch((err) => {
          reduxDispatch(openNoti(getErrorMsg(err), 'error'));
          setProcessing(false);
          handleReset();
        });
      // setTimeout(() => history.push(ROUTES.VANGUARD), 1500);
    } else {
      setActiveStep((prev) => prev + 1);
    }
  };
  const handleBack = () => {
    setActiveStep((prev) => prev - 1);
    if (activeStep === 0) {
      history.goBack();
    }
  };

  return (
    <div>
      <AppBar className={classes.appBar}>
        <Toolbar className={classes.appToolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="close"
            onClick={() => {
              setLeavePageopen(true);
            }}
          >
            <BackArrow />
          </IconButton>
          <h5 className={classes.headerTitle}>Convert to the Member</h5>
          <Button
            color="inherit"
            className={classes.HomeButton}
            component={Link}
            to={ROUTES.VANGUARD}
          >
            Home
          </Button>
        </Toolbar>
      </AppBar>
      <Card className={clsx(classes.MainCard)}>
        <JBVanguardStepper
          steps={steps}
          activeStep={activeStep}
          handleBack={handleBack}
          handleNext={handleNext}
          handleReset={handleReset}
          // eslint-disable-next-line react/jsx-no-bind
          handleStepContent={getStepContent}
          validateBtn={[validStep1(data), validStep2(data)]}
          processing={processing}
          cancelBack={[0]}
          isLoading={false}
          hasErrored={false}
          backLabel="Back"
          prevLabel="Convert"
          nextLabel="Next"
          // isBackDisabled={btnDisalbed}
          // processing={isProcessing}
        />
      </Card>
      <LeavePagePopup
        setOpen={LeavePageopen}
        handleClose={() => setLeavePageopen(false)}
        handleHistory={ROUTES.VANGUARD}
      />
      <ConvertMemberPopup
        Dialogtitle="Convert to the Member?"
        setOpen={Convertopen}
        handleClose={() => setConvertopen(false)}
      />
      <SuccessPopup
        Dialogtitle="Successfully converted"
        setOpen={Sussopen}
        handleClose={() => setSussopen(false)}
      />
      {/* <ReactivateMemberPopup
        setOpen={Sussopen}
        handleClose={() => setSussopen(false)}
      /> */}
    </div>
  );
};

export default ConvertMember;
