/* eslint-disable operator-linebreak */
import React, { useContext, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Card,
  withStyles,
  Breadcrumbs,
  Typography,
  Grid
} from '@material-ui/core';
import PropType from 'prop-types';
import { MembershipTemplate as MembershipContext } from 'src/Context/MembershipTemplate/MembershipTemplateContext';
import { getGymSetup } from 'src/Redux/GymSetupReducer/GymSetup.actions';
import './CutomSelect.css';
// import { ROUTES } from '../../../constant/Constants';
import useChangeTitle from 'src/views/common/CustomHooks/useTitleChange';
import { ReactComponent as PlanDetails } from '../../../assets/image/JBSteppericon/membership/PlanDetails.svg';
import { ReactComponent as ProgramAccess } from '../../../assets/image/JBSteppericon/membership/ProgramAccess.svg';
import { ReactComponent as PaymentPlans } from '../../../assets/image/JBSteppericon/membership/PaymentPlans.svg';
import { ReactComponent as ContractTemplates } from '../../../assets/image/JBSteppericon/membership/ContractTemplates.svg';
import CancelDialogTwo from '../../common/CommonDialogBox/CancelDialogTwo';
import ColorlibStepIcon from '../../common/JBGridStepper/ColorlibStepIcon';
import JBStepper from '../../common/JBGridStepper';
import styles from './MembershipTemplate.style';
import PlanDetailsStep from './PlanTemplates/PlanDetailsStep';
import ProgramAccessStep from './PlanTemplates/ProgramAccessStep';
import PaymentPlansStep from './PlanTemplates/PaymentPlansStep';
import ContractTemplatesStep from './PlanTemplates/ContractTemplatesStep';
import CancelDialog from '../../common/CommonDialogBox/CancelDialog';
import { getMemData, validSteps } from './MemTemplate.utils';
import { openNoti } from '../../../Redux/global/global.actions';
import { createMemTemplate } from '../../../services/Membersip.services';
import { getErrorMsg } from '../../../utils/ErrorHandling';
import { checkValidString } from '../../../utils/validations';

function getSteps() {
  return [
    'Plan Details',
    'Program Access',
    'Payment Plans',
    'Contract Templates'
  ];
}

const MembershipTemplate = ({ classes, OpenNoti, getGymSteps, gymSteps }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [state] = useContext(MembershipContext);
  const [cancelDialog, setcancelDialog] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const history = useHistory();
  const steps = getSteps();
  useChangeTitle('Create New Plan Membership Template | Jungle Alliance');
  // eslint-disable-next-line consistent-return
  const handleNext = () => {
    if (activeStep < 3) {
      if (checkValidString(state?.name)) {
        return OpenNoti('Name is not valid', 'error');
      }
      if (
        state.desc &&
        state.desc.trim().length > 0 &&
        checkValidString(state.desc)
      ) {
        return OpenNoti('Please specify valid Description', 'error');
      }
      setActiveStep((prev) => prev + 1);
    } else {
      // eslint-disable-next-line
      if (!state.contract_id || state.contract_id === 0) {
        OpenNoti('Please select contract id', 'error');
      } else {
        setIsProcessing(true);
        const value = getMemData(state);
        createMemTemplate(value)
          .then(() => history.goBack())
          .then(() => {
            setIsProcessing(false);
            OpenNoti('Succuessfully created the Membership Template');
          })
          .then(() => {
            if (gymSteps.process_type.membership === 0) {
              getGymSteps();
            }
          })
          .catch((err) => {
            setIsProcessing(false);
            OpenNoti(getErrorMsg(err), 'error');
          });
      }
    }
  };

  const handleBack = () => {
    setActiveStep((prev) => prev - 1);
  };

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <PlanDetailsStep />;
      case 1:
        return <ProgramAccessStep />;
      case 2:
        return <PaymentPlansStep />;
      case 3:
        return <ContractTemplatesStep />;
      default:
        return 'Unknown step';
    }
  }
  return (
    <div className={classes.root}>
      <div className="title-bk">
        <Breadcrumbs
          aria-label="breadcrumb"
          style={{
            margin: '0px 0 21px'
          }}
        >
          <div color="inherit">Classes</div>
          <div
            color="inherit"
            onClick={() => setcancelDialog(true)}
            style={{
              cursor: 'pointer'
            }}
          >
            membership
          </div>
          <Typography variant="h3" color="Primary">
            <span>new template</span>
          </Typography>
        </Breadcrumbs>
        <div>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={0}
            style={{
              marginBottom: '15px'
            }}
          >
            <Grid item xs={8}>
              <h1
                style={{
                  marginBottom: '0px'
                }}
              >
                Create New Plan Membership Template
              </h1>
            </Grid>
            <Grid
              item
              xs={4}
              style={{
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              <CancelDialog />
            </Grid>
          </Grid>
        </div>
      </div>
      <Card>
        <JBStepper
          steps={steps}
          activeStep={activeStep}
          handleBack={handleBack}
          handleNext={handleNext}
          handleReset={() => setActiveStep(0)}
          // eslint-disable-next-line react/jsx-no-bind
          handleStepContent={getStepContent}
          validateBtn={validSteps(state, activeStep)}
          isLoading={false}
          hasErrored={false}
          isprocessing={isProcessing}
          processingLabel="Creating..."
          prevLabel="CREATE"
          nextLabel="NEXT STEP"
          CustComp={(prop) => (
            <ColorlibStepIcon
              FirstIcon={PlanDetails}
              SecondIcon={ProgramAccess}
              ThirdIcon={PaymentPlans}
              FourthIcon={ContractTemplates}
              {...prop} // eslint-disable-line
            />
          )}
        />
      </Card>
      <CancelDialogTwo
        setOpen={cancelDialog}
        handleClose={() => setcancelDialog(false)}
      />
    </div>
  );
};

MembershipTemplate.propTypes = {
  classes: PropType.object.isRequired,
  OpenNoti: PropType.func.isRequired,
  getGymSteps: PropType.func.isRequired,
  gymSteps: PropType.func.isRequired
};
const mapToState = (state) => ({
  gymSteps: state.GymSetupReducer.data
});
const mapToDispatch = (dispatch) => ({
  OpenNoti: (msg, type) => dispatch(openNoti(msg, type)),
  getGymSteps: () => dispatch(getGymSetup())
});

export default connect(
  mapToState,
  mapToDispatch
)(withStyles(styles)(MembershipTemplate));
