import React from 'react';
import PropTypes from 'prop-types';

const AbsolutePosition = (props) => {
  const { children, nodeRef, top, bottom, left, right, className } = props;

  const style = {
    position: 'absolute',
    top,
    bottom,
    left,
    right
  };
  return (
    <div
      id="custompopup_absdiv"
      style={style}
      className={className}
      ref={nodeRef}
    >
      {/* <div
        style={{
          content: '""',
          position: 'absolute',
          left: '0',
          right: '0',
          top: 'calc(100% - 100px)',
          bottom: 'calc(100% - 100px)',
          margin: '0 auto',
          width: '0',
          height: '0',
          borderTop: '10px solid transparent',
          borderBottom: '10px solid transparent',
          borderLeft: '10px solid #E91E63',
          transform: 'rotate(-90deg)'
        }}
      /> */}
      {children}
    </div>
  );
};

AbsolutePosition.propTypes = {
  top: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  bottom: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  left: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  right: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  className: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
  nodeRef: PropTypes.func.isRequired
};

export default AbsolutePosition;
