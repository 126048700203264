import React from 'react';
import { TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropType from 'prop-types';

const styles = () => ({
  textareainput: {
    color: '#7F7F7F',
    fontSize: '16px',
    border: '1px solid #a9a9a9',
    padding: '9px 15px 7px',
    marginTop: '22px !important'
  }
});
const TextareaControl = ({ label, name, classes, rows, ...rest }) => {
  return (
    <TextField
      fullWidth
      multiline
      label={label}
      rows={rows}
      name={name}
      InputProps={{
        disableUnderline: true,
        className: classes.textareainput
      }}
      InputLabelProps={{
        shrink: true
      }}
      {...rest} // eslint-disable-line
    />
  );
};

TextareaControl.propTypes = {
  classes: PropType.object.isRequired,
  label: PropType.string.isRequired,
  name: PropType.string.isRequired,
  rows: PropType.number.isRequired
};

export default withStyles(styles)(TextareaControl);
