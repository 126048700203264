import { backendDate } from 'src/utils/someCommon';

export const tobeFilters = [{ id: 1, filter: 'Payment due' }];
export const NextdayFilter = [
  { id: 'all_time', filter: 'All Time' },
  { id: 'custom', filter: 'Custom' },
  { id: 'thisyear', filter: 'This Year' },
  { id: 'lastyear', filter: 'Last Year' },
  { id: 'thismonth', filter: 'This Month' },
  { id: 'lastmonth', filter: 'Last Month' },
  { id: 'thisweek', filter: 'This Week' },
  { id: 'lastweek', filter: 'Last Week' },
  { id: 'today', filter: 'Today' },
  { id: 'yesterday', filter: 'Yesterday' }
];

export const getFilter = (filter, dateFilter, data, endDate) => {
  switch (filter.id) {
    case 1:
      if (dateFilter.id === 'custom') {
        return {
          date_range: 'custom',
          custom: backendDate(data) + '-' + backendDate(endDate)
        };
      }
      return {
        date_range: dateFilter.id
      };
    default:
      return null;
  }
};
export const getfilterLabel = (filter, dateFilter, data, endDate) => {
  switch (filter.id) {
    case 1:
      if (dateFilter?.id === 'custom') {
        return {
          id: filter?.id,
          name: `Payment due is ${backendDate(data)
            + '-'
            + backendDate(endDate)}`,
          filter: 'date_range'
        };
      }
      return {
        id: filter?.id,
        name: `Payment due is ${dateFilter.filter}`,
        filter: 'date_range'
      };

    default:
      return null;
  }
};
export const checkData = (filter, data, paymentdue, endDate) => {
  if (filter?.id === 1) {
    if (!paymentdue) {
      return true;
    }
    if (paymentdue?.id === 'custom' && !data) return true;
    if (paymentdue?.id === 'custom' && !endDate) {
      return true;
    }
  } else if (!filter || !data || String(data) === 'Invalid') {
    return true;
  }
  return false;
};
