import filterType from './programReducer.constants';

export const filterProgram = (state, type, data) => {
  switch (type) {
    case filterType.page:
      return {
        ...state,
        page: data
      };
    case filterType.search:
      return {
        ...state,
        search: data,
        page: 1
      };
    case filterType.sorting: {
      const getdata = () => {
        switch (data) {
          case state.sorting.sort_name: {
            const getSortby = () => {
              switch (state.sorting.sort_by) {
                case 'asc':
                  return {
                    ...state,
                    sorting: { ...state.sorting, sort_by: 'desc' }
                  };
                case 'desc':
                  return {
                    ...state,
                    sorting: { ...state.sorting, sort_by: 'asc' }
                  };
                default:
                  return { ...state };
              }
            };
            return getSortby();
          }
          default:
            return {
              ...state,
              sorting: {
                sort_name: data,
                sort_by: 'asc'
              }
            };
        }
      };
      return getdata();
    }
    default:
      return state;
  }
};

export const getData = (type, action, state, data) => {
  switch (type) {
    case filterType.activeProgram: {
      const Data = () => {
        switch (action) {
          case filterType.isFetching:
            return {
              ...state,
              isFetching: true
            };
          case filterType.fetchSucess:
            return {
              ...state,
              isFetching: false,
              error: null,
              data
            };
          case filterType.Errorfetch:
            return {
              ...state,
              isFetching: false,
              error: data
            };
          default:
            return state;
        }
      };
      return Data();
    }
    case filterType.inActiveProgram: {
      const Data = () => {
        switch (action) {
          case filterType.isFetching:
            return {
              ...state,
              isFetching: true
            };
          case filterType.fetchSucess:
            return {
              ...state,
              isFetching: false,
              error: null,
              data
            };
          case filterType.Errorfetch:
            return {
              ...state,
              isFetching: false,
              error: data
            };
          default:
            return state;
        }
      };
      return Data();
    }
    default:
      return state;
  }
};

export const sortPrograms = (arr) => {
  const data = arr;
  data.sort((a, b) => {
    return a.name.localeCompare(b.name);
  });
  return data;
};
