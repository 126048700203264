import types from './globalactiontypes';

// ----------NOTIFICATION OPEN CLOSE FUNCTIONS-------- START--//
export const openNoti = (message, type) => (dispatch) => {
  const id = Math.random();
  const open = true;
  // debugger; // eslint-disable-line
  let lMessage = message;
  // Don't allow empty noty error messages
  if (typeof message === 'undefined' || message === null || message === '') {
    lMessage = 'Something went wrong.';
  }
  dispatch({
    type: types.GLOBAL_NOTI_OPEN,
    payload: {
      id,
      message: lMessage,
      type,
      open
    }
  });
};
const clearNotiId = (id) => ({
  type: types.GLOBAL_NOTI_CLEAR_ITEMS,
  payload: id
});

export const cleartNoti = (idArray) => (dispatch) => {
  // debugger; //eslint-disable-line
  for (let i = 0; i < idArray.length; i += 1) {
    dispatch(clearNotiId(idArray[i]));
  }
};

// ----------NOTIFICATION OPEN CLOSE FUNCTIONS-------- END--//
