/* eslint-disable radix */
import React, { useEffect } from 'react';
import PropType from 'prop-types';
// import { VanguardMem } from 'src/Context/VanguardMembershipContext';
import {
  setPytOpt,
  getDropInPlan,
  setStartDateDropIn
} from 'src/Context/VanguardMembership/VanguardMembership.actions';
import { getMemberDetail } from 'src/services/Membersip.services';
import clsx from 'clsx';
import {
  Typography,
  makeStyles,
  Grid,
  RadioGroup,
  Radio,
  FormControlLabel,
  Box,
  Button,
  InputLabel
} from '@material-ui/core';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
// import CustomAutocomplete from 'src/views/common/CustomAutocomplete/commonAutocomplete';
import CheckIcon from '@material-ui/icons/Check';
// import FormControl from 'src/views/common/FormControl/formControl';
import JBDatePicker from 'src/views/common/FormControl/JBDatePicker';
import LoadingView from 'src/views/common/loading';
import GetPrice from 'src/views/common/CurrencySymbol/GetPrice';
// import { ReactComponent as DownIcon } from '../../../assets/image/Vanguard/downIcon.svg';
// import { ReactComponent as DownIcon } from '../../../../assets/image/Vanguard/downIcon.svg';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    background: 'rgba(29, 29, 29, 0.94)',
    boxShadow: '0px 0.33333px 0px rgb(255 255 255 / 15%)',
    backdropFilter: 'blur(54.3656px)'
  },
  appToolbar: {
    minHeight: '75px',
    justifyContent: 'space-between'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
  mainTitle: {
    fontWeight: '500',
    fontSize: '48px',
    lineHeight: '44px',
    color: '#FFFFFF',
    fontFamily: 'DDC Hardware',
    textAlign: 'center',
    margin: '38px 0 0 0',
    '@media (max-width: 992px)': {
      fontSize: '35px'
    }
  },
  subTitle: {
    fontWeight: 'bold',
    fontSize: '24px',
    lineHeight: '38px',
    color: '#FFFFFF',
    fontFamily: 'Lato',
    textAlign: 'center',
    margin: '38px 0 0 0',
    letterSpacing: '0.1px'
  },
  MainCard: {
    height: 'calc(100vh - 75px )',
    background:
      'linear-gradient(180deg, rgba(41, 41, 41, 0.95) 0%, rgba(4, 4, 4, 0.95) 100%)',
    borderRadius: '0',
    flex: '1 1 auto',
    // height: '100%',
    overflow: 'auto'
  },
  HomeButton: {
    fontFamily: 'Lato',
    color: '#2CE9DA',
    fontWeight: 'bold',
    fontSize: '22px',
    lineHeight: '32px',
    textTransform: 'capitalize'
  },
  cutom_select_Label: {
    fontFamily: 'DDC Hardware',
    fontStyle: 'normal',
    fontWeight: '500;',
    fontSize: '22px',
    lineHeight: '32px',
    letterSpacing: '0.2px',
    color: '#FFFFFF',
    '&:focus ': {
      outline: '0'
    },
    '& .MuiFormLabel-asterisk': {
      color: 'transparent'
    },
    '& .MuiFormLabel-asterisk:after': {
      content: '""',
      background: '#2CE9DA',
      display: 'inline-block',
      position: 'relative',
      width: '8px',
      height: '8px',
      borderRadius: '50%',
      top: '-14px',
      right: '10px'
    },
    '@media (max-width: 850px)': {
      fontSize: '19px'
    }
  },
  cutom_vanguard_FormControl: {
    '& .fd-input input': {
      background: 'rgba(196, 196, 196, 0.05)',
      color: '#fff',
      borderRadius: '6px',
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '20px',
      height: '45px',
      border: '2px solid #C8C8C8',
      padding: '8px 20px 8px',
      '&::placeholder ': {
        color: '#fff',
        fontWeight: 'bold',
        fontSize: '15px'
      }
    },
    '&  .MuiPaper-root': {
      backgroundColor: '#2d2d2d',
      marginTop: '0',
      marginBottom: '0',
      border: '2px solid #C8C8C8',
      borderTop: 'transparent',
      boxShadow: 'none',
      // borderRadius: '0',
      borderTopLeftRadius: '0',
      borderTopRightRadius: '0'
    },
    '& .MuiAutocomplete-option': {
      color: '#fff',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '20px',
      height: '66px'
    },
    '& .MuiAutocomplete-popper': {
      width: '100% !important',
      marginTop: '-2px'
    },
    '& .SelectDrop[aria-expanded="true"]': {
      borderBottom: '2px solid transparent',
      borderBottomLeftRadius: '0',
      borderBottomRightRadius: '0'
    }
  },

  cutom_vanguard_Drop: {
    '& .MuiAutocomplete-popper ': {
      width: 'calc(100% - 16px) !important'
    },
    '& .MuiAutocomplete-hasPopupIcon .MuiAutocomplete-inputRoot, .MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot': {
      paddingRight: '50px !important'
    }
  },
  cutom_vanguard_Autocomplete: {
    background: 'rgba(196, 196, 196, 0.05)',
    border: '2px solid #C8C8C8',
    color: '#fff',
    borderRadius: '6px',
    '& .cutom_select div': {
      minHeight: '62px'
    },
    '& .cutom_select div input ': {
      color: '#ffffff !important',
      fontWeight: 'bold',
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontSize: '20px',
      '&::placeholder': {
        fontSize: '20px !important',
        color: '#b9b9ba !important',
        fontWeight: 'bold !important'
      }
    },
    '& .cutom_select div input::placeholder': {
      fontSize: '20px !important',
      color: '#b9b9ba !important',
      fontWeight: 'bold !important'
    },
    '& .MuiAutocomplete-endAdornment': {
      minHeight: ' auto !important',
      top: 'calc(50% - 12px)'
    }
  },
  customRadio: {
    // '& .MuiFormControlLabel-root': {
    //   background: 'rgba(196, 196, 196, 0.05)',
    //   border: '2px solid #7B7B7B',
    //   borderRadius: '6px',
    //   height: '70px',
    //   margin: '0 0 15px'
    // },
    // '& .MuiFormControlLabel-root.checked': {
    //   background: 'rgba(44, 233, 218, 0.15)',
    //   border: '2px solid #2CE9DA',
    //   borderRadius: '6px'
    // },
    '& .MuiFormControlLabel-root svg.MuiSvgIcon-root': {
      width: '30px',
      height: '30px',
      fill: 'none',
      borderRadius: '50%',
      border: '2px solid #4B4B4B'
    },
    '& .MuiFormControlLabel-root.checked svg.MuiSvgIcon-root': {
      background: '#67e9da',
      padding: '4px',
      fill: '#000',
      color: '#060606',
      border: '0px solid #4B4B4B'
    },
    '& .MuiRadio-root': {
      padding: '20px'
    },
    '& .MuiFormControlLabel-label': {
      color: '#fff',
      fontSize: '20px',
      lineHeight: '26px',
      fontWeight: 'bold',
      WebkitLineClamp: '2',
      WebkitBoxOrient: 'vertical',
      display: '-webkit-box',
      whiteSpace: 'normal',
      overflow: 'hidden',
      height: 'auto',
      minHeight: 'auto',
      width: '360px',
      textAlign: 'left',
      '@media (max-width: 850px)': {
        width: '210px'
      }
    }
  },
  customGrid: {
    '& .customGridData': {
      background: 'rgba(196, 196, 196, 0.05)',
      border: '2px solid #7B7B7B',
      borderRadius: '6px',
      height: '70px',
      margin: '0 0 15px',
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      textTransform: 'capitalize'
    },
    '& .customGridData.checked': {
      background: 'rgba(44, 233, 218, 0.15)',
      border: '2px solid #2CE9DA',
      borderRadius: '6px'
    }
  },
  customGridItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '190px',
    color: '#fff',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '26px',
    alignItems: 'flex-start',
    '& h5 ': {
      fontFamily: 'Lato',
      color: 'rgb(255 255 255 / 60%)',
      fontWeight: 'normal',
      fontSize: '20px',
      lineHeight: '32px'
    }
  },
  VanguardCutom_date: {
    '& .MuiInput-root': {
      color: '#fff',
      height: '65px',
      fontWeight: '600',
      fontSize: '20px',
      lineHeight: '18px',
      marginTop: '5px !important',
      border: '2px solid #C8C8C8',
      borderRadius: '6px'
    }
  },
  labelInfo: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '20px',
    lineHeight: '26px',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.2px',
    color: '#FFFFFF',
    paddingLeft: '6px'
  },
  cutom_Text_label: {
    background: 'rgba(196, 196, 196, 0.05)',
    border: '2px solid rgba(200, 200, 200, 0.2)',
    borderRadius: '6px',
    '& label': {
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '20px',
      display: 'flex',
      alignItems: 'center',
      letterSpacing: '0.2px',
      color: '#8f8f8f',
      minHeight: '62px',
      padding: '0 15px'
    }
  }
}));

export const billedWeek = [
  { id: 2, label: '1' },
  { id: 0, label: '2' },
  { id: 1, label: '4' }
];

export const ExpirationTypeData = [
  { id: 0, name: 'No Expiration' },
  { id: 1, name: 'Expiration' }
];

export const AllDayType = [
  { id: 'day', name: 'Days' },
  { id: 'week', name: 'Weeks' },
  { id: 'month', name: 'Months' },
  { id: 'year', name: 'Years' }
];

const DropInConfigureMembership = ({ reducer: [state, dispatch] }) => {
  const classes = useStyles();
  // const [state, dispatch] = useContext(VanguardMem);
  const {
    membership_id,
    memebership_data: { isFetching, membershipdata },
    payments: { all_payments }
  } = state;
  // const [data, setData] = useState({
  //   value: ''
  // });

  // const [ExpirationType, setExpirationType] = useState(null);
  // alert(window.innerWidth, 'Hello! I am an alert box!!');
  useEffect(() => {
    if (all_payments.length === 0) {
      // dispatch(fetchMemership());
      getMemberDetail(membership_id).then((res) => {
        dispatch(getDropInPlan(res));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (isFetching) return <LoadingView />;
  // const getPaymentData = (id) => {
  //   let result = [];
  //   result = state.payments.all_payments.filter((i) => i.id === id);
  //   return result;
  // };
  return (
    <div>
      <Typography variant="h3" className={classes.mainTitle}>
        Configure Drop In
      </Typography>
      <Grid
        container
        xs={10}
        justify="center"
        style={{
          margin: '45px auto'
        }}
      >
        {/* {Boolean(membership_payment_id) && ( */}
        <>
          <Typography variant="h5" className={classes.subTitle}>
            Select the date this drop in should start.
          </Typography>
          <Grid
            item
            xs={12}
            className={classes.cutom_vanguard_FormControl}
            style={{
              margin: '24px 0px 10px'
            }}
          >
            <InputLabel
              htmlFor="age-required"
              required
              className={classes.cutom_select_Label}
              // style={{ margin: '0px 0 5px' }}
            >
              START DATE
            </InputLabel>
            <JBDatePicker
              required
              value={membershipdata.start_date}
              // error={checkValidStartDate(membershipdata.expires_date)}
              // helperText={
              //   checkValidStartDate(membershipdata.expires_date)
              //     ? 'please specify a valid start date as membership is already passed'
              //     : null
              // }
              minDate={new Date()}
              onChange={(val) => dispatch(setStartDateDropIn(val))}
              //
              className={classes.VanguardCutom_date}
            />
          </Grid>
          {/* <Typography variant="h5" className={classes.subTitle}>
            Configure the number of sessions and expiration.
          </Typography> */}
          <Grid
            container
            direction="row"
            // justify="center"
            alignItems="center"
            spacing={2}
            style={{ margin: '24px 0 0px' }}
          >
            <Grid xs={12}>
              <Typography variant="h5" className={classes.subTitle}>
                Payment option type and pricing for this drop in.
              </Typography>
              <Box
                mt="25px"
                css={{
                  width: '100%'
                }}
              />
              <RadioGroup
                value={String(membershipdata.payment_data.paymentOpt)}
                onChange={(e) => {
                  dispatch(setPytOpt(parseInt(e.target.value)));
                }}
                className={classes.customRadio}
              >
                <Grid xs={12} className={classes.customGrid}>
                  <Button
                    className={clsx(
                      'customGridData',
                      `${
                        String(membershipdata.payment_data.paymentOpt) === '1'
                          ? 'checked'
                          : ''
                      }`
                    )}
                  >
                    <Box>
                      <FormControlLabel
                        value="1"
                        control={
                          <Radio
                            color="primary"
                            checkedIcon={
                              <CheckIcon
                                htmlColor="#2CE9DA"
                                width="30px"
                                height="30px"
                              />
                            }
                            icon={
                              <RadioButtonUncheckedIcon
                                width="30px"
                                height="30px"
                              />
                            }
                          />
                        }
                        label="Pay in Full"
                        className={`${
                          String(membershipdata.payment_data.paymentOpt) === '1'
                            ? 'checked'
                            : ''
                        }`}
                      />
                    </Box>
                    <Box className={classes.customGridItem}>
                      Setup fee
                      <h5>
                        <GetPrice
                          value={
                            membershipdata.payment_data
                              .initial_commitment_pricing.full_setup_fees
                          }
                        />
                      </h5>
                    </Box>
                    <Box className={classes.customGridItem}>
                      Fee
                      <h5>
                        <GetPrice
                          value={
                            membershipdata.payment_data
                              .initial_commitment_pricing.full_membership_fees
                          }
                        />
                      </h5>
                    </Box>
                    <Box className={classes.customGridItem}>
                      Tax
                      <h5>
                        {/* <GetPrice /> */}
                        <GetPrice
                          value={
                            membershipdata.payment_data
                              .initial_commitment_pricing.full_tax_amount
                          }
                        />
                      </h5>
                    </Box>
                    <Box className={classes.customGridItem}>
                      Total
                      <h5 style={{ color: '#fff' }}>
                        <GetPrice
                          value={
                            membershipdata.payment_data
                              .initial_commitment_pricing.full_total
                          }
                        />
                      </h5>
                    </Box>
                  </Button>
                </Grid>
              </RadioGroup>
            </Grid>
          </Grid>
        </>
        {/* )} */}
      </Grid>
    </div>
  );
};

DropInConfigureMembership.propTypes = {
  reducer: PropType.array.isRequired
  // handleClose: PropType.func.isRequired
  // handleBack: PropType.func,
  // handleHistory: PropType.string
};

export default DropInConfigureMembership;
