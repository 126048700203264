const WODCONST = {
  WODLIST: 'WODLIST',
  WODLISTFAV: 'WODLISTFAV',
  isFetching: 'WORKOUTS_FETCHING_START',
  fetchSucess: 'WORKOUTS_DATA_FETCHED',
  Errorfetch: 'WORKOUTS_FETCHED_ERROR',
  page: 'PAGE',
  sorting: 'SORTING',
  search: 'SEARCH',
  addcustom: 'ADD_CUSTOM_FILTER',
  removecustom: 'REMOVE_CUSTOM_FILTER',
  sortname: 'name',
  sortday: 'wod_day',
  sortduration: 'duration',
  sortstarttime: 'start_time',
  sortendtime: 'end_time',
  sortlocation: 'location',
  sortdesc: 'description',
  sortdate: 'date'
};

export default WODCONST;
