import React, { useState } from 'react';
import PropType from 'prop-types';
import { Link } from 'react-router-dom';
import { ROUTES } from 'src/constant/Constants';
import {
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  TableSortLabel,
  Table,
  withStyles,
  Button
} from '@material-ui/core';
import filterType from 'src/Redux/Reducer.constants';
import CheckIcon from '../../../../../assets/image/check.png';
import CalendarSmallIcon from '../../../../../assets/image/calendarSmall.svg';
import ClassHistoryDialog from './ClassHistoryTable/ClassHistoryDialog';

const styles = () => ({
  table: {
    minWidth: '100%',
    wordBreak: 'break-word',
    '& td': {
      padding: '15px'
    },
    '@media (max-width: 1366px)': {
      '& td': {
        fontSize: '13px'
      }
    }
  },
  tableLink: {
    color: '#01847A',
    fontWeight: '700'
  },
  TableCell: {
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px',
    height: '56px',
    minHeight: '56px',
    padding: '15px',
    '@media (max-width: 1366px)': {
      fontSize: '13px',
      wordBreak: 'keep-all'
    }
  },
  Tablemian: {}
});

const ExpiringMembershipsTable = (props) => {
  const { values, classes, isActive, setDirection, setSort } = props;
  const [openDial, setOpenDial] = useState({ isActive: false, user: null });
  const {
    sort_members,
    sort_memberships,
    sortstartdate,
    sortexpiredate,
    sort_user_status,
    sort_auto_renew
  } = filterType;

  return (
    <TableContainer
      component={Paper}
      elevation={0}
      className={classes.Tablemian}
    >
      <Table className={classes.table} aria-label="simple table">
        <TableHead style={{ background: '#F9F9FA' }}>
          <TableRow>
            <TableCell className={classes.TableCell}>
              <TableSortLabel
                active={isActive(sort_members)}
                direction={setDirection(sort_members)}
                onClick={() => setSort(sort_members)}
              >
                Member
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell}>
              <TableSortLabel
                active={isActive(sort_memberships)}
                direction={setDirection(sort_memberships)}
                onClick={() => setSort(sort_memberships)}
              >
                Member membership plan
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell}>
              <TableSortLabel
                active={isActive(sortstartdate)}
                direction={setDirection(sortstartdate)}
                onClick={() => setSort(sortstartdate)}
              >
                Start date
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell}>
              <TableSortLabel
                active={isActive(sortexpiredate)}
                direction={setDirection(sortexpiredate)}
                onClick={() => setSort(sortexpiredate)}
              >
                Expiration date
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell}>
              <TableSortLabel
                active={isActive(sort_user_status)}
                direction={setDirection(sort_user_status)}
                onClick={() => setSort(sort_user_status)}
              >
                Active
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell}>
              <TableSortLabel
                active={isActive(sort_auto_renew)}
                direction={setDirection(sort_auto_renew)}
                onClick={() => setSort(sort_auto_renew)}
              >
                Auto renew
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell}>&nbsp;</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {values
            && values.map((row) => (
              <TableRow
                hover
                tabIndex={-1}
                key={row.id}
                style={{
                  backgroundColor: '#FFF'
                }}
              >
                <TableCell
                  style={{
                    width: '9.0909%'
                  }}
                >
                  <Link
                    className={classes.tableLink}
                    to={{
                      pathname: ROUTES.PEOPLE_MEMBER_PROFILE,
                      search: `memberId=${btoa(row.id)}`
                    }}
                  >
                    {row.member}
                  </Link>
                </TableCell>
                <TableCell
                  style={{
                    width: '9.0909%'
                  }}
                  className={classes.tableLink}
                >
                  {row.membership}
                </TableCell>
                <TableCell
                  style={{
                    width: '9.0909%'
                  }}
                >
                  {row.start_date}
                </TableCell>
                <TableCell
                  style={{
                    width: '9.0909%'
                  }}
                >
                  {row.expires_date}
                </TableCell>
                <TableCell
                  style={{
                    width: '7%'
                  }}
                >
                  {row.user_status === 'true' && (
                    <img src={CheckIcon} alt="img" />
                  )}
                  {/* {row.active} */}
                </TableCell>
                <TableCell
                  style={{
                    width: '9.0909%'
                  }}
                >
                  {row.auto_renew === 'true' && (
                    <img src={CheckIcon} alt="img" />
                  )}
                </TableCell>
                <TableCell
                  style={{
                    width: '9.0909%'
                  }}
                  align="center"
                >
                  <Button
                    onClick={() => setOpenDial({ isActive: true, user: row })}
                  >
                    <img src={CalendarSmallIcon} alt="img" />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      {values?.length === 0 && (
        <p
          style={{
            margin: '10px 15px'
          }}
        >
          No Members and Expiring Memberships to show...
        </p>
      )}
      <ClassHistoryDialog
        user={openDial.user}
        setOpen={openDial.isActive}
        handleClose={() => setOpenDial({ isActive: false, user: null })}
      />
    </TableContainer>
  );
};
ExpiringMembershipsTable.propTypes = {
  values: PropType.array.isRequired,
  classes: PropType.object.isRequired,
  isActive: PropType.func.isRequired,
  setDirection: PropType.func.isRequired,
  setSort: PropType.func.isRequired
};

export default withStyles(styles)(ExpiringMembershipsTable);
