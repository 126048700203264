import React from 'react';
import PropType from 'prop-types';
import { Link, Route, Switch, useLocation } from 'react-router-dom';
import { Box, AppBar, Tabs, Tab, withStyles } from '@material-ui/core';
import { ROUTES } from 'src/constant/Constants';
import ActiveTab from './ActiveTab';
import InactiveTab from './InactiveTab';
import AllTab from './AllTab';
import { getMissingVal } from './Documents.utils';

const styles = () => ({
  STabPanel: {
    backgroundColor: '#fff',
    boxShadow: '4px 0px 16px rgba(37, 40, 43, 0.08)',
    '& .MuiCard-root.MuiPaper-rounded': {
      boxShadow: 'none',
      padding: '0 10px'
    }
  },
  p_tabs: {
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px'
  }
});

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}
const Missingtable = ({ classes }) => {
  const location = useLocation();
  const Missinlocation = getMissingVal(location.pathname);

  return (
    <Box p="24px">
      <AppBar
        position="static"
        style={{
          backgroundColor: 'transparent',
          boxShadow: 'none'
        }}
      >
        <Tabs
          value={Missinlocation}
          indicatorColor="primary"
          textColor="primary"
          fullWidth
        >
          <Tab
            label="Active"
            component={Link}
            to={ROUTES.DOCUMENTS_CONTRACTS}
            className={classes.p_tabs}
            {...a11yProps(0)} // eslint-disable-line
          />
          <Tab
            label="Inactive"
            component={Link}
            to={ROUTES.DOCUMENTS_CONTRACTS_INACTIVE}
            className={classes.p_tabs}
            {...a11yProps(1)} // eslint-disable-line
          />
          <Tab
            label="all"
            component={Link}
            to={ROUTES.DOCUMENTS_CONTRACTS_ALL}
            className={classes.p_tabs}
            {...a11yProps(2)} // eslint-disable-line
          />
        </Tabs>
      </AppBar>
      <Switch>
        <Route path={ROUTES.DOCUMENTS_CONTRACTS_ALL} component={AllTab} />
        <Route
          path={ROUTES.DOCUMENTS_CONTRACTS_INACTIVE}
          component={InactiveTab}
        />
        <Route path={ROUTES.DOCUMENTS_CONTRACTS} component={ActiveTab} />
      </Switch>
    </Box>
  );
};

Missingtable.propTypes = {
  classes: PropType.object.isRequired,
  Tabs: PropType.number.isRequired
};

export default withStyles(styles)(Missingtable);
