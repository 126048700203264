import React, { useState, useCallback, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Grid,
  withStyles,
  Card,
  Breadcrumbs,
  Typography,
  Box,
  InputBase,
  FormControlLabel
} from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
import IOSSwitch from 'src/views/common/FormControl/IOSSwitch';
import Pagination from '@material-ui/lab/Pagination';
import PaginateData from 'src/views/common/JBTables/PaginateData';
import LoadingView from 'src/views/common/loading';
import filterType from 'src/Redux/Reducer.constants';
import { exportExpiringCreditCards } from 'src/services/reports.services';
import { getCreditCardExpReports } from 'src/Redux/ReportsReducer/reports.reducer.actions';
import { useSelector, connect } from 'react-redux';
import { debounce } from 'lodash';
import CancelDialogTwo from 'src/views/common/CommonDialogBox/CancelDialogTwo';
import useChangeTitle from 'src/views/common/CustomHooks/useTitleChange';
import JBButton from '../../common/JBButton/JBButton';
import ImportIcon from '../../../assets/image/upload.png';
import ExpireCardTable from './AllTables/ExpiringCreditCardsTable/ExpiringCreditCardsTable';
// import { DummyValues } from './AllTables/ExpiringCreditCardsTable/dummyData';
import styles from './FinancialReports.style';

const ExpiringCreditCards = (props) => {
  const {
    classes,
    CreditCardFilter,
    DefaultSelected,
    ExipiringCardsSearch,
    ExpiringCardsSort,
    customFilter
  } = props;
  useChangeTitle('Expiring credit cards | Jungle Alliance');
  const { data, error, isFetching } = useSelector(
    (state) => state.reportsReducer.financialReports.expireCards
  );
  const [search, setSearch] = useState(ExipiringCardsSearch);
  const [open, setOpen] = useState(false);
  // const [, setDefaultActive] = useState(
  //   () =>
  //     DefaultSelected.filter((l) => l.filter === 'default_payment_method')[0]
  // );
  const { data: data_reports } = useSelector(
    (state) => state.reportsReducer.reportList
  );
  const SearchQuery = useCallback(
    debounce((q) => {
      CreditCardFilter(filterType.search, q);
    }, 1500),
    []
  );

  const Paginate = (val) => {
    if (val !== data.current_page) {
      return CreditCardFilter(filterType.page, val);
    }
    return null;
  };

  const defaultPayment = useCallback(
    debounce((q, tag) => {
      if (q) {
        CreditCardFilter(filterType.addcustom, [
          { default_payment_method: 1 },
          {
            id: 1000000000002,
            name: ' Is default payment method ',
            filter: 'default_payment_method'
          }
        ]);
      } else {
        CreditCardFilter(filterType.removecustom, tag);
      }
    }, 1000),
    []
  );

  const handleReset = () => {
    CreditCardFilter(filterType.resetFilter);
    // setDefaultActive(
    //   () =>
    //     !DefaultSelected.filter((l) => l.filter === 'default_payment_method')[0]
    // );
    setSearch('');
  };

  const setDirection = (name) => {
    if (ExpiringCardsSort.sort_name === name) {
      return ExpiringCardsSort.sort_by === 'asc' ? 'asc' : 'desc';
    }
    return 'asc';
  };
  const isActive = (sortlabel) => {
    if (ExpiringCardsSort.sort_name === sortlabel) return true;
    return false;
  };
  const setSort = (sortlabel) => {
    CreditCardFilter(filterType.sorting, sortlabel);
  };

  const isStarred = data_reports?.financial_report.filter(
    (report) => report.id === 11
  )[0].is_starred;

  useEffect(() => {
    return () => {
      handleReset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (!data_reports || !data) return <LoadingView />;
  return (
    <div className={classes.root}>
      <div className="title-bk">
        <Box css={{ marginBottom: '20px' }}>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={0}
          >
            <Grid item xs={9}>
              <Box display="inline-flex">
                <Breadcrumbs aria-label="breadcrumb">
                  <div color="inherit">Reports</div>
                  <div
                    color="inherit"
                    onClick={() => setOpen(true)}
                    style={{
                      cursor: 'pointer'
                    }}
                  >
                    financial reports
                  </div>
                  <Typography variant="h3" color="Primary">
                    <span>Expiring credit cards </span>
                  </Typography>
                </Breadcrumbs>
              </Box>
              <Box
                display="inline-flex"
                ml="10px"
                css={{ verticalAlign: 'text-bottom' }}
              >
                {isStarred === 1 && (
                  <StarIcon
                    style={{
                      color: '#FFC700'
                    }}
                  />
                )}
              </Box>
            </Grid>
            <Grid
              item
              xs={3}
              style={{
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              <JBButton
                type="outlined"
                isImg={ImportIcon}
                component={Link}
                style={{ marginLeft: '10px' }}
                disabled={data.data.length === 0}
                onClick={(e) => {
                  e.preventDefault();
                  exportExpiringCreditCards({
                    search: ExipiringCardsSearch,
                    sort_name: ExpiringCardsSort.sort_name,
                    sort_by: ExpiringCardsSort.sort_by,
                    ...customFilter
                  });
                }}
              >
                Export
              </JBButton>
            </Grid>
          </Grid>
        </Box>
      </div>

      <Card className={classes.mainCard}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={0}
        >
          <Grid item xs={6}>
            <Grid container direction="row" alignItems="center" spacing={0}>
              <Grid item xs={9}>
                <div className={classes.search}>
                  <InputBase
                    fullWidth
                    placeholder="Search…"
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                      SearchQuery(e.target.value);
                    }}
                    classes={{
                      root: classes.inputRoot,
                      input: classes.searchInput
                    }}
                    InputProps={{
                      'aria-label': 'search'
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs>
                <JBButton
                  type="outlined"
                  onClick={handleReset}
                  style={{ marginLeft: '10px' }}
                >
                  reset
                </JBButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} justify="flex-end" className="MuiGrid-container">
            <Box
              mr="20px"
              css={{
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                marginRight: '20px'
              }}
            >
              <FormControlLabel
                control={<IOSSwitch />}
                checked={Boolean(DefaultSelected[0]?.id)}
                onChange={(e) => {
                  defaultPayment(
                    e.target.checked,
                    DefaultSelected.filter(
                      (l) => l.filter === 'default_payment_method'
                    )[0]
                  );
                }}
                label="Default payment methods only"
                style={{ margin: '0 0' }}
              />
            </Box>
          </Grid>
        </Grid>
        {isFetching || !data ? (
          <LoadingView />
        ) : error ? (
          <p>{error}</p>
        ) : (
          <Fragment>
            {' '}
            <Grid item xs={12}>
              <Box mt="25px" />
              <ExpireCardTable
                values={data?.data}
                setDirection={setDirection}
                isActive={isActive}
                setSort={setSort}
              />
            </Grid>
            {data?.data.length !== 0 && (
              <Grid
                item
                xs={12}
                container
                direction="row"
                alignItems="center"
                style={{ marginTop: 24 }}
              >
                <Grid item xs={6} justify="flex-start">
                  <PaginateData data={data} />
                </Grid>
                <Grid
                  item
                  xs={6}
                  justify="flex-end"
                  style={{ display: 'flex' }}
                >
                  <Pagination
                    count={data.last_page}
                    color="primary"
                    variant="outlined"
                    page={data.current_page}
                    onChange={(_, val) => Paginate(val)} //eslint-disable-line
                    shape="rounded"
                  />
                </Grid>
              </Grid>
            )}
          </Fragment>
        )}
      </Card>
      <CancelDialogTwo setOpen={open} handleClose={() => setOpen(false)} />
    </div>
  );
};
const mapToState = (state) => ({
  DefaultSelected: state.reportsReducer.financialReports.expirecardsFil.tags,
  ExipiringCardsSearch:
    state.reportsReducer.financialReports.expirecardsFil.search,
  ExpiringCardsSort:
    state.reportsReducer.financialReports.expirecardsFil.sorting,
  customFilter:
    state.reportsReducer.financialReports.expirecardsFil.customFilter
});
const mapToDispatch = (dispatch) => {
  return {
    CreditCardFilter: (filter, data) =>
      dispatch(getCreditCardExpReports(filter, data))
  };
};
ExpiringCreditCards.propTypes = {
  classes: PropTypes.object.isRequired,
  CreditCardFilter: PropTypes.func.isRequired,
  DefaultSelected: PropTypes.array.isRequired,
  ExipiringCardsSearch: PropTypes.string.isRequired,
  ExpiringCardsSort: PropTypes.object.isRequired,
  customFilter: PropTypes.object.isRequired
};

export default connect(
  mapToState,
  mapToDispatch
)(withStyles(styles)(ExpiringCreditCards));
