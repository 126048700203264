import React from 'react';
import { Link } from 'react-router-dom';
import { ROUTES } from 'src/constant/Constants';
import filterTypes from 'src/Redux/Reducer.constants';
import PropType from 'prop-types';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { get } from 'js-cookie';
import styles from './table.style';

const MemberTable = (props) => {
  const { classes, values, tableHead, isActive, setDirection, setSort } = props;
  const { sortname, sortlimiteddata } = filterTypes;
  const roleid = JSON.parse(get('jbrole') || '{}')?.role_id || 3;

  return (
    <Paper elevation={0}>
      <TableContainer component={Paper} elevation={0} className={classes.table}>
        <Table>
          <TableHead className={classes.table_th}>
            <TableRow>
              {tableHead.map((headCell) => (
                <TableCell
                  style={{ width: '10%' }}
                  className={classes.TableCell}
                  key={headCell.id}
                >
                  <TableSortLabel
                    active={
                      headCell.cellName === 'name'
                        ? isActive(sortname)
                        : isActive(sortlimiteddata)
                    }
                    direction={
                      headCell.cellName === 'name'
                        ? setDirection(sortname)
                        : setDirection(sortlimiteddata)
                    }
                    // eslint-disable-next-line no-confusing-arrow
                    onClick={() =>
                      headCell.cellName === 'name'
                        ? setSort(sortname)
                        : setSort(sortlimiteddata)
                    }
                  >
                    {headCell.label}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className={classes.Table_Body}>
            {values.map((row) => (
              <TableRow key={row.id}>
                <TableCell
                  scope="row"
                  className={classes.Table_td}
                  key={row.id}
                >
                  {roleid === 1 ? (
                    <Link
                      // to={{
                      //   pathname:
                      //     row.membership_type === 'plan'
                      //       ? ROUTES.CLASSES_PLAN_MEMBERSHIP_EDIT
                      //       : ROUTES.CLASSES_PLAN_SESSION_EDIT,
                      //   search: `${
                      //     row.membership_type === 'plan'
                      //       ? 'membershipId'
                      //       : 'sessionId'
                      //   }=${row.id}`
                      // }}
                      to={{
                        pathname: (() => {
                          switch (row.membership_type) {
                            case 'plan':
                              return ROUTES.CLASSES_PLAN_MEMBERSHIP_EDIT;
                            case 'session':
                              return ROUTES.CLASSES_PLAN_SESSION_EDIT;
                            case 'drop_in':
                              return ROUTES.CLASSES_DROPIN_EDIT;
                            default:
                              return null;
                          }
                        })(),
                        search: (() => {
                          switch (row.membership_type) {
                            case 'plan':
                              return `membershipId=${row.id}`;
                            case 'session':
                              return `sessionId=${row.id}`;
                            case 'drop_in':
                              return `dropInId=${row.id}`;
                            default:
                              return null;
                          }
                        })()
                      }}
                      className={classes.Table_td_link}
                    >
                      {row.name}
                    </Link>
                  ) : (
                    <span className={classes.Table_td}>{row.name}</span>
                  )}
                </TableCell>
                <TableCell scope="row" className={classes.Table_td}>
                  {row.limited_data}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {values.length === 0 && (
          <p
            style={{
              margin: '10px 15px'
            }}
          >
            No memberships to show...
          </p>
        )}
      </TableContainer>
    </Paper>
  );
};

MemberTable.propTypes = {
  classes: PropType.object.isRequired,
  values: PropType.array.isRequired,
  tableHead: PropType.array.isRequired,
  isActive: PropType.func.isRequired,
  setDirection: PropType.func.isRequired,
  setSort: PropType.func.isRequired
};
export default withStyles(styles)(MemberTable);
