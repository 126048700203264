import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  withStyles,
  Card,
  Breadcrumbs,
  Typography,
  Box,
  ThemeProvider,
  Tooltip
} from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
import ReactTooltip from 'react-tooltip';
import filterType from 'src/Redux/Reducer.constants';
import { useSelector, connect } from 'react-redux';
import { debounce } from 'lodash';
import { getLeadInsights } from 'src/Redux/ReportsReducer/reports.reducer.actions';
import LoadingView from 'src/views/common/loading';
import ApplyFilter from 'src/views/common/ApplyFilter/applyFilter';
import CancelDialogTwo from 'src/views/common/CommonDialogBox/CancelDialogTwo';
import useChangeTitle from 'src/views/common/CustomHooks/useTitleChange';
import JBButton from '../../common/JBButton/JBButton';
import { ReactComponent as Funnel1 } from '../../../assets/image/funnel/funnel1.svg';
import { ReactComponent as Funnel2 } from '../../../assets/image/funnel/funnel2.svg';
import { ReactComponent as Funnel3 } from '../../../assets/image/funnel/funnel3.svg';
import { ReactComponent as Funnel4 } from '../../../assets/image/funnel/funnel4.svg';
import { ReactComponent as Funnel5 } from '../../../assets/image/funnel/funnel5.svg';
import { ReactComponent as InfoIcon } from '../../../assets/image/infoicon.svg';
import StackedBarChart from '../chart/StackedBarChart';
import LeadInsightFilter from './LeadInsightsFilter';
// import { Locations } from './Filter.utils';
// import { data as chartdata } from './chartData/LeadInsightsData';
import styles from './LeadReports.style';
import { getLeadPieData } from '../Reports.utils';

const themeInstance = {
  overrides: {
    MuiBreadcrumbs: {
      root: {
        color: '#949494',
        textTransform: 'uppercase',
        fontSize: '20px',
        lineHeight: '24px',
        fontWeight: '700'
      },
      li: {
        '& a': {
          color: '#949494',
          textTransform: 'uppercase',
          fontSize: '20px',
          lineHeight: '24px'
        },
        '@media (max-width: 1440px)': {
          '& a': {
            fontSize: '18px'
          },
          '& h3': {
            fontSize: '18px'
          }
        },
        '& h3': {
          margin: '0 0'
        }
      },
      separator: {
        marginLeft: '12px',
        marginRight: '12px'
      }
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '14px',
        fontWeight: '700',
        color: '#FFF',
        backgroundColor: '#01847A',
        borderRadius: '2px',
        padding: '10px 8px'
      },
      arrow: {
        color: '#01847A'
      }
    }
  }
};

const LeadInsights = ({ classes, LeadInsightsFilter, LeadInsightsTags }) => {
  useChangeTitle('Lead Insightsr | Jungle Alliance');
  const { isFetching, data, error } = useSelector(
    (state) => state.reportsReducer?.leadReports?.leadInsites
  );
  const { data: data_reports } = useSelector(
    (state) => state.reportsReducer.reportList
  );
  const [open, setOpen] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [location, setLocation] = useState(
    () => LeadInsightsTags.filter((l) => l.filter === 'location_id')[0]
  );
  // eslint-disable-next-line no-unused-vars
  const locationSelect = useCallback(
    debounce((q, tag) => {
      if (q) {
        LeadInsightsFilter(filterType.addcustom, [
          { location_id: q.id },
          { id: q.id, name: `Location is ${q.label}`, filter: 'location_id' }
        ]);
      } else {
        LeadInsightsFilter(filterType.removecustom, tag);
      }
    }, 1000),
    []
  );

  const handleReset = () => {
    LeadInsightsFilter(filterType.resetFilter);
    setLocation(
      () => LeadInsightsTags.filter((l) => l.filter === 'location_id')[0]
    );
  };

  const isStarred = data_reports?.lead_report.filter(
    (report) => report.id === 16
  )[0].is_starred;
  useEffect(() => {
    return () => {
      handleReset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const svgs = [Funnel1, Funnel2, Funnel3, Funnel4, Funnel5, Funnel5];
  return (
    <div className={classes.root}>
      <div className="title-bk">
        <Box css={{ marginBottom: '20px' }}>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={0}
          >
            <Grid item xs={9}>
              <Box display="inline-flex">
                <ThemeProvider theme={themeInstance}>
                  <Breadcrumbs aria-label="breadcrumb">
                    <div color="inherit">Reports</div>
                    <div
                      color="inherit"
                      onClick={() => setOpen(true)}
                      style={{
                        cursor: 'pointer'
                      }}
                    >
                      lead reports
                    </div>
                    <Typography variant="h3" color="Primary">
                      <span>lead insights</span>
                    </Typography>
                  </Breadcrumbs>
                </ThemeProvider>
              </Box>
              <Box
                display="inline-flex"
                ml="10px"
                css={{ verticalAlign: 'text-bottom' }}
              >
                {isStarred === 1 && (
                  <StarIcon
                    style={{
                      color: '#FFC700'
                    }}
                  />
                )}
              </Box>
            </Grid>
            <Grid
              item
              xs={3}
              style={{
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              &nbsp;
            </Grid>
          </Grid>
        </Box>
      </div>
      {error ? (
        <p>{error}</p>
      ) : (
        <Card className={classes.mainCard}>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={0}
          >
            <Grid item xs={12}>
              <Grid container direction="row" alignItems="center" spacing={0}>
                <Box css={{ width: '240px' }} display="inline-block">
                  <ApplyFilter>
                    <LeadInsightFilter
                      getFilData={(val1, val2) =>
                        LeadInsightsFilter(filterType.addcustom, [val1, val2])
                      }
                    />
                  </ApplyFilter>
                </Box>

                <Grid item xs>
                  <JBButton
                    type="outlined"
                    onClick={handleReset}
                    style={{ marginLeft: '10px' }}
                  >
                    reset
                  </JBButton>
                </Grid>
                <Grid item xs={12}>
                  <div>
                    {LeadInsightsTags.map((tags) => (
                      <div
                        className={classes.DTags}
                        key={tags.id}
                        style={{ display: 'inline-block' }}
                      >
                        <Box display="inline-block" mr={1}>
                          <label>
                            {tags.name}
                            <span
                              className={classes.DTagsClose}
                              onClick={() => {
                                LeadInsightsFilter(
                                  filterType.removecustom,
                                  tags
                                );
                                setLocation(
                                  () =>
                                    LeadInsightsTags.filter(
                                      (l) => l.filter === 'location_id'
                                    )[0]
                                );
                              }}
                            >
                              ×
                            </span>
                          </label>
                        </Box>
                      </div>
                    ))}
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {isFetching || !data ? (
            <LoadingView />
          ) : (
            <>
              <Box
                css={{
                  clear: 'both',
                  display: 'table',
                  margin: '30px 0 0'
                }}
              />
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                spacing={4}
              >
                <Grid
                  item
                  xs={4}
                  justify="center"
                  alignItems="center"
                  textAlign="center"
                >
                  <Box className={classes.leadsBox}>
                    <Typography variant="h1">
                      <span>{data?.new_leads[0]?.new_leads}</span>
                    </Typography>
                    <label>
                      New leads
                      <ThemeProvider theme={themeInstance}>
                        <Box
                          className={classes.TooltipInfo}
                          css={{
                            display: 'inline-block',
                            verticalAlign: 'top',
                            marginLeft: '8px'
                          }}
                        >
                          <Tooltip
                            title="All new leads created during the set time period."
                            arrow
                            placement="bottom"
                          >
                            <InfoIcon />
                          </Tooltip>
                        </Box>
                      </ThemeProvider>
                    </label>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={4}
                  justify="center"
                  alignItems="center"
                  textAlign="center"
                >
                  <Box className={classes.leadsBox}>
                    <Typography variant="h1">
                      <span>
                        {
                          data?.leads_with_no_action_from_last_10_days[0]
                            ?.leads_with_no_action_from_last_10_days
                        }
                      </span>
                    </Typography>
                    <label>
                      Leads with no action
                      <ThemeProvider theme={themeInstance}>
                        <Box
                          className={classes.TooltipInfo}
                          css={{
                            display: 'inline-block',
                            verticalAlign: 'top',
                            marginLeft: '8px'
                          }}
                        >
                          <Tooltip
                            title="All leads created during the set time period that have not been archived, deleted, and have not done any activity in the past 10 days."
                            arrow
                            placement="bottom"
                          >
                            <InfoIcon />
                          </Tooltip>
                        </Box>
                      </ThemeProvider>
                    </label>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={4}
                  justify="center"
                  alignItems="center"
                  textAlign="center"
                >
                  <Box className={classes.leadsBox}>
                    <Typography variant="h1">
                      <span>
                        {data?.avg_of_day_lead_to_member[0]
                          ?.avg_of_lead_to_member || 0}
                      </span>
                    </Typography>
                    <label>
                      Average days to conversation
                      <ThemeProvider theme={themeInstance}>
                        <Box
                          className={classes.TooltipInfo}
                          css={{
                            display: 'inline-block',
                            verticalAlign: 'top',
                            marginLeft: '8px'
                          }}
                        >
                          <Tooltip
                            title="For leads who converted to active clients, on average, how long did it take them to convert?"
                            arrow
                            placement="bottom"
                          >
                            <InfoIcon />
                          </Tooltip>
                        </Box>
                      </ThemeProvider>
                    </label>
                  </Box>
                </Grid>
              </Grid>
              <Box mb="50px" />
              <Grid
                container
                direction="row"
                // justify="center"
                alignItems="flex-start"
                spacing={0}
              >
                <Grid item xs={12}>
                  {data.leads_funnel.length === 0 ? null : (
                    <Grid container direction="row" spacing={0}>
                      <Grid item lg={7} xl={6}>
                        <Box mb="46px">
                          <Typography variant="h5">
                            <span>
                              Leads funnel{' '}
                              <ThemeProvider theme={themeInstance}>
                                <Box
                                  className={classes.TooltipInfo}
                                  css={{
                                    display: 'inline-block',
                                    verticalAlign: 'top',
                                    marginLeft: '8px'
                                  }}
                                >
                                  <Tooltip
                                    title="All leads created during the set time period and their current lead status. Lead status can be set on the leads profile."
                                    arrow
                                    placement="bottom"
                                  >
                                    <InfoIcon />
                                  </Tooltip>
                                </Box>
                              </ThemeProvider>
                            </span>
                          </Typography>
                        </Box>
                        <Box className={classes.FunnelWrapper}>
                          {data.leads_funnel?.map((lead) => (
                            <Box
                              className={classes.FunnelBox}
                              key={lead.lead_status}
                            >
                              <label style={{ textTransform: 'capitalize' }}>
                                {lead.lead_status}
                              </label>
                              <ReactTooltip
                                place="top"
                                type="dark"
                                effect="float"
                                className={classes.BoxTooltip}
                                id={lead.lead_status}
                              >
                                <div className={classes.FunnelBoxTooltip}>
                                  <p>
                                    <span>Stage:</span>
                                    <label
                                      style={{ textTransform: 'capitalize' }}
                                    >
                                      {lead.lead_status}
                                    </label>
                                  </p>
                                  <p>
                                    <span>Total:</span>
                                    <label>{lead.no_of_funnel}</label>
                                  </p>
                                  <p>
                                    <span>Retained:</span>
                                    <label>{lead.retained_per}%</label>
                                  </p>
                                </div>
                              </ReactTooltip>
                              {/* {React.createElement(svgs[i], {
                                      'data-tip data-for': lead.lead_status
                                    })} */}
                              {(() => {
                                switch (lead.lead_status) {
                                  case 'New':
                                    return <Funnel1 data-tip data-for="New" />;
                                  case 'Follow Up':
                                    return (
                                      <Funnel2 data-tip data-for="Follow Up" />
                                    );
                                  case 'Consult Booked':
                                    return (
                                      <Funnel3
                                        data-tip
                                        data-for="Consult Booked"
                                      />
                                    );
                                  case 'Onboard Booked':
                                    return (
                                      <Funnel4
                                        data-tip
                                        data-for="Onboard Booked"
                                      />
                                    );
                                  case 'Converted':
                                    return (
                                      <Funnel5 data-tip data-for="Converted" />
                                    );
                                  case 'Archived':
                                    return (
                                      <Funnel5 data-tip data-for="Archived" />
                                    );
                                  default:
                                    return null;
                                }
                              })()}
                              <span>
                                {lead.no_of_funnel} - {lead.retained_per}%
                              </span>
                            </Box>
                          ))}
                        </Box>
                      </Grid>
                      <Grid item lg={5} xl={6}>
                        <Box mb="23px">
                          <Typography variant="h5">
                            <span>
                              Leads conversions
                              <ThemeProvider theme={themeInstance}>
                                <Box
                                  className={classes.TooltipInfo}
                                  css={{
                                    display: 'inline-block',
                                    verticalAlign: 'top',
                                    marginLeft: '8px'
                                  }}
                                >
                                  <Tooltip
                                    title="All leads created during the time frame broken out by month and current conversion status."
                                    arrow
                                    placement="bottom"
                                  >
                                    <InfoIcon />
                                  </Tooltip>
                                </Box>
                              </ThemeProvider>
                            </span>
                          </Typography>
                        </Box>
                        <StackedBarChart
                          xlabel=""
                          ylabel=""
                          display={false}
                          className={classes.BkChart}
                          data={getLeadPieData(
                            data?.lead_conversions_all_time_arr
                          )}
                        />
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </>
          )}
        </Card>
      )}
      <CancelDialogTwo setOpen={open} handleClose={() => setOpen(false)} />
    </div>
  );
};
const mapToState = (state) => ({
  LeadInsightsTags: state.reportsReducer.leadReports.leadInsitesFil.tags
});
const mapToDispatch = (dispatch) => {
  return {
    LeadInsightsFilter: (filter, data) =>
      dispatch(getLeadInsights(filter, data))
  };
};
LeadInsights.propTypes = {
  classes: PropTypes.object.isRequired,
  LeadInsightsFilter: PropTypes.func.isRequired,
  LeadInsightsTags: PropTypes.array.isRequired
};

export default connect(
  mapToState,
  mapToDispatch
)(withStyles(styles)(LeadInsights));
