/* eslint-disable import/prefer-default-export */
export const getSignErrorMsg = (state, payload, payloadType) => {
  switch (payloadType) {
    case 'program': {
      if (!payload) {
        return 'Please Select Program, Class & Member to Sign In';
      }
      return 'Please Select Class & Member to Sign In';
    }
    case 'class': {
      if (!state.program) {
        return 'Please Select Program, Class & Member to Sign In';
      }
      if (!payload) {
        return 'Please Select Class';
      }
      return null;
    }
    case 'date':
      if (!state.program) {
        return 'Please Select Program, Class & Member to Sign In';
      }
      return 'Please Select Class & Member to Sign In';

    default:
      break;
  }
  return null;
};
