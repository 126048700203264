import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Grid,
  List,
  Collapse,
  Card,
  InputLabel,
  ListItem,
  withStyles
} from '@material-ui/core';
import { CirclePicker } from 'react-color';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { getGymSetup } from 'src/Redux/GymSetupReducer/GymSetup.actions';
// import rgbaTohex from 'src/utils/rgbaTohex';
import { openNoti } from 'src/Redux/global/global.actions';
import PropType from 'prop-types';
import { getErrorMsg } from 'src/utils/ErrorHandling';
import { checkValidString, validateString } from 'src/utils/validations';
import {
  editProgram,
  createProgram
} from 'src/services/programModule.services';
import {
  ActiveProgram,
  InActiveProgram,
  fetchProgramsAsync
} from 'src/Redux/programReducers/progranReducer.actions';
import useChangeTitle from 'src/views/common/CustomHooks/useTitleChange';
import CustomColorPicker from './Programcolor';
import styles from './EditProgram/programedit.styles';
import color from './colorPicker.color';
import { ROUTES } from '../../constant/Constants';
import JBButton from '../common/JBButton/JBButton';
import CancelDialog from '../common/CommonDialogBox/CancelDialog';
import FormControl from '../common/FormControl/formControl';
import ModalColor from './ModalColor/ModalColor';

const ProgramForm = (props) => {
  const {
    classes,
    editProg,
    info,
    OpenNoti,
    fetchProgram,
    fetchInActive,
    getProgram,
    getGymSteps,
    gymSteps
  } = props;
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [isprocessing, setprocessing] = useState(false);
  const [formState, setFormState] = useState({
    background: editProg ? info.color : '#01847a',
    name: editProg ? info.name : '',
    // eslint-disable-next-line
    description: editProg
      ? info.description
        ? info.description.slice(0, 50)
        : ''
      : ''
  });
  useChangeTitle(editProg ? 'Edit Program' : 'Create New Program');
  const handleSubmit = (e) => {
    e.preventDefault();
    setprocessing(true);
    // setTimeout(() => {
    //   setprocessing(false);
    // }, 500);
    if (formState.name.length !== 0 && !checkValidString(formState.name)) {
      let data = {
        name: formState.name
      };
      if (formState.background) {
        data = {
          ...data,
          color: formState.background
        };
      }
      if (formState.description.length > 0 || editProg) {
        data = {
          ...data,
          description: formState.description || null
        };
      }
      if (editProg) {
        setprocessing(true);
        editProgram(info.id, data)
          .then(() => (info.status === 1 ? fetchProgram() : fetchInActive()))
          .then(() => getProgram())
          .then(() => {
            setprocessing(false);
            OpenNoti('Sucessfully Saved Your Program', 'info');
          })
          .then(() => history.goBack())
          .catch((err) => {
            setprocessing(false);
            OpenNoti(getErrorMsg(err), 'error');
          });
      } else {
        setprocessing(true);
        createProgram(data)
          .then(() => {
            fetchProgram();
          })
          .then(() => getProgram())
          .then(() => {
            setprocessing(false);

            OpenNoti('Sucessfully created the Program', 'info');
          })
          .then(() => history.push(ROUTES.CLASSES_PROGRAMS))
          .then(() => {
            if (gymSteps.process_type.program === 0) {
              getGymSteps();
            }
          })
          .catch((err) => {
            setprocessing(false);
            OpenNoti(getErrorMsg(err), 'error');
          });
      }
    } else {
      // setprocessing(false);
      OpenNoti('Please fill valid Program name', 'error');
    }
  };
  const { name, background, description } = formState;
  return (
    <Card className={classes.mainCard}>
      <Grid container spacing={0}>
        <Grid container spacing={2}>
          <Grid item xs={9}>
            <FormControl
              control="input"
              value={name}
              placeholder="Name your program"
              required
              onChange={(e) =>
                setFormState({
                  ...formState,
                  name: e.target.value
                })
              } // eslint-disable-line
              label="Program name"
              seterrors={name.length === 0}
              seterrortext="Program Name is required field"
              style={{ margin: '0 0' }}
            />
          </Grid>
          <Grid
            item
            xs={3}
            style={
              {
                // position: 'relative'
              }
            }
          >
            <InputLabel htmlFor="age-required" style={{ fontSize: '12px' }}>
              Program color on calendar
            </InputLabel>
            <ListItem
              button
              onClick={() => setOpen(!open)}
              className={classes.colorbox}
            >
              <span style={{ width: '100%' }}>
                {background ? (
                  <span>
                    <span
                      style={{
                        backgroundColor: `${background}`,
                        width: '15px',
                        height: '15px',
                        display: 'inline-block',
                        borderRadius: '50%',
                        verticalAlign: 'bottom'
                      }}
                    />
                    <span style={{ margin: '0px 10px', color: '#545454' }}>
                      {background.split('#')[1]}
                    </span>
                  </span>
                ) : (
                  'Select color'
                )}
              </span>
              {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <ModalColor show={open} modalClose={() => setOpen(!open)}>
              <Collapse
                in={open}
                timeout="auto"
                unmountOnExit
                style={{ position: 'absolute' }}
              >
                <List component="div" className={classes.colorPickermenu}>
                  <CirclePicker
                    width={222}
                    circleSize={24}
                    className={classes.colorPicker}
                    colors={color}
                    circleSpacing={8}
                    onChangeComplete={({ hex }) =>
                      setFormState({ ...formState, background: hex })
                    } // eslint-disable-line
                  />
                  <div className="customColorpic">
                    <CustomColorPicker
                      setColor={(val) => {
                        // const hexColor = rgbaTohex(Object.values(val));
                        setFormState({ ...formState, background: val });
                      }}
                    />
                  </div>
                </List>
              </Collapse>
            </ModalColor>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <FormControl
            control="input"
            value={description}
            placeholder="Provide a short description of your program"
            onChange={(e) =>
              setFormState({
                ...formState,
                description: validateString(e.target.value)
              })
            } // eslint-disable-line
            label="Description"
            maxLength={50}
          />
          <span className="text-limit" style={{ marginBottom: '15px' }}>
            {description.length}/50
          </span>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        spacing={0}
        justify="flex-end"
        alignItems="center"
      >
        {editProg && <CancelDialog />}
        <JBButton
          type="primary"
          onClick={handleSubmit}
          style={{ marginLeft: '10px' }}
          disabled={!formState.name || isprocessing}
        >
          {isprocessing ? <span>Saving...</span> : <span>Save Program</span>}
        </JBButton>
      </Grid>
    </Card>
  );
};
ProgramForm.defaultProps = {
  editProg: false,
  info: {}
};
ProgramForm.propTypes = {
  classes: PropType.object.isRequired,
  OpenNoti: PropType.func.isRequired,
  fetchProgram: PropType.func.isRequired,
  fetchInActive: PropType.func.isRequired,
  getProgram: PropType.func.isRequired,
  getGymSteps: PropType.func.isRequired,
  gymSteps: PropType.number.isRequired,
  info: PropType.object,
  editProg: PropType.bool
};
const mapToState = (state) => ({
  gymSteps: state.GymSetupReducer.data
});
const mapToDispatch = (dispatch) => ({
  OpenNoti: (msg, type) => dispatch(openNoti(msg, type)),
  fetchProgram: () => dispatch(ActiveProgram()),
  getProgram: () => dispatch(fetchProgramsAsync()),
  fetchInActive: () => dispatch(InActiveProgram()),
  getGymSteps: () => dispatch(getGymSetup())
});

export default connect(
  mapToState,
  mapToDispatch
)(withStyles(styles)(ProgramForm));
