import React, { Component } from 'react';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
    Sentry.init({
      dsn:
        'https://2a4bfe191b694b399ebff5fc23287e25@o1337435.ingest.sentry.io/6607317',
      integrations: [new BrowserTracing()]
    });
  }

  // eslint-disable-next-line no-unused-vars
  static getDerivedStateFromError(err) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo);
  }

  render() {
    const { hasError } = this.state;
    // eslint-disable-next-line react/prop-types
    const { children } = this.props;

    if (hasError) {
      return <h1>Something went wrong.</h1>;
    }

    // return children;

    return (
      <Sentry.ErrorBoundary
        fallback={({ error, componentStack }) => (
          <React.Fragment>
            <div>You have encountered an error</div>
            <div>{error.toString()}</div>
            <div>{componentStack}</div>
          </React.Fragment>
        )}
      >
        {children}
      </Sentry.ErrorBoundary>
    );
  }
}

export default ErrorBoundary;
