import types from './SettingReducer.types';
import { getSetting } from './SettingReducer.utils';

const initialValue = {
  currency: { isFetching: false, data: null, error: null },
  date: { data: null, error: null },
  taxRates: { isFetching: false, data: null, error: null },
  revenueCtgry: { isFetching: false, data: null, error: null },
  allGymloc: { data: null, error: null },
  pubContracts: { data: null, error: null },
  invoice_footer: { isFetching: false, data: null, error: null },
  invoice_setting: { isFetching: false, data: null, error: null },
  countries: { isFetching: false, data: null, error: null },
  paymentPlans: { isFetching: false, data: null, error: null },
  allRoles: { isFetching: false, data: null, error: null }
};

export default (state = initialValue, action) => {
  switch (action.type) {
    case types.getTaxRates:
      return {
        ...state,
        taxRates: getSetting(
          types.getTaxRates,
          action.actionType,
          state.taxRates,
          action.payload
        )
      };
    case types.getInvoiceSetting:
      return {
        ...state,
        invoice_setting: getSetting(
          types.getInvoiceSetting,
          action.actionType,
          state.invoice_setting,
          action.payload
        )
      };
    case types.getCurrencyRates:
      return {
        ...state,
        currency: getSetting(
          types.getCurrencyRates,
          action.actionType,
          state.currency,
          action.payload
        )
      };
    case types.getPaymentPlans:
      return {
        ...state,
        paymentPlans: getSetting(
          types.getPaymentPlans,
          action.actionType,
          state.paymenyPlans,
          action.payload
        )
      };
    case types.getDateformat:
      return {
        ...state,
        date: getSetting(
          types.getDateformat,
          action.actionType,
          state.date,
          action.payload
        )
      };
    case types.getRevCtgry:
      return {
        ...state,
        revenueCtgry: getSetting(
          types.getRevCtgry,
          action.actionType,
          state.revenueCtgry,
          action.payload
        )
      };
    case types.getGymloc:
      return {
        ...state,
        allGymloc: getSetting(
          types.getGymloc,
          action.actionType,
          state.allGymloc,
          action.payload
        )
      };
    case types.getContracts:
      return {
        ...state,
        pubContracts: getSetting(
          types.getContracts,
          action.actionType,
          state.currency,
          action.payload
        )
      };
    case types.getinvoicefooter:
      return {
        ...state,
        invoice_footer: getSetting(
          types.getinvoicefooter,
          action.actionType,
          state.invoice_footer,
          action.payload
        )
      };
    case types.getAllCountries:
      return {
        ...state,
        countries: getSetting(
          types.getAllCountries,
          action.actionType,
          state.countries,
          action.payload
        )
      };
    case types.getRoles:
      return {
        ...state,
        allRoles: getSetting(
          types.getRoles,
          action.actionType,
          state.allRoles,
          action.payload
        )
      };
    default:
      return state;
  }
};
