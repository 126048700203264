/* eslint-disable function-paren-newline */
import React, { useEffect, useState } from 'react';
import PropType from 'prop-types';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  withStyles,
  Grid,
  InputLabel,
  Box
} from '@material-ui/core';
import dcloseicon from 'src/assets/image/closeicon.png';
import JBButton from 'src/views/common/JBButton/JBButton';
import CustomAutoComplete from 'src/views/common/CustomAutocomplete/commonAutocomplete';
import GetPrice from 'src/views/common/CurrencySymbol/GetPrice';
import { getPaymentMet } from 'src/services/user.service';
import { toPayInvoice } from 'src/services/Invoice.services';
import { ROUTES } from 'src/constant/Constants';
import { getErrorMsg } from 'src/utils/ErrorHandling';
import { openNoti } from 'src/Redux/global/global.actions';
import {
  getAllInvoices,
  getPaidInvs,
  getUnpaidInvs
} from 'src/Redux/InvoiceReducer/InvoiceReducer.actions';

const styles = () => ({
  dtitle: {
    color: '#292929',
    fontWeight: 'bold',
    fontSize: '33px',
    lineHeight: '42px'
  },
  titlebk: {
    display: 'inline-block',
    padding: '25px 24px 5px'
  },
  cutom_select_Label: {
    fontSize: '12px',
    padding: '7px 0'
  },
  Discountinput: {
    margin: '0px',
    display: 'inline-flex',
    width: '97px',
    marginLeft: '15px',
    border: '1px solid #a9a9a9',
    height: '48px',
    padding: '9px 15px 7px'
  },
  DiscounFormControl: {
    width: '95px',
    height: '48px',
    marginLeft: '20px',
    '& .MuiOutlinedInput-input': {
      padding: '14.5px 8px'
    }
  },
  Discounsign: {
    '& p': {
      color: 'rgb(41 41 41 / 0.4)'
    }
  },
  amountBk: {
    fontWeight: 'bold',
    fontSize: '14px',
    marginBottom: '18px',
    '& label': {
      color: '#949494'
    },
    '& span': {
      color: '#000',
      marginLeft: '3px'
    }
  }
});

function PayInvoiceDiag(props) {
  const { classes, handleClose, setOpen, data } = props;
  const history = useHistory();
  const [userpaymenthod, setuserpaymentmeth] = useState(null);
  const [isprocessing, setisprocessing] = useState(false);
  const [paymentMeth, setpayMeth] = useState([
    { name: 'Cash', payment_method: 2 }
  ]);

  useEffect(() => {
    getPaymentMet(data.user_id)
      .then((res) => {
        const vals = res
          .filter((list) => list.payment_method !== 2)
          // eslint-disable-next-line no-confusing-arrow
          .map((sublist) =>
            sublist.default_method === 1
              ? { ...sublist, name: sublist.name + ' (Default Method)' }
              : sublist
          );
        setpayMeth([...paymentMeth, ...vals]);
      })
      .catch(() => setpayMeth([]));
  }, [data]); // eslint-disable-line

  const handleSave = async () => {
    const { OpenNoti, getAllInvs, getPaidInv, getunpaids } = props;
    try {
      let b = {
        invoice_id: data.id,
        payment_method: userpaymenthod.payment_method !== 2 ? 3 : 2
      };
      if (userpaymenthod.payment_method !== 2) {
        b = {
          ...b,
          user_payment_method: userpaymenthod.id
        };
      }
      setisprocessing(true);
      const arr = await toPayInvoice(b);

      if (arr[0]?.transaction_status === 'succeeded') {
        history.push({
          pathname: ROUTES.FINANCIAL_INVOICES_EDIT,
          search: `Invoice=${btoa(data.id)}&status=success`
        });
        getAllInvs();
        getPaidInv();
        getunpaids();
        OpenNoti('Sucessfully Paid the Invoice', 'info');
        setisprocessing(false);
        handleClose();
      } else if (arr[0]?.transaction_status === 'requires_action') {
        setisprocessing(false);
        history.push({
          pathname: ROUTES.FINANCIAL_INVOICES_EDIT,
          search: `Invoice=${btoa(data.id)}&status=require`
        });
        OpenNoti(arr[0]?.transaction_msg, 'error');
        handleClose();
      } else if (arr[0]?.transaction_status === 'processing') {
        setisprocessing(false);
        history.push({
          pathname: ROUTES.FINANCIAL_INVOICES_EDIT,
          search: `Invoice=${btoa(data.id)}&status=processing`
        });
        OpenNoti(arr[0]?.transaction_msg, 'error');
        handleClose();
      } else {
        OpenNoti(arr[0]?.transaction_msg, 'error');
        handleClose();
      }
    } catch (err) {
      OpenNoti(getErrorMsg(err), 'error');
      setisprocessing(false);
    }
  };

  return (
    <div>
      <Dialog
        open={setOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div style={{ width: '548px' }}>
          <Grid alignItems="center" container direction="row">
            <Grid item xs>
              <DialogTitle id="alert-dialog-title" className={classes.titlebk}>
                <h4 className={classes.dtitle}>
                  Pay Invoice{' '}
                  {data.invoice_no || String(data.id).padStart(7, '0')}
                </h4>
              </DialogTitle>
            </Grid>
            <Grid
              item
              xs={2}
              style={{ textAlign: 'right', marginRight: '10px' }}
            >
              <Button className={classes.btns} onClick={handleClose}>
                <img alt="img" src={dcloseicon} />
              </Button>
            </Grid>
          </Grid>

          <DialogContent>
            <Grid container spacing={2} style={{ marginTop: '5px' }}>
              <Grid item xs={12} style={{}}>
                <Box
                  display="inline-flex"
                  alignItems="center"
                  className={classes.amountBk}
                >
                  <label>Unpaid amount:</label>
                  <span>
                    <GetPrice value={data.final_amount} />
                  </span>
                </Box>
                <Box>
                  <InputLabel
                    htmlFor="age-required"
                    className={classes.cutom_select_Label}
                    style={{ margin: '0px 0px 5px', padding: '0 0' }}
                  >
                    Payment method
                  </InputLabel>
                  <CustomAutoComplete
                    holder="Select payment method"
                    Options="name"
                    value={paymentMeth}
                    data={userpaymenthod}
                    disabled={isprocessing}
                    Change={(e) => setuserpaymentmeth(e)}
                  />
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions
            style={{
              padding: '20px 20px'
            }}
          >
            <JBButton
              type="primary"
              disabled={!userpaymenthod || isprocessing}
              onClick={handleSave}
            >
              {isprocessing ? 'Processing..' : 'complete payment'}
            </JBButton>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}

PayInvoiceDiag.propTypes = {
  classes: PropType.object.isRequired,
  setOpen: PropType.bool.isRequired,
  handleClose: PropType.func.isRequired,
  data: PropType.object.isRequired,
  OpenNoti: PropType.func.isRequired,
  getAllInvs: PropType.func.isRequired,
  getPaidInv: PropType.func.isRequired,
  getunpaids: PropType.func.isRequired
};

const mapDispatchToProps = (dispatch) => ({
  OpenNoti: (msg, type) => dispatch(openNoti(msg, type)),
  getAllInvs: () => dispatch(getAllInvoices()),
  getPaidInv: () => dispatch(getPaidInvs()),
  getunpaids: () => dispatch(getUnpaidInvs())
});

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(PayInvoiceDiag));
