export const RadiotableHead = [
  {
    id: 1,
    label: 'Template name',
    cellName: 'name'
  }
];

export const RadioDummydata = [
  {
    id: 1,
    name: 'No Membership Template'
  },
  {
    id: 2,
    name: 'Membership Template'
  },
  {
    id: 3,
    name: 'Membership Template'
  }
];
