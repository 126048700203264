import React, { useState, useContext, useEffect } from 'react';
import PropType from 'prop-types';
import { connect } from 'react-redux';
import { FilterContext } from 'src/views/common/ApplyFilter/applyFilter';
import JBButton from 'src/views/common/JBButton/JBButton';
import { InputLabel, withStyles } from '@material-ui/core';
import CustomAutoComplete from '../../../../common/CustomAutocomplete/commonAutocomplete';
import styles from '../filters.style';
import { getFilter, Filters, getfilterLabel } from './Filter.utils';

const PaymentPlanFilters = ({
  classes,
  getFilData,
  MembershipPlans,
  MembershipSessions,
  PaymentPlans
}) => {
  const [filter, setFilter] = useState(null);
  const [data, setData] = useState({ id: 0, name: 'All time' });
  const memberships = MembershipPlans?.data?.data.concat(
    MembershipSessions?.data?.data
  );
  const [open, setOpen] = useContext(FilterContext);
  const paymentData = PaymentPlans?.data?.memberReport;
  useEffect(() => {
    if (filter?.id === 1) {
      setData({ id: 0, name: 'All time' });
    } else {
      setData({ payment_plan_id: 0, payment_plan: 'All' });
    }
  }, [filter]);
  return (
    <div>
      <CustomAutoComplete
        holder="Select Filter"
        style={{ marginBottom: '15px' }}
        Options="filter"
        value={Filters}
        data={filter}
        Change={(val) => setFilter(val)}
      />
      <div>
        {(() => {
          switch (filter?.id) {
            case 1:
              return (
                <div>
                  <InputLabel
                    htmlFor="age-required"
                    className={classes.cutom_select_Label}
                    style={{ margin: '0px 0 5px' }}
                  >
                    Membership Plan
                  </InputLabel>
                  <CustomAutoComplete
                    value={[...memberships, { id: 0, name: 'All time' }]}
                    Options="name"
                    data={data || { id: 0, name: 'All time' }}
                    Change={(val) => setData(val)}
                    style={{ marginBottom: '15px' }}
                  />
                </div>
              );
            case 2:
              return (
                <div>
                  <InputLabel
                    htmlFor="age-required"
                    className={classes.cutom_select_Label}
                    style={{ margin: '0px 0 5px' }}
                  >
                    Payment Plan
                  </InputLabel>
                  <CustomAutoComplete
                    value={[
                      ...paymentData,
                      { payment_plan_id: 0, payment_plan: 'All' }
                    ]}
                    Options="payment_plan"
                    data={data || null}
                    Change={(val) => setData(val)}
                    style={{ marginBottom: '15px' }}
                  />
                </div>
              );
            default:
              return null;
          }
        })()}
      </div>

      <JBButton
        type="primary"
        style={{
          width: '100%',
          // minHeight: '22px',
          padding: '7px 25px',
          marginTop: '10px'
        }}
        disabled={!filter}
        onClick={() => {
          getFilData(getFilter(filter, data), getfilterLabel(filter, data));
          setOpen(!open);
        }}
      >
        Apply filter
      </JBButton>
    </div>
  );
};
PaymentPlanFilters.propTypes = {
  classes: PropType.object.isRequired,
  getFilData: PropType.func.isRequired,
  MembershipPlans: PropType.object.isRequired,
  MembershipSessions: PropType.object.isRequired,
  PaymentPlans: PropType.object.isRequired
};
const mapToState = (state) => ({
  MembershipPlans: state.membershipReducer.memberships,
  MembershipSessions: state.membershipReducer.sessions,
  PaymentPlans: state.SettingReducer.paymentPlans
});
export default connect(mapToState)(withStyles(styles)(PaymentPlanFilters));
