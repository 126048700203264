import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getRevenSet } from 'src/Redux/SettingReducer/SettingReducer.actions';
import { updateRevenueCategory } from 'src/services/Setting.services';
import { openNoti } from 'src/Redux/global/global.actions';
import { getErrorMsg } from 'src/utils/ErrorHandling';
import PropType from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  withStyles,
  Grid,
  FormControlLabel,
  Box
} from '@material-ui/core';
import IOSSwitch from 'src/views/common/FormControl/IOSSwitch';
import dcloseicon from '../../../assets/image/closeicon.png';
import JBButton from '../../common/JBButton/JBButton';
import FormControl from '../../common/FormControl/formControl';

const styles = () => ({
  dtitle: {
    color: '#292929',
    fontWeight: 'bold',
    fontSize: '33px',
    lineHeight: 'normal'
  },
  cutom_select_Label: {
    fontSize: '12px',
    padding: '7px 0'
  },
  titlebk: {
    display: 'inline-block',
    padding: '25px 24px 0'
  },
  closeBtns: {
    position: 'absolute',
    right: '10px',
    top: '15px'
  },
  ActionsBtns: {
    padding: '20px 24px 30px'
  }
});

function EditCategoryDialog({
  classes,
  handleHistory,
  Buttonlabel,
  Dialogtitle,
  item, //eslint-disable-line
  openNoti, //eslint-disable-line
  getRevenSet, //eslint-disable-line
  setOpen,
  handleClose,
  ...props
}) {
  // const [open, setOpen] = React.useState(false);
  const [revenueData, setRevenueData] = useState(() => item);
  // const handleClickOpen = () => {
  //   setOpen(true);
  // };
  // const handleClose = () => {
  //   setOpen(false);
  //   setRevenueData(item);
  // };
  const handleSave = () => {
    /* eslint-disable */
    updateRevenueCategory(item.id, {
      /* eslint-enable */
      label: revenueData.label,
      status: revenueData.status
    })
      .then(() => {
        getRevenSet();
        handleClose();
        openNoti(`Revenue category "${revenueData?.label}" succesfuly updated`);
      })
      .catch((err) => {
        openNoti(getErrorMsg(err), 'error');
        setRevenueData(item);
      });
  };
  useEffect(() => {
    setRevenueData(item);
  }, [item]);

  return (
    /* eslint-disable */
    <div {...props}>
      {/* eslint-enable */}
      {/* <Button size="small" onClick={handleClickOpen}>
        <img src={EditIcon} alt="img" />
      </Button> */}
      <Dialog
        open={setOpen}
        onClose={() => {
          // setOpen(false);
          setRevenueData(item);
          handleClose();
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick
      >
        <div style={{ width: '548px' }}>
          <Grid alignItems="center" container direction="row">
            <Grid item xs>
              <DialogTitle id="alert-dialog-title" className={classes.titlebk}>
                <h4 className={classes.dtitle}>{Dialogtitle}</h4>
              </DialogTitle>
            </Grid>
            <Grid item xs={2}>
              <Button className={classes.closeBtns} onClick={handleClose}>
                <img alt="img" src={dcloseicon} />
              </Button>
            </Grid>
          </Grid>

          <DialogContent>
            <Grid container spacing={2} style={{ marginTop: '5px' }}>
              <Grid item xs={12} style={{}}>
                <Box>
                  <FormControl
                    control="input"
                    placeholder=""
                    label="Label"
                    margin="none"
                    onChange={(e) =>
                      setRevenueData({ ...revenueData, label: e.target.value })
                    }
                    value={revenueData?.label}
                    // style={{ margin: '0 0' }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} className="switch-btn">
                <Box mt="10px" mb="15px">
                  <FormControlLabel
                    control={
                      <IOSSwitch
                        checked={revenueData?.status}
                        onChange={() => {
                          setRevenueData({
                            ...revenueData,
                            status: revenueData.status ? 0 : 1
                          });
                        }}
                      />
                    }
                    label="In Use"
                    style={{ margin: '0 0' }}
                  />
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions className={classes.ActionsBtns}>
            <JBButton type="primary" onClick={handleSave}>
              Save
            </JBButton>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}
const mapToDispatch = (dispatch) => {
  return {
    getRevenSet: () => dispatch(getRevenSet()),
    openNoti: (error, message) => dispatch(openNoti(error, message))
  };
};
EditCategoryDialog.defaultProps = {
  handleHistory: null
};

EditCategoryDialog.propTypes = {
  classes: PropType.object.isRequired,
  handleHistory: PropType.string,
  Buttonlabel: PropType.string.isRequired,
  Dialogtitle: PropType.string.isRequired,
  setOpen: PropType.bool.isRequired,
  handleClose: PropType.func.isRequired
};
export default connect(
  null,
  mapToDispatch
)(withStyles(styles)(EditCategoryDialog));
