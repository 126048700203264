/* eslint-disable operator-linebreak */
import React, { useEffect, useState } from 'react';
import PropType from 'prop-types';
import {
  Grid,
  Card,
  Typography,
  Box,
  Button,
  Breadcrumbs,
  withStyles,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import FormControl from 'src/views/common/FormControl/formControl';
import CancelDialog from 'src/views/common/CommonDialogBox/CancelDialog';
import IOSSwitch from 'src/views/common/FormControl/IOSSwitch';
import ArrowLeft from 'src/assets/image/arrow_left.png';
import JBButton from 'src/views/common/JBButton/JBButton';
import { openNoti } from 'src/Redux/global/global.actions';
import { connect } from 'react-redux';
import {
  missingInfo,
  creatMissingInfo
} from 'src/services/Communication.service';
import { checkQuillValid } from 'src/utils/someCommon';
import { checkValidString } from 'src/utils/validations';
import NotFoundView from 'src/views/errors/NotFoundView';
import LoadingView from 'src/views/common/loading';
import CancelDialogTwo from 'src/views/common/CommonDialogBox/CancelDialogTwo';
// import JBCKEditor from 'src/views/common/JBCKEditor';
import JBCKEditorPlaceholder from 'src/views/common/JBCKEditorPlaceholder';
import useChangeTitle from 'src/views/common/CustomHooks/useTitleChange';
import ResettemplateDialog from '../ResettemplateDialog';
import styles from '../automatedemails.style';
import { getErrorMsg } from '../automatedemails.utils';

const PaymentInformationMissing = ({ classes, openNotify }) => {
  const [error, setError] = useState(false);
  const [data, setData] = useState(null);
  const [open, setOpen] = useState(false);
  const [reset, setReset] = useState(null);
  useChangeTitle('Payment Information Missing | Jungle Alliance');
  const holderList = [
    'memfirstname',
    'memlastname',
    'gymname',
    'gymaddress',
    'gymemail',
    'gymphone',
    'gymwebsite',
    'gymlogo',
    'signature'
  ];
  useEffect(() => {
    missingInfo()
      .then((res) => {
        setData({
          ...res,
          email_template: JSON.parse(res.email_template),
          setting: JSON.parse(res.setting)
        });
        setReset({
          ...res,
          email_template: JSON.parse(res.default_email_template),
          setting: JSON.parse(res.setting)
        });
      })
      .catch(() => {
        setError(true);
      });
  }, []);
  const handleCreate = () => {
    const result = {
      setting: JSON.stringify(data.setting),
      subject: data.email_template.subject,
      body: data.email_template.body
    };
    creatMissingInfo(result)
      .then(() => {
        openNotify('Email template successfully saved');
      })
      .catch((err) => openNotify(getErrorMsg(err), 'error'));
  };
  const handleSave = () => {
    if (
      !data.email_template.subject ||
      checkValidString(data.email_template.subject)
    ) {
      openNotify('Please enter a valid subject', 'error');
    } else if (
      !data.email_template.body ||
      !checkQuillValid(data.email_template.body)
    ) {
      openNotify('Please enter a valid email body', 'error');
    } else {
      handleCreate();
    }
  };
  if (error) return <NotFoundView />;
  if (!data) return <LoadingView />;
  return (
    <div className={classes.root}>
      <div className="title-bk">
        <Breadcrumbs aria-label="breadcrumb">
          <div color="inherit">communication</div>
          <div
            color="inherit"
            onClick={() => setOpen(true)}
            style={{
              cursor: 'pointer'
            }}
          >
            Automated emails
          </div>
          <div
            color="inherit"
            onClick={() => setOpen(true)}
            style={{
              cursor: 'pointer'
            }}
          >
            General
          </div>
          <Typography variant="h3" color="Primary">
            <span>payment information missing</span>
          </Typography>
        </Breadcrumbs>
      </div>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="flex-start"
        spacing={0}
        style={{ margin: '20px 0 20px' }}
      >
        <div>
          <Button
            className={classes.ArrowLeft}
            // onClick={() => history.goBack()}
            onClick={() => setOpen(true)}
          >
            <img alt="Plusicon" src={ArrowLeft} />
          </Button>
        </div>
        <Grid xs>
          <h1 style={{ margin: '0px 0 10px' }}>
            Edit “Payment Information Missing” Template
          </h1>
          <p className={classes.ptext}>
            Remind members to add a payment method.
          </p>
        </Grid>
      </Grid>
      <Card className={classes.mainCard}>
        <Box>
          <Grid container direction="row">
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="flex-start"
            >
              <Grid item xs={8} xl={9}>
                <Typography
                  variant="h5"
                  style={{
                    color: '#000',
                    marginTop: '10px',
                    marginBottom: '10px'
                  }}
                >
                  When should this email be sent?
                </Typography>
              </Grid>
              <Grid
                item
                xs={4}
                xl={3}
                justify="center"
                className="MuiGrid-container"
                direction="column"
              >
                <Box>
                  <FormControlLabel
                    disabled={!data.setting.send_every_seven_day_editable}
                    checked={data.setting.send_every_seven_day}
                    control={
                      <Checkbox
                        name="checkedB"
                        color="primary"
                        style={{
                          padding: '5px',
                          color: '#949494'
                        }}
                      />
                    }
                    label="Send every 7 days"
                    className={classes.ckControlLabel}
                  />
                </Box>
                <Box>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="checkedB"
                        color="primary"
                        style={{
                          padding: '5px'
                        }}
                        checked={data.setting.dont_send_admin_manger_coach}
                        disabled={
                          !data.setting.dont_send_admin_manger_coach_editable
                        }
                        onChange={() => {
                          setData({
                            ...data,
                            setting: {
                              ...data.setting,
                              dont_send_admin_manger_coach:
                                !data.setting.dont_send_admin_manger_coach * 1
                            }
                          });
                        }}
                      />
                    }
                    label="Do not send to Admins, Staff or Coaches"
                    // className={classes.ckControlLabel}
                  />
                </Box>
              </Grid>
            </Grid>
            <div className={classes.hr} />
            <Grid item xs={12}>
              <Grid
                item
                xs={12}
                className="switch-btn"
                style={{ marginBottom: '15px' }}
              >
                <FormControlLabel
                  control={
                    <IOSSwitch
                      checked={data.setting.enabled}
                      onChange={() => {
                        setData({
                          ...data,
                          setting: {
                            ...data.setting,
                            enabled: !data.setting.enabled * 1
                          }
                        });
                      }}
                    />
                  }
                  label="Enabled?"
                />
              </Grid>
              <Box mb="30px">
                <FormControl
                  control="input"
                  label="Subject"
                  style={{ margin: '0 0' }}
                  value={data.email_template.subject}
                  onChange={(val) => {
                    setData({
                      ...data,
                      email_template: {
                        ...data.email_template,
                        subject: val.target.value
                      }
                    });
                  }}
                />
              </Box>
              <Box>
                {/* <JBEmailQuillEditor
                  value={data.email_template.body}
                  onChange={(val) => {
                    setData({
                      ...data,
                      email_template: {
                        ...data.email_template,
                        body: val
                      }
                    });
                  }}
                /> */}
                {/* <JBCKEditor
                  data={data.email_template.body}
                  onChange={(e) => {
                    setData({
                      ...data,
                      email_template: {
                        ...data.email_template,
                        body: e.editor.getData()
                      }
                    });
                  }}
                /> */}
                <JBCKEditorPlaceholder
                  data={data.email_template.body}
                  holder={holderList}
                  onChange={(e) => {
                    setData({
                      ...data,
                      email_template: {
                        ...data.email_template,
                        body: e.editor.getData()
                      }
                    });
                  }}
                />
              </Box>
            </Grid>
          </Grid>

          <Box mt="30px" />

          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
          >
            <CancelDialog />
            <Box ml="10px">
              <ResettemplateDialog
                onReset={() => {
                  setData(reset);
                  openNotify('Email template reseted successfuly');
                }}
              />
            </Box>
            <Box ml="10px">
              <JBButton
                type="primary"
                onClick={handleSave}
                // component={Link}
                // to={ROUTES.PEOPLE_CREATE_MEMBER}
              >
                Save
              </JBButton>
            </Box>
          </Grid>
        </Box>
      </Card>
      <CancelDialogTwo setOpen={open} handleClose={() => setOpen(false)} />
    </div>
  );
};

PaymentInformationMissing.propTypes = {
  classes: PropType.object.isRequired,
  // history: PropType.any.isRequired,
  openNotify: PropType.func.isRequired
  // reset: PropType.object.isRequired
};
const mapToDispatch = (dispatch) => ({
  openNotify: (msg, type) => dispatch(openNoti(msg, type))
});
export default connect(
  null,
  mapToDispatch
)(withStyles(styles)(PaymentInformationMissing));
