import React from 'react';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { makeStyles } from '@material-ui/core';
import PropType from 'prop-types';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import { PaymentMethods } from 'src/services/gym.services';

import MenuList from '@material-ui/core/MenuList';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { getErrorMsg } from 'src/utils/ErrorHandling';
import { openNoti } from 'src/Redux/global/global.actions';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center'
  },
  paper: {
    marginRight: theme.spacing(2)
  },
  selectBtn: {
    height: '36px',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    padding: '5px 8px',
    color: '#7F7F7F',
    textTransform: 'capitalize'
  }
}));

const Actions = (props) => {
  const classes = useStyles();
  const { data, handleRefresh } = props;
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  // eslint-disable-next-line no-unused-vars
  // const [dispatch] = useContext();

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const handleDelete = () => {
    const { OpenNoti } = props;
    const val = {
      affiliate_payment_method_id: data.id,
      status: 0,
      type: 2
    };
    // console.log(val, 'Delete val');
    PaymentMethods(val)
      .then((res) => {
        handleRefresh(res);
        OpenNoti('Successfully Delete the Payment Method', 'info');
      })
      .catch((err) => OpenNoti(getErrorMsg(err), 'error'));
  };

  const handleDefault = () => {
    const { OpenNoti } = props;
    const val = {
      affiliate_payment_method_id: data.id,
      status: 1,
      type: 1
    };
    PaymentMethods(val)
      .then((res) => {
        handleRefresh(res);
        OpenNoti('Successfully make default Payment Method', 'info');
      })
      .catch((err) => OpenNoti(getErrorMsg(err), 'error'));
  };

  return (
    <div className={classes.root}>
      <div>
        <Button
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={() => setOpen(!open)}
          color="primary"
          variant="outlined"
          className={classes.selectBtn}
        >
          Actions
          {open ? <ExpandLess /> : <ExpandMore />}
        </Button>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          style={{ zIndex: '99' }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
                zIndex: '99'
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={() => setOpen(false)}>
                  <MenuList
                    autoFocusItem={open}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                  >
                    {/* {data.default_method === 1 && ( */}
                    <div>
                      <MenuItem
                        onClick={handleDelete}
                        style={{
                          minWidth: '95px'
                        }}
                      >
                        Delete
                      </MenuItem>
                      <MenuItem
                        onClick={handleDefault}
                        style={{
                          minWidth: '95px'
                        }}
                      >
                        Make Default
                      </MenuItem>
                    </div>
                    {/* )} */}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
};

Actions.propTypes = {
  data: PropType.object.isRequired,
  onEdit: PropType.func.isRequired,
  OpenNoti: PropType.func.isRequired,
  handleRefresh: PropType.func.isRequired
};

const mapDispatchToprops = (dispatch) => ({
  OpenNoti: (msg, type) => dispatch(openNoti(msg, type))
});

export default connect(null, mapDispatchToprops)(Actions);
