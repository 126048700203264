import { getErrorMsg } from 'src/utils/ErrorHandling';
import { store } from 'src/Redux/rootReducer';
import { getInvoices } from 'src/services/Invoice.services';
import types from './InvoiceReducer.types';

const fetchingInvoice = (type) => ({
  type,
  actionType: types.isFetching
});

const fetchComplete = (type, payload) => ({
  type,
  actionType: types.fetchSucess,
  payload
});

const ErrorFetching = (type, payload) => ({
  type,
  actionType: types.Errorfetch,
  payload
});

const getUnpaidFilter = (filter, payload) => ({
  type: types.setunpaidFil,
  filter,
  payload
});

const getPaidFilter = (filter, payload) => ({
  type: types.setpaidFil,
  filter,
  payload
});

const getDueFilter = (filter, payload) => ({
  type: types.setDueFil,
  filter,
  payload
});

const getAllFilter = (filter, payload) => ({
  type: types.setAllFil,
  filter,
  payload
});

const getProcessingFilter = (filter, payload) => ({
  type: types.setProcessFil,
  filter,
  payload
});

const getFailedFilter = (filter, payload) => ({
  type: types.setFailedFil,
  filter,
  payload
});

export const toggleAreAllSelected = (payload) => ({
  type: types.setAreAllSelected,
  payload
});

const getUnpaids = (val) => {
  const type = types.getUnpaid;
  return (dispatch) => {
    dispatch(fetchingInvoice(type));
    getInvoices(val)
      .then((resp) => dispatch(fetchComplete(type, resp)))
      .catch((err) => dispatch(ErrorFetching(type, getErrorMsg(err))));
  };
};

const getPaids = (val) => {
  const type = types.getpaid;
  return (dispatch) => {
    dispatch(fetchingInvoice(type));
    getInvoices(val)
      .then((resp) => dispatch(fetchComplete(type, resp)))
      .catch((err) => dispatch(ErrorFetching(type, getErrorMsg(err))));
  };
};

const getOverdues = (val) => {
  const type = types.getoverdue;
  return (dispatch) => {
    dispatch(fetchingInvoice(type));
    getInvoices(val)
      .then((resp) => dispatch(fetchComplete(type, resp)))
      .catch((err) => dispatch(ErrorFetching(type, getErrorMsg(err))));
  };
};

const getAllInvs = (val) => {
  const type = types.getallIn;
  return (dispatch) => {
    dispatch(fetchingInvoice(type));
    getInvoices(val)
      .then((resp) => dispatch(fetchComplete(type, resp)))
      .catch((err) => dispatch(ErrorFetching(type, getErrorMsg(err))));
  };
};
const getProcessingInvs = (val) => {
  const type = types.getprocessingIn;
  return (dispatch) => {
    dispatch(fetchingInvoice(type));
    getInvoices(val)
      .then((resp) => dispatch(fetchComplete(type, resp)))
      .catch((err) => dispatch(ErrorFetching(type, getErrorMsg(err))));
  };
};

const getFailedInvs = (val) => {
  const type = types.getfailedIn;
  return (dispatch) => {
    dispatch(fetchingInvoice(type));
    getInvoices(val)
      .then((resp) => dispatch(fetchComplete(type, resp)))
      .catch((err) => dispatch(ErrorFetching(type, getErrorMsg(err))));
  };
};

export const getUnpaidInvs = (filter, data) => {
  return (dispatch) => {
    dispatch(getUnpaidFilter(filter, data));
    const filData = store.getState().InvoiceReducer.unpaidFilter;
    let updatedValue = {
      page: filData.page,
      ...filData.default,
      ...filData.sorting
    };
    if (filData.search.length > 0) {
      updatedValue = {
        ...updatedValue,
        search: filData.search
      };
    }
    if (Object.keys(filData.customFilter).length > 0) {
      updatedValue = {
        ...updatedValue,
        ...filData.customFilter
      };
    }
    dispatch(getUnpaids(updatedValue));
  };
};

export const getPaidInvs = (filter, data) => {
  return (dispatch) => {
    dispatch(getPaidFilter(filter, data));
    const filData = store.getState().InvoiceReducer.paidFilter;
    let updatedValue = {
      page: filData.page,
      ...filData.default,
      ...filData.sorting
    };
    if (filData.search.length > 0) {
      updatedValue = {
        ...updatedValue,
        search: filData.search
      };
    }
    if (Object.keys(filData.customFilter).length > 0) {
      updatedValue = {
        ...updatedValue,
        ...filData.customFilter
      };
    }
    dispatch(getPaids(updatedValue));
  };
};

export const getOverdueInvs = (filter, data) => {
  return (dispatch) => {
    dispatch(getDueFilter(filter, data));
    const filData = store.getState().InvoiceReducer.overdueFilter;

    let updatedValue = {
      page: filData.page,
      ...filData.default,
      ...filData.sorting,
      ...filData.customFilter
    };
    if (filData.search.length > 0) {
      updatedValue = {
        ...updatedValue,
        search: filData.search
      };
    }
    if (Object.keys(filData.customFilter).length > 0) {
      updatedValue = {
        ...updatedValue,
        ...filData.customFilter
      };
    }
    dispatch(getOverdues(updatedValue));
  };
};

export const getAllInvoices = (filter, data) => {
  return (dispatch) => {
    dispatch(getAllFilter(filter, data));
    const filData = store.getState().InvoiceReducer.AllFilter;
    let updatedValue = {
      page: filData.page,
      ...filData.default,
      ...filData.sorting
    };
    if (filData.search.length > 0) {
      updatedValue = {
        ...updatedValue,
        search: filData.search
      };
    }
    if (Object.keys(filData.customFilter).length > 0) {
      updatedValue = {
        ...updatedValue,
        ...filData.customFilter
      };
    }
    dispatch(getAllInvs(updatedValue));
  };
};

export const getProcessingInvoices = (filter, data) => {
  return (dispatch) => {
    dispatch(getProcessingFilter(filter, data));
    const filData = store.getState().InvoiceReducer.processingFilter;
    let updatedValue = {
      page: filData.page,
      ...filData.default,
      ...filData.sorting
    };
    if (filData.search.length > 0) {
      updatedValue = {
        ...updatedValue,
        search: filData.search
      };
    }
    if (Object.keys(filData.customFilter).length > 0) {
      updatedValue = {
        ...updatedValue,
        ...filData.customFilter
      };
    }

    dispatch(getProcessingInvs(updatedValue));
  };
};

export const getFailedInvoices = (filter, data) => {
  return (dispatch) => {
    dispatch(getFailedFilter(filter, data));
    const filData = store.getState().InvoiceReducer.failedFilter;
    let updatedValue = {
      page: filData.page,
      ...filData.default,
      ...filData.sorting
    };
    if (filData.search.length > 0) {
      updatedValue = {
        ...updatedValue,
        search: filData.search
      };
    }
    if (Object.keys(filData.customFilter).length > 0) {
      updatedValue = {
        ...updatedValue,
        ...filData.customFilter
      };
    }

    dispatch(getFailedInvs(updatedValue));
  };
};
