import React, { useContext, useEffect, useState, Fragment } from 'react';
import PropType from 'prop-types';
import { connect } from 'react-redux';
import { Box, withStyles, InputLabel } from '@material-ui/core';
import JBDatePicker from 'src/views/common/FormControl/JBDatePicker';
import {
  setSignInClass,
  setSignInClassOpt,
  setSignInDate,
  setSignInError,
  setSignInProgram,
  setSignedMember,
  addLoginedUser,
  setSignProcessing,
  setSignDialog,
  setuserbyClass
} from 'src/Context/ChalkBoardReducer/chalkBoard.actions';
import { Chalk } from 'src/Context/chalkboardContext';
import Lockicon from 'src/assets/image/lockicon.png';
import Doublelefticon from 'src/assets/image/doublelefticon.png';
import Doubledownicon from 'src/assets/image/doubledownicon.png';
import { getClassWOD } from 'src/services/WODmodule.services';
import { getErrorMsg } from 'src/utils/ErrorHandling';
import {
  getAttendanceByClass,
  getClassLogin
} from 'src/services/chalkBoard.services';
import moment from 'moment';
import { chalkBoardDialogStatus } from 'src/Context/ChalkBoardReducer/chalBoard.reducer.data';
import CutsomAutoComplete from '../common/CustomAutocomplete/commonAutocomplete';
import styles from './Chalkboard.style';
import JBButton from '../common/JBButton/JBButton';
import { chalkBoardValidation } from './chalkBoard.validate';
import { getLogindata } from './chalkBoard.utils';
import ChalkBoardDialog from './ChalkBoardDialog';
// import { getClassLogin } from 'src/services/chalkBoard.services';

const ChalkboardSigninFilter = (props) => {
  const { classes, allPrograms, ActiveMembers } = props;
  const [state, dispatch] = useContext(Chalk);
  const [Edit, setEdit] = useState(true);

  useEffect(() => {
    if (state.SignIn.program && state.SignIn.date) {
      const updateDate = moment(state.SignIn.date).format('YYYY-MM-DD');
      getClassWOD(state.SignIn.program.id, updateDate)
        .then(({ data }) => dispatch(setSignInClassOpt(data)))
        .catch(() => dispatch(setSignInError('No Classes to Show')));
    }
  }, [state.SignIn.program, state.SignIn.date]); // eslint-disable-line

  useEffect(() => {
    if (state.SignIn.program && state.SignIn.date && state.SignIn.class) {
      getAttendanceByClass(state.SignIn.class.id)
        .then((res) => dispatch(setuserbyClass(res)))
        .catch((err) => dispatch(setSignInError(getErrorMsg(err))));
    }
  }, [state.SignIn.class]); // eslint-disable-line

  const handleSignIn = async () => {
    dispatch(setSignProcessing(true));
    try {
      await chalkBoardValidation.validate(state.SignIn);
      const values = getLogindata(state.SignIn);
      const data = await getClassLogin(values);
      if (data.warnings) {
        dispatch(
          setSignDialog(true, data.warnings, chalkBoardDialogStatus.warning)
        );
      } else {
        dispatch(
          setSignDialog(
            true,
            'Successfully Added the User',
            chalkBoardDialogStatus.success
          )
        );
      }
      dispatch(addLoginedUser(data.user_details));
      setTimeout(() => {
        dispatch(setSignProcessing(false));
      }, 10);
    } catch (err) {
      if (err.errors) console.log(err.errors);
      else {
        dispatch(
          setSignDialog(true, getErrorMsg(err), chalkBoardDialogStatus.error)
        );
        dispatch(setSignProcessing(false));
      }
    }
  };
  return (
    <div className={classes.chalkboardfilter}>
      <Box>
        <Box className={classes.filterinfo}>
          <Box onClick={() => setEdit(!Edit)} className={classes.btnfilter}>
            <img alt="img" src={Lockicon} /> <h5>Sign In</h5>
            <img
              alt="img"
              src={Edit ? Doubledownicon : Doublelefticon}
              style={{
                display: 'inline-flex',
                float: 'right',
                verticalAlign: 'middle',
                margin: '5px 0'
              }}
            />
          </Box>
          {Edit && (
            <Fragment>
              <Box mb="15px">
                <InputLabel
                  htmlFor="age-required"
                  className={classes.cutom_select_Label}
                >
                  Program
                </InputLabel>
                <CutsomAutoComplete
                  holder="Select a Program"
                  data={state.SignIn.program}
                  value={allPrograms}
                  Options="name"
                  Change={(val) => dispatch(setSignInProgram(val))}
                  className={classes.chalkboard_cutom_select}
                />
              </Box>
              <Box mb="15px">
                <InputLabel
                  htmlFor="age-required"
                  className={classes.cutom_select_Label}
                >
                  Date
                </InputLabel>
                <JBDatePicker
                  required
                  value={state.SignIn.date}
                  onChange={(val) => dispatch(setSignInDate(val))}
                  className={classes.chalk_cutom_date}
                />
              </Box>
              <Box mb="15px">
                <InputLabel
                  htmlFor="age-required"
                  className={classes.cutom_select_Label}
                >
                  CLASS
                </InputLabel>
                <CutsomAutoComplete
                  holder="Select a Class"
                  data={state.SignIn.class}
                  value={state.SignIn.classOption}
                  Options="name"
                  Change={(val) => dispatch(setSignInClass(val))}
                  className={classes.chalkboard_cutom_select}
                />
              </Box>
              <Box mb="15px">
                <InputLabel
                  htmlFor="age-required"
                  className={classes.cutom_select_Label}
                >
                  MEMBER
                </InputLabel>
                <CutsomAutoComplete
                  holder="SELECT MEMBER"
                  className={classes.chalkboard_cutom_select}
                  data={state.SignIn.selectedMember}
                  value={ActiveMembers || []}
                  Options="name"
                  disabled={state.SignIn.disableMemberDrop}
                  Change={(val) => dispatch(setSignedMember(val))}
                />
              </Box>
              <JBButton
                type="primary"
                disabled={
                  state.SignIn.disableButton || state.SignIn.isProcessing
                  // || getDays(state.SignIn.date, new Date()) !== 0
                }
                style={{
                  width: '100%'
                }}
                onClick={handleSignIn}
              >
                {state.SignIn.isProcessing ? 'Signing In...' : 'Sign In'}
              </JBButton>
            </Fragment>
          )}
        </Box>
        {state.SignIn.openDialog && (
          <ChalkBoardDialog open={state.SignIn.openDialog} />
        )}
      </Box>
    </div>
  );
};

ChalkboardSigninFilter.propTypes = {
  classes: PropType.object.isRequired,
  allPrograms: PropType.array.isRequired,
  ActiveMembers: PropType.array.isRequired
};
const mapToState = (state) => ({
  allPrograms: state.allprograms.programs,
  ActiveMembers: state.memberReducer.allActiveMembers.data
});

export default connect(mapToState)(withStyles(styles)(ChalkboardSigninFilter));
