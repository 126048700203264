import { ROUTES } from 'src/constant/Constants';

// eslint-disable-next-line
export const getActiveVal = (pathname) => {
  switch (pathname) {
    case ROUTES.FINANCIAL_INVOICES:
      return 0;
    case ROUTES.FINANCIAL_INVOICES_PAID:
      return 1;
    case ROUTES.FINANCIAL_INVOICES_OVERDUE:
      return 2;
    case ROUTES.FINANCIAL_INVOICES_PROCESSING:
      return 3;
    case ROUTES.FINANCIAL_INVOICES_FAILED:
      return 4;
    case ROUTES.FINANCIAL_INVOICES_ALL:
      return 5;
    default:
      return 0;
  }
};
