import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid, Box, Paper, InputBase } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import filterType from 'src/Redux/Reducer.constants';
import ApplyFilter from 'src/views/common/ApplyFilter/applyFilter';
import LoadingView from 'src/views/common/loading';
import _ from 'lodash';
import useChangeTitle from 'src/views/common/CustomHooks/useTitleChange';
import { AllClassdata } from 'src/Redux/ClassReducer/classReducer.actions';
// import { DataTable } from './staticData';
import useStyles from './ListTabs.style';
import Paginate from './classList.utils';
import ClassFilters from './ClassFilters';
import ClassTable from './ClassTable';

const AllClassList = (props) => {
  const classes = useStyles();
  const {
    filteredClass,
    classSearch,
    getClass: { isFetching, data, error },
    filteredTags,
    classSort
  } = props;
  const [searchValue, setSearchValue] = useState(classSearch);

  const SearchQuery = useCallback(
    _.debounce((q) => filteredClass(filterType.search, q), 1000),
    []
  );
  useChangeTitle('All Classes');
  const setDirection = (name) => {
    if (classSort.sort_name === name) {
      return classSort.sort_by === 'asc' ? 'asc' : 'dsc';
    }
    return 'asc';
  };

  if (error) return <p>{error}</p>;
  //  debugging
  const isActive = (sortlabel) => {
    if (classSort.sort_name === sortlabel) return true;
    return false;
  };
  const setSort = (sortlabel) => {
    filteredClass(filterType.sorting, sortlabel);
  };
  return (
    <div className={classes.root}>
      <Grid
        container
        direction="row"
        justify="start"
        alignItems="center"
        spacing={0}
        style={{ margin: '20px 0 20px' }}
      >
        <Grid container disableGutters style={{ marginBottom: '15px' }}>
          <Box mr="15px">
            <ApplyFilter>
              <ClassFilters
                getFilData={(val, val2) =>
                  filteredClass(filterType.addcustom, [val, val2])
                } // eslint-disable-line
              />
            </ApplyFilter>
          </Box>
          <Grid item xs>
            <div className={classes.search}>
              <InputBase
                fullWidth
                placeholder="Search…"
                value={searchValue}
                onChange={(e) => {
                  setSearchValue(e.target.value);
                  SearchQuery(e.target.value);
                }}
                classes={{
                  root: classes.inputRoot,
                  input: classes.searchInput
                }}
                InputProps={{
                  'aria-label': 'search'
                }}
              />
            </div>
          </Grid>
        </Grid>
        <div>
          {filteredTags.map((tags) => (
            <div
              className={classes.DTags}
              key={tags.id}
              style={{ margin: '0 0 15px', display: 'inline-block' }}
            >
              <Box display="inline-block" mr={1}>
                <label>
                  {tags.name}
                  <span
                    className={classes.DTagsClose}
                    onClick={() => filteredClass(filterType.removecustom, tags)}
                  >
                    ×
                  </span>
                </label>
              </Box>
            </div>
          ))}
        </div>
        {isFetching ? (
          <LoadingView />
        ) : (
          <>
            <Grid item xs={12} className={classes.SimpleTablestyle}>
              <ClassTable
                values={data.data}
                toAllClass={classSort}
                setDirection={setDirection}
                isActive={isActive}
                setSort={setSort}
                total={data.total}
              />
            </Grid>

            <Grid
              item
              xs={12}
              container
              direction="row"
              alignItems="center"
              style={{ marginTop: 24 }}
            >
              <Grid item xs={6} justify="flex-start">
                {/* <Paper elevation={0} style={{ color: '#A9A9A9' }}>
              {data.from} - {data.to}
            </Paper> */}
                {data.from && data.to > 1 ? (
                  <Paper elevation={0} style={{ color: '#A9A9A9' }}>
                    {`${data.from} - ${data.to} of ${data.total}`}
                  </Paper>
                ) : null}
              </Grid>
              <Grid item xs={6} justify="flex-end" style={{ display: 'flex' }}>
                <Pagination
                  count={data.last_page}
                  page={data.current_page}
                  color="primary"
                  variant="outlined"
                  onChange={(__, val) => Paginate(val, data, AllClassdata)}
                  shape="rounded"
                />
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
};
AllClassList.propTypes = {
  filteredClass: PropTypes.func.isRequired,
  getClass: PropTypes.object.isRequired,
  classSearch: PropTypes.string.isRequired,
  classSort: PropTypes.object.isRequired,
  filteredTags: PropTypes.array.isRequired
};

const mapToDispatch = (dispatch) => ({
  filteredClass: (filter, data) => dispatch(AllClassdata(filter, data))
});
const mapToState = (state) => ({
  getClass: state.classReducer.allClass,
  classSearch: state.classReducer.allClassFilter.search,
  classSort: state.classReducer.allClassFilter.sorting,
  filteredTags: state.classReducer.allClassFilter.tags
});
export default connect(mapToState, mapToDispatch)(AllClassList);
