/* eslint-disable operator-linebreak */
import { getValuefromObject, backendDate } from 'src/utils/someCommon';

export const Filters = [
  { id: 1, filter: 'Member since' },
  { id: 2, filter: 'membership type' },
  { id: 3, filter: 'membership plan' }
];
export const MemType = [
  { id: 'plan', filter: 'Plan' },
  { id: 'session', filter: 'Session' },
  { id: 'drop_in', filter: 'Drop in' },
  { id: 'all', filter: 'All' }
];
export const MemSince = [
  { id: 'all_time', filter: 'All Time' },
  { id: 'custom', filter: 'Custom' },
  { id: 'lastmonth', filter: 'Last Month' },
  { id: 'lastyear', filter: 'Last Year' }
];
export const getFilter = (filter, dateFilter, data, endDate) => {
  switch (filter.id) {
    case 1:
      if (dateFilter.id === 'custom') {
        return {
          member_since: 'custom',
          date_range: backendDate(data) + '-' + backendDate(endDate)
        };
      }
      return {
        member_since: dateFilter.id
      };

    case 2:
      return {
        membership_type: data.id
      };
    case 3:
      return {
        membership_plan: getValuefromObject(data)
      };
    default:
      return null;
  }
};
export const getfilterLabel = (filter, dateFilter, data, endDate) => {
  switch (filter.id) {
    case 1:
      if (dateFilter?.id === 'custom') {
        return {
          id: filter?.id,
          name: `Member since is ${backendDate(data) +
            '-' +
            backendDate(endDate)}`,
          filter: 'member_since'
        };
      }
      return {
        id: filter?.id,
        name: `Member since is ${dateFilter.filter}`,
        filter: 'member_since'
      };
    case 2:
      return {
        id: filter.id,
        name: `Membership type is ${data.filter}`,
        filter: 'membership_type'
      };
    case 3:
      return {
        id: filter.id,
        name: `Membership plan is ${Object.values(data)[1]}`,
        filter: 'membership_plan'
      };
    default:
      return null;
  }
};
export const checkData = (filter, data, paymentdue, endDate) => {
  if (filter?.id === 1) {
    if (!paymentdue) {
      return true;
    }
    if (paymentdue?.id === 'custom' && !data) return true;
    if (paymentdue?.id === 'custom' && !endDate) {
      return true;
    }
  } else if (!filter || !data || String(data) === 'Invalid') return true;
  return false;
};
