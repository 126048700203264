export default {
  getAllActiveMem: 'GET_ALL_ACTIVE_MEMBERS',
  getActiveMem: 'FETCH_ACTIVE_MEMBERS',
  getInActiveMem: 'FETCH_INACTIVE_MEMBERS',
  getAllMem: 'FETCH_ALL_MEMBERS',
  setActiveFilter: 'APPLY_FILTER_IN_ACTIVE_MEMBERS',
  setInActiveFilter: 'APPLY_FILTER_IN_INACTIVE_MEMBERS',
  setAllFilter: 'APPLY_FILTER_IN_ALL_MEMBERS',
  isFetching: 'MEMBERS_FETCHING_START',
  fetchSucess: 'MEMBERS_FETCHED_SUCCESSFUL',
  Errorfetch: 'ERROR_OCCURED_WHEN_FETCHING',
  toggleSelect: 'SELECT_ALL'
};
