export const getpaymentvalue = (data) => {
  const paymentdata = data.payment_data;
  let getvals = {
    full_fee: paymentdata.initial_commitment_pricing.full_membership_fees,
    setup_fee: paymentdata.initial_commitment_pricing.full_setup_fees
  };
  if (data.initial_pricing === 'weekly') {
    getvals = {
      setup_fee: paymentdata.initial_commitment_pricing.week_setup_fees,
      full_fee: paymentdata.initial_commitment_pricing.week_membership_fees,
      memfee: true
    };
  }
  return getvals;
};

const getFeeTotal = (data, type) => {
  const getvals = getpaymentvalue(data);
  const paymentdata = data.payment_data;
  if (
    typeof getvals.full_fee !== 'number'
    || typeof getvals.setup_fee !== 'number'
  ) {
    return 0;
  }

  if (type === 'session') {
    return getvals.full_fee;
  }
  const totalweek = parseInt(paymentdata.initial_commitment.initial, 10)
    / paymentdata.initial_commitment.billed_every.id;
  let weekfee = getvals.full_fee;
  if (getvals.memfee) {
    weekfee = totalweek * getvals.full_fee;
  }
  return weekfee;
};

export const getrenpaymentvalue = (data) => {
  const paymentdata = data.payment_data;
  let getvals = {
    full_fee: paymentdata.auto_renewal_pricing.full_membership_fees
  };
  if (data.renewal_pricing === 'weekly') {
    getvals = {
      full_fee: paymentdata.auto_renewal_pricing.week_membership_fees,
      memfee: true
    };
  }
  return getvals;
};

export const getsetupdiscount = (data) => {
  const vals = getpaymentvalue(data);
  if (
    typeof data.discount.setup.amount !== 'number'
    || typeof vals.setup_fee !== 'number'
    || (data.discount.setup.type === 'fixed'
      && vals.setup_fee < data.discount.setup.amount)
    || (data.discount.setup.type === 'percentage'
      && data.discount.setup.amount > 100)
  ) {
    return 0;
  }
  let result = 0;
  if (data.discount.setup.type === 'fixed') {
    result = data.discount.setup.amount;
    return result;
  }
  if (data.initial_pricing === 'weekly') {
    const totalweek = parseInt(data.payment_data.initial_commitment.initial, 10)
      / data.payment_data.initial_commitment.billed_every.id;

    result *= totalweek;
  }
  result = vals.setup_fee * (data.discount.setup.amount / 100);
  return result;
};

export const getmemdiscount = (data) => {
  const vals = getpaymentvalue(data);
  if (
    typeof data.discount.initial.amount !== 'number'
    || typeof vals.full_fee !== 'number'
    || (data.discount.initial.type === 'fixed'
      && vals.full_fee < data.discount.initial.amount)
    || (data.discount.initial.type === 'percentage'
      && data.discount.initial.amount > 100)
  ) {
    return 0;
  }
  let result = 0;
  if (data.discount.initial.type === 'fixed') {
    result = data.discount.initial.amount;
  } else {
    result = vals.full_fee * (data.discount.initial.amount / 100);
  }
  return result;
};

export const getrenewaldiscount = (data) => {
  const vals = getrenpaymentvalue(data);
  if (
    typeof data.discount.renewal.amount !== 'number'
    || typeof vals.full_fee !== 'number'
    || (data.discount.renewal.type === 'fixed'
      && vals.full_fee < data.discount.renewal.amount)
    || (data.discount.renewal.type === 'percentage'
      && data.discount.renewal.amount > 100)
  ) {
    return 0;
  }
  let result = 0;
  if (data.discount.renewal.type === 'fixed') {
    result = data.discount.renewal.amount;
  } else {
    result = vals.full_fee * (data.discount.renewal.amount / 100);
    // if (data.renewal_pricing === 'weekly') {
    //   const totalweek =
    //     parseInt(data.payment_data.auto_renew_meta.renewal, 10) /
    //     data.payment_data.auto_renew_meta.billed_every.id;

    //   result *= totalweek;
    // }
  }
  return result;
};

export const getIniDiscount = (data) => {
  const setupdiscount = getsetupdiscount(data);
  const memdiscount = getmemdiscount(data);
  let totalDiscount = setupdiscount + memdiscount;

  if (data.initial_pricing === 'weekly') {
    const totalweek = parseInt(data.payment_data.initial_commitment.initial, 10)
      / data.payment_data.initial_commitment.billed_every.id;

    totalDiscount = totalweek * memdiscount + setupdiscount;
  }
  return totalDiscount;
};

export const getIniSubTotal = (data, type) => {
  const getvals = getpaymentvalue(data);
  const paymentdata = data.payment_data;
  if (
    typeof getvals.full_fee !== 'number'
    || typeof getvals.setup_fee !== 'number'
  ) {
    return 0;
  }

  if (type === 'session') {
    return getvals.full_fee + getvals.setup_fee - getIniDiscount(data);
  }

  if (type === 'drop_in') {
    return getvals.full_fee + getvals.setup_fee;
  }

  const totalweek = parseInt(paymentdata.initial_commitment.initial, 10)
    / paymentdata.initial_commitment.billed_every.id;
  let weekfee = getvals.full_fee;
  if (getvals.memfee) {
    weekfee = totalweek * getvals.full_fee;
  }
  const totalfee = weekfee + getvals.setup_fee;
  return totalfee - getIniDiscount(data);
};

export const gettotalrendiscount = (data) => {
  const rendiscount = getrenewaldiscount(data);
  let result_total = rendiscount;
  if (data.renewal_pricing === 'weekly') {
    const totalweek = parseInt(data.payment_data.auto_renew_meta.renewal, 10)
      / data.payment_data.auto_renew_meta.billed_every.id;

    result_total *= totalweek;
  }
  return result_total;
};

export const getRenSubTotal = (data) => {
  const getvals = getrenpaymentvalue(data);
  const paymentdata = data.payment_data;
  if (typeof getvals.full_fee !== 'number') {
    return 0;
  }

  const totalweek = parseInt(paymentdata.auto_renew_meta.renewal, 10)
    / paymentdata.auto_renew_meta.billed_every.id;
  let weekfee = getvals.full_fee;
  if (getvals.memfee) {
    weekfee = totalweek * getvals.full_fee;
  }
  const totalfee = weekfee - gettotalrendiscount(data);
  return totalfee;
};

export const getIniTotalDue = (data, type) => {
  const subtotal = getIniSubTotal(data, type);
  return subtotal;
};

export const gettaxtotal = (data) => {
  const getvals = getpaymentvalue(data);
  if (
    typeof getvals.full_fee !== 'number'
    || typeof getvals.setup_fee !== 'number'
  ) {
    return 0;
  }
  const rate = 1 + data.payment_data.tax_rate / 100;
  // const totalrate = data.payment_data.tax_rate / 100;
  const setupfee = getvals.setup_fee - getsetupdiscount(data);
  let memfee = getvals.full_fee - getmemdiscount(data);
  if (data.initial_pricing === 'weekly') {
    const fullfee = getFeeTotal(data);
    const totalweek = parseInt(data.payment_data.initial_commitment.initial, 10)
      / data.payment_data.initial_commitment.billed_every.id;
    const discountmem = getmemdiscount(data) * totalweek;
    memfee = fullfee - discountmem;
  }
  const setupdis = setupfee - setupfee / rate;
  const fulldis = memfee - memfee / rate;
  let result = 0;
  result = fulldis + setupdis;

  return result;
};

//
export const getDropIntaxtotal = (data) => {
  const getvals = getpaymentvalue(data);
  if (
    typeof getvals.full_fee !== 'number'
    || typeof getvals.setup_fee !== 'number'
  ) {
    return 0;
  }
  const rate = 1 + data.payment_data.tax_rate / 100;
  const setupfee = getvals.setup_fee;
  // eslint-disable-next-line prefer-const
  let memfee = getvals.full_fee;
  const setupdis = setupfee - setupfee / rate;
  const fulldis = memfee - memfee / rate;
  let result = 0;
  result = fulldis + setupdis;
  return result;
};
//

export const getRenTax = (data) => {
  const getvals = getrenpaymentvalue(data);
  if (typeof getvals.full_fee !== 'number') {
    return 0;
  }
  const rate = 1 + data.payment_data.tax_rate / 100;
  const memfee = getRenSubTotal(data);
  const fulldis = memfee - memfee / rate;
  return fulldis;
};

export const changeinisetcost = (data) => {
  if (data.initial_pricing === 'weekly') {
    return 'week_setup_fees';
  }
  return 'full_setup_fees';
};

export const changeinimemfee = (data) => {
  if (data.initial_pricing === 'weekly') {
    return 'week_membership_fees';
  }
  return 'full_membership_fees';
};

export const changerenwalfee = (data) => {
  if (data.renewal_pricing === 'weekly') {
    return 'week_membership_fees';
  }
  return 'full_membership_fees';
};

export const getinisetupcost = (data) => {
  if (data.initial_pricing === 'weekly') {
    return data.payment_data.initial_commitment_pricing.week_setup_fees;
  }
  return data.payment_data.initial_commitment_pricing.full_setup_fees;
};

export const getinimemcost = (data) => {
  if (data.initial_pricing === 'weekly') {
    return data.payment_data.initial_commitment_pricing.week_membership_fees;
  }
  return data.payment_data.initial_commitment_pricing.full_membership_fees;
};

export const getrenmemcost = (data) => {
  if (data.renewal_pricing === 'weekly') {
    return data.payment_data.auto_renewal_pricing.week_membership_fees;
  }
  return data.payment_data.auto_renewal_pricing.full_membership_fees;
};

export const updateIniTaxes = (data, type) => {
  const taxamount = gettaxtotal(data);
  const subtotal = getIniSubTotal(data, type);
  if (data.initial_pricing === 'weekly') {
    return {
      week_tax_amount: taxamount,
      week_total: subtotal
    };
  }
  return {
    full_tax_amount: taxamount,
    full_total: subtotal
  };
};

export const updaterenTaxes = (data) => {
  const taxamount = getRenTax(data);
  const subtotal = getRenSubTotal(data);
  if (data.renewal_pricing === 'weekly') {
    return {
      week_tax_amount: taxamount,
      week_total: subtotal
    };
  }
  return {
    full_tax_amount: taxamount,
    full_total: subtotal
  };
};
