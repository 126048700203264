import React from 'react';
import { connect } from 'react-redux';
import {
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  withStyles,
  Grid,
  FormControlLabel,
  Radio,
  RadioGroup
} from '@material-ui/core';
import PropType from 'prop-types';
import GetPrice from 'src/views/common/CurrencySymbol/GetPrice';
import styles from '../../../../table.style';
import { chngeinitialPrice } from '../../NewMembership.actions';

function DropinPaymentOptionTable(props) {
  const { classes, commitprice, reducer, isEditable } = props;

  const [state, dispatch] = reducer;

  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      spacing={0}
      style={{ margin: '3px 0 20px' }}
    >
      <TableContainer
        style={{ border: '1px solid #A8A8A8', borderRadius: '5px' }}
      >
        <Table className={classes.table} aria-label="simple table">
          <TableHead className={classes.table_th}>
            <TableRow>
              <TableCell
                align="right"
                style={{ width: '5%' }}
                className={classes.TableCell}
              >
                &nbsp;
              </TableCell>
              <TableCell className={classes.TableCell} style={{ width: '20%' }}>
                Payment option type
              </TableCell>
              <TableCell className={classes.TableCell} style={{ width: '20%' }}>
                Setup fee
              </TableCell>
              <TableCell className={classes.TableCell}>Fee</TableCell>
              <TableCell className={classes.TableCell}>Tax</TableCell>
              <TableCell className={classes.TableCell}>Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <RadioGroup
                value={state.data.initial_pricing}
                onChange={(e) => dispatch(chngeinitialPrice(e.target.value))}
                disabled={!isEditable}
                style={{
                  display: 'contents'
                }}
              >
                <TableCell>
                  <FormControlLabel
                    value="full"
                    control={<Radio color="primary" />}
                    style={{ margin: '0 0' }}
                    disabled={!isEditable}
                  />
                </TableCell>
                <TableCell className={classes.Table_td}>Pay in Full</TableCell>
                <TableCell className={classes.Table_td}>
                  <GetPrice value={commitprice.full_setup_fees} />
                </TableCell>
                <TableCell className={classes.Table_td}>
                  <GetPrice value={commitprice.full_membership_fees} />
                </TableCell>
                <TableCell className={classes.Table_td}>
                  <GetPrice value={commitprice.full_tax_amount} />
                </TableCell>
                <TableCell className={classes.Table_td}>
                  <GetPrice value={commitprice.full_total} />
                </TableCell>
              </RadioGroup>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}

DropinPaymentOptionTable.defaultProps = {
  isEditable: true
};

DropinPaymentOptionTable.propTypes = {
  classes: PropType.object.isRequired,
  commitprice: PropType.object.isRequired,
  reducer: PropType.array.isRequired,
  isEditable: PropType.bool
};

export default connect()(withStyles(styles)(DropinPaymentOptionTable));
