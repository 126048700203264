import moment from 'moment';
import { getCalender } from 'src/services/classModule.services';
import { getErrorMsg } from 'src/utils/ErrorHandling';
import { store } from '../rootReducer';
import types from './Calender.types';
import { getUpdatedCalender } from './Calnder.utils';

const getData = (payload) => ({
  type: types.getData,
  payload
});

const fetchComplete = (payload) => ({
  type: types.fetchSucess,
  payload
});

const changeDateRange = (payload) => ({
  type: types.changeDefault,
  payload
});

// eslint-disable-next-line
export const getCalenderData = () => {
  return (dispatch) => {
    const date = store.getState().calenderReducer.defaultDate;
    // const from = new Date(date.getFullYear(), date.getMonth(), 1);
    // const to = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    const from = moment(date)
      .subtract(7, 'days')
      .format('YYYY-MM-DD');
    const to = moment(date)
      .add(7, 'days')
      .format('YYYY-MM-DD');
    const values = {
      from,
      to
    };
    const type = types.getData;
    dispatch(getData(type));
    getCalender(values)
      .then((res) => {
        dispatch(fetchComplete(getUpdatedCalender(res)));
      })
      .catch((err) => getErrorMsg(err));
  };
};

export const changeDateCalender = (date) => {
  return (dispatch) => {
    dispatch(changeDateRange(date));
    dispatch(getCalenderData());
  };
};

export const ChangeViewCal = (payload) => ({
  type: types.changeView,
  payload
});
