import React from 'react';
import PropTypes from 'prop-types';
import { ROUTES } from 'src/constant/Constants';
import { Link, Route, Switch, useLocation } from 'react-router-dom';
import { AppBar, Card, Tab, Tabs, withStyles } from '@material-ui/core';
import useChangeTitle from 'src/views/common/CustomHooks/useTitleChange';
import { get } from 'js-cookie';
import ErrorUserView from 'src/views/errorUserPermission/errorUserView';
import { getActiveVal } from './classSetting.utils';
import MembershipHoldTypes from './MembershipHoldTypes';
import MembershipProgramsTab from './MembershipProgramsTab';
import Attendancetabs from './Attendancetabs';

const styles = () => ({
  // const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1
  },
  STabPanel: {
    backgroundColor: '#fff',
    boxShadow: '4px 0px 16px rgba(37, 40, 43, 0.08)',
    '& .MuiCard-root.Tabulation-mainCard-136': {
      boxShadow: 'none',
      padding: '0 0'
    }
  },
  hr: {
    border: '1px solid rgb(196 196 196 / 0.4)',
    display: 'inline-block',
    width: '100%',
    margin: '10px 0'
  },
  ptext: {
    fontSize: '16px',
    lineHeight: '24px',
    color: '#7F7F7F',
    fontWeight: 'normal'
  },
  cutom_Switch_Label: {
    display: 'inline-block',
    marginRight: '30px',
    verticalAlign: 'middle',
    fontSize: '14px',
    fontWeight: '700',
    color: '#000'
  },
  mainCard: {
    boxShadow: '4px 0px 16px rgba(37, 40, 43, 0.08)',
    borderRadius: '5px',
    background: '#FFFF',
    padding: 20
  },
  p_tabs: {
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px'
  }
});

const MembershipTabs = ({ classes }) => {
  const location = useLocation();
  const Tabslocation = getActiveVal(location.pathname);
  const roleid = JSON.parse(get('jbrole') || '{}')?.role_id || 3;
  useChangeTitle('Classes Settings');
  return (
    <div className={classes.root}>
      <Card className={classes.mainCard}>
        <AppBar
          position="static"
          style={{
            backgroundColor: 'transparent',
            boxShadow: 'none'
          }}
        >
          {roleid === 1 ? (
            <Tabs
              value={Tabslocation}
              indicatorColor="primary"
              textColor="primary"
              fullWidth
              // onChange={(val) => changeTab(val)}
            >
              <Tab
                label="Attendance"
                component={Link}
                to={ROUTES.CLASSES_SETTINGS_MEMBERSHIP}
                className={classes.p_tabs}
                // {...a11yProps(0)} // eslint-disable-line
              />
              <Tab
                label="mass update membership programs"
                component={Link}
                to={ROUTES.CLASSES_SETTINGS_MEMBERSHIP_MASS_UPDATE}
                className={classes.p_tabs}
                // {...a11yProps(1)} // eslint-disable-line
              />
              <Tab
                label="membership hold types"
                component={Link}
                to={ROUTES.CLASSES_SETTINGS_MEMBERSHIP_HOLD_TYPES}
                className={classes.p_tabs}
                // {...a11yProps(2)} // eslint-disable-line
              />
            </Tabs>
          ) : (
            <Tabs
              value={0}
              indicatorColor="primary"
              textColor="primary"
              fullWidth
              // onChange={(val) => changeTab(val)}
            >
              <Tab
                label="membership hold types"
                component={Link}
                to={ROUTES.CLASSES_SETTINGS_MEMBERSHIP_HOLD_TYPES}
                className={classes.p_tabs}
                // {...a11yProps(2)} // eslint-disable-line
              />
            </Tabs>
          )}
        </AppBar>
        <Switch>
          <Route
            exact
            path={ROUTES.CLASSES_SETTINGS_MEMBERSHIP_HOLD_TYPES}
            render={() => <MembershipHoldTypes />}
          />
          <Route
            path={ROUTES.CLASSES_SETTINGS_MEMBERSHIP_MASS_UPDATE}
            render={() => {
              if (roleid === 1) {
                return <MembershipProgramsTab />;
              }
              return <ErrorUserView />;
            }}
          />
          <Route
            path={ROUTES.CLASSES_SETTINGS_MEMBERSHIP}
            render={() => {
              if (roleid === 1) {
                return <Attendancetabs />;
              }
              return <ErrorUserView />;
            }}
          />
        </Switch>
      </Card>
    </div>
  );
};

MembershipTabs.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(MembershipTabs);
