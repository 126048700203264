import React, { useReducer, useState } from 'react';
import { connect } from 'react-redux';
import PropType from 'prop-types';
import {
  Grid,
  Button,
  Card,
  withStyles,
  DialogContent,
  DialogTitle
} from '@material-ui/core';
import JBStepper from 'src/views/common/JBGridStepper';
import { openNoti } from 'src/Redux/global/global.actions';
import ColorlibStepIcon from 'src/views/common/JBGridStepper/ColorlibStepIcon';
import { ReactComponent as PlanDetails } from 'src/assets/image/JBSteppericon/membership/PlanDetails.svg';
import { ReactComponent as PaymentPlans } from 'src/assets/image/JBSteppericon/membership/PaymentPlans.svg';
import dcloseicon from 'src/assets/image/closeicon.png';
import CancelDialogTwo from 'src/views/common/CommonDialogBox/CancelDialogTwo';
import styles from '../PaymentPlan.style';
import PlanBasicDetails from './PlanBasicDetails';
import PlanPricing from './PlanPricing';
import { sessionReducer, initialValue } from './Sessionpaymentplan.Reducer';
import { validStep1, validStep2 } from './Sessionpayment.validation';
import { includeTaxinitials } from '../../MembershipTemplate/MemTemplate.utils';

function getSteps() {
  return ['Basic Details', 'Pricing'];
}

function PaymentPlan(props) {
  const { classes, handleClose, values, allTaxes, dialogtitle } = props;
  const [activeStep, setActiveStep] = useState(0);
  const [cancelDialog, setcancelDialog] = useState(false);
  const [state, dispatch] = useReducer(
    sessionReducer,
    values ? values : includeTaxinitials(initialValue, allTaxes || []) // eslint-disable-line
  );
  const steps = getSteps();

  const handleNext = () => {
    const { allpayments, OpenNotify, getData, editIndex } = props;

    if (activeStep === 0) {
      const { error } = validStep1(state, allpayments, editIndex);
      if (error) {
        OpenNotify(error, 'error');
      } else {
        window.scrollTo(0, 0);
        setActiveStep((prev) => prev + 1);
      }
    } else {
      const { error } = validStep2(state);
      if (error) {
        OpenNotify(error, 'error');
      } else {
        getData(state);
        OpenNotify('Payment plan saved successfully', 'info');
        handleClose();
      }
    }
  };

  const handleBack = () => {
    setActiveStep((prev) => prev - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <PlanBasicDetails reducer={[state, dispatch]} />;
      case 1:
        return <PlanPricing reducer={[state, dispatch]} />;
      default:
        return 'Unknown step';
    }
  }

  return (
    <div>
      <div>
        <Grid alignItems="center" container direction="row">
          <Grid item xs>
            <DialogTitle id="alert-dialog-title">
              <h4 className={classes.dialogtitle}>{dialogtitle}</h4>
            </DialogTitle>
          </Grid>
          <Grid item xs={2} style={{ textAlign: 'right', marginRight: '10px' }}>
            <Button
              className={classes.btns}
              onClick={() => setcancelDialog(true)}
            >
              <img alt="img" src={dcloseicon} />
            </Button>
          </Grid>
        </Grid>

        <DialogContent>
          <Card elevation={0}>
            <JBStepper
              className={classes.DialogJBStepper}
              steps={steps}
              activeStep={activeStep}
              handleBack={handleBack}
              handleNext={handleNext}
              handleReset={handleReset}
              handleStepContent={getStepContent}
              isLoading={false}
              hasErrored={false}
              // prevLabel="CREATE"
              prevLabel={!values ? 'CREATE' : 'Save'}
              nextLabel="NEXT STEP"
              CustComp={(prop) => (
                <ColorlibStepIcon
                  FirstIcon={PlanDetails}
                  SecondIcon={PaymentPlans}
                  {...prop} // eslint-disable-line
                />
              )}
            />
          </Card>
        </DialogContent>
        <CancelDialogTwo
          setOpen={cancelDialog}
          handleBack={() => {
            setcancelDialog(false);
            handleClose();
          }}
          handleClose={() => setcancelDialog(false)}
        />
      </div>
    </div>
  );
}

PaymentPlan.defaultProps = {
  values: null,
  editIndex: null,
  dialogtitle: 'Create New Payment Plan'
};

PaymentPlan.propTypes = {
  classes: PropType.object.isRequired,
  handleClose: PropType.func.isRequired,
  getData: PropType.func.isRequired,
  values: PropType.object,
  allpayments: PropType.array.isRequired,
  allTaxes: PropType.array.isRequired,
  OpenNotify: PropType.func.isRequired,
  editIndex: PropType.number,
  dialogtitle: PropType.string
};

const mapToState = (state) => ({
  allTaxes: state.SettingReducer.taxRates.data
});

const mapToDispatch = (dispatch) => ({
  OpenNotify: (msg, type) => dispatch(openNoti(msg, type))
});

export default connect(
  mapToState,
  mapToDispatch
)(withStyles(styles)(PaymentPlan));
