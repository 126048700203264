/* eslint-disable react/jsx-props-no-spreading */
import React, { memo, useEffect, useRef, useState } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ROUTES } from 'src/constant/Constants';
import PropType from 'prop-types';
import { get, set } from 'js-cookie';
import { getCurrentUser } from 'src/services/user.service';
import DashboardLayout from 'src/layouts/DashboardLayout';
import ChalkboardLayout from 'src/layouts/ChalkboardLayout';
import MasterHQLayout from 'src/layouts/MasterHQLayout';
import VanguardLayout from 'src/layouts/Vanguard';
import DashboardNav from 'src/layouts/DashboardLayout/NavBar/DashboardNav/DashboardNav';
import TopBar from 'src/layouts/DashboardLayout/TopBar';
import JBStripeDialogue from 'src/views/common/JBStripeDialogue/JBStripeDialogue';
import LoadingView from 'src/views/common/loading';
import SearchBoxDialog from 'src/views/common/SearchBox/SearchBoxDialog';
import ErrorUserView from 'src/views/errorUserPermission/errorUserView';
import { getlogOut } from 'src/services/auth.services';
import persistreduxpriorityData from './data/persistreduxpriority.data';

const ProgramContainer = memo((props) => {
  const [state, setState] = useState(() => ({
    currentUser: null,
    error: false,
    fetching: true
  }));
  const [isMiniActive, setMiniActive] = useState(true);
  const [stripeDialog, setStripeDialog] = useState(() => {
    try {
      return JSON.parse(get('account') || '{}')?.dialog;
    } catch (err) {
      return true;
    }
  });
  const dispatch = useDispatch();
  const isMaster = useRef();
  const Location = useLocation();
  async function hitAPIs() {
    try {
      const res = await getCurrentUser();
      const dialgeavailable = get('account');
      if (res.account_details && !dialgeavailable) {
        const details = {
          ...res?.account_details,
          dialog: true
        };
        set('account', details);
        set('first_name', res.first_name);
      }
      persistreduxpriorityData.forEach((l) => {
        if (!l.loc) {
          dispatch(l.func());
        }
      });
      setState({ ...state, currentUser: res, fetching: false });
      if (res.affiliate_id) {
        set('affiliate', res.affiliate_id);
      }
    } catch (err) {
      if (err?.response?.status === 429) {
        setTimeout(() => {
          hitAPIs();
        }, 5000);
      } else {
        console.log(err);
        setState({ ...state, error: true, fetching: false });
      }
    }
  }
  useEffect(() => {
    hitAPIs();
    try {
      isMaster.current = Boolean(JSON.parse(get('jbrole') || '{}')?.master);
    } catch (err) {
      setState({ ...state, error: true, fetching: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const miniMenu = localStorage.getItem('mini-menu');
  useEffect(() => {
    if (isMiniActive === true) {
      setMiniActive(true);
      document.body.classList.add('MiniMenuOpen');
    }
    localStorage.setItem('mini-menu', isMiniActive);
    if (Location.pathname.includes('chalkboard')) {
      setMiniActive(true);
      document.body.classList.add('MiniMenuOpen');
    }
  }, [isMiniActive, Location]);

  // Mini Menu
  const toggleClass = () => {
    // 👇️ toggle class on the body element
    // console.log(isMiniActive, 'isMiniActive');
    if (isMiniActive) {
      setMiniActive(false);
      document.body.classList.toggle('MiniMenuOpen');
      localStorage.setItem('mini-menu', 'false');
    } else {
      setMiniActive(true);
      document.body.classList.toggle('MiniMenuOpen');
      localStorage.setItem('mini-menu', 'true');
    }
  };

  const { currentUser, error, fetching } = state;
  const {
    match: { url }
  } = props;

  if (!localStorage.getItem('x-token')) {
    return (window.location = ROUTES.APP_LOGIN); // eslint-disable-line
  }
  if (error) {
    // eslint-disable-next-line no-return-assign
    getlogOut().then(() => (window.location = ROUTES.APP_LOGIN));
  }
  if (!currentUser || fetching) return <LoadingView />;

  const isDialog = JSON.parse(get('account'));

  return (
    <div>
      {window.location.pathname.includes('vanguard') ? null : (
        <>
          <TopBar
            currentUser={currentUser}
            className="TopBar"
            toggleMenu={toggleClass}
            MiniActive={isMiniActive}
          />
          <SearchBoxDialog />
        </>
      )}
      {isDialog?.dialog && !isDialog?.account_activated && stripeDialog ? (
        <JBStripeDialogue
          open={isDialog.dialog}
          message={isDialog.message}
          onClose={() => {
            setStripeDialog(() => false);
            set('account', { ...isDialog, dialog: false });
          }}
        />
      ) : null}
      <Switch>
        <Route path={`${url}/chalkboard`} component={ChalkboardLayout} />
        <Route
          path={`${url}/masterhq`}
          render={(prop) => {
            if (!isMaster.current) return <ErrorUserView />;
            return <MasterHQLayout {...prop} />;
          }}
        />
        <Route
          path={`${url}/vanguard`}
          render={() => <VanguardLayout currentUser={currentUser} />}
        />
        <Route
          path={url}
          render={() => <DashboardLayout currentUser={currentUser} />}
        />
      </Switch>
      <DashboardNav />
    </div>
  );
});
ProgramContainer.propTypes = {
  match: PropType.object.isRequired
};

export default ProgramContainer;
