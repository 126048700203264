/* eslint-disable operator-linebreak */
/* eslint-disable no-restricted-globals */

// Regular Expression

export const validPhoneRegEx = /^$|^[0-9 ()+-]+$/;
export const noSpecialCharsRegEx = /[a-zA-Z0-9]/s;
export const validStringRegEx = /^[a-zA-Z ]+$/;
export const validEmailRegEx = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i; // eslint-disable-line
export const validNumsAndStringRegEx = /[^a-zA-Z0-9\s,.]/g;
export const postalRegEx = /^[a-zA-Z0-9][a-zA-Z0-9\s]+$/;
export const UrlRegex = /^((http(s?)?):\/\/)?([wW]{3}\.)?[a-zA-Z0-9\-.]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\/)?$/i;

// eslint-disable-next-line
export function validateString(s1) {
  const s2 = s1;
  let num = s2.length;
  // eslint-disable-next-line
  for (let i = 0; i < s2.length; i++) {
    if (
      (s2.charCodeAt(i) >= 97 && s2.charCodeAt(i) <= 122) ||
      (s2.charCodeAt(i) >= 65 && s2.charCodeAt(i) <= 90)
    ) {
      num = i;
      break;
    }
  }
  return s2.slice(num);
}

export function validateFinalString(s1) {
  const s2 = s1;
  let num = s2.length;
  // eslint-disable-next-line
  for (let i = 0; i < s2.length; i++) {
    if (
      (s2.charCodeAt(i) >= 97 && s2.charCodeAt(i) <= 122) ||
      (s2.charCodeAt(i) >= 65 && s2.charCodeAt(i) <= 90)
    ) {
      num = i;
      break;
    }
  }
  return s2.slice(num).trim();
}

export const checkValidString = (s2) => {
  if (!s2) {
    return true;
  }
  const s1 = s2?.trim();
  if (/([A-Z][a-z])/i.test(s1)) return false;
  return true;
};

export const validateFloat = (n) => {
  const l = n.length - 1;
  const k = n.indexOf('.', n.indexOf('.') + 1);
  if (k > -1) {
    return parseFloat(n.substring(0, k));
  }
  if (n.charCodeAt(l) === 45) return n;
  if (n.charCodeAt(l) === 32) return n;
  if (isNaN(parseFloat(n))) {
    return '';
  }
  if (n.charCodeAt(l) > 47 && n.charCodeAt(l) < 58) {
    return parseFloat(n);
  }
  if (n.charCodeAt(l) === 46) return n;

  n.slice(0, -1);
  if (isNaN(parseFloat(n))) {
    return '';
  }
  return parseFloat(n);
};

export const validateInt = (n) => {
  const l = n.length - 1;
  if (isNaN(parseInt(n, 10))) {
    return '';
  }
  if (n.charCodeAt(l) > 47 && n.charCodeAt(l) < 58) {
    return parseInt(n, 10);
  }
  n.slice(0, -1);
  if (isNaN(parseInt(n, 10))) {
    return '';
  }
  return parseInt(n, 10);
};

// export const validateNum = (num) => {
//   if (num === ' ') {
//     return 0;
//   }
//   if (num === '') {
//     return num;
//   }
//   const s1 = String(num).replace('-', '');
//   s1.replace(/[^0-9]/g, '');
//   const result = parseInt(s1, 10);
//   return result;
// };

export const checkValidNum = (num) => {
  if (typeof num === 'number' && num > 0) {
    return false;
  }
  return true;
};

export const checkEmail = (email) => {
  const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!regex.test(email)) {
    return true;
  }
  return false;
};

export const isValidPhoto = (file) => {
  const rgex = /(jpg|png|jpeg)/i;
  if (!rgex.test(file.type)) {
    return { error: 'Extenstion is not valid' };
  }
  if (file.size / 1024 / 1024 > 20) {
    return { error: 'File size cant be larger than 20mb' };
  }
  return { value: true };
};

export const validPrice = (value) => {
  if (value === '') {
    return 0;
  }
  if (value < 0) {
    return Math.abs(value);
  }
  return value;
};

export const validSign = (value) => {
  let s1 = value;
  const l = value.length - 1;
  if (
    (s1.charCodeAt(l) >= 97 && s1.charCodeAt(l) <= 122) ||
    (s1.charCodeAt(l) >= 65 && s1.charCodeAt(l) <= 90)
  ) {
    return s1;
  }
  s1 = s1.slice(0, -1);
  return s1;
};

export const isValidExcel = (file) => {
  const rgex = /(openxmlformats-officedocument.spreadsheetml.sheet|xlsx|csv)/i;
  if (!rgex.test(file.type)) {
    return { error: 'File Extenstion is not valid' };
  }
  if (file.size / 1024 / 1024 > 20) {
    return { error: 'File size cant be larger than 20mb' };
  }
  return { value: true };
};

export const validMobile = (n) => {
  const l = n.length - 1;
  if (
    (n.charCodeAt(l) > 47 && n.charCodeAt(l) < 58) ||
    (l !== 0 && n.charCodeAt(l) === 32) ||
    n.charCodeAt(l) === 40 ||
    n.charCodeAt(l) === 41 ||
    n.charCodeAt(l) === 45 ||
    (l !== 0 && n.charCodeAt(l) === 189) ||
    (l === 0 && n.charCodeAt(l) === 43)
  ) {
    return n;
  }
  return n.slice(0, -1);
};

export const isValidQuill = (data) => {
  const rgex = /<(.|\n)*?>/gim;
  if (rgex.test(data)) return true;
  return false;
};

export const isValidMobile = (num) => {
  if (!num || num?.trim().length === 0) {
    return false;
  }
  // eslint-disable-next-line no-useless-escape
  const regex = /^([0-9\s\-\+\(\)]*)$/;
  // const regex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]\d{4}$/;
  if (regex.test(num)) return true;
  return false;
};

export const isValidPostal = (num) => {
  const regex = /^[a-zA-Z0-9][a-zA-Z0-9\s]+$/;
  if (regex.test(num)) return true;
  return false;
};

export const isPhoneNumberInvalid = (number) => {
  return (
    number &&
    (number.replace(noSpecialCharsRegEx, '').length < 9 ||
      number.replace(noSpecialCharsRegEx, '').length >= 14)
  );
};

export function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}
