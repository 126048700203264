import React, { memo, useCallback } from 'react';
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha
} from 'react-google-recaptcha-v3';
import { getRecaptchaVerify } from 'src/services/auth.services';
import propTypes from 'prop-types';

const JBReCaptcha = memo(
  ({ setValidToken }) => {
    const onVerify = useCallback(async (newtoken) => {
      if (newtoken) {
        try {
          const value = await getRecaptchaVerify(newtoken);
          if (value.data.success) {
            setValidToken();
          } else {
            console.log(value);
          }
        } catch (error) {
          console.log(error);
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA}>
        <GoogleReCaptcha onVerify={onVerify} />
      </GoogleReCaptchaProvider>
    );
  },
  () => true
);

JBReCaptcha.propTypes = {
  setValidToken: propTypes.func.isRequired
};

export default JBReCaptcha;
