/* eslint-disable indent */
import React, { Fragment, useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Grid,
  withStyles,
  Card,
  Breadcrumbs,
  Typography,
  Box,
  InputBase,
  FormControlLabel,
  Paper,
  Checkbox
} from '@material-ui/core';
import { debounce } from 'lodash';
import StarIcon from '@material-ui/icons/Star';
import { getmembershipsOnhold } from 'src/Redux/ReportsReducer/reports.reducer.actions';
import Pagination from '@material-ui/lab/Pagination';
import LoadingView from 'src/views/common/loading';
import filterType from 'src/Redux/Reducer.constants';
import ImportIcon from 'src/assets/image/upload.png';
import JBButton from 'src/views/common/JBButton/JBButton';
import { exportMembershipsHold } from 'src/services/reports.services';
import CancelDialogTwo from 'src/views/common/CommonDialogBox/CancelDialogTwo';
import useChangeTitle from 'src/views/common/CustomHooks/useTitleChange';
import MembershipOnHoldTable from './AllTables/MembershipOnHoldTable/MembershipOnHoldTable';
import styles from './MembersReports.style';

const MembershipsOnHold = (props) => {
  const {
    MembershipHoldSearch,
    FilterData,
    MembershipSort,
    classes,
    programSelected,
    defaultValue,
    customFilter
  } = props;
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const { isFetching, data, error } = useSelector(
    (state) => state.reportsReducer.membersReports?.membershipsOnHold
  );
  const { data: data_reports } = useSelector(
    (state) => state.reportsReducer.reportList
  );
  useChangeTitle('Members with Memberships on Hold | Jungle Alliance');
  const [search, setSearch] = useState(MembershipHoldSearch);
  const [activeuser, setactiveuser] = useState(() => defaultValue.current);

  const isStarred = data_reports?.members_report.filter(
    (report) => report.id === 5
  )[0].is_starred;

  const SearchQuery = useCallback(
    debounce((q) => FilterData(filterType.search, q), 1500),
    []
  );

  const userSelect = useCallback(
    debounce(
      // eslint-disable-next-line no-confusing-arrow
      (q) =>
        q
          ? FilterData(filterType.addcustom, [
              { current: 1 },
              {
                id: 1000000000001,
                name: 'Current on hold',
                filter: 'current'
              }
            ])
          : FilterData(filterType.addcustom, [
              { current: 0 },
              {
                id: 1000000000001,
                name: 'Current not on hold',
                filter: 'current'
              }
            ]),
      1000
    ),
    []
  );

  const setDirection = (name) => {
    if (MembershipSort.sort_name === name) {
      return MembershipSort.sort_by === 'asc' ? 'asc' : 'desc';
    }
    return 'asc';
  };

  const isActive = (sortlabel) => {
    if (MembershipSort.sort_name === sortlabel) return true;
    return false;
  };

  const setSort = (sortlabel) => {
    FilterData(filterType.sorting, sortlabel);
  };

  const handleReset = () => {
    FilterData(filterType.resetFilter);
    setactiveuser(() => defaultValue.current);
    setSearch('');
  };

  const Paginate = (val) => {
    if (val !== data.current_page) {
      return dispatch(getmembershipsOnhold(filterType.page, val));
    }
    return null;
  };
  useEffect(() => {
    return () => {
      handleReset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (!data_reports || !data) return <LoadingView />;

  return (
    <div className={classes.root}>
      <div className="title-bk">
        <Box css={{ marginBottom: '20px' }}>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={0}
          >
            <Grid item xs={9}>
              <Box display="inline-flex">
                <Breadcrumbs aria-label="breadcrumb">
                  <div color="inherit">Reports</div>
                  <div
                    color="inherit"
                    onClick={() => setOpen(true)}
                    style={{
                      cursor: 'pointer'
                    }}
                  >
                    members reports
                  </div>
                  <Typography variant="h3" color="Primary">
                    <span>Members with Memberships on Hold</span>
                  </Typography>
                </Breadcrumbs>
              </Box>
              <Box
                display="inline-flex"
                ml="10px"
                css={{ verticalAlign: 'text-bottom' }}
              >
                {isStarred === 1 && (
                  <StarIcon
                    style={{
                      color: '#FFC700'
                    }}
                  />
                )}
              </Box>
            </Grid>
            <Grid
              item
              xs={3}
              style={{
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              <JBButton
                type="outlined"
                isImg={ImportIcon}
                style={{ marginLeft: '10px' }}
                disabled={data.data.length === 0}
                onClick={(e) => {
                  e.preventDefault();
                  exportMembershipsHold({
                    search: MembershipHoldSearch,
                    sort_name: MembershipSort.sort_name,
                    sort_by: MembershipSort.sort_by,
                    current: customFilter
                      ? customFilter.current
                      : defaultValue.current
                  });
                }}
              >
                Export
              </JBButton>
            </Grid>
          </Grid>
        </Box>
      </div>

      <Card className={classes.mainCard}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={0}
        >
          <Grid item xs={6}>
            <Grid container direction="row" alignItems="center" spacing={0}>
              <Grid item xs={9}>
                <div className={classes.search}>
                  <InputBase
                    fullWidth
                    placeholder="Search…"
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                      SearchQuery(e.target.value);
                    }}
                    classes={{
                      root: classes.inputRoot,
                      input: classes.searchInput
                    }}
                    InputProps={{
                      'aria-label': 'search'
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs>
                <JBButton
                  type="outlined"
                  style={{ marginLeft: '10px' }}
                  onClick={() => handleReset()}
                >
                  reset
                </JBButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} justify="flex-end" className="MuiGrid-container">
            <Box
              mr="0px"
              css={{
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                marginRight: '0px'
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={activeuser}
                    name="checkedB"
                    color="primary"
                    onChange={(e) => {
                      setactiveuser(e.target.checked);
                      userSelect(
                        e.target.checked,
                        programSelected.filter((l) => l.filter === 'current')[0]
                      );
                    }}
                  />
                }
                label="Current Hold Only?"
              />
            </Box>
          </Grid>
        </Grid>
        {isFetching ? (
          <LoadingView />
        ) : error ? (
          <p>{error}</p>
        ) : (
          <Fragment>
            <Grid item xs={12}>
              <Box mt="25px" />
              <MembershipOnHoldTable
                values={data?.data}
                setDirection={setDirection}
                isActive={isActive}
                setSort={setSort}
              />
            </Grid>
            {data?.data.length !== 0 && (
              <Grid
                item
                xs={12}
                container
                direction="row"
                alignItems="center"
                style={{ marginTop: 24 }}
              >
                <Grid item xs={6} justify="flex-start">
                  {data?.from && data?.to > 1 ? (
                    <Paper elevation={0} style={{ color: '#A9A9A9' }}>
                      {`${data?.from} - ${data?.to} of ${data?.total}`}
                    </Paper>
                  ) : null}
                </Grid>
                <Grid
                  item
                  xs={6}
                  justify="flex-end"
                  style={{ display: 'flex' }}
                >
                  <Pagination
                    count={data?.last_page}
                    color="primary"
                    variant="outlined"
                    page={data?.current_page}
                    onChange={(_, val) => Paginate(val)}
                    shape="rounded"
                  />
                </Grid>
              </Grid>
            )}
          </Fragment>
        )}
      </Card>
      <CancelDialogTwo setOpen={open} handleClose={() => setOpen(false)} />
    </div>
  );
};

MembershipsOnHold.propTypes = {
  classes: PropTypes.object.isRequired,
  FilterData: PropTypes.func.isRequired,
  MembershipHoldSearch: PropTypes.string.isRequired,
  MembershipSort: PropTypes.object.isRequired,
  programSelected: PropTypes.array.isRequired,
  defaultValue: PropTypes.object.isRequired,
  customFilter: PropTypes.object.isRequired
};

const mapToState = (state) => ({
  MembershipHoldSearch:
    state.reportsReducer.membersReports?.membershipsOnholdFil.search,
  MembershipSort:
    state.reportsReducer.membersReports?.membershipsOnholdFil.sorting,
  programSelected:
    state.reportsReducer.membersReports?.membershipsOnholdFil.tags,
  defaultValue:
    state.reportsReducer.membersReports.membershipsOnholdFil.default,
  customFilter:
    state.reportsReducer.membersReports.membershipsOnholdFil.customFilter
});

const mapToDispatch = (dispatch) => ({
  FilterData: (filter, data) => dispatch(getmembershipsOnhold(filter, data))
});

export default connect(
  mapToState,
  mapToDispatch
)(withStyles(styles)(MembershipsOnHold));
