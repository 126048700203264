import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import PropType from 'prop-types';
import styles from './Button.style';
import getClass from './Button.utils';

function ButtonPrimary({ classes, children, isImg, type, ...props }) {
  // const { classes, children, isImg, type } = props;
  const buttonClass = getClass(type, classes);
  return (
    <Button
      variant={type === 'primary' ? 'contained' : 'outlined'}
      color="primary"
      className={buttonClass}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {isImg && <img src={isImg} alt={isImg} className={classes.imgMargin} />}
      <span>{children}</span>
    </Button>
  );
}

ButtonPrimary.defaultProps = {
  isImg: null
};

ButtonPrimary.propTypes = {
  classes: PropType.object.isRequired,
  children: PropType.string.isRequired,
  type: PropType.string.isRequired,
  isImg: PropType.string
};

export default withStyles(styles)(ButtonPrimary);
