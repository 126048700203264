import React from 'react';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import moment from 'moment';
import PropType from 'prop-types';
import { Grid, Box, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import dcloseicon from '../../../assets/image/closeicon.png';
import styles from './Dialog.style';

function DeletedDialog({ classes, values, allClass, onClose }) {
  // Delete Dialog Box
  return (
    <div>
      <div style={{ width: '285px' }} className={classes.dmainbk}>
        <Grid alignItems="center" container direction="row">
          <Grid item xs>
            {/* <Button
              className={classes.btns}
              style={{
                display: 'inline-block',
                marginLeft: '11px',
                marginRight: '0',
                lineHeight: 'normal'
              }}
            >
              <img alt="img" src={arrowleft} />
            </Button> */}
            <DialogTitle
              id="alert-dialog-title"
              color="primary"
              className={classes.titlebk}
              style={{
                display: 'inline-block'
              }}
            >
              <h4 className={classes.dtitle}>Delete Class</h4>
            </DialogTitle>
          </Grid>

          <Grid item style={{ textAlign: 'right', marginRight: '10px' }}>
            <Button className={classes.btns} onClick={() => onClose()}>
              <img alt="img" src={dcloseicon} />
            </Button>
          </Grid>
        </Grid>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography
              variant="h5"
              gutterBottom
              style={{ color: '#000', textAlign: 'center' }}
            >
              Classes Deleted
            </Typography>
            <div className={classes.warningInfo}>
              <Box display="block">
                {allClass ? (
                  <p>
                    All classes on {moment(values.start_time).format('LT')}{' '}
                    {values.class_name} are deleted.
                  </p>
                ) : (
                  <p>
                    Class on {moment(values.start_time).format('LT')}{' '}
                    {values.class_name} is deleted
                  </p>
                )}
              </Box>
            </div>

            <div className={classes.d_button}>
              <Box display="block" mt={3} mb={1}>
                <Button
                  variant="outlined"
                  color="primary"
                  fullWidth
                  onClick={() => onClose()}
                >
                  Close
                </Button>
              </Box>
            </div>
          </DialogContentText>
        </DialogContent>
      </div>
    </div>
  );
}

DeletedDialog.defaultProps = {
  allClass: false
};

DeletedDialog.propTypes = {
  classes: PropType.object.isRequired,
  values: PropType.object.isRequired,
  onClose: PropType.func.isRequired,
  allClass: PropType.bool
};

export default withStyles(styles)(DeletedDialog);
