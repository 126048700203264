import React, { useEffect } from 'react';
import { ROUTES } from 'src/constant/Constants';
import PropTypes from 'prop-types';
import { Link, Route, Switch, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  getActiveAllies,
  getAllAllies,
  getInActiveAllies
} from 'src/Redux/MasterHQ/AlliesReducer/allies.reducer.actions';
import {
  Grid,
  withStyles,
  AppBar,
  Tabs,
  Tab,
  Card,
  Breadcrumbs,
  Typography
} from '@material-ui/core';
import { getActiveVal } from './affiliatesTabs.utils';
import styles from './affiliates.style';
import Plusicon from '../../../assets/image/plusicon.png';
import ActiveTab from './ActiveTab';
import InactiveTab from './InactiveTab';
import AllTab from './AllTab';
import JBButton from '../../common/JBButton/JBButton';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

const AffiliatesTabs = (props) => {
  const { classes } = props;

  const history = useHistory();
  const dispatch = useDispatch();
  const Tabslocation = getActiveVal(history.location.pathname);

  useEffect(() => {
    dispatch(getActiveAllies());
    dispatch(getInActiveAllies());
    dispatch(getAllAllies());
  }, [dispatch]);

  return (
    <div className={classes.root}>
      <div className="title-bk">
        <div style={{ marginBottom: '20px' }}>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={0}
          >
            <Grid item xs={6}>
              <Breadcrumbs aria-label="breadcrumb">
                <Link color="inherit" to={ROUTES.MASTER_HQ_AFFILIATES}>
                  Allies
                </Link>
                <Typography variant="h3" color="Primary">
                  {(() => {
                    switch (Tabslocation) {
                      case 0:
                        return <span>Active</span>;
                      case 1:
                        return <span>Inactive</span>;
                      case 2:
                        return <span>All</span>;
                      default:
                        return null;
                    }
                  })()}
                </Typography>
              </Breadcrumbs>
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              <JBButton
                type="primary"
                isImg={Plusicon}
                component={Link}
                to={ROUTES.MASTER_HQ_AFFILIATES_CREATE}
              >
                new Ally
              </JBButton>
            </Grid>
          </Grid>
        </div>
      </div>

      <Card className={classes.mainCard}>
        <AppBar
          position="static"
          style={{
            backgroundColor: 'transparent',
            boxShadow: 'none'
          }}
        >
          <Tabs
            value={Tabslocation}
            indicatorColor="primary"
            textColor="primary"
            fullWidth
          >
            <Tab
              label="Active"
              component={Link}
              to={ROUTES.MASTER_HQ_AFFILIATES}
              className={classes.p_tabs}
              {...a11yProps(0)} // eslint-disable-line
            />
            <Tab
              label="Inactive"
              component={Link}
              to={ROUTES.MASTER_HQ_AFFILIATES_INACTIVE}
              className={classes.p_tabs}
              {...a11yProps(1)} // eslint-disable-line
            />
            <Tab
              label="All"
              component={Link}
              to={ROUTES.MASTER_HQ_AFFILIATES_ALL}
              className={classes.p_tabs}
              {...a11yProps(2)} // eslint-disable-line
            />
          </Tabs>
        </AppBar>

        <Switch>
          <Route path={ROUTES.MASTER_HQ_AFFILIATES_ALL} component={AllTab} />
          <Route
            path={ROUTES.MASTER_HQ_AFFILIATES_INACTIVE}
            component={InactiveTab}
          />
          <Route path={ROUTES.MASTER_HQ_AFFILIATES} component={ActiveTab} />
        </Switch>
      </Card>
    </div>
  );
};

AffiliatesTabs.propTypes = {
  classes: PropTypes.object.isRequired
  // eslint-disable-next-line react/no-unused-prop-types
};

export default withStyles(styles, { withTheme: true })(AffiliatesTabs);
