import React, { memo } from 'react';
import PropType from 'prop-types';
import CKEditor from 'ckeditor4-react';

const JBCKEditor = memo(({ onChange, data }) => (
  <CKEditor
    data={data || '<p></p>'}
    config={{
      toolbar: [
        [
          'Bold',
          'Italic',
          'Underline',
          'Font',
          // 'Format',
          'Link',
          'FontSize',
          'Blockquote',
          // 'CodeSnippet',
          'JustifyRight',
          'JustifyCenter',
          'JustifyLeft',
          'TextColor',
          'BGColor'
        ]
      ],
      fontSize_sizes:
        '11/11px;12/12px;14/14px;16/16px;18/18px;20/20px;22/22px;24/24px;26/26px;28/28px;36/36px;48/48px;72/72px',
      removeButtons: 'Subscript,Superscript',
      font_names:
        'Arial; Courier New; Georgia; Lucida Sans Unicode; Tahoma; Times New Roman; Trebuchet MS; Verdana; Lato;',
      extraPlugins: 'font,codesnippet,justify,colorbutton,colordialog,dialog',
      enterMode: 2,
      forceEnterMode: 2
    }}
    onBeforeLoad={(CKEDITOR) => {
      CKEDITOR.plugins.addExternal(
        'dialog',
        `${process.env.PUBLIC_URL}/otherplugins/dialog/`,
        'plugin.js'
      );
      CKEDITOR.plugins.addExternal(
        'dialog',
        `${process.env.PUBLIC_URL}/otherplugins/colordialog/`,
        'plugin.js'
      );
    }}
    onChange={onChange}
  />
));

JBCKEditor.propTypes = {
  onChange: PropType.func.isRequired,
  data: PropType.string.isRequired
};

export default JBCKEditor;
