import React, { useEffect } from 'react';
import {
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  withStyles,
  Grid,
  InputLabel
} from '@material-ui/core';
import GetPrice from 'src/views/common/CurrencySymbol/GetPrice';
import PropType from 'prop-types';
import FormControl from 'src/views/common/FormControl/formControl';
import styles from '../../../../table.style';
import {
  changeinimemfee,
  changeinisetcost,
  getIniDiscount,
  getIniSubTotal,
  getIniTotalDue,
  gettaxtotal,
  updateIniTaxes
} from '../FinalPrice.utils';
import {
  chngememfee,
  chngesetupfee,
  changeinifulltax
} from '../../NewMembership.actions';

function SessionFinalPricingTable({ classes, reducer, isEditable }) {
  const [state, dispatch] = reducer;

  const initialprice = state.data.payment_data.initial_commitment_pricing;

  useEffect(() => {
    dispatch(changeinifulltax(updateIniTaxes(state.data, 'session')));
    // eslint-disable-next-line
  }, [initialprice.full_membership_fees, initialprice.full_setup_fees]);

  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      spacing={0}
      style={{ margin: '10px 0 20px' }}
    >
      <TableContainer
        style={{ border: '1px solid #A8A8A8', borderRadius: '5px' }}
      >
        <Table className={classes.table} aria-label="simple table">
          <TableHead className={classes.table_th}>
            <TableRow>
              <TableCell className={classes.TableCell} style={{ width: '50%' }}>
                Name
              </TableCell>
              <TableCell className={classes.TableCell} style={{ width: '20%' }}>
                Payment plan
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell className={classes.Table_td}>
                Payment option type
              </TableCell>
              <TableCell className={classes.Table_td}>Pay in Full</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.Table_td}>
                <InputLabel
                  htmlFor="age-required"
                  className={classes.cutom_Table_Label}
                >
                  Setup fee
                </InputLabel>
              </TableCell>

              <TableCell
                className={classes.Table_td}
                style={{
                  padding: '3px 15px 7px 15px'
                }}
              >
                {isEditable ? (
                  <FormControl
                    control="input"
                    label=""
                    value={
                      state.data.payment_data.initial_commitment_pricing
                        .full_setup_fees
                    }
                    maxLength={10}
                    required
                    onChange={(e) => {
                      const actionkey = changeinisetcost(state.data);
                      dispatch(chngesetupfee(actionkey, e.target.value));
                    }}
                    style={{ margin: '0 0' }}
                  />
                ) : (
                  <GetPrice
                    value={
                      state.data.payment_data.initial_commitment_pricing
                        .full_setup_fees
                    }
                  />
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.Table_td}>
                <InputLabel
                  htmlFor="age-required"
                  required
                  className={classes.cutom_Table_Label}
                >
                  Fee
                </InputLabel>
              </TableCell>
              <TableCell
                className={classes.Table_td}
                style={{
                  padding: '3px 15px 7px 15px'
                }}
              >
                {isEditable ? (
                  <FormControl
                    control="input"
                    label=""
                    value={
                      state.data.payment_data.initial_commitment_pricing
                        .full_membership_fees
                    }
                    required
                    maxLength={10}
                    onChange={(e) => {
                      const actionkey = changeinimemfee(state.data);
                      dispatch(chngememfee(actionkey, e.target.value));
                    }}
                    style={{ margin: '0 0' }}
                  />
                ) : (
                  <GetPrice
                    value={
                      state.data.payment_data.initial_commitment_pricing
                        .full_membership_fees
                    }
                  />
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.Table_td}>Subtotal</TableCell>
              <TableCell className={classes.Table_td}>
                <GetPrice value={getIniSubTotal(state.data, 'session')} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.Table_td}>Total discount</TableCell>
              <TableCell className={classes.Table_td}>
                <GetPrice value={getIniDiscount(state.data)} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.Table_td}>Total Due</TableCell>
              <TableCell className={classes.Table_td}>
                <GetPrice value={getIniTotalDue(state.data, 'session')} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.Table_td}>GST</TableCell>
              <TableCell className={classes.Table_td}>
                <GetPrice value={gettaxtotal(state.data)} />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}

SessionFinalPricingTable.defaultProps = {
  isEditable: true
};

SessionFinalPricingTable.propTypes = {
  classes: PropType.object.isRequired,
  reducer: PropType.array.isRequired,
  isEditable: PropType.bool
};

export default withStyles(styles)(SessionFinalPricingTable);
