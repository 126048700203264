/* eslint-disable consistent-return */
/* eslint-disable default-case */
import React, { useState, useEffect, useContext } from 'react';
import PropType from 'prop-types';
import JBButton from 'src/views/common/JBButton/JBButton';
import JBDatePicker from 'src/views/common/FormControl/JBDatePicker';
import { InputLabel, withStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { FilterContext } from 'src/views/common/ApplyFilter/applyFilter';
import { getMinDate } from 'src/utils/someCommon';
import CustomAutoComplete from 'src/views/common/CustomAutocomplete/commonAutocomplete';
import styles from './filters.style';
import {
  dateRange,
  getFilter,
  getfilterLabel,
  tobeFilter,
  checkData
} from './Filter.utils';

const LeadInsightFilter = ({ classes, getFilData }) => {
  const [data, setData] = useState(null);
  const [filter, setFilter] = useState(null);
  const [filterDate, setFilterDate] = useState(null);
  const Locations = useSelector((state) => state.SettingReducer.allGymloc.data);
  const [endDate, setendDate] = useState(() => getMinDate(new Date()));
  const [open, setOpen] = useContext(FilterContext);
  useEffect(() => {
    setData(null);
  }, [filter]);
  return (
    <div>
      <CustomAutoComplete
        value={tobeFilter}
        holder="Select Filter"
        style={{ marginBottom: '15px' }}
        Options="filter"
        data={filter}
        Change={(val) => setFilter(val)}
      />
      {(() => {
        switch (filter?.id) {
          case 1:
            return (
              <CustomAutoComplete
                value={Locations}
                holder="All locations"
                style={{ marginBottom: '15px' }}
                Options="gym_name"
                data={data}
                Change={(val) => setData(val)}
              />
            );
          case 2:
            return (
              <div>
                <InputLabel
                  htmlFor="age-required"
                  className={classes.cutom_select_Label}
                  style={{ margin: '0px 0 5px' }}
                >
                  Date Range
                </InputLabel>
                <CustomAutoComplete
                  holder="This Month"
                  Options="filter"
                  value={dateRange}
                  data={filterDate}
                  Change={(val) => setFilterDate(val)}
                  style={{ marginBottom: '15px' }}
                />
                {filterDate?.id === 'custom' && (
                  <>
                    <InputLabel
                      htmlFor="age-required"
                      className={classes.cutom_select_Label}
                      style={{ margin: '5px 0' }}
                    >
                      From
                    </InputLabel>
                    <JBDatePicker
                      label="Start Date"
                      value={data}
                      maxDate={new Date()}
                      onChange={(val) => {
                        setData(val);
                        setendDate(getMinDate(val));
                      }}
                    />
                    <InputLabel
                      htmlFor="age-required"
                      className={classes.cutom_select_Label}
                      style={{ padding: '20px 0px 5px' }}
                    >
                      To
                    </InputLabel>
                    <JBDatePicker
                      label="End Date"
                      minDate={data ? getMinDate(data) : getMinDate(new Date())}
                      maxDate={getMinDate(new Date())}
                      value={endDate}
                      onChange={(val) => setendDate(val)}
                    />
                  </>
                )}
              </div>
            );
        }
      })()}
      <JBButton
        type="primary"
        style={{
          width: '100%',
          // minHeight: '22px',
          padding: '7px 25px',
          marginTop: '10px'
        }}
        disabled={checkData(filter, data, filterDate, endDate)}
        onClick={() => {
          getFilData(
            getFilter(filter, filterDate, data, endDate),
            getfilterLabel(filter, filterDate, data, endDate)
          );
          setOpen(!open);
        }}
      >
        Apply filter
      </JBButton>
    </div>
  );
};
LeadInsightFilter.propTypes = {
  classes: PropType.object.isRequired,
  getFilData: PropType.func.isRequired
};

export default withStyles(styles)(LeadInsightFilter);
