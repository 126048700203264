/* eslint-disable operator-linebreak */
import { getValuefromObject, backendDate } from 'src/utils/someCommon';
import moment from 'moment';

export const getFilter = (id, data, endDate) => {
  switch (id) {
    case 1:
      return {
        auto_bill: getValuefromObject(data)
      };
    case 2:
      return {
        user_id: getValuefromObject(data)
      };
    case 3:
      return {
        filter_by_status: getValuefromObject(data)
      };
    case 4:
      return {
        date_range: backendDate(data) + '-' + backendDate(endDate)
      };

    default:
      return null;
  }
};

export const getfilterLabel = (id, data, endDate, selected, dateformat) => {
  switch (id) {
    case 1:
      return {
        id,
        name: `Auto bill ${Object.values(selected)[1]} ${
          Object.values(data)[1]
        }`,
        filter: 'auto_bill'
        // connetor: 'status_operator'
      };
    case 2:
      return {
        id,
        name: `Members ${Object.values(selected)[1]} ${Object.values(data)[1]}`,
        filter: 'user_id'
        // connetor: 'contract_operator'
      };
    case 3:
      return {
        id,
        name: `Status ${Object.values(selected)[1]} ${Object.values(data)[1]}`,
        filter: 'filter_by_status'
        // connetor: 'membership_operator'
      };
    case 4:
      return {
        id,
        name: `Date is ${moment(data).format(dateformat) +
          '-' +
          moment(endDate).format(dateformat)}`,
        filter: 'date_range'
      };
    default:
      return null;
  }
};

export const checkData = (filter, data, endDate) => {
  if (!filter || !data || !endDate || String(data).includes('Invalid')) {
    return true;
  }
  return false;
};
