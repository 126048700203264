/* eslint-disable operator-linebreak */
import {
  changeDatereverse,
  checkQuillValid,
  getIdfromArr,
  getValuefromObject
} from 'src/utils/someCommon';
import { checkValidString, validateFinalString } from 'src/utils/validations';

export const getUniTags = (tags) => {
  const uniqueTags = [...new Set(tags)];
  const result = [];
  // eslint-disable-next-line
  for (let i = 0; i < uniqueTags.length; i++) {
    const b = {
      id: uniqueTags[i],
      text: uniqueTags[i]
    };
    result.push(b);
  }
  return result;
};

export const getUnisuggest = (unitags, pritag, sectag) => {
  const uniqueTags = [...new Set(unitags)];
  const result = [];
  const pritags = getIdfromArr(pritag).split(',');
  const sectags = getIdfromArr(sectag).split(',');
  const uniTag = uniqueTags.filter(
    (tag) => !pritags.includes(tag) && !sectags.includes(tag)
  );
  // eslint-disable-next-line
  for (let i = 0; i < uniTag.length; i++) {
    const b = {
      id: uniTag[i],
      text: uniTag[i]
    };
    result.push(b);
  }
  return result;
};

export const setSecTags = (prevTags, priTags, value) => {
  const result = prevTags;
  let flag = 0;
  // eslint-disable-next-line
  for (let i = 0; i < prevTags.length; i += 1) {
    if (
      prevTags[i].id === value.id ||
      prevTags[i].id === validateFinalString(value.id)
    ) {
      flag = 1;
      return {
        error:
          'You have entered a tag that already exists in this field. You can not add duplicate tags'
      };
    }
  }
  for (let i = 0; i < priTags.length; i += 1) {
    if (
      priTags[i].id === value.id ||
      priTags[i].id === validateFinalString(value.id)
    ) {
      flag = 1;
      return {
        error:
          'You have entered a tag that already exists in Primary/Secondary Tag field. You can not add duplicate tags'
      };
    }
  }
  if (prevTags.length > 24) {
    return { error: 'You can not add more than 25 Secondary Tags' };
  }
  if (flag === 1 || validateFinalString(value.id).length === 0) {
    return { data: result };
  }
  const updatedVal = {
    id: validateFinalString(value.id),
    text: validateFinalString(value.text)
  };
  result.push(updatedVal);
  return { data: result };
};

export const setPriTags = (prevTags, SecTags, value) => {
  const result = prevTags;
  let flag = 0;
  // eslint-disable-next-line
  for (let i = 0; i < prevTags.length; i += 1) {
    if (
      prevTags[i].id === value.id ||
      prevTags[i].id === validateFinalString(value.id)
    ) {
      flag = 1;
      return {
        error:
          'You have entered a tag that already exists in this field. You can not add duplicate tags'
      };
    }
  }
  for (let i = 0; i < SecTags.length; i += 1) {
    if (
      SecTags[i].id === value.id ||
      SecTags[i].id === validateFinalString(value.id)
    ) {
      flag = 1;
      return {
        error:
          'You have entered a tag that already exists in Secondary Tag field. You can not add duplicate tags'
      };
    }
  }
  if (prevTags.length > 9) {
    return { error: 'You can not add more than 10 Primary Tags' };
  }
  if (flag === 1 || validateFinalString(value.id).length === 0) {
    return { data: result };
  }
  const updatedVal = {
    id: validateFinalString(value.id),
    text: validateFinalString(value.text)
  };
  result.push(updatedVal);
  return { data: result };
};

export const getTagfromStr = (tags) => {
  if (!tags) {
    return [];
  }
  const result = [];
  const allTags = tags.split(',');
  // eslint-disable-next-line
  for (let i = 0; i < allTags.length; i++) {
    const b = {
      id: allTags[i],
      text: allTags[i]
    };
    result.push(b);
  }
  return result;
};

export const getCreData = (baseinfo, date, pid, Notes) => {
  let val = {
    ...baseinfo,
    program_id: pid,
    wod_date: changeDatereverse(date),
    workout_details: Notes.workout_details
  };
  if (Notes.tags.length > 0) {
    val = {
      ...val,
      tags: getIdfromArr(Notes.tags)
    };
  }
  if (Notes.secondary_tags.length > 0) {
    val = {
      ...val,
      secondary_tags: getIdfromArr(Notes.secondary_tags)
    };
  }
  if (checkQuillValid(Notes.coach_notes) && Notes.coach_notes.length > 0) {
    val = {
      ...val,
      coach_notes: Notes.coach_notes
    };
  }
  return val;
};

export const checkEditWOD = (data) => {
  if (
    checkValidString(data?.name) ||
    !data.date ||
    String(Object.keys(data?.date)).includes('invalid') ||
    !data.programe ||
    data.class_id.length === 0 ||
    !checkQuillValid(data?.workout_details)
  ) {
    return false;
  }
  return true;
};

export const getEditData = (data) => {
  let val = {
    name: data.name,
    wod_date: changeDatereverse(data.date),
    classes_id: data.class_id.join(','),
    program_id: data.programe,
    workout_details: data.workout_details,
    tags: null,
    secondary_tags: null,
    coach_notes: null
  };
  if (data.tags.length > 0) {
    val = {
      ...val,
      tags: getIdfromArr(data.tags)
    };
  }
  if (data.secondary_tags.length > 0) {
    val = {
      ...val,
      secondary_tags: getIdfromArr(data.secondary_tags)
    };
  }
  if (data.coach_notes && checkQuillValid(data.coach_notes)) {
    val = {
      ...val,
      coach_notes: data.coach_notes
    };
  }
  return val;
};

export const checkCopyWOD = (data, pid, date) => {
  if (
    checkValidString(data?.name) ||
    !date ||
    String(Object.keys(date)).includes('invalid') ||
    !pid ||
    data?.class_list?.length === 0 ||
    !checkQuillValid(data?.workout_details)
  ) {
    return false;
  }
  return true;
};

export const getCopyData = (data, pid, date) => {
  let val = {
    name: data.name,
    program_id: getValuefromObject(pid),
    classes_id: data?.class_list?.join(','),
    wod_date: changeDatereverse(date),
    workout_details: data?.workout_details
  };
  if (data?.tags?.length > 0) {
    val = {
      ...val,
      tags: getIdfromArr(data.tags)
    };
  }
  if (data?.secondary_tags?.length > 0) {
    val = {
      ...val,
      secondary_tags: getIdfromArr(data.secondary_tags)
    };
  }
  if (data?.coach_notes && checkQuillValid(data?.coach_notes)) {
    val = {
      ...val,
      coach_notes: data.coach_notes
    };
  }
  return val;
};
export const setBulkTags = (tags, val, add) => {
  const result = [...tags];
  let flag = 0;
  for (let i = 0; i < tags.length; i += 1) {
    if (
      tags[i].id === validateFinalString(val.id) ||
      validateFinalString(val.id) === ''
    ) {
      flag = 1;
    }
  }
  if (flag === 1 || (add && tags.length >= 10)) {
    return result;
  }
  const updatedVal = {
    id: validateFinalString(val.id),
    text: validateFinalString(val.text)
  };
  result.push(updatedVal);
  return result;
};

export const checkValidTags = (tags, suggest) => {
  let alltags = 0;
  for (let i = 0; i < tags.length; i += 1) {
    for (let j = 0; j < suggest.length; j += 1) {
      if (tags[i].id === suggest[j].id) {
        alltags += 1;
      }
    }
  }
  if (alltags !== tags.length) {
    return true;
  }
  return false;
};

export const getValidStep1 = (baseinfo, date, programs) => {
  if (!baseinfo.name || !date || !programs || !baseinfo.classes_id) {
    return true;
  }
  return false;
};

export const getValidStep2 = (Notes) => {
  if (
    !Notes.workout_details
    // Notes.workout_details?.replace(/<(.|\n)*?>/g, '').trim().length === 0
  ) {
    return true;
  }
  return false;
};
