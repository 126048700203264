export const dialogRepeat = [
  { id: 0, name: 'Non Repetition' },
  { id: 'custom', name: 'Custom' },
  { id: 'weekly', name: 'Weekly' }
];

export const Weekdata = [
  { id: 'mo', label: 'Mon', name: 'Monday' },
  { id: 'tu', label: 'Tue', name: 'Tuesday' },
  { id: 'we', label: 'Wed', name: 'Wednesday' },
  { id: 'th', label: 'Thu', name: 'Thursday' },
  { id: 'fr', label: 'Fri', name: 'Friday' },
  { id: 'sa', label: 'Sat', name: 'Saturday' },
  { id: 'su', label: 'Sun', name: 'Sunday' }
];
