import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'redux';
import AbsolutePosition from './AbsolutePosition';

const styles = () => ({
  appCalPopup: {
    zIndex: 1300
    // '&::before': {
    //   content: '""',
    //   position: 'absolute',
    //   left: '0',
    //   right: '0',
    //   top: 'calc(100% - 100px)',
    //   bottom: 'calc(100% - 100px)',
    //   // inset: 'inherit',
    //   margin: '0 auto',
    //   width: '0',
    //   height: '0',
    //   borderTop: '10px solid transparent',
    //   borderBottom: '10px solid transparent',
    //   borderLeft: '10px solid #E91E63',
    //   transform: 'rotate(-90deg)'
    // }
  }
});

const getCalPopupPosition = (windowSize, location) => {
  // http://jsfiddle.net/AkshayBandivadekar/zakn7Lwb/14/
  const verticalCenter = windowSize.height / 2 + 152.5;
  // eslint-disable-next-line
  const horizontalCenter =
    windowSize.width > 1280 ? windowSize.width / 2 + 309 : windowSize.width / 2;

  let top;
  let right;
  let bottom;
  let left;
  let isClickedBottom = false;
  const theGap = windowSize.width - location.x - 152;
  /* eslint-disable */
  const isBottomClicked =
    location.y > verticalCenter && //
    (location.x <= horizontalCenter || location.x > horizontalCenter);
  /* eslint-enable */

  // height= 458, width = 280
  if (theGap < 0 && !isBottomClicked) {
    // When user click far right
    top = location.y - 152.5 + location.scroll;
    right = 'auto';
    bottom = 'auto';
    left = // eslint-disable-line
      windowSize.width > 1280
        ? location.x - 450 + theGap
        : location.x - 40 - 140 + theGap;
    isClickedBottom = false;
  } else if (isBottomClicked && theGap < 0) {
    // When user click bottom far right
    top = location.y - 152.5 + location.scroll - 480;
    left = // eslint-disable-line
      windowSize.width > 1280
        ? location.x - 450 + theGap
        : location.x - 40 - 140 + theGap;
    right = 'auto';
    bottom = 'auto';
    isClickedBottom = true;
  } else if (
    // eslint-disable-next-line
    isBottomClicked
  ) {
    // When user click on bottom-left or bottom-right part of window
    top = location.y - 152.5 + location.scroll - 480;
    left = windowSize.width > 1280 ? location.x - 450 : location.x - 40 - 140;
    right = 'auto';
    bottom = 'auto';
    isClickedBottom = true;
  } else {
    top = location.y - 152.5 + location.scroll;
    right = 'auto';
    bottom = 'auto';
    left = windowSize.width > 1280 ? location.x - 450 : location.x - 40 - 140;
    isClickedBottom = false;
  }
  return {
    top,
    right,
    bottom,
    left,
    isClickedBottom,
    theGap
  };
};

const CustomPopup = (props) => {
  const { location, classes, children, windowSize, nodeRef } = props;
  const positionArray = useMemo(
    () => getCalPopupPosition(windowSize, location),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [windowSize, location]
  );

  return (
    <AbsolutePosition
      top={positionArray.top}
      right={positionArray.right}
      bottom={positionArray.bottom}
      left={positionArray.left}
      className={classes.appCalPopup}
      nodeRef={nodeRef}
    >
      <div
        style={{
          content: '""',
          position: 'absolute',
          left:
            positionArray.theGap < 0
              ? 200 - (windowSize.width - location.x)
              : '0',
          right: '0',
          top: `${positionArray.isClickedBottom ? '98.7%' : ''}`,
          bottom: `${!positionArray.isClickedBottom ? '98.7%' : ''}`,
          margin: '0 auto',
          width: '0',
          height: '0',
          borderTop: '10px solid transparent',
          borderBottom: '10px solid transparent',
          borderLeft: '10px solid #fff',
          transform: `${
            positionArray.isClickedBottom ? 'rotate(90deg)' : 'rotate(-90deg)'
          }`
        }}
      />
      {children}
    </AbsolutePosition>
  );
};

CustomPopup.defaultProps = {};

CustomPopup.propTypes = {
  location: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  children: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
  classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  windowSize: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  nodeRef: PropTypes.func.isRequired
  // csstop: PropTypes.any.isRequired
};

export default compose(withStyles(styles))(CustomPopup);
