import { useEffect } from 'react';

const useChangeTitle = (s1) => {
  useEffect(() => {
    document.title = s1;
    return () => {
      document.title = 'Jungle Alliance';
    };
  }, []); // eslint-disable-line
};
export default useChangeTitle;
