import React, { useState, useEffect } from 'react';
import PropType from 'prop-types';
import { ROUTES } from 'src/constant/Constants';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getClassesForVanguard } from 'src/services/vanguard.services';
import { getErrorMsg } from 'src/utils/ErrorHandling';
import moment from 'moment';
import clsx from 'clsx';
import {
  Button,
  AppBar,
  Toolbar,
  IconButton,
  withStyles,
  Card,
  Grid,
  Box,
  InputLabel,
  Typography
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import JBDatePicker from 'src/views/common/FormControl/JBDatePicker';
import useGymSettingReducer from 'src/views/common/CustomHooks/useGymSettingRefresh';
import useChangeTitle from 'src/views/common/CustomHooks/useTitleChange';
import CustomAutocomplete from '../../common/CustomAutocomplete/commonAutocomplete';
import VanguardListLoadingView from '../../common/loading/VanguardListLoadingView';
import { ReactComponent as BackArrow } from '../../../assets/image/Vanguard/icon_back_vanguard.svg';
import { ReactComponent as ArrowL } from '../../../assets/image/Vanguard/arrowL.svg';
import { ReactComponent as ArrowR } from '../../../assets/image/Vanguard/arrowR.svg';
import { ReactComponent as ArrowRight } from '../../../assets/image/Vanguard/arrowRight.svg';
import JBVanguardButton from '../../common/JBVanguardButton/JBButton';
import { ReactComponent as DownIcon } from '../../../assets/image/Vanguard/downIcon.svg';
import LeavePagePopup from '../LeavepagePopup';
import styles from './chalkboardIndex.style';

const rowsPerPage = 15;

const ChalkboardIndex = ({ classes }) => {
  const programs = useSelector((state) => state.allprograms.programs);
  const [data, setData] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [LeavePageopen, setLeavePageopen] = useState(false);
  const [programSelected, setProgramSelected] = useState(null);
  const [selectedDate, setSelectedDate] = useState(Date.now());
  useChangeTitle('Chalkboard | Jungle Alliance');
  const today = Date.now();
  const history = useHistory();
  const getAllClassData = async (date, pid) => {
    try {
      setIsFetching(true);
      const response = await getClassesForVanguard({
        date,
        pid
      });
      setData(response);
      setIsFetching(false);
      setError(null);
    } catch (err) {
      setIsFetching(false);
      setData(null);
      setError(getErrorMsg(err));
    }
  };

  useEffect(() => {
    const formatedDate = `${moment(selectedDate).format('YYYY-MM-DD')}`;

    getAllClassData(formatedDate, programSelected?.id);
  }, [selectedDate, programSelected]);

  const { dateformat } = useGymSettingReducer();

  const setNextOrPrevDate = (time) => {
    if (time === 'next') {
      setSelectedDate((prevDate) => moment(prevDate).add(1, 'days'));
    } else {
      setSelectedDate((prevDate) => moment(prevDate).add(-1, 'days'));
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const slicedData = data?.slice(
    (page - 1) * rowsPerPage,
    (page - 1) * rowsPerPage + rowsPerPage
  );

  return (
    <div>
      <AppBar className={classes.appBar}>
        <Toolbar className={classes.appToolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="close"
            onClick={() => {
              // setLeavePageopen(true);
              history.goBack();
            }}
          >
            <BackArrow />
          </IconButton>
          <h5 className={classes.headerTitle}>Chalkboard</h5>
          <Button
            color="inherit"
            className={classes.HomeButton}
            component={Link}
            to={ROUTES.VANGUARD}
          >
            Home
          </Button>
        </Toolbar>
      </AppBar>

      <Card className={classes.MainCard}>
        <Box className={classes.chalkHeader}>
          <Grid
            container
            xs={10}
            justify="center"
            style={{
              margin: '0px auto'
            }}
          >
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={2}
              // style={{ margin: '20px 0 20px' }}
            >
              <Grid
                item
                xs={6}
                className={classes.cutom_vanguard_FormControl}
                style={{
                  margin: '10px 0'
                }}
              >
                <InputLabel
                  htmlFor="age-required"
                  className={classes.cutom_select_Label}
                  // style={{ margin: '0px 0 5px' }}
                >
                  Date
                </InputLabel>
                <JBDatePicker
                  required
                  value={selectedDate}
                  onChange={(val) => {
                    setSelectedDate(val);
                  }}
                  className={classes.VanguardCutom_date}
                />
              </Grid>

              <Grid
                item
                xs={6}
                className={clsx(
                  classes.cutom_vanguard_FormControl,
                  classes.cutom_vanguard_Drop
                )}
                style={{
                  position: 'relative'
                }}
              >
                <InputLabel
                  htmlFor="age-required"
                  className={classes.cutom_select_Label}
                  style={{ margin: '0px 0 6px' }}
                >
                  Program
                </InputLabel>
                <CustomAutocomplete
                  disablePortal
                  holder="Select program"
                  data={programSelected}
                  value={programs}
                  Options="name"
                  Change={(val) => {
                    setProgramSelected(val);
                  }}
                  popupIcon={<DownIcon />}
                  className={clsx(
                    classes.cutom_vanguard_Autocomplete,
                    'SelectmemberDrop'
                  )}
                />
              </Grid>
            </Grid>
            <Box
              css={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
                marginTop: '15px'
              }}
            >
              <Button color="inherit" className={classes.VArrowButton}>
                <ArrowL onClick={() => setNextOrPrevDate('prev')} />
              </Button>
              <Box className={classes.DateSection}>
                {/* eslint-disable */}
                <h5>
                  {moment(selectedDate)
                    .add(-1, 'days')
                    .format(`${dateformat}`) ===
                  moment(today).format(`${dateformat}`)
                    ? 'Today'
                    : moment(selectedDate)
                        .add(-1, 'days')
                        .format('ddd D MMM')}
                </h5>

                <h5
                  style={{
                    color: '#FFFFFF'
                  }}
                >
                  {moment(selectedDate).format(`${dateformat}`) ===
                  moment(Date.now()).format(`${dateformat}`)
                    ? 'Today'
                    : moment(selectedDate).format('ddd D MMM')}
                </h5>
                {/* <h5>wed 29 SEP.</h5> */}
                <h5>
                  {moment(selectedDate)
                    .add(1, 'days')
                    .format(`${dateformat}`) ===
                  moment(today).format(`${dateformat}`)
                    ? 'Today'
                    : moment(selectedDate)
                        .add(1, 'days')
                        .format('ddd D MMM')}
                </h5>
                {/* eslint-enable */}
              </Box>
              <Button color="inherit" className={classes.VArrowButton}>
                <ArrowR onClick={() => setNextOrPrevDate('next')} />
              </Button>
            </Box>
          </Grid>
        </Box>

        <Box
          style={{
            margin: '0px auto 0px',
            overflow: 'auto',
            height: 'calc(100vh - 355px)'
          }}
        >
          <Grid
            container
            xs={10}
            justify="center"
            style={{
              margin: '50px auto'
            }}
          >
            {isFetching ? (
              <VanguardListLoadingView />
            ) : error || !data ? (
              <Typography variant="h4" style={{ color: 'white' }}>
                {error}
              </Typography>
            ) : (
              slicedData.map((row) => {
                return (
                  <Box className={classes.proList} key={row.id}>
                    <Box
                      css={{
                        width: '100%'
                      }}
                    >
                      <div className={classes.proInfo}>
                        <label className={classes.proListTime}>
                          {row.class_start_time}
                        </label>
                        <div
                          className={classes.proName}
                          // style={{
                          //   // alignItems: 'center',
                          //   display: 'flex'
                          // }}
                        >
                          <div>
                            <Link
                              to={{
                                pathname: ROUTES.VANGUARD_CHALKBOARD_WORKOUT,
                                state: {
                                  classId: row.id,
                                  program_name: row.program_name,
                                  dateInfo: `${row.class_start_time}, ${moment(
                                    selectedDate
                                  ).format('ddd D MMM YYYY')}`,
                                  sign_in: row.sign_in
                                }
                              }}
                              style={{
                                display: 'block'
                              }}
                            >
                              <h4>
                                <label>{row.program_name}</label>
                              </h4>
                            </Link>
                            <span>{row.class_name}</span>
                          </div>
                        </div>
                      </div>
                    </Box>
                    <ArrowRight
                      className={classes.ArrowRightcss}
                      // style={{
                      //   marginRight: '10px',
                      //   verticalAlign: 'bottom',
                      //   width: '20%',
                      //   display: 'block',
                      //   height: '40px',
                      //   minWidth: '40px'
                      // }}
                    />
                    {/* eslint-disable */}
                    {moment(selectedDate).isBefore(new Date()) &&
                    row.sign_in === 0 ? (
                      <JBVanguardButton
                        // CutomClass={classes.SignInBtn}
                        CutomClass={clsx(
                          classes.SignInBtn,
                          classes.ViewSignInBtn
                        )}
                        type="outlined"
                        component={Link}
                        to={{
                          pathname:
                            ROUTES.VANGUARD_CHALKBOARD_WORKOUT_SIGN_IN_MEMBER,
                          state: {
                            classId: row.id,
                            program_name: row.program_name,
                            dateInfo: `${row.class_start_time}, ${moment(
                              selectedDate
                            ).format('ddd D MMM YYYY')}`,
                            isPast: true
                          }
                        }}
                      >
                        View Attendance
                      </JBVanguardButton>
                    ) : (
                      <JBVanguardButton
                        CutomClass={classes.SignInBtn}
                        disabled={row.sign_in === 0}
                        type="outlined"
                        component={Link}
                        to={{
                          pathname:
                            ROUTES.VANGUARD_CHALKBOARD_WORKOUT_SIGN_IN_MEMBER,
                          state: {
                            classId: row.id,
                            program_name: row.program_name,
                            dateInfo: `${row.class_start_time}, ${moment(
                              selectedDate
                            ).format('ddd D MMM YYYY')}`,
                            isPast: false
                          }
                        }}
                      >
                        Sign In
                      </JBVanguardButton>
                    )}
                  </Box>
                );
              })
            )}
            {/* eslint-enable */}
            {data && !isFetching && !error && data?.length > rowsPerPage && (
              <Pagination
                count={Math.ceil(data.length / rowsPerPage)}
                color="primary"
                variant="outlined"
                page={page}
                onChange={handlePageChange}
                shape="rounded"
                className={classes.root}
              />
            )}
          </Grid>
        </Box>
      </Card>
      <LeavePagePopup
        setOpen={LeavePageopen}
        handleClose={() => setLeavePageopen(false)}
        handleHistory={ROUTES.VANGUARD}
      />
    </div>
  );
};

ChalkboardIndex.propTypes = {
  classes: PropType.object.isRequired
};
export default withStyles(styles)(ChalkboardIndex);
