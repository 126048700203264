import { openNoti } from 'src/Redux/global/global.actions';
import types from './SettingReducer.types';
import {
  getGymSet,
  getInvoiceSet,
  getAllTaxes,
  getRevenueCategorywithStatus,
  updateRevenueCategory,
  createTax,
  deleteTax
} from '../../services/Setting.services';
import { getErrorMsg, getError } from '../../utils/ErrorHandling';
import {
  fetchAllRoles,
  getCountries,
  getGymloc
} from '../../services/Members.module';
import {
  getAllContracts,
  getAllPaymentPlans
} from '../../services/Membersip.services';

const isFetching = (type) => ({
  type,
  actionType: types.isFetching
});

const fetchComplete = (type, payload) => ({
  type,
  actionType: types.fetchSucess,
  payload
});

const errorFetch = (type, payload) => ({
  type,
  actionType: types.Errorfetch,
  payload
});

const getupdateTax = (tax) => {
  const result = tax.map((res) => {
    if (res.rate > 0) {
      res.name = `${res.name} ${res.rate}%`;
    }
    return res;
  });
  return result;
};

export const getAllInvoiceSet = () => {
  const type = types.getCurrencyRates;
  const footerType = types.getinvoicefooter;
  const settingType = types.getInvoiceSetting;
  return (dispatch) => {
    getInvoiceSet()
      .then(
        ({
          currency_config,
          invoice_footer,
          member_invoice_date,
          invoice_auto_bill
        }) => {
          const invoiceFooter = /^(<p>)/.test(invoice_footer)
            ? invoice_footer
            : `<p>${invoice_footer}</p>`;
          dispatch(fetchComplete(type, currency_config));
          dispatch(fetchComplete(footerType, invoiceFooter));
          dispatch(
            fetchComplete(settingType, {
              member_invoice_date,
              invoice_auto_bill
            })
          );
        }
      )
      .catch((err) => {
        dispatch(errorFetch(type, getErrorMsg(err)));
        dispatch(errorFetch(footerType, getErrorMsg(err)));
        dispatch(errorFetch(settingType, getErrorMsg(err)));
      });
  };
};

export const getAllGymSet = () => {
  const type = types.getDateformat;
  return (dispatch) => {
    getGymSet()
      .then((res) => dispatch(fetchComplete(type, res)))
      .catch((err) => dispatch(errorFetch(type, getErrorMsg(err))));
  };
};

export const getRevenSet = () => {
  const type = types.getRevCtgry;
  return (dispatch) => {
    getRevenueCategorywithStatus()
      .then((res) => dispatch(fetchComplete(type, res)))
      .catch((err) => dispatch(errorFetch(type, getErrorMsg(err))));
  };
};
export const updateRevenueCtgry = (id, values) => {
  return (dispatch) => {
    updateRevenueCategory(id, values)
      .then(() => {
        dispatch(getRevenSet());
        dispatch(
          openNoti(`Revenue category "${values.label}" succesfuly updated`)
        );
      })
      .catch((err) => dispatch(openNoti(getErrorMsg(err), 'error')));
  };
};
// export const createRevenueCtgry = (values) => {
//   return (dispatch) => {
//     createRevenueCategory(values)
//       .then(() => {
//         dispatch(getRevenSet());
//         dispatch(
//           openNoti(`Revenue category ${values.label} succesfuly created`)
//         );
//       })
//       .catch((err) => dispatch(openNoti(getErrorMsg(err), 'error')));
//   };
// };
export const getAllGymloc = () => {
  const type = types.getGymloc;
  return (dispatch) => {
    getGymloc()
      .then((res) => dispatch(fetchComplete(type, res)))
      .catch((err) => dispatch(errorFetch(type, getErrorMsg(err))));
  };
};

export const getAllTax = () => {
  const type = types.getTaxRates;
  return (dispatch) => {
    getAllTaxes()
      .then((res) => dispatch(fetchComplete(type, getupdateTax(res))))
      .catch((err) => dispatch(errorFetch(type, getErrorMsg(err))));
  };
};
export const createNewTax = (values) => {
  return (dispatch) => {
    createTax(values)
      .then(() => {
        dispatch(getAllTax());
        dispatch(openNoti('Tax created succesfuly'));
      })
      .catch((err) => dispatch(openNoti(getError(err), 'error')));
  };
};
export const removeTax = (id, values) => {
  return (dispatch) => {
    deleteTax(id, values)
      .then(() => {
        dispatch(getAllTax());
        dispatch(openNoti('Tax deleted succesfuly'));
      })
      .catch((err) => dispatch(openNoti(getErrorMsg(err), 'error')));
  };
};
export const getContracts = () => {
  const type = types.getContracts;
  return (dispatch) => {
    getAllContracts()
      .then((res) => dispatch(fetchComplete(type, res)))
      .catch((err) => dispatch(errorFetch(type, getErrorMsg(err))));
  };
};

export const getAllCountries = () => {
  const type = types.getAllCountries;
  return (dispatch) => {
    getCountries()
      .then((res) => dispatch(fetchComplete(type, res)))
      .catch((err) => dispatch(errorFetch(type, getErrorMsg(err))));
  };
};
export const getPaymentPlans = () => {
  const type = types.getPaymentPlans;
  return (dispatch) => {
    getAllPaymentPlans()
      .then((res) => dispatch(fetchComplete(type, res)))
      .catch((err) => dispatch(errorFetch(type, getErrorMsg(err))));
  };
};

export const getAllRoles = () => {
  const type = types.getRoles;
  return (dispatch) => {
    dispatch(isFetching(type));
    fetchAllRoles()
      .then((res) => dispatch(fetchComplete(type, res)))
      .catch((err) => dispatch(errorFetch(type, getErrorMsg(err))));
  };
};
