import React, { useState } from 'react';
// import { useHistory } from 'react-router-dom';
import PropType from 'prop-types';
import { useDispatch } from 'react-redux';
import { getRevenSet } from 'src/Redux/SettingReducer/SettingReducer.actions';
import { createRevenueCategory } from 'src/services/Setting.services';
import { openNoti } from 'src/Redux/global/global.actions';
import { getErrorMsg } from 'src/utils/ErrorHandling';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  withStyles,
  Grid,
  FormControlLabel,
  Box
} from '@material-ui/core';
import IOSSwitch from 'src/views/common/FormControl/IOSSwitch';
import Plusicon from '../../../assets/image/plusicon.png';
import dcloseicon from '../../../assets/image/closeicon.png';
import JBButton from '../../common/JBButton/JBButton';
import FormControl from '../../common/FormControl/formControl';

const styles = () => ({
  dtitle: {
    color: '#292929',
    fontWeight: 'bold',
    fontSize: '33px',
    lineHeight: 'normal'
  },
  cutom_select_Label: {
    fontSize: '12px',
    padding: '7px 0'
  },
  titlebk: {
    display: 'inline-block',
    padding: '25px 24px 0'
  },
  closeBtns: {
    position: 'absolute',
    right: '10px',
    top: '15px'
  },
  ActionsBtns: {
    padding: '20px 24px 30px'
  }
});

function CategoryDialog({
  classes,
  handleHistory,
  Buttonlabel,
  Dialogtitle,
  ...props
}) {
  const [open, setOpen] = React.useState(false);
  const [data, setData] = useState({ label: '', status: 1 });
  const dispatch = useDispatch();
  // const history = useHistory();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setData({ label: '', status: 0 });
  };
  const handleCreate = () => {
    createRevenueCategory(data)
      .then(() => {
        dispatch(getRevenSet());
        handleClose();
        dispatch(
          openNoti(`Revenue category "${data?.label}" succesfuly created`)
        );
      })
      .catch((err) => dispatch(openNoti(getErrorMsg(err), 'error')));
  };
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div {...props}>
      <JBButton type="primary" onClick={handleClickOpen} isImg={Plusicon}>
        {Buttonlabel}
      </JBButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick
      >
        <div style={{ width: '548px' }}>
          <Grid alignItems="center" container direction="row">
            <Grid item xs>
              <DialogTitle id="alert-dialog-title" className={classes.titlebk}>
                <h4 className={classes.dtitle}>{Dialogtitle}</h4>
              </DialogTitle>
            </Grid>
            <Grid item xs={2}>
              <Button className={classes.closeBtns} onClick={handleClose}>
                <img alt="img" src={dcloseicon} />
              </Button>
            </Grid>
          </Grid>

          <DialogContent>
            <Grid container spacing={2} style={{ marginTop: '5px' }}>
              <Grid item xs={12} style={{}}>
                <Box>
                  <FormControl
                    control="input"
                    placeholder=""
                    label="Label"
                    margin="none"
                    required
                    value={data.label}
                    onChange={(e) =>
                      setData({ ...data, label: e.target.value })
                    }
                    // style={{ margin: '0 0' }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} className="switch-btn">
                <Box mt="10px" mb="15px">
                  <FormControlLabel
                    control={
                      <IOSSwitch
                        checked={data.status}
                        onChange={() =>
                          setData({ ...data, status: data.status ? 0 : 1 })
                        }
                      />
                    }
                    label="In Use"
                    style={{ margin: '0 0' }}
                  />
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions className={classes.ActionsBtns}>
            <JBButton type="primary" onClick={handleCreate}>
              Create
            </JBButton>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}
CategoryDialog.defaultProps = {
  handleHistory: null
};

CategoryDialog.propTypes = {
  classes: PropType.object.isRequired,
  handleHistory: PropType.string,
  Buttonlabel: PropType.string.isRequired,
  Dialogtitle: PropType.string.isRequired
};
export default withStyles(styles)(CategoryDialog);
