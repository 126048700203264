import React from 'react';
import PropType from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  DialogTitle,
  withStyles
} from '@material-ui/core';
import JBButton from 'src/views/common/JBButton/JBButton';
import questionmark from '../../../../../assets/image/questionmark.png';

const styles = (theme) => ({
  Dialogtitle: {
    padding: '16px 24px 0',
    color: '#fff'
  },
  DialogActionsBk: {
    padding: '5px 20px 20px'
  },
  DialogPopup: {
    '& .MuiPaper-root': {
      background: theme.masterhq.mainCard.background
    }
  }
});

function ConfirmDialog({ classes, handleClose, setOpen, addDiscount }) {
  // eslint-disable-next-line no-unused-vars
  return (
    <div>
      <Dialog
        open={setOpen}
        onClose={() => handleClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick
        className={classes.DialogPopup}
      >
        <div style={{ width: 394 }}>
          <DialogTitle id="alert-dialog-title" className={classes.Dialogtitle}>
            <img
              alt="Plusicon"
              src={questionmark}
              style={{ display: 'inline-block', verticalAlign: 'sub' }}
            />
            <Typography
              color="#000"
              variant="h5"
              component="div"
              className={classes.title}
              style={{ display: 'inline-block', marginLeft: '10px' }}
            >
              Apply discount?
            </Typography>
          </DialogTitle>
          <DialogContent>
            <p
              style={{
                fontWeight: 'normal',
                fontSize: '14px',
                lineHeight: '18px',
                letterSpacing: '0.002em',
                color: '#7F7F7F',
                padding: '10px 0'
              }}
            >
              Are you sure you want to apply a discount?
            </p>
          </DialogContent>
          <DialogActions className={classes.DialogActionsBk}>
            <Button
              variant="outlined"
              onClick={handleClose}
              style={{
                height: '48px',
                color: '#7F7F7F',
                fontWeight: 'bold'
              }}
            >
              Cancel
            </Button>
            <JBButton
              onClick={addDiscount}
              type="primary"
              // onClick={() => {
              //   // eslint-disable-next-line
              //   handleBack
              //     ? handleBack()
              //     : handleHistory
              //     ? history.push(`${handleHistory}`)
              //     : history.goBack();
              // }}
            >
              Confirm
            </JBButton>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}
// ConfirmDialog.defaultProps = {
//   handleHistory: null,
//   handleBack: null
// };

ConfirmDialog.propTypes = {
  classes: PropType.object.isRequired,
  setOpen: PropType.bool.isRequired,
  handleClose: PropType.func.isRequired,
  addDiscount: PropType.func.isRequired
  // handleBack: PropType.func,
  // handleHistory: PropType.string
};
export default withStyles(styles)(ConfirmDialog);
