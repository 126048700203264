import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Button } from '@material-ui/core';
import PropType from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import FormControl from 'src/views/common/FormControl/formControl';
import { Form, Formik } from 'formik';
import InputAdornment from '@material-ui/core/InputAdornment';
import * as Yup from 'yup';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { openNoti } from 'src/Redux/global/global.actions';
import { getErrorMsg } from 'src/utils/ErrorHandling';
import { resetPassoword } from 'src/services/auth.services';

const ResetPassForm = ({ closeDialog, OpenNotiDisp, data }) => {
  const [showcurrent, setshowcurrent] = useState(false);
  const [showreset, setshowreset] = useState(false);

  const initialValues = {
    password: '',
    confirmpassword: ''
  };

  const validationSchema = Yup.object({
    password: Yup.string()
      .min(6, 'Password length too short')
      .max(20, 'Password length too lengthy')
      .matches(
        new RegExp(
          /^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[A-Z])(?=.*[a-z])[a-zA-Z0-9!@#$%^&*]{6,20}$/
        ),
        'Password is not strong enough'
      )
      .required('Please Enter Your new password'),
    confirmpassword: Yup.string()
      .oneOf([Yup.ref('password')], 'Password must match')
      .required('Please enter confirm password')
  });

  const onSubmitting = async (val, submitProp) => {
    try {
      const values = {
        ...data,
        password: val.password,
        password_confirmation: val.confirmpassword
      };
      await resetPassoword(values);
      OpenNotiDisp('Successfully change the password', 'info');
      submitProp.setSubmitting(false);
      closeDialog();
    } catch (err) {
      OpenNotiDisp(getErrorMsg(err), 'error');
      submitProp.setSubmitting(false);
    }
  };

  return (
    <div style={{ width: 540 }}>
      <IconButton
        color="#01847A"
        onClick={closeDialog}
        aria-label="Close"
        style={{ float: 'right', padding: '20px' }}
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle id="alert-dialog-title">
        <h2
          style={{
            textAlign: 'center',
            fontWeight: 'bold',
            fontSize: '32px'
          }}
        >
          Reset Password
        </h2>
      </DialogTitle>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmitting}
        validationSchema={validationSchema}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          touched,
          isSubmitting,
          isValid,
          values
        }) => (
          <Form>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <FormControl
                  control="input"
                  label="Please Enter your Password"
                  name="password"
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password && errors.password}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type={showcurrent ? 'text' : 'password'}
                  value={values.password}
                  autoComplete="current-password"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setshowcurrent(!showcurrent)}
                        >
                          {showcurrent ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  maxLength={20}
                />
                <FormControl
                  control="input"
                  label="Please Enter Confirm Password"
                  name="confirmpassword"
                  error={Boolean(
                    touched.confirmpassword && errors.confirmpassword
                  )}
                  helperText={touched.confirmpassword && errors.confirmpassword}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type={showreset ? 'text' : 'password'}
                  value={values.confirmpassword}
                  autoComplete="current-password"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setshowreset(!showreset)}
                        >
                          {showreset ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  maxLength={20}
                />
              </DialogContentText>
              <Button
                color="primary"
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                style={{
                  padding: '15px 24px',
                  background: '#01847A',
                  boxShadow: '0px 8px 16px rgba(0, 126, 255, 0.16)',
                  borderRadius: '2px',
                  margin: '30px 0px'
                }}
                disabled={isSubmitting || !isValid}
              >
                Reset Password
              </Button>
            </DialogContent>
          </Form>
        )}
      </Formik>
    </div>
  );
};
ResetPassForm.propTypes = {
  closeDialog: PropType.func.isRequired,
  OpenNotiDisp: PropType.func.isRequired,
  data: PropType.object.isRequired
};

const mapToDispatch = (dispatch) => ({
  OpenNotiDisp: (msg, type) => dispatch(openNoti(msg, type))
});

export default connect(null, mapToDispatch)(ResetPassForm);
