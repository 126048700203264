/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Box, Container, Typography, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import { ReactComponent as Error403 } from 'src/assets/image/403-Error.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  image: {
    marginTop: 50,
    display: 'inline-block',
    maxWidth: '100%',
    width: 560
  }
}));

const ErrorUserView = () => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="403">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="md">
          <Typography align="center" color="textPrimary" variant="h1">
            403: You don't have permission to access this page
          </Typography>
          {/* <Typography align="center" color="textPrimary" variant="subtitle2">
            You either tried some shady route or you came here by mistake.
            Whichever it is, try using the navigation
          </Typography> */}
          <Box textAlign="center">
            <Error403 className={classes.image} />
          </Box>
        </Container>
      </Box>
    </Page>
  );
};

export default ErrorUserView;
