import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Grid,
  FormControlLabel,
  InputLabel,
  Radio,
  RadioGroup,
  Paper,
  withStyles
} from '@material-ui/core';
import PropType from 'prop-types';
import IOSSwitch from 'src/views/common/FormControl/IOSSwitch';
import { checkValidNum, validateInt } from 'src/utils/validations';
import { getValuefromObject } from 'src/utils/someCommon';
import FormControl from 'src/views/common/FormControl/formControl';
import CustomAutoComplete from '../commonAutocomplete';
import styles from './Class.style';

const RepeatWeek = (props) => {
  const { classes, weekdata, dataweek, getRepeatWeek, getAllCoaches } = props;

  const [data, setData] = useState(weekdata ? weekdata[dataweek.id] : {});

  useEffect(() => {
    const labels = dataweek.id;
    const updatedValue = {};
    updatedValue[labels] = {
      is_limit: data.is_limit ? 1 : 0,
      head_coach: null,
      co_coach: null
    };
    if (data.head_coach) {
      updatedValue[labels] = {
        ...updatedValue[labels],
        head_coach: getValuefromObject(data.head_coach)
      };
    }
    if (data.co_coach) {
      updatedValue[labels] = {
        ...updatedValue[labels],
        co_coach: getValuefromObject(data.co_coach)
      };
    }
    if (!data.is_limit) {
      getRepeatWeek(updatedValue);
    } else {
      updatedValue[labels] = {
        ...updatedValue[labels],
        class_limit: data.class_limit,
        allow_waitlist: data.allow_waitlist ? 1 : 0
      };
      if (data.allow_waitlist && data.is_limit) {
        updatedValue[labels] = {
          ...updatedValue[labels],
          class_open: data.class_open
        };
      }
      getRepeatWeek(updatedValue);
    }
  }, [data, dataweek]); // eslint-disable-line
  return (
    <Grid item xs={12} style={{ marginTop: '30px' }}>
      <div className={classes.day_br}>
        <h3 style={{ marginBottom: '10px' }}>{dataweek.label}</h3>
      </div>
      <Grid
        item
        xs={12}
        className="switch-btn"
        style={{ marginBottom: '15px', marginTop: '15px' }}
      >
        <FormControlLabel
          control={
            <IOSSwitch
              checked={data.is_limit}
              onChange={() => setData({ ...data, is_limit: !data.is_limit })}
            />
          }
          label="Class limit/Reservation"
        />
      </Grid>
      {data.is_limit && (
        <Grid xs={12} style={{ marginTop: '0' }}>
          <Grid item xs={4} style={{ padding: 0, margin: '0px' }}>
            <FormControl
              control="input"
              label="Class limit"
              value={data.class_limit}
              placeholder="Specify max amount of members"
              seterrors={checkValidNum(data.class_limit)}
              seterrortext="Please Provide valid Class Limit"
              onChange={(e) =>
                setData({
                  ...data,
                  class_limit: validateInt(e.target.value) // eslint-disable-line
                })
              } // eslint-disable-line
            />
          </Grid>

          <Grid
            item
            xs={12}
            className="switch-btn"
            style={{
              display: 'flex',
              margin: '15px 0'
            }}
          >
            <FormControlLabel
              control={
                <IOSSwitch
                  checked={data.allow_waitlist}
                  onChange={(e) =>
                    setData({
                      ...data,
                      allow_waitlist: e.target.checked
                    })
                  } // eslint-disable-line
                  name="waitlist"
                />
              }
              label="Allow waitlist"
            />
          </Grid>
          {data.allow_waitlist && (
            <div>
              <Grid
                item
                xs={12}
                style={{
                  display: 'flex',
                  margin: '10px 0'
                }}
              >
                <Paper elevation={0}>
                  <p className={classes.ptext}>
                    When a spot in the class opens, people on the waitlist are:
                  </p>
                </Paper>
              </Grid>

              <Grid
                item
                xs={12}
                style={{
                  display: 'flex',
                  margin: '10px 0'
                }}
              >
                <RadioGroup
                  aria-label="quiz"
                  name="quiz"
                  value={data.class_open}
                  onChange={(e) =>
                    setData({
                      ...data,
                      class_open: e.target.value
                    })
                  } // eslint-disable-line
                >
                  <FormControlLabel
                    value="0"
                    control={<Radio color="primary" />}
                    label="Automatically added to the class in the order they signed up."
                  />
                  <FormControlLabel
                    value="1"
                    control={<Radio color="primary" />}
                    label="All emailed, first to reply gets added to the class."
                  />
                </RadioGroup>
              </Grid>
            </div>
          )}
        </Grid>
      )}
      <Grid xs={12} style={{ marginTop: '0', display: 'flex' }}>
        <Grid item xs={4} style={{ marginTop: '0px', marginRight: '10px' }}>
          <InputLabel
            htmlFor="age-required"
            className={classes.cutom_select_Label}
          >
            Head coach
          </InputLabel>
          <CustomAutoComplete
            holder="Head Coach"
            value={
              data.co_coach
                ? getAllCoaches.filter(
                    (list) => list.id !== data.co_coach.id // eslint-disable-line
                  ) // eslint-disable-line
                : getAllCoaches
            }
            Options="name"
            data={data.head_coach}
            Change={(val) => setData({ ...data, head_coach: val })}
          />
        </Grid>
        <Grid item xs={4} style={{ marginTop: '0px' }}>
          <InputLabel
            htmlFor="age-required"
            className={classes.cutom_select_Label}
          >
            Co-Coach
          </InputLabel>
          <CustomAutoComplete
            holder="Select Co-Coach"
            value={
              data.head_coach
                ? getAllCoaches.filter(
                    (list) => list.id !== data.head_coach.id // eslint-disable-line
                  ) // eslint-disable-line
                : getAllCoaches
            }
            data={data.co_coach}
            Options="name"
            Change={(val) => setData({ ...data, co_coach: val })}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
RepeatWeek.propTypes = {
  classes: PropType.object.isRequired,
  weekdata: PropType.object.isRequired,
  dataweek: PropType.object.isRequired,
  getAllCoaches: PropType.array.isRequired,
  getRepeatWeek: PropType.func.isRequired
};

const mapToState = (state) => ({
  getAllCoaches: state.classReducer.coaches
});

export default connect(mapToState)(withStyles(styles)(RepeatWeek));
