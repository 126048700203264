export default {
  ResetStep: 'RESET_STEP',
  NextStep: 'NEXT_STEP',
  StepBack: 'STEP_BACK',
  SetMembershiptype: 'SET_MEMBERSHIP_TYPE',
  setMembership: 'SET_MEMBERSHIP',
  fetchMembershipPlan: 'FETCH_MEMBERSHIP_PLAN',
  getMembershipPlan: 'GET_MEMBERSHIP_PLAN',
  setMembershipPayment: 'SET_MEMBERSHIP_PAYMENT',
  setUserTouched: 'SET_USER_TOUCHED',
  setUserError: 'SET_USER_ERROR',
  setUserInfo: 'SET_USER_INFO',
  setMemDate: 'SET_MEM_START_DATE',
  setMemInitial: 'SET_MEM_INITIAL',
  setMemAttendance: 'SET_ATTENDANCE',
  setBilledEvery: 'SET_BILLED',
  setAttendanceUses: 'SET_USES',
  setUsesType: 'SET_USES_TYPE',
  changeSignContract: 'CHANGE_SIGN_CONTRACT',
  setNoOfSessions: 'SET_NUM_SESSIONS',
  setUsedSessions: 'SET_USED_SESSIONS',
  getMemSession: 'GET_MEM_SESSION',
  setAutoRenew: 'SET_AUTO_RENEW',
  setPytOpt: 'SET_PAY_OPT',
  setSignInitials: 'SET_INITIALS',
  setPolicy: 'SET_POLICY',
  setRenewal: 'SET_RENEWAL_COMMIT',
  setRenewalBilled: 'SET_RENEWAL_BILLED',
  changeSignStatus: 'SET_SIGNED',
  addProgram: 'ADD_PROGRAM',
  setUser: 'SET_USER',
  setUserContract: 'SET_USER_CONTRACT',
  setSessionDate: 'SET_SESSION_DATE',
  setDiscountType: 'SET_DISCOUNT_TYPE',
  setDiscountAmount: 'SET_DISCOUNT_AMOUNT',
  setSetupFee: 'SET_SETUP_FEE',
  setMembershipFee: 'SET_MEM_FEE',
  setDefaultPaymentMethode: 'SET_DEFAULT_PAYMENT',
  setCardName: 'SET_CARD_NAME',
  setPaymentMeTab: 'SET_PAYMENT_TAB',
  setSelectedUser: 'SET_SELECTED_USER',
  setSignature: 'SET_SIGNATURE',
  setPolicyInitials: 'SET_POLICY_INITIALS',
  setRenewOpt: 'SET_RENEW_OPT',
  selectAllPrograms: 'SELECT_ALL',
  clearSignature: 'CLEARE_SIGNATURE',
  resetSearch: 'RESET_SEARCH',
  setSessionUses: 'SET_SESSION_USES',
  setSessionUsesType: 'SET_SESSIONS_TYPE',
  resetData: 'RESET_DATA',
  setWeekSetup: 'SET_WEEK_SETUP',
  setWeekFee: 'SET_WEEK_FEE',
  skipStep: 'SKIP_STEP',
  setRenewalFee: 'RENEWAL_FEE',
  setRenewalWeek: 'RENEWAL_WEEK',
  setErrorMsg: 'ERROR_MSG',
  setContract: 'SET_CONTRACT',
  resetUser: 'RESET_USER',
  setBankData: 'SET_BANK_DATA',
  SetSelectedWaiver: 'SET_SELECTED_WAIVER',
  setQuestionnaire: 'SET_QUESTIONNAIRE',
  SetActiveStep: 'SET_ACTIVE_STEP',
  toggleSignStatus: 'TOGGLE_SIGN_STATUS',
  setWaiverSignInitials: 'SET_WAIVER_SIGN_INITIALS',
  setIsSigned: 'SET_IS_SIGNED',
  setDropInSignature: 'SET_DROP_IN_SIGNATURE',
  setSignStatus: 'SET_SIGN_STATUS',
  getDropInPlan: 'GET_DROP_IN_PLAN',
  setStartDateDropIn: 'SET_STARTDATE_DROPIN',
  setWaiverInitialBox: 'SET_WAIVER_INITIAL_BOX'
};
