import types from './wod.actionTypes';
import {
  getAllTags,
  getWODs,
  toStarred
} from '../../services/WODmodule.services';
import filterType from './wod.reducer.constants';
import { store } from '../rootReducer';
import { getErrorMsg } from '../../utils/ErrorHandling';

// new Reducer filters added
const workoutsFilter = (filter, data) => ({
  type: types.workoutsfilter,
  filter,
  data
});

const WorrkoutsFavFilter = (filter, data) => ({
  type: types.workoutsFavfilter,
  filter,
  data
});

const fetchingWOD = (type) => ({
  type,
  actionType: filterType.isFetching
});

const fetchComplete = (type, payload) => ({
  type,
  actionType: filterType.fetchSucess,
  payload
});

const ErrorFetching = (type, payload) => ({
  type,
  actionType: filterType.Errorfetch,
  payload
});

const getTags = (payload) => ({
  type: types.getTags,
  payload
});
export const toggleSelectAll = (payload) => ({
  type: types.selectAll,
  payload
});

// addStarred fav WOD

// get WODS

const getDefaultWOD = (val) => {
  const type = types.workouts;
  return (dispatch) => {
    dispatch(fetchingWOD(type));
    getWODs(val)
      .then((resp) => {
        dispatch(fetchComplete(type, resp));
      })
      .catch((err) => dispatch(ErrorFetching(type, getErrorMsg(err))));
  };
};

const getFavWOD = (val) => {
  const type = types.workoutsFav;
  return (dispatch) => {
    dispatch(fetchingWOD(type));
    getWODs(val)
      .then((res) => dispatch(fetchComplete(type, res)))
      .catch((err) => dispatch(ErrorFetching(type, getErrorMsg(err))));
  };
};

// filter Async
export const getWOD = (filter, data) => {
  return (dispatch) => {
    dispatch(workoutsFilter(filter, data));
    const filData = store.getState().wodList.workoutsFilter;
    let updatedValue = {
      page: filData.page,
      search: filData.search,
      ...filData.sorting
    };
    if (Object.keys(filData.customFilter).length > 0) {
      updatedValue = {
        ...updatedValue,
        ...filData.customFilter
      };
    } else {
      updatedValue = {
        ...updatedValue,
        ...filData.default
      };
    }
    dispatch(getDefaultWOD(updatedValue));
  };
};

export const FavWOD = (filter, data) => {
  return (dispatch) => {
    dispatch(WorrkoutsFavFilter(filter, data));
    const filData = store.getState().wodList.workoutsFavFilter;
    const updatedValue = {
      page: filData.page,
      search: filData.search,
      ...filData.default,
      ...filData.sorting,
      ...filData.customFilter
    };
    dispatch(getFavWOD(updatedValue));
  };
};
// Starred WOD's

export const tobeStarred = (id) => {
  toStarred(id, 1)
    .then(() => store.dispatch(getWOD()))
    .then(() => store.dispatch(FavWOD()))
    .catch(() => console.warn('Server Error'));
};

export const toUnStarred = (id) => {
  toStarred(id, 0)
    .then(() => store.dispatch(getWOD()))
    .then(() => store.dispatch(FavWOD()))
    .catch(() => console.warn('Server Error'));
};

export const getWODTags = () => {
  return (dispatch) => {
    getAllTags()
      .then((res) => dispatch(getTags(res)))
      .catch(() => dispatch(getTags(['Error from Server'])));
  };
};
