import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropType from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import LoadingView from '../views/common/loading';
import Documents from '../views/Documents/Documents';
import Waivers from '../views/Documents/Waivers/Waivers';
import WaiversSigned from '../views/Documents/Waivers/WaiversSigned';
import WaiversTemplates from '../views/Documents/Waivers/WaiversTemplates';
import CopyWaiver from '../views/Documents/Waivers/CopyWaiver';
import NewWaiver from '../views/Documents/Waivers/NewWaiver';
import {
  getActiveMissCont,
  getAllMissCont,
  getInActiveMissCont,
  getSignedCont,
  getTempCont
} from '../Redux/ContractReducer/ContractReducer.actions';
import MembershipContract from '../views/Documents/MembershipContract';
import {
  getMissWav,
  getSignedWav,
  getTempWav
} from '../Redux/WaiverReducer/WaiverReducer.actions';

class DocumentContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fetched: false
    };
  }

  componentDidMount() {
    const {
      allMissContracts,
      actMissContracts,
      inActMissContracts,
      SigContracts,
      getMissWaiver,
      getSignWaiver,
      getTempWaiver,
      getTemplateCont
    } = this.props;
    actMissContracts();
    SigContracts();
    getMissWaiver();
    getSignWaiver();
    getTempWaiver();
    inActMissContracts();
    allMissContracts();
    getTemplateCont();
    this.setState({ fetched: true });
  }

  render() {
    const {
      match: { url }
    } = this.props;
    const { fetched } = this.state;
    if (!fetched) return <LoadingView />;
    return (
      <Switch>
        <Route path={`${url}/waiver/detail`} component={WaiversSigned} />
        <Route
          path={`${url}/waiver/templates/detail`}
          component={WaiversTemplates}
        />
        <Route path={`${url}/waiver/copy`} component={CopyWaiver} />
        <Route path={`${url}/waiver/new`} component={NewWaiver} />
        <Route path={`${url}/waiver`} component={Waivers} />
        <Route
          path={`${url}/membership/contract`}
          component={MembershipContract}
        />
        <Route path={`${url}/`} component={Documents} />
      </Switch>
    );
  }
}

DocumentContainer.propTypes = {
  match: PropType.object.isRequired,
  allMissContracts: PropType.func.isRequired,
  actMissContracts: PropType.func.isRequired,
  inActMissContracts: PropType.func.isRequired,
  SigContracts: PropType.func.isRequired,
  getMissWaiver: PropType.func.isRequired,
  getSignWaiver: PropType.func.isRequired,
  getTempWaiver: PropType.func.isRequired,
  getTemplateCont: PropType.func.isRequired
};

const mapToDispatch = (dispatch) => ({
  allMissContracts: () => dispatch(getAllMissCont()),
  actMissContracts: () => dispatch(getActiveMissCont()),
  inActMissContracts: () => dispatch(getInActiveMissCont()),
  SigContracts: () => dispatch(getSignedCont()),
  getMissWaiver: () => dispatch(getMissWav()),
  getSignWaiver: () => dispatch(getSignedWav()),
  getTempWaiver: () => dispatch(getTempWav()),
  getTemplateCont: () => dispatch(getTempCont())
});

export default connect(null, mapToDispatch)(DocumentContainer);
