import React from 'react';
import { compose, withProps } from 'recompose';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from 'react-google-maps';
import { API_KEY } from '../../../constant/Constants';

export default compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&v=3.exp&libraries=geometry,drawing,places`,
    loadingElement: <div style={{ height: '250px' }} />,
    containerElement: <div style={{ height: '250px', width: '100%' }} />,
    mapElement: <div style={{ height: '250px', width: '100%' }} />
  }),
  withScriptjs,
  withGoogleMap
)((props) => (
  <GoogleMap
    defaultZoom={16}
    defaultCenter={props.positions}
    center={props.positions}
  >
    <Marker position={props.positions} />
  </GoogleMap>
));
