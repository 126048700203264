import React from 'react';
import PropType from 'prop-types';
import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import Typography from '@material-ui/core/Typography';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import JBButton from '../../../common/JBButton/JBButton';
import dcloseicon from '../../../../assets/image/closeicon.png';

const styles = () => ({
  Deletebtn: {
    background: '#FB4E4E',
    boxShadow: '0px 8px 16px rgba(251, 78, 78, 0.16)',
    borderRadius: '2px',
    height: '48px',
    padding: '10px 15px',
    color: '#fff',
    fontWeight: 'bold',
    '&:hover': {
      background: '#FB4E4E'
    }
  },
  hr: {
    border: '1px solid rgb(196 196 196 / 0.4)',
    display: 'inline-block',
    width: '100%',
    margin: '5px 0'
  },
  ptext: {
    fontSize: '14px',
    lineHeight: '24px',
    color: '#292929',
    fontWeight: 'normal',
    padding: '0px 10px'
  },
  headtext: {
    fontSize: '15px',
    marginBottom: '10px',
    padding: '15px 10px',
    background: '#eaeaea'
  }
});

function ReservationDialog({ classes, open, handleClose, values }) {
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick
      >
        <div style={{ width: '575px' }}>
          <Grid alignItems="center" container direction="row">
            <Grid item xs>
              <DialogTitle id="alert-dialog-title">
                <Typography
                  color="#000"
                  variant="h3"
                  component="div"
                  className={classes.title}
                  style={{ display: 'inline-block' }}
                >
                  {values.name}
                  {values.limit - values.arr.length > 0
                    ? `(${values.limit - values.arr.length} Available)`
                    : null}
                </Typography>
              </DialogTitle>
            </Grid>
            <Grid
              item
              xs={2}
              style={{ textAlign: 'right', marginRight: '10px' }}
            >
              <Button className={classes.btns} onClick={handleClose}>
                <img alt="img" src={dcloseicon} />
              </Button>
            </Grid>
          </Grid>

          <DialogContent>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={0}
              style={{ margin: '0 0 20px' }}
            >
              {/* <Grid item xs={12}>
                <h3 className={classes.headtext}>Member Reserved</h3>
              </Grid> */}
              <Grid item xs={6}>
                <h3 className={classes.headtext}>Member Name</h3>
              </Grid>
              <Grid item xs={6}>
                <h3 className={classes.headtext}>Status</h3>
              </Grid>
              {values.arr?.map((row) => (
                <React.Fragment key={10 + row.user_id}>
                  <Grid item xs={6}>
                    <p className={classes.ptext}>{row.member_name}</p>
                  </Grid>
                  <Grid item xs={6}>
                    <p
                      className={classes.ptext}
                      style={{
                        textDecoration:
                          row.status === 'cancelled' && 'line-through'
                      }}
                    >
                      {row.is_waitlist ? 'In waitlist' : row.status}
                    </p>
                  </Grid>
                  {/* <Grid item xs={4}>
                    &nbsp;
                  </Grid> */}
                  <div className={classes.hr} />
                </React.Fragment>
              ))}

              {/* <Grid item xs={12}>
                <h3 className={classes.headtext}>Waitlist member</h3>
              </Grid> */}

              <Grid item xs={12}>
                <p
                  className={classes.ptext}
                  style={{
                    textAlign: 'center',
                    fontWeight: 'bold'
                  }}
                >
                  {values.arr?.length} records
                </p>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions
            style={{
              padding: '20px 20px'
            }}
          >
            <JBButton onClick={handleClose} type="primary" autoFocus>
              Close
            </JBButton>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}

ReservationDialog.propTypes = {
  classes: PropType.object.isRequired,
  open: PropType.bool.isRequired,
  handleClose: PropType.func.isRequired,
  values: PropType.array.isRequired
};
export default withStyles(styles)(ReservationDialog);
