export default {
  fetchingUser: 'FETCHING_USER_START',
  fetchdata: 'FETCHED_USER_DATA',
  changeTab: 'CHANGE_MEMBER_TAB',
  errorUser: 'ERROR_FETCHING_USER',
  updateunpaidFil: 'UPDATE_UNPAID_FILTER',
  updatepaidFil: 'UPDATE_PAID_FILTER',
  updateProcessingFil: 'UPDATE_PROCESSING_FIL',
  updateallFil: 'UPDATE_ALL_FILTER',
  updateActiveMemFil: 'UPDATE_ACTIVE_MEMBERSHIP_FILTER',
  updateInActMemFil: 'UPDATE_INACTIVE_MEMBERSHIP_FILTER',
  updateMemHoldFil: 'UPDATE_MEMBERSHIP_HOLD_FILTER',
  updateFailedFil: 'UPDATE_FAILED_FILTER',
  updateOverdueFil: 'UPDATE_OVERDUE_FILTER',
  getMemHold: 'GET_MEMBERSHIP_HOLD_DATA',
  activeMem: 'ACTIVE_MEMBERSHIP',
  inactiveMem: 'INACTIVE_MEMBERSHIP',
  unpaidInvoice: 'GET_UNPAID_INVOICE',
  paidInvoice: 'GET_PAID_INVOICE',
  allInvoice: 'GET_ALL_INVOICE',
  processingInvoice: 'GET_PROCESSING_INVOICE',
  failedInvoice: 'GET_FAILED_INVOICE',
  overdueInvoice: 'GET_OVERDUE_INVOICE',
  getpaymentmethods: 'GET_ALL_PAYMENT_METHODS',
  isFetching: 'DATA_FETCHING_START',
  fetchSucess: 'DATA_FETCHED_SUCCESSFUL',
  Errorfetch: 'ERROR_OCCURED_WHEN_FETCHING',
  getActivityHistory: 'FETCH_ACTIVITY_HISTORY',
  ActivityHistoryFilter: 'ACTIVITY_HISTORY_FILTER',
  ProfileChange: 'USER_DATA_PROFILE_CHANGE',
  updateStatus: 'UPDATE_MEMBERSHIP_STATUS',
  getattendance: 'GET_ATTENDANCE',
  attendanceFilter: 'ATTENDANCE_FILTER',
  updateActivityHistory: 'UPDATE_ACTIVITY_HISTORY'
};
