import React from 'react';
import PropTypes from 'prop-types';
import { ROUTES } from 'src/constant/Constants';
import { Link, Route, Switch, useLocation } from 'react-router-dom';
import { AppBar, Box, Tab, Tabs, ThemeProvider, Typography, withStyles } from '@material-ui/core';
import useChangeTitle from 'src/views/common/CustomHooks/useTitleChange';
import { get } from 'js-cookie';
import { ReactComponent as TabeditIcon } from '../../../assets/image/tabediticon.svg';
import MembershipTabs from './MembershipTabs';

import SettingClassTab from './SettingClassTab';

const themeInstance = {
  overrides: {
    MuiTabs: {
      indicator: {
        backgroundColor: 'transparent'
      }
    },
    MuiTab: {
      root: {
        maxWidth: '100%',
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
        border: '1px solid #EAEAEA',
        borderBottom: '0',
        margin: '0px 5px 0 0',
        '&$selected': {
          backgroundColor: 'white',
          color: '#000 ',
          '&:hover': {
            color: '#37847a'
          },
          '&$textColorPrimary': {
            color: '#01847A'
          },
          '& svg path': {
            stroke: '#01847A'
          }
        }
      },
      wrapper: {
        flexDirection: 'row',
        justifyContent: 'space-around'
      },
      labelIcon: {
        minHeight: '55px'
      },
      textColorPrimary: {
        color: '#545454',
        fontSize: '14px',
        fontWeight: '900',
        lineHeight: '18px',
        letterSpacing: '0.5px'
      }
    }
  }
};

function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
        <Box p={0}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

const styles = () => ({
  // const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1
  },
  STabPanel: {
    backgroundColor: '#fff',
    boxShadow: '4px 0px 16px rgba(37, 40, 43, 0.08)',
    '& .MuiCard-root.Tabulation-mainCard-136': {
      boxShadow: 'none',
      padding: '0 0'
    }
  },
  hr: {
    border: '1px solid rgb(196 196 196 / 0.4)',
    display: 'inline-block',
    width: '100%',
    margin: '10px 0'
  },
  ptext: {
    fontSize: '16px',
    lineHeight: '24px',
    color: '#7F7F7F',
    fontWeight: 'normal'
  },
  cutom_Switch_Label: {
    display: 'inline-block',
    marginRight: '30px',
    verticalAlign: 'middle',
    fontSize: '14px',
    fontWeight: '700',
    color: '#000'
  },
  mainCard: {
    boxShadow: '4px 0px 16px rgba(37, 40, 43, 0.08)',
    borderRadius: '5px',
    background: '#FFFF',
    padding: 20
  }
});
const ClassSettings = ({ classes }) => {
  const location = useLocation();
  const roleid = JSON.parse(get('jbrole') || '{}')?.role_id || 3;
  useChangeTitle('Class Settings');
  const MainTabsValue = location.pathname === ROUTES.CLASSES_SETTINGS ? 0 : 1;
  return (
    <div className={classes.root}>
      <ThemeProvider theme={themeInstance}>
        <AppBar
          position="static"
          style={{
            backgroundColor: 'transparent',
            boxShadow: 'none'
          }}
        >
          <Tabs
            value={MainTabsValue}
            indicatorColor="primary"
            textColor="primary"
            color="primary"
            fullWidth
            // onChange={handleChange}
            aria-label="simple tabs example"
          >
            <Tab
              label="Classes"
              component={Link}
              to={ROUTES.CLASSES_SETTINGS}
              icon={<TabeditIcon />}
            />
            <Tab
              label="Membership"
              component={Link}
              to={
                roleid === 1
                  ? ROUTES.CLASSES_SETTINGS_MEMBERSHIP
                  : ROUTES.CLASSES_SETTINGS_MEMBERSHIP_HOLD_TYPES
              }
              icon={<TabeditIcon />}
            />
          </Tabs>
        </AppBar>
      </ThemeProvider>

      <div>
        <Switch>
          <Route
            path={ROUTES.CLASSES_SETTINGS_MEMBERSHIP}
            render={() => <MembershipTabs />}
          />
          <Route
            path={ROUTES.CLASSES_SETTINGS}
            render={() => <SettingClassTab />}
          />
        </Switch>
      </div>
    </div>
  );
};

ClassSettings.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ClassSettings);
