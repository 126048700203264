import React from 'react';
import PropType from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  DialogTitle,
  withStyles
} from '@material-ui/core';
import { connect } from 'react-redux';
import { openNoti } from 'src/Redux/global/global.actions';
import { getErrorMsg } from 'src/utils/ErrorHandling';
import { defaultWaiver } from 'src/services/Contracts.services';
import JBButton from '../../common/JBButton/JBButton';
import questionmark from '../../../assets/image/questionmark.png';
import { getTempWav } from '../../../Redux/WaiverReducer/WaiverReducer.actions';

const styles = () => ({
  tablebtnmakeDe: {
    width: '100px',
    height: '24px',
    color: '#9B9B9B',
    border: '1px solid #949494',
    borderRadius: '2px',
    fontWeight: 'bold',
    fontSize: '11px',
    lineHeight: '16px'
  },
  pText: {
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '0.002em',
    color: '#7F7F7F',
    padding: '10px 0'
  },
  Dialogtitle: {
    padding: '16px 24px 0'
  },
  DialogActionsBk: {
    padding: '5px 20px 20px'
  }
});

function MakeDefaultDialog({
  classes,
  id,
  name,
  openNotify,
  WaivFilter,
  is_published
}) {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      {/* <JBButton type="outlined" onClick={handleClickOpen}>
        make default
      </JBButton> */}
      {is_published ? (
        <Button className={classes.tablebtnmakeDe} onClick={handleClickOpen}>
          make default
        </Button>
      ) : (
        <></>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div style={{ width: 394 }}>
          <DialogTitle id="alert-dialog-title" className={classes.Dialogtitle}>
            <img
              alt="Plusicon"
              src={questionmark}
              style={{ display: 'inline-block', verticalAlign: 'sub' }}
            />
            <Typography
              color="#000"
              variant="h5"
              component="div"
              className={classes.title}
              style={{ display: 'inline-block', marginLeft: '10px' }}
            >
              Make this waiver as default
            </Typography>
          </DialogTitle>
          <DialogContent>
            <p className={classes.pText}>
              Are you sure you want to make this waiver as default.
            </p>
          </DialogContent>
          <DialogActions className={classes.DialogActionsBk}>
            <Button
              variant="outlined"
              onClick={handleClose}
              style={{
                height: '48px',
                color: '#7F7F7F',
                fontWeight: 'bold'
              }}
            >
              Cancel
            </Button>
            <JBButton
              onClick={() => {
                defaultWaiver(id)
                  .then(() => {
                    WaivFilter();
                    openNotify(`Waiver ${name} is set as default waiver`);
                    setOpen(false);
                  })
                  .catch((err) => openNotify(getErrorMsg(err), 'error'));
              }}
              type="primary"
            >
              Confirm
            </JBButton>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}
// MakeDefaultDialog.defaultProps = {
//   handleHistory: null
// };

MakeDefaultDialog.propTypes = {
  classes: PropType.object.isRequired,
  // handleHistory: PropType.string,
  id: PropType.string.isRequired,
  name: PropType.string.isRequired,
  WaivFilter: PropType.func.isRequired
};

const mapToDispatch = (dispatch) => ({
  WaivFilter: (filter, data) => dispatch(getTempWav(filter, data)),
  openNotify: (msg, type) => dispatch(openNoti(msg, type))
});
export default connect(
  null,
  mapToDispatch
)(withStyles(styles)(MakeDefaultDialog));
