import React, { useEffect, useState, useContext } from 'react';
import { AlliesProfile } from 'src/Context/AlliesContext';
import {
  Grid,
  Box,
  withStyles,
  Breadcrumbs,
  Typography
} from '@material-ui/core';
import {
  getAlliesProfileById,
  getAffilatePlan,
  getAffiliateDiscounts,
  getAffilateTransactionCharges
} from 'src/services/masterHQ.services';
import {
  fetchingUser,
  fetchedUser,
  errorUser,
  fetchPlanDetails,
  getPlanDetails,
  errorPlanDetails,
  fetchDiscounts,
  getDiscounts,
  errorDiscount,
  fetchTransatcions,
  getTransactions,
  errorTransactions
} from 'src/Context/MasterHQAllies/masterhqallies.actions';
import PropType from 'prop-types';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { getErrorMsg } from 'src/utils/ErrorHandling';
// import Avatar from '@material-ui/core/Avatar';
// import DefaultUser from 'src/assets/image/defaultUser.png';
// import { ReactComponent as EdiIcon } from 'src/assets/image/edi-icon.svg';
import CancelDialogTwo from 'src/views/common/CommonDialogBox/CancelDialogTwo';
import JBButton from 'src/views/common/JBButton/JBButton';
// import Action from './Action';
// import ImageUpload from './ImageUpload/ImageUpload';
// import DeletePhotoDia from './ImageUpload/DeletePhotoDia';
import NotFoundView from 'src/views/errors/NotFoundView';
import LoadingView from 'src/views/common/loading';
import useChangeTitle from 'src/views/common/CustomHooks/useTitleChange';
import AffiliateProfileTabs from './AffiliateProfileTabs';
import styles from './Profile.style';
// import DeactivateMemberDialog from './DeactivateMemberDialog';
import DeactivateAllyDialog from './DeactivateAllyDialog';
import ReactivateAlly from './ReactivateAlly';

const AffiliateProfile = (props) => {
  const [{ userdetails }, dispatch] = useContext(AlliesProfile); // eslint-disable-line
  const { classes } = props;
  const location = useLocation();
  const { affiliateId } = queryString.parse(location.search);
  const [cancelDialog, setcancelDialog] = useState(false);
  const [activation, setActivation] = useState(() => ({
    deactivation: false,
    reactivation: false
  }));
  useChangeTitle('Allies | Jungle Alliance');
  useEffect(() => {
    dispatch(fetchingUser());
    getAlliesProfileById(affiliateId)
      .then((response) => dispatch(fetchedUser(response)))
      .catch((error) => {
        dispatch(errorUser(getErrorMsg(error)));
      });
    dispatch(fetchPlanDetails());
    getAffilatePlan()
      .then((res) => dispatch(getPlanDetails(res)))
      .catch((err) => dispatch(errorPlanDetails(err)));
    dispatch(fetchDiscounts);
    getAffiliateDiscounts(affiliateId)
      .then((res) => dispatch(getDiscounts(res)))
      .catch((err) => dispatch(errorDiscount(err)));
    dispatch(fetchTransatcions());
    getAffilateTransactionCharges(affiliateId)
      .then((res) => dispatch(getTransactions(res[0])))
      .catch((err) => dispatch(errorTransactions(err)));
  }, [dispatch, affiliateId]);

  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleActivation = () => {
    if (userdetails?.data?.status) {
      setActivation(() => ({
        deactivation: true,
        reactivation: false
      }));
    } else {
      setActivation(() => ({
        deactivation: false,
        reactivation: true
      }));
    }
  };

  const { isFetching, data, error } = userdetails;

  if (error || !affiliateId) return <NotFoundView />;
  if (isFetching || !data) return <LoadingView />;

  return (
    <div className={classes.root}>
      <div className="title-bk">
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={0}
          style={{ margin: '0 0 20px' }}
        >
          <Grid item xs={8}>
            <Breadcrumbs aria-label="breadcrumb">
              <div
                color="inherit"
                onClick={() => setcancelDialog(true)}
                style={{
                  cursor: 'pointer'
                }}
              >
                {' '}
                Allies
              </div>
              <div color="inherit">
                {`${data.first_name} ${data.last_name}`}
              </div>
              <Typography variant="h3" color="Primary">
                {!value ? (
                  <span>General Information</span>
                ) : (
                  <span>Payment & billing</span>
                )}
              </Typography>
            </Breadcrumbs>
          </Grid>
          <Grid
            item
            xs={4}
            className={classes.btnBlock}
            style={{ justifyContent: 'flex-end', display: 'flex' }}
          >
            {/* <JBButton type="outlined">Active</JBButton> */}
            {/* <DeactivateMemberDialog /> */}
            <JBButton
              type={data.status === 1 ? 'delete' : ''}
              onClick={handleActivation}
            >
              {data.status === 1 ? 'DEACTIVATE' : 'REACTIVATE'}
            </JBButton>
          </Grid>
        </Grid>
      </div>

      <Box className={classes.memberWrapper}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={0}
          style={{ margin: '0 0 20px', paddingTop: '0px' }}
        >
          <Grid item xs={4}>
            <Box className={classes.userInfo}>
              <Box className={classes.userWrapper}>
                <Box className={classes.media} style={{ alignItems: 'center' }}>
                  {/* user image no-needed by keval */}
                  {/* <Box display="flex" className={classes.proimage}>
                    <img
                      src={DefaultUser}
                      onError={handleErrorImg}
                      alt="UserIcon"
                      style={{
                        verticalAlign: 'sub'
                      }}
                      className={classes.avt}
                    />
                    <Box
                      css={{
                        right: '0',
                        position: 'absolute'
                      }}
                      className="EditImageBk"
                    >
                      <Button
                        color="primary"
                        // onClick={() => seteditImage(true)}
                        style={{
                          minWidth: 'auto',
                          padding: '0'
                        }}
                      >
                        <EdiIcon fontSize="small" />
                      </Button>
                    </Box>
                    {/* {editImage && (
                      <ImageUpload
                        open={editImage}
                        handleClose={() => seteditImage(false)}
                        profile_pic={data.profile_pic}
                        handleSave={(val) => {
                          dispatch(ProfileChange(val));
                          if (data.status === 1) {
                            getActiveMembers();
                          } else if (data.staus === 0) {
                            getInActiveMembers();
                          }
                        }}
                      />
                    )} */}
                  {/* {data.profile_pic && (
                      <DeletePhotoDia
                        reducer={[state, dispatch]}
                        userId={memberId}
                      />
                    )}
                  </Box> */}
                  <Box className={classes.mediabody}>
                    <h3 style={{ margin: '0 0 8px' }}>{data?.gym_name}</h3>
                    <div>
                      <label className={classes.titlelabel}>Ally since</label>
                      <span className={classes.lbinfo}>
                        {data?.member_since}
                      </span>
                    </div>
                  </Box>
                  {/* <div>
                    <Button variant="contained" className={classes.userStatus}>
                      Active
                    </Button>
                  </div> */}
                </Box>
              </Box>
            </Box>
          </Grid>
          <Box style={{ position: 'relative', top: '-26px', right: '15px' }}>
            <div className={classes.vhr} />
          </Box>
          <Grid item xs={4}>
            <Box mb={1}>
              <ul className={classes.infoul}>
                <li>
                  <label className={classes.titlelabel}>Email address</label>
                </li>
                <li>
                  <span className={classes.lbinfo}>{data?.email}</span>
                </li>
              </ul>
            </Box>
            <Box>
              <ul className={classes.infoul}>
                <li>
                  <label className={classes.titlelabel}>Mobile number</label>
                </li>
                <li>
                  <span className={classes.lbinfo}>{data?.mobile_no}</span>
                </li>
              </ul>
            </Box>
          </Grid>
          <Box style={{ position: 'relative', top: '-26px', right: '15px' }}>
            <div className={classes.vhr} />
          </Box>
          <Grid item xs={4}>
            <Box mb={1}>
              <ul className={classes.infoul}>
                <li>
                  <label className={classes.titlelabel}>Contact Person</label>
                </li>
                <li>
                  <span className={classes.lbinfo}>
                    {`${data.first_name} ${data.last_name}`}
                  </span>
                </li>
              </ul>
            </Box>
            <Box>
              <ul className={classes.infoul}>
                <li>
                  <label className={classes.titlelabel}>
                    Subscription Plan
                  </label>
                </li>
                <li>
                  <span className={classes.lbinfo}>{data?.plan_name}</span>
                </li>
              </ul>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box style={{ marginTop: '-55px' }}>
        <AffiliateProfileTabs
          Tabsdata={value}
          handleChangeData={handleChange}
        />
      </Box>
      <CancelDialogTwo
        setOpen={cancelDialog}
        handleClose={() => setcancelDialog(false)}
      />
      {activation.deactivation && (
        <DeactivateAllyDialog
          setOpen={activation.deactivation}
          subscription={data?.subscription_date}
          handleClose={() =>
            setActivation(() => ({ deactivation: false, reactivation: false }))
          }
        />
      )}
      {activation.reactivation && (
        <ReactivateAlly
          open={activation.reactivation}
          handleClose={() =>
            setActivation(() => ({ deactivation: false, reactivation: false }))
          }
        />
      )}
    </div>
  );
};

AffiliateProfile.propTypes = {
  classes: PropType.object.isRequired
  // location: PropType.object.isRequired,
  // openNotify: PropType.func.isRequired,
};

export default withStyles(styles)(AffiliateProfile);
