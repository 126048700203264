import React, { useEffect } from 'react';
import {
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  withStyles,
  Grid,
  InputLabel
} from '@material-ui/core';
import PropType from 'prop-types';
import FormControl from 'src/views/common/FormControl/formControl';
import GetPrice from 'src/views/common/CurrencySymbol/GetPrice';
import styles from '../../../table.style';
import {
  changeinimemfee,
  changeinisetcost,
  changerenwalfee,
  getIniDiscount,
  getinimemcost,
  getinisetupcost,
  getIniSubTotal,
  getIniTotalDue,
  getrenmemcost,
  getRenSubTotal,
  getRenTax,
  gettaxtotal,
  gettotalrendiscount,
  updateIniTaxes,
  updaterenTaxes
} from './FinalPrice.utils';
import {
  changeinifulltax,
  chngememfee,
  chngerenwalfee,
  chngesetupfee,
  changerenfulltax
} from '../NewMembership.actions';

function FinalPricingTable({ classes, reducer, isEditable }) {
  const [state, dispatch] = reducer;
  const initialprice = state.data.payment_data.initial_commitment_pricing;

  useEffect(() => {
    dispatch(changeinifulltax(updateIniTaxes(state.data)));
    // eslint-disable-next-line
  }, [
    initialprice.full_membership_fees,
    initialprice.week_membership_fees,
    initialprice.full_setup_fees,
    initialprice.week_setup_fees
  ]);

  useEffect(() => {
    if (state.data.payment_data.auto_renew) {
      dispatch(changerenfulltax(updaterenTaxes(state.data)));
    }
    // eslint-disable-next-line
  }, [
    state.data.payment_data.auto_renewal_pricing.full_membership_fees,
    state.data.payment_data.auto_renewal_pricing.week_membership_fees,
    state.data.payment_data.auto_renew
  ]);

  const isrenewal = Boolean(state.data.payment_data.auto_renew);

  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      spacing={0}
      style={{ margin: '10px 0 20px' }}
    >
      <TableContainer
        style={{ border: '1px solid #A8A8A8', borderRadius: '5px' }}
      >
        <Table className={classes.table} aria-label="simple table">
          <TableHead className={classes.table_th}>
            <TableRow>
              <TableCell className={classes.TableCell} style={{ width: '30%' }}>
                Name
              </TableCell>
              <TableCell className={classes.TableCell}>Payment plan</TableCell>
              {isrenewal && (
                <TableCell className={classes.TableCell}>
                  Payment renewal
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell className={classes.Table_td}>
                Payment option type
              </TableCell>
              <TableCell className={classes.Table_td}>
                {state.data.initial_pricing === 'weekly'
                  ? 'Weekly'
                  : 'Pay in Full'}
              </TableCell>
              {isrenewal && (
                <TableCell className={classes.Table_td}>
                  {state.data.renewal_pricing === 'weekly'
                    ? 'Weekly'
                    : 'Pay in Full'}
                </TableCell>
              )}
            </TableRow>
            <TableRow>
              <TableCell className={classes.Table_td} required>
                <InputLabel
                  htmlFor="age-required"
                  className={classes.cutom_Table_Label}
                >
                  Setup fee
                </InputLabel>
              </TableCell>
              <TableCell
                className={classes.Table_td}
                style={{
                  padding: '3px 15px 7px'
                }}
              >
                {isEditable ? (
                  <FormControl
                    control="input"
                    label=""
                    placeholder="0"
                    required
                    maxLength={10}
                    value={getinisetupcost(state.data)}
                    style={{ margin: '0 0' }}
                    onChange={(e) => {
                      const actionkey = changeinisetcost(state.data);
                      dispatch(chngesetupfee(actionkey, e.target.value));
                    }}
                  />
                ) : (
                  <GetPrice value={getinisetupcost(state.data)} />
                )}
              </TableCell>
              {isrenewal && (
                <TableCell className={classes.Table_td}>&nbsp;</TableCell>
              )}
            </TableRow>
            <TableRow>
              <TableCell className={classes.Table_td} required>
                <InputLabel
                  htmlFor="age-required"
                  required
                  className={classes.cutom_Table_Label}
                >
                  fee
                </InputLabel>
              </TableCell>
              <TableCell
                className={classes.Table_td}
                style={{
                  padding: '3px 15px 7px'
                }}
              >
                {isEditable ? (
                  <FormControl
                    control="input"
                    label=""
                    placeholder="40"
                    required
                    maxLength={10}
                    value={getinimemcost(state.data)}
                    onChange={(e) => {
                      const actionkey = changeinimemfee(state.data);
                      dispatch(chngememfee(actionkey, e.target.value));
                    }}
                    style={{ margin: '0 0' }}
                  />
                ) : (
                  <GetPrice value={getinimemcost(state.data)} />
                )}
              </TableCell>
              {isrenewal && (
                <TableCell
                  className={classes.Table_td}
                  style={{
                    padding: '3px 15px 7px'
                  }}
                >
                  {isEditable ? (
                    <FormControl
                      control="input"
                      label=""
                      placeholder="0"
                      value={getrenmemcost(state.data)}
                      required
                      maxLength={10}
                      onChange={(e) => {
                        const actionkey = changerenwalfee(state.data);
                        dispatch(chngerenwalfee(actionkey, e.target.value));
                      }}
                      style={{ margin: '0 0' }}
                    />
                  ) : (
                    <GetPrice value={getrenmemcost(state.data)} />
                  )}
                </TableCell>
              )}
            </TableRow>
            <TableRow>
              <TableCell className={classes.Table_td}>Subtotal</TableCell>
              <TableCell className={classes.Table_td}>
                <GetPrice value={getIniSubTotal(state.data)} />
              </TableCell>
              {isrenewal && (
                <TableCell className={classes.Table_td}>
                  <GetPrice value={getRenSubTotal(state.data)} />
                </TableCell>
              )}
            </TableRow>
            <TableRow>
              <TableCell className={classes.Table_td}>Total discount</TableCell>
              <TableCell className={classes.Table_td}>
                <GetPrice value={getIniDiscount(state.data)} />
              </TableCell>
              {isrenewal && (
                <TableCell className={classes.Table_td}>
                  <GetPrice value={gettotalrendiscount(state.data)} />
                </TableCell>
              )}
            </TableRow>
            <TableRow>
              <TableCell className={classes.Table_td}>Total Due</TableCell>
              <TableCell className={classes.Table_td}>
                <GetPrice value={getIniTotalDue(state.data)} />
              </TableCell>
              {isrenewal && (
                <TableCell className={classes.Table_td}>
                  <GetPrice value={getRenSubTotal(state.data)} />
                </TableCell>
              )}
            </TableRow>
            <TableRow>
              <TableCell className={classes.Table_td}>GST</TableCell>
              <TableCell className={classes.Table_td}>
                <GetPrice value={gettaxtotal(state.data)} />
              </TableCell>
              {isrenewal && (
                <TableCell className={classes.Table_td}>
                  <GetPrice value={getRenTax(state.data)} />
                </TableCell>
              )}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}

FinalPricingTable.defaultProps = {
  isEditable: true
};

FinalPricingTable.propTypes = {
  classes: PropType.object.isRequired,
  reducer: PropType.array.isRequired,
  isEditable: PropType.bool
};

export default withStyles(styles)(FinalPricingTable);
