import React, { memo } from 'react';
import PropType from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  DialogTitle,
  withStyles
} from '@material-ui/core';
// import { ROUTES } from 'src/constant/Constants';
import X from 'src/assets/image/x.png';

const styles = () => ({
  Deletebtn: {
    background: '#FB4E4E',
    boxShadow: '0px 8px 16px rgba(251, 78, 78, 0.16)',
    borderRadius: '2px',
    height: '48px',
    padding: '10px 15px',
    color: '#fff',
    fontWeight: '700',
    '&:hover': {
      background: '#FB4E4E'
    }
  },
  Dialogtitle: {
    padding: '16px 24px 0'
  },
  DialogActionsBk: {
    padding: '5px 20px 20px'
  },
  APIBtnDel: {
    width: '40px',
    height: '40px',
    minWidth: '40px',
    border: '1px solid #e5e5e5',
    boxSizing: 'border-box',
    borderRadius: '50%'
  }
});

function WebDeleteDiaload({ classes, open, handleClose, handleDelete }) {
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div style={{ width: 394 }}>
          <DialogTitle id="alert-dialog-title" className={classes.Dialogtitle}>
            <img
              alt="Plusicon"
              src={X}
              style={{ display: 'inline-block', verticalAlign: 'sub' }}
            />
            <Typography
              color="#000"
              variant="h5"
              component="div"
              className={classes.title}
              style={{ display: 'inline-block', marginLeft: '10px' }}
            >
              Delete
            </Typography>
          </DialogTitle>
          <DialogContent>
            <p
              style={{
                fontWeight: 'normal',
                fontSize: '14px',
                lineHeight: '18px',
                letterSpacing: '0.002em',
                color: '#7F7F7F',
                padding: '10px 0'
              }}
            >
              Are you sure you want to delete?
            </p>
          </DialogContent>
          <DialogActions className={classes.DialogActionsBk}>
            <Button
              variant="outlined"
              onClick={handleClose}
              style={{
                height: '48px',
                color: '#7F7F7F',
                fontWeight: 'bold'
              }}
            >
              Cancel
            </Button>
            <Button className={classes.Deletebtn} onClick={handleDelete}>
              delete
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </>
  );
}

WebDeleteDiaload.propTypes = {
  classes: PropType.object.isRequired,
  open: PropType.bool.isRequired,
  handleClose: PropType.func.isRequired,
  handleDelete: PropType.func.isRequired
};

export default withStyles(styles)(memo(WebDeleteDiaload));
