import { AttendanceTypes } from './AttendanceReducer';
import { MembershipType, ShowClassType } from './Attendance.data';

export const fetchData = (payload) => ({
  type: AttendanceTypes.getData,
  payload
});

export const changeMemVis = () => ({
  type: AttendanceTypes.chngememvis
});

export const chngeplanMem = (payload) => ({
  type: AttendanceTypes.chngeplanmem,
  payload
});

export const changesessMem = (payload) => ({
  type: AttendanceTypes.chngesessmem,
  payload
});

export const changeclass = (payload) => ({
  type: AttendanceTypes.showclasses,
  payload
});

export const changeContract = () => ({
  type: AttendanceTypes.chngecontrt
});

export const changewaiver = () => ({
  type: AttendanceTypes.chngewaiver
});

export const getAllData = (data) => {
  const value = {
    membership_enforcement: {
      visibility: JSON.parse(data.membership_enforcement.visibility),
      limited_plan_membership:
        MembershipType.filter(
          (res) =>
            res.id === data.membership_enforcement.limited_plan_membership
        )[0] || null,
      session_membership:
        MembershipType.filter(
          (res) => res.id === data.membership_enforcement.session_membership
        )[0] || null,
      show_classes_in_athlete_app:
        ShowClassType.filter(
          (res) =>
            res.id === data.membership_enforcement.show_classes_in_athlete_app
        )[0] || null
    },
    contract_enforcement: data.contract_enforcement,
    waiver_enforcement: data.waiver_enforcement
  };
  return value;
};
