/* eslint-disable operator-linebreak */
/* eslint-disable indent */
import React, { useState, useEffect, useCallback } from 'react';
import PropType from 'prop-types';
import { useDispatch } from 'react-redux';
import { getActiveMem } from 'src/services/Members.module';
import {
  getAttendanceByClass,
  getClassLoginVanguard,
  removeUserFromClass
} from 'src/services/chalkBoard.services';
import { openNoti } from 'src/Redux/global/global.actions';
import { getErrorMsg } from 'src/utils/ErrorHandling';
import { ROUTES } from 'src/constant/Constants';
import { Link, useLocation, useHistory } from 'react-router-dom';
import moment from 'moment';
import clsx from 'clsx';
import {
  Typography,
  Button,
  AppBar,
  Toolbar,
  IconButton,
  withStyles,
  Card,
  Grid,
  Box,
  InputLabel
} from '@material-ui/core';
import VanguardMemberLoadingView from 'src/views/common/loading/VanguardMemberLoadingView';
import useChangeTitle from 'src/views/common/CustomHooks/useTitleChange';
import { ReactComponent as BackArrow } from '../../../assets/image/Vanguard/icon_back_vanguard.svg';
// import LeavePagePopup from '../LeavepagePopup';
import styles from './chalkboardIndex.style';
import JBVanguardButton from '../../common/JBVanguardButton/JBButton';
import { ReactComponent as DownIcon } from '../../../assets/image/Vanguard/downIcon.svg';
import CloseIcon from '../../../assets/image/Vanguard/x.png';
import defaultUser from '../../../assets/image/defaultUser.png';

import CustomAutocomplete from '../../common/CustomAutocomplete/commonAutocomplete';
import WarningpagePopup from './WarningpagePopup';

const ChalkboardSelectmember = ({ classes }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  useChangeTitle('Chalkboard | Jungle Alliance');
  const [LeavePageopen, setLeavePageopen] = useState(false);
  const [memberData, setMemberData] = useState(null);
  const [selectedMember, setSelectedMember] = useState(null);
  const [signedInMembers, setSignedInMembers] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [isLoading, setIsLoading] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const history = useHistory();
  const [memberID, setMemberID] = useState(null);
  const currentDate = moment(Date.now()).format('YYYY-MM-DD');
  const getSignedInMembers = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await getAttendanceByClass(location.state.classId);
      setSignedInMembers(response);
    } catch (err) {
      if (getErrorMsg(err) === 'No Sign In and Reservation Found') {
        setSignedInMembers({ reserved_users: [], sign_in_users: [] });
      }
      dispatch(openNoti(getErrorMsg(err), 'error'));
    } finally {
      setIsLoading(false);
    }
  }, [location, dispatch]);

  const getAllActiveMembers = useCallback(async () => {
    try {
      const response = await getActiveMem();
      setMemberData(response);
    } catch (err) {
      dispatch(openNoti(getErrorMsg(err), 'error'));
    }
  }, [dispatch]);

  const handleSignInMember = async (user_id, class_id, login_date) => {
    setIsLoading(true);
    setIsProcessing(true);
    try {
      const response = await getClassLoginVanguard({
        user_id,
        class_id,
        login_date: moment(login_date).format('YYYY-MM-DD'),
        allow_login: 1
      });
      if (response.success) {
        dispatch(openNoti(response.message, 'success'));
        getSignedInMembers();
        setIsProcessing(false);
      }
      if (response.data.warnings.length > 0) {
        dispatch(openNoti(response.data.warnings, 'warning'));
        setIsProcessing(false);
      }
      setSelectedMember(null);
    } catch (err) {
      dispatch(openNoti(getErrorMsg(err), 'error'));
      setSelectedMember(null);
      setIsProcessing(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRemoveMember = async (id) => {
    setIsLoading(true);
    try {
      const response = await removeUserFromClass(id);
      if (response.success) {
        setLeavePageopen(false);
        dispatch(openNoti(response.message, 'success'));
        getSignedInMembers();
      }
    } catch (err) {
      dispatch(openNoti(getErrorMsg(err), 'error'));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (location.state) {
      getAllActiveMembers();
      getSignedInMembers();
    }
  }, [dispatch, getSignedInMembers, getAllActiveMembers, location]);

  // if (!location.state) {
  //   history.push({ pathname: ROUTES.VANGUARD_CHALKBOARD });
  // }

  return (
    <div>
      <AppBar className={classes.appBar}>
        <Toolbar className={classes.appToolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="close"
            onClick={() => {
              // setLeavePageopen(true);
              history.goBack();
            }}
          >
            <BackArrow />
          </IconButton>
          <h5 className={classes.headerTitle}>Chalkboard</h5>
          <Button
            color="inherit"
            className={classes.HomeButton}
            component={Link}
            to={ROUTES.VANGUARD}
          >
            Home
          </Button>
        </Toolbar>
      </AppBar>

      <Card className={classes.MainCard}>
        <Grid
          container
          xs={10}
          justify="center"
          style={{
            margin: '50px auto'
          }}
        >
          <Box
            css={{
              textAlign: 'center'
            }}
          >
            <h4
              className={classes.mainTitle}
              style={{
                marginBottom: '40px'
              }}
            >
              SIGN-IN MEMBER
            </h4>
            <h4 className={classes.mainTitle}>
              {location?.state?.program_name},
            </h4>
            <h4 className={classes.mainTitle}>{location?.state?.dateInfo}</h4>
          </Box>
        </Grid>
        <Box className={classes.SigninMemberContent}>
          <Grid
            container
            xs={10}
            style={{
              margin: '50px auto',
              alignItems: 'end',
              justifyContent: 'space-between',
              display: 'flex',
              position: 'relative'
            }}
          >
            <Grid
              item
              xs={9}
              sm={8}
              md={9}
              className={classes.cutom_vanguard_FormControl}
              style={{
                position: 'relative'
              }}
            >
              <InputLabel
                htmlFor="age-required"
                className={classes.cutom_select_Label}
                style={{ margin: '0px 0 6px' }}
              >
                Select member
              </InputLabel>
              <CustomAutocomplete
                holder="Select"
                value={memberData}
                disabled={location?.state?.isPast}
                Options="name"
                data={selectedMember || null}
                popupIcon={<DownIcon />}
                Change={(val) => setSelectedMember(val)}
                disablePortal
                // disableClearable
                // clearOnBlur
                className={clsx(
                  classes.cutom_vanguard_Autocomplete,
                  'SelectmemberDrop'
                )}
              />
            </Grid>
            <JBVanguardButton
              type="primary"
              // disabled
              style={{
                width: '188px',
                minWidth: '188px',
                height: '70px',
                position: 'absolute',
                bottom: '0',
                right: '10px'
              }}
              // className={classes.disabledBtn}
              disabled={
                !selectedMember || location?.state?.isPast || isProcessing
              }
              onClick={() =>
                handleSignInMember(
                  selectedMember.id,
                  location?.state?.classId,
                  currentDate
                )
              }
            >
              Sign In
            </JBVanguardButton>
          </Grid>
          <Box>
            <Grid
              container
              xs={10}
              style={{
                margin: '50px auto'
              }}
            >
              {isLoading ? (
                <>
                  <VanguardMemberLoadingView />
                </>
              ) : signedInMembers &&
                signedInMembers.sign_in_users?.length !== 0 ? (
                signedInMembers.sign_in_users?.map((row) => (
                  <Box className={classes.userInBox} key={row.id}>
                    <img
                      src={row.profile_pic || defaultUser}
                      className={
                        !row.profile_pic
                          ? classes.defaultAvatar
                          : classes.userimage
                      }
                      alt="UserImg"
                    />
                    <Box className={classes.userInfo}>
                      <h4>{row.name}</h4>
                      <Box className={classes.ActionBtn}>
                        <Button
                          color="inherit"
                          // onClick={() => handleRemoveMember(row.id)}
                          onClick={() => {
                            setLeavePageopen(true);
                            setMemberID(row.id);
                          }}
                        >
                          <img src={CloseIcon} alt="Icon" />
                        </Button>
                        {/* <Button color="inherit">
                        <img src={EditIcon} alt="Icon" />
                      </Button> */}
                      </Box>
                    </Box>
                  </Box>
                ))
              ) : (
                <Typography className={classes.wodDetails}>
                  No Attendance to show
                </Typography>
              )}
              {!signedInMembers ? null : signedInMembers?.reserved_users
                  .length === 0 ? null : (
                // eslint-disable-next-line react/jsx-indent
                <Box className={classes.ReservedBox}>
                  <h3>Reserved Members</h3>
                  {signedInMembers.reserved_users.map((user) => (
                    <Box className={classes.userInBox} key={user.id}>
                      <img
                        src={user.profile_pic || defaultUser}
                        className={classes.defaultAvatar}
                        alt="UserImg"
                      />
                      <Box className={classes.userInfo}>
                        <h4>{user.name}</h4>
                        <Box className={classes.ActionBtn}>
                          <Button
                            color="inherit"
                            // onClick={() => handleRemoveMember(row.id)}
                            onClick={() => {
                              setLeavePageopen(true);
                              setMemberID(user.id);
                            }}
                          >
                            <img src={CloseIcon} alt="Icon" />
                          </Button>
                          {/* <Button color="inherit">
                          <img src={EditIcon} alt="Icon" />
                        </Button> */}
                        </Box>
                        {user.is_waitlist === 1 &&
                        user.status === 'reserved' ? (
                          <p style={{ marginTop: '7px' }}>In waitlist</p>
                        ) : (
                          <JBVanguardButton
                            type="primary"
                            CutomClass={classes.ReservedSignINBtn}
                            onClick={() =>
                              handleSignInMember(
                                user.user_id,
                                user.class_id,
                                user.attend_date
                              )
                            }
                          >
                            Sign In
                          </JBVanguardButton>
                        )}
                      </Box>
                    </Box>
                  ))}
                </Box>
              )}
            </Grid>
          </Box>
        </Box>
        <Box className={classes.actionBt}>
          <Grid
            container
            xs={10}
            justify="flex-end"
            style={{
              margin: '0px auto'
            }}
          >
            <JBVanguardButton
              type="outlined"
              style={{
                width: '240px',
                minWidth: '240px',
                height: '70px'
              }}
              component={Link}
              to={{
                pathname: ROUTES.VANGUARD_CHALKBOARD_WORKOUT,
                state: {
                  classId: location?.state?.classId,
                  program_name: location.state?.program_name,
                  dateInfo: location?.state?.dateInfo
                }
              }}
            >
              View workout
            </JBVanguardButton>
          </Grid>
        </Box>
      </Card>
      {/* <LeavePagePopup
        setOpen={LeavePageopen}
        handleClose={() => setLeavePageopen(false)}
        // handleHistory={ROUTES.VANGUARD}
      /> */}
      <WarningpagePopup
        setOpen={LeavePageopen}
        Dialogtitle="Remove member"
        handleClose={() => setLeavePageopen(false)}
        handleBack={() => {
          handleRemoveMember(memberID);
        }}
      />
    </div>
  );
};

ChalkboardSelectmember.propTypes = {
  classes: PropType.object.isRequired
};
export default withStyles(styles)(ChalkboardSelectmember);
