import React from 'react';
import Select from 'react-select';
import PropType from 'prop-types';
import Userimage from 'src/assets/image/user.png';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  selebk: {
    display: 'inline-flex',
    alignItems: 'center'
  },
  seleimage: {
    alignTtems: 'center',
    verticalAlign: 'middle'
  },
  myselect: {
    display: 'block',
    '& .css-yk16xz-control, .css-1pahdxg-control': {
      minHeight: '46px',
      padding: '0 10px',
      borderRadius: '2px',
      borderColor: '#7F7F7F',
      border: '1px solid #a9a9a9'
    }
  },
  '&.css-9gakcf-option': {
    backgroundColor: 'red'
  }
});

const customStyles = {
  control: () => ({
    // backgroundColor: 'white',
    display: 'inline-flex',
    width: '100%',
    minHeight: '46px',
    padding: '0 10px',
    borderRadius: '2px',
    borderColor: '#7F7F7F',
    border: '1px solid #a9a9a9'
  }),
  option: () => ({
    backgroundColor: 'transparent',
    padding: '10px 10px',
    ':hover': {
      backgroundColor: '#01847A',
      color: '#fff'
    }
  }),
  menu: () => ({
    border: '1px solid #a9a9a9',
    margin: '5px 0',
    top: '100%',
    backgroundColor: '#fff',
    borderRadius: '2px',
    marginBottom: '8px',
    marginTop: '8px',
    position: 'absolute',
    width: '100%',
    zIndex: '1',
    boxSizing: 'border-box'
  }),
  menuList: () => ({
    height: '150px',
    overflowY: 'auto'
  })
};

// https://codesandbox.io/embed/reactselect-formatoptionlabel-bde1q

const formatOptionLabel = ({ name, Abbr, coach_profile_pic }) => {
  const ErrorImage = (e) => {
    e.target.src = Userimage;
  };
  return (
    <div style={{ display: 'inline-flex', alignItems: 'center' }}>
      <div>
        {coach_profile_pic && (
          <img
            src={coach_profile_pic}
            alt="img"
            onError={ErrorImage}
            style={{
              alignTtems: 'center',
              verticalAlign: 'middle',
              borderRadius: '50%',
              height: '30px',
              width: '30px',
              marginRight: '10px',
              objectFit: 'cover'
            }}
          />
        )}
      </div>
      <div>{name}</div>
      <div style={{ marginLeft: '10px', color: '#ccc' }}>{Abbr}</div>
    </div>
  );
};
const JBSelect = ({ options, classes, ...props }) => {
  return (
    <Select
      formatOptionLabel={formatOptionLabel}
      options={options}
      isClearable
      {...props} // eslint-disable-line
      getOptionValue={(opt) => opt.id}
      getOptionLabel={(opt) => opt.name}
      className={classes.myselect}
      styles={customStyles}
    />
  );
};

JBSelect.propTypes = {
  classes: PropType.object.isRequired,
  // label: PropType.string.isRequired,
  // value: PropType.string.isRequired,
  // customAbbreviation: PropType.string.isRequired,
  // img: PropType.string.isRequired,
  options: PropType.object.isRequired
};

export default withStyles(styles)(JBSelect);
