import React, {
  useContext,
  useEffect,
  useReducer,
  useState
  //  useContext
} from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Card,
  withStyles,
  Breadcrumbs,
  Typography,
  Grid
} from '@material-ui/core';
import PropType from 'prop-types';
import { MembershipTemplate as MembershipContext } from 'src/Context/MembershipTemplate/MembershipTemplateContext';
import { getGymSetup } from 'src/Redux/GymSetupReducer/GymSetup.actions';
import {
  addpaymentmethod,
  changeprogramaccess
} from 'src/Context/MembershipTemplate/membershiptemplate.actions';
import CancelDialog from 'src/views/common/CommonDialogBox/CancelDialog';
import { openNoti } from 'src/Redux/global/global.actions';
import { createMemTemplate } from 'src/services/Membersip.services';
import { getErrorMsg } from 'src/utils/ErrorHandling';
import { ReactComponent as PlanDetails } from 'src/assets/image/JBSteppericon/membership/PlanDetails.svg';
import { ReactComponent as PaymentPlans } from 'src/assets/image/JBSteppericon/membership/PaymentPlans.svg';
import ColorlibStepIcon from 'src/views/common/JBGridStepper/ColorlibStepIcon';
import JBStepper from 'src/views/common/JBGridStepper';
import useChangeTitle from 'src/views/common/CustomHooks/useTitleChange';
import CancelDialogTwo from '../../../common/CommonDialogBox/CancelDialogTwo';
import styles from '../MembershipTemplate.style';
import { getAllData } from '../SessionTemplates/SessionTemplate.utils';
import {
  // checkValidNum,
  checkValidString
  // validateFloat,
  // validPrice
} from '../../../../utils/validations';
import { dropInValidSteps, includeTaxinitials } from '../MemTemplate.utils';
import '../CutomSelect.css';
import DropinDetailStep from './DropinDetailStep';
import DropinPaymentStep from './DropinPaymentStep';
import {
  sessionReducer,
  initialValue
} from '../../PaymentPlan/SessionPaymentPlan/Sessionpaymentplan.Reducer';
import { validStep2 } from '../../PaymentPlan/SessionPaymentPlan/Sessionpayment.validation';

function getSteps() {
  return [
    'Drop In Details',
    'Payment Details'
    // 'Program Access',
    // 'Contract Templates'
  ];
}

const DropinTemplate = ({
  classes,
  OpenNoti,
  getGymSteps,
  gymSteps,
  allTaxes,
  programData
}) => {
  const [activeStep, setActiveStep] = useState(0);

  const newSelecteds = programData.map((n) => n.id);

  const [state, dispatch] = useContext(MembershipContext);
  const [cancelDialog, setcancelDialog] = useState(false);
  const [sessionState, sessionDispatch] = useReducer(
    sessionReducer,
    includeTaxinitials(initialValue, allTaxes || []) // eslint-disable-line
  );

  const [isProcessing, setIsProcessing] = useState(false);
  // const [, setIsProcessing] = useState(false);

  const history = useHistory();
  const steps = getSteps();
  useChangeTitle('Create New DropIn Template | Jungle Alliance');
  // eslint-disable-next-line consistent-return

  const insideHandleNext = () => {
    const dropIn = true;
    const value = getAllData(state, dropIn);

    setIsProcessing(true);
    createMemTemplate(value)
      .then(() => {
        history.goBack();
        setIsProcessing(false);
      })
      .then(() => {
        OpenNoti('Succuessfully created the DropIn Template');
        setIsProcessing(false);
      })
      .then(() => {
        if (gymSteps.process_type.membership === 0) {
          getGymSteps();
        }
      })
      .catch((err) => {
        OpenNoti(getErrorMsg(err), 'error');
        setIsProcessing(false);
      });
  };

  // eslint-disable-next-line max-len
  // useEffect for when sessionState(sessionReducer) update MembershipContext state aftre logic handle on useEffect
  useEffect(() => {
    if (state.payment_plans.length > 0) {
      insideHandleNext();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.payment_plans]);

  useEffect(() => {
    dispatch(changeprogramaccess(newSelecteds));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line consistent-return
  const handleNext = () => {
    if (activeStep < 1) {
      if (checkValidString(state?.name)) {
        return OpenNoti('Name is not valid', 'error');
      }
      setActiveStep((prev) => prev + 1);
    } else {
      // eslint-disable-next-line
      const { error } = validStep2(sessionState);
      if (error) {
        OpenNoti(error, 'error');
      } else {
        dispatch(addpaymentmethod({ ...sessionState, name: state.name }));
      }

      // logicFunc();

      // const value = getAllData(state);

      // setIsProcessing(true);
      // createMemTemplate(value)
      //   .then(() => {
      //     history.goBack();
      //     setIsProcessing(false);
      //   })
      //   .then(() => {
      //     OpenNoti('Succuessfully created the Membership Template');
      //     setIsProcessing(false);
      //   })
      //   .then(() => {
      //     if (gymSteps.process_type.membership === 0) {
      //       getGymSteps();
      //     }
      //   })
      //   .catch((err) => {
      //     OpenNoti(getErrorMsg(err), 'error');
      //     setIsProcessing(false);
      //   });
    }
  };

  const handleBack = () => {
    setActiveStep((prev) => prev - 1);
  };

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <DropinDetailStep />;
      // case 1:
      //   return <ProgramAccessStep />;
      // changes
      case 1:
        return (
          <DropinPaymentStep
            // changeInitialFull={changeInitialFull}
            reducer={[sessionState, sessionDispatch]}
          />
        );
      default:
        return 'Unknown step';
    }
  }
  return (
    <div className={classes.root}>
      <div className="title-bk">
        <Breadcrumbs
          aria-label="breadcrumb"
          style={{
            margin: '0px 0 21px'
          }}
        >
          <div color="inherit">Classes</div>
          <div
            color="inherit"
            onClick={() => setcancelDialog(true)}
            style={{
              cursor: 'pointer'
            }}
          >
            membership
          </div>
          <Typography variant="h3" color="Primary">
            <span>new template</span>
          </Typography>
        </Breadcrumbs>
        <div>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={0}
            style={{
              marginBottom: '15px'
            }}
          >
            <Grid item xs={8}>
              <h1
                style={{
                  marginBottom: '0px'
                }}
              >
                Create New Drop In Template
              </h1>
            </Grid>
            <Grid
              item
              xs={4}
              style={{
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              <CancelDialog />
            </Grid>
          </Grid>
        </div>
      </div>
      <Card>
        <JBStepper
          steps={steps}
          activeStep={activeStep}
          handleBack={handleBack}
          handleNext={handleNext}
          handleReset={() => setActiveStep(0)}
          // eslint-disable-next-line react/jsx-no-bind
          handleStepContent={getStepContent}
          validateBtn={dropInValidSteps(state, activeStep, sessionState)}
          isLoading={false}
          hasErrored={false}
          isprocessing={isProcessing}
          processingLabel="Creating..."
          prevLabel="CREATE"
          nextLabel="NEXT STEP"
          CustComp={(prop) => (
            <ColorlibStepIcon
              FirstIcon={PlanDetails}
              SecondIcon={PaymentPlans}
              // ThirdIcon={ProgramAccess}
              // FourthIcon={ContractTemplates}
              {...prop} // eslint-disable-line
            />
          )}
        />
      </Card>
      <CancelDialogTwo
        setOpen={cancelDialog}
        handleClose={() => setcancelDialog(false)}
      />
    </div>
  );
};

DropinTemplate.propTypes = {
  classes: PropType.object.isRequired,
  OpenNoti: PropType.func.isRequired,
  getGymSteps: PropType.func.isRequired,
  gymSteps: PropType.number.isRequired,
  allTaxes: PropType.array.isRequired,
  programData: PropType.array.isRequired
};
const mapToState = (state) => ({
  gymSteps: state.GymSetupReducer.data,
  allTaxes: state.SettingReducer.taxRates.data,
  programData: state.allprograms.programs
});
const mapToDispatch = (dispatch) => ({
  OpenNoti: (msg, type) => dispatch(openNoti(msg, type)),
  getGymSteps: () => dispatch(getGymSetup())
});

export default connect(
  mapToState,
  mapToDispatch
)(withStyles(styles)(DropinTemplate));
