import {
  AllExpiration,
  SessionDay
} from '../MembershipTemplate/Membership.data';

const Meta = (meta, Gymloc, allTaxes) => {
  const result = [];
  for (let i = 0; i < meta.length; i += 1) {
    let b = {
      name: meta[i].name,
      location: null,
      tax: allTaxes.filter((res) => res.id === meta[i].tax_id)[0],
      initial_commitment_pricing: {
        full_setup_fees: meta[i].initial_commitment_pricing.full_setup_fees,
        full_membership_fees:
          meta[i].initial_commitment_pricing.full_membership_fees
      }
    };
    if (meta[i].location_id) {
      b = {
        ...b,
        location: Gymloc.filter((res) => res.id === meta[i].location_id)[0]
      };
    }
    result.push(b);
  }
  return result;
};

// eslint-disable-next-line
export const getEditTemp = (data, Gymloc, RevenCat, taxRates) => {
  // eslint-disable-next-line
  let allTax = [],
    allGyms = [],
    allRevens = [];
  if (Gymloc) {
    allGyms = [...Gymloc];
  }
  if (RevenCat) {
    allRevens = [...RevenCat];
  }
  if (taxRates) {
    allTax = [...taxRates];
  }
  let result = {
    membership_type: 'session',
    name: data.name,
    reve_cat_id: allRevens.filter((res) => res.id === data.reve_cat_id)[0],
    attendance_limit: AllExpiration.filter(
      (res) => res.id === data.attendance_limit
    )[0],
    description: data.description,
    contract_id: String(data.contract_id),
    program_ids: data.program_ids
      .split(',')
      .map((value) => parseInt(value, 10)),
    no_of_sessions: data.session,
    attendance_limited_meta: {
      uses: 0,
      type: null
    },
    meta: Meta(data.membership_payment, allGyms, allTax)
  };
  if (data.attendance_limit === 1) {
    result = {
      ...result,
      attendance_limited_meta: {
        uses: data.attendance_limited_meta.uses,
        type:
          SessionDay.filter(
            (res) => res.id === data.attendance_limited_meta.type
          )[0] || null
      }
    };
  }
  return result;
};
