import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Card } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Stepper2 from './Stepper2';
import JBVanguardButton from '../JBVanguardButton/JBButton';

const styles = () => ({
  root: {
    width: '90%'
  },
  gridRoot: {
    background: 'transparent'
  },
  mainCard: {
    boxShadow: '4px 0px 16px rgba(37, 40, 43, 0.08)',
    borderRadius: '5px',
    background: 'transparent',
    padding: 0,
    // overflow: 'visible',
    width: '100%',
    '& .sp-text': {
      height: 'calc(100vh - 285px)',
      overflow: 'auto'
    },
    '& .step-btn': {
      background: '#101010',
      position: 'absolute',
      bottom: '0',
      left: '0',
      right: '0',
      maxWidth: '1024px',
      margin: '0 auto'
    }
  }
  // button: {
  //   minWidth: '250px'
  //   background: '#01847A',
  //   color: '#FFF',
  //   padding: '12px 24px',
  //   boxShadow: '0px 8px 16px rgba(0, 126, 255, 0.16);',
  //   float: 'right;',
  //   '&:hover': {
  //     backgroundColor: '#01847A'
  //   }
  // },
  // btn_previou: {
  //   color: '#01847a',
  //   border: '1px solid #01847a',
  //   minHeight: '48px',
  //   padding: '11px 16px'
  // }
});

const JBStepper2 = (props) => {
  const {
    activeStep,
    handleNext,
    handleBack,
    handleReset,
    classes,
    steps,
    handleStepContent,
    backLabel,
    prevLabel,
    nextLabel,
    CustComp,
    profileLead,
    profileActiveStep,
    isBackDisabled,
    validateBtn,
    cancelBack,
    processing
  } = props;

  return (
    <Card className={classes.mainCard}>
      <Grid className={classes.gridRoot} container>
        <Grid item xs={12} className="StepLabelInfo">
          <Stepper2
            steps={steps}
            profileLead={profileLead}
            activeStep={activeStep}
            IconComp={CustComp}
            profileActiveStep={profileActiveStep}
          />
        </Grid>
        <Grid item xs={12}>
          {profileLead === false ? (
            <div className="sp-text">​{handleStepContent(activeStep)}</div>
          ) : null}
          <div className="step-btn">
            <Grid
              item
              xs={10}
              style={{
                margin: '0 auto'
              }}
            >
              {activeStep === steps.length ? (
                <div>
                  <JBVanguardButton
                    onClick={handleReset}
                    type="primary"
                    // className={classes.button}
                  >
                    Reset
                  </JBVanguardButton>
                </div>
              ) : (
                <div>
                  <div>
                    <JBVanguardButton
                      type="outlined"
                      disabled={
                        isBackDisabled || cancelBack.includes(activeStep)
                      }
                      onClick={handleBack}
                      // className={classes.btn_previou}
                      style={{
                        minWidth: '250px'
                      }}
                    >
                      {/* Previous */}
                      {backLabel}
                    </JBVanguardButton>
                    <JBVanguardButton
                      type="primary"
                      onClick={handleNext}
                      // className={classes.button}
                      disabled={validateBtn[activeStep] || processing}
                      style={{
                        minWidth: '250px',
                        float: 'right'
                      }}
                    >
                      {activeStep === steps.length - 1
                        ? `${prevLabel}`
                        : `${nextLabel}`}
                    </JBVanguardButton>
                  </div>
                </div>
              )}
            </Grid>
          </div>
        </Grid>
      </Grid>
    </Card>
  );
};
JBStepper2.defaultProps = {
  handleReset: null,
  backLabel: 'Back',
  // OnCloseRequest: null,
  // shouldDisableBtn: false,
  // stepperContentMaxHeight: 700,
  // noGrid: false,
  profileLead: false,
  validateBtn: [],
  cancelBack: [],
  processing: false
};

JBStepper2.propTypes = {
  classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  steps: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  activeStep: PropTypes.number.isRequired,
  handleNext: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  handleReset: PropTypes.func,
  handleStepContent: PropTypes.func.isRequired,
  backLabel: PropTypes.string,
  /*eslint-disable */
  nextLabel: PropTypes.string,
  prevLabel: PropTypes.any,
  CustComp: PropTypes.element,
  profileLead: PropTypes.bool,
  profileActiveStep: PropTypes.func,
  isBackDisabled: PropTypes.bool,
  /* eslint-enable */
  validateBtn: PropTypes.array,
  cancelBack: PropTypes.array,
  processing: PropTypes.bool
};

export default withStyles(styles)(JBStepper2);
