/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import { openNoti } from 'src/Redux/global/global.actions';
import { connect } from 'react-redux';
import PropType from 'prop-types';
import { updateusermemship } from 'src/services/user.service';
import CancelDialog from 'src/views/common/CommonDialogBox/CancelDialog';
import JBButton from 'src/views/common/JBButton/JBButton';
import queryString from 'query-string';
import SignContractDialog from 'src/views/Documents/SignContract/SignContractDialog';
import { getErrorMsg } from 'src/utils/ErrorHandling';
import { ROUTES } from 'src/constant/Constants';
import useChangeTitle from 'src/views/common/CustomHooks/useTitleChange';
import { getChangeData } from './EditMembership.utils';
import PaymentOptions from './PaymentOptions';
import DiscountsPricing from './Discounts&pricing';
import Programs from './Programs';
import { discountsvalid, paymentOpt, ProgaccessValid } from './EditUserMembsership.validations';
import { sendcreatemem } from '../Memberships/NewMembership/newmembership.utils';

const NewTemplateTabs = (props) => {
  const { Tabs, classes, reducer, setTab, Opt, start } = props;
  const [state] = reducer;
  const [updates, setupdates] = useState([]);
  const [isprocessing, setprocessing] = useState(false);
  const [signcontract, setsignContract] = useState({
    id: null,
    open: false
  });
  const history = useHistory();
  const location = useLocation();
  const { memberId, memId } = queryString.parse(location.search);
  const disabledBtn = Boolean(Tabs === 2 && updates.length === 0);
  useEffect(() => {
    const resetdata = { ...state.data.reset };
    const objdata = { ...state.data };
    delete objdata.reset;
    const result = getChangeData(objdata, resetdata);
    setupdates(result);
  }, [state.data]);
  useChangeTitle('Membership | Jungle Alliance');
  const handleNext = () => {
    const { OpenNoti } = props;
    if (Tabs === 0) {
      const { error } = paymentOpt(state.data);
      if (error) {
        OpenNoti(error, 'error');
      } else {
        setTab(Tabs + 1);
      }
    } else if (Tabs === 1) {
      const { error } = discountsvalid(state.data);
      if (error) {
        OpenNoti(error, 'error');
      } else {
        setTab(Tabs + 1);
      }
    } else if (Tabs === 2) {
      const { error, data } = ProgaccessValid(state.data);
      if (error) {
        OpenNoti(error, 'error');
      } else {
        let values = sendcreatemem(data);
        values = {
          ...values,
          membership_id: state.data.membership_id,
          membership_payment_id: state.data.membership_payment_id,
          membership_contract: state.data.membership_contract,
          initial_commitment: state.data.payment_data.initial_commitment.initial
        };
        setprocessing(true);
        updateusermemship(values, memId, atob(memberId))
          .then((resp) => {
            if (state.data.membership_contract === 'sign_contract') {
              setsignContract({ id: resp.user_contract_id, open: true });
            } else {
              history.goBack();
              OpenNoti('Successfully update the membership', 'info');
            }
            setprocessing(false);
          })
          .catch((err) => {
            OpenNoti(getErrorMsg(err), 'error');
            setprocessing(false);
          });
      }
    }
  };
  return (
    <div className={classes}>
      {(() => {
        switch (Tabs) {
          case 0:
            return (
              <PaymentOptions
                memId={memId}
                value={Opt}
                start={start}
                reducer={reducer}
              />
            );
          case 1:
            return <DiscountsPricing reducer={reducer} />;
          case 2:
            return <Programs reducer={reducer} changes={updates} />;
          default:
            return null;
        }
      })()}
      <Grid
        item
        xs={12}
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          margin: '15px 0'
        }}
      >
        {Tabs === 2 && <CancelDialog />}
        <JBButton
          type="primary"
          style={{ marginLeft: '15px' }}
          onClick={handleNext}
          disabled={disabledBtn || isprocessing}
        >
          <span>
            {Tabs === 2
              ? isprocessing
                ? 'Saving your membership....'
                : 'Save'
              : 'Next'}
          </span>
        </JBButton>
        <Grid>
          {signcontract.id && signcontract.open ? (
            <SignContractDialog
              id={signcontract.id}
              open={signcontract.open}
              setClose={() => {
                setsignContract({ id: null, open: false });
                history.goBack();
              }}
              handleHistory={`${ROUTES.PEOPLE_MEMBER_PROFILE}?memberId=${memberId}`}
            />
          ) : null}
        </Grid>
      </Grid>
    </div>
  );
};

NewTemplateTabs.propTypes = {
  Tabs: PropType.number.isRequired,
  classes: PropType.object.isRequired,
  reducer: PropType.array.isRequired,
  setTab: PropType.func.isRequired,
  OpenNoti: PropType.func.isRequired,
  start: PropType.object.isRequired,
  Opt: PropType.array.isRequired
};

const mapToDispatch = (dispatch) => ({
  OpenNoti: (msg, type) => dispatch(openNoti(msg, type))
});

export default connect(null, mapToDispatch)(NewTemplateTabs);
