import { backendDate } from 'src/utils/someCommon';

export const getFilter = (filter, startDate, endDate) => {
  switch (filter?.id) {
    case 'custom': {
      return {
        next_day: 'custom',
        custom: backendDate(startDate) + '-' + backendDate(endDate)
      };
    }
    default:
      return {
        next_day: filter.id
      };
  }
};

export const getfilterLabel = (filter, startDate, endDate) => {
  switch (filter?.id) {
    case 'custom':
      return {
        id: 1,
        name:
          'Payment due is '
          + backendDate(startDate)
          + ' to '
          + backendDate(endDate),
        filter: 'next_day,custom'
      };

    default:
      return {
        id: 1,
        name: `Payment Due is ${filter?.filter}`,
        filter: 'next_day'
      };
  }
};

export const checkData = (filter, data, endDate) => {
  if (!filter) {
    return true;
  }
  if (filter?.id === 'custom') {
    if (!data) {
      return true;
    }
    if (data?.id === 'custom' && !endDate) {
      return true;
    }
  }
  return false;
};
