import 'date-fns';
import React, { useEffect } from 'react';
import PropType from 'prop-types';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  TimePicker
  // KeyboardTimePicker
} from '@material-ui/pickers';
import { withStyles } from '@material-ui/core/styles';
// import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { useDispatch, useSelector } from 'react-redux';
import { getAllGymSet } from 'src/Redux/SettingReducer/SettingReducer.actions';
import { GymTimeFormat } from 'src/constant/GymDateTime';

const styles = () => ({
  input: {
    color: '#7F7F7F',
    border: '1px solid #a9a9a9',
    height: '48px',
    padding: '0px 9px 0px',
    marginTop: '23px !important'
  }
});

function MaterialUITimePickers({ label, classes, value, ...props }) {
  const gymSetting = useSelector((state) => state.SettingReducer.date);
  const dispatch = useDispatch();

  useEffect(() => {
    if (gymSetting.error) {
      dispatch(getAllGymSet());
    }
  }, []); // eslint-disable-line

  const TimeFormat = gymSetting?.data?.time_format === GymTimeFormat.twelve;

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container justify="space-around">
        <TimePicker
          variant="inline"
          id="time-picker"
          autoOk
          fullWidth
          label={label}
          value={value}
          ampm={TimeFormat}
          // KeyboardButtonProps={{
          //   'aria-label': 'change time'
          // }}
          // keyboardIcon={<AccessTimeIcon />}
          InputProps={{
            disableUnderline: true,
            className: classes.input
          }}
          InputLabelProps={{
            shrink: true
          }}
          {...props} // eslint-disable-line
        />
        {/* <KeyboardTimePicker
          variant="inline"
          id="time-picker"
          autoOk
          fullWidth
          label={label}
          value={value}
          KeyboardButtonProps={{
            'aria-label': 'change time'
          }}
          keyboardIcon={<AccessTimeIcon />}
          InputProps={{
            disableUnderline: true,
            className: classes.input
          }}
          InputLabelProps={{
            shrink: true
          }}
          {...props} // eslint-disable-line
        /> */}
      </Grid>
    </MuiPickersUtilsProvider>
  );
}

MaterialUITimePickers.propTypes = {
  classes: PropType.object.isRequired,
  label: PropType.string.isRequired,
  value: PropType.string.isRequired
};
export default withStyles(styles)(MaterialUITimePickers);
