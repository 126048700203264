import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Box,
  Button,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  InputLabel,
  MenuList
} from '@material-ui/core';
import PropType from 'prop-types';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import JBDatePicker from 'src/views/common/FormControl/JBDatePicker';
import { getMinDate, getValuefromObject } from 'src/utils/someCommon';
import CustomAutoComplete from '../../../calendar/commonAutocomplete';
import useStyles from './filter.style';
import { TobeFilter, StarredFilter, getTags } from './staticData';
import { seeFilter, seeAllFilters, checkData } from './filter.util';

const PROGRM_VIEW = 1;

const ApplyFilter = (props) => {
  const {
    allPrograms: { isFetching, programs },
    getData,
    allTags
  } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [filter, setFilter] = useState(null);
  const [data, setData] = useState(null);
  const [endDate, setendDate] = useState(getMinDate(new Date()));

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
    setFilter(null);
    setData(null);
  }, [open]);

  const filteredData = TobeFilter.filter((fil) => fil.id === filter)[0];
  useEffect(() => {
    if (filter === 2) {
      setData(new Date());
    } else {
      setData(null);
    }
  }, [filter]);

  if (isFetching) return <p>Loading....</p>;

  return (
    <div className={classes.root}>
      <Button
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={() => setOpen(!open)}
        variant="outlined"
        style={{
          borderRadius: '2px',
          zIndex: '9',
          width: '235px',
          height: '48px',
          color: '#000',
          maxWidth: '235px',
          justifyContent: 'flex-start'
        }}
      >
        <span
          style={{
            width: '100%',
            textAlign: 'left',
            color: '#000000',
            fontWeight: 'bold',
            textTransform: 'capitalize'
          }}
        >
          Apply filter
        </span>
        {open ? <ExpandLess /> : <ExpandMore />}
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{
          zIndex: '9',
          minWidth: '235px',
          maxWidth: '235px',
          width: '235px',
          outline: 'none !important'
        }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps} // eslint-disable-line
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
              padding: '10px',
              outline: 'none!important'
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  // eslint-disable-next-line react/jsx-no-bind
                  onKeyDown={handleListKeyDown}
                >
                  <div className={classes.filterInfo}>
                    <InputLabel
                      htmlFor="age-required"
                      className={classes.cutom_inp_Label}
                      style={{ margin: '0px 0 5px' }}
                    >
                      Show All Words Where
                    </InputLabel>
                    <Box>
                      <CustomAutoComplete
                        holder="Select Filter"
                        value={TobeFilter}
                        Options="filter"
                        data={filteredData}
                        Change={(val) => setFilter(getValuefromObject(val))}
                        style={{ marginBottom: '10px' }}
                      />
                      {/* <InputLabel
                        htmlFor="age-required"
                        className={classes.cutom_inp_Label}
                        style={{ margin: '5px 0' }}
                      >
                        is
                      </InputLabel> */}
                      {filter === PROGRM_VIEW && (
                        <CustomAutoComplete
                          holder="Select a Program"
                          value={programs}
                          data={data || ''}
                          Options="name"
                          Change={(val) => setData(val)}
                          style={{ marginbottom: '0' }}
                        />
                      )}
                      {filter === 2 && (
                        <>
                          <InputLabel
                            htmlFor="age-required"
                            className={classes.cutom_inp_Label}
                            style={{ margin: '5px 0' }}
                          >
                            From
                          </InputLabel>
                          <JBDatePicker
                            label="Start Date"
                            value={data}
                            onChange={(val) => {
                              setData(val);
                              setendDate(getMinDate(val));
                            }}
                          />
                          <InputLabel
                            htmlFor="age-required"
                            className={classes.cutom_inp_Label}
                            style={{ padding: '20px 0px 5px' }}
                          >
                            To
                          </InputLabel>
                          <JBDatePicker
                            label="End Date"
                            minDate={
                              data ? getMinDate(data) : getMinDate(new Date())
                            }
                            value={endDate}
                            onChange={(val) => setendDate(val)}
                          />
                        </>
                      )}
                      {filter === 3 && (
                        <CustomAutoComplete
                          holder="Starred"
                          value={StarredFilter}
                          Options="starred"
                          data={data || ''}
                          Change={(val) => setData(val)}
                          style={{ marginbottom: '0' }}
                        />
                      )}
                      {filter === 4 && (
                        <CustomAutoComplete
                          holder="Select WOD tag"
                          value={getTags(allTags)}
                          Options="name"
                          data={data || ''}
                          Change={(val) => setData(val)}
                          style={{ marginbottom: '0' }}
                        />
                      )}
                    </Box>
                    <Button
                      color="primary"
                      variant="contained"
                      disabled={checkData(filter, data, endDate)}
                      onClick={() => {
                        getData(
                          seeFilter(filter, data, endDate),
                          seeAllFilters(filter, data, endDate)
                        );
                        setOpen(!open);
                      }}
                      style={{ marginTop: '20px', width: '100%' }}
                    >
                      Apply Filter
                    </Button>
                  </div>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

ApplyFilter.defaultProps = {
  allTags: []
};

ApplyFilter.propTypes = {
  allPrograms: PropType.object.isRequired,
  getData: PropType.func.isRequired,
  allTags: PropType.array
  // allTags: PropType.array.isRequired
};

const mapToState = (state) => ({
  allPrograms: state.allprograms,
  allTags: state.wodList.tags
});

export default connect(mapToState)(ApplyFilter);
