import {
  validStringRegEx,
  validEmailRegEx,
  validPhoneRegEx,
  // UrlRegex,
  postalRegEx,
  isPhoneNumberInvalid,
  checkValidString
} from 'src/utils/validations';

const validateAlliesProfile = (data) => {
  if (data?.gym_name.length <= 0) {
    throw Error('Gym Name is Required');
  } else if (data?.first_name.length <= 0) {
    throw Error('First Name is Required');
  } else if (data?.last_name <= 0) {
    throw Error('Last Name is Required');
  } else if (data?.email.length <= 0) {
    throw Error('Email is Required');
  } else if (!validEmailRegEx.test(data?.email)) {
    throw Error('Please provide a valid Email Address');
  } else if (!validPhoneRegEx.test(data?.mobile_no)) {
    throw Error('Please enter a valid Mobile number');
  } else if (isPhoneNumberInvalid(String(data?.mobile_no))) {
    throw Error('Mobile number must be between 10 and 15 digits');
  } else if (data?.address.length <= 0) {
    throw Error('Address field is Required');
  } else if (checkValidString(data?.address)) {
    throw Error('Please specify valid address');
  } else if (data?.city.length <= 0) {
    throw Error('City Field is required');
  } else if (!validStringRegEx.test(data?.city)) {
    throw Error('Please specify valid city');
  } else if (data?.province.length <= 0) {
    throw Error('Please specify the Province');
  } else if (!validStringRegEx.test(data?.province)) {
    throw Error('Please specify valid Province');
  } else if (!data?.country) {
    throw Error('Please specify the country');
  } else if (data?.postal_code.length <= 0) {
    throw Error('Please specify the postal Code');
  } else if (!postalRegEx.test(data?.postal_code)) {
    throw Error('Please enter a valid postal');
  }
};

export default validateAlliesProfile;
