/* eslint-disable indent */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles, Box, Dialog, DialogContent } from '@material-ui/core';
import PropTypes from 'prop-types';
import { ReactComponent as CloseIcon } from '../../assets/image/Vanguard/closeIcon.svg';
import JBVanguardButton from '../common/JBVanguardButton/JBButton';

const useStyles = makeStyles(() => ({
  successBox: {
    background: 'rgba(29, 29, 29, 0.94)',
    position: 'absolute',
    left: '0',
    right: '0',
    top: '0',
    height: '100%',
    width: '100%',
    margin: '0 auto',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    zIndex: '9999',
    justifyContent: 'center'
  },
  successText: {
    color: '#fff',
    marginTop: '31px',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '32px',
    lineHeight: '42px'
  },
  DialogBox: {
    backgroundColor: 'transparent',
    '& .MuiDialog-paper': {
      minWidth: '100%',
      minHeight: '100%',
      backgroundColor: 'transparent'
    }
  },
  subText: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 'normal',
    color: '#fff',
    fontSize: '20px',
    lineHeight: '32px',
    marginTop: '8px'
  }
}));

const LeavePagePopup = (props) => {
  const {
    Dialogtitle,
    handleClose,
    setOpen,
    handleHistory,
    handleBack
  } = props;
  const history = useHistory();
  const classes = useStyles();
  return (
    <Dialog
      fullScreen
      open={setOpen}
      onClose={() => handleClose()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.DialogBox}
    >
      <DialogContent
        style={{
          minWidth: '100%',
          minHeight: '100%',
          backgroundColor: 'transparent'
        }}
      >
        <div className={classes.successBox}>
          <Box>
            <CloseIcon />
            <h3 className={classes.successText}>{Dialogtitle}</h3>
            <p className={classes.subText}>Data you entered may not be saved</p>
            <JBVanguardButton
              type="outlined"
              style={{
                marginTop: '32px',
                width: '250px',
                minHeight: '70px'
              }}
              onClick={() => {
                // eslint-disable-next-line no-unused-expressions
                handleBack
                  ? handleBack()
                  : handleHistory
                  ? history.push(`${handleHistory}`)
                  : history.goBack();
              }}
            >
              Leave page
            </JBVanguardButton>
            <JBVanguardButton
              type="primary"
              style={{
                marginTop: '32px',
                marginLeft: '15px',
                width: '250px',
                minHeight: '70px'
              }}
              onClick={() => handleClose()}
            >
              Stay on page
            </JBVanguardButton>
          </Box>
        </div>
      </DialogContent>
    </Dialog>
  );
};

LeavePagePopup.defaultProps = {
  Dialogtitle: 'Are you sure?',
  handleHistory: null,
  handleBack: null
};

LeavePagePopup.propTypes = {
  Dialogtitle: PropTypes.string,
  setOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleBack: PropTypes.func,
  handleHistory: PropTypes.string
};

export default LeavePagePopup;
