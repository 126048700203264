import Types from './member.Types';

// eslint-disable-next-line
export const getMembers = (type, action, state, data) => {
  switch (type) {
    case Types.getActiveMem: {
      const Data = () => {
        switch (action) {
          case Types.isFetching:
            return {
              ...state,
              isFetching: true
            };
          case Types.fetchSucess:
            return {
              ...state,
              isFetching: false,
              error: null,
              data
            };
          case Types.Errorfetch:
            return {
              ...state,
              isFetching: false,
              error: data
            };
          default:
            return state;
        }
      };
      return Data();
    }
    case Types.getInActiveMem: {
      const Data = () => {
        switch (action) {
          case Types.isFetching:
            return {
              ...state,
              isFetching: true
            };
          case Types.fetchSucess:
            return {
              ...state,
              isFetching: false,
              error: null,
              data
            };
          case Types.Errorfetch:
            return {
              ...state,
              isFetching: false,
              error: data
            };
          default:
            return state;
        }
      };
      return Data();
    }
    case Types.getAllMem: {
      const Data = () => {
        switch (action) {
          case Types.isFetching:
            return {
              ...state,
              isFetching: true
            };
          case Types.fetchSucess:
            return {
              ...state,
              isFetching: false,
              error: null,
              data
            };
          case Types.Errorfetch:
            return {
              ...state,
              isFetching: false,
              error: data
            };
          default:
            return state;
        }
      };
      return Data();
    }
    case Types.getAllActiveMem: {
      const Data = () => {
        switch (action) {
          case Types.isFetching:
            return {
              ...state,
              isFetching: true
            };
          case Types.fetchSucess:
            return {
              ...state,
              isFetching: false,
              error: null,
              data
            };
          case Types.Errorfetch:
            return {
              ...state,
              isFetching: false,
              error: data
            };
          default:
            return state;
        }
      };
      return Data();
    }

    default:
      return state;
  }
};
