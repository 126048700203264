import { ROUTES } from 'src/constant/Constants';

export const rolesList = {
  1: 'Admin',
  2: 'Staff',
  3: 'Coach',
  4: 'Member'
};

// If Any of these routes is visited, Search member name click it will prompt a Cancel dialog

export const allFormRoutes = [
  // WORKOUTS
  {
    name: 'Create New Workout',
    path: ROUTES.WORKOUT_CREATE_VIEW
  },
  {
    name: 'Edit Workout',
    path: ROUTES.WORKOUT_EDIT_VIEW
  },
  // PEOPLE
  {
    name: 'Create New Lead',
    path: ROUTES.PEOPLE_CREATE_LEAD
  },
  {
    name: 'Edit Lead Profile',
    path: ROUTES.PEOPLE_LEAD_PROFILE
  },
  {
    name: 'Create Member',
    path: ROUTES.PEOPLE_CREATE_MEMBER
  },
  {
    name: 'Edit Member',
    path: ROUTES.PEOPLE_MEMBER_PROFILE
  },
  {
    name: 'New Membership for Member',
    path: ROUTES.PEOPLE_CREATE_NEW_MEMBERSHIPS
  },
  {
    name: 'Member Profile',
    path: ROUTES.PEOPLE_MEMBER_PROFILE
  },
  // CLASSES
  {
    name: 'Create New Class',
    path: ROUTES.CLASSES_CREATE_NEW_CLS
  },
  {
    name: 'Edit Class',
    path: ROUTES.CLASSES_EDIT
  },
  {
    name: 'Create New Program',
    path: ROUTES.CLASSES_PROGRAMS_CREATE_NEW
  },
  {
    name: 'Edit Program',
    path: ROUTES.CLASSES_PROGRAMS_EDIT
  },
  {
    name: 'Create New Plan Membership',
    path: ROUTES.CLASSES_PLAN_MEMBERSHIP_CREATE
  },
  {
    name: 'Edit Plan Membership Template',
    path: ROUTES.CLASSES_PLAN_MEMBERSHIP_EDIT
  },
  {
    name: 'Create New Session Template',
    path: ROUTES.CLASSES_PLAN_SESSION_CREATE
  },
  {
    name: 'Edit Session Membership Template',
    path: ROUTES.CLASSES_PLAN_SESSION_CREATE
  },
  // add
  {
    name: 'Create New Drop In Template',
    path: ROUTES.CLASSES_DROPIN_CREATE
  },
  // COMMUNICATION
  {
    name: 'Member Payment',
    path: ROUTES.COMMUNICATION_AUTOMATED_EMAILS_MEMBER_PAYMENTS
  },
  {
    name: 'Credit Card Expiring',
    path: ROUTES.COMMUNICATION_AUTOMATED_EMAILS_CREDIT_CARD_EXP
  },
  {
    name: 'Failed Payments',
    path: ROUTES.COMMUNICATION_AUTOMATED_EMAILS_FAILED_PAYMENTS
  },
  {
    name: 'Membership Renewal Reminder',
    path: ROUTES.COMMUNICATION_AUTOMATED_EMAILS_RENEWAL_REMINDER
  },
  {
    name: 'Payment Information Missing',
    path: ROUTES.COMMUNICATION_AUTOMATED_EMAILS_PAYMENT_INFO_MISSING
  },
  {
    name: 'Welcome Email',
    path: ROUTES.COMMUNICATION_AUTOMATED_EMAILS_WELCOME_EMAIL
  },
  {
    name: 'Class has been Reinstated',
    path: ROUTES.COMMUNICATION_AUTOMATED_EMAILS_CLASSES_CLASSREINSTATED
  },
  {
    name: 'Class is Munally Canceled',
    path: ROUTES.COMMUNICATION_AUTOMATED_EMAILS_CLASSES_CLASSCANCELLED
  },
  {
    name: 'Membership Expiring - Plans',
    path: ROUTES.COMMUNICATION_AUTOMATED_EMAILS_CLASSES_EXPIRINGPLANS
  },
  {
    name: 'Membership Expiring - Sessions',
    path: ROUTES.COMMUNICATION_AUTOMATED_EMAILS_CLASSES_EXPIRINGSESSIONS
  },
  {
    name: 'Missing Member',
    path: ROUTES.COMMUNICATION_AUTOMATED_EMAILS_CLASSES_MISSING_MEMBER
  },
  // DOCUMENTS
  {
    name: 'New Membership Contract',
    path: ROUTES.DOCUMENTS_NEW_CONTRACTS
  },
  {
    name: 'Edit Contract',
    path: ROUTES.DOCUMENTS_EDIT_CONTRACTS
  },
  {
    name: 'New Waiver Template',
    path: ROUTES.DOCUMENTS_WAIVERS_NEW
  },
  {
    name: 'Waiver Template Details',
    path: ROUTES.DOCUMENTS_WAIVERS_TEMPLATES_DETAIL
  },
  // FINANCIAL
  {
    name: 'Edit Invoice',
    path: ROUTES.FINANCIAL_INVOICES_VIEW
  }
  // REPORTS
];
