import React from 'react';
import clsx from 'clsx';
import PropType from 'prop-types';
import {
  Typography,
  Grid,
  Toolbar,
  makeStyles,
  Checkbox
} from '@material-ui/core';
import BulkActions from '../JBTables/BulkActions';

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    padding: '0 5px',
    backgroundColor: '#F9F9FA',
    color: '#000'
  },
  highlight:
    theme.palette.type === 'light'
      ? { color: '#292929', backgroundColor: '#F9F9FA' }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark
      },
  title: {
    flex: '1 1 100%'
  }
}));

const TableHead = (props) => {
  const classes = useToolbarStyles();
  const { numSelected, includeBulk } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0
      })}
      style={{
        backgroundColor: '#F9F9FA'
      }}
    >
      <Checkbox
        defaultChecked
        indeterminate
        // onClick={() => ClearChecks()}
        inputProps={{ 'aria-label': 'indeterminate checkbox' }}
        color="primary"
      />
      <Grid
        spacing={0}
        disableGutters
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
      >
        <Grid item sm={3} lg={2}>
          <Typography
            className={classes.title}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected.length} workouts selected
          </Typography>
        </Grid>
        <Grid item sm={3} lg={2}>
          {includeBulk && <BulkActions />}
        </Grid>
      </Grid>
      {/* <div>
        <Tooltip title="Delete" xs>
          <IconButton aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </div> */}
    </Toolbar>
  );
};

TableHead.defaultProps = {
  includeBulk: false
};

TableHead.propTypes = {
  numSelected: PropType.number.isRequired,
  includeBulk: PropType.bool
};

export default TableHead;
