import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid, Box, withStyles } from '@material-ui/core';
import filterType from 'src/Redux/Reducer.constants';
import useChangeTitle from 'src/views/common/CustomHooks/useTitleChange';
import Templatestable from './Templatestable';
import styles from './Waivers.style';
import { getTempWav } from '../../../Redux/WaiverReducer/WaiverReducer.actions';
import LoadingView from '../../common/loading';

const TemplateTab = (props) => {
  // const classes = useStyles();
  const {
    WaivFilter,
    classes,
    WaivSort,
    getTemplates: { isFetching, data, error }
  } = props;
  useChangeTitle('Templates | Jungle Alliance');
  if (isFetching) return <LoadingView />;
  if (error) return <p>{error}</p>;

  const setDirection = (name) => {
    if (WaivSort.sort_name === name) {
      return WaivSort.sort_by === 'asc' ? 'asc' : 'dsc';
    }
    return 'asc';
  };

  const isActive = (sortlabel) => {
    if (WaivSort.sort_name === sortlabel) return true;
    return false;
  };
  const setSort = (sortlabel) => {
    WaivFilter(filterType.sorting, sortlabel);
  };
  return (
    <Box p="24px">
      <Grid
        container
        direction="row"
        justify="start"
        alignItems="center"
        spacing={0}
      >
        <Grid item xs={12} className={classes.SimpleTablestyle}>
          <Templatestable
            values={data || []}
            setDirection={setDirection}
            isActive={isActive}
            setSort={setSort}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
TemplateTab.propTypes = {
  WaivFilter: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  WaivSort: PropTypes.object.isRequired,
  getTemplates: PropTypes.object.isRequired
  // getClass: PropTypes.object.isRequired,
};

const mapToDispatch = (dispatch) => ({
  WaivFilter: (filter, data) => dispatch(getTempWav(filter, data))
});

const mapToState = (state) => ({
  getTemplates: state.WaiverReducer.Templates,
  WaivSort: state.WaiverReducer.TempFilter.sorting
});

export default connect(
  mapToState,
  mapToDispatch
)(withStyles(styles)(TemplateTab));
