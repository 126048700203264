import authhttp from './authHttp.services';

// eslint-disable-next-line
export const getConTemplate = async (param) => {
  const tempUrl = 'api/contracts';
  const {
    data: {
      data: { data }
    }
  } = await authhttp.get(tempUrl, {
    params: {
      ...param
    }
  });
  return data;
};

export const getContracts = async (values) => {
  const ContUrl = 'api/user_contracts';
  const {
    data: { data }
  } = await authhttp.get(ContUrl, {
    params: values
  });
  return data;
};
export const getWaiverName = async (values) => {
  const ContUrl = 'api/check_waiver_name';
  const {
    data: { data }
  } = await authhttp.get(ContUrl, {
    params: values
  });
  return data;
};
export const getContractName = async (values) => {
  const ContUrl = 'api/check_contract_name';
  const {
    data: { data }
  } = await authhttp.get(ContUrl, {
    params: values
  });
  return data;
};
export const getMemContract = async (id) => {
  const ContUrl = `api/get_user_contract/${id}`;
  const {
    data: { data }
  } = await authhttp.get(ContUrl);
  return data;
};

export const updateUserContract = async (id, values) => {
  const UpdateUrl = `api/user_contracts/${id}`;
  const data = await authhttp.put(UpdateUrl, values);
  return data;
};
export const deleteWaiver = async (id) => {
  const newWaiverUrl = `api/delete_waiver/${id}`;
  const { data } = await authhttp.post(newWaiverUrl);
  return data;
};
export const defaultWaiver = async (id) => {
  const newWaiverUrl = `api/default_waiver/${id}`;
  const { data } = await authhttp.post(newWaiverUrl);
  return data;
};
export const getAllWaivers = async (values) => {
  const getWavurl = 'api/user_waivers';
  const {
    data: { data }
  } = await authhttp.get(getWavurl, { params: values });
  return data;
};

export const getWaiverTemp = async (values) => {
  const getWavTemp = 'api/waivers';
  const {
    data: {
      data: { data }
    }
  } = await authhttp.get(getWavTemp, { params: values });
  return data;
};

export const getContractDetail = async (id) => {
  const editContractUrl = `api/contracts/${id}/edit`;
  const {
    data: { data }
  } = await authhttp.get(editContractUrl);
  return data;
};
export const updateContractTemp = async (values, id) => {
  const updateContractUrl = `api/contracts/${id}`;
  const data = await authhttp.put(updateContractUrl, values);
  return data;
};
export const createNewWaiver = async (values) => {
  const newWaiverUrl = 'api/waivers';
  const { data } = await authhttp.post(newWaiverUrl, values);
  return data;
};
export const updateWaiverTemp = async (values, id) => {
  const updateWaiverUrl = `api/waivers/${id}`;
  const data = await authhttp.put(updateWaiverUrl, values);
  return data;
};
export const getEditWaiver = async (id) => {
  const getEditWaiverUrl = `api/waivers/${id}/edit`;
  const {
    data: { data }
  } = await authhttp.get(getEditWaiverUrl);
  return data;
};

export const getMemWaiver = async (id) => {
  const WaiverUrl = `api/user_waivers/${id}/edit`;
  const {
    data: { data }
  } = await authhttp.get(WaiverUrl);
  return data;
};

export const exportContract = async (ids, status) => {
  const exportUrl = 'api/contract_export';
  const Values = {
    ids,
    status
  };
  authhttp
    .post(exportUrl, Values, {
      responseType: 'blob'
    })
    .then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'contract.xlsx');
      document.body.appendChild(link);
      link.click();
    });
};

export const exportWaiver = async (ids, status) => {
  const exportUrl = 'api/waiver_export';
  const Values = {
    ids,
    status
  };
  authhttp
    .post(exportUrl, Values, {
      responseType: 'blob'
    })
    .then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'waiver.xlsx');
      document.body.appendChild(link);
      link.click();
    });
};
export const downloadWaiver = async (values, id) => {
  const exportUrl = `api/export_user_waiver_pdf/${id}`;
  authhttp.post(exportUrl, values).then((res) => {
    const url = res.data.data.user_waiver_path;
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    link.setAttribute('download', 'waiver.pdf');
    document.body.appendChild(link);
    link.click();
  });
};
export const updateUserWaiver = async (id, values) => {
  const UpdateUrl = `api/user_waivers/${id}`;
  const data = await authhttp.put(UpdateUrl, values);
  return data;
};

export const deleteContract = async (id) => {
  const ContUrl = `api/contracts/${id}`;
  const { data } = await authhttp.del(ContUrl);
  return data;
};

export const exportMembershipContractPDF = async (ids) => {
  const exportUrl = `api/export_user_contract_pdf/${ids}`;
  const Values = {
    ids
  };

  authhttp
    .get(exportUrl, Values, {
      responseType: 'blob'
    })
    .then((res) => {
      // const url = window.URL.createObjectURL(
      //   new Blob([res.data.data.user_contract_path])
      // );
      const link = document.createElement('a');
      // link.href = url;
      link.href = res.data.data.user_contract_path;
      link.target = '_blank';
      link.setAttribute('download', 'Contract.pdf');
      document.body.appendChild(link);
      link.click();
    });
};

export const ContractEmail = async (id) => {
  const ContractEmailURL = `api/send_contract_mail/${id}`;
  const { data } = await authhttp.get(ContractEmailURL);
  return data;
};

export const sendEmailWaiver = async (id) => {
  const emailUrl = `api/send_waiver_list_view_mail/${id}`;
  const { data } = await authhttp.get(emailUrl);
  return data;
};

export const PostEmailWaiver = async (id, values) => {
  const emailUrl = `api/send_waiver_list_view_mail/${id}`;
  const { data } = await authhttp.post(emailUrl, values);
  return data;
};
