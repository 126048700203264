import React, { useState } from 'react';
import PropType from 'prop-types';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  withStyles,
  Grid,
  InputLabel,
  Box,
  ThemeProvider
} from '@material-ui/core';
import dcloseicon from 'src/assets/image/closeicon.png';
import JBButton from 'src/views/common/JBButton/JBButton';
import CustomAutoComplete from 'src/views/common/CustomAutocomplete/commonAutocomplete';
import { payInvoice } from 'src/services/masterHQ.invoices.services';
import { ROUTES } from 'src/constant/Constants';
import { getErrorMsg } from 'src/utils/ErrorHandling';
import { openNoti } from 'src/Redux/global/global.actions';
import GetPrice from 'src/views/common/CurrencySymbol/GetPrice';

const styles = (theme) => ({
  dtitle: {
    color: '#fff',
    fontWeight: 'bold',
    fontSize: '33px',
    lineHeight: '42px'
  },
  titlebk: {
    display: 'inline-block',
    padding: '25px 24px 5px'
  },
  cutom_select_Label: {
    fontSize: '12px',
    padding: '7px 0'
  },
  Discountinput: {
    margin: '0px',
    display: 'inline-flex',
    width: '97px',
    marginLeft: '15px',
    border: '1px solid #a9a9a9',
    height: '48px',
    padding: '9px 15px 7px'
  },
  DiscounFormControl: {
    width: '95px',
    height: '48px',
    marginLeft: '20px',
    '& .MuiOutlinedInput-input': {
      padding: '14.5px 8px'
    }
  },
  Discounsign: {
    '& p': {
      color: 'rgb(41 41 41 / 0.4)'
    }
  },
  amountBk: {
    fontWeight: 'bold',
    fontSize: '14px',
    marginBottom: '18px',
    '& label': {
      color: '#eae4e4'
    },
    '& span': {
      color: '#fff',
      marginLeft: '3px'
    }
  },
  DialogPopup: {
    '& .MuiPaper-root': {
      background: theme.masterhq.mainCard.background
    },
    '& .MuiButton-contained.Mui-disabled': {
      color: 'rgb(0 0 0 / 38%)',
      boxShadow: 'none',
      backgroundColor: 'rgb(59 60 78)'
    }
  },
  DialogCss: {
    background: theme.masterhq.mainCard.background
  }
});

const ApplyFilterStyle = {
  overrides: {
    MuiPaper: {
      root: {
        backgroundColor: '#3c3d4f',
        color: '#ffffff'
      }
    },
    MuiAutocomplete: {
      endAdornment: {
        '& svg': {
          color: '#fff'
        }
      }
    },
    MuiFormControl: {
      root: {
        '&.cutom_select div input': {
          color: '#fff !important',
          '&::placeholder': {
            color: '#fff !important'
          }
        }
      }
    }
  }
};

function PayInvoiceDiag(props) {
  const { classes, handleClose, setOpen, data, paymentMeth } = props;
  const history = useHistory();
  const [userpaymenthod, setuserpaymentmeth] = useState(null);
  const [isprocessing, setisprocessing] = useState(false);

  const handleSave = async () => {
    const { OpenNoti } = props;
    try {
      const b = {
        invoice_id: data.id,
        user_payment_method_id: userpaymenthod.id
      };
      setisprocessing(true);
      const arr = await payInvoice(b);

      if (arr?.data.status === 2 && arr.success) {
        history.push({
          pathname: ROUTES.MASTER_HQ_FINANCIAL_INVOICES_PAIND_VIEW,
          search: `Invoice=${btoa(data.id)}`
        });
        OpenNoti(arr.message, 'info');
        setisprocessing(false);
      } else {
        OpenNoti(arr.message, 'error');
        setisprocessing(false);
        handleClose();
      }
    } catch (err) {
      OpenNoti(getErrorMsg(err), 'error');
      setisprocessing(false);
    }
  };
  return (
    <div>
      <Dialog
        open={setOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick
        className={classes.DialogPopup}
      >
        <div style={{ width: '548px' }} className={classes.DialogCss}>
          <Grid alignItems="center" container direction="row">
            <Grid item xs>
              <DialogTitle id="alert-dialog-title" className={classes.titlebk}>
                <h4 className={classes.dtitle}>
                  Pay Invoice{' '}
                  {data.invoice_no || String(data.id).padStart(7, '0')}
                </h4>
              </DialogTitle>
            </Grid>
            <Grid
              item
              xs={2}
              style={{ textAlign: 'right', marginRight: '10px' }}
            >
              <Button className={classes.btns} onClick={handleClose}>
                <img alt="img" src={dcloseicon} />
              </Button>
            </Grid>
          </Grid>

          <DialogContent>
            <Grid container spacing={2} style={{ marginTop: '5px' }}>
              <Grid item xs={12} style={{}}>
                <Box
                  display="inline-flex"
                  alignItems="center"
                  className={classes.amountBk}
                >
                  <label>Unpaid amount:</label>
                  <GetPrice value={data.final_subtotal} />
                </Box>
                <Box>
                  <InputLabel
                    htmlFor="age-required"
                    className={classes.cutom_select_Label}
                    style={{ margin: '0px 0px 5px', padding: '0 0' }}
                  >
                    Payment method
                  </InputLabel>
                  <ThemeProvider theme={ApplyFilterStyle}>
                    <CustomAutoComplete
                      holder="Select payment method"
                      Options="card_view"
                      value={paymentMeth}
                      data={userpaymenthod}
                      disabled={isprocessing}
                      Change={(e) => setuserpaymentmeth(e)}
                    />
                  </ThemeProvider>
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions
            style={{
              padding: '20px 20px'
            }}
          >
            <JBButton
              type="primary"
              disabled={!userpaymenthod || isprocessing}
              onClick={handleSave}
            >
              {isprocessing ? 'Processing..' : 'complete payment'}
            </JBButton>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}

PayInvoiceDiag.propTypes = {
  classes: PropType.object.isRequired,
  setOpen: PropType.bool.isRequired,
  handleClose: PropType.func.isRequired,
  data: PropType.object.isRequired,
  OpenNoti: PropType.func.isRequired,
  paymentMeth: PropType.array.isRequired
};

const mapDispatchToProps = (dispatch) => ({
  OpenNoti: (msg, type) => dispatch(openNoti(msg, type))
});

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(PayInvoiceDiag));
