import moment from 'moment';
// import { backendDate } from 'src/utils/someCommon';
import { DateFilteroption } from 'src/views/reports/AttendanceReports/Filters/static.Data';
import {
  attendacesetClass,
  attendanceSetProgram,
  resetFilter,
  setattendanceday,
  SetNextDate,
  SetPrevDate,
  setStartTime
} from './attendanceReducer.types';

export const initialValue = {
  program: null,
  day: DateFilteroption.filter((filter) => filter.filter === 'last30day')[0],
  prevdate: new Date(),
  nextdate: moment()
    .add(1, 'day')
    .toDate(),
  start_time: null,
  error: 'Please select Program, Date, and Class to view the workout',
  data: null
};

export function attendanceReducer(state = initialValue, action) {
  switch (action.type) {
    case attendanceSetProgram:
      return {
        ...state,
        program: action.payload,
        class: null
      };

    case attendacesetClass:
      return {
        ...state,
        class: action.payload,
        error: action.payload === null ? 'Please select a class' : state.error
      };

    case setattendanceday:
      return {
        ...state,
        day: action.payload
      };

    case SetPrevDate:
      return {
        ...state,
        prevdate: action.payload
      };
    case setStartTime:
      return {
        ...state,
        start_time: action.payload
      };
    case SetNextDate:
      return {
        ...state,
        nextdate: action.payload
      };
    case resetFilter:
      return {
        ...initialValue
      };
    default:
      return state;
  }
}
