import React from 'react';
import { ROUTES } from '../../constant/Constants';

import { ReactComponent as People } from '../../assets/image/menu-svgicon/users.svg';
import { ReactComponent as Financial } from '../../assets/image/menu-svgicon/dollarsign.svg';
import { ReactComponent as Reports } from '../../assets/image/menu-svgicon/clipboard.svg';

const Navroutes = [
  {
    id: 1,
    icon: <People />,
    name: 'ALLIES',
    roles: [1],
    href: ROUTES.MASTER_HQ_AFFILIATES,
    nested: []
  },
  {
    id: 2,
    icon: <Financial />,
    name: 'FINANCIAL',
    roles: [1],
    // href: '',
    href: ROUTES.MASTER_HQ_FINANCIAL_INVOICES,
    nested: []
  },
  {
    id: 3,
    icon: <Reports />,
    name: 'Reports',
    roles: [1],
    href: ROUTES.MASTER_HQ_REPORTS_FINANCIAL,
    nested: []
  }
];

export default Navroutes;
