/* eslint-disable arrow-parens */
import React, { PureComponent } from 'react';
import { ROUTES } from 'src/constant/Constants';
import PropTypes from 'prop-types';
import { Link, Route, Switch } from 'react-router-dom';
import {
  Grid,
  withStyles,
  AppBar,
  Tabs,
  Tab,
  Card,
  Breadcrumbs,
  Typography
} from '@material-ui/core';
import styles from './FinancialSettings.style';
import InvoiceConfiguration from './InvoiceConfiguration';
import RevenueCategorys from './Revenuecategorys';
import CategoryDialog from './CategoryDialog';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

class FinancialSettings extends PureComponent {
  render() {
    const { classes, location } = this.props;

    const Tabslocation = location.pathname === ROUTES.FINANCIAL_SETTINGS_REVENUECATEGORY ? 1 : 0;
    return (
      <div className={classes.root}>
        <div className="title-bk">
          <div
            className={classes.new_programs}
            style={{ marginBottom: Tabslocation ? '15px' : '25px' }}
          >
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={0}
            >
              <Grid item xs={8}>
                <Breadcrumbs aria-label="breadcrumb">
                  <div color="inherit">financial</div>
                  <div color="inherit">Settings</div>
                  <Typography variant="h3" color="Primary">
                    {(() => {
                      switch (Tabslocation) {
                        case 0:
                          return <span>invoice configuration</span>;
                        case 1:
                          return <span>revenue category</span>;
                        default:
                          return null;
                      }
                    })()}
                  </Typography>
                </Breadcrumbs>
              </Grid>

              <Grid
                item
                xs={4}
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end'
                }}
              >
                {!Tabslocation ? null : (
                  <CategoryDialog
                    Buttonlabel="new Revenue category "
                    Dialogtitle="New Revenue Category"
                  />
                )}
              </Grid>
            </Grid>
          </div>
        </div>

        <Card className={classes.mainCard}>
          <AppBar
            position="static"
            style={{
              backgroundColor: 'transparent',
              boxShadow: 'none'
            }}
          >
            <Tabs
              value={Tabslocation}
              indicatorColor="primary"
              textColor="primary"
              fullWidth
            >
              <Tab
                label="invoice configuration"
                component={Link}
                to={ROUTES.FINANCIAL_SETTINGS}
                className={classes.p_tabs}
                {...a11yProps(0)} // eslint-disable-line
              />
              <Tab
                label="revenue category"
                component={Link}
                to={ROUTES.FINANCIAL_SETTINGS_REVENUECATEGORY}
                className={classes.p_tabs}
                {...a11yProps(1)} // eslint-disable-line
              />
            </Tabs>
          </AppBar>

          <Switch>
            <Route
              path={ROUTES.FINANCIAL_SETTINGS_REVENUECATEGORY}
              component={RevenueCategorys}
            />
            <Route
              path={ROUTES.FINANCIAL_SETTINGS}
              component={InvoiceConfiguration}
            />
          </Switch>
        </Card>
      </div>
    );
  }
}

FinancialSettings.propTypes = {
  location: PropTypes.any.isRequired,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(FinancialSettings);
