import React, { memo, useState } from 'react';
import PropType from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  DialogTitle,
  withStyles
} from '@material-ui/core';
import questionmark from 'src/assets/image/questionmark.png';
import JBButton from 'src/views/common/JBButton/JBButton';
import { reactivationAlly } from 'src/services/masterhq.allies.services';
import { withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { openNoti } from 'src/Redux/global/global.actions';
import { getErrorMsg } from 'src/utils/ErrorHandling';
import { ROUTES } from 'src/constant/Constants';
import queryString from 'query-string';

const styles = (theme) => ({
  Dialogtitle: {
    color: '#fff',
    padding: '16px 24px 0'
  },
  DialogActionsBk: {
    padding: '5px 20px 20px'
  },
  DialogPopup: {
    '& .MuiPaper-root': {
      background: theme.masterhq.mainCard.background
    }
  },
  DialogCss: {
    background: theme.masterhq.mainCard.background
  }
});

function ReactivateAlly(props) {
  const { classes, open, handleClose, location } = props;
  const [processing, setProcessing] = useState(() => false);
  const dispatch = useDispatch();
  const { affiliateId } = queryString.parse(location.search);

  const handleSave = (e) => {
    const { history } = props;
    e.stopPropagation();
    setProcessing(() => true);
    reactivationAlly(affiliateId)
      .then(() => dispatch(openNoti('Ally reactivated Successfully')))
      .then(() => handleClose())
      .then(() => history.push(ROUTES.MASTER_HQ_AFFILIATES))
      .catch((err) => dispatch(openNoti(getErrorMsg(err), 'error')))
      .finally(() => setProcessing(() => false));
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.DialogPopup}
        disableBackdropClick
      >
        <div style={{ width: 394 }} className={classes.DialogCss}>
          <DialogTitle id="alert-dialog-title" className={classes.Dialogtitle}>
            <img
              alt="Plusicon"
              src={questionmark}
              style={{ display: 'inline-block', verticalAlign: 'sub' }}
            />
            <Typography
              color="#000"
              variant="h5"
              component="div"
              className={classes.title}
              style={{ display: 'inline-block', marginLeft: '10px' }}
            >
              Reactivate this Ally
            </Typography>
          </DialogTitle>
          <DialogContent>
            <p
              style={{
                fontWeight: 'normal',
                fontSize: '14px',
                lineHeight: '18px',
                letterSpacing: '0.002em',
                color: '#7F7F7F',
                padding: '10px 0'
              }}
            >
              Are you sure you want to reactivate this Ally?
            </p>
          </DialogContent>
          <DialogActions className={classes.DialogActionsBk}>
            <Button
              variant="outlined"
              onClick={handleClose}
              style={{
                height: '48px',
                color: '#7F7F7F',
                fontWeight: 'bold'
              }}
            >
              Cancel
            </Button>
            <JBButton type="primary" onClick={handleSave} disabled={processing}>
              {processing ? 'Reactivating..' : 'Confirm'}
            </JBButton>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}

ReactivateAlly.propTypes = {
  classes: PropType.object.isRequired,
  open: PropType.bool.isRequired,
  handleClose: PropType.func.isRequired,
  history: PropType.object.isRequired,
  location: PropType.object.isRequired
};

export default withStyles(styles)(memo(withRouter(ReactivateAlly)));
