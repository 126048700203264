import React, { memo, useState, useMemo } from 'react';
import { Box, Typography } from '@material-ui/core';
import moment from 'moment';
import DOMPurify from 'dompurify';
import CustomizedSlider from './CustomizedSlider';
import { updateFontSize } from './chalkBoard.utils';
import useChangeTitle from '../common/CustomHooks/useTitleChange';

const ChalkBoardFullScreen = memo(() => {
  const [fontSize, setfontSize] = useState(30);
  const workoutdata = window.sessionStorage.getItem('workout-data');
  const workoutName = window.sessionStorage.getItem('workout-name');
  const PurifiedWorkout = useMemo(() => {
    if (workoutdata) {
      return updateFontSize(DOMPurify.sanitize(workoutdata));
    }
    return null;
  }, [workoutdata]);
  useChangeTitle('Chalkboard | Jungle Alliance');
  const onFullScreen = {
    position: 'relative',
    background: '#f9f9fa',
    width: 'calc(100%)',
    left: 'auto',
    right: '0',
    top: '0',
    zIndex: '9999',
    fontSize: `${fontSize}px`,
    height: '100%',
    padding: '15px 30px 30px 80px',
    wordBreak: 'break-word'
  };

  const WodName = {
    color: '#01847A',
    fontSize: '40px',
    fontFamily: 'Lato, sans-serif',
    margin: '0px 0 10px',
    fontWeight: '800'
  };

  // const handleClose = () => {
  //   if (workoutdata) {
  //     window.sessionStorage.removeItem('workout-data');
  //   }
  //   window.close();
  //   const win = window.open('', '_self');
  //   win.close();
  // };
  return (
    <div>
      <Box style={onFullScreen}>
        <Box mb="30px">
          <Typography
            variant="h2"
            color="primary"
            style={{ fontWeight: 900, zIndex: '99999', position: 'relative' }}
          >
            <span>Chalkboard</span>
            <label style={{ color: '#000', marginLeft: '15px' }}>
              {moment().format('ddd, DD MMMM YYYY')}
            </label>
          </Typography>
        </Box>
        <div>
          <CustomizedSlider setFont={setfontSize} fontSize={fontSize} />
        </div>
        {/* <button type="button" onClick={handleClose}>
          Close Screen
        </button> */}
        {!PurifiedWorkout ? (
          <h1>No Data is There</h1>
        ) : (
          <>
            <h4 style={WodName}>{workoutName}</h4>
            <Box
              dangerouslySetInnerHTML={{
                __html: PurifiedWorkout
              }}
              className="cke_contents_ltr cke_editable cke_editable_themed cke_show_borders"
              css={{
                fontSize: 'inherit',
                marginBottom: '60px'
              }}
            />
          </>
        )}
      </Box>
    </div>
  );
});

export default ChalkBoardFullScreen;
