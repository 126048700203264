import React, { memo } from 'react';
import { withStyles, IconButton } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import proptypes from 'prop-types';
// import JBButton from 'src/views/common/JBButton/JBButton';

const styles = () => ({
  AlertCss: {
    zIndex: '9999',
    position: 'fixed',
    left: '0',
    right: '0',
    borderRadius: '0'
  }
});

const JBStripeDialogue = (props) => {
  const { open, message, onClose, classes } = props;

  const handleClick = (e) => {
    e.stopPropagation();
    onClose();
  };

  return (
    // <Dialog
    //   open={open}
    //   aria-labelledby="alert-dialog-title"
    //   aria-describedby="alert-dialog-description"
    //   disableBackdropClick
    //   className={classes.Dialogtitle}
    // >
    //   {message}
    //   <JBButton onClick={handleClick}> Yes Close</JBButton>
    // </Dialog>
    <Alert
      variant="filled"
      severity="error"
      action={
        <IconButton
          aria-label="close"
          color="inherit"
          size="small"
          onClick={() => open()}
        >
          <CloseIcon fontSize="inherit" onClick={handleClick} />
        </IconButton>
      }
      className={classes.AlertCss}
    >
      {message}
    </Alert>
  );
};

JBStripeDialogue.propTypes = {
  open: proptypes.bool.isRequired,
  message: proptypes.string.isRequired,
  onClose: proptypes.func.isRequired,
  classes: proptypes.object.isRequired
};
export default memo(withStyles(styles)(JBStripeDialogue));
