import React from 'react';
import { useHistory } from 'react-router-dom';
import PropType from 'prop-types';
import clsx from 'clsx';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, withStyles } from '@material-ui/core';
import { remove } from 'js-cookie';
import questionmark from '../../../assets/image/questionmark.png';
import JBButton from '../JBButton/JBButton';

const styles = (theme) => ({
  Dialogtitle: {
    padding: '16px 24px 0'
  },
  HQDialogtitle: {
    color: '#fff'
  },
  DialogActionsBk: {
    padding: '5px 20px 20px'
  },
  DialogPopup: {
    '& .MuiPaper-root': {
      background: theme.masterhq.mainCard.background
    }
  }
});

function CancelDialog({ classes, handleHistory }) {
  const [open, setOpen] = React.useState(false);
  const history = useHistory();
  const masterhqDialog = history.location.pathname.includes('masterhq');
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <JBButton type="outlined" onClick={handleClickOpen}>
        Cancel
      </JBButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick
        className={clsx(masterhqDialog ? classes.DialogPopup : null)}
      >
        <div style={{ width: 394 }}>
          <DialogTitle
            id="alert-dialog-title"
            className={clsx(
              classes.Dialogtitle,
              masterhqDialog ? classes.HQDialogtitle : null
            )}
          >
            <img
              alt="Plusicon"
              src={questionmark}
              style={{ display: 'inline-block', verticalAlign: 'sub' }}
            />
            <Typography
              // color="#000"
              variant="h5"
              component="div"
              className={classes.title}
              style={{ display: 'inline-block', marginLeft: '10px' }}
            >
              Do you want to leave this page?
            </Typography>
          </DialogTitle>
          <DialogContent>
            <p
              style={{
                fontWeight: 'normal',
                fontSize: '14px',
                lineHeight: '18px',
                letterSpacing: '0.002em',
                color: '#7F7F7F',
                padding: '10px 0'
              }}
            >
              Changes you made may not be saved.
            </p>
          </DialogContent>
          <DialogActions className={classes.DialogActionsBk}>
            <Button
              variant="outlined"
              onClick={handleClose}
              style={{
                height: '48px',
                color: '#7F7F7F',
                fontWeight: 'bold'
              }}
            >
              Cancel
            </Button>
            <JBButton
              type="primary"
              onClick={() => {
                remove('create_workout');
                // eslint-disable-next-line
                handleHistory
                  ? history.push(`${handleHistory}`)
                  : history.goBack();
              }}
            >
              {' '}
              Confirm
            </JBButton>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}
CancelDialog.defaultProps = {
  handleHistory: null
};

CancelDialog.propTypes = {
  classes: PropType.object.isRequired,
  // handleDelete: PropType.func.isRequired,
  handleHistory: PropType.string
};
export default withStyles(styles)(CancelDialog);
