import React, { memo, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import PaginateData from 'src/views/common/JBTables/PaginateData';
import { getAllAllies } from 'src/Redux/MasterHQ/AlliesReducer/allies.reducer.actions';
import { withStyles, Grid, InputBase } from '@material-ui/core';
import { debounce } from 'lodash';
import filterType from 'src/Redux/Reducer.constants';
import LoadingView from 'src/views/common/loading';
import Pagination from '@material-ui/lab/Pagination';
import useChangeTitle from 'src/views/common/CustomHooks/useTitleChange';
import AffiliatesTable from './affiliatestable';
import styles from './affiliatestable.style';

const AllTabs = ({ classes }) => {
  const { isFetching, data, error } = useSelector(
    (state) => state.masterHqReducer.alliesReducer.allAllies
  );
  useChangeTitle('All | Jungle Alliance');
  const { search, sorting } = useSelector(
    (state) => state.masterHqReducer.alliesReducer.allAlliesFilters
  );
  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState(() => data?.current_page || 1);
  const [searchText, setSearchText] = useState(() => search);

  const SearchQuery = useCallback(
    debounce((value) => {
      dispatch(getAllAllies(filterType.search, value));
    }, 400),
    []
  );

  if (error) {
    return <p>{error}</p>;
  }

  const handlePage = (_, e) => {
    if (e !== currentPage) {
      setCurrentPage(e);
      setTimeout(() => {
        dispatch(getAllAllies(filterType.page, e));
      }, 200);
    }
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
    SearchQuery(e.target.value);
  };

  const setSort = (sortlabel) => {
    dispatch(getAllAllies(filterType.sorting, sortlabel));
  };

  return (
    <Grid container disableGutters>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
        style={{ marginTop: '15px' }}
      >
        <Grid item xs>
          <div className={classes.search}>
            <InputBase
              fullWidth
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.searchInput
              }}
              InputProps={{
                'aria-label': 'search'
              }}
              value={searchText}
              onChange={handleSearch}
            />
          </div>
        </Grid>
      </Grid>
      <Grid
        container
        disableGutters
        style={{ marginTop: '20px', marginBottom: '20px' }}
      >
        {isFetching ? (
          <LoadingView />
        ) : (
          <AffiliatesTable
            values={data?.data || []}
            active={sorting?.sort_name}
            direction={sorting?.sort_by === 'asc' ? 'asc' : 'dsc'}
            setSort={setSort}
          />
        )}
      </Grid>
      <Grid
        item
        xs={12}
        container
        direction="row"
        alignItems="center"
        style={{ marginTop: 24 }}
      >
        <Grid item xs={6} justify="flex-start" className={classes.PaginateData}>
          {!isFetching && data ? <PaginateData data={data} /> : null}
        </Grid>
        <Grid
          item
          xs={6}
          justify="flex-end"
          style={{ display: 'flex' }}
          className={classes.Pagination}
        >
          <Pagination
            count={data?.last_page || currentPage + 1}
            page={currentPage}
            onChange={handlePage}
            color="primary"
            variant="outlined"
            shape="rounded"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

AllTabs.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(memo(AllTabs));
