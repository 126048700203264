import React, { useCallback, useState } from 'react';
import PropType from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import {
  Grid,
  InputBase,
  InputLabel,
  Paper,
  Box,
  withStyles
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import useChangeTitle from 'src/views/common/CustomHooks/useTitleChange';
import styles from './table.style';
import { tableHead } from './staticData';
import filterTypes from '../../../Redux/Reducer.constants';
import { getSession } from '../../../Redux/MembershipReducer/MembershipReducer.actions';
import LoadingView from '../../common/loading';
import MemTable from './Membership.Table';

const SessionTable = (props) => {
  const {
    sessionplan: { isFetching, data, error },
    sessSort,
    sessFilter,
    sessSearch,
    classes
  } = props;
  const [search, setSearch] = useState(sessSearch);
  useChangeTitle('Session Templates | Jungle Alliance');
  const SearchQuery = useCallback(
    _.debounce((q) => sessFilter(filterTypes.search, q), 1000),
    []
  );

  if (error) return <p>{error}</p>;

  // eslint-disable-next-line
  const handlePaginate = (_, val) => {
    if (data.current_page !== val) {
      sessFilter(filterTypes.page, val);
    }
    return null;
  };

  const setDirection = (name) => {
    if (sessSort.sort_name === name) {
      return sessSort.sort_by === 'asc' ? 'asc' : 'dsc';
    }
    return 'asc';
  };

  const isActive = (sortlabel) => {
    if (sessSort.sort_name === sortlabel) return true;
    return false;
  };
  const setSort = (sortlabel) => {
    sessFilter(filterTypes.sorting, sortlabel);
  };

  return (
    <div>
      <Grid
        container
        spacing={2}
        disableGutters
        style={{ marginBottom: '1rem' }}
      >
        <Box mt={1}>
          <InputLabel
            htmlFor="age-required"
            className={classes.cutom_select_Label}
          >
            &nbsp;
          </InputLabel>
        </Box>
        <Grid item xs>
          <InputLabel
            htmlFor="age-required"
            className={classes.cutom_select_Label}
          >
            &nbsp;
          </InputLabel>
          <div className={classes.search}>
            <InputBase
              fullWidth
              placeholder="Search…"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
                SearchQuery(e.target.value);
              }}
              classes={{
                root: classes.inputRoot,
                input: classes.searchInput
              }}
              InputProps={{
                'aria-label': 'search'
              }}
            />
          </div>
        </Grid>
      </Grid>
      <div className={classes.root}>
        {isFetching ? (
          <LoadingView />
        ) : (
          <>
            <MemTable
              values={data.data}
              tableHead={tableHead}
              setDirection={setDirection}
              isActive={isActive}
              setSort={setSort}
            />
            <Grid
              item
              xs={12}
              container
              direction="row"
              alignItems="center"
              style={{ marginTop: 24 }}
            >
              <Grid item xs={6} justify="flex-start">
                {data.from && data.to > 1 ? (
                  <Paper elevation={0} style={{ color: '#A9A9A9' }}>
                    {`${data.from} - ${data.to} of ${data.total}`}
                  </Paper>
                ) : null}
              </Grid>
              <Grid item xs={6} justify="flex-end" style={{ display: 'flex' }}>
                <Pagination
                  color="primary"
                  count={data.last_page}
                  page={data.current_page}
                  onChange={handlePaginate}
                  variant="outlined"
                  shape="rounded"
                />
              </Grid>
            </Grid>
          </>
        )}
      </div>
    </div>
  );
};
SessionTable.propTypes = {
  classes: PropType.object.isRequired,
  sessionplan: PropType.object.isRequired,
  sessSearch: PropType.string.isRequired,
  sessFilter: PropType.func.isRequired,
  sessSort: PropType.object.isRequired
};

const mapToState = (state) => ({
  sessionplan: state.membershipReducer.sessions,
  sessSearch: state.membershipReducer.sessionFilter.search,
  sessSort: state.membershipReducer.sessionFilter.sorting
});

const mapToDispatch = (dispatch) => ({
  sessFilter: (filter, data) => dispatch(getSession(filter, data))
});

export default connect(
  mapToState,
  mapToDispatch
)(withStyles(styles)(SessionTable));
