import React from 'react';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import PropType from 'prop-types';
import filterType from 'src/Redux/Reducer.constants';
import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  ThemeProvider,
  Tooltip
} from '@material-ui/core';
import {
  AllClassdata,
  futureddata
} from 'src/Redux/ClassReducer/classReducer.actions';
import { ROUTES } from 'src/constant/Constants';
import { get } from 'js-cookie';
import { ReactComponent as Eye } from '../../../../assets/image/eye.svg';
import { addReccIdFil } from './ListFilters/classFilters.utils';

const useStyles = makeStyles({
  table: {
    minWidth: '100%',
    wordBreak: 'break-word'
  },
  tableLink: {
    color: '#01847A',
    fontWeight: '700',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  dayofweek: {
    background: '#A9BFEF',
    borderRadius: '50px',
    padding: '5px',
    color: '#000',
    textTransform: 'capitalize'
  },
  TooltipLink: {
    '&:hover': {
      '& svg path': {
        stroke: '#01847A'
      }
    }
  },
  TableCell: {
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px',
    borderBottom: '1px solid #A8A8A8',
    height: '56px',
    minHeight: '56px'
  }
});

const tooltiptheme = {
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: '14px',
        fontWeight: '700',
        color: '#FFF',
        backgroundColor: '#01847A',
        borderRadius: '2px',
        padding: '10px 8px'
      },
      arrow: {
        color: '#01847A'
      }
    }
  }
};

const ReccurTable = (props) => {
  const classes = useStyles();
  const { values, isActive, setDirection, setSort } = props;
  const { sortname, sortlocation, sortstarttime, sortendtime } = filterType;
  const history = useHistory();
  const roleid = JSON.parse(get('jbrole') || '{}')?.role_id || 3;

  const addFilter = (id) => {
    const { getAllClass, getFuture } = props;
    getAllClass(filterType.addcustom, addReccIdFil(id));
    getFuture(filterType.addcustom, addReccIdFil(id));
    history.push(ROUTES.CLASSES_LIST_All);
  };
  return (
    <TableContainer component={Paper} elevation={0}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow style={{ background: '#F9F9FA' }}>
            <TableCell className={classes.TableCell} style={{ width: '20%' }}>
              <TableSortLabel
                active={isActive(sortname)}
                direction={setDirection(sortname)}
                onClick={() => setSort(sortname)}
              >
                Name
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell}>Program</TableCell>
            <TableCell className={classes.TableCell}>
              <TableSortLabel
                active={isActive(sortlocation)}
                direction={setDirection(sortlocation)}
                onClick={() => setSort(sortlocation)}
              >
                Location
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell} style={{ width: '20%' }}>
              Day of week
            </TableCell>
            <TableCell className={classes.TableCell}>
              <TableSortLabel
                active={isActive(sortstarttime)}
                direction={setDirection(sortstarttime)}
                onClick={() => setSort(sortstarttime)}
              >
                Start time
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell}>
              <TableSortLabel
                active={isActive(sortendtime)}
                direction={setDirection(sortendtime)}
                onClick={() => setSort(sortendtime)}
              >
                End time
              </TableSortLabel>
            </TableCell>
            <TableCell
              align="right"
              style={{ width: '5%' }}
              className={classes.TableCell}
            >
              &nbsp;
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {values.map((row) => (
            <TableRow key={row.id}>
              <TableCell>
                {roleid <= 2 ? (
                  <Link
                    to={{
                      pathname: ROUTES.CLASSES_EDIT,
                      search: `RecurrId=${row.id}`
                    }}
                    className={classes.tableLink}
                  >
                    {row.name}
                  </Link>
                ) : (
                  <span className={classes.tableLink}>{row.name}</span>
                )}
              </TableCell>
              <TableCell>{row.program_name}</TableCell>
              <TableCell>{row.location || null}</TableCell>
              <TableCell>
                {row.day_of_week && (
                  <span
                    className={classes.dayofweek}
                    style={{
                      backgroundColor: row.color,
                      color: row.color === '#000000' ? '#fff' : null
                    }}
                  >
                    {row.day_of_week.split(',').join(' ')}
                  </span>
                )}
              </TableCell>
              <TableCell>{row.start_time}</TableCell>
              <TableCell>{row.end_time}</TableCell>
              <TableCell align="right">
                <ThemeProvider theme={tooltiptheme}>
                  <Tooltip title="Show classes" arrow placement="top">
                    <span
                      className={classes.TooltipLink}
                      onClick={() => addFilter(row.id)}
                    >
                      <Eye />
                    </span>
                  </Tooltip>
                </ThemeProvider>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {values.length === 0 && (
        <p
          style={{
            margin: '10px 15px'
          }}
        >
          No Classes to show...
        </p>
      )}
    </TableContainer>
  );
};
ReccurTable.propTypes = {
  values: PropType.array.isRequired,
  isActive: PropType.func.isRequired,
  setDirection: PropType.func.isRequired,
  setSort: PropType.func.isRequired,
  getFuture: PropType.func.isRequired,
  getAllClass: PropType.func.isRequired
};

const mapToDispatch = (dispatch) => ({
  getFuture: (filter, data) => dispatch(futureddata(filter, data)),
  getAllClass: (filter, data) => dispatch(AllClassdata(filter, data))
});

export default connect(null, mapToDispatch)(ReccurTable);
