import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles, Box, Dialog, DialogContent } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { openNoti } from 'src/Redux/global/global.actions';
import { ROUTES } from 'src/constant/Constants';
import { reactivateMemberProfile } from 'src/services/Members.module';
import { getErrorMsg } from 'src/utils/ErrorHandling';
import SuccessPopup from '../ConvertMember/successPopup';
import { ReactComponent as OffButtonIcon } from '../../../../assets/image/offButtonIcon.svg';
import JBVanguardButton from '../../../common/JBVanguardButton/JBButton';

const useStyles = makeStyles(() => ({
  successBox: {
    background: 'rgba(29, 29, 29, 0.94)',
    position: 'absolute',
    left: '0',
    right: '0',
    top: '0',
    height: '100%',
    width: '100%',
    margin: '0 auto',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    zIndex: '9999',
    // background: rgb(43 43 43 / 0.95);
    // opacity: 0.95;
    justifyContent: 'center'
  },
  successText: {
    color: '#fff',
    marginTop: '31px',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '32px',
    lineHeight: '42px'
  },
  DialogBox: {
    backgroundColor: 'transparent',
    '& .MuiDialog-paper': {
      minWidth: '100%',
      minHeight: '100%',
      backgroundColor: 'transparent'
    }
  }
}));

const ReactivateMemberPopup = (props) => {
  const { handleClose, setOpen, userId } = props;
  const history = useHistory();
  const classes = useStyles();
  const reduxDispatch = useDispatch();
  const [Sussopen, setSussopen] = useState(false);
  const handleReactivate = () => {
    const value = { user_id: userId };
    reactivateMemberProfile(value)
      .then(() => {
        setSussopen(true);
      })
      .catch((err) => reduxDispatch(openNoti(getErrorMsg(err), 'error')));
  };
  return (
    <Dialog
      fullScreen
      open={setOpen}
      onClose={() => handleClose()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.DialogBox}
    >
      <DialogContent
        style={{
          minWidth: '100%',
          minHeight: '100%',
          backgroundColor: 'transparent'
        }}
      >
        {/* reactivate  */}
        <div className={classes.successBox}>
          <Box>
            <OffButtonIcon />
            <h3 className={classes.successText}>
              Are you sure you want to <br /> reactivate this member?
            </h3>
            <JBVanguardButton
              type="outlined"
              style={{
                marginTop: '32px',
                marginLeft: '15px',
                width: '250px',
                minHeight: '70px'
              }}
              onClick={() => {
                handleClose();
                history.push(ROUTES.VANGUARD);
              }}
            >
              Cancel
            </JBVanguardButton>
            <JBVanguardButton
              type="primary"
              style={{
                marginTop: '32px',
                marginLeft: '15px',
                width: '250px',
                minHeight: '70px'
              }}
              onClick={() => {
                handleReactivate();
              }}
            >
              Continue
            </JBVanguardButton>
          </Box>
        </div>
        {/* end reactivate */}

        {/* this activated Member design here */}
        {/* <div className={classes.successBox}>
          <Box>
            <OffButtonIcon />
            <h3 className={classes.successText}>
              Member activated successfully
            </h3>
            <JBVanguardButton
              type="primary"
              style={{
                marginTop: '32px',
                marginLeft: '15px',
                width: '250px',
                minHeight: '70px'
              }}
              onClick={() => {
                handleClose();
                history.push(ROUTES.VANGUARD);
              }}
            >
              Continue
            </JBVanguardButton>
          </Box>
        </div> */}
        {/* end activated */}
      </DialogContent>
      <SuccessPopup
        Dialogtitle="Member activated successfully"
        setOpen={Sussopen}
        handleClose={() => {
          setSussopen(false);
          handleClose();
        }}
      />
    </Dialog>
  );
};

// ReactivateMemberPopup.defaultProps = {
//   Dialogtitle: 'created successfully'
// };

ReactivateMemberPopup.propTypes = {
  // Dialogtitle: PropTypes.string,
  setOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired
};
export default ReactivateMemberPopup;
