import {
  getCoachData,
  getOneTimeClass,
  getReccClassList
} from 'src/services/classModule.services';
import constType from './class.constant';
import Types from './classReducer.Types';
import { store } from '../rootReducer';
import { getErrorMsg } from '../../utils/ErrorHandling';

// filter Actions
const futureClassFilter = (filter, data) => ({
  type: Types.futureFilter,
  filter,
  data
});

const AllClassFilter = (filter, data) => ({
  type: Types.allFilter,
  filter,
  data
});

const recurrClassFilter = (filter, data) => ({
  type: Types.recurrFilter,
  filter,
  data
});

export const getoneTimeClass = (val) => {
  // eslint-disable-next-line
  return new Promise((res, _) => {
    setTimeout(() => {
      return res({ ...val });
    }, 4000);
  });
};

const fetchingClass = (type) => ({
  type,
  actionType: constType.isFetching
});

const fetchComplete = (type, payload) => ({
  type,
  actionType: constType.fetchSucess,
  payload
});

const ErrorFetching = (type, payload) => ({
  type,
  actionType: constType.Errorfetch,
  payload
});

const getCoaches = (payload) => ({
  type: Types.getCoaches,
  payload
});

export const toggleSelectAll = (payload) => ({
  type: Types.allSelected,
  payload
});
// get Classes function
export const getFutureClass = (val) => {
  const type = Types.futureClass;
  return (dispatch) => {
    dispatch(fetchingClass(type));
    getOneTimeClass(val)
      .then((resp) => {
        dispatch(fetchComplete(type, resp));
      })
      .catch((err) => dispatch(ErrorFetching(type, getErrorMsg(err))));
  };
};

export const getAllClass = (val) => {
  const type = Types.AllClass;
  return (dispatch) => {
    dispatch(fetchingClass(type));
    getOneTimeClass(val)
      .then((res) => dispatch(fetchComplete(type, res)))

      .catch((err) => dispatch(ErrorFetching(type, getErrorMsg(err))));
  };
};

export const getReccClass = (val) => {
  const type = Types.recurrClass;
  return (dispatch) => {
    dispatch(fetchingClass(type));
    getReccClassList(val)
      .then((res) => dispatch(fetchComplete(type, res)))
      .catch((err) => dispatch(ErrorFetching(type, getErrorMsg(err))));
  };
};

// filter Async
export const futureddata = (filter, data) => {
  return (dispatch) => {
    dispatch(futureClassFilter(filter, data));
    const filData = store.getState().classReducer.futureClassFilter;
    let updatedValue = {
      page: filData.page,
      search: filData.search,
      ...filData.sorting,
      ...filData.default
    };
    if (Object.keys(filData.customFilter).length > 0) {
      updatedValue = {
        ...updatedValue,
        ...filData.customFilter
      };
    }
    dispatch(getFutureClass(updatedValue));
  };
};

export const AllClassdata = (filter, data) => {
  return (dispatch) => {
    dispatch(AllClassFilter(filter, data));
    const filData = store.getState().classReducer.allClassFilter;
    let updatedValue = {
      page: filData.page,
      search: filData.search,
      ...filData.default,
      ...filData.sorting
    };
    if (Object.keys(filData.customFilter).length > 0) {
      updatedValue = {
        ...updatedValue,
        ...filData.customFilter
      };
    }
    dispatch(getAllClass(updatedValue));
  };
};

export const reccurdata = (filter, data) => {
  return (dispatch) => {
    dispatch(recurrClassFilter(filter, data));
    const filData = store.getState().classReducer.recurrFilter;
    let updatedValue = {
      page: filData.page,
      search: filData.search,
      ...filData.default,
      ...filData.sorting
    };
    if (Object.keys(filData.customFilter).length > 0) {
      updatedValue = {
        ...updatedValue,
        ...filData.customFilter
      };
    }
    dispatch(getReccClass(updatedValue));
  };
};

// get Coaches Data
export const getAllCoaches = () => {
  return (dispatch) => {
    getCoachData().then((res) => dispatch(getCoaches(res)));
  };
};
