import moment from 'moment';

// eslint-disable-next-line
export const getUpdatedCalender = (data) => {
  const result = data;
  for (let i = 0; i < result.length; i += 1) {
    result[i] = {
      ...result[i],
      head_coach: result[i].head_coach_name
        ? {
          id: parseInt(result[i].head_coach_id),
          name: result[i].head_coach_name,
          coach_profile_pic: result[i].head_coach_profile
        }
        : null,
      co_coach: result[i].co_coach_name
        ? {
          id: parseInt(result[i].co_coach_id),
          name: result[i].co_coach_name,
          coach_profile_pic: result[i].co_coach_profile
        }
        : null,
      start_time: moment(
        result[i].start_date + ' ' + result[i].start_time
      ).toDate(),
      end_time: moment(result[i].start_date + ' ' + result[i].end_time).toDate()
    };
    delete result[i].head_coach_id;
    delete result[i].head_coach_name;
    delete result[i].co_coach_id;
    delete result[i].co_coach_name;
    delete result[i].co_coach_profile;
    delete result[i].head_coach_profile;
  }

  return result;
};
