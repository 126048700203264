function createData(id, name, calories, fat, carbs, protein) {
  return { id, name, calories, fat, carbs, protein };
}

export const rows = [
  createData(
    1,
    'Holiday Limber Up (wk 3/4)',
    'Lift',
    'Gym workout ',
    '25/01/2020'
  ),
  createData(
    2,
    'Holiday Limber Up (wk 2/4)',
    'Lift',
    'Gym workout ',
    '25/01/2020'
  ),
  createData(
    3,
    'Holiday Limber Up (wk 1/4)',
    'Lift',
    'Gym workout ',
    '25/01/2020'
  ),
  createData(4, 'Donut', 452, 25.0, 51),
  createData(5, 'Eclair', 262, 16.0, 24),
  createData(6, 'Frozen yoghurt', 159, 6.0, 24)
];

export function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export const isRowSelected = (name, selected) => {
  for (let i = 0; i < selected.length; i += 1) {
    if (selected[i].id === name) {
      return true;
    }
  }
  return false;
};

export const handleSelectRow = (arr, selected) => {
  const { id } = arr;
  let index = null;
  let resulted = [];
  for (let i = 0; i < selected.length; i += 1) {
    if (selected[i].id === id) {
      index = i;
    }
  }
  if (index !== null) {
    for (let i = 0; i < selected.length; i += 1) {
      if (i === index) {
        i++;
      }
      if (i < selected.length) {
        resulted.push(selected[i]);
      }
    }
  } else {
    resulted = [...selected, arr];
  }
  return resulted;
};

export const handleAllCheck = (e, allvalues) => {
  if (e.target.checked) {
    return allvalues;
  }
  return [];
};
