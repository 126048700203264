import React, { useEffect, useReducer, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  FormControlLabel,
  InputLabel,
  Box,
  withStyles
} from '@material-ui/core';
import IOSSwitch from 'src/views/common/FormControl/IOSSwitch';
import { debounce } from 'lodash';
import { useDispatch } from 'react-redux';
import { openNoti } from 'src/Redux/global/global.actions';
import { updateMembershipSetting } from 'src/services/Setting.services';
import { getErrorMsg } from 'src/utils/ErrorHandling';
import Skeleton from '@material-ui/lab/Skeleton';
import CustomAutoComplete from '../../calendar/commonAutocomplete';
import Styles from './MembershipTabs.style';
import {
  attendanceReducer,
  initialValue
} from './AttendanceTabReducer/AttendanceReducer';
import {
  changeclass,
  changeContract,
  changeMemVis,
  changesessMem,
  changewaiver,
  chngeplanMem,
  fetchData,
  getAllData
} from './AttendanceTabReducer/AttendanceReducer.utils';
import {
  MembershipType,
  ShowClassType
} from './AttendanceTabReducer/Attendance.data';
import { getMemSetting } from '../../../services/Membersip.services';
import { updateAttendanceforapi } from './classSetting.utils';

const Attendancetabs = (props) => {
  const { classes } = props;
  const [state, dispatch] = useReducer(attendanceReducer, initialValue);
  const reduxdispatch = useDispatch();

  useEffect(() => {
    getMemSetting()
      .then((res) => dispatch(fetchData(getAllData(res))))
      .catch((err) => console.warn(err));
  }, []);

  function updateSetting(vals) {
    updateMembershipSetting(vals)
      .then(() => reduxdispatch(openNoti('Successfully saved the setting')))
      .catch((err) => reduxdispatch(openNoti(getErrorMsg(err), 'error')));
  }

  const [isLoading, setIsLoading] = useState(true);
  const updateAPI = useCallback(
    debounce((statedata, type, e) => {
      const { error, data } = updateAttendanceforapi(statedata, type, e);
      if (error) {
        reduxdispatch(openNoti(error, 'error'));
      } else if (data) {
        updateSetting(data);
      }
    }, 1000),
    []
  );

  const {
    membership_enforcement,
    contract_enforcement,
    waiver_enforcement
  } = state.data;

  return (
    <div className={classes.root}>
      <Box mt={2} mb={2}>
        <p className={classes.ptext}>
          Configure the factors that determine whether or not a member may sign
          in to class.
        </p>
      </Box>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        spacing={0}
        style={{ margin: '20px 0 20px' }}
      >
        <Grid item xs={9}>
          <h3 style={{ margin: '0 0' }}>Membership Enforcement</h3>
          <p className={classes.ptext}>
            Members wont be allowed to reserve or sign in to class without an
            active membership including the class program and location.
          </p>
        </Grid>
        <Grid item xs={3} style={{ textAlign: 'right' }}>
          <InputLabel
            htmlFor="age-required"
            className={classes.cutom_Switch_Label}
          >
            {membership_enforcement.visibility ? 'Enable' : 'Disable'}
          </InputLabel>
          <FormControlLabel
            control={<IOSSwitch disabled />}
            style={{ display: 'inline-block', marginRight: '0' }}
            checked={membership_enforcement.visibility}
            onChange={() => {
              dispatch(changeMemVis());
              updateAPI(
                state.data,
                'membership-visibilty',
                !membership_enforcement.visibility
              );
            }}
          />
        </Grid>
        {membership_enforcement.visibility ? (
          <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="flex-start"
            spacing={0}
            style={{ margin: '20px 0 20px' }}
          >
            <p className={classes.ptext}>
              Optionally restrict or warn members upon reservation or sign-in if
              all sessions are used on limited plan or session membership.
            </p>
            <Grid
              item
              xs={4}
              className={classes.time_box}
              style={{ marginTop: '10px', width: '100%' }}
            >
              <InputLabel
                htmlFor="age-required"
                className={classes.cutom_select_Label}
                style={{ margin: '3px 0' }}
              >
                Limited plan membership
              </InputLabel>
              <CustomAutoComplete
                holder="Select Limit plan membership"
                Options="name"
                style={{ marginbottom: '0' }}
                value={MembershipType}
                data={membership_enforcement.limited_plan_membership}
                Change={(val) => {
                  dispatch(chngeplanMem(val));
                  updateAPI(state.data, 'lmtplanmembership', val);
                }}
              />
            </Grid>
            <Grid
              item
              xs={4}
              className={classes.time_box}
              style={{ marginTop: '10px', width: '100%' }}
            >
              <InputLabel
                htmlFor="age-required"
                className={classes.cutom_select_Label}
                style={{ margin: '3px 0' }}
              >
                Session membership
              </InputLabel>
              <CustomAutoComplete
                holder="-"
                Options="name"
                style={{ marginbottom: '0' }}
                value={MembershipType}
                data={membership_enforcement.session_membership}
                Change={(val) => {
                  dispatch(changesessMem(val));
                  updateAPI(state.data, 'sessmembership', val);
                }}
              />
            </Grid>
            <Grid
              item
              xs={4}
              className={classes.time_box}
              style={{ marginTop: '10px', width: '100%' }}
            >
              <InputLabel
                htmlFor="age-required"
                className={classes.cutom_select_Label}
                style={{ margin: '3px 0' }}
              >
                Show classes in Jungle app
              </InputLabel>
              <CustomAutoComplete
                holder="-"
                Options="name"
                value={ShowClassType}
                data={membership_enforcement.show_classes_in_athlete_app}
                style={{ marginbottom: '0' }}
                Change={(val) => {
                  dispatch(changeclass(val));
                  updateAPI(state.data, 'showclasses', val);
                }}
              />
            </Grid>
          </Grid>
        ) : null}
      </Grid>
      <div className={classes.hr} />
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        spacing={0}
        style={{ margin: '20px 0 20px' }}
      >
        <Grid item xs={9}>
          <h3 style={{ margin: '0 0' }}>Contract Enforcement</h3>
          <p className={classes.ptext}>
            Members wont be allowed to reserve or sign in to class with a
            missing contract.
          </p>
        </Grid>
        <Grid item xs={3} style={{ textAlign: 'right' }}>
          {(contract_enforcement === true || contract_enforcement === false)
          && isLoading ? (
            <>
              <Skeleton />
              <Skeleton />
            </>
            ) : (
              <>
                <InputLabel
                  htmlFor="age-required"
                  className={classes.cutom_Switch_Label}
                >
                  {contract_enforcement ? 'Enable' : 'Disable'}
                </InputLabel>
                <FormControlLabel
                  control={<IOSSwitch />}
                  style={{ display: 'inline-block', marginRight: '0' }}
                  checked={contract_enforcement}
                  onChange={() => {
                    dispatch(changeContract());
                    updateAPI(
                      state.data,
                      'contract-enforcement',
                      !contract_enforcement
                    );
                    setIsLoading(false);
                  }}
                />
              </>
            )}
        </Grid>
      </Grid>
      <div className={classes.hr} />

      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        spacing={0}
        style={{ margin: '20px 0 20px' }}
      >
        <Grid item xs={9}>
          <h3 style={{ margin: '0 0' }}>Waiver Enforcement</h3>
          <p className={classes.ptext}>
            Members or leads wont be allowed to reserve or sign in to class with
            a missing waiver.
          </p>
        </Grid>
        <Grid item xs={3} style={{ textAlign: 'right' }}>
          {(waiver_enforcement === true || waiver_enforcement === false)
          && isLoading ? (
            <>
              <Skeleton />
              <Skeleton />
            </>
            ) : (
              <>
                <InputLabel
                  htmlFor="age-required"
                  className={classes.cutom_Switch_Label}
                >
                  {waiver_enforcement ? 'Enable' : 'Disable'}
                </InputLabel>
                <FormControlLabel
                  control={<IOSSwitch />}
                  style={{ display: 'inline-block', marginRight: '0' }}
                  checked={waiver_enforcement}
                  onChange={() => {
                    dispatch(changewaiver());
                    updateAPI(state.data, 'waiver-enforce', !waiver_enforcement);
                    setIsLoading(false);
                  }}
                />
              </>
            )}
        </Grid>
      </Grid>
      <div className={classes.hr} />
    </div>
  );
};
Attendancetabs.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(Styles)(Attendancetabs);
