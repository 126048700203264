/* eslint-disable indent */
import React, { useEffect, useState } from 'react';
import PropType from 'prop-types';
import {
  Grid,
  Typography,
  Box,
  withStyles,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import FormControl from 'src/views/common/FormControl/formControl';
import CancelDialog from 'src/views/common/CommonDialogBox/CancelDialog';
import IOSSwitch from 'src/views/common/FormControl/IOSSwitch';
import NotFoundView from 'src/views/errors/NotFoundView';
import LoadingView from 'src/views/common/loading';
import { checkQuillValid } from 'src/utils/someCommon';
import { checkValidString } from 'src/utils/validations';
import {
  addCommunication,
  deleteCommunication,
  reccuringClassCancel,
  createReccuringClassCancel
} from 'src/services/Communication.service';
import { openNoti } from 'src/Redux/global/global.actions';
import { connect } from 'react-redux';
import JBCKEditorPlaceholder from 'src/views/common/JBCKEditorPlaceholder';
import useChangeTitle from 'src/views/common/CustomHooks/useTitleChange';
import styles from '../../automatedemails.style';
import JBButton from '../../../../common/JBButton/JBButton';
import ResettemplateDialog from '../../ResettemplateDialog';
import { ReactComponent as AttachmentIcon } from '../../../../../assets/image/attachment.svg';

const RecurringCanceledStaff = ({ classes, openNotify }) => {
  const [error, setError] = useState(false);
  // let reset = location.state.data.data[7];
  const [data, setData] = useState(null);
  const [reset, setReset] = useState(null);
  const placeholdersList = [
    'memfirstname',
    'classname',
    'classdate',
    'classprogram',
    'classtime'
  ];
  useChangeTitle(
    'Recurring Class is Manually-Canceled - Staff | Jungle Alliance'
  );
  useEffect(() => {
    reccuringClassCancel()
      .then((res) => {
        setData({
          ...res,
          email_template: JSON.parse(res.email_template),
          setting: JSON.parse(res.setting),
          default_email_template: JSON.parse(res.default_email_template)
        });
        setReset({
          ...res,
          email_template: JSON.parse(res.default_email_template),
          setting: JSON.parse(res.setting),
          default_email_template: JSON.parse(res.default_email_template)
        });
      })
      .catch(() => setError(true));
  }, []);
  const handleCreate = () => {
    const formData = new FormData();
    formData.append('setting', JSON.stringify(data.setting));
    formData.append('subject', data.email_template.subject);
    formData.append('body', data.email_template.body);
    // formData.append('attachments[0]', data.email_template.attachments[0]);
    createReccuringClassCancel(formData) // pass id
      .then((res) => {
        setReset({
          ...res.data,
          email_template: JSON.parse(res.data.defaultemail_template),
          setting: JSON.parse(res.data.setting),
          default_email_template: JSON.parse(res.data.default_email_template)
        });
        openNotify('Email template successfully saved');
      })
      .catch((err) => openNotify(err, 'error'));
  };
  const updateAttach = (e, dataval) => {
    let flag = 0;
    let validate = 1;

    let local = [...dataval.email_template.attachments];
    const formData = new FormData();
    for (let i = 0; i < e.target.files.length; i += 1) {
      for (let j = 0; j < dataval.email_template.attachments.length; j += 1) {
        if (e.target.files[i].name === dataval.email_template.attachments[j]) {
          flag = 1;
          break;
        }
      }
      if (e.target.files[i].size / 1000000 > 20) {
        openNotify('File Exceed more than 20 MB', 'error');
        validate = 0;
        break;
      } else if (flag === 1) {
        openNotify('Duplicate file name found', 'error');
        flag = 0;
        validate = 0;
        break;
      }
      local = [...local, e.target.files[i].name];
      formData.append(`attachments[${i}]`, e.target.files[i]);
    }
    if (validate === 1) {
      addCommunication(14, formData)
        .then(() => {
          openNotify('Email template successfully saved');
          setData({
            ...dataval,
            email_template: {
              ...dataval.email_template,
              attachments: local
            }
          });
        })
        .catch((err) => openNotify(err, 'error'));

      validate = 1;
    }
  };

  const handleSave = () => {
    if (
      !data.email_template.subject || // eslint-disable-line
      checkValidString(data.email_template.subject)
    ) {
      openNotify('Please enter a valid subject', 'error');
    } else if (
      !data.email_template.body || // eslint-disable-line
      !checkQuillValid(data.email_template.body)
    ) {
      openNotify('Please enter a valid template body', 'error');
    } else {
      handleCreate();
    }
  };

  const handleReset = () => {
    setData(reset);
  };

  if (error) return <NotFoundView />;
  if (!data) return <LoadingView />;
  return (
    <Box>
      <Grid container direction="row">
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
        >
          <Grid item xs={9} xl={9}>
            <Typography variant="h5" style={{ color: '#000' }}>
              When should this email be sent?
            </Typography>
          </Grid>
          <Grid
            item
            xs={3}
            xl={3}
            justify="center"
            className="MuiGrid-container"
            direction="column"
          >
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    name="checkedB"
                    color="primary"
                    style={{
                      padding: '5px'
                    }}
                    disabled={!data.setting.send_admin_editable}
                    checked={data.setting.send_admin}
                    onChange={() => {
                      setData({
                        ...data,
                        setting: {
                          ...data.setting,
                          send_admin: !data.setting.send_admin * 1
                        }
                      });
                    }}
                  />
                }
                label="Send to all admins"
              />
            </Box>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    name="checkedB"
                    color="primary"
                    style={{
                      padding: '5px'
                    }}
                    disabled={!data.setting.send_staff_editable}
                    checked={data.setting.send_staff}
                    onChange={() => {
                      setData({
                        ...data,
                        setting: {
                          ...data.setting,
                          send_staff: !data.setting.send_staff * 1
                        }
                      });
                    }}
                  />
                }
                label="Send to all staff"
              />
            </Box>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    name="checkedB"
                    color="primary"
                    style={{
                      padding: '5px'
                    }}
                    disabled={!data.setting.send_coach_editable}
                    checked={data.setting.send_coach}
                    onChange={() => {
                      setData({
                        ...data,
                        setting: {
                          ...data.setting,
                          send_coach: !data.setting.send_coach * 1
                        }
                      });
                    }}
                  />
                }
                label="Send to all coaches"
              />
            </Box>
          </Grid>
        </Grid>
        <div className={classes.hr} />
        <Grid item xs={12}>
          <Grid
            item
            xs={12}
            className="switch-btn"
            style={{ marginBottom: '15px' }}
          >
            <FormControlLabel
              control={
                <IOSSwitch
                  checked={data.setting.enabled}
                  onChange={() => {
                    setData({
                      ...data,
                      setting: {
                        ...data.setting,
                        enabled: !data.setting.enabled * 1
                      }
                    });
                  }}
                />
              }
              label="Enabled?"
            />
          </Grid>
          <Box mb="30px">
            <FormControl
              control="input"
              label="Subject"
              style={{ margin: '0 0' }}
              value={data.email_template.subject}
              onChange={(val) => {
                setData({
                  ...data,
                  email_template: {
                    ...data.email_template,
                    subject: val.target.value
                  }
                });
              }}
            />
          </Box>
          <Box>
            {/* <JBEmailQuillEditor
              value={data.email_template.body}
              onChange={(val) => {
                setData({
                  ...data,
                  email_template: {
                    ...data.email_template,
                    body: val
                  }
                });
              }}
            /> */}
            <JBCKEditorPlaceholder
              data={data.email_template.body}
              holder={placeholdersList}
              onChange={(e) => {
                setData({
                  ...data,
                  email_template: {
                    ...data.email_template,
                    body: e.editor.getData()
                  }
                });
              }}
            />
          </Box>
          <Grid
            item
            xs={3}
            style={{
              marginTop: '20px',
              padding: '0px 0px 0px 0px'
            }}
          >
            {Array.isArray(data.email_template.attachments)
              ? data.email_template.attachments.map((item) => {
                  return (
                    <div>
                      <Box
                        mb="10px"
                        css={{
                          display: 'inline-block',
                          padding: '5px',
                          borderRadius: '2px',
                          background: 'rgba(224 224 224 / 72%)'
                        }}
                      >
                        <label>{item}</label>
                        <JBButton
                          size="small"
                          type="primary"
                          onClick={() => {
                            deleteCommunication(13, { attachment: item })
                              .then(() => {
                                setData({
                                  ...data,
                                  email_template: {
                                    ...data.email_template,
                                    attachments: data.email_template.attachments.filter(
                                      (item1) => item !== item1
                                    )
                                  }
                                });
                                openNotify('File deleted sucessfully');
                              })
                              .catch((err) => console.warn(err));
                          }}
                          className={classes.attachBtn}
                        >
                          <span>Delete</span>
                        </JBButton>
                      </Box>
                      {/* <p>{item}</p>
                      <button
                        onClick={() => {
                          deleteCommunication(13, { attachment: item })
                            .then((res) => {
                              setData({
                                ...data,
                                email_template: {
                                  ...data.email_template,
                                  attachments: data.email_template.attachments.filter(
                                    (item1) => item !== item1
                                  )
                                }
                              });
                              openNotify('File deleted sucessfully');
                            })
                            .catch((err) => console.warn(err));
                        }}
                      >
                        Delete
                      </button> */}
                    </div>
                  );
                })
              : null}
            <Box mt="15px">
              <input
                accept="image/*"
                className={classes.input}
                id="contained-button-file"
                multiple
                type="file"
                onChange={(e) => updateAttach(e, data)}
                style={{ display: 'none' }}
              />
              <label htmlFor="contained-button-file">
                <JBButton type="outlined" component="span">
                  <span>upload attachment</span>
                  <AttachmentIcon
                    style={{
                      verticalAlign: 'sub',
                      marginLeft: '10px'
                    }}
                  />
                </JBButton>
              </label>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Box mt="30px" />
      <Grid container direction="row" justify="flex-end" alignItems="center">
        <CancelDialog />
        <Box ml="10px">
          <ResettemplateDialog onReset={handleReset} />
        </Box>
        <Box ml="10px">
          <JBButton
            type="primary"
            onClick={handleSave}
            // component={Link}
            // to={ROUTES.PEOPLE_CREATE_MEMBER}
          >
            Save
          </JBButton>
        </Box>
      </Grid>
    </Box>
  );
};

RecurringCanceledStaff.propTypes = {
  classes: PropType.object.isRequired,
  openNotify: PropType.func.isRequired
  // history: PropType.any.isRequired
};
const mapToDispatch = (dispatch) => ({
  openNotify: (msg, type) => dispatch(openNoti(msg, type))
});
export default connect(
  null,
  mapToDispatch
)(withStyles(styles)(RecurringCanceledStaff));
