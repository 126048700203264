import authhttp from './authHttp.services';

// eslint-disable-next-line
export const getLeadSrc = async () => {
  const leadUrl = 'api/lead_source';
  const {
    data: { data }
  } = await authhttp.get(leadUrl);
  return data;
};
export const createLeadStatus = async (values) => {
  const leadUrl = 'api/lead_status';
  const {
    data: { data }
  } = await authhttp.post(leadUrl, values);
  return data;
};
export const getEditLeadStatus = async (id) => {
  const EditLeadStatusUrl = `api/lead_status/${id}/edit`;
  const {
    data: { data }
  } = await authhttp.get(EditLeadStatusUrl);
  return data;
};
export const EditLeadStatus = async (values, id) => {
  const EditLeadUrl = `api/lead_status/${id}`;
  const {
    data: { data }
  } = await authhttp.put(EditLeadUrl, values);
  return data;
};
export const createLeadSrc = async (values) => {
  const leadUrl = 'api/lead_source';
  const {
    data: { data }
  } = await authhttp.post(leadUrl, values);
  return data;
};
export const DeleteLeadSrc = async (id) => {
  const DeleteLeadSrcUrl = `api/lead_source/${id}`;
  const {
    data: { data }
  } = await authhttp.del(DeleteLeadSrcUrl);
  return data;
};
export const GetEditLeadSrc = async (id) => {
  const EditLeadSrcUrl = `api/lead_source/${id}/edit`;
  const {
    data: { data }
  } = await authhttp.get(EditLeadSrcUrl);
  return data;
};
export const EditLeadSrc = async (values, id) => {
  const EditLeadSrcUrl = `api/lead_source/${id}`;
  const {
    data: { data }
  } = await authhttp.put(EditLeadSrcUrl, values);
  return data;
};
export const createNewLead = async (values) => {
  const newUrlLead = 'api/store_leads';
  const { data } = await authhttp.post(newUrlLead, values);
  return data;
};
export const getAllActivity = async (values) => {
  const newUrlLead = 'api/user_activity_history';
  const {
    data: { data }
  } = await authhttp.get(newUrlLead, {
    params: values
  });
  return data;
};
export const editActivity = async (values, id) => {
  const updateMemUrl = `api/user_activity_history/${id}`;
  const data = await authhttp.put(updateMemUrl, values);
  return data;
};
export const getEditActivity = async (id) => {
  const newUrlLeadStatus = `api/user_activity_history/${id}/edit`;
  const {
    data: { data }
  } = await authhttp.get(newUrlLeadStatus);
  return data;
};
export const getActivityType = async () => {
  const newUrlLeadStatus = 'api/activity_type';
  const {
    data: { data }
  } = await authhttp.get(newUrlLeadStatus);
  return data;
};
export const getActivity_in_drop_downlist = async () => {
  const newUrlLeadStatus = 'api/activity_in_drop_downlist';
  const {
    data: { data }
  } = await authhttp.get(newUrlLeadStatus);
  return data;
};
export const createActivityType = async (values) => {
  const activityTypeUrl = 'api/activity_type';
  const {
    data: { data }
  } = await authhttp.post(activityTypeUrl, values);
  return data;
};
export const getEditActivityType = async (id) => {
  const activityTypeUrl = `api/activity_type/${id}/edit`;
  const {
    data: { data }
  } = await authhttp.get(activityTypeUrl);
  return data;
};
export const editActivityType = async (values, id) => {
  const activityTypeUrl = `api/activity_type/${id}`;
  const {
    data: { data }
  } = await authhttp.put(activityTypeUrl, values);
  return data;
};
export const deleteActivityType = async (id) => {
  const activityTypeUrl = `api/activity_type/${id}`;
  const {
    data: { data }
  } = await authhttp.del(activityTypeUrl);
  return data;
};
export const getleadStatus = async (values) => {
  const newUrlLeadStatus = 'api/lead_status';
  const { data } = await authhttp.get(newUrlLeadStatus, values);
  return data;
};
export const getLeadProfile = async (id) => {
  const leadUrl = `api/show_lead_profile/${id}`;
  const {
    data: { data }
  } = await authhttp.get(leadUrl);
  return data;
};
export const updateLeadProfile = async (values, id) => {
  const updateMemUrl = `api/update_lead_profile/${id}`;
  const data = await authhttp.put(updateMemUrl, values);
  return data;
};
export const convertedLead = async (values, id) => {
  const newUrlLead = `api/convert_lead/${id}`;
  const { data } = await authhttp.post(newUrlLead, values);
  return data;
};
export const addActivity = async (values) => {
  const newUrlLead = 'api/user_activity_history';
  const { data } = await authhttp.post(newUrlLead, values);
  return data;
};
export const deleteLead = async (id) => {
  const ContUrl = `api/delete_lead/${id}`;
  const { data } = await authhttp.del(ContUrl);
  return data;
};
export const deleteActivity = async (id) => {
  const Url = `api/user_activity_history/${id}`;
  const { data } = await authhttp.del(Url);
  return data;
};
export const archiveLead = async (id, values) => {
  const updateMemUrl = `api/change_lead_status/${id}`;
  const data = await authhttp.put(updateMemUrl, values);
  return data;
};
export const getAllLeads = async (values) => {
  const newUrlLead = 'api/leads';
  const {
    data: { data }
  } = await authhttp.get(newUrlLead, {
    params: values
  });
  return data;
};

// export const gettags = async () => {
//   const newUrlLead =  'api/get_tags';
//   const Values = {
//     module_name: 'user'
//   };
//   const data = await authhttp.post(newUrlLead, Values);
//   return data;
// };

export const gettags = async () => {
  const getTagUrl = 'api/get_tags';
  const Value = {
    module_name: 'user'
  };
  const {
    data: {
      data: { tags }
    }
  } = await authhttp.post(getTagUrl, Value);
  return tags;
};

export const gettagsAll = async () => {
  const getTagUrl = 'api/tags';
  const {
    data: {
      data: { data }
    }
  } = await authhttp.get(getTagUrl);
  return data;
};
// user image
export const ChangeProfilePic = async (val) => {
  const ProfilePicUrl = 'api/change_image_upload';
  const {
    data: { data }
  } = await authhttp.post(ProfilePicUrl, val);
  return data;
};

export const changeleadsTags = async (val) => {
  const LeadsTagsUrl = 'api/add_tags_for_users';
  const {
    data: { data }
  } = await authhttp.post(LeadsTagsUrl, val);
  return data;
};

export const importLead = async (value) => {
  const importUrl = 'api/import_leads';
  const {
    data: { data }
  } = await authhttp.post(importUrl, value);
  return data;
};

export const exportLead = async (ids) => {
  const exportUrl = 'api/export_users';
  const Values = {
    ids
  };
  authhttp
    .post(exportUrl, Values, {
      responseType: 'blob'
    })
    .then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'lead-user.xlsx');
      document.body.appendChild(link);
      link.click();
    });
};

// export const LeadAddTags = async (ids, tags) => {
//   const addTagsUrl =  'api/add_bulk_users_tags';
//   const Value = {
//     ids,
//     tags
//   };
//   const data = await authhttp.post(addTagsUrl, Value);
//   return data;
// };

export const LeadAddTags = async (val) => {
  const addTagsUrl = 'api/add_bulk_users_tags';
  const {
    data: { data }
  } = await authhttp.post(addTagsUrl, val);
  return data;
};

export const LeadRemoveTags = async (val) => {
  const addTagsUrl = 'api/remove_bulk_users_tags';
  const {
    data: { data }
  } = await authhttp.post(addTagsUrl, val);
  return data;
};

export const LeadDefaultMemberStatus = async (val) => {
  const MemberStatus = 'api/default_member_status';
  const {
    data: { data }
  } = await authhttp.get(MemberStatus, val);
  return data;
};

export const LeadChangeDefaultMemberStatus = async (val) => {
  const MemberStatus = 'api/change_default_lead_status';
  const {
    data: { data }
  } = await authhttp.post(MemberStatus, val);
  return data;
};

export const LeadContactForm = async (val) => {
  const URL = 'api/show_lead_contact_form';
  const {
    data: { data }
  } = await authhttp.get(URL, val);
  return data;
};
export const LeadContactFormSave = async (val) => {
  const URL = 'api/update_lead_form_name_email';
  const {
    data: { data }
  } = await authhttp.post(URL, val);
  return data;
};
