import React, { useContext, useState } from 'react';
import {
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  withStyles,
  Grid,
  Typography
} from '@material-ui/core';
import PropType from 'prop-types';
import { AlliesProfile } from 'src/Context/AlliesContext';
import { openNoti } from 'src/Redux/global/global.actions';
import { useDispatch } from 'react-redux';
import useGymSettingReducer from 'src/views/common/CustomHooks/useGymSettingRefresh';
import { cancelAffilateDiscount } from 'src/services/masterHQ.services';
import { getDiscounts } from 'src/Context/MasterHQAllies/masterhqallies.actions';
import JBButton from 'src/views/common/JBButton/JBButton';
import moment from 'moment';
import GetPrice from 'src/views/common/CurrencySymbol/GetPrice';
import { getErrorMsg } from 'src/utils/ErrorHandling';
import CancelConfirm from './cancelConfirm';
import styles from './table.style';

const DiscountTypes = [
  { id: 1, filter: 'Expires' },
  { id: 2, filter: 'Fixed Expire' },
  { id: 3, filter: 'No End' }
];
function DiscountTable({ classes, values, affiliate_id }) {
  // const [Applyopen, setApplyOpen] = useState(false);
  const { dateformat } = useGymSettingReducer();
  const [, dispatch] = useContext(AlliesProfile);
  const reduxDispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const cancelDiscount = () => {
    const vals = {
      affiliate_id,
      status: 0
    };
    cancelAffilateDiscount(vals)
      .then((res) => {
        dispatch(getDiscounts(Boolean(res.filter((d) => d.status === 1)[0])));
        reduxDispatch(openNoti('Discount canceled successfully '));
        setOpen(false);
      })
      .catch((err) => reduxDispatch(openNoti(getErrorMsg(err), 'error')));
  };
  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      spacing={0}
      style={{ margin: '0px 0px 20px' }}
    >
      <TableContainer>
        <Table className={classes.table} aria-label="simple table">
          <TableHead className={classes.table_th}>
            <TableRow>
              <TableCell className={classes.TableCell} style={{ width: '5%' }}>
                Discount
              </TableCell>
              <TableCell className={classes.TableCell} style={{ width: '5%' }}>
                Type
              </TableCell>
              <TableCell className={classes.TableCell} style={{ width: '5%' }}>
                Start Date
              </TableCell>
              <TableCell className={classes.TableCell} style={{ width: '5%' }}>
                End Date
              </TableCell>
              <TableCell
                className={classes.TableCell}
                align="right"
                style={{ width: '5%' }}
              >
                &nbsp;
              </TableCell>
            </TableRow>
          </TableHead>
          {values && values.status ? (
            <TableBody>
              {/* add activeplan class */}
              <TableRow>
                <TableCell className={classes.Table_td}>
                  {values.discount_type === 'fixed' ? (
                    <GetPrice value={values.discount_amount} />
                  ) : (
                    <Typography
                      color="#000"
                      variant="h6"
                      component="label"
                      // className={classes.bktitle}
                      style={{
                        fontSize: '14px',
                        // marginRight: '15px',
                        display: 'block'
                      }}
                    >
                      {values.discount_value}%
                    </Typography>
                  )}
                </TableCell>

                <TableCell className={classes.Table_td}>
                  {
                    DiscountTypes.filter(
                      // eslint-disable-next-line eqeqeq
                      (i) => i.id == values.discount_valid_type
                    )[0]?.filter
                  }
                </TableCell>
                <TableCell className={classes.Table_td}>
                  {moment(values.discount_start_on).isValid()
                    ? moment(values.discount_start_on).format(dateformat)
                    : values.discount_start_on}
                </TableCell>
                <TableCell className={classes.Table_td}>
                  {moment(values.discount_end_at).isValid()
                    ? moment(values.discount_end_at).format(dateformat)
                    : values.discount_end_at}
                </TableCell>
                <TableCell className={classes.Table_td} align="right">
                  {/* <JBButton
                  type="primary"
                  className={classes.smallBtn}
                  style={{
                    margin: '0 0 0 0px'
                  }}
                  onClick={() => setApplyOpen(true)}
                >
                  Apply
                </JBButton> */}
                  <JBButton
                    type="outlined"
                    className={classes.smallBtn}
                    onClick={() => setOpen(true)}
                    style={{
                      margin: '0 0 0 15px'
                    }}
                  >
                    Cancel
                  </JBButton>
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <p
              style={{
                color: '#fff'
              }}
            >
              No discount applied
            </p>
          )}
        </Table>
      </TableContainer>
      <CancelConfirm
        setOpen={open}
        cancelDiscount={cancelDiscount}
        handleClose={() => setOpen(false)}
      />
    </Grid>
  );
}
DiscountTable.defaultProps = {
  affiliate_id: null
};
DiscountTable.propTypes = {
  classes: PropType.object.isRequired,
  values: PropType.array.isRequired,
  affiliate_id: PropType.any
};

export default withStyles(styles)(DiscountTable);
