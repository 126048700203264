import {
  changetemplatename,
  changeattendncelmt,
  changeattendancelmttype,
  changeattendancelmtuses,
  changetemplaterevecat,
  changetemplatedesc,
  addtemplatepayment,
  changetemplatepayment,
  changetemplateprogram,
  changetemplatecontract,
  changeNoSession
} from './membershiptemplate.types';

export const templatechangename = (payload) => ({
  type: changetemplatename,
  payload
});

export const changeattlmt = (payload) => ({
  type: changeattendncelmt,
  payload
});

export const changeattlmtuses = (payload) => ({
  type: changeattendancelmtuses,
  payload
});

export const changeattlmttype = (payload) => ({
  type: changeattendancelmttype,
  payload
});

export const changerevencat = (payload) => ({
  type: changetemplaterevecat,
  payload
});

export const changetempdesc = (payload) => ({
  type: changetemplatedesc,
  payload
});

export const addpaymentmethod = (payload) => ({
  type: addtemplatepayment,
  payload
});

export const changepaymentmethod = (payload) => ({
  type: changetemplatepayment,
  payload
});

export const changeprogramaccess = (payload) => ({
  type: changetemplateprogram,
  payload
});

export const changecontractid = (payload) => ({
  type: changetemplatecontract,
  payload
});

export const changeSessions = (payload) => ({
  type: changeNoSession,
  payload
});
