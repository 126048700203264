import { backendDate, getValuefromObject } from 'src/utils/someCommon';

// const checkDay = (data, filters, selected) => {
//   if (filters.day_of_week) {
//     if (filters.day_of_week !== getValuefromObject(data)) {
//       return {
//         id: Math.floor(Math.random() * 100) + 10,
//         name: `Day of week ${Object.values(selected)[1]} ${
//           Object.values(data)[1]
//         }`,
//         filter: 'day_of_week',
//         connetor: 'day_of_week_operator'
//       };
//     }
//   }
//   return {
//     id: 1,
//     name: `Day of week ${Object.values(selected)[1]} ${Object.values(data)[1]}`,
//     filter: 'day_of_week',
//     connetor: 'day_of_week_operator'
//   };
// };

export const getFilter = (id, data, endDate, selected) => {
  switch (id) {
    case 1:
      return {
        day_of_week: getValuefromObject(data),
        day_of_week_operator: getValuefromObject(selected)
      };
    case 2:
      return {
        recurring: getValuefromObject(data)
      };
    case 3:
      return {
        program_id: getValuefromObject(data)
      };
    case 4:
      return {
        filter_by_date: backendDate(data) + '-' + backendDate(endDate)
      };
    default:
      return null;
  }
};

export const getfilterLabel = (id, data, endDate, selected) => {
  switch (id) {
    case 1:
      return {
        id,
        name: `Day of week ${Object.values(selected)[1]} ${
          Object.values(data)[1]
        }`,
        filter: 'day_of_week',
        connetor: 'day_of_week_operator'
      };
    case 2:
      return {
        id,
        name: `Filter by ${Object.values(data)[1]}`,
        filter: 'recurring'
      };
    case 3:
      return {
        id,
        name: `Program is ${Object.values(data)[1]}`,
        filter: 'program_id'
      };
    case 4:
      return {
        id,
        name: `Custom Date is ${backendDate(data)
          + '-'
          + backendDate(endDate)}`,
        filter: 'filter_by_date'
      };
    default:
      return null;
  }
};

export const checkData = (filter, data, endDate, selected) => {
  if (!filter || !data || String(data).includes('Invalid')) {
    return true;
  }
  if (filter === 1) {
    if (!data || !selected) {
      return true;
    }
  }
  if (filter === 4 && String(endDate).includes('Invalid')) {
    return true;
  }
  return false;
};

export const addReccIdFil = (id) => {
  const filter = {
    recurring_id: id
  };
  const tag = {
    name: 'Showing recurring Classes',
    id: 7,
    filter: 'recurring_id'
  };
  return [filter, tag];
};
