import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Grid, Breadcrumbs, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import './Program.css';
import ProgramForm from './commonform';
import { ROUTES } from '../../constant/Constants';
import styles from './EditProgram/addprogram.style';
import CancelDialog from '../common/CommonDialogBox/CancelDialog';

class Program extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <div className="title-bk">
          {/* <h3>
            Classes <label>/</label> Programs <label>/</label>{' '}
            <span>create new </span>
          </h3> */}
          <Breadcrumbs
            aria-label="breadcrumb"
            style={{
              margin: '0px 0 21px'
            }}
          >
            <Link color="inherit" to={ROUTES.CLASSES_PROGRAMS}>
              Classes
            </Link>
            <Link color="inherit" to={ROUTES.CLASSES_PROGRAMS}>
              Programs
            </Link>
            <Typography variant="h3" color="Primary">
              <span>create new</span>
            </Typography>
          </Breadcrumbs>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={0}
            style={{ marginBottom: '20px' }}
          >
            <Grid item xs={6}>
              <h1 style={{ margin: '0 0' }}>Create New Program</h1>
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              <CancelDialog />
            </Grid>
          </Grid>
          <div>
            {/* <Button
              variant="outlined"
              color="primary"
              component={Link}
              to={ROUTES.CLASSES_PROGRAMS}
              className="btn-cancel btn"
            >
              Cancel
            </Button> */}
          </div>
        </div>
        <ProgramForm />
      </div>
    );
  }
}

Program.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Program);
