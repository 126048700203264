import React, { memo } from 'react';
import PropType from 'prop-types';
import { ROUTES } from 'src/constant/Constants';
import { Link } from 'react-router-dom';
import {
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  withStyles,
  TableSortLabel
} from '@material-ui/core';
import CheckIcon from 'src/assets/image/check.png';
import filterType from 'src/Redux/Reducer.constants';

const styles = () => ({
  table: {
    minWidth: '100%',
    wordBreak: 'break-word',
    '& td': {
      padding: '10px'
    },
    '@media (max-width: 1366px)': {
      '& td': {
        fontSize: '13px'
      }
    }
  },
  tableLink: {
    color: '#01847A',
    fontWeight: '700'
  },
  TableCell: {
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px',
    height: '56px',
    minHeight: '56px',
    padding: '10px',
    '@media (max-width: 1366px)': {
      fontSize: '13px',
      wordBreak: 'keep-all'
    }
  },
  Tablemian: {}
});

const MembershipDetailsTable = memo((props) => {
  // eslint-disable-next-line react/prop-types
  const { values, classes, isActive, setDirection, setSort } = props;

  const {
    sort_members,
    sort_class_name,
    sort_class_location,
    sort_class_programe,
    sort_class_start_date_time,
    sort_coach_name
  } = filterType;

  return (
    <TableContainer
      component={Paper}
      elevation={0}
      className={classes.Tablemian}
    >
      <Table className={classes.table} aria-label="simple table">
        <TableHead
          style={{ background: '#F9F9FA', backgroundColor: '#F9F9FA' }}
        >
          <TableRow>
            <TableCell className={classes.TableCell}>
              <TableSortLabel
                active={isActive(sort_members)}
                direction={setDirection(sort_members)}
                onClick={() => setSort(sort_members)}
              >
                Member
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell}>
              <TableSortLabel
                active={isActive(sort_class_name)}
                direction={setDirection(sort_class_name)}
                onClick={() => setSort(sort_class_name)}
              >
                Class name
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell}>
              <TableSortLabel
                active={isActive(sort_class_location)}
                direction={setDirection(sort_class_location)}
                onClick={() => setSort(sort_class_location)}
              >
                Class location
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell}>
              <TableSortLabel
                active={isActive(sort_class_programe)}
                direction={setDirection(sort_class_programe)}
                onClick={() => setSort(sort_class_programe)}
              >
                Class program
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell}>
              <TableSortLabel
                active={isActive(sort_class_start_date_time)}
                direction={setDirection(sort_class_start_date_time)}
                onClick={() => setSort(sort_class_start_date_time)}
              >
                Class Start Date & Time
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell}>
              <TableSortLabel
                active={isActive(sort_coach_name)}
                direction={setDirection(sort_coach_name)}
                onClick={() => setSort(sort_coach_name)}
              >
                Coaches
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell} align="center">
              Counts against attendance
            </TableCell>
            <TableCell className={classes.TableCell} align="center">
              Is active
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {values.map((row) => (
            <TableRow
              hover
              tabIndex={-1}
              key={row.id}
              style={{
                backgroundColor: '#FFF'
              }}
            >
              <TableCell
                style={{
                  width: '9.0909%'
                }}
              >
                <Link
                  className={classes.tableLink}
                  to={{
                    pathname: ROUTES.PEOPLE_MEMBER_PROFILE,
                    search: `memberId=${btoa(row.id)}`
                  }}
                >
                  {row?.member}
                </Link>
              </TableCell>
              <TableCell
                style={{
                  width: '9.0909%'
                }}
              >
                {row?.class_name}
              </TableCell>
              <TableCell
                style={{
                  width: '9.0909%'
                }}
              >
                {row?.class_location}
              </TableCell>
              <TableCell
                style={{
                  width: '9.0909%'
                }}
              >
                {row?.class_programe}
              </TableCell>
              <TableCell
                style={{
                  width: '9%'
                }}
              >
                {row?.class_start_date_time}
              </TableCell>
              <TableCell
                style={{
                  width: '9%'
                }}
              >
                {row?.head_coach_name}
              </TableCell>
              <TableCell
                style={{
                  width: '7%'
                }}
                align="center"
              >
                {/* {row.history} */}
                {row?.count_against_attendance === 'true' && (
                  <img src={CheckIcon} alt="img" />
                )}
              </TableCell>
              <TableCell
                style={{
                  width: '7%'
                }}
                align="center"
              >
                {row?.is_active === 'true' && <img src={CheckIcon} alt="img" />}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {values?.length === 0 && (
        <p
          style={{
            margin: '10px 15px'
          }}
        >
          No attendance history to show...
        </p>
      )}
    </TableContainer>
  );
});
MembershipDetailsTable.propTypes = {
  values: PropType.array.isRequired,
  classes: PropType.object.isRequired,
  isActive: PropType.func.isRequired,
  setDirection: PropType.func.isRequired,
  setSort: PropType.func.isRequired
};

export default withStyles(styles)(MembershipDetailsTable);
