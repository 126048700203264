import Types from './chalkBoard.Types';

export const SetProgram = (payload) => ({
  type: Types.SetProgram,
  payload
});

export const SetDate = (payload) => ({
  type: Types.SetDate,
  payload
});

export const SetClassOption = (payload) => ({
  type: Types.getClassOpt,
  payload
});

export const SetError = (payload) => ({
  type: Types.SetError,
  payload
});

export const SetClass = (payload) => ({
  type: Types.SetClass,
  payload
});

export const SetWorkoutData = (payload) => ({
  type: Types.SetWorkoutData,
  payload
});

export const setSignInProgram = (payload) => ({
  type: Types.setSignProgram,
  payload
});

export const setSignInDate = (payload) => ({
  type: Types.setSignDate,
  payload
});

export const setSignInClassOpt = (payload) => ({
  type: Types.setSignClassOpt,
  payload
});

export const setSignInClass = (payload) => ({
  type: Types.setSignClass,
  payload
});

export const setSignInError = (payload) => ({
  type: Types.setSignError,
  payload
});

export const setSignedMember = (payload) => ({
  type: Types.setSignMember,
  payload
});

export const addLoginedUser = (payload) => ({
  type: Types.setLoginedUser,
  payload
});

export const setSignDialog = (payload, message, status) => ({
  type: Types.setSignDialog,
  payload,
  message,
  status
});

export const setSignProcessing = (payload) => ({
  type: Types.setSignProcessing,
  payload
});

export const setuserbyClass = (payload) => ({
  type: Types.setlogineduserbyClass,
  payload
});

export const removedloginedUser = (payload) => ({
  type: Types.removeLoginedUser,
  payload
});

export const removedResevedMembers = (payload) => ({
  type: Types.removeReservedMembers,
  payload
});
