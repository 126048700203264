import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
// import compose from 'recompose/compose';
import Noti from '../views/common/notification/Noti';
import { cleartNoti } from '../Redux/global/global.actions';

const styles = (theme) => ({
  snackbar: {
    margin: theme.spacing(1)
  }
});

const MAX_VISIBLE_MESSAGES = 1; // Maximum number of messages that are visible

class NotiContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      messageArray: {}
    };
    this.queue = [];
  }

  componentDidMount() {
    // get all the data from the queu and push to the local aray
    const { notiQueue } = this.props;
    this.addMessagesToQueue(notiQueue);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { notiQueue } = this.props;
    // debugger; //eslint-disable-line
    if (
      nextProps.notiQueue.length !== 0
      && notiQueue.length !== nextProps.notiQueue.length
    ) {
      // push data into queue
      //  debugger; //eslint-disable-line
      this.addMessagesToQueue(nextProps.notiQueue);
    }
  }

  // Following is a wrong implementation of shouldComponentUpdate
  // This can force component to not to show messages even if needed
  // shouldComponentUpdate(nextProps, nextState) {
  //   let lbShouldUpdate = true;
  //   const { messageArray } = this.state;

  //   if (messageArray.length === nextState.messageArray.length) {
  //     lbShouldUpdate = false;
  //   }
  //   //  if (lbShouldUpdate) debugger; //eslint-disable-line
  //   return lbShouldUpdate;
  // }

  addMessagesToQueue = (notiQueue) => {
    const { cleartNotiFunc } = this.props;
    const removeIds = [];
    //  debugger; //eslint-disable-line
    if (notiQueue.length === 0) return;
    for (let i = 0; i < notiQueue.length; i += 1) {
      this.queue.push(notiQueue[i]);
      removeIds.push(notiQueue[i].id);
    }
    // if (this.state.open) {
    //   // immediately begin dismissing current message
    //   // to start showing new one
    //   this.setState({ open: false });
    // } else {
    this.processQueue();
    // }
    // send removeIds to clear that data from the store
    if (removeIds.length > 0) cleartNotiFunc(removeIds);
  };

  processQueue = () => {
    // if message array has messages in array that means messages are not yet cleared
    // and they are being showsed on view
    const { messageArray } = this.state;
    if (this.queue.length > 0) {
      // eslint-disable-next-line max-len
      let noOfMsgToshow = this.queue.length < MAX_VISIBLE_MESSAGES
        ? this.queue.length
        : MAX_VISIBLE_MESSAGES;
      let newMsgArray = [];
      if (messageArray.length !== undefined) {
        // if ther are exisiting messages on view , dont remove messages from view
        if (messageArray.length >= MAX_VISIBLE_MESSAGES) {
          // removed the last item and show other
          newMsgArray = newMsgArray.concat(messageArray.slice(-1)); // copy last 1 messages only
          noOfMsgToshow -= newMsgArray.length;
          newMsgArray = newMsgArray.concat(this.queue.slice(0, noOfMsgToshow));
        } else {
          const noOfMsgInArray = messageArray.length;
          noOfMsgToshow = MAX_VISIBLE_MESSAGES - noOfMsgInArray;
          newMsgArray = newMsgArray.concat(messageArray.slice());
          newMsgArray = newMsgArray.concat(this.queue.slice(0, noOfMsgToshow));
        }
      } else {
        newMsgArray = this.queue.slice(0, noOfMsgToshow);
      }
      this.queue.splice(0, noOfMsgToshow); // remove those messages from queue

      this.setState({
        ...messageArray,
        messageArray: newMsgArray,
        open: true
      });
      if (this.queue.length > 0) {
        // if there are messages in queue than process queue
        setTimeout(this.processQueue, 5000);
      }
    }
  };

  createMarkeup = (content) => ({
    __html: content
  });

  handleCloseNoti = (event, reason, itemId) => {
    // this.props.closeNoti(true, id);
    const { messageArray } = this.state;
    if (reason === 'clickaway') {
      return;
    }
    // debugger; //eslint-disable-line
    // debugger; //eslint-disable-line
    this.setState({
      messageArray: messageArray.filter((node) => node.id !== itemId)
    });

    if (this.queue.length > 0) this.processQueue();
  };

  render() {
    //  debugger; //eslint-disable-line
    const { messageArray, open } = this.state;
    const { classes, autoHideDuration } = this.props;
    // debugger; //eslint-disable-line
    return (
      <div>
        {messageArray.length > 0 ? (
          messageArray.map((item, index) => (
            <div key={item.id} className={classes.snackbar}>
              {/* eslint-disable react/no-danger */}
              <Noti
                id={item.id}
                handleOnRequestClose={(event, reason) => {
                  this.handleCloseNoti(event, reason, item.id);
                }}
                open={open}
                marginDiff={
                  index === 2 && item.message.length > 80
                    ? (index + 1) * 55
                    : index * 55
                }
                autoHideDuration={autoHideDuration} // pass null to disable autohide
                message={
                  <div
                    id={`msg${item.id}`}
                    dangerouslySetInnerHTML={this.createMarkeup(item.message)}
                  />
                }
                type={item.type}
              />
              {/* eslint-disable react/no-danger */}
            </div>
          ))
        ) : (
          <div />
        )}
      </div>
    );
  }
}
NotiContainer.defaultProps = {
  autoHideDuration: 3000
};

NotiContainer.propTypes = {
  cleartNotiFunc: PropTypes.func.isRequired,
  // noti: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  notiQueue: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  autoHideDuration: PropTypes.number // pass null to disable autohide
};
const mapStateToProps = (state) => ({
  noti: state.globalReducer.noti,
  notiQueue: state.globalReducer.notiQueue
});

const mapDispatchToProps = (dispatch) => ({
  cleartNotiFunc: (idArray) => dispatch(cleartNoti(idArray))
});
export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(NotiContainer);
