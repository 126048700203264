/* eslint-disable no-unused-vars */
/* eslint-disable indent */
/* eslint-disable default-case */
/* eslint-disable object-shorthand */
import React, { useState, useContext, useEffect } from 'react';
import { VanguardMem } from 'src/Context/VanguardMembershipContext';
import { connect } from 'react-redux';
import { openNoti } from 'src/Redux/global/global.actions';
import {
  NextStep,
  StepBack,
  ResetStep,
  // setUser,
  setUserContract,
  skipStep,
  resetData
} from 'src/Context/VanguardMembership/VanguardMembership.actions';
import PropType from 'prop-types';
import { ROUTES } from 'src/constant/Constants';
import { useHistory, Link, useLocation } from 'react-router-dom';
import {
  updateVanguardUser,
  getUnsignedContracts
} from 'src/services/vanguard.services';
import {
  useElements,
  useStripe,
  CardElement,
  AuBankAccountElement
} from '@stripe/react-stripe-js';
import {
  createusermemship,
  createPaymentMet,
  createStripeCustomer
} from 'src/services/user.service';
import {
  Button,
  AppBar,
  Toolbar,
  IconButton,
  withStyles,
  Card,
  Box
} from '@material-ui/core';
import { updateUserContract } from 'src/services/Contracts.services';
import { getErrorMsg } from 'src/utils/ErrorHandling';
import useChangeTitle from 'src/views/common/CustomHooks/useTitleChange';
import {
  validStep2,
  validStep3,
  validStep4,
  validStep5,
  verifyInfo,
  validStep6,
  // getUserData,
  getMembershipData,
  stripeData,
  validStep7,
  validStep9,
  validStep0,
  getContractData,
  planStep5,
  updateUserData
} from '../Vanguard.utills';
import { ReactComponent as BackArrow } from '../../../../assets/image/Vanguard/icon_back_vanguard.svg';
import LeavePagePopup from '../../LeavepagePopup';
import styles from '../membershipIndex.style';
import JBVanguardStepper from '../../../common/JBVanguardStepper';
import PersonalInfo from '../../personalInfo';
import SelectMembership from '../selectMembership';
import SuccessPopup from '../../successPopup';
import PlanConfigureMembership from '../Plan/ConfigureMembership';
import SessionConfigureMembership from '../Session/ConfigureMembership';
import DiscountsPricing from '../Session/DiscountsPricing';
import PaymentAutoRenew from '../Plan/PaymentAutoRenew';
import ProgramAccess from '../programAccess';
import AddPaymentMethod from '../PaymentMethod/AddPaymentMethod';
import PaymentPlanTerms from '../PaymentPlanTerms';
import Membershipagreement from '../MembershipAgreement';
import SessionPlanTerms from '../SessionPlanTerms';
import Sign from '../../sign';
import ConvertMemberPopup from '../ConvertMember/ConvertMemberPopup';
import ReactivateMemberPopup from '../InactiveMember/ReactivateMemberPopup';
import SelectContract from '../SelectContract/SelectContract';
import FindMember from './findMember';

function getSteps() {
  return ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11'];
}

const Returning = ({ classes, openNotify }) => {
  const history = useHistory();
  useChangeTitle(
    (() => {
      switch (history.location.state.type) {
        case 'active':
          return 'Active Member | Jungle Alliance';
        case 'lead':
          return 'Onboarding member | Jungle Alliance';
        case 'inactive':
          return 'Inactive Member | Jungle Alliance';
        default:
          return 'Active Member | Jungle Alliance';
      }
    })()
  );
  const {
    state: { type }
  } = useLocation();
  const [LeavePageopen, setLeavePageopen] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [convertOpen, setConvertOpen] = useState(false);
  const [reactiveOpen, setReactiveOpen] = useState(false);
  const [state, dispatch] = useContext(VanguardMem);
  const stripe = useStripe();
  const elements = useElements();
  const {
    active_Step,
    membership_type,
    user_id,
    personal_info: { user_details, error, error_msg },
    memebership_data: { membershipdata }
  } = state;
  const [activeStep, setActiveStep] = useState(0);

  const steps = getSteps();
  const [Sussopen, setSussopen] = useState(false);
  const [MembershipType, setMembershipType] = useState(0); // plan-0 and session-1

  const handleNext = async () => {
    if (active_Step === 0) {
      setProcessing(true);
      switch (type) {
        case 'lead':
          setProcessing(false);
          setConvertOpen(true);
          dispatch(NextStep());
          break;
        case 'inactive':
          setProcessing(false);
          setReactiveOpen(true);
          dispatch(NextStep());
          break;
        case 'active':
          getUnsignedContracts(user_id)
            .then((res) => {
              setProcessing(false);
              history.push(ROUTES.VANGUARD_MEMBERSHIP_SELECT_CONTRACT, {
                contracts: res
              });
            })
            .catch(() => {
              setProcessing(false);
              dispatch(NextStep());
            });
          break;
        default:
          dispatch(NextStep());
      }
    } else if (active_Step === 1) {
      setProcessing(true);
      updateVanguardUser(updateUserData(user_details, user_id))
        .then((res) => {
          dispatch(NextStep());
          openNotify('Member updated successfully');
          setProcessing(false);
        })
        .catch((err) => {
          setProcessing(false);
          openNotify(getErrorMsg(err), 'error');
        });
    } else if (active_Step === 5) {
      setProcessing(true);
      createusermemship(getMembershipData(state), state.user_id)
        .then((res) => {
          dispatch(setUserContract(res.user_contract_id));
          openNotify('Membership created  successfully');
          if (res.payment_method_exists) {
            if (membershipdata.membership_contract === 'send_contract') {
              setSussopen(true);
              setTimeout(() => setSussopen(false), 4000);
              setTimeout(() => history.push(ROUTES.VANGUARD), 3000);
            } else {
              dispatch(skipStep());
            }
          } else {
            dispatch(NextStep());
          }
          setProcessing(false);
        })
        .catch((err) => {
          setProcessing(false);
          openNotify(getErrorMsg(err), 'error');
        });
    } else if (active_Step === 6) {
      setProcessing(true);
      if (!elements || !stripe) return;
      const billling = stripeData(user_details);
      try {
        if (state.paymentMeTab === 0) {
          const value = {
            payment_method: 1,
            user_id: user_id,
            payment_address_meta: JSON.stringify({ billing_details: billling })
          };

          const res = await createStripeCustomer(value);

          const payload = await stripe.confirmCardSetup(res?.client_secret, {
            payment_method: {
              card: elements.getElement(CardElement),
              billing_details: billling
            }
          });

          const {
            setupIntent: { payment_method }
          } = payload;
          const val = {
            user_id: user_id,
            payment_method_id: payment_method,
            default_method: membershipdata.payment_data.is_default ? 1 : 0,
            payment_method: state.paymentMeTab + 1,
            user_payment_method_id: res?.user_payment_method_id
          };
          createPaymentMet(val)
            .then(() => {
              openNotify('Payment method added successfully');
              if (membershipdata.membership_contract === 'send_contract') {
                setSussopen(true);
                setTimeout(() => setSussopen(false), 4000);
                setTimeout(() => history.push(ROUTES.VANGUARD), 3000);
              } else {
                dispatch(NextStep());
              }
              setProcessing(false);
            })
            .catch((err) => {
              setProcessing(false);
              openNotify(getErrorMsg(err), 'error');
            });
        } else if (state.paymentMeTab === 1) {
          const val = {
            user_id: user_id,
            default_method: membershipdata.payment_data.is_default ? 1 : 0,
            payment_method: state.paymentMeTab + 1
          };
          createPaymentMet(val)
            .then(() => {
              openNotify('Payment method added successfully');
              if (membershipdata.membership_contract === 'send_contract') {
                setSussopen(true);
                setTimeout(() => setSussopen(false), 4000);
                setTimeout(() => history.push(ROUTES.VANGUARD), 3000);
              } else {
                dispatch(NextStep());
              }
              setProcessing(false);
            })
            .catch((err) => {
              setProcessing(false);
              openNotify(getErrorMsg(err), 'error');
            });
        } else if (state.paymentMeTab === 2) {
          // for stripe bank account element added
          const value = {
            payment_method: 3,
            user_id: user_id,
            payment_address_meta: JSON.stringify({ billing_details: billling })
          };

          const res = await createStripeCustomer(value);

          const payload = await stripe.confirmAuBecsDebitSetup(
            res?.client_secret,
            {
              payment_method: {
                au_becs_debit: elements.getElement(AuBankAccountElement),
                billing_details: billling
              }
              // type: 'au_becs_debit',
            }
          );

          const {
            setupIntent: { payment_method }
          } = payload;

          const val = {
            user_id: user_id,
            default_method: membershipdata.payment_data.is_default ? 1 : 0,
            payment_method: state.paymentMeTab + 1,
            payment_method_id: payment_method,
            // account_number:
            //   membershipdata.payment_data.bank_details.account_number,
            // bsb_number: membershipdata.payment_data.bank_details.bsb_number,
            account_type: 'Saving account',
            payment_address_meta: JSON.stringify({ billing_details: billling }),
            user_payment_method_id: res?.user_payment_method_id
          };
          createPaymentMet(val)
            .then(() => {
              openNotify('Payment method added successfully');
              if (membershipdata.membership_contract === 'send_contract') {
                setSussopen(true);
                setTimeout(() => setSussopen(false), 4000);
                setTimeout(() => history.push(ROUTES.VANGUARD), 3000);
              } else {
                dispatch(NextStep());
              }
              setProcessing(false);
            })
            .catch((err) => {
              setProcessing(false);
              openNotify(getErrorMsg(err), 'error');
            });
        }
      } catch (err) {
        setProcessing(false);
        openNotify(error_msg?.message, 'error');
      }
    } else if (active_Step === 8) {
      if (
        // eslint-disable-next-line operator-linebreak
        membershipdata.user_contract.sign_initials !==
        membershipdata.user_contract.policy_initials
      ) {
        openNotify('Initials must be the same', 'error');
      } else {
        dispatch(NextStep());
      }
    } else if (active_Step === 9) {
      setProcessing(true);
      updateUserContract(
        membershipdata.user_contract.id,
        getContractData(user_details, membershipdata.user_contract)
      )
        .then(() => {
          openNotify('Contract completed successfully');
          setSussopen(true);
          setTimeout(() => setSussopen(false), 4000);
          setTimeout(() => history.push(ROUTES.VANGUARD), 3000);
          setProcessing(false);
        })
        .catch((err) => {
          setProcessing(false);
          openNotify(getErrorMsg(err), 'error');
        });
    } else {
      dispatch(NextStep());
    }
  };
  const planNext = async () => {
    if (active_Step === 0) {
      setProcessing(true);
      switch (type) {
        case 'lead':
          setProcessing(false);
          setConvertOpen(true);
          dispatch(NextStep());
          break;
        case 'inactive':
          setProcessing(false);
          setReactiveOpen(true);
          dispatch(NextStep());
          break;
        case 'active':
          getUnsignedContracts(user_id)
            .then((res) => {
              setProcessing(false);
              history.push(ROUTES.VANGUARD_MEMBERSHIP_SELECT_CONTRACT, {
                contracts: res
              });
            })
            .catch(() => {
              setProcessing(false);
              dispatch(NextStep());
            });
          break;
        default:
          dispatch(NextStep());
      }
    } else if (active_Step === 1) {
      setProcessing(true);
      updateVanguardUser(updateUserData(user_details, user_id))
        .then((res) => {
          dispatch(NextStep());
          openNotify('Member updated successfully');
          setProcessing(false);
        })
        .catch((err) => {
          setProcessing(false);
          openNotify(getErrorMsg(err), 'error');
        });
    } else if (active_Step === 6) {
      setProcessing(true);
      createusermemship(getMembershipData(state), state.user_id)
        .then((res) => {
          dispatch(setUserContract(res.user_contract_id));
          openNotify('Membership created  successfully');
          if (res.payment_method_exists) {
            if (membershipdata.membership_contract === 'send_contract') {
              setSussopen(true);
              setTimeout(() => setSussopen(false), 4000);
              setTimeout(() => history.push(ROUTES.VANGUARD), 3000);
            } else {
              dispatch(skipStep());
            }
          } else {
            dispatch(NextStep());
          }
          setProcessing(false);
        })
        .catch((err) => {
          setProcessing(false);
          openNotify(getErrorMsg(err), 'error');
        });
    } else if (active_Step === 7) {
      setProcessing(true);
      if (!elements || !stripe) return;
      const billling = stripeData(user_details);
      try {
        if (state.paymentMeTab === 0) {
          const value = {
            payment_method: 1,
            user_id: user_id,
            payment_address_meta: JSON.stringify({ billing_details: billling })
          };

          const res = await createStripeCustomer(value);

          const payload = await stripe.confirmCardSetup(res?.client_secret, {
            payment_method: {
              card: elements.getElement(CardElement),
              billing_details: billling
            }
          });
          const {
            setupIntent: { payment_method }
          } = payload;
          const val = {
            user_id: user_id,
            payment_method_id: payment_method,
            default_method: membershipdata.payment_data.is_default ? 1 : 0,
            payment_method: state.paymentMeTab + 1,
            user_payment_method_id: res?.user_payment_method_id
          };
          createPaymentMet(val)
            .then(() => {
              openNotify('Payment method added successfully');
              if (membershipdata.membership_contract === 'send_contract') {
                setSussopen(true);
                setTimeout(() => setSussopen(false), 4000);
                setTimeout(() => history.push(ROUTES.VANGUARD), 3000);
              } else {
                dispatch(NextStep());
              }
              setProcessing(false);
            })
            .catch((err) => {
              setProcessing(false);
              openNotify(getErrorMsg(err), 'error');
            });
        } else if (state.paymentMeTab === 1) {
          const val = {
            user_id: user_id,
            default_method: membershipdata.payment_data.is_default ? 1 : 0,
            payment_method: state.paymentMeTab + 1
          };
          createPaymentMet(val)
            .then(() => {
              openNotify('Payment method added successfully');
              if (membershipdata.membership_contract === 'send_contract') {
                setSussopen(true);
                setTimeout(() => setSussopen(false), 4000);
                setTimeout(() => history.push(ROUTES.VANGUARD), 3000);
              } else {
                dispatch(NextStep());
              }
              setProcessing(false);
            })
            .catch((err) => {
              setProcessing(false);
              openNotify(getErrorMsg(err), 'error');
            });
        } else if (state.paymentMeTab === 2) {
          // for stripe bank account element added
          const value = {
            payment_method: 3,
            user_id: user_id,
            payment_address_meta: JSON.stringify({ billing_details: billling })
          };

          const res = await createStripeCustomer(value);

          const payload = await stripe.confirmAuBecsDebitSetup(
            res?.client_secret,
            {
              payment_method: {
                au_becs_debit: elements.getElement(AuBankAccountElement),
                billing_details: billling
              }
              // type: 'au_becs_debit',
            }
          );

          const {
            setupIntent: { payment_method }
          } = payload;

          const val = {
            user_id: user_id,
            default_method: membershipdata.payment_data.is_default ? 1 : 0,
            payment_method: state.paymentMeTab + 1,
            payment_method_id: payment_method,
            // account_number:
            //   membershipdata.payment_data.bank_details.account_number,
            // bsb_number: membershipdata.payment_data.bank_details.bsb_number,
            account_type: 'Saving account',
            payment_address_meta: JSON.stringify({ billing_details: billling }),
            user_payment_method_id: res?.user_payment_method_id
          };
          createPaymentMet(val)
            .then(() => {
              openNotify('Payment method added successfully');
              if (membershipdata.membership_contract === 'send_contract') {
                setSussopen(true);
                setTimeout(() => setSussopen(false), 4000);
                setTimeout(() => history.push(ROUTES.VANGUARD), 3000);
              } else {
                dispatch(NextStep());
              }
              setProcessing(false);
            })
            .catch((err) => {
              setProcessing(false);
              openNotify(getErrorMsg(err), 'error');
            });
        }
      } catch (err) {
        setProcessing(false);
        openNotify(error_msg?.message, 'error');
      }
    } else if (active_Step === 9) {
      if (
        // eslint-disable-next-line operator-linebreak
        membershipdata.user_contract.sign_initials !==
        membershipdata.user_contract.policy_initials
      ) {
        openNotify('Initials must be the same', 'error');
      } else {
        dispatch(NextStep());
      }
    } else if (active_Step === 10) {
      setProcessing(true);
      updateUserContract(
        membershipdata.user_contract.id,
        getContractData(user_details, membershipdata.user_contract)
      )
        .then(() => {
          openNotify('Contract completed successfully');
          setSussopen(true);
          setTimeout(() => setSussopen(false), 4000);
          setTimeout(() => history.push(ROUTES.VANGUARD), 3000);
          setProcessing(false);
        })
        .catch((err) => {
          setProcessing(false);
          openNotify(getErrorMsg(err), 'error');
        });
    } else {
      dispatch(NextStep());
    }
  };
  const handleReset = () => {
    dispatch(ResetStep());
  };
  const handleBack = () => {
    dispatch(StepBack());
    if (active_Step === 0) {
      history.goBack();
    }
  };

  useEffect(() => {
    dispatch(resetData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getStepContent(step) {
    switch (true) {
      case step === 0:
        return <FindMember type={type} />;
      case step === 1:
        return <PersonalInfo Maintitle="Check Personal Information" />;
      case step === 2:
        return <SelectMembership TabValue={membership_type} />;
      case step > 2 && step <= 10:
        if (membershipdata.payment_data.membership.membership_type === 'plan') {
          switch (step) {
            case 3:
              return <PlanConfigureMembership />;
            case 4:
              return <PaymentAutoRenew />;
            case 5:
              return (
                <DiscountsPricing
                  title="Select the discounts you want to apply to this plan membership."
                  subtitle=" The final pricing details for this member plan."
                />
              );
            case 6:
              return <ProgramAccess />;
            case 7:
              return <AddPaymentMethod />;
            case 8:
              return <PaymentPlanTerms />;
            case 9:
              return <Membershipagreement />;
            case 10:
              return <Sign />;
          }
        } else {
          switch (step) {
            case 3:
              return <SessionConfigureMembership />;
            case 4:
              return (
                <DiscountsPricing
                  title="Select the discounts you want to apply to this sessions membership."
                  subtitle=" The final pricing details for this sessions membership."
                />
              );
            case 5:
              return <ProgramAccess />;
            case 6:
              return <AddPaymentMethod />;
            case 7:
              return <SessionPlanTerms />;
            case 8:
              return <Membershipagreement />;
            case 9:
              return <Sign />;
          }
        }
      // eslint-disable-next-line no-fallthrough
      default:
        return 'Unknown step';
    }
  }

  return (
    <Box>
      <AppBar className={classes.appBar}>
        <Toolbar className={classes.appToolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="close"
            onClick={() => {
              setLeavePageopen(true);
            }}
          >
            <BackArrow />
          </IconButton>
          <h5 className={classes.headerTitle}>Create Membership</h5>
          <Button
            color="inherit"
            className={classes.HomeButton}
            component={Link}
            to={ROUTES.VANGUARD}
          >
            Home
          </Button>
        </Toolbar>
      </AppBar>

      <Card className={classes.MainCard}>
        <JBVanguardStepper
          steps={membership_type === 0 ? steps : steps.slice(0, -1)}
          activeStep={active_Step}
          handleBack={handleBack}
          handleNext={membership_type === 1 ? handleNext : planNext}
          handleReset={handleReset}
          // eslint-disable-next-line react/jsx-no-bind
          handleStepContent={getStepContent}
          cancelBack={membership_type === 1 ? [2, 6, 7] : [2, 7, 8]}
          validateBtn={
            membership_type === 1
              ? [
                  validStep0(user_id),
                  verifyInfo(user_details),
                  validStep2(state),
                  validStep3(state, membershipdata),
                  validStep4(membershipdata.payment_data, membership_type),
                  validStep5(membershipdata),
                  state.paymentMeTab === 1
                    ? false
                    : validStep6(
                        user_details,
                        membershipdata.payment_data,
                        state.paymentMeTab
                      ),
                  validStep7(membershipdata.user_contract),
                  validStep7(membershipdata.user_contract),
                  validStep9(membershipdata.user_contract)
                ]
              : [
                  validStep0(user_id),
                  verifyInfo(user_details),
                  validStep2(state),
                  validStep3(state, membershipdata),
                  validStep4(membershipdata.payment_data, membership_type),
                  planStep5(membershipdata.payment_data),
                  validStep5(membershipdata),
                  state.paymentMeTab === 1
                    ? false
                    : validStep6(
                        user_details,
                        membershipdata.payment_data,
                        state.paymentMeTab
                      ),
                  validStep7(membershipdata.user_contract),
                  validStep7(membershipdata.user_contract),
                  validStep9(membershipdata.user_contract)
                ]
          }
          isLoading={false}
          hasErrored={false}
          processing={processing}
          backLabel="Back"
          prevLabel="Sign"
          nextLabel={
            membership_type === 0
              ? active_Step === 7
                ? 'Add & continue'
                : 'Next'
              : active_Step === 6
              ? 'Add & continue'
              : 'Next'
          }
        />
      </Card>
      <LeavePagePopup
        setOpen={LeavePageopen}
        handleClose={() => setLeavePageopen(false)}
        // handleHistory={ROUTES.VANGUARD}
      />
      <SuccessPopup
        Dialogtitle="Membership created successfully"
        setOpen={Sussopen}
        handleClose={() => setSussopen(false)}
      />
      <ConvertMemberPopup
        setOpen={convertOpen}
        handleClose={() => setConvertOpen(false)}
        Dialogtitle="Convert Member"
      />
      <ReactivateMemberPopup
        setOpen={reactiveOpen}
        userId={user_id}
        handleClose={() => setReactiveOpen(false)}
      />
    </Box>
  );
};
const mapToDispatch = (dispatch) => ({
  openNotify: (msg, type) => dispatch(openNoti(msg, type))
});
Returning.propTypes = {
  classes: PropType.object.isRequired,
  openNotify: PropType.func.isRequired
};
export default connect(null, mapToDispatch)(withStyles(styles)(Returning));
