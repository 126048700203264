import { ROUTES } from 'src/constant/Constants';

const getGeneral = (data) => {
  const result = data.filter(
    (item, index, self) =>
      index === self.findIndex((t) => t.category === item.category)
  );
  return result;
};
const getGeneralRoutes = (data) => {
  const result = {};
  const routes = [
    ROUTES.COMMUNICATION_AUTOMATED_EMAILS_MEMBER_PAYMENTS,
    ROUTES.COMMUNICATION_AUTOMATED_EMAILS_CREDIT_CARD_EXP,
    ROUTES.COMMUNICATION_AUTOMATED_EMAILS_FAILED_PAYMENTS,
    ROUTES.COMMUNICATION_AUTOMATED_EMAILS_RENEWAL_REMINDER,
    ROUTES.COMMUNICATION_AUTOMATED_EMAILS_PAYMENT_INFO_MISSING,
    ROUTES.COMMUNICATION_AUTOMATED_EMAILS_WELCOME_EMAIL
  ];
  for (let i = 0; i < routes.length; i += 1) {
    result[`${data[i].id}`] = routes[i];
  }
  return result;
};
const getLabels = (data) => {
  const result = data.reduce((a, c) => {
    // eslint-disable-next-line no-param-reassign
    a[c.category] = (a[c.category] || 0) + 1;
    return a;
  }, {});
  return result;
};
const getInactiveLabels = (data) => {
  const result = data.reduce((a, c) => {
    const setting = JSON.parse(c.setting);
    if (!setting.enabled) {
      // eslint-disable-next-line no-param-reassign
      a[c.category] = (a[c.category] || 0) + 1;
    }
    // eslint-disable-next-line no-param-reassign
    a[c.category] = a[c.category] || 0;
    return a;
  }, {});
  result['Welcome Email'] = 0;
  return result;
};
const getErrorMsg = (error) => {
  const { data } = error.response;
  const message = Object.values(data.message);
  return message[0];
};
export {
  getGeneral,
  getGeneralRoutes,
  getLabels,
  getInactiveLabels,
  getErrorMsg
};
