import React, { useState, useEffect, useContext } from 'react';
import { FilterContext } from 'src/views/common/ApplyFilter/applyFilter';
import PropType from 'prop-types';
import { connect } from 'react-redux';
import { InputLabel } from '@material-ui/core';
import JBDatePicker from 'src/views/common/FormControl/JBDatePicker';
import JBButton from 'src/views/common/JBButton/JBButton';
import { getMinDate, getValuefromObject } from 'src/utils/someCommon';
import CustomAutoComplete from '../../../common/CustomAutocomplete/commonAutocomplete';
import {
  TobeFilter,
  ReccurFilter,
  DayofWeekData,
  isNotData
} from './ListFilters/static.Data';
import {
  checkData,
  getFilter,
  getfilterLabel
} from './ListFilters/classFilters.utils';

const ClassFilters = (props) => {
  const { getPrograms, getFilData } = props;
  const [data, setData] = useState(null);
  const [selectis, setSelect] = useState(isNotData[1]);
  const [open, setOpen] = useContext(FilterContext);
  const [endDate, setendDate] = useState(getMinDate(new Date()));
  const [filter, setFilter] = useState(null);
  const filteredData = TobeFilter.filter((fil) => fil.id === filter)[0];

  useEffect(() => {
    if (filter === 4) {
      setData(new Date());
    } else {
      setData(null);
    }
  }, [filter]);

  return (
    <div>
      <CustomAutoComplete
        holder="Select Filter"
        value={TobeFilter}
        Options="filter"
        data={filteredData}
        Change={(val) => setFilter(getValuefromObject(val))}
        style={{ marginBottom: '15px' }}
      />
      <div>
        {(() => {
          switch (filter) {
            case 1:
              return (
                <div>
                  <CustomAutoComplete
                    holder="includes"
                    Options="name"
                    data={selectis}
                    value={isNotData}
                    Change={(val) => setSelect(val)}
                  />
                  {selectis && (
                    <CustomAutoComplete
                      holder="Select Day of Week"
                      Options="name"
                      Change={(val) => setData(val)}
                      value={DayofWeekData}
                      data={data || null}
                      style={{ marginBottom: '15px' }}
                    />
                  )}
                </div>
              );
            case 2:
              return (
                <CustomAutoComplete
                  holder="Recurring"
                  Options="starred"
                  Change={(val) => setData(val)}
                  value={ReccurFilter}
                  data={data || null}
                />
              );
            case 3:
              return (
                <CustomAutoComplete
                  holder="Select Program"
                  value={getPrograms}
                  data={data || null}
                  Options="name"
                  Change={(val) => setData(val)}
                />
              );
            case 4:
              return (
                <div>
                  <InputLabel
                    htmlFor="age-required"
                    style={{ margin: '5px 0' }}
                  >
                    From
                  </InputLabel>
                  <JBDatePicker
                    label="Start Date"
                    value={data}
                    onChange={(val) => {
                      setData(val);
                      setendDate(getMinDate(val));
                    }}
                  />
                  <InputLabel
                    htmlFor="age-required"
                    style={{ padding: '17px 0px 5px' }}
                  >
                    To
                  </InputLabel>
                  <JBDatePicker
                    label="End Date"
                    minDate={data ? getMinDate(data) : getMinDate(new Date())}
                    value={endDate}
                    onChange={(val) => setendDate(val)}
                  />
                </div>
              );
            default:
              return null;
          }
        })()}
      </div>
      <JBButton
        type="primary"
        disabled={checkData(filter, data, endDate, selectis)}
        onClick={() => {
          getFilData(
            getFilter(filter, data, endDate, selectis),
            getfilterLabel(filter, data, endDate, selectis)
          );
          setOpen(!open);
        }} // eslint-disable-line
        style={{
          width: '100%',
          minHeight: '22px',
          padding: '7px 25px',
          marginTop: '20px'
        }}
      >
        Apply Filter
      </JBButton>
    </div>
  );
};
ClassFilters.propTypes = {
  getPrograms: PropType.array.isRequired,
  getFilData: PropType.func.isRequired
};

const mapToState = (state) => ({
  getPrograms: state.allprograms.programs
});
export default connect(mapToState)(ClassFilters);
