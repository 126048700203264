import React, { useState, useCallback, useContext, useEffect } from 'react';
import { debounce } from 'lodash';
import { VanguardWaiverCtx } from 'src/Context/VanguardWaiverContext';
import { useLocation } from 'react-router-dom';
import {
  SetMemberData,
  ResetData
} from 'src/Context/VanguardWaiver/VanguardWaiver.actions';
import {
  searchVanguardUser,
  searchLeads
} from 'src/services/vanguard.services';
import {
  Typography,
  makeStyles,
  Grid,
  InputLabel,
  Box,
  List,
  ListItem,
  ListItemText
} from '@material-ui/core';

import FormControl from '../../common/FormControl/formControl';

const useStyles = makeStyles(() => ({
  mainTitle: {
    fontWeight: '500',
    fontSize: '48px',
    lineHeight: '44px',
    color: '#FFFFFF',
    fontFamily: 'DDC Hardware',
    textAlign: 'center',
    margin: '38px 0 0 0',
    '@media (max-width: 992px)': {
      fontSize: '35px'
    }
  },

  cutom_select_Label: {
    fontFamily: 'DDC Hardware',
    fontStyle: 'normal',
    fontWeight: '500;',
    fontSize: '22px',
    lineHeight: '32px',
    letterSpacing: '0.2px',
    color: '#FFFFFF',
    '&:focus ': {
      outline: '0'
    }
  },
  cutom_vanguard_FormControl: {
    '& .fd-input input': {
      background: 'rgba(196, 196, 196, 0.05)',
      color: '#fff',
      borderRadius: '6px',
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '20px',
      height: '45px',
      border: '2px solid #C8C8C8',
      padding: '8px 20px 8px',
      '&::placeholder ': {
        color: '#fff',
        fontWeight: 'bold',
        fontSize: '15px'
      }
    }
  },
  listData: {
    '& ul': {
      listStyle: 'none',
      overflowY: 'auto',
      height: '230px'
    },
    '& li': {
      padding: '10px',
      background: '#333333',
      color: '#fff',
      marginBottom: '2px',
      borderRadius: '2px',
      fontSize: '20px',
      '&.active': {
        background: '#67e9da',
        color: '#000'
      }
    }
  }
}));

const FindMember = () => {
  const classes = useStyles();
  const [state, dispatch] = useContext(VanguardWaiverCtx); // eslint-disable-line
  const [searchField, setSearchField] = useState('');
  const location = useLocation();
  const [foundMembers, setFoundMembers] = useState([]);
  const [active, setActive] = useState(false);

  const debounceSearch = useCallback(
    debounce((q) => {
      if (q) {
        if (location.state.type === 'active') {
          searchVanguardUser(q)
            .then((res) => setFoundMembers(res))
            .catch(() => setFoundMembers([]));
        } else if (location.state.type === 'on_board') {
          searchLeads(q)
            .then((res) => setFoundMembers(res))
            .catch(() => setFoundMembers());
        }
      } else {
        dispatch(ResetData());
      }
    }, 500),
    []
  );
  useEffect(() => {
    dispatch(ResetData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <Typography variant="h3" className={classes.mainTitle}>
        FIND A MEMBER
      </Typography>
      <Grid
        container
        xs={10}
        justify="center"
        style={{
          margin: '45px auto'
        }}
      >
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={2}
          // style={{ margin: '20px 0 20px' }}
        >
          <Grid item xs={12} className={classes.cutom_vanguard_FormControl}>
            <InputLabel
              htmlFor="age-required"
              className={classes.cutom_select_Label}
              // style={{ margin: '0px 0 5px' }}
            >
              Name, Phone Or Email
            </InputLabel>
            <FormControl
              control="input"
              onChange={(e) => {
                setSearchField(e.target.value);
                debounceSearch(e.target.value);
              }}
              value={searchField}
              placeholder="Search..."
              autoComplete="off"
              // eslint-disable-line
              // label="Description"
              // maxLength={50}
              style={{
                margin: '0 0'
              }}
            />
          </Grid>
          {/* dynamic list data  */}
          <Grid
            item
            xs={12}
            style={{
              paddingTop: '0'
            }}
          >
            <Box className={classes.listData}>
              <List>
                {foundMembers.map((member) => {
                  return (
                    <ListItem
                      className={active === member.id && 'active'}
                      onMouseEnter={() => setActive(member.id)}
                      onMouseLeave={() => setActive(null)}
                      onClick={() => {
                        dispatch(SetMemberData(member));
                        setSearchField(
                          `${member.first_name} ${member.last_name}`
                        );
                        setFoundMembers([]);
                      }}
                      key={member.id}
                      style={{ cursor: 'pointer' }}
                    >
                      <ListItemText
                        primary={`${member.first_name} ${member.last_name}`}
                      />
                    </ListItem>
                  );
                })}
                {/* eslint-disable */}
                {/* {foundMembers.length === 0 &&
                  searchField.length > 0 &&
                  !isLoading && (
                    <ListItem>
                      <ListItemText primary="Search result not found" />
                    </ListItem>
                  )} */}
                {/* eslint-enable */}
              </List>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default FindMember;
