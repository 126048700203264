import authhttp from './authHttp.services';

// eslint-disable-next-line
export const getMemberSuccess = async (id) => {
  const url = `api/member_payments_email/${id}/edit`;
  const {
    data: { data }
  } = await authhttp.get(url);
  return data;
};
export const getAnonymousSucces = async (id) => {
  const url = `api/anonymous_payments/${id}/edit`;
  const {
    data: { data }
  } = await authhttp.get(url);
  return data;
};
export const createAnonymousSuccess = async (values, id) => {
  const url = `api/anonymous_payments/${id}`;
  const { data } = await authhttp.post(url, values);
  return data;
};
export const createMemberSuccess = async (values, id) => {
  const url = `api/member_payments_email/${id}`;
  const { data } = await authhttp.post(url, values);
  return data;
};
export const automatedList = async (param) => {
  const url = 'api/automated_email';
  const {
    data: { data }
  } = await authhttp.get(url, { params: { ...param } });
  return data;
};

export const creditCard = async () => {
  const url = 'api/credit_card_expiring/7/edit';
  const {
    data: { data }
  } = await authhttp.get(url);
  return data;
};
export const createCreditCard = async (values) => {
  const url = 'api/credit_card_expiring/7';
  const { data } = await authhttp.post(url, values);
  return data;
};
export const failedPayment = async () => {
  const url = 'api/failed_payments/8/edit';
  const {
    data: { data }
  } = await authhttp.get(url);
  return data;
};
export const createFailedPayment = async (values) => {
  const url = 'api/failed_payments/8';
  const { data } = await authhttp.post(url, values);
  return data;
};
export const membershipRenewal = async () => {
  const url = 'api/membership_renewal/9/edit';
  const {
    data: { data }
  } = await authhttp.get(url);
  return data;
};
export const createMembershipRenewal = async (values) => {
  const url = 'api/membership_renewal/9';
  const { data } = await authhttp.post(url, values);
  return data;
};
export const getAutomated = async (param) => {
  const tempUrl = 'api/automated_email';
  const {
    data: {
      data: { data }
    }
  } = await authhttp.get(tempUrl, {
    params: {
      ...param
    }
  });
  return data;
};
export const missingInfo = async () => {
  const url = 'api/payment_info_missing/10/edit';
  const {
    data: { data }
  } = await authhttp.get(url);
  return data;
};
export const creatMissingInfo = async (values) => {
  const url = 'api/payment_info_missing/10';
  const { data } = await authhttp.post(url, values);
  return data;
};

export const welcomeEmail = async () => {
  const url = 'api/welcome_email/11/edit';
  const {
    data: { data }
  } = await authhttp.get(url);
  return data;
};
export const creatWelcomeEmail = async (values) => {
  const url = 'api/welcome_email/11';
  const { data } = await authhttp.post(url, values);
  return data;
};
export const classReinstall = async () => {
  const url = 'api/class_reinstate_email/12/edit';
  const {
    data: { data }
  } = await authhttp.get(url);
  return data;
};
export const createClassReinstall = async (values) => {
  const url = 'api/class_reinstate_email/12';
  const { data } = await authhttp.post(url, values);
  return data;
};

export const classCancel = async () => {
  const url = 'api/class_cancel_email/13/edit';
  const {
    data: { data }
  } = await authhttp.get(url);
  return data;
};
export const createClassCancel = async (values) => {
  const url = 'api/class_cancel_email/13';
  const { data } = await authhttp.post(url, values);
  return data;
};

export const reccuringClassCancel = async () => {
  const url = 'api/class_cancel_email/14/edit';
  const {
    data: { data }
  } = await authhttp.get(url);
  return data;
};

export const createReccuringClassCancel = async (values) => {
  const url = 'api/class_cancel_email/14';
  const { data } = await authhttp.post(url, values);
  return data;
};

export const createClassRecurring = async (values) => {
  const url = 'api/class_cancel_email/send_mail/23';
  const { data } = await authhttp.post(url, values);
  return data;
};
export const memExp = async () => {
  const url = 'api/membership_expiring_plan_email/15/edit';
  const {
    data: { data }
  } = await authhttp.get(url);
  return data;
};
export const creatememExp = async (values) => {
  const url = 'api/membership_expiring_plan_email/15';
  const { data } = await authhttp.post(url, values);
  return data;
};
export const deleteCommunication = async (id, values) => {
  const newWaiverUrl = `api/delete_communication_attachments/${id}`;
  const { data } = await authhttp.post(newWaiverUrl, values);
  return data;
};
export const addCommunication = async (id, values) => {
  const newWaiverUrl = `api/manage_communication_attachments/${id}`;
  const { data } = await authhttp.post(newWaiverUrl, values);
  return data;
};
export const memExpSession = async () => {
  const url = 'api/membership_expiring_session_email/16/edit';
  const {
    data: { data }
  } = await authhttp.get(url);
  return data;
};
export const creatememExpSession = async (values) => {
  const url = 'api/membership_expiring_session_email/16';
  const { data } = await authhttp.post(url, values);
  return data;
};

export const missingMem = async () => {
  const url = 'api/missing_member_email/17/edit';
  const {
    data: { data }
  } = await authhttp.get(url);
  return data;
};
export const createmissingMem = async (values) => {
  const url = 'api/missing_member_email/17';
  const { data } = await authhttp.post(url, values);
  return data;
};
export const commuSettings = async () => {
  const url = 'api/settings/communication/edit';
  const {
    data: { data }
  } = await authhttp.get(url);
  return data;
};
export const creatcommuSettings = async (values) => {
  const url = 'api/update_settings';
  const { data } = await authhttp.post(url, values);
  return data;
};
