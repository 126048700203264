const styles = () => ({
  root: {
    marginTop: 0,
    margin: '0px 20px 20px',
    padding: '0px 20px 20px',
    '& ul > li:not(:first-child):not(:last-child) > button:not(.Mui-selected)': {
      backgroundColor: 'white'
    },
    '.MuiTypography-root': {
      color: 'white'
    }
  },
  mainCard: {
    // boxShadow: '4px 0px 16px rgba(37, 40, 43, 0.08)',
    // borderRadius: '5px',
    backgroundColor: 'transparent'
    // padding: 20
  },
  mainList: {
    width: 'calc(100% - 10%)',
    height: '160px',
    textAlign: 'left',
    display: 'flex',
    justifyContent: 'start',
    margin: '0 auto 30px',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    padding: '40px',
    borderRadius: '10px',
    '@media (max-width: 992px)': {
      padding: '30px'
    }
  },
  mainTitle: {
    fontWeight: '500',
    fontSize: '48px',
    lineHeight: '44px',
    color: '#FFFFFF',
    fontFamily: 'DDC Hardware',
    '@media (max-width: 992px)': {
      fontSize: '35px'
    }
  },
  mainArrowRight: {
    position: 'absolute',
    right: '35px'
  },
  LockIcon: {
    marginRight: '25px'
  },
  logoMsize: {
    margin: '30px auto 50px',
    textAlign: 'center',
    '@media (max-width: 992px)': {
      margin: '30px auto 30px'
    }
  },
  potential: {
    background: 'rgba(29, 29, 29, 0.94)',
    boxShadow: '0px 0.33333px 0px rgba(255, 255, 255, 0.15)',
    width: '100%',
    height: '92px',
    position: 'fixed',
    bottom: '0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    left: '0',
    right: '0',
    cursor: 'pointer',
    '& h4': {
      fontWeight: '500',
      fontSize: '32px',
      lineHeight: '44px',
      color: '#FFFFFF',
      fontFamily: 'DDC Hardware'
    }
  },
  poteIcon: {
    marginLeft: '15px'
  },
  appBar: {
    // position: 'relative',
    background: 'rgba(29, 29, 29, 0.94)',
    boxShadow: '0px 0.33333px 0px rgb(255 255 255 / 15%)',
    backdropFilter: 'blur(54.3656px)',
    height: '75px',
    position: 'fixed',
    width: '100%'
  },
  appToolbar: {
    minHeight: '75px',
    justifyContent: 'space-between'
  },
  MainCard: {
    height: 'calc(100vh - 75px)',
    background:
      'linear-gradient(180deg, rgba(41, 41, 41, 0.95) 0%, rgba(4, 4, 4, 0.95) 100%)',
    borderRadius: '0',
    flex: '1 1 auto',
    // height: '100%',
    overflow: 'auto',
    marginTop: '75px'
  },
  HomeButton: {
    fontFamily: 'Lato',
    color: '#2CE9DA',
    fontWeight: 'bold',
    fontSize: '22px',
    lineHeight: '32px',
    textTransform: 'capitalize'
  },
  headerTitle: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '26px'
  },
  cutom_select_Label: {
    fontFamily: 'DDC Hardware',
    fontStyle: 'normal',
    fontWeight: '500;',
    fontSize: '22px',
    lineHeight: '32px',
    letterSpacing: '0.2px',
    color: '#FFFFFF',
    '&:focus ': {
      outline: '0'
    }
  },
  cutom_vanguard_FormControl: {
    '& .fd-input input': {
      background: 'rgba(196, 196, 196, 0.05)',
      color: '#fff',
      borderRadius: '6px',
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '20px',
      height: '45px',
      border: '2px solid #C8C8C8',
      padding: '8px 20px 8px',
      '&::placeholder ': {
        color: '#fff',
        fontWeight: 'bold',
        fontSize: '15px'
      }
    },
    '&  .MuiPaper-root': {
      backgroundColor: '#2d2d2d',
      marginTop: '0',
      marginBottom: '0',
      border: '2px solid #C8C8C8',
      borderTop: 'transparent',
      boxShadow: 'none',
      // borderRadius: '0',
      borderTopLeftRadius: '0',
      borderTopRightRadius: '0'
    },
    '& .MuiAutocomplete-option': {
      color: '#fff',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '20px',
      height: '66px'
    },
    '& .MuiAutocomplete-popper': {
      width: '100% !important',
      marginTop: '-2px'
    },
    '& .SelectmemberDrop[aria-expanded="true"]': {
      borderBottom: '2px solid transparent',
      borderBottomLeftRadius: '0',
      borderBottomRightRadius: '0'
    }
  },
  cutom_vanguard_Drop: {
    '& .MuiAutocomplete-popper ': {
      width: 'calc(100% - 16px) !important'
    }
  },
  cutom_vanguard_Autocomplete: {
    background: 'rgba(196, 196, 196, 0.05)',
    border: '2px solid #C8C8C8',
    color: '#fff',
    borderRadius: '6px',
    '& .cutom_select div': {
      minHeight: '66px'
    },
    '& .cutom_select div input': {
      color: '#ffffff !important',
      fontWeight: 'bold',
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontSize: '20px',
      '&::placeholder': {
        fontSize: '20px !important',
        color: '#b9b9ba !important',
        fontWeight: 'bold !important'
      }
    },
    // '& .cutom_select div input::placeholder': {
    //   color: '#b9b9ba',
    //   fontWeight: 'bold',
    //   fontSize: '15px'
    // },
    '& .MuiAutocomplete-endAdornment': {
      minHeight: ' auto !important',
      top: 'calc(50% - 12px)'
    },
    '& .MuiAutocomplete-clearIndicator': {
      color: 'rgb(103 233 218)'
    }
  },
  // cutom_vanguard_Autocomplete: {
  //   '& .MuiAutocomplete-popper .MuiAutocomplete-listbox': {
  //     backgroundColor: '#2d2d2d'
  //   }
  // },
  VanguardCutom_date: {
    '& .MuiInput-root': {
      color: '#fff',
      height: '65px',
      fontWeight: '600',
      fontSize: '20px',
      lineHeight: '18px',
      marginTop: '5px !important',
      border: '2px solid #C8C8C8',
      borderRadius: '6px'
    }
  },
  VArrowButton: {
    margin: '30px 0',
    minWidth: 'auto'
  },
  DateSection: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%',
    '& h5': {
      fontFamily: 'DDC Hardware',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '32px',
      lineHeight: '44px',
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
      letterSpacing: '-1px',
      color: '#616161'
    }
  },
  chalkHeader: {
    padding: '35px 0 0',
    background: '#1D1D1D',
    height: '278px',
    borderBottom: '1px solid #696969',
    borderTop: '1px solid #3f3f3f'
  },
  proList: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    marginBottom: '50px'
  },
  proInfo: {
    display: 'flex',
    width: '100%'
  },
  proName: {
    display: 'flex',
    flex: 1,
    '& h4 label': {
      fontFamily: 'DDC Hardware',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '40px',
      lineHeight: '44px',
      alignItems: 'center',
      textAlign: 'left',
      letterSpacing: '-1px',
      color: '#FFFFFF',
      // display: 'flex',
      wordBreak: 'break-word',
      // display: 'inline-block',
      // display: '-webkit-box',
      // overflow: 'hidden',
      // textOverflow: 'ellipsis',
      // WebkitBoxOrient: 'vertical',
      // WebkitLineClamp: '2',
      // width: '250px',
      '@media (max-width: 992px)': {
        fontSize: '30px'
      }
    },
    '& span ': {
      fontSize: '18px',
      lineHeight: '28px',
      fontWeight: 'bold',
      textAlign: 'right',
      letterSpacing: '0.1px',
      color: 'rgb(255 255 255 / 50%)'
    }
  },
  ArrowRightcss: {
    marginRight: '10px',
    verticalAlign: 'bottom',
    width: '20%',
    display: 'block',
    height: '40px',
    minWidth: '40px'
  },
  proListTime: {
    fontFamily: 'DDC Hardware',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '40px',
    lineHeight: '44px',
    alignItems: 'center',
    textAlign: 'center',
    letterSpacing: '-1px',
    color: '#FFFFFF',
    position: 'relative',
    paddingRight: '16px',
    marginRight: '16px',
    '&:after': {
      content: '""',
      background: 'rgb(255 255 255 / 0.3)',
      width: '1px',
      height: '32px',
      display: 'block',
      position: 'absolute',
      top: '5px',
      right: '0'
    },
    '@media (max-width: 992px)': {
      fontSize: '30px'
    }
  },
  // Workout
  mainContent: {
    height: 'calc(100vh - 410px)',
    overflowY: 'auto'
  },
  // signinmember
  SigninMemberContent: {
    height: 'calc(100vh - 465px)',
    overflowY: 'auto'
  },
  actionBt: {
    background: '#101010',
    height: '118px',
    alignItems: 'center',
    display: 'flex',
    position: 'fixed',
    width: '100%',
    bottom: '0'
  },
  userInBox: {
    width: '189px',
    height: '227px',
    background: '#eaeaea',
    borderRadius: '5px',
    marginRight: '15px',
    marginBottom: '15px',
    '& h4': {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '18px',
      lineHeight: '24px',
      letterSpacing: '0.2px',
      color: '#292929'
    }
  },
  userInfo: {
    padding: '10px',
    height: '127px',
    display: 'block',
    position: 'relative'
  },
  userimage: {
    width: '189px',
    height: '100px',
    objectFit: 'cover',
    display: 'block',
    borderRadius: '5px 5px 0 0'
  },
  defaultAvatar: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '60%',
    padding: '10px',
    maxHeight: '100px',
    objectFit: 'contain'
  },
  ActionBtn: {
    position: 'absolute',
    bottom: '20px',
    '& img ': {
      width: 'auto',
      height: 'auto'
    },
    '& button': {
      width: 'auto',
      minWidth: 'auto',
      fontFamily: 'Lato',
      color: '#2CE9DA',
      fontWeight: 'bold',
      fontSize: '22px',
      lineHeight: '32px',
      textTransform: 'capitalize'
    }
  },
  wodDetails: {
    color: 'white'
    // fontSize: '26px'
    // lineHeight: '32px'
  },
  SignInBtn: {
    width: '240px',
    minWidth: '211px',
    height: '70px',
    '@media (max-width: 992px)': {
      width: '190px',
      minWidth: '211px'
    }
  },
  ViewSignInBtn: {
    width: '211px',
    minWidth: '211px'
  },
  WodName: {
    color: '#2CE9DA',
    fontSize: '40px',
    fontFamily: 'DDC Hardware',
    margin: '0px 0 10px',
    fontWeight: '500;'
  },
  ReservedBox: {
    width: '100%',
    margin: '15px 0',
    display: 'flex',
    flexWrap: 'wrap',
    '& h3': {
      color: '#2ce9da',
      width: '100%',
      margin: '10px 0',
      display: 'inline-block',
      padding: '15px',
      fontSize: '20px',
      background: '#262626',
      fontFamily: 'Lato, sans-serif',
      fontWeight: '900',
      marginBottom: '30px'
    }
  },
  ReservedSignINBtn: {
    marginTop: 'auto',
    marginLeft: 'auto',
    width: 'auto',
    minHeight: 'auto',
    padding: '5px 10px',
    fontSize: '18px',
    bottom: '20px',
    position: 'absolute',
    right: '18px',
    color: '#1e1e1e'
  }
});
export default styles;
