import types from './MembershipReducer.types';
import { getErrorMsg } from '../../utils/ErrorHandling';
import { store } from '../rootReducer';
import { getMembership } from '../../services/Membersip.services';

const fetchingMembership = (type) => ({
  type,
  actionType: types.isFetching
});

const fetchComplete = (type, payload) => ({
  type,
  actionType: types.fetchSucess,
  payload
});

const ErrorFetching = (type, payload) => ({
  type,
  actionType: types.Errorfetch,
  payload
});

const memPlanFilter = (filter, payload) => ({
  type: types.MemPlanFilter,
  filter,
  payload
});

const sessionFilter = (filter, payload) => ({
  type: types.SessionFilter,
  filter,
  payload
});

const dropInFilter = (filter, payload) => ({
  type: types.dropInFilter,
  filter,
  payload
});

const getMemplan = (val) => {
  const type = types.getplanMembership;
  return (dispatch) => {
    dispatch(fetchingMembership(type));
    getMembership(val)
      .then((resp) => dispatch(fetchComplete(type, resp)))
      .catch((err) => dispatch(ErrorFetching(type, getErrorMsg(err))));
  };
};

const getSessionPlan = (val) => {
  const type = types.getsessionMem;
  return (dispatch) => {
    dispatch(fetchingMembership(type));
    getMembership(val)
      .then((resp) => dispatch(fetchComplete(type, resp)))
      .catch((err) => dispatch(ErrorFetching(type, getErrorMsg(err))));
  };
};

const getDropInPlan = (val) => {
  const type = types.getDropInMem;
  return (dispatch) => {
    dispatch(fetchingMembership(type));
    getMembership(val)
      .then((resp) => dispatch(fetchComplete(type, resp)))
      .catch((err) => dispatch(ErrorFetching(type, getErrorMsg(err))));
  };
};

export const getplanMem = (filter, data) => {
  return (dispatch) => {
    dispatch(memPlanFilter(filter, data));
    const filData = store.getState().membershipReducer.membershipFilter;
    let updatedValue = {
      page: filData.page,
      ...filData.default,
      ...filData.sorting
    };
    if (filData.search.length > 0) {
      updatedValue = {
        ...updatedValue,
        search: filData.search
      };
    }
    dispatch(getMemplan(updatedValue));
  };
};
export const getSession = (filter, data) => {
  return (dispatch) => {
    dispatch(sessionFilter(filter, data));
    const filData = store.getState().membershipReducer.sessionFilter;
    let updatedValue = {
      page: filData.page,
      ...filData.default,
      ...filData.sorting
    };
    if (filData.search.length > 0) {
      updatedValue = {
        ...updatedValue,
        search: filData.search
      };
    }
    dispatch(getSessionPlan(updatedValue));
  };
};

export const getDropIn = (filter, data) => {
  return (dispatch) => {
    dispatch(dropInFilter(filter, data));
    const filData = store.getState().membershipReducer.dropInFilter;
    let updatedValue = {
      page: filData.page,
      ...filData.default,
      ...filData.sorting
    };
    if (filData.search.length > 0) {
      updatedValue = {
        ...updatedValue,
        search: filData.search
      };
    }
    dispatch(getDropInPlan(updatedValue));
  };
};
