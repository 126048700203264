export default {
  getplanMembership: 'GET_PLAN_MEMBERSHIP',
  getsessionMem: 'GET_SESSION_MEMBERSHIP',
  MemPlanFilter: 'MEMBERSHIP_PLAN_FILTER',
  SessionFilter: 'MEMBERSHIP_SESSION_FILTER',
  isFetching: 'MEMBERSHIP_FETCHING_START',
  fetchSucess: 'MEMBERSHIP_FETCHED_SUCCESSFUL',
  Errorfetch: 'ERROR_OCCURED_WHEN_FETCHING',
  getDropInMem: 'GET_DROP_IN',
  dropInFilter: 'DROP_IN_FILTER'
};
