import React, { useState } from 'react';
import PropType from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  withStyles,
  TableSortLabel,
  Button
} from '@material-ui/core';
import CalendarSmallIcon from 'src/assets/image/calendarSmall.svg';
import filterType from 'src/Redux/Reducer.constants';
import { ROUTES } from 'src/constant/Constants';
import HistoryDialog from '../../HistoryTable/HistoryDialog';

const styles = () => ({
  table: {
    minWidth: '100%',
    wordBreak: 'break-word',
    '& td': {
      padding: '10px'
    },
    '@media (max-width: 1366px)': {
      '& td': {
        fontSize: '13px'
      }
    }
  },
  tableLink: {
    color: '#01847A',
    fontWeight: '700'
  },
  TableCell: {
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px',
    height: '56px',
    minHeight: '56px',
    padding: '10px',
    '@media (max-width: 1366px)': {
      fontSize: '13px',
      wordBreak: 'keep-all'
    }
  },
  Tablemian: {}
});

const MembershipDetailsTable = (props) => {
  const { values, classes, isActive, setDirection, setSort } = props;
  const {
    sort_members,
    sortemail,
    sort_attendance_limit,
    sort_class_attendance,
    sort_week_since
  } = filterType;

  const [data, setData] = useState({
    open: false,
    id: null,
    userName: ''
  });
  return (
    <TableContainer
      component={Paper}
      elevation={0}
      className={classes.Tablemian}
    >
      <Table className={classes.table} aria-label="simple table">
        <TableHead
          style={{ background: '#F9F9FA', backgroundColor: '#F9F9FA' }}
        >
          <TableRow>
            <TableCell className={classes.TableCell}>
              <TableSortLabel
                active={isActive(sort_members)}
                direction={setDirection(sort_members)}
                onClick={() => setSort(sort_members)}
              >
                Member
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell}>
              <TableSortLabel
                active={isActive(sortemail)}
                direction={setDirection(sortemail)}
                onClick={() => setSort(sortemail)}
              >
                Email
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell}>Phone</TableCell>
            <TableCell className={classes.TableCell} align="right">
              <TableSortLabel
                active={isActive(sort_attendance_limit)}
                direction={setDirection(sort_attendance_limit)}
                onClick={() => setSort(sort_attendance_limit)}
              >
                Attendance limit
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell} align="right">
              <TableSortLabel
                active={isActive(sort_class_attendance)}
                direction={setDirection(sort_class_attendance)}
                onClick={() => setSort(sort_class_attendance)}
              >
                Classes attended
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell} align="right">
              <TableSortLabel
                active={isActive(sort_week_since)}
                direction={setDirection(sort_week_since)}
                onClick={() => setSort(sort_week_since)}
              >
                Weeks since last class
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell} align="center">
              History
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {values.map((row) => (
            <TableRow
              hover
              tabIndex={-1}
              key={row.id}
              style={{
                backgroundColor: '#FFF'
              }}
            >
              <TableCell
                style={{
                  width: '9.0909%'
                }}
              >
                <Link
                  className={classes.tableLink}
                  to={{
                    pathname: ROUTES.PEOPLE_MEMBER_PROFILE,
                    search: `memberId=${btoa(row.id)}`
                  }}
                >
                  {row.member}
                </Link>
              </TableCell>
              <TableCell
                style={{
                  width: '9.0909%'
                }}
                className={classes.tableLink}
              >
                {row.email}
              </TableCell>
              <TableCell
                style={{
                  width: '9.0909%'
                }}
              >
                {row?.mobile_no}
              </TableCell>
              <TableCell
                style={{
                  width: '9.0909%'
                }}
                align="right"
              >
                {row?.attendance_limit_data}
              </TableCell>
              <TableCell
                style={{
                  width: '7%'
                }}
                align="right"
              >
                {row.class_attended}
              </TableCell>
              <TableCell
                style={{
                  width: '7%'
                }}
                align="right"
              >
                {row.week_since}
              </TableCell>
              <TableCell
                style={{
                  width: '7%'
                }}
                align="center"
              >
                {/* {row.history} */}
                <Button
                  onClick={() =>
                    setData({ open: true, id: row.id, userName: row.member })
                  }
                >
                  <img src={CalendarSmallIcon} alt="img" />
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {values.length === 0 && (
        <p
          style={{
            margin: '10px 15px'
          }}
        >
          No attendance to show...
        </p>
      )}
      <HistoryDialog
        id={data.id}
        userName={data.userName}
        setOpen={data.open}
        handleClose={() => setData({ id: '', userName: '', open: false })}
      />
    </TableContainer>
  );
};
MembershipDetailsTable.propTypes = {
  values: PropType.array.isRequired,
  classes: PropType.object.isRequired,
  isActive: PropType.func.isRequired,
  setDirection: PropType.func.isRequired,
  setSort: PropType.func.isRequired
};

export default withStyles(styles)(MembershipDetailsTable);
