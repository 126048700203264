import { ROUTES } from 'src/constant/Constants';
import {
  attendanceHistoryReports,
  getexpireMembershipReports,
  getMembershipReports,
  getMembershipTypeReports,
  getMembersPaymentPlanReports,
  lastAttendanceReports,
  attendanceSummaryReports,
  getinvoicereports,
  getrevenuememreports,
  getoveraccountreports,
  getattendanceTrends,
  getCreditCardExpReports,
  getrevenueCategory,
  getLeadInsights,
  getmembershipsOnhold,
  getReportsListData,
  getMembersValue
} from 'src/Redux/ReportsReducer/reports.reducer.actions';

export default [
  {
    url: ROUTES.REPORTS_MEMBERS,
    tab: 'members',
    functions: getReportsListData,
    restfunc: [
      getMembershipReports,
      getexpireMembershipReports,
      getMembershipTypeReports,
      getMembersPaymentPlanReports,
      getmembershipsOnhold
    ]
  },
  {
    // Membershis and Membership details
    url: ROUTES.REPORTS_MEMBERS_MEMBERSHIP_DETAILS,
    tab: 'members',
    functions: getMembershipReports,
    restfunc: [
      getReportsListData,
      getexpireMembershipReports,
      getMembershipTypeReports,
      getMembersPaymentPlanReports,
      getmembershipsOnhold
    ]
  },
  {
    // Members with expiring membership
    url: ROUTES.REPORTS_MEMBERS_EXPIRING_MEMBERSHIPS,
    tab: 'members',
    functions: getexpireMembershipReports,
    restfunc: [
      getReportsListData,
      getMembershipReports,
      getMembershipTypeReports,
      getMembersPaymentPlanReports,
      getmembershipsOnhold
    ]
  },
  {
    // Members by membership type
    url: ROUTES.REPORTS_MEMBERS_MEMBERSHIP_TYPE,
    tab: 'members',
    functions: getMembershipTypeReports,
    restfunc: [
      getReportsListData,
      getMembershipReports,
      getexpireMembershipReports,
      getMembersPaymentPlanReports,
      getmembershipsOnhold
    ]
  },
  {
    // Members by paayment plan
    url: ROUTES.REPORTS_MEMBERS_PAYMENT_PLAN,
    tab: 'members',
    functions: getMembersPaymentPlanReports,
    restfunc: [
      getReportsListData,
      getMembershipReports,
      getexpireMembershipReports,
      getMembershipTypeReports,
      getmembershipsOnhold
    ]
  },
  {
    // Members with membership on hold
    url: ROUTES.REPORTS_MEMBERS_MEMBERSHIPS_ON_HOLD,
    tab: 'members',
    functions: getmembershipsOnhold,
    restfunc: [
      getReportsListData,
      getMembershipReports,
      getexpireMembershipReports,
      getMembershipTypeReports,
      getMembersPaymentPlanReports
    ]
  },
  {
    url: ROUTES.REPORTS_ATTENDANCE,
    tab: 'attendance',
    functions: getReportsListData,
    restfunc: [
      getattendanceTrends,
      attendanceSummaryReports,
      lastAttendanceReports,
      attendanceHistoryReports
    ]
  },
  {
    // Member attedance trend
    url: ROUTES.REPORTS_ATTENDANCE_ATTENDANCE_TREND,
    tab: 'attendance',
    functions: getattendanceTrends,
    restfunc: [
      getReportsListData,
      attendanceSummaryReports,
      lastAttendanceReports,
      attendanceHistoryReports
    ]
  },
  {
    // Class attedance summary
    url: ROUTES.REPORTS_ATTENDANCE_ATTENDANCE_SUMMARY,
    tab: 'attendance',
    functions: attendanceSummaryReports,
    restfunc: [
      getReportsListData,
      getattendanceTrends,
      lastAttendanceReports,
      attendanceHistoryReports
    ]
  },
  {
    // Member last attedance
    url: ROUTES.REPORTS_ATTENDANCE_LAST_ATTENDANCE,
    tab: 'attendance',
    functions: lastAttendanceReports,
    restfunc: [
      getReportsListData,
      getattendanceTrends,
      attendanceSummaryReports,
      attendanceHistoryReports
    ]
  },
  {
    // Total attendance history
    url: ROUTES.REPORTS_ATTENDANCE_ATTENDANCE_HISTORY,
    tab: 'attendance',
    functions: attendanceHistoryReports,
    restfunc: [
      getReportsListData,
      getattendanceTrends,
      attendanceSummaryReports,
      lastAttendanceReports
    ]
  },
  {
    url: ROUTES.REPORTS_FINANCIAL,
    tab: 'financial',
    functions: getReportsListData,
    restfunc: [
      getinvoicereports,
      getCreditCardExpReports,
      getrevenueCategory,
      getMembersValue,
      getoveraccountreports,
      getrevenuememreports
    ]
  },
  {
    // All invoices
    url: ROUTES.REPORTS_FINANCIAL_ALLINVOICES,
    tab: 'financial',
    functions: getinvoicereports,
    restfunc: [
      getReportsListData,
      getCreditCardExpReports,
      getrevenueCategory,
      getMembersValue,
      getoveraccountreports,
      getrevenuememreports
    ]
  },
  {
    // Expiring credit cards
    url: ROUTES.REPORTS_FINANCIAL_EXPIRING_CREDIT_CARDS,
    tab: 'financial',
    functions: getCreditCardExpReports,
    restfunc: [
      getReportsListData,
      getinvoicereports,
      getrevenueCategory,
      getMembersValue,
      getoveraccountreports,
      getrevenuememreports
    ]
  },
  {
    // Revenue by revenue category
    url: ROUTES.REPORTS_FINANCIAL_REVENUE_CATEGORY,
    tab: 'financial',
    functions: getrevenueCategory,
    restfunc: [
      getReportsListData,
      getinvoicereports,
      getCreditCardExpReports,
      getMembersValue,
      getoveraccountreports,
      getrevenuememreports
    ]
  },
  {
    // Value of each member
    url: ROUTES.REPORTS_FINANCIAL_VALUEMEMBER,
    tab: 'financial',
    functions: getMembersValue,
    restfunc: [
      getReportsListData,
      getinvoicereports,
      getCreditCardExpReports,
      getrevenueCategory,
      getoveraccountreports,
      getrevenuememreports
    ]
  },
  {
    // Overdue account receivable
    url: ROUTES.REPORTS_FINANCIAL_OVERDUE_ACCOUNT_RECEIVABLE,
    tab: 'financial',
    functions: getoveraccountreports,
    restfunc: [
      getReportsListData,
      getinvoicereports,
      getCreditCardExpReports,
      getrevenueCategory,
      getMembersValue,
      getrevenuememreports
    ]
  },
  {
    // Revenue by membership
    url: ROUTES.REPORTS_FINANCIAL_REVENUE_MEMBERSHIP,
    tab: 'financial',
    functions: getrevenuememreports,
    restfunc: [
      getReportsListData,
      getinvoicereports,
      getCreditCardExpReports,
      getrevenueCategory,
      getMembersValue,
      getoveraccountreports
    ]
  },
  {
    url: ROUTES.REPORTS_LEAD,
    tab: 'lead',
    functions: getReportsListData,
    restfunc: [getLeadInsights]
  },
  {
    // Lead insights
    url: ROUTES.REPORTS_LEAD_INSIGHTS,
    tab: 'lead',
    functions: getLeadInsights,
    restfunc: [getReportsListData]
  }
];
