export const initialValue = {
  name: '',
  location: null,
  tax: null,
  initial_commitment: {
    initial: 0,
    billed_every: null
  },
  auto_renew: false,
  auto_renew_meta: {
    renewal: 0,
    billed_every: null
  },
  initial_commitment_pricing: {
    week_setup_fees: 0,
    week_membership_fees: 0,
    full_setup_fees: 0,
    full_membership_fees: 0
  },
  auto_renewal_pricing: {
    week_membership_fees: 0,
    full_membership_fees: 0
  }
};

export const paymentDisType = {
  name: 'CHANGE_NAME',
  location: 'CHANGE_LOCATION',
  changeTax: 'CHANGE_TAX',
  initialCommini: 'INITIAL_COMMITMENT_INITIAL',
  initialCommbill: 'INITIALCOMMITMENT_BILLED',
  autorenew: 'CHANGE_AUTO_RENEW',
  autorenewren: 'AUTO_RENEW_RENEWAL',
  autorenewbill: 'AUTO_RENEW_BILLED',
  initialpriceweek: 'INITIAL_COMMITMENT_PRICING_WEEK_FEE',
  initialpricemem: 'INITIAL_COMMITMENT_PRICING_MEMBER_FEE',
  initialpricefull: 'INITIAL_COMMITMENT_PRICING_FULL_STEP_FEE',
  initialpricefullmem: 'INITIAL_COMMITMENT_PRICING_FULL_MEMBERSHIP',
  autorenweek: 'AUTO_RENEWAL_PRICE_WEEK',
  autorenfull: 'AUTO_RENEWAL_FULL_MEMBER'
};

export const paymentReducer = (state = initialValue, action) => {
  switch (action.type) {
    case paymentDisType.name:
      return {
        ...state,
        name: action.payload
      };
    case paymentDisType.location:
      return {
        ...state,
        location: action.payload
      };
    case paymentDisType.changeTax:
      return {
        ...state,
        tax: action.payload
      };
    case paymentDisType.initialCommini:
      return {
        ...state,
        initial_commitment: {
          ...state.initial_commitment,
          initial: action.payload
        }
      };
    case paymentDisType.initialCommbill:
      return {
        ...state,
        initial_commitment: {
          ...state.initial_commitment,
          billed_every: action.payload
        }
      };
    case paymentDisType.autorenew:
      return {
        ...state,
        auto_renew: !state.auto_renew,
        auto_renew_meta: {
          renewal: state.auto_renew ? 0 : state.auto_renew_meta.renewal,
          billed_every: state.auto_renew
            ? null
            : state.auto_renew_meta.billed_every
        },
        auto_renewal_pricing: {
          week_membership_fees: state.auto_renew
            ? 0
            : state.auto_renewal_pricing.week_membership_fees,
          full_membership_fees: state.auto_renew
            ? 0
            : state.auto_renewal_pricing.full_membership_fees
        }
      };
    case paymentDisType.autorenewren:
      return {
        ...state,
        auto_renew_meta: {
          ...state.auto_renew_meta,
          renewal: action.payload
        }
      };
    case paymentDisType.autorenewbill:
      return {
        ...state,
        auto_renew_meta: {
          ...state.auto_renew_meta,
          billed_every: action.payload
        }
      };
    case paymentDisType.initialpriceweek:
      return {
        ...state,
        initial_commitment_pricing: {
          ...state.initial_commitment_pricing,
          week_setup_fees: action.payload
        }
      };
    case paymentDisType.initialpricemem:
      return {
        ...state,
        initial_commitment_pricing: {
          ...state.initial_commitment_pricing,
          week_membership_fees: action.payload
        }
      };
    case paymentDisType.initialpricefull:
      return {
        ...state,
        initial_commitment_pricing: {
          ...state.initial_commitment_pricing,
          full_setup_fees: action.payload
        }
      };
    case paymentDisType.initialpricefullmem:
      return {
        ...state,
        initial_commitment_pricing: {
          ...state.initial_commitment_pricing,
          full_membership_fees: action.payload
        }
      };
    case paymentDisType.autorenweek:
      return {
        ...state,
        auto_renewal_pricing: {
          ...state.auto_renewal_pricing,
          week_membership_fees: action.payload
        }
      };
    case paymentDisType.autorenfull:
      return {
        ...state,
        auto_renewal_pricing: {
          ...state.auto_renewal_pricing,
          full_membership_fees: action.payload
        }
      };
    default:
      return state;
  }
};
