/* eslint-disable operator-linebreak */
import React, { useRef, useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { openNoti } from 'src/Redux/global/global.actions';
import { isValidPhoto } from 'src/utils/validations';
import PropType from 'prop-types';
import { withStyles, Button } from '@material-ui/core';
import ReactCrop from 'react-image-crop';
import imgplusicon from '../../../assets/image/imgplusicon.png';
import { ReactComponent as Delicon } from '../../../assets/image/del-icon.svg';
import JBButton from '../JBButton/JBButton';
import 'react-image-crop/dist/ReactCrop.css';

const styles = () => ({
  btnReset: {
    position: 'absolute',
    right: '-10px',
    cursor: 'pointer',
    minWidth: '20px',
    padding: '0',
    borderRadius: '50%',
    marginTop: '-10px',
    zIndex: '99',
    color: '#000'
  }
});

const Userimage = (props) => {
  const { classes, OpenNoti, setImage } = props;
  const uploadedImage = useRef(null);
  const imageUploader = useRef(null);
  const canvasref = useRef(null);
  const [image, setimageurl] = useState(null);
  const [preview, setpreview] = useState(false);
  const [crop, setCrop] = useState({ height: 200, width: 200 });
  const [completedCrop, setCompletedCrop] = useState(null);
  const [uploaded, setUploaded] = useState(false);

  useEffect(() => {
    if (preview && canvasref?.current && uploadedImage?.current) {
      const { current: canvas } = canvasref;
      const { current: imageref } = uploadedImage;
      const ctx = canvas.getContext('2d');
      const scaleX = imageref.naturalWidth / imageref.width;
      const scaleY = imageref.naturalHeight / imageref.height;
      const pixelRatio =
        window.navigator?.userAgentData?.mobile ||
        window.navigator.userAgent.match(/iPhone/i)
          ? window.devicePixelRatio
          : 1;
      canvas.width = completedCrop.width * pixelRatio;
      canvas.height = completedCrop.height * pixelRatio;
      // ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
      ctx.imageSmoothingQuality = 'high';
      ctx.drawImage(
        imageref,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
      );

      canvas.toBlob(
        (blob) => {
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = () => {
            const base64data = reader.result;
            setImage(base64data);
          };
        },
        'image/png',
        1
      );
    }
  }, [preview]); // eslint-disable-line

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const { error, value } = isValidPhoto(file);
      if (value) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e2) => {
          const img = new Image();
          img.src = e2.target.result;
          img.onload = (e1) => {
            if (e1.currentTarget.height < 200 || e1.currentTarget.width < 200) {
              OpenNoti(
                'The image is too small. Min. Image resolution should be 200 * 200 pixels.',
                'error'
              );
            } else {
              setimageurl(e2.target.result);
              setUploaded(true);
            }
          };
        };
      } else if (error) {
        OpenNoti(error, 'error');
      }
    }
  };
  const handleReset = () => {
    setImage(null);
    setpreview(false);
    setimageurl(null);
    setUploaded(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };
  const handleDragEnter = (e) => {
    e.preventDefault();
  };

  const onLoad = useCallback((img) => {
    uploadedImage.current = img;
  }, []);

  const handleDrop = (e) => {
    e.preventDefault();
    if (e.dataTransfer) {
      if (e.dataTransfer.files.length > 1) {
        OpenNoti('Please upload one at time', 'error');
      } else {
        const data = {
          target: e.dataTransfer
        };
        handleImageUpload(data);
      }
    }
  };
  const handleLeave = (e) => {
    e.preventDefault();
  };

  const handleInputClick = () => {
    imageUploader.current.value = null;
  };

  return (
    <>
      <div
        className="wrapperimgBox"
        onDragOver={handleDragOver}
        onDragEnter={handleDragEnter}
        onDrop={handleDrop}
        onDragLeave={handleLeave}
        // style={{ display: preview && 'none' }}
      >
        <input
          type="file"
          accept="image/*"
          onChange={handleImageUpload}
          ref={imageUploader}
          onClick={handleInputClick}
          style={{
            display: 'none'
          }}
        />
        {!uploaded ? null : (
          <Button
            color="primary"
            onClick={handleReset}
            className={classes.btnReset}
          >
            <Delicon fontSize="small" />
          </Button>
        )}
        <div
          className="imageUploaderBox"
          onClick={() => !uploaded && imageUploader.current.click()}
        >
          {/* <img
          alt=""
          ref={uploadedImage}
          onError={handleError}
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            borderRadius: '8px',
            objectFit: 'contain'
          }}
        /> */}
          {!uploaded ? null : (
            <ReactCrop
              src={image}
              onImageLoaded={onLoad}
              crop={crop}
              locked
              onChange={(newCrop) => {
                if (preview) {
                  setImage(null);
                  setpreview(false);
                }
                setCrop({
                  height: 200,
                  width: 200,
                  x: newCrop.x,
                  y: newCrop.y,
                  unit: 'px',
                  // as per issue macOS
                  aspect: 4 / 3,
                  minWidth: 200,
                  minHeight: 200,
                  maxWidth: 200,
                  maxHeight: 200
                  // End as per issue macOS
                });
              }}
              onComplete={(c) => setCompletedCrop(c)}
            />
          )}
          {!uploaded ? (
            <span className="Clicktextinfo">
              <img
                src={imgplusicon}
                alt="img"
                style={{
                  display: 'block',
                  margin: '0 auto',
                  cursor: 'pointer'
                }}
              />
              Click or drag file <br /> to this area
            </span>
          ) : null}
        </div>
        {!preview ? (
          <JBButton
            onClick={() => {
              setpreview(!preview);
            }}
            disabled={!completedCrop || !image}
            style={{
              margin: '10px 0'
            }}
          >
            {!preview ? 'preview' : 'clear preview'}
          </JBButton>
        ) : null}
        {!preview ? null : (
          <JBButton
            onClick={() => {
              // setpreview(!preview);
              setpreview(false);
              setImage(null);
            }}
            disabled={!completedCrop || !image}
            style={{
              margin: '10px 0'
            }}
          >
            clear preview
          </JBButton>
        )}
      </div>
      <canvas
        ref={canvasref}
        style={{ margin: '10px auto', display: !preview ? 'none' : 'block' }}
      />
    </>
  );
};

Userimage.propTypes = {
  classes: PropType.object.isRequired,
  OpenNoti: PropType.func.isRequired,
  setImage: PropType.func.isRequired
  // imageRef: PropType.any.isRequired
};

const mapToDispatch = (dispatch) => ({
  OpenNoti: (msg, type) => dispatch(openNoti(msg, type))
});

export default connect(null, mapToDispatch)(withStyles(styles)(Userimage));
