import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropType from 'prop-types';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  TableContainer,
  Button,
  Dialog
} from '@material-ui/core';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import EditIcon from 'src/assets/image/editIcon.png';
import DeleteIcon from 'src/assets/image/deleteIcon.png';
import { openNoti } from 'src/Redux/global/global.actions';
import styles from './table.style';
import { StartCost } from '../../PaymentPlan/PaymentPlan.validation';
import PaymentPlan from '../../PaymentPlan/PaymentPlan';
import SessionPlan from '../../PaymentPlan/SessionPaymentPlan/Sessionpaymentplan';

const PaymentTable = (props) => {
  const {
    classes,
    values,
    Delpay,
    Editpay,
    session,
    currency,
    OpenNotify
  } = props;
  const [openData, setopenData] = useState({
    openDiag: false,
    data: null,
    index: null
  });

  const handleCloseDia = () => {
    setopenData({ openDiag: false, data: null, index: null });
  };

  if (!currency) return <p>Loading...</p>;
  return (
    <Paper elevation={0}>
      <TableContainer
        style={{ border: '1px solid #A8A8A8', borderRadius: '5px' }}
        className={classes.Tablemian}
      >
        <Table
          stickyHeader
          style={{
            wordBreak: 'break-word'
          }}
        >
          <TableHead className={classes.table_th}>
            <TableRow>
              <TableCell style={{ width: '15%' }} className={classes.TableCell}>
                Name
              </TableCell>
              <TableCell style={{ width: '10%' }} className={classes.TableCell}>
                Cost
              </TableCell>
              <TableCell style={{ width: '10%' }} className={classes.TableCell}>
                Setup fee
              </TableCell>
              <TableCell style={{ width: '10%' }} className={classes.TableCell}>
                Location
              </TableCell>
              {session ? null : (
                <TableCell
                  style={{ width: '10%' }}
                  className={classes.TableCell}
                >
                  Auto renew
                </TableCell>
              )}

              <TableCell style={{ width: '9%' }} className={classes.TableCell}>
                &nbsp;
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={classes.Table_Body}>
            {values.map((row, i) => (
              <TableRow key={row.Classname}>
                <TableCell
                  scope="row"
                  className={clsx(classes.tableLink, classes.Table_td)}
                >
                  {row.name}
                </TableCell>
                <TableCell scope="row" className={classes.Table_td}>
                  {session
                    ? currency.currency_symbol
                      + parseFloat(
                        parseFloat(
                          row.initial_commitment_pricing.full_membership_fees
                        )
                          + parseFloat(
                            row.initial_commitment_pricing.full_setup_fees
                          )
                      ).toFixed(2)
                    : StartCost(row, currency.currency_symbol)}
                </TableCell>
                <TableCell scope="row" className={classes.Table_td}>
                  {/* eslint-disable-next-line */}
                  {session
                    ? row.initial_commitment_pricing.full_setup_fees > 0
                      ? 'Yes'
                      : 'No'
                    : parseFloat(
                      row.initial_commitment_pricing.full_setup_fees
                    ) > 0
                      || parseFloat(
                        row.initial_commitment_pricing.week_setup_fees
                      ) > 0
                      ? 'Yes'
                      : 'No'}
                </TableCell>

                <TableCell scope="row" className={classes.Table_td}>
                  {row.location ? row.location.gym_name : 'Jungle Brothers'}
                </TableCell>

                {session ? null : (
                  <TableCell scope="row" className={classes.Table_td}>
                    {row.auto_renew ? 'Yes' : 'No'}
                  </TableCell>
                )}
                <TableCell
                  scope="row"
                  align="right"
                  className={classes.Table_td}
                >
                  <Button
                    className={classes.actionbtn}
                    onClick={() =>
                      setopenData({ openDiag: true, data: row, index: i })
                    } // eslint-disable-line
                  >
                    <img alt="EditIcon" src={EditIcon} />
                  </Button>
                  <Button
                    className={classes.actionbtn}
                    onClick={() => {
                      Delpay(i);
                      OpenNotify('Payment plan deleted successfully', 'info');
                    }}
                  >
                    <img alt="DeleteIcon" src={DeleteIcon} />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Dialog
          open={openData.data}
          fullWidth
          maxWidth="lg"
          onClose={handleCloseDia}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          disableBackdropClick
        >
          {session ? (
            <SessionPlan
              getData={(val) => Editpay(val, openData.index)}
              handleClose={handleCloseDia}
              values={openData.data}
              allpayments={values}
              editIndex={openData.index}
              dialogtitle="Edit Payment Plan"
            />
          ) : (
            <PaymentPlan
              getData={(val) => Editpay(val, openData.index)}
              handleClose={handleCloseDia}
              values={openData.data}
              allpayments={values}
              editIndex={openData.index}
              dialogtitle="Edit Payment Plan"
            />
          )}
        </Dialog>
      </TableContainer>
    </Paper>
  );
};

PaymentTable.defaultProps = {
  session: false
};

PaymentTable.propTypes = {
  classes: PropType.object.isRequired,
  values: PropType.array.isRequired,
  Delpay: PropType.func.isRequired,
  Editpay: PropType.func.isRequired,
  currency: PropType.object.isRequired,
  session: PropType.bool,
  OpenNotify: PropType.func.isRequired
};

const mapToDispatch = (dispatch) => ({
  OpenNotify: (msg, type) => dispatch(openNoti(msg, type))
});

const mapToSTate = (state) => ({
  currency: state.SettingReducer.currency.data
});

export default connect(
  mapToSTate,
  mapToDispatch
)(withStyles(styles)(PaymentTable));
