const styles = () => ({
  searchInput: {
    padding: '6px 20px 6px !important;',
    width: '100%',
    height: '35px',
    boxShadow: '0px 12px 40px rgba(37, 40, 43, 0.32)',
    borderRadius: '8px',
    border: '1px solid #E4E4E4'
  },
  hr: {
    border: '1px solid rgb(196 196 196 / 0.4)',
    display: 'inline-block',
    width: '100%',
    margin: '15px 0'
  },
  media: {
    // display: '-ms-flexbox',
    display: 'flex',
    msFlexSlign: 'start',
    alignItems: 'flex-start'
  },
  mediaSelected: {
    display: 'flex',
    msFlexSlign: 'start',
    alignItems: 'flex-start',
    backgroundColor: 'green'
  },
  mediabody: {
    flex: '1',
    fontSize: '14px',
    marginLeft: '15px'
  },
  uName: {
    fontWeight: 'bold',
    color: '#292929'
  },
  uEmail: {
    color: '#292929'
  },
  userInfo: {
    padding: '15px',
    '& ul': {
      fontSize: '14px',
      padding: '15px',
      borderBottom: '1px solid #EAEAEA',
      '& li': {
        display: 'inline-block',
        minWidth: 'calc(100% / 3)',
        '&:first-child ': {
          float: 'left'
        },
        '&:nth-child(2)': {
          minWidth: '20%',
          display: 'inline-flex',
          width: 'calc(100% - 40%)'
        }
      }
    }
  },
  userWrapper: {
    background: '#01847A',
    borderRadius: '8px',
    borderBottomLeftRadius: '0',
    borderBottomRightRadius: '0',
    padding: '15px',
    '& h3': {
      color: '#FFF',
      fontSize: '20px'
    }
  },
  lastattendance: {
    fontSize: '14px',
    padding: '5px 0',
    '& span': {
      color: 'rgb(255 255 255 / 0.5)'
    },
    '& label': {
      color: '#FFFFFF',
      marginLeft: '5px'
    }
  },
  textoutline: {
    '& ul': {
      fontSize: '12px',
      border: '0',
      padding: '0px 0px 0 0',
      color: '#FFFFFF',
      '& li': {
        display: 'inline-block !important',
        minWidth: 'auto !important',
        border: '1px solid rgb(255 255 255 / 60%)',
        margin: '5px 5px 0 0',
        padding: '5px 15px',
        width: 'auto !important'
      }
    }
  },
  userStatus: {
    color: '#01847A',
    borderRadius: '10px',
    background: '#fff',
    fontSize: '14px',
    fontWeight: '700',
    textTransform: 'capitalize'
  },
  userContectInfo: {
    border: '1px solid #A8A8A8',
    boxSizing: 'border-box',
    borderRadius: '0px 0px 4px 4px',
    wordBreak: 'break-word'
  },
  UserAvatar: {
    width: '95px',
    height: '95px',
    borderRadius: '50%'
  },
  Avataruname: {
    width: '95px',
    height: '95px',
    backgroundColor: '#CCE6E4',
    fontSize: '24px',
    fontWeight: 'bold',
    color: '#37847a'
  },
  ListSelected: {
    backgroundColor: '#EFF4F5',
    cursor: 'pointer',
    position: 'relative',
    '& :before': {
      content: '""',
      left: 0,
      width: '2px',
      height: '100%',
      position: 'absolute',
      background: '#01847A',
      zIndex: '999',
      top: '0'
    }
  },
  btnClose: {
    position: 'fixed',
    zIndex: '9999',
    background: 'rgb(255 255 255 / 50%) !important',
    padding: '0 0',
    minWidth: 'auto',
    borderRadius: '50%',
    right: '0',
    width: '64px',
    height: '64px'
  },
  uAvatar: {
    width: '40px',
    height: '40px',
    fontSize: '20px',
    backgroundColor: '#CCE6E4',
    fontWeight: 'bold',
    color: '#37847a',
    '&.listAv::before': {
      display: 'none',
      background: 'transparent !important'
    }
  }
});

export default styles;
