export const initialValue = {
  data: {
    default_reservation_settings: {
      login_open_time: null,
      login_open_unit: null,
      res_open_time: null,
      res_open_unit: null,
      res_close_time: null,
      res_close_unit: null,
      res_cancel_time: null,
      res_cancel_unit: null
    },
    late_cancel_settings: true,
    late_cancel_email_template: {
      visibility: false,
      subject: 'Test',
      text:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
    },
    no_show_settings: false,
    no_show_email_template: {
      visibility: true,
      subject: 'Test',
      text:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
    }
  }
};

export const SettingReducerTypes = {
  getData: 'Fetched_Data',
  reservation: 'default_reservation_setting',
  lateEmail: 'Late_Email_Template',
  noShowEmail: 'No_Show_Email_Template',
  noShow: 'No_Show_Setting',
  lateCancel: 'Late_Cancel_Setting',
  resOpenTime: 'resvervation_open_time',
  resOpenUnit: 'reservation_open_unit',
  resCloseTime: 'reservation_close_time',
  resCloseUnit: 'reservation_close_Unit',
  resCancelTime: 'reservation_cancellation_time',
  resCancelUnit: 'reservation_cancel_unit',
  loginOpenTime: 'login_open_time',
  loginOpenUnit: 'login_open_unit',
  emailSub: 'Email_Subject',
  emailText: 'Email_Text',
  enableEmail: 'Enable_Email_Template'
};

export const ClassSettingreducer = (state = initialValue, action) => {
  switch (action.type) {
    case SettingReducerTypes.getData:
      return {
        ...state,
        data: action.payload
      };
    case SettingReducerTypes.reservation: {
      const setData = () => {
        switch (action.subType) {
          case SettingReducerTypes.resOpenTime:
            return {
              ...state,
              data: {
                ...state.data,
                default_reservation_settings: {
                  ...state.data.default_reservation_settings,
                  res_open_time: action.payload
                }
              }
            };
          case SettingReducerTypes.resOpenUnit:
            return {
              ...state,
              data: {
                ...state.data,
                default_reservation_settings: {
                  ...state.data.default_reservation_settings,
                  res_open_unit: action.payload
                }
              }
            };
          case SettingReducerTypes.resCloseTime:
            return {
              ...state,
              data: {
                ...state.data,
                default_reservation_settings: {
                  ...state.data.default_reservation_settings,
                  res_close_time: action.payload
                }
              }
            };
          case SettingReducerTypes.resCloseUnit:
            return {
              ...state,
              data: {
                ...state.data,
                default_reservation_settings: {
                  ...state.data.default_reservation_settings,
                  res_close_unit: action.payload
                }
              }
            };
          case SettingReducerTypes.resCancelTime:
            return {
              ...state,
              data: {
                ...state.data,
                default_reservation_settings: {
                  ...state.data.default_reservation_settings,
                  res_cancel_time: action.payload
                }
              }
            };
          case SettingReducerTypes.resCancelUnit:
            return {
              ...state,
              data: {
                ...state.data,
                default_reservation_settings: {
                  ...state.data.default_reservation_settings,
                  res_cancel_unit: action.payload
                }
              }
            };
          case SettingReducerTypes.loginOpenTime:
            return {
              ...state,
              data: {
                ...state.data,
                default_reservation_settings: {
                  ...state.data.default_reservation_settings,
                  login_open_time: action.payload
                }
              }
            };
          case SettingReducerTypes.loginOpenUnit:
            return {
              ...state,
              data: {
                ...state.data,
                default_reservation_settings: {
                  ...state.data.default_reservation_settings,
                  login_open_unit: action.payload
                }
              }
            };
          default:
            return state;
        }
      };
      return setData();
    }
    case SettingReducerTypes.lateCancel:
      return {
        ...state,
        data: {
          ...state.data,
          late_cancel_settings: action.payload
        }
      };
    case SettingReducerTypes.lateEmail: {
      const getData = () => {
        switch (action.subType) {
          case SettingReducerTypes.enableEmail:
            return {
              ...state,
              data: {
                ...state.data,
                late_cancel_email_template: {
                  ...state.data.late_cancel_email_template,
                  visibility: action.payload
                }
              }
            };
          case SettingReducerTypes.emailSub:
            return {
              ...state,
              data: {
                ...state.data,
                late_cancel_email_template: {
                  ...state.data.late_cancel_email_template,
                  subject: action.payload
                }
              }
            };
          case SettingReducerTypes.emailText:
            return {
              ...state,
              data: {
                ...state.data,
                late_cancel_email_template: {
                  ...state.data.late_cancel_email_template,
                  text: action.payload
                }
              }
            };
          default:
            return state;
        }
      };
      return getData();
    }
    case SettingReducerTypes.noShow:
      return {
        ...state,
        data: {
          ...state.data,
          no_show_settings: action.payload
        }
      };
    case SettingReducerTypes.noShowEmail: {
      const getData = () => {
        switch (action.subType) {
          case SettingReducerTypes.enableEmail:
            return {
              ...state,
              data: {
                ...state.data,
                no_show_email_template: {
                  ...state.data.no_show_email_template,
                  visibility: action.payload
                }
              }
            };
          case SettingReducerTypes.emailSub:
            return {
              ...state,
              data: {
                ...state.data,
                no_show_email_template: {
                  ...state.data.no_show_email_template,
                  subject: action.payload
                }
              }
            };
          case SettingReducerTypes.emailText:
            return {
              ...state,
              data: {
                ...state.data,
                no_show_email_template: {
                  ...state.data.no_show_email_template,
                  text: action.payload
                }
              }
            };
          default:
            return state;
        }
      };
      return getData();
    }
    default:
      return state;
  }
};
