import React, { createContext, useReducer } from 'react';
import PropType from 'prop-types';
import {
  attendanceReducer,
  initialValue
} from './AttendanceSummary/attendanceReducer';

export const AttendanceContext = createContext();

export const AttendanceSummaryContext = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(attendanceReducer, initialValue);
  return (
    <AttendanceContext.Provider value={[state, dispatch]}>
      {children}
    </AttendanceContext.Provider>
  );
};
AttendanceSummaryContext.propTypes = {
  children: PropType.node.isRequired
};
