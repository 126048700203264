const color = [
  '#B4E09F',
  '#8BC9A0',
  '#55B4A1',
  '#FEB89D',
  '#F9D77F',
  '#F9F785',
  '#FDBAC7',
  '#CD85A0',
  '#D4A1F3',
  '#A1A7DC',
  '#A9BFEF',
  '#9AD6E2'
];
export default color;
