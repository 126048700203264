import React from 'react';
import { makeStyles } from '@material-ui/core';
import { ROUTES } from 'src/constant/Constants';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import VanguardTabs from '../../views/vanguard/vanguardTabs';
// import NavBar from './VanguardNav';

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: '#2e2e2e',
    background:
      'linear-gradient(180deg, rgba(41, 41, 41, 0.95) 0%, rgba(4, 4, 4, 0.95) 100%)',
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%',
    '& .VanguardWrapper': {
      paddingBottom: window.location.pathname === ROUTES.VANGUARD ? '75px' : 0
    },
    '& .InnerWrapper': {
      paddingTop: '0px'
    }
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    height: '100vh',
    paddingTop: '75px',
    left: 'auto',
    maxWidth: '1024px',
    margin: '0 auto',
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 272
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: 0
    }
  },
  wrapperLeft: {
    display: 'flex',
    // overflow: 'hidden',
    paddingTop: 140,
    wordBreak: 'break-all',
    width: '285px',
    // height: 'calc(100% - 195px)',
    minHeight: '200px',
    borderRight: '0',
    backgroundColor: '#f9f9fa',
    position: 'fixed',
    [theme.breakpoints.up('lg')]: {
      // paddingLeft: 272
    }
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}));

const VanguardLayout = (props) => {
  const { currentUser } = props;
  const classes = useStyles();
  // const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  return (
    <div className={classes.root}>
      {/* <div className={classes.wrapperLeft}>
        <NavBar
          onMobileClose={() => setMobileNavOpen(false)}
          openMobile={isMobileNavOpen}
        />
      </div> */}
      {window.location.pathname === ROUTES.VANGUARD ? (
        <div className={clsx(classes.wrapper, 'VanguardWrapper')}>
          <div className={classes.contentContainer}>
            <div className={classes.content}>
              <VanguardTabs currentUserData={currentUser} />
            </div>
          </div>
        </div>
      ) : (
        <div className={clsx(classes.wrapper, 'InnerWrapper')}>
          <div className={classes.contentContainer}>
            <div className={classes.content}>
              <VanguardTabs currentUserData={currentUser} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

VanguardLayout.propTypes = {
  currentUser: PropTypes.object.isRequired
};

export default VanguardLayout;
