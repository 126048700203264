import authhttp from './authHttp.services';

const ProUrl = 'api/programes';
export const getPaginateData = async (Url) => {
  const {
    data: { data }
  } = await authhttp.get(Url);
  return data;
};

export const getProgramData = async (values) => {
  const {
    data: { data }
  } = await authhttp.get(ProUrl, {
    params: {
      ...values
    }
  });
  return data;
};

export const editProgram = async (id, value) => {
  const Url = ProUrl + '/' + id;
  const data = await authhttp.put(Url, value);
  return data;
};

export const createProgram = async (val) => {
  const data = await authhttp.post(ProUrl, val);
  return data;
};

export const ProgramActiveInActive = async (val) => {
  const Progurl = 'api/activate_program';
  const data = await authhttp.post(Progurl, val);
  return data;
};

export const getProData = async (id) => {
  const proUrl = `api/programes/${id}/edit`;
  const {
    data: { data }
  } = await authhttp.get(proUrl);
  return data;
};
