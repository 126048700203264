import React from 'react';
import PropType from 'prop-types';
import { connect } from 'react-redux';
import {
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  makeStyles
} from '@material-ui/core';
import { openNoti } from 'src/Redux/global/global.actions';
// import { getErrorMsg } from 'src/utils/ErrorHandling';
// import { delLineInvoice, updateLineInv } from 'src/services/Invoice.services';
import GetPrice from 'src/views/common/CurrencySymbol/GetPrice';
// import DeleteDropInDialog from '../DeleteDropInDialog';
// import DeleteIcon from '../../../../../assets/image/deleteIcon.png';
// import EditItem from '../EditItem';
// import { getEditItem } from '../AddItem.validation';

const useStyles = makeStyles({
  table: {
    minWidth: '100%'
  },
  tableLink: {
    color: '#01847A',
    fontWeight: '700'
  },
  TableCell: {
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px',
    height: '56px',
    minHeight: '56px'
  },
  Tablemian: {
    borderRadius: '0px',
    backgroundColor: 'transparent',
    '& .MuiTableCell-head': {
      color: '#FFF',
      backgroundColor: 'rgb(59, 60, 78)'
    },
    '& .MuiTableSortLabel-root:hover': {
      color: '#01847A'
    },
    '& .MuiTableSortLabel-root.MuiTableSortLabel-active': {
      color: '#ffffff'
    },
    '& .MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon': {
      color: '#ffffff'
    },
    '& .MuiTableCell-body': {
      color: '#FFF'
    },
    '& .MuiTableBody-root .MuiTableCell-root': {
      borderBottom: '1px solid rgba(234, 234, 234, 0.2)'
    }
  },
  button_status_deac: {
    fontWeight: 'bold',
    fontSize: '11px',
    color: '#01847A',
    border: '1px solid rgba(1, 132, 122, 1)',
    width: '100px',
    height: '24px',
    padding: '0 0',
    borderRadius: '2px'
  },
  button_status_act: {
    fontWeight: 'bold',
    fontSize: '11px',
    color: '#9B9B9B',
    border: '1px solid #949494',
    width: '100px',
    height: '24px',
    padding: '0 0',
    borderRadius: '2px',
    '&$hover': {
      backgroundColor: '#1976d2'
    }
  },
  DeleteIcon: {
    padding: '6px 5px',
    minWidth: 'auto'
  }
});

const InvoiceTable = (props) => {
  const classes = useStyles();
  const {
    values,
    currencyState: { data, error },
    // OpenNoti,
    // deleteItem,
    statuscode
  } = props;
  // const [DeleteDialog, setDeleteDialog] = useState({
  //   id: null,
  //   show: false
  // });
  // const [editDiag, seteditDiag] = useState({
  //   data: null,
  //   show: false
  // });

  // const handleDelete = () => {
  //   if (DeleteDialog.id) {
  //     delLineInvoice(DeleteDialog.id)
  //       .then((res) => deleteItem(res))
  //       .then(() => setDeleteDialog({ id: null, show: false }))
  //       .catch((err) => OpenNoti(getErrorMsg(err), 'error'));
  //   }
  // };

  // const handleSave = (values) => {
  //   updateLineInv(values, editDiag.data.id)
  //     .then((res) => deleteItem(res))
  //     .then(() => OpenNoti('Successfuly updated the product', 'info'))
  //     .then(() => seteditDiag({ data: null, show: false }))
  //     .catch((err) => OpenNoti(getErrorMsg(err), 'error'));
  // };

  if (error) return <p>Error Occured , please try again...</p>;
  return (
    <TableContainer
      component={Paper}
      elevation={0}
      className={classes.Tablemian}
    >
      <Table className={classes.table} aria-label="simple table">
        <TableHead
          style={{ background: '#3B3C4E', backgroundColor: '#3B3C4E' }}
        >
          <TableRow>
            <TableCell className={classes.TableCell}>Product name</TableCell>
            <TableCell className={classes.TableCell} align="right">
              Quantity
            </TableCell>
            <TableCell className={classes.TableCell} align="right">
              Item price
            </TableCell>
            <TableCell className={classes.TableCell}>&nbsp;</TableCell>
            <TableCell className={classes.TableCell}>&nbsp;</TableCell>
            {statuscode === 5 ? (
              <TableCell className={classes.TableCell} align="right">
                Refunded Amount
              </TableCell>
            ) : null}
            <TableCell className={classes.TableCell} align="right">
              Total
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow
            hover
            tabIndex={-1}
            key={data.id}
            style={{
              backgroundColor: '#26273B'
            }}
          >
            <TableCell
              style={{
                width: '25%'
                // cursor: 'pointer'
              }}
              // onClick={() => seteditDiag({ data: row, show: true })}
            >
              {values.product_name}
            </TableCell>
            <TableCell
              style={{
                width: '5%'
              }}
              align="right"
            >
              1
            </TableCell>
            <TableCell
              style={{
                width: '15%'
              }}
              align="right"
            >
              <GetPrice value={values.plan_monthly_price} />
              {/* {data.currency_symbol +
                  parseInt(row.sale_price, 10).toFixed(data.decimals)} */}
            </TableCell>
            <TableCell
              style={{
                width: '13%'
              }}
              align="right"
            >
              {values.notes}
            </TableCell>
            <TableCell
              style={{
                width: '15%'
              }}
              align="right"
            >
              &nbsp;
            </TableCell>
            {statuscode === 5 ? (
              <TableCell
                style={{
                  width: '17%'
                }}
                align="right"
              >
                (<GetPrice value={values.refunded_amount} valuetype="neg" />)
              </TableCell>
            ) : null}
            <TableCell
              style={{
                width: '17%'
              }}
              align="right"
            >
              <GetPrice value={values.plan_monthly_price} />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      {/* {editDiag.data && (
        <EditItem
          setOpen={editDiag.show}
          // item={getEditItem(editDiag.data)}
          getValues={(val) => handleSave(val)}
          handleClose={() => seteditDiag({ data: null, show: false })}
        />
      )} */}
      {/* <DeleteDropInDialog
        setOpen={DeleteDialog.show}
        handleDelete={() => handleDelete()}
        handleClose={() => setDeleteDialog({ id: null, show: false })}
      /> */}
    </TableContainer>
  );
};
InvoiceTable.propTypes = {
  values: PropType.array.isRequired,
  currencyState: PropType.object.isRequired,
  // OpenNoti: PropType.func.isRequired,
  // deleteItem: PropType.func.isRequired,
  statuscode: PropType.number.isRequired
};

const mapToState = (state) => ({
  currencyState: state.SettingReducer.currency
});

const mapToDispatch = (dispatch) => ({
  OpenNoti: (msg, type) => dispatch(openNoti(msg, type))
});

export default connect(mapToState, mapToDispatch)(InvoiceTable);
