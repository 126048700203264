/* eslint-disable operator-linebreak */
/* eslint-disable indent */
import React, { useState, Fragment, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Grid,
  withStyles,
  Card,
  Breadcrumbs,
  Typography,
  Box,
  Paper,
  ThemeProvider
} from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
import { debounce } from 'lodash';
import { useSelector, connect } from 'react-redux';
import LoadingView from 'src/views/common/loading';
import Pagination from '@material-ui/lab/Pagination';
import ApplyFilter from 'src/views/common/ApplyFilter/applyFilter';
import filterType from 'src/Redux/Reducer.constants';
import { getrevenuememreports } from 'src/Redux/ReportsReducer/reports.reducer.actions';
import { exportRevenueMembership } from 'src/services/reports.services';
import CancelDialogTwo from 'src/views/common/CommonDialogBox/CancelDialogTwo';
import useChangeTitle from 'src/views/common/CustomHooks/useTitleChange';
import JBButton from '../../common/JBButton/JBButton';
import ImportIcon from '../../../assets/image/upload.png';
import CustomAutoComplete from '../../common/CustomAutocomplete/commonAutocomplete';
import RevenueMembershipTable from './AllTables/RevenueMembershipTable/RevenueMembershipTable';
// import { DummyValues } from './AllTables/RevenueMembershipTable/dummyData';
import BarChart from '../chart/BarChart';
import styles from './FinancialReports.style';
import { getRevenueChart } from '../Reports.utils';
import RevenueMembershipFilters from './Filters/RevenueMembershipFilters';

export const MembershipTypeFilter = [
  { id: 'Plan', name: 'plan' },
  { id: 'Session', name: 'session' },
  { id: 'Drop In', name: 'drop_in' },
  { id: 'All', name: 'all' }
];

const themeInstance = {
  overrides: {
    MuiBreadcrumbs: {
      root: {
        color: '#949494',
        textTransform: 'uppercase',
        fontSize: '20px',
        lineHeight: '24px',
        fontWeight: '700'
      },
      li: {
        '& a': {
          color: '#949494',
          textTransform: 'uppercase',
          fontSize: '20px',
          lineHeight: '24px'
        },
        '@media (max-width: 1440px)': {
          '& a': {
            fontSize: '18px'
          },
          '& h3': {
            fontSize: '18px'
          }
        },
        '& h3': {
          margin: '0 0'
        }
      },
      separator: {
        marginLeft: '12px',
        marginRight: '12px'
      }
    }
  }
};

const RevenueMembership = (props) => {
  // eslint-disable-next-line react/prop-types
  const { ApplyFilterData, classes, filteredTags, customFilter } = props;
  const { isFetching, data, error } = useSelector(
    (state) => state?.reportsReducer?.financialReports?.revenueMembership
  );
  useChangeTitle('Revenue by Membership | Jungle Alliance');
  const [open, setOpen] = useState(false);
  // const [dataFil, setDataFil] = useState(null);
  const [Membershipdata, setMembershipData] = useState(
    () => filteredTags.filter((l) => l.filter === 'membership_type')[0]
  );

  const { data: data_reports } = useSelector(
    (state) => state.reportsReducer.reportList
  );

  const isStarred = data_reports?.financial_report.filter(
    (report) => report.id === 15
  )[0].is_starred;

  // const setDirection = (name) => {
  //   if (AttendanceSort.sort_name === name) {
  //     return AttendanceSort.sort_by === 'asc' ? 'asc' : 'dsc';
  //   }
  //   return 'asc';
  // };

  // const isActive = (sortlabel) => {
  //   if (AttendanceSort.sort_name === sortlabel) return true;
  //   return false;
  // };

  // const setSort = (sortlabel) => {
  //   AttendanceFilter(filterType.sorting, sortlabel);
  // };

  const Paginate = (val) => {
    if (val !== data.current_page) {
      return ApplyFilterData(filterType.page, val);
    }
    return null;
  };

  const handleReset = () => {
    ApplyFilterData(filterType.resetFilter);
    setMembershipData(null);
  };

  const ProgramSelect = useCallback(
    debounce((q) => {
      if (q) {
        ApplyFilterData(filterType.addcustom, [
          { membership_type: q?.name === 'all' ? '' : q.name },
          {
            id: 10000001,
            name: `Membership type is ${q?.id}`,
            filter: 'membership_type'
          }
        ]);
      }
    }, 1000),
    []
  );
  useEffect(() => {
    return () => {
      handleReset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (!data_reports || !data) return <LoadingView />;
  return (
    <div className={classes.root}>
      <div className="title-bk">
        <Box css={{ marginBottom: '20px' }}>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={0}
          >
            <Grid item xs={9}>
              <Box display="inline-flex">
                <ThemeProvider theme={themeInstance}>
                  <Breadcrumbs aria-label="breadcrumb">
                    <div color="inherit">Reports</div>
                    <div
                      color="inherit"
                      onClick={() => setOpen(true)}
                      style={{
                        cursor: 'pointer'
                      }}
                    >
                      financial reports
                    </div>
                    <Typography variant="h3" color="Primary">
                      <span>Revenue by Membership</span>
                    </Typography>
                  </Breadcrumbs>
                </ThemeProvider>
              </Box>
              <Box
                display="inline-flex"
                ml="10px"
                css={{ verticalAlign: 'text-bottom' }}
              >
                {isStarred === 1 && (
                  <StarIcon
                    style={{
                      color: '#FFC700'
                    }}
                  />
                )}
              </Box>
            </Grid>
            <Grid
              item
              xs={3}
              style={{
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              <JBButton
                type="outlined"
                isImg={ImportIcon}
                component={Link}
                style={{ marginLeft: '10px' }}
                disabled={data.data.data.length === 0}
                onClick={(e) => {
                  e.preventDefault();
                  exportRevenueMembership({ ...customFilter });
                }}
              >
                Export
              </JBButton>
            </Grid>
          </Grid>
        </Box>
      </div>

      <Card className={classes.mainCard}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={0}
        >
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={0}
          >
            <Grid
              item
              xs={12}
              justify="center"
              alignItems="center"
              textAlign="center"
            >
              {data?.membership_name.length === 0 &&
              data?.net_revenue.length === 0 ? null : (
                <Box css={{ textAlign: 'center' }}>
                  <Box display="inline-block">
                    <Typography variant="h6" className={classes.BkChartTitle}>
                      <span>Revenue By Membership</span>
                    </Typography>
                    <BarChart
                      xlabel="MEMBERSHIP NAME"
                      ylabel="NET REVENUE"
                      data={getRevenueChart(
                        data?.membership_name,
                        data?.net_revenue
                      )}
                      className={classes.BkChart}
                    />
                  </Box>
                </Box>
              )}
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container direction="row" alignItems="center" spacing={0}>
              <Grid
                item
                style={{
                  display: 'inherit'
                }}
              >
                <Box css={{ width: '240px' }} display="inline-block">
                  {/* <CustomAutoComplete
                    holder="Payment due"
                    Options="filter"
                    data={dataFil || null}
                    // value={PaymentDue}
                    value={TobeFilter}
                    Change={(val) => setDataFil(val)}
                    // style={{ marginBottom: '15px', marginTop: '0px' }}
                    disableClearable
                  /> */}
                  <ApplyFilter>
                    <RevenueMembershipFilters
                      getFilData={(val, val2) =>
                        ApplyFilterData(filterType.addcustom, [val, val2])
                      }
                    />
                  </ApplyFilter>
                </Box>
                <Box css={{ width: '240px' }} display="inline-block" ml="15px">
                  {/* <CustomAutoComplete holder="Select All" /> */}
                  <CustomAutoComplete
                    holder="Select Membership Type"
                    Options="id"
                    data={Membershipdata || null}
                    value={MembershipTypeFilter}
                    Change={(val) => {
                      setMembershipData(val);
                      ProgramSelect(
                        val,
                        filteredTags.filter(
                          (l) => l.filter === 'membership_type'
                        )[0]
                      );
                    }}
                    style={{ marginTop: '0px' }}
                    disableClearable
                  />
                </Box>
              </Grid>
              <Grid item xs>
                <JBButton
                  type="outlined"
                  style={{ marginLeft: '10px' }}
                  onClick={() => handleReset()}
                >
                  reset
                </JBButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {isFetching ? (
          <LoadingView />
        ) : error ? (
          <p>{error}</p>
        ) : (
          <Fragment>
            <Grid item xs={12}>
              <Box mt="12.5px" mb="0">
                {filteredTags.map((tags) => (
                  <div
                    className={classes.DTags}
                    key={tags.id}
                    style={{ margin: '0 0 0px', display: 'inline-block' }}
                  >
                    <Box display="inline-block" mr={1}>
                      <label>
                        {tags.name}
                        <span
                          className={classes.DTagsClose}
                          onClick={() => {
                            ApplyFilterData(filterType.removecustom, tags);
                            if (tags.filter === 'membership_type') {
                              setMembershipData(null);
                            }
                          }}
                        >
                          ×
                        </span>
                      </label>
                    </Box>
                  </div>
                ))}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box mt="25px" />
              <RevenueMembershipTable
                values={data?.data?.data}
                Summary={data?.summary}
              />
            </Grid>
            {data?.data.data.length !== 0 && (
              <Grid
                item
                xs={12}
                container
                direction="row"
                alignItems="center"
                style={{ marginTop: 24 }}
              >
                <Grid item xs={6} justify="flex-start">
                  <Paper elevation={0} style={{ color: '#A9A9A9' }}>
                    {data?.data.from && data?.data.to > 1 ? (
                      <Paper elevation={0} style={{ color: '#A9A9A9' }}>
                        {`${data?.data.from} - ${data?.data.to} of ${data?.data.total}`}
                      </Paper>
                    ) : null}
                  </Paper>
                </Grid>
                <Grid
                  item
                  xs={6}
                  justify="flex-end"
                  style={{ display: 'flex' }}
                >
                  {/* <Pagination
              count={data?.data.last_page}
              color="primary"
              variant="outlined"
              page={data?.data.current_page}
              onChange={(_, val) => console.log(val)}
              shape="rounded"
            /> */}
                  <Pagination
                    count={data.data.last_page}
                    color="primary"
                    variant="outlined"
                    page={data.data.current_page}
                    // onChange={(_, val) => console.log(val)}
                    onChange={(__, val) => Paginate(val)}
                    shape="rounded"
                  />
                </Grid>
              </Grid>
            )}
          </Fragment>
        )}
      </Card>
      <CancelDialogTwo setOpen={open} handleClose={() => setOpen(false)} />
    </div>
  );
};

RevenueMembership.propTypes = {
  classes: PropTypes.object.isRequired,
  ApplyFilterData: PropTypes.func.isRequired,
  filteredTags: PropTypes.array.isRequired
};

const mapToState = (state) => ({
  filteredTags:
    state?.reportsReducer?.financialReports?.revenueMembershipFil.tags,
  RevenueMembershipSearch:
    state?.reportsReducer?.financialReports?.revenueMembershipFil.search,
  customFilter:
    state.reportsReducer.financialReports.revenueMembershipFil.customFilter
});

const mapToDispatch = (dispatch) => ({
  ApplyFilterData: (filter, data) =>
    dispatch(getrevenuememreports(filter, data))
});

export default connect(
  mapToState,
  mapToDispatch
)(withStyles(styles)(RevenueMembership));
