import { checkEmail, isValidQuill } from 'src/utils/validations';

export function getdataforemail(values) {
  return {
    email: values.email,
    subject: values.subject,
    body: isValidQuill(values.body) ? values.body : `<p>${values.body}</p>`
  };
}

export function validTemplate(data) {
  if (checkEmail(data.email)) {
    return { error: 'Please specify valid email address' };
  }
  if (data.subject?.trim().length === 0) {
    return { error: 'Please specify subject' };
  }
  // if (data.body?.trim().length === 0) {
  //   return { error: 'email body cant be empty' };
  // }
  return { data: getdataforemail(data) };
}
