import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles,
  Dialog,
  Button,
  ClickAwayListener,
  Grid,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList
} from '@material-ui/core';
import SignContractDialog from 'src/views/Documents/SignContract/SignContractDialog';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import LoadingView from 'src/views/common/loading';
import { getDays } from 'src/utils/someCommon';
import { ROUTES } from 'src/constant/Constants';
import { connect } from 'react-redux';
import moment from 'moment';
import { openNoti } from 'src/Redux/global/global.actions';
import { ContractEmail } from 'src/services/Contracts.services';
import AutoRenewDialog from './AutoRenewDialog';
import AddNewMemberPlanHold from './AddNewMemberPlanHold';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  paper: {
    marginRight: theme.spacing(2)
  }
}));

const Actions = ({ data, updateUi, reducer, openNotify }) => {
  const classes = useStyles();
  // const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [openDiag, setDiag] = React.useState(false);
  const [openSignDiag, setSignDiag] = useState({
    id: null,
    open: false
  });
  const [dropDownData, setDownData] = React.useState(null);
  const [state] = reducer;

  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  const handleEmailSend = (dataval) => {
    ContractEmail(dataval)
      .then(() => {
        openNotify('Email send successfully');
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    const dropDownData1 = [];
    let m = 0;
    if (state.data.payment_data.auto_renew_meta.renewal) {
      for (
        let i = 1;
        i
        <= (365 - getDays(new Date(), state.data.expires_date))
          / (state.data.payment_data.auto_renew_meta.renewal * 7);
        i += 1
      ) {
        dropDownData1.push({
          id: m,
          label: moment(state.data.expires_date, 'DD, MMMM YYYY')
            .add(state.data.payment_data.auto_renew_meta.renewal * m, 'weeks')
            .format('DD/MM/YYYY')
        });
        m += 1;
      }
    }
    setDownData(dropDownData1);
  }, [
    state.data.expires_date,
    state.data.payment_data.auto_renew_meta.renewal
  ]);
  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }
  if (!dropDownData) {
    return (
      <Dialog>
        <LoadingView />
      </Dialog>
    );
  }

  return (
    <div className={classes.root}>
      <div>
        {/* <Box>
          <JBButton
            aria-controls={open ? 'simple-menu' : undefined}
            aria-haspopup="true"
            style={{ height: '48px', fontWeight: '700' }}
            ref={anchorRef}
            onClick={() => setOpen(!open)}
          >
            <span
              style={{
                display: 'inline-flex'
              }}
            >
              Actions
              {open ? <ExpandLess /> : <ExpandMore />}
            </span>
          </JBButton>
          <Menu
            id="simple-menu"
            anchorEl={anchorRef.current}
            keepMounted
            open={open}
            onClose={handleClose}
            style={{
              zIndex: '9999',
              position: 'absolute',
              inset: '55px 0px 0px 0px',
              width: '150px',
              height: '240px'
            }}
          >
            <MenuItem>Profile</MenuItem>
            <MenuItem>My account</MenuItem>
            <MenuItem>Logout</MenuItem>
          </Menu>
        </Box> */}

        <Button
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={() => setOpen(!open)}
          color="primary"
          variant="outlined"
          style={{ height: '48px', fontWeight: '700' }}
        >
          Actions
          {open ? <ExpandLess /> : <ExpandMore />}
        </Button>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          style={{ zIndex: '99' }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
                zIndex: '99'
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={() => setOpen(false)}>
                  <MenuList
                    autoFocusItem={open}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                  >
                    {state.data.payment_data.auto_renew ? (
                      <MenuItem onClick={() => setDiag(1)}>
                        {state.data.is_stop_autorenew
                          ? 'Edit Auto-Renew'
                          : 'Stop Auto-Renew'}
                      </MenuItem>
                    ) : null}
                    <MenuItem onClick={() => setDiag(2)}>Add Hold</MenuItem>
                    {state.data.sign_status === 0 && (
                      <MenuItem
                        onClick={() =>
                          setSignDiag({
                            id: state.data.user_contract_id,
                            open: true
                          })
                        }
                      >
                        Sign Contract
                      </MenuItem>
                    )}
                    <MenuItem
                      onClick={() => {
                        handleEmailSend(state.data.user_contract_id);
                      }}
                    >
                      Email Contract
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
      <Grid>
        {openSignDiag.id && openSignDiag.open ? (
          <SignContractDialog
            id={openSignDiag.id}
            open={openSignDiag.open}
            setClose={() => {
              setSignDiag({ id: null, open: false });
            }}
            disableBackdropClick
            handleHistory={`${ROUTES.PEOPLE_MEMBER_PROFILE}?memberId=${btoa(
              data.user_member_id
            )}`}
          />
        ) : null}
      </Grid>
      {openDiag === 2 ? (
        <AddNewMemberPlanHold
          reducer={reducer}
          value={data}
          open1
          updateUi={(res) => updateUi(res)}
          onClose={() => setDiag(false)}
        />
      ) : (
        <></>
      )}
      {openDiag === 1 ? (
        <AutoRenewDialog
          reducer={reducer}
          value={{ dropDownData, data }}
          open1
          onClose={() => setDiag(false)}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

Actions.propTypes = {
  data: PropTypes.object.isRequired,
  reducer: PropTypes.array.isRequired,
  updateUi: PropTypes.func.isRequired,
  openNotify: PropTypes.func.isRequired
};

// export default Actions;
const mapToDispatch = (dispatch) => ({
  openNotify: (msg, type) => dispatch(openNoti(msg, type))
});

export default connect(null, mapToDispatch)(Actions);
