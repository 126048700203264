import moment from 'moment';
import {
  getIdfromArr,
  getTime,
  getValuefromObject
} from 'src/utils/someCommon';
import { checkValidString } from 'src/utils/validations';
import { WeekData } from '../Class/createClass.Data';
import { Weekdata } from './Dialog.Data';

const getMetaforDialog = (days, head_coach) => {
  let result = {};
  for (let i = 0; i < days.length; i += 1) {
    const b = {};
    const { id } = days[i];
    b[id] = {
      is_limit: 0
    };
    if (head_coach) {
      b[id] = {
        ...b[id],
        head_coach: getValuefromObject(head_coach)
      };
    }
    result = { ...result, ...b };
  }
  return result;
};

const getWeeklyDOW = (startDate) => {
  const day = moment(startDate).format('dddd');
  const result = Weekdata.filter((list) => list.name === day)[0].id;
  return result;
};

const getWeeklyMeta = (startDate, coach) => {
  const day = moment(startDate).format('dddd');
  const dayid = Weekdata.filter((list) => list.name === day)[0].id;
  const result = {};
  result[dayid] = {
    is_limit: 0
  };
  if (coach) {
    result[dayid] = {
      ...result[dayid],
      head_coach: getValuefromObject(coach)
    };
  }
  return JSON.stringify(result);
};

export const getDOWcolor = (startDate) => {
  const day = moment(startDate).format('dddd');
  const result = Weekdata.filter((list) => list.name === day);
  for (let i = 0; i < result.length; i += 1) {
    result[i] = { ...result[i], colors: true };
  }
  return result;
};

export const getValWeek = (val, arr, data) => {
  let result = [...arr];
  if (!val) {
    result = result.filter((list) => list.id !== data.id);
  } else if (val) {
    result = [...result, val];
  }
  return result;
};

export const getRepeatStr = (repeatType, data, date) => {
  let days = '';
  let result = '';
  if (!data.end_type || !repeatType) {
    return result;
  }
  if (repeatType.id === 'weekly' && data.end_type.id === 'ondate') {
    result = `Repeats weekly every ${moment(date).format('dddd')}
                            until ${moment(data.end_date).format('LL')}`;
    return result;
  }
  if (repeatType.id === 'weekly' && data.end_type.id === 'forever') {
    result = `Repeats weekly every ${moment(date).format('dddd')} `;
    return result;
  }
  for (let i = 0; i < data.day_of_week.length; i += 1) {
    if (i < data.day_of_week.length - 1) {
      days += data.day_of_week[i].name + ', ';
    } else {
      days += data.day_of_week[i].name;
    }
  }
  if (repeatType.id === 'custom' && !data.repeatition_week) {
    return result;
  }
  if (repeatType.id === 'custom' && data.end_type.id === 'ondate') {
    result = `Repeat every ${getValuefromObject(
      data.repeatition_week
    )} weeks on ${days} until ${moment(data.end_date).format('LL')}`;
    return result;
  }
  if (repeatType.id === 'custom' && data.end_type.id === 'forever') {
    result = `Repeat every ${getValuefromObject(
      data.repeatition_week
    )} weeks on ${days}`;
    return result;
  }
  return result;
};

export const validateCreate = (data, recurrdata) => {
  if (
    data.name.length === 0
    || checkValidString(data.name)
    || !data.program
    || !data.start_time
    || !data.end_time
    || !data.repeat_type
    || data.end_time.getTime() <= data.start_time.getTime()
    || new Date().getTime() >= data.start_time.getTime()
  ) {
    return null;
  }
  let values = {
    attendence_limit: 1,
    program_id: getValuefromObject(data.program),
    name: data.name,
    start_date: moment(data.start_time).format('YYYY-MM-DD'),
    start_time: getTime(data.start_time),
    end_time: getTime(data.end_time)
  };
  const {
    repeat_type: { id }
  } = data;
  switch (id) {
    case 0: {
      values = {
        ...values,
        is_limit: 0
      };
      if (data.coach) {
        values = {
          ...values,
          head_coach: getValuefromObject(data.coach)
        };
      }
      return values;
    }
    case 'custom': {
      if (
        !recurrdata.end_type
        || recurrdata.day_of_week.length === 0
        || !recurrdata.repeatition_week
      ) {
        return null;
      }
      values = {
        ...values,
        day_of_week: getIdfromArr(recurrdata.day_of_week),
        end_type: getValuefromObject(recurrdata.end_type),
        repeat_type: getValuefromObject(data.repeat_type),
        meta: JSON.stringify(
          getMetaforDialog(recurrdata.day_of_week, data.coach)
        ),
        repetition_period: getValuefromObject(recurrdata.repeatition_week)
      };
      if (recurrdata.end_type.id === 'ondate') {
        values = {
          ...values,
          end_date: moment(recurrdata.end_date).format('YYYY-MM-DD')
        };
      }
      return values;
    }
    case 'weekly': {
      if (!recurrdata.end_type) {
        return null;
      }
      values = {
        ...values,
        repeat_type: getValuefromObject(data.repeat_type),
        end_type: getValuefromObject(recurrdata.end_type),
        day_of_week: getWeeklyDOW(data.start_time),
        meta: getWeeklyMeta(data.start_time, data.coach)
      };
      if (recurrdata.end_type.id === 'ondate') {
        values = {
          ...values,
          end_date: moment(recurrdata.end_date).format('YYYY-MM-DD')
        };
      }
      return values;
    }
    default:
      return data;
  }
};

export const getRepeatEditStr = (data) => {
  let days = '';
  const dayofweek = data.day_of_week ? data.day_of_week.split(',') : '';

  for (let i = 0; i < dayofweek.length; i += 1) {
    for (let j = 0; j < WeekData.length; j += 1) {
      if (dayofweek[i] === Weekdata[j].id) {
        days += Weekdata[j].name + ', ';
      }
    }
  }
  if (days[days.length - 1] === ',') {
    days = days.slice(0, days.length - 1);
  }
  switch (data.repeat_type) {
    case 'custom': {
      const getData = () => {
        switch (data.end_type) {
          case 'ondate':
            return `Repeat every ${data.repetition_period} weeks on ${days} until ${data.end_date}`;
          case 'forever':
            return `Repeat every ${data.repetition_period} weeks on ${days}`;
          default:
            return '';
        }
      };
      return getData();
    }
    case 'weekly': {
      const getData = () => {
        switch (data.end_type) {
          case 'ondate':
            return `Repeats weekly on every ${days} until ${data.end_date}`;
          case 'forever':
            return `Repeats weekly on every ${days}`;
          default:
            return '';
        }
      };
      return getData();
    }
    default:
      return '';
  }
};

export const getUpdateDiag = (data) => {
  if (
    data.class_name.length === 0
    || checkValidString(data.class_name)
    || !data.start_time
    || !data.end_time
    || new Date().getTime() >= data.start_time.getTime()
    || data.end_time.getTime() <= data.start_time.getTime()
  ) {
    return null;
  }
  let values = {
    name: data.class_name,
    program_id: data.program_id,
    start_date: moment(data.start_time).format('YYYY-MM-DD'),
    start_time: moment(data.start_time).format('hh:mm A'),
    end_time: moment(data.end_time).format('hh:mm A'),
    head_coach: null,
    co_coach: null,
    is_limit: data.is_limit,
    attendence_limit: data.attendence_limit
  };
  if (data.is_limit === 1) {
    values = {
      ...values,
      allow_waitlist: data.allow_waitlist,
      class_limit: data.class_limit
    };
  }
  if (data.allow_waitlist) {
    values = {
      ...values,
      class_open: data.class_open
    };
  }
  if (data.head_coach) {
    values = {
      ...values,
      head_coach: getValuefromObject(data.head_coach)
    };
  }
  if (data.co_coach) {
    values = {
      ...values,
      co_coach: getValuefromObject(data.co_coach)
    };
  }
  return values;
};
