import React, { useState } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropType from 'prop-types';
import {
  DeleteSingleClass
  // DelRecurrClass
} from 'src/services/classModule.services';
import {
  AllClassdata,
  futureddata,
  reccurdata
} from 'src/Redux/ClassReducer/classReducer.actions';
import { Grid, Box, Typography } from '@material-ui/core';
import { openNoti } from 'src/Redux/global/global.actions';
import { withStyles } from '@material-ui/core/styles';
import { getCalenderData } from 'src/Redux/CalenderReducer/Calender.actions';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { getErrorMsg } from 'src/utils/ErrorHandling';
import Checkbox from '@material-ui/core/Checkbox';
import DeletedDialog from './DialogDeleteAll';
import dcloseicon from '../../../assets/image/closeicon.png';
import arrowleft from '../../../assets/image/arrowleft.png';
import styles from './Dialog.style';

function DeleteDialog(props) {
  const {
    classes,
    goBack,
    values,
    OpenNotiDisp,
    reloadCalender,
    onClose,
    getAllClass,
    getFuture,
    getReccClass,
    allevents
  } = props;
  const [deleteDiag, setDeleteDiag] = useState({
    show: false,
    allClass: false
  });
  const [check, setcheck] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [processingAll, setProcessingAll] = useState(false);
  // Delete Dialog Box
  const handleSingleDelete = () => {
    setProcessing(true);
    const value = {
      id: values.id,
      future_class: 0,
      send_mail: check === true ? 1 : 0
    };
    DeleteSingleClass(value)
      .then(() => reloadCalender())
      .then(() => getAllClass())
      .then(() => getFuture())
      .then(
        () =>
          setDeleteDiag({
            ...deleteDiag,
            show: true
          })
        // eslint-disable-next-line function-paren-newline
      )
      .then(() => setProcessing(false))
      .catch((err) => {
        OpenNotiDisp(getErrorMsg(err), 'error');
        setProcessing(false);
      });
  };
  const handleAllDelete = () => {
    setProcessingAll(true);
    const value = {
      id: values.id,
      future_class: 1,
      send_mail: check === true ? 1 : 0
    };
    DeleteSingleClass(value)
      .then(() => reloadCalender())
      .then(() => getAllClass())
      .then(() => getFuture())
      .then(() => getReccClass())
      .then(
        () =>
          setDeleteDiag({
            show: true,
            allClass: true
          })
        // eslint-disable-next-line function-paren-newline
      )
      .then(() => setProcessingAll(false))
      .catch((err) => {
        OpenNotiDisp(getErrorMsg(err), 'error');
        setProcessingAll(false);
      });
  };
  return (
    <div>
      {/* eslint-disable-next-line */}
      {allevents.isFetching ? null : (
        <>
          {deleteDiag.show ? (
            deleteDiag.allClass ? (
              <DeletedDialog allClass values={values} onClose={onClose} />
            ) : (
              <DeletedDialog values={values} onClose={onClose} />
            )
          ) : (
            <div style={{ width: '285px' }} className={classes.dmainbk}>
              <Grid alignItems="center" container direction="row">
                <Grid
                  item
                  xs
                  style={{ cursor: 'pointer' }}
                  onClick={() => goBack()}
                >
                  <Button
                    className={classes.btns}
                    style={{
                      display: 'inline-block',
                      marginLeft: '11px',
                      marginRight: '0',
                      lineHeight: 'normal'
                    }}
                  >
                    <img alt="img" src={arrowleft} />
                  </Button>
                  <DialogTitle
                    id="alert-dialog-title"
                    color="primary"
                    className={classes.titlebk}
                    style={{
                      display: 'inline-block'
                    }}
                  >
                    <h4 className={classes.dtitle}>Delete Class</h4>
                  </DialogTitle>
                </Grid>

                <Grid item style={{ textAlign: 'right', marginRight: '10px' }}>
                  <Button className={classes.btns} onClick={() => onClose()}>
                    <img alt="img" src={dcloseicon} />
                  </Button>
                </Grid>
              </Grid>

              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <Typography
                    variant="h5"
                    gutterBottom
                    style={{ color: '#000', textAlign: 'center' }}
                  >
                    Warning
                  </Typography>
                  <div className={classes.warningInfo}>
                    <Box display="block">
                      <p>
                        Are you sure you want to delete the {values.class_name}{' '}
                        on {moment(values.start_time).format('llll')}
                        <span>
                          All reservations from this class will be removed.
                        </span>
                      </p>
                    </Box>
                  </div>

                  <Box display="block" mt={5} mb={1}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={check}
                          onChange={() => setcheck(!check)}
                          name="checkedB"
                          color="primary"
                        />
                      }
                      label="Send 'Cancelled Class' email to admins, managers and coaches"
                      style={{ color: '#000' }}
                    />
                  </Box>
                  <div className={classes.d_button}>
                    <Box display="block" mt={2} mb={1}>
                      <Button
                        variant="outlined"
                        color="primary"
                        fullWidth
                        onClick={handleSingleDelete}
                        disabled={processing}
                      >
                        Delete only this class
                      </Button>
                    </Box>
                    {values.is_recurring ? (
                      <Box display="block" mt={2} mb={1}>
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={handleAllDelete}
                          fullWidth
                          style={{
                            paddingLeft: '10px',
                            paddingRight: '10px'
                          }}
                          disabled={processingAll}
                        >
                          Delete this & future classes
                        </Button>
                      </Box>
                    ) : null}
                  </div>
                </DialogContentText>
              </DialogContent>
            </div>
          )}
        </>
      )}
    </div>
  );
}

DeleteDialog.propTypes = {
  classes: PropType.object.isRequired,
  goBack: PropType.func.isRequired,
  values: PropType.object.isRequired,
  OpenNotiDisp: PropType.func.isRequired,
  reloadCalender: PropType.func.isRequired,
  onClose: PropType.func.isRequired,
  getReccClass: PropType.func.isRequired,
  getAllClass: PropType.func.isRequired,
  getFuture: PropType.func.isRequired,
  allevents: PropType.object.isRequired
};

const mapToState = (state) => ({
  allevents: state.calenderReducer
});

const mapToDispatch = (dispatch) => ({
  OpenNotiDisp: (msg, type) => dispatch(openNoti(msg, type)),
  reloadCalender: () => dispatch(getCalenderData()),
  getFuture: () => dispatch(futureddata()),
  getAllClass: () => dispatch(AllClassdata()),
  getReccClass: () => dispatch(reccurdata())
});

export default connect(
  mapToState,
  mapToDispatch
)(withStyles(styles)(DeleteDialog));
