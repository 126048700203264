import { getReducerData } from 'src/Redux/reducer.common.utils';
import filterList from 'src/Redux/Reducers.utils';
import initalState from './masterhqallies.initialstate';
import {
  ACTION_TYPES,
  ALLIES_DETAILS,
  CHANGE_ACTIVE_TAB,
  PAST_INVOICE,
  PLAN_DETAILS,
  PAST_INVOICE_FILTER,
  DISCOUNTS,
  DISCOUNT_EXPIRE_TYPE,
  DISCOUNT_MONTHS,
  DISCOUNT_END_DATE,
  DISCOUNT_AMOUNT,
  DISCOUNT_TYPE,
  DISCOUNT_VALUE,
  TRANSACTION_CHARGES,
  RESET_VALUES
} from './masterhqallies.types';

export default function masterhqReducer(state = initalState, action) {
  const { type, actionType, payload, filter } = action;
  const {
    userdetails,
    paymentBilling: { past_invoices, past_invoices_filter },
    subscription_details: {
      plan_details: { all_plans }
    }
  } = state;

  switch (type) {
    case ALLIES_DETAILS:
      return {
        ...state,
        userdetails: getReducerData(
          actionType,
          userdetails,
          payload,
          ACTION_TYPES
        )
      };

    case CHANGE_ACTIVE_TAB:
      return {
        ...state,
        activeTab: payload
      };

    case PAST_INVOICE:
      return {
        ...state,
        paymentBilling: {
          ...state.paymentBilling,
          past_invoices: getReducerData(
            actionType,
            past_invoices,
            payload,
            ACTION_TYPES
          )
        }
      };

    case PAST_INVOICE_FILTER:
      return {
        ...state,
        paymentBilling: {
          ...state.paymentBilling,
          past_invoices_filter: filterList(
            past_invoices_filter,
            filter,
            payload
          )
        }
      };

    case PLAN_DETAILS:
      return {
        ...state,
        subscription_details: {
          ...state.subscription_details,
          plan_details: {
            ...state.subscription_details.plan_details,
            all_plans: getReducerData(
              actionType,
              all_plans,
              payload,
              ACTION_TYPES
            )
          }
        }
      };
    case TRANSACTION_CHARGES:
      return {
        ...state,
        subscription_details: {
          ...state.subscription_details,
          transactions: getReducerData(
            actionType,
            state.subscription_details.transactions,
            payload,
            ACTION_TYPES
          )
        }
      };
    case DISCOUNTS:
      return {
        ...state,
        subscription_details: {
          ...state.subscription_details,
          discounts: getReducerData(
            actionType,
            state.subscription_details.discounts,
            payload,
            ACTION_TYPES
          )
        }
      };
    case DISCOUNT_EXPIRE_TYPE:
      return {
        ...state,
        subscription_details: {
          ...state.subscription_details,
          discount: {
            ...state.subscription_details.discount,
            discount_valid_type: payload
          }
        }
      };
    case DISCOUNT_MONTHS:
      return {
        ...state,
        subscription_details: {
          ...state.subscription_details,
          discount: {
            ...state.subscription_details.discount,
            no_of_month: payload
          }
        }
      };
    case DISCOUNT_END_DATE:
      return {
        ...state,
        subscription_details: {
          ...state.subscription_details,
          discount: {
            ...state.subscription_details.discount,
            end_date: payload
          }
        }
      };
    case DISCOUNT_VALUE:
      return {
        ...state,
        subscription_details: {
          ...state.subscription_details,
          discount: {
            ...state.subscription_details.discount,
            discount_value: payload
          }
        }
      };
    case DISCOUNT_TYPE:
      return {
        ...state,
        subscription_details: {
          ...state.subscription_details,
          discount: {
            ...state.subscription_details.discount,
            discount_type: payload
          }
        }
      };
    case DISCOUNT_AMOUNT:
      return {
        ...state,
        subscription_details: {
          ...state.subscription_details,
          discount: {
            ...state.subscription_details.discount,
            discount_amount: payload
          }
        }
      };
    case RESET_VALUES:
      return {
        ...state,
        subscription_details: {
          ...state.subscription_details,
          discount: { ...initalState.subscription_details.discount }
        }
      };
    default:
      return state;
  }
}
