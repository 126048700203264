/* eslint-disable react/no-unescaped-entities */
import React, { useEffect } from 'react';
import PropType from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  withStyles,
  Grid,
  Paper
} from '@material-ui/core';
import { connect, useDispatch } from 'react-redux';
import filterType from 'src/Redux/Reducer.constants';
import {
  getUserHistory,
  UserHistoryFilter
} from 'src/Redux/ReportsReducer/reports.reducer.actions';
import Pagination from '@material-ui/lab/Pagination';
import LoadingView from 'src/views/common/loading';
import dcloseicon from '../../../../assets/image/closeicon.png';
import ClassHistoryTable from './HistoryTable';

const styles = () => ({
  dtitle: {
    color: '#292929',
    fontWeight: 'bold',
    fontSize: '33px',
    lineHeight: 'normal'
  },
  cutom_select_Label: {
    fontSize: '12px',
    padding: '7px 0'
  },
  titlebk: {
    display: 'inline-block',
    padding: '25px 24px 10px'
  }
});

const ClassHistoryDialog = (props) => {
  const {
    classes,
    // handleHistory,
    handleClose,
    setOpen,
    id,
    userName,
    HistoryFilter,
    userHistoryData,
    userHistorySort
  } = props;

  // const [state, dispatch] = useContext();
  // const { data, isFetching, error } = state.membersLastAttendance;

  const { isFetching, data, error } = userHistoryData;
  const dispatch = useDispatch();
  const isActive = (sortlabel) => {
    if (userHistorySort.sort_name === sortlabel) return true;
    return false;
  };
  const setSort = (sortlabel) => {
    HistoryFilter(id, filterType.sorting, sortlabel);
  };
  const setDirection = (name) => {
    if (userHistorySort.sort_name === name) {
      return userHistorySort.sort_by === 'asc' ? 'asc' : 'dsc';
    }
    return 'asc';
  };
  const paginate = (val) => {
    if (val !== data.current_page) {
      return HistoryFilter(id, filterType.page, val);
    }
    return null;
  };
  const handleReset = () => {
    dispatch(UserHistoryFilter(filterType.resetFilter));
  };
  useEffect(() => {
    HistoryFilter(id);
    return () => {
      handleReset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div {...props}>
      <Dialog
        open={setOpen}
        onClose={() => handleClose()}
        fullWidth
        maxWidth="lg"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick
      >
        <div>
          <Grid alignItems="center" container direction="row">
            <Grid item xs>
              <DialogTitle id="alert-dialog-title" className={classes.titlebk}>
                <h4 className={classes.dtitle}>{userName}'s Class History</h4>
              </DialogTitle>
            </Grid>
            <Grid
              item
              xs={2}
              style={{ textAlign: 'right', marginRight: '10px' }}
            >
              <Button className={classes.btns} onClick={handleClose}>
                <img alt="img" src={dcloseicon} />
              </Button>
            </Grid>
          </Grid>

          <DialogContent>
            <Grid container>
              <Grid item xs={12}>
                {isFetching ? (
                  <LoadingView />
                ) : error ? (
                  <p>{error}</p>
                ) : (
                  <ClassHistoryTable
                    isActive={isActive}
                    setDirection={setDirection}
                    setSort={setSort}
                    values={data?.data}
                  />
                )}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions
            style={{
              padding: '20px 24px'
            }}
          >
            <Grid
              item
              xs={12}
              container
              direction="row"
              alignItems="center"
              style={{ marginTop: 24 }}
            >
              <Grid item xs={6} justify="flex-start">
                {data?.from && data?.to > 1 ? (
                  <Paper elevation={0} style={{ color: '#A9A9A9' }}>
                    {`${data?.from} - ${data?.to} of ${data?.total}`}
                  </Paper>
                ) : null}
              </Grid>
              <Grid item xs={6} justify="flex-end" style={{ display: 'flex' }}>
                <Pagination
                  count={data?.last_page}
                  color="primary"
                  variant="outlined"
                  page={data?.current_page}
                  onChange={(_, val) => paginate(val)} //eslint-disable-line
                  // onChange={handleChangePage}
                  shape="rounded"
                />
                {/* <Pagination
                  count={tableValue.last_page}
                  color="primary"
                  variant="outlined"
                  page={tableValue.current_page}
                  onChange={(_, val) => Paginate(val)}
                  shape="rounded"
                /> */}
              </Grid>
            </Grid>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
};

const mapToDispatch = (dispatch) => ({
  HistoryFilter: (id, filter, data) =>
    dispatch(getUserHistory(id, filter, data))
});
const mapToState = (state) => ({
  userHistoryData: state.reportsReducer.attendanceReports.userHistory,
  userHistorySort: state.reportsReducer.attendanceReports.userHistoryFil.sorting
});
ClassHistoryDialog.defaultProps = {
  // handleHistory: null,
  userName: ''
};

ClassHistoryDialog.propTypes = {
  classes: PropType.object.isRequired,
  // handleHistory: PropType.string,
  setOpen: PropType.bool.isRequired,
  handleClose: PropType.func.isRequired,
  id: PropType.number.isRequired,
  userName: PropType.string,
  HistoryFilter: PropType.func.isRequired,
  userHistoryData: PropType.object.isRequired,
  userHistorySort: PropType.object.isRequired
};
export default connect(
  mapToState,
  mapToDispatch
)(withStyles(styles)(ClassHistoryDialog));
