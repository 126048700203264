import React from 'react';
import {
  changeDateCalender,
  ChangeViewCal
} from 'src/Redux/CalenderReducer/Calender.actions';
import { connect } from 'react-redux';
import { checkCalDateRange } from 'src/utils/someCommon';
import PropType from 'prop-types';
import Toolbar from 'react-big-calendar/lib/Toolbar';
import { AppBar, Tabs, Tab, Grid, Button, Typography } from '@material-ui/core';
import CalLeft from '../../../assets/image/calLeft.png';
import CalRight from '../../../assets/image/calRight.png';
import { getViewValue } from './calender.utils';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

class CustomToolbar extends Toolbar {
  render() {
    const {
      calenderDefaults,
      checkDate,
      date,
      view,
      currentView,
      changeView
    } = this.props;
    if (checkCalDateRange(date, calenderDefaults)) {
      checkDate(date);
    }
    if (view !== currentView) {
      changeView(view);
    }
    const value = getViewValue(view);
    return (
      <div style={{ marginBottom: '1.2rem' }}>
        <AppBar
          position="static"
          style={{
            backgroundColor: 'transparent',
            boxShadow: 'none'
          }}
        >
          <Tabs
            value={value}
            indicatorColor="primary"
            textColor="primary"
            aria-label="simple tabs example"
          >
            <Tab
              label="Week"
              onClick={this.view.bind(null, 'week')}
              {...a11yProps(0)}
              style={{
                minWidth: '90px',
                fontWeight: 'bold'
              }}
            />
            <Tab
              label="Day"
              {...a11yProps(1)}
              onClick={this.view.bind(null, 'day')}
              style={{
                minWidth: '90px',
                fontWeight: 'bold'
              }}
            />
            <Tab
              label="Time"
              onClick={this.view.bind(null, 'agenda')}
              {...a11yProps(2)}
              style={{
                minWidth: '90px',
                fontWeight: 'bold'
              }}
            />
            <Grid
              xs={12}
              alignItems="center"
              container
              direction="row"
              justify="flex-end"
            >
              <Button onClick={() => this.navigate('PREV')}>
                <img alt="img" src={CalLeft} />
              </Button>
              <Typography
                variant="h6"
                gutterBottom
                style={{ color: '#000', margin: '0 0' }}
              >
                {this.props.label}
              </Typography>
              <Button onClick={() => this.navigate('NEXT')}>
                <img alt="img" src={CalRight} />
              </Button>
            </Grid>
          </Tabs>
        </AppBar>
      </div>
    );
  }
}
CustomToolbar.propTypes = {
  calenderDefaults: PropType.object.isRequired,
  checkDate: PropType.func.isRequired,
  currentView: PropType.string.isRequired,
  changeView: PropType.func.isRequired
};

const mapToState = (state) => ({
  calenderDefaults: state.calenderReducer.defaultDate,
  currentView: state.calenderReducer.currentView
});

const mapToDispatch = (dispatch) => ({
  checkDate: (date) => dispatch(changeDateCalender(date)),
  changeView: (view) => dispatch(ChangeViewCal(view))
});

export default connect(mapToState, mapToDispatch)(CustomToolbar);
