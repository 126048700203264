export const initialValue = {
  data: {
    id: 1,
    contract_name: '',
    payment_plan_meta: {
      name_of_membership: '',
      attedence_limit: '',
      expiration: '',
      programe: [],
      discount: null,
      auto_renew: 'Yes',
      initial_commitment: '',
      start_date: '',
      expiration_date: '',
      setup_cost: '',
      setup_tax: '',
      payment_option: '',
      commitment_cost: '',
      commitment_tax: '',
      commitment_total: '',
      billed_on: '',
      renewal_commitment: '',
      renewal_payment_option: '',
      renewal_cost: '',
      renewal_tax: '',
      renewal_total: 'A$',
      renewal_billed_on: '',
      intial_payment: null,
      sign_initials: null
    },
    membership_policy_meta: {
      policy_meta: {
        policy: '',
        initial: 0
      },
      sign_initials: null
    },
    sign_status: 0,
    date_signed: null,
    signature: null,
    status: 1,
    created_by: 5,
    updated_by: 5,
    user_details: {
      id: 5,
      first_name: '',
      last_name: '',
      email: '',
      gender: '',
      address: '',
      address2: null,
      city: '',
      province: '',
      postal_code: '',
      country: '',
      mobile_no: null,
      dob: '1994-10-12'
    }
  }
};

export const SignTypes = {
  getData: 'FETCHING_DATA',
  setFistName: 'SET_USER_DETAILS_FIRST_NAME',
  setlasttName: 'SET_USER_DETAILS_LAST_NAME',
  setemail: 'SET_USER_DETAILS_EMAIL',
  setGender: 'SET_USER_DETAILS_GENDER',
  setAddress: 'SET_USER_DETAILS_ADDRESS',
  setCity: 'SET_USER_DETAILS_CITY',
  setState: 'SET_USER_DETAILS_PROVINCE',
  setPostalCode: 'SET_USER_DETAILS_POSTALCODE',
  setCountry: 'SET_USER_DETAILS_COUNTRY',
  setPhone: 'SET_USER_DETAILS_PHONE_NUMBER',
  setDOB: 'SET_USER_DETAILS_DOB',
  setpaymentIntials: 'SET_USER_DETAILS_PAYMENT_INITIALS',
  setpolicyInitials: 'SET_USER_DETAILS_POLICY_INITIALS',
  setSignStatus: 'CHANGE_SIGN_STATUS'
};

export const SignContractReducer = (state = initialValue, action) => {
  const { type, payload } = action;
  switch (type) {
    case SignTypes.getData:
      return {
        ...state,
        data: payload
      };
    case SignTypes.setFistName:
      return {
        ...state,
        data: {
          ...state.data,
          user_details: {
            ...state.data.user_details,
            first_name: payload
          }
        }
      };
    case SignTypes.setlasttName:
      return {
        ...state,
        data: {
          ...state.data,
          user_details: {
            ...state.data.user_details,
            last_name: payload
          }
        }
      };
    case SignTypes.setemail:
      return {
        ...state,
        data: {
          ...state.data,
          user_details: {
            ...state.data.user_details,
            email: payload
          }
        }
      };
    case SignTypes.setGender:
      return {
        ...state,
        data: {
          ...state.data,
          user_details: {
            ...state.data.user_details,
            gender: payload
          }
        }
      };
    case SignTypes.setAddress:
      return {
        ...state,
        data: {
          ...state.data,
          user_details: {
            ...state.data.user_details,
            address: payload
          }
        }
      };
    case SignTypes.setCity:
      return {
        ...state,
        data: {
          ...state.data,
          user_details: {
            ...state.data.user_details,
            city: payload
          }
        }
      };
    case SignTypes.setState:
      return {
        ...state,
        data: {
          ...state.data,
          user_details: {
            ...state.data.user_details,
            province: payload
          }
        }
      };
    case SignTypes.setCountry:
      return {
        ...state,
        data: {
          ...state.data,
          user_details: {
            ...state.data.user_details,
            country: payload
          }
        }
      };
    case SignTypes.setPostalCode:
      return {
        ...state,
        data: {
          ...state.data,
          user_details: {
            ...state.data.user_details,
            postal_code: payload
          }
        }
      };
    case SignTypes.setPhone:
      return {
        ...state,
        data: {
          ...state.data,
          user_details: {
            ...state.data.user_details,
            mobile_no: payload
          }
        }
      };
    case SignTypes.setDOB:
      return {
        ...state,
        data: {
          ...state.data,
          user_details: {
            ...state.data.user_details,
            dob: payload
          }
        }
      };
    case SignTypes.setpaymentIntials:
      return {
        ...state,
        data: {
          ...state.data,
          payment_plan_meta: {
            ...state.data.payment_plan_meta,
            sign_initials: payload
          }
        }
      };
    case SignTypes.setpolicyInitials:
      return {
        ...state,
        data: {
          ...state.data,
          membership_policy_meta: {
            ...state.data.membership_policy_meta,
            sign_initials: state.data.membership_policy_meta.policy_meta.initial
              ? payload
              : null
          }
        }
      };
    case SignTypes.setSignStatus:
      return {
        ...state,
        data: {
          ...state.data,
          sign_status: state.data.sign_status === 1 ? 0 : 1
        }
      };
    default:
      return state;
  }
};
