/* eslint-disable operator-linebreak */
/* eslint-disable arrow-parens */
import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Button,
  InputBase,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Checkbox,
  Box,
  TableHead,
  TableSortLabel
} from '@material-ui/core';
import { connect } from 'react-redux';
import Pagination from '@material-ui/lab/Pagination';
import { Link, withRouter } from 'react-router-dom';
import StarIcon from '@material-ui/icons/Star';
import filterType from 'src/Redux/Reducer.constants';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import useChangeTitle from 'src/views/common/CustomHooks/useTitleChange';
import { getTags } from 'src/utils/someCommon';
import _ from 'lodash';
import {
  getWOD,
  tobeStarred,
  toUnStarred
} from '../../../Redux/WODReducers/wodReducer.actions';
import { openNoti } from '../../../Redux/global/global.actions';
import EnhancedTableToolbar from '../../common/JBTables/enhanceToolbar';
import ApplyFilter from './ListFilters/ApplyFilter';
import useStyles from './table.style';
import LoadingView from '../../common/loading/index';
import { ROUTES } from '../../../constant/Constants';

const WodListTable = (props) => {
  const {
    openNotiDisp,
    WODs: { isFetching, data, error },
    filtertags,
    WODFilter,
    wodSearch,
    wodSort,
    allSelected
  } = props;

  const classes = useStyles();
  const [selected, setSelected] = useState([]);
  const [search, setSearch] = useState(wodSearch);

  const handleFave = (id) => {
    openNotiDisp('success', 'info');
    tobeStarred(id);
  };

  const SearchQue = useCallback(
    _.debounce((q) => WODFilter(filterType.search, q), 1000),
    []
  );

  const removeFilters = (tags) => {
    WODFilter(filterType.removecustom, tags);
  };
  // eslint-disable-next-line no-shadow
  const handleClick = (_, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  useChangeTitle('This Week & Future | Jungle Alliance');
  useEffect(() => {
    if (allSelected) {
      setSelected(data.data.map((w) => w.id));
    }
  }, [data, allSelected]);
  const onPaginationChange = (event, val) => {
    if (data.current_page !== val) WODFilter(filterType.page, val);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;
  if (error) return <p>{error}</p>;
  const selectedTags = getTags(selected, data?.data);

  const setDirection = (name) => {
    if (wodSort.sort_name === name) {
      return wodSort.sort_by === 'asc' ? 'asc' : 'dsc';
    }
    return 'asc';
  };

  return (
    <div>
      <Grid
        container
        //  disableGutters
        style={{ marginBottom: '15px' }}
      >
        <Box mr="15px">
          <InputLabel
            htmlFor="age-required"
            className={classes.cutom_select_Label}
          >
            &nbsp;
          </InputLabel>
          <ApplyFilter
            getData={(val, val2) =>
              WODFilter(filterType.addcustom, [val, val2])
            }
          />
        </Box>
        <Grid item xs>
          <InputLabel
            htmlFor="age-required"
            className={classes.cutom_select_Label}
          >
            &nbsp;
          </InputLabel>
          <div className={classes.search}>
            <InputBase
              fullWidth
              placeholder="Search…"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
                SearchQue(e.target.value);
              }}
              classes={{
                root: classes.inputRoot,
                input: classes.searchInput
              }}
              inputProps={{
                'aria-label': 'search'
              }}
              // InputProps={{
              //   'aria-label': 'search'
              // }}
            />
          </div>
        </Grid>
      </Grid>
      <div className={classes.root}>
        {filtertags.map((tags) => (
          <div
            className={classes.DTags}
            key={tags.id}
            style={{ margin: '0 0 15px', display: 'inline-block' }}
          >
            <Box display="inline-block" mr={1}>
              <label>
                {tags.name}
                <span
                  className={classes.DTagsClose}
                  onClick={() => removeFilters(tags)}
                >
                  ×
                </span>
              </label>
            </Box>
          </div>
        ))}
        {isFetching ? (
          <LoadingView />
        ) : (
          <>
            <Paper className={classes.paper} elevation={0}>
              <TableContainer
                style={{
                  overflowY: 'auto'
                  // minHeight: '200px'
                }}
              >
                {selected.length > 0 ? (
                  <EnhancedTableToolbar
                    ClearChecks={() => setSelected([])}
                    numSelected={selected}
                    selectedTags={selectedTags}
                    total={data.total}
                    setSelected={setSelected}
                    allSelected={allSelected}
                    values={data.data}
                  />
                ) : null}
                <Table
                  className={classes.table}
                  aria-labelledby="tableTitle"
                  size="medium"
                  aria-label="enhanced table"
                  style={{
                    backgroundColor: '#F9F9FA'
                  }}
                >
                  {selected.length > 0 ? null : (
                    <TableHead>
                      <TableRow>
                        <TableCell
                          padding="checkbox"
                          style={{
                            borderBottom: '1px solid rgb(168, 168, 168)'
                          }}
                        >
                          <Checkbox
                            // checked={
                            //   data.data.length > 0 && selected === data.data.length
                            // }
                            onChange={() => {
                              const val = data.data.map((n) => n.id);
                              setSelected(val);
                            }}
                            inputProps={{ 'aria-label': 'select all desserts' }}
                            color="primary"
                          />
                        </TableCell>
                        <TableCell className={classes.HeadCell} />
                        <TableCell
                          className={classes.HeadCell}
                          style={{ padding: '16px 0' }}
                        >
                          <TableSortLabel
                            active={wodSort.sort_name === filterType.sortname}
                            onClick={() =>
                              WODFilter(filterType.sorting, filterType.sortname)
                            } // eslint-disable-line
                            direction={setDirection(filterType.sortname)}
                          >
                            Name
                          </TableSortLabel>
                        </TableCell>
                        <TableCell className={classes.HeadCell}>
                          Program
                        </TableCell>
                        <TableCell
                          className={classes.HeadCell}
                          style={{ width: '20%' }}
                        >
                          Primary Tags
                        </TableCell>
                        <TableCell
                          className={classes.HeadCell}
                          align="right"
                          style={{
                            width: '140px'
                          }}
                        >
                          <TableSortLabel
                            active={
                              wodSort.sort_name === filterType.sort_wod_day
                            }
                            onClick={() =>
                              WODFilter(
                                filterType.sorting,
                                filterType.sort_wod_day
                              )
                            } // eslint-disable-line
                            direction={setDirection(filterType.sort_wod_day)}
                          >
                            Date
                          </TableSortLabel>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                  )}
                  <TableBody>
                    {data?.data?.map((row, index) => {
                      const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow
                          key={row.id}
                          hover
                          // role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          selected={isItemSelected}
                          classes={{ selected: classes.selected }}
                          style={{
                            backgroundColor: '#FFF'
                          }}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{ 'aria-labelledby': labelId }}
                              // shift from table row
                              key={row.id}
                              selected={isItemSelected}
                              onClick={(event) => handleClick(event, row.id)}
                            />
                          </TableCell>
                          <TableCell style={{ width: '48px' }}>
                            {row.starred ? (
                              <Button>
                                <StarIcon
                                  htmlColor="#FFC700"
                                  onClick={() => toUnStarred(row.id)}
                                />
                              </Button>
                            ) : (
                              <Button onClick={() => handleFave(row.id)}>
                                <StarBorderIcon htmlColor="#949494" />
                              </Button>
                            )}
                          </TableCell>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                            className={classes.Table_td_link}
                          >
                            <Link
                              to={{
                                pathname: ROUTES.WORKOUT_DETAIL_VIEW,
                                search: `workout=${row.id}`,
                                state: row.id
                              }}
                              className={classes.Table_td_link}
                            >
                              {row.name}
                            </Link>
                          </TableCell>
                          <TableCell align="left">{row.program_name}</TableCell>
                          <TableCell
                            align="left"
                            style={{ width: '15%', wordBreak: 'break-word' }}
                          >
                            {row.tags &&
                              row.tags.split(',').map((tag, i) => (
                                <span style={{ margin: '0 2px' }}>
                                  {tag}
                                  {i < row.tags.split(',').length - 1 && ','}
                                </span>
                              ))}
                          </TableCell>
                          <TableCell align="right">{row.wod_date}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              {data.data.length === 0 && (
                <p
                  style={{
                    margin: '10px 15px'
                  }}
                >
                  No Workouts to show...
                </p>
              )}
            </Paper>
            <Grid
              item
              xs={12}
              container
              direction="row"
              alignItems="center"
              style={{ marginTop: 24 }}
            >
              <Grid
                item
                xs={6}
                // justify="flex-start"
              >
                {/* <Paper elevation={0} style={{ color: '#A9A9A9' }}>
               {data.to > 1 && `${data.from} - ${data.to}`}
             </Paper> */}
                {data.from && data.to > 1 ? (
                  <Paper elevation={0} style={{ color: '#A9A9A9' }}>
                    {`${data.from} - ${data.to} of ${data.total}`}
                  </Paper>
                ) : null}
              </Grid>
              <Grid
                item
                xs={6}
                // justify="flex-end"
                style={{ display: 'flex', justifyContent: 'flex-end' }}
              >
                <Pagination
                  count={data.last_page}
                  page={data.current_page}
                  onChange={onPaginationChange}
                  color="primary"
                  variant="outlined"
                  shape="rounded"
                />
              </Grid>
            </Grid>
          </>
        )}
      </div>
    </div>
  );
};
WodListTable.propTypes = {
  // classes: PropTypes.object.isRequired,
  openNotiDisp: PropTypes.func.isRequired,
  WODs: PropTypes.object.isRequired,
  WODFilter: PropTypes.func.isRequired,
  wodSearch: PropTypes.string.isRequired,
  wodSort: PropTypes.object.isRequired,
  filtertags: PropTypes.array.isRequired,
  allSelected: PropTypes.bool.isRequired
  // location: PropTypes.any.isRequired
};

const mapToState = (state) => ({
  WODs: state.wodList.workout,
  filtertags: state.wodList.workoutsFilter.tags,
  wodSearch: state.wodList.workoutsFilter.search,
  wodSort: state.wodList.workoutsFilter.sorting,
  allSelected: state.wodList.areAllSelected
});

const mapToDispatch = (dispatch) => ({
  WODFilter: (filter, type) => dispatch(getWOD(filter, type)),
  openNotiDisp: (msg, type) => dispatch(openNoti(msg, type))
});

export default withRouter(connect(mapToState, mapToDispatch)(WodListTable));
