const styles = () => ({
  root: {
    marginTop: 0,
    margin: '0px 20px 20px',
    padding: '0px 20px 20px'
  },
  mainCard: {
    boxShadow: '4px 0px 16px rgba(37, 40, 43, 0.08)',
    borderRadius: '5px',
    background: '#FFFF',
    padding: 30,
    overflow: 'visible',
    '& h3': {
      color: '#292929'
    }
  },
  button: {
    background: '#01847A',
    color: '#FFF',
    padding: '15px 24px',
    boxShadow: '0px 8px 16px rgba(0, 126, 255, 0.16);',
    float: 'right;',
    '&:hover': {
      backgroundColor: '#01847A'
    }
  },
  btn_previou: {
    color: '#01847a',
    border: '1px solid #01847a',
    borderRadius: '0',
    minHeight: '48px',
    padding: '15px 16px'
  },
  cutom_select_border: {
    border: '1px solid #a9a9a9'
  },
  cutom_select_Label: {
    fontSize: '12px',
    padding: '7px 0'
  },
  ptext: {
    fontSize: '16px',
    lineHeight: '24px',
    color: '#7F7F7F',
    fontWeight: 'normal'
  },
  btnBlock: {
    justifyContent: 'flex-end',
    display: 'flex'
  },
  checkBoxTable: {
    '& thead th': {
      padding: '7px 5px',
      backgroundColor: '#f9f9fa'
    },
    '& thead th:nth-child(2)': {
      paddingLeft: '15px'
    }
  },
  TextareaControlBox: {
    '& textarea': {
      color: '#292929'
    },
    '& textarea::placeholder': {
      color: '#7F7F7F !important',
      fontWeight: '500',
      fontSize: '16px',
      opacity: '0.5'
    }
  }
});

export default styles;
