import moment from 'moment';
import Timezone from 'src/constant/timeformats';
import { GymdateFormat, GymTimeFormat } from 'src/constant/GymDateTime';
import { initialState, GymReducerType } from './GymReducer';
import { GymweekDay, Gymagelimit } from './GymSetting.data';

export function GetGymData(gymData) {
  const { data, error } = gymData;
  if (!data || error) {
    return initialState.data;
  }

  const DateFormat = GymdateFormat.filter((l) => l.format === data?.date_format)[0] || null;
  return {
    date_format: DateFormat,
    hours_format: data?.time_format,
    time_zone: Timezone.filter((l) => l.id === data?.time_zone)[0] || null,
    hours_value:
      data?.time_format === GymTimeFormat.twelve
        ? moment().format(`${DateFormat?.value} h:mm:ss A`)
        : moment().format(`${DateFormat?.value} HH:mm:ss`),
    week_day: GymweekDay.filter((l) => l.id === data?.start_week_on)[0] || null,
    age_limit:
      Gymagelimit.filter((l) => l.id === data?.age_of_maturity)[0] || null
  };
}

function isGymDatavalid(state, type) {
  if (!state.data?.date_format && type !== GymReducerType.updateDateFmt) {
    return { isError: 'Gym Date format is required' };
  }
  if (!state.data?.hours_format && type !== GymReducerType.updateTimeFmt) {
    return { isError: 'Gym Time format is required' };
  }
  if (!state.data?.time_zone && type !== GymReducerType.updateTimeZone) {
    return { isError: 'Gym Time Zone is required' };
  }
  if (!state.data?.week_day && type !== GymReducerType.updateweekday) {
    return { isError: 'Gym Week of day is required' };
  }
  if (!state.data?.age_limit && type !== GymReducerType.updateageMature) {
    return { isError: 'Gym Minimum Age Limit is required' };
  }
  return { data: true };
}

export function validateGymData(payload, type, state) {
  switch (type) {
    case GymReducerType.updateDateFmt: {
      if (!payload) {
        return { error: 'Gym Date format is required' };
      }
      const { isError } = isGymDatavalid(state, type);
      if (isError) {
        return { error: isError };
      }
      return {
        data: {
          date_format: payload?.format,
          time_format: state.data?.hours_format,
          time_zone: state.data?.time_zone?.id,
          start_week_on: state.data?.week_day?.id,
          age_of_maturity: state.data?.age_limit?.id
        }
      };
    }

    case GymReducerType.updateTimeFmt: {
      if (!payload) {
        return { error: 'Gym Time format is required' };
      }
      const { isError } = isGymDatavalid(state, type);
      if (isError) {
        return { error: isError };
      }
      return {
        data: {
          date_format: state.data?.date_format?.format,
          time_format:
            payload === GymTimeFormat.twelve
              ? GymTimeFormat.twentyfour
              : GymTimeFormat.twelve,
          time_zone: state.data?.time_zone?.id,
          start_week_on: state.data?.week_day?.id,
          age_of_maturity: state.data?.age_limit?.id
        }
      };
    }

    case GymReducerType.updateTimeZone: {
      if (!payload) {
        return { error: 'Gym Timezone is required' };
      }
      const { isError } = isGymDatavalid(state, type);
      if (isError) {
        return { error: isError };
      }
      return {
        data: {
          date_format: state.data?.date_format?.format,
          time_format: state.data?.hours_format,
          time_zone: payload?.id,
          start_week_on: state.data?.week_day?.id,
          age_of_maturity: state.data?.age_limit?.id
        }
      };
    }
    case GymReducerType.updateweekday: {
      if (!payload) {
        return { error: 'Gym Start week is required' };
      }
      const { isError } = isGymDatavalid(state, type);
      if (isError) {
        return { error: isError };
      }
      return {
        data: {
          date_format: state.data?.date_format?.format,
          time_format: state.data?.hours_format,
          time_zone: state.data?.time_zone?.id,
          start_week_on: payload?.id,
          age_of_maturity: state.data?.age_limit?.id
        }
      };
    }
    case GymReducerType.updateageMature: {
      if (!payload) {
        return { error: 'Gym Age Maturity is required' };
      }
      const { isError } = isGymDatavalid(state, type);
      if (isError) {
        return { error: isError };
      }
      return {
        data: {
          date_format: state.data?.date_format?.format,
          time_format: state.data?.hours_format,
          time_zone: state.data?.time_zone?.id,
          start_week_on: state.data?.week_day?.id,
          age_of_maturity: payload?.id
        }
      };
    }
    default:
      return { data: state.data };
  }
}
