import { ROUTES } from 'src/constant/Constants';
import {
  getinvoicereports,
  // getrevenuememreports,
  getoveraccountreports,
  getCreditCardExpReports,
  getrevenuePerMonth,
  getReportsListData
  // getMembersValue
} from 'src/Redux/MasterHQ/ReportsReducer/reports.reducer.actions';

export default [
  {
    url: [
      ROUTES.MASTER_HQ_REPORTS,
      ROUTES.MASTER_HQ_REPORTS_FINANCIAL,
      ROUTES.MASTER_HQ_REPORTS_FINANCIAL_ALLINVOICES,
      ROUTES.MASTER_HQ_REPORTS_FINANCIAL_EXPIRED_CARDS,
      ROUTES.MASTER_HQ_REPORTS_FINANCIAL_REVENUE_PER_MONTH,
      ROUTES.MASTER_HQ_REPORTS_FINANCIAL_OVERDUE_ACCOUNT_RECEIVABLE
    ],
    tab: 'financial',
    functions: getReportsListData,
    restfunc: [
      getinvoicereports,
      getCreditCardExpReports,
      getrevenuePerMonth,
      // getMembersValue,
      getoveraccountreports
      // getrevenuememreports
    ]
  }
];
