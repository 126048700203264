export const HeadCoachData = [
  { id: 1, name: 'Shubham' },
  { id: 2, name: 'Jignesh' },
  { id: 3, name: 'Mann' },
  { id: 4, name: 'Kishan' },
  { id: 5, name: 'Keval' }
];

export const CoCoachData = [
  { id: 11, name: 'Meet' },
  { id: 12, name: 'Deval' },
  { id: 13, name: 'Patil' },
  { id: 14, name: 'Kishan' },
  { id: 15, name: 'Suresh' }
];

export const WeekData = [
  { id: 'mo', label: 'Monday' },
  { id: 'tu', label: 'Tuesday' },
  { id: 'we', label: 'Wednesday' },
  { id: 'th', label: 'Thursday' },
  { id: 'fr', label: 'Friday' },
  { id: 'sa', label: 'Saturday' },
  { id: 'su', label: 'Sunday' }
];

export const ProgramData = [
  { id: 1, name: 'BodyWeight A' },
  { id: 2, name: 'BodyWeight B' },
  { id: 3, name: 'BodyWeight C' },
  { id: 4, name: 'BodyWeight D' }
];

export const Repeatdata = [
  { id: 'custom', name: 'Custom' },
  { id: 'weekly', name: 'Weekly' }
];

export const getRepeatWeek = () => {
  const arr = [];
  for (let i = 1; i <= 52; i++) {
    const b = {
      id: i,
      name: String(i)
    };
    arr.push(b);
  }
  return arr;
};

export const EndTypeData = [
  { id: 'forever', name: 'Forever' },
  { id: 'ondate', name: 'On Date' }
];
