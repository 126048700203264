/* eslint-disable operator-linebreak */
import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import PropType from 'prop-types';
import {
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  makeStyles,
  TableSortLabel
} from '@material-ui/core';
import { ROUTES } from 'src/constant/Constants';
import filterType from 'src/Redux/Reducer.constants';
import CheckIcon from '../../../assets/image/check.png';

const useStyles = makeStyles({
  table: {
    minWidth: '100%',
    wordBreak: 'break-word',
    '@media (max-width: 1440px)': {
      '& td': {
        padding: '10px 6px 10px 6px'
      }
    },
    '& .MuiTableCell-head': {
      color: '#FFF'
    },
    '& .MuiTableSortLabel-root:hover': {
      color: '#01847A'
    },
    '& .MuiTableSortLabel-root.MuiTableSortLabel-active': {
      color: '#ffffff'
    },
    '& .MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon': {
      color: '#ffffff'
    },
    '& .MuiTableCell-body': {
      color: '#FFF'
    },
    '& .MuiTableBody-root .MuiTableCell-root': {
      borderBottom: '1px solid rgba(234, 234, 234, 0.2)'
    }
  },
  tableLink: {
    color: '#01847A',
    fontWeight: '700'
  },
  dayofweek: {
    background: '#A9BFEF',
    borderRadius: '50px',
    padding: '8px',
    color: '#FFF'
  },
  TooltipLink: {
    '&:hover': {
      '& svg path': {
        stroke: '#01847A'
      }
    }
  },
  TableCell: {
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px',
    borderBottom: '1px solid #A8A8A8',
    height: '56px',
    minHeight: '56px',
    '@media (max-width: 1440px)': {
      padding: '10px 6px 10px 6px'
    }
  }
});

const AffiliateTable = (props) => {
  const classes = useStyles();
  const { values, active, direction, setSort } = props;
  const {
    sort_member,
    sort_affiliate_gym_name,
    sort_affiliate_since,
    sort_realtime_max_active_users,
    sort_verall_max_active_users
  } = filterType;

  const isActive = {
    [sort_member]: false,
    [sort_affiliate_gym_name]: false,
    [sort_affiliate_since]: false,
    [sort_realtime_max_active_users]: false,
    [sort_verall_max_active_users]: false
  };
  const directions = {
    [sort_member]: 'asc',
    [sort_affiliate_gym_name]: 'asc',
    [sort_affiliate_since]: 'asc',
    [sort_realtime_max_active_users]: 'asc',
    [sort_verall_max_active_users]: 'asc'
  };
  if (active) {
    isActive[active] = true;
    directions[active] = direction;
  }

  const handleSort = useCallback(
    (label) => {
      setTimeout(() => {
        setSort(label);
      }, 200);
    },
    [setSort]
  );

  return (
    <TableContainer
      component={Paper}
      elevation={0}
      style={{ borderRadius: '0', backgroundColor: 'transparent' }}
    >
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow style={{ background: '#3B3C4E' }}>
            <TableCell style={{ width: '15%' }} className={classes.TableCell}>
              <TableSortLabel
                active={isActive[sort_affiliate_gym_name]}
                direction={directions[sort_affiliate_gym_name]}
                onClick={() => handleSort(sort_affiliate_gym_name)}
              >
                Gym Name
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell} style={{ width: '15%' }}>
              <TableSortLabel
                active={isActive[sort_member]}
                direction={directions[[sort_member]]}
                onClick={() => handleSort(sort_member)}
              >
                Contact person
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell} style={{ width: '13%' }}>
              Country
            </TableCell>
            <TableCell className={classes.TableCell} style={{ width: '10%' }}>
              Status
            </TableCell>
            <TableCell className={classes.TableCell} style={{ width: '11%' }}>
              <TableSortLabel
                active={isActive[sort_realtime_max_active_users]}
                direction={directions[[sort_realtime_max_active_users]]}
                onClick={() => handleSort(sort_realtime_max_active_users)}
              >
                Real Time Active Users
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell} style={{ width: '11%' }}>
              <TableSortLabel
                active={isActive[sort_verall_max_active_users]}
                direction={directions[[sort_verall_max_active_users]]}
                onClick={() => handleSort(sort_verall_max_active_users)}
              >
                Overall Max Active Users
              </TableSortLabel>
            </TableCell>
            <TableCell
              className={classes.TableCell}
              style={{ width: '11%' }}
              align="center"
            >
              Stripe Account Status
            </TableCell>
            <TableCell
              className={classes.TableCell}
              style={{ width: '11%' }}
              align="right"
            >
              <TableSortLabel
                active={isActive[sort_affiliate_since]}
                direction={directions[[sort_affiliate_since]]}
                onClick={() => handleSort(sort_affiliate_since)}
              >
                Allies Since
              </TableSortLabel>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {values?.length > 0 &&
            values.map((row) => (
              <TableRow
                hover
                // role="checkbox"
                // aria-checked={isSelected(row.id)}
                tabIndex={-1}
                key={row.affiliate_id}
                // selected={isSelected(row.id)}
                style={{
                  backgroundColor: '#26273B'
                }}
              >
                <TableCell
                  style={{
                    position: 'relative',
                    color: '#01847A',
                    fontWeight: '700',
                    width: '15%'
                  }}
                >
                  <Link
                    className={classes.tableLink}
                    to={{
                      pathname: ROUTES.MASTER_HQ_AFFILIATES_PROFILE,
                      search: `affiliateId=${row.affiliate_id}`
                    }}
                  >
                    {row.gym_name}
                  </Link>
                </TableCell>
                <TableCell
                  style={{
                    width: '15%'
                  }}
                >
                  {row.contact_person}
                </TableCell>
                <TableCell
                  style={{
                    width: '13%'
                  }}
                >
                  {row.country}
                </TableCell>
                <TableCell
                  style={{
                    width: '10%'
                  }}
                >
                  {row.user_status}
                </TableCell>
                <TableCell
                  style={{
                    width: '11%'
                  }}
                >
                  {row.real_time_active_user + ' users'}
                </TableCell>
                <TableCell
                  style={{
                    width: '11%'
                  }}
                >
                  {row.overall_max_active_user + ' users'}
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    width: '11%'
                  }}
                >
                  {row.stripe_account_status === 1 ? (
                    <img
                      src={CheckIcon}
                      alt="img"
                      style={{
                        marginRight: '20px'
                      }}
                    />
                  ) : null}
                </TableCell>
                <TableCell
                  align="right"
                  style={{
                    width: '11%'
                  }}
                >
                  {row.affiliated_since}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      {values.length === 0 && (
        <p
          style={{
            margin: '10px 15px',
            color: '#fff'
          }}
        >
          No Allies to show...
        </p>
      )}
    </TableContainer>
  );
};
AffiliateTable.propTypes = {
  values: PropType.array.isRequired,
  active: PropType.string.isRequired,
  direction: PropType.string.isRequired,
  setSort: PropType.func.isRequired
};

export default AffiliateTable;
