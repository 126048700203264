import {
  getAllLeads,
  getLeadSrc,
  gettags,
  getleadStatus,
  LeadDefaultMemberStatus
} from 'src/services/Leadmodule.services';
import { getErrorMsg } from 'src/utils/ErrorHandling';
import { store } from '../rootReducer';
import types from './lead.Types';

const fetchingLead = (type) => ({
  type,
  actionType: types.isFetching
});

const fetchComplete = (type, payload) => ({
  type,
  actionType: types.fetchSucess,
  payload
});

const ErrorFetching = (type, payload) => ({
  type,
  actionType: types.Errorfetch,
  payload
});

const DailyListLeadFilter = (filter, payload) => ({
  type: types.dailylistfilter,
  filter,
  payload
});

const newleadFilter = (filter, payload) => ({
  type: types.newleadsfilter,
  filter,
  payload
});

const followupfilter = (filter, payload) => ({
  type: types.followupfilter,
  filter,
  payload
});

const ConsultBookedfilter = (filter, payload) => ({
  type: types.ConsultBookedfilter,
  filter,
  payload
});

const Onboardbookedfilter = (filter, payload) => ({
  type: types.Onboardbookedfilter,
  filter,
  payload
});

const ConvertedLeadfilter = (filter, payload) => ({
  type: types.ConvertedLeadfilter,
  filter,
  payload
});

const AllLeadfilter = (filter, payload) => ({
  type: types.AllLeadfilter,
  filter,
  payload
});

const ArchievedLeadFilter = (filter, payload) => ({
  type: types.ArchievedLeadFilter,
  filter,
  payload
});

export const ProfileChange = (payload) => ({
  type: types.ProfileChange,
  payload
});

export const toggleSelectAll = (payload) => ({
  type: types.selectAll,
  payload
});

export const getAllLeadsMembers = (val) => {
  const type = types.getDailyListLead;
  return (dispatch) => {
    dispatch(fetchingLead(type));
    getAllLeads(val)
      .then((resp) => dispatch(fetchComplete(type, resp)))
      .catch((err) => dispatch(ErrorFetching(type, getErrorMsg(err))));
  };
};

export const getnewleads = (val) => {
  const type = types.getNewLead;
  return (dispatch) => {
    dispatch(fetchingLead(type));
    getAllLeads(val)
      .then((resp) => dispatch(fetchComplete(type, resp)))
      .catch((err) => dispatch(ErrorFetching(type, getErrorMsg(err))));
  };
};

export const getfollowups = (val) => {
  const type = types.getfollowuplist;
  return (dispatch) => {
    dispatch(fetchingLead(type));
    getAllLeads(val)
      .then((resp) => dispatch(fetchComplete(type, resp)))
      .catch((err) => dispatch(ErrorFetching(type, getErrorMsg(err))));
  };
};

export const getconsultbooked = (val) => {
  const type = types.getConsultBooked;
  return (dispatch) => {
    dispatch(fetchingLead(type));
    getAllLeads(val)
      .then((resp) => dispatch(fetchComplete(type, resp)))
      .catch((err) => dispatch(ErrorFetching(type, getErrorMsg(err))));
  };
};

export const getOnboardbooked = (val) => {
  const type = types.getOnboardbooked;
  return (dispatch) => {
    dispatch(fetchingLead(type));
    getAllLeads(val)
      .then((resp) => dispatch(fetchComplete(type, resp)))
      .catch((err) => dispatch(ErrorFetching(type, getErrorMsg(err))));
  };
};

export const getConvertedLead = (val) => {
  const type = types.getConvertedLead;
  return (dispatch) => {
    dispatch(fetchingLead(type));
    getAllLeads(val)
      .then((resp) => dispatch(fetchComplete(type, resp)))
      .catch((err) => dispatch(ErrorFetching(type, getErrorMsg(err))));
  };
};

export const getAllLeadlist = (val) => {
  const type = types.getAllLead;
  return (dispatch) => {
    dispatch(fetchingLead(type));
    getAllLeads(val)
      .then((resp) => dispatch(fetchComplete(type, resp)))
      .catch((err) => dispatch(ErrorFetching(type, getErrorMsg(err))));
  };
};

export const getArchievedLeadlist = (val) => {
  const type = types.getArchievedLead;
  return (dispatch) => {
    dispatch(fetchingLead(type));
    getAllLeads(val)
      .then((resp) => dispatch(fetchComplete(type, resp)))
      .catch((err) => dispatch(ErrorFetching(type, getErrorMsg(err))));
  };
};
export const getLeadSource = () => {
  const type = types.getLeadSource;
  return (dispatch) => {
    dispatch(fetchingLead(type));
    getLeadSrc()
      .then((resp) => dispatch(fetchComplete(type, resp)))
      .catch((err) => dispatch(ErrorFetching(type, getErrorMsg(err))));
  };
};
export const getLeadStatus = () => {
  const type = types.getLeadStatus;
  return (dispatch) => {
    dispatch(fetchingLead(type));
    getleadStatus()
      .then((resp) => dispatch(fetchComplete(type, resp)))
      .catch((err) => dispatch(ErrorFetching(type, getErrorMsg(err))));
  };
};
export const getTags = () => {
  const type = types.getTags;
  return (dispatch) => {
    dispatch(fetchingLead(type));
    gettags()
      .then((resp) => dispatch(fetchComplete(type, resp)))
      .catch((err) => dispatch(ErrorFetching(type, getErrorMsg(err))));
  };
};
export const getLeadDefaultStatus = (val) => {
  const type = types.getLeadDefaultMemberStatus;
  return (dispatch) => {
    dispatch(fetchingLead(type));
    LeadDefaultMemberStatus(val)
      .then((resp) => dispatch(fetchComplete(type, resp)))
      .catch((err) => dispatch(ErrorFetching(type, getErrorMsg(err))));
  };
};
// filter Async
export const getDailyListLead = (filter, data) => {
  return (dispatch) => {
    dispatch(DailyListLeadFilter(filter, data));
    const filData = store.getState().LeadReducer.DailylistLeadFilter;
    let updatedValue = {
      page: filData.page,
      ...filData.sorting,
      ...filData.default
    };
    if (filData.search.length > 0) {
      updatedValue = {
        ...updatedValue,
        search: filData.search
      };
    }
    if (Object.keys(filData.customFilter).length > 0) {
      updatedValue = {
        ...updatedValue,
        ...filData.customFilter
      };
    }
    dispatch(getAllLeadsMembers(updatedValue));
  };
};

export const newleads = (filter, data) => {
  return (dispatch) => {
    dispatch(newleadFilter(filter, data));
    const filData = store.getState().LeadReducer.NewLeadFilter;
    let updatedValue = {
      page: filData.page,
      ...filData.sorting,
      ...filData.default
    };
    if (filData.search.length > 0) {
      updatedValue = {
        ...updatedValue,
        search: filData.search
      };
    }
    if (Object.keys(filData.customFilter).length > 0) {
      updatedValue = {
        ...updatedValue,
        ...filData.customFilter
      };
    }
    dispatch(getnewleads(updatedValue));
  };
};

export const followuplead = (filter, data) => {
  return (dispatch) => {
    dispatch(followupfilter(filter, data));
    const filData = store.getState().LeadReducer.FollowupLeadFilter;
    let updatedValue = {
      page: filData.page,
      ...filData.sorting,
      ...filData.default
    };
    if (filData.search.length > 0) {
      updatedValue = {
        ...updatedValue,
        search: filData.search
      };
    }
    if (Object.keys(filData.customFilter).length > 0) {
      updatedValue = {
        ...updatedValue,
        ...filData.customFilter
      };
    }
    dispatch(getfollowups(updatedValue));
  };
};

export const consultbooked = (filter, data) => {
  return (dispatch) => {
    dispatch(ConsultBookedfilter(filter, data));
    const filData = store.getState().LeadReducer.ConsultbookedLeadFilter;
    let updatedValue = {
      page: filData.page,
      ...filData.sorting,
      ...filData.default
    };
    if (filData.search.length > 0) {
      updatedValue = {
        ...updatedValue,
        search: filData.search
      };
    }
    if (Object.keys(filData.customFilter).length > 0) {
      updatedValue = {
        ...updatedValue,
        ...filData.customFilter
      };
    }
    dispatch(getconsultbooked(updatedValue));
  };
};

export const Onboardbooked = (filter, data) => {
  return (dispatch) => {
    dispatch(Onboardbookedfilter(filter, data));
    const filData = store.getState().LeadReducer.OnboardbookedLeadFilter;
    let updatedValue = {
      page: filData.page,
      ...filData.sorting,
      ...filData.default
    };
    if (filData.search.length > 0) {
      updatedValue = {
        ...updatedValue,
        search: filData.search
      };
    }
    if (Object.keys(filData.customFilter).length > 0) {
      updatedValue = {
        ...updatedValue,
        ...filData.customFilter
      };
    }
    dispatch(getOnboardbooked(updatedValue));
  };
};

export const convertedLead = (filter, data) => {
  return (dispatch) => {
    dispatch(ConvertedLeadfilter(filter, data));
    const filData = store.getState().LeadReducer.ConvertedLeadFilter;
    let updatedValue = {
      page: filData.page,
      ...filData.sorting,
      ...filData.default
    };
    if (filData.search.length > 0) {
      updatedValue = {
        ...updatedValue,
        search: filData.search
      };
    }
    if (Object.keys(filData.customFilter).length > 0) {
      updatedValue = {
        ...updatedValue,
        ...filData.customFilter
      };
    }
    dispatch(getConvertedLead(updatedValue));
  };
};

export const AllLead = (filter, data) => {
  return (dispatch) => {
    dispatch(AllLeadfilter(filter, data));
    const filData = store.getState().LeadReducer.AllLeadFilter;
    let updatedValue = {
      page: filData.page,
      ...filData.sorting,
      ...filData.default
    };
    if (filData.search.length > 0) {
      updatedValue = {
        ...updatedValue,
        search: filData.search
      };
    }
    if (Object.keys(filData.customFilter).length > 0) {
      updatedValue = {
        ...updatedValue,
        ...filData.customFilter
      };
    }
    dispatch(getAllLeadlist(updatedValue));
  };
};

export const ArchievedLead = (filter, data) => {
  return (dispatch) => {
    dispatch(ArchievedLeadFilter(filter, data));
    const filData = store.getState().LeadReducer.ArchievedLeadFilter;
    let updatedValue = {
      page: filData.page,
      ...filData.sorting,
      ...filData.default
    };
    if (filData.search.length > 0) {
      updatedValue = {
        ...updatedValue,
        search: filData.search
      };
    }
    if (Object.keys(filData.customFilter).length > 0) {
      updatedValue = {
        ...updatedValue,
        ...filData.customFilter
      };
    }
    dispatch(getArchievedLeadlist(updatedValue));
  };
};
