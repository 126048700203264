const styles = () => ({
  buttonprimary: {
    background: '#2CE9DA',
    color: '#292929',
    padding: '12px 24px',
    borderRadius: '8px',
    minHeight: '48px',
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '22px',
    textTransform: 'inherit',
    boxShadow: '0px 8px 16px rgba(0, 126, 255, 0.16);',
    '&:hover': {
      backgroundColor: '#2CE9DA'
    },
    '&.Mui-disabled': {
      color: '#292929',
      backgroundColor: '#787878',
      display: 'block !important'
    },
    '&:hover.Mui-disabled': {
      backgroundColor: '#787878'
    }
  },
  deletebutton: {
    color: '#FB4E4E',
    border: '1px solid',
    borderRadius: '8px',
    minHeight: '48px',
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '22px',
    textTransform: 'inherit',
    '&:hover': {
      borderColor: '#FB4E4E'
    }
  },
  Outlinebutton: {
    color: '#2CE9DA',
    minHeight: '48px',
    borderRadius: '5px',
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '22px',
    border: '5px solid #2CE9DA',
    textTransform: 'inherit',
    '&:hover': {
      border: '5px solid #2CE9DA',
      backgroundColor: 'rgb(103 233 218 / 40%)'
    },
    '&.Mui-disabled': {
      color: '#787878',
      border: '5px solid #787878'
    }
  },
  imgMargin: {
    marginRight: 10
  }
});

export default styles;
