import React, { memo } from 'react';
import PropType from 'prop-types';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { get } from 'js-cookie';
import { STRIPE_TEST_KEY } from 'src/constant/Constants';

const StripeElement = ({ children, usingMaster }) => {
  let AccountId = get('account');
  if (AccountId) {
    AccountId = JSON.parse(AccountId || '{}')?.account_id;
  }
  const stripePromise = loadStripe(STRIPE_TEST_KEY, {
    stripeAccount: usingMaster
      ? process.env.REACT_APP_MASTER_ACCOUNT
      : AccountId || ''
  });
  return <Elements stripe={stripePromise}>{children}</Elements>;
};

StripeElement.defaultProps = {
  usingMaster: false
};

StripeElement.propTypes = {
  children: PropType.node.isRequired,
  usingMaster: PropType.bool
};

export default memo(StripeElement);
