import { DataObj, FilterObj } from 'src/Redux/reducer.default.data';

export default {
  activeAllies: DataObj,
  inActiveAllies: DataObj,
  allAllies: DataObj,
  activeAlliesFilters: FilterObj({ user_status: '1' }),
  inActiveAlliesFilters: FilterObj({ user_status: '0' }),
  allAlliesFilters: FilterObj({ user_status: 'all' })
};
