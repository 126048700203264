import {
  checkValidString,
  checkEmail,
  isPhoneNumberInvalid,
  isValidPostal
} from 'src/utils/validations';

export const validStep1 = (data) => {
  if (!data.first_name.trim().length) {
    return true;
  }
  if (!data.last_name.trim().length) {
    return true;
  }
  if (!data.email || checkEmail(data.email)) {
    return true;
  }
  if (!data.gender) return true;
  if (!data.phone_num || isPhoneNumberInvalid(String(data.phone_num))) {
    return true;
  }
  if (!data.date) return true;
  return false;
};
export const validStep2 = (data) => {
  if (!data.address || checkValidString(data.address)) return true;
  if (!data.city || checkValidString(data.city)) return true;
  if (!data.province || checkValidString(data.province)) return true;
  if (!data.postal || !isValidPostal(data.postal)) return true;
  if (!data.country) return true;
  if (!data.location) return true;
  return false;
};
