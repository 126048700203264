import React, { useState } from 'react';
import PropType from 'prop-types';
import {
  Grid,
  Card,
  Typography,
  Box,
  Button,
  Breadcrumbs,
  withStyles,
  AppBar,
  Tabs,
  Tab,
  ThemeProvider
} from '@material-ui/core';
import CancelDialogTwo from 'src/views/common/CommonDialogBox/CancelDialogTwo';
import ArrowLeft from '../../../../assets/image/arrow_left.png';
import ClassCanceledStaff from './ClassCancelled/ClassCanceledStaff';
import RecurringCanceledStaff from './ClassCancelled/RecurringCanceledStaff';
import styles from '../automatedemails.style';

const themeInstance = {
  overrides: {
    MuiBreadcrumbs: {
      root: {
        color: '#949494',
        textTransform: 'uppercase',
        fontSize: '20px',
        lineHeight: '24px',
        fontWeight: '700'
      },
      li: {
        '& a': {
          color: '#949494',
          textTransform: 'uppercase',
          fontSize: '20px',
          lineHeight: '24px'
        },
        '@media (max-width: 1440px)': {
          '& a': {
            fontSize: '18px'
          },
          '& h3': {
            fontSize: '18px'
          }
        },
        '& h3': {
          margin: '0 0'
        }
      },
      separator: {
        marginLeft: '12px',
        marginRight: '12px'
      }
    }
  }
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

const ClassesEditTabsTemplate = ({ classes, history }) => {
  const [tabValue, setTabValue] = useState(0);
  const [open, setOpen] = useState(false);
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  return (
    <div className={classes.root}>
      <div className="title-bk">
        <ThemeProvider theme={themeInstance}>
          <Breadcrumbs aria-label="breadcrumb">
            <div color="inherit">communication</div>
            <div
              color="inherit"
              onClick={() => setOpen(true)}
              style={{
                cursor: 'pointer'
              }}
            >
              Automated emails
            </div>
            <div
              color="inherit"
              onClick={() => setOpen(true)}
              style={{
                cursor: 'pointer'
              }}
            >
              Classes
            </div>
            <Typography variant="h3" color="Primary">
              {!tabValue ? (
                <span>Class is Manually-Canceled - Staff</span>
              ) : (
                <span>Recurring Class is Manually-Canceled - Staff</span>
              )}
            </Typography>
          </Breadcrumbs>
        </ThemeProvider>
      </div>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="flex-start"
        spacing={0}
        style={{ margin: '20px 0 20px' }}
      >
        <div>
          <Button
            className={classes.ArrowLeft}
            // onClick={() => history.goBack()}
            onClick={() => setOpen(true)}
          >
            <img alt="Plusicon" src={ArrowLeft} />
          </Button>
        </div>
        <Grid xs>
          <h1 style={{ margin: '0px 0 10px' }}>
            Edit “Class is Manually-Canceled - Staff” Template
          </h1>
          <p className={classes.ptext}>
            A class may be manually cancelled. This email alerts all Admins,
            Managers, and Coaches tied to the class that the class is cancelled.
          </p>
        </Grid>
      </Grid>
      <Card className={classes.mainCard}>
        <AppBar
          position="static"
          style={{
            backgroundColor: 'transparent',
            boxShadow: 'none'
          }}
        >
          <Tabs
            value={tabValue}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            fullWidth
          >
            <Tab
              label="Class is Manually-Canceled - Staff"
              className={classes.p_tabs}
              {...a11yProps(0)} // eslint-disable-line
            />
            <Tab
              label="Recurring Class is Manually-Canceled - Staff"
              className={classes.p_tabs}
              {...a11yProps(1)} // eslint-disable-line
            />
          </Tabs>
        </AppBar>
        <Box mt="35px">
          {!tabValue ? (
            <ClassCanceledStaff history={history} />
          ) : (
            <RecurringCanceledStaff history={history} />
          )}
        </Box>
      </Card>
      <CancelDialogTwo setOpen={open} handleClose={() => setOpen(false)} />
    </div>
  );
};

ClassesEditTabsTemplate.propTypes = {
  classes: PropType.object.isRequired,
  history: PropType.any.isRequired
};
export default withStyles(styles)(ClassesEditTabsTemplate);
