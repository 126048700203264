import {
  AllAttendanceLmt,
  AllDayType
} from '../MembershipTemplate/Membership.data';

const paymentMeta = (meta, Gymloc, allTaxes) => {
  const result = [];
  for (let i = 0; i < meta.length; i += 1) {
    let b = {
      id: meta[i].id || null,
      name: meta[i].name,
      location: null,
      tax: allTaxes.filter((res) => res.id === meta[i].tax_id)[0],
      initial_commitment: {
        ...meta[i].initial_commitment,
        billed_every: {
          id: meta[i].initial_commitment.billed_every,
          name: String(meta[i].initial_commitment.billed_every)
        }
      },
      auto_renew: meta[i].auto_renew === 1,
      auto_renew_meta: {
        ...meta[i].auto_renew_meta,
        billed_every: null
      },
      initial_commitment_pricing: {
        ...meta[i].initial_commitment_pricing
      },
      auto_renewal_pricing: {
        ...meta[i].auto_renewal_pricing
      }
    };
    if (meta[i].location_id) {
      b = {
        ...b,
        location: Gymloc.filter((res) => res.id === meta[i].location_id)[0]
      };
    }
    if (meta[i].auto_renew === 1) {
      b = {
        ...b,
        auto_renew_meta: {
          renewal: meta[i].auto_renew_meta.renewal,
          billed_every: {
            id: meta[i].auto_renew_meta.billed_every,
            name: String(meta[i].auto_renew_meta.billed_every)
          }
        }
      };
    }
    result.push(b);
  }
  return result;
};

// eslint-disable-next-line
export const getEditTemp = (data, Gymloc, RevenCat, taxRates) => {
  // eslint-disable-next-line
  let allTax = [],
    allGyms = [],
    allRevens = [];
  if (Gymloc) {
    allGyms = [...Gymloc];
  }
  if (RevenCat) {
    allRevens = [...RevenCat];
  }
  if (taxRates) {
    allTax = [...taxRates];
  }
  let result = {
    attendance_limit: AllAttendanceLmt.filter(
      (res) => res.id === data.attendance_limit
    )[0],
    revCat: allRevens.filter((res) => res.id === data.reve_cat_id)[0],
    name: data.name,
    desc: data.description,
    contract_id: String(data.contract_id),
    programs: data.program_ids.split(',').map((value) => parseInt(value, 10)),
    attendance_limited_meta: {
      uses: 0,
      type: null
    },
    payments: paymentMeta(data.membership_payment, allGyms, allTax)
  };
  if (data.attendance_limit === 1) {
    result = {
      ...result,
      attendance_limited_meta: {
        uses: data.attendance_limited_meta.uses,
        type: AllDayType.filter(
          (res) => res.id === data.attendance_limited_meta.type
        )[0]
      }
    };
  }
  return result;
};
