/* eslint-disable import/prefer-default-export */
export const getFullAddress = (data) => {
  let { country } = data;
  country = '';
  if (typeof data.country === 'object' && data.country) {
    country = data.country.name;
  }
  const updated = {
    address: data.address,
    address2: data.address2,
    city: data.city,
    state: data.state,
    zipcode: data.zipcode,
    country
  };
  const value = Object.values(updated)
    .filter((l) => l)
    .join(', ');
  return value;
};
