import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
// import Button from '@material-ui/core/Button';
// import Card from '@material-ui/core/Card';
import { Grid } from '@material-ui/core';
import '../JBStepper/stepper.css';
// import ColorlibStepIcon from './ColorlibStepIcon';

const styles = (theme) => ({
  root: {
    width: '90%'
  },
  stepperRoot: {
    padding: '0'
  },
  actionsContainer: {
    marginBottom: theme.spacing(1) * 2
  },
  resetContainer: {
    padding: theme.spacing(1) * 3
  },
  errorDiv: {
    color: theme.palette.error.main
  },
  noWrap: {
    whiteSpace: 'nowrap',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px'
  }
});

const JBStepper = (props) => {
  const { activeStep, classes, steps, IconComp } = props;

  // const backBtnDisabled = !!(activeStep === 0 || isLoading);
  // const nextBtnDisabled = !!(shouldDisableBtn || isLoading);
  // const lsErrorMessage = 'Sorry.We hit a snag.';

  return (
    <div>
      <Stepper
        classes={{
          root: classes.stepperRoot // className, e.g. `OverridesClasses-root-X`
        }}
        activeStep={activeStep}
        orientation="vertical"
      >
        {steps.map((step) => {
          // eslint-disable-line no-debugger
          return (
            <Step key={step}>
              <StepLabel StepIconComponent={IconComp} className="sp-stepLabel">
                <Grid item xs>
                  <div className={classes.noWrap}>{step}</div>
                </Grid>
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </div>
  );
};
JBStepper.defaultProps = {
  // handleReset: null
  // OnCloseRequest: null,
  // shouldDisableBtn: false,
  IconComp: () => {}
};

JBStepper.propTypes = {
  classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  steps: PropTypes.array.isRequired,
  // steps: PropTypes.arrayOf(PropTypes.object).isRequired,
  activeStep: PropTypes.number.isRequired,
  IconComp: PropTypes.func
  // IconComp: PropTypes.element
  // hasErrored: PropTypes.bool.isRequired,
  // isLoading: PropTypes.bool.isRequired,
  // OnCloseRequest: PropTypes.func,
  // shouldDisableBtn: PropTypes.bool
};

export default withStyles(styles)(JBStepper);
