const styles = (theme) => ({
  root: {
    // marginTop: 30,
    // margin: 20,
    // padding: 20,
    marginTop: 0,
    margin: '0px 20px 20px',
    padding: '0px 20px 20px',
    root: {
      padding: theme.spacing(4)
    }
  },
  mainCard: {
    boxShadow: '4px 0px 16px rgba(37, 40, 43, 0.08)',
    borderRadius: '5px',
    background: '#FFFF',
    padding: 30,
    overflow: 'visible'
  },
  button: {
    background: '#01847A',
    color: '#FFF',
    padding: '15px 24px',
    boxShadow: '0px 8px 16px rgba(0, 126, 255, 0.16);',
    float: 'right;',
    '&:hover': {
      backgroundColor: '#01847A'
    }
  },
  btn_previou: {
    color: '#01847a',
    border: '1px solid #01847a',
    borderRadius: '0',
    minHeight: '48px',
    padding: '15px 16px'
  },
  actionsContainer: {
    marginBottom: theme.spacing(2)
  },
  resetContainer: {
    padding: theme.spacing(3)
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '25ch'
  },
  time_box: {
    marginTop: '15px',
    marginRight: '0px'
  },
  cutom_select_border: {
    border: '1px solid #a9a9a9'
  },
  cutom_select_Label: {
    fontSize: '12px',
    padding: '7px 0'
  },
  ptext: {
    fontSize: '16px',
    lineHeight: '24px',
    color: '#7F7F7F',
    fontWeight: 'normal'
  },
  btn_days: {
    background: '#EAEAEA',
    border: '1px solid #949494',
    boxSizing: 'border-box',
    borderRadius: '4px',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px',
    color: '#292929',
    marginRight: '10px',
    textTransform: 'capitalize'
  },
  btn_days_selected: {
    background: '#01847A',
    border: '1px solid #01847A',
    boxSizing: 'border-box',
    borderRadius: '4px',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px',
    color: '#FFFFFF',
    marginRight: '10px',
    textTransform: 'capitalize'
  },
  day_br: {
    borderBottom: '1px solid #C4C4C4'
  },
  Deletebutton: {
    color: '#FB4E4E',
    border: '1px solid',
    height: '48px',
    padding: '15px',
    borderRadius: '2px',
    fontWeight: '700'
  },
  Deletebtn: {
    background: '#FB4E4E',
    boxShadow: '0px 8px 16px rgba(251, 78, 78, 0.16)',
    borderRadius: '2px',
    height: '48px',
    padding: '10px 15px',
    color: '#fff',
    fontWeight: 'bold',
    '&:hover': {
      background: '#FB4E4E'
    }
  }
});

export default styles;
