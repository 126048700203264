import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles, Grid, InputBase, Paper, Box } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import {
  getMasterInvoices,
  toggleAreAllSelected
} from 'src/Redux/MasterHQ/FinancialReducer/financial.reducer.actions';
import filterType from 'src/Redux/Reducer.constants';
import _ from 'lodash';
import ApplyFilter from 'src/views/common/ApplyFilter/applyFilter';
import LoadingView from 'src/views/common/loading';
import useChangeTitle from 'src/views/common/CustomHooks/useTitleChange';
import Invoicestable from './financialTable/Invoicestable';
import styles from './financial.style';
import InvoiceFilters from './Invoice/Filters/InvoiceFilters';

const InvoicesTab = (props) => {
  const {
    InvFilter,
    InvSort,
    getIvoices: { isFetching, data, error },
    InvSearch,
    classes,
    filteredTags
  } = props;
  const [search, setSearch] = useState(InvSearch);
  const SearchQuery = useCallback(
    _.debounce((q) => InvFilter(filterType.search, q), 1500),
    []
  );
  const setDirection = (name) => {
    if (InvSort.sort_name === name) {
      return InvSort.sort_by === 'asc' ? 'asc' : 'dsc';
    }
    return 'asc';
  };
  useChangeTitle('Invoices | Jungle Alliance');
  const isActive = (sortlabel) => {
    if (InvSort.sort_name === sortlabel) return true;
    return false;
  };

  const setSort = (sortlabel) => {
    InvFilter(filterType.sorting, sortlabel);
  };

  const Paginate = (val) => {
    if (val !== data.current_page) {
      return InvFilter(filterType.page, val);
    }
    return null;
  };

  const [selected, setSelected] = useState([]);

  useEffect(() => {
    toggleAreAllSelected(false);
    setSelected([]);
  }, []);

  if (!data) return <LoadingView />;

  return (
    <Grid container disableGutters>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
        style={{ marginTop: '15px' }}
      >
        <Grid container disableGutters style={{ marginBottom: '15px' }}>
          <Box mr="15px" className={classes.Filtermenu}>
            <ApplyFilter>
              <InvoiceFilters
                getFilData={(val, val2) =>
                  InvFilter(filterType.addcustom, [val, val2])
                }
              />
            </ApplyFilter>
          </Box>
          <Grid item xs>
            <div className={classes.search}>
              <InputBase
                fullWidth
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                  SearchQuery(e.target.value);
                }}
                placeholder="Search…"
                classes={{
                  root: classes.inputRoot,
                  input: classes.searchInput
                }}
                InputProps={{
                  'aria-label': 'search'
                }}
              />
            </div>
          </Grid>
        </Grid>
        <div>
          {filteredTags.map((tags) => (
            <div
              className={classes.DTags}
              key={tags.id}
              style={{ margin: '0 0 15px', display: 'inline-block' }}
            >
              <Box display="inline-block" mr={1}>
                <label>
                  {tags.name}
                  <span
                    className={classes.DTagsClose}
                    onClick={() => InvFilter(filterType.removecustom, tags)}
                  >
                    ×
                  </span>
                </label>
              </Box>
            </div>
          ))}
        </div>
      </Grid>
      {isFetching ? (
        <LoadingView />
      ) : error ? (
        <p>{error}</p>
      ) : (
        <Grid container disableGutters style={{ marginBottom: '20px' }}>
          <Invoicestable
            values={data ? data.data : []}
            setDirection={setDirection}
            isActive={isActive}
            setSort={setSort}
            total={data.total}
            selected={selected}
            setSelected={setSelected}
          />
        </Grid>
      )}
      <Grid
        item
        xs={12}
        container
        direction="row"
        alignItems="center"
        style={{ marginTop: 24 }}
      >
        <Grid item xs={6} className={classes.PaginateData} justify="flex-start">
          {data && data.from && data.to >= 1 ? (
            <Paper elevation={0} style={{ color: '#A9A9A9' }}>
              {`${data.from} - ${data.to} of ${data.total}`}
            </Paper>
          ) : null}
        </Grid>
        <Grid
          item
          xs={6}
          className={classes.Pagination}
          justify="flex-end"
          style={{ display: 'flex' }}
        >
          <Pagination
            count={data.last_page}
            color="primary"
            variant="outlined"
            page={data.current_page}
            onChange={(_, val) => Paginate(val)} // eslint-disable-line
            shape="rounded"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

InvoicesTab.propTypes = {
  InvFilter: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  getIvoices: PropTypes.object.isRequired,
  InvSort: PropTypes.object.isRequired,
  InvSearch: PropTypes.string.isRequired,
  filteredTags: PropTypes.array.isRequired
};

const mapToDispatch = (dispatch) => ({
  InvFilter: (filter, data) => dispatch(getMasterInvoices(filter, data))
  // toggleAreAllSelected: (value) => dispatch(toggleAreAllSelected(value))
});

const mapToState = (state) => ({
  getIvoices: state.masterHqReducer.financialReducer.masterInvoices,
  InvSearch: state.masterHqReducer.financialReducer.masterInvoiceFilter.search,
  InvSort: state.masterHqReducer.financialReducer.masterInvoiceFilter.sorting,
  filteredTags: state.masterHqReducer.financialReducer.masterInvoiceFilter.tags
});

export default connect(
  mapToState,
  mapToDispatch
)(withStyles(styles)(InvoicesTab));
