import React, { memo, useState } from 'react';
import PropType from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  DialogTitle,
  withStyles
} from '@material-ui/core';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { openNoti } from 'src/Redux/global/global.actions';
import { getErrorMsg } from 'src/utils/ErrorHandling';
import { deleteContract } from 'src/services/Contracts.services';
import { ROUTES } from 'src/constant/Constants';

import { getTempCont } from 'src/Redux/ContractReducer/ContractReducer.actions';
import { getContracts } from 'src/Redux/SettingReducer/SettingReducer.actions';
import JBButton from '../common/JBButton/JBButton';
import X from '../../assets/image/x.png';

const styles = () => ({
  Deletebtn: {
    background: '#FB4E4E',
    boxShadow: '0px 8px 16px rgba(251, 78, 78, 0.16)',
    borderRadius: '2px',
    height: '48px',
    padding: '10px 15px',
    color: '#fff',
    fontWeight: '700',
    '&:hover': {
      background: '#FB4E4E'
    }
  },
  Dialogtitle: {
    padding: '16px 24px 0'
  },
  DialogActionsBk: {
    padding: '5px 20px 20px'
  }
});

const DeleteDialog = memo((props) => {
  const [open, setOpen] = useState(false);
  const { classes, getTempContUpdate, getContractsUpdate } = props;
  const [name] = useState(() => props.name);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const history = useHistory();
  const handleDelete = () => {
    const { id, openNotify } = props;
    deleteContract(id)
      .then(() => history.push(ROUTES.DOCUMENTS_CONTRACTS_TEMPLATES))
      .then(() => getTempContUpdate())
      .then(() => getContractsUpdate())
      .then(() => {
        openNotify(`Contract template  "${name}" deleted successfully`);
      })
      .catch((err) => openNotify(getErrorMsg(err), 'error'));
    setOpen(false);
  };

  return (
    <div>
      <JBButton type="delete" onClick={handleClickOpen}>
        DELETE
      </JBButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div style={{ width: 394 }}>
          <DialogTitle id="alert-dialog-title" className={classes.Dialogtitle}>
            <img
              alt="Plusicon"
              src={X}
              style={{ display: 'inline-block', verticalAlign: 'sub' }}
            />
            <Typography
              color="#000"
              variant="h5"
              component="div"
              className={classes.title}
              style={{ display: 'inline-block', marginLeft: '10px' }}
            >
              Delete contract
            </Typography>
          </DialogTitle>
          <DialogContent>
            <p
              style={{
                fontWeight: 'normal',
                fontSize: '14px',
                lineHeight: '18px',
                letterSpacing: '0.002em',
                color: '#7F7F7F',
                padding: '10px 0'
              }}
            >
              Are you sure you want to permanently delete this contract?
            </p>
          </DialogContent>
          <DialogActions className={classes.DialogActionsBk}>
            <Button
              variant="outlined"
              onClick={handleClose}
              style={{
                height: '48px',
                color: '#7F7F7F',
                fontWeight: 'bold'
              }}
            >
              Cancel
            </Button>
            <Button className={classes.Deletebtn} onClick={handleDelete}>
              delete
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
});

DeleteDialog.propTypes = {
  classes: PropType.object.isRequired,
  openNotify: PropType.func.isRequired,
  id: PropType.number.isRequired,
  name: PropType.string.isRequired,

  getContractsUpdate: PropType.func.isRequired,
  getTempContUpdate: PropType.func.isRequired
};
const mapToDispatch = (dispatch) => ({
  openNotify: (msg, type) => dispatch(openNoti(msg, type)),
  getContractsUpdate: () => dispatch(getContracts()),
  getTempContUpdate: () => dispatch(getTempCont())
});

export default connect(null, mapToDispatch)(withStyles(styles)(DeleteDialog));
