/* eslint-disable react/prop-types */
/* eslint-disable indent */
import React, { useState, useCallback, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Grid,
  withStyles,
  Card,
  Breadcrumbs,
  Typography,
  Box,
  InputBase,
  FormControlLabel,
  Paper,
  ThemeProvider
} from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
import { debounce } from 'lodash';
import Pagination from '@material-ui/lab/Pagination';
import ApplyFilter from 'src/views/common/ApplyFilter/applyFilter';
import LoadingView from 'src/views/common/loading';
import IOSSwitch from 'src/views/common/FormControl/IOSSwitch';
import filterType from 'src/Redux/Reducer.constants';
import { useSelector, connect } from 'react-redux';
import { getattendanceTrends } from 'src/Redux/ReportsReducer/reports.reducer.actions';
import { export_attendanceTrend } from 'src/services/reports.services';
import CancelDialogTwo from 'src/views/common/CommonDialogBox/CancelDialogTwo';
import useChangeTitle from 'src/views/common/CustomHooks/useTitleChange';
import JBButton from '../../common/JBButton/JBButton';
import ImportIcon from '../../../assets/image/upload.png';
import AttendanceTrendTable from './AllTables/AttendanceTrendTable/AttendanceTrendTable';
import AttendanceTrendFilters from './Filters/AttendanceTrendFilters/Filters';
import PieChart from '../chart/PieChart';
import StackedBarChart from '../chart/StackedBarChart';
// eslint-disable-next-line no-unused-vars
import { data as barchart } from './chartData/AttendanceTrendData';
import styles from './AttendanceReports.style';
import { getAttendancePieData, getAttendanceTrendBar } from '../Reports.utils';

const themeInstance = {
  overrides: {
    MuiBreadcrumbs: {
      root: {
        color: '#949494',
        textTransform: 'uppercase',
        fontSize: '20px',
        lineHeight: '24px',
        fontWeight: '700'
      },
      li: {
        '& a': {
          color: '#949494',
          textTransform: 'uppercase',
          fontSize: '20px',
          lineHeight: '24px'
        },
        '@media (max-width: 1440px)': {
          '& a': {
            fontSize: '18px'
          },
          '& h3': {
            fontSize: '18px'
          }
        },
        '& h3': {
          margin: '0 0'
        }
      },
      separator: {
        marginLeft: '12px',
        marginRight: '12px'
      }
    }
  }
};

const AttendanceTrend = (props) => {
  const {
    AttendanceSearch,
    AttendanceFilter,
    AttendanceSort,
    filteredTags,
    classes,
    // eslint-disable-next-line react/prop-types
    customFilter
  } = props;
  const [open, setOpen] = useState(false);
  const { isFetching, error, data } = useSelector(
    (state) => state.reportsReducer?.attendanceReports.attendanceTrends
  );
  const [search, setSearch] = useState(AttendanceSearch);
  const [activeuser, setactiveuser] = useState(
    () => !filteredTags.filter((l) => l.filter === 'user_status')[0]
  );
  const { data: data_reports } = useSelector(
    (state) => state.reportsReducer.reportList
  );
  const isStarred = data_reports?.attendance_reports.filter(
    (report) => report.id === 6
  )[0].is_starred;
  useChangeTitle('Member Attendance trend | Jungle Alliance');
  const SearchQuery = useCallback(
    debounce((q) => AttendanceFilter(filterType.search, q), 1500),
    []
  );
  const userSelect = useCallback(
    debounce(
      // eslint-disable-next-line no-confusing-arrow
      (q) =>
        q
          ? AttendanceFilter(filterType.addcustom, [
              { user_status: 1 },
              {
                id: 1000000000001,
                name: 'Members is active',
                filter: 'user_status'
              }
            ])
          : AttendanceFilter(filterType.addcustom, [
              { user_status: 0 },
              {
                id: 1000000000001,
                name: 'Members is inactive',
                filter: 'user_status'
              }
            ]),
      1000
    ),
    []
  );

  const setDirection = (name) => {
    if (AttendanceSort.sort_name === name) {
      return AttendanceSort.sort_by === 'asc' ? 'asc' : 'dsc';
    }
    return 'asc';
  };

  const isActive = (sortlabel) => {
    if (AttendanceSort.sort_name === sortlabel) return true;
    return false;
  };

  const setSort = (sortlabel) => {
    AttendanceFilter(filterType.sorting, sortlabel);
  };

  const Paginate = (val) => {
    if (val !== data.current_page) {
      return AttendanceFilter(filterType.page, val);
    }
    return null;
  };

  const handleReset = () => {
    AttendanceFilter(filterType.resetFilter);
    setactiveuser(
      () => !filteredTags.filter((l) => l.filter === 'user_status')[0] || true
    );
    setSearch('');
  };
  useEffect(() => {
    return () => {
      handleReset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (!data_reports || !data) return <LoadingView />;

  return (
    <div className={classes.root}>
      <div className="title-bk">
        <Box css={{ marginBottom: '20px' }}>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={0}
          >
            <Grid item xs={9}>
              <Box display="inline-flex">
                <ThemeProvider theme={themeInstance}>
                  <Breadcrumbs aria-label="breadcrumb">
                    <div color="inherit">Reports</div>
                    <div
                      color="inherit"
                      onClick={() => setOpen(true)}
                      style={{
                        cursor: 'pointer'
                      }}
                    >
                      attendance reports
                    </div>
                    <Typography variant="h3" color="Primary">
                      <span>Member Attendance trend</span>
                    </Typography>
                  </Breadcrumbs>
                </ThemeProvider>
              </Box>
              <Box
                display="inline-flex"
                ml="10px"
                css={{ verticalAlign: 'text-bottom' }}
              >
                {isStarred === 1 && (
                  <StarIcon
                    style={{
                      color: '#FFC700'
                    }}
                  />
                )}
              </Box>
            </Grid>
            <Grid
              item
              xs={3}
              style={{
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              <JBButton
                type="outlined"
                isImg={ImportIcon}
                component={Link}
                disabled={data.data.data.length === 0}
                onClick={(e) => {
                  e.preventDefault();
                  export_attendanceTrend({
                    search: AttendanceSearch,
                    sort_by: AttendanceSort.sort_by,
                    sort_name: AttendanceSort.sort_name,
                    trend: customFilter.trend,
                    // eslint-disable-next-line react/prop-types
                    weekly_attendance: customFilter.weekly_attendance,
                    user_status: activeuser
                  });
                }}
                style={{ marginLeft: '10px' }}
              >
                Export
              </JBButton>
            </Grid>
          </Grid>
        </Box>
      </div>

      <Card className={classes.mainCard}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={0}
        >
          {data?.pie_report.length === 0 ? null : (
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={0}
            >
              <Grid
                item
                xs={6}
                justify="center"
                alignItems="center"
                textAlign="center"
              >
                <Box css={{ textAlign: 'center' }}>
                  <Box display="inline-block">
                    <Typography variant="h6" className={classes.BkChartTitle}>
                      <span>Member Attendance Trend</span>
                    </Typography>
                    <PieChart
                      className={classes.BkChart}
                      data={getAttendancePieData(data?.pie_report)}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid
                item
                xs={6}
                justify="center"
                alignItems="center"
                textAlign="center"
              >
                <Box css={{ textAlign: 'center' }}>
                  <Box display="inline-block">
                    <Typography variant="h6" className={classes.BkChartTitle}>
                      <span>Trend Over Time</span>
                    </Typography>
                    <StackedBarChart
                      xlabel="WEEK PREVIOUS"
                      ylabel="MEMBER (%)"
                      display
                      data={getAttendanceTrendBar(data?.bar_report)}
                      className={classes.BkChart}
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          )}

          <Grid item xs={6}>
            <Grid container direction="row" alignItems="center" spacing={0}>
              <Grid item xs={9}>
                <div className={classes.search}>
                  <InputBase
                    fullWidth
                    placeholder="Search…"
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                      SearchQuery(e.target.value);
                    }}
                    classes={{
                      root: classes.inputRoot,
                      input: classes.searchInput
                    }}
                    InputProps={{
                      'aria-label': 'search'
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs>
                <JBButton
                  type="outlined"
                  style={{ marginLeft: '10px' }}
                  onClick={() => handleReset()}
                >
                  reset
                </JBButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} justify="flex-end" className="MuiGrid-container">
            <Box
              mr="20px"
              css={{
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                marginRight: '20px'
              }}
            >
              <FormControlLabel
                control={
                  <IOSSwitch
                    checked={Boolean(activeuser)}
                    onChange={(e) => {
                      setactiveuser(e.target.checked);
                      userSelect(
                        e.target.checked,
                        filteredTags.filter(
                          (l) => l.filter === 'user_status'
                        )[0]
                      );
                    }}
                  />
                }
                label="Active members only"
                style={{ margin: '0 0' }}
              />
            </Box>
            <Box css={{ width: '240px' }}>
              <ApplyFilter>
                <AttendanceTrendFilters
                  getFilData={(val, val2) =>
                    AttendanceFilter(filterType.addcustom, [val, val2])
                  }
                />
              </ApplyFilter>
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <div>
            {filteredTags
              .filter((tags) => tags.filter !== 'user_status')
              .map((tags) => (
                <div
                  className={classes.DTags}
                  key={tags.id}
                  style={{ display: 'inline-block' }}
                >
                  <Box display="inline-block" mr={1}>
                    <label>
                      {tags.name}
                      <span
                        className={classes.DTagsClose}
                        onClick={() => {
                          AttendanceFilter(filterType.removecustom, tags);
                        }}
                      >
                        ×
                      </span>
                    </label>
                  </Box>
                </div>
              ))}
          </div>
        </Grid>
        {isFetching || !data ? (
          <LoadingView />
        ) : error ? (
          <p>{error}</p>
        ) : (
          <Fragment>
            {' '}
            <Grid item xs={12}>
              <Box mb="15px" mt="15px">
                <p className={classes.ptext}>
                  Important: This report is most relevant after you have 8 weeks
                  of attendance data.
                </p>
              </Box>
              <AttendanceTrendTable
                values={data?.data?.data}
                setDirection={setDirection}
                isActive={isActive}
                setSort={setSort}
              />
            </Grid>
            {data?.data.data.length !== 0 && (
              <Grid
                item
                xs={12}
                container
                direction="row"
                alignItems="center"
                style={{ marginTop: 24 }}
              >
                <Grid item xs={6} justify="flex-start">
                  {data && data.data.from && data.data.to >= 1 ? (
                    <Paper elevation={0} style={{ color: '#A9A9A9' }}>
                      {`${data.data.from} - ${data.data.to} of ${data.data.total}`}
                    </Paper>
                  ) : null}
                </Grid>
                <Grid
                  item
                  xs={6}
                  justify="flex-end"
                  style={{ display: 'flex' }}
                >
                  <Pagination
                    count={data.data?.last_page}
                    color="primary"
                    variant="outlined"
                    page={data.data?.current_page}
                    onChange={(__, val) => Paginate(val)}
                    shape="rounded"
                  />
                </Grid>
              </Grid>
            )}
          </Fragment>
        )}
      </Card>
      <CancelDialogTwo setOpen={open} handleClose={() => setOpen(false)} />
    </div>
  );
};

AttendanceTrend.propTypes = {
  classes: PropTypes.object.isRequired,
  AttendanceFilter: PropTypes.func.isRequired,
  AttendanceSearch: PropTypes.string.isRequired,
  AttendanceSort: PropTypes.object.isRequired,
  filteredTags: PropTypes.array.isRequired
};

const mapToState = (state) => ({
  AttendanceSearch:
    state.reportsReducer.attendanceReports?.attendanceTrendsFil.search,
  AttendanceSort:
    state.reportsReducer.attendanceReports?.attendanceTrendsFil.sorting,
  filteredTags:
    state.reportsReducer.attendanceReports?.attendanceTrendsFil.tags,
  customFilter:
    state.reportsReducer.attendanceReports.attendanceTrendsFil.customFilter
});

const mapToDispatch = (dispatch) => ({
  AttendanceFilter: (filter, data) =>
    dispatch(getattendanceTrends(filter, data))
});

export default connect(
  mapToState,
  mapToDispatch
)(withStyles(styles)(AttendanceTrend));
