import React, { useState } from 'react';
import { TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropType from 'prop-types';

const styles = () => ({
  input: {
    color: '#292929',
    fontSize: '16px',
    '&::placeholder': {
      color: '#7F7F7F',
      fontSize: '16px',
      fontWeight: '500',
      opacity: '0.5'
    }
  }
});
const Inputs = (props) => {
  const {
    label,
    name,
    classes,
    seterrors,
    seterrortext,
    maxLength,
    inputMode
  } = props;
  const [refs, setRefs] = useState(null);

  return (
    <TextField
      fullWidth
      label={label}
      margin="normal"
      name={name}
      className="fd-input"
      onFocus={() => setRefs(true)}
      inputProps={{
        maxLength,
        inputMode
      }}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      InputProps={{
        disableUnderline: true,
        classes: {
          input: classes.input
        }
      }}
      InputLabelProps={{
        shrink: true
      }}
      error={Boolean(refs && seterrors)}
      helperText={refs && seterrors ? seterrortext : null}
      {...props} // eslint-disable-line
    />
  );
};

Inputs.defaultProps = {
  maxLength: 100,
  seterrors: false,
  name: 'Name',
  seterrortext: 'Required Fields',
  inputMode: ''
};

Inputs.propTypes = {
  classes: PropType.object.isRequired,
  maxLength: PropType.number,
  label: PropType.string.isRequired,
  name: PropType.string,
  seterrors: PropType.bool,
  seterrortext: PropType.string,
  inputMode: PropType.string
};

export default withStyles(styles)(Inputs);
