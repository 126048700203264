/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  AppBar,
  Tabs,
  Tab,
  Typography,
  makeStyles,
  ThemeProvider
} from '@material-ui/core';
import GeneralInformation from './GeneralInformation/GeneralInformation';
import PaymentBilling from './PaymentBilling/PaymentBilling';
import Pricing from './Pricing/Pricing';

const themeInstance = {
  overrides: {
    MuiTabs: {
      indicator: {
        backgroundColor: 'transparent'
      }
    },
    MuiTab: {
      root: {
        maxWidth: '100%',
        borderTopLeftRadius: '5px',
        borderTopRightRadius: '5px',
        color: 'rgb(255 255 255 / 0.5)',
        borderBottom: '0',
        margin: '0px 5px 0 0',
        '&$selected': {
          backgroundColor: '#26273B',
          color: '#000 ',
          '&:hover': {
            color: '#37847a'
          },
          '&$textColorPrimary': {
            color: '#01847A'
          }
        }
      },
      wrapper: {
        flexDirection: 'row',
        justifyContent: 'space-around'
      },
      labelIcon: {
        minHeight: '55px'
      },
      textColorPrimary: {
        color: 'rgb(255 255 255 / 0.5)',
        fontSize: '14px',
        fontWeight: '900',
        lineHeight: '18px',
        letterSpacing: '0.5px'
      }
    }
  }
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other} // eslint-disable-line
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  STabPanel: {
    backgroundColor: theme.masterhq.mainCard.background,
    boxShadow: '4px 0px 16px rgba(37, 40, 43, 0.08)',
    padding: '20px',
    '& .MuiCard-root.MuiPaper-rounded': {
      boxShadow: 'none',
      padding: '0 10px'
    },
    '& .fd-input input': {
      color: '#ffffff',
      background: 'rgba(255, 255, 255, 0.1)',
      borderRadius: '2px'
    },
    '& .cutom_select div input': {
      color: '#ffffff !important'
    }
  },
  hr: {
    border: '1px solid rgb(196 196 196 / 0.4)',
    display: 'inline-block',
    width: '100%',
    margin: '10px 0'
  },
  ptext: {
    fontSize: '16px',
    lineHeight: '24px',
    color: '#7F7F7F',
    fontWeight: 'normal'
  },
  cutom_Switch_Label: {
    display: 'inline-block',
    marginRight: '30px',
    verticalAlign: 'middle',
    fontSize: '14px',
    fontWeight: '700',
    color: '#000'
  },
  cutom_select_Label: {
    fontSize: '12px',
    padding: '7px 0'
  },
  BalanceInfo: {
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px',
    color: '#292929',
    marginLeft: '20px'
  },
  titleHeading: {
    fontWeight: 'bold',
    fontSize: '20px',
    lineHeight: '26px',
    color: '#000'
  },
  tableTopBtn: {
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'absolute',
    right: '10px',
    top: '0',
    zIndex: '9'
  },
  headBlock: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'baseline',
    marginBottom: '15px',
    marginTop: '20px',
    padding: '0 10px'
  }
}));

export default function SimpleTabs(props) {
  const classes = useStyles();
  const { Tabsdata, handleChangeData } = props;
  return (
    <div className={classes.root}>
      <ThemeProvider theme={themeInstance}>
        <AppBar
          position="static"
          style={{
            backgroundColor: 'transparent',
            boxShadow: 'none',
            padding: '0 20px'
          }}
        >
          <Tabs
            value={Tabsdata}
            indicatorColor="primary"
            textColor="primary"
            color="primary"
            fullWidth
            onChange={handleChangeData}
            aria-label="simple tabs example"
          >
            <Tab label="General Information" {...a11yProps(0)} />
            <Tab label="Payment & billing" {...a11yProps(1)} />
            <Tab label="Subscription Details" {...a11yProps(2)} />
          </Tabs>
        </AppBar>
      </ThemeProvider>
      {/* Profile */}
      <TabPanel value={Tabsdata} index={0} className={classes.STabPanel}>
        <GeneralInformation />
      </TabPanel>
      <TabPanel value={Tabsdata} index={1} className={classes.STabPanel}>
        <PaymentBilling />
      </TabPanel>
      <TabPanel value={Tabsdata} index={2} className={classes.STabPanel}>
        <Pricing />
      </TabPanel>
    </div>
  );
}

SimpleTabs.propTypes = {
  profileinfo: PropTypes.object.isRequired,
  Tabsdata: PropTypes.any.isRequired,
  handleChangeData: PropTypes.string.isRequired
};
