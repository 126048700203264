import React, { useState, useEffect, useContext } from 'react';
import { VanguardWaiverCtx } from 'src/Context/VanguardWaiverContext';
import PropType from 'prop-types';
import {
  SelectWaiverId,
  setIsLoading
} from 'src/Context/VanguardWaiver/VanguardWaiver.actions';
// import { getWaiverTemp } from 'src/services/Contracts.services';
import {
  Typography,
  makeStyles,
  Grid,
  RadioGroup,
  Radio,
  FormControlLabel
} from '@material-ui/core';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import VanguardListLoadingView from 'src/views/common/loading/VanguardListLoadingView';
import CheckIcon from '@material-ui/icons/Check';
import { getWaiverWithUser } from 'src/services/vanguard.services';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    background: 'rgba(29, 29, 29, 0.94)',
    boxShadow: '0px 0.33333px 0px rgb(255 255 255 / 15%)',
    backdropFilter: 'blur(54.3656px)'
  },
  appToolbar: {
    minHeight: '75px',
    justifyContent: 'space-between'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
  mainTitle: {
    fontWeight: '500',
    fontSize: '48px',
    lineHeight: '44px',
    color: '#FFFFFF',
    fontFamily: 'DDC Hardware',
    textAlign: 'center',
    margin: '38px 0 0 0',
    '@media (max-width: 992px)': {
      fontSize: '35px'
    }
  },
  MainCard: {
    height: 'calc(100vh - 75px )',
    background:
      'linear-gradient(180deg, rgba(41, 41, 41, 0.95) 0%, rgba(4, 4, 4, 0.95) 100%)',
    borderRadius: '0',
    flex: '1 1 auto',
    // height: '100%',
    overflow: 'auto'
  },
  HomeButton: {
    fontFamily: 'Lato',
    color: '#2CE9DA',
    fontWeight: 'bold',
    fontSize: '22px',
    lineHeight: '32px',
    textTransform: 'capitalize'
  },
  cutom_select_Label: {
    fontFamily: 'DDC Hardware',
    fontStyle: 'normal',
    fontWeight: '500;',
    fontSize: '22px',
    lineHeight: '32px',
    letterSpacing: '0.2px',
    color: '#FFFFFF',
    '&:focus ': {
      outline: '0'
    }
  },
  cutom_vanguard_FormControl: {
    '& .fd-input input': {
      background: 'rgba(196, 196, 196, 0.05)',
      color: '#fff',
      borderRadius: '6px',
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '20px',
      height: '45px',
      border: '2px solid #C8C8C8',
      padding: '8px 20px 8px',
      '&::placeholder ': {
        color: '#fff',
        fontWeight: 'bold',
        fontSize: '15px'
      }
    }
  },
  cutom_vanguard_Autocomplete: {
    background: 'rgba(196, 196, 196, 0.05)',
    border: '2px solid #C8C8C8',
    color: '#fff',
    borderRadius: '6px',
    '& .cutom_select div': {
      minHeight: '62px'
    },
    '& .cutom_select div input ': {
      color: '#ffffff !important',
      fontWeight: 'bold',
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontSize: '20px'
    },
    '& .MuiAutocomplete-endAdornment': {
      minHeight: ' auto !important',
      top: 'calc(50% - 12px)'
    }
  },
  customRadio: {
    '& .MuiFormControlLabel-root': {
      background: 'rgba(196, 196, 196, 0.05)',
      border: '2px solid #7B7B7B',
      borderRadius: '6px',
      height: '70px',
      margin: '0 0 15px'
    },
    '& .MuiFormControlLabel-root.checked': {
      background: 'rgba(44, 233, 218, 0.15)',
      border: '2px solid #2CE9DA',
      borderRadius: '6px'
    },
    '& .MuiFormControlLabel-root svg.MuiSvgIcon-root': {
      width: '30px',
      height: '30px',
      fill: 'none',
      borderRadius: '50%',
      border: '2px solid #4B4B4B'
    },
    '& .MuiFormControlLabel-root.checked svg.MuiSvgIcon-root': {
      background: '#67e9da',
      padding: '4px',
      fill: '#000',
      color: '#060606',
      border: '0px solid #4B4B4B'
    },
    '& .MuiRadio-root': {
      padding: '20px'
    },
    '& .MuiFormControlLabel-label': {
      color: '#fff',
      fontSize: '20px',
      lineHeight: '26px',
      fontWeight: 'bold'
    }
  },
  message: {
    color: 'rgb(255 255 255 / 60%)',
    marginLeft: '20px',
    fontSize: '20px',
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 'bold',
    lineHeight: '32px',
    wordBreak: 'break-word'
  }
}));

const SelectWaiver = ({ UserData }) => {
  const classes = useStyles();
  const [state, dispatch] = useContext(VanguardWaiverCtx); // eslint-disable-line
  const [data, setData] = useState([]);
  const [Missingdata, setMissingData] = useState([]);
  const [error, setError] = useState(null);

  const { isLoading, selectedWaiver } = state;
  // useEffect(() => {
  //   dispatch(setIsLoading(true));
  //   getWaiverTemp({ filter_by_visibility: 1 })
  //     .then((response) => {
  //       setData(response);
  //     })
  //     .catch((err) => setError(err))
  //     .finally(() => dispatch(setIsLoading(false)));
  // }, [dispatch]);

  useEffect(() => {
    dispatch(setIsLoading(true));
    getWaiverWithUser(UserData.id)
      .then((response) => {
        setData(response.all_waivers);
        setMissingData(response.user_waivers);
      })
      .catch((err) => setError(err))
      .finally(() => dispatch(setIsLoading(false)));
  }, [dispatch, UserData.id]);

  if (!data || !Missingdata) return <VanguardListLoadingView />;

  return (
    <div>
      {Missingdata.length === 0 ? null : (
        <div>
          <Typography variant="h3" className={classes.mainTitle}>
            Missing Waiver
          </Typography>
          <Grid
            container
            xs={10}
            justify="center"
            style={{
              margin: '45px auto'
            }}
          >
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={2}
              // style={{ margin: '20px 0 20px' }}
            >
              {isLoading ? (
                <VanguardListLoadingView />
              ) : error ? (
                <p>{error.message}</p>
              ) : (
                <Grid xs={12}>
                  <RadioGroup
                    value={selectedWaiver}
                    onChange={(e) => {
                      dispatch(SelectWaiverId(Number(e.target.value)));
                    }}
                    className={classes.customRadio}
                  >
                    {Missingdata.map((waiver) => {
                      return (
                        <FormControlLabel
                          key={waiver.id}
                          value={waiver.id}
                          control={
                            <Radio
                              color="primary"
                              onClick={() => {
                                dispatch(SelectWaiverId(waiver.id));
                              }}
                              checkedIcon={
                                <CheckIcon
                                  htmlColor="#2CE9DA"
                                  width="30px"
                                  height="30px"
                                />
                              }
                              icon={
                                <RadioButtonUncheckedIcon
                                  width="30px"
                                  height="30px"
                                />
                              }
                            />
                          }
                          label={waiver.name}
                          // checked
                          className={`${
                            selectedWaiver === waiver.id ? 'checked' : ''
                          }`}
                        />
                      );
                    })}
                  </RadioGroup>
                </Grid>
              )}
            </Grid>
          </Grid>
        </div>
      )}
      {data.length === 0 && Missingdata.length === 0 && (
        <p
          style={{
            color: '#fff',
            textAlign: 'center'
          }}
        >
          No waiver to show...
        </p>
      )}
      {data.length === 0 ? null : (
        <>
          <Typography variant="h3" className={classes.mainTitle}>
            Select Waiver
          </Typography>
          <Grid
            container
            xs={10}
            justify="center"
            style={{
              margin: '45px auto'
            }}
          >
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={2}
              // style={{ margin: '20px 0 20px' }}
            >
              {isLoading ? (
                <VanguardListLoadingView />
              ) : error ? (
                <p>{error.message}</p>
              ) : (
                <Grid xs={12}>
                  <RadioGroup
                    value={selectedWaiver}
                    onChange={(e) => {
                      dispatch(SelectWaiverId(Number(e.target.value)));
                    }}
                    className={classes.customRadio}
                  >
                    {data.map((waiver) => {
                      return (
                        <FormControlLabel
                          key={waiver.id}
                          value={waiver.id}
                          control={
                            <Radio
                              color="primary"
                              onClick={() => {
                                dispatch(SelectWaiverId(waiver.id));
                              }}
                              checkedIcon={
                                <CheckIcon
                                  htmlColor="#2CE9DA"
                                  width="30px"
                                  height="30px"
                                />
                              }
                              icon={
                                <RadioButtonUncheckedIcon
                                  width="30px"
                                  height="30px"
                                />
                              }
                            />
                          }
                          label={waiver.name}
                          // checked
                          className={`${
                            selectedWaiver === waiver.id ? 'checked' : ''
                          }`}
                        />
                      );
                    })}
                  </RadioGroup>
                </Grid>
              )}
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
};

SelectWaiver.propTypes = {
  // setOpen: PropType.bool.isRequired,
  // handleClose: PropType.func.isRequired
  // handleBack: PropType.func,
  // handleHistory: PropType.string
  UserData: PropType.object.isRequired
};

export default SelectWaiver;
