import React, { memo } from 'react';
import PropType from 'prop-types';
import CKEditor from 'ckeditor4-react';

const JBCKEditor = memo(({ data, onChange, holder }) => {
  const place_holders = Array.isArray(holder)
    ? holder
    : ['gymname', 'gymlabel', 'signature'];

  return (
    <CKEditor
      data={data || '<p></p>'}
      config={{
        toolbar: [
          [
            'placeholder_select',
            'Bold',
            'Italic',
            'Underline',
            'Font',
            'Link',
            'FontSize',
            'Blockquote',
            'JustifyRight',
            'JustifyCenter',
            'JustifyLeft',
            'TextColor',
            'BGColor'
          ]
        ],
        fontSize_sizes:
          '11/11px;12/12px;14/14px;16/16px;18/18px;20/20px;22/22px;24/24px;26/26px;28/28px;36/36px;48/48px;72/72px',
        font_names:
          'Arial; Courier New; Georgia; Lucida Sans Unicode; Tahoma; Times New Roman; Trebuchet MS; Verdana; Lato;',
        removeButtons: 'Subscript,Superscript',
        extraPlugins:
          'font,codesnippet,justify,colorbutton,placeholder_select,richcombo,colordialog,dialog',
        enterMode: 2,
        forceEnterMode: 2,
        placeholder_select: {
          placeholders: place_holders
        }
      }}
      onBeforeLoad={(CKEDITOR) => {
        CKEDITOR.plugins.addExternal(
          'placeholder_select',
          `${process.env.PUBLIC_URL}/otherplugins/placeholder_select/`,
          'plugin.js'
        );
        CKEDITOR.plugins.addExternal(
          'richcombo',
          `${process.env.PUBLIC_URL}/otherplugins/richcombo/`,
          'plugin.js'
        );
        CKEDITOR.plugins.addExternal(
          'button',
          `${process.env.PUBLIC_URL}/otherplugins/button/`,
          'plugin.js'
        );
        CKEDITOR.plugins.addExternal(
          'floatpanel',
          `${process.env.PUBLIC_URL}/otherplugins/floatpanel/`,
          'plugin.js'
        );
        CKEDITOR.plugins.addExternal(
          'listblock',
          `${process.env.PUBLIC_URL}/otherplugins/listblock/`,
          'plugin.js'
        );
        CKEDITOR.plugins.addExternal(
          'panel',
          `${process.env.PUBLIC_URL}/otherplugins/panel/`,
          'plugin.js'
        );
        CKEDITOR.plugins.addExternal(
          'dialog',
          `${process.env.PUBLIC_URL}/otherplugins/dialog/`,
          'plugin.js'
        );
        CKEDITOR.plugins.addExternal(
          'dialog',
          `${process.env.PUBLIC_URL}/otherplugins/colordialog/`,
          'plugin.js'
        );
      }}
      onChange={onChange}
    />
  );
});

JBCKEditor.propTypes = {
  onChange: PropType.func.isRequired,
  data: PropType.string.isRequired,
  holder: PropType.array.isRequired
};

export default JBCKEditor;
