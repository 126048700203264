import React from 'react';
import { withStyles } from '@material-ui/core';
import DatePicker from 'react-datepicker';
import PropType from 'prop-types';
import 'react-datepicker/dist/react-datepicker.css';
import styles from './TimePicker.style';

const TimePicker = (props) => {
  const { classes, value, Change } = props;
  const InvalidateInput = (
    { value, onClick } // eslint-disable-line
  ) => (
    <div
      onClick={onClick}
      style={{ color: 'black', fontWeight: '600', cursor: 'pointer' }}
    >
      {' '}
      {value}
    </div>
  );
  return (
    <DatePicker
      selected={value}
      onChange={(date) => Change(date)}
      showTimeSelect
      showTimeSelectOnly
      timeIntervals={15}
      timeCaption="Time"
      customInput={<InvalidateInput />}
      dateFormat="h:mm aa"
      className={classes.timeinput}
      {...props} // eslint-disable-line
    />
  );
};

TimePicker.propTypes = {
  classes: PropType.object.isRequired,
  Change: PropType.func.isRequired,
  value: PropType.object.isRequired,
  onClick: PropType.func.isRequired
};

export default withStyles(styles)(TimePicker);
