/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react';
import PropType from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  withStyles,
  Grid,
  Paper
} from '@material-ui/core';
import { getValueOfSpecificMember } from 'src/services/reports.services';
import Pagination from '@material-ui/lab/Pagination';
import LoadingView from 'src/views/common/loading';
import dcloseicon from 'src/assets/image/closeicon.png';
import ValueTable from './ValueTable';

const styles = () => ({
  dtitle: {
    color: '#292929',
    fontWeight: 'bold',
    fontSize: '33px',
    lineHeight: 'normal'
  },
  cutom_select_Label: {
    fontSize: '12px',
    padding: '7px 0'
  },
  titlebk: {
    display: 'inline-block',
    padding: '25px 24px 10px'
  }
});

const ValueDialog = (props) => {
  const {
    classes,
    // handleHistory,
    handleClose,
    setOpen,
    id,
    userName,
    month
  } = props;

  // const [state, dispatch] = useContext();
  // const { data, isFetching, error } = state.membersLastAttendance;

  const [tableValue, setTableValue] = useState({
    isFetching: true,
    data: null,
    error: null
  });

  useEffect(() => {
    setTableValue({ isFetching: true, data: null, error: null });
    getValueOfSpecificMember({ user_id: id, month })
      .then((res) => {
        setTableValue({ data: res, isFetching: false, error: null });
        // setData(res.data);
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div {...props}>
      <Dialog
        open={setOpen}
        onClose={() => handleClose()}
        fullWidth
        maxWidth="lg"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick
      >
        <div>
          <Grid alignItems="center" container direction="row">
            <Grid item xs>
              <DialogTitle id="alert-dialog-title" className={classes.titlebk}>
                <h4 className={classes.dtitle}>{userName}'s value</h4>
              </DialogTitle>
            </Grid>
            <Grid
              item
              xs={2}
              style={{ textAlign: 'right', marginRight: '10px' }}
            >
              <Button className={classes.btns} onClick={handleClose}>
                <img alt="img" src={dcloseicon} />
              </Button>
            </Grid>
          </Grid>

          <DialogContent>
            <Grid container>
              <Grid item xs={12}>
                {tableValue.isFetching ? (
                  <LoadingView />
                ) : tableValue.error ? (
                  <p>{tableValue.error}</p>
                ) : (
                  <ValueTable values={tableValue.data.data} />
                )}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions
            style={{
              padding: '20px 24px'
            }}
          >
            <Grid
              item
              xs={12}
              container
              direction="row"
              alignItems="center"
              style={{ marginTop: 24 }}
            >
              <Grid item xs={6} justify="flex-start">
                {tableValue.data?.from && tableValue.data?.to > 1 ? (
                  <Paper elevation={0} style={{ color: '#A9A9A9' }}>
                    {`${tableValue.data?.from} - ${tableValue.data?.to} of ${tableValue.data?.total}`}
                  </Paper>
                ) : null}
              </Grid>
              <Grid item xs={6} justify="flex-end" style={{ display: 'flex' }}>
                <Pagination
                  count={tableValue.data?.last_page}
                  color="primary"
                  variant="outlined"
                  page={tableValue.data?.current_page}
                  // onChange={((_, val) => val, tableValue.data?.current_page)} //eslint-disable-line
                  // onChange={handleChangePage}
                  shape="rounded"
                />
                {/* <Pagination
                  count={tableValue.last_page}
                  color="primary"
                  variant="outlined"
                  page={tableValue.current_page}
                  onChange={(_, val) => Paginate(val)}
                  shape="rounded"
                /> */}
              </Grid>
            </Grid>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
};
ValueDialog.defaultProps = {
  // handleHistory: null,
  userName: ''
};

ValueDialog.propTypes = {
  classes: PropType.object.isRequired,
  // handleHistory: PropType.string,
  setOpen: PropType.bool.isRequired,
  handleClose: PropType.func.isRequired,
  id: PropType.number.isRequired,
  userName: PropType.string,
  month: PropType.any
};
export default withStyles(styles)(ValueDialog);
