import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid, Paper, InputBase, Box } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import LoadingView from 'src/views/common/loading';
import useChangeTitle from 'src/views/common/CustomHooks/useTitleChange';
import ApplyFilter from 'src/views/common/ApplyFilter/applyFilter';
import _ from 'lodash';
import filterType from 'src/Redux/Reducer.constants';
import { reccurdata } from 'src/Redux/ClassReducer/classReducer.actions';
import ReccurTable from './Recurrtable';
import useStyles from './ListTabs.style';
import Paginate from './classList.utils';
import ReccurFilter from './ListFilters/recuurFilters';

const RecurringTab = (props) => {
  const classes = useStyles();
  const {
    ReccFilter,
    reccurSearch,
    getClass: { isFetching, data, error },
    filteredTags,
    changeTab,
    reccurSort
  } = props;
  const [search, setSearch] = useState(reccurSearch);

  const SearchQuery = useCallback(
    _.debounce((q) => ReccFilter(filterType.search, q), 1500),
    []
  );
  useChangeTitle('Recurring Classes');
  if (error) return <p>{error}</p>;

  const setDirection = (name) => {
    if (reccurSort.sort_name === name) {
      return reccurSort.sort_by === 'asc' ? 'asc' : 'dsc';
    }
    return 'asc';
  };

  const isActive = (sortlabel) => {
    if (reccurSort.sort_name === sortlabel) return true;
    return false;
  };
  const setSort = (sortlabel) => {
    ReccFilter(filterType.sorting, sortlabel);
  };
  return (
    <div className={classes.root}>
      <Grid
        container
        direction="row"
        justify="start"
        alignItems="center"
        spacing={0}
        style={{ margin: '20px 0 20px' }}
      >
        <Grid container disableGutters style={{ marginBottom: '15px' }}>
          <Box mr="15px">
            <ApplyFilter>
              <ReccurFilter
                getFilData={(val, val2) =>
                  ReccFilter(filterType.addcustom, [val, val2])
                } // eslint-disable-line
              />
            </ApplyFilter>
          </Box>
          <Grid item xs>
            <div className={classes.search}>
              <InputBase
                fullWidth
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                  SearchQuery(e.target.value);
                }}
                placeholder="Search…"
                classes={{
                  root: classes.inputRoot,
                  input: classes.searchInput
                }}
                InputProps={{
                  'aria-label': 'search'
                }}
              />
            </div>
          </Grid>
        </Grid>
        <div>
          {filteredTags.map((tags) => (
            <div
              className={classes.DTags}
              key={tags.id}
              style={{ margin: '0 0 15px', display: 'inline-block' }}
            >
              <Box display="inline-block" mr={1}>
                <label>
                  {tags.name}
                  <span
                    className={classes.DTagsClose}
                    onClick={() => ReccFilter(filterType.removecustom, tags)}
                  >
                    ×
                  </span>
                </label>
              </Box>
            </div>
          ))}
        </div>
        {isFetching ? (
          <LoadingView />
        ) : (
          <>
            <Grid item xs={12} className={classes.SimpleTablestyle}>
              <ReccurTable
                values={data.data}
                toAllClass={changeTab}
                setDirection={setDirection}
                isActive={isActive}
                setSort={setSort}
              />
            </Grid>

            <Grid
              item
              xs={12}
              container
              direction="row"
              alignItems="center"
              style={{ marginTop: 24 }}
            >
              <Grid item xs={6} justify="flex-start">
                {/* <Paper elevation={0} style={{ color: '#A9A9A9' }}>
              {data.from}-{data.to}
            </Paper> */}
                {data.from && data.to > 1 ? (
                  <Paper elevation={0} style={{ color: '#A9A9A9' }}>
                    {`${data.from} - ${data.to} of ${data.total}`}
                  </Paper>
                ) : null}
              </Grid>
              <Grid item xs={6} justify="flex-end" style={{ display: 'flex' }}>
                <Pagination
                  count={data.last_page}
                  color="primary"
                  variant="outlined"
                  page={data.current_page}
                  onChange={(_, val) => Paginate(val, data, reccurdata)} //eslint-disable-line
                  shape="rounded"
                />
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
};
RecurringTab.propTypes = {
  ReccFilter: PropTypes.func.isRequired,
  getClass: PropTypes.object.isRequired,
  reccurSearch: PropTypes.string.isRequired,
  changeTab: PropTypes.func.isRequired,
  reccurSort: PropTypes.object.isRequired,
  filteredTags: PropTypes.array.isRequired
};

const mapToDispatch = (dispatch) => ({
  ReccFilter: (filter, data) => dispatch(reccurdata(filter, data))
});

const mapToState = (state) => ({
  getClass: state.classReducer.recurrClass,
  reccurSearch: state.classReducer.recurrFilter.search,
  reccurSort: state.classReducer.recurrFilter.sorting,
  filteredTags: state.classReducer.recurrFilter.tags
});

export default connect(mapToState, mapToDispatch)(RecurringTab);
