import PropType from 'prop-types';

const styles = (theme, fontSizes) => ({
  root: {
    marginTop: 0,
    margin: '0px 20px 0 25px',
    padding: '0px 20px 0',
    '& .ql-editor h1': {
      fontSize: `${fontSizes}px`,
      lineHeight: 'inherit'
    },
    [theme.breakpoints.down('md')]: {
      margin: '0px 0px 0 0px'
    },
    [theme.breakpoints.down('sm')]: {
      margin: '0px 0px 0 0px',
      padding: '0px 20px 0 0'
    }
  },
  ChalkboardTitle: {
    [theme.breakpoints.down('md')]: {
      fontSize: '34px',
      marginTop: '15px',
      zIndex: '0 !important'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '30px',
      marginTop: '15px',
      zIndex: '0 !important'
    }
  },
  ScreenBtn: {
    [theme.breakpoints.down('md')]: {
      '& button': {
        right: '20px !important'
      }
    },
    [theme.breakpoints.down('sm')]: {
      '& button': {
        minHeight: 'auto',
        padding: '8px 8px',
        marginTop: '-66px !important',
        right: '20px !important'
      }
    }
  },
  cutom_select_border: {
    border: '1px solid #a9a9a9'
  },
  cutom_select_Label: {
    padding: '7px 0',
    fontSize: '14px',
    lineHeight: '18px',
    color: '#000',
    fontWeight: 'normal',
    letterSpacing: '1px',
    textTransform: 'uppercase'
  },
  ptext: {
    fontSize: '16px',
    lineHeight: '24px',
    color: '#7F7F7F',
    fontWeight: 'normal'
  },
  p_tabs: {
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px'
  },
  STabPanel: {
    backgroundColor: '#fff',
    boxShadow: '4px 0px 16px rgba(37, 40, 43, 0.08)',
    width: '100%',
    display: 'block',
    paddingBottom: '20px',
    paddingTop: '20px',
    paddingLeft: '20px',
    paddingRight: '20px',
    // paddingBottom: '20px',
    borderRadius: '5px',
    borderTopLeftRadius: '0',
    border: '1px solid #EAEAEA',
    borderTop: '0',
    height: 'calc(100vh - 258px)',
    overflowY: 'auto'
  },
  htmldata: {
    background: '#FFFFFF'
    // position: 'relative'
  },
  chalkboardfilter: {
    background: '#FFFFFF',
    boxShadow: '4px 0px 16px rgba(37, 40, 43, 0.08)',
    borderRadius: '5px',
    width: '100%',
    padding: '15px',
    margin: '15px',
    height: 'calc(100vh - 250px)',
    overflowY: 'auto',
    '& h5': {
      display: 'inline-block',
      fontWeight: 'bold',
      fontSize: '20px',
      lineHeight: '26px',
      color: '#545454',
      verticalAlign: 'middle',
      marginLeft: '6px'
    },
    '& img': {
      display: 'inline-block',
      verticalAlign: 'middle'
    }
  },
  btnfilter: {
    display: 'inline-block',
    width: '100%',
    cursor: 'pointer'
  },
  filterbk: {
    display: 'inline-block',
    width: '100%',
    position: 'relative',
    padding: '12px 0',
    '&::before': {
      content: '""',
      position: 'absolute',
      bottom: '-2px',
      height: '2px',
      margin: '0 auto',
      width: '100%',
      background: '#01847A',
      borderTopLeftRadius: '2px',
      borderTopRightRadius: '2px'
    }
  },
  filterinfo: {
    width: '100%',
    margin: '15px 0'
  },
  chalkboard_cutom_select: {
    width: '100%',
    border: '1px solid #a9a9a9',
    '& .cutom_select div': {
      minHeight: '60px'
    }
  },
  chalk_cutom_date: {
    '& .MuiInput-root': {
      color: '#545454',
      height: '60px',
      fontWeight: '600',
      fontSize: '14px',
      lineHeight: '18px',
      marginTop: '0px !important'
    }
  },
  siBox: {
    background: '#FFFFFF',
    border: '1px solid #EAEAEA',
    borderRadius: '5px',
    display: 'inline-flex',
    width: '318px',
    height: '145px',
    position: 'relative',
    marginBottom: '30px',
    marginRight: '30px',
    '& img': {
      borderRadius: '5px 0px 0px 5px',
      width: '143px',
      height: '143px',
      objectFit: 'fill'
    }
  },
  siBoxinfo: {
    width: '100%',
    padding: '55px 15px 15px',
    wordBreak: 'break-all',
    overflow: 'hidden',
    '& h3': {
      fontWeight: 'bold',
      fontSize: '20px',
      lineHeight: '26px',
      letterSpacing: '0.2px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      width: '100%'
    }
  },
  closebk: {
    position: 'absolute',
    right: '0',
    marginTop: '6px'
  },
  Deletebutton: {
    color: '#FB4E4E',
    border: '1px solid',
    height: '48px',
    marginRight: '15px',
    fontWeight: '700'
  },
  Deletebtn: {
    background: '#FB4E4E',
    boxShadow: '0px 8px 16px rgba(251, 78, 78, 0.16)',
    borderRadius: '2px',
    height: '48px',
    padding: '10px 15px',
    color: '#fff',
    fontWeight: '700',
    '&:hover': {
      background: '#FB4E4E'
    }
  },
  msError: {
    fontSize: '40px',
    fontWeight: '900',
    [theme.breakpoints.down('sm')]: {
      fontSize: '24px'
    }
  },
  WodName: {
    color: '#01847A',
    fontSize: '40px',
    fontFamily: 'Lato, sans-serif',
    margin: '0px 0 10px',
    fontWeight: '800'
  },
  SignBox: {
    width: '100%',
    display: 'block',
    margin: '0px 0',
    '& h4': {
      color: '#01847a',
      width: '100%',
      margin: '10px 0',
      padding: '15px',
      fontSize: '20px',
      fontFamily: 'Lato, sans-serif',
      fontWeight: '900',
      display: 'inline-block',
      background: '#eaeaea',
      marginBottom: '30px',
      marginTop: '0'
    }
  },
  ReservedBox: {
    width: '100%',
    display: 'block',
    margin: '15px 0',
    '& h4': {
      color: '#01847a',
      width: '100%',
      margin: '10px 0',
      padding: '15px',
      fontSize: '20px',
      fontFamily: 'Lato, sans-serif',
      fontWeight: '900',
      display: 'inline-block',
      background: '#eaeaea',
      marginBottom: '30px'
    }
  }
});
styles.propTypes = {
  fontSize: PropType.string.isRequired
};
export default styles;
