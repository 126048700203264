/* eslint-disable react/jsx-curly-brace-presence */
import React from 'react';
import PropType from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  withStyles,
  TableSortLabel
} from '@material-ui/core';
import { getInvoiceStatus } from 'src/views/financial/invoices/Invoice/InvoiceView.utils';
import GetPrice from 'src/views/common/CurrencySymbol/GetPrice';
import filterType from 'src/Redux/Reducer.constants';
import { ROUTES } from 'src/constant/Constants';

const styles = () => ({
  table: {
    minWidth: '100%',
    wordBreak: 'break-word',
    '& td': {
      padding: '10px'
    },
    '@media (max-width: 1366px)': {
      '& td': {
        fontSize: '13px'
      }
    }
  },
  tableLink: {
    color: '#01847A',
    fontWeight: '700'
  },
  TableCell: {
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px',
    height: '56px',
    minHeight: '56px',
    padding: '10px',
    '@media (max-width: 1366px)': {
      fontSize: '13px',
      wordBreak: 'keep-all'
    }
  },
  Tablemian: {},
  SummaryCell: {
    color: '#292929',
    fontWeight: 'bold'
  }
});

const AllInvoicesTable = (props) => {
  const {
    values,
    classes,
    isActive,
    setDirection,
    setSort,
    summaryData
  } = props;
  const {
    sort_invoice,
    sort_invoice_status,
    sort_member_name,
    sort_payment_due,
    sort_exp_revenue,
    sort_total_discounts,
    sort_refunded_amount,
    sort_sub_total,
    sort_final_tax,
    sort_final_charge
  } = filterType;

  return (
    <TableContainer
      component={Paper}
      elevation={0}
      className={classes.Tablemian}
    >
      <Table className={classes.table} aria-label="simple table">
        <TableHead
          style={{ background: '#F9F9FA', backgroundColor: '#F9F9FA' }}
        >
          <TableRow>
            <TableCell className={classes.TableCell}>
              <TableSortLabel
                active={isActive(sort_invoice)}
                direction={setDirection(sort_invoice)}
                onClick={() => setSort(sort_invoice)}
              >
                Invoice
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell}>
              <TableSortLabel
                active={isActive(sort_invoice_status)}
                direction={setDirection(sort_invoice_status)}
                onClick={() => setSort(sort_invoice_status)}
              >
                Status
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell}>
              <TableSortLabel
                active={isActive(sort_member_name)}
                direction={setDirection(sort_member_name)}
                onClick={() => setSort(sort_member_name)}
              >
                Member
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell}>
              <TableSortLabel
                active={isActive(sort_payment_due)}
                direction={setDirection(sort_payment_due)}
                onClick={() => setSort(sort_payment_due)}
              >
                Payment Due
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell} align="right">
              <TableSortLabel
                active={isActive(sort_exp_revenue)}
                direction={setDirection(sort_exp_revenue)}
                onClick={() => setSort(sort_exp_revenue)}
                style={{ flexDirection: 'row' }}
              >
                Expected Revenue
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell} align="right">
              <TableSortLabel
                active={isActive(sort_total_discounts)}
                direction={setDirection(sort_total_discounts)}
                onClick={() => setSort(sort_total_discounts)}
                style={{ flexDirection: 'row' }}
              >
                Total Discounts
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell} align="right">
              <TableSortLabel
                active={isActive(sort_refunded_amount)}
                direction={setDirection(sort_refunded_amount)}
                onClick={() => setSort(sort_refunded_amount)}
                style={{ flexDirection: 'row' }}
              >
                Refunded Amount
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell} align="right">
              <TableSortLabel
                active={isActive(sort_sub_total)}
                direction={setDirection(sort_sub_total)}
                onClick={() => setSort(sort_sub_total)}
                style={{ flexDirection: 'row' }}
              >
                Subtotal
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell} align="right">
              <TableSortLabel
                active={isActive(sort_final_tax)}
                direction={setDirection(sort_final_tax)}
                onClick={() => setSort(sort_final_tax)}
                style={{ flexDirection: 'row' }}
              >
                Final Tax
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell} align="right">
              <TableSortLabel
                active={isActive(sort_final_charge)}
                direction={setDirection(sort_final_charge)}
                onClick={() => setSort(sort_final_charge)}
                style={{ flexDirection: 'row' }}
              >
                Final Charge
              </TableSortLabel>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {values.length !== 0 && (
            <TableRow>
              <TableCell
                style={{
                  width: '9.0909%'
                }}
                className={classes.SummaryCell}
              >
                Summary
              </TableCell>
              <TableCell
                style={{
                  width: '9.0909%'
                }}
                className={classes.SummaryCell}
              >
                &nbsp;
              </TableCell>
              <TableCell
                style={{
                  width: '9%'
                }}
                className={classes.SummaryCell}
              >
                &nbsp;
              </TableCell>
              <TableCell
                style={{
                  width: '9%'
                }}
                className={classes.SummaryCell}
              >
                &nbsp;
              </TableCell>
              <TableCell
                style={{
                  width: '9%'
                }}
                align="center"
                className={classes.SummaryCell}
              >
                {summaryData[0].exp_revenue && (
                  <GetPrice value={summaryData[0].exp_revenue} />
                )}
              </TableCell>
              <TableCell
                style={{
                  width: '9%'
                }}
                align="center"
                className={classes.SummaryCell}
              >
                {<GetPrice value={summaryData[0].total_discounts} />}
              </TableCell>
              <TableCell
                style={{
                  width: '9%'
                }}
                align="center"
                className={classes.SummaryCell}
              >
                {<GetPrice value={summaryData[0].refunded_amount} />}
              </TableCell>
              <TableCell
                style={{
                  width: '9%'
                }}
                align="center"
                className={classes.SummaryCell}
              >
                {<GetPrice value={summaryData[0].sub_total} />}
              </TableCell>
              <TableCell
                style={{
                  width: '9%'
                }}
                align="center"
                className={classes.SummaryCell}
              >
                {<GetPrice value={summaryData[0].final_tax} />}
              </TableCell>
              <TableCell
                style={{
                  width: '9%'
                }}
                align="center"
                className={classes.SummaryCell}
              >
                {/* <GetPrice value={summaryData[0].final_charge} /> */}
                {<GetPrice value={summaryData[0].final_charge} />}
              </TableCell>
            </TableRow>
          )}

          {values.map((row) => (
            <TableRow
              hover
              tabIndex={-1}
              key={row.id}
              style={{
                backgroundColor: '#FFF'
              }}
            >
              <TableCell
                style={{
                  width: '9.0909%'
                }}
              >
                <Link
                  className={classes.tableLink}
                  to={{
                    pathname:
                      row.i_status === 2 || row.i_status === 5
                        ? ROUTES.FINANCIAL_INVOICES_EDIT
                        : ROUTES.FINANCIAL_INVOICES_VIEW,
                    search: `?Invoice=${btoa(row.invoice_id)}`,
                    state: row.i_status
                  }}
                >
                  {row.invoice}
                </Link>
              </TableCell>
              <TableCell
                style={{
                  width: '9.0909%'
                }}
              >
                {getInvoiceStatus(row.i_status)}
              </TableCell>
              <TableCell
                style={{
                  width: '9%'
                }}
              >
                <Link
                  className={classes.tableLink}
                  to={{
                    pathname: ROUTES.PEOPLE_MEMBER_PROFILE,
                    search: `memberId=${btoa(row.user_id)}`
                  }}
                >
                  {row.member}
                </Link>
              </TableCell>
              <TableCell
                style={{
                  width: '9%'
                }}
              >
                {/* {row.payment_due} */}
                {row.payment_due}
              </TableCell>
              <TableCell
                style={{
                  width: '9%'
                }}
                align="center"
              >
                <GetPrice value={row.exp_revenue} />
              </TableCell>
              <TableCell
                style={{
                  width: '9%'
                }}
                align="center"
              >
                <GetPrice value={row.total_discounts} />
              </TableCell>
              <TableCell
                style={{
                  width: '9%'
                }}
                align="center"
              >
                <GetPrice value={row.refunded_amount} />
              </TableCell>
              <TableCell
                style={{
                  width: '9%'
                }}
                align="center"
              >
                {<GetPrice value={row.sub_total} />}
              </TableCell>
              <TableCell
                style={{
                  width: '9%'
                }}
                align="center"
              >
                <GetPrice value={row.final_tax} />
              </TableCell>
              <TableCell
                style={{
                  width: '9%'
                }}
                align="center"
              >
                <GetPrice value={row.final_charge} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {values.length === 0 && (
        <p
          style={{
            margin: '10px 15px'
          }}
        >
          No Invoices to show...
        </p>
      )}
    </TableContainer>
  );
};
AllInvoicesTable.propTypes = {
  values: PropType.array.isRequired,
  classes: PropType.object.isRequired,
  isActive: PropType.func.isRequired,
  setDirection: PropType.func.isRequired,
  setSort: PropType.func.isRequired,
  summaryData: PropType.any.isRequired
};

export default withStyles(styles)(AllInvoicesTable);
