import types from './globalactiontypes';

const initialState = {
  notiQueue: []
};

const programReducer = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case types.GLOBAL_NOTI_CLEAR_ITEMS:
      newState = {
        ...state,
        notiQueue: state.notiQueue.filter((noti) => noti.id !== action.payload)
      };
      break;
    case types.GLOBAL_NOTI_OPEN:
      newState = {
        ...state,
        notiQueue: [...state.notiQueue, action.payload]
      };
      break;
    default:
      newState = {
        ...state
      };
  }
  return newState;
};
export default programReducer;
