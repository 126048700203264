import { combineReducers } from 'redux';
import alliesReducer from './AlliesReducer/allies.reducer';
import financialReducer from './FinancialReducer/financial.reducer';
import reportsReducer from './ReportsReducer/reports.reducer';
import {
  MASTERHQ_ALLIES_REDUCER,
  MASTERHQ_FINANCIAL_REDUCER,
  MASTERHQ_REPORTS
} from './masterhq.constants';

export default combineReducers({
  [MASTERHQ_ALLIES_REDUCER]: alliesReducer,
  [MASTERHQ_FINANCIAL_REDUCER]: financialReducer,
  [MASTERHQ_REPORTS]: reportsReducer
});
