/* eslint-disable no-else-return */
import React, { useContext, useEffect } from 'react';
// import PropType from 'prop-types';
import { makeStyles, Grid, InputLabel } from '@material-ui/core';
import { VanguardMem } from 'src/Context/VanguardMembershipContext';
import {
  setTouched,
  setError,
  setUserData
} from 'src/Context/VanguardMembership/VanguardMembership.actions';
import { useSelector } from 'react-redux';
import { deviceDetect } from 'react-device-detect';
import clsx from 'clsx';
import {
  validEmailRegEx,
  isPhoneNumberInvalid,
  isValidPostal,
  checkValidString,
  checkEmail,
  validMobile
} from 'src/utils/validations';
// import { ReactComponent as BackArrow } from '../../assets/image/Vanguard/icon_back_vanguard.svg';
import JBDatePicker from 'src/views/common/FormControl/JBDatePicker';
import { ReactComponent as DownIcon } from '../../../../assets/image/Vanguard/downIcon.svg';
import FormControl from '../../../common/FormControl/formControl';
import CustomAutocomplete from '../../../common/CustomAutocomplete/commonAutocomplete';

const useStyles = makeStyles(() => ({
  mainTitle: {
    fontWeight: '500',
    fontSize: '48px',
    lineHeight: '44px',
    color: '#FFFFFF',
    fontFamily: 'DDC Hardware',
    textAlign: 'center',
    margin: '38px 0 0 0',
    '@media (max-width: 992px)': {
      fontSize: '35px'
    }
  },

  cutom_select_Label: {
    fontFamily: 'DDC Hardware',
    fontStyle: 'normal',
    fontWeight: '500;',
    fontSize: '22px',
    lineHeight: '32px',
    letterSpacing: '0.2px',
    color: '#FFFFFF',
    '&:focus ': {
      outline: '0'
    },
    '& .MuiFormLabel-asterisk': {
      color: 'transparent'
    },
    '& .MuiFormLabel-asterisk:after': {
      content: '""',
      background: '#2CE9DA',
      display: 'inline-block',
      position: 'relative',
      width: '8px',
      height: '8px',
      borderRadius: '50%',
      top: '-14px',
      right: '10px'
    }
  },
  cutom_vanguard_FormControl: {
    '& .fd-input input': {
      background: 'rgba(196, 196, 196, 0.05)',
      color: '#fff',
      borderRadius: '6px',
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '20px',
      height: '45px',
      border: '2px solid #C8C8C8',
      padding: '8px 20px 8px',
      '&::placeholder ': {
        color: '#fff',
        fontWeight: 'bold',
        fontSize: '15px'
      }
    },
    '&  .MuiPaper-root': {
      backgroundColor: '#2d2d2d',
      marginTop: '0',
      marginBottom: '0',
      border: '2px solid #C8C8C8',
      borderTop: 'transparent',
      boxShadow: 'none',
      // borderRadius: '0',
      borderTopLeftRadius: '0',
      borderTopRightRadius: '0'
    },
    '& .MuiAutocomplete-option': {
      color: '#fff',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '20px',
      height: '66px'
    },
    '& .MuiAutocomplete-popper': {
      width: '100% !important',
      marginTop: '-2px'
    },
    '& .SelectDrop[aria-expanded="true"]': {
      borderBottom: '2px solid transparent',
      borderBottomLeftRadius: '0',
      borderBottomRightRadius: '0'
    }
  },
  cutom_vanguard_Drop: {
    '& .MuiAutocomplete-popper ': {
      width: 'calc(100% - 16px) !important'
    }
  },
  cutom_vanguard_Autocomplete: {
    background: 'rgba(196, 196, 196, 0.05)',
    border: '2px solid #C8C8C8',
    color: '#fff',
    borderRadius: '6px',
    '& .cutom_select div': {
      minHeight: '62px'
    },
    '& .cutom_select div input ': {
      color: '#ffffff !important',
      fontWeight: 'bold',
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontSize: '20px'
    },
    '& .MuiAutocomplete-endAdornment': {
      minHeight: ' auto !important',
      top: 'calc(50% - 12px)'
    }
  },
  VanguardCutom_date: {
    '& .MuiInput-root': {
      color: '#fff',
      height: '65px',
      fontWeight: '600',
      fontSize: '20px',
      lineHeight: '18px',
      marginTop: '5px !important',
      border: '2px solid #C8C8C8',
      borderRadius: '6px'
    }
  }
}));

// export const Data = [
//   { id: 0, label: 'Male' },
//   { id: 1, label: 'Female' }
// ];

export const Data = [
  { id: 0, label: 'Male', name: 'Male' },
  { id: 1, label: 'Female', name: 'Female' },
  { id: 2, label: 'Non_binary', name: 'Non Binary' },
  { id: 3, label: 'Prefer_not_to_say', name: 'Prefer Not To Say' }
];

const PersonalInfo = () => {
  const classes = useStyles();
  const userAgent = deviceDetect();
  // const { Maintitle } = props;
  // const [data, setData] = useState(null);
  const allCountries = useSelector(
    (state) => state.SettingReducer.countries.data
  );
  const [state, dispatch] = useContext(VanguardMem);
  const {
    personal_info: { user_details, touched, error }
  } = state;
  const handleBlur = (e) => {
    const { name } = e.target;
    dispatch(setTouched(name));
  };
  const handleChangeError = (name, value) => {
    if (name === 'email') {
      if (!value) {
        return 'field required';
      } else if (!validEmailRegEx.test(value)) {
        return 'please enter a valid email';
      } else {
        return null;
      }
    } else if (name === 'phone_num') {
      if (!value) {
        return 'field required';
      } else if (isPhoneNumberInvalid(value)) {
        return 'please enter a valid phone number';
      } else {
        return null;
      }
    } else if (name === 'postal') {
      if (!value) {
        return 'field required';
      } else if (!isValidPostal(value)) {
        return 'please enter a valid postal';
      } else {
        return null;
      }
    } else if (name === 'eme_phone') {
      if (!value) {
        return 'field required';
      } else if (isPhoneNumberInvalid(value)) {
        return 'please enter a valid phone number';
      } else return null;
    } else if (name === 'date') {
      if (!value) return 'field required';
    } else if (name === 'gender') {
      if (!value) return 'field required';
    } else if (name === 'country') {
      if (!value) return 'field required';
    } else {
      // eslint-disable-next-line no-lonely-if
      if (!value) {
        return 'field required';
      } else if (checkValidString(value)) return `please enter a valid ${name}`;
      else return null;
    }
    return null;
  };
  const handleChange = (value, name) => {
    dispatch(setUserData({ name, info: value }));
    if (Object.keys(error).includes(name)) {
      // eslint-disable-next-line no-shadow
      const error = handleChangeError(name, value);
      dispatch(setError({ error, name }));
    }
  };
  useEffect(() => {
    if (user_details.country) {
      const country = allCountries.filter(
        (c) => c.code === user_details.country
      )[0];
      handleChange(country, 'country');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      {/* <Typography variant="h3" className={classes.mainTitle}>
        {Maintitle}
      </Typography> */}
      <Grid
        container
        xs={10}
        justify="center"
        style={{
          margin: '45px auto'
        }}
      >
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={2}
          // style={{ margin: '20px 0 20px' }}
        >
          <Grid item xs={6} className={classes.cutom_vanguard_FormControl}>
            <InputLabel
              htmlFor="age-required"
              required
              className={classes.cutom_select_Label}
              // style={{ margin: '0px 0 5px' }}
            >
              FIRST Name
            </InputLabel>
            <FormControl
              control="input"
              value={user_details.first_name}
              onChange={(e) => handleChange(e.target.value, 'first_name')} // eslint-disable-line
              name="first_name"
              maxLength={50}
              onBlur={handleBlur}
              error={Boolean(touched.first_name && !user_details.first_name)}
              helperText={
                touched.first_name && !user_details.first_name
                  ? 'please enter first name'
                  : null
              }
              style={{
                margin: '0 0'
              }}
            />
          </Grid>
          <Grid item xs={6} className={classes.cutom_vanguard_FormControl}>
            <InputLabel
              htmlFor="age-required"
              required
              className={classes.cutom_select_Label}
              // style={{ margin: '0px 0 5px' }}
            >
              LAST Name
            </InputLabel>
            <FormControl
              control="input"
              value={user_details.last_name}
              onChange={(e) => handleChange(e.target.value, 'last_name')} // eslint-disable-line
              name="last_name"
              maxLength={50}
              onBlur={handleBlur}
              error={Boolean(touched.last_name && !user_details.last_name)}
              helperText={
                touched.last_name && !user_details.last_name
                  ? 'please enter a valid last name'
                  : null
              }
              style={{
                margin: '0 0'
              }}
            />
          </Grid>
          <Grid
            item
            xs={6}
            className={classes.cutom_vanguard_FormControl}
            style={{
              margin: '0px 0 0px'
            }}
          >
            <InputLabel
              htmlFor="age-required"
              required
              className={classes.cutom_select_Label}
              // style={{ margin: '0px 0 5px' }}
            >
              Email
            </InputLabel>
            <FormControl
              control="input"
              value={user_details.email}
              onChange={(e) => handleChange(e.target.value, 'email')}
              onBlur={handleBlur}
              error={Boolean(touched.email && checkEmail(user_details.email))}
              helperText={
                touched.email && checkEmail(user_details.email)
                  ? 'please enter a valid email'
                  : null
              }
              name="email"
              maxLength={50}
              style={{
                margin: '0 0'
              }}
            />
          </Grid>
          <Grid
            item
            xs={6}
            className={clsx(
              classes.cutom_vanguard_FormControl,
              classes.cutom_vanguard_Drop
            )}
            style={{
              position: 'relative'
            }}
          >
            <InputLabel
              htmlFor="age-required"
              required
              className={classes.cutom_select_Label}
              style={{ margin: '0px 0 6px' }}
            >
              GENDER
            </InputLabel>
            <CustomAutocomplete
              // holder="Lead source"
              disablePortal
              value={Data}
              Options="label"
              data={user_details.gender || null}
              popupIcon={<DownIcon />}
              Change={(val) => handleChange(val, 'gender')}
              disableClearable
              // className={classes.cutom_vanguard_Autocomplete}
              className={clsx(
                classes.cutom_vanguard_Autocomplete,
                'SelectDrop'
              )}
              // Change={(val) => {
              //   console.log(val);
              //   setData1({ ...data1, lead_source: val });
              // }}
            />
          </Grid>
          <Grid
            item
            xs={6}
            className={classes.cutom_vanguard_FormControl}
            style={{
              margin: '10px 0'
            }}
          >
            <InputLabel
              htmlFor="age-required"
              className={classes.cutom_select_Label}
              // style={{ margin: '0px 0 5px' }}
            >
              Phone Number
            </InputLabel>
            <FormControl
              control="input"
              inputMode="numeric"
              value={user_details.phone_num}
              onChange={(e) =>
                handleChange(validMobile(e.target.value), 'phone_num')
              }
              eslint-disable-line
              onBlur={handleBlur}
              name="phone_num"
              error={
                // eslint-disable-next-line operator-linebreak
                touched.phone_num &&
                isPhoneNumberInvalid(String(user_details.phone_num))
              }
              helperText={
                // eslint-disable-next-line operator-linebreak
                touched.phone_num &&
                isPhoneNumberInvalid(String(user_details.phone_num))
                  ? 'phone number invalid'
                  : null
              }
              maxLength={50}
              style={{
                margin: '0 0'
              }}
            />
          </Grid>
          <Grid
            item
            xs={6}
            className={classes.cutom_vanguard_FormControl}
            style={{
              margin: '10px 0'
            }}
          >
            <InputLabel
              htmlFor="age-required"
              className={classes.cutom_select_Label}
              // style={{ margin: '0px 0 5px' }}
            >
              DATE OF BIRTH
            </InputLabel>
            <JBDatePicker
              required
              vanguard
              openTo="year"
              value={user_details.date}
              maxDate={new Date()}
              onChange={(val) => handleChange(val, 'date')}
              className={classes.VanguardCutom_date}
            />
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          // justify="center"
          alignItems="center"
          spacing={2}
          style={{ padding: '50px 0 0px' }}
        >
          <Grid
            item
            xs={6}
            className={classes.cutom_vanguard_FormControl}
            style={{
              margin: '10px 0'
            }}
          >
            <InputLabel
              htmlFor="age-required"
              className={classes.cutom_select_Label}
              // style={{ margin: '0px 0 5px' }}
            >
              STREET ADDRESS
            </InputLabel>
            <FormControl
              control="input"
              // inputMode="numeric"
              inputMode={userAgent.os === 'Android' ? '' : 'numeric'}
              value={user_details.address}
              onChange={(e) => handleChange(e.target.value, 'address')} // eslint-disable-line
              name="address"
              onBlur={handleBlur}
              error={Boolean(
                touched.address && checkValidString(user_details.address)
              )}
              helperText={
                touched.address && checkValidString(user_details.address)
                  ? 'please enter a valid address'
                  : null
              }
              maxLength={50}
              style={{
                margin: '0 0'
              }}
            />
          </Grid>
          <Grid
            item
            xs={6}
            className={classes.cutom_vanguard_FormControl}
            style={{
              margin: '10px 0'
            }}
          >
            <InputLabel
              htmlFor="age-required"
              className={classes.cutom_select_Label}
              // style={{ margin: '0px 0 5px' }}
            >
              CITY
            </InputLabel>
            <FormControl
              control="input"
              value={user_details.city}
              onChange={(e) => handleChange(e.target.value, 'city')} // eslint-disable-line
              name="city"
              onBlur={handleBlur}
              error={Boolean(
                touched.city && checkValidString(user_details.city)
              )}
              helperText={
                touched.city && checkValidString(user_details.city)
                  ? 'please enter a valid city'
                  : null
              }
              maxLength={50}
              style={{
                margin: '0 0'
              }}
            />
          </Grid>

          <Grid
            item
            xs={6}
            className={classes.cutom_vanguard_FormControl}
            style={{
              margin: '10px 0'
            }}
          >
            <InputLabel
              htmlFor="age-required"
              className={classes.cutom_select_Label}
              // style={{ margin: '0px 0 5px' }}
            >
              State
            </InputLabel>
            <FormControl
              control="input"
              value={user_details.province}
              onChange={(e) => handleChange(e.target.value, 'province')} // eslint-disable-line
              name="province"
              onBlur={handleBlur}
              error={Boolean(
                touched.province && checkValidString(user_details.province)
              )}
              helperText={
                touched.province && checkValidString(user_details.province)
                  ? 'please enter a valid province'
                  : null
              }
              // label="Description"
              maxLength={50}
              style={{
                margin: '0 0'
              }}
            />
          </Grid>
          <Grid
            item
            xs={6}
            className={clsx(
              classes.cutom_vanguard_FormControl,
              classes.cutom_vanguard_Drop
            )}
            style={{
              position: 'relative'
            }}
          >
            <InputLabel
              htmlFor="age-required"
              required
              className={classes.cutom_select_Label}
              style={{ margin: '0px 0 6px' }}
            >
              COUNTRY
            </InputLabel>
            <CustomAutocomplete
              // holder="Lead source"
              disablePortal
              value={allCountries}
              Options="name"
              data={user_details.country || null}
              popupIcon={<DownIcon />}
              Change={(val) => handleChange(val, 'country')}
              disableClearable
              // className={classes.cutom_vanguard_Autocomplete}
              className={clsx(
                classes.cutom_vanguard_Autocomplete,
                'SelectDrop'
              )}
              // Change={(val) => {
              //   console.log(val);
              //   setData1({ ...data1, lead_source: val });
              // }}
            />
          </Grid>
          <Grid
            item
            xs={6}
            className={classes.cutom_vanguard_FormControl}
            style={{
              margin: '10px 0'
            }}
          >
            <InputLabel
              htmlFor="age-required"
              className={classes.cutom_select_Label}
              // style={{ margin: '0px 0 5px' }}
            >
              POSTAL CODE
            </InputLabel>
            <FormControl
              control="input"
              inputMode="numeric"
              value={user_details.postal}
              onChange={(e) => handleChange(e.target.value, 'postal')} // eslint-disable-line
              name="postal"
              onBlur={handleBlur}
              error={Boolean(
                touched.postal && !isValidPostal(user_details.postal)
              )}
              helperText={
                touched.postal && !isValidPostal(user_details.postal)
                  ? 'please enter a valid postal'
                  : null
              }
              // label="Description"
              maxLength={50}
              style={{
                margin: '0 0'
              }}
            />
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          // justify="center"
          alignItems="center"
          spacing={2}
          style={{ padding: '50px 0 0px' }}
        >
          <Grid
            item
            xs={6}
            className={classes.cutom_vanguard_FormControl}
            style={{
              margin: '10px 0'
            }}
          >
            <InputLabel
              htmlFor="age-required"
              className={classes.cutom_select_Label}
              // style={{ margin: '0px 0 5px' }}
            >
              EMERGENCY CONTACT
            </InputLabel>
            <FormControl
              control="input"
              // value={description}
              value={user_details.eme_contact}
              onBlur={handleBlur}
              name="eme_contact"
              error={checkValidString(user_details.eme_contact)}
              helperText={
                touched.eme_contact &&
                checkValidString(user_details.eme_contact)
                  ? 'please enter a valid name'
                  : null
              }
              onChange={(e) => handleChange(e.target.value, 'eme_contact')} // eslint-disable-line
              maxLength={50}
              style={{
                margin: '0 0'
              }}
            />
          </Grid>
          <Grid
            item
            xs={6}
            className={classes.cutom_vanguard_FormControl}
            style={{
              margin: '10px 0'
            }}
          >
            <InputLabel
              htmlFor="age-required"
              className={classes.cutom_select_Label}
              // style={{ margin: '0px 0 5px' }}
            >
              EMERGENCY CONTACT PHONE
            </InputLabel>
            <FormControl
              control="input"
              inputMode="numeric"
              value={user_details.eme_phone}
              onBlur={handleBlur}
              name="eme_phone"
              error={isPhoneNumberInvalid(String(user_details.eme_phone))}
              helperText={
                touched.eme_phone &&
                isPhoneNumberInvalid(String(user_details.eme_phone))
                  ? 'please enter a valid phone number'
                  : null
              }
              onChange={(e) => handleChange(e.target.value, 'eme_phone')} // eslint-disable-line
              maxLength={50}
              style={{
                margin: '0 0'
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

PersonalInfo.defaultProps = {
  Maintitle: 'PERSONAL INFORMATION'
};
PersonalInfo.propTypes = {
  // Maintitle: PropType.string
};

export default PersonalInfo;
