/* eslint-disable operator-linebreak */
/* eslint-disable indent */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropType from 'prop-types';
import { Box, Grid } from '@material-ui/core';
import { ROUTES } from 'src/constant/Constants';
import { withStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import moment from 'moment';
import FormControl from 'src/views/common/FormControl/formControl';
import JBButton from 'src/views/common/JBButton/JBButton';
import { getCalenderData } from 'src/Redux/CalenderReducer/Calender.actions';
import { checkClassName } from 'src/utils/someCommon';
import { checkValidString } from 'src/utils/validations';
import { openNoti } from 'src/Redux/global/global.actions';
import usePrevious from 'src/views/common/CustomHooks/usePrevious';
import LinearProgress from '@material-ui/core/LinearProgress';
import { getErrorMsg } from 'src/utils/ErrorHandling';
import {
  getReiniateClass,
  updateSigClass
} from 'src/services/classModule.services';
import { get, set } from 'js-cookie';
import DeleteDialog from './DialogDelete';
import CancelDialog from './DialogCancel';
import TimeChangeEditDiag from './TimeChangeEditDiag';
import CoachSetDiag from './CoachSetting';
import ReservDialog from './RservDialog';
import dEdit from '../../../assets/image/dEdit.png';
import dDelete from '../../../assets/image/dDelete.png';
import dcloseicon from '../../../assets/image/closeicon.png';
import styles from './Dialog.style';
import { getRepeatEditStr, getUpdateDiag } from './Dialog.utils';
import { Diags } from './Dialog.const';

function DialogEdit(props) {
  const { classes, data, onClose, OpenNotiDisp, reloadCalender } = props;
  const [editData, setData] = useState(data);
  const [editing, setEdit] = useState(0);
  const [saved, setSaved] = useState(false);
  const [processReinstate, setProcessReinstate] = useState(false);
  const history = useHistory();
  const roleid = JSON.parse(get('jbrole') || '{}')?.role_id || 3;
  // Edit Dialog Box
  const isEditable =
    data.start_time.getTime() > new Date().getTime() &&
    data.status === 1 &&
    roleid <= 2;

  const prevData = usePrevious(editData);
  useEffect(() => {
    if (
      prevData &&
      !checkClassName(editData.class_name, editData.program_name)
    ) {
      setSaved(true);
    }
  }, [editData.class_name]); // eslint-disable-line

  const handleReInitiate = () => {
    setProcessReinstate(true);
    getReiniateClass(editData.id)
      .then(() => reloadCalender())
      .then(() => OpenNotiDisp('Successfully Reinitiate the Class', 'info'))
      .then(() => onClose())
      .then(() => setProcessReinstate(false))
      .catch((err) => {
        OpenNotiDisp(getErrorMsg(err), 'error');
        setProcessReinstate(false);
      });
  };

  const handleSaved = () => {
    const value = getUpdateDiag(editData);
    if (!value) {
      if (checkValidString(editData.class_name)) {
        OpenNotiDisp('Please provide valid class name', 'error');
      } else OpenNotiDisp('Invalid Data Something went wrong', 'error');
    } else {
      updateSigClass(value, editData.id)
        .then(() => reloadCalender())
        .then(() => setSaved(false))
        .then(() => OpenNotiDisp('Successfully updated the class', 'info'))
        .catch((err) => OpenNotiDisp(getErrorMsg(err), 'error'));
    }
  };

  const handleCreateWod = () => {
    const values = {
      pid: editData.program_id,
      date: moment(editData.start_time).format('MM/DD/YYYY'),
      class_id: editData.id
    };

    set('create_workout', values, {
      expires: moment()
        .add(10, 'minutes')
        .toDate()
    });
    history.push(ROUTES.WORKOUT_CREATE_VIEW);
  };

  return (
    <div>
      {editing === Diags.RESERVATION_SETTING && (
        <ReservDialog
          onClose={onClose}
          goBack={() => setEdit(Diags.EDIT_CLASS)}
          data={editData}
          getUpdate={(val) => setData(val)}
        />
      )}
      {editing === Diags.COACH_SETTING && (
        <CoachSetDiag
          goBack={() => setEdit(Diags.EDIT_CLASS)}
          values={editData}
          onClose={onClose}
          getUpdate={(val) => setData(val)}
        />
      )}
      {editing === Diags.CANCEL_CLASS && (
        <CancelDialog
          goBack={() => setEdit(Diags.TIME_CHANGE)}
          values={editData}
          onClose={onClose}
        />
      )}
      {editing === Diags.DELETE_CLASS && (
        <DeleteDialog
          goBack={() => setEdit(Diags.EDIT_CLASS)}
          values={editData}
          onClose={onClose}
        />
      )}
      {editing === Diags.TIME_CHANGE && (
        <TimeChangeEditDiag
          values={editData}
          backtoClass={() => setEdit(Diags.EDIT_CLASS)}
          onClose={onClose}
          getUpdate={(val) => setData(val)}
        />
      )}
      {editing === Diags.EDIT_CLASS && (
        <div style={{ width: '280px' }} className={classes.dmainbk}>
          <Grid alignItems="center" container direction="row">
            <Grid item xs>
              <DialogTitle
                id="alert-dialog-title"
                color="primary"
                className={classes.titlebk}
              >
                <h4 className={classes.dtitle}>
                  {editData.status === 0 ? 'Cancelled Class' : 'Edit Class'}
                </h4>
              </DialogTitle>
            </Grid>

            <Grid item style={{ textAlign: 'right', marginRight: '10px' }}>
              {editData.status === 1 && roleid <= 2 ? (
                <span>
                  <RouterLink
                    to={{
                      pathname: ROUTES.CLASSES_EDIT,
                      search: `Classid=${editData.id}`
                    }}
                    className={classes.tableLink}
                  >
                    <Button className={classes.btns}>
                      <img alt="img" src={dEdit} />
                    </Button>
                  </RouterLink>
                  <Button
                    className={classes.btns}
                    onClick={() => setEdit(Diags.DELETE_CLASS)}
                  >
                    <img alt="img" src={dDelete} />
                  </Button>
                </span>
              ) : null}
              <Button className={classes.btns} onClick={() => onClose()}>
                <img alt="img" src={dcloseicon} />
              </Button>
            </Grid>
          </Grid>

          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Box display="block">
                <label className={classes.dlabel}>Program</label>
                <h3 className={classes.dh3}>{editData.program_name}</h3>
              </Box>
              <Box display="block" mt={1} mb={1}>
                <label className={classes.dlabel}>Workout</label>
                {!editData.workout_name && isEditable ? (
                  <Link
                    component="button"
                    onClick={handleCreateWod}
                    className={classes.dLink}
                  >
                    Create
                  </Link>
                ) : null}
                <h3 className={classes.dh3} style={{ color: '#FF7723' }}>
                  {editData.workout_name || 'No Workout'}
                </h3>
              </Box>
              <Box display="block" mt={1} mb={1}>
                <label className={classes.dlabel}>Date & time</label>
                {isEditable && (
                  <Link
                    component="button"
                    onClick={() => setEdit(Diags.TIME_CHANGE)}
                    className={classes.dLink}
                  >
                    Edit
                  </Link>
                )}
                <h3 className={classes.dh3}>
                  {moment(editData.start_time).format('MMM DD,') +
                    ' ' +
                    moment(editData.start_time).format('LT') +
                    ' - ' +
                    moment(editData.start_time).format('MMM DD,') +
                    ' ' +
                    moment(editData.end_time).format('LT')}
                </h3>
                {editData.is_recurring ? (
                  <label className={classes.dlabel}>
                    {getRepeatEditStr(editData)}
                  </label>
                ) : null}
              </Box>
              <Box display="block" mt={1} mb={1}>
                <FormControl
                  control="input"
                  label="Name"
                  value={editData.class_name}
                  disabled={!isEditable}
                  onChange={(e) =>
                    setData({ ...editData, class_name: e.target.value })
                  } // eslint-disable-line
                  required
                  style={{ margin: '0 0' }}
                />
              </Box>

              <Box display="block" mt={1} mb={1}>
                <label className={classes.dlabel}>Coach</label>
                <Link
                  component="button"
                  onClick={() => setEdit(Diags.COACH_SETTING)}
                  className={classes.dLink}
                >
                  {isEditable ? 'Edit' : 'View'}
                </Link>

                {editData.co_coach && (
                  <span style={{ fontSize: '12px' }}> +1</span>
                )}
                <h3 className={classes.dh3}>
                  {editData.head_coach
                    ? editData.head_coach.name
                    : 'No Coach Selected'}
                </h3>
              </Box>
              <Box display="block" mt={1} mb={1}>
                <label className={classes.dlabel}>Reservations</label>
                {/* {isEditable && ( */}
                <Link
                  component="button"
                  onClick={() => setEdit(Diags.RESERVATION_SETTING)}
                  className={classes.dLink}
                >
                  View
                </Link>
                <h3 className={classes.dh3}>
                  {editData.is_limit
                    ? `${editData.class_data_reservation} of ${editData.class_limit}`
                    : `Total Reservation is ${editData.class_data_reservation}`}
                </h3>
                <LinearProgress
                  variant="determinate"
                  value={
                    !editData.is_limit
                      ? 0
                      : (editData.get_attendance.length /
                          editData.class_limit) *
                          100 >
                        100
                      ? 100
                      : (editData.get_attendance.length /
                          editData.class_limit) *
                        100
                  }
                  className={classes.dprogress}
                />
              </Box>
              {data.start_time > new Date() && roleid <= 2 ? (
                <div>
                  <Box display="block" mt={2} mb={1}>
                    {editData.status === 0 ? (
                      <JBButton
                        type="outlined"
                        fullWidth
                        onClick={handleReInitiate}
                        disabled={processReinstate}
                      >
                        Reinstate Class
                      </JBButton>
                    ) : (
                      <JBButton
                        type="outlined"
                        fullWidth
                        onClick={() => setEdit(Diags.CANCEL_CLASS)}
                      >
                        Cancel Class
                      </JBButton>
                    )}
                  </Box>
                  {editData.status === 0 && (
                    <Box display="block" mt={2} mb={1}>
                      <JBButton
                        type="delete"
                        fullWidth
                        onClick={() => setEdit(Diags.DELETE_CLASS)}
                      >
                        Delete Class
                      </JBButton>
                    </Box>
                  )}
                  {saved && (
                    <Box display="block" mt={2} mb={1}>
                      <JBButton type="outlined" fullWidth onClick={handleSaved}>
                        Save Changes
                      </JBButton>
                    </Box>
                  )}
                </div>
              ) : null}
            </DialogContentText>
          </DialogContent>
        </div>
      )}
    </div>
  );
}

DialogEdit.propTypes = {
  classes: PropType.object.isRequired,
  data: PropType.object.isRequired,
  OpenNotiDisp: PropType.func.isRequired,
  reloadCalender: PropType.func.isRequired,
  onClose: PropType.func.isRequired
};

const mapToDispatch = (dispatch) => ({
  reloadCalender: () => dispatch(getCalenderData()),
  OpenNotiDisp: (msg, type) => dispatch(openNoti(msg, type))
});

export default connect(null, mapToDispatch)(withStyles(styles)(DialogEdit));
