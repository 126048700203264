/* eslint-disable arrow-parens */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Link, Route, Switch } from 'react-router-dom';
import {
  AppBar,
  Breadcrumbs,
  Button,
  Grid,
  Tab,
  Tabs,
  Typography
} from '@material-ui/core';
import { get } from 'js-cookie';
import MembershipList from './MembershipTable';
import SessionTable from './Session.Table';
import Plusicon from '../../../assets/image/plusicon.png';
import styles from './ListView.style';
import { ROUTES } from '../../../constant/Constants';
import LoadingView from '../../common/loading';
import {
  getDropIn,
  getplanMem,
  getSession
} from '../../../Redux/MembershipReducer/MembershipReducer.actions';
import DropinTable from './Dropin.Table';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}
class MembershipsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fetched: false
    };
  }

  componentDidMount() {
    const { getMemberships, getSessions, getDropIns } = this.props;
    getMemberships();
    getSessions();
    getDropIns();
    this.setState({ fetched: true });
  }

  render() {
    const { fetched } = this.state;
    const roleid = JSON.parse(get('jbrole') || '{}')?.role_id || 3;

    if (!fetched) return <LoadingView />;

    const { classes, location } = this.props;

    // const Tabslocation = !match.isExact ? 1 : 0;

    const getActiveVal = (pathname) => {
      switch (pathname) {
        case ROUTES.CLASSES_MEMBERSHIPS:
          return 0;
        case ROUTES.CLASSES_SESSION_TEMPLATE:
          return 1;
        case ROUTES.CLASSES_DROPIN:
          return 2;
        default:
          return 0;
      }
    };

    const Tabslocation = getActiveVal(location.pathname);

    return (
      <div className={classes.root}>
        <div className="title-bk">
          <div className={classes.new_programs} style={{ marginBottom: 15 }}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={0}
            >
              <Grid item xs={6}>
                <Breadcrumbs aria-label="breadcrumb">
                  <div>Classes</div>
                  <Link color="inherit" to={ROUTES.CLASSES_MEMBERSHIPS}>
                    Memberships
                  </Link>
                  <Typography variant="h3" color="Primary">
                    {/* {match.isExact ? (
                      <span>plan templates</span>
                    ) : (
                      <span>session templates</span>
                    )} */}
                    {(() => {
                      switch (Tabslocation) {
                        case 0:
                          return <span>plan templates</span>;
                        case 1:
                          return <span>session templates</span>;
                        case 2:
                          return <span>Drop In templates</span>;
                        default:
                          return null;
                      }
                    })()}
                  </Typography>
                </Breadcrumbs>
              </Grid>
              <Grid item xs={6} alignItems="center">
                {roleid === 1 && (
                  <Button
                    color="primary"
                    className={classes.button}
                    component={Link}
                    // to={ROUTES.CLASSES_DROPIN_CREATE}
                    // to={
                    //   match.isExact
                    //     ? ROUTES.CLASSES_PLAN_MEMBERSHIP_CREATE
                    //     : ROUTES.CLASSES_PLAN_SESSION_CREATE
                    // }
                    to={(() => {
                      switch (Tabslocation) {
                        case 0:
                          return ROUTES.CLASSES_PLAN_MEMBERSHIP_CREATE;
                        case 1:
                          return ROUTES.CLASSES_PLAN_SESSION_CREATE;
                        case 2:
                          return ROUTES.CLASSES_DROPIN_CREATE;
                        default:
                          return null;
                      }
                    })()}
                  >
                    <img
                      alt="Plusicon"
                      src={Plusicon}
                      style={{ marginRight: 10 }}
                    />
                    <span>
                      {/* new {!match.isExact ? 'Session' : 'Plan'} Template */}
                      new
                      {(() => {
                        switch (Tabslocation) {
                          case 0:
                            return <span> plan </span>;
                          case 1:
                            return <span> session </span>;
                          case 2:
                            return <span> Drop In </span>;
                          default:
                            return null;
                        }
                      })()}
                      Template
                    </span>
                  </Button>
                )}
              </Grid>
            </Grid>
          </div>
        </div>
        <div className={classes.mainCard}>
          <AppBar
            position="static"
            style={{
              backgroundColor: 'transparent',
              boxShadow: 'none'
            }}
          >
            <Tabs
              value={Tabslocation}
              indicatorColor="primary"
              textColor="primary"
              color="primary"
              fullWidth
            >
              <Tab
                label=" Plan Templates"
                component={Link}
                to={ROUTES.CLASSES_MEMBERSHIPS}
                className={classes.p_tabs}
                {...a11yProps(0)} // eslint-disable-line
              />
              <Tab
                label="Session Templates"
                component={Link}
                to={ROUTES.CLASSES_SESSION_TEMPLATE}
                className={classes.p_tabs}
                {...a11yProps(1)} // eslint-disable-line
              />
              <Tab
                label="Drop In Templates"
                component={Link}
                to={ROUTES.CLASSES_DROPIN}
                className={classes.p_tabs}
                {...a11yProps(2)} // eslint-disable-line
              />
            </Tabs>
          </AppBar>
          <Switch>
            <Route path={ROUTES.CLASSES_DROPIN} component={DropinTable} />
            <Route
              path={ROUTES.CLASSES_SESSION_TEMPLATE}
              component={SessionTable}
            />
            <Route
              path={ROUTES.CLASSES_MEMBERSHIPS}
              component={MembershipList}
            />
          </Switch>
        </div>
      </div>
    );
  }
}

MembershipsList.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.any.isRequired,
  getMemberships: PropTypes.func.isRequired,
  getSessions: PropTypes.func.isRequired,
  location: PropTypes.any.isRequired,
  getDropIns: PropTypes.func.isRequired
};

const mapToDispatch = (dispatch) => ({
  getMemberships: () => dispatch(getplanMem()),
  getSessions: () => dispatch(getSession()),
  getDropIns: () => dispatch(getDropIn())
});

export default connect(
  null,
  mapToDispatch
)(withStyles(styles, { withTheme: true })(MembershipsList));
