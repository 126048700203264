import React, { useContext, useState } from 'react';
import { connect } from 'react-redux';
import { Grid, withStyles } from '@material-ui/core';
import PropType from 'prop-types';
import { MembershipTemplate as MembershipContext } from 'src/Context/MembershipTemplate/MembershipTemplateContext';
import { SessionTemplateContext } from 'src/Context/MembershipTemplate/SessionTemplate.Context';
import '../CutomSelect.css';
import JBButton from 'src/views/common/JBButton/JBButton';
import { changecontractid } from 'src/Context/MembershipTemplate/membershiptemplate.actions';
import { getContracts } from 'src/Redux/SettingReducer/SettingReducer.actions';
import RadioButtonTable from '../../../common/RadioButtonTable/RadioButtonTable';
import { RadiotableHead } from '../staticDataRadio';
import styles from '../MembershipTemplate.style';
import LoadingView from '../../../common/loading';
import Plusicon from '../../../../assets/image/plusicon.png';
import NewContractDialog from './NewContractDialog';

const ContractTemplatesStep = ({
  Contracts: { isFetching, data: condata, error },
  getContractsUpdate,
  session
}) => {
  const [openDialog, setopenDialog] = useState({
    id: null,
    open: false
  });

  // eslint-disable-next-line no-unused-vars
  const [_, dispatch] = useContext(
    !session ? MembershipContext : SessionTemplateContext
  );

  if (error) return <p>Something Went Wrong</p>;
  if (isFetching) return <LoadingView />;

  const handleReload = () => {
    setopenDialog({ id: null, open: false });
    getContractsUpdate();
  };

  return (
    <div>
      <h3 style={{ marginBottom: '20px' }}>
        Assign a contract template to this membership template
      </h3>

      <Grid container spacing={0} xs={12} disableGutters direction="row">
        <Grid item xs={12} style={{ marginTop: '0px', marginBottom: '20px' }}>
          {condata.length > 0 ? (
            <RadioButtonTable
              values={condata}
              tableHead={RadiotableHead}
              getData={(val) => dispatch(changecontractid(val))}
            />
          ) : (
            <div>
              <JBButton
                type="primary"
                isImg={Plusicon}
                onClick={(e) => {
                  e.stopPropagation();
                  setopenDialog({ id: null, open: true });
                }}
              >
                create new contract
              </JBButton>
              <NewContractDialog
                open={openDialog.open}
                setClose={() => {
                  setopenDialog({ id: null, open: false });
                }}
                reloadFunc={handleReload}
              />
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

ContractTemplatesStep.defaultProps = {
  session: false
};

ContractTemplatesStep.propTypes = {
  Contracts: PropType.object.isRequired,
  getContractsUpdate: PropType.func.isRequired,
  session: PropType.bool
};

const mapToState = (state) => ({
  Contracts: state.SettingReducer.pubContracts
});

const mapToDispatch = (dispatch) => ({
  getContractsUpdate: () => dispatch(getContracts())
});
export default connect(
  mapToState,
  mapToDispatch
)(withStyles(styles)(ContractTemplatesStep));
