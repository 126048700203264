// import {
//   getNonReccEdit,
//   getReccurEdit
// } from 'src/views/calendar/Class/Class.utils';
import authhttp from './authHttp.services';

export const createOneTime = async (values) => {
  const OneTimeUrl = 'api/clasess';
  const { data } = await authhttp.post(OneTimeUrl, values);
  return data;
};

export const createReccClass = async (values) => {
  const ReccClassUrl = 'api/recurring_class';
  const { data } = await authhttp.post(ReccClassUrl, values);
  return data;
};

export const getOneTimeClass = async (val) => {
  const OneTimeUrl = 'api/clasess';
  // eslint-disable-next-line
  try {
    const {
      data: { data }
    } = await authhttp.get(OneTimeUrl, {
      params: {
        ...val
      }
    });
    return data;
  } catch (err) {
    throw err;
  }
};

export const getReccClassList = async (val) => {
  const ReccUrl = 'api/recurring_class';
  const {
    data: { data }
  } = await authhttp.get(ReccUrl, {
    params: {
      ...val
    }
  });
  return data;
};

export const getCoachData = async () => {
  const CoachUrl = 'api/get_coaches';
  const {
    data: { data }
  } = await authhttp.get(CoachUrl);
  return data;
};

export const getOneTimeEdit = async (id) => {
  const editUrl = `api/clasess/${id}/edit`;

  const {
    data: { data }
  } = await authhttp.get(editUrl);
  return data;
};

export const getReccEdit = async (id) => {
  const ReccUrl = `api/recurring_class/${id}/edit`;
  const {
    data: { data }
  } = await authhttp.get(ReccUrl);
  return data;
};

export const updateSigClass = async (values, id) => {
  const updateUrl = `api/clasess/${id}`;
  const data = await authhttp.put(updateUrl, values);
  return data;
};

export const updateReccClass = async (values, id) => {
  const updateUrl = `api/recurring_class/${id}`;
  const data = await authhttp.put(updateUrl, values);
  return data;
};

export const deleteClasses = async (values) => {
  const DelUrl = 'api/deleteclass';
  const updated = {
    id: values.join(','),
    future_class: 0
  };
  const data = await authhttp.post(DelUrl, updated);
  return data;
};

export const cancelClasses = async (val) => {
  const cancelUrl = 'api/cancelclass';
  const updated = {
    id: val.join(',')
  };
  const data = await authhttp.post(cancelUrl, updated);
  return data;
};

export const DelSingleClass = async (id) => {
  const DelUrl = `api/clasess/${id}`;
  const data = await authhttp.del(DelUrl);
  return data;
};

export const DelRecurrClass = async (id) => {
  const DelUrl = `api/recurring_class/${id}`;
  const data = await authhttp.del(DelUrl);
  return data;
};

export const getCoachesDetail = async (ids) => {
  const getCoachUrl = 'api/change_coach_in_classes';
  const {
    data: { data }
  } = await authhttp.get(getCoachUrl, {
    params: {
      class_ids: ids.join(',')
    }
  });
  return data;
};

export const changeBulkCoach = async (values) => {
  const changeCoachUrl = 'api/change_coach_in_classes';
  const data = await authhttp.post(changeCoachUrl, values);
  return data;
};

export const getClassSetting = async () => {
  const SetUrl = 'api/settings/class/edit';
  const {
    data: {
      data: { meta }
    }
  } = await authhttp.get(SetUrl);
  return meta;
};

export const getCalender = async (values) => {
  const CalUrl = 'api/get_calender_data';
  const {
    data: { data }
  } = await authhttp.get(CalUrl, {
    params: {
      ...values
    }
  });
  return data;
};

export const getReiniateClass = async (id) => {
  const ReiniateUrl = 'api/reinstateclass';
  const data = await authhttp.post(ReiniateUrl, {
    id
  });
  return data;
};

export const getCancelClass = async (values) => {
  const CancelClasUrl = 'api/cancelclass';
  const data = await authhttp.post(CancelClasUrl, values);
  return data;
};

export const DeleteSingleClass = async (val) => {
  const DelUrl = 'api/deleteclass';
  const data = await authhttp.post(DelUrl, val);
  return data;
};
