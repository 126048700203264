import { getValuefromObject } from 'src/utils/someCommon';

export const getFilter = (id, data, selected) => {
  switch (id) {
    case 1:
      return {
        filter_by_waiver: getValuefromObject(data),
        waiver_operator: getValuefromObject(selected)
      };
    case 2:
      return {
        filter_by_member_status: getValuefromObject(data),
        member_status_operator: getValuefromObject(selected)
      };
    case 3:
      return {
        filter_by_lead_status: getValuefromObject(data),
        lead_status_operator: getValuefromObject(selected)
      };

    default:
      return null;
  }
};

export const getfilterLabel = (id, data, selected) => {
  switch (id) {
    case 1:
      return {
        id,
        name: `Published Waiver ${Object.values(selected)[1]} ${
          Object.values(data)[1]
        }`,
        filter: 'filter_by_waiver',
        connetor: 'waiver_operator'
      };
    case 2:
      return {
        id,
        name: `Member Status ${Object.values(selected)[1]} ${
          Object.values(data)[1]
        }`,
        filter: 'filter_by_member_status',
        connetor: 'member_status_operator'
      };
    case 3:
      return {
        id,
        name: `Lead Status ${Object.values(selected)[1]} ${data.label}`,
        filter: 'filter_by_lead_status',
        connetor: 'lead_status_operator'
      };

    default:
      return null;
  }
};

export const checkData = (filter, data) => {
  if (!filter || !data || String(data).includes('Invalid')) {
    return true;
  }
  return false;
};
