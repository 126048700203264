import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import EditTabsTemplate from 'src/views/communication/automatedemails/EditTabsTemplate';
import ClassesEditTabsTemplate from 'src/views/communication/automatedemails/Classes/ClassesEditTabsTemplate';
import CreditCardExpiring from 'src/views/communication/automatedemails/General/CreditCardExpiring';
import FailedPayments from 'src/views/communication/automatedemails/General/FailedPayments';
import MembershipRenewalReminder from 'src/views/communication/automatedemails/General/MembershipRenewalReminder';
import PaymentInformationMissing from 'src/views/communication/automatedemails/General/PaymentInformationMissing';
import WelcomeEmail from 'src/views/communication/automatedemails/General/WelcomeEmail';
import ClassReinstated from 'src/views/communication/automatedemails/Classes/ClassReinstated';
import ExpiringPlans from 'src/views/communication/automatedemails/Classes/ExpiringPlans';
import ExpiringSessions from 'src/views/communication/automatedemails/Classes/ExpiringSessions';
import MissingMember from 'src/views/communication/automatedemails/Classes/MissingMember';
// import AnonymousTabsTemplate from 'src/views/communication/automatedemails/AnonymousTabsTemplate';
import automatedemails from 'src/views/communication/automatedemails/automatedemails';
import ComSettings from 'src/views/communication/settings/ComSettings';

// eslint-disable-next-line react/prefer-stateless-function
class CommunicationContainer extends Component {
  render() {
    const {
      match: { url }
    } = this.props;
    return (
      <Switch>
        <Route
          path={`${url}/automatedemails/general/memberpayments`}
          component={EditTabsTemplate}
        />
        <Route
          path={`${url}/automatedemails/classes/classcancelled`}
          component={ClassesEditTabsTemplate}
        />
        <Route
          path={`${url}/automatedemails/general/creditcardexpiring`}
          component={CreditCardExpiring}
        />
        <Route
          path={`${url}/automatedemails/general/failedpayments`}
          component={FailedPayments}
        />
        <Route
          path={`${url}/automatedemails/general/renewalreminder`}
          component={MembershipRenewalReminder}
        />
        <Route
          path={`${url}/automatedemails/general/paymentinformationmissing`}
          component={PaymentInformationMissing}
        />
        <Route
          path={`${url}/automatedemails/general/welcomeemail`}
          component={WelcomeEmail}
        />
        <Route
          path={`${url}/automatedemails/classes/classreinstated`}
          component={ClassReinstated}
        />
        <Route
          path={`${url}/automatedemails/classes/expiringplans`}
          component={ExpiringPlans}
        />
        <Route
          path={`${url}/automatedemails/classes/expiringsessions`}
          component={ExpiringSessions}
        />
        <Route
          path={`${url}/automatedemails/classes/missingmember`}
          component={MissingMember}
        />
        <Route path={`${url}/automatedemails`} component={automatedemails} />
        <Route path={`${url}/setting`} component={ComSettings} />
      </Switch>
    );
  }
}
CommunicationContainer.propTypes = {
  match: PropTypes.object.isRequired
};
export default CommunicationContainer;
