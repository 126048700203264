export const TobeFilter = [
  { id: 1, filter: 'Day of Week' },
  { id: 2, filter: 'Reccuring' },
  { id: 3, filter: 'Program' },
  { id: 4, filter: 'Date Range' }
];

export const ReccurFilter = [
  { id: 0, starred: 'is Not Reccuring' },
  { id: 1, starred: 'is Reccuring' }
];

export const DayofWeekData = [
  { id: 0, name: 'Monday' },
  { id: 1, name: 'Tuesday' },
  { id: 2, name: 'Wednesday' },
  { id: 3, name: 'Thursday' },
  { id: 4, name: 'Friday' },
  { id: 5, name: 'Saturday' },
  { id: 6, name: 'Sunday' }
];

export const isNotData = [
  { id: 0, name: 'is Not' },
  { id: 1, name: 'is' }
];

export const ReccFilter = [{ id: 3, filter: 'Program' }];
