/* eslint-disable operator-linebreak */
/* eslint-disable indent */
import React, { useState } from 'react';
import {
  Grid,
  withStyles,
  FormControlLabel,
  InputLabel
} from '@material-ui/core';
import PropType from 'prop-types';
import { MemberProContext } from 'src/Context/MemberProContext';
import moment from 'moment';
import JBDatePicker from 'src/views/common/FormControl/JBDatePicker';
import IOSSwitch from 'src/views/common/FormControl/IOSSwitch';
import { EverWeekData } from 'src/views/memberships/PaymentPlan/PaymentPlan.data';
// import useGymSettingReducer from 'src/views/common/CustomHooks/useGymSettingRefresh';
import clsx from 'clsx';
import {
  AllAttendanceLmt,
  AllDayType,
  AllSessionlmt,
  SessionDay
} from 'src/views/memberships/MembershipTemplate/Membership.data';
import FormControl from 'src/views/common/FormControl/formControl';
import CustomAutoComplete from '../../../../common/CustomAutocomplete/commonAutocomplete';
import styles from './NewTemplate.style';
import {
  changeAttlmt,
  changebilled,
  changeDate,
  changeinitials,
  chngeAtttype,
  chngeAttuses,
  chngeautorenew,
  chngeautorenwal,
  chngenofsess,
  chngerenewbilled,
  chngeusedsess
} from '../Memberships/NewMembership/NewMembership.actions';
import PaymentOptionTable from '../Memberships/NewMembership/AllTable/PaymentOptionTable';
import AutoRenewOptionTable from '../Memberships/NewMembership/AllTable/AutoRenewOptionTable';
import SessionPaymentOptionTable from '../Memberships/NewMembership/AllTable/SessionTable/SessionPaymentOptionTable';
import EditMemberHold from './EditMemberHold';
import DropinPaymentOptionTable from '../Memberships/NewMembership/AllTable/DropInTable/DropinPaymentOptionTable';

const PaymentOptions = ({ classes, reducer, value, start }) => {
  const [state, dispatch] = reducer;
  const isEditable = Boolean(state.data.is_editable);
  const [open, setOpen] = useState(false);
  const [id, setId] = useState(null);
  const [data, setData] = value;
  // const { dateformat } = useGymSettingReducer();
  return (
    <div>
      {data.map((item) => (
        <Grid
          item
          xs={12}
          style={{
            margin: '15px 0'
          }}
        >
          <div
            onClick={() => {
              setOpen(true);
              setId(item);
            }}
            style={{
              display: 'inline-block'
            }}
          >
            <p style={{ cursor: 'pointer' }} className={classes.reminderNoti}>
              This membership has hold on {item.hold_start_date}
              {/* {moment(item.hold_start_date, 'DD-MM-YYYY').format(
                `${dateformat}`
              )} */}
              -
              {/* {moment(item.hold_end_date, 'DD-MM-YYYY').format(`${dateformat}`)} */}
              {item.hold_end_date}
            </p>
          </div>
        </Grid>
      ))}
      {state.data.is_stop_autorenew && state.data.payment_data.auto_renew ? (
        <Grid
          item
          xs={12}
          style={{
            margin: '15px 0'
          }}
        >
          <p className={classes.reminderNoti}>
            This membership will stop auto renewing on{' '}
            {state.data.auto_renew_date}
          </p>
        </Grid>
      ) : null}
      <Grid
        container
        spacing={3}
        xs={12}
        disableGutters
        direction="row"
        style={{
          margin: '30px 0'
        }}
      >
        <Grid
          item
          xs={3}
          style={{
            marginTop: '0px',
            paddingLeft: '0px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start'
          }}
        >
          <h3>Start Date</h3>
          <p className={classes.ptext}>
            Select the date this member plan should start.
          </p>
        </Grid>
        <Grid item xs={9}>
          <Grid
            container
            direction="row"
            spacing={2}
            style={
              {
                // alignItems: 'center'
              }
            }
          >
            <Grid
              item
              xs={4}
              className={classes.time_box}
              style={{ marginTop: '0px' }}
            >
              {isEditable ? (
                <JBDatePicker
                  label="Start Date"
                  required
                  value={state.data.start_date}
                  onChange={(e) => dispatch(changeDate(e))}
                />
              ) : (
                <p>
                  <InputLabel
                    htmlFor="age-required"
                    className={classes.cutom_select_Label}
                    style={{
                      paddingTop: '0'
                    }}
                  >
                    Start Date
                  </InputLabel>
                  {moment(state.data.start_date || new Date()).format(
                    // 'MMMM Do, YYYY'
                    'DD, MMMM YYYY'
                  )}
                </p>
              )}
            </Grid>
            <Grid
              item
              xs={4}
              className={classes.time_box}
              style={{
                marginTop: '0px',
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <InputLabel
                htmlFor="age-required"
                className={classes.cutom_select_Label}
                style={{
                  paddingTop: '0'
                }}
              >
                Payment Plan
              </InputLabel>
              <h4 style={{ marginBottom: '0px', fontWeight: 'normal' }}>
                {state.data.payment_data.name}
              </h4>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* Plan Details */}
      {state.data.type === 'drop_in' ? (
        ''
      ) : (
        <>
          {state.data.type === 'plan' ? (
            <Grid
              container
              direction="row"
              spacing={2}
              style={{
                margin: '40px 0px 0',
                alignItems: 'flex-start'
              }}
            >
              <Grid
                item
                xs={3}
                style={{
                  marginTop: '0px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  paddingLeft: '0'
                }}
              >
                <h3>Plan Details</h3>
                <p className={classes.ptext}>
                  Configure the billing schedule, attendance limit, and initial
                  commitment.
                </p>
              </Grid>
              <Grid item xs={9}>
                <Grid
                  container
                  direction="row"
                  spacing={2}
                  style={{
                    alignItems: 'center'
                  }}
                >
                  <Grid
                    container
                    direction="row"
                    style={{
                      padding: '0 9px'
                    }}
                  >
                    <Grid item style={{ marginRight: '5px' }} xs={4}>
                      <InputLabel
                        htmlFor="age-required"
                        // required
                        className={classes.cutom_select_Label}
                        style={{ padding: '5px 0' }}
                      >
                        Billing day
                      </InputLabel>
                      <h4 style={{ marginBottom: '0px', fontWeight: 'normal' }}>
                        Membership start day
                      </h4>
                    </Grid>
                    {state.data.initial_pricing === 'weekly' && (
                      <Grid item xs={4}>
                        <Grid
                          container
                          direction="row"
                          spacing={2}
                          alignItems="center"
                        >
                          <Grid item xs={9}>
                            <InputLabel
                              htmlFor="age-required"
                              required
                              className={classes.cutom_select_Label}
                              style={{ padding: '5px 0' }}
                            >
                              Member is billed every
                            </InputLabel>
                            {isEditable ? (
                              <CustomAutoComplete
                                holder="Please select week"
                                data={
                                  state.data.payment_data.initial_commitment
                                    .billed_every
                                }
                                value={EverWeekData}
                                Options="name"
                                Change={(e) => dispatch(changebilled(e))}
                              />
                            ) : (
                              <p>
                                {
                                  state.data.payment_data.initial_commitment
                                    ?.billed_every.id
                                }
                              </p>
                            )}
                          </Grid>
                          <Grid item xs={3}>
                            <InputLabel
                              htmlFor="age-required"
                              className={classes.cutom_select_Label}
                              style={{ padding: '3px 0' }}
                            >
                              &nbsp;
                            </InputLabel>
                            <h4>weeks</h4>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    style={{
                      padding: '0 9px'
                    }}
                  >
                    <Grid
                      item
                      xs={4}
                      style={{
                        marginRight:
                          // eslint-disable-next-line no-nested-ternary
                          state.data.payment_data.membership
                            .attendance_limit === null
                            ? '5px'
                            : state.data.payment_data.membership
                                .attendance_limit.id === 0
                            ? '5px'
                            : null,
                        paddingRight:
                          // eslint-disable-next-line no-nested-ternary
                          state.data.payment_data.membership
                            .attendance_limit === null
                            ? '5px'
                            : state.data.payment_data.membership
                                .attendance_limit.id === 0
                            ? '5px'
                            : null,
                        marginBottom: isEditable ? '0px' : '8px'
                      }}
                    >
                      <InputLabel
                        htmlFor="age-required"
                        required
                        className={classes.cutom_select_Label}
                        style={{ padding: '5px 0' }}
                      >
                        Attendance limit
                      </InputLabel>
                      {isEditable ? (
                        <CustomAutoComplete
                          holder="Please select attendance limit"
                          data={
                            state.data.payment_data.membership.attendance_limit
                          }
                          value={AllAttendanceLmt}
                          Options="name"
                          Change={(e) => dispatch(changeAttlmt(e))}
                        />
                      ) : (
                        <p>
                          {
                            state.data.payment_data.membership.attendance_limit
                              ?.name
                          }
                        </p>
                      )}
                    </Grid>
                    {state.data.payment_data.membership.attendance_limit &&
                    state.data.payment_data.membership.attendance_limit.id ===
                      1 ? (
                      // eslint-disable-next-line react/jsx-indent
                      <Grid xs={8}>
                        <Grid
                          container
                          direction="row"
                          alignItems="center"
                          spacing={2}
                        >
                          <Grid
                            item
                            xs={isEditable ? 4 : null}
                            style={{
                              marginLeft: isEditable ? '10px' : '5px'
                            }}
                          >
                            {/* <InputLabel
                          htmlFor="age-required"
                          className={classes.cutom_select_Label}
                          style={{ padding: '5px 0' }}
                        >
                          &nbsp;
                        </InputLabel> */}
                            {isEditable ? (
                              <FormControl
                                control="input"
                                value={
                                  state.data.payment_data.membership
                                    .attendance_limited_meta.uses
                                }
                                onChange={(e) =>
                                  dispatch(chngeAttuses(e.target.value))
                                }
                              />
                            ) : (
                              <>
                                <InputLabel
                                  htmlFor="age-required"
                                  className={classes.cutom_select_Label}
                                  style={{ padding: '5px 0' }}
                                >
                                  &nbsp;
                                </InputLabel>
                                <p>
                                  {
                                    state.data.payment_data.membership
                                      .attendance_limited_meta?.uses
                                  }
                                </p>
                              </>
                            )}
                          </Grid>
                          <Grid
                            item
                            xs={2}
                            style={{
                              textAlign: 'center'
                            }}
                          >
                            <InputLabel
                              htmlFor="age-required"
                              className={classes.cutom_select_Label}
                              style={{
                                padding: isEditable ? '0px 0' : '5px 0'
                              }}
                            >
                              &nbsp;
                            </InputLabel>
                            <h4>times</h4>
                          </Grid>
                          <Grid item xs={4}>
                            <InputLabel
                              htmlFor="age-required"
                              className={classes.cutom_select_Label}
                              style={{
                                padding: isEditable ? '1px 0' : '5px 0'
                              }}
                            >
                              &nbsp;
                            </InputLabel>
                            {isEditable ? (
                              <CustomAutoComplete
                                holder="Please select expired type"
                                value={AllDayType}
                                Options="name"
                                data={
                                  state.data.payment_data.membership
                                    .attendance_limited_meta.type
                                }
                                Change={(e) => dispatch(chngeAtttype(e))}
                              />
                            ) : (
                              <p>
                                {
                                  state.data.payment_data.membership
                                    .attendance_limited_meta?.type.name
                                }
                              </p>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    ) : null}
                    <Grid
                      item
                      xs={isEditable ? '3' : null}
                      className={classes.cutom_input_css}
                    >
                      <InputLabel
                        htmlFor="age-required"
                        required
                        className={classes.cutom_select_Label}
                        style={{ padding: '5px 0' }}
                      >
                        Initial commitment
                      </InputLabel>
                      {isEditable ? (
                        <FormControl
                          control="input"
                          maxLength={4}
                          value={
                            state.data.payment_data.initial_commitment.initial
                          }
                          onChange={(e) =>
                            dispatch(changeinitials(e.target.value))
                          }
                          style={{
                            margin: '0 0'
                          }}
                        />
                      ) : (
                        <p>
                          {state.data.payment_data.initial_commitment.initial}
                        </p>
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      style={{
                        alignItems: 'center',
                        display: 'flex'
                      }}
                    >
                      <h4
                        style={{
                          marginTop: '15px',
                          marginLeft: '15px'
                        }}
                      >
                        weeks
                      </h4>
                    </Grid>
                  </Grid>

                  <Grid item xs={4}>
                    <InputLabel
                      htmlFor="age-required"
                      // required
                      className={classes.cutom_select_Label}
                      style={{ padding: '5px 0' }}
                    >
                      End date
                    </InputLabel>
                    {isEditable ? (
                      <h4>
                        {state.data.payment_data.initial_commitment.initial &&
                          state.data.start_date &&
                          moment(state.data.start_date)
                            .add(
                              state.data.payment_data.initial_commitment
                                .initial,
                              'weeks'
                            )
                            .format('DD, MMMM YYYY')}
                      </h4>
                    ) : (
                      // <></>
                      <h4>{state.data.expires_date}</h4>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid
              container
              direction="row"
              spacing={2}
              style={{
                margin: '40px 0px 0',
                alignItems: 'flex-start'
              }}
            >
              <Grid
                item
                xs={3}
                style={{
                  marginTop: '0px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  paddingLeft: '0'
                }}
              >
                <h3>Session Details</h3>
                <p className={classes.ptext}>
                  Configure the No of Session , Used Session and Expiration
                  limit
                </p>
              </Grid>
              <Grid item xs={9}>
                <Grid container spacing={2} style={{ alignItems: 'center' }}>
                  <Grid item xs={5}>
                    <InputLabel
                      htmlFor="age-required"
                      required
                      className={classes.cutom_select_Label}
                      style={{ padding: '5px 0' }}
                    >
                      Number of sessions
                    </InputLabel>
                    {isEditable ? (
                      <FormControl
                        control="input"
                        label=""
                        placeholder="0"
                        maxLength={10}
                        value={
                          state.data.payment_data.membership.no_of_sessions
                        }
                        onChange={(e) => dispatch(chngenofsess(e.target.value))}
                        style={{ margin: '0 0' }}
                      />
                    ) : (
                      <p>{state.data.payment_data.membership.no_of_sessions}</p>
                    )}
                  </Grid>
                  <Grid item xs={5}>
                    <InputLabel
                      htmlFor="age-required"
                      required
                      className={classes.cutom_select_Label}
                      style={{ padding: '5px 0' }}
                    >
                      Sessions already used
                    </InputLabel>
                    {isEditable ? (
                      <FormControl
                        control="input"
                        label=""
                        placeholder="0"
                        required
                        maxLength={10}
                        value={state.data.usedsession}
                        style={{ margin: '0 0' }}
                        onChange={(e) =>
                          dispatch(chngeusedsess(e.target.value))
                        }
                      />
                    ) : (
                      <p>{state.data.usedsession}</p>
                    )}
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  style={{
                    alignItems: 'center',
                    padding: '15px 0'
                    // marginBottom: '20px'
                  }}
                >
                  <Grid item xs={4}>
                    <InputLabel
                      htmlFor="age-required"
                      className={classes.cutom_select_Label}
                      style={{ padding: '5px 0' }}
                    >
                      Expiration
                    </InputLabel>
                    {isEditable ? (
                      <CustomAutoComplete
                        holder="Please select attendance limit"
                        data={
                          state.data.payment_data.membership.attendance_limit
                        }
                        value={AllSessionlmt}
                        Options="name"
                        Change={(e) => dispatch(changeAttlmt(e))}
                      />
                    ) : (
                      <p>
                        {
                          state.data.payment_data.membership.attendance_limit
                            ?.name
                        }
                      </p>
                    )}
                  </Grid>

                  {state.data.payment_data.membership.attendance_limit &&
                  state.data.payment_data.membership.attendance_limit.id ===
                    1 ? (
                    // eslint-disable-next-line react/jsx-indent
                    <Grid xs={6}>
                      <Grid
                        container
                        direction="row"
                        spacing={2}
                        alignItems="center"
                      >
                        <Grid
                          item
                          xs={2}
                          style={{
                            textAlign: 'center'
                          }}
                        >
                          <InputLabel
                            htmlFor="age-required"
                            className={classes.cutom_select_Label}
                            style={{ padding: '3px 0' }}
                          >
                            &nbsp;
                          </InputLabel>
                          <h4>after</h4>
                        </Grid>
                        <Grid item xs={4} className={classes.cutom_input_css}>
                          <InputLabel
                            htmlFor="age-required"
                            className={classes.cutom_select_Label}
                            style={{ padding: '5px 0' }}
                          >
                            &nbsp;
                          </InputLabel>
                          {isEditable ? (
                            <FormControl
                              control="input"
                              value={
                                state.data.payment_data.membership
                                  .attendance_limited_meta.uses
                              }
                              onChange={(e) =>
                                dispatch(chngeAttuses(e.target.value))
                              }
                              style={{
                                margin: '0 0'
                              }}
                            />
                          ) : (
                            <p>
                              {
                                state.data.payment_data.membership
                                  .attendance_limited_meta.uses
                              }
                            </p>
                          )}
                        </Grid>
                        <Grid item xs={5}>
                          <InputLabel
                            htmlFor="age-required"
                            className={classes.cutom_select_Label}
                            style={{ padding: '5px 0' }}
                          >
                            &nbsp;
                          </InputLabel>
                          {isEditable ? (
                            <CustomAutoComplete
                              holder="Please select expired type"
                              value={SessionDay}
                              Options="name"
                              data={
                                state.data.payment_data.membership
                                  .attendance_limited_meta.type
                              }
                              Change={(e) => dispatch(chngeAtttype(e))}
                            />
                          ) : (
                            <p>
                              {
                                state.data.payment_data.membership
                                  .attendance_limited_meta.type.name
                              }
                            </p>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          )}
        </>
      )}

      {/* Payment Options */}
      <Grid
        container
        direction="row"
        spacing={2}
        style={{
          margin: '40px 0px 0',
          alignItems: 'flex-start'
        }}
      >
        <Grid
          item
          xs={3}
          style={{
            marginTop: '0px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            paddingLeft: '0'
          }}
        >
          <h3>Payment Options</h3>
          <p className={classes.ptext}>
            Select the payment option type and pricing for this member plan.
          </p>
        </Grid>
        <Grid item xs={9}>
          <Grid
            container
            direction="row"
            spacing={2}
            style={{
              alignItems: 'center'
            }}
          >
            <Grid item xs={12}>
              {/* {state.data.type === 'plan' ? (
                <PaymentOptionTable
                  values={state.data.payment_data.initial_commitment_pricing}
                  reducer={reducer}
                  isEditable={isEditable}
                />
              ) : (
                <SessionPaymentOptionTable
                  commitprice={
                    state.data.payment_data.initial_commitment_pricing
                  }
                  reducer={reducer}
                  isEditable={isEditable}
                />
              )} */}

              {(() => {
                switch (state.data.type) {
                  case 'session':
                    return (
                      <SessionPaymentOptionTable
                        commitprice={
                          state.data.payment_data.initial_commitment_pricing
                        }
                        reducer={reducer}
                        isEditable={isEditable}
                      />
                    );
                  case 'plan':
                    return (
                      <PaymentOptionTable
                        values={
                          state.data.payment_data.initial_commitment_pricing
                        }
                        reducer={reducer}
                        isEditable={isEditable}
                      />
                    );
                  case 'drop_in':
                    return (
                      <DropinPaymentOptionTable
                        commitprice={
                          state.data.payment_data.initial_commitment_pricing
                        }
                        reducer={reducer}
                        isEditable={isEditable}
                      />
                    );
                  default:
                    return null;
                }
              })()}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* Renewal Options */}
      {state.data.payment_data.auto_renew === 0 ? null : (
        <Grid
          container
          direction="row"
          spacing={2}
          style={{
            margin: '40px 0px 0',
            alignItems: 'flex-start'
          }}
        >
          <Grid
            item
            xs={3}
            style={{
              marginTop: '0px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              paddingLeft: '0'
            }}
          >
            <h3>Renewal Options</h3>
            <p className={classes.ptext}>
              Configure the auto-renew options for this member plan.
            </p>
          </Grid>
          <Grid item xs={9}>
            {/* <Grid
              container
              direction="row"
              spacing={2}
              style={{
                alignItems: 'center'
              }}
            > */}
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <IOSSwitch
                    checked={state.data.payment_data.auto_renew}
                    onChange={() => dispatch(chngeautorenew())}
                    disabled={!isEditable}
                  />
                }
                label="Enable Auto-renew"
                disabled={!isEditable}
                style={{ margin: '0 0' }}
                className={clsx(!isEditable ? classes.disabledSwitch : null)}
              />
            </Grid>
            {state.data.payment_data.auto_renew && (
              <Grid container direction="row">
                <Grid item xs={4} lg={5}>
                  <Grid
                    container
                    direction="row"
                    spacing={2}
                    alignItems="center"
                  >
                    <Grid
                      item
                      xs={6}
                      lg={6}
                      className={classes.cutom_input_css}
                    >
                      <InputLabel
                        htmlFor="age-required"
                        required
                        className={classes.cutom_select_Label}
                        style={{ padding: '5px 0' }}
                      >
                        Renewal commitment
                      </InputLabel>
                      {isEditable ? (
                        <FormControl
                          control="input"
                          maxLength={10}
                          value={
                            state.data.payment_data.auto_renew_meta.renewal
                          }
                          onChange={(e) =>
                            dispatch(chngeautorenwal(e.target.value))
                          }
                          style={{
                            margin: '0 0'
                          }}
                        />
                      ) : (
                        <p>{state.data.payment_data.auto_renew_meta.renewal}</p>
                      )}
                    </Grid>
                    <Grid item xs={1}>
                      <InputLabel
                        htmlFor="age-required"
                        className={classes.cutom_select_Label}
                        style={{ padding: '3px 0' }}
                      >
                        &nbsp;
                      </InputLabel>
                      <h4>weeks</h4>
                    </Grid>
                  </Grid>
                </Grid>
                {state.data.renewal_pricing === 'weekly' ? (
                  <Grid item xs={4} lg={5}>
                    <Grid
                      container
                      direction="row"
                      spacing={2}
                      alignItems="center"
                    >
                      <Grid item xs={6} lg={6}>
                        <InputLabel
                          htmlFor="age-required"
                          required
                          className={classes.cutom_select_Label}
                          style={{ padding: '5px 0' }}
                        >
                          Member is billed every
                        </InputLabel>
                        {isEditable ? (
                          <CustomAutoComplete
                            holder="Please select auto renew billed"
                            value={EverWeekData}
                            Options="name"
                            data={
                              state.data.payment_data.auto_renew_meta
                                .billed_every
                            }
                            Change={(e) => dispatch(chngerenewbilled(e))}
                          />
                        ) : (
                          <p>
                            {
                              state.data.payment_data.auto_renew_meta
                                ?.billed_every.id
                            }
                          </p>
                        )}
                      </Grid>
                      <Grid item xs={1}>
                        <InputLabel
                          htmlFor="age-required"
                          className={classes.cutom_select_Label}
                          style={{ padding: '3px 0' }}
                        >
                          &nbsp;
                        </InputLabel>
                        <h4>weeks</h4>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : null}
                <Grid
                  item
                  xs={12}
                  style={{ marginTop: '20px', marginBottom: '20px' }}
                >
                  <AutoRenewOptionTable
                    commitprice={state.data.payment_data.auto_renewal_pricing}
                    reducer={reducer}
                    isEditable={isEditable}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
      {/* System Information */}
      <Grid
        container
        direction="row"
        spacing={2}
        style={{
          margin: '40px 0px 0',
          alignItems: 'flex-start'
        }}
      >
        <Grid
          item
          xs={3}
          style={{
            marginTop: '0px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            paddingLeft: '0'
          }}
        >
          <h3>System Information</h3>
          <p className={classes.ptext}>
            Member plan create and update log details.
          </p>
        </Grid>
        <Grid item xs={9}>
          <Grid
            container
            direction="row"
            spacing={2}
            style={{
              alignItems: 'center'
            }}
          >
            <Grid item xs={6}>
              <InputLabel
                htmlFor="age-required"
                // required
                className={classes.cutom_select_Label}
                style={{ padding: '5px 0' }}
              >
                Created by
              </InputLabel>
              <h4 style={{ marginBottom: '0px', fontWeight: 'normal' }}>
                {state.data.createdon}
              </h4>
            </Grid>
            <Grid item xs={6}>
              <InputLabel
                htmlFor="age-required"
                // required
                className={classes.cutom_select_Label}
                style={{ padding: '5px 0' }}
              >
                Last updated by
              </InputLabel>
              <h4 style={{ marginBottom: '0px', fontWeight: 'normal' }}>
                {state.data.updateddon}
              </h4>
            </Grid>
          </Grid>
        </Grid>
        {/* <Grid
          item
          xs={12}
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            margin: '15px 0'
          }}
        >
          <JBButton type="outlined">Cancel</JBButton>
          <JBButton type="primary" style={{ marginLeft: '15px' }}>
            <span>Save</span>
          </JBButton>
        </Grid> */}
      </Grid>
      {open ? (
        <MemberProContext>
          <EditMemberHold
            id={id}
            open1
            start={start}
            onClose={() => {
              setOpen(false);
            }}
            reducer={reducer}
            onEdit={(start_date, end) => {
              const local = data;
              for (let i = 0; i < data.length; i += 1) {
                if (data[i].id === id.id) {
                  local[i] = {
                    ...local[i],
                    hold_start_date: start_date,
                    hold_end_date: end
                  };
                }
              }
            }}
            onDelete={() => setData(data.filter((item) => item.id !== id.id))}
          />
        </MemberProContext>
      ) : (
        <></>
      )}
    </div>
  );
};

PaymentOptions.propTypes = {
  classes: PropType.object.isRequired,
  reducer: PropType.array.isRequired,
  value: PropType.array.isRequired,
  start: PropType.object.isRequired
};

export default withStyles(styles)(PaymentOptions);
