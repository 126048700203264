import React, { Component } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import reactCSS from 'reactcss';
import { ChromePicker } from 'react-color';
import PropTypes from 'prop-types';

class CustomColorPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayColorPicker: false,
      color: '#01847a'
      // r: '241',
      // g: '112',
      // b: '19',
      // a: '1'
    };
  }

  handleClick = () => {
    const { displayColorPicker } = this.state;
    this.setState({ displayColorPicker: !displayColorPicker });
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
  };

  handleChange = (color) => {
    const { setColor } = this.props;
    setColor(color.hex);
    this.setState({ color: color.hex });
  };

  render() {
    const { displayColorPicker, color } = this.state;
    const styles = reactCSS({
      default: {
        color: {
          width: '24px',
          height: '24px',
          borderRadius: '50%',
          background: `${color}`
        },
        swatch: {
          display: 'inline-block',
          cursor: 'pointer',
          color: '#028379',
          border: '1px solid rgba(41, 41, 41, 0.4)',
          borderRadius: '4px',
          lineHeight: '16px',
          padding: '2px 4px',
          fontSize: '12px',
          letterSpacing: '0.2px',
          marginBottom: '10px',
          fontWeight: 'bold'
        },
        popover: {
          position: 'absolute',
          zIndex: '2',
          display: 'block'
        },
        cover: {
          position: 'relative',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px'
        }
      }
    });
    return (
      <div>
        <div style={styles.swatch} onClick={this.handleClick}>
          {displayColorPicker ? (
            <div>
              <div style={styles.cover} onClick={this.handleClose} />
              <p>Close custom color </p>
            </div>
          ) : (
            <div>Add custom color</div>
          )}
        </div>
        <div>
          <div style={styles.color} />
        </div>
        {displayColorPicker ? (
          <div style={styles.popover}>
            <div style={styles.cover} onClick={this.handleClose} />
            <ChromePicker
              color={color}
              onChange={this.handleChange}
              disableAlpha
            />
          </div>
        ) : null}
      </div>
    );
  }
}

CustomColorPicker.propTypes = {
  setColor: PropTypes.func.isRequired
};

export default CustomColorPicker;
