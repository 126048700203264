import { checkValidString } from '../../../utils/validations';
import { SessionMeta } from '../MembershipTemplate/SessionTemplates/SessionTemplate.utils';

// eslint-disable-next-line
export const editPlanValidate = (data) => {
  if (checkValidString(data.name)) {
    return { error: 'Please specify valid name' };
  }

  if (!data.reve_cat_id) {
    return { error: 'Please select revenue category' };
  }

  if (data.program_ids.length === 0) {
    return { error: 'Please Select atleast one Program' };
  }
  if (data.meta.length === 0) {
    return { error: 'Please create atleast one payment plan' };
  }

  return { value: data };
};

export const getUpdateData = (data) => {
  const value = {
    membership_type: 'drop_in',
    name: data.name,
    reve_cat_id: data.reve_cat_id.id,
    attendance_limit: data.attendance_limit,
    program_ids: data.program_ids.join(','),
    meta: JSON.stringify(SessionMeta(data.meta)),
    description: null
  };

  return value;
};
