import { ROUTES } from 'src/constant/Constants';

// the ID must match the Communication Automated Email Template ID from the Backend
export const CLASS_ROUTES = [
  {
    id: 12,
    to: ROUTES.COMMUNICATION_AUTOMATED_EMAILS_CLASSES_CLASSREINSTATED
  },
  {
    id: 13,
    to: ROUTES.COMMUNICATION_AUTOMATED_EMAILS_CLASSES_CLASSCANCELLED
  },
  {
    id: 15,
    to: ROUTES.COMMUNICATION_AUTOMATED_EMAILS_CLASSES_EXPIRINGPLANS
  },
  {
    id: 16,
    to: ROUTES.COMMUNICATION_AUTOMATED_EMAILS_CLASSES_EXPIRINGSESSIONS
  },
  {
    id: 17,
    to: ROUTES.COMMUNICATION_AUTOMATED_EMAILS_CLASSES_MISSING_MEMBER
  }
];

export default CLASS_ROUTES;
