/* eslint-disable operator-linebreak */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
import { getSignErrorMsg } from './chalBoard.reducer.utils';
import types from './chalkBoard.Types';

export const initialValue = {
  program: null,
  date: new Date(),
  class: null,
  error: 'Please Select Program, Date, and Class to view the workout',
  classOption: [],
  data: null,
  SignIn: {
    program: null,
    selectedMember: null,
    date: new Date(),
    class: null,
    classOption: [],
    logined_users: { sign_in_users: [], reserved_users: [] },
    error: 'Please Select Program, Date, and Class to Sign In',
    disableButton: true,
    openDialog: false,
    DialoagErrMsg: null,
    dialogStatus: null,
    disableMemberDrop: true,
    isProcessing: false
  }
};

export const chalkboardReducer = (state = initialValue, action) => {
  switch (action.type) {
    case types.SetProgram:
      return {
        ...state,
        program: action.payload,
        classOption: [],
        class: null,
        error:
          state.date && action.payload
            ? 'Please Select a class'
            : 'Please Select Program, and Class to View Workout'
      };
    case types.SetDate:
      return {
        ...state,
        date: action.payload,
        class: null,
        classOption: [],
        error:
          state.program && action.payload
            ? 'Please Select a class'
            : 'Please Select Program, and Class to View Workout'
      };
    case types.getClassOpt:
      return {
        ...state,
        classOption: action.payload
      };
    case types.SetClass:
      return {
        ...state,
        class: action.payload,
        error: action.payload === null ? 'Please select a class' : state.error
      };
    case types.SetError:
      return {
        ...state,
        error: action.payload
      };
    case types.SetWorkoutData:
      return {
        ...state,
        data: action.payload,
        error: null
      };
    case types.setSignProgram:
      return {
        ...state,
        SignIn: {
          ...state.SignIn,
          program: action.payload,
          classOption: [],
          class: null,
          disableMemberDrop: true,
          selectedMember: null,
          error: getSignErrorMsg(state.SignIn, action.payload, 'program'),
          disableButton: true
        }
      };
    case types.setSignDate:
      return {
        ...state,
        SignIn: {
          ...state.SignIn,
          date: action.payload,
          class: null,
          classOption: [],
          selectedMember: null,
          disableMemberDrop: true,
          error: getSignErrorMsg(state.SignIn, action.payload, 'date'),
          disableButton: true
        }
      };
    case types.setSignClassOpt:
      return {
        ...state,
        SignIn: {
          ...state.SignIn,
          classOption: action.payload
        }
      };
    case types.setSignClass:
      return {
        ...state,
        SignIn: {
          ...state.SignIn,
          class: action.payload,
          error: getSignErrorMsg(state.SignIn, action.payload, 'class'),
          selectedMember: null,
          disableMemberDrop: false,
          disableButton: true
        }
      };
    case types.setSignError:
      return {
        ...state,
        SignIn: {
          ...state.SignIn,
          error: action.payload
        }
      };
    case types.setSignMember:
      return {
        ...state,
        SignIn: {
          ...state.SignIn,
          selectedMember: action.payload,
          disableButton: !Boolean(
            state.SignIn.class &&
              state.SignIn.date &&
              state.SignIn.program &&
              action.payload
          )
        }
      };
    case types.setlogineduserbyClass:
      return {
        ...state,
        SignIn: {
          ...state.SignIn,
          logined_users: action.payload
        }
      };
    case types.setLoginedUser:
      return {
        ...state,
        SignIn: {
          ...state.SignIn,
          logined_users: {
            ...state.SignIn.logined_users,
            sign_in_users: [
              ...state.SignIn.logined_users.sign_in_users,
              action.payload
            ],
            reserved_users: state.SignIn.logined_users.reserved_users.filter(
              (user) => user.id !== action?.payload?.id
            )
          },
          error: ''
        }
      };
    case types.removeLoginedUser:
      return {
        ...state,
        SignIn: {
          ...state.SignIn,
          logined_users: {
            ...state.SignIn.logined_users,
            sign_in_users: state.SignIn.logined_users.sign_in_users.filter(
              (user) => user.user_id !== action.payload
            )
          }
        }
      };

    case types.removeReservedMembers:
      return {
        ...state,
        SignIn: {
          ...state.SignIn,
          logined_users: {
            ...state.SignIn.logined_users,
            reserved_users: state.SignIn.logined_users.reserved_users.filter(
              (user) => user.id !== action.payload
            )
          }
        }
      };

    case types.setSignDialog:
      return {
        ...state,
        SignIn: {
          ...state.SignIn,
          openDialog: action.payload,
          dialogStatus: action.payload ? action.status : null,
          DialoagErrMsg: action.payload ? action.message : null,
          selectedMember: !action.payload ? null : state.SignIn.selectedMember,
          disableButton: !action.payload
        }
      };
    case types.setSignProcessing:
      return {
        ...state,
        SignIn: {
          ...state.SignIn,
          isProcessing: action.payload
        }
      };
    default:
      return state;
  }
};
