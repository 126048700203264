export const WODProgram = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Select/Deselect all'
  },
  {
    id: 'classtime',
    numeric: true,
    disablePadding: false,
    label: 'Class time'
  },
  {
    id: 'headcoach',
    numeric: true,
    disablePadding: false,
    label: 'Head coach'
  }
];

export const headWODlist = [
  {
    id: 'starred',
    disablePadding: true,
    label: ''
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Name'
  },
  { id: 'program', numeric: true, disablePadding: false, label: 'Program' },
  { id: 'tag', numeric: true, disablePadding: false, label: 'Workout tags' },
  { id: 'date', numeric: true, disablePadding: false, label: 'Date' }
];
