/* eslint-disable operator-linebreak */
import { ROUTES } from 'src/constant/Constants';
import {
  MembershipType,
  ShowClassType
} from './AttendanceTabReducer/Attendance.data';
import { getSetHour } from './ClassSetting.Data';

// eslint-disable-next-line
export const getValidData = (data) => {
  const updatedVal = {
    ...data,
    default_reservation_settings: {
      ...data.default_reservation_settings,
      res_cancel_unit: getSetHour.filter(
        (list) => list.id === data.default_reservation_settings.res_cancel_unit
      )[0],
      res_close_unit: getSetHour.filter(
        (list) => list.id === data.default_reservation_settings.res_close_unit
      )[0],
      res_open_unit: getSetHour.filter(
        (list) => list.id === data.default_reservation_settings.res_open_unit
      )[0],
      login_open_unit: getSetHour.filter(
        (list) => list.id === data.default_reservation_settings.login_open_unit
      )[0]
    },
    late_cancel_settings: JSON.parse(data.late_cancel_settings),
    no_show_settings: JSON.parse(data.no_show_settings),
    late_cancel_email_template: {
      ...data.late_cancel_email_template,
      visibility: JSON.parse(data.late_cancel_email_template.visibility)
    },
    no_show_email_template: {
      ...data.no_show_email_template,
      visibility: JSON.parse(data.no_show_email_template.visibility)
    }
  };
  return updatedVal;
};

export function updateAttendanceforapi(data, type, value) {
  if (type === 'contract-enforcement' && typeof value !== 'boolean') {
    return { error: 'Contract enforment is not valid, please verify' };
  }
  if (type === 'waiver-enforce' && typeof value !== 'boolean') {
    return { error: 'Waiver enforment is not valid, please verify' };
  }
  if (type === 'membership-visibilty' && typeof value !== 'boolean') {
    return { error: 'Membership visibilty is not valid, please verify' };
  }
  if (
    type === 'lmtplanmembership' &&
    (!value || !MembershipType.includes(value))
  ) {
    return {
      error: 'Limited Plan Membership is not valid , please select valid plan'
    };
  }
  if (
    type === 'sessmembership' &&
    (!value || !MembershipType.includes(value))
  ) {
    return {
      error: 'Session Membership is not valid, please select valid session'
    };
  }
  if (type === 'showclasses' && (!value || !ShowClassType.includes(value))) {
    return {
      error: 'Show Class is not valid please select valid class'
    };
  }
  if (
    !data.membership_enforcement.limited_plan_membership &&
    type !== 'lmtplanmembership'
  ) {
    return { error: 'please select limited plan membership first' };
  }
  if (
    !data.membership_enforcement.session_membership &&
    type !== 'sessmembership'
  ) {
    return { error: 'please select session membership first' };
  }
  if (
    !data.membership_enforcement.show_classes_in_athlete_app &&
    type !== 'showclasses'
  ) {
    return { error: 'Please select show classes in membership first' };
  }
  const result = {
    membership_enforcement: {
      visibility: data.membership_enforcement.visibility ? 1 : 0,
      limited_plan_membership:
        data.membership_enforcement.limited_plan_membership?.id,
      session_membership: data.membership_enforcement.session_membership?.id,
      show_classes_in_athlete_app:
        data.membership_enforcement.show_classes_in_athlete_app?.id
    },
    contract_enforcement: data.contract_enforcement ? 1 : 0,
    waiver_enforcement: data.waiver_enforcement ? 1 : 0
  };
  if (type === 'contract-enforcement') {
    result.contract_enforcement = value ? 1 : 0;
  }
  if (type === 'waiver-enforce') {
    result.waiver_enforcement = value ? 1 : 0;
  }
  if (type === 'membership-visibilty') {
    result.membership_enforcement.visibility = value ? 1 : 0;
  }
  if (type === 'lmtplanmembership') {
    result.membership_enforcement.limited_plan_membership = value?.id;
  }
  if (type === 'sessmembership') {
    result.membership_enforcement.session_membership = value?.id;
  }
  if (type === 'showclasses') {
    result.membership_enforcement.show_classes_in_athlete_app = value?.id;
  }
  return { data: result };
}

export const getActiveVal = (pathname) => {
  switch (pathname) {
    case ROUTES.CLASSES_SETTINGS_MEMBERSHIP:
      return 0;
    case ROUTES.CLASSES_SETTINGS_MEMBERSHIP_MASS_UPDATE:
      return 1;
    case ROUTES.CLASSES_SETTINGS_MEMBERSHIP_HOLD_TYPES:
      return 2;
    default:
      return 0;
  }
};
