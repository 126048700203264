import { getProtectedReports } from 'src/Redux/ReportsReducer/reports.reducer.utils';
import authhttp from './authHttp.services';

export const saveReportAsFavourite = async (values) => {
  const favouriteUrl = 'api/set_as_fav_report';
  const {
    data: { data }
  } = await authhttp.post(favouriteUrl, values);
  return data;
};

export const getmembersMembershipDetails = async (val) => {
  const membershipUrl = 'api/members_membership_details';
  const {
    data: { data }
  } = await authhttp.post(membershipUrl, val);
  return data;
};

export const getmembersMembershipType = async (values) => {
  const membershipUrl = 'api/members_membership_type';
  const {
    data: { data }
  } = await authhttp.post(membershipUrl, values);
  return data;
};

export const getmembersPaymentPlan = async (values) => {
  const membershipUrl = 'api/members_payment_plan';
  const {
    data: { data }
  } = await authhttp.post(membershipUrl, values);
  return data;
};

export const getmembersexpiringMembership = async (values) => {
  const membershipUrl = 'api/members_expiring_membership';
  const {
    data: { data }
  } = await authhttp.post(membershipUrl, values);
  return data;
};

export const getClassHistoryOfAUser = async (values) => {
  const membershipUrl = 'api/classHistoryOfUser';
  const {
    data: { data }
  } = await authhttp.post(membershipUrl, values);
  return data;
};

export const getmemberslastAttendance = async (values) => {
  const attendanceUrl = 'api/member_last_attendance';
  const {
    data: { data }
  } = await authhttp.post(attendanceUrl, values);
  return data;
};

export const getTotalattendanceHistory = async (values) => {
  const attendanceUrl = 'api/totalAttendanceHistory';
  const {
    data: { data }
  } = await authhttp.post(attendanceUrl, values);
  return data;
};

export const getattendanceSummaryDetail = async (values) => {
  const attendanceUrl = 'api/attendance_summary_report';
  const {
    data: { data }
  } = await authhttp.post(attendanceUrl, values);
  return data;
};

export const getallinvoicereports = async (values) => {
  const invoiceurl = 'api/all_invoice_report';
  const {
    data: { data }
  } = await authhttp.post(invoiceurl, values);
  return data;
};

export const getrevenuemembershipreports = async (values) => {
  const invoiceurl = 'api/revenueByMemebership';
  const {
    data: { data }
  } = await authhttp.post(invoiceurl, values);
  return data;
};

export const getoverdueaccountsrecieve = async (values) => {
  const invoiceurl = 'api/overdueAccountReceivable';
  const {
    data: { data }
  } = await authhttp.post(invoiceurl, values);
  return data;
};

export const exportLast_attendance = async (values) => {
  const exportUrl = 'api/member_last_attendance_export';
  authhttp
    .get(exportUrl, {
      responseType: 'blob',
      params: { ...values }
    })
    .then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'last_attendance.xlsx');
      document.body.appendChild(link);
      link.click();
      link.remove();
      URL.revokeObjectURL(link.href);
    });
};

export const export_attendanceTrend = async (values) => {
  const exportUrl = 'api/memberTrendsReportExport';
  authhttp
    .get(exportUrl, {
      responseType: 'blob',
      params: {
        ...values
      }
    })
    .then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'member_attendance_trend.xlsx');
      document.body.appendChild(link);
      link.click();
      link.remove();
      URL.revokeObjectURL(link.href);
    });
};

export const export_totalAttendanceHistory = async (values) => {
  const exportUrl = 'api/total_attendance_history_export';
  authhttp
    .get(exportUrl, {
      responseType: 'blob',
      params: {
        ...values
      }
    })
    .then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'total_attendance_history.xlsx');
      document.body.appendChild(link);
      link.click();
      link.remove();
      URL.revokeObjectURL(link.href);
    });
};

export const membertrendsreports = async (values) => {
  const membertrends = 'api/memberTrendsReports';
  const {
    data: { data }
  } = await authhttp.post(membertrends, values);
  return data;
};

export const exportOverDueaccount = async (values) => {
  const exportUrl = 'api/overdueAccountReceivableExport';
  authhttp
    .get(exportUrl, {
      responseType: 'blob',
      params: { ...values }
    })
    .then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'OverdueAccountReceivable.xlsx');
      document.body.appendChild(link);
      link.click();
      link.remove();
      URL.revokeObjectURL(link.href);
    });
};

export const expirecreditcardReports = async (values) => {
  const membertrends = 'api/expiringCreditCards';
  const {
    data: { data }
  } = await authhttp.post(membertrends, values);
  return data;
};

export const revenueCategoryReports = async (values) => {
  const membertrends = 'api/revenueByRevenueCategory';
  const {
    data: { data }
  } = await authhttp.post(membertrends, values);
  return data;
};

export const getLeadInsightsReports = async (values) => {
  const membertrends = 'api/lead_insights_report';
  const {
    data: { data }
  } = await authhttp.post(membertrends, values);
  return data;
};

export const UserAttendaceHistory = async (values) => {
  const editUrl = 'api/getLastAttendaceDetailsOfUser';
  const {
    data: { data }
  } = await authhttp.post(editUrl, values);
  return data;
};

export const getMembershipsOnHoldReports = async (values) => {
  const holdMembershipsUrl = 'api/getHoldMemberships';
  const {
    data: { data }
  } = await authhttp.post(holdMembershipsUrl, values);
  return data;
};
export const getValueOfEachMember = async (values) => {
  const valueMembersUrl = 'api/valueOfEachMember';
  const {
    data: { data }
  } = await authhttp.post(valueMembersUrl, values);
  return data;
};
export const getValueOfSpecificMember = async (id) => {
  const valueMembersUrl = 'api/valueOfEachMemberSpecificDetails';
  const {
    data: { data }
  } = await authhttp.post(valueMembersUrl, id);
  return data;
};
export const exportMembershipsHold = async (values) => {
  const exportUrl = 'api/getHoldMembershipsExport';
  authhttp
    .get(exportUrl, {
      responseType: 'blob',
      params: {
        ...values
      }
    })
    .then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'HoldMemberships.xlsx');
      document.body.appendChild(link);
      link.click();
      link.remove();
      URL.revokeObjectURL(link.href);
    });
};
export const exportMembershipsDetails = async (values) => {
  const exportUrl = 'api/members_membership_details_export';
  authhttp
    .get(exportUrl, { responseType: 'blob', params: { ...values } })
    .then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'membership_details.xlsx');
      document.body.appendChild(link);
      link.click();
      link.remove();
      URL.revokeObjectURL(link.href);
    });
};
export const exportMembersExpiring = (values) => {
  const exportUrl = 'api/members_expiring_membership_export';
  authhttp
    .get(exportUrl, { responseType: 'blob', params: { ...values } })
    .then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'members_expiring.xlsx');
      document.body.appendChild(link);
      link.click();
      link.remove();
      URL.revokeObjectURL(link.href);
    });
};
export const exportMembersPaymentByPlan = (values) => {
  const exportUrl = 'api/members_payment_plan_export';
  authhttp
    .get(exportUrl, { responseType: 'blob', params: { ...values } })
    .then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'members_payment_by_plan.xlsx');
      document.body.appendChild(link);
      link.click();
      link.remove();
      URL.revokeObjectURL(link.href);
    });
};
export const exportAllInvoices = async (values) => {
  const exportUrl = 'api/allInvoiceExport';
  authhttp
    .get(exportUrl, { responseType: 'blob', params: { ...values } })
    .then(async (res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'all_invoices.xlsx');
      document.body.appendChild(link);
      link.click();
      link.remove();
      URL.revokeObjectURL(link.href);
    });
};
export const exportMembershipType = async (values) => {
  const exportUrl = 'api/members_membership_type_export';
  authhttp
    .get(exportUrl, { responseType: 'blob', params: { ...values } })
    .then(async (res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'membership_type.xlsx');
      document.body.appendChild(link);
      link.click();
      link.remove();
      URL.revokeObjectURL(link.href);
    });
};
export const exportExpiringCreditCards = async (values) => {
  const exportUrl = 'api/expiringCreditCardsExports';
  authhttp
    .get(exportUrl, {
      responseType: 'blob',
      params: { ...values }
    })
    .then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'expiring_credit_cards.xlsx');
      document.body.appendChild(link);
      link.click();
    });
};
export const exportRevenueCategory = async (values) => {
  const exportUrl = 'api/revenueByRevenueCategoryDepthExport';
  authhttp
    .get(exportUrl, { responseType: 'blob', params: { ...values } })
    .then(async (res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'revenue_category.xlsx');
      document.body.appendChild(link);
      link.click();
      link.remove();
      URL.revokeObjectURL(link.href);
    });
};
export const exportValueMember = async (values) => {
  const exportUrl = 'api/valueOfEachMemberExport';
  authhttp
    .get(exportUrl, { responseType: 'blob', params: { ...values } })
    .then(async (res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'value_each_member.xlsx');
      document.body.appendChild(link);
      link.click();
      link.remove();
      URL.revokeObjectURL(link.href);
    });
};
export const exportRevenueMembership = async (values) => {
  const exportUrl = 'api/revenueByMemebershipExport';
  authhttp
    .get(exportUrl, { responseType: 'blob', params: { ...values } })
    .then(async (res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'revenue_membership.xlsx');
      document.body.appendChild(link);
      link.click();
      link.remove();
      URL.revokeObjectURL(link.href);
    });
};

export const getReportsData = async (val, roleid) => {
  const ReportsUrl = 'api/jb_reports';
  const {
    data: { data }
  } = await authhttp.get(ReportsUrl);
  return roleid !== 1 ? getProtectedReports(data) : data;
};

export const FavReport = async (values) => {
  const ReportsUrl = 'api/set_as_fav_report';
  const {
    data: { data }
  } = await authhttp.post(ReportsUrl, values);
  return data;
};
