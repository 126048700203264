import React from 'react';
import { AuBankAccountElement } from '@stripe/react-stripe-js';
import { withStyles } from '@material-ui/core';
import PropType from 'prop-types';

const styles = () => ({
  cutom_StripeCard: {
    border: '1px solid #a9a9a9',
    padding: '13.5px 15px'
  }
});

const BANKACCOUNT_OPTIONS = {
  iconStyle: 'solid',
  hidePostalCode: true,
  style: {
    base: {
      iconColor: '#c4f0ff',
      fontWeight: 500,
      fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
      fontSize: '16px',
      color: '#01847a',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': {
        color: '#fce883'
      },
      '::placeholder': {
        color: '#7F7F7F'
      }
    },
    invalid: {
      iconColor: '#ffc7ee'
    }
  }
};

const StripeBankAccount = ({ onChange, classes }) => {
  return (
    <div className={classes.cutom_StripeCard}>
      <AuBankAccountElement options={BANKACCOUNT_OPTIONS} onChange={onChange} />
    </div>
  );
};

StripeBankAccount.propTypes = {
  onChange: PropType.func.isRequired,
  classes: PropType.object.isRequired
};

export default withStyles(styles)(StripeBankAccount);
