import React, { useState, useEffect } from 'react';
import PropType from 'prop-types';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  withStyles,
  Grid,
  Box,
  Tabs,
  Tab,
  ThemeProvider
} from '@material-ui/core';
import Plusicon from 'src/assets/image/plusicon.png';
import dcloseicon from 'src/assets/image/closeicon.png';
import { getCurrentUser } from 'src/services/user.service';
import { getCountries } from 'src/services/Members.module';
import { ReactComponent as CreditCard } from '../../../../assets/image/JBSteppericon/CreditCard.svg';
import JBButton from '../../../common/JBButton/JBButton';
import AddPaymentMethodTabs from './AddPaymentMethodTabs';
import styles from './AddPaymentMethod.style';

const themeInstance = {
  overrides: {
    MuiTabs: {
      indicator: {
        backgroundColor: 'transparent'
      }
    },
    MuiTab: {
      root: {
        maxWidth: '100%',
        margin: '0px 15px 0 0',
        width: '368px',
        height: '239px',
        borderRadius: '4px',
        border: '1px solid #949494',
        '&$selected': {
          backgroundColor: '#01847A',
          border: '1px solid #01847A',
          color: '#fff ',
          '&:hover': {
            color: '#37847a'
          },
          '&$textColorPrimary': {
            color: '#fff'
          },
          '& svg path': {
            fill: '#fff'
          }
        }
      },
      wrapper: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex'
      },
      labelIcon: {
        minHeight: '55px',
        '& svg': {
          marginBottom: '50px !important'
        }
      },
      textColorPrimary: {
        color: '#545454',
        fontSize: '14px',
        fontWeight: '700',
        lineHeight: '18px',
        letterSpacing: '0.5px'
      }
    }
  }
};
function AddPaymentMethodDialog({ classes, updateData }) {
  const [open, setOpen] = React.useState(false);
  const [info, setInfo] = useState(null);
  const [AddPaymentMethodTabsValue, setAddPaymentMethodTabsValue] = useState(0);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    Promise.all([getCountries(), getCurrentUser()]).then((res) => {
      setInfo({
        ...res[1],
        country:
          res[0].filter((list) => list.code === res[1].country)[0] || null
      });
    });
  }, []);
  return (
    <div>
      <JBButton type="primary" onClick={handleClickOpen} isImg={Plusicon}>
        new Payment method
      </JBButton>
      <Dialog
        open={open}
        fullWidth
        maxWidth="lg"
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div>
          <Grid alignItems="center" container direction="row">
            <Grid item xs>
              <DialogTitle
                id="alert-dialog-title"
                className={classes.titlebk}
                style={{
                  display: 'inline-block',
                  padding: '25px 24px'
                }}
              >
                <h4 className={classes.dtitle}> Add New Payment Method</h4>
              </DialogTitle>
            </Grid>
            <Grid
              item
              xs={2}
              style={{ textAlign: 'right', marginRight: '10px' }}
            >
              <Button className={classes.btns} onClick={handleClose}>
                <img alt="img" src={dcloseicon} />
              </Button>
            </Grid>
          </Grid>

          <DialogContent style={{ padding: '15px 24px', overflowY: 'hidden' }}>
            <Grid container spacing={0} style={{ marginTop: '0px' }}>
              <Grid item xs={12} style={{}}>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                  spacing={0}
                  style={{ margin: '0 0 0px' }}
                >
                  <Grid item xs={3}>
                    &nbsp;
                  </Grid>
                  <Grid item xs={9}>
                    <Box ml="15px">
                      <ThemeProvider theme={themeInstance}>
                        <Tabs
                          value={AddPaymentMethodTabsValue}
                          onChange={(_, val) =>
                            setAddPaymentMethodTabsValue(val)
                          }
                          indicatorColor="primary"
                          textColor="primary"
                          fullWidth
                        >
                          <Tab
                            label="Credit Card"
                            icon={<CreditCard />}
                            className={classes.p_tabs}
                          />
                        </Tabs>
                      </ThemeProvider>
                    </Box>
                  </Grid>
                </Grid>
                <Box className={classes.TabulationCss}>
                  <AddPaymentMethodTabs
                    info={info}
                    Tabs={AddPaymentMethodTabsValue}
                    handleClose={handleClose}
                    updateData={updateData}
                  />
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
        </div>
      </Dialog>
    </div>
  );
}

AddPaymentMethodDialog.propTypes = {
  classes: PropType.object.isRequired,
  updateData: PropType.func.isRequired
};
export default withStyles(styles)(AddPaymentMethodDialog);
