import React from 'react';
import { ROUTES } from 'src/constant/Constants';
import filterTypes from 'src/Redux/programReducers/programReducer.constants';
import { Link as RouterLink } from 'react-router-dom';
import PropType from 'prop-types';
import {
  Button,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel
} from '@material-ui/core';
import { get } from 'js-cookie';
import { ReactComponent as ActivateIcon } from '../../../assets/image/power.svg';
import { ReactComponent as DeactivateIcon } from '../../../assets/image/power_d.svg';

const ProgramTable = ({
  classes,
  data,
  isActive,
  setDirection,
  setSort,
  handleActivate
}) => {
  const { sortdesc, sortname } = filterTypes;
  const roleid = JSON.parse(get('jbrole') || '{}')?.role_id || 3;

  return (
    <TableContainer>
      <Table className={classes.table}>
        <TableHead className={classes.table_th}>
          <TableRow>
            <TableCell
              className={classes.TableCell}
              style={{ width: '0.1%', padding: '0 0' }}
            >
              &nbsp;
            </TableCell>
            <TableCell
              className={classes.TableCell}
              style={{ paddingLeft: '25px', width: '20%' }}
            >
              <TableSortLabel
                active={isActive(sortname)}
                onClick={() => setSort(sortname)} // eslint-disable-line
                direction={setDirection(sortname)}
              >
                Name
              </TableSortLabel>
            </TableCell>
            <TableCell
              numeric
              className={classes.TableCell}
              style={{ width: '50%' }}
            >
              <TableSortLabel
                active={isActive(sortdesc)}
                onClick={() => setSort(sortdesc)} // eslint-disable-line
                direction={setDirection(sortdesc)}
              >
                Description
              </TableSortLabel>
            </TableCell>
            <TableCell
              numeric
              className={classes.TableCell}
              style={{ textAlign: 'center', width: '10%' }}
            >
              Is active
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody wrap="nowrap">
          {data.data.map((row) => {
            return (
              <TableRow key={row.id}>
                <TableCell
                  scope="row"
                  className={classes.Table_td}
                  style={{ padding: '0 10px' }}
                >
                  <span
                    className={classes.colorcode}
                    style={{
                      backgroundColor: `${row.color}`
                    }}
                  />
                </TableCell>
                <TableCell scope="row" className={classes.Table_td}>
                  {roleid <= 2 ? (
                    <Link
                      component={RouterLink}
                      to={{
                        pathname: ROUTES.CLASSES_PROGRAMS_EDIT,
                        search: `program=${row.id}`
                      }}
                      className={classes.Table_td_link}
                    >
                      {row.name}
                    </Link>
                  ) : (
                    <span className={classes.Table_td_link}>{row.name}</span>
                  )}
                </TableCell>
                <TableCell numeric className={classes.Table_td}>
                  {row.description}
                </TableCell>
                <TableCell
                  numeric
                  className={classes.Table_td}
                  style={{ textAlign: 'center' }}
                >
                  {row.status === 1 ? (
                    <Button
                      variant="outlined"
                      color="primary"
                      size="small"
                      className={classes.button_status_deac}
                    >
                      <DeactivateIcon />
                      <span style={{ marginLeft: 5 }}>deactivate</span>
                    </Button>
                  ) : (
                    <Button
                      variant="outlined"
                      color="primary"
                      size="small"
                      className={classes.button_status_act}
                      onClick={() => handleActivate(row.id)}
                    >
                      <ActivateIcon />
                      <span style={{ marginLeft: 5 }}>Activate</span>
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      {data.data.length === 0 && (
        <p
          style={{
            color: '#263238'
          }}
        >
          No Programs to show...
        </p>
      )}
    </TableContainer>
  );
};

ProgramTable.propTypes = {
  classes: PropType.object.isRequired,
  data: PropType.object.isRequired,
  isActive: PropType.func.isRequired,
  setDirection: PropType.func.isRequired,
  setSort: PropType.func.isRequired,
  handleActivate: PropType.func.isRequired
};

export default ProgramTable;
