const styles = () => ({
  dtitle: {
    color: '#292929',
    fontWeight: 'bold',
    fontSize: '33px',
    lineHeight: '26px'
  },
  cutom_select_Label: {
    fontSize: '12px',
    padding: '7px 0'
  },
  cutom_select_border: {
    border: '1px solid #a9a9a9'
  },
  ptext: {
    fontSize: '16px',
    lineHeight: '24px',
    color: '#7F7F7F',
    fontWeight: 'normal'
  },
  TabulationCss: {
    '& .MuiPaper-root.MuiCard-root': {
      boxShadow: 'none !important',
      padding: '0 0',
      margin: '10px 0'
    },
    '& .MuiStepper-vertical': {
      position: 'relative',
      top: '-230px'
    }
  }
});

export default styles;
