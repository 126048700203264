/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Grid,
  Card,
  Radio,
  RadioGroup,
  Breadcrumbs,
  Typography
} from '@material-ui/core';
import { connect } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import PropType from 'prop-types';
import './CutomSelect.css';
import IOSSwitch from 'src/views/common/FormControl/IOSSwitch';
import JBDatePicker from 'src/views/common/FormControl/JBDatePicker';
import CancelDialog from 'src/views/common/CommonDialogBox/CancelDialog';
import CancelDialogTwo from 'src/views/common/CommonDialogBox/CancelDialogTwo';
import {
  createOneTime,
  createReccClass
} from 'src/services/classModule.services';
import JBTimePicker from 'src/views/common/FormControl/JBTimePicker';
import { openNoti } from 'src/Redux/global/global.actions';
import {
  AllClassdata,
  futureddata,
  reccurdata
} from 'src/Redux/ClassReducer/classReducer.actions';
import {
  checkValidNum,
  validateInt,
  validateString
} from 'src/utils/validations';
import {
  checkClassName,
  checkEndTime,
  checkStartTime,
  getTime
} from 'src/utils/someCommon';
import { addDays, addHours, addMonths, addYears } from 'date-fns';
import { getCalenderData } from 'src/Redux/CalenderReducer/Calender.actions';
import { getErrorMsg } from 'src/utils/ErrorHandling';
import useChangeTitle from 'src/views/common/CustomHooks/useTitleChange';
import { ReactComponent as RIcon } from '../../../assets/image/R.svg';
import { ReactComponent as BasicInformation } from '../../../assets/image/JBSteppericon/BasicInformation.svg';
import { ReactComponent as HistoryIcon } from '../../../assets/image/history.svg';

import {
  WeekData,
  Repeatdata,
  getRepeatWeek,
  EndTypeData
} from './createClass.Data';
import FormControl from '../../common/FormControl/formControl';
import ColorlibStepIcon from '../../common/JBGridStepper/ColorlibStepIcon';
import CustomAutocomplete from '../../common/CustomAutocomplete/commonAutocomplete';
import JBStepper from '../../common/JBGridStepper';
import styles from './Class.style';
import WeekButton from './weekButton';
import RepeatWeek from './repetitionweek';
import {
  getData,
  getRepWeek,
  removeWeekdays,
  validClassStep1,
  validClassStep2,
  getValidStep1,
  getValidStep2
} from './Class.utils';

function getSteps(recurr) {
  if (!recurr) {
    return ['Basic Details', 'Reservation & Coaches'];
  }
  return ['Basic Details', 'Repeating Class Settings'];
}

const CreateNewClass = (props) => {
  const { classes, OpenNoti, getCoaches, getprograms } = props;
  const [open, setOpen] = useState(false);
  // state declaration
  const [activeStep, setActiveStep] = useState(0);
  const [recurring, setRecurring] = useState(false);
  const steps = getSteps(recurring);
  const [commStep1, setCommStep1] = useState({
    program: null,
    ClassName: '',
    startTime: addHours(new Date(), 1),
    EndTime: addHours(new Date(), 2),
    attendanceLimit: true
  });
  const [ReccStep1, setReccStep1] = useState({
    endType: null,
    endDate: addMonths(new Date(), 1)
  });
  const [nonReccur, setNonReccur] = useState({
    Classlimit: false,
    headCoach: '',
    coCoach: ''
  });
  const [classLimit, setClassLimit] = useState({
    classlimit: 0,
    allowWaitlist: false,
    classOpen: null
  });
  const [customWeeks, setCustomWeeks] = useState([]);
  const [ReccWeek, setReccWeek] = useState({
    repeatType: null,
    repeatWeeks: null,
    reccWeeks: []
  });

  const [isprocessing, setprocessing] = useState(false);

  // useEffect
  useChangeTitle('Create New Class');
  useEffect(() => {
    if (
      commStep1.program
      && commStep1.startTime
      && checkClassName(commStep1.ClassName, commStep1.program)
    ) {
      setCommStep1({
        ...commStep1,
        ClassName: commStep1.program.name + ' ' + getTime(commStep1.startTime)
      });
    }
  }, [commStep1.program, commStep1.startTime]); // eslint-disable-line
  // function handling
  const history = useHistory();

  const handleNext = () => {
    const { getFuture, getAllClass, getReccClass, reloadCalender } = props;
    if (activeStep === 0) {
      if (!validClassStep1(commStep1, ReccStep1, recurring)) {
        OpenNoti('Please fill all the fields', 'error');
      } else {
        setActiveStep((prev) => prev + 1);
      }
    } else if (activeStep === 1) {
      if (
        !validClassStep2(
          nonReccur,
          classLimit,
          customWeeks,
          ReccWeek,
          recurring
        )
      ) {
        OpenNoti('Please fill all the fields', 'error');
      } else {
        const { recc, data } = getData(
          commStep1,
          ReccStep1,
          nonReccur,
          classLimit,
          customWeeks,
          ReccWeek,
          recurring
        );
        setprocessing(true);
        if (recc) {
          createReccClass(data)
            .then(() => getFuture())
            .then(() => getAllClass())
            .then(() => getReccClass())
            .then(() => reloadCalender())
            .then(() => history.goBack())
            .then(() => {
              OpenNoti('Successfluly created All the classes');
              setprocessing(false);
            })
            .catch((err) => {
              OpenNoti(getErrorMsg(err), 'error');
              setprocessing(false);
            });
        } else if (!recc) {
          createOneTime(data)
            .then(() => getAllClass())
            .then(() => getFuture())
            .then(() => reloadCalender())
            .then(() => history.goBack())
            .then(() => {
              OpenNoti('Successfully created the class');
              setprocessing(false);
            })
            .catch((err) => {
              OpenNoti(getErrorMsg(err), 'error');
              setprocessing(false);
            });
        }
      }
    }
  };

  const handleBack = () => {
    setActiveStep((prev) => prev - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  // set Default Values
  // debugging console.log
  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <div>
            <h3 style={{ marginBottom: '15px' }}>
              Specify the name, location, program and start/end times for the
              class
            </h3>
            <Grid
              item
              xs={12}
              className="switch-btn"
              style={{ marginBottom: '5px' }}
            >
              <FormControlLabel
                control={
                  <IOSSwitch
                    checked={recurring}
                    onChange={() => setRecurring(!recurring)}
                  />
                }
                label="Recurring/Repeating class"
              />
            </Grid>
            <Grid container spacing={0} direction="column" className="stap-3">
              <Grid item xs={4}>
                <InputLabel
                  htmlFor="age-required"
                  required
                  className={classes.cutom_select_Label}
                >
                  Program
                </InputLabel>
                <CustomAutocomplete
                  holder="Select Program"
                  data={commStep1.program}
                  Options="name"
                  value={getprograms}
                  onChange={(_, val) => {
                    setCommStep1({ ...commStep1, program: val });
                  }}
                  seterrors={!commStep1.program}
                  seterrortext="Please Select the Program"
                />
              </Grid>
              {!recurring && (
                <Grid item xs={4} style={{ marginTop: '15px' }} spacing={1}>
                  <JBDatePicker
                    label="Date"
                    required
                    minDate={new Date()}
                    value={commStep1.startTime}
                    onChange={(val) =>
                      setCommStep1({
                        ...commStep1,
                        startTime: val,
                        EndTime: addHours(val, 1)
                      })
                    } // eslint-disable-line
                  />
                </Grid>
              )}
              <Grid
                container
                spacing={0}
                xs={4}
                disableGutters
                direction="row"
                className="stap-3"
              >
                <Grid
                  item
                  xs
                  className={classes.time_box}
                  style={{ marginTop: '5px' }}
                >
                  <JBTimePicker
                    label="Start time"
                    value={commStep1.startTime}
                    error={checkStartTime(commStep1.startTime)}
                    onChange={(val) =>
                      setCommStep1({
                        ...commStep1,
                        startTime: val,
                        EndTime: addHours(val, 1)
                      })
                    } // eslint-disable-line
                  />
                  {checkStartTime(commStep1.startTime) && (
                    <p
                      className="Mui-error"
                      style={{
                        fontSize: '0.75rem',
                        marginTop: '3px',
                        textAlign: 'left',
                        fontFamily: 'Lato',
                        fontWeight: '400',
                        lineHeight: '1.66',
                        color: '#f44336'
                      }}
                    >
                      Can't create the Class of past date
                    </p>
                  )}
                </Grid>
              </Grid>

              <Grid
                container
                spacing={0}
                xs={4}
                disableGutters
                direction="row"
                className="stap-3"
              >
                <Grid
                  item
                  xs
                  className={classes.time_box}
                  style={{ marginTop: '5px' }}
                >
                  <JBTimePicker
                    label="End time"
                    error={checkEndTime(commStep1.startTime, commStep1.EndTime)}
                    value={commStep1.EndTime}
                    onChange={(val) =>
                      setCommStep1({ ...commStep1, EndTime: val })
                    } // eslint-disable-line
                  />
                  {checkEndTime(commStep1.startTime, commStep1.EndTime) && (
                    <p
                      className="Mui-error"
                      style={{
                        fontSize: '0.75rem',
                        marginTop: '3px',
                        textAlign: 'left',
                        fontFamily: 'Lato',
                        fontWeight: '400',
                        lineHeight: '1.66',
                        color: '#f44336'
                      }}
                    >
                      End Time cannot equal or less than Start Time
                    </p>
                  )}
                </Grid>
              </Grid>

              {recurring && (
                <Grid style={{ marginTop: '0' }}>
                  <Grid
                    item
                    xs={4}
                    style={{
                      marginTop: '15px',
                      marginRight: '10px',
                      float: 'none'
                    }}
                  >
                    <JBDatePicker
                      label="Start date"
                      value={commStep1.startTime}
                      required
                      minDate={new Date()}
                      onChange={(val) => {
                        setCommStep1({
                          ...commStep1,
                          startTime: val,
                          EndTime: addHours(val, 1)
                        });
                        setReccStep1({
                          ...reccurdata,
                          endDate: addMonths(val, 1)
                        });
                      }} // eslint-disable-line
                    />
                  </Grid>
                  <Grid
                    xs={12}
                    style={{
                      marginTop: '0',
                      display: 'inline-flex',
                      width: '100%'
                    }}
                  >
                    <Grid
                      item
                      xs={4}
                      style={{ marginTop: '15px', marginRight: '10px' }}
                    >
                      <InputLabel
                        htmlFor="age-required"
                        className={classes.cutom_select_Label}
                        style={{ padding: '2px 0 8px' }}
                        required
                      >
                        End Date
                      </InputLabel>
                      <CustomAutocomplete
                        holder="end type"
                        value={EndTypeData}
                        Options="name"
                        data={ReccStep1.endType}
                        onChange={(_, val) =>
                          setReccStep1({ ...ReccStep1, endType: val })
                        } // eslint-disable-line
                        seterrors={!ReccStep1.endType}
                        seterrortext="Please Select the end type"
                      />
                    </Grid>
                    {ReccStep1.endType && ReccStep1.endType.id === 'ondate' ? (
                      <Grid item xs={4} style={{ marginTop: '14px' }}>
                        <JBDatePicker
                          minDate={addDays(commStep1.startTime, 1)}
                          maxDate={addYears(commStep1.startTime, 1)}
                          maxDateMessage="Invalid Date cannot greater than 1 year"
                          // label="End Date"
                          value={ReccStep1.endDate}
                          onChange={(val) =>
                            setReccStep1({ ...ReccStep1, endDate: val })
                          } // eslint-disable-line
                        />
                      </Grid>
                    ) : null}
                  </Grid>
                </Grid>
              )}

              <Grid
                item
                xs={12}
                className="switch-btn"
                style={{ marginTop: '15px', marginBottom: '15px' }}
              >
                <FormControlLabel
                  control={
                    <IOSSwitch
                      checked={commStep1.attendanceLimit}
                      onChange={(e) =>
                        setCommStep1({
                          ...commStep1,
                          attendanceLimit: e.target.checked
                        })
                      } // eslint-disable-line
                      name="checkedB"
                    />
                  }
                  label="Count towards attendance limits"
                />
              </Grid>
              <Grid item lg={10} xl={8} style={{ padding: 0, margin: '0px' }}>
                <FormControl
                  control="input"
                  seterrors={commStep1.ClassName.length === 0}
                  seterrortext="Name cant be empty"
                  required
                  label="Class Name"
                  placeholder="Create Class Name"
                  value={commStep1.ClassName}
                  onChange={(e) =>
                    setCommStep1({
                      ...commStep1,
                      ClassName: validateString(e.target.value)
                    })
                  } // eslint-disable-line
                  style={{ margin: '0px 0' }}
                />
              </Grid>
            </Grid>
          </div>
        );
      case 1:
        // button onClick ={() => setVisible(1)}
        return recurring ? (
          <div>
            <Grid
              item
              xs={12}
              style={{ marginTop: '0px', marginBottom: '20px' }}
            >
              <h3
                style={{
                  marginBottom: '10px',
                  lineHeight: 'normal'
                }}
              >
                Assign a period for this repetition
              </h3>
            </Grid>

            <Grid
              xs={12}
              container
              direction="row"
              alignItems="center"
              style={{ marginTop: '0', display: 'flex' }}
            >
              <Grid
                item
                xs={4}
                style={{ marginTop: '0px', marginRight: '10px' }}
              >
                <InputLabel
                  htmlFor="age-required"
                  className={classes.cutom_select_Label}
                  required
                >
                  Repeat
                </InputLabel>
                <CustomAutocomplete
                  holder="Select Repeat type"
                  value={Repeatdata}
                  Options="name"
                  data={ReccWeek.repeatType}
                  onChange={(_, val) =>
                    setReccWeek({ ...ReccWeek, repeatType: val })
                  } // eslint-disable-line
                />
              </Grid>
              {ReccWeek.repeatType && ReccWeek.repeatType.id === 'custom' ? (
                <>
                  <Paper elevation={0}>
                    <InputLabel
                      htmlFor="age-required"
                      className={classes.cutom_select_Label}
                    >
                      &nbsp;
                    </InputLabel>
                    <p
                      style={{
                        textAlign: 'center',
                        fontWeight: 'bold',
                        padding: '0 10px'
                      }}
                    >
                      Repetition period
                    </p>
                  </Paper>
                  <Grid item xs={2} style={{ marginTop: '0px' }}>
                    <InputLabel
                      htmlFor="age-required"
                      className={classes.cutom_select_Label}
                    >
                      &nbsp;
                    </InputLabel>
                    <CustomAutocomplete
                      holder="Repeat"
                      Options="name"
                      data={ReccWeek.repeatWeeks}
                      onChange={(_, val) =>
                        setReccWeek({ ...ReccWeek, repeatWeeks: val })
                      } // eslint-disable-line
                      value={getRepeatWeek()}
                    />
                  </Grid>

                  <Paper elevation={0}>
                    <InputLabel
                      htmlFor="age-required"
                      className={classes.cutom_select_Label}
                    >
                      &nbsp;
                    </InputLabel>
                    <p
                      style={{
                        textAlign: 'center',
                        fontWeight: 'bold',
                        padding: '0 10px'
                      }}
                    >
                      Week(s)
                    </p>
                  </Paper>
                </>
              ) : null}
            </Grid>
            <Grid item xs={12} style={{ marginTop: '30px' }}>
              <h3 style={{ marginBottom: '10px', lineHeight: 'normal' }}>
                Choose days, assign a class limit and coaches, and enable a
                waitlist for the recurring class
              </h3>
            </Grid>
            <Grid container xs={12} style={{ marginTop: '20px' }}>
              {WeekData.map((data) => (
                <WeekButton
                  key={data.id}
                  label={data}
                  data={customWeeks.filter((list) => list.id === data.id)}
                  setWeeks={(val) => {
                    if (val) setCustomWeeks([...customWeeks, val]);
                    else {
                      setCustomWeeks((prev) =>
                        prev.filter((list) => list.id !== data.id));
                      setReccWeek({
                        ...ReccWeek,
                        reccWeeks: removeWeekdays(ReccWeek.reccWeeks, data)
                      });
                    }
                  }}
                />
              ))}
            </Grid>
            {customWeeks.length > 0
              && customWeeks.map((list) => (
                <RepeatWeek
                  key={list.id}
                  dataweek={list}
                  getRepeatWeek={(val) => {
                    setReccWeek({
                      ...ReccWeek,
                      reccWeeks: getRepWeek(ReccWeek.reccWeeks, val)
                    });
                  }}
                />
              ))}
          </div>
        ) : (
          <div>
            <h3 style={{ marginBottom: '15px' }}>
              Specify reservation settings and coaches for the class
            </h3>
            <Grid
              item
              xs={12}
              className="switch-btn"
              style={{ marginBottom: '5px' }}
            >
              <FormControlLabel
                control={
                  <IOSSwitch
                    checked={nonReccur.Classlimit}
                    onChange={() =>
                      setNonReccur({
                        ...nonReccur,
                        Classlimit: !nonReccur.Classlimit
                      })
                    } // eslint-disable-line
                  />
                }
                label="Class limit/Reservation"
              />
            </Grid>
            {nonReccur.Classlimit && (
              <Grid xs={12} style={{ marginTop: '0' }}>
                <Grid item xs={4} style={{ padding: 0, margin: '0px' }}>
                  <FormControl
                    control="input"
                    seterrors={checkValidNum(classLimit.classlimit)}
                    seterrortext="Please put valid class limit"
                    label="Class Limit"
                    placeholder="Specify max amount of members"
                    value={classLimit.classlimit}
                    required
                    onChange={(e) =>
                      setClassLimit({
                        ...classLimit,
                        classlimit: validateInt(e.target.value) // eslint-disable-line
                      })
                    } // eslint-disable-line
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  className="switch-btn"
                  style={{
                    display: 'flex',
                    margin: '15px 0'
                  }}
                >
                  <FormControlLabel
                    control={
                      <IOSSwitch
                        checked={classLimit.allowWaitlist}
                        onChange={(e) =>
                          setClassLimit({
                            ...classLimit,
                            allowWaitlist: e.target.checked
                          })
                        } // eslint-disable-line
                        name="checkedB"
                      />
                    }
                    label="Allow waitlist"
                  />
                </Grid>
                {classLimit.allowWaitlist && (
                  <div>
                    <Grid
                      item
                      xs={12}
                      style={{
                        display: 'flex',
                        margin: '10px 0'
                      }}
                    >
                      <Paper elevation={0}>
                        <p className={classes.ptext}>
                          When a spot in the class opens, people on the waitlist
                          are:
                        </p>
                      </Paper>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      style={{
                        display: 'flex',
                        margin: '10px 0'
                      }}
                    >
                      <RadioGroup
                        aria-label="quiz"
                        name="quiz"
                        value={classLimit.classOpen}
                        onChange={(e) =>
                          setClassLimit({
                            ...classLimit,
                            classOpen: e.target.value
                          })
                        } // eslint-disable-line
                      >
                        <FormControlLabel
                          value="1"
                          control={<Radio color="primary" />}
                          label="All emailed, first to reply gets added to the class."
                        />
                        <FormControlLabel
                          value="0"
                          control={<Radio color="primary" />}
                          label="Automatically added to the class in the order they signed up."
                        />
                      </RadioGroup>
                    </Grid>
                  </div>
                )}
              </Grid>
            )}
            <Grid
              xs={12}
              style={{
                marginTop: '0',
                display: 'inline-flex',
                width: '100%'
              }}
            >
              <Grid
                item
                xs={4}
                style={{ marginTop: '0px', marginRight: '10px' }}
              >
                <InputLabel
                  htmlFor="age-required"
                  className={classes.cutom_select_Label}
                  // required
                >
                  Head coach
                </InputLabel>
                <CustomAutocomplete
                  holder="Select head coach"
                  value={
                    nonReccur.coCoach
                      ? getCoaches.filter(
                          (list) => list.id !== nonReccur.coCoach.id // eslint-disable-line
                        ) // eslint-disable-line
                      : getCoaches
                  }
                  data={nonReccur.headCoach}
                  Options="name"
                  onChange={(_, val) =>
                    setNonReccur({ ...nonReccur, headCoach: val })
                  } // eslint-disable-line
                />
              </Grid>
              <Grid item xs={4} style={{ marginTop: '0px' }}>
                <InputLabel
                  htmlFor="age-required"
                  className={classes.cutom_select_Label}
                >
                  Co-Coach
                </InputLabel>
                <CustomAutocomplete
                  holder="Select co-coach"
                  value={
                    nonReccur.headCoach
                      ? getCoaches.filter(
                          (list) => list.id !== nonReccur.headCoach.id // eslint-disable-line
                        ) // eslint-disable-line
                      : getCoaches
                  }
                  data={nonReccur.coCoach}
                  Options="name"
                  onChange={(_, val) =>
                    setNonReccur({ ...nonReccur, coCoach: val })
                  } // eslint-disable-line
                />
              </Grid>
            </Grid>
          </div>
        );
      default:
        return 'Unknown step';
    }
  }
  return (
    <div className={classes.root}>
      <div className="title-bk">
        {/* <h3>
          Classes <label>/</label> <span>Calendar</span>
        </h3> */}
        <Breadcrumbs aria-label="breadcrumb" style={{ marginBottom: '21px' }}>
          <div
            color="inherit"
            onClick={() => setOpen(true)}
            style={{
              cursor: 'pointer'
            }}
          >
            Classes
          </div>
          <Typography variant="h3" color="Primary">
            <span>Calendar</span>
          </Typography>
        </Breadcrumbs>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={0}
          style={{ margin: '0 0 20px' }}
        >
          <Grid item xs={6}>
            <h1 style={{ margin: '0 0' }}>Create New Class</h1>
          </Grid>
          <Grid
            item
            xs={6}
            className={classes.btnBlock}
            style={{ justifyContent: 'flex-end', display: 'flex' }}
          >
            <CancelDialog />
          </Grid>
        </Grid>
      </div>
      <Card>
        <JBStepper
          steps={steps}
          activeStep={activeStep}
          handleBack={handleBack}
          handleNext={handleNext}
          handleReset={handleReset}
          // eslint-disable-next-line react/jsx-no-bind
          handleStepContent={getStepContent}
          isLoading={false}
          hasErrored={false}
          validateBtn={[
            getValidStep1(commStep1, recurring, ReccStep1),
            getValidStep2(
              nonReccur,
              recurring,
              classLimit,
              ReccWeek,
              customWeeks
            )
          ]}
          prevLabel="CREATE CLASS"
          nextLabel="NEXT STEP"
          isprocessing={isprocessing}
          processingLabel="Creating..."
          CustComp={(prop) => (
            <ColorlibStepIcon
              FirstIcon={BasicInformation}
              SecondIcon={recurring ? HistoryIcon : RIcon}
              {...prop} // eslint-disable-line
            />
          )}
        />
      </Card>
      <CancelDialogTwo setOpen={open} handleClose={() => setOpen(false)} />
    </div>
  );
};

CreateNewClass.propTypes = {
  classes: PropType.object.isRequired,
  history: PropType.any.isRequired,
  OpenNoti: PropType.func.isRequired,
  getCoaches: PropType.array.isRequired,
  getprograms: PropType.array.isRequired,
  getFuture: PropType.func.isRequired,
  getReccClass: PropType.func.isRequired,
  getAllClass: PropType.func.isRequired,
  reloadCalender: PropType.func.isRequired
};

const mapToDispatch = (dispatch) => ({
  OpenNoti: (msg, type) => dispatch(openNoti(msg, type)),
  getFuture: () => dispatch(futureddata()),
  getAllClass: () => dispatch(AllClassdata()),
  reloadCalender: () => dispatch(getCalenderData()),
  getReccClass: () => dispatch(reccurdata())
});

const mapToState = (state) => ({
  getprograms: state.allprograms.programs,
  getCoaches: state.classReducer.coaches
});

export default connect(
  mapToState,
  mapToDispatch
)(withStyles(styles)(CreateNewClass));
