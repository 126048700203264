import types from './Calender.types';

const initialValue = {
  data: [],
  defaultDate: new Date(),
  currentView: 'week',
  isFetching: false
};

const calenderReducer = (state = initialValue, action) => {
  switch (action.type) {
    case types.getData:
      return {
        ...state,
        isFetching: true
      };
    case types.fetchSucess:
      return {
        ...state,
        data: action.payload,
        isFetching: false
      };
    case types.changeDefault:
      return {
        ...state,
        defaultDate: action.payload
      };
    case types.changeView:
      return {
        ...state,
        currentView: action.payload
      };
    default:
      return state;
  }
};

export default calenderReducer;
