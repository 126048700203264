import React, { useState, useContext, useEffect } from 'react';
import PropType from 'prop-types';
import JBButton from 'src/views/common/JBButton/JBButton';
import { InputLabel, withStyles } from '@material-ui/core';
import { FilterContext } from 'src/views/common/ApplyFilter/applyFilter';
import { connect } from 'react-redux';
import { getMinDate } from 'src/utils/someCommon';
import JBDatePicker from 'src/views/common/FormControl/JBDatePicker';
import CustomAutoComplete from '../../../common/CustomAutocomplete/commonAutocomplete';
import styles from './filters.style';
import {
  Filters,
  MemType,
  getFilter,
  getfilterLabel,
  MemSince,
  checkData
} from './Filter.utils';

const MembershipTypeFilters = ({
  classes,
  getFilData,
  MembershipPlans,
  MembershipSessions
}) => {
  const [filter, setFilter] = useState(null);
  const [open, setOpen] = useContext(FilterContext);
  const [dateFilter, setDateFilter] = useState(null);
  const memberships = MembershipPlans.data.data.concat(
    MembershipSessions.data.data
  );
  const [data, setData] = useState(null);
  const [endDate, setendDate] = useState(() => getMinDate(new Date()));
  useEffect(() => {
    if (filter?.id === 3) {
      setData({ id: 0, name: 'All' });
    } else if (filter?.id === 2) {
      setData({ id: 'all', filter: 'All' });
    } else {
      setData(null);
    }
  }, [filter]);
  return (
    <div>
      <div>
        <CustomAutoComplete
          holder="Select Filter"
          style={{ marginBottom: '15px' }}
          Options="filter"
          value={Filters}
          data={filter}
          Change={(val) => setFilter(val)}
        />

        {(() => {
          switch (filter?.id) {
            case 1:
              return (
                <div>
                  <InputLabel
                    htmlFor="age-required"
                    className={classes.cutom_select_Label}
                    style={{ margin: '0px 0 5px' }}
                  >
                    Member Since
                  </InputLabel>
                  <CustomAutoComplete
                    holder="All time"
                    Options="filter"
                    value={MemSince}
                    data={dateFilter}
                    Change={(val) => setDateFilter(val)}
                    style={{ marginBottom: '15px' }}
                  />
                  {dateFilter?.id === 'custom' && (
                    <>
                      <InputLabel
                        htmlFor="age-required"
                        className={classes.cutom_select_Label}
                        style={{ margin: '5px 0' }}
                      >
                        From
                      </InputLabel>
                      <JBDatePicker
                        label="Start Date"
                        value={data}
                        onChange={(val) => {
                          setData(val);
                          setendDate(getMinDate(val));
                        }}
                      />
                      <InputLabel
                        htmlFor="age-required"
                        className={classes.cutom_select_Label}
                        style={{ padding: '20px 0px 5px' }}
                      >
                        To
                      </InputLabel>
                      <JBDatePicker
                        label="End Date"
                        minDate={
                          data ? getMinDate(data) : getMinDate(new Date())
                        }
                        value={endDate}
                        onChange={(val) => setendDate(val)}
                      />
                    </>
                  )}
                </div>
              );
            case 2:
              return (
                <div>
                  <InputLabel
                    htmlFor="age-required"
                    className={classes.cutom_select_Label}
                    style={{ margin: '0px 0 5px' }}
                  >
                    Membership Type
                  </InputLabel>
                  <CustomAutoComplete
                    value={MemType}
                    Options="filter"
                    data={data || null}
                    Change={(val) => setData(val)}
                    style={{ marginBottom: '15px' }}
                  />
                </div>
              );
            case 3:
              return (
                <div>
                  <InputLabel
                    htmlFor="age-required"
                    className={classes.cutom_select_Label}
                    style={{ margin: '0px 0 5px' }}
                  >
                    Membership plan
                  </InputLabel>
                  <CustomAutoComplete
                    value={[...memberships, { id: 0, name: 'All' }]}
                    Options="name"
                    data={data || null}
                    Change={(val) => setData(val)}
                    style={{ marginBottom: '15px' }}
                  />
                </div>
              );
            default:
              return null;
          }
        })()}
      </div>
      <JBButton
        type="primary"
        style={{
          width: '100%',
          // minHeight: '22px',
          padding: '7px 25px',
          marginTop: '10px'
        }}
        onClick={() => {
          getFilData(
            getFilter(filter, dateFilter, data, endDate),
            getfilterLabel(filter, dateFilter, data, endDate)
          );
          setOpen(!open);
        }}
        disabled={checkData(filter, data, dateFilter, endDate)}
      >
        Apply filter
      </JBButton>
    </div>
  );
};
const mapToState = (state) => ({
  MembershipPlans: state.membershipReducer.memberships,
  MembershipSessions: state.membershipReducer.sessions,
  allprogramsData: state.allprograms.programs
});
MembershipTypeFilters.propTypes = {
  classes: PropType.object.isRequired,
  MembershipPlans: PropType.array.isRequired,
  getFilData: PropType.func.isRequired,
  MembershipSessions: PropType.object.isRequired
};

export default connect(mapToState)(withStyles(styles)(MembershipTypeFilters));
