import { GymReducerType } from './GymReducer';
import { GymTimeFormat } from './GymSetting.data';

export function updateGymData(payload) {
  return {
    type: GymReducerType.updateData,
    payload
  };
}

export function updateGymDate(payload) {
  return {
    type: GymReducerType.updateDateFmt,
    payload
  };
}

export function updateGymTime(payload) {
  return {
    type: GymReducerType.updateTimeFmt,
    payload:
      payload === GymTimeFormat.twelve
        ? GymTimeFormat.twentyfour
        : GymTimeFormat.twelve
  };
}

export function updateGymTimezone(payload) {
  return {
    type: GymReducerType.updateTimeZone,
    payload
  };
}

export function updateGymWeek(payload) {
  return {
    type: GymReducerType.updateweekday,
    payload
  };
}

export function updateGymAge(payload) {
  return {
    type: GymReducerType.updateageMature,
    payload
  };
}
