import React from 'react';
import { Link } from 'react-router-dom';
import PropType from 'prop-types';
import { ROUTES } from 'src/constant/Constants';
import {
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  makeStyles,
  TableSortLabel,
  Button
} from '@material-ui/core';
import filterType from 'src/Redux/Reducer.constants';
import MakeDefaultDialog from './MakeDefaultDialog';

const useStyles = makeStyles({
  table: {
    wordBreak: 'break-word'
  },
  tableLink: {
    color: '#01847A',
    fontWeight: '700'
  },
  dayofweek: {
    background: '#A9BFEF',
    borderRadius: '50px',
    padding: '8px',
    color: '#FFF'
  },
  TooltipLink: {
    '&:hover': {
      '& svg path': {
        stroke: '#01847A'
      }
    }
  },
  TableCell: {
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px',
    borderBottom: '1px solid #A8A8A8',
    height: '56px',
    minHeight: '56px'
  },
  tablebtndefault: {
    width: '100px',
    height: '24px',
    color: '#01847A',
    border: '1px solid #01847A',
    borderRadius: '2px',
    fontWeight: 'bold',
    fontSize: '11px',
    lineHeight: '16px'
  },
  tablebtnmakeDe: {
    width: '100px',
    height: '24px',
    color: '#9B9B9B',
    border: '1px solid #949494',
    borderRadius: '2px',
    fontWeight: 'bold',
    fontSize: '11px',
    lineHeight: '16px'
  }
});

const Templatestable = (props) => {
  const classes = useStyles();
  const { values, isActive, setDirection, setSort } = props;
  const { sortname, sort_updated, sort_visibility } = filterType;
  return (
    <div>
      <TableContainer component={Paper} elevation={0}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow style={{ background: '#F9F9FA' }}>
              <TableCell className={classes.TableCell} style={{ width: '20%' }}>
                <TableSortLabel
                  active={isActive(sortname)}
                  direction={setDirection(sortname)}
                  onClick={() => setSort(sortname)}
                >
                  Waiver name
                </TableSortLabel>
              </TableCell>
              <TableCell className={classes.TableCell} style={{ width: '15%' }}>
                <TableSortLabel
                  active={isActive(sort_updated)}
                  direction={setDirection(sort_updated)}
                  onClick={() => setSort(sort_updated)}
                >
                  Updated
                </TableSortLabel>
              </TableCell>
              <TableCell className={classes.TableCell} style={{ width: '8%' }}>
                <TableSortLabel
                  active={isActive(sort_visibility)}
                  direction={setDirection(sort_visibility)}
                  onClick={() => setSort(sort_visibility)}
                >
                  Visibility
                </TableSortLabel>
              </TableCell>
              <TableCell className={classes.TableCell} style={{ width: '10%' }}>
                &nbsp;
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {values.map((row) => (
              <TableRow
                hover
                // role="checkbox"
                tabIndex={-1}
                key={row.id}
                style={{
                  backgroundColor: '#FFF'
                }}
              >
                <TableCell
                  style={{
                    position: 'relative',
                    color: '#01847A',
                    fontWeight: '700'
                  }}
                >
                  <Link
                    className={classes.tableLink}
                    to={{
                      pathname: ROUTES.DOCUMENTS_WAIVERS_TEMPLATES_DETAIL,
                      search: `waiverId=${row.id}`
                    }}
                  >
                    {row.name}
                  </Link>
                </TableCell>
                <TableCell>{row.updated}</TableCell>
                <TableCell>
                  {row.is_published === 1 ? 'Published' : 'Draft'}
                </TableCell>
                <TableCell align="center">
                  {row.is_default === 1 ? (
                    <Button className={classes.tablebtndefault}>default</Button>
                  ) : (
                    <MakeDefaultDialog
                      id={row.id}
                      name={row.name}
                      is_published={row.is_published}
                    />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {values.length === 0 && (
          <p
            style={{
              margin: '10px 15px'
            }}
          >
            No Templates to show...
          </p>
        )}
      </TableContainer>
    </div>
  );
};
Templatestable.propTypes = {
  values: PropType.array.isRequired,
  isActive: PropType.func.isRequired,
  setDirection: PropType.func.isRequired,
  setSort: PropType.func.isRequired
};

export default Templatestable;
