const styles = () => ({
  cutom_select_Label: {
    fontSize: '12px',
    '&:focus ': {
      outline: '0'
    }
  },
  FiltersWrapper: {
    background: '#fff'
  },
  cutom_date_select: {
    '& .MuiInput-root': {
      marginTop: '0px !important',
      fontSize: '14px'
    }
  },
  time_box: {
    '& div.MuiInput-root': {
      marginTop: '0px !important'
    }
  }
});
export default styles;
