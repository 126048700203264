/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import PropType from 'prop-types';
import {
  withStyles,
  DialogTitle,
  Typography,
  DialogContent,
  DialogActions,
  Dialog,
  Button
} from '@material-ui/core';
// import {
//   getActiveMem,
//   getInactivemem
// } from 'src/Redux/MemberReducer/memberReducer.actions';
import {
  AllLead,
  ProfileChange
} from 'src/Redux/LeadReducer/leadReducer.actions';
import { deleteUserImage } from 'src/services/Members.module';
import X from 'src/assets/image/x.png';
import { ReactComponent as DelIcon } from 'src/assets/image/del-icon.svg';
import { connect } from 'react-redux';
import { openNoti } from 'src/Redux/global/global.actions';
import { getErrorMsg } from 'src/utils/ErrorHandling';

const styles = () => ({
  dtitle: {
    color: '#292929',
    fontWeight: 'bold',
    fontSize: '33px',
    lineHeight: '26px'
  },
  cutom_select_Label: {
    fontSize: '12px',
    padding: '7px 0'
  },
  Ptext: {
    color: '#7F7F7F',
    fontSize: '14px',
    fontWeight: 'normal',
    lineHeight: '18px',
    letterSpacing: '0.002em',
    margin: '20px 0'
  },
  Imageuploadbk: {
    color: '#292929'
  },
  imgDelBtn: {
    position: 'absolute',
    right: '-2px',
    cursor: 'pointer',
    minWidth: '20px',
    padding: '0',
    borderRadius: '50%',
    marginTop: '0px',
    bottom: '0',
    zIndex: '99',
    color: '#000'
  },
  Deletebutton: {
    color: '#FB4E4E',
    border: '1px solid',
    height: '48px',
    marginRight: '15px',
    fontWeight: '700'
  },
  Deletebtn: {
    background: '#FB4E4E',
    boxShadow: '0px 8px 16px rgba(251, 78, 78, 0.16)',
    borderRadius: '2px',
    height: '48px',
    padding: '10px 15px',
    color: '#fff',
    fontWeight: '700',
    '&:hover': {
      background: '#FB4E4E'
    }
  },
  Dialogtitle: {
    padding: '16px 24px 0'
  },
  DialogActionsBk: {
    padding: '5px 20px 20px'
  }
});

function DeletePhotoDia({
  classes,
  userId,
  // handleSave,
  // reducer,
  // getActiveMembers,
  // getInActiveMembers,
  OpenNotify
}) {
  const [open, setOpen] = React.useState(false);
  // const [state, dispatch] = reducer;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    deleteUserImage(userId)
      .then(() => {
        OpenNotify('Delete Photo Successfully', 'info');
        ProfileChange(null);
        // handleSave(null);
        // if (state.userdata.data.status === 1) {
        //   getActiveMembers();
        // } else if (state.userdata.data.staus === 0) {
        //   getInActiveMembers();
        // }
      })
      .catch((err) => OpenNotify(getErrorMsg(err), 'error'));
  };

  return (
    <div className="imgDelBtnup">
      <Button
        color="primary"
        onClick={handleClickOpen}
        className={classes.imgDelBtn}
      >
        <DelIcon fontSize="small" />
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div style={{ width: 394 }}>
          <DialogTitle id="alert-dialog-title" className={classes.Dialogtitle}>
            <img
              alt="Plusicon"
              src={X}
              style={{ display: 'inline-block', verticalAlign: 'sub' }}
            />
            <Typography
              color="#000"
              variant="h5"
              component="div"
              className={classes.title}
              style={{ display: 'inline-block', marginLeft: '10px' }}
            >
              Delete photo
            </Typography>
          </DialogTitle>
          <DialogContent>
            <p
              style={{
                fontWeight: 'normal',
                fontSize: '14px',
                lineHeight: '18px',
                letterSpacing: '0.002em',
                color: '#7F7F7F',
                padding: '10px 0'
              }}
            >
              Are you sure you want to permanently delete this photo?
            </p>
          </DialogContent>
          <DialogActions className={classes.DialogActionsBk}>
            <Button
              variant="outlined"
              onClick={handleClose}
              style={{
                height: '48px',
                color: '#7F7F7F',
                fontWeight: 'bold'
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleDelete}
              autoFocus
              className={classes.Deletebtn}
            >
              delete
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}

DeletePhotoDia.propTypes = {
  classes: PropType.object.isRequired,
  // reducer: PropType.array.isRequired,
  userId: PropType.number.isRequired,
  OpenNotify: PropType.func.isRequired
  // getInActiveMembers: PropType.func.isRequired,
  // getActiveMembers: PropType.func.isRequired
};

const mapToDispatch = (dispatch) => ({
  OpenNotify: (msg, type) => dispatch(openNoti(msg, type)),
  getAllLead: () => dispatch(AllLead())
});

export default connect(null, mapToDispatch)(withStyles(styles)(DeletePhotoDia));
