import React, { createContext, useReducer } from 'react';
import PropType from 'prop-types';
import {
  initialState,
  VanguardWaiverReducer
} from './VanguardWaiver/VanguardWaiver.reducer';

export const VanguardWaiverCtx = createContext();

export const VanguardWaiverContext = ({ children }) => {
  const [state, dispatch] = useReducer(VanguardWaiverReducer, initialState);
  const value = [state, dispatch];
  return (
    <VanguardWaiverCtx.Provider value={value}>
      {children}
    </VanguardWaiverCtx.Provider>
  );
};

VanguardWaiverContext.propTypes = {
  children: PropType.node.isRequired
};
