export const DataObj = {
  isFetching: false,
  data: null,
  error: null
};

export function FilterObj(defaultVal) {
  return {
    page: 1,
    search: '',
    sorting: {},
    tags: [],
    customFilter: defaultVal,
    // customFilter: {},

    default: defaultVal
  };
}

export function getUpdatedValueObj(updatedVal) {
  let result = {};
  if (updatedVal.page) {
    result = {
      ...result,
      page: updatedVal.page
    };
  }
  if (updatedVal.default && Object.keys(updatedVal.default).length > 0) {
    result = {
      ...result,
      ...updatedVal.default
    };
  }
  if (
    // eslint-disable-next-line operator-linebreak
    updatedVal.customFilter &&
    Object.keys(updatedVal.customFilter).length > 0
  ) {
    result = {
      ...result,
      ...updatedVal.customFilter
    };
  }
  if (updatedVal.sorting && Object.keys(updatedVal.sorting).length > 0) {
    result = {
      ...result,
      ...updatedVal.sorting
    };
  }
  if (updatedVal?.search.length > 0) {
    result = {
      ...result,
      search: updatedVal.search
    };
  }
  return result;
}
