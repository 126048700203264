import React, { useCallback, useState } from 'react';
import { Grid, Paper } from '@material-ui/core';
import { connect } from 'react-redux';
import filterType from 'src/Redux/programReducers/programReducer.constants';
import Pagination from '@material-ui/lab/Pagination';
import PropType from 'prop-types';
import { withStyles } from '@material-ui/styles';
import _ from 'lodash';
import LoadingView from 'src/views/common/loading';
import {
  InActiveProgram,
  ProgAcDc
} from 'src/Redux/programReducers/progranReducer.actions';
import { openNoti } from 'src/Redux/global/global.actions';
import useChangeTitle from '../../common/CustomHooks/useTitleChange';
import InActiveProgramTable from './InactiveProgramTable';
import styles from './status.style';
import TabContainer from '../../common/TabContainer';
import FormControl from '../../common/FormControl/formControl';

const InactiveProgram = (props) => {
  const {
    classes,
    ProgramFilter,
    programs: { isFetching, data, error },
    programSort,
    progsearch,
    OpenNotiDisp
  } = props;

  const [search, setSearch] = useState(progsearch);
  useChangeTitle('Inactive Program  | Jungle Alliance');
  const handleActivate = (id) => {
    ProgAcDc(id, filterType.Activate)
      .then(() => OpenNotiDisp('Sucessfully Activated the Program', 'info'))
      .catch((err) => OpenNotiDisp(err.response.data.message, 'error'));
  };
  const isActive = (sortlabel) => {
    if (programSort.sort_name === sortlabel) return true;
    return false;
  };
  const setSort = (sortlabel) => {
    ProgramFilter(filterType.sorting, sortlabel);
  };
  const SearchQuery = useCallback(
    _.debounce((q) => ProgramFilter(filterType.search, q), 1000),
    []
  );

  if (error) return <p>{error}</p>;

  const onPaginate = (e, val) => {
    if (data.current_page !== val) ProgramFilter(filterType.page, val);
  };

  const setDirection = (name) => {
    if (programSort.sort_name === name) {
      return programSort.sort_by === 'asc' ? 'asc' : 'dsc';
    }
    return 'asc';
  };

  return (
    <TabContainer>
      <FormControl
        control="input"
        placeholder="Search... "
        value={search}
        onChange={(e) => {
          setSearch(e.target.value);
          SearchQuery(e.target.value);
        }}
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput
        }}
        style={{
          marginTop: 15,
          marginBottom: 15,
          color: '#01847A'
        }}
      />

      {isFetching ? (
        <LoadingView />
      ) : (
        <>
          <InActiveProgramTable
            data={data}
            classes={classes}
            setDirection={setDirection}
            isActive={isActive}
            setSort={setSort}
            handleActivate={handleActivate}
          />
          <Grid
            item
            xs={12}
            container
            direction="row"
            alignItems="center"
            style={{ marginTop: 24 }}
          >
            <Grid item xs={6} justify="flex-start">
              {/* {data.from && data.to && data.from > 1 && data.to > 1 ? (
            <Paper elevation={0} style={{ color: '#A9A9A9' }}>
              {`${data.from} - ${data.to}`}
            </Paper>
          ) : null} */}
              {data.from && data.to > 1 ? (
                <Paper elevation={0} style={{ color: '#A9A9A9' }}>
                  {`${data.from} - ${data.to} of ${data.total}`}
                </Paper>
              ) : null}
            </Grid>
            <Grid item xs={6} justify="flex-end" style={{ display: 'flex' }}>
              <Pagination
                count={data.last_page}
                variant="outlined"
                shape="rounded"
                color="primary"
                selected
                page={data.current_page}
                onChange={onPaginate}
                // classes={{ selected: classes.selected }}
              />
            </Grid>
          </Grid>
        </>
      )}
    </TabContainer>
  );
};

InactiveProgram.propTypes = {
  classes: PropType.object.isRequired,
  ProgramFilter: PropType.func.isRequired,
  programs: PropType.object.isRequired,
  progsearch: PropType.string.isRequired,
  OpenNotiDisp: PropType.func.isRequired,
  programSort: PropType.object.isRequired
};

const mapToDispatch = (dispatch) => ({
  ProgramFilter: (filter, data) => dispatch(InActiveProgram(filter, data)),
  OpenNotiDisp: (msg, type) => dispatch(openNoti(msg, type))
});

const mapToState = (state) => ({
  programs: state.allprograms.programInActiveList,
  progsearch: state.allprograms.programListInActiveFilter.search,
  programSort: state.allprograms.programListInActiveFilter.sorting
});

export default connect(
  mapToState,
  mapToDispatch
)(withStyles(styles)(InactiveProgram));
