const types = {
  getWODStart: 'GETWODSTART',
  getWODFAV_START: 'GETWODFAVSTART',
  getWODSucess: 'GETWODSUCESS',
  getWODfailure: 'GETWODFAILURE',
  addFavWOD: 'TOBESTARRED',
  WOD_LIST_DEFAULT: 'WOD_LISTWOD',
  WODfav: 'WODLIST_FAV',
  removeFavWOD: 'REMOVESTARRED',
  getTags: 'GET_ALL_WOD_TAGS',
  addWODfilter: 'ADD_CUSTOM_FILTER_WOD',
  addWODFavFilter: 'ADD_CUSTOM_FILTER_FAVWOD',
  removeWODfilter: 'REMOVE_WOD_FILTER',
  removeWODFavFilter: 'REMOVE_CUSTOM_FILTER_FAVWOD',
  removeFavStarred: 'REMOVE_STARRED_FAV',
  workoutsfilter: 'WORKSOUTS_FILTER',
  workoutsFavfilter: 'WORKS_FAV_FILTER',
  workouts: 'ALL_WORKOUTS',
  workoutsFav: 'WORKOUTS_FAVOURITE',
  selectAll: 'All_SELECTED'
};

export default types;
