/* eslint-disable operator-linebreak */
/* eslint-disable indent */
import React, { useState } from 'react';
import PropType from 'prop-types';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { openNoti } from 'src/Redux/global/global.actions';
import { checkValidString, isValidQuill } from 'src/utils/validations';
import { getErrorMsg } from 'src/utils/ErrorHandling';
import LoadingView from 'src/views/common/loading';
import {
  Grid,
  Card,
  Typography,
  Box,
  Breadcrumbs,
  InputLabel,
  withStyles,
  FormControlLabel,
  // Checkbox,
  Radio,
  RadioGroup,
  Button
} from '@material-ui/core';
import FormControl from 'src/views/common/FormControl/formControl';
// import JBquillEditor from 'src/views/common/JBQuillEditor/JBquillEditor';
import TextareaControl from 'src/views/common/FormControl/TextareaControl';
import JBCKEditor from 'src/views/common/JBCKEditor';
import IOSSwitch from 'src/views/common/FormControl/IOSSwitch';
import AddIcon from '@material-ui/icons/Add';
import { ROUTES } from 'src/constant/Constants';
import { checkQuillValid } from 'src/utils/someCommon';
import useChangeTitle from 'src/views/common/CustomHooks/useTitleChange';
import {
  // updateWaiverTemp,
  // getEditWaiver,
  createNewWaiver
} from '../../../services/Contracts.services';
import styles from './Waivers.style';
import { ReactComponent as Btnup } from '../../../assets/image/Btnup.svg';
import { ReactComponent as Btndown } from '../../../assets/image/Btndown.svg';
import { ReactComponent as Btnclose } from '../../../assets/image/Btnclose.svg';
import DeleteBtn from '../../../assets/image/dDelete.png';
import JBButton from '../../common/JBButton/JBButton';
import UpdateWaiversDialog from './UpdateWaiversDialog';
import CancelDialog from '../../common/CommonDialogBox/CancelDialog';
import CustomAutoComplete from '../../common/CustomAutocomplete/commonAutocomplete';
import CancelDialogTwo from '../../common/CommonDialogBox/CancelDialogTwo';
import { getTempWav } from '../../../Redux/WaiverReducer/WaiverReducer.actions';
import { AllQue } from './NewWaiver.data';
import { deleteMcqAns } from './newWaiver.utils';
import WaiverTooltip from './WaiverTooltip';

const CopyWaiver = (props) => {
  const {
    classes,
    openNotify,
    location: { state },
    WaivFilter
  } = props;

  const [data, setData] = useState({
    ...state.data,
    name: '',
    total_signed_users: 0
  });
  const history = useHistory();
  useChangeTitle('Copy Waiver | Jungle Alliance');
  const [que_id, setId] = useState(state.id);
  const [que, setQue] = useState(null);
  const [mcqOption, setMcqOption] = useState(3);
  const [cancelDialog, setcancelDialog] = useState(false);

  const handleQue = () => {
    if (que) {
      if (que === 'Yes/No with follow-up') {
        setData({
          ...data,
          questionnaire_meta: [
            ...data.questionnaire_meta,
            {
              id: que_id,
              que_type: que,
              que: null,
              required: 1,
              follow_up: null
            }
          ]
        });
      } else if (que === 'Multiple Choice') {
        setData({
          ...data,
          questionnaire_meta: [
            ...data.questionnaire_meta,
            {
              id: que_id,
              que_type: que,
              que: null,
              required: 1,
              answer_choices: [
                { id: 1, value: null },
                { id: 2, value: null }
              ],
              allow_multiple_ans: 0
            }
          ]
        });
      } else {
        setData({
          ...data,
          questionnaire_meta: [
            ...data.questionnaire_meta,
            {
              id: que_id,
              que_type: que,
              que: '',
              required: 1
            }
          ]
        });
      }
      setId(que_id + 1);
    }
  };
  const handleRequired = (index) => {
    const newArray = [...data.questionnaire_meta];
    newArray[index] = {
      ...newArray[index],
      required: !data.questionnaire_meta[index].required * 1
    };
    setData({
      ...data,
      questionnaire_meta: newArray
    });
  };
  const handleDelete = (index) => {
    setData({
      ...data,
      questionnaire_meta: [...data.questionnaire_meta].filter(
        (list) => list.id !== index
      )
    });
  };
  const handleUp = (index) => {
    const newArray = [...data.questionnaire_meta];
    const newIndex = newArray.findIndex((x) => x.id === index);
    if (newIndex > 0) {
      newArray[newIndex] = {
        ...newArray[newIndex],
        id: index - 1
      };
      newArray[newIndex - 1] = {
        ...newArray[newIndex - 1],
        id: index
      };

      newArray.sort((a, b) => {
        return a.id - b.id;
      });

      setData({
        ...data,
        questionnaire_meta: newArray
      });
    }
  };
  const handleDown = (index) => {
    const newArray = [...data.questionnaire_meta];
    const newIndex = newArray.findIndex((x) => x.id === index);
    if (newIndex < data.questionnaire_meta.length - 1) {
      newArray[newIndex] = {
        ...newArray[newIndex],
        id: index + 1
      };
      newArray[newIndex + 1] = {
        ...newArray[newIndex + 1],
        id: index
      };

      newArray.sort((a, b) => {
        return a.id - b.id;
      });
      setData({
        ...data,
        questionnaire_meta: newArray
      });
    }
  };
  const createMcqOption = (index) => {
    const newArray = [...data.questionnaire_meta];
    let createMcq = [...data.questionnaire_meta[index].answer_choices];

    createMcq = [
      ...createMcq,
      {
        id: mcqOption,
        value: null
      }
    ];
    newArray[index] = {
      ...newArray[index],
      answer_choices: createMcq
    };
    setData({
      ...data,
      questionnaire_meta: newArray
    });
    setMcqOption(mcqOption + 1);
  };
  const validateQues = (validateArray) => {
    for (let i = 0; i < validateArray.length; i += 1) {
      if (!validateArray[i].que || checkValidString(validateArray[i].que)) {
        return false;
      }
      if (validateArray[i].que_type === 'Multiple Choice') {
        for (let j = 0; j < validateArray[i].answer_choices.length; j += 1) {
          if (
            !validateArray[i].answer_choices[j].value ||
            validateArray[i].answer_choices[j].value.trim().length === 0
          ) {
            return false;
          }
        }
      }
    }
    return true;
  };
  const validateData = () => {
    if (!data.name) {
      openNotify('Waiver name is empty', 'error');
      return false;
    }
    if (checkValidString(data.name)) {
      openNotify('Please provide valid waiver name', 'error');
      return false;
    }
    if (data.is_published === 1) {
      if (
        !data.waiver_terms_meta.terms ||
        !checkQuillValid(data.waiver_terms_meta.terms)
      ) {
        openNotify('Membership Policy is required field', 'error');
        return false;
      }
      if (!validateQues(data.questionnaire_meta)) {
        openNotify('Please provide valid questionnaire details', 'error');
        return false;
      }
      if (!data.email_template.subject || !data.email_template.body) {
        openNotify('Please provide email subject and body', 'error');
        return false;
      }
      if (
        checkValidString(data.email_template.subject) ||
        checkValidString(data.email_template.body)
      ) {
        openNotify('Email subject and body are not valid', 'error');
        return false;
      }
    }
    return true;
  };
  const updateWaiver = (value) => {
    let result = [];
    result = {
      ...value,
      // waiver_terms_meta: JSON.stringify(value.waiver_terms_meta),
      terms: isValidQuill(value.waiver_terms_meta.terms)
        ? value.waiver_terms_meta.terms
        : `<p>${value.waiver_terms_meta.terms}</p>`,
      initial: value.waiver_terms_meta.initial,
      questionnaire_meta: JSON.stringify(value.questionnaire_meta),
      email_template: JSON.stringify(value.email_template)
    };
    delete result.waiver_terms_meta;
    if (data.is_published) {
      if (validateData()) {
        createNewWaiver(result)
          .then(() => {
            WaivFilter();
            history.push(ROUTES.DOCUMENTS_WAIVERS_TEMPLATES);
          })
          .then(() => openNotify(`Waiver "${result.name}" copied successfully`))
          .catch((err) => openNotify(getErrorMsg(err), 'error'));
      }
    } else {
      createNewWaiver(result)
        .then(() => {
          WaivFilter();
          history.push(ROUTES.DOCUMENTS_WAIVERS_TEMPLATES);
        })
        .then(() => openNotify(`Waiver "${result.name}" copied successfully`))
        .catch((err) => openNotify(getErrorMsg(err), 'error'));
    }
  };

  if (!state) return <LoadingView />;
  return (
    <div className={classes.root}>
      <div className="title-bk">
        <Breadcrumbs aria-label="breadcrumb">
          <div color="inherit">documents</div>
          <div
            color="inherit"
            onClick={() => setcancelDialog(true)}
            style={{
              cursor: 'pointer'
            }}
          >
            waivers
          </div>
          <Typography variant="h3" color="Primary">
            <span>templates</span>
          </Typography>
        </Breadcrumbs>
      </div>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        spacing={0}
        style={{ margin: '20px 0 20px' }}
      >
        <Grid item xs={6}>
          <h1 style={{ margin: '0 0' }}>Copy Waiver</h1>
        </Grid>
        <Grid
          item
          xs={6}
          className={classes.btnBlock}
          style={{ justifyContent: 'flex-end', display: 'flex' }}
        />
      </Grid>
      <Card className={clsx(classes.mainCard, classes.contractCard)}>
        <Grid container direction="row">
          <Grid item xs={3}>
            <h3 className={classes.headingTitle}>Basic Details</h3>
          </Grid>
          <Grid item xs={9}>
            <FormControl
              control="input"
              label="Waiver name"
              placeholder=""
              value={data.name}
              onChange={(e) => {
                setData({ ...data, name: e.target.value });
              }}
              required
              style={{ margin: '0 0' }}
            />
          </Grid>
        </Grid>
        <Box mt="50px" />
        <Grid container direction="row">
          <Grid item xs={3}>
            <h3 className={classes.headingTitle}>Waiver Terms</h3>
            <p className={clsx(classes.contrP, classes.mpP)}>
              Type or paste your current waiver terms into the text area below
              and format it appropriately.
            </p>
          </Grid>
          <Grid item xs={9}>
            <InputLabel
              htmlFor="age-required"
              className={classes.cutom_select_Label}
            >
              Waiver Terms
            </InputLabel>
            <JBCKEditor
              data={data?.waiver_terms_meta?.terms}
              onChange={(e) => {
                setData({
                  ...data,
                  waiver_terms_meta: {
                    ...data.waiver_terms_meta,
                    terms: e.editor.getData()
                  }
                });
              }}
            />
            {/* <JBquillEditor
              value={data.waiver_terms_meta.terms}
              theme="snow"
              onChange={(value) => {
                setData({
                  ...data,
                  waiver_terms_meta: {
                    ...data.waiver_terms_meta,
                    terms: value
                  }
                });
              }}
              ErrorText="Contract details must be fill valid values"
            /> */}
            <Box className={classes.Initialbox}>
              <FormControlLabel
                control={
                  <IOSSwitch
                    checked={data.waiver_terms_meta.initial}
                    onChange={() => {
                      setData({
                        ...data,
                        waiver_terms_meta: {
                          ...data.waiver_terms_meta,
                          initial: !data.waiver_terms_meta.initial * 1
                        }
                      });
                    }}
                  />
                }
                style={{ display: 'inline-block', margin: '0 0' }}
              />

              <InputLabel
                htmlFor="age-required"
                className={classes.cutom_Switch_Label}
              >
                Initial box
              </InputLabel>
              <WaiverTooltip style={{ display: 'inline-block' }} />
            </Box>
          </Grid>
        </Grid>

        <Box mt="50px" />
        <Grid container direction="row">
          <Grid item xs={3}>
            <h3 className={classes.headingTitle}>Questionnaire</h3>
            <p className={clsx(classes.contrP, classes.mpP)}>
              Create custom questions for your member to answer in regards to
              health history, exercise habits, injuries, etc.
            </p>
          </Grid>
          <Grid item xs={9}>
            <Grid item xs={12}>
              <Box className={classes.AddQuestion}>
                <InputLabel
                  htmlFor="age-required"
                  className={classes.cutom_select_Label}
                >
                  Add question
                </InputLabel>

                <CustomAutoComplete
                  holder={que || 'Add question type'}
                  data={que}
                  value={AllQue}
                  Options="name"
                  Change={(e) => {
                    // eslint-disable-next-line no-unused-expressions
                    e ? setQue(e.name) : setQue(null);
                  }}
                />
              </Box>
              <JBButton onClick={handleQue} type="primary">
                Add
              </JBButton>
            </Grid>
            <Box mt="30px" />
            <Grid item xs={12}>
              {data.questionnaire_meta.map((type, index) => {
                if (type.que_type === 'Yes/No') {
                  return (
                    <Box className={classes.QuestionBox}>
                      <Box className={classes.QueBtnWrapper}>
                        <h5>Yes/No</h5>
                        <Box className={classes.QueBtnBk}>
                          <Button onClick={() => handleUp(type.id)}>
                            <Btnup />
                          </Button>
                          <Button onClick={() => handleDown(type.id)}>
                            <Btndown />
                          </Button>
                          <Button onClick={() => handleDelete(type.id)}>
                            <Btnclose />
                          </Button>
                        </Box>
                      </Box>
                      <FormControl
                        control="input"
                        label="Type a question"
                        placeholder=""
                        value={type.que}
                        onChange={(e) => {
                          const newArray = [...data.questionnaire_meta];
                          newArray[index] = {
                            ...newArray[index],
                            que: e.target.value
                          };
                          setData({ ...data, questionnaire_meta: newArray });
                        }}
                        style={{ margin: '0 0' }}
                      />
                      <Box mt="25px" />
                      <Box>
                        <FormControlLabel
                          control={
                            <IOSSwitch
                              checked={data.questionnaire_meta[index].required}
                              onChange={() => handleRequired(index)}
                            />
                          }
                          style={{ display: 'inline-block', margin: '0 0' }}
                        />
                        <InputLabel
                          htmlFor="age-required"
                          className={classes.cutom_Switch_Label}
                        >
                          Required
                        </InputLabel>
                      </Box>
                    </Box>
                  );
                }
                if (type.que_type === 'Freeform Answer Box') {
                  return (
                    <Box className={classes.QuestionBox}>
                      <Box className={classes.QueBtnWrapper}>
                        <h5>Freeform Answer Box</h5>
                        <Box className={classes.QueBtnBk}>
                          <Button onClick={() => handleUp(type.id)}>
                            <Btnup />
                          </Button>
                          <Button onClick={() => handleDown(type.id)}>
                            <Btndown />
                          </Button>
                          <Button onClick={() => handleDelete(type.id)}>
                            <Btnclose />
                          </Button>
                        </Box>
                      </Box>
                      <FormControl
                        control="input"
                        label="Type a question"
                        placeholder=""
                        value={type.que}
                        onChange={(e) => {
                          const newArray = [...data.questionnaire_meta];
                          newArray[index] = {
                            ...newArray[index],
                            que: e.target.value
                          };
                          setData({ ...data, questionnaire_meta: newArray });
                        }}
                        style={{ margin: '0 0' }}
                      />
                      <Box mt="25px" />
                      <Box>
                        <FormControlLabel
                          control={
                            <IOSSwitch
                              checked={data.questionnaire_meta[index].required}
                              onChange={() => handleRequired(index)}
                            />
                          }
                          style={{ display: 'inline-block', margin: '0 0' }}
                        />
                        <InputLabel
                          htmlFor="age-required"
                          className={classes.cutom_Switch_Label}
                        >
                          Required
                        </InputLabel>
                      </Box>
                    </Box>
                  );
                }
                if (type.que_type === 'Multiple Choice') {
                  return (
                    <Box className={classes.QuestionBox}>
                      <Box className={classes.QueBtnWrapper}>
                        <h5>Multiple Choice</h5>
                        <Box className={classes.QueBtnBk}>
                          <Button onClick={() => handleUp(type.id)}>
                            <Btnup />
                          </Button>
                          <Button onClick={() => handleDown(type.id)}>
                            <Btndown />
                          </Button>
                          <Button onClick={() => handleDelete(type.id)}>
                            <Btnclose />
                          </Button>
                        </Box>
                      </Box>
                      <Box>
                        <FormControl
                          control="input"
                          label="Type a question"
                          placeholder=""
                          value={type.que}
                          onChange={(e) => {
                            const newArray = [...data.questionnaire_meta];
                            newArray[index] = {
                              ...newArray[index],
                              que: e.target.value
                            };
                            setData({ ...data, questionnaire_meta: newArray });
                          }}
                          style={{ margin: '0 0' }}
                        />
                      </Box>
                      {type.answer_choices.map((blank, index2) => {
                        return (
                          <Box display="flex" alignItems="center">
                            <FormControl
                              control="input"
                              label=""
                              value={blank.value}
                              onChange={(e) => {
                                const createMcq = [
                                  ...data.questionnaire_meta[index]
                                    .answer_choices
                                ];
                                createMcq[index2] = {
                                  ...createMcq[index2],
                                  value: e.target.value
                                };
                                const newArray = [...data.questionnaire_meta];
                                newArray[index] = {
                                  ...newArray[index],
                                  answer_choices: createMcq
                                };
                                setData({
                                  ...data,
                                  questionnaire_meta: newArray
                                });
                              }}
                              placeholder="Type answer here"
                              style={{ margin: '15px 0 0' }}
                            />

                            {type.answer_choices &&
                            type.answer_choices.length > 2 ? (
                              <Button
                                onClick={() => {
                                  setData({
                                    ...data,
                                    questionnaire_meta: deleteMcqAns(
                                      data.questionnaire_meta,
                                      type.id,
                                      blank.id
                                    )
                                  });
                                }}
                                className={classes.QueBtnDel}
                              >
                                <img src={DeleteBtn} alt="img" />
                              </Button>
                            ) : (
                              <></>
                            )}
                          </Box>
                        );
                      })}

                      <Box mt="20px" />
                      <JBButton
                        onClick={() => createMcqOption(index)}
                        type="outlined"
                      >
                        <AddIcon
                          className={classes.BtnAddIcon}
                          fontSize="small"
                        />
                        add an answer
                      </JBButton>
                      <Box mt="25px" />
                      <Box>
                        <FormControlLabel
                          control={
                            <IOSSwitch
                              checked={
                                data.questionnaire_meta[index]
                                  .allow_multiple_ans
                              }
                              onChange={() => {
                                const newArray = [...data.questionnaire_meta];
                                newArray[index] = {
                                  ...newArray[index],
                                  allow_multiple_ans:
                                    !data.questionnaire_meta[index]
                                      .allow_multiple_ans * 1
                                };
                                setData({
                                  ...data,
                                  questionnaire_meta: newArray
                                });
                              }}
                            />
                          }
                          style={{ display: 'inline-block', margin: '0 0' }}
                        />
                        <InputLabel
                          htmlFor="age-required"
                          className={classes.cutom_Switch_Label}
                        >
                          Allow multiple answers
                        </InputLabel>
                      </Box>
                      <Box>
                        <FormControlLabel
                          control={
                            <IOSSwitch
                              checked={data.questionnaire_meta[index].required}
                              onChange={() => {
                                const newArray = [...data.questionnaire_meta];
                                newArray[index] = {
                                  ...newArray[index],
                                  required:
                                    !data.questionnaire_meta[index].required * 1
                                };
                                setData({
                                  ...data,
                                  questionnaire_meta: newArray
                                });
                              }}
                            />
                          }
                          style={{ display: 'inline-block', margin: '0 0' }}
                        />
                        <InputLabel
                          htmlFor="age-required"
                          className={classes.cutom_Switch_Label}
                        >
                          Required
                        </InputLabel>
                      </Box>
                    </Box>
                  );
                }
                if (type.que_type === 'Yes/No with follow-up') {
                  return (
                    <Box className={classes.QuestionBox}>
                      <Box className={classes.QueBtnWrapper}>
                        <h5>Yes/No with follow-up</h5>
                        <Box className={classes.QueBtnBk}>
                          <Button onClick={() => handleUp(type.id)}>
                            <Btnup />
                          </Button>
                          <Button onClick={() => handleDown(type.id)}>
                            <Btndown />
                          </Button>
                          <Button onClick={() => handleDelete(type.id)}>
                            <Btnclose />
                          </Button>
                        </Box>
                      </Box>
                      <Box>
                        <FormControl
                          control="input"
                          label="Type a question"
                          value={type.que}
                          onChange={(e) => {
                            const newArray = [...data.questionnaire_meta];
                            newArray[index] = {
                              ...newArray[index],
                              que: e.target.value
                            };
                            setData({ ...data, questionnaire_meta: newArray });
                          }}
                          placeholder=""
                          style={{ margin: '0 0' }}
                        />
                      </Box>
                      <Box>
                        <FormControl
                          control="input"
                          label="Type a follow-up question"
                          placeholder="Type answer here"
                          value={type.follow_up}
                          onChange={(e) => {
                            const newArray = [...data.questionnaire_meta];
                            newArray[index] = {
                              ...newArray[index],
                              follow_up: e.target.value
                            };
                            setData({ ...data, questionnaire_meta: newArray });
                          }}
                          style={{ margin: '15px 0 0' }}
                        />
                      </Box>
                      <Box mt="25px" />
                      <Box>
                        <FormControlLabel
                          control={
                            <IOSSwitch
                              checked={data.questionnaire_meta[index].required}
                              onChange={() => {
                                const newArray = [...data.questionnaire_meta];
                                newArray[index] = {
                                  ...newArray[index],
                                  required:
                                    !data.questionnaire_meta[index].required * 1
                                };
                                setData({
                                  ...data,
                                  questionnaire_meta: newArray
                                });
                              }}
                            />
                          }
                          style={{ display: 'inline-block', margin: '0 0' }}
                        />
                        <InputLabel
                          htmlFor="age-required"
                          className={classes.cutom_Switch_Label}
                        >
                          Required
                        </InputLabel>
                      </Box>
                    </Box>
                  );
                }
                return null;
              })}
            </Grid>
          </Grid>
        </Grid>
        <Box mt="50px" />
        <Grid container direction="row">
          <Grid item xs={3}>
            <h3 className={classes.headingTitle}>Email Template</h3>
            <p className={clsx(classes.contrP, classes.mpP)}>
              Customize the email template that will be sent to the member,
              including a link to the waiver,
            </p>
          </Grid>
          <Grid item xs={9}>
            <FormControl
              control="input"
              label="Email subject"
              placeholder=""
              style={{ margin: '0 0' }}
              value={data.email_template.subject}
              onChange={(e) => {
                setData({
                  ...data,
                  email_template: {
                    ...data.email_template,
                    subject: e.target.value
                  }
                });
              }}
            />
            <Box mt="15px" />
            <TextareaControl
              value={data.email_template.body}
              onChange={(e) => {
                setData({
                  ...data,
                  email_template: {
                    ...data.email_template,
                    body: e.target.value
                  }
                });
              }}
              rows={8}
              label="Text"
              className={classes.TextareaControlBox}
            />
          </Grid>
        </Grid>

        <Box mt="10px" />
        <Grid container direction="row">
          <Grid item xs={3} />
          <Grid item xs={9}>
            <Grid container direction="row">
              <Grid item xs={12}>
                <Box mt="30px">
                  <p className={clsx(classes.contrP)}>Visibility</p>
                  <Grid item xs={4}>
                    <RadioGroup
                      value={String(data.is_published)}
                      onChange={(e) => {
                        setData({ ...data, is_published: e.target.value * 1 });
                      }}
                      style={{
                        display: 'inline-block'
                      }}
                    >
                      <FormControlLabel
                        value="1"
                        control={<Radio color="primary" />}
                        label="Published"
                      />
                      <FormControlLabel
                        value="0"
                        control={<Radio color="primary" />}
                        label="Draft (not available for members to sign)"
                      />
                    </RadioGroup>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container direction="row" justify="flex-end" alignItems="center">
          {/* <JBButton
            type="outlined"
            // component={Link}
            // to={ROUTES.PEOPLE_CREATE_MEMBER}

            style={{ marginRight: '10px' }}
          >
            Cancel
          </JBButton> */}
          <CancelDialog handleHistory={ROUTES.DOCUMENTS_WAIVERS_TEMPLATES} />
          {data.is_published && data.total_signed_users !== 0 ? (
            <UpdateWaiversDialog
              values={data}
              handleStatus={(id) => {
                setData({ ...data, need_resign: id });
              }}
              handleValidate={() => validateData()}
              handleSave={() => updateWaiver(data)}
            />
          ) : (
            <div style={{ marginLeft: '10px' }}>
              <JBButton type="primary" onClick={() => updateWaiver(data)}>
                Save waiver
              </JBButton>
            </div>
          )}
        </Grid>
        <CancelDialogTwo
          setOpen={cancelDialog}
          handleHistory={ROUTES.DOCUMENTS_WAIVERS_TEMPLATES}
          handleClose={() => setcancelDialog(false)}
        />
      </Card>
    </div>
  );
};

CopyWaiver.propTypes = {
  classes: PropType.object.isRequired,
  WaivFilter: PropType.func.isRequired,
  openNotify: PropType.func.isRequired,
  location: PropType.object.isRequired
};
const mapToDispatch = (dispatch) => ({
  openNotify: (msg, type) => dispatch(openNoti(msg, type)),
  WaivFilter: (filter, data) => dispatch(getTempWav(filter, data))
});
export default connect(null, mapToDispatch)(withStyles(styles)(CopyWaiver));
