/* eslint-disable indent */
import React, { useState, useContext, useEffect } from 'react';
import { VanguardMem } from 'src/Context/VanguardMembershipContext';
import {
  setUserData,
  setDefaultPaymentMet,
  setCardName
} from 'src/Context/VanguardMembership/VanguardMembership.actions';
import { getCountries } from 'src/services/Members.module';
import {
  Typography,
  makeStyles,
  Grid,
  FormControlLabel,
  Checkbox,
  Box,
  InputLabel,
  Button
} from '@material-ui/core';
import clsx from 'clsx';
import CheckIcon from '@material-ui/icons/Check';
import FormControl from 'src/views/common/FormControl/formControl';
import JBVanguardButton from 'src/views/common/JBVanguardButton/JBButton';
import StripeCard from 'src/views/common/StripeComponent/StripeCard';
import {
  checkValidString,
  isPhoneNumberInvalid,
  isValidPostal
} from 'src/utils/validations';
import { ReactComponent as EditIcon } from '../../../../assets/image/Vanguard/editIcon.svg';
import { ReactComponent as DownIcon } from '../../../../assets/image/Vanguard/downIcon.svg';
import { getFullAddress } from '../Vanguard.utills';
import CustomAutocomplete from '../../../common/CustomAutocomplete/commonAutocomplete';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    background: 'rgba(29, 29, 29, 0.94)',
    boxShadow: '0px 0.33333px 0px rgb(255 255 255 / 15%)',
    backdropFilter: 'blur(54.3656px)'
  },
  appToolbar: {
    minHeight: '75px',
    justifyContent: 'space-between'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
  mainTitle: {
    fontWeight: '500',
    fontSize: '48px',
    lineHeight: '44px',
    color: '#FFFFFF',
    fontFamily: 'DDC Hardware',
    textAlign: 'center',
    margin: '38px 0 0 0'
  },
  MainCard: {
    height: 'calc(100vh - 75px )',
    background:
      'linear-gradient(180deg, rgba(41, 41, 41, 0.95) 0%, rgba(4, 4, 4, 0.95) 100%)',
    borderRadius: '0',
    flex: '1 1 auto',
    // height: '100%',
    overflow: 'auto'
  },
  EditBtn: {
    fontFamily: 'Lato',
    color: '#2CE9DA',
    fontWeight: 'bold',
    fontSize: '22px',
    lineHeight: '32px',
    textTransform: 'capitalize',
    position: 'absolute',
    right: '0',
    margin: '20px 10px 15px 0px'
  },
  cutom_select_Label: {
    fontFamily: 'DDC Hardware',
    fontStyle: 'normal',
    fontWeight: '500;',
    fontSize: '22px',
    lineHeight: '32px',
    letterSpacing: '0.2px',
    color: '#FFFFFF',
    '&:focus ': {
      outline: '0'
    },
    '& .MuiFormLabel-asterisk': {
      color: 'transparent'
    },
    '& .MuiFormLabel-asterisk:after': {
      content: '""',
      background: '#2CE9DA',
      display: 'inline-block',
      position: 'relative',
      width: '8px',
      height: '8px',
      borderRadius: '50%',
      top: '-14px',
      right: '10px'
    }
  },
  cutom_vanguard_FormControl: {
    '& .fd-input input': {
      background: 'rgba(196, 196, 196, 0.05)',
      color: '#fff',
      borderRadius: '6px',
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '20px',
      height: '45px',
      border: '2px solid #C8C8C8',
      padding: '8px 20px 8px',
      '&::placeholder ': {
        color: '#fff',
        fontWeight: 'bold',
        fontSize: '15px'
      }
    },
    '&  .MuiPaper-root': {
      backgroundColor: '#2d2d2d',
      marginTop: '0',
      marginBottom: '0',
      border: '2px solid #C8C8C8',
      borderTop: 'transparent',
      boxShadow: 'none',
      // borderRadius: '0',
      borderTopLeftRadius: '0',
      borderTopRightRadius: '0'
    },
    '& .MuiAutocomplete-option': {
      color: '#fff',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '20px',
      height: '66px'
    },
    '& .MuiAutocomplete-popper': {
      width: '100% !important',
      marginTop: '-2px'
    },
    '& .SelectDrop[aria-expanded="true"]': {
      borderBottom: '2px solid transparent',
      borderBottomLeftRadius: '0',
      borderBottomRightRadius: '0'
    }
  },
  cutom_vanguard_Drop: {
    '& .MuiAutocomplete-popper ': {
      width: 'calc(100% - 16px) !important'
    }
  },
  cutom_vanguard_Autocomplete: {
    background: 'rgba(196, 196, 196, 0.05)',
    border: '2px solid #C8C8C8',
    color: '#fff',
    borderRadius: '6px',
    '& .cutom_select div': {
      minHeight: '62px'
    },
    '& .cutom_select div input ': {
      color: '#ffffff !important',
      fontWeight: 'bold',
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontSize: '20px'
    },
    '& .MuiAutocomplete-endAdornment': {
      minHeight: ' auto !important',
      top: 'calc(50% - 12px)'
    }
  },
  customRadio: {
    '& .MuiFormControlLabel-root': {
      background: 'rgba(196, 196, 196, 0.05)',
      border: '2px solid #7B7B7B',
      borderRadius: '6px',
      height: '70px',
      margin: '0 0 15px'
    },
    '& .MuiFormControlLabel-root.checked': {
      background: 'rgba(44, 233, 218, 0.15)',
      border: '2px solid #2CE9DA',
      borderRadius: '6px'
    },
    '& .MuiFormControlLabel-root svg.MuiSvgIcon-root': {
      width: '30px',
      height: '30px',
      fill: 'none',
      borderRadius: '50%',
      border: '2px solid #4B4B4B'
    },
    '& .MuiFormControlLabel-root.checked svg.MuiSvgIcon-root': {
      background: '#67e9da',
      padding: '4px',
      fill: '#000',
      color: '#060606',
      border: '0px solid #4B4B4B'
    },
    '& .MuiRadio-root': {
      padding: '20px'
    },
    '& .MuiFormControlLabel-label': {
      color: '#fff',
      fontSize: '20px',
      lineHeight: '26px',
      fontWeight: 'bold'
    }
  },
  VanCustomcheckbox: {
    width: '100%',
    '& .MuiFormControlLabel-root svg.MuiSvgIcon-root': {
      width: '30px',
      height: '30px',
      fill: 'none',
      borderRadius: '6px',
      border: '2px solid #4B4B4B'
    },
    '& .MuiFormControlLabel-root.checked svg.MuiSvgIcon-root': {
      background: '#67e9da',
      padding: '4px',
      fill: '#000',
      color: '#060606',
      border: '0px solid #4B4B4B'
    },
    '& .MuiTypography-root': {
      padding: '10px 15px'
    },
    '& .MuiFormControlLabel-label': {
      color: '#fff',
      fontSize: '20px',
      lineHeight: '26px',
      fontWeight: 'bold'
    }
  },
  subTitle: {
    fontWeight: 'bold',
    fontSize: '24px',
    lineHeight: '38px',
    color: '#FFFFFF',
    fontFamily: 'Lato',
    textAlign: 'center',
    margin: '38px 0 0 0',
    letterSpacing: '0.1px'
  },
  StripeGrid: {
    '& > div': {
      color: '#FFFFFF',
      padding: '20px 15px',
      border: '2px solid #C8C8C8',
      fontSize: '20px',
      background: 'rgba(196, 196, 196, 0.05)',
      fontStyle: 'normal',
      fontWeight: '700',
      borderRadius: '6px',
      '& .ElementsApp': {
        color: '#FFFFFF',
        fontSize: '20px',
        fontWeight: '700'
      }
    }
  }
}));

const CARD_OPTIONS = {
  iconStyle: 'solid',
  hidePostalCode: true,
  style: {
    base: {
      iconColor: '#c4f0ff',
      fontWeight: 700,
      fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
      fontSize: '20px',
      color: '#fff',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': {
        color: '#fce883'
      },
      '::placeholder': {
        color: '#7F7F7F'
      }
    },
    invalid: {
      iconColor: '#ffc7ee'
    }
  }
};

const CreditCard = () => {
  const [touched, setTouched] = useState({ cardName: false });
  const classes = useStyles();
  const [countries, setCountries] = useState(null);
  const [state, dispatch] = useContext(VanguardMem);
  const {
    personal_info: { user_details },
    memebership_data: {
      membershipdata: { payment_data }
    }
  } = state;
  const [EditAdd, setEditAdd] = useState(false);
  const [fullAdress, setFullAdress] = useState(() =>
    getFullAddress({
      address: user_details.address,
      city: user_details.city,
      province: user_details.province,
      country: user_details.country,
      postal: user_details.postal
    }));
  const handleChange = (value, name) => {
    dispatch(setUserData({ name, info: value }));
  };
  const handleBlur = (e) => {
    const { name } = e.target;
    setTouched({ [name]: true });
  };
  useEffect(() => {
    getCountries().then((res) => setCountries(res));
  }, []);
  return (
    <div>
      <Grid xs={12}>
        <Box mt="10px" className={classes.VanCustomcheckbox}>
          <FormControlLabel
            // onChange={(e) => {
            //   setData({ ...data, value: e.target.value });
            // }}
            control={
              <Checkbox
                checked={payment_data.is_default === 1}
                onChange={() =>
                  dispatch(
                    setDefaultPaymentMet(payment_data.is_default ? 0 : 1)
                  )
                }
                name="checkedB"
                color="primary"
                checkedIcon={
                  <CheckIcon htmlColor="#2CE9DA" width="30px" height="30px" />
                }
              />
            }
            label="Make default payment method"
            className={`${payment_data.is_default === 1 ? 'checked' : ''}`}
          />
        </Box>
        <Typography variant="h5" className={classes.subTitle}>
          Billing Information
        </Typography>
      </Grid>
      <Grid
        container
        direction="row"
        // justify="center"
        alignItems="center"
        spacing={2}
        style={{ margin: '20px 0 20px' }}
      >
        <Grid item xs={6} className={classes.cutom_vanguard_FormControl}>
          <InputLabel
            htmlFor="age-required"
            required
            className={classes.cutom_select_Label}
            // style={{ margin: '0px 0 5px' }}
          >
            FIRST Name
          </InputLabel>
          <FormControl
            control="input"
            value={user_details.first_name}
            onChange={(e) => {
              handleChange(e.target.value, 'first_name');
            }}
            error={!user_details.first_name}
            helperText={
              !user_details.first_name ? 'please enter  first name' : null
            }
            // label="Description"
            // maxLength={50}
            style={{
              margin: '0 0'
            }}
          />
        </Grid>
        <Grid item xs={6} className={classes.cutom_vanguard_FormControl}>
          <InputLabel
            htmlFor="age-required"
            required
            className={classes.cutom_select_Label}
            // style={{ margin: '0px 0 5px' }}
          >
            LAST Name
          </InputLabel>
          <FormControl
            control="input"
            value={user_details.last_name}
            onChange={(e) => handleChange(e.target.value, 'last_name')}
            error={!user_details.last_name}
            helperText={
              !user_details.last_name ? 'please enter last name' : null
            }
            // label="Description"
            // maxLength={50}
            style={{
              margin: '0 0'
            }}
          />
        </Grid>

        <Grid
          item
          xs={6}
          className={classes.cutom_vanguard_FormControl}
          style={{
            margin: '10px 0'
          }}
        >
          <InputLabel
            htmlFor="age-required"
            required
            className={classes.cutom_select_Label}
            // style={{ margin: '0px 0 5px' }}
          >
            Phone Number
          </InputLabel>
          <FormControl
            control="input"
            value={user_details.phone_num}
            onChange={(e) => handleChange(e.target.value, 'phone_num')}
            error={isPhoneNumberInvalid(user_details.phone_num)}
            helperText={
              isPhoneNumberInvalid(user_details.phone_num)
                ? 'please enter a valid phone number'
                : null
            }
            // label="Description"
            // maxLength={50}
            style={{
              margin: '0 0'
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          className={classes.cutom_vanguard_FormControl}
          style={{
            margin: '0px 0 0px',
            position: 'relative'
          }}
        >
          <InputLabel
            htmlFor="age-required"
            required
            className={classes.cutom_select_Label}
            // style={{ margin: '0px 0 5px' }}
          >
            STREET, CITY, STATE/PROVINCE, ZIP CODE
          </InputLabel>
          <FormControl
            control="input"
            disabled
            value={fullAdress}
            // onChange={(e) =>
            //   setFormState({
            //     ...formState,
            //     description: validateString(e.target.value)
            //   })
            // } // eslint-disable-line
            // label="Description"
            // maxLength={50}
            style={{
              margin: '0 0'
            }}
          />
          <Button
            color="inherit"
            className={classes.EditBtn}
            onClick={() => setEditAdd(!EditAdd)}
          >
            <EditIcon />
          </Button>
        </Grid>

        {EditAdd && (
          <>
            <Grid
              item
              xs={6}
              className={classes.cutom_vanguard_FormControl}
              style={{
                margin: '10px 0'
              }}
            >
              <InputLabel
                htmlFor="age-required"
                required
                className={classes.cutom_select_Label}
                // style={{ margin: '0px 0 5px' }}
              >
                Street address line 1
              </InputLabel>
              <FormControl
                control="input"
                value={user_details.address}
                onChange={(e) => handleChange(e.target.value, 'address')}
                error={checkValidString(user_details.address)}
                helperText={
                  checkValidString(user_details.address)
                    ? 'please enter a valid address'
                    : null
                }
                // label="Description"
                // maxLength={50}
                style={{
                  margin: '0 0'
                }}
              />
            </Grid>
            <Grid
              item
              xs={6}
              className={classes.cutom_vanguard_FormControl}
              style={{
                margin: '10px 0'
              }}
            >
              <InputLabel
                htmlFor="age-required"
                className={classes.cutom_select_Label}
                // style={{ margin: '0px 0 5px' }}
              >
                Street address line 2
              </InputLabel>
              <FormControl
                control="input"
                // value={description}
                // onChange={(e) =>
                //   setFormState({
                //     ...formState,
                //     description: validateString(e.target.value)
                //   })
                // } // eslint-disable-line
                // label="Description"
                // maxLength={50}
                style={{
                  margin: '0 0'
                }}
              />
            </Grid>
            <Grid
              item
              xs={6}
              className={classes.cutom_vanguard_FormControl}
              style={{
                margin: '10px 0'
              }}
            >
              <InputLabel
                htmlFor="age-required"
                required
                className={classes.cutom_select_Label}
                // style={{ margin: '0px 0 5px' }}
              >
                CITY
              </InputLabel>
              <FormControl
                control="input"
                value={user_details.city}
                onChange={(e) => handleChange(e.target.value, 'city')}
                error={checkValidString(user_details.city)}
                helperText={
                  checkValidString(user_details.city)
                    ? 'please enter a valid city name'
                    : null
                }
                // label="Description"
                // maxLength={50}
                style={{
                  margin: '0 0'
                }}
              />
            </Grid>
            <Grid
              item
              xs={6}
              className={classes.cutom_vanguard_FormControl}
              style={{
                margin: '10px 0'
              }}
            >
              <InputLabel
                htmlFor="age-required"
                required
                className={classes.cutom_select_Label}

                // style={{ margin: '0px 0 5px' }}
              >
                PROVINCE
              </InputLabel>
              <FormControl
                control="input"
                value={user_details.province}
                onChange={(e) => handleChange(e.target.value, 'province')}
                error={checkValidString(user_details.province)}
                helperText={
                  checkValidString(user_details.province)
                    ? 'pleaser enter a valid  province'
                    : null
                }
                // label="Description"
                // maxLength={50}
                style={{
                  margin: '0 0'
                }}
              />
            </Grid>
            <Grid
              item
              xs={6}
              className={clsx(
                classes.cutom_vanguard_FormControl,
                classes.cutom_vanguard_Drop
              )}
              style={{
                position: 'relative'
              }}
            >
              <InputLabel
                htmlFor="age-required"
                required
                className={classes.cutom_select_Label}
                style={{ margin: '0px 0 6px' }}
              >
                Country
              </InputLabel>
              <CustomAutocomplete
                // holder="Lead source"
                disablePortal
                value={countries}
                Options="name"
                data={user_details.country || null}
                popupIcon={<DownIcon />}
                Change={(val) => handleChange(val, 'country')}
                disableClearable
                // className={classes.cutom_vanguard_Autocomplete}
                className={clsx(
                  classes.cutom_vanguard_Autocomplete,
                  'SelectDrop'
                )}
                // Change={(val) => {
                //   setData1({ ...data1, lead_source: val });
                // }}
              />
            </Grid>

            <Grid
              item
              xs={6}
              className={classes.cutom_vanguard_FormControl}
              style={{
                margin: '10px 0'
              }}
            >
              <InputLabel
                htmlFor="age-required"
                required
                className={classes.cutom_select_Label}
                // style={{ margin: '0px 0 5px' }}
              >
                Postal code
              </InputLabel>
              <FormControl
                control="input"
                value={user_details.postal}
                onChange={(e) => handleChange(e.target.value, 'postal')}
                error={!isValidPostal(user_details.postal)}
                helperText={
                  !isValidPostal(user_details.postal)
                    ? 'please enter a valid postal'
                    : null
                }
                // label="Description"
                // maxLength={50}
                style={{
                  margin: '0 0'
                }}
              />
            </Grid>

            <Grid
              item
              xs={12}
              style={{
                justifyContent: 'flex-end',
                display: 'flex'
              }}
            >
              <JBVanguardButton
                type="primary"
                style={{
                  marginTop: '15px',
                  width: '250px',
                  minHeight: '70px'
                }}
                onClick={() => {
                  setFullAdress(
                    getFullAddress({
                      address: user_details.address,
                      city: user_details.city,
                      province: user_details.province,
                      country: user_details.country,
                      postal: user_details.postal
                    })
                  );
                  setEditAdd((prev) => !prev);
                }}
              >
                Save address
              </JBVanguardButton>
            </Grid>
          </>
        )}
        <Grid
          item
          xs={12}
          style={{
            justifyContent: 'center',
            display: 'flex'
          }}
        >
          <Typography variant="h5" className={classes.subTitle}>
            Card Information
          </Typography>
        </Grid>
        <Grid
          container
          spacing={2}
          style={{ alignItems: 'center', padding: '0px 0 10px' }}
        >
          <Grid
            item
            xs={12}
            className={classes.cutom_vanguard_FormControl}
            style={{
              paddingLeft: '16px',
              paddingRight: '16px'
            }}
          >
            <InputLabel
              htmlFor="age-required"
              required
              className={classes.cutom_select_Label}
              style={{ padding: '5px 0' }}
            >
              Name on card
            </InputLabel>
            <FormControl
              control="input"
              label=""
              placeholder=""
              required
              name="cardName"
              onBlur={handleBlur}
              value={payment_data.card_name}
              onChange={(e) => dispatch(setCardName(e.target.value))}
              error={
                touched.cardName && checkValidString(payment_data.card_name)
              }
              helperText={
                touched.cardName && payment_data.card_name === ''
                  ? 'field is required'
                  : touched.cardName && checkValidString(payment_data.card_name)
                  ? 'please enter a valid name'
                  : null
              }
              style={{ margin: '0 0' }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            className={classes.StripeGrid}
            style={{
              paddingLeft: '16px',
              paddingRight: '16px'
            }}
          >
            <InputLabel
              htmlFor="age-required"
              required
              className={classes.cutom_select_Label}
              style={{ padding: '5px 0 10px' }}
            >
              Card number
            </InputLabel>
            <StripeCard
              CustOptions={CARD_OPTIONS}
              // onChange={(e) => {
              //   dispatch(setErrorMsg(e.error));
              //   // setCardComplete(e.complete);
              // }}
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

CreditCard.propTypes = {
  // setOpen: PropType.bool.isRequired,
  // handleClose: PropType.func.isRequired
  // handleBack: PropType.func,
  // handleHistory: PropType.string
};

export default CreditCard;
