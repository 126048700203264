export const FilterStatus = [
  // All id key from back end
  { id: 0, name: 'All' },
  { id: 2, name: 'Paid' },
  { id: 1, name: 'Unpaid' },
  { id: 5, name: 'Refunded' },
  { id: 3, name: 'Voided' }
];

export const isNotData = [
  { id: 0, name: 'is Not' },
  { id: 1, name: 'is' }
];

export const TobeFilter = [
  { id: 1, filter: 'Invoice Status' },
  { id: 2, filter: 'Payment Due' }
];

export const PaymentDueFilter = [
  { id: 'all_time', filter: 'All Time' },
  { id: 'custom', filter: 'Custom' },
  { id: 'thisyear', filter: 'This Year' },
  { id: 'lastyear', filter: 'Last Year' },
  { id: 'thismonth', filter: 'This Month' },
  { id: 'lastmonth', filter: 'Last Month' }
];

// export const PaymentDueFilter = [
//   { id: 1, name: 'All Time' },
//   { id: 2, name: 'Custom' },
//   { id: 3, name: 'This Year' },
//   { id: 4, name: 'Last Year' },
//   { id: 5, name: 'This Month' },
//   { id: 6, name: 'Last Month' }
// ];
