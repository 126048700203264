import { checkValidString } from '../../../../utils/validations';

export const SessionMeta = (data) => {
  const result = [];
  for (let i = 0; i < data.length; i += 1) {
    let b = {
      id: data[i].id,
      name: data[i].name,
      tax_id: data[i].tax.id,
      initial_commitment_pricing: {
        ...data[i].initial_commitment_pricing
      }
    };
    if (data[i].id) {
      b = {
        ...b,
        id: data[i].id
      };
    }
    if (data[i].location) {
      b = {
        ...b,
        location_id: data[i].location.id
      };
    }
    result.push(b);
  }
  return result;
};

export const basicDetails = (data) => {
  if (!data.name || checkValidString(data.name)) {
    return { error: 'Please specify valid name' };
  }
  if (!data.sessions || data.sessions === 0) {
    return { error: 'Please speacify valid sessions' };
  }
  if (!data.reveCat) {
    return { error: 'Please  select  revenue category' };
  }
  if (!data.attLmt) {
    return { error: 'Please select the expiration limit' };
  }

  if (data.attLmt.id === 1) {
    if (
      !data.attendance_limited_meta.uses
      || data.attendance_limited_meta.uses.length === 0
    ) {
      return { error: 'Please specify valid expiration uses' };
    }
    if (!data.attendance_limited_meta.type) {
      return { error: 'Please specify expiration type' };
    }
  }
  if (data.desc && data.desc.trim().length > 0 && checkValidString(data.desc)) {
    return { error: 'Please specify valid Description' };
  }

  return { data: true };
};

export const getAllData = (state, dropIn) => {
  if (dropIn) {
    const value = {
      membership_type: 'drop_in',
      name: state.name,
      // no_of_sessions: state.session,
      reve_cat_id: state.reveCat.id,
      attendance_limit: 0,
      program_ids: state.program_ids.join(','),
      meta: JSON.stringify(SessionMeta(state.payment_plans)),
      // contract_id: state.contract_id
      description: state.desc
    };
    // if (state.desc && state.desc.trim().length > 0) {
    //   value = {
    //     ...value,
    //     description: state.desc
    //   };
    // }
    // if (state.attLmt.id === 1) {
    //   value = {
    //     ...value,
    //     attendance_limited_meta: JSON.stringify({
    //       ...state.attendance_limited_meta,
    //       type: state.attendance_limited_meta.type.id
    //     })
    //   };
    // }
    return value;
  }
  let value = {
    membership_type: 'session',
    name: state.name,
    no_of_sessions: state.session,
    reve_cat_id: state.reveCat.id,
    attendance_limit: state.attLmt.id,
    program_ids: state.program_ids.join(','),
    meta: JSON.stringify(SessionMeta(state.payment_plans)),
    contract_id: state.contract_id
  };
  if (state.desc && state.desc.trim().length > 0) {
    value = {
      ...value,
      description: state.desc
    };
  }
  if (state.attLmt.id === 1) {
    value = {
      ...value,
      attendance_limited_meta: JSON.stringify({
        ...state.attendance_limited_meta,
        type: state.attendance_limited_meta.type.id
      })
    };
  }
  return value;
};

export const getValidStep1 = (data) => {
  if (!data.name) {
    return true;
  }

  return false;
};
