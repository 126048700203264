import React, { createContext, useReducer } from 'react';
import PropType from 'prop-types';
// import {
//   initialValue,
//   MemberProfileReducer
// } from './MemberProfileReducer/MemProfile.reducer';
import MasterHqReducer from './MasterHQAllies/masterhqallies.reducer';
import initialValues from './MasterHQAllies/masterhqallies.initialstate';

export const AlliesProfile = createContext();

export const AlliesConext = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(MasterHqReducer, initialValues);
  return (
    <AlliesProfile.Provider value={[state, dispatch]}>
      {children}
    </AlliesProfile.Provider>
  );
};

AlliesConext.propTypes = {
  children: PropType.node.isRequired
};
