import React from 'react';
// import { makeStyles } from '@material-ui/styles';
import PropType from 'prop-types';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  TableContainer
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import styles from './ClassTable.style';

const ClassTable = ({ classes, values }) => {
  // function createData(Classname, Classtime, Headcoach) {
  //   return { Classname, Classtime, Headcoach };
  // }
  // const rows = [
  //   createData('Week lift class', '5:00 AM - 6:00 AM', 'Jack Smith'),
  //   createData('Week lift class', '5:00 AM - 6:00 AM', 'Jack Smith'),
  //   createData('Week lift class', '5:00 AM - 6:00 AM', 'Jack Smith')
  // ];

  return (
    <Paper elevation={0}>
      <TableContainer component={Paper} elevation={0} className={classes.table}>
        <Table>
          <TableHead className={classes.table_th}>
            <TableRow>
              <TableCell
                className={classes.TableCell}
                style={{ width: '40%', borderTopLeftRadius: '4px' }}
              >
                Class Name
              </TableCell>
              <TableCell style={{ width: '10%' }} className={classes.TableCell}>
                Class time
              </TableCell>
              <TableCell style={{ width: '10%' }} className={classes.TableCell}>
                Head coach
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={classes.Table_Body}>
            {values.map((row) => (
              <TableRow key={row.id}>
                <TableCell scope="row" className={classes.Table_td}>
                  {row.name}
                </TableCell>
                <TableCell className={classes.Table_td}>
                  {row.class_time}
                </TableCell>
                <TableCell className={classes.Table_td}>
                  {row.head_coach}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

ClassTable.defaultProps = {
  classes: null
};

ClassTable.propTypes = {
  classes: PropType.object,
  values: PropType.array.isRequired
};
export default withStyles(styles)(ClassTable);
