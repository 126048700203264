import React from 'react';
import PropType from 'prop-types';
import moment from 'moment';

const CustomDate = (props) => {
  const { day, label } = props;
  return (
    <div
      style={{
        position: 'sticky',
        background: '#f9f9fa'
      }}
    >
      <div className="CustomDate">
        {label}
        <label className="CustomDatelabel">{moment(day).format('ll')}</label>
      </div>
    </div>
  );
};
CustomDate.propTypes = {
  day: PropType.object.isRequired,
  label: PropType.string.isRequired
};
export default CustomDate;
