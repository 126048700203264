const styles = (theme) => ({
  root: {
    marginTop: 0,
    margin: '0px 20px 20px',
    padding: '0px 20px 20px'
    // Do not remove below media css
    // '@media (max-width: 1440px)': {
    //   '& .title-bk h3': {
    //     fontSize: '18px'
    //   }
    // }
  },

  mainCard: {
    boxShadow: '4px 0px 16px rgba(37, 40, 43, 0.08)',
    borderRadius: '5px',
    background: '#FFFF',
    padding: 20
  },
  ptext: {
    fontSize: '14px',
    lineHeight: '24px',
    color: '#7F7F7F',
    fontWeight: 'normal'
  },
  button: {
    background: '#01847A',
    color: '#FFF',
    padding: '15px 24px',
    borderRadius: '2px;',
    boxShadow: '0px 8px 16px rgba(0, 126, 255, 0.16);',
    float: 'right;',
    '&:hover': {
      backgroundColor: '#01847A'
    }
  },
  p_tabs: {
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px',
    color: '#545454'
  },
  DTags: {
    marginTop: '20px',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px',
    color: '#292929',
    '& label': {
      background: '#EAEAEA',
      borderRadius: '10px',
      padding: '8px 16px',
      display: 'block'
    }
  },
  DTagsClose: {
    color: '#7F7F7F',
    marginLeft: '5px',
    cursor: 'pointer',
    border: '1px solid #7F7F7F',
    borderRadius: '50%',
    fontSize: '14px',
    padding: '0px 4.5px',
    width: '20px',
    height: '20px',
    lineHeight: 'normal',
    fontWeight: 'bolder',
    maxWidth: '20px',
    maxHeight: '20px',
    display: 'inline-block'
  },
  search: {
    position: 'relative',
    height: '48px',
    marginLeft: 0,
    width: '100%'
    // marginBottom: '21px'
  },
  searchInput: {
    padding: '6px 20px 6px !important;',
    paddingRight: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    height: '35px',
    border: '1px solid #a9a9a9'
  },
  hr: {
    border: '1px solid rgb(196 196 196 / 0.4)',
    display: 'inline-block',
    width: '100%',
    margin: '20px 0'
  },
  cutom_Switch_Label: {
    display: 'inline-block',
    marginRight: '30px',
    verticalAlign: 'middle',
    fontSize: '14px',
    fontWeight: '700',
    color: '#000'
  },

  cutom_select_Label: {
    fontSize: '12px',
    '&:focus ': {
      outline: '0'
    }
  },
  wrapperBox: {
    border: '1px solid #EAEAEA',
    borderRadius: '8px',
    padding: '25px',
    minHeight: '132px',
    [theme.breakpoints.down('lg')]: {
      padding: '20px'
    }
  },
  InactiveBox: {
    border: '1px solid #EAEAEA',
    color: '#292929',
    width: 'auto',
    maxWidth: '96px',
    height: '24px',
    fontWeight: 'bold',
    fontSize: '11px',
    lineHeight: '16px',
    padding: '4px 12px',
    borderRadius: '8px',
    display: 'inline-table',
    textAlign: 'center',
    background: '#F9F9FA'
  },
  wraBoxTitle: {
    display: 'inline-flex',
    width: '100%',
    justifyContent: 'space-between'
  },
  ArrowLeft: {
    borderRadius: '50%',
    padding: '0 0',
    minWidth: 'auto',
    marginRight: '15px'
  },
  ckControlLabel: {
    '& span': {
      color: '#949494'
    }
  },
  renewaldayBox: {
    margin: '0 0',
    display: 'inline-flex',
    width: '77px',
    verticalAlign: 'middle',
    marginRight: '10px'
  },
  leadsBox: {
    border: '1px solid #EAEAEA',
    borderRadius: '8px',
    padding: '25px',
    minHeight: '174px',
    textAlign: 'center',
    color: '#292929',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',

    '& label': {
      fontSize: '16px',
      fontWeight: 'bold',
      lineHeight: '24px',
      marginTop: '10px'
    }
  },
  FunnelWrapper: {
    textAlign: 'center',
    display: 'inline-flex',
    margin: '0 0 50px',
    borderBottom: '1px solid #EAEAEA'
  },
  FunnelBox: {
    display: 'inline-flex',
    alignItems: 'center',
    position: 'relative',
    marginLeft: '1px',
    fontSize: '12px',
    lineHeight: '16px',
    color: '#545454',
    '&:first-child ': {
      marginLeft: '0px'
    },
    '& label': {
      position: 'absolute',
      right: '0',
      left: '0',
      top: '-25px',
      margin: '0 auto !important'
    },
    '& span': {
      position: 'absolute',
      right: '0',
      left: '0',
      bottom: '-30px',
      margin: '0 auto !important'
    }
  },
  BoxTooltip: {
    padding: '8px 8px !important',
    zIndex: '9999 !important'
  },
  FunnelBoxTooltip: {
    position: 'relative',
    '& label, span': {
      position: 'relative',
      right: 'auto',
      left: 'auto',
      top: 'auto',
      bottom: 'auto',
      display: 'inline-block',
      fontWeight: 'bold'
    },
    '& span': {
      color: '#7F7F7F',
      paddingRight: '5px',
      textAlign: 'right',
      float: 'left',
      width: '60px',
      display: 'block'
    },
    '& label': {
      color: '#fff',
      textAlign: 'left',
      display: 'flex'
    }
  },
  BkChart: {
    margin: '0 0',
    width: '400px'
  },
  TooltipInfo: {
    '&:hover': {
      '& svg path': {
        stroke: '#01847A'
      }
    }
  }
});
export default styles;
