import React, { memo, useContext } from 'react';
import PropType from 'prop-types';
import JBButton from 'src/views/common/JBButton/JBButton';
import { InputLabel, withStyles } from '@material-ui/core';
import { useSelector, connect } from 'react-redux';
import { FilterContext } from 'src/views/common/ApplyFilter/applyFilter';
import JBDatePicker from 'src/views/common/FormControl/JBDatePicker';
import JBTimePicker from 'src/views/common/FormControl/JBTimePicker';
import { AttendanceContext } from 'src/Context/attendanceSummaryContext';
import { getMinDate } from 'src/utils/someCommon';

import { attendanceSummaryReports } from 'src/Redux/ReportsReducer/reports.reducer.actions';
import {
  AttendanceSetNextDate,
  AttendanceSetProgram,
  AttendanceSetPrevDate,
  AttendanceSetDay,
  AttendanceSetStartTime
} from 'src/Context/AttendanceSummary/attendanceReducer.actions';
import ReducerConstants from 'src/Redux/Reducer.constants';
import CustomAutoComplete from 'src/views/common/CustomAutocomplete/commonAutocomplete';
import useGymSettingReducer from 'src/views/common/CustomHooks/useGymSettingRefresh';
import styles from './filters.style';
import { DateFilteroption } from './static.Data';

const AttendanceSummaryFilters = memo((props) => {
  const { classes, summaryFilter } = props;
  const { programs } = useSelector((state) => state.allprograms);
  const [state, dispatch] = useContext(AttendanceContext);
  const [open, setOpen] = useContext(FilterContext);
  const { ampm, dateformat } = useGymSettingReducer();
  return (
    <div>
      <div>
        <InputLabel
          htmlFor="age-required"
          className={classes.cutom_select_Label}
          style={{ margin: '0px 0 5px' }}
        >
          Program
        </InputLabel>
        <CustomAutoComplete
          holder="All"
          Options="name"
          data={state?.program}
          value={programs}
          style={{ marginBottom: '15px' }}
          Change={(val) => dispatch(AttendanceSetProgram(val))}
        />
      </div>
      <div className={classes.cutom_date_select}>
        <InputLabel
          htmlFor="age-required"
          className={classes.cutom_select_Label}
          style={{ margin: '0px 0 5px' }}
        >
          Date Range
        </InputLabel>
        <CustomAutoComplete
          data={state?.day}
          value={DateFilteroption}
          Options="name"
          Change={(val) => dispatch(AttendanceSetDay(val))}
        />
        <div>
          {state.day?.filter === 'custom' && (
            <div>
              <InputLabel
                htmlFor="age-required"
                className={classes.cutom_select_Label}
                style={{ margin: '5px 0' }}
              >
                From
              </InputLabel>
              <JBDatePicker
                label=""
                value={state?.prevdate}
                onChange={(val) => {
                  dispatch(AttendanceSetPrevDate(val));
                }}
              />
              <InputLabel
                htmlFor="age-required"
                className={classes.cutom_select_Label}
                style={{ padding: '10px 0px 5px' }}
              >
                To
              </InputLabel>
              <JBDatePicker
                label=""
                minDate={
                  state?.prevdate
                    ? getMinDate(state?.prevdate)
                    : getMinDate(new Date())
                }
                value={state?.nextdate}
                onChange={(val) => dispatch(AttendanceSetNextDate(val))}
              />
            </div>
          )}
        </div>
      </div>
      <div className={classes.time_box}>
        <InputLabel
          htmlFor="age-required"
          className={classes.cutom_select_Label}
          style={{ margin: '10px 0 5px' }}
        >
          Start Time
        </InputLabel>
        <JBTimePicker
          value={state?.start_time}
          onChange={(val) => dispatch(AttendanceSetStartTime(val))}
        />
      </div>

      <JBButton
        type="primary"
        style={{
          width: '100%',
          padding: '7px 25px',
          marginTop: '10px'
        }}
        onClick={() => {
          summaryFilter(ReducerConstants.addcustom, state, {
            ampm,
            dateformat
          });
          setOpen(!open);
        }}
      >
        Apply filter
      </JBButton>
    </div>
  );
});

const mapToDispatch = (dispatch) => {
  return {
    summaryFilter: (filter, data, ampm) =>
      dispatch(attendanceSummaryReports(filter, data, ampm))
  };
};
AttendanceSummaryFilters.propTypes = {
  classes: PropType.object.isRequired,
  summaryFilter: PropType.func.isRequired
};

function applyStyle(func) {
  return withStyles(styles)(func);
}

export default connect(
  null,
  mapToDispatch
)(applyStyle(AttendanceSummaryFilters));
