// eslint-disable-next-line import/prefer-default-export
export const getFinalAmount = (data, discount) => {
  let result = data;
  if (discount && discount.status === 1) {
    if (discount.discount_type === 'percentage') {
      const amount = (discount.discount_value / 100) * data;
      result -= amount;
    } else {
      const amount = discount.discount_amount;
      result -= amount;
    }
  }
  return result;
};
export const validateCharges = (data) => {
  if (data.percentage_charge > 100) throw Error('Percentage should be less or equal to 100');
};
