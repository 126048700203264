import {
  getAffiliateallinvoicereports,
  getAllyOverdueaccountsrecieve,
  getExpiredCreditCardsAllyreports,
  getrevenuePerMonthReports
} from 'src/services/masterHQ.services';
import {
  getattendanceSummaryDetail,
  getmembersexpiringMembership,
  getmemberslastAttendance,
  getmembersMembershipDetails,
  getmembersMembershipType,
  getmembersPaymentPlan,
  getrevenuemembershipreports,
  getTotalattendanceHistory,
  membertrendsreports,
  getReportsData,
  getValueOfEachMember,
  UserAttendaceHistory
} from 'src/services/reports.services';
import { getErrorMsg } from 'src/utils/ErrorHandling';
import { getUpdatedValueObj } from 'src/Redux/reducer.default.data';
import types from './reports.action.type';
import {
  getAttenState,
  getAttenSummaryState,
  getFinancialState,
  getreportState
} from './reports.reducer.data';

export const fetchingReports = (type) => ({
  type,
  actionType: types.isFetching
});

export const fetchComplete = (type, payload) => ({
  type,
  actionType: types.fetchSucess,
  payload
});

export const ErrorFetching = (type, payload) => ({
  type,
  actionType: types.Errorfetch,
  payload
});
const creditCardExpFilter = (filter, payload) => ({
  type: types.expirecreditcardsFil,
  filter,
  payload
});
const memDetailFilter = (filter, payload) => ({
  type: types.membershipDetailsFilter,
  filter,
  payload
});

const expMemFilter = (filter, payload) => ({
  type: types.expireMembershipFilter,
  filter,
  payload
});

const memTypeFilter = (filter, payload) => ({
  type: types.membersMembershipTypeFilter,
  filter,
  payload
});

const attendanceTrendsdataFilter = (filter, payload) => ({
  type: types.attendanceTrendsFilter,
  filter,
  payload
});

const mempaymentplanFilter = (filter, payload) => ({
  type: types.membersPaymentPlanFilter,
  filter,
  payload
});

const lastAttendanceFilter = (filter, payload) => ({
  type: types.membersLastAttendanceFil,
  filter,
  payload
});

const revenueBymemberShipFilter = (filter, payload) => ({
  type: types.revenuebymembershipFilter,
  filter,
  payload
});
const revenuePerMonthFilter = (filter, payload) => ({
  type: types.revenuePerMonthFil,
  filter,
  payload
});
const valueEeachMemberFilter = (filter, payload) => ({
  type: types.valueEeachMemberFil,
  filter,
  payload
});
const allInvoiceFilter = (filter, payload) => ({
  type: types.allinvoicereportsFilter,
  filter,
  payload
});

const overdueaccountFilter = (filter, payload) => ({
  type: types.overdueaccountFilter,
  filter,
  payload
});

const attendanceHistoryFilter = (filter, payload) => ({
  type: types.membersAttendanceHistoryFil,
  filter,
  payload
});
export const UserHistoryFilter = (filter, payload) => ({
  type: types.userHistoryFil,
  filter,
  payload
});
const attendanceSummaryFilter = (filter, payload, date) => ({
  type: types.attendanceSummaryinitalFil,
  filter,
  date,
  payload
});

const getMembershipDetail = (val) => {
  const type = types.membershipDetails;
  return (dispatch) => {
    dispatch(fetchingReports(type));
    const keys = Object.keys(val);
    const values = new FormData();
    keys.map((l) => values.append(l, val[l]));
    getmembersMembershipDetails(values)
      .then((resp) => dispatch(fetchComplete(type, resp)))
      .catch((err) => dispatch(ErrorFetching(type, getErrorMsg(err))));
  };
};
const valueOfEeachMember = (val) => {
  const type = types.valueEeachMember;
  return (dispatch) => {
    dispatch(fetchingReports(type));
    getValueOfEachMember(val)
      .then((res) => dispatch(fetchComplete(type, res)))
      .catch((err) => dispatch(ErrorFetching(type, getErrorMsg(err))));
  };
};
const UserHistory = (val) => {
  const type = types.getUserHistory;
  return (dispatch) => {
    dispatch(fetchingReports(type));
    UserAttendaceHistory(val)
      .then((res) => dispatch(fetchComplete(type, res)))
      .catch((err) => dispatch(ErrorFetching(type, getErrorMsg(err))));
  };
};
const getexpireMembership = (vals) => {
  const type = types.expireMembership;
  return (dispatch) => {
    dispatch(fetchingReports(type));
    getmembersexpiringMembership(vals)
      .then((res) => dispatch(fetchComplete(type, res)))
      .catch((err) => dispatch(ErrorFetching(type, getErrorMsg(err))));
  };
};

const getMembershipType = (vals) => {
  const type = types.membersMembershipType;
  return (dispatch) => {
    dispatch(fetchingReports(type));
    getmembersMembershipType(vals)
      .then((res) => dispatch(fetchComplete(type, res)))
      .catch((err) => dispatch(ErrorFetching(type, getErrorMsg(err))));
  };
};

const getMembersPaymentPlan = (vals) => {
  const type = types.membersPaymentPlan;
  return (dispatch) => {
    dispatch(fetchingReports(type));
    getmembersPaymentPlan(vals)
      .then((res) => dispatch(fetchComplete(type, res)))
      .catch((err) => dispatch(ErrorFetching(type, getErrorMsg(err))));
  };
};

const getLastAttendanceDetails = (val) => {
  const type = types.membersLastAttendance;
  return (dispatch) => {
    dispatch(fetchingReports(type));
    const keys = Object.keys(val);
    const values = new FormData();
    keys.map((l) => values.append(l, val[l]));
    getmemberslastAttendance(values)
      .then((resp) => dispatch(fetchComplete(type, resp)))
      .catch((err) => dispatch(ErrorFetching(type, getErrorMsg(err))));
  };
};

const getattendanceHistoryDetails = (val) => {
  const type = types.membersAttendanceHistory;
  return (dispatch) => {
    dispatch(fetchingReports(type));
    getTotalattendanceHistory(val)
      .then((resp) => dispatch(fetchComplete(type, resp)))
      .catch((err) => dispatch(ErrorFetching(type, getErrorMsg(err))));
  };
};

const attendancetrendsdata = (vals) => {
  const type = types.attendanceTrends;
  return (dispatch) => {
    dispatch(fetchingReports(type));
    const keys = Object.keys(vals);
    const values = new FormData();
    keys.map((l) => values.append(l, vals[l]));
    membertrendsreports(values)
      .then((resp) => dispatch(fetchComplete(type, resp)))
      .catch((err) => dispatch(ErrorFetching(type, getErrorMsg(err))));
  };
};

const attendanceSummaryDetails = (vals) => {
  const type = types.attendanceSummary;
  return (dispatch) => {
    dispatch(fetchingReports(type));
    getattendanceSummaryDetail(vals)
      .then((resp) => dispatch(fetchComplete(type, resp)))
      .catch((err) => dispatch(ErrorFetching(type, getErrorMsg(err))));
  };
};

const invoicereports = (vals) => {
  const type = types.allinvoicereports;
  return (dispatch) => {
    dispatch(fetchingReports(type));
    getAffiliateallinvoicereports(vals)
      .then((res) => dispatch(fetchComplete(type, res)))
      .catch((err) => dispatch(ErrorFetching(type, getErrorMsg(err))));
  };
};

const revenuemembership = (vals) => {
  const type = types.revenuebymembership;
  return (dispatch) => {
    dispatch(fetchingReports(type));
    getrevenuemembershipreports(vals)
      .then((res) => dispatch(fetchComplete(type, res)))
      .catch((err) => dispatch(ErrorFetching(type, getErrorMsg(err))));
  };
};

const creditcardExp = (vals) => {
  const type = types.expirecreditcards;
  return (dispatch) => {
    dispatch(fetchingReports(type));
    getExpiredCreditCardsAllyreports(vals)
      .then((res) => dispatch(fetchComplete(type, res)))
      .catch((err) => dispatch(ErrorFetching(type, getErrorMsg(err))));
  };
};

const revenuePerMonth = (vals) => {
  const type = types.revenuePerMonth;
  return (dispatch) => {
    dispatch(fetchingReports(type));
    getrevenuePerMonthReports(vals)
      .then((res) => dispatch(fetchComplete(type, res)))
      .catch((err) => dispatch(ErrorFetching(type, getErrorMsg(err))));
  };
};

const overdueaccount = (vals) => {
  const type = types.overdueaccount;
  return (dispatch) => {
    dispatch(fetchingReports(type));
    const keys = Object.keys(vals);
    const values = new FormData();
    keys.map((l) => values.append(l, vals[l]));
    getAllyOverdueaccountsrecieve(values)
      .then((res) => dispatch(fetchComplete(type, res)))
      .catch((err) => dispatch(ErrorFetching(type, getErrorMsg(err))));
  };
};

export const getReportsListData = (val) => {
  const type = types.getreportList;
  return (dispatch) => {
    dispatch(fetchingReports(type));
    getReportsData(val)
      .then((resp) => dispatch(fetchComplete(type, resp)))
      .catch((err) => dispatch(ErrorFetching(type, getErrorMsg(err))));
  };
};

export const getMembershipReports = (filter, data) => {
  return (dispatch) => {
    dispatch(memDetailFilter(filter, data));
    const updatedVal = getUpdatedValueObj(getreportState('memberDetailsFil'));
    dispatch(getMembershipDetail(updatedVal));
  };
};
export const getMembersValue = (filter, data) => {
  return (dispatch) => {
    dispatch(valueEeachMemberFilter(filter, data));
    const updatedVal = getUpdatedValueObj(getFinancialState('membersValueFil'));
    dispatch(valueOfEeachMember(updatedVal));
  };
};
export const getUserHistory = (id, filter, data) => {
  return (dispatch) => {
    dispatch(UserHistoryFilter(filter, data));
    const updatedVal = getUpdatedValueObj(getAttenState('userHistoryFil'));
    const vals = {
      ...updatedVal,
      user_id: id
    };
    dispatch(UserHistory(vals));
  };
};
export const getexpireMembershipReports = (filter, data) => {
  return (dispatch) => {
    dispatch(expMemFilter(filter, data));
    const updatedVal = getUpdatedValueObj(
      getreportState('expireMembershipFil')
    );
    dispatch(getexpireMembership(updatedVal));
  };
};

export const getMembershipTypeReports = (filter, data) => {
  return (dispatch) => {
    dispatch(memTypeFilter(filter, data));
    const updatedVal = getUpdatedValueObj(
      getreportState('byMembershipTypeFil')
    );
    dispatch(getMembershipType(updatedVal));
  };
};

export const getMembersPaymentPlanReports = (filter, data) => {
  return (dispatch) => {
    dispatch(mempaymentplanFilter(filter, data));
    const updatedVal = getUpdatedValueObj(getreportState('byPaymentPlanFil'));
    dispatch(getMembersPaymentPlan(updatedVal));
  };
};

export const lastAttendanceReports = (filter, data) => {
  return (dispatch) => {
    dispatch(lastAttendanceFilter(filter, data));
    const updatedVal = getUpdatedValueObj(getAttenState('lastAttendanceFil'));
    dispatch(getLastAttendanceDetails(updatedVal));
  };
};

export const attendanceHistoryReports = (filter, data) => {
  return (dispatch) => {
    dispatch(attendanceHistoryFilter(filter, data));
    const updatedVal = getUpdatedValueObj(
      getAttenState('attendanceHistoryFil')
    );
    dispatch(getattendanceHistoryDetails(updatedVal));
  };
};

export const attendanceSummaryReports = (filter, data, date) => {
  return (dispatch) => {
    dispatch(attendanceSummaryFilter(filter, data, date));
    const updatedVal = getAttenSummaryState();
    dispatch(attendanceSummaryDetails(updatedVal));
  };
};

export const getinvoicereports = (filter, data) => {
  return (dispatch) => {
    dispatch(allInvoiceFilter(filter, data));
    const updatedVal = getUpdatedValueObj(getFinancialState('allInvoiceFil'));
    dispatch(invoicereports(updatedVal));
  };
};

export const getrevenuememreports = (filter, data) => {
  return (dispatch) => {
    dispatch(revenueBymemberShipFilter(filter, data));
    const updatedVal = getUpdatedValueObj(
      getFinancialState('revenueMembershipFil')
    );
    dispatch(revenuemembership(updatedVal));
  };
};

export const getoveraccountreports = (filter, data) => {
  return (dispatch) => {
    dispatch(overdueaccountFilter(filter, data));
    const updatedVal = getUpdatedValueObj(
      getFinancialState('overdueAccountsFil')
    );
    dispatch(overdueaccount(updatedVal));
  };
};

export const getattendanceTrends = (filter, data) => {
  return (dispatch) => {
    dispatch(attendanceTrendsdataFilter(filter, data));
    const updatedVal = getUpdatedValueObj(getAttenState('attendanceTrendsFil'));
    dispatch(attendancetrendsdata(updatedVal));
  };
};

export const getCreditCardExpReports = (filter, data) => {
  return (dispatch) => {
    dispatch(creditCardExpFilter(filter, data));
    const updatedVal = getUpdatedValueObj(getFinancialState('expirecardsFil'));
    dispatch(creditcardExp(updatedVal));
  };
};

// export const getrevenuePerMonth = (filter, data) => {
//   return (dispatch) => {
//     dispatch(revenuePerMonthFilter(filter, data));
//     const updatedVal = getUpdatedValueObj(
//       getFinancialState('revenuePerMonthFil')
//     );
//     dispatch(revenuePerMonth(updatedVal));
//   };
// };

export const getrevenuePerMonth = (filter, data) => {
  return (dispatch) => {
    dispatch(revenuePerMonthFilter(filter, data));
    const updatedVal = getUpdatedValueObj(
      getFinancialState('revenuePerMonthFil')
    );
    dispatch(revenuePerMonth(updatedVal));
  };
};
