/* eslint-disable arrow-parens */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  withStyles,
  Typography,
  Box,
  InputLabel
} from '@material-ui/core';
import FormControl from 'src/views/common/FormControl/formControl';
import { getCountries, getlocMarks } from 'src/services/Members.module';
// eslint-disable-next-line import/order
import { getFullAddress } from './infoLocations.utils';
import {
  // validateInt,
  checkEmail,
  checkValidString,
  validMobile
} from 'src/utils/validations';
import { getProfile, updateGymLocation } from 'src/services/gym.services';
import LoadingView from 'src/views/common/loading';
import JBmap from 'src/views/common/JBMaps/JBmap';
import TimeFormats from 'src/constant/timeformats';
// import NotFoundView from 'src/views/errors/NotFoundView';
import { getErrorMsg } from 'src/utils/ErrorHandling';
import { openNoti } from 'src/Redux/global/global.actions';
import { getGymSetup } from 'src/Redux/GymSetupReducer/GymSetup.actions';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getAllGymloc } from 'src/Redux/SettingReducer/SettingReducer.actions';
import useChangeTitle from 'src/views/common/CustomHooks/useTitleChange';
import styles from '../GeneralInfo.style';
// import { DummyValues } from './LocationsTable/dummyData';
import LocationsTable from './LocationsTable/LocationsTable';
import JBButton from '../../common/JBButton/JBButton';
import edit from '../../../assets/image/edit.png';
import CustomAutocomplete from '../../common/CustomAutocomplete/commonAutocomplete';
import { validateGymLocationData } from './infoLocation.validate';

const InfoLocations = ({ classes }) => {
  const [isProcessing, setProcessing] = useState(() => false);
  const history = useHistory();
  const [Edit, setEdit] = useState(false);
  useChangeTitle('Info & Locations | Jungle Alliance');
  // const [data, setData] = useState({
  //   gym_name: 'Jungle Brothers',
  //   phone: '61296665269',
  //   email: 'info@junglebrother.com',
  //   website: 'https://junglebrothers.com/',
  //   address: '15 Underwood Ave',
  //   city: 'Botany',
  //   state: 'New South Wales',
  //   country: 'India',
  //   zipcode: '1450',
  //   // address2: null,
  //   timezone: null
  // });
  const [data, setData] = useState({
    gym_name: null,
    // phone: null,
    email: null,
    website: null,
    address: null,
    city: null,
    state: null,
    country: null,
    zipcode: null,
    timezone: null
  });
  const [gymId, setGymId] = useState(null);
  const [positions, setpositions] = useState(null);
  const [errorFetch, setErrorFetch] = useState(false);
  const [country, setcountry] = useState(null);
  const [touched, setTouched] = useState({
    gym_name: false,
    email: false,
    state: false,
    city: false,
    address: false,
    website: false,
    country: false,
    zipcode: false,
    timezone: false
  });

  const dispatch = useDispatch();
  const gymLocs = useSelector((state) => state.SettingReducer.allGymloc.data);
  const gymStep = useSelector((state) => state.GymSetupReducer.data);
  const getAddress = (allData) => {
    const address = getFullAddress(allData);
    getlocMarks(address)
      .then((res) => {
        if (res.lat) {
          setpositions(res);
        } else {
          setpositions({ error: true });
        }
      })
      .catch((err) => console.warn(err));
  };

  useEffect(() => {
    setProcessing(() => true);

    Promise.all([getCountries(), getProfile()])
      .then((res) => {
        setProcessing(() => false);
        setGymId(res[1].id);
        setcountry(res[0]);

        const setReceivedData = {
          gym_name: res[1].gym_name,
          phone: res[1].phone,
          email: res[1].email,
          website: res[1].website,
          city: res[1].city,
          state: res[1].state,
          country:
            res[0].filter((list) => list.code === res[1].country)[0] || null,
          zipcode: res[1].zipcode,
          address2: res[1].address2,
          address: res[1].address,
          timezone:
            TimeFormats.filter((list) => list.id === res[1].timezone)[0] || null
        };
        setData(setReceivedData);
        getAddress(setReceivedData);
      })
      .catch(() => {
        setProcessing(() => false);
        setErrorFetch(true);
      });
  }, []);

  const handleUpdate = () => {
    try {
      validateGymLocationData(data);
      const input = {
        ...data,
        country: data.country.code,
        timezone: data.timezone.id
      };

      setProcessing(() => true);
      updateGymLocation(input, gymId)
        .then(() => {
          // setData(() => ({ ...res }));
          if (gymStep.process_type.basic_info === 0) {
            dispatch(getGymSetup());
          }
          dispatch(getAllGymloc());
          dispatch(openNoti('Save successfully.'));
          setProcessing(() => false);
        })
        .catch((err) => {
          console.log(getErrorMsg(err));
          dispatch(openNoti(getErrorMsg(err), 'error'));
          setProcessing(() => false);
        });
    } catch (e) {
      if (!e.response) {
        dispatch(openNoti(e.message, 'error'));
      } else {
        dispatch(openNoti(e.response.data.message, 'error'));
      }

      setProcessing(() => false);
    }
  };

  const handleCancel = () => {
    history.goBack();
    setData({
      gym_name: null,
      // phone: null,
      email: null,
      website: null,
      address: null,
      city: null,
      state: null,
      country: null,
      zipcode: null,
      timezone: null
    });
  };

  const MapLoads = () => {
    if (positions) {
      const { error } = positions;
      if (error) {
        return (
          <p
            style={{
              color: '#f44336',
              fontSize: '14px'
            }}
          >
            Please Put Valid Address
          </p>
        );
      }
      return <JBmap positions={positions} />;
    }
    return <LoadingView />;
  };

  const handleBlur = (e) => {
    const { name } = e.target;
    setTouched({ ...touched, [name]: true });
  };

  useEffect(() => {
    if (!Edit) {
      getAddress(data);
    }
  }, [Edit]); // eslint-disable-line

  if (errorFetch) return <p>Something Went Wrong</p>;

  return (
    <div>
      <Grid
        container
        direction="row"
        spacing={0}
        style={{ margin: '10px 0 0px' }}
      >
        <Grid item xs={7}>
          <Box mt="15px" mb="15px">
            <Typography
              color="#000"
              variant="h5"
              component="div"
              className={classes.bktitle}
            >
              Gym Information
            </Typography>
            <p className={classes.ptext}>
              Edit the general information about your gym.
            </p>
          </Box>
        </Grid>
        <Grid
          container
          spacing={2}
          style={{
            alignItems: 'center'
          }}
        >
          <Grid item xs={4}>
            <InputLabel
              htmlFor="age-required"
              required
              className={classes.cutom_select_Label}
              style={{ padding: '5px 0' }}
            >
              Gym name
            </InputLabel>
            <FormControl
              control="input"
              label=""
              placeholder=""
              required
              value={data.gym_name}
              onBlur={handleBlur}
              onChange={(e) => {
                setData({ ...data, gym_name: e.target.value });
              }}
              name="gym_name"
              style={{ margin: '0 0' }}
              error={Boolean(
                touched.gym_name && checkValidString(data.gym_name)
              )}
              helperText={
                touched.gym_name && checkValidString(data.gym_name)
                  ? 'Please enter valid Gym name'
                  : null
              }
            />
          </Grid>
          <Grid item xs={4}>
            <InputLabel
              htmlFor="age-required"
              className={classes.cutom_select_Label}
              style={{ padding: '5px 0' }}
            >
              Phone number
            </InputLabel>
            <FormControl
              control="input"
              label=""
              placeholder=""
              maxLength={15}
              value={data.phone}
              onChange={(e) => {
                setData({ ...data, phone: validMobile(e.target.value) });
              }}
              required
              style={{ margin: '0 0' }}
            />
          </Grid>
          <Grid item xs={4}>
            <InputLabel
              htmlFor="age-required"
              className={classes.cutom_select_Label}
              style={{ padding: '5px 0' }}
            >
              Email
            </InputLabel>
            <FormControl
              control="input"
              label=""
              required
              placeholder=""
              name="email"
              onBlur={handleBlur}
              value={data.email}
              maxLength={60}
              onChange={(e) => setData({ ...data, email: e.target.value })}
              style={{ margin: '0 0' }}
              error={Boolean(touched.email && checkEmail(data.email))}
              helperText={
                touched.email && checkEmail(data.email)
                  ? 'Please enter valid Email'
                  : null
              }
            />
          </Grid>
          <Grid item xs={4}>
            <InputLabel
              htmlFor="age-required"
              className={classes.cutom_select_Label}
              style={{ padding: '5px 0' }}
            >
              Website
            </InputLabel>
            <FormControl
              control="input"
              label=""
              placeholder=""
              name="website"
              onBlur={handleBlur}
              required
              value={data.website}
              onChange={(e) => setData({ ...data, website: e.target.value })}
              style={{ margin: '0 0' }}
              maxLength={200}
            />
          </Grid>
        </Grid>
      </Grid>
      <Box mt="40px" />
      <Grid container direction="row" spacing={0}>
        <Grid item>
          <Box>
            <Typography
              color="#000"
              variant="h5"
              component="div"
              className={classes.bktitle}
              style={{ margin: '0 0' }}
            >
              Address Information
            </Typography>
          </Box>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={2}
        style={{ alignItems: 'center', padding: '10px 0 0' }}
      >
        <Grid item xs={10} xl={10} lg={10}>
          <InputLabel
            htmlFor="age-required"
            required
            className={classes.cutom_select_Label}
            style={{ padding: '5px 0' }}
          >
            Street, City, State/Province, Zip Code
          </InputLabel>
          <FormControl
            control="input"
            label=""
            placeholder=""
            required
            value={getFullAddress(data)}
            style={{ margin: '0 0' }}
          />
        </Grid>
        <Grid item xs style={{ marginTop: '5px' }}>
          <InputLabel
            htmlFor="age-required"
            className={classes.cutom_select_Label}
            style={{ padding: '5px 0' }}
          >
            &nbsp;
          </InputLabel>
          {!Edit ? (
            <JBButton
              type="outlined"
              style={{ width: '100%', padding: '5px 10px' }}
              isImg={edit}
              onClick={() => setEdit(true)}
            >
              Edit Address
            </JBButton>
          ) : (
            <JBButton
              type="outlined"
              style={{ width: '100%' }}
              // isImg={edit}
              onClick={() => setEdit(false)}
            >
              Save Address
            </JBButton>
          )}
        </Grid>
      </Grid>

      {!Edit ? null : (
        <Box style={{ width: '100%' }}>
          <Grid
            container
            spacing={2}
            style={{
              alignItems: 'center',
              padding: '15px 0'
            }}
          >
            <Grid item xs={6}>
              <InputLabel
                htmlFor="age-required"
                required
                className={classes.cutom_select_Label}
                style={{ padding: '5px 0' }}
              >
                Street address line 1
              </InputLabel>
              <FormControl
                control="input"
                label=""
                placeholder=""
                required
                value={data.address}
                name="address"
                onBlur={handleBlur}
                onChange={(e) => setData({ ...data, address: e.target.value })}
                style={{ margin: '0 0' }}
                error={Boolean(
                  touched.address && checkValidString(data.address)
                )}
                helperText={
                  touched.address && checkValidString(data.address)
                    ? 'Please enter valid Address'
                    : null
                }
              />
            </Grid>
            <Grid item xs={6}>
              <InputLabel
                htmlFor="age-required"
                className={classes.cutom_select_Label}
                style={{ padding: '5px 0' }}
              >
                Street address line 2
              </InputLabel>
              <FormControl
                value={data.address2}
                onChange={(e) => setData({ ...data, address2: e.target.value })}
                control="input"
                label=""
                placeholder=""
                required
                style={{ margin: '0 0' }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            style={{
              alignItems: 'center'
              // marginBottom: '5px'
            }}
          >
            <Grid item xs={3}>
              <InputLabel
                htmlFor="age-required"
                required
                className={classes.cutom_select_Label}
                style={{ padding: '5px 0' }}
              >
                City
              </InputLabel>
              <FormControl
                control="input"
                label=""
                placeholder=""
                value={data.city}
                required
                name="city"
                onBlur={handleBlur}
                onChange={(e) => setData({ ...data, city: e.target.value })}
                style={{ margin: '0 0' }}
                error={Boolean(touched.city && checkValidString(data.city))}
                helperText={
                  touched.city && checkValidString(data.city)
                    ? 'Please enter valid City'
                    : null
                }
              />
            </Grid>
            <Grid item xs={3}>
              <InputLabel
                htmlFor="age-required"
                required
                className={classes.cutom_select_Label}
                style={{ padding: '5px 0' }}
              >
                Province
              </InputLabel>
              <FormControl
                control="input"
                label=""
                placeholder=""
                required
                value={data.state}
                name="state"
                onBlur={handleBlur}
                onChange={(e) => setData({ ...data, state: e.target.value })}
                style={{ margin: '0 0' }}
                error={Boolean(touched.state && checkValidString(data.state))}
                helperText={
                  touched.state && checkValidString(data.state)
                    ? 'Please enter valid State'
                    : null
                }
              />
            </Grid>
            <Grid item xs={3}>
              <InputLabel
                htmlFor="age-required"
                required
                className={classes.cutom_select_Label}
                style={{ padding: '8px 0' }}
              >
                Country
              </InputLabel>
              <CustomAutocomplete
                data={data.country}
                Options="name"
                name="country"
                required
                value={country || []}
                onBlur={handleBlur}
                Change={(val) => setData({ ...data, country: val })}
                error={Boolean(
                  touched.country && checkValidString(data.country)
                )}
                helperText={
                  touched.country && checkValidString(data.country)
                    ? 'Please enter valid country'
                    : null
                }
              />
            </Grid>
            <Grid item xs={3}>
              <InputLabel
                htmlFor="age-required"
                required
                className={classes.cutom_select_Label}
                style={{ padding: '5px 0' }}
              >
                Postal code
              </InputLabel>
              <FormControl
                control="input"
                label=""
                placeholder=""
                required
                name="zipcode"
                onBlur={handleBlur}
                value={data.zipcode}
                onChange={(e) => setData({ ...data, zipcode: e.target.value })}
                style={{ margin: '0 0' }}
                maxLength={10}
              />
            </Grid>
          </Grid>
        </Box>
      )}
      <Box mt="10px" />
      {/* google map */}
      <Grid container>
        <Grid item xs={12} style={{ margin: '20px 0' }}>
          {/* google map */}
          {MapLoads()}
        </Grid>
      </Grid>
      <Box mt="10px" />
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <InputLabel
            htmlFor="age-required"
            required
            className={classes.cutom_select_Label}
            style={{ padding: '8px 0' }}
          >
            Time zone
          </InputLabel>
          <CustomAutocomplete
            data={data.timezone}
            value={TimeFormats}
            Change={(val) => setData({ ...data, timezone: val })}
            Options="name"
            error={Boolean(touched.timezone && checkValidString(data.timezone))}
            helperText={
              touched.timezone && checkValidString(data.timezone)
                ? 'Please enter valid timezone'
                : null
            }
          />
        </Grid>
      </Grid>

      <Box mt="40px" />
      <Grid container direction="row" spacing={0}>
        <Grid item>
          <Box>
            <Typography
              color="#000"
              variant="h5"
              component="div"
              className={classes.bktitle}
            >
              Locations
            </Typography>
          </Box>
          <p className={classes.ptext}>
            Details for each location created in Jungle can be edited here.
          </p>
        </Grid>

        <Grid item xs={12}>
          <Box mt="25px" />
          {gymLocs.length > 0 ? (
            <LocationsTable values={gymLocs} />
          ) : (
            <LoadingView />
          )}
          {/* <LocationsTable values={DummyValues} /> */}
        </Grid>
      </Grid>

      <Box mt="40px" />
      <Grid container direction="row" justify="flex-end" alignItems="center">
        <Box ml="10px">
          <JBButton
            type="outlined"
            onClick={handleCancel}
            // component={Link}
            // to={ROUTES.PEOPLE_CREATE_MEMBER}
          >
            Cancel
          </JBButton>
        </Box>
        <Box ml="10px">
          <JBButton
            type="primary"
            onClick={handleUpdate}
            disabled={isProcessing}
          >
            Save
          </JBButton>
        </Box>
      </Grid>
    </div>
  );
};

InfoLocations.propTypes = {
  classes: PropTypes.object.isRequired
};
// const mapToDispatch = (dispatch) => ({
//   getAllGymloc: () => dispatch(getAllGymloc()),
//   openNotify: (msg, type) => dispatch(openNoti(msg, type))
// });
// export default connect(null, mapToDispatch)(withStyles(styles)(InfoLocations));

export default withStyles(styles, { withTheme: true })(InfoLocations);
