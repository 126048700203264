export const RadiotableHead = [
  {
    id: 1,
    label: 'Template name',
    cellName: 'name'
  }
];

export const RadioDummydata = [
  {
    id: 1,
    name: '6 Month Contract',
    members: 'Whatever'
  },
  {
    id: 2,
    name: '6 Month Contract',
    members: 'Whatever'
  }
];
