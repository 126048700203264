import React, { useState } from 'react';
import PropType from 'prop-types';
import { connect } from 'react-redux';
import {
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  makeStyles
} from '@material-ui/core';
import { openNoti } from 'src/Redux/global/global.actions';
import { getErrorMsg } from 'src/utils/ErrorHandling';
import { delLineInvoice, updateLineInv } from 'src/services/Invoice.services';
import GetPrice from 'src/views/common/CurrencySymbol/GetPrice';
import {
  getAllInvoices,
  getFailedInvoices,
  getOverdueInvs,
  getPaidInvs,
  getProcessingInvoices,
  getUnpaidInvs
} from 'src/Redux/InvoiceReducer/InvoiceReducer.actions';
import DeleteDropInDialog from '../DeleteDropInDialog';
// import DeleteIcon from '../../../../../assets/image/deleteIcon.png';
// import { DiscountType } from '../Invoice.data';
import EditItem from '../EditItem';
import { getEditItem } from '../AddItem.validation';

const useStyles = makeStyles({
  table: {
    minWidth: '100%'
  },
  tableLink: {
    color: '#01847A',
    fontWeight: '700'
  },
  TableCell: {
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px',
    height: '56px',
    minHeight: '56px'
  },
  Tablemian: {
    borderRadius: '4px'
  },
  button_status_deac: {
    fontWeight: 'bold',
    fontSize: '11px',
    color: '#01847A',
    border: '1px solid rgba(1, 132, 122, 1)',
    width: '100px',
    height: '24px',
    padding: '0 0',
    borderRadius: '2px'
  },
  button_status_act: {
    fontWeight: 'bold',
    fontSize: '11px',
    color: '#9B9B9B',
    border: '1px solid #949494',
    width: '100px',
    height: '24px',
    padding: '0 0',
    borderRadius: '2px',
    '&$hover': {
      backgroundColor: '#1976d2'
    }
  }
  // DeleteIcon: {
  //   padding: '6px 5px',
  //   minWidth: 'auto'
  // }
});

const CategoryTable = (props) => {
  const classes = useStyles();
  const {
    values,
    currencyState: { error },
    OpenNoti,
    deleteItem,
    refreshAll,
    refreshOverdue,
    refreshPaid,
    refreshUnpaid,
    statuscode,
    refreshProcessing,
    refreshFailed
  } = props;
  const [DeleteDialog, setDeleteDialog] = useState({
    id: null,
    name: null,
    show: false
  });
  const [editDiag, seteditDiag] = useState({
    data: null,
    show: false
  });
  const handleDelete = () => {
    if (DeleteDialog.id) {
      delLineInvoice(DeleteDialog.id)
        .then((res) => deleteItem(res))
        .then(() =>
          OpenNoti(`${DeleteDialog.name} successfuly deleted`, 'info'))
        .then(() => {
          refreshAll();
          refreshPaid();
          refreshUnpaid();
          refreshOverdue();
          refreshProcessing();
          refreshFailed();
          setDeleteDialog({ id: null, name: null, show: false });
        })
        .catch((err) => OpenNoti(getErrorMsg(err), 'error'));
    }
  };

  const handleSave = (newValues) => {
    updateLineInv(newValues, editDiag.data.id)
      .then((res) => deleteItem(res))
      .then(() => OpenNoti('Successfuly updated the product', 'info'))
      .then(() => seteditDiag({ data: null, show: false }))
      .catch((err) => OpenNoti(getErrorMsg(err), 'error'));
  };

  if (error) return <p>Error Occured , please try again...</p>;
  return (
    <TableContainer
      component={Paper}
      elevation={0}
      className={classes.Tablemian}
    >
      <Table className={classes.table} aria-label="simple table">
        <TableHead
          style={{ background: '#F9F9FA', backgroundColor: '#F9F9FA' }}
        >
          <TableRow>
            <TableCell className={classes.TableCell}>Product name</TableCell>
            <TableCell className={classes.TableCell} align="right">
              Quantity
            </TableCell>
            <TableCell className={classes.TableCell} align="right">
              Item price
            </TableCell>
            <TableCell className={classes.TableCell} align="right">
              Notes
            </TableCell>
            <TableCell className={classes.TableCell} align="right">
              Item Discount
            </TableCell>
            {statuscode === 5 ? (
              <TableCell className={classes.TableCell} align="right">
                Refunded Amount
              </TableCell>
            ) : null}
            <TableCell className={classes.TableCell} align="right">
              Item Total
            </TableCell>
            <TableCell className={classes.TableCell} align="center">
              &nbsp;
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {values.map((row) => (
            <TableRow
              hover
              tabIndex={-1}
              key={row.id}
              style={{
                backgroundColor: '#FFF'
              }}
            >
              <TableCell
                style={{
                  width: '25%'
                  // cursor: 'pointer'
                }}
                // onClick={() => seteditDiag({ data: row, show: true })}
              >
                {row.name}
              </TableCell>
              <TableCell
                style={{
                  width: '5%'
                }}
                align="right"
              >
                {row.qty}
              </TableCell>
              <TableCell
                style={{
                  width: '15%'
                }}
                align="right"
              >
                {/* {data.currency_symbol +
                  parseInt(row.sale_price, 10).toFixed(data.decimals)} */}
                <GetPrice value={row.sale_price} />
              </TableCell>
              <TableCell
                style={{
                  width: '13%'
                }}
                align="right"
              >
                {/* {row.discount_notes} */}
                {row.discount_notes && row.discount_notes.split('.00').join('')}
              </TableCell>
              <TableCell
                style={{
                  width: '15%'
                }}
                align="right"
              >
                {row.discount_type === 'fixed' ? (
                  <div>
                    <span>-</span>
                    <GetPrice value={row.line_discount} />
                  </div>
                ) : (
                  <p>{row.line_discount} %</p>
                )}
                {/* {row.line_discount +
                  DiscountType.filter((res) => res.id === row.discount_type)[0]
                    .symbol || null} */}
              </TableCell>

              {statuscode === 5 ? (
                <TableCell
                  style={{
                    width: '17%'
                  }}
                  align="right"
                >
                  {/* {data.currency_symbol +
                  parseInt(row.sale_price, 10).toFixed(data.decimals)} */}
                  <div>
                    (
                    <GetPrice value={row.subtotal} valuetype="neg" />)
                  </div>
                </TableCell>
              ) : null}

              <TableCell
                style={{
                  width: '10%'
                }}
                align="right"
              >
                {/* {data.currency_symbol +
                  parseInt(row.subtotal, 10).toFixed(data.decimals)} */}
                <GetPrice value={row.subtotal} />
              </TableCell>

              <TableCell
                style={{
                  width: '3%'
                }}
              >
                {/* Not Required in Phase 1 */}
                {/* statuscode === 2 || statuscode === 3 ? null : (
                  <Button
                    onClick={() =>
                      setDeleteDialog({
                        id: row.id,
                        name: row.name,
                        show: true
                      })
                    }
                    className={classes.DeleteIcon}
                  >
                    <img alt="img" src={DeleteIcon} />
                  </Button>
                  ) */}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {editDiag.data && (
        <EditItem
          setOpen={editDiag.show}
          item={getEditItem(editDiag.data)}
          getValues={(val) => handleSave(val)}
          handleClose={() => seteditDiag({ data: null, show: false })}
        />
      )}
      <DeleteDropInDialog
        name={DeleteDialog.name}
        setOpen={DeleteDialog.show}
        handleDelete={() => handleDelete()}
        handleClose={() =>
          setDeleteDialog({ id: null, name: null, show: false })
        }
      />
    </TableContainer>
  );
};
CategoryTable.propTypes = {
  values: PropType.array.isRequired,
  currencyState: PropType.object.isRequired,
  OpenNoti: PropType.func.isRequired,
  deleteItem: PropType.func.isRequired,
  statuscode: PropType.number.isRequired,
  refreshAll: PropType.func.isRequired,
  refreshOverdue: PropType.func.isRequired,
  refreshPaid: PropType.func.isRequired,
  refreshUnpaid: PropType.func.isRequired,
  refreshProcessing: PropType.func.isRequired,
  refreshFailed: PropType.func.isRequired
};

const mapToState = (state) => ({
  currencyState: state.SettingReducer.currency
});

const mapToDispatch = (dispatch) => ({
  OpenNoti: (msg, type) => dispatch(openNoti(msg, type)),
  refreshAll: () => dispatch(getAllInvoices()),
  refreshPaid: () => dispatch(getPaidInvs()),
  refreshUnpaid: () => dispatch(getUnpaidInvs()),
  refreshOverdue: () => dispatch(getOverdueInvs()),
  refreshProcessing: () => dispatch(getProcessingInvoices()),
  refreshFailed: () => dispatch(getFailedInvoices())
});

export default connect(mapToState, mapToDispatch)(CategoryTable);
