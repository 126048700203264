// eslint-disable-next-line import/prefer-default-export
export const getReducerData = (action, state, data, type) => {
  switch (action) {
    case type.isFetching:
      return {
        ...state,
        isFetching: true
      };
    case type.fetchSucess:
      return {
        ...state,
        isFetching: false,
        error: null,
        data
      };
    case type.Errorfetch:
      return {
        ...state,
        isFetching: false,
        error: data
      };
    default:
      return state;
  }
};
