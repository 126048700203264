// eslint-disable-next-line import/prefer-default-export
export const TobeFilter = [
  { id: 'all_time', filter: 'All Time' },
  { id: 'custom', filter: 'Custom' },
  { id: 'thisyear', filter: 'This Year' },
  { id: 'lastyear', filter: 'Last Year' },
  { id: 'thismonth', filter: 'This Month' },
  { id: 'lastmonth', filter: 'Last Month' }
];
// eslint-disable-next-line import/prefer-default-export
// export const TobeFilter = [
//   // { id: 1, filter: 'All Time' },
//   { id: 1, filter: 'Custom' },
//   { id: 2, filter: 'This Year' },
//   { id: 3, filter: 'Last Year' },
//   { id: 4, filter: 'This Month' },
//   { id: 5, filter: 'Last Month' }
// ];
