import React from 'react';
import PropType from 'prop-types';
import {
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles({
  table: {
    minWidth: '100%'
  },
  tableLink: {
    color: '#01847A',
    fontWeight: '700'
  },
  TableCell: {
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px',
    height: '56px',
    minHeight: '56px'
  },
  Tablemian: {
    borderRadius: '4px'
  },
  button_status_deac: {
    fontWeight: 'bold',
    fontSize: '11px',
    color: '#01847A',
    border: '1px solid rgba(1, 132, 122, 1)',
    width: '100px',
    height: '24px',
    padding: '0 0',
    borderRadius: '2px'
  },
  button_status_act: {
    fontWeight: 'bold',
    fontSize: '11px',
    color: '#9B9B9B',
    border: '1px solid #949494',
    width: '100px',
    height: '24px',
    padding: '0 0',
    borderRadius: '2px',
    '&$hover': {
      backgroundColor: '#1976d2'
    }
  }
});

const CategoryTable = (props) => {
  const classes = useStyles();
  const { values } = props;
  return (
    <TableContainer
      component={Paper}
      elevation={0}
      className={classes.Tablemian}
    >
      <Table className={classes.table} aria-label="simple table">
        <TableHead
          style={{ background: '#F9F9FA', backgroundColor: '#F9F9FA' }}
        >
          <TableRow>
            <TableCell className={classes.TableCell}>Name</TableCell>
            <TableCell className={classes.TableCell}>Street Address</TableCell>
            <TableCell className={classes.TableCell}>City</TableCell>
            <TableCell className={classes.TableCell}>State</TableCell>
            <TableCell className={classes.TableCell}>Zip code</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {values.map((row) => (
            <TableRow
              hover
              tabIndex={-1}
              key={row.id}
              style={{
                backgroundColor: '#FFF'
              }}
            >
              <TableCell
                style={{
                  width: '20%'
                }}
              >
                {row.gym_name}
                {/* {row.name} */}
              </TableCell>
              <TableCell
                style={{
                  width: '30%'
                }}
              >
                {row.address} {row.address2}
                {/* {row.streetaddress} */}
              </TableCell>
              <TableCell
                style={{
                  width: '20%'
                }}
              >
                {row.city}
              </TableCell>
              <TableCell
                style={{
                  width: '15%'
                }}
              >
                {row.state}
              </TableCell>
              <TableCell
                style={{
                  width: '10%'
                }}
              >
                {row.zipcode}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
CategoryTable.propTypes = {
  values: PropType.array.isRequired
};

export default CategoryTable;
