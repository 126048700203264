import React from 'react';
import PropType from 'prop-types';
import { ROUTES } from 'src/constant/Constants';
import { Link } from 'react-router-dom';
import {
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableSortLabel,
  TableBody,
  Table,
  withStyles
} from '@material-ui/core';
import GetPrice from 'src/views/common/CurrencySymbol/GetPrice';
import filterType from 'src/Redux/Reducer.constants';

const styles = () => ({
  table: {
    minWidth: '100%',
    wordBreak: 'break-word',
    '& td': {
      padding: '10px'
    },
    '@media (max-width: 1366px)': {
      '& td': {
        fontSize: '13px'
      }
    }
  },
  tableLink: {
    color: '#01847A',
    fontWeight: '700'
  },
  TableCell: {
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px',
    height: '56px',
    minHeight: '56px',
    padding: '10px',
    '@media (max-width: 1366px)': {
      fontSize: '13px',
      wordBreak: 'keep-all'
    }
  },
  Tablemian: {},
  SummaryCell: {
    color: '#292929',
    fontWeight: 'bold'
  }
});

const RevenueCategoryTable = (props) => {
  const {
    values,
    classes,
    isActive,
    setDirection,
    setSort,
    totalSummary,
    summary
    // summary
  } = props;
  const {
    sort_net_revenue,
    sort_revenue_category,
    sort_quantity,
    sort_product_name,
    sort_location_name,
    sort_members,
    sort_payment_due
  } = filterType;
  return (
    <TableContainer
      component={Paper}
      elevation={0}
      className={classes.Tablemian}
    >
      <Table className={classes.table} aria-label="simple table">
        <TableHead
          style={{ background: '#F9F9FA', backgroundColor: '#F9F9FA' }}
        >
          <TableRow>
            <TableCell className={classes.TableCell}>
              <TableSortLabel
                direction={setDirection(sort_revenue_category)}
                active={isActive(sort_revenue_category)}
                onClick={() => setSort(sort_revenue_category)}
              >
                Revenue Category
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell}>
              <TableSortLabel
                direction={setDirection(sort_location_name)}
                active={isActive(sort_location_name)}
                onClick={() => setSort(sort_location_name)}
              >
                Location
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell}>
              <TableSortLabel
                direction={setDirection(sort_payment_due)}
                active={isActive(sort_payment_due)}
                onClick={() => setSort(sort_payment_due)}
              >
                Payment Due
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell}>
              <TableSortLabel
                direction={setDirection(sort_members)}
                active={isActive(sort_members)}
                onClick={() => setSort(sort_members)}
              >
                Member
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell}>
              <TableSortLabel
                direction={setDirection(sort_product_name)}
                active={isActive(sort_product_name)}
                onClick={() => setSort(sort_product_name)}
              >
                Product
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell} align="right">
              <TableSortLabel
                direction={setDirection(sort_quantity)}
                active={isActive(sort_quantity)}
                onClick={() => setSort(sort_quantity)}
              >
                Quantity
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell} align="right">
              Payment Method
            </TableCell>
            <TableCell className={classes.TableCell} align="right">
              <TableSortLabel
                direction={setDirection(sort_net_revenue)}
                active={isActive(sort_net_revenue)}
                onClick={() => setSort(sort_net_revenue)}
              >
                Net Revenue
              </TableSortLabel>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            <>
              <TableRow>
                <TableCell
                  style={{
                    width: '9%'
                  }}
                  className={classes.SummaryCell}
                >
                  Summary
                </TableCell>
                <TableCell
                  style={{
                    width: '9%'
                  }}
                  className={classes.SummaryCell}
                >
                  &nbsp;
                </TableCell>
                <TableCell
                  style={{
                    width: '9%'
                  }}
                  className={classes.SummaryCell}
                >
                  &nbsp;
                </TableCell>
                <TableCell
                  style={{
                    width: '9%'
                  }}
                  className={classes.SummaryCell}
                >
                  &nbsp;
                </TableCell>
                <TableCell
                  style={{
                    width: '9%'
                  }}
                  className={classes.SummaryCell}
                >
                  &nbsp;
                </TableCell>
                <TableCell
                  style={{
                    width: '9%'
                  }}
                  align="right"
                  className={classes.SummaryCell}
                >
                  {totalSummary.total_records_summary}
                </TableCell>
                <TableCell
                  style={{
                    width: '9%'
                  }}
                  className={classes.SummaryCell}
                >
                  &nbsp;
                </TableCell>
                <TableCell
                  style={{
                    width: '9%'
                  }}
                  className={classes.SummaryCell}
                  align="right"
                >
                  <GetPrice value={totalSummary.net_revenue_summary} />
                </TableCell>
              </TableRow>
              {summary.map((item) => (
                <TableRow>
                  <TableCell
                    style={{
                      width: '9%'
                    }}
                    className={classes.SummaryCell}
                  >
                    {item.revenue_category}
                  </TableCell>
                  <TableCell
                    style={{
                      width: '9%'
                    }}
                    className={classes.SummaryCell}
                  >
                    &nbsp;
                  </TableCell>
                  <TableCell
                    style={{
                      width: '9%'
                    }}
                    className={classes.SummaryCell}
                  >
                    &nbsp;
                  </TableCell>
                  <TableCell
                    style={{
                      width: '9%'
                    }}
                    className={classes.SummaryCell}
                  >
                    &nbsp;
                  </TableCell>
                  <TableCell
                    style={{
                      width: '9%'
                    }}
                    className={classes.SummaryCell}
                  >
                    &nbsp;
                  </TableCell>
                  <TableCell
                    style={{
                      width: '9%'
                    }}
                    className={classes.SummaryCell}
                    align="right"
                  >
                    {item.sum_quantity}
                  </TableCell>
                  <TableCell
                    style={{
                      width: '9%'
                    }}
                    className={classes.SummaryCell}
                  >
                    &nbsp;
                  </TableCell>
                  <TableCell
                    style={{
                      width: '9%'
                    }}
                    className={classes.SummaryCell}
                    align="right"
                  >
                    <GetPrice value={item.sum_subtotal} />
                  </TableCell>
                </TableRow>
              ))}{' '}
            </>
          }
          {values
            && values.data
            && values.data.map((row) => (
              <TableRow
                hover
                tabIndex={-1}
                key={row.invoice_id}
                style={{
                  backgroundColor: '#FFF'
                }}
              >
                <TableCell
                  style={{
                    width: '9.0909%'
                  }}
                >
                  {/* <Link className={classes.tableLink}>
                </Link> */}
                  {row.revenue_category}
                </TableCell>
                <TableCell
                  style={{
                    width: '9.0909%'
                  }}
                >
                  {row.location_name}
                </TableCell>
                <TableCell
                  style={{
                    width: '9%'
                  }}
                >
                  {row.payment_due}
                </TableCell>
                <TableCell
                  style={{
                    width: '9%'
                  }}
                >
                  <Link
                    className={classes.tableLink}
                    to={{
                      pathname: ROUTES.PEOPLE_MEMBER_PROFILE,
                      search: `memberId=${btoa(row.user_id)}`
                    }}
                  >
                    {row.member}
                  </Link>
                </TableCell>
                <TableCell
                  style={{
                    width: '9%'
                  }}
                  align="left"
                >
                  {row.product_name}
                </TableCell>
                <TableCell
                  style={{
                    width: '9%'
                  }}
                  align="right"
                >
                  {row.quantity}
                </TableCell>
                <TableCell
                  style={{
                    width: '9%'
                  }}
                  align="right"
                >
                  {row.payment_method}
                </TableCell>
                <TableCell
                  style={{
                    width: '9%'
                  }}
                  align="right"
                >
                  <GetPrice value={row.net_revenue} />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      {values?.data?.length === 0 && (
        <p
          style={{
            margin: '10px 15px'
          }}
        >
          No revenue to show..
        </p>
      )}
    </TableContainer>
  );
};
RevenueCategoryTable.propTypes = {
  values: PropType.array.isRequired,
  totalSummary: PropType.array.isRequired,
  classes: PropType.object.isRequired,
  // summary: PropType.array.isRequired,
  setDirection: PropType.func.isRequired,
  isActive: PropType.func.isRequired,
  setSort: PropType.func.isRequired,
  summary: PropType.array.isRequired
};

export default withStyles(styles)(RevenueCategoryTable);
