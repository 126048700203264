import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ROUTES } from 'src/constant/Constants';
import PropType from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import {
  AllClassdata,
  futureddata,
  reccurdata
} from 'src/Redux/ClassReducer/classReducer.actions';
import Typography from '@material-ui/core/Typography';
import { openNoti } from 'src/Redux/global/global.actions';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  DelRecurrClass,
  DelSingleClass
} from 'src/services/classModule.services';
import { withStyles } from '@material-ui/core/styles';
import styles from './Class.style';
import X from '../../../assets/image/x.png';
import { getErrorMsg } from '../../../utils/ErrorHandling';

function DeleteDialog(props) {
  const { classes, recurr } = props;
  const [open, setOpen] = React.useState(false);
  const history = useHistory();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    const {
      id,
      OpenNoti,
      getReccClass,
      getFuture,
      getAllClass,
      openWarnigDialog
    } = props;
    if (recurr) {
      DelRecurrClass(id)
        .then(() => getReccClass())
        .then(() => getAllClass())
        .then(() => getFuture())
        .then(() => history.push(ROUTES.CLASSES_RECURRING))
        .then(() => OpenNoti('Sucessfully deleted all classes', 'info'))
        .catch((err) => {
          if (err?.response?.status === 418) {
            openWarnigDialog(err.response.data.message);
            handleClose();
          } else {
            OpenNoti(getErrorMsg(err), 'error');
          }
        });
    } else {
      DelSingleClass(id)
        .then(() => getAllClass())
        .then(() => getFuture())
        .then(() => history.goBack())
        .then(() => OpenNoti('Successfully delete the class', 'info'))
        .catch((err) => OpenNoti(getErrorMsg(err), 'error'));
    }
  };

  return (
    <div>
      <Button
        className={classes.Deletebutton}
        onClick={handleClickOpen}
        style={{ marginLeft: '15px' }}
      >
        DELETE
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div style={{ width: 394 }}>
          <DialogTitle id="alert-dialog-title">
            <img
              alt="Plusicon"
              src={X}
              style={{ display: 'inline-block', verticalAlign: 'sub' }}
            />
            <Typography
              color="#000"
              variant="h5"
              component="div"
              className={classes.title}
              style={{ display: 'inline-block', marginLeft: '10px' }}
            >
              Delete Class
            </Typography>
          </DialogTitle>
          <DialogContent>
            <p
              style={{
                fontWeight: 'normal',
                fontSize: '14px',
                lineHeight: '18px',
                letterSpacing: '0.002em',
                color: '#7F7F7F',
                padding: '10px 0'
              }}
            >
              Are you sure you want to permanently delete this{' '}
              {recurr && 'Recurring'} Class?
            </p>
          </DialogContent>
          <DialogActions
            style={{
              padding: '20px 20px'
            }}
          >
            <Button
              variant="outlined"
              onClick={handleClose}
              style={{
                height: '48px',
                color: '#7F7F7F',
                fontWeight: 'bold'
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleDelete}
              autoFocus
              className={classes.Deletebtn}
            >
              delete
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}

DeleteDialog.propTypes = {
  classes: PropType.object.isRequired,
  recurr: PropType.bool.isRequired,
  id: PropType.number.isRequired,
  OpenNoti: PropType.func.isRequired,
  getFuture: PropType.func.isRequired,
  getAllClass: PropType.func.isRequired,
  getReccClass: PropType.func.isRequired,
  openWarnigDialog: PropType.func.isRequired
};
const mapToDispatch = (dispatch) => ({
  OpenNoti: (msg, type) => dispatch(openNoti(msg, type)),
  getFuture: () => dispatch(futureddata()),
  getAllClass: () => dispatch(AllClassdata()),
  getReccClass: () => dispatch(reccurdata())
});
export default connect(null, mapToDispatch)(withStyles(styles)(DeleteDialog));
