import { ROUTES } from 'src/constant/Constants';

// eslint-disable-next-line
export const getActiveVal = (pathname) => {
  switch (pathname) {
    case ROUTES.DOCUMENTS_WAIVERS:
      return 0;
    case ROUTES.DOCUMENTS_WAIVERS_SIGNED:
      return 1;
    case ROUTES.DOCUMENTS_WAIVERS_TEMPLATES:
      return 2;
    default:
      return 0;
  }
};
// eslint-disable-next-line
export const getMissingVal = (pathname) => {
  switch (pathname) {
    case ROUTES.DOCUMENTS_WAIVERS:
      return 0;
    case ROUTES.DOCUMENTS_WAIVERS_INACTIVE:
      return 1;
    case ROUTES.DOCUMENTS_WAIVERS_ALL:
      return 2;
    default:
      return 0;
  }
};

// eslint-disable-next-line
// Check Status export Waiver
export const CheckStatus = (pathname) => {
  switch (pathname) {
    case ROUTES.DOCUMENTS_WAIVERS:
      return 0;
    case ROUTES.DOCUMENTS_WAIVERS_SIGNED:
      return 1;
    default:
      return 0;
  }
};
