/* eslint-disable operator-linebreak */
export const getAddr = (data) => {
  const postal = data.address.postal_code;
  let country = '';

  // if (typeof data.address.postal_code === 'number') {
  //   postal = data.address.postal_code;
  // }
  if (data.address.country) {
    country = data.address.country.name;
  }
  const updated = {
    address: data.address.line1,
    address2: data.address.line2,
    city: data.address.city,
    province: data.address.state,
    postal_code: postal,
    country
  };
  const result = Object.values(updated)
    .filter((l) => l)
    .join(', ');

  return result;
};

export const forStripeData = (data) => {
  const b = {
    name: data.name,
    phone: data.phone,
    address: {
      ...data.address,
      country: data.address.country.code
    }
  };
  return b;
};

export const getValidStep1 = (data) => {
  if (!data) {
    return true;
  }
  return false;
};

export const getValidStep2 = (data) => {
  if (
    !data.phone ||
    !data.address.line1 ||
    !data.address.city ||
    !data.address.state ||
    !data.address.country ||
    !data.address.postal_code
  ) {
    return true;
  }
  return false;
};

export const getValidStep3 = (billing, cardComplete) => {
  if (!billing.name || !cardComplete) {
    return true;
  }
  return false;
};

export const getInfoValues = (info, countrycode) => {
  return {
    name: info?.first_name + ' ' + info?.last_name,
    phone: info?.mobile_no,
    address: {
      city: info?.city || '',
      country:
        countrycode.filter((code) => code.code === info.country)[0] || null,
      line1: info?.address || '',
      line2: info?.address2 || '',
      postal_code: info?.postal_code || '',
      state: info?.province
    }
  };
};

export const validBankDetails = (data) => {
  if (
    !data.name
    // ||
    // !data.account_number ||
    // !data.bsb_number ||
    // data.account_number.length < 3 ||
    // data.bsb_number.length < 3
  ) {
    return true;
  }
  return false;
};
