export const GymdateFormat = [
  { id: 1, format: 'd/m/Y', value: 'DD/MM/YYYY', picker: 'dd/MM/yyyy' },
  { id: 2, format: 'd-m-Y', value: 'DD-MM-YYYY', picker: 'dd-MM-yyyy' },
  { id: 3, format: 'm/d/Y', value: 'MM/DD/YYYY', picker: 'MM/dd/yyyy' },
  { id: 4, format: 'm-d-Y', value: 'MM-DD-YYYY', picker: 'MM-dd-yyyy' },
  { id: 5, format: 'Y/m/d', value: 'YYYY/MM/DD', picker: 'yyyy/MM/dd' },
  { id: 6, format: 'Y-m-d', value: 'YYYY-MM-DD', picker: 'yyyy-MM-dd' }
];

export const GymTimeFormat = {
  twelve: '12',
  twentyfour: '24'
};
