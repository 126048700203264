export const tableHead = [
  {
    id: 1,
    label: 'Program name',
    cellName: 'name',
    href: '#'
  },
  {
    id: 2,
    label: 'Description',
    cellName: 'description'
  }
];

export const Dummydata = [
  {
    id: 1,
    name: 'Bodyweight: A',
    members: 'Prep the body for the rigours of Movement training'
  },
  {
    id: 2,
    name: 'Bodyweight: A',
    members: 'Prep the body for the rigours of Movement training'
  },
  {
    id: 3,
    name: 'Bodyweight: A',
    members: 'Prep the body for the rigours of Movement training'
  }
];
// ==================
export const DatatableHead = [
  {
    id: 1,
    label: 'Membership template',
    cellName: 'name'
  },
  {
    id: 2,
    label: 'Membership type',
    cellName: 'membershiptype'
  },
  {
    id: 3,
    label: 'Membership attendance type',
    cellName: 'attendance'
  }
];

export const DummyValues = [
  {
    id: 1,
    name: '$50/wk student membership',
    membershiptype: 'Class Plan',
    attendance: 'Unlimited'
  },
  {
    id: 2,
    name: '$50/wk student membership',
    membershiptype: 'Class Plan',
    attendance: 'Unlimited'
  },
  {
    id: 3,
    name: '$50/wk student membership',
    membershiptype: 'Class Plan',
    attendance: 'Unlimited'
  },
  {
    id: 4,
    name: '$50/wk student membership',
    membershiptype: 'Class Plan',
    attendance: 'Unlimited'
  },
  {
    id: 5,
    name: '$50/wk student membership',
    membershiptype: 'Class Plan',
    attendance: 'Unlimited'
  }
];
// ==============================

export const stDatatableHead = [
  {
    id: 1,
    label: 'Members',
    cellName: 'members'
  },
  {
    id: 2,
    label: 'Membership name',
    cellName: 'membership_name'
  },
  {
    id: 3,
    label: 'Membership plan type',
    cellName: 'membership_type'
  },
  {
    id: 4,
    label: 'Programs',
    cellName: 'programe'
  }
];

export const stDummydata = [
  {
    id: 1,
    name: 'Aaron Hook',
    membership_name: 'Class Plan',
    plan: 'Class plan',
    programe: ['Bodyweight: A', 'Bodyweight: B', 'Bodyweight: C']
  },
  {
    id: 2,
    name: 'Aaron Hook',
    membership_name: 'Class Plan',
    membership_type: 'Class plan',
    programe:
      'Bodyweight: A, Bodyweight: B, Bodyweight: C, Fight Factory, Hand Balance, Jiu Jitsu, Lift, Strenght Movement, Stratch, Swole'
  },
  {
    id: 3,
    name: 'Aaron Hook',
    membership_name: 'Class Plan',
    membership_type: 'Class plan',
    programe:
      'Bodyweight: A, Bodyweight: B, Bodyweight: C, Fight Factory, Hand Balance, Jiu Jitsu, Lift, Strenght Movement, Stratch, Swole'
  },
  {
    id: 4,
    name: 'Aaron Hook',
    membership_name: 'Class Plan',
    membership_type: 'Class plan',
    programe:
      'Bodyweight: A, Bodyweight: B, Bodyweight: C, Fight Factory, Hand Balance, Jiu Jitsu, Lift, Strenght Movement, Stratch, Swole'
  },
  {
    id: 5,
    name: 'Aaron Hook',
    membership_name: 'Class Plan',
    membership_type: 'Class plan',
    programe:
      'Bodyweight: A, Bodyweight: B, Bodyweight: C, Fight Factory, Hand Balance, Jiu Jitsu, Lift, Strenght Movement, Stratch, Swole'
  }
];
