import Types from './MembershipReducer.types';
import getMembers from './MembershipReducer.utils';
import filterlist from '../Reducers.utils';

const initialValue = {
  memberships: { isFetching: false, data: null, error: null },
  sessions: { isFetching: false, data: null, error: null },
  dropIn: { isFetching: false, data: null, error: null },
  membershipFilter: {
    page: 1,
    search: '',
    sorting: {},
    default: {
      membership_type: 'plan'
    }
  },
  sessionFilter: {
    page: 1,
    search: '',
    sorting: {},
    default: {
      membership_type: 'session'
    }
  },
  dropInFilter: {
    page: 1,
    search: '',
    sorting: {},
    default: {
      membership_type: 'drop_in'
    }
  }
};

export default (state = initialValue, action) => {
  switch (action.type) {
    case Types.getplanMembership:
      return {
        ...state,
        memberships: getMembers(
          Types.getplanMembership,
          action.actionType,
          state.memberships,
          action.payload
        )
      };
    case Types.getsessionMem:
      return {
        ...state,
        sessions: getMembers(
          Types.getsessionMem,
          action.actionType,
          state.sessions,
          action.payload
        )
      };
    case Types.getDropInMem:
      return {
        ...state,
        dropIn: getMembers(
          Types.getDropInMem,
          action.actionType,
          state.dropIn,
          action.payload
        )
      };
    case Types.MemPlanFilter:
      return {
        ...state,
        membershipFilter: filterlist(
          state.membershipFilter,
          action.filter,
          action.payload
        )
      };
    case Types.SessionFilter:
      return {
        ...state,
        sessionFilter: filterlist(
          state.sessionFilter,
          action.filter,
          action.payload
        )
      };
    case Types.dropInFilter:
      return {
        ...state,
        dropInFilter: filterlist(
          state.dropInFilter,
          action.filter,
          action.payload
        )
      };
    default:
      return state;
  }
};
