/* eslint-disable react/no-danger */
import React, { useEffect, useState, useMemo } from 'react';
import PropType from 'prop-types';
import { connect } from 'react-redux';
import clsx from 'clsx';
import {
  Grid,
  Card,
  Typography,
  Box,
  Breadcrumbs,
  InputLabel,
  withStyles,
  FormControlLabel,
  Button
} from '@material-ui/core';
import IOSSwitch from 'src/views/common/FormControl/IOSSwitch';
import queryString from 'query-string';
import DOMpurify from 'dompurify';
import { Redirect, Link } from 'react-router-dom';
import { ROUTES } from 'src/constant/Constants';
import moment from 'moment';
import TextareaControl from 'src/views/common/FormControl/TextareaControl';
import { editInvoice, updateInvoice } from 'src/services/Invoice.services';
import CancelDialog from 'src/views/common/CommonDialogBox/CancelDialog';
import JBDatePicker from 'src/views/common/FormControl/JBDatePicker';
import LoadingView from 'src/views/common/loading';
import { getErrorMsg } from 'src/utils/ErrorHandling';
import { openNoti } from 'src/Redux/global/global.actions';
import NotFoundView from 'src/views/errors/NotFoundView';
import GetPrice from 'src/views/common/CurrencySymbol/GetPrice';
import CancelDialogTwo from 'src/views/common/CommonDialogBox/CancelDialogTwo';
import useGymSetting from 'src/views/common/CustomHooks/useGymSettingRefresh';
import useChangeTitle from 'src/views/common/CustomHooks/useTitleChange';
import JBButton from '../../../common/JBButton/JBButton';
import CustomAutoComplete from '../../../common/CustomAutocomplete/commonAutocomplete';
import Actions from './Action';
import DiscountDiag from './DiscountDiag';
import styles from './InvoiceView.style';
import InvoiceTable from './InvoiceTable/InvoiceTable';
import Plusicon from '../../../../assets/image/plusicon.png';
import AddItem from './AddItem';
import { ReactComponent as SearchIcon } from '../../../../assets/image/searchIcon.svg';
import {
  getCreatedOn,
  getInvoiceData,
  getInvoiceStatus,
  getUpdatedOn
} from './InvoiceView.utils';
import TransactionDiag from './TransactionsInvoiceDialog';

const InvoiceView = (props) => {
  const {
    classes,
    location,
    OpenNoti,
    GetInvoicefooter: { data: Invoicefooter, error: Invoicefootererror }
  } = props;
  const [AddItemDialog, setAddItemDialog] = useState(false);
  const [discountDiag, setDisDiag] = useState({ data: null, show: false });
  const [data, setData] = useState(null);
  const [errorFetch, setErrFetch] = useState(false);
  const { Invoice } = queryString.parse(location.search);
  const [open, setOpen] = useState(false);
  const [TransInvoiceDialog, setTransInvoiceDialog] = useState(false);
  const { dateformat, timeformat } = useGymSetting();

  const title = location.state?.isNew
    ? 'Create New Invoice'
    : `Edit Invoice ”${data?.invoice_no || String(data?.id).padStart(7, '0')}”`;
  useChangeTitle(
    location.state?.isNew
      ? 'Create New Invoice | Jungle Alliance'
      : 'Edit Invoice | Jungle Alliance'
  );
  useEffect(() => {
    editInvoice(atob(Invoice))
      .then((res) => setData(getInvoiceData(res[0])))
      .catch(() => setErrFetch(true));
  }, [Invoice]);

  // const { timeformat } = useGymSettingReducer();
  // footer key updated by BE
  // eslint-disable-next-line no-unused-vars
  const footerSanitized = useMemo(() => {
    return DOMpurify.sanitize(Invoicefooter);
  }, [Invoicefooter]);

  if (Invoicefootererror || errorFetch || !Invoice) return <NotFoundView />;

  if (data && data?.status !== 1) {
    return (
      <Redirect
        to={{
          pathname: ROUTES.FINANCIAL_INVOICES_EDIT,
          search: `Invoice=${btoa(data.id)}`
        }}
      />
    );
  }

  if (!data) return <LoadingView />;

  const handleSave = () => {
    const value = {
      // user_id: data.get_user_name.id,
      auto_bill: data.auto_bill ? 1 : 0,
      due_date: moment(data.due_date).format('YYYY-MM-DD'),
      notes: data.notes,
      thanks_msg: data.thanks_msg
    };
    updateInvoice(value, data.id)
      .then(() => OpenNoti('Successfully Saved your Invoice', 'info'))
      .catch((err) => OpenNoti(getErrorMsg(err), 'error'));
  };

  return (
    <div className={classes.root}>
      <div className="title-bk">
        <Breadcrumbs aria-label="breadcrumb">
          <div
            color="inherit"
            onClick={() => setOpen(true)}
            style={{
              cursor: 'pointer'
            }}
          >
            financial
          </div>
          <Typography variant="h3" color="Primary">
            <span>invoices</span>
          </Typography>
        </Breadcrumbs>
      </div>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        spacing={0}
        style={{ margin: '20px 0 20px' }}
      >
        <Grid item xs={6}>
          <h1 style={{ margin: '0 0' }}>{title}</h1>
        </Grid>
        <Grid
          item
          xs={6}
          className={classes.btnBlock}
          style={{ justifyContent: 'flex-end', display: 'flex' }}
        >
          {data && (
            <Actions
              data={data}
              updateData={(val) => setData(getInvoiceData(val[0]))}
            />
          )}
          <Box ml="15px" />
          <CancelDialog />
        </Grid>
      </Grid>
      {data ? (
        <div>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={0}
            style={{ margin: '15px 0 15px' }}
          >
            <Grid item xs={12}>
              <Box className={classes.invoicehead}>
                <Box className={classes.invoiceheadBox}>
                  <span>Tax Invoice #</span>
                  <label>
                    {data.invoice_no || String(data.id).padStart(7, '0')}
                  </label>
                </Box>
                {/* <Box className={classes.invoiceheadBox}>
                  <span>Invoice Status:</span>
                  <label>{getInvoiceStatus(data.status)}</label>
                </Box> */}
                {data.status === 3 && (
                  <>
                    <Box className={classes.invoiceheadBox}>
                      <span>Member</span>
                      <label>
                        <Link
                          to={{
                            pathname: ROUTES.PEOPLE_MEMBER_PROFILE,
                            search: `memberId=${btoa(data.get_user_name.id)}`
                          }}
                          style={{ color: '#01847a', fontWeight: '600' }}
                        >
                          {data.get_user_name.name}
                        </Link>
                      </label>
                    </Box>
                    <Box className={classes.invoiceheadBox}>
                      <span>Location</span>
                      <label>{data.location_name}</label>
                    </Box>
                    <Box className={classes.invoiceheadBox}>
                      <span>Auto bill</span>
                      <label>{data.auto_bill === true ? 'Yes' : 'NO'}</label>
                    </Box>
                  </>
                )}
                <Box className={classes.invoiceheadBox}>
                  <span>Invoice Status</span>
                  <label
                    style={{
                      display: 'inline-block'
                    }}
                  >
                    {getInvoiceStatus(data.status)}
                  </label>
                  {data.status === 3 && (
                    <Button
                      onClick={() => setTransInvoiceDialog(true)}
                      className={classes.SearchTrans}
                    >
                      <SearchIcon />
                    </Button>
                  )}
                </Box>

                {data.status === 3 && (
                  <Box className={classes.invoiceheadBox}>
                    <span>Payment due</span>
                    {/* <label>{data.due_date}</label> */}
                    <label>
                      {moment(data.due_date).format(`${dateformat}`)}
                    </label>
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>

          <Card className={clsx(classes.mainCard, classes.InvoiceCard)}>
            {data.status !== 3 && (
              <Grid
                container
                direction="row"
                alignItems="center"
                spacing={0}
                style={{ margin: '0px 0 20px' }}
              >
                <Box
                  css={{
                    width: '300px'
                  }}
                  mr="15px"
                >
                  {location.state ? (
                    <InputLabel
                      htmlFor="age-required"
                      className={classes.cutom_select_Label}
                      style={{ margin: '0px 0px 5px', padding: '0 0' }}
                    >
                      Member
                    </InputLabel>
                  ) : (
                    <InputLabel
                      htmlFor="age-required"
                      className={classes.cutom_select_Label}
                      style={{ margin: '0px 0px 5px', padding: '0 0' }}
                      required
                    >
                      Select member
                    </InputLabel>
                  )}

                  {data.status === 1 ? (
                    <Link
                      to={{
                        pathname: ROUTES.PEOPLE_MEMBER_PROFILE,
                        search: `memberId=${btoa(data.get_user_name.id)}`
                      }}
                      style={{
                        color: '#01847a',
                        fontWeight: '600',
                        fontSize: '14px'
                      }}
                    >
                      {data.get_user_name.name}
                    </Link>
                  ) : (
                    <CustomAutoComplete
                      data={data.get_user_name}
                      disabled
                      value={[]}
                      Options="name"
                    />
                  )}
                </Box>
                <Box
                  mr="15px"
                  css={{
                    width: '300px'
                  }}
                >
                  {location.state ? (
                    <InputLabel
                      htmlFor="age-required"
                      className={classes.cutom_select_Label}
                      style={{ margin: '0px 0px 5px', padding: '0 0' }}
                    >
                      Payment due
                    </InputLabel>
                  ) : (
                    <InputLabel
                      htmlFor="age-required"
                      required
                      className={classes.cutom_select_Label}
                      style={{ margin: '0px 0px 5px', padding: '0 0' }}
                    >
                      Payment due date
                    </InputLabel>
                  )}

                  <JBDatePicker
                    holder="Select a Date"
                    value={data.due_date}
                    onChange={(val) => setData({ ...data, due_date: val })}
                    style={{
                      margin: '0 0 !important'
                    }}
                    className={classes.cutom_date_css}
                    disabled={data.status === 3}
                  />
                </Box>
                <Box
                  ml="5px"
                  mt="15px"
                  css={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex'
                  }}
                >
                  <FormControlLabel
                    control={
                      <IOSSwitch
                        checked={data.auto_bill}
                        onChange={() =>
                          setData({ ...data, auto_bill: !data.auto_bill })
                        }
                      />
                    }
                    label="Auto bill"
                    style={{ margin: '0 0' }}
                    disabled={data.status === 3}
                  />
                </Box>
              </Grid>
            )}
            <Grid container direction="row" alignItems="center" spacing={0}>
              <Grid
                item
                xs={12}
                style={{
                  marginBottom: data.status === 3 && '20px'
                }}
              >
                <InvoiceTable
                  values={data.get_line_item || []}
                  deleteItem={(val) => setData(getInvoiceData(val[0]))}
                  statuscode={data.status}
                />
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justify="flex-end"
              alignItems="center"
            >
              {data.status !== 3 && (
                <>
                  <Box mr="10px">
                    {location.state ? null : (
                      <p className={classes.ptext}>
                        Start be adding a line item
                      </p>
                    )}
                  </Box>
                  <JBButton
                    type="primary"
                    style={{ margin: '15px 0' }}
                    isImg={Plusicon}
                    onClick={() => setAddItemDialog(true)}
                    disabled={data.status !== 1}
                  >
                    Add line item
                  </JBButton>
                </>
              )}
            </Grid>
            <Grid
              container
              direction="row"
              // justify="flex-end"
              alignItems="center"
              className={classes.totalItem}
            >
              <Grid item xs={10}>
                <Typography variant="h6">
                  <span>Item Subtotal</span>
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="h6" align="center">
                  <GetPrice value={data.final_subtotal} />
                </Typography>
              </Grid>
              {data.invoice_discounted_amount > 0 && (
                <div
                  style={{
                    display: 'contents'
                  }}
                >
                  <Grid
                    item
                    xs={10}
                    onClick={() =>
                      setDisDiag({
                        data: {
                          discount_type:
                            data.discount_type === 'percentage' ? '2' : '1',
                          discount: data.invoice_discount
                        },
                        show: true
                      })
                    }
                  >
                    <Typography variant="h6">
                      <span>
                        {data.invoice_discount}{' '}
                        {data.discount_type === 'percentage' && '%'} Invoice
                        Discount
                      </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant="h6" align="center">
                      {/* <span>
                        -{getCurrency(data.invoice_discounted_amount)}
                      </span> */}
                      <GetPrice value={data.invoice_discounted_amount} />
                    </Typography>
                  </Grid>
                </div>
              )}
              <Grid
                container
                direction="row"
                alignItems="center"
                className={classes.totalDue}
              >
                <Grid item xs={10}>
                  <Typography variant="h6">
                    <span>Total Due (Including GST)</span>
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="h6" align="center">
                    {/* <span>{getCurrency(data.final_amount)}</span> */}
                    <GetPrice value={data.final_amount} />
                  </Typography>
                </Grid>
              </Grid>

              <Grid item xs={10}>
                <Typography variant="h6">
                  <span>Total Discount Applied</span>
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="h6" align="center">
                  {/* <span>-{getCurrency(data.final_discount)}</span> */}
                  <GetPrice value={data.final_discount} />
                </Typography>
              </Grid>
              <Grid item xs={10}>
                <Typography variant="h6">
                  <span>GST Paid</span>
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="h6" align="center">
                  {/* <span>{getCurrency(data.final_gst)}</span> */}
                  <GetPrice value={data.final_gst} />
                </Typography>
              </Grid>
            </Grid>

            <Grid
              container
              direction="row"
              alignItems="center"
              style={{
                margin: '15px 0'
              }}
            >
              <Grid item xs={6}>
                <TextareaControl
                  placeholder="Add note"
                  value={data.notes}
                  className={classes.TextareaControlNots}
                  onChange={(e) => setData({ ...data, notes: e.target.value })}
                />
                <Box mb="20px" />
                <TextareaControl
                  placeholder="Add ”Thank You” message"
                  value={data.thanks_msg}
                  className={classes.TextareaControlNots}
                  onChange={(e) =>
                    setData({ ...data, thanks_msg: e.target.value })
                  }
                />
              </Grid>
              <Grid
                item
                xs={6}
                justify="flex-end"
                className="MuiGrid-container"
              >
                <Box
                  css={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                    width: '150px',
                    textAlign: 'right'
                  }}
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data.invoice_footer || ''
                    }}
                    // dangerouslySetInnerHTML={{ __html: footerSanitized || '' }}
                  />
                </Box>
              </Grid>
            </Grid>

            <Grid container direction="row" alignItems="center">
              <Grid item xs={6}>
                <Box mt="25px">
                  <Box className={classes.invoicestatus}>
                    <span>Created:</span>
                    <label>{getCreatedOn(data, timeformat)}</label>
                  </Box>
                  <Box className={classes.invoicestatus}>
                    <span>Updated:</span>
                    <label>{getUpdatedOn(data, timeformat)}</label>
                  </Box>
                </Box>
              </Grid>
              <Grid
                item
                xs={6}
                justify="flex-end"
                alignItems="center"
                className="MuiGrid-container"
              >
                <JBButton type="primary" onClick={handleSave}>
                  Save
                </JBButton>
              </Grid>
            </Grid>

            {TransInvoiceDialog && (
              <TransactionDiag
                invoiceNumber={
                  data.invoice_no || String(data.id).padStart(7, '0')
                }
                setOpen={TransInvoiceDialog}
                handleClose={() => setTransInvoiceDialog(false)}
                id={data.id}
              />
            )}
            {AddItemDialog && (
              <AddItem
                open={AddItemDialog}
                addItemId={data.id}
                handleClose={() => setAddItemDialog(false)}
                addItem={(val) => setData(getInvoiceData(val[0]))}
              />
            )}
            {discountDiag.show && (
              <DiscountDiag
                setOpen={discountDiag.show}
                handleClose={() => setDisDiag({ data: null, show: false })}
                invoiceId={data.id}
                initials={discountDiag.data}
                updateData={(val) => setData(getInvoiceData(val[0]))}
              />
            )}
          </Card>
          <CancelDialogTwo setOpen={open} handleClose={() => setOpen(false)} />
        </div>
      ) : (
        <LoadingView />
      )}
    </div>
  );
};

InvoiceView.propTypes = {
  classes: PropType.object.isRequired,
  location: PropType.object.isRequired,

  OpenNoti: PropType.func.isRequired,
  GetInvoicefooter: PropType.object.isRequired
};

const mapToState = (state) => ({
  GetInvoicefooter: state.SettingReducer.invoice_footer
});

const mapToDispatch = (dispatch) => ({
  OpenNoti: (msg, type) => dispatch(openNoti(msg, type))
});
export default connect(
  mapToState,
  mapToDispatch
)(withStyles(styles)(InvoiceView));
