/* eslint-disable arrow-parens */
import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { AlliesProfile } from 'src/Context/AlliesContext';
import {
  Grid,
  withStyles,
  Typography,
  Box,
  Input,
  InputAdornment
} from '@material-ui/core';
// import VisaCardIcon from '../../../../../assets/image/visacard.png';
import clsx from 'clsx';
import CurrencyInputs from 'src/views/common/CurrencyControlForm/CurrencyInputs';
import { useDispatch, useSelector } from 'react-redux';
import { openNoti } from 'src/Redux/global/global.actions';
import PercentageFormControl from '@material-ui/core/FormControl';
import moment from 'moment';
import {
  getDiscounts,
  getTransactions,
  resetValues
} from 'src/Context/MasterHQAllies/masterhqallies.actions';
import {
  createAffilateDiscount,
  editAffiliateTransactionCharges
} from 'src/services/masterHQ.services';
import { backendDate } from 'src/utils/someCommon';
import { getErrorMsg } from 'src/utils/ErrorHandling';
import { validPrice, validateFloat } from 'src/utils/validations';
import useGymSettingReducer from 'src/views/common/CustomHooks/useGymSettingRefresh';
import { validateCharges } from './Pricing.utils';
import JBButton from '../../../../common/JBButton/JBButton';
import PricingTable from './PricingTable';
import DiscountTable from './DiscountTable';
import styles from './Pricing.style';
import ConfirmDialog from './ConfirmDialog';
import ApplyConfirmDialog from './ApplyConfirmDialog';
import ApplyDialog from './ApplyDialog';

const Pricing = ({ classes }) => {
  const [state, dispatch] = useContext(AlliesProfile);
  const { dateformat } = useGymSettingReducer();
  const {
    subscription_details: { plan_details, discounts, discount, transactions },
    userdetails
  } = state;

  const { currency_symbol } = useSelector(
    (reduxState) => reduxState.SettingReducer.currency.data
  );
  const [Percentage, setPercentage] = useState(
    transactions.data?.percentage_charge
  );
  const [fix, setFix] = useState(transactions.data?.fix_charge);
  const [open, setOpen] = useState(false);
  const reduxDispatch = useDispatch();
  const [Applyopen, setApplyOpen] = useState(false);
  const [confirmApply, setConfirmApply] = useState(false);
  const [isEditable, setisEditable] = useState(Boolean(false));
  const handleEdit = () => {
    setisEditable(true);
  };
  const addDiscount = () => {
    const values = {
      affiliate_id: userdetails.data.id,
      discount_type: discount.discount_type,
      discount_value: discount.discount_value,
      discount_amount: discount.discount_amount,
      discount_valid_type: discount.discount_valid_type.id,
      no_of_months: discount.no_of_month.name,
      end_date: backendDate(discount.end_date)
    };
    createAffilateDiscount(values)
      .then((res) => {
        let message = '';
        switch (discount.discount_valid_type.id) {
          case 1:
            message = ` for the next ${
              discount.no_of_month.name
            } months till ${moment(res.discount_end_at).format(dateformat)}`;
            break;
          case 2:
            message = ` till ${moment(res.discount_end_at).format(dateformat)}`;
            break;
          default:
            message = ' on all invoices for a lifetime';
        }
        dispatch(getDiscounts(res));
        reduxDispatch(
          openNoti(
            `The affiliate will get a discount of ${
              discount.discount_type === 'percentage'
                ? discount.discount_value + '%'
                : currency_symbol + discount.discount_amount
            }` + message
          )
        );
        dispatch(resetValues());
        setConfirmApply(false);
        setApplyOpen(false);
      })
      .catch((err) => {
        console.warn(err);
        reduxDispatch(openNoti(getErrorMsg(err), 'error'));
      });
  };
  const editCharges = () => {
    const values = {
      affiliate_id: userdetails.data.id,
      fix_charge: fix,
      percentage_charge: Percentage,
      status: 1
    };
    try {
      validateCharges(values);
      editAffiliateTransactionCharges(transactions.data.id, values)
        .then((res) => {
          dispatch(getTransactions(res));
          reduxDispatch(openNoti('Transaction charges updated'));
          setOpen(false);
        })
        .catch((err) => reduxDispatch(openNoti(getErrorMsg(err), 'error')));
    } catch (err) {
      reduxDispatch(openNoti(err.message, 'error'));
    }
  };

  return (
    <div>
      <Grid
        container
        direction="row"
        spacing={0}
        style={{ margin: '25px 0 0px' }}
      >
        <Grid
          item
          xs={12}
          style={{
            margin: '0 0 15px 0px'
          }}
        >
          <Box
            mt="0px"
            display="inline-block"
            css={{
              background: 'rgb(59 60 78)',
              padding: '10px',
              borderRadius: '4px'
            }}
          >
            <Typography
              color="#000"
              variant="h5"
              component="div"
              className={classes.ActiveUserInfo}
            >
              Real Time Active Users :
              <label className={classes.numActive}>
                {userdetails.data.real_time_active_user}
              </label>
            </Typography>
          </Box>
          <Box
            mt="0px"
            ml="15px"
            display="inline-block"
            css={{
              background: 'rgb(59 60 78)',
              padding: '10px',
              borderRadius: '4px'
            }}
          >
            <Typography
              color="#000"
              variant="h5"
              component="div"
              className={classes.ActiveUserInfo}
            >
              Overall Max Active Users :
              <label className={classes.numActive}>
                {userdetails.data.overall_max_active_user}
              </label>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box
            mt="0px"
            mb="20px"
            css={{
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <Typography
              color="#000"
              variant="h5"
              component="div"
              className={classes.bktitle}
            >
              Plan Details
            </Typography>
            {/* <JBButton type="primary" className={classes.smallBtn}>
              Sava
            </JBButton> */}
          </Box>
          <PricingTable
            values={plan_details.all_plans.data}
            plan_id={userdetails.data.plan_id}
            discount={discounts.data}
          />
        </Grid>

        <Grid item xs={12}>
          <Box
            mt="0px"
            mb="20px"
            css={{
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <Typography
              color="#000"
              variant="h5"
              component="div"
              className={classes.bktitle}
            >
              Discount
            </Typography>
            <Box>
              <JBButton
                type="primary"
                className={classes.smallBtn}
                disabled={discounts.data}
                onClick={() => setApplyOpen(true)}
              >
                Apply Discount
              </JBButton>
              {/* <JBButton
                type="outlined"
                className={classes.smallBtn}
                style={{
                  marginLeft: '10px'
                }}
              >
                Cancel Discount
              </JBButton> */}
            </Box>
          </Box>
          <Grid>
            <DiscountTable
              affiliate_id={userdetails.data.id}
              values={discounts.data}
            />
          </Grid>
          {/* <Box>
            <Box
              css={{
                display: 'inline-flex',
                justifyItems: 'center',
                flexDirection: 'row',
                alignItems: 'center'
              }}
            >
              <Box>
                <Typography
                  color="#000"
                  variant="h6"
                  component="label"
                  // className={classes.bktitle}
                  style={{
                    fontSize: '16px',
                    marginRight: '15px',
                    display: 'block'
                  }}
                >
                  No discount applied
                </Typography>
                <Typography
                  color="#000"
                  variant="h6"
                  component="label"
                  // className={classes.bktitle}
                  style={{
                    fontSize: '16px',
                    marginRight: '15px',
                    display: 'block'
                  }}
                >
                  The affiliate will get a 50% discount for the next
                  <span
                    style={{
                      color: '#01847A',
                      marginLeft: '5px',
                      fontWeight: '700'
                    }}
                  >
                    3 months till December-21.
                  </span>
                </Typography>
                <Typography
                  color="#000"
                  variant="h6"
                  component="label"
                  // className={classes.bktitle}
                  style={{
                    fontSize: '16px',
                    marginRight: '15px',
                    display: 'block'
                  }}
                >
                  The affiliate will get a 50% discount till
                  <span
                    style={{
                      color: '#01847A',
                      marginLeft: '5px',
                      fontWeight: '700'
                    }}
                  >
                    31st December 2021.
                  </span>
                </Typography>

                <Typography
                  color="#000"
                  variant="h6"
                  component="label"
                  // className={classes.bktitle}
                  style={{
                    fontSize: '16px',
                    marginRight: '15px'
                  }}
                >
                  The affiliate will get a 50% discount on
                  <span
                    style={{
                      color: '#01847A',
                      marginLeft: '5px',
                      fontWeight: '700'
                    }}
                  >
                    all the invoices for a lifetime.
                  </span>
                </Typography>
              </Box>

              <JBButton type="primary" className={classes.smallBtn}>
                Apply Discount
              </JBButton>
              <JBButton
                type="outlined"
                className={classes.smallBtn}
                style={{
                  marginLeft: '10px'
                }}
              >
                Cancel Discount
              </JBButton>
            </Box>
            <Box
              css={{
                display: 'flow-root',
                alignItems: 'center'
              }}
              className={classes.TranCharge}
            >
              <Typography
                color="#000"
                variant="h6"
                component="label"
                style={{
                  fontSize: '16px',
                  marginRight: '15px'
                }}
              >
                Apply Discount
              </Typography>
              <Box
                css={{
                  display: 'inline-flex',
                  backgroundColor: '#eaeaea',
                  padding: '20px 20px',
                  borderRadius: '8px',
                  width: 'auto',
                  alignItems: 'center'
                }}
                className="DiscountBlock"
              >
                <Box className="RadioGroupBk">
                  <RadioGroup
                    style={{
                      flexDirection: 'row',
                      justifyContent: 'left',
                      flexWrap: 'nowrap'
                    }}
                    onChange={handleChange}
                    value={Elder}
                  >
                    <FormControlLabel
                      value="Percentage"
                      label="Percentage"
                      control={<Radio color="primary" />}
                      style={{ margin: '0 0' }}
                    />
                    <FormControlLabel
                      value="Fixed"
                      label="Fixed"
                      control={<Radio color="primary" />}
                      style={{ margin: '0px 0px 0px 5px' }}
                    />
                  </RadioGroup>
                </Box>

                {Elder === 'Fixed' ? (
                  <CurrencyInputs
                    // placeholder={currencies.currency_symbol}
                    // value={week_setup_fees}
                    placeholder=""
                    maxLength={10}
                    currencySymbol="A$"
                    style={{
                      margin: '0 0',
                      width: '80px',
                      maxWidth: '80px'
                    }}
                  />
                ) : (
                  <PercentageFormControl
                    className={clsx(
                      classes.PercentageFormControl,
                      ElderPerce <= 100 ? null : 'MuiInput-underline Mui-error'
                    )}
                    style={{
                      width: 'auto'
                    }}
                  >
                    <Input
                      id="standard-adornment-weight"
                      value={ElderPerce}
                      onChange={(e) => {
                        setElderPerce(e.target.value);
                      }}
                      endAdornment={
                        <InputAdornment
                          position="end"
                          style={{
                            color: '#292929',
                            margin: '-1px 0 0 0px'
                          }}
                        >
                          <span>%</span>
                        </InputAdornment>
                      }
                      aria-describedby="standard-weight-helper-text"
                      inputProps={{
                        'aria-label': 'weight'
                      }}
                      style={{
                        color: '#292929'
                      }}
                    />
                  </PercentageFormControl>
                )}
              </Box>

              <Typography
                color="#000"
                variant="h6"
                component="label"
                style={{
                  fontSize: '16px',
                  margin: '10px 0 5px'
                }}
              >
                Discount Valid Till
              </Typography>
              <FollowupFilters />
              <>
                <JBButton
                  type="primary"
                  className={classes.smallBtn}
                  style={{
                    margin: '0 0 0 0px'
                  }}
                  onClick={() => setApplyOpen(true)}
                >
                  Apply
                </JBButton>
                <JBButton
                  type="outlined"
                  className={classes.smallBtn}
                  style={{
                    margin: '0 0 0 15px'
                  }}
                  onClick={() => {
                    setisEditable(false);
                  }}
                >
                  Cancel
                </JBButton>
              </>
            </Box>
          </Box> */}
        </Grid>

        <Grid item xs={12} className={classes.TranCharge}>
          <Box mt="15px" mb="20px">
            <Typography
              color="#000"
              variant="h5"
              component="div"
              className={classes.bktitle}
            >
              Transaction Charges
            </Typography>
          </Box>
          <Box
            css={{
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <Box
              css={{
                display: 'inline-flex',
                backgroundColor: 'rgb(59 60 78)',
                padding: '20px 20px',
                borderRadius: '8px'
              }}
            >
              <div className={classes.currencyBox}>
                <label>Fix</label>
                <CurrencyInputs
                  placeholder=""
                  value={fix}
                  maxLength={10}
                  onChange={(e) =>
                    setFix(validPrice(validateFloat(e.target.value)))
                  }
                  currencySymbol="A$"
                  style={{
                    margin: '0 0',
                    width: '80px',
                    maxWidth: '80px',
                    backgroundColor: !isEditable
                      ? 'rgb(127 127 127 / 52%)'
                      : '#fff',
                    border: !isEditable ? '0' : null
                  }}
                  disabled={!isEditable}
                />
              </div>
              <span className={classes.plusIcon}>+</span>
              <div>
                <label>Percentage</label>

                <PercentageFormControl
                  className={clsx(
                    classes.PercentageFormControl,
                    Percentage <= 100 ? null : 'MuiInput-underline Mui-error'
                  )}
                  style={{
                    display: 'block',
                    backgroundColor: !isEditable
                      ? 'rgb(127 127 127 / 52%)'
                      : '#fff',
                    border: !isEditable ? '0' : null
                  }}
                  disabled={!isEditable}
                >
                  <Input
                    id="standard-adornment-weight"
                    value={Percentage}
                    onChange={(e) => {
                      setPercentage(validPrice(validateFloat(e.target.value)));
                    }}
                    endAdornment={
                      <InputAdornment
                        position="end"
                        style={{
                          color: '#292929',
                          margin: '-1px 0 0 0px'
                        }}
                      >
                        <span>%</span>
                      </InputAdornment>
                    }
                    aria-describedby="standard-weight-helper-text"
                    inputProps={{
                      'aria-label': 'weight'
                    }}
                    style={{
                      color: '#292929'
                    }}
                  />
                </PercentageFormControl>
              </div>
            </Box>
            {isEditable ? (
              <>
                <JBButton
                  type="primary"
                  className={classes.smallBtn}
                  style={{
                    margin: '0 0 0 15px'
                  }}
                  onClick={() => setOpen(true)}
                >
                  Save
                </JBButton>
                <JBButton
                  type="outlined"
                  className={classes.smallBtn}
                  style={{
                    margin: '0 0 0 15px'
                  }}
                  onClick={() => {
                    setisEditable(false);
                    setFix(transactions.data.fix_charge);
                    setPercentage(transactions.data.percentage_charge);
                  }}
                >
                  Cancel
                </JBButton>
              </>
            ) : (
              <JBButton
                type="primary"
                className={classes.smallBtn}
                style={{
                  margin: '0 0 0 15px'
                }}
                onClick={handleEdit}
              >
                Edit
              </JBButton>
            )}
          </Box>
        </Grid>
      </Grid>
      {/* <Box mb="40px" /> */}
      {/* <Grid container direction="row" justify="flex-end" alignItems="center">
        <Box ml="10px">
          <CancelDialog />
        </Box>
        <Box ml="10px">
          <JBButton
            type="primary"
            // component={Link}
          >
            Save
          </JBButton>
        </Box>
      </Grid> */}
      <ConfirmDialog
        setOpen={open}
        editCharges={editCharges}
        handleClose={() => setOpen(false)}
      />
      <ApplyConfirmDialog
        setOpen={confirmApply}
        addDiscount={addDiscount}
        handleClose={() => {
          setConfirmApply(false);
        }}
      />
      <ApplyDialog
        setOpen={Applyopen}
        confirmApply={() => setConfirmApply(true)}
        handleClose={() => setApplyOpen(false)}
      />
    </div>
  );
};

Pricing.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(Pricing);
