/* eslint-disable arrow-parens */
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { AppBar, Breadcrumbs, Button, Grid, Tab, Tabs, Typography } from '@material-ui/core';
import { Link, Route, Switch, withRouter } from 'react-router-dom';
import { get } from 'js-cookie';
// eslint-disable-next-line import/no-cycle
import InActiveProgram from './programStatus/InActiveProgram';
import Plusicon from '../../assets/image/plusicon.png';
import styles from './programStatus/status.style';
import { ROUTES } from '../../constant/Constants';
import ActiveProgram from './programStatus/activeProgram';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}
class ProgramStatus extends React.PureComponent {
  render() {
    const { classes, location } = this.props;
    const Tabslocation = location.pathname === ROUTES.CLASSES_PROGRAMS_INACTIVE ? 1 : 0;
    const roleid = JSON.parse(get('jbrole') || '{}')?.role_id || 3;

    return (
      <div className={classes.root}>
        <div className="title-bk">
          <div className={classes.new_programs} style={{ marginBottom: 15 }}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={0}
            >
              <Grid item xs={6}>
                {/* <h3 style={{ marginBottom: 0 }}>
                  Classes <label>/</label>
                  <span>Programs</span>
                </h3> */}
                <Breadcrumbs aria-label="breadcrumb">
                  <Link color="inherit" to={ROUTES.CLASSES_PROGRAMS}>
                    Classes
                  </Link>
                  <Typography variant="h3" color="Primary">
                    <span>Programs</span>
                  </Typography>
                </Breadcrumbs>
              </Grid>
              <Grid item xs={6} alignItems="center">
                {roleid <= 2 && (
                  <Button
                    color="primary"
                    className={classes.button}
                    component={Link}
                    to={ROUTES.CLASSES_PROGRAMS_CREATE_NEW}
                  >
                    <img
                      alt="Plusicon"
                      src={Plusicon}
                      style={{ marginRight: 10 }}
                    />
                    <span>new program</span>
                  </Button>
                )}
              </Grid>
            </Grid>
          </div>
        </div>
        {/* <Tabulation
          label1="Active"
          label2="InActive"
          value={value}
          Change={(val) => this.setState({ value: val })}
        >
          {value === 0 ? <ActiveProgram /> : <InActiveProgram />}
        </Tabulation> */}
        <div className={classes.mainCard}>
          <AppBar
            position="static"
            style={{
              backgroundColor: 'transparent',
              boxShadow: 'none'
            }}
          >
            <Tabs
              value={Tabslocation}
              indicatorColor="primary"
              textColor="primary"
              color="primary"
              fullWidth
            >
              <Tab
                label="Active"
                component={Link}
                to={ROUTES.CLASSES_PROGRAMS}
                className={classes.p_tabs}
                {...a11yProps(0)} // eslint-disable-line
              />
              <Tab
                label="InActive"
                component={Link}
                to={ROUTES.CLASSES_PROGRAMS_INACTIVE}
                className={classes.p_tabs}
                {...a11yProps(1)} // eslint-disable-line
              />
            </Tabs>
            <Switch>
              <Route
                exact
                path={ROUTES.CLASSES_PROGRAMS_INACTIVE}
                component={InActiveProgram}
              />
              <Route path={ROUTES.CLASSES_PROGRAMS} component={ActiveProgram} />
            </Switch>
          </AppBar>
        </div>
      </div>
    );
  }
}

ProgramStatus.defaultProps = {
  programListObject: {}
};

ProgramStatus.propTypes = {
  classes: PropTypes.object.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  programListObject: PropTypes.any,
  location: PropTypes.any.isRequired
};

export default withRouter(
  withStyles(styles, { withTheme: true })(ProgramStatus)
);
