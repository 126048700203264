import React, { useState, useRef, useEffect } from 'react';
import PropType from 'prop-types';
import {
  Button,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  makeStyles,
  ClickAwayListener
} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import { retrivePaymentMethod } from 'src/services/masterHQ.invoices.services';
import ExpandMore from '@material-ui/icons/ExpandMore';
import VoidInvoiceDialog from './VoidInvoiceDialog';
import PayInvoiceDialog from './PayInvoiceDialog';
import DiscountDiag from './DiscountDiag';
import RefundInvoice from './RefundInvoice';
import EmailInvoice from './EmailInvoice';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  paper: {
    marginRight: theme.spacing(2)
  },
  rootPopper: {
    '& .MuiPaper-root': {
      backgroundColor: '#3b3c4e'
    },
    '& .MuiMenuItem-root': {
      color: '#ffffff'
    }
  }
}));

const Actions = ({ data, updateData }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [voidInvoice, setVoidInvoice] = useState(false);
  const [payInvoice, setPayinvoice] = useState(false);
  const [discount, setDiscount] = useState(false);
  const [refund, setRefund] = useState(false);
  const [emailInvoice, setEmailInvoice] = useState(false);
  const [paymentMeth, setpayMeth] = useState(null);
  const anchorRef = useRef(null);

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }
  useEffect(() => {
    retrivePaymentMethod(data.affiliate_id)
      .then((res) => {
        setpayMeth(res);
      })
      .catch(() => setpayMeth([]));
  }, []); // eslint-disable-line
  return (
    <div className={classes.root}>
      <div>
        <Button
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={() => setOpen(!open)}
          color="primary"
          variant="outlined"
          style={{ height: '48px', fontWeight: '700' }}
        >
          Actions
          {open ? <ExpandLess /> : <ExpandMore />}
        </Button>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          style={{ zIndex: '99' }}
          className={classes.rootPopper}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps} // eslint-disable-line
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
                zIndex: '99'
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={() => setOpen(false)}>
                  <MenuList
                    autoFocusItem={open}
                    id="menu-list-grow"
                    // eslint-disable-next-line react/jsx-no-bind
                    onKeyDown={handleListKeyDown}
                  >
                    {data.status === 1 && (
                      <>
                        <MenuItem onClick={() => setPayinvoice(true)}>
                          Pay invoice
                        </MenuItem>
                        <MenuItem onClick={() => setDiscount(true)}>
                          {data.is_discount_applied
                            ? 'Edit Discount'
                            : 'Apply a Discount'}
                        </MenuItem>
                        <MenuItem onClick={() => setVoidInvoice(true)}>
                          Void Invoice
                        </MenuItem>
                      </>
                    )}
                    {data.status === 2 && (
                      <MenuItem onClick={() => setRefund(true)}>
                        Refund Invoice
                      </MenuItem>
                    )}
                    <MenuItem onClick={() => setEmailInvoice(true)}>
                      Email Invoice
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        <VoidInvoiceDialog
          setOpen={voidInvoice}
          handleClose={() => setVoidInvoice(false)}
          invoiceId={data.id}
          updateData={(val) => updateData(val)}
        />
        <PayInvoiceDialog
          data={data}
          setOpen={payInvoice}
          handleClose={() => setPayinvoice(false)}
          paymentMeth={paymentMeth}
          updateData={(val) => updateData(val)}
        />
        <DiscountDiag
          setOpen={discount}
          userData={data}
          handleClose={() => setDiscount(false)}
          updateData={(val) => updateData(val)}
        />
        <RefundInvoice
          setOpen={refund}
          id={data.id}
          amount={data.final_amount}
          handleClose={() => setRefund(false)}
          paymentMeth={paymentMeth}
          updateData={(val) => updateData(val)}
        />
        <EmailInvoice
          setOpen={emailInvoice}
          invoicedatastatus={data.status}
          invoicenum={data.id}
          handleClose={() => setEmailInvoice(false)}
          email={data.email}
        />
      </div>
    </div>
  );
};

Actions.propTypes = {
  data: PropType.object.isRequired,
  updateData: PropType.func.isRequired
};

export default Actions;
