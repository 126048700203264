import {
  MASTERHQ_REDUCER,
  MASTERHQ_FINANCIAL_REDUCER
} from '../masterhq.constants';

// eslint-disable-next-line import/prefer-default-export
export const getInvoiceState = (state) => {
  return state()[MASTERHQ_REDUCER][MASTERHQ_FINANCIAL_REDUCER]
    .masterInvoiceFilter;
};
