// import { initialValue as attendInitials } from 'src/Context/AttendanceSummary/attendanceReducer';
import types from './reports.action.type';
import { DataObj, FilterObj } from '../../reducer.default.data';
import getReports from './reports.reducer.utils';
import filterList from '../../Reducers.utils';
// import { getAttendancefilterAdded, getAddedTags } from './reports.common.utils';

const initialValue = {
  financialReports: {
    allInvoice: DataObj,
    expireCards: DataObj,
    overdueAccounts: DataObj,
    revenuePerMonth: DataObj,
    allInvoiceFil: FilterObj({}),
    revenuePerMonthFil: FilterObj({}),
    expirecardsFil: FilterObj({}),
    overdueAccountsFil: FilterObj({ user_status: 0 })
  },
  reportList: DataObj
};

export default (state = initialValue, action) => {
  switch (action.type) {
    case types.allinvoicereports:
      return {
        ...state,
        financialReports: {
          ...state.financialReports,
          allInvoice: getReports(
            types.allinvoicereports,
            action.actionType,
            state.financialReports.allInvoice,
            action.payload
          )
        }
      };
    case types.revenuePerMonth:
      return {
        ...state,
        financialReports: {
          ...state.financialReports,
          revenuePerMonth: getReports(
            types.revenuePerMonth,
            action.actionType,
            state.financialReports.revenuePerMonth,
            action.payload
          )
        }
      };

    case types.expirecreditcards:
      return {
        ...state,
        financialReports: {
          ...state.financialReports,
          expireCards: getReports(
            types.expireMembership,
            action.actionType,
            state.financialReports.expireCards,
            action.payload
          )
        }
      };

    case types.expirecreditcardsFil:
      return {
        ...state,
        financialReports: {
          ...state.financialReports,
          expirecardsFil: filterList(
            state.financialReports.expirecardsFil,
            action.filter,
            action.payload
          )
        }
      };

    case types.revenuePerMonthFil:
      return {
        ...state,
        financialReports: {
          ...state.financialReports,
          revenuePerMonthFil: filterList(
            state.financialReports.revenuePerMonthFil,
            action.filter,
            action.payload
          )
        }
      };

    case types.allinvoicereportsFilter:
      return {
        ...state,
        financialReports: {
          ...state.financialReports,
          allInvoiceFil: filterList(
            state.financialReports.allInvoiceFil,
            action.filter,
            action.payload
          )
        }
      };

    case types.overdueaccount:
      return {
        ...state,
        financialReports: {
          ...state.financialReports,
          overdueAccounts: getReports(
            types.overdueaccount,
            action.actionType,
            state.financialReports.overdueAccounts,
            action.payload
          )
        }
      };

    case types.overdueaccountFilter:
      return {
        ...state,
        financialReports: {
          ...state.financialReports,
          overdueAccountsFil: filterList(
            state.financialReports.overdueAccountsFil,
            action.filter,
            action.payload
          )
        }
      };

    case types.getreportList:
      return {
        ...state,
        reportList: getReports(
          types.getreportList,
          action.actionType,
          state.reportList,
          action.payload
        )
      };
    default:
      return state;
  }
};
