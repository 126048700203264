import React, { useState, useEffect, useMemo } from 'react';
import PropType from 'prop-types';
import { ROUTES } from 'src/constant/Constants';
import { Link, useLocation, useHistory } from 'react-router-dom';
import clsx from 'clsx';
import DOMPurify from 'dompurify';
import {
  Button,
  Typography,
  AppBar,
  Toolbar,
  IconButton,
  withStyles,
  Card,
  Grid,
  Box
} from '@material-ui/core';
import { getWODDetailByCls } from 'src/services/WODmodule.services';
import useChangeTitle from 'src/views/common/CustomHooks/useTitleChange';
import { ReactComponent as BackArrow } from '../../../assets/image/Vanguard/icon_back_vanguard.svg';
import LeavePagePopup from '../LeavepagePopup';
import styles from './chalkboardIndex.style';
import JBVanguardButton from '../../common/JBVanguardButton/JBButton';

const ChalkboardWorkout = ({ classes }) => {
  const history = useHistory();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [LeavePageopen, setLeavePageopen] = useState(false);
  const location = useLocation();
  useChangeTitle('Chalkboard | Jungle Alliance');
  useEffect(() => {
    if (location.state) {
      getWODDetailByCls(location.state.classId || 1)
        .then((response) => {
          setData(response);
        })
        .catch((err) => setError(err.response.data.message));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const PurifiedWorkout = useMemo(() => {
    if (data) {
      return DOMPurify.sanitize(data.workout_details);
    }
    return null;
  }, [data]);

  // if (!location.state) {
  //   history.push({ pathname: ROUTES.VANGUARD_CHALKBOARD });
  // }

  return (
    <div>
      <AppBar className={classes.appBar}>
        <Toolbar className={classes.appToolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="close"
            onClick={() => {
              // setLeavePageopen(true);
              history.goBack();
            }}
          >
            <BackArrow />
          </IconButton>
          <h5 className={classes.headerTitle}>Chalkboard</h5>
          <Button
            color="inherit"
            className={classes.HomeButton}
            component={Link}
            to={ROUTES.VANGUARD}
          >
            Home
          </Button>
        </Toolbar>
      </AppBar>

      <Card className={classes.MainCard}>
        <Grid
          container
          xs={10}
          justify="center"
          style={{
            margin: '50px auto'
          }}
        >
          <Box
            css={{
              textAlign: 'center'
            }}
          >
            <h4 className={classes.mainTitle}>
              {location?.state?.program_name}
            </h4>
            <h4 className={classes.mainTitle}>{location?.state?.dateInfo}</h4>
          </Box>
        </Grid>
        <Box className={classes.mainContent}>
          <Grid
            container
            xs={10}
            style={{
              margin: '50px auto'
            }}
          >
            <Grid item xs>
              {error ? (
                <Typography className={classes.wodDetails}>
                  No Workout to Show
                </Typography>
              ) : (
                <>
                  <h4 className={classes.WodName}>{data?.name}</h4>
                  <Box
                    className={clsx(
                      classes.wodDetails,
                      'cke_contents_ltr cke_editable cke_editable_themed cke_show_borders'
                    )}
                    dangerouslySetInnerHTML={{ __html: PurifiedWorkout }}
                  />
                </>
              )}
            </Grid>
          </Grid>
        </Box>
        <Box className={classes.actionBt}>
          <Grid
            container
            xs={10}
            justify="flex-end"
            style={{
              margin: '0px auto'
            }}
          >
            <JBVanguardButton
              type="outlined"
              disabled={location?.state?.sign_in === 0}
              style={{
                width: '240px',
                minWidth: '240px',
                height: '70px'
              }}
              component={Link}
              to={{
                pathname: ROUTES.VANGUARD_CHALKBOARD_WORKOUT_SIGN_IN_MEMBER,
                state: {
                  classId: location?.state?.classId,
                  program_name: location?.state?.program_name,
                  dateInfo: location?.state?.dateInfo
                }
              }}
            >
              Sign In
            </JBVanguardButton>
          </Grid>
        </Box>
      </Card>
      <LeavePagePopup
        setOpen={LeavePageopen}
        handleClose={() => setLeavePageopen(false)}
        // handleHistory={ROUTES.VANGUARD}
      />
    </div>
  );
};

ChalkboardWorkout.propTypes = {
  classes: PropType.object.isRequired
};
export default withStyles(styles)(ChalkboardWorkout);
