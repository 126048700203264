const styles = () => ({
  root: {
    marginTop: 0,
    margin: 20,
    padding: '0px 20px 20px'
  },
  mainCard: {
    boxShadow: '4px 0px 16px rgba(37, 40, 43, 0.08)',
    borderRadius: '5px',
    background: '#FFFF',
    padding: 30
  },
  button: {
    background: '#01847A',
    color: '#FFF',
    padding: '12px 24px',
    marginLeft: '15px',
    boxShadow: '0px 8px 16px rgba(0, 126, 255, 0.16);',
    '&:hover': {
      backgroundColor: '#01847A'
    }
  },
  Button: {
    height: '48px'
  },
  Deletebutton: {
    color: '#FB4E4E',
    border: '1px solid',
    height: '48px',
    marginRight: '15px'
  },
  btnBlock: {
    justifyContent: 'flex-end',
    display: 'flex'
  },
  title: {
    color: '#292929'
  },
  ArrowLeft: {
    borderRadius: '50%',
    padding: '0 0',
    minWidth: 'auto',
    marginRight: '15px'
  },
  Dinfo: {
    marginTop: '15px',
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '18px',
    '& span': {
      color: '#949494'
    },
    '& label': {
      color: '#000000'
    }
  },
  DTags: {
    marginTop: '20px',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px',
    color: '#292929',
    '& label': {
      background: '#EAEAEA',
      borderRadius: '10px',
      padding: '8px 16px',
      display: 'block'
    }
  },
  p_tabs: {
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px'
  },
  table: {
    fontSize: '14px',
    lineHeight: '22px',
    border: '1px solid #A8A8A8',
    borderRadius: '4px',
    marginTop: '20px'
  },
  table_th: {
    backgroundColor: '#F9F9FA',
    color: '#292929'
  },
  TableCell: {
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px',
    borderBottom: '1px solid #A8A8A8',
    height: '56px',
    minHeight: '56px'
  },
  Table_td: {
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '22px',
    position: 'relative',
    padding: '12px 15px 13px 20px',
    height: '56px',
    minHeight: '56px'
  },
  Table_Body: {},
  Deletebtn: {
    background: '#FB4E4E',
    boxShadow: '0px 8px 16px rgba(251, 78, 78, 0.16)',
    borderRadius: '2px',
    height: '48px',
    padding: '10px 15px',
    color: '#fff',
    fontWeight: 'bold',
    '&:hover': {
      background: '#FB4E4E'
    }
  },
  cutom_select_Label: {
    fontSize: '12px',
    padding: '7px 0'
  }
});
export default styles;
