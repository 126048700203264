import filterList from 'src/Redux/Reducers.utils';
import types from './MemProfile.types';
import updateData from './MemProfile.reducer.utils';
import { getUser } from './MemProfileReducer.actions';

export const initialValue = {
  userdata: { isFetching: true, data: null, error: null },
  unpaidInvoice: { isFetching: true, data: null, error: null },
  paidInvoice: { isFetching: true, data: null, error: null },
  allInvoice: { isFetching: true, data: null, error: null },
  processingInvoice: { isFetching: true, data: null, error: null },
  failedInvoice: { isFetching: true, data: null, error: null },
  overdueInvoice: { isFetching: true, data: null, error: null },
  MembershipHolds: { isFetching: true, data: null, error: null },
  Actmemberships: { isFetching: true, data: null, error: null },
  InActmemberships: { isFetching: true, data: null, error: null },
  paymentMethods: { isFetching: true, data: null, error: null },
  attendance: { isFetching: false, data: null, error: null },

  // Activity History tabs
  activityHistory: { isFetching: true, data: null, error: null },

  MemholdFil: {
    sorting: {},
    default: {}
  },
  ActmembershipFil: {
    sorting: {},
    default: {
      filter_by_status: 1
    }
  },
  InActmembershipFil: {
    sorting: {},
    default: {
      filter_by_status: 2
    }
  },
  attendFilter: {
    page: 1,
    search: '',
    customFilter: {},
    tags: [],
    sorting: {},
    default: {}
  },
  unpaidFilter: {
    page: 1,
    sorting: {},
    default: {
      tab: 1
    }
  },
  paidFilter: {
    page: 1,
    sorting: {},
    default: {
      tab: 2
    }
  },
  allFilter: {
    page: 1,
    sorting: {},
    default: {
      tab: 0
    }
  },
  processingFilter: {
    page: 1,
    sorting: {},
    default: {
      tab: 4
    }
  },
  failedFilter: {
    page: 1,
    sorting: {},
    default: {
      tab: 5
    }
  },
  overdueFilter: {
    page: 1,
    sorting: {},
    default: {
      tab: 3
    }
  },
  // Activity History tabs
  activityHistoryFilter: {
    page: 1,
    sorting: {},
    default: {}
  },
  activetab: 0
};

export const MemberProfileReducer = (state = initialValue, action) => {
  switch (action.type) {
    case types.fetchdata:
      return {
        ...state,
        userdata: getUser(state.userdata, action)
      };
    case types.changeTab:
      return {
        ...state,
        activetab: action.payload
      };
    case types.updateunpaidFil:
      return {
        ...state,
        unpaidFilter: filterList(
          state.unpaidFilter,
          action.filter,
          action.payload
        )
      };
    case types.updatepaidFil:
      return {
        ...state,
        paidFilter: filterList(state.paidFilter, action.filter, action.payload)
      };
    case types.updateallFil:
      return {
        ...state,
        allFilter: filterList(state.allFilter, action.filter, action.payload)
      };
    case types.updateProcessingFil:
      return {
        ...state,
        processingFilter: filterList(
          state.processingFilter,
          action.filter,
          action.payload
        )
      };
    case types.updateFailedFil:
      return {
        ...state,
        failedFilter: filterList(
          state.failedFilter,
          action.filter,
          action.payload
        )
      };
    case types.updateOverdueFil:
      return {
        ...state,
        overdueFilter: filterList(
          state.overdueFilter,
          action.filter,
          action.payload
        )
      };
    case types.updateActiveMemFil:
      return {
        ...state,
        ActmembershipFil: filterList(
          state.ActmembershipFil,
          action.filter,
          action.payload
        )
      };
    case types.updateInActMemFil:
      return {
        ...state,
        InActmembershipFil: filterList(
          state.InActmembershipFil,
          action.filter,
          action.payload
        )
      };
    case types.updateMemHoldFil:
      return {
        ...state,
        MemholdFil: filterList(state.MemholdFil, action.filter, action.payload)
      };

    case types.ActivityHistoryFilter:
      return {
        ...state,
        activityHistoryFilter: filterList(
          state.activityHistoryFilter,
          action.filter,
          action.payload
        )
      };

    case types.attendanceFilter:
      return {
        ...state,
        attendFilter: filterList(
          state.attendFilter,
          action.filter,
          action.payload
        )
      };

    case types.getMemHold:
      return {
        ...state,
        MembershipHolds: updateData(
          types.activeMem,
          action.actionType,
          state.MembershipHolds,
          action.payload
        )
      };
    case types.activeMem:
      return {
        ...state,
        Actmemberships: updateData(
          types.activeMem,
          action.actionType,
          state.Actmemberships,
          action.payload
        )
      };
    case types.inactiveMem:
      return {
        ...state,
        InActmemberships: updateData(
          types.activeMem,
          action.actionType,
          state.InActmemberships,
          action.payload
        )
      };
    case types.unpaidInvoice:
      return {
        ...state,
        unpaidInvoice: updateData(
          types.unpaidInvoice,
          action.actionType,
          state.unpaidInvoice,
          action.payload
        )
      };
    case types.paidInvoice:
      return {
        ...state,
        paidInvoice: updateData(
          types.paidInvoice,
          action.actionType,
          state.paidInvoice,
          action.payload
        )
      };
    case types.allInvoice:
      return {
        ...state,
        allInvoice: updateData(
          types.allInvoice,
          action.actionType,
          state.allInvoice,
          action.payload
        )
      };
    case types.overdueInvoice:
      return {
        ...state,
        overdueInvoice: updateData(
          types.overdueInvoice,
          action.actionType,
          state.overdueInvoice,
          action.payload
        )
      };
    case types.processingInvoice:
      return {
        ...state,
        processingInvoice: updateData(
          types.processingInvoice,
          action.actionType,
          state.processingInvoice,
          action.payload
        )
      };
    case types.failedInvoice:
      return {
        ...state,
        failedInvoice: updateData(
          types.failedInvoice,
          action.actionType,
          state.failedInvoice,
          action.payload
        )
      };
    case types.getpaymentmethods:
      return {
        ...state,
        paymentMethods: updateData(
          types.getpaymentmethods,
          action.actionType,
          state.paymentMethods,
          action.payload
        )
      };
    case types.getActivityHistory:
      return {
        ...state,
        activityHistory: updateData(
          types.getActivityHistory,
          action.actionType,
          state.activityHistory,
          action.payload
        )
      };
    case types.getattendance:
      return {
        ...state,
        attendance: updateData(
          types.getattendance,
          action.actionType,
          state.attendance,
          action.payload
        )
      };
    case types.ProfileChange:
      return {
        ...state,
        userdata: {
          ...state.userdata,
          data: {
            ...state.userdata.data,
            profile_pic: action.payload
          }
        }
      };
    case types.updateStatus:
      return {
        ...state,
        userdata: {
          ...state.userdata,
          data: {
            ...state.userdata.data,
            status: action.payload
          }
        }
      };
    default:
      return state;
  }
};
