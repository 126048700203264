/* eslint-disable operator-linebreak */
import moment from 'moment';

export function getLogindata(data) {
  return {
    user_id: data.selectedMember?.id,
    class_id: data.class?.id,
    login_date: moment(data.date).format('YYYY-MM-DD'),
    allow_login: 1
  };
}
export function getReservedData(data) {
  return {
    user_id: data.user_id,
    class_id: data.class_id,
    login_date: moment(data.attend_date).format('YYYY-MM-DD'),
    allow_login: 1
  };
}
export function updateFontSize(data) {
  const matchedarr = [...data.matchAll(/font-size:\d+px/gi)];
  let result = data;
  for (let i = 0; i < matchedarr.length; i += 1) {
    const indexed = matchedarr[i].index + i;
    const pxed = matchedarr[i][0].match(/\d+/).map(Number)[0];
    let tousedpx = pxed / 16;
    if (pxed > 10 && pxed < 100) {
      tousedpx = tousedpx.toFixed(1);
    } else {
      tousedpx = parseInt(tousedpx, 10);
    }
    result =
      result.substr(0, indexed) +
      `font-size:${tousedpx}em` +
      data.substr(indexed + matchedarr[i][0].length - i);
  }
  return result;
}
