/* eslint-disable operator-linebreak */
/* eslint-disable function-paren-newline */
/* eslint-disable arrow-parens */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  withStyles,
  Typography,
  Box,
  InputLabel
} from '@material-ui/core';
import { openNoti } from 'src/Redux/global/global.actions';
import FormControl from 'src/views/common/FormControl/formControl';
import { useSelector, useDispatch } from 'react-redux';
import edit from 'src/assets/image/edit.png';
import {
  // checkValidString,
  isPhoneNumberInvalid,
  validMobile,
  validPhoneRegEx
} from 'src/utils/validations';
import VisaCardIcon from 'src/assets/image/visacard.png';
import { getErrorMsg } from 'src/utils/ErrorHandling';
import {
  getAdminsOfAffiliate,
  UpdatePaymentMethod
} from 'src/services/gym.services';
import { get } from 'js-cookie';
import LoadingView from 'src/views/common/loading';
import CustomAutocomplete from '../../common/CustomAutocomplete/commonAutocomplete';
import JBButton from '../../common/JBButton/JBButton';
import CheckBoxTable from './CheckBoxTable/checkBoxTable';
import styles from '../GeneralInfo.style';
import PaymentMethod from './PaymentMethod';

const PaymentBilling = ({ classes, data, handleRefresh }) => {
  const Dispatch = useDispatch();
  const [Edit, setEdit] = useState(false);
  const gymLocs = useSelector((state) => state.SettingReducer.allGymloc.data);
  const [admins, setAdmins] = useState(null);
  const affid = get('affiliate');
  const [paymentdata, setPaymentData] = useState(() => null);
  // const [touched, setTouched] = useState({
  //   name_on_card: false,
  //   contact_number: false,
  //   location: false
  // });
  // const handleBlur = (e) => {
  //   const { name } = e.target;
  //   setTouched({ ...touched, [name]: true });
  // };

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setPaymentData({ ...paymentdata, [name]: value });
  // };

  useEffect(() => {
    if (data.data) {
      const paymentdataval = data.data?.payment_method.filter(
        (l) => l.payment_method && l.default_method
      )[0];
      setPaymentData(() => ({
        ...paymentdataval,
        person_contact: {
          id: paymentdataval?.person_contact,
          member_name: paymentdataval?.person_contact_name
        },
        location_id: gymLocs?.filter(
          (l) => l.id === paymentdataval?.location_id
        )[0]
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.data]);

  useEffect(() => {
    getAdminsOfAffiliate(affid)
      .then((res) => setAdmins(res))
      .catch(() => console.warn('Admin data not found'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [affid]);
  const handleCreate = () => {
    const formData = new FormData();
    formData.append('payment_contact', paymentdata.person_contact?.id);
    formData.append('contact_number', paymentdata.contact_number);
    formData.append('location_id', paymentdata.location_id.id);
    formData.append('payment_method_id', paymentdata.id);
    formData.append('affiliate_id', paymentdata.affiliate_id);
    UpdatePaymentMethod(formData) // pass id
      .then(() => {
        Dispatch(openNoti('successfully saved', 'info'));
        setEdit(() => false);
      })
      .catch((err) => {
        openNoti(getErrorMsg(err), 'error');
      });
  };
  const handleSave = () => {
    if (
      paymentdata.contact_number &&
      !validPhoneRegEx.test(paymentdata.contact_number)
    ) {
      // return { Dispatch(openNoti('Please enter a valid Phone number', 'error') };
      // setEdit(true);
      Dispatch(openNoti('Please enter a valid Phone number', 'error'));
    }
    if (isPhoneNumberInvalid(paymentdata.contact_number)) {
      // setEdit(true);
      // return { error: 'Mobile number must be between 10 and 15 digits' };
      Dispatch(
        openNoti('Phone number must be between 10 and 15 digits', 'error')
      );
    } else {
      handleCreate();
    }
  };

  if (data.isFetching) {
    return <LoadingView />;
  }
  if (data.error) {
    return <p>{data.error || 'Something went wrong'}</p>;
  }

  return (
    <div>
      {data.data?.payment_method?.length > 0 && (
        <>
          <Grid
            container
            direction="row"
            spacing={0}
            style={{ margin: '10px 0 0px' }}
          >
            <Grid item xs={12}>
              <Box mt="15px" mb="15px">
                <Typography
                  color="#000"
                  variant="h5"
                  component="div"
                  className={classes.bktitle}
                >
                  Payment Methods
                </Typography>
                <p className={classes.ptext}>
                  Select the payment method used to pay for each location.
                </p>
              </Box>
            </Grid>
          </Grid>

          <PaymentMethod
            dataval={data}
            handleRefresh={(res) => handleRefresh(res)}
          />

          <Box
            style={{
              border: '1px solid #EAEAEA',
              borderRadius: '8px',
              display: 'none'
            }}
            p="15px"
          >
            <Grid
              container
              spacing={2}
              justify="flex-start"
              alignItems="center"
            >
              <Grid item xs={6}>
                <Box alignItems="center" display="inline-flex">
                  <Box className={classes.cardimage}>
                    <img src={VisaCardIcon} draggable="false" alt="card" />
                  </Box>
                  <Box
                    className={classes.cardnum}
                    display="inline-flex"
                    ml="15px"
                  >
                    <span className={classes.cardnumDots} />
                    <span className={classes.cardnumDots} />
                    <span className={classes.cardnumDots} />
                    <label>{data.data?.payment_method[0]?.card_number}</label>
                  </Box>
                </Box>
              </Grid>
              <Grid
                item
                xs={6}
                justify="flex-end"
                className="MuiGrid-container"
              >
                <Box className={classes.Payinfobk}>
                  <Box className={classes.Paybk}>
                    <span>Exp:</span>
                    <label>{data.data?.payment_method[0]?.card_expiry}</label>
                  </Box>
                  <Box className={classes.Paybk}>
                    <span>Postal Code:</span>
                    <label>{data.data?.payment_method[0]?.postal_code}</label>
                  </Box>
                  <Box className={classes.Paybk}>
                    <label>{data.data?.payment_method[0].location}</label>
                  </Box>
                  {!Edit ? (
                    <JBButton
                      type="outlined"
                      style={{ width: '83px' }}
                      isImg={edit}
                      onClick={() => setEdit(true)}
                    >
                      Edit
                    </JBButton>
                  ) : (
                    <JBButton
                      type="primary"
                      style={{ width: '83px' }}
                      // isImg={edit}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleSave();
                      }}
                      disabled={
                        !paymentdata.person_contact ||
                        !paymentdata.contact_number ||
                        !paymentdata.location_id
                      }
                    >
                      Save
                    </JBButton>
                  )}
                </Box>
              </Grid>
            </Grid>

            {!Edit ? null : (
              <Box style={{ width: '100%' }}>
                <Grid
                  container
                  spacing={2}
                  style={{
                    alignItems: 'center',
                    padding: '15px 0'
                  }}
                >
                  <Grid item xs={4}>
                    <InputLabel
                      htmlFor="age-required"
                      required
                      className={classes.cutom_select_Label}
                      style={{ padding: '11px 0px' }}
                    >
                      Payment contact
                    </InputLabel>
                    {/* <FormControl
                      control="input"
                      label=""
                      value={paymentdata.name_on_card}
                      style={{ margin: '0 0' }}
                    /> */}
                    <CustomAutocomplete
                      data={paymentdata.person_contact}
                      Change={(val) =>
                        setPaymentData({
                          ...paymentdata,
                          person_contact: val
                        })
                      }
                      value={admins?.list_of_admin}
                      Options="member_name"
                    />
                    {/* <FormControl
                      control="input"
                      label=""
                      placeholder=""
                      name="name_on_card"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={paymentdata.name_on_card}
                      error={Boolean(
                        touched.name_on_card &&
                          checkValidString(paymentdata.name_on_card)
                      )}
                      helperText={
                        touched.name_on_card &&
                        checkValidString(paymentdata.name_on_card)
                          ? 'Please enter valid Payment contact'
                          : null
                      }
                      style={{ margin: '0 0' }}
                    /> */}
                  </Grid>
                  <Grid item xs={4}>
                    <InputLabel
                      required
                      htmlFor="age-required"
                      className={classes.cutom_select_Label}
                      style={{ padding: '8px 0' }}
                    >
                      Phone number
                    </InputLabel>
                    <FormControl
                      control="input"
                      label=""
                      placeholder=""
                      onChange={(e) =>
                        setPaymentData({
                          ...paymentdata,
                          contact_number: validMobile(e.target.value)
                        })
                      }
                      value={paymentdata.contact_number}
                      name="contact_number"
                      maxLength={23}
                      seterrors={paymentdata.contact_number < 0}
                      seterrortext="Please Enter Valid Phone number"
                      style={{ margin: '0 0' }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <InputLabel
                      required
                      htmlFor="age-required"
                      className={classes.cutom_select_Label}
                      style={{ padding: '11px 0px' }}
                    >
                      Locations
                    </InputLabel>
                    <CustomAutocomplete
                      data={paymentdata.location_id}
                      Change={(val) =>
                        setPaymentData({ ...paymentdata, location_id: val })
                      }
                      value={gymLocs}
                      Options="gym_name"
                      style={{ margin: '0 0' }}
                    />
                  </Grid>
                </Grid>
              </Box>
            )}
          </Box>
        </>
      )}
      <Grid
        container
        direction="row"
        spacing={0}
        style={{ margin: '10px 0 0px' }}
      >
        <Grid item xs={12}>
          <Box mt="15px" mb="20px">
            <Typography
              color="#000"
              variant="h5"
              component="div"
              className={classes.bktitle}
            >
              Billing Details
            </Typography>
            <p className={classes.ptext}>
              Review your billing details for gym software.
            </p>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <CheckBoxTable values={data.data?.invoice_list || []} />
        </Grid>
      </Grid>
    </div>
  );
};

PaymentBilling.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  handleRefresh: PropTypes.func.isRequired
};

export default withStyles(styles, { withTheme: true })(PaymentBilling);
