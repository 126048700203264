import React from 'react';
import PropType from 'prop-types';
// import { Link } from 'react-router-dom';
import {
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  TableSortLabel,
  withStyles
  // Button
} from '@material-ui/core';
import filterType from 'src/Redux/Reducer.constants';
import LoadingView from 'src/views/common/loading';

const styles = () => ({
  table: {
    minWidth: '100%',
    wordBreak: 'break-word',
    '& td': {
      padding: '15px'
    }
  },
  tableLink: {
    color: '#01847A',
    fontWeight: '700'
  },
  TableCell: {
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px',
    height: '56px',
    minHeight: '56px',
    padding: '15px',
    backgroundColor: '#F9F9FA'
  },
  Tablemian: {
    maxHeight: '400px'
  }
});

const ClassHistoryTable = (props) => {
  const {
    values,
    isFetching,
    classes,
    setSort,
    isActive,
    setDirection,
    tableRef
  } = props;
  const { sortdate, sorttime, sortlocation, sortbyprogram } = filterType;

  if (isFetching || !values) return <LoadingView />;

  return (
    <TableContainer
      component={Paper}
      elevation={0}
      className={classes.Tablemian}
      ref={tableRef}
    >
      <Table className={classes.table} stickyHeader aria-label="simple table">
        <TableHead
          style={{ background: '#F9F9FA', backgroundColor: '#F9F9FA' }}
        >
          <TableRow>
            <TableCell className={classes.TableCell}>
              <TableSortLabel
                active={isActive(sortdate)}
                direction={setDirection(sortdate)}
                onClick={() => setSort(sortdate)}
              >
                Date
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell}>
              <TableSortLabel
                active={isActive(sorttime)}
                direction={setDirection(sorttime)}
                onClick={() => setSort(sorttime)}
              >
                Time
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell}>
              <TableSortLabel
                active={isActive(sortlocation)}
                direction={setDirection(sortlocation)}
                onClick={() => setSort(sortlocation)}
              >
                Location
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell}>
              <TableSortLabel
                active={isActive(sortbyprogram)}
                direction={setDirection(sortbyprogram)}
                onClick={() => setSort(sortbyprogram)}
              >
                Program
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell}>Coaches</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {values?.length === 0 ? (
            <p>This user has not attended any classes</p>
          ) : (
            values.map((row) => (
              <TableRow
                hover
                tabIndex={-1}
                key={row.id}
                style={{
                  backgroundColor: '#FFF'
                }}
              >
                <TableCell
                  style={{
                    width: '9.0909%'
                  }}
                >
                  {row.date}
                </TableCell>
                <TableCell
                  style={{
                    width: '9.0909%'
                  }}
                >
                  {row.time}
                </TableCell>
                <TableCell
                  style={{
                    width: '9.0909%'
                  }}
                >
                  {row.location}
                </TableCell>
                <TableCell
                  style={{
                    width: '9.0909%'
                  }}
                >
                  {row.program}
                </TableCell>

                <TableCell
                  style={{
                    width: '9.0909%'
                  }}
                >
                  {row.head_coach ? row.head_coach : '-'}
                  {row.co_coach ? `, ${row.co_coach}` : null}
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
ClassHistoryTable.propTypes = {
  values: PropType.array, // eslint-disable-line
  isFetching: PropType.bool.isRequired,
  classes: PropType.object.isRequired,
  setSort: PropType.func.isRequired,
  setDirection: PropType.func.isRequired,
  isActive: PropType.func.isRequired
};

export default withStyles(styles)(ClassHistoryTable);
