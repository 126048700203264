import React, { createContext, useReducer } from 'react';
import PropType from 'prop-types';
import SessionReducer, { initialValue } from './SessionTemplate.reducer';

export const SessionTemplateContext = createContext();

export const SessionContext = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(SessionReducer, initialValue);
  return (
    <SessionTemplateContext.Provider value={[state, dispatch]}>
      {children}
    </SessionTemplateContext.Provider>
  );
};

SessionContext.propTypes = {
  children: PropType.node.isRequired
};
