import React, { useState, useRef } from 'react';
// import PropType from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import MainRoutes from './MainRoutes';
import NavBar from './NavBar/index';
// import TopBar from './TopBar';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#f9f9fa',
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    height: 'calc(100vh)',
    paddingTop: 104,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 250
    }
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}));

const DashboardLayout = () => {
  // const { currentUser } = props;
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const scrollRef = useRef();

  const handleCalendarClick = () => {
    return scrollRef.current.scrollTop;
  };

  const miniMenu = localStorage.getItem('mini-menu');
  return (
    <div className={classes.root}>
      {/* <TopBar
        onMobileNavOpen={() => setMobileNavOpen(true)}
        currentUser={currentUser}
        className="TopBar"
      /> */}
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <div
        className={clsx(
          classes.wrapper,
          miniMenu === 'false' ? 'wrapperContent' : null
        )}
      >
        <div className={classes.contentContainer}>
          <div className={classes.content} ref={scrollRef}>
            <MainRoutes onCalendarClick={handleCalendarClick} />
          </div>
        </div>
      </div>
    </div>
  );
};

// DashboardLayout.propTypes = {
//   // currentUser: PropType.object.isRequired
// };

export default DashboardLayout;
