import { getValuefromObject } from 'src/utils/someCommon';

export const Filters = [
  { id: 1, filter: 'Membership Plan' },
  { id: 2, filter: 'Payment Plan' }
];
export const getPaymentLabels = (data) => {
  const result = data?.filter(
    (item, index, self) =>
      index
      === self.findIndex((t) => t.payment_plan_id === item.payment_plan_id)
  );
  return result;
};
export const getFilter = (filter, data) => {
  switch (filter.id) {
    case 1:
      return {
        membership_plan: getValuefromObject(data)
      };

    case 2:
      return {
        payment_plan: data.payment_plan_id
      };
    default:
      return null;
  }
};
export const getfilterLabel = (filter, data) => {
  switch (filter.id) {
    case 1:
      return {
        id: filter.id,
        name: `Membership plan is ${Object.values(data)[1]}`,
        filter: 'membership_plan'
      };
    case 2:
      return {
        id: filter.id,
        name: `Payment plan is ${data.payment_plan}`,
        filter: 'payment_plan'
      };
    default:
      return null;
  }
};
export const checkData = (filter, data) => {
  if (!filter || !data || String(data) === 'Invalid') return true;
  return false;
};
