/* eslint-disable no-unused-vars */
/* eslint-disable indent */
/* eslint-disable default-case */
/* eslint-disable object-shorthand */
/* eslint-disable radix */
import React, { useState, useContext, useEffect } from 'react';
import { VanguardMem } from 'src/Context/VanguardMembershipContext';
import { connect, useSelector } from 'react-redux';

import { openNoti } from 'src/Redux/global/global.actions';

import {
  NextStep,
  StepBack,
  ResetStep,
  setUser,
  setUserContract,
  skipStep,
  setMembershipType,
  SetActiveStep,
  resetData,
  setStartDateDropIn,
  setPytOpt,
  getDropInPlan,
  selectAllPrograms
} from 'src/Context/VanguardMembership/VanguardMembership.actions';
import PropType from 'prop-types';
import { ROUTES } from 'src/constant/Constants';
import { useHistory, Link, useLocation } from 'react-router-dom';

import { useElements, useStripe, CardElement } from '@stripe/react-stripe-js';
import { createusermemship, createPaymentMet } from 'src/services/user.service';
import {
  Button,
  AppBar,
  Toolbar,
  IconButton,
  withStyles,
  Card,
  Box
} from '@material-ui/core';
import {
  createVanguardDropInUser,
  updateUserWaiverVanguard
} from 'src/services/vanguard.services';
import { updateUserContract } from 'src/services/Contracts.services';
import { getErrorMsg } from 'src/utils/ErrorHandling';
import { VanguardMembershipType } from 'src/Context/VanguardMembership/VanguardMemership.data';
import useChangeTitle from 'src/views/common/CustomHooks/useTitleChange';
import { getMemberDetail } from 'src/services/Membersip.services';
import {
  assignWaiverToUserVanguard,
  updateVanguardUser,
  getUnsignedContracts
} from '../../../services/vanguard.services';
import { ReactComponent as BackArrow } from '../../../assets/image/Vanguard/icon_back_vanguard.svg';
import LeavePagePopup from '../LeavepagePopup';
import styles from '../membership/membershipIndex.style';
import JBVanguardStepper from '../../common/JBVanguardStepper';
import PersonalInfo from '../personalInfo';
import SuccessPopup from '../successPopup';
import PlanConfigureMembership from '../membership/Plan/ConfigureMembership';
import SessionConfigureMembership from '../membership/Session/ConfigureMembership';
import DiscountsPricing from '../membership/Session/DiscountsPricing';
import PaymentAutoRenew from '../membership/Plan/PaymentAutoRenew';
import ProgramAccess from '../membership/programAccess';

import PaymentPlanTerms from '../membership/PaymentPlanTerms';
import Membershipagreement from '../membership/MembershipAgreement';
import SessionPlanTerms from '../membership/SessionPlanTerms';
import Sign from './signDropin';
import ConvertMemberPopup from '../membership/ConvertMember/ConvertMemberPopup';
import ReactivateMemberPopup from '../membership/InactiveMember/ReactivateMemberPopup';
import SelectWaiver from './selectWaiverDropin';
import Terms from './terms';
import Questionnaire from './questionnaireDropin';
import SelectMembership from './selectMembership';
import DropInConfigureMembership from './ConfigureMembership';
import PricingDropin from './PricingDropin';
import ProgramAccessDropin from './ProgramAccessDropin';
// import FindMember from '../Returning/findMember';

import AddPaymentMethod from '../membership/PaymentMethod/AddPaymentMethod';
import {
  // verifyInfo,
  validStep6,
  getUserData,
  getMembershipData,
  stripeData,
  validStep7,
  validStep9,
  getContractData,
  planStep5,
  getDropInData,
  validStep0,
  verifyInfo
} from '../membership/Vanguard.utills';
import { getWaiverData, validateQues } from '../waiver/waiverValidation';

function getSteps() {
  return ['1', '2', '3', '4', '5', '6', '7'];
}

const DropinMember = ({ classes, openNotify }) => {
  const history = useHistory();
  useChangeTitle('Drop In Member ');
  const [LeavePageopen, setLeavePageopen] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [state, dispatch] = useContext(VanguardMem);

  // const stripe = useStripe();
  // const elements = useElements();
  const {
    active_Step,
    membership_id,
    membership_type,
    signStatus,
    isSigned,
    user_id,
    personal_info: { user_details, error, error_msg },
    memebership_data: { membershipdata },
    selectedWaiver,
    waiver_terms_initial: { sign_initials, initial_box },
    questionnaire_meta,
    dropIn_data: { signature },
    payments: { all_payments }
  } = state;

  const steps = getSteps();
  const stripe = useStripe();
  const elements = useElements();
  // const [Sussopen, setSussopen] = useState(false);
  const [memCreate, setMemCreate] = useState(false);

  const allPrograms = useSelector(
    (statedata) => statedata.allprograms.programs
  );

  // eslint-disable-next-line consistent-return
  const handleNext = async () => {
    // dispatch(NextStep());
    if (active_Step === 0) {
      // dispatch(NextStep());

      setProcessing(true);
      createVanguardDropInUser(getUserData(user_details))
        .then((res) => {
          dispatch(setUser(res.id));
          dispatch(NextStep());
          openNotify('Profile created');
          setProcessing(false);
        })
        .catch((err) => {
          setProcessing(false);
          openNotify(getErrorMsg(err), 'error');
        });
    } else if (active_Step === 1) {
      if (selectedWaiver) {
        const val = {
          user_id,
          waiver_id: selectedWaiver
        };
        assignWaiverToUserVanguard(val).then((res) => {
          console.warn(res);
        });
        dispatch(NextStep());
      } else {
        openNotify('Please select a Waiver', 'error');
      }
    } else if (active_Step === 2) {
      if (!signStatus) {
        return openNotify(
          'You must agree to the terms and conditions',
          'error'
        );
      }
      if (initial_box) {
        if (sign_initials.length === 0) {
          return openNotify('Initials Required', 'error');
        }
      }
      if (questionnaire_meta.length === 0) {
        dispatch(SetActiveStep(4));
      } else {
        dispatch(NextStep());
      }
    } else if (active_Step === 3) {
      if (validateQues(questionnaire_meta)) {
        openNotify('Please provide valid questionnaire details', 'error');
      } else {
        dispatch(NextStep());
      }
    } else if (active_Step === 4) {
      setProcessing(true);
      if (!isSigned) {
        openNoti('Please provide a signature', 'error');
      } else if (!signStatus) {
        openNoti('You must agree to the terms and conditions', 'error');
      } else {
        const val = {
          ...user_details,
          waiver_terms_initial: state.waiver_terms_initial,
          questionnaire_meta,
          signature,
          id: user_id
        };
        const formData = getDropInData(val);
        // dispatch(NextStep()); // remove
        updateUserWaiverVanguard(formData, selectedWaiver)
          .then(() => {
            openNotify('Waiver signed successfully');
            // setSussopen(true);
            // setTimeout(() => setSussopen(false), 3500);
            setProcessing(false);
            dispatch(NextStep());
          })
          .catch((err) => {
            setProcessing(false);
            openNoti(getErrorMsg(err), 'error');
          }); //eslint-disable-line
      }
    } else if (active_Step === 5) {
      // setProcessing(true);
      dispatch(setStartDateDropIn(new Date()));
      dispatch(setPytOpt(parseInt(1)));

      dispatch(NextStep());
    } else if (active_Step === 6) {
      // eslint-disable-next-line consistent-return
      if (!elements || !stripe) return;
      setProcessing(true);
      const billling = stripeData(user_details);
      try {
        const payload = await stripe.createPaymentMethod({
          type: 'card',
          card: elements.getElement(CardElement),
          billing_details: billling
        });
        const {
          paymentMethod: { id }
        } = payload;
        const val = {
          user_id: user_id,
          payment_method_id: id,
          default_method: 1,
          payment_method: state.paymentMeTab + 1
        };
        createPaymentMet(val)
          .then((res) => {
            // openNotify('Payment method added successfully');
            const value = {
              payment_method: res.payment_method,
              payment_method_id: res.payment_method_id,
              user_payment_method: res.id
            };
            createusermemship(
              getMembershipData({ ...state, ...value }),
              state.user_id
            )
              .then(() => {
                setMemCreate(true);
                setTimeout(() => {
                  setMemCreate(false);
                  history.push(ROUTES.VANGUARD);
                }, 3500);
                // dispatch(setUserContract(res.user_contract_id));
                // openNotify('Membership created successfully');
                // if (res.payment_method_exists) {
                //   dispatch(skipStep());
                // } else {
                //   dispatch(NextStep());
                // }
              })
              .catch((err) => {
                setProcessing(false);
                openNotify(getErrorMsg(err), 'error');
              });
          })
          .catch((err) => {
            setProcessing(false);
            openNotify(getErrorMsg(err), 'error');
          });
      } catch (err) {
        setProcessing(false);
        openNotify(getErrorMsg(err), 'error');
      }
    } else {
      dispatch(NextStep());
    }
  };

  const handleReset = () => {
    dispatch(ResetStep());
  };
  const handleBack = () => {
    if (active_Step === 0) {
      history.goBack();
    }
    dispatch(StepBack());
  };

  useEffect(() => {
    dispatch(setMembershipType(VanguardMembershipType.dropIn));
  }, [dispatch]);

  // --------------------- stepper validation -----------------

  const validStep2 = (data) => {
    if (!data) return true;
    return false;
  };

  const validStep3 = (data, val) => {
    if (!data || !val) return true;

    return false;
  };

  const validStep4 = (data) => {
    if (data.length === 0) return true;
    return false;
  };
  const validStep = () => {
    return false;
  };

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <PersonalInfo Maintitle="Personal Information" />;
      case 1:
        return <SelectWaiver UserId={user_id} reducer={[state, dispatch]} />;
      case 2:
        return <Terms reducer={[state, dispatch]} />;
      case 3:
        return <Questionnaire reducer={[state, dispatch]} />;
      case 4:
        return <Sign reducer={[state, dispatch]} />;
      case 5:
        return <SelectMembership reducer={[state, dispatch]} />;
      // case 6:
      //   return <DropInConfigureMembership reducer={[state, dispatch]} />;
      // case 7:
      //   return (
      //     <PricingDropin
      //       subtitle="The final pricing details for this sessions membership."
      //       reducer={[state, dispatch]}
      //     />
      //   );
      // case 8:
      //   return <ProgramAccessDropin />;
      case 6:
        return (
          <>
            <PricingDropin
              subtitle="The final pricing details for this Drop In"
              reducer={[state, dispatch]}
              isLoading={isLoading}
            />
            <AddPaymentMethod Type={VanguardMembershipType.dropIn} />
          </>
        );
      // eslint-disable-next-line no-fallthrough
      default:
        return 'Unknown step';
    }
  }

  useEffect(() => {
    dispatch(resetData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (active_Step === 6) {
      if (all_payments.length === 0) {
        setIsLoading(true);
        getMemberDetail(membership_id).then((res) => {
          dispatch(getDropInPlan(res));
          setIsLoading(false);
        });
      }
      dispatch(
        selectAllPrograms({
          programs: allPrograms,
          checked: true
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active_Step]);

  return (
    <Box>
      <AppBar className={classes.appBar}>
        <Toolbar className={classes.appToolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="close"
            onClick={() => {
              setLeavePageopen(true);
            }}
          >
            <BackArrow />
          </IconButton>
          <h5 className={classes.headerTitle}>Create Drop In</h5>
          <Button
            color="inherit"
            className={classes.HomeButton}
            component={Link}
            to={ROUTES.VANGUARD}
          >
            Home
          </Button>
        </Toolbar>
      </AppBar>

      <Card className={classes.MainCard}>
        <JBVanguardStepper
          steps={steps}
          // steps={membership_type === 0 ? steps : steps.slice(0, -1)}
          activeStep={active_Step}
          handleBack={handleBack}
          handleNext={handleNext}
          handleReset={handleReset}
          // eslint-disable-next-line react/jsx-no-bind
          handleStepContent={getStepContent}
          cancelBack={[0, 1, 5]}
          validateBtn={[
            verifyInfo(user_details), // step 1
            validStep2(selectedWaiver), // step 2
            // validStep3(sign_initials, signStatus), // step 3
            validStep(), // step 3
            validStep4(questionnaire_meta), // step 4
            validStep3(signature, signStatus), // step 5
            validStep2(membership_id), // step 6
            validStep6(
              user_details,
              membershipdata.payment_data,
              state.paymentMeTab
            ) // step 7
            // validStep3(
            //   membershipdata.start_date,
            //   membershipdata.payment_data.paymentOpt
            // ), // step 7
            // validStep2(true) //   step 8
            // validStep4(membershipdata.program_access), // step 9
          ]}
          isLoading={false}
          hasErrored={false}
          processing={processing}
          backLabel="Back"
          prevLabel="Pay & Continue"
          nextLabel={
            active_Step === 0
              ? 'Continue'
              : active_Step === 4
              ? 'Sign and Continue'
              : 'Next'
          }
        />
      </Card>
      <LeavePagePopup
        setOpen={LeavePageopen}
        handleClose={() => setLeavePageopen(false)}
        // handleHistory={ROUTES.VANGUARD}
      />
      {/* <SuccessPopup
        Dialogtitle="Waiver signed successfully"
        setOpen={Sussopen}
        handleClose={() => setSussopen(false)}
      /> */}

      <SuccessPopup
        Dialogtitle="Payment successful. Drop in session has been purchased"
        setOpen={memCreate}
        handleClose={() => setMemCreate(false)}
      />
    </Box>
  );
};
const mapToDispatch = (dispatch) => ({
  openNotify: (msg, type) => dispatch(openNoti(msg, type))
});
DropinMember.propTypes = {
  classes: PropType.object.isRequired,
  openNotify: PropType.func.isRequired
};
export default connect(null, mapToDispatch)(withStyles(styles)(DropinMember));
