import React from 'react';
import { Button, withStyles } from '@material-ui/core';
import PropType from 'prop-types';
import styles from './Class.style';

const WeekButton = ({ label, classes, data, setWeeks }) => {
  const handleWeeks = (la) => {
    if (data.length > 0 && data[0].name === la.name) {
      setWeeks(null);
    } else {
      setWeeks({ ...la, colors: true });
    }
  };
  return (
    <Button
      variant="contained"
      className={
        data.length > 0 && data[0].colors
          ? classes.btn_days_selected
          : classes.btn_days
      }
      onClick={() => handleWeeks(label)}
    >
      {label.label}
    </Button>
  );
};
WeekButton.propTypes = {
  label: PropType.string.isRequired,
  setWeeks: PropType.func.isRequired,
  classes: PropType.object.isRequired,
  data: PropType.array.isRequired
};

export default withStyles(styles)(WeekButton);
