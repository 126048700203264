import React, { useContext, useEffect } from 'react';
import { VanguardMem } from 'src/Context/VanguardMembershipContext';
import { getMemContract } from 'src/services/Contracts.services';
import {
  setPolicyInitials,
  setPolicy
} from 'src/Context/VanguardMembership/VanguardMembership.actions';
import { validateString } from 'src/utils/validations';
import DOMpurify from 'dompurify';
import { Typography, makeStyles, Grid, InputLabel } from '@material-ui/core';
import FormControl from 'src/views/common/FormControl/formControl';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    background: 'rgba(29, 29, 29, 0.94)',
    boxShadow: '0px 0.33333px 0px rgb(255 255 255 / 15%)',
    backdropFilter: 'blur(54.3656px)'
  },
  appToolbar: {
    minHeight: '75px',
    justifyContent: 'space-between'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
  mainTitle: {
    fontWeight: '500',
    fontSize: '48px',
    lineHeight: '44px',
    color: '#FFFFFF',
    fontFamily: 'DDC Hardware',
    textAlign: 'center',
    margin: '38px 0 0 0',
    '@media (max-width: 992px)': {
      fontSize: '35px'
    }
  },
  MainCard: {
    height: 'calc(100vh - 75px )',
    background:
      'linear-gradient(180deg, rgba(41, 41, 41, 0.95) 0%, rgba(4, 4, 4, 0.95) 100%)',
    borderRadius: '0',
    flex: '1 1 auto',
    // height: '100%',
    overflow: 'auto'
  },
  HomeButton: {
    fontFamily: 'Lato',
    color: '#2CE9DA',
    fontWeight: 'bold',
    fontSize: '22px',
    lineHeight: '32px',
    textTransform: 'capitalize'
  },
  cutom_select_Label: {
    fontFamily: 'DDC Hardware',
    fontStyle: 'normal',
    fontWeight: '500;',
    fontSize: '22px',
    lineHeight: '32px',
    letterSpacing: '0.2px',
    color: '#FFFFFF',
    '&:focus ': {
      outline: '0'
    },
    '& .MuiFormLabel-asterisk': {
      color: 'transparent'
    },
    '& .MuiFormLabel-asterisk:after': {
      content: '""',
      background: '#2CE9DA',
      display: 'inline-block',
      position: 'relative',
      width: '8px',
      height: '8px',
      borderRadius: '50%',
      top: '-14px',
      right: '10px'
    }
  },
  cutom_vanguard_FormControl: {
    '& .fd-input input': {
      background: 'rgba(196, 196, 196, 0.05)',
      color: '#fff',
      borderRadius: '6px',
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '20px',
      height: '45px',
      border: '2px solid #C8C8C8',
      padding: '8px 20px 8px',
      '&::placeholder ': {
        color: '#fff',
        fontWeight: 'bold',
        fontSize: '15px'
      }
    }
  },
  cutom_vanguard_Autocomplete: {
    background: 'rgba(196, 196, 196, 0.05)',
    border: '2px solid #C8C8C8',
    color: '#fff',
    borderRadius: '6px',
    '& .cutom_select div': {
      minHeight: '62px'
    },
    '& .cutom_select div input ': {
      color: '#ffffff !important',
      fontWeight: 'bold',
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontSize: '20px'
    },
    '& .MuiAutocomplete-endAdornment': {
      minHeight: ' auto !important',
      top: 'calc(50% - 12px)'
    }
  },
  customRadio: {
    '& .MuiFormControlLabel-root': {
      background: 'rgba(196, 196, 196, 0.05)',
      border: '2px solid #7B7B7B',
      borderRadius: '6px',
      height: '70px',
      margin: '0 0 15px'
    },
    '& .MuiFormControlLabel-root.checked': {
      background: 'rgba(44, 233, 218, 0.15)',
      border: '2px solid #2CE9DA',
      borderRadius: '6px'
    },
    '& .MuiFormControlLabel-root svg.MuiSvgIcon-root': {
      width: '30px',
      height: '30px',
      fill: 'none',
      borderRadius: '50%',
      border: '2px solid #4B4B4B'
    },
    '& .MuiFormControlLabel-root.checked svg.MuiSvgIcon-root': {
      background: '#67e9da',
      padding: '4px',
      fill: '#000',
      color: '#060606',
      border: '0px solid #4B4B4B'
    },
    '& .MuiRadio-root': {
      padding: '20px'
    },
    '& .MuiFormControlLabel-label': {
      color: '#fff',
      fontSize: '20px',
      lineHeight: '26px',
      fontWeight: 'bold'
    }
  },
  dynamicData: {
    border: '2px solid #7B7B7B',
    height: 'calc(100vh - 416px)',
    padding: '10px',
    boxSizing: 'border-box',
    color: '#fff',
    overflowY: 'auto',
    borderRadius: '6px',
    marginBottom: '15px'
  }
}));

const MembershipAgreement = () => {
  const classes = useStyles();
  const [state, dispatch] = useContext(VanguardMem);
  const {
    memebership_data: {
      membershipdata: { user_contract }
    }
  } = state;
  useEffect(() => {
    getMemContract(user_contract.id).then((res) =>
      dispatch(setPolicy(res.membership_policy_meta.policy_meta.policy)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const htmlData = DOMpurify.sanitize(user_contract.policy);
  const handleChange = (e) => {
    e.preventDefault();
  };
  return (
    <div>
      <Typography variant="h3" className={classes.mainTitle}>
        Membership Agreement
      </Typography>
      <Grid
        container
        xs={10}
        justify="center"
        style={{
          margin: '45px auto'
        }}
      >
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={2}
          // style={{ margin: '20px 0 20px' }}
        >
          <Grid xs={12} className={classes.dynamicData}>
            {user_contract.policy && (
              // eslint-disable-next-line react/no-danger
              <div dangerouslySetInnerHTML={{ __html: htmlData }} />
            )}
          </Grid>
          <Grid
            container
            direction="row"
            style={{
              justifyContent: 'flex-end'
            }}
          >
            <Grid
              item
              className={classes.cutom_vanguard_FormControl}
              style={{
                margin: '10px 0',
                width: '185px'
              }}
            >
              <InputLabel
                htmlFor="age-required"
                required
                className={classes.cutom_select_Label}
                // style={{ margin: '0px 0 5px' }}
              >
                INITIALS HERE
              </InputLabel>
              <FormControl
                control="input"
                value={user_contract.policy_initials}
                onCut={handleChange}
                onCopy={handleChange}
                onPaste={handleChange}
                onChange={(e) =>
                  dispatch(setPolicyInitials(validateString(e.target.value)))
                }
                maxLength={3}
                style={{
                  margin: '0 0'
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

MembershipAgreement.propTypes = {
  // setOpen: PropType.bool.isRequired,
  // handleClose: PropType.func.isRequired
  // handleBack: PropType.func,
  // handleHistory: PropType.string
};

export default MembershipAgreement;
