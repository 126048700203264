import moment from 'moment';
import Timezone from 'src/constant/timeformats';
import { GymdateFormat, GymTimeFormat } from 'src/constant/GymDateTime';
import { GymweekDay, Gymagelimit } from './GymSetting.data';

export const initialState = {
  data: {
    date_format: GymdateFormat.filter((l) => l.format === 'd-m-Y')[0] || null,
    hours_format: GymTimeFormat.twelve,
    time_zone: Timezone.filter((l) => l.id === 'Australia/Sydney')[0] || null,
    hours_value: moment().format('DD-MM-YYYY h:mm:ss A'),
    week_day: GymweekDay.filter((l) => l.id === 1)[0] || null,
    age_limit: Gymagelimit.filter((l) => l.id === 21)[0] || null
  }
};

export const GymReducerType = {
  updateData: 'UPDATE_INITIAL_STATE_DATA',
  updateDateFmt: 'UPDATE_DATE_FORMAT',
  updateTimeFmt: 'UPDATE_TIME_FORMAT',
  updateTimeZone: 'UPDATE_TIME_ZONE',
  updateweekday: 'UPDATE_WEEK_DAY',
  updateageMature: 'UPDATE_AGE_MATURITY'
};

export const GymReducer = (state = initialState, action) => {
  switch (action.type) {
    case GymReducerType.updateData:
      return {
        ...state,
        data: action.payload
      };

    case GymReducerType.updateDateFmt:
      return {
        ...state,
        data: {
          ...state.data,
          date_format: action.payload,
          hours_value:
            state.data.hours_format === GymTimeFormat.twelve
              ? moment().format(`${action.payload.value} h:mm:ss A`)
              : moment().format(`${action.payload.value} HH:mm:ss`)
        }
      };

    case GymReducerType.updateTimeFmt:
      return {
        ...state,
        data: {
          ...state.data,
          hours_format: action.payload,
          hours_value:
            action.payload === GymTimeFormat.twelve
              ? moment().format(`${state.data?.date_format?.value} h:mm:ss A`)
              : moment().format(`${state.data?.date_format?.value} HH:mm:ss`)
        }
      };

    case GymReducerType.updateTimeZone:
      return {
        ...state,
        data: {
          ...state.data,
          time_zone: action.payload
        }
      };

    case GymReducerType.updateweekday:
      return {
        ...state,
        data: {
          ...state.data,
          week_day: action.payload
        }
      };

    case GymReducerType.updateageMature:
      return {
        ...state,
        data: {
          ...state.data,
          age_limit: action.payload
        }
      };
    default:
      return state;
  }
};
