import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Switch, Route, withRouter } from 'react-router-dom';
import PropType from 'prop-types';
import { getCalenderData } from 'src/Redux/CalenderReducer/Calender.actions';
import {
  AllClassdata,
  futureddata,
  reccurdata,
  getAllCoaches
} from '../Redux/ClassReducer/classReducer.actions';
import EditClass from '../views/calendar/Class/EditClass';
import CreateNewClass from '../views/calendar/Class/CreateNewClass';
import ClassesList from '../views/calendar/Classes/List/List';
import MainCalendar from '../views/calendar/Calender/Calendar';
import ClassesSettings from '../views/classessettings/ClassesSettings';

class ClassContainer extends Component {
  componentWillMount() {
    const { getFuture, getAllClass, getReccClass, getCoaches } = this.props;
    getFuture();
    getAllClass();
    getReccClass();
    getCoaches();
  }

  componentDidMount() {
    const { getCalender } = this.props;
    getCalender();
  }

  render() {
    const {
      match: { url },
      onCalendarClick
    } = this.props;
    return (
      <Switch>
        <Route exact path={`${url}/create`} component={CreateNewClass} />
        <Route
          exact
          path={`${url}/calendar`}
          render={() => <MainCalendar onCalendarClick={onCalendarClick} />}
        />
        <Route exact path={`${url}/edit`} component={EditClass} />
        <Route path={`${url}/setting`} component={ClassesSettings} />
        <Route path={`${url}/`} component={ClassesList} />
      </Switch>
    );
  }
}

ClassContainer.propTypes = {
  getFuture: PropType.func.isRequired,
  match: PropType.any.isRequired,
  getAllClass: PropType.func.isRequired,
  getReccClass: PropType.func.isRequired,
  getCalender: PropType.func.isRequired,
  onCalendarClick: PropType.func.isRequired,
  getCoaches: PropType.func.isRequired
};

const mapToDispatch = (dispatch) => ({
  getFuture: () => dispatch(futureddata()),
  getAllClass: () => dispatch(AllClassdata()),
  getReccClass: () => dispatch(reccurdata()),
  getCalender: () => dispatch(getCalenderData()),
  getCoaches: () => dispatch(getAllCoaches())
});

export default connect(null, mapToDispatch)(withRouter(ClassContainer));
