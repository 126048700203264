import React, { useEffect, useState } from 'react';
import PropType from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Grid,
  Card,
  Typography,
  Box,
  Button,
  Breadcrumbs,
  withStyles,
  AppBar,
  Tabs,
  Tab
} from '@material-ui/core';
import NotFoundView from 'src/views/errors/NotFoundView';
import {
  getMemberSuccess,
  getAutomated
} from 'src/services/Communication.service';
import LoadingView from 'src/views/common/loading';
import CancelDialogTwo from 'src/views/common/CommonDialogBox/CancelDialogTwo';
import ArrowLeft from '../../../assets/image/arrow_left.png';
import MemberPaymentSuccess from './General/MemberPayments/MemberPaymentSuccess';
import MemberPaymentRefund from './General/MemberPayments/MemberPaymentRefund';
import MemberVoidSuccess from './General/MemberPayments/MemberVoidSuccess';

import styles from './automatedemails.style';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

const EditTabsTemplate = ({ classes }) => {
  const [tabValue, setTabValue] = useState(0);
  const [error, setError] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [data, setData] = useState(null);
  const [open, setOpen] = useState(false);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  useEffect(() => {
    setIsFetching(true);
    Promise.all([
      getMemberSuccess(4),
      getMemberSuccess(5),
      getMemberSuccess(6),
      getAutomated({ search: 'Member Payments', email_general_class: 1 }),
      getAutomated({ search: 'Anonymous Payments', email_general_class: 1 })
    ])
      .then((res) => {
        console.warn([
          {
            ...res[0],
            email_template: JSON.parse(res[0].email_template),
            setting: JSON.parse(res[0].setting)
          },
          {
            ...res[1],
            email_template: JSON.parse(res[1].email_template),
            setting: JSON.parse(res[1].setting)
          },
          {
            ...res[2],
            email_template: JSON.parse(res[2].email_template),
            setting: JSON.parse(res[2].setting)
          },
          [
            {
              ...res[3][0],
              email_template: JSON.parse(res[3][0].email_template),
              setting: JSON.parse(res[3][0].setting)
            },
            {
              ...res[3][1],
              email_template: JSON.parse(res[3][1].email_template),
              setting: JSON.parse(res[3][1].setting)
            },
            {
              ...res[3][2],
              email_template: JSON.parse(res[3][2].email_template),
              setting: JSON.parse(res[3][2].setting)
            }
          ]
        ]);
        setData([
          {
            ...res[0],
            email_template: JSON.parse(res[0].email_template),
            setting: JSON.parse(res[0].setting)
          },
          {
            ...res[1],
            email_template: JSON.parse(res[1].email_template),
            setting: JSON.parse(res[1].setting)
          },
          {
            ...res[2],
            email_template: JSON.parse(res[2].email_template),
            setting: JSON.parse(res[2].setting)
          },
          [
            {
              ...res[3][0],
              email_template: JSON.parse(res[3][0].email_template),
              setting: JSON.parse(res[3][0].setting)
            },
            {
              ...res[3][1],
              email_template: JSON.parse(res[3][1].email_template),
              setting: JSON.parse(res[3][1].setting)
            },
            {
              ...res[3][2],
              email_template: JSON.parse(res[3][2].email_template),
              setting: JSON.parse(res[3][2].setting)
            }
          ]
        ]);
        setIsFetching(false);
      })
      .catch(() => setError(true));
  }, [tabValue]);
  if (error) return <NotFoundView />;
  return (
    <div className={classes.root}>
      <div className="title-bk">
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="inherit">communication</Link>
          <div
            color="inherit"
            onClick={() => setOpen(true)}
            style={{
              cursor: 'pointer'
            }}
          >
            Automated emails
          </div>
          <div
            color="inherit"
            onClick={() => setOpen(true)}
            style={{
              cursor: 'pointer'
            }}
          >
            General
          </div>
          <Typography variant="h3" color="Primary">
            {(() => {
              switch (tabValue) {
                case 0:
                  return <span>Member Payments</span>;
                case 1:
                  return <span>member payment refund</span>;
                case 2:
                  return <span>member void success</span>;
                default:
                  return null;
              }
            })()}
          </Typography>
        </Breadcrumbs>
      </div>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="flex-start"
        spacing={0}
        style={{ margin: '20px 0 20px' }}
      >
        <div>
          <Button className={classes.ArrowLeft} onClick={() => setOpen(true)}>
            <img alt="Plusicon" src={ArrowLeft} />
          </Button>
        </div>
        <CancelDialogTwo setOpen={open} handleClose={() => setOpen(false)} />
        <Grid xs>
          <h1 style={{ margin: '0px 0 10px' }}>
            Edit “Member Payments” Template
          </h1>
          <p className={classes.ptext}>
            Send payment notification emails to member
          </p>
        </Grid>
      </Grid>
      <Card className={classes.mainCard}>
        <AppBar
          position="static"
          style={{
            backgroundColor: 'transparent',
            boxShadow: 'none'
          }}
        >
          <Tabs
            value={tabValue}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            fullWidth
          >
            <Tab
              label="member payment success"
              className={classes.p_tabs}
              {...a11yProps(0)} // eslint-disable-line
            />
            <Tab
              label="member payment refund"
              className={classes.p_tabs}
              {...a11yProps(1)} // eslint-disable-line
            />
            <Tab
              label="member void success"
              className={classes.p_tabs}
              {...a11yProps(1)} // eslint-disable-line
            />
          </Tabs>
        </AppBar>
        {data ? (
          <Box mt="35px">
            {(() => {
              switch (tabValue) {
                case 0:
                  return (
                    <MemberPaymentSuccess
                      info={data[0]}
                      isFetching={isFetching}
                      reset={data[3][0]}
                    />
                  );
                case 1:
                  return (
                    <MemberPaymentRefund
                      info={data[1]}
                      isFetching={isFetching}
                      reset={data[3][1]}
                    />
                  );
                case 2:
                  return (
                    <MemberVoidSuccess
                      info={data[2]}
                      isFetching={isFetching}
                      reset={data[3][2]}
                    />
                  );
                default:
                  return null;
              }
            })()}
          </Box>
        ) : (
          <LoadingView />
        )}
      </Card>
    </div>
  );
};

EditTabsTemplate.propTypes = {
  classes: PropType.object.isRequired,
  history: PropType.any.isRequired
};
export default withStyles(styles)(EditTabsTemplate);
