import React from 'react';
import { Paper } from '@material-ui/core';

function PaginateData(value) {
  const { data } = value;
  return (
    <Paper elevation={0} style={{ color: '#A9A9A9' }}>
      {data.from && data.to > 1 ? (
        <Paper elevation={0} style={{ color: '#A9A9A9' }}>
          {`${data.from} - ${data.to} of ${data.total}`}
        </Paper>
      ) : null}
    </Paper>
  );
}

export default PaginateData;
