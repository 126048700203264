import React, { useState, useEffect, useContext } from 'react';
import PropType from 'prop-types';
import { connect } from 'react-redux';
import JBButton from 'src/views/common/JBButton/JBButton';
import { InputLabel, withStyles } from '@material-ui/core';
import { getMinDate } from 'src/utils/someCommon';
import { FilterContext } from 'src/views/common/ApplyFilter/applyFilter';
import JBDatePicker from 'src/views/common/FormControl/JBDatePicker';
import { TobeFilter } from './static.Data';
import CustomAutoComplete from '../../../common/CustomAutocomplete/commonAutocomplete';
import styles from './filters.style';
import {
  getFilter,
  getfilterLabel,
  checkData
} from './RevenueMembershipFilters.utils';

const RevenueMembershipFilters = (props) => {
  const { classes, getFilData } = props;
  const [data, setData] = useState(null);
  const [filter, setFilter] = useState(null);
  // const filteredData = TobeFilter.filter((fil) => fil.id === filter);
  const [open, setOpen] = useContext(FilterContext);
  const [endDate, setendDate] = useState(getMinDate(new Date()));
  // useEffect(() => {
  //   if (filter === 4) {
  //     setData(new Date());
  //   } else {
  //     setData(null);
  //   }
  // }, [filter]);
  useEffect(() => {
    setData(null);
  }, [filter]);

  return (
    <div>
      <InputLabel
        htmlFor="age-required"
        className={classes.cutom_select_Label}
        style={{ margin: '5px 0' }}
      >
        Payment Due
      </InputLabel>
      <CustomAutoComplete
        holder="This Month"
        style={{ marginBottom: '15px' }}
        value={TobeFilter}
        Options="filter"
        data={filter}
        Change={(val) => {
          setFilter(val);
        }}
        // data={filteredData}
        // Change={(val) => {
        //   setFilter(val.id);
        //   // getValuefromObject(val),
        //   // if (TobeFilter.id === 'custom') {
        //   //   setFilter(getValuefromObject(val));
        //   // } else {
        //   //   setFilter(val.id);
        //   // }
        // }}
      />

      <div>
        {(() => {
          switch (filter?.id) {
            case 'custom':
              return (
                <div>
                  <InputLabel
                    htmlFor="age-required"
                    className={classes.cutom_select_Label}
                    style={{ margin: '5px 0' }}
                  >
                    From
                  </InputLabel>
                  <JBDatePicker
                    label="Start Date"
                    value={data}
                    onChange={(val) => {
                      setData(val);
                      setendDate(getMinDate(val));
                    }}
                  />
                  <InputLabel
                    htmlFor="age-required"
                    className={classes.cutom_select_Label}
                    style={{ padding: '20px 0px 5px' }}
                  >
                    To
                  </InputLabel>
                  <JBDatePicker
                    label="End Date"
                    minDate={data ? getMinDate(data) : getMinDate(new Date())}
                    value={endDate}
                    onChange={(val) => setendDate(val)}
                  />
                </div>
              );
            default:
              return null;
          }
        })()}
      </div>

      <JBButton
        type="primary"
        style={{
          width: '100%',
          minHeight: '22px',
          padding: '7px 25px',
          marginTop: '10px'
        }}
        disabled={checkData(filter, data, endDate)}
        onClick={() => {
          getFilData(
            getFilter(filter, data, endDate),
            getfilterLabel(filter, data, endDate)
          );

          setOpen(!open);
        }} // eslint-disable-line
      >
        Apply Filter
      </JBButton>
    </div>
  );
};
RevenueMembershipFilters.propTypes = {
  getFilData: PropType.func.isRequired,
  classes: PropType.object.isRequired
};

// const mapToState = (state) => ({
//   allGymlocData: state.SettingReducer.allGymloc.data
// });

export default connect(
  null,
  null
)(withStyles(styles)(RevenueMembershipFilters));
