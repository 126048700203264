import React, { useEffect, useContext, useState } from 'react';
import { deviceDetect } from 'react-device-detect';
import { VanguardWaiverCtx } from 'src/Context/VanguardWaiverContext';
import { SetMemberData } from 'src/Context/VanguardWaiver/VanguardWaiver.actions';
// import PropType from 'prop-types';
import { makeStyles, Grid, InputLabel } from '@material-ui/core';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { AllGender } from 'src/views/people/Members/CreateMember/CreateMember.data';
import JBDatePicker from 'src/views/common/FormControl/JBDatePicker';
import { ReactComponent as DownIcon } from '../../../assets/image/Vanguard/downIcon.svg';
import FormControl from '../../common/FormControl/formControl';
import CustomAutocomplete from '../../common/CustomAutocomplete/commonAutocomplete';

const useStyles = makeStyles(() => ({
  mainTitle: {
    fontWeight: '500',
    fontSize: '48px',
    lineHeight: '44px',
    color: '#FFFFFF',
    fontFamily: 'DDC Hardware',
    textAlign: 'center',
    margin: '38px 0 0 0',
    '@media (max-width: 992px)': {
      fontSize: '35px'
    }
  },

  cutom_select_Label: {
    fontFamily: 'DDC Hardware',
    fontStyle: 'normal',
    fontWeight: '500;',
    fontSize: '22px',
    lineHeight: '32px',
    letterSpacing: '0.2px',
    color: '#FFFFFF',
    '&:focus ': {
      outline: '0'
    },
    '& .MuiFormLabel-asterisk': {
      color: 'transparent'
    },
    '& .MuiFormLabel-asterisk:after': {
      content: '""',
      background: '#2CE9DA',
      display: 'inline-block',
      position: 'relative',
      width: '8px',
      height: '8px',
      borderRadius: '50%',
      top: '-14px',
      right: '10px'
    },
    '@media (max-width: 992px)': {
      fontSize: '19px'
    }
  },
  cutom_vanguard_FormControl: {
    '& .fd-input input': {
      background: 'rgba(196, 196, 196, 0.05)',
      color: '#fff',
      borderRadius: '6px',
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '20px',
      height: '45px',
      border: '2px solid #C8C8C8',
      padding: '8px 20px 8px',
      '&::placeholder ': {
        color: '#fff',
        fontWeight: 'bold',
        fontSize: '15px'
      }
    },
    '&  .MuiPaper-root': {
      backgroundColor: '#2d2d2d',
      marginTop: '0',
      marginBottom: '0',
      border: '2px solid #C8C8C8',
      borderTop: 'transparent',
      boxShadow: 'none',
      // borderRadius: '0',
      borderTopLeftRadius: '0',
      borderTopRightRadius: '0'
    },
    '& .MuiAutocomplete-option': {
      color: '#fff',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '20px',
      height: '66px'
    },
    '& .MuiAutocomplete-popper': {
      width: '100% !important',
      marginTop: '-2px'
    },
    '& .SelectDrop[aria-expanded="true"]': {
      borderBottom: '2px solid transparent',
      borderBottomLeftRadius: '0',
      borderBottomRightRadius: '0'
    }
  },
  cutom_vanguard_Drop: {
    '& .MuiAutocomplete-popper ': {
      width: 'calc(100% - 16px) !important'
    }
  },
  cutom_vanguard_Autocomplete: {
    background: 'rgba(196, 196, 196, 0.05)',
    border: '2px solid #C8C8C8',
    color: '#fff',
    borderRadius: '6px',
    '& .cutom_select div': {
      minHeight: '62px'
    },
    '& .cutom_select div input ': {
      color: '#ffffff !important',
      fontWeight: 'bold',
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontSize: '20px'
    },
    '& .MuiAutocomplete-endAdornment': {
      minHeight: ' auto !important',
      top: 'calc(50% - 12px)'
    }
  },
  VanguardCutom_date: {
    '& .MuiInput-root': {
      color: '#fff',
      height: '65px',
      fontWeight: '600',
      fontSize: '20px',
      lineHeight: '18px',
      marginTop: '5px !important',
      border: '2px solid #C8C8C8',
      borderRadius: '6px'
    }
  }
}));

const PersonalInfoWaiver = () => {
  const classes = useStyles();
  const [state, dispatch] = useContext(VanguardWaiverCtx);
  const userAgent = deviceDetect();
  // const { Maintitle } = props;
  const {
    personal_info: {
      first_name,
      last_name,
      email,
      gender,
      mobile_no,
      dob,
      address,
      city,
      province,
      postal_code,
      country,
      eme_contact_name,
      eme_contact_phone
    }
  } = state;
  const [countryData, setCountryData] = useState({ name: country });
  const [genderData, setGenderData] = useState({ name: gender });
  const allCountries = useSelector(
    // eslint-disable-next-line no-shadow
    (state) => state.SettingReducer.countries.data
  );
  useEffect(() => {
    setCountryData(allCountries?.filter((c) => c.code === country)[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (event) => {
    dispatch(SetMemberData({ [event.target.name]: event.target.value }));
  };

  return (
    <div>
      {/* <Typography variant="h3" className={classes.mainTitle}>
        {Maintitle}
      </Typography> */}
      <Grid
        container
        xs={10}
        justify="center"
        style={{
          margin: '45px auto'
        }}
      >
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={2}
          // style={{ margin: '20px 0 20px' }}
        >
          <Grid item xs={6} className={classes.cutom_vanguard_FormControl}>
            <InputLabel
              htmlFor="age-required"
              required
              className={classes.cutom_select_Label}
              // style={{ margin: '0px 0 5px' }}
            >
              FIRST Name
            </InputLabel>
            <FormControl
              control="input"
              value={first_name}
              name="first_name"
              onChange={handleChange}
              // onChange={(e) =>
              //   setFormState({
              //     ...formState,
              //     description: validateString(e.target.value)
              //   })
              // } // eslint-disable-line
              // label="Description"
              // maxLength={50}
              style={{
                margin: '0 0'
              }}
            />
          </Grid>
          <Grid item xs={6} className={classes.cutom_vanguard_FormControl}>
            <InputLabel
              htmlFor="age-required"
              required
              className={classes.cutom_select_Label}
              // style={{ margin: '0px 0 5px' }}
            >
              LAST Name
            </InputLabel>
            <FormControl
              control="input"
              value={last_name}
              name="last_name"
              onChange={handleChange}
              // onChange={(e) =>
              //   setFormState({
              //     ...formState,
              //     description: validateString(e.target.value)
              //   })
              // } // eslint-disable-line
              // label="Description"
              // maxLength={50}
              style={{
                margin: '0 0'
              }}
            />
          </Grid>
          <Grid
            item
            xs={6}
            className={classes.cutom_vanguard_FormControl}
            style={{
              margin: '0px 0 0px'
            }}
          >
            <InputLabel
              htmlFor="age-required"
              required
              className={classes.cutom_select_Label}
              // style={{ margin: '0px 0 5px' }}
            >
              Email
            </InputLabel>
            <FormControl
              control="input"
              value={email}
              name="email"
              onChange={handleChange}
              // value={description}
              // onChange={(e) =>
              //   setFormState({
              //     ...formState,
              //     description: validateString(e.target.value)
              //   })
              // } // eslint-disable-line
              // label="Description"
              // maxLength={50}
              style={{
                margin: '0 0'
              }}
            />
          </Grid>
          <Grid
            item
            xs={6}
            className={clsx(
              classes.cutom_vanguard_FormControl,
              classes.cutom_vanguard_Drop
            )}
            style={{
              position: 'relative'
            }}
          >
            <InputLabel
              htmlFor="age-required"
              required
              className={classes.cutom_select_Label}
              style={{ margin: '0px 0 6px' }}
            >
              GENDER
            </InputLabel>
            <CustomAutocomplete
              // holder="Lead source"
              disablePortal
              Options="name"
              value={AllGender}
              data={genderData}
              popupIcon={<DownIcon />}
              disableClearable
              Change={(val) => {
                setGenderData(val);
                dispatch(SetMemberData({ gender: val.id }));
              }}
              // className={classes.cutom_vanguard_Autocomplete}
              className={clsx(
                classes.cutom_vanguard_Autocomplete,
                'SelectDrop'
              )}
            />
          </Grid>
          <Grid
            item
            xs={6}
            className={classes.cutom_vanguard_FormControl}
            style={{
              margin: '10px 0'
            }}
          >
            <InputLabel
              htmlFor="age-required"
              className={classes.cutom_select_Label}
              required
              // style={{ margin: '0px 0 5px' }}
            >
              Phone Number
            </InputLabel>
            <FormControl
              control="input"
              value={mobile_no}
              name="mobile_no"
              inputMode="numeric"
              onChange={handleChange}
              // onChange={(e) =>
              //   setFormState({
              //     ...formState,
              //     description: validateString(e.target.value)
              //   })
              // } // eslint-disable-line
              // label="Description"
              // maxLength={50}
              style={{
                margin: '0 0'
              }}
            />
          </Grid>
          <Grid
            item
            xs={6}
            className={classes.cutom_vanguard_FormControl}
            style={{
              margin: '10px 0'
            }}
          >
            <InputLabel
              htmlFor="age-required"
              className={classes.cutom_select_Label}
              required
              // style={{ margin: '0px 0 5px' }}
            >
              DATE OF BIRTH
            </InputLabel>
            <JBDatePicker
              required
              vanguard
              openTo="year"
              value={dob}
              name="dob"
              maxDate={new Date()}
              onChange={(e) => dispatch(SetMemberData({ dob: e }))}
              // onChange={(val) => dispatch(SetDate(val))}
              className={classes.VanguardCutom_date}
            />
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          // justify="center"
          alignItems="center"
          spacing={2}
          style={{ padding: '50px 0 0px' }}
        >
          <Grid
            item
            xs={6}
            className={classes.cutom_vanguard_FormControl}
            style={{
              margin: '10px 0'
            }}
          >
            <InputLabel
              htmlFor="age-required"
              className={classes.cutom_select_Label}
              required
              // style={{ margin: '0px 0 5px' }}
            >
              STREET ADDRESS
            </InputLabel>
            <FormControl
              control="input"
              // inputMode="numeric"
              inputMode={userAgent.os === 'Android' ? '' : 'numeric'}
              value={address}
              name="address"
              onChange={handleChange}
              // onChange={(e) =>
              //   setFormState({
              //     ...formState,
              //     description: validateString(e.target.value)
              //   })
              // } // eslint-disable-line
              // label="Description"
              // maxLength={50}
              style={{
                margin: '0 0'
              }}
            />
          </Grid>
          <Grid
            item
            xs={6}
            className={classes.cutom_vanguard_FormControl}
            style={{
              margin: '10px 0'
            }}
          >
            <InputLabel
              htmlFor="age-required"
              className={classes.cutom_select_Label}
              required
              // style={{ margin: '0px 0 5px' }}
            >
              CITY
            </InputLabel>
            <FormControl
              control="input"
              value={city}
              name="city"
              onChange={handleChange}
              // onChange={(e) =>
              //   setFormState({
              //     ...formState,
              //     description: validateString(e.target.value)
              //   })
              // } // eslint-disable-line
              // label="Description"
              // maxLength={50}
              style={{
                margin: '0 0'
              }}
            />
          </Grid>

          <Grid
            item
            xs={6}
            className={classes.cutom_vanguard_FormControl}
            style={{
              margin: '10px 0'
            }}
          >
            <InputLabel
              htmlFor="age-required"
              className={classes.cutom_select_Label}
              required
              // style={{ margin: '0px 0 5px' }}
            >
              State
            </InputLabel>
            <FormControl
              control="input"
              value={province}
              name="province"
              onChange={handleChange}
              // onChange={(e) =>
              //   setFormState({
              //     ...formState,
              //     description: validateString(e.target.value)
              //   })
              // } // eslint-disable-line
              // label="Description"
              // maxLength={50}
              style={{
                margin: '0 0'
              }}
            />
          </Grid>

          <Grid
            item
            xs={6}
            className={classes.cutom_vanguard_FormControl}
            style={{
              margin: '10px 0'
            }}
          >
            <InputLabel
              htmlFor="age-required"
              className={classes.cutom_select_Label}
              required
              // style={{ margin: '0px 0 5px' }}
            >
              POSTAL CODE
            </InputLabel>
            <FormControl
              control="input"
              inputMode="numeric"
              disableClearable
              value={postal_code}
              name="postal_code"
              onChange={handleChange}
              // onChange={(e) =>
              //   setFormState({
              //     ...formState,
              //     description: validateString(e.target.value)
              //   })
              // } // eslint-disable-line
              // label="Description"
              // maxLength={50}
              style={{
                margin: '0 0'
              }}
            />
          </Grid>

          <Grid
            item
            xs={6}
            className={clsx(
              classes.cutom_vanguard_FormControl,
              classes.cutom_vanguard_Drop
            )}
            style={{
              // margin: '10px 0',
              position: 'relative'
            }}
          >
            <InputLabel
              htmlFor="age-required"
              required
              className={classes.cutom_select_Label}
              // style={{ margin: '0px 0 5px' }}
            >
              COUNTRY
            </InputLabel>
            <CustomAutocomplete
              disablePortal
              Options="name"
              value={allCountries}
              data={countryData}
              popupIcon={<DownIcon />}
              disableClearable
              Change={(val) => {
                setCountryData(val);
                dispatch(SetMemberData({ country: val.code }));
              }}
              // className={classes.cutom_vanguard_Autocomplete}
              className={clsx(
                classes.cutom_vanguard_Autocomplete,
                'SelectDrop'
              )}
            />
            {/* <FormControl
              control="input"
              value={country}
              name="country"
              onChange={handleChange}
              // onChange={(e) =>
              //   setFormState({
              //     ...formState,
              //     description: validateString(e.target.value)
              //   })
              // } // eslint-disable-line
              // label="Description"
              // maxLength={50}
              style={{
                margin: '0 0'
              }}
            /> */}
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          // justify="center"
          alignItems="center"
          spacing={2}
          style={{ padding: '50px 0 0px' }}
        >
          <Grid
            item
            xs={6}
            className={classes.cutom_vanguard_FormControl}
            style={{
              margin: '10px 0'
            }}
          >
            <InputLabel
              htmlFor="age-required"
              className={classes.cutom_select_Label}
              required
              // style={{ margin: '0px 0 5px' }}
            >
              EMERGENCY CONTACT
            </InputLabel>
            <FormControl
              control="input"
              value={eme_contact_name}
              name="eme_contact_name"
              onChange={handleChange}
              // onChange={(e) =>
              //   setFormState({
              //     ...formState,
              //     description: validateString(e.target.value)
              //   })
              // } // eslint-disable-line
              // label="Description"
              // maxLength={50}
              style={{
                margin: '0 0'
              }}
            />
          </Grid>
          <Grid
            item
            xs={6}
            className={classes.cutom_vanguard_FormControl}
            style={{
              margin: '10px 0'
            }}
          >
            <InputLabel
              htmlFor="age-required"
              className={classes.cutom_select_Label}
              required
              // style={{ margin: '0px 0 5px' }}
            >
              EMERGENCY CONTACT PHONE
            </InputLabel>
            <FormControl
              control="input"
              inputMode="numeric"
              value={eme_contact_phone}
              name="eme_contact_phone"
              onChange={handleChange}
              // onChange={(e) =>
              //   setFormState({
              //     ...formState,
              //     description: validateString(e.target.value)
              //   })
              // } // eslint-disable-line
              // label="Description"
              // maxLength={50}
              style={{
                margin: '0 0'
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

PersonalInfoWaiver.defaultProps = {
  Maintitle: 'PERSONAL INFORMATION'
};
PersonalInfoWaiver.propTypes = {
  // Maintitle: PropType.string
};

export default PersonalInfoWaiver;
