import React, { useState } from 'react';
import PropType from 'prop-types';
import {
  Button,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  makeStyles,
  ClickAwayListener
} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import GetPrice from 'src/views/common/CurrencySymbol/GetPrice';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { resendPaymentLink } from 'src/services/Invoice.services';
import { useDispatch } from 'react-redux';
import { openNoti } from 'src/Redux/global/global.actions';
import { getErrorMsg } from 'src/utils/ErrorHandling';
import SendInvoice from './SendInvoice';
import RefundInvoiceDialog from './RefundInvoiceDialog';
import VoidInvoiceDialog from './VoidInvoiceDialog';
import PayInvoiceDiag from './PayInvoiceDiag';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  paper: {
    marginRight: theme.spacing(2)
  }
}));

const Actions = ({ data, updateData }) => {
  const classes = useStyles();
  // const { data, updateData } = props;
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [EmailInvoice, setEmailInvoice] = useState(false);
  const [RefundInvoice, setRefundInvoice] = useState(false);
  const [VoidInvoice, setVoidInvoice] = useState(false);
  const [PayInvoiceDialog, setPayInvoiceDialog] = useState(false);

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const handlePaymentLink = async (id) => {
    try {
      const res = await resendPaymentLink(id);
      if (res) {
        dispatch(openNoti(res.message, 'info'));
      }
    } catch (error) {
      console.log('error: ', error);
      dispatch(openNoti(getErrorMsg(error), 'error'));
    }
  };
  return (
    <div className={classes.root}>
      <div>
        <Button
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={() => setOpen(!open)}
          color="primary"
          variant="outlined"
          style={{ height: '48px', fontWeight: '700' }}
        >
          Actions
          {open ? <ExpandLess /> : <ExpandMore />}
        </Button>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          style={{ zIndex: '99' }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps} // eslint-disable-line
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
                zIndex: '99'
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={() => setOpen(false)}>
                  <MenuList
                    autoFocusItem={open}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                  >
                    {data.status === 2 ? (
                      <MenuItem onClick={() => setRefundInvoice(true)}>
                        Refund total invoice &nbsp;{' '}
                        <GetPrice value={data?.final_amount} />
                      </MenuItem>
                    ) : null}

                    {data.status !== 7 && data.status !== 8 && (
                      <MenuItem onClick={() => setEmailInvoice(true)}>
                        Email Invoice
                      </MenuItem>
                    )}

                    {(data.status === 8 || data.status === 7) && (
                      <MenuItem onClick={() => setVoidInvoice(true)}>
                        Void Invoice
                      </MenuItem>
                    )}

                    {data.status === 7 && (
                      <MenuItem onClick={() => setPayInvoiceDialog(true)}>
                        Pay invoice
                      </MenuItem>
                    )}

                    {data.status === 8 && (
                      <MenuItem onClick={() => handlePaymentLink(data.id)}>
                        Resend Payment Link
                      </MenuItem>
                    )}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        {RefundInvoice && (
          <RefundInvoiceDialog
            setOpen={RefundInvoice}
            id={data?.id}
            amount={data?.final_amount}
            handleClose={() => setRefundInvoice(false)}
          />
        )}
        {EmailInvoice && (
          <SendInvoice
            setOpen={EmailInvoice}
            handleClose={() => setEmailInvoice(false)}
            invoicenum={data?.id}
            email={data?.get_user_name?.email}
            invoicedatastatus={data.status}
          />
        )}

        {VoidInvoice && (
          <VoidInvoiceDialog
            setOpen={VoidInvoice}
            handleClose={() => setVoidInvoice(false)}
            invoiceId={data.id}
            updateData={(val) => updateData(val)}
          />
        )}

        {PayInvoiceDialog && (
          <PayInvoiceDiag
            setOpen={PayInvoiceDialog}
            handleClose={() => setPayInvoiceDialog(false)}
            data={data}
          />
        )}
      </div>
    </div>
  );
};

Actions.propTypes = {
  data: PropType.object.isRequired,
  updateData: PropType.func.isRequired
};

export default Actions;
