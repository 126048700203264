import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  withStyles,
  makeStyles,
  ButtonGroup,
  Tooltip,
  Slider,
  Button,
  SvgIcon
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 300 + theme.spacing(3) * 2
  },
  margin: {
    height: theme.spacing(3)
  },
  customizedslider: {
    position: 'fixed',
    left: '0',
    bottom: '14px',
    zIndex: '999',
    background: 'white',
    width: '258px',
    padding: '9.5px',
    margin: '15px 15px 0',
    boxShadow: '0px 4px 16px rgba(37, 40, 43, 0.08)',
    borderRadius: '5px'
  }
}));

function ValueLabelComponent(props) {
  const { children, open, value } = props;

  return (
    <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
}

ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.number.isRequired
};

const PrettoSlider = withStyles({
  root: {
    color: '#EAEAEA',
    // color: 'primary',
    height: 15,
    // width: '187px'
    width: 'calc(100% - 20px)'
  },
  thumb: {
    height: 30,
    width: 30,
    backgroundColor: '#37847a',
    border: '2px solid #37847a',
    marginTop: -8,
    marginLeft: -14.5,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit'
    }
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)'
  },
  track: {
    height: 15,
    borderRadius: 15
  },
  rail: {
    height: 15,
    borderRadius: 15,
    opacity: 1
  }
})(Slider);

const CustomizedSlider = memo(({ setFont, fontSize }) => {
  const classes = useStyles();

  const handleIncrease = () => {
    setFont((prev) => prev + 1);
  };
  return (
    <div className={classes.root}>
      <ButtonGroup
        className={classes.customizedslider}
        color="primary"
        disableFocusRipple
        disableElevation
        disableRipple
      >
        <Button
          aria-label="reduce"
          color="primary"
          disabled={fontSize <= 12}
          style={{
            border: '0px solid rgba(0, 0, 0, 0.23)',
            // padding: '5px 5px',
            margin: '0 5px'
          }}
          onClick={() => setFont((prev) => prev - 1)}
        >
          <SvgIcon component={RemoveIcon} fontSize="small" />
          {/* <RemoveIcon fontSize="small" /> */}
        </Button>
        <PrettoSlider
          // valueLabelDisplay="auto"
          component="div"
          aria-label="pretto slider"
          value={fontSize}
          min={12}
          onChange={(_, val) => setFont(val)}
          max={80}
        />
        <Button
          aria-label="increase"
          color="primary"
          disabled={fontSize >= 80}
          style={{
            border: '0px solid rgba(0, 0, 0, 0.23)',
            // padding: '5px 5px',
            margin: '0 5px'
          }}
          onClick={handleIncrease}
        >
          {/* <AddIcon fontSize="small" fullWidth={false} /> */}
          <SvgIcon component={AddIcon} fontSize="small" />
        </Button>
      </ButtonGroup>
    </div>
  );
});

CustomizedSlider.propTypes = {
  setFont: PropTypes.func.isRequired,
  fontSize: PropTypes.number.isRequired
};

export default CustomizedSlider;
