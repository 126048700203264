import React, { memo } from 'react';
import PropType from 'prop-types';
import { Link } from 'react-router-dom';
import { ROUTES } from 'src/constant/Constants';
import {
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  TableSortLabel,
  Table,
  makeStyles
} from '@material-ui/core';
import CheckIcon from 'src/assets/image/check.png';

const useStyles = makeStyles({
  table: {
    minWidth: '100%'
  },
  tableLink: {
    color: '#01847A',
    fontWeight: '700'
  },
  TableCell: {
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px',
    height: '56px',
    minHeight: '56px'
  },
  Tablemian: {
    backgroundColor: 'transparent',
    borderRadius: '0px',
    '& .MuiTableCell-head': {
      color: '#FFF'
    },
    '& .MuiTableSortLabel-root:hover': {
      color: '#01847A'
    },
    '& .MuiTableSortLabel-root.MuiTableSortLabel-active': {
      color: '#ffffff'
    },
    '& .MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon': {
      color: '#ffffff'
    },
    '& .MuiTableCell-body': {
      color: '#FFF'
    },
    '& .MuiTableBody-root .MuiTableCell-root': {
      borderBottom: '1px solid rgba(234, 234, 234, 0.2)'
    }
  },
  button_status_deac: {
    fontWeight: 'bold',
    fontSize: '11px',
    color: '#01847A',
    border: '1px solid rgba(1, 132, 122, 1)',
    width: '100px',
    height: '24px',
    padding: '0 0',
    borderRadius: '2px'
  },
  button_status_act: {
    fontWeight: 'bold',
    fontSize: '11px',
    color: '#9B9B9B',
    border: '1px solid #949494',
    width: '100px',
    height: '24px',
    padding: '0 0',
    borderRadius: '2px',
    '&$hover': {
      backgroundColor: '#1976d2'
    }
  }
});

const PaymentBillingTable = (props) => {
  const classes = useStyles();
  const { values, direction, setSortName, setOrder, isActive } = props;

  return (
    <TableContainer
      component={Paper}
      elevation={0}
      className={classes.Tablemian}
    >
      <Table className={classes.table} aria-label="simple table">
        <TableHead
          style={{ background: '#3B3C4E', backgroundColor: '#3B3C4E' }}
        >
          <TableRow>
            <TableCell className={classes.TableCell}>
              <TableSortLabel
                active={isActive('invoice_id')}
                onClick={() => {
                  setSortName('invoice_id');
                  setOrder(() => (direction === 'asc' ? 'desc' : 'asc'));
                }}
                direction={direction}
              >
                Invoice
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell}>Payment method</TableCell>
            <TableCell className={classes.TableCell}>Status</TableCell>
            <TableCell className={classes.TableCell} align="center">
              Auto bill
            </TableCell>
            <TableCell className={classes.TableCell}>
              <TableSortLabel
                active={isActive('final_subtotal')}
                onClick={() => {
                  setSortName('final_subtotal');
                  setOrder(() => (direction === 'asc' ? 'desc' : 'asc'));
                }}
                direction={direction}
              >
                Final charge
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell} align="right">
              <TableSortLabel
                active={isActive('due_date')}
                onClick={() => {
                  setSortName('due_date');
                  setOrder(() => (direction === 'asc' ? 'desc' : 'asc'));
                }}
                direction={direction}
              >
                Payment due
              </TableSortLabel>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {values.map((row, i) => (
            <TableRow
              hover
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              style={{
                backgroundColor: '#26273B'
              }}
            >
              <TableCell
                style={{
                  width: '5%'
                }}
                className={classes.tableLink}
              >
                <Link
                  className={classes.tableLink}
                  to={{
                    pathname:
                      row.status === 1 || row.status === 3
                        ? ROUTES.MASTER_HQ_FINANCIAL_INVOICES_VIEW
                        : ROUTES.MASTER_HQ_FINANCIAL_INVOICES_PAIND_VIEW,
                    search: `?Invoice=${btoa(row.id)}`
                  }}
                >
                  {String(row.id)?.padStart(7, '0')}
                </Link>
              </TableCell>
              <TableCell
                style={{
                  width: '25%'
                }}
              >
                {row.payment_method_name}
              </TableCell>
              <TableCell
                style={{
                  width: '7%'
                }}
              >
                {row.invoice_status}
              </TableCell>
              <TableCell
                align="center"
                style={{
                  width: '7%'
                }}
              >
                {row.auto_bill === 1 ? <img src={CheckIcon} alt="img" /> : null}
              </TableCell>
              <TableCell
                style={{
                  width: '7%'
                }}
              >
                {row.final_amount}
              </TableCell>
              <TableCell
                align="right"
                style={{
                  width: '10%'
                }}
              >
                {row.due_date_on}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
PaymentBillingTable.defaultProps = {
  direction: 'asc'
};
PaymentBillingTable.propTypes = {
  values: PropType.array.isRequired,
  direction: PropType.string,
  setOrder: PropType.func.isRequired,
  setSortName: PropType.func.isRequired,
  isActive: PropType.func.isRequired
};

export default memo(PaymentBillingTable);
