import React, { lazy, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import Loadable from 'src/hocComponents/Loadable';

const PeopleSettings = Loadable(
  lazy(() => import('../views/people/Settings/PeopleSettings'))
);
const LeadsContainer = Loadable(lazy(() => import('./LeadContainer')));
const MembersContainer = Loadable(lazy(() => import('./MembersContainer')));

class PeopleContainer extends PureComponent {
  render() {
    const {
      match: { url }
    } = this.props;

    return (
      <Switch>
        <Route
          path={[`${url}/leads`, `${url}/lead`]}
          component={LeadsContainer}
        />
        <Route
          path={[`${url}/members`, `${url}/member`, `${url}/membership`]}
          component={MembersContainer}
        />
        <Route path={`${url}/setting`} component={PeopleSettings} />
      </Switch>
    );
  }
}

PeopleContainer.propTypes = {
  match: PropTypes.object.isRequired
};

export default PeopleContainer;
