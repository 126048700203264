/* eslint-disable operator-linebreak */
import React from 'react';
import PropType from 'prop-types';
import { Link } from 'react-router-dom';
import { ROUTES } from 'src/constant/Constants';
import {
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  withStyles,
  TableSortLabel
} from '@material-ui/core';
import filterType from 'src/Redux/Reducer.constants';
import { ReactComponent as Upicon } from 'src/assets/image/up.svg';
import { ReactComponent as Downicon } from 'src/assets/image/down.svg';
import { ReactComponent as Closeicon } from 'src/assets/image/closex.svg';
import { ReactComponent as MinusIcon } from 'src/assets/image/minusIcon.svg';
import { ReactComponent as QuestionIcon } from 'src/assets/image/questionIcon.svg';
import { ReactComponent as Infoicon } from 'src/assets/image/infoicon.svg';
import CheckIcon from 'src/assets/image/check.png';

const styles = () => ({
  table: {
    minWidth: '100%',
    wordBreak: 'break-word',
    '& td': {
      padding: '15px'
    },
    '@media (max-width: 1366px)': {
      '& td': {
        fontSize: '13px',
        padding: '13px'
      }
    }
  },
  tableLink: {
    color: '#01847A',
    fontWeight: '700'
  },
  TableCell: {
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px',
    height: '56px',
    minHeight: '56px',
    padding: '15px',
    '@media (max-width: 1366px)': {
      fontSize: '13px',
      wordBreak: 'keep-all',
      padding: '13px'
    }
  },
  Tablemian: {}
});

const AttendanceTrendTable = (props) => {
  const { values, classes, isActive, setDirection, setSort } = props;

  const {
    sort_members,
    sort_gym_name,
    sort_lastfourweek,
    sort_percentage_change,
    sort_trend
  } = filterType;

  return (
    <TableContainer
      component={Paper}
      elevation={0}
      className={classes.Tablemian}
    >
      <Table className={classes.table} aria-label="simple table">
        <TableHead
          style={{ background: '#F9F9FA', backgroundColor: '#F9F9FA' }}
        >
          <TableRow>
            <TableCell className={classes.TableCell}>
              <TableSortLabel
                active={isActive(sort_members)}
                direction={setDirection(sort_members)}
                onClick={() => setSort(sort_members)}
              >
                Member
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell}>
              <TableSortLabel
                active={isActive(sort_gym_name)}
                direction={setDirection(sort_gym_name)}
                onClick={() => setSort(sort_gym_name)}
              >
                Default location
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell} align="right">
              <TableSortLabel
                active={isActive(sort_lastfourweek)}
                direction={setDirection(sort_lastfourweek)}
                onClick={() => setSort(sort_lastfourweek)}
              >
                Average Class/Week
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell} align="right">
              <TableSortLabel
                active={isActive(sort_percentage_change)}
                direction={setDirection(sort_percentage_change)}
                onClick={() => setSort(sort_percentage_change)}
              >
                Percent change
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell} align="center">
              <TableSortLabel
                active={isActive(sort_trend)}
                direction={setDirection(sort_trend)}
                onClick={() => setSort(sort_trend)}
              >
                Trend
              </TableSortLabel>
              <Infoicon
                style={{
                  verticalAlign: 'inherit',
                  marginLeft: '5px'
                }}
              />
            </TableCell>
            <TableCell className={classes.TableCell} align="center">
              Is active
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {values.map((row) => (
            <TableRow
              hover
              tabIndex={-1}
              key={row.id}
              style={{
                backgroundColor: '#FFF'
              }}
            >
              <TableCell
                style={{
                  width: '9.0909%'
                }}
              >
                <Link
                  className={classes.tableLink}
                  to={{
                    pathname: ROUTES.PEOPLE_MEMBER_PROFILE,
                    search: `memberId=${btoa(row.user_id)}`
                  }}
                >
                  {row.member}
                </Link>
              </TableCell>
              <TableCell
                style={{
                  width: '9.0909%'
                }}
              >
                {row.gym_name}
              </TableCell>
              <TableCell
                style={{
                  width: '9.0909%'
                }}
                align="right"
              >
                {row.lastfourweek}
              </TableCell>
              <TableCell
                style={{
                  width: '9.0909%'
                }}
                align="right"
              >
                {row.percentage_change &&
                  parseFloat(row.percentage_change).toFixed(2)}
              </TableCell>
              <TableCell
                style={{
                  width: '6%'
                }}
                align="center"
              >
                {/* {row.trend_logo === 'New Member' && <Upicon />} */}
                {(() => {
                  switch (row.trend_logo) {
                    case 'minus':
                      return <MinusIcon />;
                    case 'up_arrow':
                      return <Upicon />;
                    case 'down_arrow':
                      return <Downicon />;
                    case 'question_mark':
                      return <QuestionIcon />;
                    case 'multiply':
                      return <Closeicon />;
                    default:
                      return <MinusIcon />;
                  }
                })()}
              </TableCell>
              <TableCell
                style={{
                  width: '6%'
                }}
                align="center"
              >
                {row.user_status === 1 && <img src={CheckIcon} alt="img" />}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {values.length === 0 && (
        <p
          style={{
            margin: '10px 15px'
          }}
        >
          No Member Attendance trend to show...
        </p>
      )}
    </TableContainer>
  );
};
AttendanceTrendTable.propTypes = {
  values: PropType.array.isRequired,
  classes: PropType.object.isRequired,
  isActive: PropType.func.isRequired,
  setDirection: PropType.func.isRequired,
  setSort: PropType.func.isRequired
};

export default withStyles(styles)(AttendanceTrendTable);
