/* eslint-disable arrow-parens */
import React, { PureComponent } from 'react';
import { ROUTES } from 'src/constant/Constants';
import PropTypes from 'prop-types';
import { Link, Route, Switch } from 'react-router-dom';
import {
  Grid,
  withStyles,
  AppBar,
  Tabs,
  Tab,
  Card,
  Breadcrumbs,
  Typography
} from '@material-ui/core';
import styles from './automatedemails.style';
import General from './General/General';
import Classes from './Classes/Classes';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}
class Automatedemails extends PureComponent {
  render() {
    const { classes, location } = this.props;
    const Tabslocation = location.pathname === ROUTES.COMMUNICATION_AUTOMATED_EMAILS_CLASSES
      ? 1
      : 0;
    return (
      <div className={classes.root}>
        <div className="title-bk">
          <div style={{ marginBottom: '20px' }}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={0}
            >
              <Grid item xs={12}>
                <Breadcrumbs aria-label="breadcrumb">
                  <Link
                    color="inherit"
                    to={ROUTES.COMMUNICATION_AUTOMATED_EMAILS}
                  >
                    communication
                  </Link>
                  <Link
                    color="inherit"
                    to={ROUTES.COMMUNICATION_AUTOMATED_EMAILS}
                  >
                    Automated emails
                  </Link>
                  <Typography variant="h3" color="Primary">
                    {!Tabslocation ? (
                      <span>general</span>
                    ) : (
                      <span>Classes</span>
                    )}
                  </Typography>
                </Breadcrumbs>
              </Grid>
            </Grid>
          </div>
        </div>

        <Card className={classes.mainCard}>
          <AppBar
            position="static"
            style={{
              backgroundColor: 'transparent',
              boxShadow: 'none'
            }}
          >
            <Tabs
              value={Tabslocation}
              indicatorColor="primary"
              textColor="primary"
              fullWidth
            >
              <Tab
                label="General"
                component={Link}
                to={ROUTES.COMMUNICATION_AUTOMATED_EMAILS}
                className={classes.p_tabs}
                {...a11yProps(0)} // eslint-disable-line
              />
              <Tab
                label="Classes"
                component={Link}
                to={ROUTES.COMMUNICATION_AUTOMATED_EMAILS_CLASSES}
                className={classes.p_tabs}
                {...a11yProps(1)} // eslint-disable-line
              />
            </Tabs>
          </AppBar>

          <Switch>
            <Route
              path={ROUTES.COMMUNICATION_AUTOMATED_EMAILS_CLASSES}
              component={Classes}
            />
            <Route
              path={ROUTES.COMMUNICATION_AUTOMATED_EMAILS}
              component={General}
            />
          </Switch>
        </Card>
      </div>
    );
  }
}

Automatedemails.propTypes = {
  classes: PropTypes.object.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  location: PropTypes.any.isRequired,
  pathname: PropTypes.any.isRequired
};

export default withStyles(styles, { withTheme: true })(Automatedemails);
