import { getReducerData } from 'src/Redux/reducer.common.utils';
import filterList from 'src/Redux/Reducers.utils';
import initialState from './financial.reducer.initialState';
import {
  MASTER_INVOICE,
  ACTION_TYPES,
  MASTER_INVOICE_FIL,
  INVOICE_SETTINGS
} from './financial.reducer.types';

export default function financialReducer(state = initialState, action) {
  const { type, payload, actionType, filter } = action;

  switch (type) {
    case MASTER_INVOICE:
      return {
        ...state,
        masterInvoices: getReducerData(
          actionType,
          state.masterInvoices,
          payload,
          ACTION_TYPES
        )
      };
    case INVOICE_SETTINGS:
      return {
        ...state,
        Settings: getReducerData(
          actionType,
          state.Settings,
          payload,
          ACTION_TYPES
        )
      };
    case MASTER_INVOICE_FIL:
      return {
        ...state,
        masterInvoiceFilter: filterList(
          state.masterInvoiceFilter,
          filter,
          payload
        )
      };
    case ACTION_TYPES.setAreAllSelected:
      return {
        ...state,
        areAllSelected: action.payload
      };
    default:
      return state;
  }
}
