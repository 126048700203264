import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import programReducer from './programReducers/program.reducer';
import wodReducer from './WODReducers/wod.reducer';
import classReducer from './ClassReducer/classReducer';
import globalReducer from './global/global.reducers';
import memberReducer from './MemberReducer/memberReducer';
import calenderReducer from './CalenderReducer/Calender.reducer';
import membershipReducer from './MembershipReducer/Membership.reducer';
import SettingReducer from './SettingReducer/Setting.reducer';
import ContractReducer from './ContractReducer/ContractReducer';
import WaiverReducer from './WaiverReducer/WaiverReducer';
import InvoiceReducer from './InvoiceReducer/InvoiceReducer';
import LeadReducer from './LeadReducer/leadReducer';
import reportsReducer from './ReportsReducer/reports.reducer';
import masterHqReducer from './MasterHQ/rootmasterhq.reducer';
import GymSetupReducer from './GymSetupReducer/GymSetup.reducer';
import { MASTERHQ_REDUCER } from './MasterHQ/masterhq.constants';

const reducers = combineReducers({
  allprograms: programReducer,
  wodList: wodReducer,
  classReducer,
  globalReducer,
  memberReducer,
  calenderReducer,
  membershipReducer,
  SettingReducer,
  ContractReducer,
  InvoiceReducer,
  WaiverReducer,
  LeadReducer,
  reportsReducer,
  GymSetupReducer,
  [MASTERHQ_REDUCER]: masterHqReducer
});

const persistconfig = {
  key: 'root',
  storage,
  whitelist: ['SettingReducer', 'allprograms', 'GymSetupReducer']
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistreduce = persistReducer(persistconfig, reducers);

let middlewares = applyMiddleware(thunk);

if (process.env.NODE_ENV === 'development') {
  middlewares = composeEnhancers(applyMiddleware(logger, thunk));
}

export const store = createStore(persistreduce, middlewares);
export const persistor = persistStore(store);
