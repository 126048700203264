/* eslint-disable operator-linebreak */
import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useLocation } from 'react-router-dom';
import { get } from 'js-cookie';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Button } from '@material-ui/core';
import { setHidden } from 'src/Redux/GymSetupReducer/GymSetup.actions';
import WidgetsIcon from '@material-ui/icons/Widgets';
import ClearIcon from '@material-ui/icons/Clear';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import ProcessAccordion from './processAccordion';

const useStyles = makeStyles({
  list: {
    width: 360
  },
  fullList: {
    width: 'auto'
  },
  GymSetUp: {
    right: '40px',
    position: 'absolute',
    bottom: '15px',
    zIndex: '9'
  },
  headInfo: {
    color: '#fff',
    padding: '15px',
    background: '#01847A',
    minHeight: '215px',
    '& h4': {
      padding: '10px 0',
      fontSize: '20px'
    }
  },
  headProgress: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    margin: '10px auto'
  },
  Progresslabel: {
    fontSize: '13px',
    margin: '0 5px 0 0'
  },
  stepInfo: {
    color: '#fff',
    width: '190px',
    display: 'flex',
    padding: '10px 15px',
    background: '#27847a',
    borderRadius: '30px',
    fontSize: '13px',
    alignItems: 'center',
    cursor: 'pointer'
  },
  StepCompleteInfo: {},
  stepName: {
    fontWeight: '800'
  },
  closebtns: {
    position: 'absolute',
    right: '15px',
    top: '5px',
    zIndex: 2
  },
  hidebtns: {
    position: 'absolute',
    right: '15px',
    bottom: '5px'
  }
});

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    border: '2px solid #eeeeee',
    height: '15px',
    borderRadius: '10px',
    margin: '15px 0',
    width: '100%'
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === 'light' ? 200 : 700]
  },
  bar: {
    borderRadius: 10,
    backgroundColor: '#01847A'
  }
}))(LinearProgress);

export default function GymSetUpDrawer() {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const dispatch = useDispatch();
  const location = useLocation();
  const first_name = get('first_name');
  const jbrole = get('jbrole');
  const { data, isHidden } = useSelector((state) => state.GymSetupReducer);

  const toggleDrawer = (event) => {
    if (
      // eslint-disable-next-line operator-linebreak
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setOpen(!open);
  };

  const onClickHide = () => {
    dispatch(setHidden(true));
  };

  return (
    <div>
      {!data || isHidden ? null : location.pathname === '/login' ||
        location.pathname === '/link' ||
        location.pathname === '/chalboard-full-screen' ||
        window.location.pathname.includes('vanguard') ||
        JSON.parse(jbrole || '{}')?.role_id !== 1 ||
        data.total_percentage === 100 ? null : (
        // eslint-disable-next-line react/jsx-indent
        <React.Fragment>
          <div className={classes.GymSetUp}>
            <div onClick={toggleDrawer} className={classes.stepInfo}>
              <div>
                <WidgetsIcon />
              </div>
              <Box
                css={{
                  marginLeft: '10px'
                }}
              >
                <label className={classes.stepName}>Basic Info</label>
                <div className={classes.StepCompleteInfo}>
                  {
                    Object.values(data?.process_type).filter(
                      (process) => process > 0
                    ).length
                  }{' '}
                  of 9 completed
                </div>
              </Box>
            </div>
          </div>
          <Drawer anchor="right" open={open} onClose={toggleDrawer}>
            <div role="presentation" className={clsx(classes.list)}>
              <Button className={classes.closebtns} onClick={toggleDrawer}>
                <ClearIcon fontSize="large" htmlColor="#fff" />
              </Button>
              <Button className={classes.hidebtns} onClick={onClickHide}>
                <RemoveCircleIcon fontSize="large" htmlColor="#01847A" />
              </Button>
              <div className={classes.headInfo}>
                <h4>Welcome, {first_name || 'User'}</h4>
                <p>Knock off the steps below to get your biz up and running:</p>
                <div className={classes.headProgress}>
                  <label className={classes.Progresslabel}>
                    {data?.total_percentage}%
                  </label>
                  <BorderLinearProgress
                    variant="determinate"
                    value={data?.total_percentage}
                  />
                </div>
              </div>
              <ProcessAccordion
                data={data.process_type}
                handleClose={() => setOpen(false)}
              />
            </div>
          </Drawer>
        </React.Fragment>
        // eslint-disable-next-line indent
      )}
    </div>
  );
}
