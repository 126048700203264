import React from 'react';
import { connect } from 'react-redux';
import {
  withStyles,
  Grid,
  Typography,
  Box,
  ThemeProvider,
  Button
} from '@material-ui/core';
import { FavReport } from 'src/services/reports.services';
import { Link } from 'react-router-dom';
import LoadingView from 'src/views/common/loading';
import { ROUTES } from 'src/constant/Constants';
import PropType from 'prop-types';
import { getReportsListData } from 'src/Redux/ReportsReducer/reports.reducer.actions';
import StarBorderSharpIcon from '@material-ui/icons/StarBorderSharp';
import StarIcon from '@material-ui/icons/Star';
import useChangeTitle from 'src/views/common/CustomHooks/useTitleChange';
import styles from './MembersReports.style';
import { openNoti } from '../../../Redux/global/global.actions';

const themeInstance = {
  overrides: {
    MuiRadio: {
      colorPrimary: {
        color: '#949494',
        padding: '5px',
        '&$checked': {
          color: '#FFC700'
        }
      },
      checked: {}
    }
  }
};

const MembersReports = (props) => {
  const { classes, getReportMembersList, OpenNotify, getReportsList } = props;
  useChangeTitle('Members Reports | Jungle Alliance');
  const handleChange = (id, is_starred) => {
    const formData = new FormData();
    formData.append('report_id', id);
    formData.append('is_starred', is_starred);
    FavReport(formData)
      .then(() => {
        const stared = is_starred === 0 ? 'Unstar' : 'Star';
        OpenNotify(`Reports "${stared}" successfully`);
        getReportsList();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <Box mt="15px">
      <ThemeProvider theme={themeInstance}>
        <Grid container direction="row" spacing={0}>
          <Grid container direction="row" spacing={2}>
            {getReportMembersList ? (
              getReportMembersList.map((row) => (
                <Grid item xs={6} key={row.id}>
                  <Link to={ROUTES.REPORTS + '/' + row.report_url}>
                    <Box className={classes.wrapperBox}>
                      <Box className={classes.wraBoxTitle}>
                        <Grid container direction="row">
                          <Grid item xs={11}>
                            <Typography
                              variant="h5"
                              component="h5"
                              style={{ color: '#292929' }}
                            >
                              {row.report_title}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={1}
                            justify="flex-end"
                            className="MuiGrid-container"
                          >
                            <Box>
                              {row.is_starred ? (
                                <Button
                                  onClick={(e) => {
                                    e.preventDefault();
                                  }}
                                  style={{
                                    minWidth: 'auto'
                                  }}
                                >
                                  <StarIcon
                                    htmlColor="#FFC700"
                                    onClick={() => {
                                      // eslint-disable-next-line operator-linebreak
                                      const stared =
                                        row.is_starred === 1 ? 0 : 1;
                                      handleChange(row.id, stared);
                                    }}
                                  />
                                </Button>
                              ) : (
                                <Button
                                  onClick={(e) => {
                                    e.preventDefault();
                                  }}
                                  style={{
                                    minWidth: 'auto'
                                  }}
                                >
                                  <StarBorderSharpIcon
                                    htmlColor="#949494"
                                    onClick={() => {
                                      // eslint-disable-next-line operator-linebreak
                                      const stared =
                                        row.is_starred === 1 ? 0 : 1;
                                      handleChange(row.id, stared);
                                    }}
                                  />
                                </Button>
                              )}
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                      <Box>
                        <p className={classes.ptext}>
                          {row.report_description}
                        </p>
                      </Box>
                    </Box>
                  </Link>
                </Grid>
              ))
            ) : (
              <LoadingView />
            )}
          </Grid>
        </Grid>
      </ThemeProvider>
    </Box>
  );
};

MembersReports.propTypes = {
  classes: PropType.object.isRequired,
  OpenNotify: PropType.func.isRequired,
  getReportMembersList: PropType.func.isRequired,
  getReportsList: PropType.func.isRequired
};

const mapToState = (state) => ({
  getReportMembersList: state.reportsReducer.reportList?.data?.members_report
});

const mapToDispatch = (dispatch) => ({
  OpenNotify: (msg, type) => dispatch(openNoti(msg, type)),
  getReportsList: (data) => dispatch(getReportsListData(data))
});

export default connect(
  mapToState,
  mapToDispatch
)(withStyles(styles)(MembersReports));
