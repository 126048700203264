const styles = ({ theme }) => ({
  root: {
    width: '100%'
  },
  paper: {
    width: '100%'
  },
  table: {
    minWidth: 750,
    border: '1px solid #A8A8A8',
    borderRadius: '5px'
  },
  table_th: {
    backgroundColor: '#F9F9FA',
    color: '#292929'
  },
  TableCell: {
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px',
    height: '56px',
    minHeight: '56px',
    borderBottom: '1px solid #A8A8A8',
    backgroundColor: '#F9F9FA'
  },
  Table_td: {
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '22px',
    position: 'relative',
    padding: '12px 15px 13px 18px',
    height: '56px',
    minHeight: '56px'
  },
  Table_td_link: {
    color: '#01847A',
    fontWeight: '700'
  },
  actionbtn: {
    padding: '6px 5px',
    minWidth: 'auto',
    margin: '0 5px',
    textAlign: 'center',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  Tablemian: {
    maxHeight: '350px'
  }
});
export default styles;
