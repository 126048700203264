// eslint-disable-next-line import/prefer-default-export
export const DateFilteroption = [
  { id: 11, filter: 'custom', name: 'Custom' },
  { id: 3, filter: 'last30day', name: 'Last 30 Days' },
  { id: 4, filter: 'last60day', name: 'Last 60 Days' },
  { id: 6, filter: 'lastmonth', name: 'Last Month' },
  { id: 7, filter: 'lastyear', name: 'Last Year' },
  { id: 2, filter: 'thismonth', name: 'This Month to Date' },
  { id: 8, filter: 'thisyear', name: 'This Year to Date' }
];
