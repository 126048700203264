import React from 'react';
import {
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  withStyles,
  Grid,
} from '@material-ui/core';
import PropType from 'prop-types';
import GetPrice from 'src/views/common/CurrencySymbol/GetPrice';
import CheckIcon from '../../../../../assets/image/check.png';
import styles from './table.style';
import { getFinalAmount } from './Pricing.utils';

function PricingTable({ classes, values, plan_id, discount }) {
  // const [Elder, setElder] = useState('Percentage');
  // const [Cub, setCub] = useState('Percentage');
  // const [Juvenile, setJuvenile] = useState('Fixed');

  // const [CubPerce, setCubPerce] = useState(0);
  // const [JuvenilePerce, setJuvenilePerce] = useState(0);
  // const [ElderPerce, setElderPerce] = useState(0);

  // const handleChange = (event) => {
  //   setElder(event.target.value);
  // };
  // const handleChangeCub = (event) => {
  //   setCub(event.target.value);
  // };
  // const handleChangeJuvenile = (event) => {
  //   setJuvenile(event.target.value);
  // };

  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      spacing={0}
      style={{ margin: '0px 0px 40px' }}
    >
      <TableContainer>
        <Table className={classes.table} aria-label="simple table">
          <TableHead className={classes.table_th}>
            <TableRow>
              <TableCell className={classes.TableCell} style={{ width: '15%' }}>
                Tier
              </TableCell>
              <TableCell
                className={classes.TableCell}
                align="center"
                style={{ width: '5%' }}
              >
                &nbsp;
              </TableCell>
              <TableCell className={classes.TableCell} style={{ width: '11%' }}>
                Active members
              </TableCell>
              <TableCell className={classes.TableCell} style={{ width: '10%' }}>
                Monthly
              </TableCell>
              <TableCell
                className={classes.TableCell}
                align="right"
                style={{ width: '10%' }}
              >
                Final Amount
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {values.affiliate_plans.map((item) => (
              <TableRow
                key={item.id}
                // eslint-disable-next-line eqeqeq
                className={item.id == plan_id ? classes.activePlan : null}
              >
                <TableCell className={classes.Table_td}>
                  {item.plan_name}
                </TableCell>
                <TableCell className={classes.Table_td}>
                  {// eslint-disable-next-line eqeqeq
                  item.id == plan_id ? <img src={CheckIcon} alt="img" /> : null}
                </TableCell>
                <TableCell className={classes.Table_td}>
                  {item.is_max_unlimited_flag
                    ? `${item.min_member}+`
                    : `${item.min_member}-${item.max_member}`}
                </TableCell>
                <TableCell className={classes.Table_td}>
                  <GetPrice value={item.monthly_price} />
                </TableCell>

                <TableCell className={classes.Table_td} align="right">
                  <GetPrice
                    value={getFinalAmount(item.monthly_price, discount)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}

PricingTable.propTypes = {
  classes: PropType.object.isRequired,
  values: PropType.array.isRequired
};

export default withStyles(styles)(PricingTable);
