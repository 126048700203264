import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Grid,
  FormControlLabel,
  InputLabel,
  Radio,
  RadioGroup,
  Paper,
  withStyles
} from '@material-ui/core';
import PropType from 'prop-types';
import IOSSwitch from 'src/views/common/FormControl/IOSSwitch';
import { checkValidNum, validateInt } from 'src/utils/validations';
import { getValuefromObject } from 'src/utils/someCommon';
import FormControl from 'src/views/common/FormControl/formControl';
import CustomAutoComplete from '../commonAutocomplete';
import styles from './Class.style';

const RepeatWeek = ({ classes, dataweek, getRepeatWeek, getAllCoaches }) => {
  const [Classlimit, setClasslimit] = useState(false);
  const [classLmtObj, setClassLmtObj] = useState({
    classlimit: 0,
    allowWaitlist: false,
    isOpen: null
  });
  const [common, setCommon] = useState({
    headCoach: null,
    CoCoach: null
  });
  useEffect(() => {
    const labels = dataweek.id;
    const updatedValue = {};
    updatedValue[labels] = {
      is_limit: Classlimit ? 1 : 0,
      allow_waitlist: classLmtObj.allowWaitlist ? 1 : 0
    };
    if (common.headCoach) {
      updatedValue[labels] = {
        ...updatedValue[labels],
        head_coach: getValuefromObject(common.headCoach)
      };
    }
    if (common.CoCoach) {
      updatedValue[labels] = {
        ...updatedValue[labels],
        co_coach: getValuefromObject(common.CoCoach)
      };
    }
    if (!Classlimit) {
      getRepeatWeek(updatedValue);
    } else {
      updatedValue[labels] = {
        ...updatedValue[labels],
        class_limit: classLmtObj.classlimit
      };
      if (classLmtObj.allowWaitlist && Classlimit) {
        updatedValue[labels] = {
          ...updatedValue[labels],
          class_open: classLmtObj.isOpen
        };
      }
      getRepeatWeek(updatedValue);
    }
  }, [classLmtObj, common, Classlimit, dataweek]); // eslint-disable-line

  return (
    <Grid item xs={12} style={{ marginTop: '30px' }}>
      <div className={classes.day_br}>
        <h3 style={{ marginBottom: '10px' }}>{dataweek.label}</h3>
      </div>
      <Grid
        item
        xs={12}
        className="switch-btn"
        style={{ marginBottom: '15px', marginTop: '15px' }}
      >
        <FormControlLabel
          control={
            <IOSSwitch
              checked={Classlimit}
              onChange={() => setClasslimit(!Classlimit)}
            />
          }
          label="Class limit/Reservation"
        />
      </Grid>
      {Classlimit && (
        <Grid xs={12} style={{ marginTop: '0' }}>
          <Grid item xs={4} style={{ padding: 0, margin: '0px' }}>
            <FormControl
              control="input"
              label="Class limit"
              value={classLmtObj.classlimit}
              required
              placeholder="Specify max amount of members"
              onChange={(e) =>
                setClassLmtObj({
                  ...classLmtObj,
                  classlimit: validateInt(e.target.value) // eslint-disable-line
                })
              } // eslint-disable-line
              style={{ margin: '0px 0' }}
              seterrors={checkValidNum(classLmtObj.classlimit)}
              seterrortext="Please put valid class limit"
            />
          </Grid>

          <Grid
            item
            xs={12}
            className="switch-btn"
            style={{
              display: 'flex',
              margin: '15px 0'
            }}
          >
            <FormControlLabel
              control={
                <IOSSwitch
                  checked={classLmtObj.allowWaitlist}
                  onChange={(e) =>
                    setClassLmtObj({
                      ...classLmtObj,
                      allowWaitlist: e.target.checked
                    })
                  } // eslint-disable-line
                  name="waitlist"
                />
              }
              label="Allow waitlist"
            />
          </Grid>
          {classLmtObj.allowWaitlist && (
            <div>
              <Grid
                item
                xs={12}
                style={{
                  display: 'flex',
                  margin: '10px 0'
                }}
              >
                <Paper elevation={0}>
                  <p className={classes.ptext}>
                    When a spot in the class opens, people on the waitlist are:
                  </p>
                </Paper>
              </Grid>

              <Grid
                item
                xs={12}
                style={{
                  display: 'flex',
                  margin: '10px 0'
                }}
              >
                <RadioGroup
                  aria-label="quiz"
                  name="quiz"
                  value={classLmtObj.isOpen}
                  onChange={(e) =>
                    setClassLmtObj({ ...classLmtObj, isOpen: e.target.value })
                  } // eslint-disable-line
                >
                  <FormControlLabel
                    value="0"
                    control={<Radio color="primary" />}
                    label="Automatically added to the class in the order they signed up."
                  />
                  <FormControlLabel
                    value="1"
                    control={<Radio color="primary" />}
                    label="All emailed, first to reply gets added to the class."
                  />
                </RadioGroup>
              </Grid>
            </div>
          )}
        </Grid>
      )}
      <Grid xs={12} style={{ marginTop: '0', display: 'flex' }}>
        <Grid item xs={4} style={{ marginTop: '0px', marginRight: '10px' }}>
          <InputLabel
            htmlFor="age-required"
            className={classes.cutom_select_Label}
            // required
          >
            Head coach
          </InputLabel>
          <CustomAutoComplete
            holder="Head Coach"
            value={
              common.CoCoach
                ? getAllCoaches.filter(
                    (list) => list.id !== common.CoCoach.id // eslint-disable-line
                  ) // eslint-disable-line
                : getAllCoaches
            }
            Options="name"
            data={common.headCoach}
            Change={(val) => setCommon({ ...common, headCoach: val })}
          />
        </Grid>
        <Grid item xs={4} style={{ marginTop: '0px' }}>
          <InputLabel
            htmlFor="age-required"
            className={classes.cutom_select_Label}
          >
            Co-Coach
          </InputLabel>
          <CustomAutoComplete
            holder="Select Co-Coach"
            value={
              common.headCoach
                ? getAllCoaches.filter(
                    (list) => list.id !== common.headCoach.id // eslint-disable-line
                  ) // eslint-disable-line
                : getAllCoaches
            }
            data={common.CoCoach}
            Options="name"
            Change={(val) => setCommon({ ...common, CoCoach: val })}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
RepeatWeek.propTypes = {
  classes: PropType.object.isRequired,
  dataweek: PropType.string.isRequired,
  getAllCoaches: PropType.array.isRequired,
  getRepeatWeek: PropType.func.isRequired
};

const mapToState = (state) => ({
  getAllCoaches: state.classReducer.coaches
});

export default connect(mapToState)(withStyles(styles)(RepeatWeek));
