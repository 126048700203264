import {
  addTagsWOD,
  exportWOD,
  removeTagsWOD
} from 'src/services/WODmodule.services';
import actionType from './bulActions.constants';

const getBulkAction = (type, module, data) => {
  switch (type) {
    case actionType.addTags:
      switch (module) {
        case 'class':
          return null;
        case 'WOD':
          return addTagsWOD(data[0], data[1]);
        default:
          return null;
      }
    case actionType.removeTags:
      switch (module) {
        case 'class':
          return null;
        case 'WOD':
          return removeTagsWOD(data[0], data[1]);
        default:
          return null;
      }
    case 'exportFile':
      switch (module) {
        case 'class':
          return null;
        case 'WOD':
          return exportWOD(data.join(','));
        default:
          return null;
      }
    default:
      return null;
  }
};

export default getBulkAction;
