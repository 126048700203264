import React from 'react';
import PropType from 'prop-types';

const CustomTime = (props) => {
  const { label, event } = props;
  return (
    <div
      style={{
        margin: '7.5px',
        position: 'sticky'
      }}
    >
      <div style={{ textDecoration: event.status === 0 && 'line-through' }}>
        {label}
      </div>
    </div>
  );
};
CustomTime.propTypes = {
  label: PropType.string.isRequired,
  event: PropType.object.isRequired
};
export default CustomTime;
