import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid, Box, withStyles } from '@material-ui/core';
import { getTempCont } from 'src/Redux/ContractReducer/ContractReducer.actions';
import filterType from 'src/Redux/Reducer.constants';
import Templatestable from './Templatestable';
import styles from './Documents.style';
import useChangeTitle from '../common/CustomHooks/useTitleChange';

const TemplateTab = (props) => {
  const { classes, errorState, ContFilter, ContSort } = props;
  useChangeTitle('Templates | Jungle Alliance');
  if (errorState) return <p>{errorState}</p>;

  const setDirection = (name) => {
    if (ContSort.sort_name === name) {
      return ContSort.sort_by === 'asc' ? 'asc' : 'dsc';
    }
    return 'asc';
  };

  const isActive = (sortlabel) => {
    if (ContSort.sort_name === sortlabel) return true;
    return false;
  };

  const setSort = (sortlabel) => {
    ContFilter(filterType.sorting, sortlabel);
  };
  return (
    <Box p="24px">
      <Grid
        container
        direction="row"
        justify="start"
        alignItems="center"
        spacing={0}
      >
        <Grid item xs={12} className={classes.SimpleTablestyle}>
          <Templatestable
            isActive={isActive}
            setSort={setSort}
            setDirection={setDirection}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
TemplateTab.propTypes = {
  classes: PropTypes.object.isRequired,
  errorState: PropTypes.string.isRequired,
  ContFilter: PropTypes.func.isRequired,
  ContSort: PropTypes.object.isRequired
};

const mapToState = (state) => ({
  errorState: state.ContractReducer.Templates.error,
  ContSort: state.ContractReducer.tempFilter.sorting
});

const mapToDispatch = (dispatch) => ({
  ContFilter: (filter, type) => dispatch(getTempCont(filter, type))
});

export default connect(
  mapToState,
  mapToDispatch
)(withStyles(styles)(TemplateTab));
