import React, { useEffect, useState } from 'react';
// import { VanguardMem } from 'src/Context/VanguardMembershipContext';
import { setMembership } from 'src/Context/VanguardMembership/VanguardMembership.actions';
import clsx from 'clsx';
import {
  makeStyles,
  Grid,
  RadioGroup,
  Radio,
  FormControlLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@material-ui/core';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckIcon from '@material-ui/icons/Check';
import PropType from 'prop-types';
import GetPrice from 'src/views/common/CurrencySymbol/GetPrice';
import { ReactComponent as PlusIcon } from '../../../assets/image/Vanguard/plus.svg';
import { ReactComponent as MinusIcon } from '../../../assets/image/Vanguard/minus.svg';

const useStyles = makeStyles(() => ({
  // custom Accordion
  customAccordion: {
    '& .MuiAccordion-root': {
      background: 'rgba(196, 196, 196, 0.05)',
      border: '2px solid #7B7B7B',
      borderRadius: '6px',
      margin: '0 0 12px'
    },
    '& .MuiAccordion-root.checked': {
      background: 'rgba(44, 233, 218, 0.15)',
      border: '2px solid #2CE9DA',
      borderRadius: '6px'
    },
    '& .MuiAccordion-root .MuiAccordionSummary-content': {
      margin: '3px 0',
      display: 'flex',
      alignItems: 'center',
      '& span': {
        color: '#fff',
        fontWeight: 'bold'
        // fontSize: '18px'
      }
    },
    '& .MuiAccordion-root .MuiAccordionSummary-content svg.MuiSvgIcon-root': {
      width: '30px',
      height: '30px',
      fill: 'none',
      borderRadius: '50%',
      border: '2px solid #4B4B4B'
    },
    '& .MuiAccordion-root.checked .MuiAccordionSummary-content svg.MuiSvgIcon-root': {
      background: '#67e9da',
      padding: '4px',
      fill: '#000',
      color: '#060606',
      border: '0px solid #4B4B4B'
    },
    '& .MuiRadio-root': {
      padding: '20px'
    },
    '& .MuiFormControlLabel-label': {
      color: '#fff',
      fontSize: '20px',
      lineHeight: '26px',
      fontWeight: 'bold'
    },
    '& .MuiCollapse-wrapperInner': {
      width: 'calc(100% - 75px)',
      margin: '0 0 0 auto',
      padding: '0',
      display: 'flex'
    },
    '& .MuiAccordionDetails-root': {
      padding: '0px 10px 20px 0px',
      flexDirection: 'column'
    }
  },
  AccordionDetails: {
    '& h6': {
      color: '#FFFFFF',
      fontSize: '14px',
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontWeight: 'bold',
      lineHeight: '26px'
    },
    '& h5': {
      color: 'rgb(255 255 255 / 60%)',
      fontSize: '20px',
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontWeight: 'bold',
      lineHeight: '32px',
      wordBreak: 'break-word'
    }
  },

  message: {
    color: 'rgb(255 255 255 / 60%)',
    fontSize: '20px',
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 'bold',
    lineHeight: '32px',
    wordBreak: 'break-word'
  }
}));

const VanguardAccordion = (props) => {
  const {
    values,
    reducer: [state, dispatch]
  } = props;

  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const { membership_id, membership_type } = state;
  // const [data, setData] = useState({
  //   value: membership_id
  // });
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const getMembership = (val) => {
    const mem = values.filter((i) => i.id === val);
    return mem[0];
  };
  useEffect(() => {
    setExpanded(null);
  }, []);
  // }, [membership_type]);

  return (
    <div>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        // spacing={2}
        // style={{ margin: '20px 0 20px' }}
      >
        <Grid xs={12}>
          <RadioGroup
            value={String(membership_id)}
            onChange={(e) => {
              // eslint-disable-next-line radix
              dispatch(
                setMembership(getMembership(parseInt(e.target.value, 10)))
              );
            }}
            className={classes.customAccordion}
          >
            {values
              && values.map((item) => (
                <Accordion
                  className={clsx(
                    `${
                      String(membership_id) === item.id.toString()
                        ? 'checked'
                        : ''
                    }`
                  )}
                  // expanded={expanded === 'panel'}
                  expanded={expanded === `panel${item.id}`}
                  // onChange={handleChange('panel')}

                  onChange={handleChange(`panel${item.id}`)}
                >
                  <AccordionSummary
                    // expandIcon={expanded === 'panel' ? <MinusIcon /> : <PlusIcon />}
                    expandIcon={
                      expanded === `panel${item.id}` ? (
                        <MinusIcon />
                      ) : (
                        <PlusIcon />
                      )
                    }
                    aria-label="Expand"
                    aria-controls="additional-actions1-content"
                    id="additional-actions1-header"
                    onClick={(event) => event.stopPropagation()}
                    onFocus={(event) => event.stopPropagation()}
                    className={`${
                      String(membership_id) === item.id.toString()
                        ? 'checked'
                        : ''
                    }`}
                    style={{
                      display: 'flex',
                      width: '100%'
                    }}
                  >
                    <FormControlLabel
                      value={item.id.toString()}
                      aria-label="Acknowledge"
                      onClick={(event) => event.stopPropagation()}
                      onFocus={(event) => event.stopPropagation()}
                      control={
                        <Radio
                          color="primary"
                          icon={
                            <RadioButtonUncheckedIcon
                              width="30px"
                              height="30px"
                            />
                          }
                          checkedIcon={
                            <CheckIcon
                              htmlColor="#2CE9DA"
                              width="30px"
                              height="30px"
                            />
                          }
                        />
                      }
                      label={item.name}
                      // label="hello"
                      className={`${
                        String(membership_id) === item.id.toString()
                          ? 'checked'
                          : ''
                      }`}
                      style={{
                        display: 'flex',
                        width: '100%'
                      }}
                    />
                    <GetPrice value={item.initial_price} />
                    {/* <h3 style={{ color: '#fff' }}>{item.initial_price}</h3> */}
                  </AccordionSummary>
                  <AccordionDetails className={classes.AccordionDetails}>
                    <h6>Programs</h6>
                    <h5
                      style={{
                        marginBottom: '20px'
                      }}
                    >
                      {/* check program */}
                      {item.programes.join()}
                    </h5>
                  </AccordionDetails>
                </Accordion>
              ))}
          </RadioGroup>
          {values?.length === 0 && (
            <h5 className={classes.message}>
              {membership_type === 0
                ? 'No membership plans available'
                : 'No membership sessions available'}
            </h5>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

VanguardAccordion.propTypes = {
  values: PropType.array.isRequired,
  reducer: PropType.array.isRequired
  // handleClose: PropType.func.isRequired
  // handleBack: PropType.func,
  // handleHistory: PropType.string
};

export default VanguardAccordion;
