/* eslint-disable operator-linebreak,indent */
import React, { memo, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { openNoti } from 'src/Redux/global/global.actions';
import { Box, Grid, Typography, withStyles } from '@material-ui/core';
// import LoadingView from 'src/views/common/loading';
import FormControl from 'src/views/common/FormControl/formControl';
import { getWebIntegrations, updateSettingsByModule } from 'src/services/Setting.services';
import SyncIcon from '@material-ui/icons/Sync';
// import Plusicon from '../../../assets/image/plusicon.png';
import JBButton from 'src/views/common/JBButton/JBButton';
import { getErrorMsg } from 'src/utils/ErrorHandling';
import useChangeTitle from 'src/views/common/CustomHooks/useTitleChange';
import isValidWeb from './WebIntegrations.validation';
import styles from '../GeneralInfo.style';
import WebDeleteDiaload from './WebDeleteDiaload';

const WebIntegrations = ({ classes, openNotify }) => {
  const [isProcessing, setProcessing] = useState(() => false);
  const [deleteDiag, setDelDiag] = useState(() => false);
  const [data, setData] = useState(() => ({
    api_title: '',
    api_key: '',
    api_url: '',
    is_registered: 0
  }));
  useChangeTitle('Web Integrations | Jungle Alliance');
  useEffect(() => {
    const localData = localStorage.getItem('webSynced');
    getWebIntegrations()
      .then((res) => {
        let values = res;
        if (localData) {
          values = {
            ...res,
            ...JSON.parse(localData)
          };
        }
        setData(() => values);
      })
      .catch((err) => openNotify(getErrorMsg(err), 'error'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getSynData = async () => {
      try {
        const { SyncWebStatus } = await import('src/services/gym.services');
        const values = await SyncWebStatus();
        const response = {
          ...data,
          is_registered: values.fully_synced,
          sync_data: {
            ...values,
            completed: Math.round(
              ((values.sync.synced + values.sync.unsynced) /
                values.sync.total_records) *
                100
            )
          }
        };
        setData(() => response);
        if (!values.fully_synced) {
          localStorage.setItem('webSynced', JSON.stringify(response));
        } else if (localStorage.getItem('webSynced')) {
          localStorage.removeItem('webSynced');
        }
      } catch (e) {
        console.log(e);
      }
    };
    if (data.sync_data && data.sync_data?.fully_synced === 0) {
      setTimeout(getSynData, 5000);
    }

    return () => {
      if (data.sync_data && data.sync_data?.fully_synced === 1) {
        localStorage.removeItem('webSynced');
      }
    };
    // eslint-disable-next-line
  }, [data.sync_data]);

  const handleSave = () => {
    const { error } = isValidWeb(data);
    if (error) {
      openNotify(error, 'error');
    } else {
      setProcessing(() => true);
      const val = {
        api_title: data.api_title,
        api_key: data.api_key,
        api_url: data.api_url
      };
      updateSettingsByModule('webintegration', val)
        .then((res) => {
          setData(() => ({ ...res }));
          openNotify('Save successfully.');
          setProcessing(() => false);
        })
        .catch((err) => {
          openNotify(getErrorMsg(err), 'error');
          setProcessing(() => false);
        });
    }
  };

  const handleSync = useCallback(async () => {
    setProcessing(() => true);
    try {
      const { SyncWebIntegration, SyncWebStatus } = await import(
        'src/services/gym.services'
      );
      const res = await SyncWebIntegration();
      if (res.success) {
        const values = await SyncWebStatus();
        const response = {
          ...data,
          is_registered: values.fully_synced,
          sync_data: {
            ...values,
            completed: Math.round(
              ((values.sync.synced + values.sync.unsynced) /
                values.sync.total_records) *
                100
            )
          }
        };
        setData(() => response);
        if (!values.fully_synced) {
          localStorage.setItem('webSynced', JSON.stringify(response));
        } else if (localStorage.getItem('webSynced')) {
          localStorage.removeItem('webSynced');
        }
        setProcessing(() => false);
      }
    } catch (err) {
      openNotify(getErrorMsg(err), 'error');
      setProcessing(() => false);
    }
  }, [data, openNotify]);

  const handleDelete = useCallback(async () => {
    setProcessing(() => true);
    const { destroyWebInt } = await import('src/services/gym.services');
    destroyWebInt()
      .then((res) => {
        setData(() => ({ ...res }));
        setProcessing(() => false);
        setDelDiag(() => false);
      })
      .catch((err) => {
        openNotify(getErrorMsg(err), 'error');
        setProcessing(() => false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Grid
        container
        direction="row"
        spacing={0}
        style={{ margin: '10px 0 0px' }}
      >
        <Grid item xs={8}>
          <Box mt="15px" mb="15px">
            <Typography
              color="#000"
              variant="h5"
              component="div"
              className={classes.bktitle}
            >
              API Keys
            </Typography>
            <p className={classes.ptext}>
              Use API keys to integrate with your website or apps like Zapier.
              We recommend you use a different key for each integration.
            </p>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Grid container direction="row" spacing={2} alignItems="center">
            <Grid item xs>
              <FormControl
                control="input"
                label="API name"
                placeholder=""
                required
                value={data.api_title}
                onChange={(e) => {
                  setData({
                    ...data,
                    api_title: e.target.value,
                    is_registered: 0
                  });
                }}
                name="APIname"
                // style={{ margin: '0 0' }}
              />
            </Grid>
            <Grid item xs>
              <FormControl
                control="input"
                label="API Key"
                placeholder=""
                required
                value={data.api_key}
                onChange={(e) => {
                  setData({
                    ...data,
                    api_key: e.target.value,
                    is_registered: 0
                  });
                }}
                name="APIkey"
                // style={{ margin: '0 0' }}
              />
            </Grid>
            <Grid item xs>
              <FormControl
                control="input"
                label="API URL"
                placeholder=""
                required
                value={data.api_url}
                onChange={(e) => {
                  setData({
                    ...data,
                    api_url: e.target.value,
                    is_registered: 0
                  });
                }}
                name="APIurl"
                // style={{ margin: '0 0' }}
              />
            </Grid>
            {/* <Box className={classes.BoxBtnAPI}>
              <WebDeleteDiaload
                disabled={!data.apiKey || !data.apiName || !data.apiURL}
              />
            </Box> */}
          </Grid>
          {/* <Grid container direction="row" spacing={2} alignItems="center">
            <Grid item xs>
              <FormControl
                control="input"
                margin="dense"
                label="API Key name"
                required
              />
            </Grid>
            <Grid item xs>
              <FormControl control="input" margin="dense" label="API Key" />
            </Grid>
            <Box className={classes.BoxBtnAPI}>
              <Button className={classes.APIBtnDel}>
                <img src={DeleteBtn} alt="img" />
              </Button>
            </Box>
          </Grid> */}
        </Grid>
        <Box mt="15px" mb="15px">
          <JBButton type="primary" onClick={handleSave} disabled={isProcessing}>
            save Api key
          </JBButton>
          {/* {data.is_registered === 1 && ( */}
          <>
            <JBButton
              type="outlined"
              style={{
                marginLeft: '15px',
                marginRight: '15px'
              }}
              disabled={
                isProcessing ||
                data.is_registered === 0 ||
                data?.sync_data?.completed === 100
              }
              onClick={handleSync}
            >
              <SyncIcon
                style={{
                  verticalAlign: 'sub',
                  marginRight: '5px'
                }}
                className={
                  data.sync_data && !data.sync_data.fully_synced
                    ? classes.SyncIconSVG
                    : null
                }
              />
              {data.sync_data ? `${data.sync_data.completed} %` : null} Sync
            </JBButton>
            <JBButton
              type="delete"
              onClick={() => setDelDiag(true)}
              disabled={isProcessing || data.is_registered === 0}
            >
              Delete
            </JBButton>
            {deleteDiag && (
              <WebDeleteDiaload
                open={deleteDiag}
                handleClose={() => setDelDiag(false)}
                handleDelete={handleDelete}
              />
            )}
          </>
          {/* )} */}
        </Box>
      </Grid>
    </div>
  );
};

WebIntegrations.propTypes = {
  classes: PropTypes.object.isRequired,
  openNotify: PropTypes.func.isRequired
};

const mapToDispatch = (dispatch) => ({
  openNotify: (msg, type) => dispatch(openNoti(msg, type))
});

export default connect(
  null,
  mapToDispatch
)(withStyles(styles)(memo(WebIntegrations)));
