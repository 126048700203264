const styles = () => ({
  root: {
    marginTop: 0,
    margin: '0px 20px 20px',
    padding: '0px 20px 20px'
  },
  mainCard: {
    boxShadow: '4px 0px 16px rgba(37, 40, 43, 0.08)',
    borderRadius: '5px',
    background: '#FFFF',
    padding: 20
  },
  ptext: {
    fontSize: '14px',
    lineHeight: 'normal',
    color: '#7F7F7F',
    fontWeight: 'normal'
  },
  bktitle: {
    fontSize: '20px',
    lineHeight: '26px',
    color: '#000',
    fontWeight: '700',
    marginBottom: '10px'
  },
  hr: {
    border: '1px solid rgb(196 196 196 / 0.4)',
    display: 'inline-block',
    width: '100%',
    margin: '20px 0'
  },
  cutom_Switch_Label: {
    display: 'inline-block',
    marginRight: '30px',
    verticalAlign: 'middle',
    fontSize: '14px',
    fontWeight: '700',
    color: '#000'
  },
  cutom_select_Label: {
    fontSize: '12px',
    '&:focus ': {
      outline: '0'
    }
  },
  inputdisabled: {
    '& .MuiInputBase-input.Mui-disabled': {
      background: '#F9F9FA',
      color: '#949494',
      border: '1px solid #EAEAEA'
    },
    '& .MuiInput-underline.Mui-disabled:before': {
      display: 'none'
    }
  },
  TimezoneGrid: {
    maxWidth: 'calc(41.666667% - 20px)',
    margin: '0 0 0 auto',
    '@media (max-width: 1440px)': {
      maxWidth: 'calc(41.666667% - 16px)'
    }
  }
});
export default styles;
