import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropType from 'prop-types';
import { Checkbox, Grid, makeStyles, MenuItem, Toolbar, Typography } from '@material-ui/core';
import { toggleAreAllSelected } from 'src/Redux/MasterHQ/FinancialReducer/financial.reducer.actions';

import { useDispatch, useSelector } from 'react-redux';
// import IconButton from '@material-ui/core/IconButton';
// import DeleteIcon from '@material-ui/icons/Delete';
import BulkActions from 'src/views/common/JBTables/BulkActions';
// import { exportInvoices } from 'src/services/Invoice.services';
import { exportAllInvoices, exportInvoicesWithId } from 'src/services/masterHQ.services';
// import ChangeCoach from './ChangeCoach';
// import WarningDialog from './WarningDialog';

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    padding: '0 5px',
    backgroundColor: '#F9F9FA',
    color: '#000'
  },
  highlight:
    theme.palette.type === 'light'
      ? { color: '#292929', backgroundColor: '#F9F9FA' }
      : {
          color: theme.palette.text.primary, // eslint-disable-line
          backgroundColor: theme.palette.secondary.dark // eslint-disable-line
        }, // eslint-disable-line
  title: {
    flex: '1 1 100%'
  },
  bkselected: {
    minWidth: '185px',
    // textAlign: 'center',
    color: '#ffffff',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '0.2px',
    paddingLeft: '15px'
  },
  BulkActions: {
    // color: '#ffffff',
    '& .MuiPaper-root': { background: '#1b1c30' },

    '& button.MuiButtonBase-root ': {
      color: '#ffffff',
      background: '#1b1c30',
      border: '1px solid #26273b'
    },

    '& .MuiMenuItem-root': {
      color: '#ffffff'
    }
  }
}));

const EnhancedTableToolbar = (props) => {
  const { ClearChecks, numSelected, total, values, setSelected } = props;
  // const [openDial, setOpenDial] = useState(false);
  // const [openDelDial, setDelDial] = useState(false);
  // const [openCoachDial, setCoachDial] = useState(false);
  const classes = useToolbarStyles();

  const dispatch = useDispatch();

  const areAllSelected = useSelector(
    (state) => state.masterHqReducer.financialReducer.areAllSelected
  );

  const [isAllSelected, setIsAllSelected] = useState(0);
  const [isAllSelectedTotal, setIsAllSelectedTotal] = useState(0);

  useEffect(() => {
    if (areAllSelected) {
      setIsAllSelectedTotal(total);
      setIsAllSelected(1);
    }
  }, [areAllSelected, total]);

  const SelectAll = () => {
    if (isAllSelected === 0) {
      setIsAllSelected(1);
      dispatch(toggleAreAllSelected(true));
      const newSelecteds = values.map((n) => n.id);
      setSelected(newSelecteds);
    } else {
      setIsAllSelected(0);
      dispatch(toggleAreAllSelected(false));
      setSelected([]);
    }

    setIsAllSelectedTotal(total);
  };

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0
      })}
      style={{
        backgroundColor: 'rgb(50 51 69)'
      }}
    >
      <Checkbox
        defaultChecked
        indeterminate
        onClick={() => ClearChecks()}
        inputProps={{ 'aria-label': 'indeterminate checkbox' }}
        color="primary"
      />
      <Grid
        spacing={0}
        disableGutters
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
      >
        <Grid item>
          <Typography
            color="inherit"
            variant="subtitle1"
            component="div"
            className={classes.bkselected}
          >
            {isAllSelected === 0 ? numSelected.length : isAllSelectedTotal}{' '}
            invoices selected
            {/* {numSelected.length} invoices selected */}
          </Typography>
        </Grid>
        <Grid item className={classes.BulkActions}>
          <BulkActions>
            <MenuItem
              onClick={() => {
                if (isAllSelected === 0) {
                  exportInvoicesWithId(numSelected.join(','));
                } else {
                  exportAllInvoices();
                }
              }}
            >
              <div>Export</div>
            </MenuItem>
          </BulkActions>
        </Grid>
        {numSelected.length >= 25 ? (
          <Grid item>
            <Typography
              color="inherit"
              variant="subtitle1"
              component="div"
              className={classes.bkselected}
            >
              <span
                style={{
                  marginRight: '10px'
                }}
              >
                {isAllSelected === 0
                  ? 'All invoices on this page are selected'
                  : 'All invoices are selected'}
              </span>

              {isAllSelected === 0 ? (
                <span
                  onClick={SelectAll}
                  style={{
                    textDecoration: 'underline',
                    color: '#37847c',
                    cursor: 'pointer'
                  }}
                >
                  Select All {total} invoices
                </span>
              ) : (
                <span
                  onClick={SelectAll}
                  style={{
                    textDecoration: 'underline',
                    color: '#37847c',
                    cursor: 'pointer'
                  }}
                >
                  Clear selection
                </span>
              )}
            </Typography>
          </Grid>
        ) : null}
      </Grid>
    </Toolbar>
  );
};
EnhancedTableToolbar.propTypes = {
  numSelected: PropType.array.isRequired,
  // numSelected: PropType.number.isRequired,
  ClearChecks: PropType.func.isRequired,

  total: PropType.number.isRequired,
  values: PropType.array.isRequired,
  setSelected: PropType.func.isRequired
};

export default EnhancedTableToolbar;
