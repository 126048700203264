import { backendDate } from 'src/utils/someCommon';

export const tobeFilter = [
  { id: 1, filter: 'Location' },
  { id: 2, filter: 'Date Range' }
];
export const dateRange = [
  { id: 'all_time', filter: 'All Time' },
  { id: 'custom', filter: 'Custom' },
  { id: 'thisyear', filter: 'This Year' },
  { id: 'lastyear', filter: 'Last Year' },
  { id: 'thisquarter', filter: 'This Quarter' },
  { id: 'lastquarter', filter: 'Last Quarter' },
  { id: 'thismonth', filter: 'This Month' },
  { id: 'lastmonth', filter: 'Last Month' },
  { id: 'thisweek', filter: 'This Week' },
  { id: 'lastweek', filter: 'Last Week' }
];
export const getFilter = (filter, filterDate, data, endDate) => {
  switch (filter.id) {
    case 2:
      if (filterDate?.id === 'custom') {
        return {
          date_range: 'custom',
          custom: backendDate(data) + '-' + backendDate(endDate)
        };
        // eslint-disable-next-line no-else-return
      } else {
        return {
          date_range: filterDate.id
        };
      }
    case 1:
      return {
        location_id: data.id
      };
    default:
      return null;
  }
};
export const getfilterLabel = (filter, filterDate, data, endDate) => {
  switch (filter.id) {
    case 2:
      if (filterDate?.id === 'custom') {
        return {
          id: filter.id,
          name: `Date range is ${backendDate(data)
            + '-'
            + backendDate(endDate)}`,
          filter: 'date_range'
        };
        // eslint-disable-next-line no-else-return
      } else {
        return {
          id: filter.id,
          name: `Date range is ${filterDate.filter}`,
          filter: 'date_range'
        };
      }
    case 1:
      return {
        id: filter.id,
        name: `Location is ${data.gym_name}`,
        filter: 'location_id'
      };
    default:
      return null;
  }
};

export const checkData = (filter, data, paymentdue, endDate) => {
  if (!filter) {
    return true;
  }
  if (filter?.id === 1 && !data) {
    return true;
  }
  if (filter?.id === 2) {
    if (!paymentdue) {
      return true;
    }
    if (paymentdue?.id === 'custom' && !data) return true;
    if (paymentdue?.id === 'custom' && !endDate) {
      return true;
    }
  }
  return false;
};
