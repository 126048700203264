import {
  ACTION_TYPES,
  ALLIES_DETAILS,
  PLAN_DETAILS,
  DISCOUNTS,
  DISCOUNT_EXPIRE_TYPE,
  DISCOUNT_MONTHS,
  DISCOUNT_END_DATE,
  DISCOUNT_AMOUNT,
  DISCOUNT_VALUE,
  DISCOUNT_TYPE,
  TRANSACTION_CHARGES,
  RESET_VALUES
} from './masterhqallies.types';

export const fetchingUser = () => ({
  type: ALLIES_DETAILS,
  actionType: ACTION_TYPES.isFetching
});

export const fetchedUser = (payload) => ({
  type: ALLIES_DETAILS,
  actionType: ACTION_TYPES.fetchSucess,
  payload
});

export const errorUser = (payload) => ({
  type: ALLIES_DETAILS,
  actionType: ACTION_TYPES.Errorfetch,
  payload
});
export const fetchPlanDetails = () => ({
  type: PLAN_DETAILS,
  actionType: ACTION_TYPES.isFetching
});
export const getPlanDetails = (payload) => ({
  type: PLAN_DETAILS,
  actionType: ACTION_TYPES.fetchSucess,
  payload
});
export const errorPlanDetails = (payload) => ({
  type: PLAN_DETAILS,
  actionType: ACTION_TYPES.Errorfetch,
  payload
});
export const fetchDiscounts = () => ({
  type: DISCOUNTS,
  actionType: ACTION_TYPES.isFetching
});
export const getDiscounts = (payload) => ({
  type: DISCOUNTS,
  actionType: ACTION_TYPES.fetchSucess,
  payload
});
export const fetchTransatcions = () => ({
  type: TRANSACTION_CHARGES,
  actionType: ACTION_TYPES.isFetching
});
export const getTransactions = (payload) => ({
  type: TRANSACTION_CHARGES,
  actionType: ACTION_TYPES.fetchSucess,
  payload
});
export const errorTransactions = (payload) => ({
  type: TRANSACTION_CHARGES,
  actionType: ACTION_TYPES.Errorfetch,
  payload
});
export const errorDiscount = (payload) => ({
  type: DISCOUNTS,
  actionType: ACTION_TYPES.Errorfetch,
  payload
});
export const setDiscountExpireType = (payload) => ({
  type: DISCOUNT_EXPIRE_TYPE,
  payload
});
export const setDiscountMonth = (payload) => ({
  type: DISCOUNT_MONTHS,
  payload
});
export const setDiscountEndDate = (payload) => ({
  type: DISCOUNT_END_DATE,
  payload
});
export const setDiscountType = (payload) => ({
  type: DISCOUNT_TYPE,
  payload
});
export const setDiscountValue = (payload) => ({
  type: DISCOUNT_VALUE,
  payload
});
export const setDiscountAmount = (payload) => ({
  type: DISCOUNT_AMOUNT,
  payload
});
export const resetValues = () => ({
  type: RESET_VALUES
});
