import authhttp from './authHttp.services';

// eslint-disable-next-line
export const getSettings = async () => {
  const Url = 'api/settings/gym/edit';
  const {
    data: { data }
  } = await authhttp.get(Url);
  return data;
};
export const getProfile = async () => {
  const Url = 'api/gym_locations/1/edit';
  const {
    data: { data }
  } = await authhttp.get(Url);
  return data;
};

export const destroyWebInt = async () => {
  const Url = 'api/destroy_webintegreation';
  const {
    data: {
      data: { meta }
    }
  } = await authhttp.get(Url);
  return meta;
};

export const SyncWebIntegration = async () => {
  const Url = 'api/sync_mannually';
  const { data } = await authhttp.get(Url);
  return data;
};

export const SyncWebStatus = async () => {
  const Url = 'api/getStatusOfSync';
  const {
    data: { data }
  } = await authhttp.get(Url);
  return data;
};

export const getAffiliatePayments = async (id) => {
  const values = {
    affiliate_id: id
  };
  const url = 'api/get_affiliate_payment_billing_tab';
  const {
    data: { data }
  } = await authhttp.post(url, values);
  return data;
};
export const getAdminsOfAffiliate = async (id) => {
  const url = `api/get_gym_admin_of_affiliate/${id}`;
  const {
    data: { data }
  } = await authhttp.get(url);
  return data;
};
export const createAffiliatePaymentMethod = async (values) => {
  const url = 'api/create_payment_method_affiliate';
  const {
    data: { data }
  } = await authhttp.post(url, values);
  return data;
};

export const UpdatePaymentMethod = async (values) => {
  const url = 'api/update_payment_method_affiliate';
  const {
    data: { data }
  } = await authhttp.post(url, values);
  return data;
};

export const updateGymLocation = async (values, id) => {
  const updateUrl = `api/gym_locations/${id}`;
  const {
    data: { data }
  } = await authhttp.put(updateUrl, values);
  return data;
};

export const AffiliateInvoiceDownload = async (ids) => {
  const exportUrl = `api/download_invoice_with_affiliate_invoice_id/${ids}`;
  const Values = {
    ids
  };

  authhttp
    .get(exportUrl, Values, {
      responseType: 'blob'
    })
    .then((res) => {
      const link = document.createElement('a');
      // link.href = url;
      link.href = res.data.data.affiliate_invoice_path;
      link.target = '_blank';
      link.setAttribute('download', 'affiliateinvoice.pdf');
      document.body.appendChild(link);
      link.click();
    });
};

export const PaymentMethods = async (values) => {
  const paymentUrl = 'api/update_payment_method_status_delete';
  const {
    data: { data }
  } = await authhttp.post(paymentUrl, values);
  return data;
};
