// eslint-disable-next-line
export const getErrorMsg = (err) => {
  if (!err.response) {
    return 'Something Went Wrong';
  }
  if (err.response) {
    if (err.response.status === 500) {
      return 'Something Went Wrong Server Error';
    }
    if (err.response.status > 400) {
      return err.response.data.message;
    }
  }
};

export const getErrorMessage = (error) => {
  const { data } = error.response;
  return data.message;
};
export const getOneErrorMessage = (error) => {
  const { data } = error.response;
  return data.message.split(',')[0];
};

export const getError = (error) => {
  const { data } = error.response;
  const message = Object.values(data.message);
  return message[0];
};
