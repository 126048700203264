import authhttp from './authHttp.services';

// eslint-disable-next-line
export const getCurrentUser = async () => {
  const userUrl = 'api/users';
  const {
    data: { data }
  } = await authhttp.get(userUrl);
  return data;
};

export const createusermemship = async (values, id) => {
  const memurl = 'api/user_member';
  const userval = {
    ...values,
    user_id: id
  };
  const {
    data: { data }
  } = await authhttp.post(memurl, userval);
  return data;
};

export const getusermembership = async (id) => {
  const memurl = `api/user_member/${id}/edit`;
  const {
    data: { data }
  } = await authhttp.get(memurl);
  return data;
};

export const updateusermemship = async (values, id, memid) => {
  const memurl = `api/user_member/${id}`;
  const userval = {
    ...values,
    user_id: memid
  };
  const {
    data: { data }
  } = await authhttp.put(memurl, userval);
  return data;
};

export const createPaymentMet = async (values) => {
  const paymentUrl = 'api/userpaymentmethod';
  const {
    data: { data }
  } = await authhttp.post(paymentUrl, values);
  return data;
};

export const getPaymentMet = async (user_id) => {
  const paymentUrl = 'api/userpaymentmethod';
  const {
    data: { data }
  } = await authhttp.get(paymentUrl, {
    params: {
      user_id
    }
  });
  return data;
};

export const importUser = async (value) => {
  const importUrl = 'api/import_users';
  const {
    data: { data }
  } = await authhttp.post(importUrl, value);
  return data;
};

export const getpaymentDetail = async (id) => {
  const paymentUrl = `api/userpaymentmethod/${id}/edit`;
  const {
    data: { data }
  } = await authhttp.get(paymentUrl);
  return data;
};

export const updateCard = async (id, val) => {
  const paymentUrl = `api/userpaymentmethod/${id}`;
  const data = await authhttp.put(paymentUrl, val);
  return data;
};

export const DeletePaymentMethod = async (id) => {
  const PaymentMethodURL = `api/userpaymentmethod/${id}`;
  const { data } = await authhttp.del(PaymentMethodURL);
  return data;
};

export const makeDefaultPayment = async (id) => {
  const makedefaultUrl = `api/make_default_payment_method/${id}`;
  const data = await authhttp.post(makedefaultUrl);
  return data;
};

// get new Ally setup unprocessed steps
export const getAllySteps = async () => {
  const url = 'api/check-unprocessed-steps';
  const {
    data: { data }
  } = await authhttp.get(url);
  return data;
};

export const createStripeCustomer = async (values) => {
  const paymentUrl = 'api/createStripeCustomer';
  const {
    data: { data }
  } = await authhttp.post(paymentUrl, values);
  return data;
};
