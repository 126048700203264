/* eslint-disable no-confusing-arrow */
import React, { useEffect, useState } from 'react';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Drawer,
  Hidden,
  List,
  ListItem,
  Popover,
  ListSubheader
} from '@material-ui/core';
import { getRouteIndex } from 'src/utils/someCommon';
import Collapse from '@material-ui/core/Collapse';
// import NavItem from './NavItem';
import clsx from 'clsx';
// import Admin from '../../../assets/image/admin.png';
// import Chalkboard from '../../../assets/image/Chalkboard.png';
// import Hub from '../../../assets/image/Hub.png';
// import Vanguard from '../../../assets/image/Vanguard.png';
import { get } from 'js-cookie';
import useStyles from './navBar.style';
import NavRoutes from './routes';

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();

  const [open, setOpen] = React.useState(false);

  const [selectedIndex, setSelectedIndex] = React.useState('');

  const [activeMenu] = useState('main');
  const [anchorEl, setAnchorEl] = React.useState(selectedIndex);

  const handleListItemClick = (event, index) => {
    setAnchorEl(event.currentTarget);
    setSelectedIndex(index);
    setOpen(!open);
  };

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    if (!selectedIndex) {
      setSelectedIndex(getRouteIndex(NavRoutes, location.pathname));
      setOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const roleid = JSON.parse(get('jbrole') || '{}')?.role_id || 3;
  const miniMenu = localStorage.getItem('mini-menu');

  // openPopover
  const handleClose = () => {
    setAnchorEl(null);
  };
  const openPopover = Boolean(anchorEl);
  // const id = openPopover ? 'simple-popover' : undefined;
  // end openPopover

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Box p={2}>
        {NavRoutes.map((route) => (
          <div key={route.id}>
            {/* eslint-disable-next-line no-nested-ternary */}
            {route.roles >= roleid ? (
              <List
                // disableGutters
                key={route.id}
                className={classes.navitems}
                disablePadding
              >
                <ListItem
                  in={activeMenu}
                  // in={activeMenu === 'main'}
                  selected={selectedIndex === route.id}
                  onClick={(event) => handleListItemClick(event, route.id)}
                  // onMouseOver={(event) => handleListItemClick(event, route.id)}
                  className="list-items"
                  disableGutters
                  aria-describedby={selectedIndex === route.id}
                  // onClick={handleClick}
                  // unmountOnExit
                >
                  {route.href ? (
                    <Button
                      component={RouterLink}
                      to={route.href}
                      className={clsx(
                        classes.button,
                        route.name === 'WORKOUTS' || route.name === 'GYM'
                          ? 'SvgStyle'
                          : null
                      )}
                    >
                      {route.icon}
                      <span className={classes.listName}>{route.name}</span>
                    </Button>
                  ) : (
                    <Button
                      className={clsx(
                        classes.button,
                        route.name === 'WORKOUTS' || route.name === 'GYM'
                          ? 'SvgStyle'
                          : null
                      )}
                    >
                      {route.icon}
                      <span className={classes.listName}>{route.name}</span>
                    </Button>
                  )}
                </ListItem>
              </List>
            ) : null}
            {route.nested.length > 0 && (
              <div>
                {/* Popover over menu -(in progress) */}
                {miniMenu === 'false' ? (
                  <Collapse
                    in={selectedIndex === route.id}
                    timeout="auto"
                    unmountOnExit
                    className="list-collapse-mimiMenu"
                  >
                    <Popover
                      id={openPopover === selectedIndex}
                      open={openPopover}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        // vertical: 'bottom',
                        // horizontal: 'left'
                        vertical: 'top',
                        horizontal: 'right'
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left'
                      }}
                      PaperProps={{
                        style: {
                          // backgroundColor: 'transparent'
                          // boxShadow: 'none',
                          // borderRadius: 0
                        }
                      }}
                      disablePortal
                    >
                      <Box className={classes.PopoverDiv} />
                      <List
                        component="div"
                        subheader={<ListSubheader>{route.name}</ListSubheader>}
                      >
                        {route.nested.map((nestedRoute) =>
                          nestedRoute.roles >= roleid ? (
                            <ListItem
                              className={classes.nested}
                              key={nestedRoute.id}
                              onClick={handleClose}
                            >
                              <Button
                                activeClassName={classes.active}
                                className={classes.button}
                                exact
                                component={RouterLink}
                                to={`${nestedRoute.href}`}
                              >
                                <span className={classes.collapselistName}>
                                  {nestedRoute.name}
                                </span>
                              </Button>
                            </ListItem>
                          ) : null
                        )}
                      </List>
                    </Popover>
                    {/* Popover over menu End */}
                  </Collapse>
                ) : (
                  <Collapse
                    in={selectedIndex === route.id}
                    timeout="auto"
                    unmountOnExit
                    className="list-collapse"
                  >
                    <List component="div" disablePadding>
                      {/* eslint-disable-next-line no-confusing-arrow */}
                      {route.nested.map((nestedRoute) =>
                        nestedRoute.roles >= roleid ? (
                          <ListItem
                            className={classes.nested}
                            key={nestedRoute.id}
                          >
                            <Button
                              activeClassName={classes.active}
                              className={classes.button}
                              exact
                              component={RouterLink}
                              to={`${nestedRoute.href}`}
                            >
                              <span className={classes.collapselistName}>
                                {nestedRoute.name}
                              </span>
                            </Button>
                          </ListItem>
                        ) : null
                      )}
                    </List>
                  </Collapse>
                )}
              </div>
            )}
          </div>
        ))}
      </Box>
      <Box flexGrow={1} />
    </Box>
  );
  // Disabling for developing Environment
  return (
    <>
      <div>
        <Hidden lgUp>
          <Drawer
            anchor="left"
            classes={{ paper: classes.mobileDrawer }}
            onClose={onMobileClose}
            open={openMobile}
            variant="temporary"
          >
            {content}
          </Drawer>
        </Hidden>
        <Hidden mdDown>
          <Drawer
            className={clsx('desktopMenu')}
            anchor="left"
            classes={{ paper: classes.desktopDrawer }}
            open
            variant="persistent"
          >
            {content}
          </Drawer>
          {/* <div className="nav-end">
            <ListItem className={classes.nested}>
              <Button
                activeClassName={classes.myactive}
                className={classes.button}
                component={RouterLink}
                to={ROUTES.WORKOUT_LIST}
              >
                <img src={Admin} alt="img" />
                Admin
              </Button>
            </ListItem>

            <ListItem className={classes.nested}>
              <Button
                activeClassName={classes.myactive}
                className={classes.button}
                // component={RouterLink}
                // to={ROUTES.DASHBOARD}
                style={{
                  fontFamily: 'Lato',
                  fontWeight: 'bold',
                  fontSize: '12px',
                  lineHeight: '16px',
                  padding: '10px 2px',
                  display: 'block'
                }}
              >
                <img src={Chalkboard} alt="img" />
                Chalkboard
              </Button>
            </ListItem>

            <ListItem className={classes.nested}>
              <Button
                activeclassname={classes.myactive}
                className={classes.button}
                // component={CustomRouterLink}
                // to="/#"
                style={{
                  fontFamily: 'Lato',
                  fontWeight: 'bold',
                  fontSize: '12px',
                  lineHeight: '16px',
                  padding: '10px 2px',
                  display: 'block'
                }}
              >
                <img
                  src={Hub}
                  alt="img"
                  style={{
                    display: 'block',
                    margin: '0 auto',
                    padding: '5px 0'
                  }}
                />
                Hub
              </Button>
            </ListItem>
            <ListItem className={classes.nested}>
              <Button
                activeclassname={classes.myactive}
                className={classes.button}
                // component={CustomRouterLink}
                // to="/#"
                style={{
                  fontFamily: 'Lato',
                  fontWeight: 'bold',
                  fontSize: '12px',
                  lineHeight: '16px',
                  padding: '10px 2px',
                  display: 'block'
                }}
              >
                <img src={Vanguard} alt="img" />
                Vanguard
              </Button>
            </ListItem>
          </div> */}
        </Hidden>
        {/* <DashboardNav /> */}
      </div>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

export default NavBar;
