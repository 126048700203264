import { ROUTES } from 'src/constant/Constants';

// eslint-disable-next-line
export const getActiveVal = (pathname) => {
  switch (pathname) {
    case ROUTES.DOCUMENTS_CONTRACTS:
      return 0;
    case ROUTES.DOCUMENTS_CONTRACTS_SIGNED:
      return 1;
    case ROUTES.DOCUMENTS_CONTRACTS_TEMPLATES:
      return 2;
    default:
      return 0;
  }
};
// eslint-disable-next-line
export const getMissingVal = (pathname) => {
  switch (pathname) {
    case ROUTES.DOCUMENTS_CONTRACTS:
      return 0;
    case ROUTES.DOCUMENTS_CONTRACTS_INACTIVE:
      return 1;
    case ROUTES.DOCUMENTS_CONTRACTS_ALL:
      return 2;
    default:
      return 0;
  }
};
