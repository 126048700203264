/* eslint-disable arrow-parens */
import React from 'react';
import PropTypes from 'prop-types';
import { Breadcrumbs, Grid, Typography, withStyles } from '@material-ui/core';
import { get } from 'js-cookie';
import styles from './Waivers.style';
import WaiversTabs from './WaiversTabs';
import { getActiveVal } from './Waivers.utils';

class Waivers extends React.PureComponent {
  render() {
    const { classes, location } = this.props;
    const Tabslocation = getActiveVal(location.pathname);
    const roleid = JSON.parse(get('jbrole') || '{}')?.role_id || 3;

    return (
      <div className={classes.root}>
        <div className="title-bk">
          <div className={classes.new_programs} style={{ marginBottom: 20 }}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={0}
            >
              <Grid item xs={12}>
                <Breadcrumbs aria-label="breadcrumb">
                  <div color="inherit">documents</div>
                  <div color="inherit">waivers</div>
                  <Typography variant="h3" color="Primary">
                    {(() => {
                      switch (Tabslocation) {
                        case 0:
                          return <span>Missing</span>;
                        case 1:
                          return <span>signed</span>;
                        case 2:
                          return roleid === 1 ? <span>Templates</span> : null;
                        default:
                          return null;
                      }
                    })()}
                  </Typography>
                </Breadcrumbs>
              </Grid>
            </Grid>
          </div>
        </div>

        <WaiversTabs />
      </div>
    );
  }
}

Waivers.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.any.isRequired
};

export default withStyles(styles)(Waivers);
