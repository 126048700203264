export const MembershipType = [
  { id: 'COUNT_AND_HIDE_ALL_MSG', name: 'Count & Hide All Messages' },
  {
    id: 'COUNT_ALLOW_SIGNIN',
    name: 'Count & Allow Over Sign-in but Display Warnings'
  },
  {
    id: 'SHOW_VALID_CLASS',
    name: 'Count & Prevent Sign-in if All Sessions Used in Period'
  }
];

export const ShowClassType = [
  {
    id: 'SHOW_VALID_CLASS',
    name: 'Only show classes valid for subscribed members'
  },
  { id: 'SHOW_ALL_CLASS', name: 'Show all classes' }
];
