import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { connect, useDispatch } from 'react-redux';
import { openNoti } from 'src/Redux/global/global.actions';
import PropType from 'prop-types';
import {
  Checkbox,
  Grid,
  makeStyles,
  MenuItem,
  Toolbar,
  Typography
} from '@material-ui/core';
import { getCoachesDetail } from 'src/services/classModule.services';
import { toggleSelectAll } from 'src/Redux/ClassReducer/classReducer.actions';
import BulkActions from 'src/views/common/JBTables/BulkActions';
import { get } from 'js-cookie';
import ChangeCoach from './ChangeCoach';
import WarningDialog from './WarningDialog';

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    padding: '0 5px',
    backgroundColor: '#F9F9FA',
    color: '#000'
  },
  highlight:
    theme.palette.type === 'light'
      ? { color: '#292929', backgroundColor: '#F9F9FA' }
      : {
          color: theme.palette.text.primary, // eslint-disable-line
          backgroundColor: theme.palette.secondary.dark // eslint-disable-line
        }, // eslint-disable-line
  title: {
    flex: '1 1 100%'
  },
  bkselected: {
    minWidth: '185px',
    textAlign: 'center',
    color: '#292929',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '0.2px'
  }
}));

const EnhancedTableToolbar = (props) => {
  const {
    ClearChecks,
    numSelected,
    OpenNoti,
    setSelected,
    allSelected,
    values,
    total
  } = props;
  const roleid = JSON.parse(get('jbrole') || '{}')?.role_id || 3;
  const dispatch = useDispatch();
  const [openDial, setOpenDial] = useState(false);
  const [openDelDial, setDelDial] = useState(false);
  const [openCoachDial, setCoachDial] = useState(false);
  const [isAllSelected, setIsAllSelected] = useState(0);
  const [totalSelected, setTotalSelected] = useState(0);
  const classes = useToolbarStyles();
  const [coaches, setCoaches] = useState([]);

  useEffect(() => {
    if (allSelected) {
      setIsAllSelected(1);
      setTotalSelected(total);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectAll = () => {
    if (isAllSelected === 0) {
      setIsAllSelected(1);
      dispatch(toggleSelectAll(true));
      const newSelected = values.map((n) => n.id);
      setSelected(newSelected);
    } else {
      setIsAllSelected(0);
      dispatch(toggleSelectAll(false));
      setSelected([]);
    }
    setTotalSelected(total);
  };
  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0
      })}
      style={{
        backgroundColor: '#F9F9FA'
      }}
    >
      <Checkbox
        defaultChecked
        indeterminate
        onClick={() => ClearChecks()}
        inputProps={{ 'aria-label': 'indeterminate checkbox' }}
        color="primary"
      />
      <Grid
        spacing={0}
        disableGutters
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
      >
        <Grid item>
          <Typography
            color="inherit"
            variant="subtitle1"
            component="div"
            className={classes.bkselected}
          >
            {isAllSelected === 0 ? numSelected.length : totalSelected} classes
            selected
          </Typography>
        </Grid>
        <Grid item xl={2} lg={3} sm={3}>
          {roleid <= 2 && (
            <BulkActions>
              <MenuItem onClick={() => setOpenDial(true)}>
                <div>Cancel Classes</div>
              </MenuItem>
              <MenuItem onClick={() => setDelDial(true)}>
                <div>Delete Classes</div>
              </MenuItem>
              <MenuItem
                onClick={() =>
                  getCoachesDetail(numSelected)
                    .then((res) => setCoaches(res))
                    .then(() => setCoachDial(true))
                    .catch(() =>
                      OpenNoti(
                        'Please select class with same Programs',
                        'error'
                      )
                    )
                } // eslint-disable-line
              >
                <div>Change Coach</div>
              </MenuItem>
            </BulkActions>
          )}
        </Grid>
        {numSelected.length >= 25 ? (
          <Grid item>
            <Typography
              variant="subtitle1"
              color="inherit"
              component="div"
              className={classes.bkselected}
            >
              <span style={{ marginRight: '10px' }}>
                {isAllSelected === 0
                  ? 'All leads on this page are selected'
                  : 'All leads are selected'}
              </span>
              {isAllSelected === 0 ? (
                <span
                  style={{
                    textDecoration: 'underline',
                    color: '#37847c',
                    cursor: 'pointer'
                  }}
                  onClick={selectAll}
                >
                  Select all {total} leads
                </span>
              ) : (
                <span
                  onClick={selectAll}
                  style={{
                    textDecoration: 'underline',
                    color: '#37847c',
                    cursor: 'pointer'
                  }}
                >
                  Clear selection
                </span>
              )}
            </Typography>
          </Grid>
        ) : null}
      </Grid>
      <WarningDialog
        setOpen={openDial}
        handleClose={() => setOpenDial(false)}
        records={numSelected}
      />
      <WarningDialog
        setOpen={openDelDial}
        handleClose={() => setDelDial(false)}
        records={numSelected}
        DeleteDialog
      />
      <ChangeCoach
        setOpen={openCoachDial}
        handleClose={() => setCoachDial(false)}
        coaches={coaches}
        classids={numSelected}
      />
      {/* <div>
        <Tooltip title="Delete" xs>
          <IconButton aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </div> */}
    </Toolbar>
  );
};
EnhancedTableToolbar.propTypes = {
  numSelected: PropType.number.isRequired,
  ClearChecks: PropType.func.isRequired,
  OpenNoti: PropType.func.isRequired,
  setSelected: PropType.func.isRequired,
  values: PropType.array.isRequired,
  allSelected: PropType.bool.isRequired,
  total: PropType.number.isRequired
};

const mapToDis = (dispatch) => ({
  OpenNoti: (msg, type) => dispatch(openNoti(msg, type))
});

export default connect(null, mapToDis)(EnhancedTableToolbar);
