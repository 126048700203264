import React, { useState } from 'react';
import PropType from 'prop-types';
import { connect } from 'react-redux';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  DialogTitle,
  withStyles
} from '@material-ui/core';
import { voidInvoice } from 'src/services/masterHQ.invoices.services';
import { getMasterInvoices } from 'src/Redux/MasterHQ/FinancialReducer/financial.reducer.actions';
import { openNoti } from 'src/Redux/global/global.actions';
import { getErrorMsg } from 'src/utils/ErrorHandling';
import X from '../../../../assets/image/x.png';

const styles = (theme) => ({
  Deletebtn: {
    background: '#FB4E4E',
    boxShadow: '0px 8px 16px rgba(251, 78, 78, 0.16)',
    borderRadius: '2px',
    height: '48px',
    padding: '10px 15px',
    color: '#fff',
    fontWeight: '700',
    '&:hover': {
      background: '#FB4E4E'
    }
  },
  bodytxt: {
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '0.002em',
    color: '#7F7F7F',
    padding: '10px 0'
  },
  titlebk: {
    padding: '16px 24px 5px',
    color: '#fff'
  },
  DialogPopup: {
    '& .MuiPaper-root': {
      background: theme.masterhq.mainCard.background
    },
    '& .MuiButton-contained.Mui-disabled': {
      color: 'rgb(0 0 0 / 38%)',
      boxShadow: 'none',
      backgroundColor: 'rgb(59 60 78)'
    }
  },
  DialogCss: {
    background: theme.masterhq.mainCard.background
  }
});

function VoidInvoiceDialog(props) {
  const { classes, setOpen, handleClose, invoiceId, updateData } = props;
  const [isprocessing, setprocessing] = useState(false);

  const handleVoid = () => {
    const { OpenNoti, getInvoices } = props;
    const formData = new FormData();
    formData.set('invoice_id', invoiceId);
    setprocessing(true);
    voidInvoice(formData)
      .then((res) => {
        updateData(res);
        OpenNoti('Successfully void the invoice', 'info');
        setprocessing(false);
      })
      .then(() => handleClose())
      .then(() => getInvoices())
      .catch((err) => {
        OpenNoti(getErrorMsg(err), 'error');
        setprocessing(false);
      });
  };

  return (
    <div>
      <Dialog
        open={setOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick
        className={classes.DialogPopup}
      >
        <div style={{ width: 394 }} className={classes.DialogCss}>
          <DialogTitle id="alert-dialog-title" className={classes.titlebk}>
            <img
              alt="Plusicon"
              src={X}
              style={{ display: 'inline-block', verticalAlign: 'sub' }}
            />
            <Typography
              color="#000"
              variant="h5"
              component="div"
              className={classes.title}
              style={{ display: 'inline-block', marginLeft: '10px' }}
            >
              Void Invoice
            </Typography>
          </DialogTitle>
          <DialogContent>
            <p className={classes.bodytxt}>
              Are you sure you want to void this invoice?
            </p>
          </DialogContent>
          <DialogActions
            style={{
              padding: '5px 20px 20px'
            }}
          >
            <Button
              variant="outlined"
              onClick={handleClose}
              style={{
                height: '48px',
                color: '#7F7F7F',
                fontWeight: 'bold'
              }}
            >
              Cancel
            </Button>
            <Button
              className={classes.Deletebtn}
              onClick={handleVoid}
              disabled={isprocessing}
            >
              {isprocessing ? 'Processing...' : 'Void'}
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}

VoidInvoiceDialog.propTypes = {
  classes: PropType.object.isRequired,
  setOpen: PropType.bool.isRequired,
  handleClose: PropType.func.isRequired,
  invoiceId: PropType.number.isRequired,
  OpenNoti: PropType.func.isRequired,
  updateData: PropType.func.isRequired,
  getInvoices: PropType.func.isRequired
};

const mapToDispatch = (dispatch) => ({
  OpenNoti: (msg, type) => dispatch(openNoti(msg, type)),
  getInvoices: (filter, data) => dispatch(getMasterInvoices(filter, data))
});

export default connect(
  null,
  mapToDispatch
)(withStyles(styles)(VoidInvoiceDialog));
