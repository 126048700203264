import React, { useState, useRef } from 'react';
import PropType from 'prop-types';
import {
  Button,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  makeStyles,
  ClickAwayListener
} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import AddItem from './AddItem';
import DiscountDiag from './DiscountDiag';
import VoidInvoiceDialog from './VoidInvoiceDialog';
import SendInvoice from './SendInvoice';
import PayInvoiceDiag from './PayInvoiceDiag';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  paper: {
    marginRight: theme.spacing(2)
  }
}));

const Actions = (props) => {
  const classes = useStyles();
  const { data, updateData } = props;
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const [AddItemDialog, setAddItemDialog] = useState(false);
  const [DiscountDialog, setDiscountDialog] = useState(false);
  const [VoidInvoice, setVoidInvoice] = useState(false);
  const [EmailInvoice, setEmailInvoice] = useState(false);
  const [PayInvoiceDialog, setPayInvoiceDialog] = useState(false);

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }
  return (
    <div className={classes.root}>
      <div>
        <Button
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={() => setOpen(!open)}
          color="primary"
          variant="outlined"
          style={{ height: '48px', fontWeight: '700' }}
        >
          Actions
          {open ? <ExpandLess /> : <ExpandMore />}
        </Button>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          style={{ zIndex: '99' }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps} // eslint-disable-line
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
                zIndex: '99'
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={() => setOpen(false)}>
                  <MenuList
                    autoFocusItem={open}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                  >
                    {data.status === 1 && (
                      <MenuItem onClick={() => setPayInvoiceDialog(true)}>
                        Pay invoice
                      </MenuItem>
                    )}
                    {!data.status === 3 && (
                      <>
                        <MenuItem onClick={() => setAddItemDialog(true)}>
                          Add line item
                        </MenuItem>
                        {/* <MenuItem onClick={() => setDiscountDialog(true)}>
                          Apply a Discount
                        </MenuItem> */}
                      </>
                    )}
                    {data.status === 1 && (
                      <>
                        <MenuItem onClick={() => setVoidInvoice(true)}>
                          Void Invoice
                        </MenuItem>
                        <MenuItem onClick={() => setDiscountDialog(true)}>
                          Apply a Discount
                        </MenuItem>
                      </>
                    )}
                    <MenuItem onClick={() => setEmailInvoice(true)}>
                      Email Invoice
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        {PayInvoiceDialog && data.status === 1 ? (
          <PayInvoiceDiag
            setOpen={PayInvoiceDialog}
            handleClose={() => setPayInvoiceDialog(false)}
            data={data}
          />
        ) : null}
        {AddItemDialog && (
          <AddItem
            open={AddItemDialog}
            handleClose={() => setAddItemDialog(false)}
            addItem={(val) => updateData(val)}
            addItemId={data.id}
          />
        )}
        {DiscountDialog && (
          <DiscountDiag
            setOpen={DiscountDialog}
            handleClose={() => setDiscountDialog(false)}
            invoiceId={data.id}
            updateData={(val) => updateData(val)}
          />
        )}
        {data.status === 1 && (
          <VoidInvoiceDialog
            setOpen={VoidInvoice}
            handleClose={() => setVoidInvoice(false)}
            invoiceId={data.id}
            updateData={(val) => updateData(val)}
          />
        )}
        {EmailInvoice && (
          <SendInvoice
            setOpen={EmailInvoice}
            email={data.get_user_name?.email}
            handleClose={() => setEmailInvoice(false)}
            invoicenum={data?.id}
            invoicedatastatus={data.status}
          />
        )}
      </div>
    </div>
  );
};

Actions.propTypes = {
  data: PropType.object.isRequired,
  updateData: PropType.func.isRequired
};

export default Actions;
