import React, { useContext, useMemo, useState } from 'react';
// import { useLocation } from 'react-router-dom';
import PropType from 'prop-types';
import { Grid, Box, withStyles } from '@material-ui/core';
import { Chalk } from 'src/Context/chalkboardContext';
import JBButton from 'src/views/common/JBButton/JBButton';
import DOMPurify from 'dompurify';
import clsx from 'clsx';
import styles from './Chalkboard.style';
import useChangeTitle from '../common/CustomHooks/useTitleChange';
// import CustomizedSlider from './CustomizedSlider';

const WorkoutDetail = ({ classes }) => {
  // const classes = useStyles();
  const [getFullScr, setFullScr] = useState(false);
  const [state] = useContext(Chalk);
  useChangeTitle('Workout | Jungle Alliance');
  // const [fontSize, setfontSize] = useState(30);
  // const onFullScreen = {
  //   position: 'absolute',
  //   background: '#f9f9fa',
  //   width: 'calc(100% - 330px)',
  //   left: 'auto',
  //   right: '0',
  //   top: '0',
  //   zIndex: '9999',
  //   fontSize: `${fontSize}px`,
  //   height: '100vh',
  //   padding: '170px 30px 30px 0'
  // };
  const onNormal = {
    position: 'relative'
  };

  const PurifiedWorkout = useMemo(() => {
    if (state.data) {
      return DOMPurify.sanitize(state.data.workout_details);
    }
    return null;
  }, [state.data]);

  // const PurifiedWorkout = useMemo(() => {

  //   if (state.data) {
  //     // if (getFullScr) {
  //     //   state.data.workout_details.substring(
  //     //     state.data.workout_details.lastIndexOf('font-size:') + 1,
  //     //     state.data.workout_details.lastIndexOf('px;')
  //     //   );
  //     //   state.data.workout_details.replaceAll('px;', '');
  //     //   const res2 = state.data.workout_details.replaceAll(
  //     //     'font-size:',
  //     //     'font-size: inherit;'
  //     //   );
  //     //   return DOMPurify.sanitize(res2);
  //     // }
  //     // return DOMPurify.sanitize(state.data.workout_details);
  //     // if (getFullScr === true) {
  //     //   state.data.workout_details.substring(
  //     //     state.data.workout_details.lastIndexOf('font-size:') + 1,
  //     //     state.data.workout_details.lastIndexOf('px;')
  //     //   );
  //     //   state.data.workout_details.replaceAll('px;', '');
  //     //   const res2 = state.data.workout_details.replaceAll(
  //     //     'font-size:',
  //     //     'font-size: inherit;'
  //     //   );
  //     //   return DOMPurify.sanitize(res2);
  //     // }

  //     // if (getFullScr === false) {
  //     //   const res2 = state.data.workout_details;
  //     //   return DOMPurify.sanitize(res2);
  //     // }

  //     // const res = state.data.workout_details.replaceAll(
  //     //   'font-size:',
  //     //   // 'font-size: 24px;',
  //     //   `font-size: ${fontSize}px;`
  //     // );
  //     // const el = document.getElementsByClassName('sun-editor-editable');
  //     // el.style.removeProperty('font-size');
  //     // const copydata = state.data.workout_details.split('px');

  //     return DOMPurify.sanitize(state.data.workout_details);
  //   }
  //   return null;
  // }, [state.data, getFullScr]);

  // const location = useLocation();
  // const ClickedNewTab = () => {
  //   setFullScr(!getFullScr);
  //   const url = location.pathname;
  //   window.open(url, '_blank');
  // };

  return (
    <div>
      <Grid
        container
        direction="row"
        justify="start"
        alignItems="center"
        spacing={0}
      >
        <Grid item xs={12} className={classes.htmldata}>
          {/* {getFullScr ? (
            <div>
              <CustomizedSlider setFont={(val) => setfontSize(val)} />
            </div>
          ) : null} */}

          <Box style={{ position: 'relative' }}>
            {/* <JBButton
              type="primary"
              style={{ position: 'absolute', right: '0' }}
            >
              view full screen
            </JBButton> */}
          </Box>
          <Box style={getFullScr ? null : onNormal}>
            <Box
              style={{
                overflowY: getFullScr ? 'auto' : 'auto',
                height: getFullScr ? 'calc(100vh - 180px);' : '100%'
              }}
            >
              <div className={classes.ScreenBtn}>
                <JBButton
                  type={getFullScr ? 'primary' : 'primary'}
                  style={{
                    position: getFullScr ? 'fixed' : 'fixed',
                    right: getFullScr ? '44px' : '44px',
                    top: getFullScr ? 'auto' : 'auto',
                    marginTop: getFullScr ? '-75px' : '-75px',
                    zIndex: '9999'
                  }}
                  onClick={() => {
                    setFullScr(!getFullScr);
                    if (!getFullScr && !state.error) {
                      window.sessionStorage.setItem(
                        'workout-data',
                        state.data.workout_details
                      );
                      window.sessionStorage.setItem(
                        'workout-name',
                        state.data.name
                      );
                      window.open('/chalboard-full-screen');
                    }
                  }}
                >
                  {/* {getFullScr ? 'Close Screen' : 'View Full Screen'} */}
                  View Full Screen
                </JBButton>
              </div>
              {state.error ? (
                <h1 className={classes.msError}>{state.error}</h1>
              ) : (
                <>
                  <h4 className={classes.WodName}>{state.data.name}</h4>
                  <Box
                    dangerouslySetInnerHTML={{ __html: PurifiedWorkout }}
                    className={clsx(
                      'cke_contents_ltr cke_editable cke_editable_themed cke_show_borders'
                      // getFullScr ? null : 'htmldata'
                    )}
                    style={{
                      fontSize: 'inherit'
                    }}
                  />
                </>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

WorkoutDetail.propTypes = {
  classes: PropType.object.isRequired
};
export default withStyles(styles)(WorkoutDetail);
