import Types from './GymSetup.types';
import getGymSetup from './GymSetup.utils';

const initialState = {
  isFetching: true,
  data: null,
  isHidden: false,
  error: null
};

const GymSetupReducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.getSteps:
      return {
        ...state,
        ...getGymSetup(Types.getSteps, action.actionType, state, action.payload)
      };
    case Types.isHidden:
      return {
        ...state,
        isHidden: action.payload
      };
    default:
      return state;
  }
};
export default GymSetupReducer;
