import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import LoadingView from 'src/views/common/loading';
import { getReportsListData } from 'src/Redux/ReportsReducer/reports.reducer.actions';
import { ROUTES } from 'src/constant/Constants';
import Reports from 'src/views/reports/reports';
import MembershipsOnHold from 'src/views/reports/MembersReports/MembershipsOnHold';
import PaymentPlan from 'src/views/reports/MembersReports/PaymentPlan';
import MembershipType from 'src/views/reports/MembersReports/MembershipType';
import MembershipDetails from 'src/views/reports/MembersReports/MembershipDetails';
import ExpiringMemberships from 'src/views/reports/MembersReports/ExpiringMemberships';
import LastAttendance from 'src/views/reports/AttendanceReports/LastAttendance';
import AttendanceSummary from 'src/views/reports/AttendanceReports/AttendanceSummary';
import AttendanceHistory from 'src/views/reports/AttendanceReports/AttendanceHistory';
import AttendanceTrend from 'src/views/reports/AttendanceReports/AttendanceTrend';
import ExpiringCreditCards from 'src/views/reports/FinancialReports/ExpiringCreditCards';
import AllInvoices from 'src/views/reports/FinancialReports/AllInvoices';
import RevenueCategory from 'src/views/reports/FinancialReports/RevenueCategory';
import ValueOfmember from 'src/views/reports/FinancialReports/ValueOfmember';
import OverdueAccountReceivable from 'src/views/reports/FinancialReports/OverdueAccountReceivable';
import RevenueMembership from 'src/views/reports/FinancialReports/RevenueMembership';
import LeadInsights from 'src/views/reports/LeadReports/LeadInsights';
import ReportsPriority from 'src/views/reports/ReportsPriority';
import { AttendanceSummaryContext } from 'src/Context/attendanceSummaryContext';

const ReportsContainer = memo(({ location: { pathname } }) => {
  const dispatch = useDispatch();
  const [state, setState] = useState(true);
  const {
    membersReports: {
      membershipDetails,
      expireMemberships,
      byMemshipType,
      byPaymentPlan,
      membershipsOnHold
    },
    attendanceReports: {
      attendanceTrends,
      attendanceSummary,
      lastAttendance,
      attendanceHistory
    },
    financialReports: {
      allInvoice,
      expireCards,
      membersValue,
      revenueCategory,
      overdueAccounts,
      revenueMembership
    },
    leadReports: { leadInsites }
  } = useSelector((report) => report?.reportsReducer);

  /* eslint-disable */

  const isTabFetched = (tab) => {
    if (tab === 'starred') {
      return true;
    }
    if (tab === 'members') {
      return (
        (membershipDetails.data ||
          expireMemberships.data ||
          byMemshipType.data ||
          byPaymentPlan.data ||
          membershipsOnHold.data) === null
      );
    }
    if (tab === 'attendance') {
      return (
        (attendanceTrends.data ||
          attendanceSummary.data ||
          lastAttendance.data ||
          attendanceHistory.data) === null
      );
    }
    if (tab === 'financial') {
      return (
        (allInvoice.data ||
          expireCards.data ||
          membersValue.data ||
          revenueCategory.data ||
          overdueAccounts.data ||
          revenueMembership.data) === null
      );
    }
    if (tab === 'lead') {
      return leadInsites.data === null;
    }
  };
  /* eslint-enable */

  useEffect(() => {
    function fetchReports(url) {
      for (let i = 0; i < ReportsPriority.length; i += 1) {
        if (
          ReportsPriority[i].url === url
          && isTabFetched(ReportsPriority[i].tab)
        ) {
          dispatch(ReportsPriority[i].functions());
          if (state) {
            setState(false);
          }
          let j = 0;
          const interval = setInterval(() => {
            if (j === ReportsPriority[i].restfunc.length - 1) {
              clearInterval(interval);
            }
            if (ReportsPriority[i].restfunc.length !== 0) {
              dispatch(ReportsPriority[i].restfunc[j]());
              j += 1;
            }
          }, 500);
        }
      }
      if (state) {
        setState(false);
      }
    }
    dispatch(getReportsListData());
    fetchReports(pathname);
  }, [pathname]); // eslint-disable-line

  if (state) return <LoadingView />;

  return (
    <Switch>
      <Route
        path={ROUTES.REPORTS_MEMBERS_MEMBERSHIPS_ON_HOLD}
        component={MembershipsOnHold}
      />
      <Route
        path={ROUTES.REPORTS_MEMBERS_PAYMENT_PLAN}
        component={PaymentPlan}
      />
      <Route
        path={ROUTES.REPORTS_MEMBERS_MEMBERSHIP_TYPE}
        component={MembershipType}
      />
      <Route
        path={ROUTES.REPORTS_MEMBERS_MEMBERSHIP_DETAILS}
        component={MembershipDetails}
      />
      <Route
        path={ROUTES.REPORTS_MEMBERS_EXPIRING_MEMBERSHIPS}
        component={ExpiringMemberships}
      />
      <Route
        path={ROUTES.REPORTS_ATTENDANCE_LAST_ATTENDANCE}
        component={LastAttendance}
      />
      <Route
        path={ROUTES.REPORTS_ATTENDANCE_ATTENDANCE_SUMMARY}
        render={(props) => (
          <AttendanceSummaryContext>
            {/* eslint-disable-next-line */}
            <AttendanceSummary {...props} />
          </AttendanceSummaryContext>
        )}
      />
      <Route
        path={ROUTES.REPORTS_ATTENDANCE_ATTENDANCE_HISTORY}
        component={AttendanceHistory}
      />
      <Route
        path={ROUTES.REPORTS_ATTENDANCE_ATTENDANCE_TREND}
        component={AttendanceTrend}
      />
      <Route
        path={ROUTES.REPORTS_FINANCIAL_EXPIRING_CREDIT_CARDS}
        component={ExpiringCreditCards}
      />
      <Route
        path={ROUTES.REPORTS_FINANCIAL_ALLINVOICES}
        component={AllInvoices}
      />
      <Route
        path={ROUTES.REPORTS_FINANCIAL_REVENUE_CATEGORY}
        component={RevenueCategory}
      />
      <Route
        path={ROUTES.REPORTS_FINANCIAL_VALUEMEMBER}
        component={ValueOfmember}
      />
      <Route
        path={ROUTES.REPORTS_FINANCIAL_OVERDUE_ACCOUNT_RECEIVABLE}
        component={OverdueAccountReceivable}
      />
      <Route
        path={ROUTES.REPORTS_FINANCIAL_REVENUE_MEMBERSHIP}
        component={RevenueMembership}
      />
      <Route path={ROUTES.REPORTS_LEAD_INSIGHTS} component={LeadInsights} />
      <Route path={ROUTES.REPORTS} component={Reports} />
    </Switch>
  );
});

ReportsContainer.propTypes = {
  location: PropTypes.object.isRequired,
  pathname: PropTypes.string.isRequired
};

export default ReportsContainer;
