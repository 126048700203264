import React, { useState, useEffect } from 'react';
import PropType from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  withStyles,
  Grid,
  InputLabel,
  Box
} from '@material-ui/core';
import JBCKEditor from 'src/views/common/JBCKEditorPlaceholder';
import dcloseicon from 'src/assets/image/closeicon.png';
import LoadingView from 'src/views/common/loading';
import JBButton from 'src/views/common/JBButton/JBButton';
import FormControl from 'src/views/common/FormControl/formControl';
import { useDispatch } from 'react-redux';
import { openNoti } from 'src/Redux/global/global.actions';
import {
  emailInvoice,
  getEmailInvoice
} from 'src/services/masterHQ.invoices.services';
import { getErrorMsg } from 'src/utils/ErrorHandling';
import { validTemplate } from 'src/views/financial/invoices/Invoice/SendEmailInvoice.utils';

const styles = () => ({
  dtitle: {
    color: '#292929',
    fontWeight: 'bold',
    fontSize: '33px',
    lineHeight: '26px'
  },
  cutom_select_Label: {
    fontSize: '12px',
    padding: '7px 0'
  },
  Discountinput: {
    margin: '0px',
    display: 'inline-flex',
    width: '97px',
    marginLeft: '15px',
    border: '1px solid #a9a9a9',
    height: '48px',
    padding: '9px 15px 7px'
    // '&:focus': {
    //   border: '1px solid #01847a'
    // }
  },
  DiscounFormControl: {
    width: '95px',
    height: '48px',
    marginLeft: '20px',
    '& .MuiOutlinedInput-input': {
      padding: '14.5px 8px'
    }
  },
  Discounsign: {
    '& p': {
      color: 'rgb(41 41 41 / 0.4)'
    }
  },
  LabelControl: {
    width: '130px',
    marginRight: '0'
  },
  finalCharge: {
    width: '333px',
    height: '40px',
    background: '#F9F9F9',
    float: 'right',
    display: 'flex',
    alignItems: 'center',
    padding: '7px 16px',
    color: '#292929',
    '& h6': {
      fontWeight: '700',
      fontSize: '14px',
      lineHeight: '18px',
      width: '100%'
    }
  },
  Totalcharge: {
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '22px'
  }
  // DialogPopup: {
  //   '& .MuiPaper-root': {
  //     background: theme.masterhq.mainCard.background
  //   },
  //   '& .MuiButton-contained.Mui-disabled': {
  //     color: 'rgb(0 0 0 / 38%)',
  //     boxShadow: 'none',
  //     backgroundColor: 'rgb(59 60 78)'
  //   },
  //   '& .fd-input input': {
  //     color: '#ffffff'
  //   }
  // },
  // DialogCss: {
  //   background: theme.masterhq.mainCard.background
  // }
});

function EmailInvoice({
  classes,
  handleClose,
  setOpen,
  email,
  invoicenum,
  invoicedatastatus
}) {
  const [data, setData] = useState({
    email,
    subject: '',
    body: null
  });
  const [isprocessing, setprocessing] = useState(false);

  const getInvoiceStatus = (param) => {
    switch (param) {
      case 1:
        return 11;
      case 2:
        return 4;
      case 5:
        return 12;
      case 3:
        return 13;
      default:
        return 0;
    }
  };

  // eslint-disable-next-line no-unused-vars

  const [isLoading, setIsLoading] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    getEmailInvoice(getInvoiceStatus(invoicedatastatus))
      .then((res) => {
        setIsLoading(false);
        const showData = JSON.parse(res.data.email_template);
        setData({ ...data, subject: showData.subject, body: showData.body });
      })
      .catch((err) => {
        setIsLoading(false);
        const error = getErrorMsg(err);

        dispatch(openNoti(error.id[0], 'error'));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleSave() {
    const { data: validdata, error } = validTemplate(data);
    setprocessing(true);
    if (error) {
      setprocessing(false);
      dispatch(openNoti(error, 'error'));
    } else {
      const values = {
        ...validdata,
        invoice_id: invoicenum,
        template_id: getInvoiceStatus(invoicedatastatus)
      };
      emailInvoice(values)
        .then(() => {
          setprocessing(false);
          dispatch(openNoti('Successfully sent the email'));
          handleClose();
        })
        .catch((err) => {
          setprocessing(false);
          dispatch(openNoti(getErrorMsg(err), 'error'));
        });
    }
  }
  return (
    <div>
      <Dialog
        open={setOpen}
        onClose={() => handleClose()}
        fullWidth
        maxWidth="lg"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick
        disableEnforceFocus
        // className={classes.DialogPopup}
      >
        <div>
          <Grid alignItems="center" container direction="row">
            <Grid item xs>
              <DialogTitle
                id="alert-dialog-title"
                className={classes.titlebk}
                style={{
                  display: 'inline-block',
                  padding: '25px 24px'
                }}
              >
                <h4 className={classes.dtitle}>
                  Send Invoice #{String(invoicenum).padStart(7, '0')}
                </h4>
              </DialogTitle>
            </Grid>
            <Grid
              item
              xs={2}
              style={{ textAlign: 'right', marginRight: '10px' }}
            >
              <Button className={classes.btns} onClick={handleClose}>
                <img alt="img" src={dcloseicon} />
              </Button>
            </Grid>
          </Grid>

          <DialogContent>
            {isLoading ? (
              <LoadingView />
            ) : (
              <Grid container style={{ marginTop: '5px' }}>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={6}
                    // style={{ marginBottom: '15px' }}
                  >
                    <InputLabel
                      htmlFor="age-required"
                      className={classes.cutom_select_Label}
                      style={{ margin: '0 0', padding: '0 0' }}
                    >
                      Email address
                    </InputLabel>
                    <FormControl
                      control="input"
                      placeholder=""
                      value={data.email}
                      onChange={(e) =>
                        setData({ ...data, email: e.target.value })
                      }
                      style={{ margin: '0 0' }}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <InputLabel
                      htmlFor="age-required"
                      className={classes.cutom_select_Label}
                      style={{ margin: '0 0', padding: '0 0' }}
                    >
                      Subject line
                    </InputLabel>
                    <FormControl
                      control="input"
                      placeholder=""
                      value={data.subject}
                      onChange={(e) =>
                        setData({ ...data, subject: e.target.value })
                      }
                      style={{ margin: '0 0' }}
                    />
                  </Grid>
                </Grid>

                <Grid
                  item
                  xs={12}
                  style={{
                    margin: '25px 0 10px'
                  }}
                >
                  <InputLabel
                    htmlFor="age-required"
                    className={classes.cutom_select_Label}
                  >
                    Text
                  </InputLabel>
                  <JBCKEditor
                    data={data.body}
                    onChange={(e) =>
                      setData({ ...data, body: e.editor.getData() })
                    }
                    holder={['gymname', 'gymlabel', 'signature', 'invoice']}
                  />
                </Grid>
              </Grid>
            )}
            <Box mt="20px" />
          </DialogContent>

          <DialogActions
            style={{
              padding: '20px 24px 30px'
            }}
          >
            <JBButton
              type="primary"
              style={{ marginLeft: '15px' }}
              // eslint-disable-next-line react/jsx-no-bind
              onClick={handleSave}
              disabled={!data.subject || !data.email || isprocessing}
            >
              Send email
            </JBButton>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}

EmailInvoice.propTypes = {
  classes: PropType.object.isRequired,
  setOpen: PropType.bool.isRequired,
  handleClose: PropType.func.isRequired,
  email: PropType.string.isRequired,
  invoicenum: PropType.string.isRequired,
  invoicedatastatus: PropType.string.isRequired
};
export default withStyles(styles)(EmailInvoice);
