import React, { useState, useContext } from 'react';
import { Dialog, Grid, withStyles } from '@material-ui/core';
import { MembershipTemplate as MembershipContext } from 'src/Context/MembershipTemplate/MembershipTemplateContext';
import Plusicon from 'src/assets/image/plusicon.png';
import JBButton from 'src/views/common/JBButton/JBButton';
import {
  addpaymentmethod,
  changepaymentmethod
} from 'src/Context/MembershipTemplate/membershiptemplate.actions';
import '../CutomSelect.css';
import PaymentPlanTable from './paymentplanTable';
import PaymentPlan from '../../PaymentPlan/PaymentPlan';
import styles from '../MembershipTemplate.style';
import {
  Delpayment,
  Editpayment
} from '../../PaymentPlan/PaymentPlan.validation';

const PaymentPlansStep = () => {
  const [state, dispatch] = useContext(MembershipContext);
  const [openDialog, setOpenDialog] = useState(false);

  return (
    <div>
      <h3 style={{ marginBottom: '20px' }}>
        Create payment plans for different commitments, payment options and
        locations
      </h3>

      <Grid container spacing={0} xs={12} disableGutters direction="row">
        <Grid item xs={12} style={{ marginTop: '0px', marginBottom: '20px' }}>
          <JBButton
            type="primary"
            isImg={Plusicon}
            onClick={() => setOpenDialog(true)}
          >
            new payment plan
          </JBButton>
          <Dialog
            open={openDialog}
            fullWidth
            maxWidth="lg"
            onClose={() => setOpenDialog(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            disableBackdropClick
          >
            <PaymentPlan
              getData={(val) => dispatch(addpaymentmethod(val))}
              handleClose={() => setOpenDialog(false)}
              allpayments={state?.payment_plans}
            />
          </Dialog>
        </Grid>

        <Grid item xs={12} style={{ marginTop: '0px' }}>
          {state?.payment_plans.length > 0 && (
            <PaymentPlanTable
              values={state?.payment_plans}
              Delpay={(index) =>
                dispatch(
                  changepaymentmethod(Delpayment(state?.payment_plans, index))
                )
              }
              Editpay={(val, index) =>
                dispatch(
                  changepaymentmethod(
                    Editpayment(state?.payment_plans, index, val)
                  )
                )
              } // eslint-disable-line
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(PaymentPlansStep);
