import React, { useState, useRef, useEffect, useContext } from 'react';
import SignaturePad from 'signature_pad';
import { VanguardMem } from 'src/Context/VanguardMembershipContext';
import {
  changeSignStatus,
  setSignature,
  cleareSignature
} from 'src/Context/VanguardMembership/VanguardMembership.actions';
import {
  Typography,
  makeStyles,
  Grid,
  FormControlLabel,
  Checkbox,
  Box
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import JBVanguardButton from '../../../common/JBVanguardButton/JBButton';

const useStyles = makeStyles(() => ({
  mainTitle: {
    fontWeight: '500',
    fontSize: '48px',
    lineHeight: '44px',
    color: '#FFFFFF',
    fontFamily: 'DDC Hardware',
    textAlign: 'center',
    margin: '38px 0 0 0',
    '@media (max-width: 992px)': {
      fontSize: '35px'
    }
  },
  signatureBox: {
    filter: 'invert(1)',
    backgroundColor: 'transparent',
    // borderBottom: '2px solid rgb(255 255 255 / 50%)',
    paddingBottom: '25px',
    marginBottom: '40px',
    width: '100%'
  },
  signatureGrid: {
    background: '#515151',
    borderRadius: '8px',
    padding: '20px',
    textAlign: 'center',
    marginBottom: '10px',
    position: 'relative',
    '&:after': {
      content: '""',
      width: '100%',
      display: 'block',
      position: 'relative',
      borderBottom: '2px solid rgb(255 255 255 / 50%)',
      top: '-45px'
    }
  },
  btClearsignature: {
    color: '#67e9da',
    padding: '0px 5px',
    textTransform: 'inherit',
    right: '0',
    margin: '0px 0 0',
    position: 'absolute',
    width: '155px',
    border: '0 !important',
    backgroundColor: 'transparent !important',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '32px',
    '& .Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.6)'
    }
  },
  VanCustomcheckbox: {
    '& .MuiFormControlLabel-root svg.MuiSvgIcon-root': {
      width: '30px',
      height: '30px',
      fill: 'none',
      borderRadius: '6px',
      border: '2px solid #4B4B4B'
    },
    '& .MuiFormControlLabel-root.checked svg.MuiSvgIcon-root': {
      background: '#67e9da',
      padding: '4px',
      fill: '#000',
      color: '#060606',
      border: '0px solid #4B4B4B'
    },
    '& .MuiTypography-root': {
      padding: '20px 15px'
    },
    '& .MuiFormControlLabel-label': {
      color: '#fff',
      fontSize: '20px',
      lineHeight: '26px',
      fontWeight: 'bold'
    }
  }
}));

const Sign = () => {
  const classes = useStyles();
  const [state, dispatch] = useContext(VanguardMem);
  const {
    memebership_data: {
      membershipdata: { user_contract }
    }
  } = state;
  const [sign, setSign] = useState(null);
  const [signed, setSigned] = useState(false);
  const canRef = useRef(null);

  useEffect(() => {
    if (canRef.current) {
      const canvas = new SignaturePad(canRef.current, {
        minWidth: 0.7,
        maxWidth: 1.5,
        onBegin() {
          setSigned(true);
        },
        onEnd() {
          dispatch(setSignature(canvas));
        }
      });
      setSign(canvas);
    }
    // if (activeStep === 3 && signed) {
    //   setSigned(false);
    // }
  }, [user_contract.sign_status]); // eslint-disable-line

  return (
    <div>
      <Typography variant="h3" className={classes.mainTitle}>
        sign
      </Typography>
      <Grid
        container
        xs={10}
        justify="center"
        style={{
          margin: '45px auto'
        }}
      >
        <Grid alignItems="center" container direction="row">
          {user_contract.sign_status === 1 && (
            <Grid item xs={12} className={classes.signatureGrid}>
              <canvas
                ref={canRef}
                width="800"
                height="250"
                className={classes.signatureBox}
              />
              <JBVanguardButton
                color="primary"
                onClick={() => {
                  if (sign) {
                    sign.clear();
                    setSigned(false);
                    dispatch(cleareSignature());
                  }
                }}
                disabled={!sign || !signed}
                className={classes.btClearsignature}
              >
                Clear signature
              </JBVanguardButton>
            </Grid>
          )}
          <Box mt="10px" className={classes.VanCustomcheckbox}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={user_contract.sign_status === 1}
                  onChange={() =>
                    dispatch(
                      changeSignStatus(user_contract.sign_status ? 0 : 1)
                    )
                  }
                  name="checkedB"
                  color="primary"
                  checkedIcon={
                    <CheckIcon htmlColor="#2CE9DA" width="30px" height="30px" />
                  }
                />
              }
              label="I agree to use electronic records and signatures"
              className={`${user_contract.sign_status === 1 ? 'checked' : ''}`}
            />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default Sign;
