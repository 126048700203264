import React, { useContext } from 'react';
import { VanguardMem } from 'src/Context/VanguardMembershipContext';
import {
  Typography,
  makeStyles,
  Grid,
  Box,
  TableRow,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  InputLabel
} from '@material-ui/core';
import { setContractInitials } from 'src/Context/VanguardMembership/VanguardMembership.actions';
import { validateString } from 'src/utils/validations';
import FormControl from 'src/views/common/FormControl/formControl';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    background: 'rgba(29, 29, 29, 0.94)',
    boxShadow: '0px 0.33333px 0px rgb(255 255 255 / 15%)',
    backdropFilter: 'blur(54.3656px)'
  },
  appToolbar: {
    minHeight: '75px',
    justifyContent: 'space-between'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
  mainTitle: {
    fontWeight: '500',
    fontSize: '48px',
    lineHeight: '44px',
    color: '#FFFFFF',
    fontFamily: 'DDC Hardware',
    textAlign: 'center',
    margin: '38px 0 0 0',
    '@media (max-width: 992px)': {
      fontSize: '35px'
    }
  },
  MainCard: {
    height: 'calc(100vh - 75px )',
    background:
      'linear-gradient(180deg, rgba(41, 41, 41, 0.95) 0%, rgba(4, 4, 4, 0.95) 100%)',
    borderRadius: '0',
    flex: '1 1 auto',
    // height: '100%',
    overflow: 'auto'
  },
  HomeButton: {
    fontFamily: 'Lato',
    color: '#2CE9DA',
    fontWeight: 'bold',
    fontSize: '22px',
    lineHeight: '32px',
    textTransform: 'capitalize'
  },
  cutom_select_Label: {
    fontFamily: 'DDC Hardware',
    fontStyle: 'normal',
    fontWeight: '500;',
    fontSize: '22px',
    lineHeight: '32px',
    letterSpacing: '0.2px',
    color: '#FFFFFF',
    '&:focus ': {
      outline: '0'
    },
    '& .MuiFormLabel-asterisk': {
      color: 'transparent'
    },
    '& .MuiFormLabel-asterisk:after': {
      content: '""',
      background: '#2CE9DA',
      display: 'inline-block',
      position: 'relative',
      width: '8px',
      height: '8px',
      borderRadius: '50%',
      top: '-14px',
      right: '10px'
    }
  },
  cutom_vanguard_FormControl: {
    '& .fd-input input': {
      background: 'rgba(196, 196, 196, 0.05)',
      color: '#fff',
      borderRadius: '6px',
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '20px',
      height: '45px',
      border: '2px solid #C8C8C8',
      padding: '8px 20px 8px',
      '&::placeholder ': {
        color: '#fff',
        fontWeight: 'bold',
        fontSize: '15px'
      }
    }
  },
  cutom_vanguard_Autocomplete: {
    background: 'rgba(196, 196, 196, 0.05)',
    border: '2px solid #C8C8C8',
    color: '#fff',
    borderRadius: '6px',
    '& .cutom_select div': {
      minHeight: '62px'
    },
    '& .cutom_select div input ': {
      color: '#ffffff !important',
      fontWeight: 'bold',
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontSize: '20px'
    },
    '& .MuiAutocomplete-endAdornment': {
      minHeight: ' auto !important',
      top: 'calc(50% - 12px)'
    }
  },
  customRadio: {
    '& .MuiFormControlLabel-root': {
      background: 'rgba(196, 196, 196, 0.05)',
      border: '2px solid #7B7B7B',
      borderRadius: '6px',
      height: '70px',
      margin: '0 0 15px'
    },
    '& .MuiFormControlLabel-root.checked': {
      background: 'rgba(44, 233, 218, 0.15)',
      border: '2px solid #2CE9DA',
      borderRadius: '6px'
    },
    '& .MuiFormControlLabel-root svg.MuiSvgIcon-root': {
      width: '30px',
      height: '30px',
      fill: 'none',
      borderRadius: '50%',
      border: '2px solid #4B4B4B'
    },
    '& .MuiFormControlLabel-root.checked svg.MuiSvgIcon-root': {
      background: '#67e9da',
      padding: '4px',
      fill: '#000',
      color: '#060606',
      border: '0px solid #4B4B4B'
    },
    '& .MuiRadio-root': {
      padding: '20px'
    },
    '& .MuiFormControlLabel-label': {
      color: '#fff',
      fontSize: '20px',
      lineHeight: '26px',
      fontWeight: 'bold'
    }
  },
  subTitle: {
    fontWeight: 'bold',
    fontSize: '24px',
    lineHeight: '38px',
    color: '#FFFFFF',
    fontFamily: 'Lato',
    textAlign: 'center',
    margin: '38px 0 0 0',
    letterSpacing: '0.1px'
  },
  PlanTerms: {
    border: '2px solid #7B7B7B',
    borderRadius: '6px',
    marginBottom: '15px',
    '& tr': {
      '& td': {
        fontSize: '20px',
        color: 'rgb(255 255 255 / 60%)',
        lineHeight: '32px',
        letterSpacing: '0.2px',
        borderBottom: '0',
        '&:nth-of-type(odd)': {
          color: '#ffffff',
          minWidth: '250px',
          fontWeight: '700',
          width: 'calc(100% - 55%)'
        }
      }
    }
  },
  termsagree: {
    width: '100%'
  }
}));

const PaymentPlanTerms = () => {
  const classes = useStyles();
  const [state, dispatch] = useContext(VanguardMem);
  const {
    memebership_data: {
      membershipdata: { user_contract }
    }
  } = state;
  const paymentPlan = JSON.parse(user_contract.payment_plan_meta);

  const handleChange = (e) => e.preventDefault();
  return (
    <div>
      <Typography variant="h3" className={classes.mainTitle}>
        Payment Plan Terms
      </Typography>
      <Typography variant="h5" className={classes.subTitle}>
        Here are the payment plan terms for your membership.
      </Typography>
      <Grid
        container
        xs={10}
        justify="center"
        style={{
          margin: '45px auto'
        }}
      >
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={0}
          // style={{ margin: '20px 0 20px' }}
        >
          {/* <Grid container spacing={0} style={{ margin: '20px 0' }}> */}
          <Grid container spacing={0} style={{ alignItems: 'center' }}>
            <TableContainer className={classes.PlanTerms}>
              <Table className={classes.table}>
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.TableCell}>
                      Name of membership
                    </TableCell>
                    <TableCell className={classes.TableCell}>
                      {paymentPlan.name_of_membership}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    {paymentPlan?.attedence_limit ? (
                      <>
                        <TableCell className={classes.TableCell}>
                          Attendance limit
                        </TableCell>
                        <TableCell className={classes.TableCell}>
                          {paymentPlan.attedence_limit}
                        </TableCell>
                      </>
                    ) : (
                      <>
                        <TableCell className={classes.TableCell}>
                          Number of Sessions
                        </TableCell>
                        <TableCell className={classes.TableCell}>
                          {paymentPlan.number_of_session}
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.TableCell}>
                      Expiration
                    </TableCell>
                    <TableCell className={classes.TableCell}>
                      {paymentPlan.expiration}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.TableCell}>
                      Programs
                    </TableCell>
                    <TableCell className={classes.TableCell}>
                      {paymentPlan.programe.join()}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.TableCell}>
                      Discount
                    </TableCell>
                    <TableCell className={classes.TableCell}>
                      {paymentPlan.discount}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.TableCell}>
                      Auto-Renew
                    </TableCell>
                    <TableCell className={classes.TableCell}>
                      {paymentPlan.auto_renew}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

            <TableContainer className={classes.PlanTerms}>
              <Table className={classes.table}>
                <TableBody>
                  <TableRow>
                    <TableCell>Initial commitment</TableCell>
                    <TableCell>{paymentPlan.initial_commitment}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Payment option</TableCell>
                    <TableCell>{paymentPlan.payment_option}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Start date</TableCell>
                    <TableCell>{paymentPlan.start_date}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Expiration date</TableCell>
                    <TableCell>{paymentPlan.expiration_date}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

            <TableContainer className={classes.PlanTerms}>
              <Table className={classes.table}>
                <TableBody>
                  <TableRow>
                    <TableCell>Setup cost</TableCell>
                    <TableCell>{paymentPlan.setup_cost}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Setup tax</TableCell>
                    <TableCell>{paymentPlan.setup_tax}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <TableContainer className={classes.PlanTerms}>
              <Table className={classes.table}>
                <TableBody>
                  <TableRow>
                    <TableCell>Commitment cost</TableCell>
                    <TableCell>{paymentPlan.commitment_cost}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Commitment tax</TableCell>
                    <TableCell>{paymentPlan.commitment_tax}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Commitment total</TableCell>
                    <TableCell>{paymentPlan.commitment_total}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Billed on</TableCell>
                    <TableCell>{paymentPlan.billed_on}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

            {paymentPlan.auto_renew === 'Yes' ? (
              <TableContainer className={classes.PlanTerms}>
                <Table className={classes.table}>
                  <TableBody>
                    <TableRow>
                      <TableCell>Renewal commitment</TableCell>
                      <TableCell>{paymentPlan.renewal_commitment}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Payment option</TableCell>
                      <TableCell>
                        {paymentPlan.renewal_payment_option}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Renewal cost</TableCell>
                      <TableCell>{paymentPlan.renewal_cost}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Renewal tax</TableCell>
                      <TableCell>{paymentPlan.renewal_tax}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Renewal total</TableCell>
                      <TableCell>{paymentPlan.renewal_total}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Billed on</TableCell>
                      <TableCell>{paymentPlan.renewal_billed_on}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            ) : null}
            <TableContainer className={classes.PlanTerms}>
              <Table className={classes.table}>
                <TableBody>
                  <TableRow>
                    <TableCell>Initial payment (due now)</TableCell>
                    <TableCell>
                      <span style={{ color: '#2CE9DA', fontWeight: 'bold' }}>
                        {paymentPlan.intial_payment
                          || paymentPlan.total_payment}
                      </span>
                    </TableCell>
                  </TableRow>
                  {paymentPlan.auto_renew === 'Yes' && (
                    <TableRow>
                      <TableCell>Renewal payments</TableCell>
                      <TableCell>
                        <span style={{ color: '#2CE9DA', fontWeight: 'bold' }}>
                          {paymentPlan.renewal_payment}
                        </span>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <Box className={classes.termsagree}>
              <Grid
                container
                direction="row"
                style={{
                  justifyContent: 'flex-end'
                }}
              >
                <Grid
                  item
                  className={classes.cutom_vanguard_FormControl}
                  style={{
                    margin: '10px 0',
                    width: '185px'
                  }}
                >
                  <InputLabel
                    htmlFor="age-required"
                    required
                    className={classes.cutom_select_Label}
                    // style={{ margin: '0px 0 5px' }}
                  >
                    INITIALS HERE
                  </InputLabel>
                  <FormControl
                    control="input"
                    value={user_contract.sign_initials}
                    onCut={handleChange}
                    onPaste={handleChange}
                    onCopy={handleChange}
                    onChange={(e) =>
                      dispatch(
                        setContractInitials(validateString(e.target.value))
                      )
                    }
                    maxLength={3}
                    style={{
                      margin: '0 0'
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      {/* </Grid> */}
    </div>
  );
};

export default PaymentPlanTerms;
