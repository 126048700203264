import React from 'react';
import PropType from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import Typography from '@material-ui/core/Typography';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import styles from './DetailView/detailView.style';
import X from '../../assets/image/x.png';

function DeleteDialog({ classes, handleDelete }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button className={classes.Deletebutton} onClick={handleClickOpen}>
        DELETE
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div style={{ width: 394 }}>
          <DialogTitle id="alert-dialog-title">
            <img
              alt="Plusicon"
              src={X}
              style={{ display: 'inline-block', verticalAlign: 'sub' }}
            />
            <Typography
              // color="#000"
              variant="h5"
              component="div"
              className={classes.title}
              style={{ display: 'inline-block', marginLeft: '10px' }}
            >
              Delete workout
            </Typography>
          </DialogTitle>
          <DialogContent>
            <p
              style={{
                fontWeight: 'normal',
                fontSize: '14px',
                lineHeight: '18px',
                letterSpacing: '0.002em',
                color: '#7F7F7F',
                padding: '10px 0'
              }}
            >
              Are you sure you want to permanently delete this workout?
            </p>
          </DialogContent>
          <DialogActions
            style={{
              padding: '20px 20px'
            }}
          >
            <Button
              variant="outlined"
              onClick={handleClose}
              style={{
                height: '48px',
                color: '#7F7F7F',
                fontWeight: 'bold'
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleDelete}
              autoFocus
              className={classes.Deletebtn}
            >
              delete
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}

DeleteDialog.defaultProps = {
  classes: null
};

DeleteDialog.propTypes = {
  classes: PropType.object,
  handleDelete: PropType.func.isRequired
};
export default withStyles(styles)(DeleteDialog);
