/* eslint-disable operator-linebreak */
import React, { memo, useState, useCallback, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Grid,
  withStyles,
  Card,
  Breadcrumbs,
  Typography,
  Box,
  InputBase,
  Paper,
  ThemeProvider
} from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
import Pagination from '@material-ui/lab/Pagination';
import { useDispatch, useSelector } from 'react-redux';
import LoadingView from 'src/views/common/loading';
import ReducerConstants from 'src/Redux/Reducer.constants';
import { exportMembersPaymentByPlan } from 'src/services/reports.services';
import {
  getplanMem,
  getSession
} from 'src/Redux/MembershipReducer/MembershipReducer.actions';
import { getMembersPaymentPlanReports } from 'src/Redux/ReportsReducer/reports.reducer.actions';
import { getPaymentPlans } from 'src/Redux/SettingReducer/SettingReducer.actions';
import ApplyFilter from 'src/views/common/ApplyFilter/applyFilter';
import { debounce } from 'lodash';
import CancelDialogTwo from 'src/views/common/CommonDialogBox/CancelDialogTwo';
import useChangeTitle from 'src/views/common/CustomHooks/useTitleChange';
import JBButton from '../../common/JBButton/JBButton';
import ImportIcon from '../../../assets/image/upload.png';
import PaymentPlanTable from './AllTables/PaymentPlanTable/PaymentPlanTable';
import PaymentPlanFilters from './Filters/PaymentPlanFilter/PaymentPlanFilters';
import PieChart from '../chart/PieChart';
import { getMembershipPaymentPieData } from '../Reports.utils';
import styles from './MembersReports.style';

const themeInstance = {
  overrides: {
    MuiBreadcrumbs: {
      root: {
        color: '#949494',
        textTransform: 'uppercase',
        fontSize: '20px',
        lineHeight: '24px',
        fontWeight: '700'
      },
      li: {
        '& a': {
          color: '#949494',
          textTransform: 'uppercase',
          fontSize: '20px',
          lineHeight: '24px'
        },
        '@media (max-width: 1440px)': {
          '& a': {
            fontSize: '18px'
          },
          '& h3': {
            fontSize: '18px'
          }
        },
        '& h3': {
          margin: '0 0'
        }
      },
      separator: {
        marginLeft: '12px',
        marginRight: '12px'
      }
    }
  }
};

const PaymentPlan = memo(({ classes }) => {
  useChangeTitle('Members by Payment Plan | Jungle Alliance');
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const { data: data_reports } = useSelector(
    (state) => state.reportsReducer.reportList
  );
  const { isFetching, data, error } = useSelector(
    (state) => state.reportsReducer.membersReports?.byPaymentPlan
  );
  const MembersPaymentSearch = useSelector(
    (state) => state.reportsReducer.membersReports.byPaymentPlanFil.search
  );
  const [search, setSearch] = useState(MembersPaymentSearch);

  const { sorting } = useSelector(
    (state) => state.reportsReducer.membersReports?.byPaymentPlanFil
  );
  const filteredTags = useSelector(
    (state) => state.reportsReducer.membersReports?.byPaymentPlanFil.tags
  );
  const customFilter = useSelector(
    (state) =>
      state.reportsReducer.membersReports?.byPaymentPlanFil.customFilter
  );
  const SearchQuery = useCallback(
    debounce(
      (q) => dispatch(getMembersPaymentPlanReports(ReducerConstants.search, q)),
      1500
    ),
    []
  );

  const isStarred = data_reports?.members_report.filter(
    (report) => report.id === 4
  )[0].is_starred;

  const { avg_payment_plan, list_membership_type } = data || {};
  const setDirection = (name) => {
    if (sorting.sort_name === name) {
      return sorting.sort_by === 'asc' ? 'asc' : 'dsc';
    }
    return 'asc';
  };

  const isActive = (sortlabel) => {
    if (sorting.sort_name === sortlabel) return true;
    return false;
  };

  const setSort = (sortlabel) => {
    dispatch(getMembersPaymentPlanReports(ReducerConstants.sorting, sortlabel));
  };

  const Paginate = (val) => {
    if (val !== list_membership_type.current_page) {
      return dispatch(getMembersPaymentPlanReports(ReducerConstants.page, val));
    }
    return null;
  };
  const PaymentFilter = (filter, updata) => {
    dispatch(getMembersPaymentPlanReports(filter, updata));
  };
  const handleReset = () => {
    dispatch(getMembersPaymentPlanReports(ReducerConstants.resetFilter));
    setSearch('');
  };
  useEffect(() => {
    dispatch(getplanMem());
    dispatch(getSession());
    dispatch(getPaymentPlans());
    return () => {
      handleReset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!data_reports || !data) return <LoadingView />;
  if (error) return <p>{error}</p>;
  return (
    <div className={classes.root}>
      <div className="title-bk">
        <Box css={{ marginBottom: '20px' }}>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={0}
          >
            <Grid item xs={9}>
              <Box display="inline-flex">
                <ThemeProvider theme={themeInstance}>
                  <Breadcrumbs aria-label="breadcrumb">
                    <div color="inherit">Reports</div>
                    <div
                      onClick={() => setOpen(true)}
                      style={{
                        cursor: 'pointer'
                      }}
                    >
                      members reports
                    </div>
                    <Typography variant="h3" color="Primary">
                      <span>Members by Payment Plan</span>
                    </Typography>
                  </Breadcrumbs>
                </ThemeProvider>
              </Box>
              <Box
                display="inline-flex"
                ml="10px"
                css={{ verticalAlign: 'text-bottom' }}
              >
                {isStarred === 1 && (
                  <StarIcon
                    style={{
                      color: '#FFC700'
                    }}
                  />
                )}
              </Box>
            </Grid>
            <Grid
              item
              xs={3}
              style={{
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              <JBButton
                type="outlined"
                isImg={ImportIcon}
                component={Link}
                disabled={list_membership_type.data.length === 0}
                onClick={(e) => {
                  e.preventDefault();
                  exportMembersPaymentByPlan({
                    search: MembersPaymentSearch,
                    sort_name: sorting.sort_name,
                    sort_by: sorting.sort_by,
                    ...customFilter
                  });
                }}
                style={{ marginLeft: '10px' }}
              >
                Export
              </JBButton>
            </Grid>
          </Grid>
        </Box>
      </div>

      <Card className={classes.mainCard}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={0}
        >
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={0}
          >
            <Grid
              item
              xs={12}
              justify="center"
              alignItems="center"
              textAlign="center"
            >
              {avg_payment_plan.length === 0 ? null : (
                <Box css={{ textAlign: 'center' }}>
                  <Box display="inline-block">
                    <PieChart
                      className={classes.BkChart}
                      data={getMembershipPaymentPieData(avg_payment_plan)}
                    />
                  </Box>
                </Box>
              )}
            </Grid>
          </Grid>

          <Grid item xs={6}>
            <Grid container direction="row" alignItems="center" spacing={0}>
              <Grid item xs={9}>
                <div className={classes.search}>
                  <InputBase
                    fullWidth
                    placeholder="Search…"
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                      SearchQuery(e.target.value);
                    }}
                    classes={{
                      root: classes.inputRoot,
                      input: classes.searchInput
                    }}
                    InputProps={{
                      'aria-label': 'search'
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs>
                <JBButton
                  type="outlined"
                  onClick={handleReset}
                  style={{ marginLeft: '10px' }}
                >
                  reset
                </JBButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} justify="flex-end" className="MuiGrid-container">
            <Box css={{ width: '240px' }}>
              <ApplyFilter>
                <PaymentPlanFilters
                  getFilData={(val1, val2) => {
                    PaymentFilter(ReducerConstants.addcustom, [val1, val2]);
                  }}
                />
              </ApplyFilter>
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <div>
            {filteredTags?.map((tags) => (
              <div
                className={classes.DTags}
                key={tags?.id}
                style={{ display: 'inline-block' }}
              >
                <Box display="inline-block" mr={1}>
                  <label>
                    {tags?.name}
                    <span
                      className={classes.DTagsClose}
                      onClick={() =>
                        PaymentFilter(ReducerConstants.removecustom, tags)
                      }
                    >
                      ×
                    </span>
                  </label>
                </Box>
              </div>
            ))}
          </div>
        </Grid>
        {isFetching || !data ? (
          <LoadingView />
        ) : error ? (
          <p>{error}</p>
        ) : (
          <Fragment>
            {' '}
            <Grid item xs={12}>
              <Box mt="25px" />
              <PaymentPlanTable
                values={list_membership_type?.data}
                isActive={isActive}
                setSort={setSort}
                setDirection={setDirection}
              />
            </Grid>
            {list_membership_type?.data.length !== 0 && (
              <Grid
                item
                xs={12}
                container
                direction="row"
                alignItems="center"
                style={{ marginTop: 24 }}
              >
                <Grid item xs={6} justify="flex-start">
                  {list_membership_type?.from &&
                  list_membership_type?.to > 1 ? (
                    <Paper elevation={0} style={{ color: '#A9A9A9' }}>
                      {`${list_membership_type?.from} - ${list_membership_type?.to} of ${list_membership_type?.total}`}
                    </Paper>
                  ) : null}
                </Grid>
                <Grid
                  item
                  xs={6}
                  justify="flex-end"
                  style={{ display: 'flex' }}
                >
                  <Pagination
                    count={list_membership_type?.last_page}
                    color="primary"
                    variant="outlined"
                    page={list_membership_type?.current_page}
                    onChange={(_, val) => Paginate(val)}
                    shape="rounded"
                  />
                </Grid>
              </Grid>
            )}
          </Fragment>
        )}
      </Card>
      <CancelDialogTwo setOpen={open} handleClose={() => setOpen(false)} />
    </div>
  );
});

PaymentPlan.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(PaymentPlan);
