import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { ROUTES } from 'src/constant/Constants';
import { get } from 'js-cookie';
import LoginView from '../../views/auth/LoginView';
import NotFoundView from '../../views/errors/NotFoundView';
import RegisterView from '../../views/auth/RegisterView';
// import TopBar from './TopBar';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#f9f9fa',
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 0 // 64
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}));

const MainLayout = () => {
  const classes = useStyles();
  const token = localStorage.getItem('x-token');
  const refreshtoken = localStorage.getItem('qid');
  const roleid = JSON.parse(get('jbrole') || '{}')?.role_id || 3;
  if (token && refreshtoken) {
    if (roleid === 1) {
      return <Redirect to={ROUTES.WORKOUT_LIST} />;
    }
    return <Redirect to={ROUTES.CLASSES_LIST} />;
  }

  return (
    <div className={classes.root}>
      {/* <TopBar /> */}
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            <Switch>
              <Route path={ROUTES.APP_REGISTER} component={RegisterView} />
              <Route path={ROUTES.APP_LOGIN} component={LoginView} />
              <Redirect from="/" to={ROUTES.APP_LOGIN} />
              <Route path="*" component={NotFoundView} />
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
