const styles = (theme) => ({
  root: {
    marginTop: 0,
    margin: '0px 20px 20px',
    padding: '0px 20px 20px'
  },
  mainCard: {
    boxShadow: '4px 0px 16px rgba(37, 40, 43, 0.08)',
    borderRadius: '5px',
    background: '#FFFF',
    padding: 20
  },
  button: {
    background: '#01847A',
    color: '#FFF',
    padding: '15px 24px',
    borderRadius: '2px;',
    boxShadow: '0px 8px 16px rgba(0, 126, 255, 0.16);',
    float: 'right;',
    '&:hover': {
      backgroundColor: '#01847A'
    }
  },
  p_tabs: {
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px'
  },
  ptext: {
    fontSize: '14px',
    lineHeight: '24px',
    color: '#7F7F7F',
    fontWeight: 'normal'
  },
  bktitle: {
    fontSize: '20px',
    lineHeight: '26px',
    color: '#000',
    fontWeight: '700',
    marginBottom: '10px'
  },

  table: {
    fontSize: '14px',
    lineHeight: '22px'
  },
  table_th: {
    backgroundColor: '#F9F9FA',
    color: '#292929'
  },
  TableCell: {
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px',
    borderBottom: '1px solid #A8A8A8',
    height: '56px',
    minHeight: '56px'
  },
  Table_td: {
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '22px',
    position: 'relative',
    padding: '12px 15px 13px 25px',
    height: '56px',
    minHeight: '56px'
  },
  colorcode: {
    width: '10px',
    height: '10px',
    position: 'absolute',
    left: '0',
    top: '21px',
    borderRadius: '50%'
  },
  button_status: {
    fontWeight: 'bold',
    fontSize: '11px'
  },
  button_status_act: {
    fontWeight: 'bold',
    fontSize: '11px',
    color: '#9B9B9B',
    border: '1px solid #949494',
    width: '100px',
    height: '24px',
    padding: '0 0',
    borderRadius: '2px',
    '&$hover': {
      backgroundColor: '#1976d2'
    }
  },
  button_status_deac: {
    fontWeight: 'bold',
    fontSize: '11px',
    color: '#01847A',
    border: '1px solid rgba(1, 132, 122, 1)',
    width: '100px',
    height: '24px',
    padding: '0 0',
    borderRadius: '2px'
  },
  selected: {
    backgroundColor: 'red !important'
  },
  search: {
    position: 'relative',
    height: '48px',
    marginLeft: 0,
    width: '100%'
    // marginBottom: '21px'
  },
  searchInput: {
    padding: '6px 20px 6px !important;',
    paddingRight: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    height: '35px',
    border: '1px solid #a9a9a9'
  },
  hr: {
    border: '1px solid rgb(196 196 196 / 0.4)',
    display: 'inline-block',
    width: '100%',
    margin: '20px 0'
  },
  cutom_Switch_Label: {
    display: 'inline-block',
    marginRight: '30px',
    verticalAlign: 'middle',
    fontSize: '14px',
    fontWeight: '700',
    color: '#000'
  },
  DTags: {
    marginTop: '20px',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px',
    color: '#292929',
    '& label': {
      background: '#EAEAEA',
      borderRadius: '10px',
      padding: '8px 16px',
      display: 'block'
    }
  },
  DTagsClose: {
    color: '#7F7F7F',
    marginLeft: '5px',
    cursor: 'pointer',
    border: '1px solid #7F7F7F',
    borderRadius: '50%',
    fontSize: '14px',
    padding: '0px 4.5px',
    width: '20px',
    height: '20px',
    lineHeight: 'normal',
    fontWeight: 'bolder',
    maxWidth: '20px',
    maxHeight: '20px',
    display: 'inline-block'
  },
  cutom_select_Label: {
    fontSize: '12px',
    '&:focus ': {
      outline: '0'
    }
  },
  inputdisabled: {
    '& .MuiInputBase-input.Mui-disabled': {
      background: '#F9F9FA'
    }
  },
  BoxBtnAPI: {
    margin: '27px 15px 0px 10px',
    width: '40px'
  },
  taxBtnDel: {
    width: '40px',
    height: '40px',
    minWidth: '40px',
    border: '1px solid #e5e5e5',
    boxSizing: 'border-box',
    borderRadius: '50%'
  },
  Payinfobk: {},
  Paybk: {
    fontSize: '14px',
    fontWeight: 'bold',
    display: 'inline-block',
    marginRight: '25px',
    '& span': {
      color: '#949494',
      marginRight: '4px'
    },
    '& label': {
      color: '#000'
    }
  },
  cardimage: {
    border: '1px solid #EAEAEA',
    borderRadius: '4px',
    width: '97px',
    height: '63px',
    display: 'inline-flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& img': {
      display: 'block',
      width: '64px',
      height: '20px'
    }
  },
  cardnum: {
    color: '#000',
    '& label': {
      fontSize: '20px',
      fontWeight: '700',
      lineHeight: '26px',
      letterSpacing: '0.2px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '0 0px 0 4px'
    }
  },
  cardnumDots: {
    position: 'relative',
    marginTop: '-14px',
    '&:before': {
      content: "'....'",
      fontSize: '30px',
      lineHeight: 'normal',
      display: 'inline-flex',
      padding: '0 7px 0 0',
      justifyContent: 'center',
      alignItems: 'center'
    }
  },
  APIBtnDel: {
    width: '40px',
    height: '40px',
    minWidth: '40px',
    border: '1px solid #e5e5e5',
    boxSizing: 'border-box',
    borderRadius: '50%'
  },
  SyncIconSVG: {
    animation: 'circleSVG 10s linear infinite'
  }
});
export default styles;
