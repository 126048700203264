import authhttp from './authHttp.services';

export const getClassWOD = async (id, date) => {
  const forClassUrl = 'api/getClassesByWorkout';
  const { data } = await authhttp.get(forClassUrl, {
    params: {
      pid: id,
      date
    }
  });
  return data;
};

export const getWODs = async (values) => {
  const getWOD = 'api/workouts';
  // eslint-disable-next-line
  try {
    const {
      data: { data }
    } = await authhttp.get(getWOD, {
      params: {
        ...values
      }
    });
    return data;
  } catch (err) {
    throw err;
  }
};

export const editWOD = async (id) => {
  const editUrl = `api/workouts/${id}/edit`;
  // eslint-disable-next-line
  try {
    const {
      data: { data }
    } = await authhttp.get(editUrl);
    return data;
  } catch (err) {
    throw err;
  }
};

export const delWOD = async (id) => {
  const delWODUrl = `api/workouts/${id}`;
  const {
    data: { success }
  } = await authhttp.del(delWODUrl);
  return success;
};

export const detailWOD = async (id) => {
  const detailUrl = `api/workouts/${id}`;
  const {
    data: { data }
  } = await authhttp.get(detailUrl);
  return data;
};

export const createCopyWOD = async (values) => {
  const copyWODUrl = 'api/workouts';
  const {
    data: { data }
  } = await authhttp.post(copyWODUrl, values);
  return data;
};

export const updateWOD = async (values, id) => {
  const updateWODUrl = `api/workouts/${id}`;
  const data = await authhttp.put(updateWODUrl, values);
  return data;
};

export const createWOD = async (val) => {
  const createWODUrl = 'api/workouts';
  const { data } = await authhttp.post(createWODUrl, val);
  return data;
};

export const toStarred = async (id, starred) => {
  const starWODUrl = 'api/star_workouts';
  const Values = {
    id,
    starred
  };
  const { data } = await authhttp.post(starWODUrl, Values);
  return data;
};

export const getAllTags = async () => {
  const getTagUrl = 'api/get_tags';
  const Value = {
    module_name: 'workout'
  };
  const {
    data: {
      data: { tags }
    }
  } = await authhttp.post(getTagUrl, Value);
  return tags;
};

export const exportWOD = async (ids) => {
  const exportUrl = 'api/workout_export';
  const Values = {
    ids
  };
  authhttp
    .post(exportUrl, Values, {
      responseType: 'blob'
    })
    .then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'workout.xlsx');
      document.body.appendChild(link);
      link.click();
    });
};

export const addTagsWOD = async (ids, tags) => {
  const addTagsUrl = 'api/add_workouts_tags';
  const Value = {
    ids,
    tags
  };
  const data = await authhttp.post(addTagsUrl, Value);
  return data;
};

export const removeTagsWOD = async (ids, tags) => {
  const addTagsUrl = 'api/remove_workouts_tags';
  const Value = {
    ids,
    tags
  };
  const data = await authhttp.post(addTagsUrl, Value);
  return data;
};

export const updateSetting = async (val) => {
  const settingUrl = 'api/settings';
  const data = await authhttp.post(settingUrl, val);
  return data;
};

export const getWODDetailByCls = async (id) => {
  const DetailUrl = 'api/get_workout_details';
  const {
    data: { data }
  } = await authhttp.get(DetailUrl, {
    params: {
      class_id: id
    }
  });
  return data;
};
export const getSetting = async () => {
  const url = 'api/settings/workout/edit';
  const {
    data: { data }
  } = await authhttp.get(url);
  return data;
};
