import React from 'react';
import PropTypes from 'prop-types';
import { TableHead, TableRow, TableCell, Checkbox } from '@material-ui/core';

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    numSelected,
    rowCount,
    headCells,
    isDisabled
  } = props;
  return (
    <TableHead>
      <TableRow>
        <TableCell
          padding="checkbox"
          style={{ borderBottom: '1px solid rgb(168, 168, 168)' }}
        >
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            disabled={isDisabled}
            inputProps={{ 'aria-label': 'select all desserts' }}
            color="primary"
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'left' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            className={classes.TabCell}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.defaultProps = {
  isDisabled: false
};

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
  headCells: PropTypes.array.isRequired,
  isDisabled: PropTypes.bool
};

export default EnhancedTableHead;
