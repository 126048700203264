const getValidateWeb = (data) => {
  // eslint-disable-next-line no-useless-escape
  const urlRgex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/i;
  if (!data?.api_title || data?.api_title?.trim().length === 0) {
    return { error: 'Title is required' };
  }
  if (!data?.api_key || data?.api_key?.trim().length === 0) {
    return { error: 'API Key is required' };
  }
  if (!data?.api_url) {
    return { error: 'URl is required' };
    // eslint-disable-next-line no-else-return
  } else if (!urlRgex.test(data?.api_url)) {
    return { error: 'Invalid Url' };
  }

  return { data: true };
};

export default getValidateWeb;
