/* eslint-disable arrow-parens */
import React, { useEffect, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { Grid, withStyles, Breadcrumbs, Typography } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import { openNoti } from 'src/Redux/global/global.actions';
import queryString from 'query-string';
import Tabulation from 'src/views/common/Tabulation/tabulation';
import { getusermembership } from 'src/services/user.service';
import {
  editMember,
  getHoldList,
  deleteMember
} from 'src/services/Members.module';
import NotFoundView from 'src/views/errors/NotFoundView';
import LoadingView from 'src/views/common/loading';
import CancelDialogTwo from 'src/views/common/CommonDialogBox/CancelDialogTwo';
import { getErrorMsg } from 'src/utils/ErrorHandling';
import styles from './NewTemplate.style';
import NewTemplateTabs from './NewTemplateTabs';
import Action from './Action';
import DeleteDialog from './DeleteDialog';
import { updatemem } from './EditMembership.utils';
import {
  membershipReducer,
  initialValue
} from '../Memberships/NewMembership/NewMemship.reducer';
import { updateAllData } from '../Memberships/NewMembership/NewMembership.actions';
import {
  discountsvalid,
  paymentOpt,
  ProgaccessValid
} from './EditUserMembsership.validations';

function EditMembership(props) {
  const { OpenNoti } = props;
  const [value, setTabVal] = useState(0);
  const [state, dispatch] = useReducer(membershipReducer, initialValue);
  const [userdata, setuserdata] = useState(null);
  const [errorfetch, seterrfetch] = useState(false);
  const location = useLocation();
  const { memId, memberId } = queryString.parse(location.search);
  const [cancelDialog, setcancelDialog] = useState(false);
  const [Opt, setOpt] = useState(null);
  const history = useHistory();
  useEffect(() => {
    Promise.all([
      editMember(atob(memberId)),
      getusermembership(memId),
      getHoldList({ user_id: atob(memberId) })
    ])
      .then((res) => {
        setOpt(
          res[2]?.filter(
            (memships) => memships.user_member_id === parseInt(memId, 10)
          )
        );
        dispatch(
          updateAllData({ ...updatemem(res[1]), reset: updatemem(res[1]) })
        );
        setuserdata(res[0]);
      })
      .catch(() => {
        seterrfetch(true);
      });
  }, [memId, memberId]);

  if (!memId || !memberId || errorfetch) return <NotFoundView />;
  if (!userdata || !Opt) return <LoadingView />;

  const handleNext = (val) => {
    if (value === 0) {
      const { error } = paymentOpt(state.data);
      if (error) {
        OpenNoti(error, 'error');
      } else {
        setTabVal(val);
      }
    } else if (value === 1) {
      const { error } = discountsvalid(state.data);
      if (error) {
        OpenNoti(error, 'error');
      } else {
        setTabVal(val);
      }
    } else if (value === 2) {
      const { error } = ProgaccessValid(state.data);
      if (error) {
        OpenNoti(error, 'error');
      } else {
        setTabVal(val);
      }
    }
  };
  const handleDelete = () => {
    deleteMember(memId)
      .then(
        () => history.push(`/app/people/member/profile?memberId=${memberId}`)
        // eslint-disable-next-line function-paren-newline
      )
      // .then(() => getInActiveMembers())
      // .thne(() => getActiveMembers())
      .then(() => {
        // eslint-disable-next-line no-unused-expressions
        state.data.check_paid_invoice
          ? OpenNoti('Membership successfully deactivated')
          : OpenNoti('Membership successfully deleted');
      })

      .catch((err) => OpenNoti(getErrorMsg(err), 'error'));
  };
  const { classes } = props;
  return (
    <div className={classes.root}>
      <div className="title-bk">
        {/* <h3>
          Classes <label>/</label> membership <label>/</label>
          <span>new template</span>
        </h3> */}
        <Breadcrumbs
          aria-label="breadcrumb"
          style={{
            margin: '0px 0 20px'
          }}
        >
          <div color="inherit">People</div>
          <div
            color="inherit"
            onClick={() => setcancelDialog(true)}
            style={{
              cursor: 'pointer'
            }}
          >
            {userdata.first_name + ' ' + userdata.last_name}
          </div>
          <Typography variant="h3" color="Primary">
            <span>membership</span>
          </Typography>
        </Breadcrumbs>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={0}
          style={{ margin: '0 0 20px' }}
        >
          <Grid
            item
            xs={8}
            style={{
              display: 'flex'
            }}
          >
            {userdata.profile_pic ? (
              <img
                src={userdata.profile_pic}
                alt="UserIcon"
                style={{
                  verticalAlign: 'sub',
                  width: '40px',
                  height: '40px',
                  marginRight: '15px',
                  borderRadius: '50%'
                }}
              />
            ) : (
              <Avatar
                className={classes.Avataruname}
                style={{
                  marginRight: '15px'
                }}
              >
                {userdata.first_name[0] + ' ' + userdata.last_name[0]}
              </Avatar>
            )}
            <h1 style={{ margin: '0 0' }}>
              {userdata.first_name + ' ' + userdata.last_name}: Edit{' '}
              {`"${state.data.payment_data.membership.name}"`}
            </h1>
          </Grid>
          <Grid
            item
            xs={4}
            className={classes.btnBlock}
            style={{
              justifyContent: 'flex-end',
              display: 'flex'
            }}
          >
            {state.data.type === 'drop_in' ? (
              ''
            ) : (
              <>
                {state.data.status === 1 && (
                  <Action
                    reducer={[state, dispatch]}
                    data={{
                      started_date: state.data.start_date,
                      user_id: memId,
                      user_member_id: atob(memberId)
                    }}
                    updateUi={(res) =>
                      setOpt(
                        res.filter(
                          (memships) =>
                            memships.user_member_id === parseInt(memId, 10)
                        )
                      )
                    }
                  />
                )}
              </>
            )}

            <DeleteDialog
              checkPaid={state.data.check_paid_invoice}
              checkStatus={state.data.status}
              handleDelete={handleDelete}
              checkMembershipTitle={state.data.payment_data.membership.name}
            />
          </Grid>
        </Grid>
      </div>

      <Tabulation
        label1="payment options"
        label2={
          state.data.type === 'drop_in' ? 'Pricing' : 'Discounts & pricing'
        }
        label3="Programs"
        style={{ padding: '0 0', boxShadow: 'none !important' }}
        value={value}
        Change={handleNext}
      >
        <NewTemplateTabs
          Tabs={value}
          Opt={[Opt, setOpt]}
          start={state.data.start_date}
          reducer={[state, dispatch]}
          setTab={(val) => setTabVal(val)}
        />
      </Tabulation>
      <CancelDialogTwo
        setOpen={cancelDialog}
        handleClose={() => setcancelDialog(false)}
      />
    </div>
  );
}

EditMembership.propTypes = {
  classes: PropTypes.object.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  OpenNoti: PropTypes.func.isRequired
};

const mapToDispatch = (dispatch) => ({
  OpenNoti: (msg, type) => dispatch(openNoti(msg, type))
});

export default connect(
  null,
  mapToDispatch
)(withStyles(styles, { withTheme: true })(EditMembership));
