import React, { useState } from 'react';
import PropType from 'prop-types';
import {
  Paper,
  Button,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  makeStyles
} from '@material-ui/core';
import { ReactComponent as DeactivateIcon } from '../../../../assets/image/power_d.svg';
import { ReactComponent as ActivateIcon } from '../../../../assets/image/power.svg';
import EditIcon from '../../../../assets/image/editIcon.png';
import EditCategoryDialog from '../EditCategoryDialog';
import ChangeStatusDialog from '../ChangeStatusDialog';

const useStyles = makeStyles({
  table: {
    minWidth: '100%'
  },
  tableLink: {
    color: '#01847A',
    fontWeight: '700'
  },
  TableCell: {
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px',
    // borderBottom: '1px solid #A8A8A8',
    height: '56px',
    minHeight: '56px'
  },
  Tablemian: {
    // border: '1px solid #A8A8A8',
    borderRadius: '4px'
    // maxHeight: '300px'
  },
  button_status_deac: {
    fontWeight: 'bold',
    fontSize: '11px',
    color: '#01847A',
    border: '1px solid rgba(1, 132, 122, 1)',
    width: '100px',
    height: '24px',
    padding: '0 0',
    borderRadius: '2px'
  },
  button_status_act: {
    fontWeight: 'bold',
    fontSize: '11px',
    color: '#9B9B9B',
    border: '1px solid #949494',
    width: '100px',
    height: '24px',
    padding: '0 0',
    borderRadius: '2px',
    '&$hover': {
      backgroundColor: '#1976d2'
    }
  }
});

const CategoryTable = (props) => {
  const classes = useStyles();
  const { values } = props;
  const [selected] = useState([]);
  const [StatusEditData, setStatusEditData] = useState([]);
  const [StatusEditopen, setStatusEditOpen] = useState(false);
  const [statusDialog, setStatusDialog] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  // const handleClick = (name) => {
  //   const selectedIndex = selected.indexOf(name);
  //   let newSelected = [];
  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, name);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1)
  //     );
  //   }
  //   setSelected(newSelected);
  // };
  const isSelected = (name) => selected.indexOf(name) !== -1;
  return (
    <TableContainer
      component={Paper}
      elevation={0}
      className={classes.Tablemian}
    >
      <Table className={classes.table} aria-label="simple table">
        <TableHead
          style={{ background: '#F9F9FA', backgroundColor: '#F9F9FA' }}
        >
          <TableRow>
            <TableCell style={{ width: '75%' }} className={classes.TableCell}>
              Label
            </TableCell>
            <TableCell>&nbsp;</TableCell>
            <TableCell>&nbsp;</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {values.map((row) => (
            <TableRow
              hover
              // role="checkbox"
              aria-checked={isSelected(row.id)}
              tabIndex={-1}
              key={row.id}
              selected={isSelected(row.id)}
              style={{
                backgroundColor: '#FFF'
              }}
            >
              <TableCell
                style={{
                  position: 'relative',
                  color: '#01847A',
                  fontWeight: '700',
                  width: '20%'
                }}
              >
                <span
                  className={classes.tableLink}
                  onClick={() => {
                    setStatusEditOpen(true);
                    setStatusEditData(row);
                  }}
                  style={{
                    cursor: 'pointer'
                  }}
                >
                  {row.label}
                </span>
              </TableCell>
              <TableCell
                style={{
                  width: '2%'
                }}
              >
                {/* <EditCategoryDialog
                  item={row}
                  Dialogtitle="Edit Revenue Category"
                /> */}
                <Button
                  // className={classes.actionbtn}
                  onClick={() => {
                    setStatusEditOpen(true);
                    setStatusEditData(row);
                  }}
                  size="small"
                >
                  <img alt="EditIcon" src={EditIcon} />
                </Button>
              </TableCell>
              <TableCell
                align="right"
                style={{
                  width: '5%'
                }}
              >
                {row.status === 1 ? (
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    className={classes.button_status_deac}
                    onClick={() => {
                      setStatusDialog(true);
                      setSelectedItem(row);
                    }}
                  >
                    <DeactivateIcon />
                    <span style={{ marginLeft: 5 }}>deactivate</span>
                  </Button>
                ) : (
                  <Button
                    variant="outlined"
                    size="small"
                    className={classes.button_status_act}
                    onClick={() => {
                      setStatusDialog(true);
                      setSelectedItem(row);
                    }}
                  >
                    <ActivateIcon />
                    <span style={{ marginLeft: 5 }}>Activate</span>
                  </Button>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <ChangeStatusDialog
        open={statusDialog}
        handleClose={() => setStatusDialog(false)}
        item={selectedItem}
      />
      {StatusEditopen === true && (
        <EditCategoryDialog
          item={StatusEditData}
          Dialogtitle="Edit Revenue Category"
          setOpen={StatusEditopen}
          handleClose={() => setStatusEditOpen(false)}
        />
      )}
    </TableContainer>
  );
};
CategoryTable.propTypes = {
  values: PropType.array.isRequired
};

export default CategoryTable;
