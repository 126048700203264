import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, Route, Switch, useHistory } from 'react-router-dom';
import { ROUTES } from 'src/constant/Constants';
import {
  Grid,
  withStyles,
  AppBar,
  Tabs,
  Tab,
  Card,
  Breadcrumbs,
  Typography
} from '@material-ui/core';
import { getMasterInvoices } from 'src/Redux/MasterHQ/FinancialReducer/financial.reducer.actions';
import { useDispatch } from 'react-redux';
import { getActiveVal } from './financialTabs.utils';
import styles from './financial.style';
import InvoicesTab from './InvoicesTab';
import SettingsTab from './settingsTab';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

const FinancialTabs = memo((props) => {
  const { classes } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMasterInvoices());
    // dispatch(getInvoiceSettings());
  }, [dispatch]);

  const history = useHistory();
  const Tabslocation = getActiveVal(history.location.pathname);
  return (
    <div className={classes.root}>
      <div className="title-bk">
        <div style={{ marginBottom: '20px' }}>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={0}
          >
            <Grid item xs={12}>
              <Breadcrumbs aria-label="breadcrumb">
                <Link color="inherit" to={ROUTES.MASTER_HQ_AFFILIATES}>
                  financial
                </Link>
                <Typography variant="h3" color="Primary">
                  {(() => {
                    switch (Tabslocation) {
                      case 0:
                        return <span>Invoices</span>;
                      case 1:
                        return <span>Settings</span>;
                      default:
                        return null;
                    }
                  })()}
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
        </div>
      </div>

      <Card className={classes.mainCard}>
        <AppBar
          position="static"
          style={{
            backgroundColor: 'transparent',
            boxShadow: 'none'
          }}
        >
          <Tabs
            value={Tabslocation}
            indicatorColor="primary"
            textColor="primary"
            fullWidth
          >
            <Tab
              label="Invoices"
              component={Link}
              to={ROUTES.MASTER_HQ_FINANCIAL_INVOICES}
              className={classes.p_tabs}
              {...a11yProps(0)} // eslint-disable-line
            />
            <Tab
              label="Settings"
              // disabled
              component={Link}
              to={ROUTES.MASTER_HQ_FINANCIAL_SETTINGS}
              className={classes.p_tabs}
              {...a11yProps(1)} // eslint-disable-line
            />
          </Tabs>
        </AppBar>

        <Switch>
          <Route
            path={ROUTES.MASTER_HQ_FINANCIAL_SETTINGS}
            component={SettingsTab}
          />
          <Route
            path={ROUTES.MASTER_HQ_FINANCIAL_INVOICES}
            component={InvoicesTab}
          />
        </Switch>
      </Card>
    </div>
  );
});

FinancialTabs.propTypes = {
  classes: PropTypes.object.isRequired
  // eslint-disable-next-line react/no-unused-prop-types
};

export default withStyles(styles)(FinancialTabs);
