import axios from 'axios';
import { BASE_URL } from 'src/constant/Constants';
import { openNoti } from 'src/Redux/global/global.actions';
import { store } from 'src/Redux/rootReducer';

axios.interceptors.request.use(
  async (config) => {
    /* eslint-disable */
    config.baseURL = BASE_URL;
    const token = localStorage.getItem('x-token');
    config.headers.Authorization = `Bearer ${token}`;
    return config;
    /* eslint-enable */
  },
  () => {
    store.dispatch(openNoti('SomeThing went wrong', 'error'));
  }
);

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  del: axios.delete
};
