import React, { useState } from 'react';
import Proptype from 'prop-types';
import { WithContext as ReactTags } from 'react-tag-input';
// import { COUNTRIES } from './countries';
import './style.css';

const KeyCodes = {
  comma: 188,
  enter: 13,
  space: 32,
  tab: 9
};

const delimiters = [
  KeyCodes.comma,
  KeyCodes.enter,
  KeyCodes.space,
  KeyCodes.tab
];

const CustomTags = (props) => {
  const { handleDelete, handleAddition, tags, placeholderModule } = props;
  // eslint-disable-next-line
  const [refs, setRefs] = useState(false);
  return (
    <div>
      <ReactTags
        tags={tags}
        placeholder={`Tags. Use tags to organise your library of ${placeholderModule}.`}
        //  suggestions={suggestions}
        delimiters={delimiters}
        handleDelete={handleDelete}
        handleAddition={handleAddition}
        maxLength={20}
        allowDragDrop={false}
        allowUnique={false}
        handleInputFocus={() => setRefs(true)}
        // style={{ border: refs && tags.length === 0 ? '1px solid red' : null }}
        {...props} // eslint-disable-line
      />
      {/* {refs && tags.length === 0 ? (
        <span style={{ fontSize: '10px', color: 'red' }}>Please Add Tags</span>
      ) : null} */}
    </div>
  );
};

CustomTags.propTypes = {
  tags: Proptype.array.isRequired,
  handleDelete: Proptype.func.isRequired,
  handleAddition: Proptype.func.isRequired,
  placeholderModule: Proptype.string
};

CustomTags.defaultProps = {
  placeholderModule: 'workouts'
};

export default CustomTags;
