export const DummyDataCB = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Select/Deselect all',
    cellName: 'name'
  }
];

export const DummyValues = [
  {
    id: 1,
    name: 'Bodyweight: A'
  },
  {
    id: 2,
    name: 'Bodyweight: B'
  },
  {
    id: 3,
    name: 'Bodyweight: C'
  },
  {
    id: 4,
    name: 'Bodyweight: D'
  },
  {
    id: 5,
    name: 'Fight Factory'
  },
  {
    id: 6,
    name: 'Stretch'
  }
];
