import { validateFloat, validateInt, validPrice } from 'src/utils/validations';
import { memshipType } from './NewMemship.reducer';

export const updateAllData = (payload) => ({
  type: memshipType.updateAlldate,
  payload
});

export const changeMembership = (payload, memType) => ({
  type: memshipType.membershipid,
  payload,
  memType
});

export const getpayments = (payload) => ({
  type: memshipType.getallpayments,
  payload
});

export const getpaymentdata = (payload) => ({
  type: memshipType.fetchpaymentdata,
  payload
});

export const updatepaymentid = (payload) => ({
  type: memshipType.updatepaymentid,
  payload
});

export const changeDate = (payload) => ({
  type: memshipType.changestartdate,
  payload
});

export const changeAttlmt = (payload) => ({
  type: memshipType.editattendlmt,
  payload
});

export const changebilled = (payload) => ({
  type: memshipType.editinitialbilled,
  payload
});

export const changeinitials = (payload) => ({
  type: memshipType.editinitialsweek,
  payload: validateInt(payload)
});

export const chngeAttuses = (payload) => ({
  type: memshipType.editattuses,
  payload: validateInt(payload)
});

export const chngeAtttype = (payload) => ({
  type: memshipType.editatttype,
  payload
});

export const chngenofsess = (payload) => ({
  type: memshipType.editnumsession,
  payload: validateInt(payload)
});

export const chngeusedsess = (payload) => ({
  type: memshipType.editusedsession,
  payload: validateInt(payload)
});

export const chngeinitialPrice = (payload) => ({
  type: memshipType.chngeinitialPrice,
  payload
});

export const chngerenPrice = (payload) => ({
  type: memshipType.chngerenwalPrice,
  payload
});

export const chngeautorenew = () => ({
  type: memshipType.chngeautorenew
});

export const chngeautorenwal = (payload) => ({
  type: memshipType.chngeautorenwal,
  payload: validateInt(payload)
});

export const chngerenewbilled = (payload) => ({
  type: memshipType.chngeautorenbilled,
  payload
});

export const chngeinidistype = (payload) => ({
  type: memshipType.changeinidistype,
  payload
});

export const chngeinidisamt = (payload) => ({
  type: memshipType.changeinidisamount,
  payload: validPrice(validateFloat(payload))
});

export const chngesetdistype = (payload) => ({
  type: memshipType.changesetdistype,
  payload
});

export const chngesetdisamt = (payload) => ({
  type: memshipType.changesetdisamount,
  payload: validPrice(validateFloat(payload))
});

export const chngrendistype = (payload) => ({
  type: memshipType.changerendistype,
  payload
});

export const chngerendisamt = (payload) => ({
  type: memshipType.changerendisamount,
  payload: validPrice(validateFloat(payload))
});

export const chngememfee = (actionKey, payload) => ({
  type: memshipType.chngeiniprice,
  actionKey,
  payload: validPrice(validateFloat(payload))
});

export const chngesetupfee = (actionKey, payload) => ({
  type: memshipType.chngesetupprice,
  actionKey,
  payload: validPrice(validateFloat(payload))
});

export const chngerenwalfee = (actionKey, payload) => ({
  type: memshipType.chngerenewalfee,
  actionKey,
  payload: validPrice(validateFloat(payload))
});

export const changeprogacess = (payload) => ({
  type: memshipType.acesss_program,
  payload
});

export const chngesigncontract = (payload) => ({
  type: memshipType.changesigncontract,
  payload
});

export const changeinifulltax = (payload) => ({
  type: memshipType.changeinitaxes,
  payload
});

export const changerenfulltax = (payload) => ({
  type: memshipType.changerenewaltaxes,
  payload
});
export const updateEndDate = (payload) => ({
  type: memshipType.changeenddate,
  payload
});
