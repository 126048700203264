import React from 'react';
import { Grid, withStyles } from '@material-ui/core';
import PropType from 'prop-types';
import styles from './NewTemplate.style';
import DiscountTable from '../Memberships/NewMembership/AllTable/DiscountTable';
import SessDiscountTable from '../Memberships/NewMembership/AllTable/SessionTable/DiscountTable';
import FinalPricingTable from '../Memberships/NewMembership/AllTable/FinalPricingTable';
import SessionFinalPricingTable from '../Memberships/NewMembership/AllTable/SessionTable/SessionFinalPricingTable';
import DropinFinalPricingTable from '../Memberships/NewMembership/AllTable/DropInTable/DropinFinalPricingTable';

const DiscountsPricing = ({ classes, reducer }) => {
  const [state] = reducer;

  const isEditable = Boolean(state.data.is_editable);

  return (
    <div>
      {state.data.type === 'drop_in' ? (
        ''
      ) : (
        <>
          <Grid
            container
            spacing={0}
            xs={12}
            disableGutters
            direction="row"
            style={{
              margin: '30px 0'
            }}
          >
            <Grid
              item
              xs={3}
              style={{
                marginTop: '0px',
                paddingLeft: '0px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start'
              }}
            >
              <h3>Discounts & Promo Codes</h3>
              <p className={classes.ptext}>
                Select any discounts you want to apply to this member plan.
              </p>
            </Grid>
            <Grid item xs={9}>
              <Grid
                container
                direction="row"
                spacing={0}
                style={{
                  alignItems: 'center'
                }}
              >
                {state.data.type === 'plan' ? (
                  <DiscountTable
                    reducer={reducer}
                    isEditable={state.data.is_editable}
                  />
                ) : (
                  <SessDiscountTable
                    reducer={reducer}
                    isEditable={state.data.is_editable}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </>
      )}

      <Grid
        container
        direction="row"
        spacing={0}
        style={{
          margin: '40px 0px 0',
          alignItems: 'flex-start'
        }}
      >
        <Grid
          item
          xs={3}
          style={{
            marginTop: '0px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            paddingLeft: '0'
          }}
        >
          <h3>Pricing</h3>
          <p className={classes.ptext}>
            The final pricing details for this member plan.
          </p>
        </Grid>
        <Grid item xs={9}>
          <Grid
            container
            direction="row"
            spacing={0}
            style={{
              alignItems: 'center'
            }}
          >
            {/* {state.data.type === 'session' ? (
              <SessionFinalPricingTable
                reducer={reducer}
                isEditable={isEditable}
              />
            ) : (
              <FinalPricingTable reducer={reducer} isEditable={isEditable} />
            )} */}

            {(() => {
              switch (state.data.type) {
                case 'session':
                  return (
                    <SessionFinalPricingTable
                      reducer={reducer}
                      isEditable={isEditable}
                    />
                  );
                case 'plan':
                  return (
                    <FinalPricingTable
                      reducer={reducer}
                      isEditable={isEditable}
                    />
                  );
                case 'drop_in':
                  return (
                    <DropinFinalPricingTable
                      reducer={reducer}
                      isEditable={isEditable}
                    />
                  );
                default:
                  return null;
              }
            })()}
          </Grid>
        </Grid>
      </Grid>
      {/* <Grid
        item
        xs={12}
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          margin: '15px 0'
        }}
      >
        <JBButton type="outlined">Cancel</JBButton>
        <JBButton type="primary" style={{ marginLeft: '15px' }}>
          <span>Save</span>
        </JBButton>
      </Grid> */}
    </div>
  );
};

DiscountsPricing.propTypes = {
  classes: PropType.object.isRequired,
  reducer: PropType.array.isRequired
};

export default withStyles(styles)(DiscountsPricing);
