import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid, Paper, InputBase, Box, withStyles } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import LoadingView from 'src/views/common/loading';
import ApplyFilter from 'src/views/common/ApplyFilter/applyFilter';
import _ from 'lodash';
import filterType from 'src/Redux/Reducer.constants';
import useChangeTitle from 'src/views/common/CustomHooks/useTitleChange';
import SignedTable from './Signedtable';
import styles from './Waivers.style';
import { getSignedWav } from '../../../Redux/WaiverReducer/WaiverReducer.actions';
import SignedTabFilters from './Filters/SignedTabFilters';

const SignedTab = (props) => {
  const {
    setWaivers: { isFetching, data, error },
    filteredTags,
    WaiverFilter,
    WaivSearch,
    WaivSort,
    classes
  } = props;
  const [search, setSearch] = useState(WaivSearch);
  useChangeTitle('Signed | Jungle Alliance');
  const SearchQuery = useCallback(
    _.debounce((q) => WaiverFilter(filterType.search, q), 1500),
    []
  );

  if (error) return <p>{error}</p>;

  const setDirection = (name) => {
    if (WaivSort.sort_name === name) {
      return WaivSort.sort_by === 'asc' ? 'asc' : 'dsc';
    }
    return 'asc';
  };

  const isActive = (sortlabel) => {
    if (WaivSort.sort_name === sortlabel) return true;
    return false;
  };

  const setSort = (sortlabel) => {
    WaiverFilter(filterType.sorting, sortlabel);
  };

  const Paginate = (val) => {
    if (val !== data.current_page) {
      return WaiverFilter(filterType.page, val);
    }
    return null;
  };

  return (
    <Box p="24px">
      <Grid
        container
        direction="row"
        justify="start"
        alignItems="center"
        spacing={0}
        style={{ margin: '0px 0 20px' }}
      >
        <Grid container disableGutters style={{ marginBottom: '15px' }}>
          <Box mr="15px">
            <ApplyFilter>
              <SignedTabFilters
                getFilData={(val, val2) =>
                  WaiverFilter(filterType.addcustom, [val, val2])
                }
              />
            </ApplyFilter>
          </Box>
          <Grid item xs>
            <div className={classes.search}>
              <InputBase
                fullWidth
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                  SearchQuery(e.target.value);
                }}
                placeholder="Search…"
                classes={{
                  root: classes.inputRoot,
                  input: classes.searchInput
                }}
                InputProps={{
                  'aria-label': 'search'
                }}
              />
            </div>
          </Grid>
        </Grid>
        {isFetching ? (
          <LoadingView />
        ) : (
          <>
            <div>
              {filteredTags.map((tags) => (
                <div
                  className={classes.DTags}
                  key={tags.id}
                  style={{ margin: '0 0 15px', display: 'inline-block' }}
                >
                  <Box display="inline-block" mr={1}>
                    <label>
                      {tags.name}
                      <span
                        className={classes.DTagsClose}
                        onClick={() =>
                          WaiverFilter(filterType.removecustom, tags)
                        }
                      >
                        ×
                      </span>
                    </label>
                  </Box>
                </div>
              ))}
            </div>
            <Grid item xs={12} className={classes.SimpleTablestyle}>
              <SignedTable
                values={data.data}
                setDirection={setDirection}
                isActive={isActive}
                setSort={setSort}
              />
            </Grid>

            <Grid
              item
              xs={12}
              container
              direction="row"
              alignItems="center"
              style={{ marginTop: 24 }}
            >
              <Grid item xs={6} justify="flex-start">
                {data.from && data.to > 1 ? (
                  <Paper elevation={0} style={{ color: '#A9A9A9' }}>
                    {`${data.from} - ${data.to} of ${data.total}`}
                  </Paper>
                ) : null}
              </Grid>
              <Grid item xs={6} justify="flex-end" style={{ display: 'flex' }}>
                <Pagination
                  count={data.last_page}
                  color="primary"
                  variant="outlined"
                  page={data.current_page}
                  // eslint-disable-next-line no-shadow
                  onChange={(_, val) => Paginate(val)}
                  shape="rounded"
                />
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};

SignedTab.propTypes = {
  classes: PropTypes.object.isRequired,
  setWaivers: PropTypes.object.isRequired,
  WaiverFilter: PropTypes.func.isRequired,
  WaivSearch: PropTypes.string.isRequired,
  WaivSort: PropTypes.object.isRequired,
  filteredTags: PropTypes.array.isRequired
};

const mapToDispatch = (dispatch) => ({
  WaiverFilter: (filter, data) => dispatch(getSignedWav(filter, data))
});

const mapToState = (state) => ({
  setWaivers: state.WaiverReducer.Signed,
  WaivSearch: state.WaiverReducer.SignedFilters.search,
  WaivSort: state.WaiverReducer.SignedFilters.sorting,
  filteredTags: state.WaiverReducer.SignedFilters.tags
});

export default connect(
  mapToState,
  mapToDispatch
)(withStyles(styles)(SignedTab));
