import Types from './GymSetup.types';

const getGymSetup = (type, action, state, data) => {
  switch (type) {
    case Types.getSteps: {
      const Data = () => {
        switch (action) {
          case Types.isFetching:
            return {
              ...state,
              isFetching: true
            };
          case Types.error:
            return {
              ...state,
              isFetching: false,
              error: data
            };
          case Types.isCompleted:
            return {
              ...state,
              data,
              isFetching: false
            };
          default:
            return state;
        }
      };
      return Data();
    }
    default:
      return state;
  }
};

export default getGymSetup;
