import React, { useState, useEffect } from 'react';
import PropType from 'prop-types';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import moment from 'moment';
import {
  Grid,
  Card,
  Typography,
  Box,
  Breadcrumbs,
  withStyles,
  TableRow,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  TableHead,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  Avatar
} from '@material-ui/core';
import FormControl from 'src/views/common/FormControl/formControl';
import TextareaControl from 'src/views/common/FormControl/TextareaControl';
import queryString from 'query-string';
import {
  downloadWaiver,
  getMemWaiver,
  // sendEmailWaiver,
  PostEmailWaiver,
  getWaiverTemp
} from 'src/services/Contracts.services';
import { useDispatch } from 'react-redux';
import NotFoundView from 'src/views/errors/NotFoundView';
import useGymSettingReducer from 'src/views/common/CustomHooks/useGymSettingRefresh';
import LoadingView from 'src/views/common/loading';
import { openNoti } from 'src/Redux/global/global.actions';
import { ROUTES } from 'src/constant/Constants';
// import UserIcon from '../../../assets/image/user.png';
import JBButton from '../../common/JBButton/JBButton';
import ImageNotFound from '../../../assets/image/image-not-found.jpg';
import DownloadIcon from '../../../assets/image/download.png';
import { ReactComponent as Emailicon } from '../../../assets/image/email.svg';
import styles from './Waivers.style';
import { jsonToHtml } from './newWaiver.utils';

const WaiversSigned = ({ classes, location }) => {
  const [data, setData] = useState(null);
  const [errorFetch, seterrfetch] = useState(false);
  const [waiversList, setWaiversList] = useState(null);
  const { waiverSignId } = queryString.parse(location.search);
  const dispatch = useDispatch();
  /* eslint-disable */
  const waiverData =
    data &&
    waiversList &&
    waiversList.find((waiver) => waiver.id === data.waiver_id);
  /* eslint-enable */

  useEffect(() => {
    getMemWaiver(waiverSignId) // pass id
      .then((res) => {
        setData(res);
      })
      .catch(() => seterrfetch(true));
  }, [waiverSignId]);

  useEffect(() => {
    getWaiverTemp()
      .then((res) => {
        setWaiversList(res);
      })
      .catch(() => seterrfetch(true));
  }, []);

  const { dateformat } = useGymSettingReducer();

  const handleEmailSend = () => {
    PostEmailWaiver(waiverSignId, {
      questionnaire_meta: jsonToHtml(data.questionnaire_meta)
    })
      .then(() => {
        dispatch(openNoti('Email send successfully'));
      })
      .catch((err) => console.log(err));
  };

  if (errorFetch || !waiverSignId) return <NotFoundView />;

  if (!data || !waiversList) return <LoadingView />;
  return (
    <div className={classes.root}>
      <div className="title-bk">
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="inherit" to={ROUTES.DOCUMENTS_CONTRACTS}>
            documents
          </Link>
          <Link color="inherit" to={ROUTES.DOCUMENTS_WAIVERS}>
            waivers
          </Link>
          {/* <Link color="inherit" to={ROUTES.DOCUMENTS_WAIVERS_SIGNED}>
            Signed
          </Link> */}
          <Typography variant="h3" color="Primary">
            <span>
              Signed
              {/* {data
                ? data.user_details.first_name +
                  ' ' +
                  data.user_details.last_name
                : 'Waiver Name'} */}
            </span>
          </Typography>
        </Breadcrumbs>
      </div>
      {data ? (
        <div>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={0}
            style={{ margin: '20px 0 20px' }}
          >
            <Grid item xs={6}>
              <h1 style={{ margin: '0 0', display: 'flex' }}>
                {/* <img
                  src={
                    data.user_details.profile_pic === null
                      ? UserIcon
                      : data.user_details.profile_pic
                  }
                  className={classes.useravatar}
                  alt="UserIcon"
                /> */}
                {data.user_details.profile_pic ? (
                  <img
                    src={data.user_details.profile_pic}
                    alt="UserIcon"
                    className={classes.useravatar}
                  />
                ) : (
                  <Avatar
                    className={classes.Avataruname}
                    style={{
                      fontSize: '1.1rem',
                      marginRight: '15px'
                    }}
                  >
                    {data.user_details.first_name[0]
                      + ' '
                      + data.user_details.last_name[0]}
                  </Avatar>
                )}
                {data.user_details.first_name
                  + ' '
                  + data.user_details.last_name}
                : Waivers
              </h1>
            </Grid>
            <Grid
              item
              xs={6}
              className={classes.btnBlock}
              style={{ justifyContent: 'flex-end', display: 'flex' }}
            >
              <JBButton
                type="outlined"
                // component={Link}
                // to={ROUTES.PEOPLE_CREATE_MEMBER}
                onClick={() => {
                  // jsonToHtml(data.questionnaire_meta);

                  downloadWaiver(
                    { questionnaire_meta: jsonToHtml(data.questionnaire_meta) },
                    waiverSignId
                  );
                }}
              >
                download
                <img
                  src={DownloadIcon}
                  alt="img"
                  style={{ verticalAlign: 'sub', marginLeft: '10px' }}
                />
              </JBButton>
              <JBButton
                type="outlined"
                style={{ marginLeft: '15px' }}
                onClick={handleEmailSend}
              >
                send
                <Emailicon
                  style={{ verticalAlign: 'sub', marginLeft: '10px' }}
                />
              </JBButton>
            </Grid>
          </Grid>
          <Card className={classes.mainCard}>
            <Grid container direction="row">
              <Grid item xs={3}>
                &nbsp;
              </Grid>
              <Grid item xs={9}>
                <p
                  style={{
                    fontSize: '14px',
                    lineHeight: '18px',
                    color: '#7F7F7F',
                    fontWeight: 'normal',
                    marginBottom: '20px'
                  }}
                >
                  {waiverData.name} - READ ONLY
                </p>
              </Grid>
            </Grid>
            <Grid container direction="row" style={{ marginBottom: '40px' }}>
              <Grid item xs={3}>
                <h3 className={classes.headingTitle}>
                  Personal Information <br />
                  &amp; Location
                </h3>
              </Grid>
              <Grid item xs={9}>
                <TableContainer
                  className={clsx(classes.PlanTerms, classes.Pinfo)}
                >
                  <Table className={classes.table}>
                    <TableHead className={classes.tbhead}>
                      <TableRow>
                        <TableCell>Full Name</TableCell>
                        <TableCell>Email Address</TableCell>
                        <TableCell>Phone</TableCell>
                        <TableCell>Gender</TableCell>
                        <TableCell>DOB</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell className={classes.TableCell}>
                          {data.user_details.first_name
                            + ' '
                            + data.user_details.last_name}
                        </TableCell>
                        <TableCell className={classes.TableCell}>
                          {data.user_details.email}
                        </TableCell>
                        <TableCell className={classes.TableCell}>
                          {data.user_details.mobile_no || null}
                        </TableCell>
                        <TableCell
                          className={classes.TableCell}
                          style={{
                            textTransform: 'capitalize'
                          }}
                        >
                          {data.user_details.gender}
                        </TableCell>
                        <TableCell className={classes.TableCell}>
                          {moment(data.user_details.dob).format(
                            `${dateformat}`
                          )}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <TableContainer
                  className={clsx(classes.PlanTerms, classes.Pinfo)}
                >
                  <Table className={classes.table}>
                    <TableHead className={classes.tbhead}>
                      <TableRow>
                        <TableCell>Street Address</TableCell>
                        <TableCell>City</TableCell>
                        <TableCell>Province</TableCell>
                        <TableCell>Country</TableCell>
                        <TableCell>Zip</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell className={classes.TableCell}>
                          {data.user_details.address}
                          {!data.user_details.address2
                            ? '  '
                            : ',' + data.user_details.address2}
                        </TableCell>
                        <TableCell className={classes.TableCell}>
                          {data.user_details.city}
                        </TableCell>
                        <TableCell className={classes.TableCell}>
                          {' '}
                          {data.user_details.province}
                        </TableCell>
                        <TableCell className={classes.TableCell}>
                          {data.user_details.country}
                        </TableCell>
                        <TableCell className={classes.TableCell}>
                          {data.user_details.postal_code}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>

                <TableContainer
                  className={clsx(classes.PlanTerms, classes.Pinfo)}
                >
                  <Table className={classes.table}>
                    <TableHead className={classes.tbhead}>
                      <TableRow>
                        <TableCell>Emergency contact name</TableCell>
                        <TableCell>Emergency contact phone</TableCell>
                        <TableCell>Status when signed</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell className={classes.TableCell}>
                          {data.user_details.eme_contact_name}
                        </TableCell>
                        <TableCell className={classes.TableCell}>
                          {data.user_details.eme_contact_phone}
                        </TableCell>
                        <TableCell className={classes.TableCell}>
                          {data.sign_status === 1 ? 'Active' : 'Inactive'}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
            <Grid container direction="row" className={classes.WaiverTerms}>
              <Grid item xs={3}>
                <h3 className={classes.headingTitle}>Waiver Terms</h3>
              </Grid>
              <Grid item xs={9}>
                <Box>
                  <Typography variant="h5" className={classes.MasubTitle}>
                    Please read through carefully
                  </Typography>
                  <div
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: data.waiver_terms_meta.terms
                    }}
                  />
                  <Box mt="30px" />
                </Box>
                <Box className={classes.termsagree}>
                  <FormControl
                    control="input"
                    label=""
                    disabled
                    value={data.waiver_terms_meta.sign_initials}
                    required
                    style={{ margin: '0 0' }}
                  />
                </Box>
              </Grid>
            </Grid>
            <Box mt="40px" />
            {data.questionnaire_meta.length > 0 && (
              <Grid container direction="row" className={classes.WaiverTerms}>
                <Grid item xs={3}>
                  <h3 className={classes.headingTitle}>Questionnaire</h3>
                </Grid>
                <Grid item xs={9}>
                  <Box>
                    <Grid container spacing={0}>
                      {data.questionnaire_meta.length > 0 ? (
                        data.questionnaire_meta.map((item, index) => {
                          if (item.que_type === 'Multiple Choice') {
                            if (item.allow_multiple_ans) {
                              return (
                                <Grid item xs={12}>
                                  <Box mt={index === 0 ? '0px' : '30px'}>
                                    <h6 className={classes.MasubTitle}>
                                      {item.que}
                                      {item.required ? '*' : ''}
                                    </h6>
                                    <Grid
                                      container
                                      spacing={0}
                                      style={{ marginTop: '10px' }}
                                    >
                                      <Grid item xs={4}>
                                        {item.answer_choices.map((item1) => {
                                          for (
                                            let i = 0;
                                            i < item.ans.length;
                                            // eslint-disable-next-line no-plusplus
                                            i++
                                          ) {
                                            if (item.ans[i] === item1.id) {
                                              return (
                                                <Box>
                                                  <FormControlLabel
                                                    control={
                                                      <Checkbox
                                                        checked
                                                        name="checkedB"
                                                        color="primary"
                                                      />
                                                    }
                                                    label={item1.value}
                                                  />
                                                </Box>
                                              );
                                            }
                                          }
                                          return (
                                            <Box>
                                              <FormControlLabel
                                                control={
                                                  <Checkbox
                                                    checked={false}
                                                    name="checkedB"
                                                    color="primary"
                                                  />
                                                }
                                                label={item1.value}
                                              />
                                            </Box>
                                          );
                                        })}
                                      </Grid>
                                    </Grid>
                                  </Box>
                                </Grid>
                              );
                            }
                            return (
                              <Grid item xs={12}>
                                <Box mt={index === 0 ? '0px' : '30px'}>
                                  <h6 className={classes.MasubTitle}>
                                    {item.que}
                                    {item.required ? '*' : ''}
                                  </h6>
                                  <RadioGroup
                                    value={item.ans}
                                    style={{ marginTop: '10px' }}
                                  >
                                    {item.answer_choices.map((item1) => {
                                      return (
                                        <FormControlLabel
                                          value={item1.id}
                                          control={<Radio color="primary" />}
                                          label={item1.value}
                                        />
                                      );
                                    })}
                                  </RadioGroup>
                                </Box>
                              </Grid>
                            );
                          }
                          if (item.que_type === 'Yes/No') {
                            return (
                              <Grid item xs={12}>
                                <Box mt={index === 0 ? '0px' : '30px'}>
                                  <h6
                                    className={classes.MasubTitle}
                                    style={{
                                      textTransform: 'capitalize'
                                    }}
                                  >
                                    {item.que}
                                    {item.required ? '*' : ''}
                                  </h6>
                                  <RadioGroup
                                    value={item.ans}
                                    row
                                    style={{ marginTop: '10px' }}
                                  >
                                    <FormControlLabel
                                      value={1}
                                      control={<Radio color="primary" />}
                                      label="Yes"
                                      className={classes.RadioControl}
                                    />
                                    <FormControlLabel
                                      value={0}
                                      control={<Radio color="primary" />}
                                      label="No"
                                      className={classes.RadioControl}
                                    />
                                  </RadioGroup>
                                </Box>
                              </Grid>
                            );
                          }
                          if (item.que_type === 'Yes/No with follow-up') {
                            return (
                              <Grid item xs={12}>
                                <Box mt={index === 0 ? '0px' : '30px'}>
                                  <h6 className={classes.MasubTitle}>
                                    {item.que}
                                    {item.required ? '*' : ''}
                                  </h6>
                                  <RadioGroup
                                    value={item.ans}
                                    row
                                    style={{ marginTop: '10px' }}
                                  >
                                    <FormControlLabel
                                      value={1}
                                      control={<Radio color="primary" />}
                                      label="Yes"
                                      className={classes.RadioControl}
                                    />
                                    <FormControlLabel
                                      value={0}
                                      control={<Radio color="primary" />}
                                      label="No"
                                      className={classes.RadioControl}
                                    />
                                  </RadioGroup>
                                </Box>
                                <Box mt="30px">
                                  <h6 className={classes.MasubTitle}>
                                    {item.follow_up} {item.required ? '*' : ''}
                                  </h6>
                                  <TextareaControl
                                    value={item.followup_ans}
                                    rows={8}
                                    label=""
                                    className={classes.TextareaControlBox}
                                  />
                                </Box>
                              </Grid>
                            );
                          }
                          if (item.que_type === 'Freeform Answer Box') {
                            return (
                              <Grid item xs={12}>
                                <Box mt={index === 0 ? '0px' : '30px'}>
                                  <h6 className={classes.MasubTitle}>
                                    {item.que} {item.required ? '*' : ''}
                                  </h6>
                                  <TextareaControl
                                    value={item.ans}
                                    rows={8}
                                    label=""
                                    className={classes.TextareaControlBox}
                                  />
                                </Box>
                              </Grid>
                            );
                          }
                          return true;
                        })
                      ) : (
                        <p>No questions to show</p>
                      )}
                    </Grid>
                  </Box>
                  <Box mt="30px" />
                </Grid>
              </Grid>
            )}

            <Grid
              container
              direction="row"
              lassName={classes.SignBox}
              style={{ marginTop: '50px' }}
            >
              <Grid item xs={3}>
                <h3
                  className={classes.headingTitle}
                  style={{
                    marginTop: '9px'
                  }}
                >
                  Sign
                </h3>
              </Grid>
              <Grid item xs={9}>
                <Grid
                  container
                  justify="flex-end"
                  alignItems="center"
                  direction="row"
                >
                  <Grid item xs={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="checkedB"
                          checked={data.sign_status}
                          style={{ color: '#949494' }}
                        />
                      }
                      label="I agree to use electronic records and signatures"
                      className={classes.SignLabel}
                      style={{
                        marginRight: '0'
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <div className={classes.tbDates}>
                      <label>Date Issued: </label>
                      <span>
                        {data.date_issued}
                        {/* {data.created_at} */}
                        {/* {data.created_at &&
                          moment(data.created_at).format(`${dateformat}`)} */}
                      </span>
                      <label>Date Signed: </label>
                      <span>
                        {data.date_signed}
                        {/* {data.date_signed &&
                          moment(data.date_signed).format(`${dateformat}`)} */}
                      </span>
                    </div>
                  </Grid>
                </Grid>
                <div className={classes.tbsignatures}>
                  <img src={data.signature || ImageNotFound} alt="Signed" />
                </div>
              </Grid>
            </Grid>
          </Card>
        </div>
      ) : (
        <LoadingView />
      )}
    </div>
  );
};

WaiversSigned.propTypes = {
  classes: PropType.object.isRequired,
  location: PropType.any.isRequired
};
export default withStyles(styles)(WaiversSigned);
