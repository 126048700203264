/* eslint-disable operator-linebreak */
import React from 'react';
import PropType from 'prop-types';
import { Link } from 'react-router-dom';
import { ROUTES } from 'src/constant/Constants';
import {
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  TableSortLabel,
  Table,
  withStyles
} from '@material-ui/core';
import filterType from 'src/Redux/Reducer.constants';
import CheckIcon from '../../../../../assets/image/check.png';

const styles = () => ({
  table: {
    minWidth: '100%',
    wordBreak: 'break-word',
    '& td': {
      padding: '15px'
    },
    '@media (max-width: 1366px)': {
      '& td': {
        fontSize: '13px',
        padding: '13px'
      }
    }
  },
  tableLink: {
    color: '#01847A',
    fontWeight: '700'
  },
  TableCell: {
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px',
    height: '56px',
    minHeight: '56px',
    padding: '15px',
    '@media (max-width: 1366px)': {
      fontSize: '13px',
      wordBreak: 'keep-all',
      padding: '13px'
    }
  },
  Tablemian: {}
});

const MembershipTypeTable = (props) => {
  const { values, classes, setDirection, isActive, setSort } = props;
  const {
    sort_memberships,
    sort_membership_type,
    sort_members,
    sortstartdate,
    sortexpiredate,
    sort_member_since,
    sort_membership_location
  } = filterType;
  return (
    <TableContainer
      component={Paper}
      elevation={0}
      className={classes.Tablemian}
    >
      <Table className={classes.table} aria-label="simple table">
        <TableHead
          style={{ background: '#F9F9FA', backgroundColor: '#F9F9FA' }}
        >
          <TableRow>
            <TableCell className={classes.TableCell}>
              <TableSortLabel
                direction={setDirection(sort_members)}
                active={isActive(sort_members)}
                onClick={() => {
                  setSort(sort_members);
                }}
              >
                Member
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell}>
              <TableSortLabel
                direction={setDirection(sort_member_since)}
                active={isActive(sort_member_since)}
                onClick={() => {
                  setSort(sort_member_since);
                }}
              >
                Member since
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell}>
              <TableSortLabel
                direction={setDirection(sort_membership_location)}
                active={isActive(sort_membership_location)}
                onClick={() => {
                  setSort(sort_membership_location);
                }}
              >
                Membership location
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell}>
              <TableSortLabel
                direction={setDirection(sort_membership_type)}
                active={isActive(sort_membership_type)}
                onClick={() => {
                  setSort(sort_membership_type);
                }}
              >
                Membership type
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell}>
              <TableSortLabel
                direction={setDirection(sort_memberships)}
                active={isActive(sort_memberships)}
                onClick={() => {
                  setSort(sort_memberships);
                }}
              >
                Membership
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell}>
              <TableSortLabel
                direction={setDirection(sortstartdate)}
                active={isActive(sortstartdate)}
                onClick={() => {
                  setSort(sortstartdate);
                }}
              >
                Start date
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell}>
              <TableSortLabel
                direction={setDirection(sortexpiredate)}
                active={isActive(sortexpiredate)}
                onClick={() => {
                  setSort(sortexpiredate);
                }}
              >
                Expiration Date
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell} align="center">
              Auto renew
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {values &&
            values.map((row) => (
              <TableRow
                hover
                tabIndex={-1}
                key={row.id}
                style={{
                  backgroundColor: '#FFF'
                }}
              >
                <TableCell
                  style={{
                    width: '9.0909%'
                  }}
                >
                  <Link
                    className={classes.tableLink}
                    to={{
                      pathname: ROUTES.PEOPLE_MEMBER_PROFILE,
                      search: `memberId=${btoa(row.id)}`
                    }}
                  >
                    {row?.member}
                  </Link>
                </TableCell>
                <TableCell
                  style={{
                    width: '9.0909%'
                  }}
                >
                  {row?.member_since}
                </TableCell>
                <TableCell
                  style={{
                    width: '9.0909%'
                  }}
                >
                  {row?.membership_location}
                </TableCell>
                <TableCell
                  style={{
                    width: '9.0909%'
                  }}
                >
                  {row.memberships_type === 'drop_in'
                    ? 'drop in'
                    : row.memberships_type}
                </TableCell>
                <TableCell
                  style={{
                    width: '9.0909%'
                  }}
                  className={classes.tableLink}
                >
                  {row.membership}
                </TableCell>
                <TableCell
                  style={{
                    width: '9.0909%'
                  }}
                >
                  {row.start_date}
                </TableCell>
                <TableCell
                  style={{
                    width: '9.0909%'
                  }}
                >
                  {row.expires_date}
                </TableCell>
                <TableCell
                  style={{
                    width: '7%'
                  }}
                  align="center"
                >
                  {/* {row.active} */}
                  {row?.auto_renew === 'true' && (
                    <img src={CheckIcon} alt="img" />
                  )}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      {values?.length === 0 && (
        <p
          style={{
            margin: '10px 15px'
          }}
        >
          No Members with Memberships type to show...
        </p>
      )}
    </TableContainer>
  );
};
MembershipTypeTable.propTypes = {
  values: PropType.array.isRequired,
  classes: PropType.object.isRequired,
  setDirection: PropType.func.isRequired,
  isActive: PropType.func.isRequired,
  setSort: PropType.func.isRequired
};

export default withStyles(styles)(MembershipTypeTable);
