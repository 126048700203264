/* eslint-disable indent */
/* eslint-disable spaced-comment */
import React, { useEffect, useReducer, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
// import moment from 'moment';
import {
  Grid,
  InputLabel,
  withStyles,
  Box,
  FormControlLabel,
  TableRow,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Checkbox
} from '@material-ui/core';
import PropType from 'prop-types';
import SignaturePad from 'signature_pad';
import DOMpurify from 'dompurify';
import FormControl from 'src/views/common/FormControl/formControl';
import useGymSettingReducer from 'src/views/common/CustomHooks/useGymSettingRefresh';
import JBDatePicker from 'src/views/common/FormControl/JBDatePicker';
import { ReactComponent as BasicInformation } from '../../../assets/image/JBSteppericon/BasicInformation.svg';
import { ReactComponent as ListIcon } from '../../../assets/image/list.svg';
import { ReactComponent as BookopenIcon } from '../../../assets/image/JBSteppericon/bookopen.svg';
import { ReactComponent as EditpenIcon } from '../../../assets/image/JBSteppericon/editpen.svg';
import CustomAutoComplete from '../../common/CustomAutocomplete/commonAutocomplete';
import ColorlibStepIcon from '../../common/JBGridStepper/ColorlibStepIcon';
import JBStepper from '../../common/JBGridStepper';
import styles from './SignContract.style';
import { initialValue, SignContractReducer } from './SignContract.reducer';
import {
  getMemContract,
  updateUserContract
} from '../../../services/Contracts.services';
import {
  fetchSignCont,
  setFistname,
  setlastName,
  setpayInitials,
  setpolicyInitials,
  setSignAddress,
  setSignCity,
  setSignCountry,
  setSignDOB,
  setSignEmail,
  setSignGender,
  setSignPhone,
  setSignPostal,
  setSignProvince,
  setSignStatus
} from './SignContractReducer.actions';
import JBButton from '../../common/JBButton/JBButton';

import {
  validateContractStepOne,
  validateContractStepTwo,
  validateContractStepThree
} from './SignContract.validations';
import {
  getCountries,
  searchUserByEmail
} from '../../../services/Members.module';
import {
  /*getBilled,*/

  getSignedData,
  getSignedSave
} from './SignContract.utils';
import { AllGender } from '../../people/Members/CreateMember/CreateMember.data';
import { openNoti } from '../../../Redux/global/global.actions';
import { getErrorMsg } from '../../../utils/ErrorHandling';
import {
  getActiveMissCont,
  getAllMissCont,
  getInActiveMissCont,
  getSignedCont,
  getSigned
} from '../../../Redux/ContractReducer/ContractReducer.actions';

import NotFoundView from '../../errors/NotFoundView';
// import data from 'src/views/customer/CustomerListView/data';

function getSteps() {
  return [
    'Personal Information',
    'Payment Plan Terms',
    'Membership Policy',
    'Sign'
  ];
}

const SignContractStepper = (props) => {
  const { classes, id, OpenNoti, reloadFunc } = props;
  const [activeStep, setActiveStep] = useState(0);
  const [sign, setSign] = useState(null);
  const [countryOpts, setCountryOpts] = useState([]);
  const [signed, setSigned] = useState(false);
  const canRef = useRef(null);
  const [errorFetch, setErrFetch] = useState(false);
  const [state, dispatch] = useReducer(SignContractReducer, initialValue);
  const history = useHistory();
  useEffect(() => {
    Promise.all([getCountries(), getMemContract(id)])
      .then((res) => {
        setCountryOpts(res[0]);
        dispatch(fetchSignCont(getSignedData(res[1], res[0])));
      })
      .catch(() => setErrFetch(true));
  }, [id]);

  const { dateformat } = useGymSettingReducer();
  console.warn(dateformat);

  useEffect(() => {
    if (canRef.current) {
      const canvas = new SignaturePad(canRef.current, {
        minWidth: 0.7,
        maxWidth: 1.5,
        onBegin() {
          setSigned(true);
        },
        onEnd() {}
      });
      setSign(canvas);
    }
    if (activeStep === 3 && signed) {
      setSigned(false);
    }
  }, [activeStep, state.data.sign_status]); // eslint-disable-line

  if (errorFetch) return <NotFoundView />;
  const steps = getSteps();

  const handleNext = () => {
    if (activeStep === 0) {
      try {
        validateContractStepOne(state.data.user_details);
        if (state.data.user_details.email) {
          searchUserByEmail({ search: state.data.user_details.email })
            .then((res) => {
              if (state.data.user_details.id === res.id) {
                setActiveStep(1);
              } else {
                OpenNoti('The email has already been taken', 'error');
              }
            })
            .catch(() => setActiveStep(1));
        }
      } catch (error) {
        OpenNoti(error.message, 'error');
      }
    } else if (activeStep === 1) {
      try {
        validateContractStepTwo(state.data);
        setActiveStep(2);
        // validSignStep2
        //   .validate(state.data.payment_plan_meta)
        //   .then(() => setActiveStep(2))
        //   .catch((err) => OpenNoti(err.errors[0], 'error'));
      } catch (error) {
        OpenNoti(error.message, 'error');
      }
    } else if (activeStep === 2) {
      try {
        validateContractStepThree(state.data);
        setActiveStep(3);
        // validSignStep3(state.data.payment_plan_meta.sign_initials)
        // .validate(state.data.membership_policy_meta)
        // .then(() => setActiveStep(3))
        // .catch((err) => OpenNoti(err.errors[0], 'error'));
      } catch (error) {
        OpenNoti(error.message, 'error');
      }
    } else {
      const {
        goToHistory,
        updateActiveCont,
        updateAllCont,
        updateInActiveCont,
        updateSigned,
        getSignedUpdate
      } = props;
      if (!signed || state.data.sign_status === 0) {
        OpenNoti('Please provide signatures', 'error');
      } else {
        const signs = sign.toDataURL();
        updateUserContract(id, getSignedSave(state.data, signs))
          .then(() => updateSigned())
          .then(() => updateActiveCont())
          .then(() => updateInActiveCont())
          .then(() => updateAllCont())
          .then(() => getSignedUpdate())
          .then(() => {
            // eslint-disable-next-line no-unused-expressions
            goToHistory ? history.push(goToHistory) : reloadFunc();
          })
          .then(() => OpenNoti('Successfully Save the Contract', 'info'))
          .catch((err) => {
            OpenNoti(getErrorMsg(err), 'error');
          });
      }
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  /* eslint-disable */

  const htmlData =
    DOMpurify.sanitize(state.data.membership_policy_meta.policy_meta.policy) ||
    '';
  /* eslint-enable */

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <div>
            <Grid container spacing={2} style={{ alignItems: 'center' }}>
              <Grid item xs={4}>
                <InputLabel
                  htmlFor="age-required"
                  required
                  className={classes.cutom_select_Label}
                  style={{ padding: '5px 0' }}
                >
                  First name
                </InputLabel>
                <FormControl
                  control="input"
                  label=""
                  placeholder=""
                  onChange={(e) => dispatch(setFistname(e.target.value))}
                  value={state.data.user_details.first_name}
                  required
                  style={{ margin: '0 0' }}
                />
              </Grid>
              <Grid item xs={4}>
                <InputLabel
                  htmlFor="age-required"
                  required
                  className={classes.cutom_select_Label}
                  style={{ padding: '5px 0' }}
                >
                  Last name
                </InputLabel>
                <FormControl
                  control="input"
                  label=""
                  placeholder=""
                  onChange={(e) => dispatch(setlastName(e.target.value))}
                  value={state.data.user_details.last_name}
                  required
                  style={{ margin: '0 0' }}
                />
              </Grid>
              <Grid item xs={4}>
                <InputLabel
                  htmlFor="age-required"
                  required
                  className={classes.cutom_select_Label}
                  style={{ padding: '5px 0' }}
                >
                  Email
                </InputLabel>
                <FormControl
                  control="input"
                  label=""
                  placeholder=""
                  onChange={(e) => dispatch(setSignEmail(e.target.value))}
                  value={state.data.user_details.email}
                  required
                  style={{ margin: '0 0' }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ alignItems: 'center' }}>
              <Grid item xs={4}>
                <InputLabel
                  htmlFor="age-required"
                  required
                  className={classes.cutom_select_Label}
                  style={{ padding: '5px 0' }}
                >
                  Gender
                </InputLabel>

                <CustomAutoComplete
                  holder="Select Gender"
                  Options="name"
                  style={{ marginbottom: '0' }}
                  value={AllGender}
                  data={state.data.user_details.gender}
                  Change={(val) => dispatch(setSignGender(val))}
                />
              </Grid>
              <Grid item xs={4}>
                <InputLabel
                  htmlFor="age-required"
                  required
                  className={classes.cutom_select_Label}
                  style={{ padding: '5px 0' }}
                >
                  Street address
                </InputLabel>
                <FormControl
                  control="input"
                  label=""
                  placeholder=""
                  value={state.data.user_details.address}
                  required
                  onChange={(e) => dispatch(setSignAddress(e.target.value))}
                  style={{ margin: '0 0' }}
                />
              </Grid>
              <Grid item xs={4}>
                <InputLabel
                  htmlFor="age-required"
                  required
                  className={classes.cutom_select_Label}
                  style={{ padding: '5px 0' }}
                >
                  City
                </InputLabel>
                <FormControl
                  control="input"
                  label=""
                  placeholder=""
                  value={state.data.user_details.city}
                  required
                  onChange={(e) => dispatch(setSignCity(e.target.value))}
                  style={{ margin: '0 0' }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ alignItems: 'center' }}>
              <Grid item xs={4}>
                <InputLabel
                  htmlFor="age-required"
                  required
                  className={classes.cutom_select_Label}
                  style={{ padding: '5px 0' }}
                >
                  Province/Region
                </InputLabel>
                <FormControl
                  control="input"
                  label=""
                  placeholder=""
                  value={state.data.user_details.province}
                  required
                  onChange={(e) => dispatch(setSignProvince(e.target.value))}
                  style={{ margin: '0 0' }}
                />
              </Grid>
              <Grid item xs={4}>
                <InputLabel
                  htmlFor="age-required"
                  required
                  className={classes.cutom_select_Label}
                  style={{ padding: '5px 0' }}
                >
                  Postal code
                </InputLabel>
                <FormControl
                  control="input"
                  label=""
                  placeholder=""
                  value={state.data.user_details.postal_code}
                  required
                  onChange={(e) => dispatch(setSignPostal(e.target.value))}
                  style={{ margin: '0 0' }}
                  maxLength={10}
                />
              </Grid>
              <Grid item xs={4}>
                <InputLabel
                  htmlFor="age-required"
                  required
                  className={classes.cutom_select_Label}
                  style={{ padding: '5px 0' }}
                >
                  Country
                </InputLabel>
                <CustomAutoComplete
                  holder="Country"
                  value={countryOpts}
                  data={state.data.user_details.country}
                  Options="name"
                  Change={(e) => dispatch(setSignCountry(e))}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2} style={{ alignItems: 'center' }}>
              <Grid item xs={4}>
                <InputLabel
                  htmlFor="age-required"
                  required
                  className={classes.cutom_select_Label}
                  style={{ padding: '5px 0' }}
                >
                  Mobile Number
                </InputLabel>
                <FormControl
                  control="input"
                  label=""
                  placeholder=""
                  value={state.data.user_details.mobile_no}
                  required
                  onChange={(e) => dispatch(setSignPhone(e.target.value))}
                  style={{ margin: '0 0' }}
                  maxLength={23}
                />
              </Grid>
              <Grid item xs={5}>
                <JBDatePicker
                  label="Date of birth"
                  value={state.data.user_details.dob}
                  onChange={(e) => dispatch(setSignDOB(e))}
                  maxDate={new Date()}
                />
              </Grid>
            </Grid>
          </div>
        );
      case 1:
        return (
          <div>
            <h3 style={{ margin: '0 0 20px' }}>
              Here are the payment plan terms for your membership.
            </h3>
            <Grid container spacing={0} style={{ margin: '20px 0' }}>
              <Grid container spacing={0} style={{ alignItems: 'center' }}>
                <TableContainer className={classes.PlanTerms}>
                  <Table className={classes.table}>
                    <TableBody>
                      <TableRow>
                        <TableCell className={classes.TableCell}>
                          Name of membership
                        </TableCell>
                        <TableCell className={classes.TableCell}>
                          {state.data.payment_plan_meta.name_of_membership}
                        </TableCell>
                      </TableRow>
                      {state.data.payment_plan_meta.number_of_session ? (
                        <TableRow>
                          <TableCell className={classes.TableCell}>
                            Number of Sessions
                          </TableCell>
                          <TableCell className={classes.TableCell}>
                            {state.data.payment_plan_meta.number_of_session}
                          </TableCell>
                        </TableRow>
                      ) : null}
                      {!state.data.payment_plan_meta.number_of_session ? (
                        <TableRow>
                          <TableCell className={classes.TableCell}>
                            Attendance limit
                          </TableCell>
                          <TableCell className={classes.TableCell}>
                            {state.data.payment_plan_meta.attedence_limit}
                          </TableCell>
                        </TableRow>
                      ) : null}
                      <TableRow>
                        <TableCell className={classes.TableCell}>
                          Expiration
                        </TableCell>
                        <TableCell className={classes.TableCell}>
                          {state.data.payment_plan_meta.expiration}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.TableCell}>
                          Programs
                        </TableCell>
                        <TableCell className={classes.TableCell}>
                          {state.data.payment_plan_meta.programe.join(',')}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.TableCell}>
                          Discount
                        </TableCell>
                        <TableCell className={classes.TableCell}>
                          {state.data.payment_plan_meta.discount}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.TableCell}>
                          Auto-Renew
                        </TableCell>
                        <TableCell className={classes.TableCell}>
                          {state.data.payment_plan_meta.auto_renew}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>

                <TableContainer className={classes.PlanTerms}>
                  <Table className={classes.table}>
                    <TableBody>
                      <TableRow>
                        <TableCell>Initial commitment</TableCell>
                        <TableCell>
                          {state.data.payment_plan_meta.initial_commitment}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Payment option</TableCell>
                        <TableCell>
                          {state.data.payment_plan_meta.payment_option}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Start date</TableCell>
                        <TableCell>
                          {state.data.payment_plan_meta.start_date}
                          {/* {moment(
                            state.data.payment_plan_meta.start_date ||
                              new Date()
                          ).format(`${dateformat}`)} */}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Expiration date</TableCell>
                        <TableCell>
                          {/* eslint-disable */}

                          {state.data.payment_plan_meta.expiration_date === '-'
                            ? '-'
                            : state.data.payment_plan_meta.expiration_date

                          // moment(
                          //     state.data.payment_plan_meta.expiration_date ||
                          //       new Date()
                          //   ).format(`${dateformat}`)
                          }
                          {/* eslint-enable */}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>

                <TableContainer className={classes.PlanTerms}>
                  <Table className={classes.table}>
                    <TableBody>
                      <TableRow>
                        <TableCell>Setup cost (Including Tax)</TableCell>
                        <TableCell>
                          {state.data.payment_plan_meta.setup_cost}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Setup tax</TableCell>
                        <TableCell>
                          {state.data.payment_plan_meta.setup_tax}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <TableContainer className={classes.PlanTerms}>
                  <Table className={classes.table}>
                    <TableBody>
                      <TableRow>
                        <TableCell>Commitment cost (Including Tax)</TableCell>
                        <TableCell>
                          {state.data.payment_plan_meta.commitment_cost}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Commitment tax</TableCell>
                        <TableCell>
                          {state.data.payment_plan_meta.commitment_tax}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Commitment total (Including Tax)</TableCell>
                        <TableCell>
                          {state.data.payment_plan_meta.commitment_total}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Billed on</TableCell>
                        <TableCell>
                          {/* {state.data.payment_plan_meta.billed_on} */}
                          {state.data.payment_plan_meta.billed_on}
                          {/* {getBilled(state.data.payment_plan_meta.billed_on)} */}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                {state.data.payment_plan_meta.auto_renew === 'No' ? null : (
                  <>
                    {!state.data.payment_plan_meta.number_of_session ? (
                      <TableContainer className={classes.PlanTerms}>
                        <Table className={classes.table}>
                          <TableBody>
                            <TableRow>
                              <TableCell>Renewal commitment</TableCell>
                              <TableCell>
                                {state.data.payment_plan_meta
                                  .renewal_commitment || 'No'}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Payment option</TableCell>
                              <TableCell>
                                {state.data.payment_plan_meta
                                  .renewal_payment_option || 'No'}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                Renewal cost (Including Tax)
                              </TableCell>
                              <TableCell>
                                {state.data.payment_plan_meta.renewal_cost
                                  || 'No'}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Renewal tax</TableCell>
                              <TableCell>
                                {state.data.payment_plan_meta.renewal_tax
                                  || 'No'}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                Renewal total (Including Tax)
                              </TableCell>
                              <TableCell>
                                {state.data.payment_plan_meta.renewal_total
                                  || 'No'}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Billed on</TableCell>
                              <TableCell>
                                {/* {getBilled(
                                  state.data.payment_plan_meta.renewal_billed_on
                                )} */}
                                {state.data.payment_plan_meta.renewal_billed_on}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    ) : null}
                  </>
                )}

                <TableContainer className={classes.PlanTerms}>
                  <Table className={classes.table}>
                    <TableBody>
                      <TableRow>
                        {state.data.payment_plan_meta.number_of_session ? (
                          <TableCell>Total payment (Including Tax)</TableCell>
                        ) : (
                          <TableCell>
                            Initial payment (due now)
                            <br />
                            (Including Tax)
                          </TableCell>
                        )}

                        <TableCell>
                          <span style={{ color: '#01847a', fontWeight: '700' }}>
                            {state.data.payment_plan_meta.intial_payment
                              || state.data.payment_plan_meta.total_payment}
                          </span>
                        </TableCell>
                      </TableRow>
                      {!state.data.payment_plan_meta.number_of_session ? (
                        <TableRow>
                          <TableCell>
                            Renewal payments (Including Tax)
                          </TableCell>
                          <TableCell>
                            <span
                              style={{ color: '#01847a', fontWeight: '700' }}
                            >
                              {state.data.payment_plan_meta.renewal_payment
                                || '-'}
                            </span>
                          </TableCell>
                        </TableRow>
                      ) : null}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Box className={classes.termsagree}>
                  <Grid alignItems="center" container direction="row">
                    <Grid item xs={4}>
                      <h5 style={{ fontSize: '14px' }}>
                        Initial that you agree to these terms
                      </h5>
                    </Grid>
                    <Grid item xs={2}>
                      <FormControl
                        control="input"
                        label=""
                        placeholder=""
                        maxLength={3}
                        value={state.data.payment_plan_meta.sign_initials}
                        required
                        onChange={(e) =>
                          dispatch(setpayInitials(e.target.value))
                        }
                        style={{ margin: '0 0' }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </div>
        );
      case 2:
        return (
          <Box>
            <Box className={classes.scrollBox}>
              <Grid
                container
                spacing={0}
                style={{ alignItems: 'center', padding: '0px 0 0' }}
              >
                {/* <Grid item xs={12}>
                  <p className={classes.ptext} style={{ marginBottom: '15px' }}>
                    You must initial 1 section(s) in this policy.
                  </p>
                </Grid> */}
                {/* <Box
                  css={{
                    width: '100%'
                  }}
                >
                  <h3 style={{ margin: '0 0 20px' }}>
                    Jungle Brothers Pty Ltd Membership Agreement
                  </h3>
                </Box> */}
                <div
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: htmlData
                  }}
                  className="cke_contents_ltr cke_editable cke_editable_themed cke_show_borders"
                />
              </Grid>
            </Box>

            <Box>
              {state.data.membership_policy_meta.policy_meta.initial ? (
                <Box className={classes.termsagree}>
                  <Grid alignItems="center" container direction="row">
                    <Grid
                      item
                      style={{
                        marginRight: '15px'
                      }}
                    >
                      <h5 style={{ fontSize: '14px' }}>Initial here</h5>
                    </Grid>
                    <Grid item xs={2}>
                      <FormControl
                        control="input"
                        label=""
                        placeholder=""
                        required
                        maxLength={3}
                        value={state.data.membership_policy_meta.sign_initials}
                        onChange={(e) =>
                          dispatch(setpolicyInitials(e.target.value))
                        }
                        style={{ margin: '0 0' }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              ) : null}
            </Box>
          </Box>
        );
      case 3:
        return (
          <Box>
            <Grid alignItems="center" container direction="row">
              {state.data.sign_status === 1 && (
                <Grid item xs={12} className={classes.signatureGrid}>
                  <canvas
                    ref={canRef}
                    width="800"
                    height="250"
                    className={classes.signatureBox}
                  />
                  <JBButton
                    onClick={() => {
                      if (sign) {
                        sign.clear();
                        setSigned(false);
                      }
                    }}
                    disabled={!sign || !signed}
                    className={classes.btClearsignature}
                  >
                    Clear signature
                  </JBButton>
                </Grid>
              )}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.data.sign_status === 1}
                    onChange={() => {
                      dispatch(setSignStatus());
                      setSigned(false);
                    }}
                    name="checkedB"
                    color="primary"
                  />
                }
                label="I agree to use electronic records and signatures"
              />
            </Grid>
          </Box>
        );
      default:
        return 'Unknown step';
    }
  }
  return (
    <Box width="100%" className={classes.signcontractStepper}>
      <JBStepper
        style={{ padding: '0 0' }}
        steps={steps}
        activeStep={activeStep}
        handleBack={handleBack}
        handleNext={handleNext}
        handleReset={handleReset}
        // eslint-disable-next-line react/jsx-no-bind
        handleStepContent={getStepContent}
        isLoading={false}
        hasErrored={false}
        prevLabel="SUBMIT"
        nextLabel="NEXT STEP"
        CustComp={(prop) => (
          <ColorlibStepIcon
            FirstIcon={BasicInformation}
            SecondIcon={ListIcon}
            ThirdIcon={BookopenIcon}
            FourthIcon={EditpenIcon}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...prop}
          />
        )}
      />
    </Box>
  );
};

SignContractStepper.propTypes = {
  classes: PropType.object.isRequired,
  id: PropType.number.isRequired,
  OpenNoti: PropType.func.isRequired,
  goToHistory: PropType.string.isRequired,
  updateActiveCont: PropType.func.isRequired,
  updateInActiveCont: PropType.func.isRequired,
  updateAllCont: PropType.func.isRequired,
  updateSigned: PropType.func.isRequired,
  getSignedUpdate: PropType.func.isRequired,
  reloadFunc: PropType.func.isRequired
};

const mapToDispatch = (dispatch) => ({
  OpenNoti: (msg, type) => dispatch(openNoti(msg, type)),
  updateActiveCont: () => dispatch(getActiveMissCont()),
  updateInActiveCont: () => dispatch(getInActiveMissCont()),
  updateAllCont: () => dispatch(getAllMissCont()),
  updateSigned: () => dispatch(getSignedCont()),
  getSignedUpdate: (filter, data) => dispatch(getSigned(filter, data))
});

export default connect(
  null,
  mapToDispatch
)(withStyles(styles)(SignContractStepper));
