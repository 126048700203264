import { checkValidString, isValidPostal } from 'src/utils/validations';

export const getValidStep2 = (data) => {
  if (
    !data.phone
    || !data.address.line1
    || !data.address.city
    || !data.address.state
    || !data.address.country
    || !data.address.postal_code
    || !data.payment_contact
    || !data.location_id
  ) {
    return true;
  }
  return false;
};
export const getValidStep3 = (billing, cardComplete) => {
  if (!billing.name_on_card || !cardComplete) {
    return true;
  }
  return false;
};

export const validCard1 = (data) => {
  if (
    data.phone?.trim()?.length > 15
    || !data.phone
    || data.phone?.trim()?.length < 10
  ) {
    return { error: 'Please specify valid Phone number' };
  }
  const { city, country, line1, postal_code, state } = data.address;
  if (checkValidString(line1)) {
    return { error: 'Please specify valid Address' };
  }
  if (checkValidString(city)) {
    return { error: 'Please specify valid City' };
  }
  if (checkValidString(state)) {
    return { error: 'Please specify valid Province' };
  }
  if (!country) {
    return { error: 'Please put valid country' };
  }
  if (!isValidPostal(postal_code)) {
    return { error: 'Please specify valid Postal Code' };
  }
  return { data };
};

export const validCard2 = (data) => {
  if (checkValidString(data.name)) {
    return { error: 'Please specify valid name' };
  }
  return { data };
};
