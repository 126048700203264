/* eslint-disable operator-linebreak */
import {
  validStringRegEx,
  validEmailRegEx,
  validPhoneRegEx,
  validNumsAndStringRegEx,
  postalRegEx,
  isPhoneNumberInvalid
} from 'src/utils/validations';

export const validateContractStepOne = (data) => {
  if (data?.first_name.length <= 0) {
    throw Error('First Name is Required');
  } else if (data?.last_name.length <= 0) {
    throw Error('Last Name is Required');
  } else if (data?.email.length <= 0) {
    throw Error('Email is Required');
  } else if (!validEmailRegEx.test(data?.email)) {
    throw Error('Please provide a valid Email Address');
  } else if (!data?.gender) {
    throw Error('Gender is Required');
  } else if (data?.address.length <= 0) {
    throw Error('Address field is Required');
  } else if (validNumsAndStringRegEx.test(data?.address)) {
    throw Error('Please specify valid address');
  } else if (data?.city.length <= 0) {
    throw Error('City Field is required');
  } else if (!validStringRegEx.test(data?.city)) {
    throw Error('Please specify a valid City');
  } else if (data?.province.length <= 0) {
    throw Error('Please specify the Province/Region');
  } else if (!validStringRegEx.test(data?.province)) {
    throw Error('Please specify a valid Province/Region');
  } else if (data?.postal_code.length <= 0) {
    throw Error('Please specify the postal Code');
  } else if (!postalRegEx.test(data?.postal_code)) {
    throw Error('Please enter a valid postal');
  } else if (!data?.country) {
    throw Error('Please specify the country');
  } else if (!data?.mobile_no) {
    throw Error('Mobile number is Required');
  } else if (!validPhoneRegEx.test(data?.mobile_no)) {
    throw Error('Please enter a valid Mobile number');
  } else if (isPhoneNumberInvalid(data?.mobile_no)) {
    throw Error('Mobile number must be between 10 and 15 digits');
  }
};

export const validateContractStepTwo = (data) => {
  if (!data?.payment_plan_meta.sign_initials) {
    throw Error('Initials required');
  } else if (!validStringRegEx.test(data?.payment_plan_meta.sign_initials)) {
    throw Error('Required as Alpabets');
  }
};

export const validateContractStepThree = (data) => {
  if (
    data.membership_policy_meta.policy_meta.initial === 1 && // JB-1555
    !data?.membership_policy_meta.sign_initials
  ) {
    throw Error('Initials required');
  }
  if (data?.payment_plan_meta.sign_initials.length <= 0) {
    throw Error('Initials required');
  } else if (
    data?.membership_policy_meta.sign_initials &&
    data?.payment_plan_meta.sign_initials !==
      data?.membership_policy_meta.sign_initials
  ) {
    throw Error('Intials must be same');
  }
};

// (CHECK IF YUP PACKAGE IS STILL NEEDED)
