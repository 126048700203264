/* eslint-disable operator-linebreak */
/* eslint-disable indent */
import React from 'react';
import {
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  withStyles,
  Grid
} from '@material-ui/core';
import PropType from 'prop-types';
import LoadingView from 'src/views/common/loading';
import CheckIcon from 'src/assets/image/check.png';
// import Actions from './Action';
// import EditPaymentMethod from './EditPaymentMethod/EditPaymentMethod';
import styles from './table.style';

function PaymentMethodsTable({ classes, dataval }) {
  // const [state] = useContext(dataval);
  // const [editPayment, seteditPayment] = useState({
  //   open: false,
  //   id: null,
  //   paymentmethod: null
  // });
  // const [openselected, selectedOption] = useState(false);

  // const {
  //   paymentMethods: { isFetching, data, error }
  // } = state;
  if (dataval.error) return <p>{dataval.error}</p>;

  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      spacing={0}
      style={{ margin: '10px 0 20px', padding: '0 0px' }}
    >
      <TableContainer
        style={{
          overflowX: 'visible',
          borderRadius: '0',
          backgroundColor: 'transparent'
        }}
      >
        {dataval.isFetching ? (
          <LoadingView />
        ) : (
          <>
            <Table className={classes.table} aria-label="simple table">
              <TableHead className={classes.table_th}>
                <TableRow>
                  <TableCell
                    className={classes.TableCell}
                    style={{ width: '30%' }}
                  >
                    Name
                  </TableCell>
                  <TableCell className={classes.TableCell}>
                    Payment processor name
                  </TableCell>
                  <TableCell className={classes.TableCell}>
                    Expiration date
                  </TableCell>
                  <TableCell className={classes.TableCell}>Default</TableCell>
                  {/* <TableCell
                    align="right"
                    className={classes.TableCell}
                  >
                    &nbsp;
                  </TableCell> */}
                </TableRow>
              </TableHead>
              {dataval.data.payment_method &&
              dataval.data.payment_method.length > 0 ? (
                <TableBody>
                  {dataval.data.payment_method.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell className={classes.Table_td}>
                        <span
                          className={classes.Table_td_link}
                          style={{
                            textTransform: 'capitalize'
                          }}
                        >
                          {row.network}
                          {'-'}
                          {row.card_number}
                        </span>
                      </TableCell>
                      <TableCell className={classes.Table_td}>
                        {row.location}
                      </TableCell>
                      <TableCell className={classes.Table_td}>
                        {row.card_expiry}
                      </TableCell>
                      <TableCell>
                        {row.default_method === 1 ? (
                          <img src={CheckIcon} alt="icon" />
                        ) : null}
                      </TableCell>
                      {/* <TableCell>
                        {row.default_method === 0 ? (
                          <Actions
                            data={row}
                          />
                        ) : null}
                      </TableCell> */}
                    </TableRow>
                  ))}
                </TableBody>
              ) : null}
            </Table>

            {dataval.data.payment_method.length === 0 && (
              <p
                style={{
                  margin: '10px 15px',
                  fontSize: '14px',
                  lineHeight: '22px',
                  textAlign: 'center',
                  color: '#fff'
                }}
              >
                No payment method added
              </p>
            )}
          </>
        )}
      </TableContainer>
    </Grid>
  );
}

PaymentMethodsTable.propTypes = {
  classes: PropType.object.isRequired,
  dataval: PropType.object.isRequired
};

export default withStyles(styles)(PaymentMethodsTable);
