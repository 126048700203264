/* eslint-disable operator-linebreak */
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import clearCredentials from 'src/utils/clearCredentials';

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

axios.interceptors.response.use(null, (err) => {
  const onrequest = err.config;

  const token = localStorage.getItem('x-token');
  if (
    err.response?.config &&
    err.response?.config?.url !== 'api/login' &&
    err.response?.config?.url !== 'api/password/email' &&
    err.response?.config?.url !== 'api/password/reset' &&
    !token
  ) {
    window.location = '/login';
  }

  if (err?.response?.status === 429) {
    return axios.request(err.config);
  }

  if (
    (err.response &&
      err.response.config &&
      err.response.config.url === 'api/refresh_token' &&
      err.response.status === 401) ||
    (err?.response?.status === 422 &&
      err?.response?.data?.data === 'log_out') ||
    token === 'undefined'
  ) {
    clearCredentials();
    window.location = '/login';
  }
  const refreshToken = localStorage.getItem('qid');
  let exp = null;
  if (token && token !== 'undefined') {
    const expery = jwtDecode(token || '');
    exp = expery.exp;
  }
  // if (err.response.status === 429 && refreshToken && token) {
  //   return new Promise((res, rej) => failedQueue.push({ res, rej }))
  //     .then(() => axios(onrequest))
  //     .catch((errs) => Promise.reject(errs));
  // }

  if (
    token &&
    refreshToken &&
    token !== undefined &&
    exp &&
    exp < Date.now() / 1000 &&
    !onrequest._retry // eslint-disable-line
  ) {
    if (isRefreshing) {
      // eslint-disable-next-line
      return new Promise(function(resolve, reject) {
        failedQueue.push({ resolve, reject });
      })
        .then((tokens) => {
          onrequest.headers['Authorization'] = 'Bearer ' + tokens; // eslint-disable-line
          return axios(onrequest);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    }
    onrequest._retry = true; // eslint-disable-line
    isRefreshing = true;

    return new Promise((resolve, reject) => {
      const setRefUrl = 'api/refresh_token';
      axios
        .post(setRefUrl, {
          refresh_token: refreshToken
        })
        .then(
          ({
            data: {
              data: { access_token, refresh_token, expires_in }
            }
          }) => {
            axios.defaults.headers.common.Authorization =
              'Bearer ' + access_token;
            localStorage.setItem('x-token', access_token);
            localStorage.setItem('qid', refresh_token);
            localStorage.setItem(
              'expires',
              moment()
                .add(expires_in, 'seconds')
                .toDate()
            );
            processQueue(null, access_token);
            resolve(axios(onrequest));
          }
        )
        .catch((error) => {
          localStorage.removeItem('x-token');
          localStorage.removeItem('qid');
          localStorage.removeItem('expires');
          window.location = '/login';
          processQueue(error, null);
          reject(error);
        })
        .then(() => {
          isRefreshing = false;
        });
    });
  }
  return Promise.reject(err);
});

export default {
  get: axios.get,
  post: axios.post
};
