/* eslint-disable react/jsx-props-no-spreading */
import React, { StrictMode } from 'react';
import { Route, Switch } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import { Provider } from 'react-redux';
import GlobalStyles from 'src/components/GlobalStyles';
import 'src/mixins/chartjs';
import jungleTheme from 'src/jungleTheme';
import MainLayout from 'src/layouts/MainLayout';
import { PersistGate } from 'redux-persist/integration/react';
import queryString from 'query-string';
import ErrorBoundary from './components/ErrorBoundry';
import ProgramContainer from './hocContainers/GlobalContainer';
import { store, persistor } from './Redux/rootReducer';
import NotiContainer from './hocContainers/NotiContainer';
import ChalkBoardFullScreen from './views/chalkboard/ChalkBoardFullScreen';
import LoadingView from './views/common/loading';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-quill/dist/quill.snow.css';
import './assets/CKEditor.css';
import './assets/style.css';
import VerificationLinkView from './views/verificationLink/LinkVerificationView';
import LinkExpiredView from './views/errorsLinkExpired/LinkExpiredView';
import GymSetUpDrawer from './views/newGymSetUp/index';

const App = () => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={jungleTheme}>
        <PersistGate persistor={persistor} loading={<LoadingView />}>
          <ErrorBoundary>
            <StrictMode>
              <GlobalStyles />
              <Switch>
                <Route path="/app" component={ProgramContainer} />
                <Route
                  path="/link"
                  render={(prop) => {
                    const { status, aff } = queryString.parse(
                      prop.location.search
                    );
                    if (status === '1' && aff) {
                      return <VerificationLinkView {...prop} />;
                      // eslint-disable-next-line no-else-return
                    } else if (status === '0' && aff) {
                      return <LinkExpiredView {...prop} />;
                    } else {
                      return null;
                    }
                  }}
                />
                <Route
                  path="/chalboard-full-screen"
                  component={ChalkBoardFullScreen}
                />
                <Route path="/" component={MainLayout} />
              </Switch>
              <NotiContainer />
            </StrictMode>
            <GymSetUpDrawer />
          </ErrorBoundary>
        </PersistGate>
      </ThemeProvider>
    </Provider>
  );
};

export default App;
