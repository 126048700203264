/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
// import logo from '../assets/image/newLogo.png';
import { ReactComponent as LogoIcon } from '../assets/image/Jungle-Alliance_Primary.svg';

const Logo = (props) => {
  // return <img alt="Logo" src={logo} {...props} />;
  return <LogoIcon className="main-logo" {...props} />;
};

export default Logo;
