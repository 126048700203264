import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import PropType from 'prop-types';
import styles from './Button.style';
import getClass from './Button.utils';

function ButtonPrimary(props) {
  const { classes, children, isImg, type, CutomClass } = props;
  const buttonClass = getClass(type, classes);
  return (
    <Button
      variant={type === 'primary' ? 'contained' : 'outlined'}
      // color="primary"
      // className={buttonClass}
      className={clsx(buttonClass, CutomClass)}
      {...props}
    >
      {isImg && <img src={isImg} alt={isImg} className={classes.imgMargin} />}
      <span>{children}</span>
    </Button>
  );
}

ButtonPrimary.defaultProps = {
  isImg: null,
  CutomClass: null
};

ButtonPrimary.propTypes = {
  classes: PropType.object.isRequired,
  children: PropType.string.isRequired,
  type: PropType.string.isRequired,
  isImg: PropType.string,
  CutomClass: PropType.object
};

export default withStyles(styles)(ButtonPrimary);
