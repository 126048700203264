import React, { useState } from 'react';
import PropType from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  withStyles,
  Grid,
  FormControlLabel,
  InputLabel
} from '@material-ui/core';
import IOSSwitch from 'src/views/common/FormControl/IOSSwitch';
import dcloseicon from 'src/assets/image/closeicon.png';
import JBButton from 'src/views/common/JBButton/JBButton';
import CustomAutocomplete from 'src/views/common/CustomAutocomplete/commonAutocomplete';
import { openNoti } from 'src/Redux/global/global.actions';
import { getusermembership } from 'src/services/user.service';
import { getErrorMsg } from 'src/utils/ErrorHandling';
import { stopAutoRenew } from 'src/services/Members.module';
import { connect } from 'react-redux';
import moment from 'moment';
import { updateAllData } from '../Memberships/NewMembership/NewMembership.actions';
import { updatemem } from './EditMembership.utils';

const styles = () => ({
  dtitle: {
    color: '#292929',
    fontWeight: 'bold',
    fontSize: '33px',
    lineHeight: '26px'
  },
  cutom_select_Label: {
    fontSize: '12px',
    padding: '7px 0'
  },
  ptext: {
    fontSize: '14px',
    lineHeight: '18px',
    color: '#7F7F7F',
    fontWeight: 'normal'
  }
});

function AutoRenewDialog({
  classes,
  openNotify,
  value,
  open1,
  onClose,
  reducer
}) {
  const [open, setOpen] = useState(open1);
  const [state, dispatch] = reducer;
  const [data, setData] = useState({
    auto_renew_date: null,
    is_stop_autorenew: state.data.is_stop_autorenew,
    deactivate_user: state.data.deactivate_user
  });
  const handleClose = () => {
    setOpen(false);
    onClose();
  };
  const handleResume = () => {
    const result = {
      ...data,
      is_stop_autorenew: 0,
      auto_renew_date: moment(new Date()).format('YYYY/MM/DD')
    };
    stopAutoRenew(value.data.user_id, result)
      .then(() => {
        getusermembership(value.data.user_id)
          .then((res1) => {
            openNotify('Successfully resume stop auto renew ');
            dispatch(
              updateAllData({ ...updatemem(res1), reset: updatemem(res1) })
            );
            handleClose();
          })
          .catch((err) => openNotify(getErrorMsg(err), 'error'));
      })
      .catch((err) => openNotify(getErrorMsg(err), 'error'));
  };
  const handleSave = () => {
    if (!data.auto_renew_date) {
      openNotify('Please select the date', 'error');
    } else {
      const result = {
        ...data,
        is_stop_autorenew: 1,
        auto_renew_date: moment(
          data.auto_renew_date.label,
          'DD/MM/YYYY'
        ).format('YYYY/MM/DD')
      };
      stopAutoRenew(value.data.user_id, result)
        .then(() => {
          getusermembership(value.data.user_id)
            .then((res1) => {
              openNotify('Successfully stoped auto renew ');
              dispatch(
                updateAllData({ ...updatemem(res1), reset: updatemem(res1) })
              );
              handleClose();
            })
            .catch((err) => openNotify(getErrorMsg(err), 'error'));
        })
        .catch((err) => openNotify(getErrorMsg(err), 'error'));
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick
      >
        <div style={{ width: '548px' }}>
          <Grid alignItems="center" container direction="row">
            <Grid item xs>
              <DialogTitle
                id="alert-dialog-title"
                className={classes.titlebk}
                style={{
                  display: 'inline-block',
                  padding: '25px 24px'
                }}
              >
                <h4 className={classes.dtitle}> Stop Auto-Renew</h4>
              </DialogTitle>
            </Grid>
            <Grid
              item
              xs={2}
              style={{ textAlign: 'right', marginRight: '10px' }}
            >
              <Button onClick={handleClose} className={classes.btns}>
                <img alt="img" src={dcloseicon} />
              </Button>
            </Grid>
          </Grid>

          <DialogContent>
            <Grid container spacing={2} style={{ marginTop: '5px' }}>
              <Grid item xs={12}>
                {state.data.is_stop_autorenew ? (
                  <p className={classes.ptext} style={{ color: '#CE5B15' }}>
                    This membership expires on {state.data.expires_date}. You
                    may not stop it before then.
                  </p>
                ) : (
                  <p className={classes.ptext}>
                    This membership expires on {state.data.expires_date}. You
                    may not stop it before then.
                  </p>
                )}

                {/* If is Edit show this text */}

                {/* end */}
              </Grid>
              <Grid item xs={12}>
                <InputLabel
                  htmlFor="age-required"
                  required
                  className={classes.cutom_select_Label}
                  style={{ padding: '6px 0px' }}
                >
                  Stop auto renew as of
                </InputLabel>
                <CustomAutocomplete
                  holder="Select the date"
                  data={data.auto_renew_date}
                  Options="label"
                  value={value.dropDownData}
                  Change={(val) => {
                    setData({ ...data, auto_renew_date: val });
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                className="switch-btn"
                style={{ marginTop: '15px', marginBottom: '15px' }}
              >
                <FormControlLabel
                  control={
                    <IOSSwitch
                      checked={data.deactivate_user}
                      onChange={() => {
                        setData({
                          ...data,
                          deactivate_user: !data.deactivate_user * 1
                        });
                      }}
                    />
                  }
                  label="Deactivate the member account on this date"
                  style={{ margin: '0 0' }}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions
            style={{
              padding: '20px 20px'
            }}
          >
            {/* If is Edit show this  */}
            {state.data.is_stop_autorenew ? (
              <JBButton onClick={handleResume} type="outlined">
                resume auto-renew
              </JBButton>
            ) : null}
            {/* End */}
            <JBButton onClick={handleSave} type="primary">
              Save
            </JBButton>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}
// AutoRenewDialog.defaultProps = {
//   handleHistory: null
// };

AutoRenewDialog.propTypes = {
  classes: PropType.object.isRequired,
  openNotify: PropType.func.isRequired,
  value: PropType.object.isRequired,
  open1: PropType.isRequired,
  onClose: PropType.func.isRequired,
  reducer: PropType.array.isRequired
  // handleDelete: PropType.func.isRequired,
  // handleHistory: PropType.string
};
const mapToDispatch = (dispatch) => ({
  openNotify: (msg, type) => dispatch(openNoti(msg, type))
});
export default connect(
  null,
  mapToDispatch
)(withStyles(styles)(AutoRenewDialog));
