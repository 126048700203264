import React, { useContext, useEffect, useState } from 'react';
import PropType from 'prop-types';
import { connect } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { Box, withStyles, InputLabel } from '@material-ui/core';
import JBDatePicker from 'src/views/common/FormControl/JBDatePicker';
import {
  SetClass,
  SetClassOption,
  SetDate,
  SetError,
  SetProgram,
  SetWorkoutData
} from 'src/Context/ChalkBoardReducer/chalkBoard.actions';
import { Chalk } from 'src/Context/chalkboardContext';
import {
  getClassWOD,
  getWODDetailByCls
} from 'src/services/WODmodule.services';
import moment from 'moment';
import Lockicon from 'src/assets/image/lockicon.png';
import Doublelefticon from 'src/assets/image/doublelefticon.png';
import Doubledownicon from 'src/assets/image/doubledownicon.png';
import CutsomAutoComplete from '../common/CustomAutocomplete/commonAutocomplete';
import styles from './Chalkboard.style';

const ChalkboardFilter = (props) => {
  const { classes, allPrograms } = props;
  // const classes = useStyles();
  const match = useRouteMatch();
  const [Edit, setEdit] = useState(!match.isExact);
  const [state, dispatch] = useContext(Chalk);

  useEffect(() => {
    if (state.program && state.date) {
      const updateDate = moment(state.date).format('YYYY-MM-DD');
      getClassWOD(state.program.id, updateDate)
        .then(({ data }) => dispatch(SetClassOption(data)))
        .catch(() => dispatch(SetError('No Classes to Show')));
    }
  }, [state.program, state.date]); // eslint-disable-line

  useEffect(() => {
    if (state.class) {
      getWODDetailByCls(state.class.id)
        .then((res) => dispatch(SetWorkoutData(res)))
        .catch(() => dispatch(SetError('No Workout to Show')));
    }
  }, [state.class]); // eslint-disable-line

  useEffect(() => {
    setEdit(!match.isExact);
  }, [match]);

  return (
    <div className={classes.chalkboardfilter}>
      <Box>
        <Box className={classes.filterbk}>
          <Box onClick={() => setEdit(!Edit)} className={classes.btnfilter}>
            <img alt="img" src={Lockicon} /> <h5>Workout</h5>
            <img
              alt="img"
              src={Edit ? Doubledownicon : Doublelefticon}
              style={{
                display: 'inline-flex',
                float: 'right',
                verticalAlign: 'middle',
                margin: '5px 0'
              }}
            />
          </Box>
        </Box>
        {Edit && (
          <Box className={classes.filterinfo}>
            <Box mb="15px">
              <InputLabel
                htmlFor="age-required"
                className={classes.cutom_select_Label}
              >
                Program
              </InputLabel>
              <CutsomAutoComplete
                holder="Select a Program"
                data={state.program}
                value={allPrograms}
                Options="name"
                Change={(val) => dispatch(SetProgram(val))}
                className={classes.chalkboard_cutom_select}
              />
            </Box>
            <Box mb="15px">
              <InputLabel
                htmlFor="age-required"
                className={classes.cutom_select_Label}
              >
                Date
              </InputLabel>
              <JBDatePicker
                required
                value={state.date}
                onChange={(val) => dispatch(SetDate(val))}
                className={classes.chalk_cutom_date}
              />
            </Box>
            <Box mb="15px">
              <InputLabel
                htmlFor="age-required"
                className={classes.cutom_select_Label}
              >
                CLASS
              </InputLabel>
              <CutsomAutoComplete
                holder="Select a Class"
                data={state.class}
                value={state.classOption}
                Options="name"
                Change={(val) => dispatch(SetClass(val))}
                className={classes.chalkboard_cutom_select}
              />
            </Box>
          </Box>
        )}
      </Box>
    </div>
  );
};

ChalkboardFilter.propTypes = {
  classes: PropType.object.isRequired,
  allPrograms: PropType.array.isRequired
};
const mapToState = (state) => ({
  allPrograms: state.allprograms.programs
});

export default connect(mapToState)(withStyles(styles)(ChalkboardFilter));
