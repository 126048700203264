import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropType from 'prop-types';
import {
  Avatar,
  Checkbox,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel
} from '@material-ui/core';
import filterType from 'src/Redux/Reducer.constants';
import { get } from 'js-cookie';
import { ROUTES } from 'src/constant/Constants';
import ClassTableToolbar from './MissingTableHead';

const useStyles = makeStyles({
  table: {
    wordBreak: 'break-word'
  },
  tableLink: {
    color: '#01847A',
    fontWeight: '700'
  },
  dayofweek: {
    background: '#A9BFEF',
    borderRadius: '50px',
    padding: '8px',
    color: '#FFF'
  },
  TooltipLink: {
    '&:hover': {
      '& svg path': {
        stroke: '#01847A'
      }
    }
  },
  TableCell: {
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px',
    borderBottom: '1px solid #A8A8A8',
    height: '56px',
    minHeight: '56px'
  },
  useravatar: {
    verticalAlign: 'sub',
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    objectFit: 'cover'
  },
  Avataruname: {
    fontWeight: 'bold',
    backgroundColor: '#CCE6E4',
    color: '#37847a'
  },
  selected: {
    backgroundColor: '#E6F3F2 !important'
  }
});

const SignedTable = (props) => {
  const classes = useStyles();
  const { values, isActive, setDirection, setSort } = props;
  const {
    sortstatus,
    sort_waiver_name,
    sortname,
    sort_date_signed
  } = filterType;

  const [selected, setSelected] = useState([]);
  const roleid = JSON.parse(get('jbrole') || '{}')?.role_id || 3;

  const handleClick = (name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = values.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  const ClearCheckbox = () => {
    setSelected([]);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;
  return (
    <div>
      <TableContainer component={Paper} elevation={0}>
        {selected.length > 0 && (
          <ClassTableToolbar
            numSelected={selected}
            selectedTags={[]}
            ClearChecks={ClearCheckbox}
            module="class"
          />
        )}
        <Table className={classes.table} aria-label="simple table">
          {selected.length === 0 && (
            <TableHead>
              <TableRow style={{ background: '#F9F9FA' }}>
                <TableCell padding="checkbox" className={classes.TableCell}>
                  <Checkbox
                    color="primary"
                    onChange={handleSelectAllClick}
                    inputProps={{ 'aria-labelledby': 'IDN' }}
                  />
                </TableCell>
                <TableCell
                  style={{ width: '2%' }}
                  className={classes.TableCell}
                >
                  &nbsp;
                </TableCell>
                <TableCell
                  style={{ width: '20%' }}
                  className={classes.TableCell}
                >
                  <TableSortLabel
                    active={isActive(sortname)}
                    direction={setDirection(sortname)}
                    onClick={() => setSort(sortname)}
                  >
                    Name
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  className={classes.TableCell}
                  style={{ width: '20%' }}
                >
                  <TableSortLabel
                    active={isActive(sort_waiver_name)}
                    direction={setDirection(sort_waiver_name)}
                    onClick={() => setSort(sort_waiver_name)}
                  >
                    Waiver name
                  </TableSortLabel>
                </TableCell>
                <TableCell className={classes.TableCell}>
                  <TableSortLabel
                    active={isActive(sortstatus)}
                    direction={setDirection(sortstatus)}
                    onClick={() => setSort(sortstatus)}
                  >
                    Status
                  </TableSortLabel>
                </TableCell>
                <TableCell className={classes.TableCell} align="right">
                  <TableSortLabel
                    active={isActive(sort_date_signed)}
                    direction={setDirection(sort_date_signed)}
                    onClick={() => setSort(sort_date_signed)}
                  >
                    {' '}
                    Date Signed
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {values.map((row) => (
              <TableRow
                hover
                // role="checkbox"
                aria-checked={isSelected(row.id)}
                tabIndex={-1}
                key={row.id}
                selected={isSelected(row.id)}
                classes={{ selected: classes.selected }}
                style={{
                  backgroundColor: '#FFF'
                }}
              >
                <TableCell
                  padding="checkbox"
                  style={{
                    width: '2%'
                  }}
                >
                  <Checkbox
                    onClick={() => handleClick(row.id)}
                    color="primary"
                    checked={isSelected(row.id)}
                    inputProps={{ 'aria-labelledby': 'IDN' }}
                  />
                </TableCell>
                <TableCell
                  style={{
                    position: 'relative',
                    color: '#01847A',
                    fontWeight: '700',
                    width: '2%'
                  }}
                >
                  {/* <img
                    src={row.profile_pic === null ? UserIcon : row.profile_pic}
                    alt="UserIcon"
                    className={classes.useravatar}
                  /> */}
                  {row.profile_pic ? (
                    <img
                      src={row.profile_pic}
                      alt="UserIcon"
                      className={classes.useravatar}
                    />
                  ) : (
                    <Avatar
                      className={classes.Avataruname}
                      style={{
                        fontSize: '1.1rem'
                      }}
                    >
                      {/* {row.name[0]} */}
                      {row.first_name[0] + ' ' + row.last_name[0]}
                    </Avatar>
                  )}
                </TableCell>
                <TableCell
                  style={{
                    position: 'relative',
                    color: '#01847A',
                    fontWeight: '700',
                    width: '20%'
                  }}
                >
                  {roleid === 1 ? (
                    <Link
                      className={classes.tableLink}
                      to={{
                        pathname:
                          row.is_lead === 0
                            ? ROUTES.PEOPLE_MEMBER_PROFILE
                            : ROUTES.PEOPLE_LEAD_PROFILE,
                        search:
                          row.is_lead === 0
                            ? `memberId=${btoa(row.user_id)}`
                            : `leadId=${btoa(row.user_id)}`
                      }}
                    >
                      {row.name}
                    </Link>
                  ) : (
                    <span className={classes.tableLink}>{row.name}</span>
                  )}
                </TableCell>
                <TableCell
                  style={{
                    width: '20%'
                  }}
                >
                  <Link
                    className={classes.tableLink}
                    to={{
                      pathname: ROUTES.DOCUMENTS_WAIVERS_SIGNED_DETAIL,
                      search: `waiverSignId=${row.id}`
                    }}
                  >
                    {row.waiver_name}
                  </Link>
                </TableCell>
                <TableCell
                  style={{
                    width: '10%'
                  }}
                >
                  {row.status}
                </TableCell>
                <TableCell
                  align="right"
                  style={{
                    width: '15%'
                  }}
                >
                  {row.date_signed}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {values.length === 0 && (
          <p
            style={{
              margin: '10px 15px'
            }}
          >
            No Waivers to show...
          </p>
        )}
      </TableContainer>
    </div>
  );
};
SignedTable.propTypes = {
  values: PropType.array.isRequired,
  isActive: PropType.func.isRequired,
  setDirection: PropType.func.isRequired,
  setSort: PropType.func.isRequired
};

export default SignedTable;
