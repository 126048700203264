import React from 'react';
import PropTypes from 'prop-types';
import Portal from '@material-ui/core/Portal';
// import { Portal } from 'react-portal';

import CustomPopup from './CustomPopup';

class CustomPopupManager extends React.Component {
  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, true);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true);
  }

  handleClickOutside = (e) => {
    const { hideCustomPopup } = this.props;
    if (
      this.node !== null
      && typeof this.node !== 'undefined'
      && (!this.node || !this.node.contains(e.target))
    ) {
      const divId = e.target.id;
      const isIgnorePopup = divId.indexOf('ignorepopup') !== -1;
      if (!isIgnorePopup) {
        hideCustomPopup();
      }
    }
  };

  render() {
    const { show, location, children } = this.props;
    let lPortal = null;
    const windowSize = {
      width: window.innerWidth,
      height: window.innerHeight
    };
    // let windowSize = null;
    // debugger; //eslint-disable-line
    // if (forwardRef.current) {
    //   // windowSize = {
    //   //   height: forwardRef.current.clientHeight,
    //   //   width: forwardRef.current.clientWidth
    //   // };
    // }
    if (location !== null && show) {
      lPortal = (
        <Portal disablePortal>
          <CustomPopup
            location={location}
            windowSize={windowSize}
            nodeRef={(node) => {
              this.node = node;
            }}
          >
            {children}
          </CustomPopup>
        </Portal>
      );
    }
    return lPortal;
  }
}

CustomPopupManager.defaultProps = {};
CustomPopupManager.propTypes = {
  show: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  children: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
  hideCustomPopup: PropTypes.func.isRequired
  // forwardRef: PropTypes.any.isRequired
  // windowSize: PropTypes.object // eslint-disable-line react/forbid-prop-types
};

export default CustomPopupManager;
