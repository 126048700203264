import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import {
  withStyles,
  Grid,
  Typography,
  Box,
  InputBase
} from '@material-ui/core';
import PropType from 'prop-types';
import LoadingView from 'src/views/common/loading';
import { getAutomated } from 'src/services/Communication.service';
import _ from 'lodash';
import useChangeTitle from 'src/views/common/CustomHooks/useTitleChange';
import styles from '../automatedemails.style';
// import { ROUTES } from 'src/constant/Constants';
import { CLASS_ROUTES } from './classTemplateConstants';

const Classes = ({ classes }) => {
  const [data, setData] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const [searchField, setSearchField] = useState('');
  useChangeTitle('Classes Email templates | Jungle Alliance');
  const classCencelSettingsActive = data
    .filter(
      (template) =>
        template.category === 'Class is Manually - Cancelled - Staff'
    )
    .reduce(
      (previousValue, currentValue, index, array) => {
        return {
          count: previousValue.count + 1,
          enabled:
            array[index].setting.enabled !== 1
              ? previousValue.enabled + 1
              : previousValue.enabled
        };
      },
      { count: 0, enabled: 0 }
    );

  // const renderActiveBox = (template) => {
  //   if(template.id === 13) {

  //   } else {
  //     return
  //   }

  // }

  useEffect(() => {
    setIsFetching(true);
    getAutomated({ email_general_class: 2, search: searchField })
      .then((response) => {
        const parsedResponse = response.map((res) => {
          return { ...res, setting: JSON.parse(res.setting) };
        });
        setData(parsedResponse);
        setIsFetching(false);
      })
      .catch((error) => console.log(error));
  }, [searchField]);

  const debounceSearch = useCallback(
    _.debounce((search) => setSearchField(search), 500),
    []
  );

  const onSearchChange = (event) => {
    debounceSearch(event.target.value);
  };

  return (
    <Box>
      <Grid container direction="row" spacing={0}>
        <Grid
          container
          direction="row"
          spacing={0}
          style={{ margin: '10px 0 20px' }}
        >
          <Grid item xs={12}>
            <Typography
              variant="h3"
              component="h3"
              style={{ color: '#292929' }}
            >
              Email templates
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box mt="8px">
              <p className={classes.ptext}>
                Enable and customize the automated email templates that are sent
                to your members and staff.
              </p>
            </Box>
          </Grid>

          <Grid item xs>
            <Box className={classes.search} mt="15px">
              <InputBase
                fullWidth
                onChange={onSearchChange}
                placeholder="Search for automated emails..."
                classes={{
                  root: classes.inputRoot,
                  input: classes.searchInput
                }}
                InputProps={{
                  'aria-label': 'search'
                }}
              />
            </Box>
          </Grid>
        </Grid>

        <Grid container direction="row" spacing={2}>
          {data && data.length > 0 ? (
            data
              .filter((res) => res.id !== 14)
              .map((template) => {
                const pathMatch = CLASS_ROUTES.find(
                  (route) => route.id === template.id
                );
                return (
                  <Grid key={template.id} item xs={6}>
                    <Link to={pathMatch.to}>
                      <Box className={classes.wrapperBox}>
                        <Box className={classes.wraBoxTitle}>
                          <Grid container direction="row">
                            <Grid item xs={9}>
                              <Typography
                                variant="h5"
                                component="h5"
                                style={{ color: '#292929' }}
                              >
                                {template.category}
                              </Typography>
                            </Grid>
                            {template.setting.enabled !== 1 && // eslint-disable-line
                              template.id !== 13 && (
                                <Grid
                                  item
                                  xs={3}
                                  justify="flex-end"
                                  className="MuiGrid-container"
                                >
                                  <Box className={classes.InactiveBox}>
                                    <label>Inactive</label>
                                  </Box>
                                </Grid>
                                // eslint-disable-next-line
                              )}
                            {template.id === 13 && // eslint-disable-line
                              classCencelSettingsActive.enabled !== 0 && (
                                <Grid
                                  item
                                  xs={3}
                                  justify="flex-end"
                                  className="MuiGrid-container"
                                >
                                  <Box className={classes.InactiveBox}>
                                    <label>
                                      {classCencelSettingsActive.enabled} of{' '}
                                      {classCencelSettingsActive.count} inactive
                                    </label>
                                  </Box>
                                </Grid>
                                // eslint-disable-next-line
                              )}
                          </Grid>
                        </Box>
                        <Box mt="8px">
                          <p className={classes.ptext}>
                            {template.description}
                          </p>
                        </Box>
                      </Box>
                    </Link>
                  </Grid>
                );
              })
          ) : isFetching ? (
            <LoadingView />
          ) : (
            <p style={{ padding: '0 10px' }}>No Email templates to show...</p>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

Classes.propTypes = {
  classes: PropType.object.isRequired
};

export default withStyles(styles)(Classes);
