import {
  ArrToObj,
  changeDatereverse,
  checkMinTime,
  checkValid,
  getIdfromArr,
  getValuefromObject,
  setBackDate
} from 'src/utils/someCommon';
import { checkValidNum, checkValidString } from 'src/utils/validations';
import moment from 'moment';
import { EndTypeData, Repeatdata, WeekData } from './createClass.Data';

const CheckCustWeekClass = (arr) => {
  for (let i = 0; i < arr.length; i += 1) {
    // eslint-disable-next-line
    for (let key in arr[i]) {
      const week = arr[i][key];
      if (week.is_limit) {
        if (checkValidNum(week.class_limit)) {
          return false;
        }
        if (week.allow_waitlist) {
          if (!week.class_open) {
            return false;
          }
        }
      }
    }
  }
  return true;
};

const validateWeekData = (arr) => {
  let result = {};
  for (let i = 0; i < arr.length; i += 1) {
    const values = Object.keys(arr[i]);
    for (let j = 0; j < values.length; j += 1) {
      const b = {};
      const data = values[j];
      const temp = arr[i][data];
      b[data] = {
        is_limit: temp.is_limit ? 1 : 0,
        head_coach: temp.head_coach || null,
        co_coach: temp.co_coach || null
      };
      if (temp.head_coach && typeof temp.head_coach === 'object') {
        b[data] = {
          ...b[data],
          head_coach: temp.head_coach.id
        };
      }
      if (temp.co_coach && typeof temp.co_coach === 'object') {
        b[data] = {
          ...b[data],
          co_coach: temp.co_coach.id
        };
      }
      if (temp.is_limit) {
        b[data] = {
          ...b[data],
          class_limit: temp.class_limit,
          allow_waitlist: temp.allow_waitlist ? 1 : 0
        };
        if (temp.allow_waitlist) {
          b[data] = {
            ...b[data],
            class_open: parseFloat(temp.class_open)
          };
        }
      }
      result = { ...result, ...b };
    }
  }
  return result;
};

const setDayofWeek = (week) => {
  const data = week.split(',');
  const result = [];
  // eslint-disable-next-line
  for (let i = 0; i < data.length; i += 1) {
    const b = {
      id: data[i],
      label: WeekData.filter((list) => list.id === data[i])[0].label,
      colors: true
    };
    result.push(b);
  }
  return result;
};

const setMetaData = (meta, dayofweek, coaches) => {
  const days = dayofweek.split(',');
  const result = [];
  for (let i = 0; i < days.length; i += 1) {
    const b = {};
    const temp = days[i];
    b[temp] = {
      is_limit: !!meta[temp].is_limit,
      head_coach: null,
      co_coach: null
    };
    if (meta[temp].head_coach) {
      b[temp] = {
        ...b[temp],
        head_coach:
          coaches.filter((list) => list.id === meta[temp].head_coach)[0] || null
      };
    }
    if (meta[temp].co_coach) {
      b[temp] = {
        ...b[temp],
        co_coach:
          coaches.filter((list) => list.id === meta[temp].co_coach)[0] || null
      };
    }
    if (meta[temp].is_limit) {
      b[temp] = {
        ...b[temp],
        allow_waitlist: !!meta[temp].allow_waitlist,
        class_limit: parseInt(meta[temp].class_limit, 10)
      };
    }
    if (meta[temp].allow_waitlist) {
      b[temp] = {
        ...b[temp],
        class_open: String(meta[temp].class_open)
      };
    }
    result.push(b);
  }
  return result;
};

export const validClassStep1 = (comm, Recc, reccuring) => {
  if (!reccuring) {
    const updateComm = { ...comm };
    delete updateComm.attendanceLimit;
    if (
      !checkValid(updateComm)
      || checkMinTime(updateComm.EndTime, updateComm.startTime)
    ) {
      return false;
    }
    return true;
    // eslint-disable-next-line
  } else {
    const updateComm = { ...comm };
    delete updateComm.attendanceLimit;
    if (
      !checkValid(updateComm)
      || !Recc.endType
      || checkMinTime(updateComm.EndTime, updateComm.startTime)
    ) {
      return false;
    }
    if (Recc.endType && Recc.endType.id === 'ondate') {
      if (!Recc.endDate || String(Recc.endDate).includes('Invalid')) {
        return false;
      }
    }
    return true;
  }
};

export const validClassStep2 = (
  nonRecc,
  ClassLimit,
  custWeeks,
  ReccWeek,
  recc
) => {
  if (!recc) {
    if (nonRecc.Classlimit) {
      if (checkValidNum(ClassLimit.classlimit)) {
        return false;
      }
      if (ClassLimit.allowWaitlist) {
        if (!ClassLimit.classOpen) {
          return false;
        }
      }
      return true;
    }
    // eslint-disable-next-line
  } else {
    if (custWeeks.length === 0 || !ReccWeek.repeatType) {
      return false;
    }
    if (ReccWeek.repeatType && ReccWeek.repeatType.id === 'custom') {
      if (!ReccWeek.repeatWeeks) {
        return false;
      }
    }
    if (!CheckCustWeekClass(ReccWeek.reccWeeks)) {
      return false;
    }
  }
  return true;
};

export const removeWeekdays = (arr, week) => {
  const DataArr = [...arr];
  const resultArr = [];
  for (let i = 0; i < DataArr.length; i += 1) {
    if (Object.keys(DataArr[i])[0] === week.id) {
      i += 1;
    }
    if (i < DataArr.length) resultArr.push(DataArr[i]);
  }
  return resultArr;
};

export const getRepWeek = (arr, data) => {
  const dataArr = [...arr];
  let flag = 0;
  for (let i = 0; i < dataArr.length; i += 1) {
    if (Object.keys(data)[0] === Object.keys(dataArr[i])[0]) {
      dataArr[i] = data;
      flag = 1;
    }
  }

  if (flag === 0) {
    return [...dataArr, data];
  }
  return dataArr;
};

export const getData = (comm, ReccS, nonRecc, Limit, CustWeek, Recc, recc) => {
  if (!recc) {
    let result = {};
    let UpdatedValues = {
      name: comm.ClassName,
      program_id: getValuefromObject(comm.program),
      start_time: moment(comm.startTime).format('hh:mm A'),
      end_time: moment(comm.EndTime).format('hh:mm A'),
      attendence_limit: comm.attendanceLimit ? 1 : 0,
      start_date: moment(comm.startTime).format('YYYY-MM-DD'),
      is_limit: nonRecc.Classlimit ? 1 : 0,
      allow_waitlist: Limit.allowWaitlist ? 1 : 0
    };
    if (nonRecc.headCoach) {
      UpdatedValues = {
        ...UpdatedValues,
        head_coach: getValuefromObject(nonRecc.headCoach)
      };
    }
    if (nonRecc.coCoach) {
      UpdatedValues = {
        ...UpdatedValues,
        co_coach: getValuefromObject(nonRecc.coCoach)
      };
    }
    if (nonRecc.Classlimit) {
      UpdatedValues = {
        ...UpdatedValues,
        class_limit: Limit.classlimit
      };
    }
    if (Limit.allowWaitlist && nonRecc.Classlimit) {
      UpdatedValues = {
        ...UpdatedValues,
        class_open: Limit.classOpen
      };
    }
    result = {
      recc,
      data: { ...UpdatedValues }
    };
    return result;
    // eslint-disable-next-line
  } else {
    let result = {};
    let UpdatedVal = {
      name: comm.ClassName,
      program_id: getValuefromObject(comm.program),
      start_time: moment(comm.startTime).format('hh:mm A'),
      end_time: moment(comm.EndTime).format('hh:mm A'),
      attendence_limit: comm.attendanceLimit ? 1 : 0,
      end_type: getValuefromObject(ReccS.endType),
      start_date: changeDatereverse(comm.startTime),
      end_date: changeDatereverse(ReccS.endDate),
      repeat_type: getValuefromObject(Recc.repeatType),
      day_of_week: getIdfromArr(CustWeek),
      meta: JSON.stringify(ArrToObj(Recc.reccWeeks))
    };
    if (Recc.repeatType.id === 'custom') {
      UpdatedVal = {
        ...UpdatedVal,
        repetition_period: getValuefromObject(Recc.repeatWeeks)
      };
    }
    result = {
      recc,
      data: { ...UpdatedVal }
    };
    return result;
  }
};

export const getNonReccEdit = (data) => {
  let val = {
    id: data.id,
    name: data.name,
    program_id: data.program_id,
    program: data.program_name,
    start_date: setBackDate(data.start_date),
    head_coach: data.head_coach
      ? { id: parseInt(data.head_coach, 10), name: data.head_coach_name }
      : null,
    co_coach: data.co_coach
      ? { id: parseInt(data.co_coach, 10), name: data.co_coach_name }
      : null,
    start_time: moment(data.start_date + ' ' + data.start_time).toDate(),
    end_time: moment(data.start_date + ' ' + data.end_time).toDate(),
    attendence_limit: !!data.attendence_limit,
    is_limit: !!data.is_limit,
    status: data.status
  };
  if (val.is_limit) {
    val = {
      ...val,
      class_limit: parseInt(data.class_limit, 10),
      allow_waitlist: !!data.allow_waitlist
    };
  }
  if (val.allow_waitlist) {
    val = {
      ...val,
      class_open: String(data.class_open)
    };
  }
  return val;
};

export const getReccurEdit = (data, coaches) => {
  let val = {
    name: data.name,
    id: data.id,
    program_id: data.program_id,
    program: data.program_name,
    start_date: setBackDate(data.start_date),
    start_time: moment(data.start_time, 'hh:mm')
      .add(1, 'days')
      .toDate(),
    end_time: moment(data.end_time, 'hh:mm')
      .add(1, 'days')
      .toDate(),
    attendence_limit: !!data.attendence_limit,
    // is_limit: data.is_limit ? true : false,
    day_of_week: setDayofWeek(data.day_of_week),
    meta: setMetaData(data.meta, data.day_of_week, coaches),
    repeat_type: Repeatdata.filter((list) => list.id === data.repeat_type)[0],
    end_type: EndTypeData.filter((list) => list.id === data.end_type)[0]
  };
  if (data.end_date) {
    val = {
      ...val,
      end_date: setBackDate(data.end_date)
    };
  }
  if (data.repeat_type === 'custom') {
    val = {
      ...val,
      repetition_period: {
        id: data.repetition_period,
        name: data.repetition_period
      }
    };
  }
  return val;
};

export const getUpdateClass = (data, SingClass) => {
  if (SingClass) {
    let val = {};
    if (
      data.name.length === 0
      || checkValidString(data.name)
      || String(data.start_time).includes('Invalid')
      || String(data.end_time).includes('Invalid')
      || checkMinTime(data.end_time, data.start_time)
      || !data.start_time
      || !data.end_time
    ) {
      return null;
    }
    if (data.is_limit) {
      if (checkValidNum(data.class_limit)) {
        return null;
      }
      if (data.allow_waitlist) {
        if (!data.class_open) {
          return null;
        }
      }
    }
    val = {
      name: data.name,
      start_date: moment(data.start_time).format('YYYY-MM-DD'),
      start_time: moment(data.start_time).format('hh:mm A'),
      program_id: data.program_id,
      end_time: moment(data.end_time).format('hh:mm A'),
      attendence_limit: data.attendence_limit ? 1 : 0,
      is_limit: data.is_limit ? 1 : 0,
      head_coach: null,
      co_coach: null
    };
    if (data.head_coach) {
      val = {
        ...val,
        head_coach: getValuefromObject(data.head_coach)
      };
    }
    if (data.co_coach) {
      val = {
        ...val,
        co_coach: getValuefromObject(data.co_coach)
      };
    }
    if (data.is_limit) {
      val = {
        ...val,
        class_limit: data.class_limit,
        allow_waitlist: data.allow_waitlist ? 1 : 0
      };
      if (data.allow_waitlist && data.is_limit) {
        val = {
          ...val,
          class_open: String(data.class_open)
        };
      }
    }
    return val;
    // eslint-disable-next-line
  } else {
    if (
      data.name.length === 0
      || !data.start_date
      || String(data.start_date).includes('Invalid')
      || !data.start_time
      || checkMinTime(data.end_time, data.start_time)
      || !data.repeat_type
      || !data.end_type
      || !data.end_time
      || data.day_of_week.length === 0
      || !CheckCustWeekClass(data.meta)
    ) {
      return null;
    }
    if (data.end_type === 'ondate') {
      if (!data.end_date || String(data.end_date).includes('Invalid')) {
        return null;
      }
    }
    if (data.repeat_type === 'custom') {
      if (!data.repetition_period) {
        return null;
      }
    }
    let value = {
      name: data.name,
      program_id: data.program_id,
      start_time: moment(data.start_time).format('hh:mm A'),
      end_time: moment(data.end_time).format('hh:mm A'),
      attendence_limit: data.attendence_limit ? 1 : 0,
      end_type: getValuefromObject(data.end_type),
      start_date: changeDatereverse(data.start_date),
      repeat_type: getValuefromObject(data.repeat_type),
      day_of_week: getIdfromArr(data.day_of_week),
      meta: JSON.stringify(validateWeekData(data.meta))
    };
    if (data.end_type.id === 'ondate') {
      value = {
        ...value,
        end_date: changeDatereverse(data.end_date)
      };
    }
    if (data.repeat_type.id === 'custom') {
      value = {
        ...value,
        repetition_period: getValuefromObject(data.repetition_period)
      };
    }
    return value;
  }
};

export const getValidStep1 = (data, recurring, ReccStep1) => {
  if (!data.program || !data.startTime || !data.ClassName) {
    return true;
  }
  if (recurring) {
    if (!ReccStep1.endType) {
      return true;
    }
  }
  return false;
};

export const getValidStep2 = (
  nonReccur,
  recurring,
  classLimit,
  ReccWeek,
  customWeeks
) => {
  if (!recurring) {
    if (nonReccur.Classlimit) {
      if (checkValidNum(classLimit.classlimit)) {
        return true;
      }
      if (classLimit.allowWaitlist) {
        if (!classLimit.classOpen) {
          return true;
        }
      }
    }
    return false;
    // eslint-disable-next-line no-else-return
  } else {
    if (customWeeks.length === 0 || !ReccWeek.repeatType) {
      return true;
    }
    if (ReccWeek.repeatType?.id === 'custom') {
      if (!ReccWeek.repeatWeeks) {
        return true;
      }
    }
    if (!CheckCustWeekClass(ReccWeek.reccWeeks)) {
      return false;
    }
    return false;
  }
};
