import React from 'react';

// eslint-disable-next-line
export const getRegex = (value, search) => {
  const regex = new RegExp(`(${search})`, 'gi');
  const text = value.split(regex);
  return text.map(
    // eslint-disable-next-line
    (part) =>
      regex.test(part) ? (
        <strong style={{ color: '#01847A' }}>{part}</strong>
      ) : (
        <span>{part}</span>
      )
  );
};
