import Types from './member.Types';
import { getMembers } from './memberReducer.utils';
import filterlist from '../Reducers.utils';

const initialValue = {
  activeMembers: { isFetching: false, data: null, error: null },
  InactiveMembers: { isFetching: false, data: null, error: null },
  AllMembers: { isFetching: false, data: null, error: null },
  allActiveMembers: { isFetching: false, data: null, error: null },

  actMemFilter: {
    page: 1,
    search: '',
    customFilter: {},
    tags: [],
    sorting: { sort_name: 'first_name', sort_by: 'asc' },
    default: { status: 1 }
  },
  InactMemFilter: {
    page: 1,
    search: '',
    customFilter: {},
    tags: [],
    sorting: { sort_name: 'first_name', sort_by: 'asc' },
    default: { status: 0 }
  },
  allMemFilter: {
    page: 1,
    search: '',
    customFilter: {},
    tags: [],
    sorting: { sort_name: 'first_name', sort_by: 'asc' }
  },
  areAllSelected: false
};

export default (state = initialValue, action) => {
  switch (action.type) {
    case Types.getAllActiveMem:
      return {
        ...state,
        allActiveMembers: getMembers(
          Types.getAllActiveMem,
          action.actionType,
          state.allActiveMembers,
          action.payload
        )
      };
    case Types.getActiveMem:
      return {
        ...state,
        activeMembers: getMembers(
          Types.getActiveMem,
          action.actionType,
          state.activeMembers,
          action.payload
        )
      };
    case Types.getInActiveMem:
      return {
        ...state,
        InactiveMembers: getMembers(
          Types.getInActiveMem,
          action.actionType,
          state.InactiveMembers,
          action.payload
        )
      };
    case Types.getAllMem:
      return {
        ...state,
        AllMembers: getMembers(
          Types.getAllMem,
          action.actionType,
          state.AllMembers,
          action.payload
        )
      };

    case Types.setActiveFilter:
      return {
        ...state,
        actMemFilter: filterlist(
          state.actMemFilter,
          action.filter,
          action.payload
        )
      };
    case Types.setInActiveFilter:
      return {
        ...state,
        InactMemFilter: filterlist(
          state.InactMemFilter,
          action.filter,
          action.payload
        )
      };
    case Types.setAllFilter:
      return {
        ...state,
        allMemFilter: filterlist(
          state.allMemFilter,
          action.filter,
          action.payload
        )
      };
    case Types.toggleSelect:
      return {
        ...state,
        areAllSelected: action.payload
      };

    default:
      return state;
  }
};
