/* eslint-disable react/no-danger */
/* eslint-disable operator-linebreak */
import React, { useState, useEffect, useMemo } from 'react';
import PropType from 'prop-types';
import { connect } from 'react-redux';
import clsx from 'clsx';
import queryString from 'query-string';
import {
  Grid,
  Card,
  Typography,
  Box,
  Breadcrumbs,
  withStyles,
  Button
} from '@material-ui/core';
import { Redirect, Link } from 'react-router-dom';
import { ROUTES } from 'src/constant/Constants';
import moment from 'moment';
import DOMPurify from 'dompurify';
import TextareaControl from 'src/views/common/FormControl/TextareaControl';
import CancelDialog from 'src/views/common/CommonDialogBox/CancelDialog';
import NotFoundView from 'src/views/errors/NotFoundView';
import { openNoti } from 'src/Redux/global/global.actions';
import { editInvoice, updateInvoice } from 'src/services/Invoice.services';
import { getErrorMsg } from 'src/utils/ErrorHandling';
import LoadingView from 'src/views/common/loading';
import GetPrice from 'src/views/common/CurrencySymbol/GetPrice';
import CancelDialogTwo from 'src/views/common/CommonDialogBox/CancelDialogTwo';
import useGymSetting from 'src/views/common/CustomHooks/useGymSettingRefresh';
import useChangeTitle from 'src/views/common/CustomHooks/useTitleChange';
import { ReactComponent as SearchIcon } from '../../../../assets/image/searchIcon.svg';
import JBButton from '../../../common/JBButton/JBButton';
import PaidAction from './PaidAction';
import styles from './InvoiceView.style';
import InvoiceTable from './InvoiceTable/InvoiceTable';
import AddItem from './AddItem';
import {
  getCreatedOn,
  getInvoiceData,
  // getInvoiceStatus,
  getUpdatedOn
} from './InvoiceView.utils';
import TransactionDiag from './TransactionsInvoiceDialog';

const EditInvoiceView = (props) => {
  const {
    classes,
    location,
    OpenNoti,
    GetInvoicefooter: { data: Invoicefooter, error: Invoicefootererror }
  } = props;
  const [AddItemDialog, setAddItemDialog] = useState(false);
  const [data, setData] = useState(null);
  const [errorFetch, setErrFetch] = useState(false);
  const { Invoice, status } = queryString.parse(location.search);
  const [TransInvoiceDialog, setTransInvoiceDialog] = useState(false);
  const [open, setOpen] = useState(false);
  useChangeTitle('Edit Invoice | Jungle Alliance');
  useEffect(() => {
    editInvoice(atob(Invoice))
      .then((res) => setData(getInvoiceData(res[0])))
      .catch(() => setErrFetch(true));
  }, [Invoice, status]);
  const { dateformat, timeformat } = useGymSetting();

  // footer key updated by BE
  // eslint-disable-next-line no-unused-vars
  const footerSanitized = useMemo(() => {
    return DOMPurify.sanitize(Invoicefooter);
  }, [Invoicefooter]);

  if (Invoicefootererror || errorFetch || !Invoice) {
    return <NotFoundView />;
  }

  // if (data && data.status !== 2 && data.status !== 5) {

  if (data && data.status === 1) {
    return (
      <Redirect
        to={{
          pathname: ROUTES.FINANCIAL_INVOICES_VIEW,
          search: `Invoice=${btoa(data.id)}`
        }}
      />
    );
  }

  if (!data) return <LoadingView />;

  const handleSave = () => {
    const value = {
      auto_bill: data.auto_bill ? 1 : 0,
      due_date: moment(data.due_date).format('YYYY-MM-DD'),
      notes: data.notes,
      thanks_msg: data.thanks_msg
    };
    updateInvoice(value, data.id)
      .then(() => OpenNoti('Successfully Saved your Invoice', 'info'))
      .catch((err) => OpenNoti(getErrorMsg(err), 'error'));
  };

  return (
    <div className={classes.root}>
      <div className="title-bk">
        <Breadcrumbs aria-label="breadcrumb">
          <div
            color="inherit"
            onClick={() => setOpen(true)}
            style={{
              cursor: 'pointer'
            }}
          >
            financial
          </div>
          <Typography variant="h3" color="Primary">
            <span>invoices</span>
          </Typography>
        </Breadcrumbs>
      </div>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        spacing={0}
        style={{ margin: '20px 0 20px' }}
      >
        <Grid item xs={6}>
          <h1 style={{ margin: '0 0' }}>
            Edit Invoice ”{data.invoice_no || String(data.id).padStart(7, '0')}”
          </h1>
        </Grid>
        <Grid
          item
          xs={6}
          className={classes.btnBlock}
          style={{ justifyContent: 'flex-end', display: 'flex' }}
        >
          {data.status === 9 || data.status === 6 ? null : (
            <PaidAction
              data={data}
              updateData={(val) => setData(getInvoiceData(val[0]))}
            />
          )}
          <Box ml="15px" />
          <CancelDialog
            handleHistory={status ? ROUTES.FINANCIAL_INVOICES_PAID : null}
          />
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        spacing={0}
        style={{ margin: '15px 0 15px' }}
      >
        <Grid item xs={12}>
          <Box className={classes.invoicehead}>
            <Box className={classes.invoiceheadBox}>
              <span>Tax Invoice #</span>
              <label>
                {data.invoice_no || String(data.id).padStart(7, '0')}
              </label>
            </Box>
            <Box className={classes.invoiceheadBox}>
              <span>Member</span>
              <label>
                <Link
                  to={{
                    pathname: ROUTES.PEOPLE_MEMBER_PROFILE,
                    search: `memberId=${btoa(data.get_user_name.id)}`
                  }}
                  style={{ color: '#01847a', fontWeight: '600' }}
                >
                  {data.get_user_name.name}
                </Link>
              </label>
            </Box>
            <Box className={classes.invoiceheadBox}>
              <span>Location</span>
              <label>{data.location_name}</label>
            </Box>
            <Box className={classes.invoiceheadBox}>
              <span>Auto bill</span>
              <label>{data.auto_bill === true ? 'Yes' : 'NO'}</label>
            </Box>
            <Box className={classes.invoiceheadBox}>
              <span>Invoice Status</span>
              <label
                style={{
                  display: 'inline-block'
                }}
              >
                {/* {getInvoiceStatus(data.status)} */}
                {data.invoice_status ? data.invoice_status : '-'}
              </label>
              <Button
                onClick={() => setTransInvoiceDialog(true)}
                className={classes.SearchTrans}
              >
                <SearchIcon />
              </Button>
            </Box>
            <Box className={classes.invoiceheadBox}>
              <span>Payment due</span>
              {/* <label>{data.due_date}</label> */}
              <label>{moment(data.due_date).format(`${dateformat}`)}</label>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Card className={clsx(classes.mainCard, classes.InvoiceCard)}>
        <Grid container direction="row" alignItems="center" spacing={0}>
          <Grid
            item
            xs={12}
            style={{
              marginBottom: '20px'
            }}
          >
            <InvoiceTable
              values={data.get_line_item || []}
              deleteItem={(val) => setData(getInvoiceData(val[0]))}
              statuscode={data.status}
            />
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          // justify="flex-end"
          alignItems="center"
          className={classes.totalItem}
        >
          <Grid item xs={10}>
            <Typography variant="h6">
              <span>Item Subtotal</span>
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="h6" align="center">
              {/* <span>A$280.00</span> */}
              <GetPrice value={data.final_subtotal} />
            </Typography>
          </Grid>

          {data.invoice_discounted_amount > 0 && (
            <>
              <Grid item xs={10}>
                <Typography variant="h6">
                  {/* <span>20% Invoice Discount</span> */}
                  <span>
                    {data.invoice_discount}
                    {''}
                    {data.discount_type === 'percentage' ||
                    !data.invoice_discount
                      ? '%'
                      : null}{' '}
                    Invoice Discount
                  </span>
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="h6" align="center">
                  {/* <span>-A$56.00</span> */}
                  <GetPrice value={data.invoice_discounted_amount} />
                </Typography>
              </Grid>
            </>
          )}

          {!data.refunded_amount ? null : (
            <>
              <Grid item xs={10}>
                <Typography variant="h6">
                  <span>Refunded w/Tax</span>
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="h6" align="center">
                  <div className={classes.refunded_amount}>
                    (<GetPrice value={data.refunded_amount} valuetype="neg" />)
                  </div>
                </Typography>
              </Grid>
            </>
          )}

          <Grid
            container
            direction="row"
            alignItems="center"
            className={classes.totalDue}
          >
            <Grid item xs={10}>
              <Typography variant="h6">
                <span>Total Due (Including GST)</span>
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="h6" align="center">
                {/* <span>A$224.00</span> */}
                <GetPrice value={data.final_amount} />
              </Typography>
            </Grid>
          </Grid>

          {data.final_discount > 0 && (
            <>
              <Grid item xs={10}>
                <Typography variant="h6">
                  <span>Total Discount Applied</span>
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="h6" align="center">
                  {/* <span>-A$126.00</span> */}
                  <GetPrice value={data.final_discount} />
                </Typography>
              </Grid>
            </>
          )}
          <Grid item xs={10}>
            <Typography variant="h6">
              <span>GST Paid</span>
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="h6" align="center">
              {/* <span>A$20.36</span> */}
              <GetPrice value={data.final_gst} />
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          alignItems="center"
          style={{
            margin: '15px 0'
          }}
        >
          <Grid item xs={6}>
            <TextareaControl
              placeholder="Add note"
              className={classes.TextareaControlNots}
              value={data.notes}
              onChange={(val) => setData({ ...data, notes: val.target.value })}
            />
            <Box mb="20px" />
            <TextareaControl
              placeholder="Add ”Thank You” message"
              className={classes.TextareaControlNots}
              value={data.thanks_msg}
              onChange={(val) =>
                setData({ ...data, thanks_msg: val.target.value })
              }
            />
          </Grid>
          <Grid item xs={6} justify="flex-end" className="MuiGrid-container">
            <Box
              css={{
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                width: '150px',
                textAlign: 'right'
              }}
            >
              {/* <p className={classes.ptext}>{Invoicefooter || ''}</p> */}
              <div
                // dangerouslySetInnerHTML={{ __html: footerSanitized || '' }}
                dangerouslySetInnerHTML={{
                  __html: data.invoice_footer || ''
                }}
              />
            </Box>
          </Grid>
        </Grid>

        <Grid container direction="row" alignItems="center">
          <Grid item xs={6}>
            <Box mt="25px">
              <Box className={classes.invoicestatus}>
                <span>Created:</span>
                <label>{getCreatedOn(data, timeformat)}</label>
              </Box>
              <Box className={classes.invoicestatus}>
                <span>Updated:</span>
                <label>{getUpdatedOn(data, timeformat)}</label>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={6}
            justify="flex-end"
            alignItems="center"
            className="MuiGrid-container"
          >
            <JBButton type="primary" onClick={handleSave}>
              Save
            </JBButton>
          </Grid>
        </Grid>
        {AddItemDialog && (
          <AddItem
            open={AddItemDialog}
            handleClose={() => setAddItemDialog(false)}
            addItemId={data.id}
            addItem={(val) => setData(getInvoiceData(val[0]))}
          />
        )}
        {TransInvoiceDialog && (
          <TransactionDiag
            invoiceNumber={data.invoice_no || String(data.id).padStart(7, '0')}
            setOpen={TransInvoiceDialog}
            handleClose={() => setTransInvoiceDialog(false)}
            id={data.id}
          />
        )}
        <CancelDialogTwo setOpen={open} handleClose={() => setOpen(false)} />
      </Card>
    </div>
  );
};

EditInvoiceView.propTypes = {
  classes: PropType.object.isRequired,
  location: PropType.object.isRequired,
  GetInvoicefooter: PropType.object.isRequired,
  OpenNoti: PropType.func.isRequired
};
const mapToState = (state) => ({
  currencyState: state.SettingReducer.currency,
  GetInvoicefooter: state.SettingReducer.invoice_footer
});

const mapToDispatch = (dispatch) => ({
  OpenNoti: (msg, type) => dispatch(openNoti(msg, type))
});
export default connect(
  mapToState,
  mapToDispatch
)(withStyles(styles)(EditInvoiceView));
