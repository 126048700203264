import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    '&::focus': {
      borderColor: 'red',
      outline: 'red'
    }
  },
  paper: {
    marginRight: theme.spacing(2)
  },
  cutom_inp_Label: {
    fontSize: '12px',
    padding: '7px 0'
  },
  filterInfo: {
    '&:focus': {
      outline: '0 !important'
    }
  }
}));

export default useStyles;
