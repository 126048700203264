import React from 'react';
import PropType from 'prop-types';
import { Link } from 'react-router-dom';
import { ROUTES } from 'src/constant/Constants';
import {
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  withStyles,
  TableSortLabel
} from '@material-ui/core';
import GetPrice from 'src/views/common/CurrencySymbol/GetPrice';
import ReducerConstants from 'src/Redux/Reducer.constants';

const styles = () => ({
  table: {
    minWidth: '100%',
    wordBreak: 'break-word',
    '& td': {
      padding: '15px'
    },
    '@media (max-width: 1366px)': {
      '& td': {
        fontSize: '13px',
        padding: '13px'
      }
    }
  },
  tableLink: {
    color: '#01847A',
    fontWeight: '700'
  },
  TableCell: {
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px',
    height: '56px',
    minHeight: '56px',
    padding: '15px',
    '@media (max-width: 1366px)': {
      fontSize: '13px',
      wordBreak: 'keep-all',
      padding: '13px'
    }
  },
  Tablemian: {}
});

const PaymentPlanTable = (props) => {
  const { values, classes, isActive, setDirection, setSort } = props;
  const {
    sort_members,
    sort_member_since,
    sort_membership_location,
    sort_memberships,
    sort_payment_plan,
    sort_commitment_total,
    sort_renew_total,
    sortstartdate,
    sortexpiredate
  } = ReducerConstants;
  return (
    <TableContainer
      component={Paper}
      elevation={0}
      className={classes.Tablemian}
    >
      <Table className={classes.table} aria-label="simple table">
        <TableHead
          style={{ background: '#F9F9FA', backgroundColor: '#F9F9FA' }}
        >
          <TableRow>
            <TableCell className={classes.TableCell}>
              <TableSortLabel
                active={isActive(sort_members)}
                direction={setDirection(sort_members)}
                onClick={() => setSort(sort_members)}
              >
                Member
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell}>
              <TableSortLabel
                active={isActive(sort_member_since)}
                direction={setDirection(sort_member_since)}
                onClick={() => setSort(sort_member_since)}
              >
                Member since
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell}>
              <TableSortLabel
                active={isActive(sort_membership_location)}
                direction={setDirection(sort_membership_location)}
                onClick={() => setSort(sort_membership_location)}
              >
                Membership location
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell}>
              <TableSortLabel
                active={isActive(sort_memberships)}
                direction={setDirection(sort_memberships)}
                onClick={() => setSort(sort_memberships)}
              >
                Membership
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell}>
              <TableSortLabel
                active={isActive(sort_payment_plan)}
                direction={setDirection(sort_payment_plan)}
                onClick={() => setSort(sort_payment_plan)}
              >
                Payment plan
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell}>
              <TableSortLabel
                active={isActive(sort_commitment_total)}
                direction={setDirection(sort_commitment_total)}
                onClick={() => setSort(sort_commitment_total)}
              >
                Commitment total
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell}>
              <TableSortLabel
                active={isActive(sort_renew_total)}
                direction={setDirection(sort_renew_total)}
                onClick={() => setSort(sort_renew_total)}
              >
                Renewal total
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell}>
              <TableSortLabel
                active={isActive(sortstartdate)}
                direction={setDirection(sortstartdate)}
                onClick={() => setSort(sortstartdate)}
              >
                Start date
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell}>
              <TableSortLabel
                active={isActive(sortexpiredate)}
                direction={setDirection(sortexpiredate)}
                onClick={() => setSort(sortexpiredate)}
              >
                Expiration Date
              </TableSortLabel>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {values
            && values.map((row) => (
              <TableRow
                hover
                tabIndex={-1}
                key={row.id}
                style={{
                  backgroundColor: '#FFF'
                }}
              >
                <TableCell
                  style={{
                    width: '9.0909%'
                  }}
                >
                  <Link
                    className={classes.tableLink}
                    to={{
                      pathname: ROUTES.PEOPLE_MEMBER_PROFILE,
                      search: `memberId=${btoa(row.id)}`
                    }}
                  >
                    {row?.member}
                  </Link>
                </TableCell>
                <TableCell
                  style={{
                    width: '9.0909%'
                  }}
                >
                  {row?.member_since}
                </TableCell>
                <TableCell
                  style={{
                    width: '9.0909%'
                  }}
                >
                  {row?.membership_location}
                </TableCell>
                <TableCell
                  style={{
                    width: '9.0909%'
                  }}
                >
                  {row?.membership}
                </TableCell>
                <TableCell
                  style={{
                    width: '9.0909%'
                  }}
                  className={classes.tableLink}
                >
                  {row?.payment_plan}
                </TableCell>

                <TableCell
                  style={{
                    width: '9.0909%'
                  }}
                >
                  <GetPrice value={parseInt(row?.commitement_total, 10)} />
                </TableCell>

                <TableCell
                  style={{
                    width: '9.0909%'
                  }}
                >
                  {row?.renew_total && (
                    <GetPrice value={parseInt(row?.renew_total, 10)} />
                  )}
                </TableCell>

                <TableCell
                  style={{
                    width: '9.0909%'
                  }}
                >
                  {row.start_date}
                </TableCell>
                <TableCell
                  style={{
                    width: '9.0909%'
                  }}
                >
                  {row.expires_date}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      {values?.length === 0 && (
        <p
          style={{
            margin: '10px 15px'
          }}
        >
          No Members and Membership by payment plan to show...
        </p>
      )}
    </TableContainer>
  );
};
PaymentPlanTable.propTypes = {
  values: PropType.array.isRequired,
  classes: PropType.object.isRequired,
  isActive: PropType.func.isRequired,
  setDirection: PropType.func.isRequired,
  setSort: PropType.func.isRequired
};

export default withStyles(styles)(PaymentPlanTable);
