import React, { useState, useContext } from 'react';
import PropType from 'prop-types';
import { ROUTES } from 'src/constant/Constants';
import { useDispatch } from 'react-redux';
import { openNoti } from 'src/Redux/global/global.actions';
import { useHistory, Link } from 'react-router-dom';
import { VanguardWaiverCtx } from 'src/Context/VanguardWaiverContext';
import clsx from 'clsx';
import {
  NextStep,
  StepBack,
  ResetStep,
  SetActiveStep
} from 'src/Context/VanguardWaiver/VanguardWaiver.actions';
import {
  assignWaiverToUserVanguard,
  updateUserWaiverVanguard,
  updateVanguardUser
} from 'src/services/vanguard.services';
import {
  Button,
  AppBar,
  Toolbar,
  IconButton,
  withStyles,
  Card,
  Box
} from '@material-ui/core';
import { getErrorMsg } from 'src/utils/ErrorHandling';
import useChangeTitle from 'src/views/common/CustomHooks/useTitleChange';
import {
  validateVanguardWaiver,
  getWaiverData,
  validateQues,
  updateVanguardUserData
} from './waiverValidation';
import { ReactComponent as BackArrow } from '../../../assets/image/Vanguard/icon_back_vanguard.svg';
import LeavePagePopup from '../LeavepagePopup';
import styles from './waiverIndex.style';
import JBVanguardStepper from '../../common/JBVanguardStepper';
import PersonalInfoWaiver from './personalInfoWaiver';
import SelectWaiver from './selectWaiver';
import Terms from './terms';
import Questionnaire from './questionnaire';
import Sign from './sign';
import SuccessPopup from '../successPopup';
import FindMember from './findMember';

function getSteps() {
  return ['1', '2', '3', '4', '5', '6'];
}

const Returning = ({ classes }) => {
  const history = useHistory();
  useChangeTitle(
    history.location.state.type === 'on_board'
      ? 'Onboarding member | Jungle Alliance'
      : 'Active Member | Jungle Alliance'
  );
  const [state, dispatch] = useContext(VanguardWaiverCtx);
  const reduxDispatch = useDispatch();
  const [LeavePageopen, setLeavePageopen] = useState(false);
  // const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const {
    activeStep,
    selectedWaiver,
    signStatus,
    isSigned,
    personal_info,
    personal_info: {
      waiver_terms_initial: { sign_initials, initial_box },
      questionnaire_meta
    }
  } = state;
  const [Sussopen, setSussopen] = useState(false);
  const [btnDisalbed, setBtnDisabled] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);
  // setTimeout(() => setSussopen(false), 1500);

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <FindMember />;
      case 1:
        return <PersonalInfoWaiver Maintitle="CHECK PERSONAL INfORMATION" />;
      case 2:
        return <SelectWaiver UserData={personal_info} />;
      case 3:
        return <Terms />;
      case 4:
        return <Questionnaire />;
      case 5:
        return <Sign />;
      default:
        return 'Unknown step';
    }
  }

  const handleNext = () => {
    if (activeStep === 0) {
      if (!personal_info.id) {
        reduxDispatch(openNoti('Please select a member', 'error'));
      } else {
        dispatch(NextStep());
        setBtnDisabled(false);
      }
    }
    if (activeStep === 1) {
      try {
        setIsProcessing(true);
        validateVanguardWaiver(personal_info);
        updateVanguardUser(updateVanguardUserData(state.personal_info))
          .then(() => {
            dispatch(NextStep());
            setIsProcessing(false);
          })
          .catch((err) => {
            reduxDispatch(openNoti(getErrorMsg(err), 'error'));
            setIsProcessing(false);
          });
      } catch (error) {
        console.warn(error);
        reduxDispatch(openNoti(error.message, 'error'));
        setIsProcessing(false);
      }
    }
    if (activeStep === 2) {
      if (selectedWaiver) {
        const val = {
          user_id: personal_info.id,
          waiver_id: selectedWaiver
        };
        assignWaiverToUserVanguard(val).then((res) => {
          console.warn(res);
        });
        dispatch(NextStep());
      } else {
        reduxDispatch(openNoti('Please select a Waiver', 'error'));
      }
    }
    if (activeStep === 3) {
      if (!signStatus) {
        return reduxDispatch(
          openNoti('You must agree to the terms and conditions', 'error')
        );
      }
      if (initial_box) {
        if (sign_initials.length === 0) {
          return reduxDispatch(openNoti('Initials Required', 'error'));
        }
      }

      if (questionnaire_meta.length === 0) {
        dispatch(SetActiveStep(5));
      } else {
        dispatch(NextStep());
      }
    }
    if (activeStep === 4) {
      // validateQues(questionnaire);
      if (validateQues(questionnaire_meta)) {
        reduxDispatch(
          openNoti('Please provide valid questionnaire details', 'error')
        );
      } else {
        dispatch(NextStep());
      }
    }
    if (activeStep === 5) {
      if (!isSigned) {
        reduxDispatch(openNoti('Please provide a signature', 'error'));
      } else if (!signStatus) {
        reduxDispatch(
          openNoti('You must agree to the terms and conditions', 'error')
        );
      } else {
        const formData = getWaiverData(personal_info);
        updateUserWaiverVanguard(formData, selectedWaiver)
          .then(() => {
            setSussopen(true);
            setTimeout(() => history.push(ROUTES.VANGUARD), 3500);
          })
          .catch((err) => reduxDispatch(openNoti(getErrorMsg(err), 'error'))); //eslint-disable-line
      }
    }

    return true;
  };

  const handleReset = () => {
    dispatch(ResetStep());
  };
  const handleBack = () => {
    // dispatch(StepBack());
    if (activeStep === 0) {
      history.goBack();
    }

    if (activeStep === 1) {
      setBtnDisabled(true);
    }

    if (activeStep === 5 && questionnaire_meta.length === 0) {
      dispatch(SetActiveStep(3));
    } else {
      dispatch(StepBack());
    }
  };

  return (
    <Box>
      <AppBar className={classes.appBar}>
        <Toolbar className={classes.appToolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="close"
            onClick={() => {
              setLeavePageopen(true);
            }}
          >
            <BackArrow />
          </IconButton>
          <h5 className={classes.headerTitle}>Waiver</h5>
          <Button
            color="inherit"
            className={classes.HomeButton}
            component={Link}
            to={ROUTES.VANGUARD}
          >
            Home
          </Button>
        </Toolbar>
      </AppBar>

      <Card
        className={clsx(
          classes.MainCard,
          questionnaire_meta.length === 0 && activeStep === 3
            ? 'VanguardStepperQuestionnaire'
            : null
        )}
      >
        <JBVanguardStepper
          steps={steps}
          activeStep={activeStep}
          handleBack={handleBack}
          handleNext={handleNext}
          handleReset={handleReset}
          // eslint-disable-next-line react/jsx-no-bind
          handleStepContent={getStepContent}
          isLoading={false}
          hasErrored={false}
          backLabel="Back"
          prevLabel="Sign"
          nextLabel="Next"
          isBackDisabled={btnDisalbed}
          processing={isProcessing}
        />
      </Card>
      <LeavePagePopup
        setOpen={LeavePageopen}
        handleClose={() => setLeavePageopen(false)}
        // handleHistory={ROUTES.VANGUARD}
      />
      <SuccessPopup
        Dialogtitle="Waiver signed successfully"
        setOpen={Sussopen}
        handleClose={() => setSussopen(false)}
      />
    </Box>
  );
};

Returning.propTypes = {
  classes: PropType.object.isRequired
};
export default withStyles(styles)(Returning);
