/* eslint-disable operator-linebreak */
/* eslint-disable indent */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropType from 'prop-types';
import {
  Checkbox,
  LinearProgress,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { ROUTES } from 'src/constant/Constants';
import filterType from 'src/Redux/Reducer.constants';
import { get } from 'js-cookie';
import ClassTableToolbar from './ClassTableHead';
import SampleDialog from './ReservationInfoDialog';

const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
  tableLink: {
    color: '#01847A',
    fontWeight: '700',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  dayofweek: {
    background: '#A9BFEF',
    borderRadius: '50px',
    padding: '8px',
    color: '#FFF'
  },
  TooltipLink: {
    '&:hover': {
      '& svg path': {
        stroke: '#01847A'
      }
    }
  },
  TableCell: {
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px',
    borderBottom: '1px solid #A8A8A8',
    height: '56px',
    minHeight: '56px'
  },
  selected: {
    backgroundColor: '#E6F3F2 !important'
  }
});

const ClassTable = (props) => {
  const classes = useStyles();
  const { values, isActive, setDirection, setSort, total } = props;
  const roleid = JSON.parse(get('jbrole') || '{}')?.role_id || 3;
  const allSelected = useSelector((state) => state.classReducer.areAllSelected);
  const {
    sortname,
    sortday,
    sortduration,
    sortlocation,
    sortprogram
  } = filterType;

  const [selected, setSelected] = useState([]);
  const [openreservation, setreservationdiag] = useState({
    open: false,
    data: null
  });

  const handleClick = (name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = values.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  const ClearCheckbox = () => {
    setSelected([]);
  };

  useEffect(() => {
    if (allSelected) {
      setSelected(values.map((c) => c.id));
    }
  }, [allSelected, values]);

  const isSelected = (name) => selected.indexOf(name) !== -1;
  return (
    <div>
      <TableContainer
        component={Paper}
        elevation={0}
        style={{
          overflowY: 'initial',
          // overflowX: 'inherit',
          minHeight: '146px'
        }}
      >
        {selected.length > 0 && (
          <ClassTableToolbar
            numSelected={selected}
            setSelected={setSelected}
            total={total}
            allSelected={allSelected}
            values={values}
            selectedTags={[]}
            ClearChecks={ClearCheckbox}
            module="class"
          />
        )}
        <Table
          className={classes.table}
          aria-label="simple table"
          style={{ overflowY: 'visible' }}
        >
          {selected.length === 0 && (
            <TableHead>
              <TableRow style={{ background: '#F9F9FA' }}>
                <TableCell padding="checkbox" className={classes.TableCell}>
                  <Checkbox
                    color="primary"
                    onChange={handleSelectAllClick}
                    inputProps={{ 'aria-labelledby': 'IDN' }}
                  />
                </TableCell>
                <TableCell
                  className={classes.TableCell}
                  style={{ width: '1%' }}
                >
                  &nbsp;
                </TableCell>
                <TableCell
                  style={{ width: '20%' }}
                  className={classes.TableCell}
                >
                  <TableSortLabel
                    active={isActive(sortname)}
                    direction={setDirection(sortname)}
                    onClick={() => setSort(sortname)}
                  >
                    Name
                  </TableSortLabel>
                </TableCell>
                <TableCell className={classes.TableCell}>
                  <TableSortLabel
                    active={isActive(sortday)}
                    direction={setDirection(sortday)}
                    onClick={() => setSort(sortday)}
                  >
                    Day
                  </TableSortLabel>
                </TableCell>
                <TableCell className={classes.TableCell}>
                  <TableSortLabel
                    active={isActive(sortduration)}
                    direction={setDirection(sortduration)}
                    onClick={() => setSort(sortduration)}
                  >
                    Duration
                  </TableSortLabel>
                </TableCell>
                <TableCell className={classes.TableCell}>
                  <TableSortLabel
                    active={isActive(sortprogram)}
                    direction={setDirection(sortprogram)}
                    onClick={() => setSort(sortprogram)}
                  >
                    Program
                  </TableSortLabel>
                </TableCell>
                <TableCell className={classes.TableCell}>Reservation</TableCell>
                <TableCell className={classes.TableCell}>Coaches</TableCell>
                <TableCell align="right" className={classes.TableCell}>
                  <TableSortLabel
                    active={isActive(sortlocation)}
                    direction={setDirection(sortlocation)}
                    onClick={() => setSort(sortlocation)}
                    style={{ width: '100px' }}
                  >
                    Location
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {values.map((row) => (
              <TableRow
                hover
                // role="checkbox"
                aria-checked={isSelected(row.id)}
                tabIndex={-1}
                key={row.id}
                selected={isSelected(row.id)}
                classes={{ selected: classes.selected }}
                style={{
                  backgroundColor: '#FFF'
                }}
              >
                <TableCell padding="checkbox" style={{ width: '1%' }}>
                  <Checkbox
                    onClick={() => handleClick(row.id)}
                    color="primary"
                    checked={isSelected(row.id)}
                    inputProps={{ 'aria-labelledby': 'IDN' }}
                  />
                </TableCell>

                <TableCell style={{ width: '2%' }}>
                  <span
                    style={{
                      backgroundColor: `${row.color}`,
                      width: '10px',
                      height: '10px',
                      position: 'relative',
                      // left: '-14px',
                      // top: '24px',
                      borderRadius: '50%',
                      minWidth: '10px',
                      marginRight: '0',
                      display: 'block',
                      margin: '0 auto'
                    }}
                  />
                </TableCell>
                <TableCell
                  style={{
                    position: 'relative',
                    color: '#01847A',
                    fontWeight: '700',
                    width: '20%',
                    minWidth: '20%'
                    // lineHeight: 'inherit',
                    // display: 'flex',
                    // alignItems: 'center'
                  }}
                >
                  {roleid <= 2 ? (
                    <Link
                      to={{
                        pathname: ROUTES.CLASSES_EDIT,
                        search: `Classid=${row.id}`
                      }}
                      className={classes.tableLink}
                    >
                      {row.name}
                    </Link>
                  ) : (
                    <span className={classes.tableLink}>{row.name}</span>
                  )}
                </TableCell>
                <TableCell style={{ width: '10%' }}>{row.day}</TableCell>
                <TableCell>{row.duration}</TableCell>
                <TableCell style={{ width: '10%' }}>
                  {row.program_name}
                </TableCell>
                <TableCell
                  style={{
                    color: '#01847A',
                    fontWeight: '700',
                    textAlign: 'center',
                    width: '11%'
                  }}
                >
                  <span
                    style={{
                      cursor:
                        row?.reservation?.length > 0 ? 'pointer' : 'default'
                    }}
                    onClick={() =>
                      row.reservation?.length > 0 &&
                      setreservationdiag({
                        open: true,
                        data: {
                          arr: row.reservation,
                          name: row.name,
                          limit: row.class_limit
                        }
                      })
                    }
                  >
                    {row.class_limit
                      ? row.class_data_reservation + ' of ' + row.class_limit
                      : row.class_data_reservation}
                  </span>
                  {row.reservation.filter((i) => i.is_waitlist === 1).length >
                  0 ? (
                    <span style={{ cursor: 'default' }}>
                      {` | +${row.class_data_reservation}`}
                    </span>
                  ) : null}
                  {Boolean(row.class_limit) && (
                    <LinearProgress
                      variant="determinate"
                      value={(row.reservation.length * 100) / row.class_limit}
                      className={classes.dprogress}
                    />
                  )}
                </TableCell>
                <TableCell style={{ width: '10%' }}>
                  {row.head_coach_name}
                </TableCell>
                <TableCell align="right" style={{ width: '100px' }}>
                  {row.location || null}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {openreservation.open && (
          <SampleDialog
            handleClose={() => setreservationdiag({ open: false, data: null })}
            open={openreservation.open}
            values={openreservation.data}
          />
        )}
        {values.length === 0 && (
          <p
            style={{
              margin: '10px 15px'
            }}
          >
            No Classes to show...
          </p>
        )}
      </TableContainer>
    </div>
  );
};
ClassTable.propTypes = {
  values: PropType.array.isRequired,
  isActive: PropType.func.isRequired,
  setDirection: PropType.func.isRequired,
  setSort: PropType.func.isRequired,
  total: PropType.number.isRequired
};

export default ClassTable;
