/* eslint-disable indent */
/* eslint-disable no-useless-catch */
import axios from 'axios';
import moment from 'moment';
import { set } from 'js-cookie';
import clearCredentials from 'src/utils/clearCredentials';
import noauthhttp from './noAuth.services';
import authhttp from './authHttp.services';

export const getLogin = async (val) => {
  try {
    const Url = 'api/login';
    const {
      data: {
        data: { access_token, refresh_token, expires_in, misc }
      }
    } = await noauthhttp.post(Url, val);
    const miscJson = JSON.parse(misc || '{}');
    // eslint-disable-next-line operator-linebreak
    miscJson.role_id =
      miscJson.role_id === 2
        ? 3
        : miscJson.role_id === 3
        ? 2
        : miscJson.role_id;
    const expiry = moment()
      .add(expires_in, 'seconds')
      .toDate();
    localStorage.setItem('x-token', access_token);
    localStorage.setItem('qid', refresh_token);
    localStorage.setItem('expires', expiry);
    set('jbrole', miscJson, {
      expires: moment()
        .add(4, 'days')
        .toDate()
    });
    return miscJson;
  } catch (err) {
    // TODO: LOG ERRORS AND SHOW NOTIFICATION
    throw err;
  }
};

export const setRefToken = async (refreshToken) => {
  const setRefUrl = 'api/refresh_token';
  try {
    const {
      data: {
        data: { access_token, refresh_token, expires_in }
      }
    } = await axios.post(setRefUrl, {
      refresh_token: refreshToken
    });
    localStorage.setItem('x-token', access_token);
    localStorage.setItem('qid', refresh_token);
    localStorage.setItem(
      'expires',
      moment()
        .add(expires_in, 'seconds')
        .toDate()
    );
    return true;
  } catch (error) {
    console.warn(error);
    throw error;
  }
};

export const resetPassoword = async (values) => {
  const resetpassUrl = 'api/password/reset';
  const data = await authhttp.post(resetpassUrl, values);
  return data;
};

export const sendEmail = async (values) => {
  const emailUrl = 'api/password/email';
  const data = await authhttp.post(emailUrl, values);
  return data;
};

export const getlogOut = async () => {
  try {
    const logOutUrl = 'api/logout';
    const data = await authhttp.post(logOutUrl);
    clearCredentials();
    return data;
  } catch (err) {
    if (
      // eslint-disable-next-line operator-linebreak
      err?.response?.status === 422 &&
      err?.response?.data?.data === 'log_out'
    ) {
      clearCredentials();
      throw err;
    }
    throw err;
  }
};

export const getRecaptchaVerify = async (token) => {
  const values = {
    token
  };
  const url = 'api/google/recaptcha/v3';
  const { data } = await authhttp.post(url, values);
  return data;
};
