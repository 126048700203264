import { validSign } from 'src/utils/validations';
import types from './VanguardMembership.constants';

export const NextStep = () => ({
  type: types.NextStep
});
export const ResetStep = () => ({
  type: types.ResetStep
});
export const StepBack = () => ({
  type: types.StepBack
});
export const setMembershipType = (payload) => ({
  type: types.SetMembershiptype,
  payload
});
export const setMembership = (payload) => ({
  type: types.setMembership,
  payload
});
export const getMembershipPlan = (payload) => ({
  type: types.getMembershipPlan,
  payload
});
export const setTouched = (payload) => ({
  type: types.setUserTouched,
  payload
});
export const fetchMemership = () => ({
  type: types.fetchMembershipPlan
});
export const setMembershipPayment = (payload) => ({
  type: types.setMembershipPayment,
  payload: { id: payload.id, paymentdata: payload.paymentdata }
});
export const setError = (payload) => ({
  type: types.setUserError,
  payload
});
export const setUserData = (payload) => ({
  type: types.setUserInfo,
  payload: { name: payload.name, info: payload.info }
});
export const setMemStartDate = (payload) => ({
  type: types.setMemDate,
  payload
});
export const setInitials = (payload) => ({
  type: types.setMemInitial,
  payload
});
export const setMemAttendance = (payload) => ({
  type: types.setMemAttendance,
  payload
});
export const setBilledEvery = (payload) => ({
  type: types.setBilledEvery,
  payload
});
export const setAttendanceUses = (payload) => ({
  payload,
  type: types.setAttendanceUses
});
export const setUsesType = (payload) => ({
  payload,
  type: types.setUsesType
});
export const changeSignContract = (payload) => ({
  type: types.changeSignContract,
  payload
});
export const setNoSessions = (payload) => ({
  type: types.setNoOfSessions,
  payload
});
export const setUsedSessions = (payload) => ({
  type: types.setUsedSessions,
  payload
});
export const getMemSession = (payload) => ({
  type: types.getMemSession,
  payload
});
export const setAuto = (payload) => ({
  type: types.setAutoRenew,
  payload
});
export const setPytOpt = (payload) => ({
  type: types.setPytOpt,
  payload
});
export const setContractInitials = (payload) => ({
  type: types.setSignInitials,
  payload
});
export const setPolicy = (payload) => ({
  type: types.setPolicy,
  payload
});
export const setRenewal = (payload) => ({
  type: types.setRenewal,
  payload
});
export const setRenewalBilled = (payload) => ({
  type: types.setRenewalBilled,
  payload
});
export const changeSignStatus = (payload) => ({
  type: types.changeSignStatus,
  payload
});
export const addProgram = (payload) => ({ type: types.addProgram, payload });
export const setUser = (payload) => ({ type: types.setUser, payload });
export const setUserContract = (payload) => ({
  type: types.setUserContract,
  payload
});
export const setSessionDate = (payload) => ({
  type: types.setSessionDate,
  payload
});
export const setDiscountType = (payload) => ({
  type: types.setDiscountType,
  payload
});
export const setDiscountAmount = (payload) => ({
  type: types.setDiscountAmount,
  payload
});
export const setSetupFee = (payload) => ({
  type: types.setSetupFee,
  payload
});
export const setMemFee = (payload) => ({
  type: types.setMembershipFee,
  payload
});
export const setDefaultPaymentMet = (payload) => ({
  type: types.setDefaultPaymentMethode,
  payload
});
export const setCardName = (payload) => ({
  type: types.setCardName,
  payload
});
export const setPaymentTab = (payload) => ({
  type: types.setPaymentMeTab,
  payload
});
export const setSelectedUser = (payload) => ({
  type: types.setSelectedUser,
  payload
});
export const setSignature = (payload) => ({
  type: types.setSignature,
  payload
});
export const setPolicyInitials = (payload) => ({
  type: types.setPolicyInitials,
  payload
});
export const setRenewOpt = (payload) => ({
  type: types.setRenewOpt,
  payload
});
export const selectAllPrograms = (payload) => ({
  type: types.selectAllPrograms,
  payload: { programs: payload.programs, checked: payload.checked }
});
export const cleareSignature = () => ({
  type: types.clearSignature
});
export const resetSearch = () => ({
  type: types.resetSearch
});
export const setSessionUses = (payload) => ({
  type: types.setSessionUses,
  payload
});
export const setSessionUsesType = (payload) => ({
  type: types.setSessionUsesType,
  payload
});
export const resetData = () => ({
  type: types.resetData
});
export const setWeekSetup = (payload) => ({
  type: types.setWeekSetup,
  payload
});
export const setWeekFee = (payload) => ({
  type: types.setWeekFee,
  payload
});
export const skipStep = () => ({
  type: types.skipStep
});
export const setRenewalFee = (payload) => ({
  type: types.setRenewalFee,
  payload
});
export const setRenewalWeek = (payload) => ({
  type: types.setRenewalWeek,
  payload
});
export const setErrorMsg = (payload) => ({
  payload,
  type: types.setErrorMsg
});
export const setContract = (payload) => ({
  payload,
  type: types.setContract
});
export const resetUser = () => ({
  type: types.resetUser
});
export const setBankData = (payload) => ({
  payload: { name: payload.name, info: payload.info },
  type: types.setBankData
});

export const SelectWaiverId = (payload) => ({
  type: types.SetSelectedWaiver,
  payload
});

export const setQuestionnaire = (payload) => ({
  type: types.setQuestionnaire,
  payload
});

export const SetActiveStep = (payload) => ({
  type: types.SetActiveStep,
  payload
});

export const toggleSignStatus = () => ({
  type: types.toggleSignStatus
});

export const setWaiverSignInitials = (payload) => ({
  type: types.setWaiverSignInitials,
  payload: validSign(payload)
});

export const setIsSigned = (payload) => ({
  type: types.setIsSigned,
  payload
});

export const setDropInSignature = (payload) => ({
  type: types.setDropInSignature,
  payload
});

export const setSignStatus = (payload) => ({
  type: types.setSignStatus,
  payload
});

export const getDropInPlan = (payload) => ({
  type: types.getDropInPlan,
  payload
});
export const setStartDateDropIn = (payload) => ({
  type: types.setStartDateDropIn,
  payload
});

export const setWaiverInitialBox = (payload) => ({
  type: types.setWaiverInitialBox,
  payload
});
