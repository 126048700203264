/* eslint-disable react/jsx-curly-brace-presence */
import React from 'react';
import PropType from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  withStyles,
  TableSortLabel
} from '@material-ui/core';
// import { getInvoiceStatus } from 'src/views/financial/invoices/Invoice/InvoiceView.utils';
import GetPrice from 'src/views/common/CurrencySymbol/GetPrice';
import filterType from 'src/Redux/Reducer.constants';
import { ROUTES } from 'src/constant/Constants';

const styles = () => ({
  table: {
    minWidth: '100%',
    wordBreak: 'break-word',
    '& td': {
      padding: '10px'
    },
    '@media (max-width: 1366px)': {
      '& td': {
        fontSize: '13px'
      }
    },
    '& .MuiTableCell-head': {
      color: '#FFF'
    },
    '& .MuiTableSortLabel-root:hover': {
      color: '#01847A'
    },
    '& .MuiTableSortLabel-root.MuiTableSortLabel-active': {
      color: '#ffffff'
    },
    '& .MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon': {
      color: '#ffffff'
    },
    '& .MuiTableCell-body': {
      color: '#FFF'
    },
    '& .MuiTableBody-root .MuiTableCell-root': {
      borderBottom: '1px solid rgba(234, 234, 234, 0.2)'
    }
  },
  tableLink: {
    color: '#01847A',
    fontWeight: '700'
  },
  TableCell: {
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px',
    height: '56px',
    minHeight: '56px',
    padding: '10px',
    '@media (max-width: 1366px)': {
      fontSize: '13px',
      wordBreak: 'keep-all'
    }
  },
  Tablemian: {},
  SummaryCell: {
    color: '#292929',
    fontWeight: 'bold'
  }
});

const AllInvoicesTable = (props) => {
  const {
    values,
    classes,
    isActive,
    setDirection,
    setSort,
    summaryData
  } = props;
  const {
    sort_invoice,
    sort_invoice_status,
    sort_member_name,
    sort_payment_due,
    sort_expected_revenue,
    sort_total_discounts,
    sort_refunded_amount,
    sort_subtotal,
    sort_final_tax,
    sort_final_charge
  } = filterType;

  return (
    <TableContainer
      component={Paper}
      elevation={0}
      className={classes.Tablemian}
      style={{ borderRadius: '0', backgroundColor: 'transparent' }}
    >
      <Table className={classes.table} aria-label="simple table">
        <TableHead style={{ background: '#3B3C4E' }}>
          <TableRow>
            <TableCell className={classes.TableCell}>
              <TableSortLabel
                active={isActive(sort_invoice)}
                direction={setDirection(sort_invoice)}
                onClick={() => setSort(sort_invoice)}
              >
                Invoice
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell}>
              <TableSortLabel
                active={isActive(sort_invoice_status)}
                direction={setDirection(sort_invoice_status)}
                onClick={() => setSort(sort_invoice_status)}
              >
                Status
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell}>
              <TableSortLabel
                active={isActive(sort_member_name)}
                direction={setDirection(sort_member_name)}
                onClick={() => setSort(sort_member_name)}
              >
                Member
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell}>
              <TableSortLabel
                active={isActive(sort_payment_due)}
                direction={setDirection(sort_payment_due)}
                onClick={() => setSort(sort_payment_due)}
              >
                Payment Due
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell} align="right">
              <TableSortLabel
                active={isActive(sort_expected_revenue)}
                direction={setDirection(sort_expected_revenue)}
                onClick={() => setSort(sort_expected_revenue)}
                style={{ flexDirection: 'row' }}
              >
                Expected Revenue
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell} align="right">
              <TableSortLabel
                active={isActive(sort_total_discounts)}
                direction={setDirection(sort_total_discounts)}
                onClick={() => setSort(sort_total_discounts)}
                style={{ flexDirection: 'row' }}
              >
                Total Discounts
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell} align="right">
              <TableSortLabel
                active={isActive(sort_refunded_amount)}
                direction={setDirection(sort_refunded_amount)}
                onClick={() => setSort(sort_refunded_amount)}
                style={{ flexDirection: 'row' }}
              >
                Refunded Amount
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell} align="right">
              <TableSortLabel
                active={isActive(sort_subtotal)}
                direction={setDirection(sort_subtotal)}
                onClick={() => setSort(sort_subtotal)}
                style={{ flexDirection: 'row' }}
              >
                Subtotal
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell} align="right">
              <TableSortLabel
                active={isActive(sort_final_tax)}
                direction={setDirection(sort_final_tax)}
                onClick={() => setSort(sort_final_tax)}
                style={{ flexDirection: 'row' }}
              >
                Final Tax
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell} align="right">
              <TableSortLabel
                active={isActive(sort_final_charge)}
                direction={setDirection(sort_final_charge)}
                onClick={() => setSort(sort_final_charge)}
                style={{ flexDirection: 'row' }}
              >
                Final Charge
              </TableSortLabel>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {values.length !== 0 && (
            <TableRow
              style={{
                backgroundColor: '#26273B'
              }}
            >
              <TableCell
                style={{
                  width: '9.0909%'
                }}
                className={classes.SummaryCell}
              >
                Summary
              </TableCell>
              <TableCell
                style={{
                  width: '9.0909%'
                }}
                className={classes.SummaryCell}
              >
                &nbsp;
              </TableCell>
              <TableCell
                style={{
                  width: '9%'
                }}
                className={classes.SummaryCell}
              >
                &nbsp;
              </TableCell>
              <TableCell
                style={{
                  width: '9%'
                }}
                className={classes.SummaryCell}
              >
                &nbsp;
              </TableCell>
              <TableCell
                style={{
                  width: '9%'
                }}
                align="center"
                className={classes.SummaryCell}
              >
                {summaryData.expected_revenue && (
                  <GetPrice value={summaryData.expected_revenue} />
                )}
              </TableCell>
              <TableCell
                style={{
                  width: '9%'
                }}
                align="center"
                className={classes.SummaryCell}
              >
                {<GetPrice value={summaryData.total_discounts} />}
              </TableCell>
              <TableCell
                style={{
                  width: '9%'
                }}
                align="center"
                className={classes.SummaryCell}
              >
                {<GetPrice value={summaryData.refunded_amount} />}
              </TableCell>
              <TableCell
                style={{
                  width: '9%'
                }}
                align="center"
                className={classes.SummaryCell}
              >
                {<GetPrice value={summaryData.subtotal} />}
              </TableCell>
              <TableCell
                style={{
                  width: '9%'
                }}
                align="center"
                className={classes.SummaryCell}
              >
                {<GetPrice value={summaryData.final_tax} />}
              </TableCell>
              <TableCell
                style={{
                  width: '9%'
                }}
                align="center"
                className={classes.SummaryCell}
              >
                {<GetPrice value={summaryData.final_charge} />}
              </TableCell>
            </TableRow>
          )}

          {values.map((row) => (
            <TableRow
              hover
              tabIndex={-1}
              key={row.invoice_no}
              style={{
                backgroundColor: '#26273B'
              }}
            >
              <TableCell
                style={{
                  width: '9.0909%'
                }}
              >
                <Link
                  className={classes.tableLink}
                  to={{
                    pathname:
                      row.invoice_status === 'Paid'
                      || row.invoice_status === 'Refund'
                        ? ROUTES.MASTER_HQ_FINANCIAL_INVOICES_PAIND_VIEW
                        : ROUTES.MASTER_HQ_FINANCIAL_INVOICES_VIEW,
                    search: `?Invoice=${btoa(row.invoice_no)}`,
                    state: row.i_status
                  }}
                >
                  {row.invoice_no}
                </Link>
              </TableCell>
              <TableCell
                style={{
                  width: '9.0909%'
                }}
              >
                {row.invoice_status}
              </TableCell>
              <TableCell
                style={{
                  width: '9%'
                }}
              >
                <Link
                  className={classes.tableLink}
                  to={{
                    pathname: ROUTES.MASTER_HQ_AFFILIATES_PROFILE,
                    search: `affiliateId=${row.affiliate_id}`
                  }}
                >
                  {row.contact_person}
                </Link>
              </TableCell>
              <TableCell
                style={{
                  width: '9%'
                }}
              >
                {row.payment_due}
              </TableCell>
              <TableCell
                style={{
                  width: '9%'
                }}
                align="center"
              >
                <GetPrice value={row.expected_revenue} />
              </TableCell>
              <TableCell
                style={{
                  width: '9%'
                }}
                align="center"
              >
                <GetPrice value={row.total_discounts} />
              </TableCell>
              <TableCell
                style={{
                  width: '9%'
                }}
                align="center"
              >
                <GetPrice value={row.refunded_amount} />
              </TableCell>
              <TableCell
                style={{
                  width: '9%'
                }}
                align="center"
              >
                {<GetPrice value={row.subtotal} />}
              </TableCell>
              <TableCell
                style={{
                  width: '9%'
                }}
                align="center"
              >
                <GetPrice value={row.final_tax} />
              </TableCell>
              <TableCell
                style={{
                  width: '9%'
                }}
                align="center"
              >
                <GetPrice value={row.final_charge} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {values.length === 0 && (
        <p
          style={{
            margin: '10px 15px',
            color: '#fff'
          }}
        >
          No Invoices to show...
        </p>
      )}
    </TableContainer>
  );
};
AllInvoicesTable.propTypes = {
  values: PropType.array.isRequired,
  classes: PropType.object.isRequired,
  isActive: PropType.func.isRequired,
  setDirection: PropType.func.isRequired,
  setSort: PropType.func.isRequired,
  summaryData: PropType.any.isRequired
};

export default withStyles(styles)(AllInvoicesTable);
