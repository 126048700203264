/* eslint-disable operator-linebreak */
import React, {
  useEffect,
  // useEffect,
  useState
} from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Grid,
  Card,
  InputLabel,
  withStyles,
  Box,
  Breadcrumbs,
  Typography
} from '@material-ui/core';
import PropType from 'prop-types';
import queryString from 'query-string';
import CurrencyInputs from 'src/views/common/CurrencyControlForm/CurrencyInputs';

import { getContracts } from 'src/Redux/SettingReducer/SettingReducer.actions';
// import TextareaControl from 'src/views/common/FormControl/TextareaControl';
import { validateFloat, validPrice } from 'src/utils/validations';

import LoadingView from 'src/views/common/loading';
import FormControl from 'src/views/common/FormControl/formControl';
import CustomAutoComplete from 'src/views/common/CustomAutocomplete/commonAutocomplete';
import useChangeTitle from 'src/views/common/CustomHooks/useTitleChange';
// import CheckBoxTable from '../../common/CheckBoxTable/checkBoxTable';
// import RadioButtonTable from '../RadioTable';
// import { RadiotableHead } from '../EditPlanTemplate/staticDataRadio';
// import {
//   editPlanValidate,
//   getUpdateData
// } from '../EditSessionTemplate/editSessionTemp.validation';
import styles from '../EditPlanTemplate/EditPlanTemplate.style';
import JBButton from '../../common/JBButton/JBButton';
// import DeleteDialog from '../DeleteDialog';
import CancelDialog from '../../common/CommonDialogBox/CancelDialog';
// import LoadingView from '../../common/loading';
import NotFoundView from '../../errors/NotFoundView';
import {
  getMemberDetail,
  updateMemTemp
} from '../../../services/Membersip.services';

// import { validateInt } from '../../../utils/validations';
import { getEditTemp } from './editDropinTemplate.utils';

// import {
//   AllExpiration,
//   SessionDay
// } from '../MembershipTemplate/Membership.data';
import { openNoti } from '../../../Redux/global/global.actions';
// import { DummyDataCB } from '../EditPlanTemplate/staticData';

import '../CutomSelect.css';
import { getErrorMsg } from '../../../utils/ErrorHandling';
import { ROUTES } from '../../../constant/Constants';
import CancelDialogTwo from '../../common/CommonDialogBox/CancelDialogTwo';
import DeleteDialog from '../DeleteDialog';

import {
  editPlanValidate,
  getUpdateData
} from './editDropinTemplate.validation';
// import NewContractDialog from '../MembershipTemplate/PlanTemplates/NewContractDialog';

const EditDropinTemplate = (props) => {
  const {
    classes,
    location,
    currencyrates: { data: currencies },

    AllGymloc: { data: gyms, error: gymerror },
    RevenCat: { data: revens, error: revenerror },
    TaxRates: { data: taxes, error: taxerror },
    OpenNoti
    // allprograms
  } = props;
  useChangeTitle('Edit Drop In Template | Jungle Alliance');
  const { dropInId } = queryString.parse(location.search);

  const history = useHistory();
  const [data, setData] = useState(null);

  const [cancelDialog, setcancelDialog] = useState(false);
  const [errorFetch, setErrorFetch] = useState(false);

  useEffect(() => {
    if (gyms && revens && taxes) {
      getMemberDetail(dropInId)
        .then((res) => setData(getEditTemp(res, gyms, revens, taxes)))
        .catch(() => setErrorFetch(true));
    }
  }, [gyms, revens, taxes]); // eslint-disable-line

  if (errorFetch || gymerror || revenerror || taxerror || !dropInId) {
    return <NotFoundView />;
  }

  if (!gyms || !revens || !taxes) return <LoadingView />;

  const handleSave = () => {
    const { value, error } = editPlanValidate(data);

    if (error) {
      OpenNoti(error, 'error');
    } else {
      const values = getUpdateData(value);
      updateMemTemp(values, dropInId)
        .then(() => history.push(ROUTES.CLASSES_DROPIN))
        .then(() => {
          OpenNoti('Successfully updated the Template', 'info');
        })
        .catch((err) => {
          OpenNoti(getErrorMsg(err), 'error');
        });
    }
  };

  return (
    <div className={classes.root}>
      <div className="title-bk">
        <Breadcrumbs
          aria-label="breadcrumb"
          style={{
            margin: '0px 0 21px'
          }}
        >
          <div color="inherit">Classes</div>
          <div
            color="inherit"
            onClick={() => setcancelDialog(true)}
            style={{
              cursor: 'pointer'
            }}
          >
            membership
          </div>
          <Typography variant="h3" color="Primary">
            <span>edit Drop In template</span>
          </Typography>
        </Breadcrumbs>

        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={0}
          style={{ margin: '0 0 20px' }}
        >
          <Grid item xs={6}>
            <h1 style={{ margin: '0 0' }}>Edit Drop In Template</h1>
          </Grid>
          <Grid item xs={6} className={classes.btnBlock}>
            {data && <DeleteDialog id={dropInId} name="drop_in" />}
          </Grid>
        </Grid>
      </div>
      {!data ? (
        <LoadingView />
      ) : (
        <Box>
          <Card className={classes.mainCard}>
            <Grid xs={12}>
              <div>
                <h3>Name this Drop In, set the revenue category.</h3>
                <Grid container spacing={0} direction="column">
                  <Grid item xs={12}>
                    <FormControl
                      control="input"
                      label="Drop In name"
                      placeholder="e.g. 1 day drop in pack"
                      required
                      value={data.name}
                      onChange={(e) => {
                        setData({ ...data, name: e.target.value });
                      }}
                    />
                  </Grid>
                  <Grid
                    container
                    spacing={0}
                    xs={12}
                    disableGutters
                    direction="row"
                    style={{ marginbottom: '20px' }}
                  >
                    <Grid
                      container
                      spacing={0}
                      xs={12}
                      disableGutters
                      direction="row"
                    >
                      <Grid
                        item
                        xs={12}
                        className={classes.time_box}
                        style={{ marginTop: '0px' }}
                      >
                        <InputLabel
                          htmlFor="age-required"
                          className={classes.cutom_select_Label}
                          required
                        >
                          Revenue category
                        </InputLabel>
                        <CustomAutoComplete
                          holder="Select Revenue Category"
                          data={data.reve_cat_id}
                          value={revens}
                          Options="label"
                          style={{ marginbottom: '0' }}
                          Change={(val) => {
                            setData({ ...data, reve_cat_id: val });
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
              {/* <Box mt={5} mb={1}>
                <h3 style={{ marginBottom: '20px' }}>
                  Select the programs included with this membership
                </h3>

                <Grid
                  xs={12}
                  style={{
                    marginTop: '0',
                    display: 'inline-flex',
                    width: '100%',
                    marginBottom: '30px'
                  }}
                >
                  <Grid
                    item
                    xs
                    style={{ marginTop: '0px' }}
                    className={classes.checkBoxTable}
                  >
                    <CheckBoxTable
                      values={allprograms}
                      headNames={DummyDataCB}
                      initialSelected={data.program_ids}
                      getSelected={(val) =>
                        setData({ ...data, program_ids: val })
                      }
                    />
                  </Grid>
                </Grid>
              </Box> */}
              <Box mt={5} mb={1}>
                <h3 style={{ marginBottom: '20px' }}>
                  Create payment Details for Drop In
                </h3>

                <Grid
                  container
                  spacing={0}
                  xs={12}
                  disableGutters
                  direction="row"
                >
                  <Grid item xs={12} style={{ marginTop: '0px' }}>
                    <Grid
                      item
                      xs={3}
                      className={classes.time_box}
                      style={{ marginTop: '0px' }}
                    >
                      <CustomAutoComplete
                        holder="Select Service Tax"
                        value={taxes || []}
                        data={taxes.find(
                          (row) => row.name === data.meta[0]?.tax.name
                        )}
                        Options="name"
                        style={{ marginbottom: '0' }}
                        Change={(val) => {
                          if (!val) {
                            OpenNoti('Please specify the service tax', 'error');
                          }
                          setData({
                            ...data,
                            meta: [
                              {
                                ...data.meta[0],
                                tax: val
                              }
                            ]
                          });
                        }}
                      />
                    </Grid>
                    <Grid
                      container
                      spacing={0}
                      xs={12}
                      disableGutters
                      direction="row"
                      style={{
                        margin: '15px 0'
                      }}
                    >
                      <Grid
                        item
                        xs={2}
                        style={{
                          marginTop: '0px',
                          paddingRight: '15px',
                          display: 'flex'
                        }}
                      >
                        <h4 style={{ alignSelf: 'center' }}>
                          When paid in Full
                        </h4>
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        className={classes.time_box}
                        style={{ marginTop: '0px', paddingRight: '15px' }}
                      >
                        <InputLabel
                          htmlFor="age-required"
                          className={classes.cutom_select_Label}
                          style={{ padding: '0px 0 10px' }}
                        >
                          One-time setup fee
                        </InputLabel>
                        <CurrencyInputs
                          placeholder="1"
                          currencySymbol={currencies.currency_symbol}
                          value={
                            data.meta[0]?.initial_commitment_pricing
                              .full_setup_fees
                          }
                          style={{
                            margin: '0 0'
                          }}
                          onChange={(e) => {
                            const value = validPrice(
                              validateFloat(e.target.value)
                            );
                            setData({
                              ...data,
                              meta: [
                                {
                                  ...data.meta[0],
                                  initial_commitment_pricing: {
                                    ...data.meta[0].initial_commitment_pricing,
                                    full_setup_fees: value
                                  }
                                }
                              ]
                            });
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        className={classes.time_box}
                        style={{ marginTop: '0px', paddingRight: '15px' }}
                      >
                        <InputLabel
                          htmlFor="age-required"
                          className={classes.cutom_select_Label}
                          style={{ padding: '0px 0 10px' }}
                          required
                        >
                          Full Drop In fee
                        </InputLabel>
                        <CurrencyInputs
                          currencySymbol={currencies.currency_symbol}
                          value={
                            data.meta[0]?.initial_commitment_pricing
                              .full_membership_fees
                          }
                          placeholder="1"
                          style={{
                            margin: '0 0'
                          }}
                          onChange={(e) => {
                            const value = validPrice(
                              validateFloat(e.target.value)
                            );
                            setData({
                              ...data,
                              meta: [
                                {
                                  ...data.meta[0],
                                  initial_commitment_pricing: {
                                    ...data.meta[0].initial_commitment_pricing,
                                    full_membership_fees: value
                                  }
                                }
                              ]
                            });
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        className={classes.time_box}
                        style={{
                          marginTop: '20px',
                          display: 'flex',
                          flexDirection: 'column',
                          alignSelf: 'center'
                        }}
                      >
                        <h3 style={{ marginBottom: '0px' }}>
                          {currencies.currency_symbol +
                            ' ' +
                            parseFloat(
                              parseFloat(
                                data.meta[0].initial_commitment_pricing
                                  .full_setup_fees
                              ) +
                                parseFloat(
                                  data.meta[0].initial_commitment_pricing
                                    .full_membership_fees
                                )
                            ).toLocaleString(undefined, {
                              minimumFractionDigits: currencies.decimals
                            })}
                        </h3>
                        <InputLabel
                          htmlFor="age-required"
                          className={classes.cutom_select_Label}
                        >
                          Total cost (including tax)
                        </InputLabel>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              style={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              <CancelDialog handleHistory={ROUTES.CLASSES_DROPIN} />
              <JBButton
                type="primary"
                style={{ marginLeft: '15px' }}
                onClick={handleSave}
              >
                Save
              </JBButton>
            </Grid>
          </Card>
          <CancelDialogTwo
            setOpen={cancelDialog}
            handleClose={() => setcancelDialog(false)}
          />
        </Box>
      )}
    </div>
  );
};

EditDropinTemplate.propTypes = {
  classes: PropType.object.isRequired,
  AllGymloc: PropType.object.isRequired,
  RevenCat: PropType.object.isRequired,
  TaxRates: PropType.object.isRequired,
  // allprograms: PropType.array.isRequired,
  // contracts: PropType.object.isRequired,
  OpenNoti: PropType.func.isRequired,
  location: PropType.object.isRequired,
  currencyrates: PropType.object.isRequired
};

const mapToState = (state) => ({
  AllGymloc: state.SettingReducer.allGymloc,
  RevenCat: state.SettingReducer.revenueCtgry,
  TaxRates: state.SettingReducer.taxRates,
  allprograms: state.allprograms.programs,
  contracts: state.SettingReducer.pubContracts,
  currencyrates: state.SettingReducer.currency
});
const mapToDispatch = (dispatch) => ({
  OpenNoti: (msg, type) => dispatch(openNoti(msg, type)),
  getContractsUpdate: () => dispatch(getContracts())
});
export default connect(
  mapToState,
  mapToDispatch
)(withStyles(styles)(EditDropinTemplate));
