export default {
  getTaxRates: 'RECIEVING_SERVICE_TAX',
  getDateformat: 'RECIEVE_DATE_FORMAT',
  getRevCtgry: 'GET_REVENUE_CATEGORY',
  updateRevCrgry: 'UPDATE_REVENUE_CATEGORY',
  getCurrencyRates: 'GET_CURRENCY_RATES',
  getinvoicefooter: 'GET_INVOICE_FOOTER',
  getInvoiceSetting: 'GET_INVOICE_SETTING',
  getGymloc: 'GET_GYM_LOCATIONS',
  getAllCountries: 'GET_ALL_COUNTRIES',
  getContracts: 'GET_CONTRACTS',
  fetchSucess: 'FETCH_SUCCESSFULL',
  Errorfetch: 'ERROR_IN_FETCHING',
  isFetching: 'FETCHING_DATA',
  getPaymentPlans: 'GET_PAYMENT_PLANS',
  getRoles: 'GET_ROLES'
};
