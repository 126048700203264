const styles = (theme) => ({
  root: {
    marginTop: 0,
    margin: '0px 20px 20px',
    padding: '0px 20px 20px'
  },
  mainCard: {
    boxShadow: '4px 0px 16px rgba(37, 40, 43, 0.08)',
    borderRadius: '5px',
    background: '#FFFF',
    padding: 30,
    overflow: 'visible'
  },
  searchInput: {
    padding: '6px 20px 6px !important;',
    paddingRight: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    height: '35px',
    border: '1px solid #a9a9a9'
  },
  cutom_select_border: {
    border: '1px solid #a9a9a9'
  },
  cutom_select_Label: {
    fontSize: '12px',
    padding: '7px 0',
    '&:focus ': {
      outline: '0'
    }
  },
  btnBlock: {
    justifyContent: 'flex-end',
    display: 'flex'
  },
  ptext: {
    fontSize: '16px',
    lineHeight: '24px',
    color: '#7F7F7F',
    fontWeight: 'normal'
  },
  hr: {
    border: '1px solid rgb(196 196 196 / 0.4)',
    display: 'inline-block',
    width: '100%',
    margin: '20px 0'
  },
  // Table styles
  table: {
    fontSize: '14px',
    lineHeight: '22px'
  },
  table_th: {
    backgroundColor: '#F9F9FA',
    color: '#292929'
  },
  Table_td_link: {
    color: '#01847A',
    fontWeight: '700'
  },
  TableCell: {
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px',
    borderBottom: '1px solid #A8A8A8',
    height: '56px',
    minHeight: '56px'
  },
  Table_td: {
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '22px',
    position: 'relative',
    padding: '12px 15px 13px 15px',
    height: '56px',
    minHeight: '56px'
  },
  TbActionBtn: {
    textTransform: 'capitalize',
    color: '#7F7F7F',
    fontSize: '12px'
  },
  actionbtn: {
    padding: '6px 5px',
    minWidth: 'auto',
    margin: '0 5px',
    textAlign: 'center',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  // end table
  // MembershipContract
  useravatar: {
    verticalAlign: 'sub',
    width: '40px',
    height: '40px',
    marginRight: '15px',
    borderRadius: '50%',
    objectFit: 'cover'
  },
  PlanTerms: {
    border: '1px solid #A8A8A8',
    borderRadius: '4px',
    marginBottom: '10px',
    '& tr': {
      '& td': {
        fontSize: '14px',
        color: '#292929',
        lineHeight: '22px',
        fontWeight: '400',
        '&:nth-of-type(odd)': {
          color: '#292929',
          minWidth: '250px',
          fontWeight: '700',
          width: 'calc(100% - 70%)'
        }
      },
      '&:last-child td': {
        borderBottom: '0'
      }
    }
  },
  Pinfo: {
    '& tr': {
      '& td': {
        fontSize: '14px',
        color: '#292929',
        lineHeight: '22px',
        fontWeight: '400',
        '&:nth-of-type(odd)': {
          color: '#292929',
          minWidth: 'auto',
          fontWeight: '400',
          width: 'auto'
        }
      },
      '&:last-child td': {
        borderBottom: '0'
      }
    }
  },
  headingTitle: {
    color: '#292929',
    fontWeight: '700',
    fontSize: '20px'
  },
  tbhead: {
    '& th': {
      color: '#292929',
      fontWeight: '700',
      background: '#F9F9FA'
    }
  },
  termsagree: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100px',
    background: '#F9F9FA',
    borderRadius: '8px',
    padding: '0px',
    '& .fd-input input': {
      marginTop: '0px',
      color: '#949494',
      borderRadius: '2px',
      padding: '13.5px 15px',
      height: 'auto',
      border: '1px solid #EAEAEA'
    },
    '& .MuiInput-underline:before': {
      borderBottomStyle: 'none !important'
    }
  },
  mAgreement: {
    '& p': {
      fontSize: '14px',
      lineHeight: '22px',
      color: '#7F7F7F',
      fontWeight: 'normal'
    },
    '& ul': {
      listStyleType: 'none'
    },
    '& li': {
      position: 'relative',
      marginLeft: '15px',
      padding: '10px 0'
    }
  },
  onelineP: {
    margin: '15px 0'
  },
  listpoint: {
    width: '5px',
    height: '5px',
    background: '#7F7F7F',
    borderRadius: '50%',
    position: 'absolute',
    left: '-11px',
    marginTop: '9px'
  },
  MasubTitle: {
    color: '#000',
    marginBottom: '25px'
  },
  MasubTitleH6: {
    fontSize: '14px',
    margin: '12px 0',
    fontWeight: '700',
    color: '#292929'
  },
  SignLabel: {
    '& span': {
      color: '#949494'
    }
  },
  tbDates: {
    fontSize: '14px',
    fontWeight: '700',
    color: '#949494',
    textAlign: 'right',
    '& span': {
      color: '#000',
      margin: '0px 15px',
      '@media (max-width: 1440px)': {
        margin: '0px 13px'
      },
      '&:nth-of-type(2) ': {
        marginRight: '0'
      }
    }
  },
  tbsignatures: {
    width: '100%',
    height: '230px',
    background: '#F9F9FA',
    borderRadius: '8px',
    margin: '15px 0',
    textAlign: 'center',
    display: 'inline-block',
    '& img': {
      display: 'block',
      objectFit: 'cover',
      height: '180px',
      verticalAlign: 'middle',
      margin: '25px auto'
    }
  },
  // end MembershipContract

  // contract edit and view
  contractCard: {},
  contractInput: {
    background: 'red'
  },
  contrP: {
    fontSize: '14px',
    lineHeight: '18px',
    color: '#7F7F7F',
    fontWeight: 'normal'
  },
  TermsInfo: {
    fontSize: '14px',
    lineHeight: '22px',
    color: '#000',
    fontWeight: '700'
  },
  PlanTermsBox: {
    marginTop: '30px'
  },
  mpP: {
    paddingRight: '55px',
    marginTop: '5px'
  },
  cutom_Switch_Label: {
    display: 'inline-block',
    marginRight: '10px',
    verticalAlign: 'middle',
    fontSize: '14px',
    fontWeight: '700',
    color: '#000'
  },
  Initialbox: {
    marginTop: '25px',
    display: 'flex',
    alignItems: 'center',
    '& .MuiSwitch-root': {
      margin: '0 8px 0 0'
    }
  },

  // End contract edit and view
  contractAlert: {
    borderRadius: '2px',
    border: '1px solid #FF7723',
    background: 'rgb(255 119 35 / 0.04)'
  },
  contractAlertTitle: {
    color: '#CE5B15',
    fontWeight: 'bold',
    fontSize: '14px'
  },
  contractDisabled: {
    display: 'block',
    color: '#000',
    marginTop: '130px',
    marginBottom: '-25px',
    '& .MuiAlert-message': {
      width: '100%',
      padding: '15px 0',
      height: '94px',
      display: 'flex',
      alignItems: 'center'
    },
    '& p': {
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '22px',
      color: '#CE5B15'
    }
  },
  contractAlertbtn: {
    border: '1px solid #FF7723',
    height: '48px',
    color: '#FF7723',
    fontWeight: '700'
  },
  title: {
    fontWeight: 'bold',
    fontSize: '32px',
    lineHeight: '42px',
    color: '#292929'
  },
  bodytxt: {
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '0.002em',
    color: '#7F7F7F',
    padding: '10px 0'
  },
  Radiobtn: {
    padding: '8px 9px'
  },
  DTags: {
    marginTop: '20px',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px',
    color: '#292929',
    '& label': {
      background: '#EAEAEA',
      borderRadius: '10px',
      padding: '8px 16px',
      display: 'block'
    }
  },
  DTagsClose: {
    color: '#7F7F7F',
    marginLeft: '5px',
    cursor: 'pointer',
    border: '1px solid #7F7F7F',
    borderRadius: '50%',
    fontSize: '14px',
    padding: '0px 4.5px',
    width: '20px',
    height: '20px',
    lineHeight: 'normal',
    fontWeight: 'bolder',
    maxWidth: '20px',
    maxHeight: '20px',
    display: 'inline-block'
  },
  TextareaControlBox: {
    '& textarea': {
      color: '#292929'
    },
    '& textarea::placeholder': {
      color: '#7F7F7F !important',
      fontWeight: '500',
      fontSize: '16px',
      opacity: '0.5'
    }
  },
  memberLink: {
    color: '#01847a',
    display: 'block',
    padding: '9px 0 9px',
    fontWeight: '700'
  },
  Avataruname: {
    fontWeight: 'bold',
    backgroundColor: '#CCE6E4',
    color: '#37847a'
  }
});

export default styles;
