/* eslint-disable operator-linebreak */
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import {
  Grid,
  InputLabel,
  withStyles,
  Box,
  FormControlLabel,
  Checkbox,
  Radio,
  RadioGroup
} from '@material-ui/core';
import PropType from 'prop-types';
import SignaturePad from 'signature_pad';
import { openNoti } from 'src/Redux/global/global.actions';
import FormControl from 'src/views/common/FormControl/formControl';
import JBDatePicker from 'src/views/common/FormControl/JBDatePicker';
import TextareaControl from 'src/views/common/FormControl/TextareaControl';
import { getCountries } from 'src/services/Members.module';
import NotFoundView from 'src/views/errors/NotFoundView';
import LoadingView from 'src/views/common/loading';
import JBButton from 'src/views/common/JBButton/JBButton';
import {
  getMemWaiver,
  updateUserWaiver
} from 'src/services/Contracts.services';
import {
  checkValidString,
  checkEmail,
  validSign,
  validMobile,
  isValidMobile,
  isValidPostal,
  isPhoneNumberInvalid
} from 'src/utils/validations';
import { getErrorMsg } from 'src/utils/ErrorHandling';
import moment from 'moment';
import { ReactComponent as BasicInformation } from '../../../../assets/image/JBSteppericon/BasicInformation.svg';
import { ReactComponent as BookOpenIcon } from '../../../../assets/image/JBSteppericon/bookopen.svg';
import { ReactComponent as Questionmark } from '../../../../assets/image/JBSteppericon/questionmark.svg';
import { ReactComponent as QuestionmarkFull } from '../../../../assets/image/JBSteppericon/questionmarkGrey.svg';
import { ReactComponent as EdiIcon } from '../../../../assets/image/JBSteppericon/editpen.svg';
import ColorlibStepIcon from '../../../common/JBGridStepper/ColorlibStepIcon';
import JBStepper from '../../../common/JBGridStepper';
import styles from './SignContract.style';
import { AllGender } from './SignWaivers.utils';
import CustomAutocomplete from '../../../common/CustomAutocomplete/commonAutocomplete';
import {
  getMissWav,
  getSignedWav
} from '../../../../Redux/WaiverReducer/WaiverReducer.actions';

const SignContractStepper = ({
  classes,
  id,
  openNotify,
  WaivFilter,
  SignFilter
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [countryOpts, setCountryOpts] = useState([]);
  const [data, setData] = useState(null);
  function getSteps() {
    return ['Personal Information', 'Terms', 'Questionnaire', 'Sign'];
  }

  const steps = getSteps();
  const [check, setcheck] = useState(false);
  const [errorFetch, setErrFetch] = useState(false);
  const [selected, setSelected] = useState([]);
  const [sign, setSign] = useState(null);
  const [signed, setSigned] = useState(false);
  const canRef = useRef(null);
  // const [, setInitial] = useState(0);
  const handleClick = (name, index) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
    const newArray = [...data.questionnaire_meta];

    newArray[index] = {
      ...newArray[index],
      ans: newSelected
    };
    setData({
      ...data,
      questionnaire_meta: newArray
    });
  };
  const formData = (res, countries) => {
    let result;

    if (Array.isArray(res.questionnaire_meta)) {
      const newArray = [...res.questionnaire_meta];
      for (let i = 0; i < res.questionnaire_meta.length; i += 1) {
        newArray[i] = { ...newArray[i], ans: [], followup_ans: [] };
      }

      result = {
        ...res,
        user_details: {
          ...res.user_details,
          gender:
            AllGender.filter((lis) => lis.id === res.user_details.gender)[0] ||
            null,
          country:
            countries.filter(
              (list) => list.code === res.user_details.country
            )[0] || null
          // dob: moment(res.user_details.dob).toDate()
        },
        waiver_terms_initial: {
          sign_initials: null
        },
        questionnaire_meta: newArray
      };
    } else {
      result = {
        ...res,
        waiver_terms_initial: {
          sign_initials: null
        },
        user_details: {
          ...res.user_details,
          gender:
            AllGender.filter((lis) => lis.id === res.user_details.gender)[0] ||
            null,
          country:
            countries.filter(
              (list) => list.code === res.user_details.country
            )[0] || null
        },
        questionnaire_meta: []
      };
    }
    return result;
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;
  useEffect(() => {
    Promise.all([getCountries(), getMemWaiver(id)])
      .then((res) => {
        // if (res[1].waiver_terms_meta.initial) setInitial(1);
        setCountryOpts(res[0]);
        setData(formData(res[1], res[0]));
      })
      .catch(() => setErrFetch(true));
  }, [id]);

  useEffect(() => {
    if (canRef.current) {
      const canvas = new SignaturePad(canRef.current, {
        minWidth: 0.7,
        maxWidth: 1.5,
        onBegin() {
          setSigned(true);
        },
        onEnd() {}
      });
      setSign(canvas);
    }
    if (activeStep === 3 && signed) {
      setSigned(false);
    }
  }, [activeStep, data]); // eslint-disable-line

  const validateQues = (validateArray) => {
    for (let i = 0; i < validateArray.length; i += 1) {
      if (validateArray[i].required && validateArray[i].ans.length === 0) {
        return false;
      }
    }
    return true;
  };
  const createValidateWaiver = (validateData) => {
    let result = [];
    result = {
      first_name: validateData.user_details.first_name,
      last_name: validateData.user_details.last_name,
      email: validateData.user_details.email,
      gender: validateData.user_details.gender.id,
      address: validateData.user_details.address,
      city: validateData.user_details.city,
      province: validateData.user_details.province,
      country: validateData.user_details.country.code,
      postal_code: validateData.user_details.postal_code,
      mobile_no: validateData.user_details.mobile_no,
      dob: moment(validateData.user_details.dob).format('YYYY-MM-DD'),
      eme_contact_name: validateData.user_details.eme_contact_name,
      eme_contact_phone: validateData.user_details.eme_contact_phone,
      waiver_terms_initial: JSON.stringify(validateData.waiver_terms_initial),
      questionnaire_meta: JSON.stringify(validateData.questionnaire_meta),
      signature: sign.toDataURL()
    };
    return result;
  };
  const handleCreateWaiver = () => {
    const validateData = createValidateWaiver(data);
    updateUserWaiver(data.id, validateData)
      .then(() => {
        WaivFilter();
        SignFilter();
        openNotify('Successfully Updated');
      })
      .catch((err) => openNotify(getErrorMsg(err), 'error'));
  };
  const handleNext = () => {
    if (!data.user_details.first_name) {
      openNotify('First Name is Requried', 'error');
    }
    if (!data.user_details.last_name) {
      openNotify('Last Name is Requried', 'error');
    } else if (checkEmail(data.user_details.email)) {
      openNotify('Please provide valid email ', 'error');
    } else if (!data.user_details.gender) {
      openNotify('Please provide gender', 'error');
    } else if (!data.user_details.address) {
      openNotify('Please select address', 'error');
    } else if (checkValidString(data.user_details.address)) {
      openNotify('Please enter valid address', 'error');
    } else if (!data.user_details.city) {
      openNotify('Please enter valid city', 'error');
    } else if (checkValidString(data.user_details.city)) {
      openNotify('Please enter valid city', 'error');
    } else if (!data.user_details.province) {
      openNotify('Province field is empty', 'error');
    } else if (checkValidString(data.user_details.province)) {
      openNotify('Please enter valid province', 'error');
    } else if (!isValidPostal(data.user_details?.postal_code)) {
      openNotify('Please enter postal code', 'error');
    } else if (!data.user_details.country) {
      openNotify('Please enter valid country', 'error');
    } else if (checkValidString(data.user_details.city)) {
      openNotify('Please enter valid city', 'error');
    } else if (
      !data.user_details.mobile_no ||
      !isValidMobile(data.user_details.mobile_no)
    ) {
      openNotify('Please enter a phone number', 'error');
    } else if (isPhoneNumberInvalid(data.user_details.mobile_no)) {
      openNotify('Phone must be between 10 and 15 digits', 'error');
    } else if (!data.user_details.eme_contact_name) {
      openNotify('Please enter emergency  contact name', 'error');
    } else if (checkValidString(data.user_details.eme_contact_name)) {
      openNotify('Please specify valid emergency name', 'error');
    } else if (
      !data.user_details.eme_contact_phone ||
      !isValidMobile(data.user_details.eme_contact_phone)
    ) {
      openNotify('Please enter emergency contact phone number', 'error');
    } else if (isPhoneNumberInvalid(data.user_details.eme_contact_phone)) {
      openNotify('Emergency Phone must be between 10 and 15 digits', 'error');
    } else if (data?.user_details?.dob > Date.now()) {
      openNotify('Date of birth must be a date before Today', 'error');
    } else if (!data.user_details.dob) {
      openNotify('Please enter a Date of Birth', 'error');
    } else if (activeStep === 1) {
      if (
        data.waiver_terms_meta.initial &&
        !data.waiver_terms_initial.sign_initials
      ) {
        openNotify('Initial required', 'error');
      } else if (!check) {
        openNotify('Agree with terms and conditions!!', 'error');
      } else if (data?.questionnaire_meta.length === 0) {
        setActiveStep(3);
      } else {
        setActiveStep((prev) => prev + 1);
      }
    } else if (activeStep === 2 && !validateQues(data.questionnaire_meta)) {
      openNotify('Please provide valid questionnaire details', 'error');
    } else if (activeStep === 3) {
      if (!signed || data.sign_status === 0) {
        openNotify('Please provide signatures', 'error');
      } else {
        handleCreateWaiver();
      }
    } else {
      setActiveStep((prev) => prev + 1);
    }
  };

  const handleBack = () => {
    if (data?.questionnaire_meta.length === 0 && activeStep === 3) {
      setActiveStep(1);
    } else {
      setActiveStep((prev) => prev - 1);
    }
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  if (errorFetch) return <NotFoundView />;
  if (!data) return <LoadingView />;

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <div>
            <Grid container spacing={2} style={{ alignItems: 'center' }}>
              <Grid item xs={4}>
                <InputLabel
                  htmlFor="age-required"
                  required
                  className={classes.cutom_select_Label}
                  style={{ padding: '5px 0' }}
                >
                  First name
                </InputLabel>
                <FormControl
                  control="input"
                  label=""
                  value={data.user_details.first_name}
                  onChange={(e) =>
                    setData({
                      ...data,
                      user_details: {
                        ...data.user_details,
                        first_name: e.target.value
                      }
                    })
                  }
                  placeholder=""
                  required
                  style={{ margin: '0 0' }}
                />
              </Grid>
              <Grid item xs={4}>
                <InputLabel
                  htmlFor="age-required"
                  required
                  className={classes.cutom_select_Label}
                  style={{ padding: '5px 0' }}
                >
                  Last name
                </InputLabel>
                <FormControl
                  control="input"
                  label=""
                  placeholder=""
                  required
                  value={data.user_details.last_name}
                  onChange={(e) =>
                    setData({
                      ...data,
                      user_details: {
                        ...data.user_details,
                        last_name: e.target.value
                      }
                    })
                  }
                  style={{ margin: '0 0' }}
                />
              </Grid>
              <Grid item xs={4}>
                <InputLabel
                  htmlFor="age-required"
                  required
                  className={classes.cutom_select_Label}
                  style={{ padding: '5px 0' }}
                >
                  Email
                </InputLabel>
                <FormControl
                  control="input"
                  label=""
                  placeholder=""
                  required
                  value={data.user_details.email}
                  onChange={(e) =>
                    setData({
                      ...data,
                      user_details: {
                        ...data.user_details,
                        email: e.target.value
                      }
                    })
                  }
                  style={{ margin: '0 0' }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ alignItems: 'center' }}>
              <Grid item xs={4}>
                <InputLabel
                  htmlFor="age-required"
                  required
                  className={classes.cutom_select_Label}
                  style={{ padding: '5px 0' }}
                >
                  Gender
                </InputLabel>

                <CustomAutocomplete
                  Options="name"
                  data={data.user_details.gender}
                  value={AllGender}
                  holder="Please select your gender"
                  Change={(val) =>
                    setData({
                      ...data,
                      user_details: {
                        ...data.user_details,
                        gender: val
                      }
                    })
                  }
                  style={{ marginbottom: '0' }}
                />
              </Grid>
              <Grid item xs={4}>
                <InputLabel
                  htmlFor="age-required"
                  required
                  className={classes.cutom_select_Label}
                  style={{ padding: '5px 0' }}
                >
                  Street address
                </InputLabel>
                <FormControl
                  control="input"
                  label=""
                  placeholder=""
                  value={data.user_details.address}
                  onChange={(e) =>
                    setData({
                      ...data,
                      user_details: {
                        ...data.user_details,
                        address: e.target.value
                      }
                    })
                  }
                  required
                  style={{ margin: '0 0' }}
                />
              </Grid>
              <Grid item xs={4}>
                <InputLabel
                  htmlFor="age-required"
                  required
                  className={classes.cutom_select_Label}
                  style={{ padding: '5px 0' }}
                >
                  City
                </InputLabel>
                <FormControl
                  control="input"
                  label=""
                  placeholder=""
                  required
                  value={data.user_details.city}
                  onChange={(e) =>
                    setData({
                      ...data,
                      user_details: {
                        ...data.user_details,
                        city: e.target.value
                      }
                    })
                  }
                  style={{ margin: '0 0' }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ alignItems: 'center' }}>
              <Grid item xs={4}>
                <InputLabel
                  htmlFor="age-required"
                  required
                  className={classes.cutom_select_Label}
                  style={{ padding: '5px 0' }}
                >
                  Province/Region
                </InputLabel>
                <FormControl
                  value={data.user_details.province}
                  onChange={(e) =>
                    setData({
                      ...data,
                      user_details: {
                        ...data.user_details,
                        province: e.target.value
                      }
                    })
                  }
                  control="input"
                  label=""
                  placeholder=""
                  required
                  style={{ margin: '0 0' }}
                />
              </Grid>
              <Grid item xs={4}>
                <InputLabel
                  htmlFor="age-required"
                  required
                  className={classes.cutom_select_Label}
                  style={{ padding: '5px 0' }}
                >
                  Postal code
                </InputLabel>
                <FormControl
                  control="input"
                  label=""
                  value={data.user_details.postal_code}
                  onChange={(e) =>
                    setData({
                      ...data,
                      user_details: {
                        ...data.user_details,
                        postal_code: e.target.value
                      }
                    })
                  }
                  placeholder=""
                  required
                  style={{ margin: '0 0' }}
                  maxLength={10}
                />
              </Grid>
              <Grid item xs={4}>
                <InputLabel
                  htmlFor="age-required"
                  required
                  className={classes.cutom_select_Label}
                  style={{ padding: '5px 0' }}
                >
                  Country
                </InputLabel>
                <CustomAutocomplete
                  value={countryOpts}
                  data={data.user_details.country}
                  Options="name"
                  holder="Please Select Country"
                  style={{ margin: '0 0' }}
                  Change={(e) => {
                    setData({
                      ...data,
                      user_details: {
                        ...data.user_details,
                        country: e
                      }
                    });
                  }}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2} style={{ alignItems: 'center' }}>
              <Grid item xs={4}>
                <InputLabel
                  htmlFor="age-required"
                  required
                  className={classes.cutom_select_Label}
                  style={{ padding: '5px 0' }}
                >
                  Phone
                </InputLabel>
                <FormControl
                  control="input"
                  label=""
                  value={data.user_details.mobile_no}
                  onChange={(e) =>
                    setData({
                      ...data,
                      user_details: {
                        ...data.user_details,
                        mobile_no: validMobile(e.target.value)
                      }
                    })
                  }
                  placeholder=""
                  required
                  style={{ margin: '0 0' }}
                  maxLength={23}
                />
              </Grid>
              <Grid item xs={4}>
                <InputLabel
                  htmlFor="age-required"
                  required
                  className={classes.cutom_select_Label}
                  style={{ padding: '5px 0' }}
                >
                  Emergency contact name
                </InputLabel>
                <FormControl
                  control="input"
                  label=""
                  value={data.user_details.eme_contact_name}
                  onChange={(e) =>
                    setData({
                      ...data,
                      user_details: {
                        ...data.user_details,
                        eme_contact_name: e.target.value
                      }
                    })
                  }
                  placeholder=""
                  required
                  style={{ margin: '0 0' }}
                />
              </Grid>
              <Grid item xs={4}>
                <InputLabel
                  htmlFor="age-required"
                  required
                  className={classes.cutom_select_Label}
                  style={{ padding: '5px 0' }}
                >
                  Emergency contact phone number
                </InputLabel>
                <FormControl
                  control="input"
                  label=""
                  value={data.user_details.eme_contact_phone}
                  onChange={(e) =>
                    setData({
                      ...data,
                      user_details: {
                        ...data.user_details,
                        eme_contact_phone: validMobile(e.target.value)
                      }
                    })
                  }
                  placeholder=""
                  required
                  style={{ margin: '0 0' }}
                  maxLength={23}
                />
              </Grid>
              <Grid item xs={5}>
                <JBDatePicker
                  label="Date of birth"
                  required
                  value={data.user_details.dob}
                  maxDate={new Date()}
                  onChange={(e) =>
                    setData({
                      ...data,
                      user_details: {
                        ...data.user_details,
                        dob: e
                      }
                    })
                  }
                />
              </Grid>
            </Grid>
          </div>
        );
      case 1:
        return (
          <Box>
            <Box className={classes.scrollBox}>
              <Grid
                container
                spacing={0}
                style={{ alignItems: 'center', padding: '0px 0 0' }}
              >
                <Grid item xs={12}>
                  <p className={classes.ptext} style={{ marginBottom: '15px' }}>
                    You must initial 1 section(s) in this policy.
                  </p>
                </Grid>
                <div
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: data.waiver_terms_meta.terms
                  }}
                  className="cke_contents_ltr cke_editable cke_editable_themed cke_show_borders"
                />
              </Grid>
            </Box>
            {data.waiver_terms_meta.initial ? (
              <Box className={classes.termsagree}>
                <Grid alignItems="center" container direction="row">
                  <Grid item style={{ marginRight: '15px' }}>
                    <h5 style={{ fontSize: '14px' }}>Initial here</h5>
                  </Grid>
                  <Grid item xs={2}>
                    <FormControl
                      control="input"
                      label=""
                      value={data.waiver_terms_initial.sign_initials}
                      maxLength={3}
                      onChange={(e) => {
                        setData({
                          ...data,
                          waiver_terms_initial: {
                            sign_initials: validSign(e.target.value)
                          }
                        });
                      }}
                      placeholder=""
                      required
                      style={{ margin: '0 0' }}
                    />
                  </Grid>
                </Grid>
              </Box>
            ) : null}

            <Box mt="10px">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={check}
                    onChange={() => setcheck(!check)}
                    name="checkedB"
                    color="primary"
                  />
                }
                label="I agree to these terms."
              />
            </Box>
          </Box>
        );

      case 2:
        return (
          <>
            {data.questionnaire_meta.length > 0 && (
              <div>
                <Grid container spacing={0}>
                  {data.questionnaire_meta.length > 0 ? (
                    data.questionnaire_meta.map((item, index) => {
                      if (item.que_type === 'Multiple Choice') {
                        if (item.allow_multiple_ans) {
                          return (
                            <Grid item xs={12}>
                              <Box mt={index === 0 ? '0px' : '30px'}>
                                <h6 className={classes.StTitle}>
                                  {item.que}
                                  {item.required ? '*' : ''}
                                </h6>
                                <Grid
                                  container
                                  spacing={0}
                                  style={{ marginTop: '10px' }}
                                >
                                  <Grid item xs={4}>
                                    {item.answer_choices.map((item1) => {
                                      return (
                                        <Box>
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                onClick={() =>
                                                  handleClick(item1.id, index)
                                                }
                                                checked={isSelected(item1.id)}
                                                name="checkedB"
                                                color="primary"
                                              />
                                            }
                                            label={item1.value}
                                          />
                                        </Box>
                                      );
                                    })}
                                  </Grid>
                                </Grid>
                              </Box>
                            </Grid>
                          );
                        }
                        return (
                          <Grid item xs={12}>
                            <Box mt={index === 0 ? '0px' : '30px'}>
                              <h6 className={classes.StTitle}>
                                {item.que}
                                {item.required ? '*' : ''}
                              </h6>
                              <RadioGroup
                                value={item.ans}
                                onChange={(e) => {
                                  const newArray = [...data.questionnaire_meta];

                                  newArray[index] = {
                                    ...newArray[index],
                                    ans: Number(e.target.value)
                                  };
                                  setData({
                                    ...data,
                                    questionnaire_meta: newArray
                                  });
                                  console.warn(data);
                                }}
                                style={{ marginTop: '10px' }}
                              >
                                {item.answer_choices.map((item1) => {
                                  return (
                                    <FormControlLabel
                                      value={item1.id}
                                      control={<Radio color="primary" />}
                                      label={item1.value}
                                    />
                                  );
                                })}
                              </RadioGroup>
                            </Box>
                          </Grid>
                        );
                      }
                      if (item.que_type === 'Yes/No') {
                        return (
                          <Grid item xs={12}>
                            <Box mt={index === 0 ? '0px' : '30px'}>
                              <h6
                                className={classes.StTitle}
                                style={{
                                  textTransform: 'capitalize'
                                }}
                              >
                                {item.que}
                                {item.required ? '*' : ''}
                              </h6>
                              <RadioGroup
                                value={item.ans}
                                onChange={(e) => {
                                  const newArray = [...data.questionnaire_meta];
                                  newArray[index] = {
                                    ...newArray[index],
                                    ans: Number(e.target.value)
                                  };
                                  setData({
                                    ...data,
                                    questionnaire_meta: newArray
                                  });
                                  console.warn(data);
                                }}
                                row
                                style={{ marginTop: '10px' }}
                              >
                                <FormControlLabel
                                  value={1}
                                  control={<Radio color="primary" />}
                                  label="Yes"
                                  className={classes.RadioControl}
                                />
                                <FormControlLabel
                                  value={0}
                                  control={<Radio color="primary" />}
                                  label="No"
                                  className={classes.RadioControl}
                                />
                              </RadioGroup>
                            </Box>
                          </Grid>
                        );
                      }
                      if (item.que_type === 'Yes/No with follow-up') {
                        return (
                          <Grid item xs={12}>
                            <Box mt={index === 0 ? '0px' : '30px'}>
                              <h6 className={classes.StTitle}>
                                {item.que}
                                {item.required ? '*' : ''}
                              </h6>
                              <RadioGroup
                                value={item.ans}
                                onChange={(e) => {
                                  const newArray = [...data.questionnaire_meta];

                                  newArray[index] = {
                                    ...newArray[index],
                                    ans: Number(e.target.value)
                                  };
                                  setData({
                                    ...data,
                                    questionnaire_meta: newArray
                                  });
                                  console.warn(data);
                                }}
                                row
                                style={{ marginTop: '10px' }}
                              >
                                <FormControlLabel
                                  value={1}
                                  control={<Radio color="primary" />}
                                  label="Yes"
                                  className={classes.RadioControl}
                                />
                                <FormControlLabel
                                  value={0}
                                  control={<Radio color="primary" />}
                                  label="No"
                                  className={classes.RadioControl}
                                />
                              </RadioGroup>
                            </Box>
                            <Box mt="30px">
                              <h6 className={classes.StTitle}>
                                {item.follow_up} {item.required ? '*' : ''}
                              </h6>
                              <TextareaControl
                                value={item.followup_ans}
                                onChange={(e) => {
                                  const newArray = [...data.questionnaire_meta];

                                  newArray[index] = {
                                    ...newArray[index],
                                    followup_ans: e.target.value
                                  };
                                  setData({
                                    ...data,
                                    questionnaire_meta: newArray
                                  });
                                  console.warn(data);
                                }}
                                rows={8}
                                label=""
                                className={classes.TextareaControlBox}
                              />
                            </Box>
                          </Grid>
                        );
                      }
                      if (item.que_type === 'Freeform Answer Box') {
                        return (
                          <Grid item xs={12}>
                            <Box mt={index === 0 ? '0px' : '30px'}>
                              <h6 className={classes.StTitle}>
                                {item.que} {item.required ? '*' : ''}
                              </h6>
                              <TextareaControl
                                value={item.ans}
                                onChange={(e) => {
                                  const newArray = [...data.questionnaire_meta];

                                  newArray[index] = {
                                    ...newArray[index],
                                    ans: e.target.value
                                  };
                                  setData({
                                    ...data,
                                    questionnaire_meta: newArray
                                  });
                                  console.warn(data);
                                }}
                                rows={8}
                                label=""
                                className={classes.TextareaControlBox}
                              />
                            </Box>
                          </Grid>
                        );
                      }
                      return null;
                    })
                  ) : (
                    <p>No questions to show</p>
                  )}
                </Grid>
              </div>
            )}
          </>
        );
      case 3:
        return (
          <Box>
            <Grid alignItems="center" container direction="row">
              {data.sign_status === 1 && (
                <Grid item xs={12} className={classes.signatureGrid}>
                  <canvas
                    ref={canRef}
                    width="800"
                    height="250"
                    className={classes.signatureBox}
                  />
                  <JBButton
                    onClick={() => {
                      if (sign) {
                        sign.clear();
                        setSigned(false);
                      }
                    }}
                    disabled={!sign || !signed}
                    className={classes.btClearsignature}
                  >
                    Clear signature
                  </JBButton>
                </Grid>
              )}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={data.sign_status === 1}
                    onChange={() => {
                      setData({
                        ...data,
                        sign_status: data.sign_status === 1 ? 0 : 1
                      });
                      setSigned(false);
                    }}
                    name="checkedB"
                    color="primary"
                  />
                }
                label="I agree to use electronic records and signatures"
              />
            </Grid>
          </Box>
        );
      default:
        return 'Unknown step';
    }
  }
  return (
    <Box
      width="100%"
      className={clsx(
        classes.signcontractStepper,
        data?.questionnaire_meta.length === 0
          ? 'signStepperQuestionnaireMeta'
          : null
      )}
    >
      <JBStepper
        style={{ padding: '0 0' }}
        steps={steps}
        activeStep={activeStep}
        handleBack={handleBack}
        handleNext={handleNext}
        handleReset={handleReset}
        // eslint-disable-next-line react/jsx-no-bind
        handleStepContent={getStepContent}
        isLoading={false}
        hasErrored={false}
        prevLabel="SUBMIT"
        nextLabel="NEXT STEP"
        CustComp={(prop) => (
          <ColorlibStepIcon
            FirstIcon={BasicInformation}
            SecondIcon={BookOpenIcon}
            ThirdIcon={
              data?.questionnaire_meta.length === 0
                ? QuestionmarkFull
                : Questionmark
            }
            FourthIcon={EdiIcon}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...prop}
          />
        )}
      />
    </Box>
  );
};

SignContractStepper.propTypes = {
  classes: PropType.object.isRequired,
  id: PropType.number.isRequired,
  openNotify: PropType.func.isRequired,
  WaivFilter: PropType.func.isRequired,
  SignFilter: PropType.func.isRequired
  // goToHistory: PropType.string.isRequired,
};
const mapToDispatch = (dispatch) => ({
  WaivFilter: (filter, data) => dispatch(getMissWav(filter, data)),
  openNotify: (msg, type) => dispatch(openNoti(msg, type)),
  SignFilter: (filter, data) => dispatch(getSignedWav(filter, data))
});
export default connect(
  null,
  mapToDispatch
)(withStyles(styles)(SignContractStepper));
