import React, { useState } from 'react';
import PropType from 'prop-types';
import { connect } from 'react-redux';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  DialogTitle,
  withStyles
} from '@material-ui/core';
import { VoidInvoice } from 'src/services/Invoice.services';
import {
  getAllInvoices,
  getOverdueInvs,
  getUnpaidInvs
} from 'src/Redux/InvoiceReducer/InvoiceReducer.actions';
import { openNoti } from 'src/Redux/global/global.actions';
import { getErrorMsg } from 'src/utils/ErrorHandling';
import X from '../../../../assets/image/x.png';

const styles = () => ({
  Deletebtn: {
    background: '#FB4E4E',
    boxShadow: '0px 8px 16px rgba(251, 78, 78, 0.16)',
    borderRadius: '2px',
    height: '48px',
    padding: '10px 15px',
    color: '#fff',
    fontWeight: '700',
    '&:hover': {
      background: '#FB4E4E'
    }
  },
  bodytxt: {
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '0.002em',
    color: '#7F7F7F',
    padding: '10px 0'
  },
  titlebk: {
    padding: '16px 24px 5px'
  }
});

function VoidInvoiceDialog(props) {
  const { classes, setOpen, handleClose, invoiceId } = props;
  const [isprocessing, setprocessing] = useState(false);

  const handleVoid = () => {
    const { OpenNoti, updateData, getAllInvs, getOverInv, getunpaids } = props;
    setprocessing(true);
    VoidInvoice(invoiceId)
      .then((res) => {
        updateData(res);
        OpenNoti('Succesfully void the invoice', 'info');
      })
      .then(() => handleClose())
      .then(() => {
        getAllInvs();
        getOverInv();
        getunpaids();
      })
      .catch((err) => {
        setprocessing(false);
        OpenNoti(getErrorMsg(err), 'error');
      });
  };

  return (
    <div>
      <Dialog
        open={setOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div style={{ width: 394 }}>
          <DialogTitle id="alert-dialog-title" className={classes.titlebk}>
            <img
              alt="Plusicon"
              src={X}
              style={{ display: 'inline-block', verticalAlign: 'sub' }}
            />
            <Typography
              color="#000"
              variant="h5"
              component="div"
              className={classes.title}
              style={{ display: 'inline-block', marginLeft: '10px' }}
            >
              Void Invoice
            </Typography>
          </DialogTitle>
          <DialogContent>
            <p className={classes.bodytxt}>
              Are you sure you want to void this invoice?
            </p>
          </DialogContent>
          <DialogActions
            style={{
              padding: '5px 20px 20px'
            }}
          >
            <Button
              variant="outlined"
              onClick={handleClose}
              style={{
                height: '48px',
                color: '#7F7F7F',
                fontWeight: 'bold'
              }}
            >
              Cancel
            </Button>
            <Button
              className={classes.Deletebtn}
              onClick={handleVoid}
              disabled={isprocessing}
            >
              {isprocessing ? 'Processing...' : 'Void'}
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}

VoidInvoiceDialog.propTypes = {
  classes: PropType.object.isRequired,
  setOpen: PropType.bool.isRequired,
  handleClose: PropType.func.isRequired,
  invoiceId: PropType.number.isRequired,
  OpenNoti: PropType.func.isRequired,
  updateData: PropType.func.isRequired,
  getAllInvs: PropType.func.isRequired,
  getOverInv: PropType.func.isRequired,
  getunpaids: PropType.func.isRequired
};

const mapToDispatch = (dispatch) => ({
  OpenNoti: (msg, type) => dispatch(openNoti(msg, type)),
  getAllInvs: () => dispatch(getAllInvoices()),
  getOverInv: () => dispatch(getOverdueInvs()),
  getunpaids: () => dispatch(getUnpaidInvs())
});

export default connect(
  null,
  mapToDispatch
)(withStyles(styles)(VoidInvoiceDialog));
