/* eslint-disable arrow-parens */
import React, { useEffect, useState, useContext } from 'react';
import { AlliesProfile } from 'src/Context/AlliesContext';
import { fetchedUser } from 'src/Context/MasterHQAllies/masterhqallies.actions';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { openNoti } from 'src/Redux/global/global.actions';
// import { Link } from 'react-router-dom';
import {
  Grid,
  withStyles,
  // Card,
  // Breadcrumbs,
  Typography,
  Box,
  InputLabel
} from '@material-ui/core';
import FormControl from 'src/views/common/FormControl/formControl';
// Dummydata
import { getlocMarks, getCountries } from 'src/services/Members.module';
import { updateAllieProfile } from 'src/services/masterHQ.services';
// Dummydata
import { getFullAddress } from 'src/views/people/Members/MemberProfile/Profile/MemberProfile.utils';

import {
  checkEmail,
  checkValidString,
  postalRegEx,
  validMobile
} from 'src/utils/validations';
import LoadingView from 'src/views/common/loading';
import JBmap from 'src/views/common/JBMaps/JBmap';
// import { ROUTES } from 'src/constant/Constants';
// import NotFoundView from 'src/views/errors/NotFoundView';
import CancelDialog from 'src/views/common/CommonDialogBox/CancelDialog';
import validateAlliesProfile from './AlliesProfile.validate';
import styles from '../Profile.style';
// import { DummyValues } from './SubscriptionTable/dummyData';
// import SubscriptionTable from './SubscriptionTable/SubscriptionTable';
import JBButton from '../../../../common/JBButton/JBButton';
import edit from '../../../../../assets/image/edit.png';
import CustomAutocomplete from '../../../../common/CustomAutocomplete/commonAutocomplete';

const InfoLocations = ({ classes }) => {
  const reduxDispatch = useDispatch();
  const [Edit, setEdit] = useState(false);
  const [{ userdetails }, dispatch] = useContext(AlliesProfile); //eslint-disable-line
  const [data, setData] = useState(userdetails.data);
  const [positions, setpositions] = useState(null);
  const [errorFetch] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [countryOpts, setCountryOpts] = useState([]);
  const [country, setcountry] = useState(null); //eslint-disable-line
  const [touched, setTouched] = useState({
    first_name: false,
    last_name: false,
    gym_name: false,
    email: false,
    province: false,
    city: false,
    address: false,
    postal_code: false
  });
  useEffect(() => {
    getCountries().then((res) => {
      if (data.country) {
        setcountry(res.find((c) => c.code === data.country));
      }
      setCountryOpts(res);
    });
  }, []); // eslint-disable-line

  const MapLoads = () => {
    if (positions) {
      const { error } = positions;
      if (error) {
        return (
          <p
            style={{
              color: '#f44336',
              fontSize: '14px'
            }}
          >
            Please Put Valid Address
          </p>
        );
      }
      return <JBmap positions={positions} />;
    }
    return <LoadingView />;
  };

  const handleBlur = (e) => {
    const { name } = e.target;
    setTouched({ ...touched, [name]: true });
  };

  useEffect(() => {
    if (!Edit) {
      const address = getFullAddress(data);
      getlocMarks(address)
        .then((res) => {
          if (res.lat) {
            setpositions(res);
          } else {
            setpositions({ error: true });
          }
        })
        .catch((err) => console.warn(err));
    }
  }, [Edit]); // eslint-disable-line

  const handleSave = async () => {
    setIsProcessing(true);
    try {
      validateAlliesProfile(data);
      const { error } = positions;
      if (error) {
        throw Error('Please put a valid address');
      }
      const response = await updateAllieProfile(data, data.id);
      dispatch(fetchedUser(response));
      reduxDispatch(openNoti('Successfully updated the profile', 'info'));
    } catch (err) {
      if (!err.response) {
        reduxDispatch(openNoti(err.message, 'error'));
      } else {
        reduxDispatch(openNoti(err.response.data.message, 'error'));
      }
    } finally {
      setIsProcessing(false);
    }
  };

  if (errorFetch) return <p>Something Went Wrong</p>;

  return (
    <div>
      <Grid
        container
        direction="row"
        spacing={0}
        style={{ margin: '10px 0 0px' }}
      >
        <Grid item xs={7}>
          <Box mt="15px" mb="15px">
            <Typography
              color="#000"
              variant="h5"
              component="div"
              className={classes.bktitle}
            >
              Main Contact Information
            </Typography>
            <p className={classes.ptext}>
              Edit the general information about your gym.
            </p>
          </Box>
        </Grid>
        <Grid
          container
          spacing={2}
          style={{
            alignItems: 'center'
          }}
        >
          <Grid item xs={12}>
            <InputLabel
              htmlFor="age-required"
              required
              className={classes.cutom_select_Label}
              style={{ padding: '5px 0' }}
            >
              Gym name
            </InputLabel>
            <FormControl
              control="input"
              label=""
              placeholder=""
              required
              value={data.gym_name}
              name="gym_name"
              onBlur={handleBlur}
              onChange={(e) => {
                setData({ ...data, gym_name: e.target.value });
              }}
              style={{ margin: '0 0' }}
              error={Boolean(
                touched.gym_name && checkValidString(data.gym_name)
              )}
              helperText={
                touched.gym_name && checkValidString(data.gym_name)
                  ? 'Please enter valid Gym Name'
                  : null
              }
            />
          </Grid>
          <Grid item xs={4}>
            <InputLabel
              htmlFor="age-required"
              required
              className={classes.cutom_select_Label}
              style={{ padding: '5px 0' }}
            >
              First name
            </InputLabel>
            <FormControl
              control="input"
              label=""
              placeholder=""
              required
              value={data.first_name}
              onBlur={handleBlur}
              onChange={(e) => {
                setData({ ...data, first_name: e.target.value });
              }}
              name="first_name"
              style={{ margin: '0 0' }}
              error={Boolean(
                touched.first_name && !data.first_name.trim().length
              )}
              helperText={
                touched.first_name && !data.first_name.trim().length
                  ? 'Please enter First Name'
                  : null
              }
            />
          </Grid>
          <Grid item xs={4}>
            <InputLabel
              htmlFor="age-required"
              required
              className={classes.cutom_select_Label}
              style={{ padding: '5px 0' }}
            >
              Last name
            </InputLabel>
            <FormControl
              control="input"
              label=""
              placeholder=""
              required
              value={data.last_name}
              onBlur={handleBlur}
              onChange={(e) => {
                setData({ ...data, last_name: e.target.value });
              }}
              name="last_name"
              style={{ margin: '0 0' }}
              error={Boolean(
                touched.last_name && !data.last_name.trim().length
              )}
              helperText={
                touched.last_name && !data.last_name.trim().length
                  ? 'Please enter Last Name'
                  : null
              }
            />
          </Grid>
          <Grid item xs={4}>
            <FormControl
              control="input"
              label="Email"
              required
              placeholder=""
              name="email"
              onBlur={handleBlur}
              value={data.email}
              maxLength={60}
              onChange={(e) => setData({ ...data, email: e.target.value })}
              style={{ margin: '0 0' }}
              error={Boolean(touched.email && checkEmail(data.email))}
              helperText={
                touched.email && checkEmail(data.email)
                  ? 'Please enter valid Email'
                  : null
              }
            />
          </Grid>
          <Grid item xs={4}>
            <InputLabel
              htmlFor="age-required"
              className={classes.cutom_select_Label}
              style={{ padding: '5px 0' }}
            >
              Phone number
            </InputLabel>
            <FormControl
              control="input"
              label=""
              placeholder=""
              maxLength={15}
              value={data.mobile_no}
              name="mobile_no"
              onChange={(e) => {
                setData({ ...data, mobile_no: validMobile(e.target.value) });
              }}
              required
              style={{ margin: '0 0' }}
            />
          </Grid>
          <Grid item xs={4}>
            <InputLabel
              htmlFor="age-required"
              className={classes.cutom_select_Label}
              style={{ padding: '5px 0' }}
            >
              Website
            </InputLabel>
            <FormControl
              control="input"
              label=""
              placeholder=""
              required
              value={data.website}
              maxLength={200}
              name="website"
              onChange={(e) => setData({ ...data, website: e.target.value })}
              style={{ margin: '0 0' }}
            />
          </Grid>
          <Grid item xs={4}>
            <InputLabel
              htmlFor="age-required"
              className={classes.cutom_select_Label}
              style={{ padding: '5px 0' }}
            >
              Currency
            </InputLabel>
            <FormControl
              disabled
              control="input"
              label=""
              placeholder=""
              value={data.ally_currency.currency_code}
              required
              name="currency"
              // onBlur={handleBlur}
              // onChange={(e) => setData({ ...data, city: e.target.value })}
              style={{ margin: '0 0' }}
              // error={Boolean(touched.city && checkValidString(data.city))}
              // helperText={
              //   touched.city && checkValidString(data.city)
              //     ? 'Please enter valid City'
              //     : null
              // }
            />
          </Grid>
        </Grid>
      </Grid>
      <Box mt="40px" />
      <Grid container direction="row" spacing={0}>
        <Grid item>
          <Box>
            <Typography
              color="#000"
              variant="h5"
              component="div"
              className={classes.bktitle}
              style={{ margin: '0 0' }}
            >
              Location
            </Typography>
          </Box>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={2}
        style={{ alignItems: 'center', padding: '10px 0 0' }}
      >
        {/* <Grid item xs={12}>
          <InputLabel
            htmlFor="age-required"
            required
            className={classes.cutom_select_Label}
            style={{ padding: '5px 0' }}
          >
            Gym name
          </InputLabel>
          <FormControl
            control="input"
            label=""
            placeholder=""
            required
            value={data.gymName}
            onBlur={handleBlur}
            onChange={(e) => {
              setData({ ...data, gymName: e.target.value });
            }}
            name="gymName"
            style={{ margin: '0 0' }}
            error={Boolean(touched.gymName && checkValidString(data.gymName))}
            helperText={
              touched.gymName && checkValidString(data.gymName)
                ? 'Please enter valid Gym Name'
                : null
            }
          />
        </Grid> */}
        <Grid item xs={10} xl={10} lg={10}>
          <InputLabel
            htmlFor="age-required"
            required
            className={classes.cutom_select_Label}
            style={{ padding: '5px 0' }}
          >
            Street, City, State/Province, Zip Code
          </InputLabel>
          <FormControl
            control="input"
            label=""
            placeholder=""
            required
            value={getFullAddress(data)}
            style={{ margin: '0 0' }}
          />
        </Grid>
        <Grid item xs style={{ marginTop: '5px' }}>
          <InputLabel
            htmlFor="age-required"
            className={classes.cutom_select_Label}
            style={{ padding: '5px 0' }}
          >
            &nbsp;
          </InputLabel>
          {!Edit ? (
            <JBButton
              type="outlined"
              style={{ width: '100%', padding: '5px 10px' }}
              isImg={edit}
              onClick={() => setEdit(true)}
            >
              Edit Address
            </JBButton>
          ) : (
            <JBButton
              type="outlined"
              style={{ width: '100%' }}
              // isImg={edit}
              onClick={() => setEdit(false)}
            >
              Save Address
            </JBButton>
          )}
        </Grid>
      </Grid>

      {!Edit ? null : (
        <Box style={{ width: '100%' }}>
          <Grid
            container
            spacing={2}
            style={{
              alignItems: 'center',
              padding: '15px 0'
            }}
          >
            <Grid item xs={6}>
              <InputLabel
                htmlFor="age-required"
                required
                className={classes.cutom_select_Label}
                style={{ padding: '5px 0' }}
              >
                Street address line 1
              </InputLabel>
              <FormControl
                control="input"
                label=""
                placeholder=""
                required
                value={data.address}
                name="address"
                onBlur={handleBlur}
                onChange={(e) => setData({ ...data, address: e.target.value })}
                style={{ margin: '0 0' }}
                error={Boolean(
                  touched.address && checkValidString(data.address)
                )}
                helperText={
                  touched.address && checkValidString(data.address)
                    ? 'Please enter valid Address'
                    : null
                }
              />
            </Grid>
            <Grid item xs={6}>
              <InputLabel
                htmlFor="age-required"
                className={classes.cutom_select_Label}
                style={{ padding: '5px 0' }}
              >
                Street address line 2
              </InputLabel>
              <FormControl
                value={data.address2}
                onChange={(e) => setData({ ...data, address2: e.target.value })}
                control="input"
                label=""
                placeholder=""
                required
                style={{ margin: '0 0' }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            style={{
              alignItems: 'center'
              // marginBottom: '5px'
            }}
          >
            <Grid item xs={3}>
              <InputLabel
                htmlFor="age-required"
                required
                className={classes.cutom_select_Label}
                style={{ padding: '5px 0' }}
              >
                City
              </InputLabel>
              <FormControl
                control="input"
                label=""
                placeholder=""
                value={data.city}
                required
                name="city"
                onBlur={handleBlur}
                onChange={(e) => setData({ ...data, city: e.target.value })}
                style={{ margin: '0 0' }}
                error={Boolean(touched.city && checkValidString(data.city))}
                helperText={
                  touched.city && checkValidString(data.city)
                    ? 'Please enter valid City'
                    : null
                }
              />
            </Grid>
            <Grid item xs={3}>
              <InputLabel
                htmlFor="age-required"
                required
                className={classes.cutom_select_Label}
                style={{ padding: '5px 0' }}
              >
                Province
              </InputLabel>
              <FormControl
                control="input"
                label=""
                placeholder=""
                required
                value={data.province}
                name="province"
                onBlur={handleBlur}
                onChange={(e) => setData({ ...data, province: e.target.value })}
                style={{ margin: '0 0' }}
                error={Boolean(
                  touched.province && checkValidString(data.province)
                )}
                helperText={
                  touched.province && checkValidString(data.province)
                    ? 'Please enter valid State'
                    : null
                }
              />
            </Grid>
            <Grid item xs={3} className={classes.masterhqAutocomplete}>
              <InputLabel
                htmlFor="age-required"
                required
                className={classes.cutom_select_Label}
                style={{ padding: '8px 0' }}
              >
                Country
              </InputLabel>
              <CustomAutocomplete
                data={country}
                Options="name"
                value={countryOpts}
                disableClearable
                Change={(val) => {
                  setcountry(val);
                  setData({ ...data, country: val.code });
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <InputLabel
                htmlFor="age-required"
                required
                className={classes.cutom_select_Label}
                style={{ padding: '5px 0' }}
              >
                Postal code
              </InputLabel>
              <FormControl
                control="input"
                label=""
                placeholder=""
                required
                value={data.postal_code}
                onBlur={handleBlur}
                error={
                  touched.postal_code && !postalRegEx.test(data.postal_code)
                }
                helperText={
                  touched.postal_code && !postalRegEx.test(data.postal_code)
                    ? 'Please enter a valid postal code'
                    : null
                }
                name="postal_code"
                maxLength={10}
                onChange={(e) =>
                  setData({ ...data, postal_code: e.target.value })
                }
                style={{ margin: '0 0' }}
              />
            </Grid>
          </Grid>
        </Box>
      )}
      <Box mt="10px" />
      {/* google map */}
      <Grid container>
        <Grid item xs={12} style={{ margin: '20px 0' }}>
          {/* google map */}
          {MapLoads()}
        </Grid>
      </Grid>
      <Box mt="10px" />

      <Box mt="40px" />
      {/* <Grid container direction="row" spacing={0}>
        <Grid item>
          <Box>
            <Typography
              color="#000"
              variant="h5"
              component="div"
              className={classes.bktitle}
            >
              Subscription
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box mt="25px" />
          <SubscriptionTable values={DummyValues} />
        </Grid>
      </Grid> */}

      <Box mt="40px" />
      <Grid container direction="row" justify="flex-end" alignItems="center">
        <Box ml="10px">
          <CancelDialog />
        </Box>
        <Box ml="10px">
          <JBButton
            type="primary"
            // component={Link}
            disabled={isProcessing}
            onClick={handleSave}
          >
            {isProcessing ? 'Saving your data..' : 'Save'}
          </JBButton>
        </Box>
      </Grid>
    </div>
  );
};

InfoLocations.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(InfoLocations);
