import authhttp from './authHttp.services';

export const getInvoiceSet = async () => {
  const InvoiceUrl = 'api/settings/invoice/edit';
  const {
    data: {
      data: { meta }
    }
  } = await authhttp.get(InvoiceUrl);
  return meta;
};

export const getGymSet = async () => {
  const GymSetUrl = 'api/settings/gym/edit';
  const {
    data: {
      data: { meta }
    }
  } = await authhttp.get(GymSetUrl);
  return meta;
};

export const getRevenueCategory = async () => {
  const RevenUrl = 'api/get_revenue_category';
  const {
    data: { data }
  } = await authhttp.get(RevenUrl);
  return data;
};

export const getAllTaxes = async () => {
  const TaxUrl = 'api/tax';
  const {
    data: { data }
  } = await authhttp.get(TaxUrl);
  return data;
};
export const createTax = async (values) => {
  const TaxUrl = 'api/tax';
  const {
    data: { data }
  } = await authhttp.post(TaxUrl, values);
  return data;
};
export const deleteTax = async (id, values) => {
  const TaxUrl = `api/tax/${id}`;
  const {
    data: { data }
  } = await authhttp.put(TaxUrl, values);
  return data;
};
export const getRevenueCategorywithStatus = async () => {
  const RevenUrl = 'api/revenue_category';
  const {
    data: { data }
  } = await authhttp.get(RevenUrl);
  return data;
};
export const getEditRevenueCategory = async (id) => {
  const RevenueUrl = `api/revenue_category/${id}/edit`;
  const {
    data: { data }
  } = await authhttp.get(RevenueUrl);
  return data;
};
export const updateRevenueCategory = async (id, values) => {
  const RevenueUrl = `api/revenue_category/${id}`;
  const {
    data: { data }
  } = await authhttp.put(RevenueUrl, values);
  return data;
};
export const createRevenueCategory = async (values) => {
  const RevenueUrl = 'api/revenue_category';
  const {
    data: { data }
  } = await authhttp.post(RevenueUrl, values);
  return data;
};
export const updateMembershipSetting = async (val) => {
  const values = {
    module_name: 'membership',
    meta: JSON.stringify(val)
  };
  const settingurl = 'api/update_settings';
  const {
    data: {
      data: { meta }
    }
  } = await authhttp.post(settingurl, values);
  return meta;
};

export async function updateSettingsByModule(module_name, val) {
  const values = {
    module_name,
    meta: JSON.stringify(val)
  };
  const settingurl = 'api/update_settings';
  const {
    data: {
      data: { meta }
    }
  } = await authhttp.post(settingurl, values);
  return meta;
}
// webintegrations
export const getWebIntegrations = async () => {
  const WebUrl = 'api/get_web_integrations';
  const {
    data: { data }
  } = await authhttp.get(WebUrl);
  return data;
};

export const WebIntegrationsDelete = async () => {
  const WebUrl = 'api/destroy_webintegreation';
  const {
    data: { data }
  } = await authhttp.get(WebUrl);
  return data;
};
