const getDays = () => {
  const result = [];
  // eslint-disable-next-line
  for (let i = 1; i <= 30; i++) {
    const b = {
      id: i,
      name: String(i)
    };
    result.push(b);
  }
  return result;
};

export default getDays;
