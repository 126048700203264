import {
  attendanceSetError,
  attendanceSetProgram,
  SetNextDate,
  SetPrevDate,
  attendacesetClass,
  setattendanceday,
  setStartTime,
  resetFilter
} from './attendanceReducer.types';

export const AttendanceSetProgram = (payload) => ({
  type: attendanceSetProgram,
  payload
});
export const AttendanceSetDay = (payload) => ({
  type: setattendanceday,
  payload
});
export const AttendanceSetStartTime = (payload) => ({
  type: setStartTime,
  payload
});
export const AttendanceSetPrevDate = (payload) => ({
  type: SetPrevDate,
  payload
});

export const AttendanceSetNextDate = (payload) => ({
  type: SetNextDate,
  payload
});

export const AttendanceSetError = (payload) => ({
  type: attendanceSetError,
  payload
});

export const setAttendanceClass = (payload) => ({
  type: attendacesetClass,
  payload
});
export const Reset = () => ({
  type: resetFilter
});
