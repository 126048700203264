/* eslint-disable operator-linebreak */
/* eslint-disable indent */
import React, { useState, useCallback, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  withStyles,
  Card,
  Breadcrumbs,
  Typography,
  Box,
  InputBase,
  Paper,
  ThemeProvider,
  FormControlLabel
} from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
import filterType from 'src/Redux/Reducer.constants';
import { useSelector, connect } from 'react-redux';
import { debounce } from 'lodash';
import { getoveraccountreports } from 'src/Redux/ReportsReducer/reports.reducer.actions';
import { exportOverDueaccount } from 'src/services/reports.services';
import LoadingView from 'src/views/common/loading';
import Pagination from '@material-ui/lab/Pagination';
import IOSSwitch from 'src/views/common/FormControl/IOSSwitch';
import CancelDialogTwo from 'src/views/common/CommonDialogBox/CancelDialogTwo';
import useChangeTitle from 'src/views/common/CustomHooks/useTitleChange';
import JBButton from '../../common/JBButton/JBButton';
import ImportIcon from '../../../assets/image/upload.png';
import { getOverdueAccountReceivableChart } from '../Reports.utils';
import OverdueAccountTable from './AllTables/OverdueAccountTable/OverdueAccountTable';
// import { DummyValues } from './AllTables/OverdueAccountTable/dummyData';
// import { data as bardata } from './chartData/OverdueData';
import BarChart from '../chart/BarChart';
import styles from './FinancialReports.style';

const themeInstance = {
  overrides: {
    MuiBreadcrumbs: {
      root: {
        color: '#949494',
        textTransform: 'uppercase',
        fontSize: '20px',
        lineHeight: '24px',
        fontWeight: '700'
      },
      li: {
        '& a': {
          color: '#949494',
          textTransform: 'uppercase',
          fontSize: '20px',
          lineHeight: '24px'
        },
        '@media (max-width: 1440px)': {
          '& a': {
            fontSize: '18px'
          },
          '& h3': {
            fontSize: '18px'
          }
        },
        '& h3': {
          margin: '0 0'
        }
      },
      separator: {
        marginLeft: '12px',
        marginRight: '12px'
      }
    }
  }
};

const OverdueAccountReceivable = (props) => {
  const {
    FilterData,
    OverdueAccountSort,
    OverdueAccountSearch,
    classes,
    filteredTags,
    customFilter,
    defaultValue
    // allprogramsData,
    // programSelected
  } = props;
  useChangeTitle('Overdue Account Receivable | Jungle Alliance');
  const { isFetching, data, error } = useSelector(
    (state) => state?.reportsReducer?.financialReports?.overdueAccounts
  );
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState(OverdueAccountSearch);
  const [activeuser, setactiveuser] = useState(() => defaultValue.user_status);

  const { data: data_reports } = useSelector(
    (state) => state.reportsReducer.reportList
  );
  const isStarred = data_reports?.financial_report.filter(
    (report) => report.id === 14
  )[0].is_starred;

  const SearchQuery = useCallback(
    debounce((q) => FilterData(filterType.search, q), 1500),
    []
  );

  const userSelect = useCallback(
    debounce(
      // eslint-disable-next-line no-confusing-arrow
      (q) =>
        q
          ? FilterData(filterType.addcustom, [
              { user_status: 1 },
              {
                id: 1000000000001,
                name: 'Members is Active',
                filter: 'user_status'
              }
            ])
          : FilterData(filterType.addcustom, [
              { user_status: 0 },
              {
                id: 1000000000001,
                name: 'Members is inactive',
                filter: 'user_status'
              }
            ]),
      1000
    ),
    []
  );

  const setDirection = (name) => {
    if (OverdueAccountSort.sort_name === name) {
      return OverdueAccountSort.sort_by === 'asc' ? 'asc' : 'dsc';
    }
    return 'asc';
  };

  const isActive = (sortlabel) => {
    if (OverdueAccountSort.sort_name === sortlabel) return true;
    return false;
  };

  const setSort = (sortlabel) => {
    FilterData(filterType.sorting, sortlabel);
  };

  const Paginate = (val) => {
    if (val !== data.current_page) {
      return FilterData(filterType.page, val);
    }
    return null;
  };
  const handleReset = () => {
    FilterData(filterType.resetFilter);
    setactiveuser(() => defaultValue.user_status);
    setSearch('');
  };
  useEffect(() => {
    return () => {
      handleReset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (!data_reports || !data) return <LoadingView />;

  return (
    <div className={classes.root}>
      <div className="title-bk">
        <Box css={{ marginBottom: '20px' }}>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={0}
          >
            <Grid item xs={9}>
              <Box display="inline-flex">
                <ThemeProvider theme={themeInstance}>
                  <Breadcrumbs aria-label="breadcrumb">
                    <div color="inherit">Reports</div>
                    <div
                      color="inherit"
                      onClick={() => setOpen(true)}
                      style={{
                        cursor: 'pointer'
                      }}
                    >
                      financial reports
                    </div>
                    <Typography variant="h3" color="Primary">
                      <span>Overdue Account Receivable</span>
                    </Typography>
                  </Breadcrumbs>
                </ThemeProvider>
              </Box>
              <Box
                display="inline-flex"
                ml="10px"
                css={{ verticalAlign: 'text-bottom' }}
              >
                {isStarred === 1 && (
                  <StarIcon
                    style={{
                      color: '#FFC700'
                    }}
                  />
                )}
              </Box>
            </Grid>
            <Grid
              item
              xs={3}
              style={{
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              <JBButton
                type="outlined"
                isImg={ImportIcon}
                style={{ marginLeft: '10px' }}
                disabled={data?.paginationData.data.length === 0}
                onClick={(e) => {
                  e.preventDefault();
                  exportOverDueaccount({
                    search: OverdueAccountSearch,
                    sort_by: OverdueAccountSort.sort_by,
                    sort_name: OverdueAccountSort.sort_name,
                    ...customFilter
                  });
                }}
              >
                Export
              </JBButton>
            </Grid>
          </Grid>
        </Box>
      </div>

      <Card className={classes.mainCard}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={0}
        >
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={0}
          >
            <Grid
              item
              xs={12}
              justify="center"
              alignItems="center"
              textAlign="center"
            >
              {data?.graph.length === 0 ? null : (
                <Box css={{ textAlign: 'center' }}>
                  <Box display="inline-block">
                    <Typography variant="h6" className={classes.BkChartTitle}>
                      <span>Overdue Account Receivable</span>
                    </Typography>
                    <BarChart
                      xlabel="MONTH"
                      ylabel="REVENUE"
                      // data={bardata}
                      data={getOverdueAccountReceivableChart(
                        data?.graph
                        // =data?.net_revenue
                      )}
                      className={classes.BkChart}
                    />
                  </Box>
                </Box>
              )}
            </Grid>
          </Grid>

          <Grid item xs={6}>
            <Grid container direction="row" alignItems="center" spacing={0}>
              <Grid item xs={9}>
                <div className={classes.search}>
                  <InputBase
                    fullWidth
                    placeholder="Search…"
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                      SearchQuery(e.target.value);
                    }}
                    classes={{
                      root: classes.inputRoot,
                      input: classes.searchInput
                    }}
                    InputProps={{
                      'aria-label': 'search'
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs>
                <JBButton
                  type="outlined"
                  style={{ marginLeft: '10px' }}
                  onClick={() => handleReset()}
                >
                  reset
                </JBButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} justify="flex-end" className="MuiGrid-container">
            {/* <Box css={{ width: '240px' }}>
              <ApplyFilter>
                <RevenueCategoryFilters />
              </ApplyFilter>
            </Box> */}
            <Box
              mr="20px"
              css={{
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                marginRight: '20px'
              }}
            >
              <FormControlLabel
                control={
                  <IOSSwitch
                    checked={activeuser}
                    onChange={(e) => {
                      setactiveuser(e.target.checked);
                      userSelect(
                        e.target.checked,
                        filteredTags.filter(
                          (l) => l.filter === 'user_status'
                        )[0]
                      );
                    }}
                  />
                }
                label="Active members only"
                style={{ margin: '0 0' }}
              />
            </Box>
          </Grid>
        </Grid>

        {isFetching || !data ? (
          <LoadingView />
        ) : error ? (
          <p>{error}</p>
        ) : (
          <Fragment>
            {' '}
            <Grid item xs={12}>
              <Box mt="25px" />
              <OverdueAccountTable
                values={data?.paginationData?.data}
                unpaidamount={data?.sum_of_unpaid?.total_unpaid_amount}
                setDirection={setDirection}
                isActive={isActive}
                setSort={setSort}
              />
            </Grid>
            {data?.paginationData.data.length !== 0 && (
              <Grid
                item
                xs={12}
                container
                direction="row"
                alignItems="center"
                style={{ marginTop: 24 }}
              >
                <Grid item xs={6} justify="flex-start">
                  <Paper elevation={0} style={{ color: '#A9A9A9' }}>
                    {data?.paginationData.from &&
                    data?.paginationData.to > 1 ? (
                      <Paper elevation={0} style={{ color: '#A9A9A9' }}>
                        {`${data?.paginationData.from} - ${data?.paginationData.to} of ${data?.paginationData.total}`}
                      </Paper>
                    ) : null}
                  </Paper>
                </Grid>
                <Grid
                  item
                  xs={6}
                  justify="flex-end"
                  style={{ display: 'flex' }}
                >
                  <Pagination
                    count={data?.paginationData.last_page}
                    color="primary"
                    variant="outlined"
                    page={data?.paginationData.current_page}
                    // onChange={(_, val) => console.log(val)}
                    onChange={(__, val) => Paginate(val)}
                    shape="rounded"
                  />
                </Grid>
              </Grid>
            )}
          </Fragment>
        )}
      </Card>
      <CancelDialogTwo setOpen={open} handleClose={() => setOpen(false)} />
    </div>
  );
};

OverdueAccountReceivable.propTypes = {
  classes: PropTypes.object.isRequired,
  FilterData: PropTypes.func.isRequired,
  OverdueAccountSearch: PropTypes.string.isRequired,
  OverdueAccountSort: PropTypes.object.isRequired,
  filteredTags: PropTypes.array.isRequired,
  customFilter: PropTypes.object.isRequired,
  defaultValue: PropTypes.object.isRequired
};

const mapToState = (state) => ({
  OverdueAccountSearch:
    state?.reportsReducer.financialReports.overdueAccountsFil.search,
  OverdueAccountSort:
    state?.reportsReducer.financialReports.overdueAccountsFil.sorting,
  filteredTags: state?.reportsReducer.financialReports.overdueAccountsFil.tags,
  customFilter:
    state.reportsReducer.financialReports.overdueAccountsFil.customFilter,
  defaultValue: state.reportsReducer.financialReports.overdueAccountsFil.default
});

const mapToDispatch = (dispatch) => ({
  FilterData: (filter, data) => dispatch(getoveraccountreports(filter, data))
});

export default connect(
  mapToState,
  mapToDispatch
)(withStyles(styles)(OverdueAccountReceivable));

// export default withStyles(styles)(OverdueAccountReceivable);
