import React from 'react';
import { ROUTES } from 'src/constant/Constants';
import PropTypes from 'prop-types';
import { Link, Route, Switch } from 'react-router-dom';
import {
  Grid,
  withStyles,
  AppBar,
  Tabs,
  Tab,
  Card,
  Breadcrumbs,
  Typography
} from '@material-ui/core';
import { getActiveVal } from './Reports.utils';
import styles from './Reports.style';
import StarredReports from './StarredReports/StarredReports';
import MembersReports from './MembersReports/MembersReports';
import AttendanceReports from './AttendanceReports/AttendanceReports';
import FinancialReports from './FinancialReports/FinancialReports';
import LeadReports from './LeadReports/LeadReports';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

const Reports = ({ classes, location }) => {
  const Tabslocation = getActiveVal(location.pathname);

  return (
    <div className={classes.root}>
      <div className="title-bk">
        <div style={{ marginBottom: '20px' }}>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={0}
          >
            <Grid item xs={12}>
              <Breadcrumbs aria-label="breadcrumb">
                <Link color="inherit" to={ROUTES.REPORTS_STARRED}>
                  Reports
                </Link>
                <Typography variant="h3" color="Primary">
                  {(() => {
                    switch (Tabslocation) {
                      case 0:
                        return <span>starred reports</span>;
                      case 1:
                        return <span>Members Reports</span>;
                      case 2:
                        return <span>Attendance Reports</span>;
                      case 3:
                        return <span>Financial Reports</span>;
                      case 4:
                        return <span>lead Reports</span>;
                      default:
                        return null;
                    }
                  })()}
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
        </div>
      </div>

      <Card className={classes.mainCard}>
        <AppBar
          position="static"
          style={{
            backgroundColor: 'transparent',
            boxShadow: 'none'
          }}
        >
          <Tabs
            value={Tabslocation}
            indicatorColor="primary"
            textColor="primary"
            fullWidth
          >
            <Tab
              label="Starred Reports"
              component={Link}
              to={ROUTES.REPORTS_STARRED}
              className={classes.p_tabs}
              {...a11yProps(0)} // eslint-disable-line
            />
            <Tab
              label="Members Reports"
              component={Link}
              to={ROUTES.REPORTS_MEMBERS}
              className={classes.p_tabs}
              {...a11yProps(1)} // eslint-disable-line
            />
            <Tab
              label="Attendance Reports"
              component={Link}
              to={ROUTES.REPORTS_ATTENDANCE}
              className={classes.p_tabs}
              {...a11yProps(2)} // eslint-disable-line
            />
            <Tab
              label="Financial Reports"
              component={Link}
              to={ROUTES.REPORTS_FINANCIAL}
              className={classes.p_tabs}
              {...a11yProps(3)} // eslint-disable-line
            />
            <Tab
              label="lead Reports"
              component={Link}
              to={ROUTES.REPORTS_LEAD}
              className={classes.p_tabs}
              {...a11yProps(4)} // eslint-disable-line
            />
          </Tabs>
        </AppBar>

        <Switch>
          <Route path={ROUTES.REPORTS_LEAD} component={LeadReports} />
          <Route path={ROUTES.REPORTS_FINANCIAL} component={FinancialReports} />
          <Route
            path={ROUTES.REPORTS_ATTENDANCE}
            component={AttendanceReports}
          />
          <Route path={ROUTES.REPORTS_MEMBERS} component={MembersReports} />
          <Route path={ROUTES.REPORTS_STARRED} component={StarredReports} />
        </Switch>
      </Card>
    </div>
  );
};

Reports.propTypes = {
  classes: PropTypes.object.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  location: PropTypes.any.isRequired,
  pathname: PropTypes.any.isRequired
};

export default withStyles(styles, { withTheme: true })(Reports);
