import React, { useState, useContext } from 'react';
import PropType from 'prop-types';
import { VanguardWaiverCtx } from 'src/Context/VanguardWaiverContext';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import {
  NextStep,
  StepBack,
  ResetStep,
  SetMemberData,
  SetActiveStep
} from 'src/Context/VanguardWaiver/VanguardWaiver.actions';
// import { searchUserByEmail } from 'src/services/Members.module';
import {
  createVanguardUserWaiver,
  updateUserWaiverVanguard,
  assignWaiverToUserVanguard
} from 'src/services/vanguard.services';
import { ROUTES } from 'src/constant/Constants';
import { useHistory, Link } from 'react-router-dom';
import {
  Button,
  AppBar,
  Toolbar,
  IconButton,
  withStyles,
  Card,
  Box
} from '@material-ui/core';
import { openNoti } from 'src/Redux/global/global.actions';
import { getErrorMsg } from 'src/utils/ErrorHandling';
import useChangeTitle from 'src/views/common/CustomHooks/useTitleChange';
import { ReactComponent as BackArrow } from '../../../assets/image/Vanguard/icon_back_vanguard.svg';
import LeavePagePopup from '../LeavepagePopup';
import styles from './waiverIndex.style';
import JBVanguardStepper from '../../common/JBVanguardStepper';

import PersonalInfoWawiver from './personalInfoWaiver';
import SelectWaiver from './selectWaiver';
import Terms from './terms';
import Questionnaire from './questionnaire';
import Sign from './sign';
import SuccessPopup from '../successPopup';
import {
  getWaiverData,
  getVanguardUserData,
  validateQues,
  validateVanguardWaiver
} from './waiverValidation';

function getSteps() {
  return ['1', '2', '3', '4', '5'];
}

const WaiverNew = ({ classes }) => {
  const history = useHistory();
  useChangeTitle('New Waiver | Jungle Alliance');
  const [state, dispatch] = useContext(VanguardWaiverCtx);
  const reduxDispatch = useDispatch();
  const [LeavePageopen, setLeavePageopen] = useState(false);
  const steps = getSteps();
  const [Sussopen, setSussopen] = useState(false);
  const [btnDisalbed, setBtnDisabled] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);
  const [waiverUserData, setwaiverUserData] = useState();
  const {
    activeStep,
    selectedWaiver,
    signStatus,
    isSigned,
    personal_info,
    personal_info: {
      waiver_terms_initial: { sign_initials, initial_box },
      questionnaire_meta
    }
  } = state;

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <PersonalInfoWawiver />;
      case 1:
        return <SelectWaiver UserData={personal_info} />;
      case 2:
        return <Terms />;
      case 3:
        return <Questionnaire />;
      case 4:
        return <Sign />;
      default:
        return 'Unknown step';
    }
  }

  const handleNext = () => {
    if (activeStep === 0) {
      try {
        validateVanguardWaiver(state.personal_info);
        const data = getVanguardUserData(state.personal_info);
        setIsProcessing(true);
        createVanguardUserWaiver(data)
          .then((response) => {
            dispatch(SetMemberData(response));
            setwaiverUserData(response);
            reduxDispatch(openNoti('Profile created'));
            setBtnDisabled(true);
            dispatch(NextStep());
            setIsProcessing(false);
          })
          .catch((err) => {
            reduxDispatch(openNoti(getErrorMsg(err), 'error'));
            setIsProcessing(false);
          }); //eslint-disable-line
      } catch (err) {
        reduxDispatch(openNoti(err.message, 'error'));
      }
    }
    if (activeStep === 1) {
      if (selectedWaiver) {
        const val = {
          user_id: waiverUserData.id,
          waiver_id: selectedWaiver
        };
        assignWaiverToUserVanguard(val).then((res) => {
          console.warn(res);
        });
        setBtnDisabled(false);
        dispatch(NextStep());
      } else {
        reduxDispatch(openNoti('Please select a Waiver', 'error'));
      }
    }

    if (activeStep === 2) {
      if (!signStatus) {
        return reduxDispatch(
          openNoti('You must agree to the terms and conditions', 'error')
        );
      }
      if (initial_box) {
        if (sign_initials.length === 0) {
          return reduxDispatch(openNoti('Initials Required', 'error'));
        }
      }

      if (questionnaire_meta.length === 0) {
        dispatch(SetActiveStep(4));
      } else {
        dispatch(NextStep());
      }
    }

    if (activeStep === 3) {
      if (validateQues(questionnaire_meta)) {
        reduxDispatch(
          openNoti('Please provide valid questionnaire details', 'error')
        );
      } else {
        dispatch(NextStep());
      }
    }

    if (activeStep === 4) {
      if (!isSigned) {
        reduxDispatch(openNoti('Please provide a signature', 'error'));
      } else if (!signStatus) {
        reduxDispatch(
          openNoti('You must agree to the terms and conditions', 'error')
        );
      } else {
        const formData = getWaiverData(personal_info);
        updateUserWaiverVanguard(formData, selectedWaiver)
          .then(() => {
            setSussopen(true);
            setTimeout(() => history.push(ROUTES.VANGUARD), 3500);
          })
          .catch((err) => reduxDispatch(openNoti(getErrorMsg(err), 'error'))); //eslint-disable-line
      }
    }

    return true;
  };
  const handleReset = () => {
    dispatch(ResetStep());
  };
  const handleBack = () => {
    if (activeStep === 0) {
      history.goBack();
    }
    if (activeStep === 2) {
      setBtnDisabled(true);
    }

    if (activeStep === 4 && questionnaire_meta.length === 0) {
      dispatch(SetActiveStep(2));
    } else {
      dispatch(StepBack());
    }
  };

  return (
    <Box>
      <AppBar className={classes.appBar}>
        <Toolbar className={classes.appToolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="close"
            onClick={() => {
              setLeavePageopen(true);
            }}
          >
            <BackArrow />
          </IconButton>
          <h5 className={classes.headerTitle}>Waiver</h5>
          <Button
            color="inherit"
            className={classes.HomeButton}
            component={Link}
            to={ROUTES.VANGUARD}
          >
            Home
          </Button>
        </Toolbar>
      </AppBar>

      <Card
        className={clsx(
          classes.MainCard,
          questionnaire_meta.length === 0 && activeStep === 2
            ? 'VanguardStepperQuestionnaire-new'
            : null
        )}
      >
        <JBVanguardStepper
          steps={steps}
          activeStep={activeStep}
          handleBack={handleBack}
          handleNext={handleNext}
          handleReset={handleReset}
          // eslint-disable-next-line react/jsx-no-bind
          handleStepContent={getStepContent}
          isLoading={false}
          hasErrored={false}
          isBackDisabled={btnDisalbed}
          backLabel="Back"
          prevLabel="Sign"
          nextLabel={activeStep === 0 ? 'Next' : 'Next'}
          processing={isProcessing}
        />
      </Card>
      <LeavePagePopup
        setOpen={LeavePageopen}
        handleClose={() => setLeavePageopen(false)}
        // handleHistory={ROUTES.VANGUARD}
      />
      <SuccessPopup
        Dialogtitle="Waiver signed successfully"
        setOpen={Sussopen}
        handleClose={() => setSussopen(false)}
      />
    </Box>
  );
};

WaiverNew.propTypes = {
  classes: PropType.object.isRequired
};
export default withStyles(styles)(WaiverNew);
