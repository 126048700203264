export const AllAttendanceLmt = [
  { id: 1, name: 'Limited' },
  { id: 0, name: 'Unlimited' }
];
export const AllExpiration = [
  { id: 1, name: 'Expiration' },
  { id: 0, name: 'No Expiration' }
];
export const AllDayType = [
  { id: 'day', name: 'Day' },
  { id: 'week', name: 'Week' },
  { id: 'month', name: 'Month' }
];

export const AllRevenCtgry = [
  { id: 1, name: 'Membership Sales' },
  { id: 2, name: 'Private Coaching' },
  { id: 3, name: 'Supplement Sales' }
];

export const AllSessionlmt = [
  { id: 1, name: 'Expiration' },
  { id: 0, name: 'No Expiration' }
];

export const SessionDay = [
  { id: 'day', name: 'Days' },
  { id: 'week', name: 'Weeks' },
  { id: 'month', name: 'Months' },
  { id: 'year', name: 'Years' }
];
