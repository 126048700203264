/* eslint-disable operator-linebreak */
import React, { useCallback, useState, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Grid,
  withStyles,
  Card,
  Breadcrumbs,
  Typography,
  Box,
  InputBase,
  FormControlLabel,
  Paper
} from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
import IOSSwitch from 'src/views/common/FormControl/IOSSwitch';
import Pagination from '@material-ui/lab/Pagination';
import { getMembersValue } from 'src/Redux/ReportsReducer/reports.reducer.actions';
import LoadingView from 'src/views/common/loading';
import { debounce } from 'lodash';
import filterType from 'src/Redux/Reducer.constants';
import { exportValueMember } from 'src/services/reports.services';
import { useSelector, connect } from 'react-redux';
import CancelDialogTwo from 'src/views/common/CommonDialogBox/CancelDialogTwo';
import useChangeTitle from 'src/views/common/CustomHooks/useTitleChange';
import JBButton from '../../common/JBButton/JBButton';
import ImportIcon from '../../../assets/image/upload.png';
import ValueOfmemberTable from './AllTables/ValueOfmemberTable/ValueOfmemberTable';
import styles from './FinancialReports.style';

const ValueOfmember = (props) => {
  const { data: data_reports } = useSelector(
    (state) => state.reportsReducer.reportList
  );
  useChangeTitle('Value of Each Member | Jungle Alliance');
  const {
    classes,
    ValueTags,
    ValueMemberFilter,
    ValueSort,
    ValueSearch,
    customFilter,
    defaultValues
  } = props;
  const { isFetching, data, error } = useSelector(
    (state) => state.reportsReducer.financialReports.membersValue
  );
  const [search, setSearch] = useState(ValueSearch);
  const [open, setOpen] = useState(false);
  const [activeUser, setActiveUser] = useState(() => defaultValues.user_status);
  const [unpaidInvoice, setUnpaidInvoice] = useState(
    () => ValueTags.filter((l) => l.filter === 'invoice_status')[0]
  );
  const SearchQuery = useCallback(
    debounce((q) => ValueMemberFilter(filterType.search, q), 1500),
    []
  );
  const userActive = useCallback(
    debounce((q) => {
      if (q) {
        ValueMemberFilter(filterType.addcustom, [
          { user_status: '1' },
          { id: 1000000000003, name: 'Active Members', filter: 'user_status' }
        ]);
      } else {
        ValueMemberFilter(filterType.addcustom, [
          { user_status: '0' },
          { id: 1000000000003, name: 'Inactive Members', filter: 'user_status' }
        ]);
      }
    }, 1000),
    []
  );
  const invoiceStatus = useCallback(
    debounce((q) => {
      if (q) {
        ValueMemberFilter(filterType.addcustom, [
          { invoice_status: '1' },
          {
            id: 1000000000004,
            name: 'Unpaid invoices',
            filter: 'invoice_status'
          }
        ]);
      } else {
        ValueMemberFilter(filterType.addcustom, [
          { invoice_status: '0' },
          {
            id: 1000000000004,
            name: 'Paid invoices',
            filter: 'invoice_status'
          }
        ]);
      }
    }, 1000),
    []
  );
  const setDirection = (name) => {
    if (ValueSort.sort_name === name) {
      return ValueSort.sort_by === 'asc' ? 'asc' : 'desc';
    }
    return 'asc';
  };
  const isActive = (sortlabel) => {
    if (ValueSort.sort_name === sortlabel) return true;
    return false;
  };
  const setSort = (sortlabel) => {
    ValueMemberFilter(filterType.sorting, sortlabel);
  };
  const Paginate = (val) => {
    if (val !== data.paginationData.current_page) {
      return ValueMemberFilter(filterType.page, val);
    }
    return null;
  };
  const handleReset = () => {
    ValueMemberFilter(filterType.resetFilter);
    if (!activeUser) {
      setActiveUser(
        () => ValueTags.filter((l) => l.filter === 'user_status')[0]
      );
    }
    if (unpaidInvoice) {
      setUnpaidInvoice(
        () => !ValueTags.filter((l) => l.filter === 'invoice_status')[0]
      );
    }
    setSearch('');
  };
  const isStarred = data_reports?.financial_report.filter(
    (report) => report.id === 13
  )[0].is_starred;
  useEffect(() => {
    return () => {
      handleReset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (!data_reports || !data) return <LoadingView />;
  return (
    <div className={classes.root}>
      <div className="title-bk">
        <Box css={{ marginBottom: '20px' }}>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={0}
          >
            <Grid item xs={9}>
              <Box display="inline-flex">
                <Breadcrumbs aria-label="breadcrumb">
                  <div color="inherit">Reports</div>
                  <div
                    color="inherit"
                    onClick={() => setOpen(true)}
                    style={{
                      cursor: 'pointer'
                    }}
                  >
                    financial reports
                  </div>
                  <Typography variant="h3" color="Primary">
                    <span>value of each member</span>
                  </Typography>
                </Breadcrumbs>
              </Box>
              <Box
                display="inline-flex"
                ml="10px"
                css={{ verticalAlign: 'text-bottom' }}
              >
                {isStarred === 1 && (
                  <StarIcon
                    style={{
                      color: '#FFC700'
                    }}
                  />
                )}
              </Box>
            </Grid>
            <Grid
              item
              xs={3}
              style={{
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              <JBButton
                type="outlined"
                isImg={ImportIcon}
                component={Link}
                style={{ marginLeft: '10px' }}
                disabled={data?.paginationData.data.length === 0}
                onClick={(e) => {
                  e.preventDefault();
                  exportValueMember({
                    search: ValueSearch,
                    sort_by: ValueSort.sort_by,
                    sort_name: ValueSort.sort_name,
                    ...customFilter
                  });
                }}
              >
                Export
              </JBButton>
            </Grid>
          </Grid>
        </Box>
      </div>

      <Card className={classes.mainCard}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={0}
        >
          <Grid item xs={6}>
            <Grid container direction="row" alignItems="center" spacing={0}>
              <Grid item xs={9}>
                <div className={classes.search}>
                  <InputBase
                    fullWidth
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                      SearchQuery(e.target.value);
                    }}
                    placeholder="Search…"
                    classes={{
                      root: classes.inputRoot,
                      input: classes.searchInput
                    }}
                    InputProps={{
                      'aria-label': 'search'
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs>
                <JBButton
                  type="outlined"
                  onClick={handleReset}
                  style={{ marginLeft: '10px' }}
                >
                  reset
                </JBButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} justify="flex-end" className="MuiGrid-container">
            <Box
              mr="20px"
              css={{
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                marginRight: '20px'
              }}
            >
              <FormControlLabel
                control={<IOSSwitch />}
                checked={Boolean(activeUser)}
                onChange={(e) => {
                  setActiveUser((prev) => !prev);
                  userActive(
                    e.target.checked,
                    ValueTags.filter((l) => l.filter === 'user_status')[0]
                  );
                }}
                label="Active members only"
                style={{ margin: '0 0' }}
              />
            </Box>
            <Box
              mr="20px"
              css={{
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                marginRight: '20px'
              }}
            >
              <FormControlLabel
                control={<IOSSwitch />}
                checked={Boolean(unpaidInvoice)}
                onChange={(e) => {
                  setUnpaidInvoice((prev) => !prev);
                  invoiceStatus(
                    e.target.checked,
                    ValueTags.filter((l) => l.filter === 'invoice_status')[0]
                  );
                }}
                label="Include unpaid invoices"
                style={{ margin: '0 0' }}
              />
            </Box>
          </Grid>
        </Grid>

        {isFetching || !data ? (
          <LoadingView />
        ) : error ? (
          <p>{error}</p>
        ) : (
          <Fragment>
            <Grid item xs={12}>
              <Box mt="25px" />
              <ValueOfmemberTable
                summary={data?.summary}
                values={data?.paginationData.data}
                setDirection={setDirection}
                isActive={isActive}
                setSort={setSort}
              />
            </Grid>
            {data?.paginationData.data.length !== 0 && (
              <Grid
                item
                xs={12}
                container
                direction="row"
                alignItems="center"
                style={{ marginTop: 24 }}
              >
                <Grid item xs={6} justify="flex-start">
                  <Paper elevation={0} style={{ color: '#A9A9A9' }}>
                    {data?.paginationData.from &&
                    data?.paginationData.to > 1 ? (
                      <Paper elevation={0} style={{ color: '#A9A9A9' }}>
                        {`${data?.paginationData.from} - ${data?.paginationData.to} of ${data?.paginationData.total}`}
                      </Paper>
                    ) : null}
                  </Paper>
                </Grid>
                <Grid
                  item
                  xs={6}
                  justify="flex-end"
                  style={{ display: 'flex' }}
                >
                  <Pagination
                    count={data?.paginationData.last_page}
                    color="primary"
                    variant="outlined"
                    page={data?.paginationData.current_page}
                    onChange={(_, val) => Paginate(val)} //eslint-disable-line
                    shape="rounded"
                  />
                </Grid>
              </Grid>
            )}
          </Fragment>
        )}
      </Card>
      <CancelDialogTwo setOpen={open} handleClose={() => setOpen(false)} />
    </div>
  );
};
const mapToState = (state) => ({
  ValueTags: state.reportsReducer.financialReports.membersValueFil.tags,
  ValueSort: state.reportsReducer.financialReports.membersValueFil.sorting,
  ValueSearch: state.reportsReducer.financialReports.membersValueFil.search,
  customFilter:
    state.reportsReducer.financialReports.membersValueFil.customFilter,
  defaultValues: state.reportsReducer.financialReports.membersValueFil.default
});
const mapToDispatch = (dispatch) => {
  return {
    ValueMemberFilter: (filter, data) => dispatch(getMembersValue(filter, data))
  };
};
ValueOfmember.propTypes = {
  classes: PropTypes.object.isRequired,
  ValueTags: PropTypes.array.isRequired,
  ValueMemberFilter: PropTypes.func.isRequired,
  ValueSort: PropTypes.object.isRequired,
  ValueSearch: PropTypes.string.isRequired,
  customFilter: PropTypes.object.isRequired,
  defaultValues: PropTypes.object.isRequired
};

export default connect(
  mapToState,
  mapToDispatch
)(withStyles(styles)(ValueOfmember));
