import authhttp from './authHttp.services';

export const getMembership = async (val) => {
  const MembershipUrl = 'api/membership';
  const {
    data: { data }
  } = await authhttp.get(MembershipUrl, {
    params: {
      ...val
    }
  });
  return data;
};

export const getAllContracts = async () => {
  const contractUrl = 'api/contracts';
  const {
    data: {
      data: { data }
    }
  } = await authhttp.get(contractUrl, {
    params: {
      filter_by_status: 1,
      status_operator: 1,
      filter_by_visibility: 1,
      visibility_operator: 1
    }
  });
  return data;
};

export const createMemTemplate = async (values) => {
  const createMemUrl = 'api/membership';
  const { data } = await authhttp.post(createMemUrl, values);
  return data;
};

export const getMemberDetail = async (id) => {
  const editMemUrl = `api/membership/${id}/edit`;
  const {
    data: { data }
  } = await authhttp.get(editMemUrl);
  return data;
};

export const updateMemTemp = async (values, id) => {
  const updateMemUrl = `api/membership/${id}`;
  const data = await authhttp.put(updateMemUrl, values);
  return data;
};

export const getMemSetting = async () => {
  const SetMem = 'api/settings/membership/edit';
  const {
    data: {
      data: { meta }
    }
  } = await authhttp.get(SetMem);
  return meta;
};

export const getMemHoldData = async (id) => {
  const SetMem = 'api/user_membership_hold/' + id + '/edit';
  const {
    data: { data }
  } = await authhttp.get(SetMem);
  return data;
};

export const getMemholdType = async () => {
  const holdtypeUrl = 'api/membership_hold_types';
  const {
    data: { data }
  } = await authhttp.get(holdtypeUrl);
  return data;
};

export const addMemHold = async (value) => {
  const addholdUrl = 'api/membership_hold_types';
  const {
    data: { data }
  } = await authhttp.post(addholdUrl, {
    label: value,
    status: 1
  });
  return data;
};
export const updateMemHold = async (values, id) => {
  const updateMemHoldUrl = `api/membership_hold_types/${id}`;
  const {
    data: { data }
  } = await authhttp.put(updateMemHoldUrl, values);
  return data;
};

export const getAllMembership = async (values) => {
  const editMemUrl = 'api/getallmembership';
  const {
    data: { data }
  } = await authhttp.get(editMemUrl, {
    params: values
  });
  return data;
};
export const getAllVanguardMembership = async (values) => {
  const url = 'api/getallmembershipVg';
  const {
    data: { data }
  } = await authhttp.get(url, {
    params: values
  });
  return data;
};
export const createContract = async () => {
  const createContractUrl = 'api/contracts/create';
  const {
    data: { data }
  } = await authhttp.get(createContractUrl);
  return data;
};

export const createNewContract = async (values) => {
  const createNewContractUrl = 'api/contracts';
  const { data } = await authhttp.post(createNewContractUrl, values);
  return data;
};

export const DelMembership = async (id) => {
  const Delurl = `api/membership/${id}`;
  const data = await authhttp.del(Delurl);
  return data;
};

export const getmempayments = async (id) => {
  const paymentUrl = 'api/membership_payment';
  const {
    data: { data }
  } = await authhttp.get(paymentUrl, {
    params: {
      membership_id: id
    }
  });
  return data;
};

export const getMempaymentdata = async (id) => {
  const paymentUrl = 'api/user_member/create';
  const {
    data: { data }
  } = await authhttp.get(paymentUrl, {
    params: {
      membership_payment_id: id
    }
  });
  return data;
};

export const getMembershipMember = async (values) => {
  const membershipUrl = 'api/getall_members_membership';
  const {
    data: { data }
  } = await authhttp.post(membershipUrl, values);
  return data;
};

export const getAddMemberProgram = async (user_membership_id, pid) => {
  const addProgramUrl = 'api/add_programe_membership';
  const data = await authhttp.post(addProgramUrl, { user_membership_id, pid });
  return data;
};

export const removeProgramMember = async (user_membership_id, pid) => {
  const removeProgramUrl = 'api/remove_programe_membership';
  const data = await authhttp.post(removeProgramUrl, {
    user_membership_id,
    pid
  });
  return data;
};
export const getAllPaymentPlans = async () => {
  const PlanUrl = 'api/getPaymentPlansForReports';
  const {
    data: { data }
  } = await authhttp.get(PlanUrl);
  return data;
};
