const Meta = (meta, Gymloc, allTaxes) => {
  const result = [];
  for (let i = 0; i < meta.length; i += 1) {
    let b = {
      name: meta[i].name,
      location: null,
      tax: allTaxes.filter((res) => res.id === meta[i].tax_id)[0],
      initial_commitment_pricing: {
        full_setup_fees: meta[i].initial_commitment_pricing.full_setup_fees,
        full_membership_fees:
          meta[i].initial_commitment_pricing.full_membership_fees
      }
    };
    if (meta[i].location_id) {
      b = {
        ...b,
        location: Gymloc.filter((res) => res.id === meta[i].location_id)[0]
      };
    }
    result.push(b);
  }
  return result;
};

// eslint-disable-next-line
export const getEditTemp = (data, Gymloc, RevenCat, taxRates) => {
  // eslint-disable-next-line
  let allTax = [],
    allGyms = [],
    allRevens = [];
  if (Gymloc) {
    allGyms = [...Gymloc];
  }
  if (RevenCat) {
    allRevens = [...RevenCat];
  }
  if (taxRates) {
    allTax = [...taxRates];
  }
  const result = {
    membership_type: 'drop_in',
    name: data.name,
    reve_cat_id: allRevens.filter((res) => res.id === data.reve_cat_id)[0],
    attendance_limit: 0,
    description: data.description,
    program_ids: data.program_ids
      .split(',')
      .map((value) => parseInt(value, 10)),
    attendance_limited_meta: {
      uses: 0,
      type: null
    },
    meta: Meta(data.membership_payment, allGyms, allTax)
  };

  return result;
};
