import React, { createContext, useReducer } from 'react';
import PropType from 'prop-types';
import {
  initialValue,
  MemberProfileReducer
} from './MemberProfileReducer/MemProfile.reducer';

export const MemProfile = createContext();

export const MemberProContext = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(MemberProfileReducer, initialValue);
  return (
    <MemProfile.Provider value={[state, dispatch]}>
      {children}
    </MemProfile.Provider>
  );
};

MemberProContext.propTypes = {
  children: PropType.node.isRequired
};
