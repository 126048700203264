import React, { useState } from 'react';
import PropType from 'prop-types';
import { ROUTES } from 'src/constant/Constants';
import { Link } from 'react-router-dom';
import {
  Button,
  AppBar,
  Toolbar,
  IconButton,
  withStyles,
  Card,
  Grid,
  Box
} from '@material-ui/core';
import useChangeTitle from 'src/views/common/CustomHooks/useTitleChange';
import { ReactComponent as BackArrow } from '../../../assets/image/Vanguard/icon_back_vanguard.svg';
import { ReactComponent as ArrowRight } from '../../../assets/image/Vanguard/Varrow-right.svg';
import waiverNew from '../../../assets/image/Vanguard/waiver-new.png';
import ReturningImg from '../../../assets/image/Vanguard/returning.png';
import ActivememberImg from '../../../assets/image/Vanguard/activemember.png';
import LeavePagePopup from '../LeavepagePopup';
import styles from './waiverIndex.style';

const WaiverIndex = ({ classes }) => {
  const [LeavePageopen, setLeavePageopen] = useState(false);
  useChangeTitle('Waiver | Jungle Alliance');
  return (
    <div>
      <AppBar className={classes.appBar}>
        <Toolbar className={classes.appToolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="close"
            onClick={() => {
              setLeavePageopen(true);
            }}
          >
            <BackArrow />
          </IconButton>
          <h5 className={classes.headerTitle}>Waiver</h5>
          <Button
            color="inherit"
            className={classes.HomeButton}
            component={Link}
            to={ROUTES.VANGUARD}
          >
            Home
          </Button>
        </Toolbar>
      </AppBar>

      <Card
        className={classes.MainCard}
        style={{
          height: 'calc(100vh - 75px )'
        }}
      >
        <Grid
          container
          xs={10}
          justify="center"
          style={{
            margin: '50px auto'
          }}
        >
          <Button
            className={classes.mainList}
            component={Link}
            to={ROUTES.VANGUARD_WAIVER_NEW}
            style={{
              backgroundImage: `url('${waiverNew}')`
            }}
          >
            <Box>
              <h4 className={classes.mainTitle}>NEW</h4>
            </Box>
            <Box className={classes.mainArrowRight}>
              <ArrowRight />
            </Box>
          </Button>

          <Button
            className={classes.mainList}
            component={Link}
            to={{
              pathname: ROUTES.VANGUARD_WAIVER_RETURNING,
              state: { type: 'on_board' }
            }}
            style={{
              backgroundImage: `url('${ReturningImg}')`
            }}
          >
            <Box>
              <h4 className={classes.mainTitle}>ONBOARDING MEMBER</h4>
            </Box>
            <Box className={classes.mainArrowRight}>
              <ArrowRight />
            </Box>
          </Button>

          <Button
            className={classes.mainList}
            component={Link}
            to={{
              pathname: ROUTES.VANGUARD_WAIVER_RETURNING,
              state: { type: 'active' }
            }}
            style={{
              backgroundImage: `url('${ActivememberImg}')`
            }}
          >
            <Box>
              <h4 className={classes.mainTitle}>ACTIVE MEMBER</h4>
            </Box>
            <Box className={classes.mainArrowRight}>
              <ArrowRight />
            </Box>
          </Button>
        </Grid>
      </Card>
      <LeavePagePopup
        setOpen={LeavePageopen}
        handleClose={() => setLeavePageopen(false)}
        handleHistory={ROUTES.VANGUARD}
      />
    </div>
  );
};

WaiverIndex.propTypes = {
  classes: PropType.object.isRequired
};
export default withStyles(styles)(WaiverIndex);
