export const TobeFilter = [
  { id: 1, filter: 'Trend' },
  { id: 2, filter: 'Average Weekly Attendance Less Than' }
];

export const TrendFilter = [
  { id: 'up', name: 'Up' },
  { id: 'down', name: 'Down' },
  { id: 'no_change', name: 'No Change' },
  { id: 'no_attendance', name: 'No Attendance' },
  { id: 'new_member', name: 'New Member' },
  { id: 0, name: 'All' }
];
