export default {
  membershipDetails: 'MEMBERS_REPORT_MEMBERSHIP_DETAILS',
  membershipDetailsFilter: 'MEMBERS_REPORT_MEMBERSHIP_DETAILS_FILTER',
  expireMembership: 'MEMBERS_REPORT_EXPIRE_MEMBERSHIP',
  expireMembershipFilter: 'MEMBERS_REPORT_EXPIRE_MEMBERSHIP_FILTER',
  membersMembershipType: 'MEMBERS_REPORT_MEMBERS_MEMBERSHIP_TYPE',
  membersMembershipTypeFilter: 'MEMBERS_REPORT_MEMBERS_MEMBERSHIP_TYPE_FILTER',
  membersPaymentPlan: 'MEMBERS_REPORTS_MEMBERS_PAYMENT_PLAN',
  membersPaymentPlanFilter: 'MEMBERS_REPORTS_MEMBERS_PAYMENT_PLAN_FILTER',
  membersLastAttendance: 'ATTENDANCE_LAST_ATTENDANCE',
  membersLastAttendanceFil: 'ATTENDANCE_LAST_ATTENDANCE_FILTER',
  membersAttendanceHistory: 'ATTENDANCE_ATTENDANCE_HISTORY',
  membersAttendanceHistoryFil: 'ATTENDANCE_ATTENDANCE_HISTORY_FILTER',
  attendanceSummaryinitalFil: 'ATTENDANCE_ATTENDANCE_INITIAL_FILTER',
  attendanceSummary: 'ATTENDANCE_ATTENDANCE_SUMMARY',
  allinvoicereports: 'FINANCIAL_ALL_INVOICE_REPORTS',
  allinvoicereportsFilter: 'FINANCIAL_ALL_INVOICE_REPORTS_Filter',
  revenuebymembership: 'FINANCIAL_REVENUE_BY_MEMBERSHIP',
  revenuebymembershipFilter: 'FINANCIAL_REVENUE_BY_MEMBERSHIP_FILTER',
  overdueaccount: 'FINANCIAL_OVERDUE_ACCOUNT_RECIEVABLE',
  overdueaccountFilter: 'FINANCIAL_OVERDUE_ACCOUNT_RECIEVABLE_FILTER',
  expirecreditcards: 'MASTERHQ_EXPIRING_CARDS',
  expirecreditcardsFil: 'MASTERHQ_EXPIRING_CREDIT_CARDS_FILTER',
  revenueCategory: 'FINANCIAL_REVENUE_CATEGORY',
  revenueCategoryFil: 'FINANCIAL_REVENUE_CATEGORY_FILTER',
  valueEeachMember: 'FINANCIAL_VALUE_EACH_MEMBER',
  valueEeachMemberFil: 'FINANCIAL_VALUE_EACH_MEMBER_FILTER',
  attendanceTrends: 'MEMBERS_ATTENDANCE_TRENDS',
  attendanceTrendsFilter: 'MEMBERS_ATTENDANCE_TRENDS_FILTER',
  membershipsOnHold: 'MEMBERS_MEMBERSHIPS_ON_HOLD',
  membershipsOnholdFilter: 'MEMBERS_MEMBERSHIPS_ON_HOLD_FILTER',
  leadInsights: 'LEADS_LEADS_INSIGHTS',
  leadInsightsFil: 'LEADS_LEADS_INSIGHTS_FILTER',
  isFetching: 'REPORTS_FETCHING_START',
  fetchSucess: 'REPORTS_FETCHED_SUCCESSFUL',
  Errorfetch: 'REPORTS_OCCURED_WHEN_FETCHING',
  getreportList: 'GET_REPORT_LIST',
  getUserHistory: 'GET_USER_HISTORY',
  userHistoryFil: 'USER_HISTORY_FILTER',
  revenuePerMonth: 'FINANCIAL_REVENUE_PER_MONTH',
  revenuePerMonthFil: 'FINANCIAL_REVENUE_PER_MONTH_FILTER'
};
