import { createMuiTheme, colors } from '@material-ui/core';
import shadows from './shadows';
import typography from './typography';

const jungleTheme = createMuiTheme({
  palette: {
    background: {
      dark: '#f9f9fa',
      default: colors.common.white,
      paper: colors.common.white
    },
    primary: {
      main: '#01847A'
    },
    secondary: {
      main: colors.indigo[500]
    },
    text: {
      primary: colors.blueGrey[900],
      secondary: colors.blueGrey[600]
    }
  },
  // masterHQ theme
  masterhq: {
    root: {
      backgroundColor: '#1b1c30'
    },
    mainCard: {
      background: '#26273B'
    }
  },
  // end masterHQ
  overrides: {
    MuiPagination: {
      root: {},
      MuiButton: {
        '&$selected': {
          backgroundColor: '#1976d2'
        },
        selected: {
          backgroundColor: '#1976d2'
        }
      }
    },
    MuiPaginationItem: {
      root: {
        fontWeight: 700,
        color: '#000',
        '&$outlinedPrimary': {
          '&$selected': {
            backgroundColor: '#37847a',
            color: '#fff',
            '&:hover': {
              backgroundColor: '#37847a',
              color: '#fff'
            }
          }
        }
      }
    },
    MuiInput: {
      root: {
        fontSize: 16,
        color: '#7F7F7F'
      },
      underline: {
        '&:after': {
          borderBottom: '1px'
        },
        '&:before': {
          borderBottom: '1px'
        },
        '&.MuiAutocomplete-inputRoot': {
          '& input': {
            '&[type="text"]': {
              border: '0px solid #f44336'
            }
          }
        },
        '&.Mui-error': {
          '& input': {
            '&[type="text"]': {
              border: '1px solid #f44336'
            }
          }
        },
        '&:hover:not(.Mui-disabled):before': {
          display: 'none'
        },
        '&:hover:not(.Mui-disabled):after': {
          display: 'none'
        }
      }
    },
    MuiInputLabel: {
      root: {
        color: '#7F7F7F',
        fontWeight: 'bold',
        '&$focused': {
          color: '#01847A'
        }
      }
    },
    MuiFormControlLabel: {
      label: {
        fontSize: '14px',
        color: '#292929',
        fontWeight: 'bold',
        lineHeight: '18px'
      }
    },
    MuiStepLabel: {
      active: {
        color: '#292929 !important'
      },
      label: {
        fontWeight: 'bold',
        fontSize: '14px',
        lineHeight: '18px',
        color: '#DBDBDB'
      }
    },
    MuiStepConnector: {
      vertical: {
        padding: '0 0',
        marginLeft: '18px'
      }
    },
    MuiAutocomplete: {
      option: {
        fontSize: '14px',
        color: '#000'
        // '&[data-focus="true"]': {
        //   backgroundColor: 'red'
        // }
      },
      underline: {
        '&.Mui-error': {
          '& input': {
            border: '0px solid #f44336'
          }
        }
      }
    },
    MuiTab: {
      root: {
        maxWidth: '100%'
      }
    },
    MuiFormHelperText: {
      root: {
        '&.MuiFormHelperText-root.Mui-error': {
          position: 'absolute',
          bottom: '-20px'
        }
      }
    },
    MuiMenuItem: {
      root: {
        color: '#545454',
        fontSize: '14px',
        fontWeight: '700',
        paddingTop: '10px',
        paddingBottom: '10px',
        '&:hover': {
          color: '#01847A',
          backgroundColor: 'rgba(1 132 122 / 0.1)'
        }
      }
    },
    // All Search Box in table
    MuiInputBase: {
      input: {
        '&::placeholder': {
          color: '#01847A',
          fontSize: '14px',
          fontWeight: '700',
          opacity: '1'
        }
      }
    },
    // end
    MuiBreadcrumbs: {
      root: {
        color: '#949494',
        textTransform: 'uppercase',
        fontSize: '20px',
        lineHeight: '24px',
        fontWeight: '700'
      },
      ol: { flexWrap: 'inherit' },
      li: {
        '& a': {
          color: '#949494',
          textTransform: 'uppercase',
          fontSize: '20px',
          lineHeight: '24px'
        },
        '& h3': {
          margin: '0 0'
        }
      },
      separator: {
        marginLeft: '12px',
        marginRight: '12px'
      }
    },
    MuiButton: {
      root: {
        borderRadius: '2px'
      }
    },
    MuiFormLabel: {
      asterisk: {
        color: '#01847A'
        // '&:before': {
        //   content: '""',
        //   position: 'absolute',
        //   background: '#37847a',
        //   borderRadius: '50%',
        //   width: '4px',
        //   height: '4px',
        //   marginLeft: '2px'
        // }
      }
    }
  },
  shadows,
  typography
});

export default jungleTheme;
