import React, { useState, useEffect } from 'react';
// import { makeStyles } from '@material-ui/styles';
import PropType from 'prop-types';
import { connect } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import {
  Grid,
  Card,
  Typography,
  InputLabel,
  Breadcrumbs
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { openNoti } from 'src/Redux/global/global.actions';
import { getTime, getValuefromObject } from 'src/utils/someCommon';
import useChangeTitle from 'src/views/common/CustomHooks/useTitleChange';
import { updateSetting, getSetting } from 'src/services/WODmodule.services';
import { ROUTES } from '../../constant/Constants';
import JBButton from '../common/JBButton/JBButton';
import CustomAutoComplete from '../calendar/commonAutocomplete';
import dayData from './Wod.Data';
import styles from './CreateWOD/Wod.style';
import './style.css';
import JBTimePicker from '../common/FormControl/JBTimePicker';

const WodSettings = ({ classes, OpenNoti }) => {
  const [state, setState] = useState({
    day: null,
    time: new Date()
  });
  useChangeTitle('Workout Settings | Jungle Alliance');
  const history = useHistory();
  const handleSave = () => {
    if (!state.day || !state.time) {
      OpenNoti('Please fill all the required fields', 'error');
    } else {
      const meta = {
        days: getValuefromObject(state.day),
        hours: getTime(state.time)
          .split(' ')[0]
          .split(':')[0],
        minutes: getTime(state.time)
          .split(' ')[0]
          .split(':')[1],
        type: getTime(state.time).split(' ')[1]
      };
      const val = {
        module_name: 'workout',
        meta: JSON.stringify(meta)
      };
      updateSetting(val)
        .then(() => history.push('/app/wod'))
        .then(() => OpenNoti('Successfully Updated your Setting', 'info'))
        .catch(() => OpenNoti('Server Error', 'error'));
    }
  };
  useEffect(() => {
    getSetting()
      .then((res) => {
        setState({
          day: dayData().filter((i) => i.name === res.meta.days_before)[0],
          time: new Date().setHours(res.meta.hour, res.meta.min)
        });
      })
      .catch((err) => console.warn(err));
  }, []);

  return (
    <div className={classes.root}>
      <div className="title-bk">
        {/* <h3>
          Workouts <label>/</label> <span>Settings</span>
        </h3> */}
        <Breadcrumbs
          aria-label="breadcrumb"
          style={{
            margin: '0px 0 21px'
          }}
        >
          <Link color="inherit" to={ROUTES.WORKOUT_LIST}>
            Workouts
          </Link>
          <Typography variant="h3" color="primary">
            <span>Settings</span>
          </Typography>
        </Breadcrumbs>
        <div>
          <h1>Workout Settings</h1>
          <JBButton
            type="primary"
            style={{ float: 'right' }}
            onClick={handleSave}
          >
            Save
          </JBButton>
        </div>
      </div>
      <Card className={classes.mainCard}>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
        >
          <Grid item xs={12}>
            <Typography
              // color="#000"
              variant="h5"
              component="div"
              className={classes.title}
            >
              Publishing Rules
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <div>
              <p
                style={{
                  fontWeight: 'normal',
                  fontSize: '14px',
                  lineHeight: '18px',
                  color: '#7F7F7F',
                  marginTop: '20px'
                }}
              >
                Decide when your members can see the workout within Jungle.
                Note: Your current timezone is set to (GMT +10:00) Canbera,
                Melbourne, Sydney.
              </p>
            </div>
          </Grid>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            spacing={2}
          >
            <Grid item xs={12} style={{ marginTop: 20, marginBottom: 0 }}>
              <h4>Select when workout should be published in Jungle.</h4>
            </Grid>

            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs={2}>
                <InputLabel
                  htmlFor="age-required"
                  className={classes.cutom_select_Label}
                >
                  &nbsp;
                </InputLabel>
                <CustomAutoComplete
                  style={{ marginbottom: '0' }}
                  Options="name"
                  holder="Select Day"
                  value={dayData()}
                  data={state.day}
                  seterrors={state.day === null}
                  seterrortext="Please select day"
                  Change={(val) => setState({ ...state, day: val })}
                />
              </Grid>

              <span
                style={{
                  padding: '10px 15px',
                  position: 'relative',
                  marginTop: '15px'
                }}
              >
                days before at
              </span>

              <Grid item xs={4} style={{ margin: '0 0 !important' }}>
                <JBTimePicker
                  label=""
                  value={state.time}
                  onChange={(val) => setState({ ...state, time: val })}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};

WodSettings.propTypes = {
  classes: PropType.object.isRequired,
  OpenNoti: PropType.func.isRequired
};

const mapToDispatch = (dispatch) => ({
  OpenNoti: (msg, type) => dispatch(openNoti(msg, type))
});

export default connect(null, mapToDispatch)(withStyles(styles)(WodSettings));
