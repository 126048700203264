import { ROUTES } from 'src/constant/Constants';

// eslint-disable-next-line import/prefer-default-export
export const getActiveVal = (pathname) => {
  switch (pathname) {
    case ROUTES.MASTER_HQ_AFFILIATES:
      return 0;
    case ROUTES.MASTER_HQ_AFFILIATES_INACTIVE:
      return 1;
    case ROUTES.MASTER_HQ_AFFILIATES_ALL:
      return 2;
    default:
      return 0;
  }
};
