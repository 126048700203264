import { paymentDisType } from './paymentReducer';

export const changeName = (payload) => ({
  type: paymentDisType.name,
  payload
});

export const changeLoc = (payload) => ({
  type: paymentDisType.location,
  payload
});

export const changeTax = (payload) => ({
  type: paymentDisType.changeTax,
  payload
});

export const initialComminit = (payload) => ({
  type: paymentDisType.initialCommini,
  payload
});

export const initialCommitbill = (payload) => ({
  type: paymentDisType.initialCommbill,
  payload
});

export const changeAutorenew = () => ({
  type: paymentDisType.autorenew
});

export const changerenewrenewal = (payload) => ({
  type: paymentDisType.autorenewren,
  payload
});

export const changerenewbill = (payload) => ({
  type: paymentDisType.autorenewbill,
  payload
});

export const chngeInitialpriceWeek = (payload) => ({
  type: paymentDisType.initialpriceweek,
  payload
});

export const chngeInitialpricemem = (payload) => ({
  type: paymentDisType.initialpricemem,
  payload
});

export const chngeInitialFull = (payload) => ({
  type: paymentDisType.initialpricefull,
  payload
});

export const chngeInitialFullmem = (payload) => ({
  type: paymentDisType.initialpricefullmem,
  payload
});

export const chngeAutorenweek = (payload) => ({
  type: paymentDisType.autorenweek,
  payload
});

export const chngeAutorenfull = (payload) => ({
  type: paymentDisType.autorenfull,
  payload
});
