import React, { useState, useEffect } from 'react';
import PropType from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import {
  Grid,
  Box,
  Button,
  Card,
  InputLabel,
  Breadcrumbs
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import queryString from 'query-string';
import { Link } from 'react-router-dom';
import FormControl from 'src/views/common/FormControl/formControl';
import JBDatePicker from 'src/views/common/FormControl/JBDatePicker';
import {
  editWOD,
  getClassWOD,
  updateWOD
} from 'src/services/WODmodule.services';
import {
  getValuefromObject,
  getIDfromClass,
  changeDatereverse,
  setBackDate
} from 'src/utils/someCommon';
import usePrevious from 'src/views/common/CustomHooks/usePrevious';
import NotFoundView from 'src/views/errors/NotFoundView';
import { checkValidString } from 'src/utils/validations';
import CancelDialog from 'src/views/common/CommonDialogBox/CancelDialog';
import { getErrorMsg } from 'src/utils/ErrorHandling';
import LoadingView from 'src/views/common/loading';
import useChangeTitle from 'src/views/common/CustomHooks/useTitleChange';
import JBCKEditor from 'src/views/common/JBCKEditor';
// import JBSunEditorControl from 'src/views/common/SunEditor/SunEditor';
import Table from './editTable';
import {
  FavWOD,
  getWOD,
  getWODTags
} from '../../../Redux/WODReducers/wodReducer.actions';
import {
  setPriTags,
  setSecTags,
  getUnisuggest,
  getTagfromStr,
  checkEditWOD,
  getEditData
} from '../WOD.utils';
import CustomAutoComplete from '../../calendar/commonAutocomplete';
import CustomTags from '../../common/CustomTags/CustomTags';
import { openNoti } from '../../../Redux/global/global.actions';
import { ROUTES } from '../../../constant/Constants';
import CancelDialogTwo from '../../common/CommonDialogBox/CancelDialogTwo';
import styles from './detailView.style';

const WodEditView = (props) => {
  // First State Initialize
  // props restructure
  const {
    classes,
    location,
    programs: { isFetching, programs },
    OpenNotify,
    wodTags,
    getTags
  } = props;

  const [data, setData] = useState({});
  const [cancelDialog, setcancelDialog] = useState(false);
  const [errorFecth, setErrorFetch] = useState(false);
  const { workout } = queryString.parse(location.search);
  useChangeTitle('Edit Workout | Jungle Alliance');
  // Call when component Mounted
  useEffect(() => {
    editWOD(workout)
      .then(
        (res) =>
          setData({
            ...res,
            workout_details: res.workout_details.replace(/\\/g, ''),
            coach_notes: res.coach_notes
              ? res.coach_notes?.replace(/\\/g, '')
              : null,
            date: setBackDate(res.date),
            class_id: getIDfromClass(res.class_list),
            tags: getTagfromStr(res.tags),
            secondary_tags: getTagfromStr(res.secondary_tags)
          })
        // eslint-disable-next-line function-paren-newline
      )
      .catch(() => setErrorFetch(true));
  }, []); //eslint-disable-line

  const prevList = usePrevious(data.class_id);
  useEffect(() => {
    if (data.date && data.programe) {
      if (prevList) {
        getClassWOD(data.programe, changeDatereverse(data.date))
          .then(
            (res) =>
              setData({
                ...data,
                class_id: [],
                class_list: res.data
              })
            // eslint-disable-next-line function-paren-newline
          )
          .catch(() => setData({ ...data, class_list: null, class_id: [] }));
      } else {
        getClassWOD(data.programe, changeDatereverse(data.date))
          .then(
            (res) =>
              setData({
                ...data,
                class_list: res.data
              })
            // eslint-disable-next-line function-paren-newline
          )
          .catch(() => setData({ ...data, class_list: null, class_id: [] }));
      }
    }
  }, [data.programe, data.date]); //eslint-disable-line

  const handleAddition = (values, type) => {
    if (type === 'primary') {
      const { error, data: tagsdata } = setPriTags(
        data.tags,
        data.secondary_tags,
        values
      );
      if (error) {
        OpenNotify(error, 'error');
      } else {
        setData({
          ...data,
          tags: tagsdata
        });
      }
    } else {
      const { error, data: tagsdata } = setSecTags(
        data.secondary_tags,
        data.tags,
        values
      );
      if (error) {
        OpenNotify(error, 'error');
      } else {
        setData({
          ...data,
          secondary_tags: tagsdata
        });
      }
    }
  };

  const handleDeletion = (i, type) => {
    if (type === 'primary') {
      setData({
        ...data,
        tags: data.tags.filter((tag, index) => index !== i)
      });
    } else {
      setData({
        ...data,
        secondary_tags: data.secondary_tags.filter((tag, index) => index !== i)
      });
    }
  };

  const preLoadData = (
    <div className="title-bk">
      <div style={{ marginBottom: 15 }}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={0}
        >
          <Grid item xs={12}>
            <Breadcrumbs aria-label="breadcrumb">
              <div color="inherit">Workouts</div>
              <div
                color="inherit"
                onClick={() => setcancelDialog(true)}
                style={{
                  cursor: 'pointer'
                }}
              >
                List
              </div>
              <Typography variant="h3" color="primary">
                <span>{data ? data.name : 'Workout Name'}</span>
              </Typography>
            </Breadcrumbs>
          </Grid>

          <Grid item xs={6} className={classes.btnBlock} />
        </Grid>
      </div>
    </div>
  );
  // Login View when state is null
  if (!workout || errorFecth) return <NotFoundView />;
  if (Object.keys(data).length === 0 || isFetching) {
    return (
      <div className={classes.root}>
        {preLoadData}
        <LoadingView />
      </div>
    );
  }
  const {
    name,
    class_list,
    coach_notes,
    programe,
    date,
    workout_details //eslint-disable-line
  } = data;

  // for Material UI component
  const programData = programs.filter((prog) => prog.id === programe)[0];

  // When Saving the Data
  const handleSave = () => {
    if (!checkEditWOD(data)) {
      if (data.class_id.length === 0) {
        OpenNotify('Please Select Class', 'error');
      } else OpenNotify('Please fill all the fields', 'error');
    } else if (checkValidString(data.name)) {
      OpenNotify('Please enter valid Workout name', 'error');
    } else {
      const { history, reloadWOD, reloadFavWOD } = props;
      const values = getEditData(data);
      updateWOD(values, data.id)
        .then(() => {
          reloadWOD();
          reloadFavWOD();
          getTags();
        })
        .then(() => history.push(ROUTES.WORKOUT_LIST))
        .then(() => OpenNotify('Workout Saved Successfully', 'info'))
        .catch((err) => OpenNotify(JSON.stringify(getErrorMsg(err)), 'error'));
    }
  };

  // testing console.log

  // const handleChange = (val) => {
  // setData({ ...data, workout_details: val });
  // };

  return (
    <div className={classes.root}>
      {preLoadData}
      <Card className={classes.mainCard}>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
        >
          <Grid item xs>
            <Typography
              // color="#000"
              variant="h3"
              component="div"
              className={classes.title}
            >
              Edit {name}
            </Typography>
            <Grid
              container
              direction="row"
              spacing={2}
              alignItems="center"
              style={{ marginBottom: '8px' }}
            >
              <Grid item xs={6} style={{ marginleft: '0px' }}>
                <FormControl
                  control="input"
                  label="Workout name"
                  placeholder="Workout"
                  value={name}
                  onChange={(e) => setData({ ...data, name: e.target.value })}
                  seterrors={checkValidString(name)}
                  seterrortext="Please Enter valid Workout Name"
                  required
                />
              </Grid>
              <Grid item xs={3} style={{ marginTop: '7px' }}>
                <JBDatePicker
                  label="Date"
                  required
                  value={date}
                  onChange={(val) => setData({ ...data, date: val })}
                />
              </Grid>
              <Grid item xs={3} style={{ marginTop: '3px' }}>
                <InputLabel
                  htmlFor="age-required"
                  className={classes.cutom_select_Label}
                  required
                >
                  Program
                </InputLabel>
                <CustomAutoComplete
                  holder="Select Program"
                  value={programs}
                  data={programData}
                  Options="name"
                  Change={(val) =>
                    setData({ ...data, programe: getValuefromObject(val) })
                  } //eslint-disable-line
                  seterrors={!programData}
                  seterrortext="Please Select the Program"
                  style={{ marginbottom: '0' }}
                />
              </Grid>
            </Grid>
            {class_list ? ( //eslint-disable-line
              <Table
                values={class_list} //eslint-disable-line
                setSelect={data.class_id}
                getSelected={(val) => setData({ ...data, class_id: val })}
              />
            ) : (
              <Grid item xs={12} style={{ marginTop: '30px' }}>
                <h3>There are no classes for this program for this date</h3>
                <Button
                  variant="outlined"
                  color="primary"
                  component={Link}
                  to={ROUTES.CLASSES_CREATE_NEW_CLS}
                  style={{
                    width: '131px',
                    height: '48px',
                    marginTop: '30px'
                  }}
                >
                  Create class
                </Button>
              </Grid>
            )}
            <Grid
              item
              xs={12}
              style={{ marginTop: '20px', marginBottom: '20px' }}
            >
              <InputLabel
                htmlFor="age-required"
                className={classes.cutom_select_Label}
              >
                Primary tags
              </InputLabel>
              <CustomTags
                autofocus
                tags={data.tags}
                suggestions={getUnisuggest(
                  wodTags,
                  data.secondary_tags,
                  data.tags
                )}
                handleAddition={(val) => handleAddition(val, 'primary')} // eslint-disable-line
                handleDelete={(i) => handleDeletion(i, 'primary')} // eslint-disable-line
              />
            </Grid>
            <Grid item xs={12} style={{ marginBottom: '20px' }}>
              <InputLabel
                htmlFor="age-required"
                className={classes.cutom_select_Label}
              >
                Secondary tags
              </InputLabel>
              <CustomTags
                autofocus={false}
                tags={data.secondary_tags}
                suggestions={getUnisuggest(
                  wodTags,
                  data.tags,
                  data.secondary_tags
                )}
                handleAddition={(val) => handleAddition(val, 'secondary')} // eslint-disable-line
                handleDelete={(i) => handleDeletion(i, 'secondary ')} // eslint-disable-line
              />
              {/* <MultipleTag holder="Tags. Use tags to organise your library of workouts." /> */}
            </Grid>
            <Grid item xs={12} style={{ marginBottom: '20px' }}>
              <Box display="block" mt={3}>
                {/* <Typography
                  color="#000"
                  variant="h5"
                  component="div"
                  className={classes.title}
                  style={{ marginBottom: '20px' }}
                >
                  Workout details
                </Typography> */}
              </Box>
              <InputLabel
                htmlFor="age-required"
                required
                className={classes.cutom_select_Label}
              >
                Workout details
              </InputLabel>
              <JBCKEditor
                data={workout_details}
                onChange={(e) =>
                  setData({ ...data, workout_details: e.editor.getData() })
                }
              />
              {/* <JBSunEditorControl
                defaultValue={workout_details}
                onChange={handleChange}
                // value={workout_details} //eslint-disable-line
                // handleChange={(val) =>
                //   setData({ ...data, workout_details: val })
                // }
                // appendContents={workout_details}
                setError={!checkQuillValid(data.workout_details)}
                ErrorText="Please enter valid workout details"
              /> */}
            </Grid>
            <Grid item xs={12} style={{ marginBottom: '20px' }}>
              <InputLabel
                htmlFor="age-required"
                className={classes.cutom_select_Label}
              >
                Coaches note
              </InputLabel>
              <JBCKEditor
                data={coach_notes}
                onChange={(e) =>
                  setData({ ...data, coach_notes: e.editor.getData() })
                }
              />
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
          >
            <CancelDialog />
            <Button
              color="primary"
              className={classes.button}
              type="submit"
              onClick={handleSave}
            >
              <span>Save</span>
            </Button>
          </Grid>
        </Grid>
        <CancelDialogTwo
          setOpen={cancelDialog}
          handleHistory={ROUTES.WORKOUT_LIST}
          handleClose={() => setcancelDialog(false)}
        />
      </Card>
    </div>
  );
};

WodEditView.defaultProps = {
  wodTags: []
};

WodEditView.propTypes = {
  classes: PropType.object.isRequired,
  programs: PropType.object.isRequired,
  location: PropType.any.isRequired,
  history: PropType.any.isRequired,
  reloadWOD: PropType.func.isRequired,
  reloadFavWOD: PropType.func.isRequired,
  OpenNotify: PropType.func.isRequired,
  wodTags: PropType.array,
  // wodTags: PropType.array.isRequired
  getTags: PropType.func.isRequired
};
const mapToState = (state) => ({
  programs: state.allprograms,
  wodTags: state.wodList.tags
});
const mapToDispatch = (dispatch) => ({
  OpenNotify: (msg, type) => dispatch(openNoti(msg, type)),
  reloadWOD: () => dispatch(getWOD()),
  reloadFavWOD: () => dispatch(FavWOD()),
  getTags: () => dispatch(getWODTags())
});

export default connect(
  mapToState,
  mapToDispatch
)(withStyles(styles)(WodEditView));
