import React, { useEffect, useState, useRef } from 'react';
// import { Link } from 'react-router-dom';
import PropType from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  withStyles,
  Grid,
  Paper
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import { getClassHistoryOfAUser } from 'src/services/reports.services';
import dcloseicon from '../../../../../../assets/image/closeicon.png';
import ClassHistoryTable from './ClassHistoryTable';
// import { DummyValues } from './dummyData';

const styles = () => ({
  dtitle: {
    color: '#292929',
    fontWeight: 'bold',
    fontSize: '33px',
    lineHeight: 'normal'
  },
  cutom_select_Label: {
    fontSize: '12px',
    padding: '7px 0'
  },
  titlebk: {
    display: 'inline-block',
    padding: '25px 24px 10px'
  }
});

const rowsPerPage = 25;

function ClassHistoryDialog({
  classes,
  // handleHistory,
  handleClose,
  setOpen,
  user
  // ...props
}) {
  const [data, setData] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [direction, setDirection] = useState('asc');
  // switch is a boolean that triggers re-render
  const [sort, setSort] = useState({ sort_name: '', switch: true });
  const [page, setPage] = useState(1);
  const tableRef = useRef();

  useEffect(() => {
    if (user) {
      setIsFetching(true);
      getClassHistoryOfAUser({
        user_id: user.id,
        sort_name: sort.sort_name,
        sort_by: direction
      })
        .then((res) => {
          setData(res);
          setIsFetching(false);
        })
        .catch((error) => {
          setIsFetching(false);
          console.log(error);
        });
    }
  }, [user, sort, direction]);

  const handleSort = (name) => {
    setSort((prevState) => ({ sort_name: name, switch: !prevState.switch }));
    setDirection(direction === 'asc' ? 'desc' : 'asc');
  };

  const handleDirection = (name) => {
    if (sort.sort_name === name) {
      return direction;
    }
    return 'asc';
  };

  const handleIsActive = (sortlabel) => {
    if (sort.sort_name === sortlabel) return true;
    return false;
  };

  const handlePageChange = (event, value) => {
    setPage(value);
    tableRef.current.scrollTo(0, 0);
  };

  const slicedData = data?.slice(
    (page - 1) * rowsPerPage,
    (page - 1) * rowsPerPage + rowsPerPage
  );

  return (
    <div>
      <Dialog
        open={setOpen}
        onClose={() => handleClose()}
        fullWidth
        maxWidth="lg"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div>
          <Grid alignItems="center" container direction="row">
            <Grid item xs>
              <DialogTitle id="alert-dialog-title" className={classes.titlebk}>
                <h4 className={classes.dtitle}>
                  {user?.member}'s Class History {/* eslint-disable-line */}
                </h4>
              </DialogTitle>
            </Grid>
            <Grid
              item
              xs={2}
              style={{ textAlign: 'right', marginRight: '10px' }}
            >
              <Button className={classes.btns} onClick={handleClose}>
                <img alt="img" src={dcloseicon} />
              </Button>
            </Grid>
          </Grid>

          <DialogContent>
            <Grid container>
              <Grid item xs={12}>
                <ClassHistoryTable
                  tableRef={tableRef}
                  values={slicedData}
                  isFetching={isFetching}
                  setSort={handleSort}
                  isActive={handleIsActive}
                  setDirection={handleDirection}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions
            style={{
              padding: '20px 24px'
            }}
          >
            <Grid
              item
              xs={12}
              container
              direction="row"
              alignItems="center"
              style={{ marginTop: 24 }}
            >
              <Grid item xs={6} justify="flex-start">
                {data && data.length !== 0 && (
                  <Paper elevation={0} style={{ color: '#A9A9A9' }}>
                    {(page - 1) * rowsPerPage + 1}-
                    {slicedData.length + (page - 1) * rowsPerPage} of{' '}
                    {data.length}
                  </Paper>
                )}
              </Grid>
              <Grid item xs={6} justify="flex-end" style={{ display: 'flex' }}>
                {data && data.length !== 0 && (
                  <Pagination
                    count={Math.ceil(data.length / rowsPerPage)}
                    color="primary"
                    variant="outlined"
                    page={page}
                    onChange={handlePageChange}
                    shape="rounded"
                  />
                )}
              </Grid>
            </Grid>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}
ClassHistoryDialog.defaultProps = {
  handleHistory: null
};

ClassHistoryDialog.propTypes = {
  classes: PropType.object.isRequired,
  handleHistory: PropType.string, // eslint-disable-line
  setOpen: PropType.bool.isRequired,
  handleClose: PropType.func.isRequired,
  user: PropType.any.isRequired
};
export default withStyles(styles)(ClassHistoryDialog);
