import React from 'react';
import PropType from 'prop-types';

const CustomAgent = (props) => {
  const { event } = props;
  return (
    <div
      style={{
        right: '250px',
        position: 'sticky',
        padding: '7.5px'
      }}
    >
      <div className="CustomAgent">
        <span
          style={{
            backgroundColor: event.color,
            width: '10px',
            height: '10px',
            display: 'inline-block',
            borderRadius: '50%',
            marginRight: '10px'
          }}
        />
        <label
          className="CustomAgentlabel"
          style={{ textDecoration: event.status === 0 && 'line-through' }}
        >
          {event.class_name}
        </label>
        <div className="coachname">
          {event.head_coach ? event.head_coach.name : null}
        </div>
      </div>
    </div>
  );
};

CustomAgent.propTypes = {
  event: PropType.object.isRequired
};

export default CustomAgent;
