import React, { memo } from 'react';
import { withStyles } from '@material-ui/core';
import DatePicker from 'react-datepicker';
import useGymSettingRefresh from 'src/views/common/CustomHooks/useGymSettingRefresh';
import PropType from 'prop-types';
import 'react-datepicker/dist/react-datepicker.css';
import styles from './TimePicker.style';

const CustomDatePicker = (props) => {
  const { classes, value, Change } = props;
  const { pickerDate } = useGymSettingRefresh();
  const InvalidateInput = (
    { value, onClick } // eslint-disable-line
  ) => (
    <div
      onClick={onClick}
      style={{ color: 'black', fontWeight: '600', cursor: 'pointer' }}
    >
      {value}
    </div>
  );
  return (
    <DatePicker
      selected={value}
      onChange={(date) => Change(date)}
      className={classes.Dateinput}
      dateFormat={pickerDate}
      customInput={<InvalidateInput />}
      {...props} // eslint-disable-line
    />
  );
};

CustomDatePicker.propTypes = {
  classes: PropType.object.isRequired,
  value: PropType.object.isRequired,
  Change: PropType.func.isRequired,
  onClick: PropType.func.isRequired
};

export default withStyles(styles)(memo(CustomDatePicker));
