export default {
  page: 'PAGE',
  sorting: 'SORTING',
  search: 'SEARCH',
  addcustom: 'ADD_CUSTOM_FILTER',
  removecustom: 'REMOVE_CUSTOM_FILTER',
  resetFilter: 'RESET_FILTER',
  sortname: 'name',
  sortday: 'date',
  sorttime: 'time',
  sortstartdate: 'start_date',
  sortexpiredate: 'expires_date',
  sort_auto_renew: 'auto_renew',
  sort_sign_status: 'sign_status',
  sort_wod_day: 'wod_date',
  sortlimiteddata: 'limited_data',
  sortholdstart: 'hold_start_date',
  sortholdend: 'hold_end_date',
  sortlabel: 'label',
  sortprogram: 'program_name',
  sortbyprogram: 'program',
  sortduration: 'duration',
  sortstarttime: 'start_time',
  sortendtime: 'end_time',
  sortlocation: 'location',
  sortfirstname: 'first_name',
  sort_member: 'member_name',
  sort_contract: 'contract_name',
  sort_membership: 'membership_name',
  sort_member_status: 'member_status',
  sort_date_issued: 'date_issued',
  sort_waiver_name: 'waiver_name',
  sort_date_signed: 'date_signed',
  sort_visibility: 'visibility',
  sort_updated: 'updated',
  sortemail: 'email',
  sortstatus: 'status',
  sort_invoiceno: 'nvoice_no',
  sort_product_name: 'product_name',
  sort_autobill: 'auto_bill',
  sort_final_charge: 'final_charge',
  sort_payment_due: 'payment_due',
  sortmembershiptype: 'memberships_type',
  sortlastattendance: 'last_attendance',
  sort_payment_method: 'payment_method',
  sortlead_status: 'lead_status',
  sortcreated_on: 'created_on',
  sortlast_contact_days: 'last_contact_days',
  sortdesc: 'description',
  sortdate: 'date',
  sort_activity_type: 'activity_type',
  sort_subject: 'subject',
  sort_created_by: 'created_by',
  sort_members: 'member',
  sort_member_since: 'member_since',
  sort_membership_location: 'membership_location',
  sort_membership_type: 'memberships_type',
  sort_memberships: 'membership',
  sort_payment_plan: 'payment_plan',
  sort_commitment_total_for_memDeTable: 'commitment_total',
  sort_commitment_total: 'commitement_total',
  sort_renew_total: 'renew_total',
  sort_detail: 'detail',
  sort_attendance_limit: 'attendance_limit_data',
  sort_class_attendance: 'class_attended',
  sort_week_since: 'week_since',
  sort_invoice: 'invoice',
  sort_invoice_status: 'invoice_status',
  sort_member_name: 'member',
  sort_exp_revenue: 'exp_revenue',
  sort_total_discounts: 'total_discounts',
  sort_refunded_amount: 'refunded_amount',
  sort_net_revenue: 'net_revenue',
  sort_final_tax: 'final_tax',
  sort_unpaid_amount: 'unpaid_amount',
  sort_location_name: 'location_name',
  sort_aging: 'aging',
  sort_billing_days: 'billing_days',
  sort_commitment_type: 'commitment_type',
  sort_auto_renew_type: 'auto_renew_type',
  sort_user_status: 'user_status',
  sort_gym_name: 'gym_name',
  sort_lastfourweek: 'lastfourweek',
  sort_percentage_change: 'percentage_change',
  sort_trend: 'trend',
  sort_members_name: 'members_name',
  sort_hold_start_date: 'hold_start_date',
  sort_hold_end_date: 'hold_end_date',
  sort_class_name: 'class_name',
  sort_class_location: 'class_location',
  sort_class_programe: 'class_programe',
  sort_class_start_date: 'class_start_date',
  sort_class_start_date_time: 'class_start_date_time',
  sort_card_number: 'card_number',
  sort_card_expiry: 'card_expiry',
  sort_prev_2_month: 'last2month',
  sort_prev_month: 'prev_month',
  sort_curr_month: 'curr_month',
  sort_lifetime_value: 'lifetime_value',
  sort_revenue_category: 'revenue_category',
  sort_quantity: 'quantity',
  sort_sub_total: 'sub_total',
  sort_subtotal: 'subtotal',
  sort_expected_revenue: 'expected_revenue',
  sort_archieve_date: 'archieve_date',
  sort_default_payment_method: 'default_payment_method',
  sort_coach_name: 'head_coach_name',
  sort_invoice_no: 'invoice_no',
  sort_allies_name: 'allies_name',
  sort_final_amount: 'final_amount',
  sort_due_date: 'due_date',
  sort_default_method: 'default_method_str',
  sort_affiliate_gym_name: 'affiliate_gym_name',
  sort_realtime_max_active_users: 'realtime_max_active_users',
  sort_verall_max_active_users: 'overall_max_active_users',
  sort_affiliate_since: 'affiliate_since',
  sort_payment_method_name: 'payment_method_name',
  sort_class_date: 'class_date',
  sort_class: 'class',
  sort_is_active: 'is_active'
};
