import React, { useState, useContext } from 'react';
import { ROUTES } from 'src/constant/Constants';
import { VanguardMem } from 'src/Context/VanguardMembershipContext';
import { Link, useHistory } from 'react-router-dom';
import {
  makeStyles,
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Card
} from '@material-ui/core';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { openNoti } from 'src/Redux/global/global.actions';
import JBVanguardStepper from 'src/views/common/JBVanguardStepper';
import { updateVanguardUser } from 'src/services/vanguard.services';
import { updateUserContract } from 'src/services/Contracts.services';
import { getErrorMsg } from 'src/utils/ErrorHandling';
import {
  validStep7,
  validStep9,
  verifyInfo,
  updateUserData,
  getContractData
} from '../Vanguard.utills';
import { ReactComponent as BackArrow } from '../../../../assets/image/Vanguard/icon_back_vanguard.svg';
import LeavePagePopup from '../../LeavepagePopup';
import PersonalInfo from './personalInfo';
import Terms from './terms';
import PaymentTerms from './PaymentTerms';
import SuccessPopup from './successPopup';
import Sign from './sign';

function getSteps() {
  return ['1', '2', '3', '4'];
}

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    background: 'rgba(29, 29, 29, 0.94)',
    boxShadow: '0px 0.33333px 0px rgb(255 255 255 / 15%)',
    backdropFilter: 'blur(54.3656px)'
  },
  headerTitle: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '26px'
  },
  appToolbar: {
    minHeight: '75px',
    justifyContent: 'space-between'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
  mainTitle: {
    fontWeight: '500',
    fontSize: '48px',
    lineHeight: '44px',
    color: '#FFFFFF',
    fontFamily: 'DDC Hardware',
    textAlign: 'center',
    margin: '38px 0 0 0',
    '@media (max-width: 992px)': {
      fontSize: '35px'
    }
  },
  MainCard: {
    height: 'calc(100vh - 75px )',
    background:
      'linear-gradient(180deg, rgba(41, 41, 41, 0.95) 0%, rgba(4, 4, 4, 0.95) 100%)',
    borderRadius: '0',
    flex: '1 1 auto',
    // height: '100%',
    overflow: 'auto'
  },
  HomeButton: {
    fontFamily: 'Lato',
    color: '#2CE9DA',
    fontWeight: 'bold',
    fontSize: '22px',
    lineHeight: '32px',
    textTransform: 'capitalize'
  }
}));

const SignContract = () => {
  const history = useHistory();
  const classes = useStyles();
  const [LeavePageopen, setLeavePageopen] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [Sussopen, setSussopen] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [state, dispatch] = useContext(VanguardMem);
  const reduxDispatch = useDispatch();
  const {
    memebership_data: {
      membershipdata: { user_contract }
    },
    personal_info: { user_details },
    user_id
  } = state;
  const steps = getSteps();
  const [activeStep, setActiveStep] = useState(0);
  function getStepContent(step) {
    switch (step) {
      case 0:
        return <PersonalInfo Maintitle="Personal Information" />;
      case 1:
        return <PaymentTerms />;
      case 2:
        return <Terms />;
      case 3:
        return <Sign />;
      default:
        return 'Unknown step';
    }
  }

  const handleNext = () => {
    if (activeStep === 0) {
      setProcessing(true);
      updateVanguardUser(updateUserData(user_details, user_id))
        .then(() => {
          setProcessing(false);
          reduxDispatch(openNoti('member updated successfully'));
          setActiveStep((prev) => prev + 1);
        })
        .catch((err) => {
          setProcessing(false);
          reduxDispatch(openNoti(getErrorMsg(err), 'error'));
        });
    } else if (activeStep === 2) {
      if (
        // eslint-disable-next-line operator-linebreak
        user_contract.sign_initials !== user_contract.policy_initials
      ) {
        reduxDispatch(openNoti('Initials must be the same', 'error'));
      } else {
        setActiveStep((prev) => prev + 1);
      }
    } else if (activeStep === 3) {
      setProcessing(true);
      updateUserContract(
        user_contract.id,
        getContractData(user_details, user_contract)
      )
        .then(() => {
          setProcessing(false);
          setSussopen(true);
        })
        .catch((err) => {
          setProcessing(false);
          reduxDispatch(openNoti(getErrorMsg(err), 'error'));
        });
      // setTimeout(() => history.push(ROUTES.VANGUARD), 1500);
    } else {
      setActiveStep((prev) => prev + 1);
    }
  };
  const handleReset = () => {
    setActiveStep(0);
  };
  const handleBack = () => {
    setActiveStep((prev) => prev - 1);
    if (activeStep === 0) {
      history.goBack();
    }
  };

  return (
    <div>
      <AppBar className={classes.appBar}>
        <Toolbar className={classes.appToolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="close"
            onClick={() => {
              setLeavePageopen(true);
            }}
          >
            <BackArrow />
          </IconButton>
          <h5 className={classes.headerTitle}>Sign Contract</h5>
          <Button
            color="inherit"
            className={classes.HomeButton}
            component={Link}
            to={ROUTES.VANGUARD}
          >
            Home
          </Button>
        </Toolbar>
      </AppBar>
      <Card className={clsx(classes.MainCard)}>
        <JBVanguardStepper
          steps={steps}
          activeStep={activeStep}
          handleBack={handleBack}
          handleNext={handleNext}
          handleReset={handleReset}
          // eslint-disable-next-line react/jsx-no-bind
          handleStepContent={getStepContent}
          processing={processing}
          validateBtn={[
            verifyInfo(user_details),
            validStep7(user_contract),
            validStep7(user_contract),
            validStep9(user_contract)
          ]}
          cancelBack={[0]}
          isLoading={false}
          hasErrored={false}
          backLabel="Back"
          prevLabel="Sign"
          nextLabel="Next"
          // isBackDisabled={btnDisalbed}
          // processing={isProcessing}
        />
      </Card>
      <LeavePagePopup
        setOpen={LeavePageopen}
        handleClose={() => setLeavePageopen(false)}
        handleHistory={ROUTES.VANGUARD}
      />
      <SuccessPopup
        Dialogtitle="Contract successfully signed"
        setOpen={Sussopen}
        handleClose={() => setSussopen(false)}
      />
    </div>
  );
};

export default SignContract;
