export const getValidStep1 = (data) => {
  if (!data.name) {
    return true;
  }
  return false;
};

export const getValidStep2 = (data) => {
  if (!data.membership_policy.policy) {
    return true;
  }
  return false;
};

export const getValidStep3 = (data) => {
  if (!data.email_template.subject || !data.email_template.body) {
    return true;
  }
  return false;
};

export const getValidStep4 = (data) => {
  if (!String(data.visibility)) {
    return true;
  }
  return false;
};
