const styles = () => ({
  root: {
    // marginTop: 30,
    // margin: 20,
    // padding: 20,
    marginTop: 0,
    margin: '0px 20px 20px',
    padding: '0px 20px 20px'
    // root: {
    //   padding: theme.spacing(4)
    // }
  },
  mainCard: {
    boxShadow: '4px 0px 16px rgba(37, 40, 43, 0.08)',
    borderRadius: '5px',
    background: '#FFFF',
    padding: 30,
    overflow: 'visible'
  },
  button: {
    background: '#01847A',
    color: '#FFF',
    padding: '15px 24px',
    borderRadius: '2px;',
    boxShadow: '0px 8px 16px rgba(0, 126, 255, 0.16);',
    float: 'right;',
    '&:hover': {
      backgroundColor: '#01847A'
    }
  },
  colorbox: {
    border: '1px solid #a9a9a9',
    padding: '6px 15px 7px',
    marginTop: '9px',
    maxHeight: '48px',
    minHeight: '48px',
    position: 'relative'
  },
  colorPicker: {
    zIndex: '99',
    position: 'relative'
  },
  colorPickermenu: {
    padding: '10px 7px',
    background: '#FFFFFF',
    boxShadow: '0px 0px 8px rgba(37, 40, 43, 0.12)',
    borderRadius: '2px',
    marginTop: '4px',
    width: '222px',
    zIndex: '99',
    position: 'relative'
  },
  customColorpic: {},
  action_btn: {
    border: '1px solid #01847A',
    boxSizing: 'border-box',
    borderRadius: '2px',
    width: '115px',
    height: '48px',
    margin: '0 0 0 auto',
    color: '#01847A',
    fontWeight: 'bold',
    '&:focus': {
      color: '#01847A'
    }
  },
  Listbutton: {
    width: '100%',
    padding: '11px 16px',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px',
    color: '#545454',
    '&:hover': {
      color: '#01847A',
      backgroundColor: 'rgba(1 132 122 / 0.1)'
    }
  }
});
export default styles;
