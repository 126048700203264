import React from 'react';
import PropType from 'prop-types';
import CreditCard from './CreditCard';
import Cash from './Cash';
import BankAccount from './BankAccount';

const AddPaymentMethodTabs = (props) => {
  const { Tabs, classes, handleClose } = props;

  // eslint-disable-next-line no-unused-vars
  // const [state, dispatch] = useContext(MemProfile);

  // const handlerefresh = (val) => {
  //   const type = types.getpaymentmethods;
  //   dispatch(fetchingInvoice(type));
  //   getPaymentMet(val)
  //     .then((res) => dispatch(fetchComplete(type, res)))
  //     .catch((err) => dispatch(ErrorFetching(type, getErrorMsg(err))));
  // };

  return (
    <div className={classes}>
      {(() => {
        switch (Tabs) {
          case 0:
            return <CreditCard />;
          case 1:
            return <Cash handleClose={handleClose} />;
          case 2:
            return <BankAccount handleClose={handleClose} />;
          default:
            return null;
        }
      })()}
    </div>
  );
};

AddPaymentMethodTabs.propTypes = {
  Tabs: PropType.number.isRequired,
  classes: PropType.object.isRequired,
  handleClose: PropType.func.isRequired
};

export default AddPaymentMethodTabs;
