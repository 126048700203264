import React from 'react';
import PropType from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  withStyles,
  TableSortLabel
} from '@material-ui/core';
import GetPrice from 'src/views/common/CurrencySymbol/GetPrice';
import CheckIcon from 'src/assets/image/check.png';
import filterType from 'src/Redux/Reducer.constants';
import { ROUTES } from 'src/constant/Constants';

const styles = () => ({
  table: {
    minWidth: '100%',
    wordBreak: 'break-word',
    '& td': {
      padding: '10px'
    },
    '@media (max-width: 1366px)': {
      '& td': {
        fontSize: '13px'
      }
    },
    '& .MuiTableCell-head': {
      color: '#FFF'
    },
    '& .MuiTableSortLabel-root:hover': {
      color: '#01847A'
    },
    '& .MuiTableSortLabel-root.MuiTableSortLabel-active': {
      color: '#ffffff'
    },
    '& .MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon': {
      color: '#ffffff'
    },
    '& .MuiTableCell-body': {
      color: '#FFF'
    },
    '& .MuiTableBody-root .MuiTableCell-root': {
      borderBottom: '1px solid rgba(234, 234, 234, 0.2)'
    }
  },
  tableLink: {
    color: '#01847A',
    fontWeight: '700'
  },
  TableCell: {
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px',
    height: '56px',
    minHeight: '56px',
    padding: '10px',
    '@media (max-width: 1366px)': {
      fontSize: '13px',
      wordBreak: 'keep-all'
    }
  },
  Tablemian: {},
  SummaryCell: {
    color: '#292929',
    fontWeight: 'bold'
  }
});

const OverdueAccountTable = (props) => {
  const {
    values,
    classes,
    unpaidamount,
    isActive,
    setDirection,
    setSort
  } = props;
  const {
    sort_invoice_no,
    sort_allies_name,
    sort_payment_due,
    sort_unpaid_amount,
    sort_aging
  } = filterType;
  return (
    <TableContainer
      component={Paper}
      elevation={0}
      className={classes.Tablemian}
      style={{ borderRadius: '0', backgroundColor: 'transparent' }}
    >
      <Table className={classes.table} aria-label="simple table">
        <TableHead style={{ background: '#3B3C4E' }}>
          <TableRow>
            <TableCell className={classes.TableCell}>
              <TableSortLabel
                active={isActive(sort_allies_name)}
                direction={setDirection(sort_allies_name)}
                onClick={() => setSort(sort_allies_name)}
              >
                Member
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell}>Email</TableCell>
            <TableCell className={classes.TableCell}>Phone</TableCell>
            <TableCell className={classes.TableCell}>
              <TableSortLabel
                active={isActive(sort_invoice_no)}
                direction={setDirection(sort_invoice_no)}
                onClick={() => setSort(sort_invoice_no)}
              >
                Invoice
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell} align="center">
              Auto bill
            </TableCell>
            <TableCell className={classes.TableCell} align="right">
              <TableSortLabel
                active={isActive(sort_unpaid_amount)}
                direction={setDirection(sort_unpaid_amount)}
                onClick={() => setSort(sort_unpaid_amount)}
              >
                Unpaid amount
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell} align="right">
              <TableSortLabel
                active={isActive(sort_payment_due)}
                direction={setDirection(sort_payment_due)}
                onClick={() => setSort(sort_payment_due)}
              >
                Payment due
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell} align="right">
              <TableSortLabel
                active={isActive(sort_aging)}
                direction={setDirection(sort_aging)}
                onClick={() => setSort(sort_aging)}
              >
                Aging
              </TableSortLabel>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {unpaidamount && (
            <TableRow
              style={{
                backgroundColor: '#26273B'
              }}
            >
              <TableCell
                style={{
                  width: '9.0909%'
                }}
                className={classes.SummaryCell}
              >
                Summary
              </TableCell>
              <TableCell
                style={{
                  width: '9.0909%'
                }}
                className={classes.SummaryCell}
              >
                &nbsp;
              </TableCell>
              <TableCell
                style={{
                  width: '9%'
                }}
                className={classes.SummaryCell}
              >
                &nbsp;
              </TableCell>
              <TableCell
                style={{
                  width: '9%'
                }}
                className={classes.SummaryCell}
              >
                &nbsp;
              </TableCell>
              <TableCell
                style={{
                  width: '9%'
                }}
                align="right"
                className={classes.SummaryCell}
              >
                &nbsp;
              </TableCell>
              <TableCell
                style={{
                  width: '9%'
                }}
                align="right"
                className={classes.SummaryCell}
              >
                {/* <GetPrice value={unpaidamount} /> */}
                {unpaidamount && <GetPrice value={unpaidamount} />}
              </TableCell>
              <TableCell
                style={{
                  width: '9%'
                }}
                align="right"
                className={classes.SummaryCell}
              >
                &nbsp;
              </TableCell>
              <TableCell
                style={{
                  width: '9%'
                }}
                align="right"
                className={classes.SummaryCell}
              >
                &nbsp;
              </TableCell>
            </TableRow>
          )}

          {values.map((row) => (
            <TableRow
              hover
              tabIndex={-1}
              key={row.invoice_no}
              style={{
                backgroundColor: '#26273B'
              }}
            >
              <TableCell
                style={{
                  width: '9.0909%'
                }}
              >
                <Link
                  className={classes.tableLink}
                  to={{
                    pathname: ROUTES.MASTER_HQ_AFFILIATES_PROFILE,
                    search: `affiliateId=${row.affiliate_id}`
                  }}
                >
                  {row.allies_name}
                </Link>
              </TableCell>
              <TableCell
                style={{
                  width: '9%'
                }}
                className={classes.tableLink}
              >
                {row.email}
              </TableCell>
              <TableCell
                style={{
                  width: '9%'
                }}
                className={classes.tableLink}
              >
                {row.mobile_no}
              </TableCell>
              <TableCell
                style={{
                  width: '9%'
                }}
              >
                <Link
                  className={classes.tableLink}
                  to={{
                    pathname: ROUTES.MASTER_HQ_FINANCIAL_INVOICES_VIEW,
                    search: `?Invoice=${btoa(row.invoice_id)}`,
                    state: row.i_status
                  }}
                >
                  {row.invoice_no}
                </Link>
              </TableCell>
              <TableCell
                style={{
                  width: '9%'
                }}
                align="center"
              >
                {row.auto_bill === 1 && <img src={CheckIcon} alt="img" />}
              </TableCell>
              <TableCell
                style={{
                  width: '9%'
                }}
                align="right"
              >
                <GetPrice value={row.unpaid_amount} />
              </TableCell>
              <TableCell
                style={{
                  width: '9%'
                }}
                align="right"
              >
                {/* {row.payment_due} */}
                {row.payment_due}
              </TableCell>
              <TableCell
                style={{
                  width: '9%'
                }}
                align="right"
              >
                {row.aging}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {values.length === 0 && (
        <p
          style={{
            margin: '10px 15px',
            color: '#fff'
          }}
        >
          No Overdue Account to show...
        </p>
      )}
    </TableContainer>
  );
};
OverdueAccountTable.propTypes = {
  values: PropType.array.isRequired,
  classes: PropType.object.isRequired,
  unpaidamount: PropType.number.isRequired,
  isActive: PropType.func.isRequired,
  setDirection: PropType.func.isRequired,
  setSort: PropType.func.isRequired
};

export default withStyles(styles)(OverdueAccountTable);
