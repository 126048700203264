import React from 'react';
import PropType from 'prop-types';
import { Link } from 'react-router-dom';
import { ROUTES } from 'src/constant/Constants';
import {
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  withStyles,
  TableSortLabel
} from '@material-ui/core';
import CheckIcon from 'src/assets/image/check.png';
import filterType from 'src/Redux/Reducer.constants';

const styles = () => ({
  table: {
    minWidth: '100%',
    wordBreak: 'break-word',
    '& td': {
      padding: '10px'
    },
    '@media (max-width: 1366px)': {
      '& td': {
        fontSize: '13px'
      }
    }
  },
  tableLink: {
    color: '#01847A',
    fontWeight: '700'
  },
  TableCell: {
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px',
    height: '56px',
    minHeight: '56px',
    padding: '10px',
    '@media (max-width: 1366px)': {
      fontSize: '13px',
      wordBreak: 'keep-all'
    }
  },
  Tablemian: {}
});

const MembershipOnHoldTable = (props) => {
  const { values, classes, isActive, setDirection, setSort } = props;
  const {
    sort_members_name,
    sort_membership,
    sort_hold_start_date,
    sort_hold_end_date
  } = filterType;
  return (
    <TableContainer
      component={Paper}
      elevation={0}
      className={classes.Tablemian}
    >
      <Table className={classes.table} aria-label="simple table">
        <TableHead
          style={{ background: '#F9F9FA', backgroundColor: '#F9F9FA' }}
        >
          <TableRow>
            <TableCell className={classes.TableCell}>
              <TableSortLabel
                active={isActive(sort_members_name)}
                direction={setDirection(sort_members_name)}
                onClick={() => setSort(sort_members_name)}
              >
                Member
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell}>
              Membership Location
            </TableCell>
            <TableCell className={classes.TableCell}>
              <TableSortLabel
                active={isActive(sort_membership)}
                direction={setDirection(sort_membership)}
                onClick={() => setSort(sort_membership)}
              >
                Membership
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell}>
              <TableSortLabel
                active={isActive(sort_hold_start_date)}
                direction={setDirection(sort_hold_start_date)}
                onClick={() => setSort(sort_hold_start_date)}
              >
                Hold Start Date
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell}>
              <TableSortLabel
                active={isActive(sort_hold_end_date)}
                direction={setDirection(sort_hold_end_date)}
                onClick={() => setSort(sort_hold_end_date)}
              >
                Hold End Date
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell}>Hold Reason</TableCell>

            <TableCell className={classes.TableCell} align="center">
              Auto renew
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {values
            && values.map((row) => (
              <TableRow
                hover
                tabIndex={-1}
                key={row.id}
                style={{
                  backgroundColor: '#FFF'
                }}
              >
                <TableCell
                  style={{
                    width: '9.0909%'
                  }}
                  // className={classes.tableLink}
                >
                  <Link
                    className={classes.tableLink}
                    to={{
                      pathname: ROUTES.PEOPLE_MEMBER_PROFILE,
                      search: `memberId=${btoa(row.user_id)}`
                    }}
                  >
                    {row?.members_name}
                  </Link>
                </TableCell>
                <TableCell
                  style={{
                    width: '9.0909%'
                  }}
                >
                  {row?.membership_location}
                </TableCell>
                <TableCell
                  style={{
                    width: '9.0909%'
                  }}
                >
                  {row?.membership_name}
                </TableCell>
                <TableCell
                  style={{
                    width: '9.0909%'
                  }}
                >
                  {row?.hold_start_date}
                </TableCell>
                <TableCell
                  style={{
                    width: '9.0909%'
                  }}
                >
                  {row?.hold_end_date}
                </TableCell>
                <TableCell
                  style={{
                    width: '9.0909%',
                    textTransform: 'capitalize'
                  }}
                >
                  {row.hold_reason}
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    width: '7%'
                  }}
                >
                  {row?.auto_renew === 'TRUE' && (
                    <img src={CheckIcon} alt="img" />
                  )}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      {values?.length === 0 && (
        <p
          style={{
            margin: '10px 15px'
          }}
        >
          No Members with Memberships on Hold to show...
        </p>
      )}
    </TableContainer>
  );
};
MembershipOnHoldTable.propTypes = {
  values: PropType.array.isRequired,
  classes: PropType.object.isRequired,
  isActive: PropType.func.isRequired,
  setDirection: PropType.func.isRequired,
  setSort: PropType.func.isRequired
};

export default withStyles(styles)(MembershipOnHoldTable);
