/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { ROUTES } from 'src/constant/Constants';
import { AppBar, Box, Grid, makeStyles, Tab, Tabs, ThemeProvider, Typography } from '@material-ui/core';
import { Link, Route, Switch, useLocation } from 'react-router-dom';
import { get } from 'js-cookie';
import { ReactComponent as TabeditIcon } from '../../assets/image/tabediticon.svg';
import JBButton from '../common/JBButton/JBButton';
import Plusicon from '../../assets/image/plusicon.png';
import MissingTabs from './MissingTabs';
import SignedTab from './SignedTab';
import TemplateTab from './TemplateTab';
import { getActiveVal } from './Documents.utils';
import ErrorUserView from '../errorUserPermission/errorUserView';
// import NewContract from './NewContract';

const themeInstance = {
  overrides: {
    MuiTabs: {
      indicator: {
        backgroundColor: 'transparent'
      }
    },
    MuiTab: {
      root: {
        maxWidth: '100%',
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
        border: '1px solid #EAEAEA',
        borderBottom: '0',
        margin: '0px 5px 0 0',
        '&$selected': {
          backgroundColor: 'white',
          color: '#000 ',
          '&:hover': {
            color: '#37847a'
          },
          '&$textColorPrimary': {
            color: '#01847A'
          },
          '& svg path': {
            stroke: '#01847A'
          }
        }
      },
      wrapper: {
        flexDirection: 'row',
        justifyContent: 'space-around'
      },
      labelIcon: {
        minHeight: '55px'
      },
      textColorPrimary: {
        color: '#545454',
        fontSize: '14px',
        fontWeight: '900',
        lineHeight: '18px',
        letterSpacing: '0.5px'
      }
    }
  }
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other} // eslint-disable-line
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1
  },
  STabPanel: {
    backgroundColor: '#fff',
    boxShadow: '4px 0px 16px rgba(37, 40, 43, 0.08)',
    '& .MuiCard-root.MuiPaper-rounded': {
      boxShadow: 'none',
      padding: '0 10px'
    }
  },
  hr: {
    border: '1px solid rgb(196 196 196 / 0.4)',
    display: 'inline-block',
    width: '100%',
    margin: '10px 0'
  },
  ptext: {
    fontSize: '16px',
    lineHeight: '24px',
    color: '#7F7F7F',
    fontWeight: 'normal'
  },
  cutom_Switch_Label: {
    display: 'inline-block',
    marginRight: '30px',
    verticalAlign: 'middle',
    fontSize: '14px',
    fontWeight: '700',
    color: '#000'
  },
  p_tabs: {
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px'
  }
}));

export default function DocumentsTabs() {
  const classes = useStyles();
  // const { changeTab, changeTabmissing, tabValue, value } = props;
  const location = useLocation();
  const Tabslocation = getActiveVal(location.pathname);
  const roleid = JSON.parse(get('jbrole') || '{}')?.role_id || 3;

  return (
    <div className={classes.root}>
      <ThemeProvider theme={themeInstance}>
        <AppBar
          position="static"
          style={{
            backgroundColor: 'transparent',
            boxShadow: 'none'
          }}
        >
          <Tabs
            value={Tabslocation}
            indicatorColor="primary"
            textColor="primary"
            color="primary"
            fullWidth
            // onChange={(_, e) => changeTab(e)}
            aria-label="simple tabs example"
          >
            <Tab
              label="Missing"
              component={Link}
              to={ROUTES.DOCUMENTS_CONTRACTS}
              icon={<TabeditIcon />}
              {...a11yProps(0)}
            />
            <Tab
              label="signed"
              component={Link}
              to={ROUTES.DOCUMENTS_CONTRACTS_SIGNED}
              icon={<TabeditIcon />}
              {...a11yProps(1)}
            />
            {roleid === 1 && (
              <Tab
                label="Templates"
                component={Link}
                to={ROUTES.DOCUMENTS_CONTRACTS_TEMPLATES}
                icon={<TabeditIcon />}
                {...a11yProps(2)}
              />
            )}
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="flex-start"
              spacing={0}
            >
              <Grid item xs style={{ marginBottom: '10px' }}>
                {roleid === 1 && (
                  <JBButton
                    type="primary"
                    isImg={Plusicon}
                    component={Link}
                    to="/app/newcontract"
                    style={{ float: 'right' }}
                  >
                    new contract
                  </JBButton>
                )}
                {/* <Switch>
                  <Route
                    exact
                    path={ROUTES.DOCUMENTS_CONTRACTS_NEW}
                    component={NewContract}
                  />
                </Switch> */}
              </Grid>
            </Grid>
          </Tabs>
        </AppBar>
      </ThemeProvider>
      <Box className={classes.STabPanel}>
        <Switch>
          <Route
            path={ROUTES.DOCUMENTS_CONTRACTS_TEMPLATES}
            render={() => {
              if (roleid !== 1) {
                return <ErrorUserView />;
              }
              return <TemplateTab />;
            }}
          />
          <Route
            path={ROUTES.DOCUMENTS_CONTRACTS_SIGNED}
            component={SignedTab}
          />
          <Route path={ROUTES.DOCUMENTS_CONTRACTS} component={MissingTabs} />
        </Switch>
      </Box>
    </div>
  );
}

DocumentsTabs.propTypes = {
  tabValue: PropTypes.number.isRequired,
  location: PropTypes.any.isRequired,
  pathname: PropTypes.any.isRequired,
  value: PropTypes.number.isRequired,
  changeTab: PropTypes.func.isRequired,
  changeTabmissing: PropTypes.func.isRequired,
  Recurr: PropTypes.number.isRequired,
  changeRecurr: PropTypes.func.isRequired
};
