import React, { useState } from 'react';
import PropType from 'prop-types';
import { Link } from 'react-router-dom';
import { ROUTES } from 'src/constant/Constants';
import {
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  TableSortLabel,
  Table,
  withStyles
} from '@material-ui/core';
import filterType from 'src/Redux/Reducer.constants';
import moment from 'moment';
import GetPrice from 'src/views/common/CurrencySymbol/GetPrice';
import ValueDialog from './ValueDialog.';
// import CalendarSmallIcon from '../../../../../assets/image/calendarSmall.svg';
import CheckIcon from '../../../../../assets/image/check.png';

const styles = () => ({
  table: {
    minWidth: '100%',
    wordBreak: 'break-word',
    '& td': {
      padding: '10px'
    },
    '@media (max-width: 1366px)': {
      '& td': {
        fontSize: '13px'
      }
    }
  },
  tableLink: {
    color: '#01847A',
    fontWeight: '700'
  },
  TableCell: {
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px',
    height: '56px',
    minHeight: '56px',
    padding: '10px',
    '@media (max-width: 1366px)': {
      fontSize: '13px',
      wordBreak: 'keep-all'
    }
  },
  Tablemian: {},
  SummaryCell: {
    color: '#292929',
    fontWeight: 'bold'
  }
});

const ValueOfmemberTable = (props) => {
  const { values, classes, setDirection, isActive, setSort, summary } = props;
  const {
    sort_member_since,
    sort_members,
    sortlocation,
    sort_prev_2_month,
    sort_prev_month,
    sort_curr_month,
    sort_lifetime_value
  } = filterType;
  const [valueData, setValueData] = useState({
    open: false,
    id: null,
    username: ''
  });
  return (
    <TableContainer
      component={Paper}
      elevation={0}
      className={classes.Tablemian}
    >
      <Table className={classes.table} aria-label="simple table">
        <TableHead
          style={{ background: '#F9F9FA', backgroundColor: '#F9F9FA' }}
        >
          <TableRow>
            <TableCell className={classes.TableCell}>
              <TableSortLabel
                direction={setDirection(sort_members)}
                active={isActive(sort_members)}
                onClick={() => {
                  setSort(sort_members);
                }}
              >
                Member
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell}>
              <TableSortLabel
                direction={setDirection(sort_member_since)}
                active={isActive(sort_member_since)}
                onClick={() => {
                  setSort(sort_member_since);
                }}
              >
                Member since
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell}>
              <TableSortLabel
                direction={setDirection(sortlocation)}
                active={isActive(sortlocation)}
                onClick={() => {
                  setSort(sortlocation);
                }}
              >
                Default locations
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell} align="right">
              <TableSortLabel
                direction={setDirection(sort_lifetime_value)}
                active={isActive(sort_lifetime_value)}
                onClick={() => {
                  setSort(sort_lifetime_value);
                }}
              >
                Lifetime value
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell} align="right">
              <TableSortLabel
                direction={setDirection(sort_prev_2_month)}
                active={isActive(sort_prev_2_month)}
                onClick={() => {
                  setSort(sort_prev_2_month);
                }}
              >
                {moment()
                  .subtract(2, 'months')
                  .format('MMMM')}
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell} align="right">
              <TableSortLabel
                direction={setDirection(sort_prev_month)}
                active={isActive(sort_prev_month)}
                onClick={() => {
                  setSort(sort_prev_month);
                }}
              >
                {moment()
                  .subtract(1, 'months')
                  .format('MMMM')}
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell} align="right">
              <TableSortLabel
                direction={setDirection(sort_curr_month)}
                active={isActive(sort_curr_month)}
                onClick={() => {
                  setSort(sort_curr_month);
                }}
              >
                {moment().format('MMMM')}
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell} align="center">
              Active
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {summary && (
            <TableRow>
              <TableCell
                style={{
                  width: '9.0909%'
                }}
                className={classes.SummaryCell}
              >
                Summary
              </TableCell>

              <TableCell
                style={{
                  width: '9%'
                }}
                className={classes.SummaryCell}
              >
                &nbsp;
              </TableCell>
              <TableCell
                style={{
                  width: '9%'
                }}
                className={classes.SummaryCell}
              >
                &nbsp;
              </TableCell>
              <TableCell
                style={{
                  width: '9%'
                }}
                align="right"
                className={classes.SummaryCell}
              >
                <GetPrice value={summary.lifetime_value} />
              </TableCell>
              <TableCell
                style={{
                  width: '9%'
                }}
                align="right"
                className={classes.SummaryCell}
              >
                <GetPrice value={summary.last2month} />
              </TableCell>
              <TableCell
                style={{
                  width: '9%'
                }}
                align="right"
                className={classes.SummaryCell}
              >
                <GetPrice value={summary.prev_month} />
              </TableCell>
              <TableCell
                style={{
                  width: '9%'
                }}
                align="right"
                className={classes.SummaryCell}
              >
                <GetPrice value={summary.curr_month} />
              </TableCell>
              <TableCell
                style={{
                  width: '9%'
                }}
                className={classes.SummaryCell}
              >
                &nbsp;
              </TableCell>
            </TableRow>
          )}
          {values.map((row) => (
            <TableRow
              hover
              tabIndex={-1}
              key={row.id}
              style={{
                backgroundColor: '#FFF'
              }}
            >
              <TableCell
                style={{
                  width: '9.0909%'
                }}
              >
                <Link
                  className={classes.tableLink}
                  to={{
                    pathname: ROUTES.PEOPLE_MEMBER_PROFILE,
                    search: `memberId=${btoa(row.user_id)}`
                  }}
                >
                  {row.member}
                </Link>
              </TableCell>
              <TableCell
                style={{
                  width: '9%'
                }}
              >
                {row.member_since}
              </TableCell>
              <TableCell
                style={{
                  width: '9%'
                }}
              >
                {row.location}
              </TableCell>
              <TableCell
                style={{
                  width: '9%',
                  cursor: 'pointer'
                }}
                align="right"
                className={classes.tableLink}
                component="div"
                onClick={() => {
                  setValueData({
                    open: true,
                    id: row.user_id,
                    username: row.member
                  });
                }}
              >
                <GetPrice value={row.lifetime_value} />
              </TableCell>
              <TableCell
                style={{
                  width: '9%',
                  cursor: 'pointer'
                }}
                align="right"
                className={classes.tableLink}
                onClick={() => {
                  setValueData({
                    open: true,
                    id: row.user_id,
                    username: row.member,
                    month: moment()
                      .subtract(2, 'months')
                      .format('YYYY-MM')
                  });
                }}
              >
                <GetPrice value={row.last2month} />
              </TableCell>
              <TableCell
                style={{
                  width: '9%',
                  cursor: 'pointer'
                }}
                align="right"
                className={classes.tableLink}
                onClick={() => {
                  setValueData({
                    open: true,
                    id: row.user_id,
                    username: row.member,
                    month: moment()
                      .subtract(1, 'months')
                      .format('YYYY-MM')
                  });
                }}
              >
                <GetPrice value={row.prev_month} />
              </TableCell>
              <TableCell
                style={{
                  width: '9%',
                  cursor: 'pointer'
                }}
                align="right"
                className={classes.tableLink}
                onClick={() => {
                  setValueData({
                    open: true,
                    id: row.user_id,
                    username: row.member,
                    month: moment().format('YYYY-MM')
                  });
                }}
              >
                <GetPrice value={row.curr_month} />
              </TableCell>

              <TableCell
                style={{
                  width: '9%'
                }}
                align="center"
              >
                {row.user_status === 1 && <img src={CheckIcon} alt="img" />}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {values.length === 0 && (
        <p
          style={{
            margin: '10px 15px'
          }}
        >
          No Members to show...
        </p>
      )}
      <ValueDialog
        id={valueData.id}
        month={valueData.month}
        userName={valueData.username}
        setOpen={valueData.open}
        handleClose={() => setValueData({ open: false, id: null })}
      />
    </TableContainer>
  );
};
ValueOfmemberTable.propTypes = {
  values: PropType.array.isRequired,
  classes: PropType.object.isRequired,
  setDirection: PropType.func.isRequired,
  isActive: PropType.func.isRequired,
  setSort: PropType.func.isRequired,
  summary: PropType.any.isRequired
};

export default withStyles(styles)(ValueOfmemberTable);
