import moment from 'moment';
import { AllGender } from '../../people/Members/CreateMember/CreateMember.data';

export const getSignedData = (data, countries) => {
  let country = countries;
  if (!countries) {
    country = [];
  }
  const b = data;
  // eslint-disable-next-line prefer-destructuring
  b.user_details.gender = AllGender.filter(
    (list) => list.id === data.user_details.gender
  )[0];
  b.user_details.country = country.filter((res) => res.code === b.user_details.country)[0] || null;
  b.user_details.dob = moment(b.user_details.dob).toDate();
  b.membership_policy_meta.sign_initials = null;
  b.payment_plan_meta.sign_initials = null;
  return b;
};

export const getSignedSave = (data, sign) => {
  let results = {
    ...data.user_details,
    gender: data.user_details.gender.id,
    country: data.user_details.country.code,
    dob: moment(data.user_details.dob).format('YYYY-MM-DD'),
    payment_plan_terms_user_intial: JSON.stringify({
      sign_initials: data.payment_plan_meta.sign_initials
    }),
    signature: sign,
    membership_policy_user_intial: JSON.stringify({
      sign_initials: null
    })
  };
  if (JSON.parse(data.membership_policy_meta.policy_meta.initial) === 1) {
    results = {
      ...results,
      membership_policy_user_intial: JSON.stringify({
        sign_initials: data.membership_policy_meta.sign_initials
      })
    };
  }
  return results;
};

export const getBilled = (data) => {
  if (!data) {
    return '-';
  }
  const splits = data.split(' ');
  const date = splits[splits.length - 1];

  splits.pop();
  const result = splits.join(' ') + ' ' + moment(date, 'YYYY/MM/DD').format('DD,MMM YYYY');
  return result;
};
