/* eslint-disable function-paren-newline */
/* eslint-disable react/no-unescaped-entities */
import React, { memo, useEffect } from 'react';
import propTypes from 'prop-types';
import queryString from 'query-string';
import { useDispatch } from 'react-redux';
import { sendExpiredLink } from 'src/services/masterhq.allies.services';
import { Box, Container, Typography, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import { ReactComponent as LinkExpired } from 'src/assets/image/LinkExpired.svg';
import { openNoti } from 'src/Redux/global/global.actions';

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  image: {
    marginTop: 10,
    display: 'inline-block',
    maxWidth: '100%',
    width: 560
  }
}));

const LinkExpiredView = (props) => {
  const classes = useStyles();
  const {
    location: { search }
  } = props;
  const dispatch = useDispatch();

  const { status, aff } = queryString.parse(search);

  useEffect(() => {
    if (status && aff) {
      sendExpiredLink(aff)
        .then(() =>
          dispatch(
            openNoti('Successfully send the link please check your mail')
          )
        )
        .catch(() => openNoti('Please refresh the page server issue', 'error'));
    }
  }, [status, aff, dispatch]);

  if (!status || !aff) {
    return null;
  }

  return (
    <Page className={classes.root} title="oops">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="md">
          <Typography align="center" color="textPrimary" variant="h1">
            Oops!! Link expired. Please check your email for a new link.
          </Typography>
          {/* <Typography align="center" color="textPrimary" variant="subtitle2">
            You either tried some shady route or you came here by mistake.
            Whichever it is, try using the navigation
          </Typography> */}
          <Box textAlign="center">
            <LinkExpired className={classes.image} />
          </Box>
        </Container>
      </Box>
    </Page>
  );
};

LinkExpiredView.propTypes = {
  location: propTypes.object.isRequired
};

export default memo(LinkExpiredView);
