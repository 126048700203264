import Types from './MemProfile.types';

export default (type, action, state, data) => {
  switch (type) {
    case Types.unpaidInvoice: {
      const Data = () => {
        switch (action) {
          case Types.isFetching:
            return {
              ...state,
              isFetching: true
            };
          case Types.fetchSucess:
            return {
              ...state,
              isFetching: false,
              error: null,
              data
            };
          case Types.Errorfetch:
            return {
              ...state,
              isFetching: false,
              error: data
            };
          default:
            return state;
        }
      };
      return Data();
    }
    case Types.paidInvoice: {
      const Data = () => {
        switch (action) {
          case Types.isFetching:
            return {
              ...state,
              isFetching: true
            };
          case Types.fetchSucess:
            return {
              ...state,
              isFetching: false,
              error: null,
              data
            };
          case Types.Errorfetch:
            return {
              ...state,
              isFetching: false,
              error: data
            };
          default:
            return state;
        }
      };
      return Data();
    }
    case Types.allInvoice: {
      const Data = () => {
        switch (action) {
          case Types.isFetching:
            return {
              ...state,
              isFetching: true
            };
          case Types.fetchSucess:
            return {
              ...state,
              isFetching: false,
              error: null,
              data
            };
          case Types.Errorfetch:
            return {
              ...state,
              isFetching: false,
              error: data
            };
          default:
            return state;
        }
      };
      return Data();
    }
    case Types.processingInvoice: {
      const Data = () => {
        switch (action) {
          case Types.isFetching:
            return {
              ...state,
              isFetching: true
            };
          case Types.fetchSucess:
            return {
              ...state,
              isFetching: false,
              error: null,
              data
            };
          case Types.Errorfetch:
            return {
              ...state,
              isFetching: false,
              error: data
            };
          default:
            return state;
        }
      };
      return Data();
    }
    case Types.failedInvoice: {
      const Data = () => {
        switch (action) {
          case Types.isFetching:
            return {
              ...state,
              isFetching: true
            };
          case Types.fetchSucess:
            return {
              ...state,
              isFetching: false,
              error: null,
              data
            };

          case Types.Errorfetch:
            return {
              ...state,
              isFetching: false,
              error: data
            };
          default:
            return state;
        }
      };
      return Data();
    }
    case Types.overdueInvoice: {
      const Data = () => {
        switch (action) {
          case Types.isFetching:
            return {
              ...state,
              isFetching: true
            };
          case Types.fetchSucess:
            return {
              ...state,
              isFetching: false,
              error: null,
              data
            };

          case Types.Errorfetch:
            return {
              ...state,
              isFetching: false,
              error: data
            };
          default:
            return state;
        }
      };
      return Data();
    }
    case Types.activeMem: {
      const Data = () => {
        switch (action) {
          case Types.isFetching:
            return {
              ...state,
              isFetching: true
            };
          case Types.fetchSucess:
            return {
              ...state,
              isFetching: false,
              error: null,
              data
            };
          case Types.Errorfetch:
            return {
              ...state,
              isFetching: false,
              error: data
            };
          default:
            return state;
        }
      };
      return Data();
    }
    case Types.inactiveMem: {
      const Data = () => {
        switch (action) {
          case Types.isFetching:
            return {
              ...state,
              isFetching: true
            };
          case Types.fetchSucess:
            return {
              ...state,
              isFetching: false,
              error: null,
              data
            };
          case Types.Errorfetch:
            return {
              ...state,
              isFetching: false,
              error: data
            };
          default:
            return state;
        }
      };
      return Data();
    }
    case Types.getpaymentmethods: {
      const Data = () => {
        switch (action) {
          case Types.isFetching:
            return {
              ...state,
              isFetching: true
            };
          case Types.fetchSucess:
            return {
              ...state,
              isFetching: false,
              error: null,
              data
            };
          case Types.Errorfetch:
            return {
              ...state,
              isFetching: false,
              error: data
            };
          default:
            return state;
        }
      };
      return Data();
    }
    // Activity History tabs
    case Types.getActivityHistory: {
      const Data = () => {
        switch (action) {
          case Types.isFetching:
            return {
              ...state,
              isFetching: true
            };
          case Types.fetchSucess:
            return {
              ...state,
              isFetching: false,
              error: null,
              data
            };
          case Types.Errorfetch:
            return {
              ...state,
              isFetching: false,
              error: data
            };
          default:
            return state;
        }
      };
      return Data();
    }
    case Types.getattendance: {
      const Data = () => {
        switch (action) {
          case Types.isFetching:
            return {
              ...state,
              isFetching: true
            };
          case Types.fetchSucess:
            return {
              ...state,
              isFetching: false,
              error: null,
              data
            };
          case Types.Errorfetch:
            return {
              ...state,
              isFetching: false,
              error: data
            };
          default:
            return state;
        }
      };
      return Data();
    }
    default:
      return state;
  }
};
