import React, { useState, useEffect } from 'react';
import {
  Grid,
  InputLabel,
  withStyles,
  Box,
  FormControlLabel
} from '@material-ui/core';
import PropType from 'prop-types';
import FormControl from 'src/views/common/FormControl/formControl';
import { useElements, useStripe, CardElement } from '@stripe/react-stripe-js';
import { openNoti } from 'src/Redux/global/global.actions';
import { useSelector, useDispatch } from 'react-redux';
import IOSSwitch from 'src/views/common/FormControl/IOSSwitch';
import StripeCard from 'src/views/common/StripeComponent/StripeCard';
import {
  getAdminsOfAffiliate,
  createAffiliatePaymentMethod,
  getAffiliatePayments
} from 'src/services/gym.services';
import { validMobile } from 'src/utils/validations';
import {
  getAddr,
  forStripeData
} from 'src/views/people/Members/MemberProfile/PaymentMethods/AddPaymentMethod/CreditCard.utils';
import { getGymSetup } from 'src/Redux/GymSetupReducer/GymSetup.actions';
import {
  getValidStep2,
  getValidStep3,
  validCard1,
  validCard2
} from './CreditCard.utils';
import CustomAutocomplete from '../../../common/CustomAutocomplete/commonAutocomplete';
import { ReactComponent as BillIcon } from '../../../../assets/image/JBSteppericon/bill.svg';
import { ReactComponent as CardAtm } from '../../../../assets/image/JBSteppericon/card-atm.svg';
import ColorlibStepIcon from '../../../common/JBGridStepper/ColorlibStepIcon';
import JBStepper from '../../../common/JBGridStepper';
import JBButton from '../../../common/JBButton/JBButton';
import edit from '../../../../assets/image/edit.png';
import styles from './AddPaymentMethod.style';

function getSteps() {
  return ['Billing Information', 'Card Information'];
}

const CreditCard = ({ classes, info, handleClose, updateData }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [isprocessing, setprocessing] = useState(false);
  const [admins, setAdmins] = useState(null);
  const dispatch = useDispatch();
  const [cardComplete, setCardComplete] = useState(null);
  const elements = useElements();
  const stripe = useStripe();
  const steps = getSteps();
  const gymLocs = useSelector((state) => state.SettingReducer.allGymloc.data);
  const gymSteps = useSelector((state) => state.GymSetupReducer.data);
  const allCountries = useSelector(
    (state) => state.SettingReducer.countries.data
  );
  const [billing, setBilling] = useState({
    name: info.first_name + ' ' + info.last_name,
    phone: info.mobile_no,
    address: {
      city: info.city,
      country: info.country,
      line1: info.address,
      line2: info.address2,
      postal_code: info.postal_code,
      state: info.province
    },
    location_id: null,
    payment_contact: null,
    contact_number: null,
    name_on_card: null,
    default_method: 0
  });
  const handleNext = async () => {
    if (activeStep === 1) {
      const { error: namerr } = validCard2(billing);
      if (namerr) {
        dispatch(openNoti('Please specify valid name', 'error'));
        return;
      }
      if (!elements || !stripe) {
        return;
      }
      if (!cardComplete) {
        dispatch(openNoti('Please enter valid card details', 'error'));
      }
      try {
        setprocessing(true);
        const billdata = forStripeData(billing);
        const payload = await stripe.createPaymentMethod({
          type: 'card',
          card: elements.getElement(CardElement),
          billing_details: billdata
        });
        if (payload.error) {
          setprocessing(false);
          dispatch(openNoti(payload.error.message, 'error'));
          return;
        }
        const {
          paymentMethod: { id }
        } = payload;
        const vals = {
          payment_method: 1,
          payment_method_id: id,
          affiliate_id: info.affiliate_id,
          payment_address_meta: JSON.stringify({
            billing_details: {
              address: {
                ...billing.address,
                country: billing.address.country.code
              },
              name: billing.name_on_card,
              phone: billing.phone
            }
          }),
          contact_number: billing.phone,
          payment_contact: billing.payment_contact.id,
          location_id: billing.location_id.id,
          default_method: billing.default_method
        };
        await createAffiliatePaymentMethod(vals);
        getAffiliatePayments(info.affiliate_id)
          .then((res) => {
            updateData(res);
            setprocessing(false);
            dispatch(openNoti('Payment method added succesffuly'));
            handleClose();
          })
          .then(() => {
            if (gymSteps.process_type.payment_info === 0) {
              dispatch(getGymSetup());
            }
          });
      } catch (err) {
        setprocessing(false);
        dispatch(openNoti(err.response.data.message, 'error'));
      }
    } else {
      const { error: err } = validCard1(billing);
      if (err) {
        dispatch(openNoti(err, 'error'));
        return;
      }
      setActiveStep((prev) => prev + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prev) => prev - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const [Edit, setEdit] = useState(false);
  useEffect(() => {
    getAdminsOfAffiliate(info.affiliate_id).then((res) => setAdmins(res));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <div>
            <Grid container spacing={0} style={{ margin: '20px 0' }}>
              <Grid container spacing={2} style={{ alignItems: 'center' }}>
                <Grid item xs={12}>
                  <InputLabel
                    htmlFor="age-required"
                    required
                    className={classes.cutom_select_Label}
                    style={{ padding: '5px 0' }}
                  >
                    Billing name / Name of account
                  </InputLabel>
                  <FormControl
                    control="input"
                    label=""
                    value={billing.name}
                    onChange={(e) =>
                      setBilling({ ...billing, name: e.target.value })
                    }
                    placeholder=""
                    required
                    style={{ margin: '0 0' }}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                style={{ alignItems: 'center', padding: '15px 0px 0px' }}
              >
                <Grid item xs={9}>
                  <InputLabel
                    htmlFor="age-required"
                    required
                    className={classes.cutom_select_Label}
                    style={{ padding: '5px 0' }}
                  >
                    Street, City, State/Province, Zip Code
                  </InputLabel>
                  <FormControl
                    control="input"
                    label=""
                    value={getAddr(billing)}
                    placeholder=""
                    required
                    style={{ margin: '0 0' }}
                  />
                </Grid>
                <Grid item xs={3} style={{ marginTop: '5px' }}>
                  <InputLabel
                    htmlFor="age-required"
                    className={classes.cutom_select_Label}
                    style={{ padding: '5px 0' }}
                  >
                    &nbsp;
                  </InputLabel>
                  {!Edit ? (
                    <JBButton
                      type="outlined"
                      style={{ width: '100%' }}
                      isImg={edit}
                      onClick={() => setEdit(true)}
                    >
                      Edit Address
                    </JBButton>
                  ) : (
                    <JBButton
                      type="outlined"
                      style={{ width: '100%' }}
                      isImg={edit}
                      onClick={() => setEdit(false)}
                    >
                      Save Address
                    </JBButton>
                  )}
                </Grid>
              </Grid>
              {!Edit ? null : (
                <Box style={{ width: '100%' }}>
                  <Grid
                    container
                    spacing={2}
                    style={{
                      alignItems: 'center',
                      padding: '15px 0px 8px'
                    }}
                  >
                    <Grid item xs={6}>
                      <InputLabel
                        htmlFor="age-required"
                        className={classes.cutom_select_Label}
                        style={{ padding: '5px 0' }}
                        required
                      >
                        Street address line 1
                      </InputLabel>
                      <FormControl
                        control="input"
                        value={billing.address.line1}
                        onChange={(e) =>
                          setBilling({
                            ...billing,
                            address: {
                              ...billing.address,
                              line1: e.target.value
                            }
                          })
                        }
                        label=""
                        placeholder=""
                        required
                        style={{ margin: '0 0' }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <InputLabel
                        htmlFor="age-required"
                        className={classes.cutom_select_Label}
                        style={{ padding: '5px 0' }}
                      >
                        Street address line 2
                      </InputLabel>
                      <FormControl
                        control="input"
                        value={billing.address.line2}
                        onChange={(e) =>
                          setBilling({
                            ...billing,
                            address: {
                              ...billing.address,
                              line2: e.target.value
                            }
                          })
                        }
                        label=""
                        placeholder=""
                        required
                        style={{ margin: '0 0' }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <InputLabel
                        htmlFor="age-required"
                        className={classes.cutom_select_Label}
                        style={{ padding: '5px 0' }}
                        required
                      >
                        City
                      </InputLabel>
                      <FormControl
                        control="input"
                        value={billing.address.city}
                        onChange={(e) =>
                          setBilling({
                            ...billing,
                            address: {
                              ...billing.address,
                              city: e.target.value
                            }
                          })
                        }
                        label=""
                        placeholder=""
                        required
                        style={{ margin: '0 0' }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <InputLabel
                        htmlFor="age-required"
                        className={classes.cutom_select_Label}
                        style={{ padding: '5px 0' }}
                        required
                      >
                        Province
                      </InputLabel>
                      <FormControl
                        control="input"
                        value={billing.address.state}
                        onChange={(e) =>
                          setBilling({
                            ...billing,
                            address: {
                              ...billing.address,
                              state: e.target.value
                            }
                          })
                        }
                        label=""
                        placeholder=""
                        required
                        style={{ margin: '0 0' }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <InputLabel
                        htmlFor="age-required"
                        className={classes.cutom_select_Label}
                        style={{ padding: '5px 0' }}
                        required
                      >
                        Country
                      </InputLabel>
                      <CustomAutocomplete
                        data={billing.address.country}
                        Change={(val) =>
                          setBilling({
                            ...billing,
                            address: { ...billing.address, country: val }
                          })
                        }
                        value={allCountries}
                        Options="name"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <InputLabel
                        htmlFor="age-required"
                        className={classes.cutom_select_Label}
                        style={{ padding: '5px 0' }}
                        required
                      >
                        Postal code
                      </InputLabel>
                      <FormControl
                        control="input"
                        value={billing.address.postal_code}
                        onChange={(e) =>
                          setBilling({
                            ...billing,
                            address: {
                              ...billing.address,
                              postal_code: e.target.value
                            }
                          })
                        }
                        label=""
                        placeholder=""
                        required
                        style={{ margin: '0 0' }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              )}
              <Grid container spacing={2} style={{ alignItems: 'center' }}>
                <Grid item xs={6}>
                  <InputLabel
                    htmlFor="age-required"
                    className={classes.cutom_select_Label}
                    style={{ padding: '5px 0' }}
                    required
                  >
                    Payment contact
                  </InputLabel>
                  <CustomAutocomplete
                    data={billing.payment_contact}
                    Change={(val) =>
                      setBilling({ ...billing, payment_contact: val })
                    }
                    value={admins?.list_of_admin}
                    Options="member_name"
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputLabel
                    htmlFor="age-required"
                    className={classes.cutom_select_Label}
                    style={{ padding: '5px 0px 0' }}
                    required
                  >
                    Phone number
                  </InputLabel>
                  <FormControl
                    control="input"
                    value={billing.phone}
                    onChange={(e) =>
                      setBilling({
                        ...billing,
                        phone: validMobile(e.target.value)
                      })
                    }
                    label=""
                    placeholder=""
                    required
                    style={{ margin: '0 0' }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputLabel
                    htmlFor="age-required"
                    className={classes.cutom_select_Label}
                    style={{ padding: '5px 0' }}
                    required
                  >
                    Locations
                  </InputLabel>
                  <CustomAutocomplete
                    data={billing.location_id}
                    Change={(val) =>
                      setBilling({ ...billing, location_id: val })
                    }
                    value={gymLocs}
                    Options="gym_name"
                  />
                </Grid>
              </Grid>
            </Grid>
          </div>
        );
      case 1:
        return (
          <Grid
            container
            spacing={2}
            style={{ alignItems: 'center', padding: '10px 0 0' }}
          >
            <Grid item xs={12}>
              <InputLabel
                htmlFor="age-required"
                className={classes.cutom_select_Label}
                style={{ padding: '5px 0' }}
                required
              >
                Name on the card
              </InputLabel>
              <FormControl
                control="input"
                value={billing.name_on_card}
                onChange={(e) =>
                  setBilling({ ...billing, name_on_card: e.target.value })
                }
                label=""
                placeholder=""
                style={{ margin: '0 0' }}
              />
            </Grid>
            <Grid item xs={9}>
              <InputLabel
                htmlFor="age-required"
                className={classes.cutom_select_Label}
                style={{ padding: '5px 0' }}
                required
              >
                Card number
              </InputLabel>
              <StripeCard
                onChange={(e) => {
                  setCardComplete(e.complete);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <IOSSwitch
                    checked={billing.default_method}
                    onChange={() =>
                      setBilling({
                        ...billing,
                        default_method: billing.default_method ? 0 : 1
                      })
                    }
                  />
                }
                label="Make default payment method"
                style={{ margin: '0 0' }}
              />
            </Grid>
          </Grid>
        );
      default:
        return 'Unknown step';
    }
  }
  return (
    <div>
      <JBStepper
        steps={steps}
        activeStep={activeStep}
        handleBack={handleBack}
        handleNext={handleNext}
        handleReset={handleReset}
        // eslint-disable-next-line react/jsx-no-bind
        handleStepContent={getStepContent}
        isLoading={false}
        hasErrored={false}
        isprocessing={isprocessing}
        validateBtn={[
          getValidStep2(billing),
          getValidStep3(billing, cardComplete)
        ]}
        prevLabel="Add payment method"
        nextLabel="NEXT STEP"
        CustComp={(prop) => (
          <ColorlibStepIcon
            FirstIcon={BillIcon}
            SecondIcon={CardAtm}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...prop}
          />
        )}
      />
    </div>
  );
};

CreditCard.propTypes = {
  classes: PropType.object.isRequired,
  info: PropType.object.isRequired,
  handleClose: PropType.func.isRequired,
  updateData: PropType.func.isRequired
};

export default withStyles(styles)(CreditCard);
