import React from 'react';
import PropType from 'prop-types';
import StripeElement from 'src/views/common/StripeComponent/StripeElement';
import LoadingView from 'src/views/common/loading';
import CreditCard from './CreditCard';

const AddPaymentMethodTabs = (props) => {
  const { Tabs, classes, info, handleClose, updateData } = props;

  if (!info) return <LoadingView />;
  return (
    <div className={classes}>
      {(() => {
        switch (Tabs) {
          case 0:
            return (
              <StripeElement usingMaster>
                <CreditCard
                  info={info}
                  handleClose={handleClose}
                  updateData={updateData}
                />
              </StripeElement>
            );
          default:
            return null;
        }
      })()}
    </div>
  );
};

AddPaymentMethodTabs.propTypes = {
  Tabs: PropType.number.isRequired,
  classes: PropType.object.isRequired,
  info: PropType.object.isRequired,
  handleClose: PropType.func.isRequired,
  updateData: PropType.func.isRequired
};

export default AddPaymentMethodTabs;
