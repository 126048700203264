import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropType from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { Grid, InputLabel } from '@material-ui/core';
import { openNoti } from 'src/Redux/global/global.actions';
import Typography from '@material-ui/core/Typography';
import DialogTitle from '@material-ui/core/DialogTitle';
import getBulkAction from 'src/views/common/JBTables/bulkActions.utils';
import actionType from 'src/views/common/JBTables/bulActions.constants';
import { getIdfromArr } from 'src/utils/someCommon';
import { withStyles } from '@material-ui/core/styles';
import {
  FavWOD,
  getWOD,
  getWODTags
} from '../../../Redux/WODReducers/wodReducer.actions';
import styles from './ListView.style';
import JBButton from '../../common/JBButton/JBButton';
import CustomTags from '../../common/CustomTags/CustomTags';
import dcloseicon from '../../../assets/image/closeicon.png';
import { checkValidTags, setBulkTags } from '../WOD.utils';
import { getErrorMsg } from '../../../utils/ErrorHandling';

function TagDialog(props) {
  const {
    classes,
    open,
    handleClose,
    Add,
    tags,
    module,
    reloadWOD,
    reloadFavWOD,
    getTags
  } = props;
  const [tag, setTags] = useState([]);

  const handleClick = () => {
    const { OpenNoti, wods } = props;
    if (tag.length === 0) {
      OpenNoti('Please Add valid Tags', 'error');
    } else if (Add) {
      const allTags = getIdfromArr(tag);
      getBulkAction(actionType.addTags, module, [wods, allTags])
        .then(() => {
          reloadFavWOD();
          reloadWOD();
          getTags();
        })
        .then(() => OpenNoti('Successfully Added tags', 'info'))
        .catch((err) => OpenNoti(getErrorMsg(err), 'error'));
    } else {
      const allTags = getIdfromArr(tag);
      getBulkAction(actionType.removeTags, module, [wods, allTags])
        .then(() => {
          reloadFavWOD();
          reloadWOD();
          getTags();
        })
        .then(() => OpenNoti('Successfully removed the tags', 'info'))
        .catch((err) => OpenNoti(getErrorMsg(err), 'error'));
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        fullWidth
        maxWidth="sm"
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick
      >
        <div>
          <Grid alignItems="center" container direction="row">
            <Grid item xs>
              <DialogTitle id="alert-dialog-title">
                <Typography
                  color="#000"
                  variant="h5"
                  component="div"
                  className={classes.title}
                  style={{ display: 'inline-block' }}
                >
                  {Add ? 'Add' : 'Remove'} tags
                </Typography>
              </DialogTitle>
            </Grid>
            <Grid
              item
              xs={2}
              style={{ textAlign: 'right', marginRight: '10px' }}
            >
              <Button className={classes.btns} onClick={handleClose}>
                <img alt="img" src={dcloseicon} />
              </Button>
            </Grid>
          </Grid>

          <DialogContent style={{ overflowY: 'visible' }}>
            <Grid
              item
              xs={12}
              style={{ marginTop: '0px', marginBottom: '20px' }}
            >
              <InputLabel
                htmlFor="age-required"
                className={classes.cutom_select_Label}
                required
                style={{ marginBottom: '15px', fontSize: '12px' }}
              >
                Content
              </InputLabel>
              {Add ? (
                <CustomTags
                  tags={tag}
                  suggestions={tags}
                  handleAddition={(val) => setTags(setBulkTags(tag, val, true))}
                  handleDelete={(val) =>
                    setTags((prev) => prev.filter((l, i) => i !== val))
                  } // eslint-disable-line
                  minQueryLength={1}
                />
              ) : (
                <div>
                  <CustomTags
                    tags={tag}
                    suggestions={tags.filter(
                      ({ id, name }) =>
                        !tag.some(
                          (exclude) =>
                            exclude.id === id && exclude.name === name
                        )
                    )}
                    handleAddition={(val) => setTags(setBulkTags(tag, val))}
                    handleDelete={(val) =>
                      setTags((prev) => prev.filter((l, i) => i !== val))
                    } // eslint-disable-line
                    minQueryLength={0}
                  />
                  {checkValidTags(tag, tags) && (
                    <p style={{ color: 'red', fontSize: '12px' }}>
                      You cant remove the tags
                    </p>
                  )}
                </div>
              )}
              {/* // <CustomTags
                //   tags={tag}
                //   suggestions={tags}
                //   handleAddition={(val) => console.log(val)}
                //   handleDelete={(val) =>
                //     setTags((prev) => prev.filter((l, i) => i !== val))
                //   } // eslint-disable-line
                // /> */}
            </Grid>
          </DialogContent>
          <DialogActions
            style={{
              padding: '20px 20px'
            }}
          >
            <JBButton type="primary" onClick={handleClick}>
              {Add ? 'Add Tags' : 'Save'}
            </JBButton>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}

TagDialog.defaultProps = {
  Add: false
};

TagDialog.propTypes = {
  classes: PropType.object.isRequired,
  open: PropType.bool.isRequired,
  handleClose: PropType.func.isRequired,
  Add: PropType.bool,
  tags: PropType.array.isRequired,
  wods: PropType.string.isRequired,
  module: PropType.string.isRequired,
  OpenNoti: PropType.func.isRequired,
  reloadWOD: PropType.func.isRequired,
  reloadFavWOD: PropType.func.isRequired,
  getTags: PropType.func.isRequired
};
const mapToDispatch = (dispatch) => ({
  OpenNoti: (msg, type) => dispatch(openNoti(msg, type)),
  reloadWOD: () => dispatch(getWOD()),
  reloadFavWOD: () => dispatch(FavWOD()),
  getTags: () => dispatch(getWODTags())
});
export default connect(null, mapToDispatch)(withStyles(styles)(TagDialog));
