import React from 'react';
import { ROUTES } from '../../../constant/Constants';
import { ReactComponent as Workouts } from '../../../assets/image/menu-svgicon/workouts.svg';
import { ReactComponent as People } from '../../../assets/image/menu-svgicon/users.svg';
import { ReactComponent as Classes } from '../../../assets/image/menu-svgicon/calendar.svg';
import { ReactComponent as Communication } from '../../../assets/image/menu-svgicon/square.svg';
import { ReactComponent as Documents } from '../../../assets/image/menu-svgicon/filetext.svg';
import { ReactComponent as Financial } from '../../../assets/image/menu-svgicon/dollarsign.svg';
import { ReactComponent as Reports } from '../../../assets/image/menu-svgicon/clipboard.svg';
import { ReactComponent as Gym } from '../../../assets/image/menu-svgicon/gym.svg';

const Navroutes = [
  {
    id: 1,
    icon: <Workouts />,
    name: 'WORKOUTS',
    roles: 2,
    nested: [
      {
        id: 1,
        href: ROUTES.WORKOUT_LIST,
        name: 'List',
        roles: 2
      },
      {
        id: 2,
        href: ROUTES.WORKOUT_SETTINGS,
        name: 'Settings',
        roles: 1
      }
    ]
  },
  {
    id: 2,
    icon: <People />,
    name: 'PEOPLE',
    roles: 3,
    nested: [
      {
        id: 1,
        href: ROUTES.PEOPLE_LIST,
        name: 'Leads',
        roles: 3
      },
      {
        id: 2,
        href: ROUTES.PEOPLE_MEMBERS,
        name: 'Members',
        roles: 3
      },
      {
        id: 3,
        href: ROUTES.PEOPLE_SETTINGS,
        name: 'Settings',
        roles: 1
      }
    ]
  },
  {
    id: 3,
    icon: <Classes />,
    name: 'CLASSES',
    roles: 3,
    nested: [
      {
        id: 1,
        href: ROUTES.CLASSES_CALENDAR,
        name: 'Calendar',
        roles: 3
      },
      {
        id: 2,
        href: ROUTES.CLASSES_LIST,
        name: 'List',
        roles: 3
      },
      {
        id: 3,
        href: ROUTES.CLASSES_PROGRAMS,
        name: 'Programs',
        roles: 3
      },
      {
        id: 4,
        href: ROUTES.CLASSES_MEMBERSHIPS,
        name: 'memberships',
        roles: 3
      },
      {
        id: 5,
        href: ROUTES.CLASSES_SETTINGS,
        name: 'settings',
        roles: 2
      }
    ]
  },
  {
    id: 4,
    icon: <Communication />,
    name: 'communication',
    roles: 1,
    nested: [
      {
        id: 1,
        href: ROUTES.COMMUNICATION_AUTOMATED_EMAILS,
        name: 'Automated Emails',
        roles: 1
      },
      {
        id: 2,
        href: ROUTES.COMMUNICATION_SETTINGS,
        name: 'settings',
        roles: 1
      }
    ]
  },
  {
    id: 5,
    icon: <Documents />,
    name: 'DOCUMENTS',
    roles: 3,
    nested: [
      {
        id: 1,
        href: ROUTES.DOCUMENTS_CONTRACTS,
        name: 'Contracts',
        roles: 3
      },
      {
        id: 2,
        href: ROUTES.DOCUMENTS_WAIVERS,
        name: 'Waivers',
        roles: 3
      }
    ]
  },
  {
    id: 6,
    icon: <Financial />,
    name: 'FINANCIAL',
    roles: 3,
    nested: [
      {
        id: 1,
        href: ROUTES.FINANCIAL_INVOICES,
        name: 'invoices',
        roles: 3
      },
      {
        id: 2,
        href: ROUTES.FINANCIAL_SETTINGS,
        name: 'settings',
        roles: 1
      }
    ]
  },
  {
    id: 7,
    icon: <Reports />,
    name: 'REPORTS',
    roles: 3,
    href: ROUTES.REPORTS_STARRED,
    nested: []
  },
  {
    id: 8,
    icon: <Gym />,
    name: 'GYM',
    roles: 1,
    nested: [
      {
        id: 1,
        href: ROUTES.GYM_GENERAL_INFO_INFOLOCATIONS,
        name: 'General Info',
        roles: 1
      },
      {
        id: 2,
        href: ROUTES.GYM_SETTINGS,
        name: 'settings',
        roles: 1
      }
    ]
  }
];

export default Navroutes;
