import React from 'react';

// import {
//   setSetupFee,
//   setMemFee
// } from 'src/Context/VanguardMembership/VanguardMembership.actions';
import GetPrice from 'src/views/common/CurrencySymbol/GetPrice';
import PropType from 'prop-types';
import clsx from 'clsx';
import {
  Typography,
  makeStyles,
  Grid,
  Box,
  TableRow,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  InputLabel
} from '@material-ui/core';

// import FormControl from 'src/views/common/FormControl/formControl';
// import { validateFloat, validPrice } from 'src/utils/validations';

import VanguardTextLoadingView from 'src/views/common/loading/VanguardTextLoadingView';
import {
  // getTotal,
  // getTaxTotal,
  getTotalDue
} from '../membership/Vanguard.utills';

// [state, dispatch]
const PricingDropin = ({ subtitle, reducer: [state], isLoading }) => {
  // const [state, dispatch] = useContext(VanguardMem);
  // eslint-disable-next-line no-shadow
  // const taxRates = useSelector((state) => state.SettingReducer.taxRates.data);
  const {
    memebership_data: {
      membershipdata: { discount, payment_data }
    }
  } = state;
  // const tax = taxRates.filter((i) => i.id === payment_data.tax_id);

  // const handleChange = (event) => {
  //   dispatch(
  //     setDiscountType({ name: event.target.name, value: event.target.value })
  //   );
  // };

  const useStyles = makeStyles(() => ({
    mainTitle: {
      fontWeight: '500',
      fontSize: '48px',
      lineHeight: '44px',
      color: '#FFFFFF',
      fontFamily: 'DDC Hardware',
      textAlign: 'center',
      margin: '38px 0 0 0',
      '@media (max-width: 992px)': {
        fontSize: '35px'
      }
    },
    subTitle: {
      fontWeight: 'bold',
      fontSize: '24px',
      lineHeight: '38px',
      color: '#FFFFFF',
      fontFamily: 'Lato',
      textAlign: 'center',
      margin: '38px 0 0 0',
      letterSpacing: '0.1px'
    },

    customRadio: {
      '& .MuiFormControlLabel-root': {
        marginLeft: '-11px',
        marginRight: '5px'
      },
      // '& .MuiFormControlLabel-root': {
      //   background: 'rgba(196, 196, 196, 0.05)',
      //   border: '2px solid #7B7B7B',
      //   borderRadius: '6px',
      //   height: '70px',
      //   margin: '0 0 15px'
      // },
      // '& .MuiFormControlLabel-root.checked': {
      //   background: 'rgba(44, 233, 218, 0.15)',
      //   border: '2px solid #2CE9DA',
      //   borderRadius: '6px'
      // },
      '& .MuiFormControlLabel-root svg.MuiSvgIcon-root': {
        width: '30px',
        height: '30px',
        fill: 'none',
        borderRadius: '50%',
        border: '2px solid #4B4B4B'
      },
      '& .MuiFormControlLabel-root.checked svg.MuiSvgIcon-root': {
        background: '#67e9da',
        padding: '4px',
        fill: '#000',
        color: '#060606',
        border: '0px solid #4B4B4B'
      },
      '& .MuiRadio-root': {
        padding: '20px'
      },
      '& .MuiFormControlLabel-label': {
        color: '#fff',
        fontSize: '20px',
        lineHeight: '26px',
        fontWeight: 'bold',
        WebkitLineClamp: '2',
        WebkitBoxOrient: 'vertical',
        display: '-webkit-box',
        whiteSpace: 'normal',
        overflow: 'hidden',
        height: 'auto',
        minHeight: 'auto',
        width: 'auto',
        textAlign: 'left',
        '@media (max-width: 850px)': {
          width: 'auto'
        }
      }
    },
    customGridItem: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      width: '130px',
      color: '#fff',
      fontWeight: 'bold',
      fontSize: '14px',
      lineHeight: '26px',
      alignItems: 'flex-start',
      '& h5 ': {
        fontFamily: 'Lato',
        color: 'rgb(255 255 255 / 60%)',
        fontWeight: 'normal',
        fontSize: '20px',
        lineHeight: '32px'
      }
    },
    ItemText: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      width: '130px',
      color: '#fff',
      fontWeight: 'bold',
      fontSize: '20px',
      lineHeight: '32px',
      alignItems: 'flex-start',
      fontFamily: 'Lato',
      paddingLeft: '20px',
      '@media (max-width: 850px)': {
        width: '115px'
      }
    },
    customGrid: {
      '& .customGridData': {
        background: 'rgba(196, 196, 196, 0.05)',
        border: '2px solid #7B7B7B',
        borderRadius: '6px',
        height: '70px',
        margin: '0 0 15px',
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        textTransform: 'capitalize'
      },
      '& .customGridData.checked': {
        background: 'rgba(44, 233, 218, 0.15)',
        border: '2px solid #2CE9DA',
        borderRadius: '6px'
      }
    },
    PercentageFormControl: {
      // color: '#292929',
      // border: '1px solid #a9a9a9',
      // padding: '8px 15px 8px',
      fontSize: '16px',
      width: '123px',
      color: '#ffffff',
      background: 'rgba(196, 196, 196, 0.05)',
      border: '2px solid #C8C8C8',
      borderRadius: '6px',
      padding: '8px 15px 8px',
      minHeight: '52',
      '&.MuiInput-underline.Mui-error': {
        border: '1px solid #f44336'
      },
      '&.MuiInput-underline.Mui-error input[type="text"]': {
        border: '0px solid #f44336'
      },
      '& .MuiInput-underline.Mui-disabled:before': {
        borderBottomStyle: 'none'
      },
      '& .MuiInput-root': {
        color: '#ffffff',
        background: 'transparent !important',
        border: 'none !important',
        borderRadius: '0 !important',
        minHeight: 'auto !important'
      }
    },
    InputControl: {
      flexDirection: 'row',
      justifyContent: 'left',
      flexWrap: 'nowrap',
      display: 'flex',
      alignItems: 'center',
      '& .MuiInput-root': {
        color: '#ffffff',
        background: 'rgba(196, 196, 196, 0.05)',
        border: '2px solid #C8C8C8',
        borderRadius: '6px',
        minHeight: '52px'
      },
      '& .MuiInputAdornment-positionStart': {
        color: '#ffffff'
      },
      '& .MuiInputBase-input': {
        color: '#ffffff'
      }
    },
    finalPricing: {},
    PlanTerms: {
      // border: '2px solid #7B7B7B',
      // borderRadius: '6px',
      // marginBottom: '15px',
      '& tr': {
        border: '2px solid #7B7B7B',
        borderRadius: '6px',
        marginBottom: '15px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        maxHeight: '76px',
        '& td': {
          fontSize: '20px',
          lineHeight: 'normal',
          letterSpacing: '0.2px',
          borderBottom: '0',
          width: '200px',
          fontWeight: '700',
          color: '#fff',
          '&:nth-of-type(odd)': {
            color: '#ffffff',
            minWidth: '222px',
            fontWeight: '700',
            width: '222px'
          }
        }
      }
    },
    cutom_vanguard_FormControl: {
      '& .fd-input input': {
        background: 'rgba(196, 196, 196, 0.05)',
        color: '#fff',
        borderRadius: '6px',
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '20px',
        height: '36px',
        marginTop: '0',
        border: '2px solid #C8C8C8',
        padding: '8px 20px 8px',
        '&::placeholder ': {
          color: '#fff',
          fontWeight: 'bold',
          fontSize: '15px'
        }
      },
      '&  .MuiPaper-root': {
        backgroundColor: '#2d2d2d',
        marginTop: '0',
        marginBottom: '0',
        border: '2px solid #C8C8C8',
        borderTop: 'transparent',
        boxShadow: 'none',
        // borderRadius: '0',
        borderTopLeftRadius: '0',
        borderTopRightRadius: '0'
      },
      '& .MuiAutocomplete-option': {
        color: '#fff',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '20px',
        height: '66px'
      },
      '& .MuiAutocomplete-popper': {
        width: '100% !important',
        marginTop: '-2px'
      },
      '& .SelectDrop[aria-expanded="true"]': {
        borderBottom: '2px solid transparent',
        borderBottomLeftRadius: '0',
        borderBottomRightRadius: '0'
      }
    },
    cutom_select_Label: {
      fontStyle: 'normal',
      fontWeight: '700;',
      fontSize: '20px',
      lineHeight: '32px',
      letterSpacing: '0.2px',
      color: '#FFFFFF',
      '&:focus ': {
        outline: '0'
      },
      '& .MuiFormLabel-asterisk': {
        color: 'transparent'
      },
      '& .MuiFormLabel-asterisk:after': {
        content: '""',
        background: '#2CE9DA',
        display: 'inline-block',
        position: 'relative',
        width: '8px',
        height: '8px',
        borderRadius: '50%',
        top: '-14px',
        right: '10px'
      },
      '@media (max-width: 850px)': {
        fontSize: '19px'
      }
    },
    TableHeadCell: {
      background: '#67e9da',
      border: '0 !important',
      '& td': {
        color: '#000 !important'
      }
    }
  }));
  const classes = useStyles();

  return (
    <div>
      <Typography variant="h3" className={classes.mainTitle}>
        Pricing
      </Typography>
      <Grid
        container
        xs={10}
        justify="center"
        style={{
          margin: '45px auto'
        }}
      >
        <Typography variant="h5" className={classes.subTitle}>
          {subtitle}
        </Typography>
        <Box
          mt="25px"
          css={{
            width: '100%'
          }}
        />
        {isLoading ? (
          <VanguardTextLoadingView />
        ) : (
          <>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={2}
              // style={{ margin: '20px 0 20px' }}
            >
              <Grid xs={12} className={classes.finalPricing}>
                <TableContainer className={classes.PlanTerms}>
                  <Table className={classes.table}>
                    {/*  */}
                    <TableBody>
                      <TableRow className={classes.TableHeadCell}>
                        <TableCell className={classes.TableCell}>
                          Name
                        </TableCell>
                        <TableCell className={classes.TableCell}>
                          Payment plan
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.TableCell}>
                          Payment option type
                        </TableCell>
                        {/* {payment_data.auto_renew !== 0 && ( */}
                        <TableCell className={classes.TableCell}>
                          {/* {payment_data.renewOpt === 1 && 'Pay in full'} */}
                          Pay in full
                        </TableCell>
                        {/* )} */}
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.TableCell}>
                          <InputLabel
                            htmlFor="age-required"
                            required
                            className={classes.cutom_select_Label}
                            // style={{ margin: '0px 0 5px' }}
                          >
                            Setup fee
                          </InputLabel>
                        </TableCell>
                        <TableCell
                          className={clsx(
                            classes.TableCell,
                            classes.cutom_vanguard_FormControl
                          )}
                        >
                          <GetPrice
                            value={
                              payment_data.initial_commitment_pricing
                                .full_setup_fees
                            }
                          />
                          {/* <FormControl
                            control="input"
                            value={
                              payment_data.initial_commitment_pricing
                                .full_setup_fees
                            }
                            onChange={(e) =>
                              dispatch(
                                setSetupFee(
                                  validPrice(validateFloat(e.target.value))
                                )
                              )
                            }
                            maxLength={50}
                            style={{
                              margin: '0 0'
                            }}
                          /> */}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.TableCell}>
                          <InputLabel
                            htmlFor="age-required"
                            required
                            className={classes.cutom_select_Label}
                            // style={{ margin: '0px 0 5px' }}
                          >
                            Fee
                          </InputLabel>
                        </TableCell>
                        <TableCell
                          className={clsx(
                            classes.TableCell,
                            classes.cutom_vanguard_FormControl
                          )}
                        >
                          <GetPrice
                            value={
                              payment_data.initial_commitment_pricing
                                .full_membership_fees
                            }
                          />
                          {/* <FormControl
                            control="input"
                            value={
                              payment_data.initial_commitment_pricing
                                .full_membership_fees
                            }
                            onChange={(e) =>
                              dispatch(
                                setMemFee(
                                  validPrice(validateFloat(e.target.value))
                                )
                              )
                            }
                            maxLength={50}
                            style={{
                              margin: '0 0'
                            }}
                          /> */}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.TableCell}>
                          Subtotal
                        </TableCell>
                        <TableCell className={classes.TableCell}>
                          <GetPrice
                            value={getTotalDue(payment_data, discount)}
                          />
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell className={classes.TableCell}>
                          Total Due
                        </TableCell>
                        <TableCell className={classes.TableCell}>
                          {' '}
                          <GetPrice
                            value={getTotalDue(payment_data, discount)}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.TableCell}>GST</TableCell>
                        <TableCell className={classes.TableCell}>
                          {' '}
                          <GetPrice
                            // value={getTaxTotal(payment_data, tax[0], discount)}
                            value={
                              payment_data.initial_commitment_pricing
                                .full_tax_amount
                            }
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
};

PricingDropin.defaultProps = {
  subtitle: '',
  // title: ''
  isLoading: false
};

PricingDropin.propTypes = {
  // setOpen: PropType.bool.isRequired,
  // handleClose: PropType.func.isRequired
  // handleBack: PropType.func,
  reducer: PropType.array.isRequired,
  subtitle: PropType.string,
  isLoading: PropType.bool
};

export default PricingDropin;
