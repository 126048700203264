import { initialValue as attendInitials } from 'src/Context/AttendanceSummary/attendanceReducer';
import types from './reports.action.type';
import { DataObj, FilterObj } from '../reducer.default.data';
import getReports from './reports.reducer.utils';
import filterList from '../Reducers.utils';
import { getAttendancefilterAdded, getAddedTags } from './reports.common.utils';

const initialValue = {
  membersReports: {
    membershipDetails: DataObj,
    expireMemberships: DataObj,
    byMemshipType: DataObj,
    byPaymentPlan: DataObj,
    membershipsOnHold: DataObj,
    memberDetailsFil: FilterObj({
      user_status: 1
    }),
    expireMembershipFil: FilterObj({ next_day: 30 }),
    byMembershipTypeFil: FilterObj({ member_since: 'all_time' }),
    byPaymentPlanFil: FilterObj({}),
    membershipsOnholdFil: FilterObj({
      current: 1
    })
  },
  attendanceReports: {
    attendanceTrends: DataObj,
    attendanceSummary: DataObj,
    lastAttendance: DataObj,
    attendanceHistory: DataObj,
    userHistory: DataObj,
    lastAttendanceFil: FilterObj({
      is_active: true
    }),
    attendanceHistoryFil: FilterObj({ is_active: true }),
    attendanceSummaryFil: {
      initialFilter: attendInitials,
      filterAdded: {},
      tags: []
    },
    attendanceTrendsFil: FilterObj({
      user_status: 1,
      weekly_attendance: 10
    }),
    userHistoryFil: FilterObj({})
  },
  financialReports: {
    allInvoice: DataObj,
    expireCards: DataObj,
    membersValue: DataObj,
    revenueCategory: DataObj,
    overdueAccounts: DataObj,
    revenueMembership: DataObj,
    allInvoiceFil: FilterObj({}),
    revenueMembershipFil: FilterObj({}),
    expirecardsFil: FilterObj({}),
    revenueCategoryFil: FilterObj({}),
    membersValueFil: FilterObj({ user_status: '1' }),
    overdueAccountsFil: FilterObj({ user_status: 0 })
  },
  leadReports: {
    leadInsites: DataObj,
    leadInsitesFil: FilterObj({ date_range: 'thismonth' })
  },
  reportList: DataObj
};

export default (state = initialValue, action) => {
  switch (action.type) {
    case types.membershipDetails:
      return {
        ...state,
        membersReports: {
          ...state.membersReports,
          membershipDetails: getReports(
            types.membershipDetails,
            action.actionType,
            state.membersReports.membershipDetails,
            action.payload
          )
        }
      };
    case types.membershipDetailsFilter:
      return {
        ...state,
        membersReports: {
          ...state.membersReports,
          memberDetailsFil: filterList(
            state.membersReports.memberDetailsFil,
            action.filter,
            action.payload
          )
        }
      };
    case types.membersMembershipType:
      return {
        ...state,
        membersReports: {
          ...state.membersReports,
          byMemshipType: getReports(
            types.membersMembershipType,
            action.actionType,
            state.membersReports.byMemshipType,
            action.payload
          )
        }
      };
    case types.membersMembershipTypeFilter:
      return {
        ...state,
        membersReports: {
          ...state.membersReports,
          byMembershipTypeFil: filterList(
            state.membersReports.byMembershipTypeFil,
            action.filter,
            action.payload
          )
        }
      };
    case types.expireMembership:
      return {
        ...state,
        membersReports: {
          ...state.membersReports,
          expireMemberships: getReports(
            types.expireMembership,
            action.actionType,
            state.membersReports.expireMemberships,
            action.payload
          )
        }
      };
    case types.expireMembershipFilter:
      return {
        ...state,
        membersReports: {
          ...state.membersReports,
          expireMembershipFil: filterList(
            state.membersReports.expireMembershipFil,
            action.filter,
            action.payload
          )
        }
      };

    case types.membershipsOnHold:
      return {
        ...state,
        membersReports: {
          ...state.membersReports,
          membershipsOnHold: getReports(
            types.membershipsOnHold,
            action.actionType,
            state.membersReports.membershipsOnHold,
            action.payload
          )
        }
      };
    case types.membershipsOnholdFilter:
      return {
        ...state,
        membersReports: {
          ...state.membersReports,
          membershipsOnholdFil: filterList(
            state.membersReports.membershipsOnholdFil,
            action.filter,
            action.payload
          )
        }
      };

    case types.membersPaymentPlan:
      return {
        ...state,
        membersReports: {
          ...state.membersReports,
          byPaymentPlan: getReports(
            types.membersPaymentPlan,
            action.actionType,
            state.membersReports.byPaymentPlan,
            action.payload
          )
        }
      };
    case types.membersPaymentPlanFilter:
      return {
        ...state,
        membersReports: {
          ...state.membersReports,
          byPaymentPlanFil: filterList(
            state.membersReports.byPaymentPlanFil,
            action.filter,
            action.payload
          )
        }
      };
    case types.membersLastAttendance:
      return {
        ...state,
        attendanceReports: {
          ...state.attendanceReports,
          lastAttendance: getReports(
            types.membersLastAttendance,
            action.actionType,
            state.attendanceReports.lastAttendance,
            action.payload
          )
        }
      };
    case types.membersLastAttendanceFil:
      return {
        ...state,
        attendanceReports: {
          ...state.attendanceReports,
          lastAttendanceFil: filterList(
            state.attendanceReports.lastAttendanceFil,
            action.filter,
            action.payload
          )
        }
      };
    case types.membersAttendanceHistory:
      return {
        ...state,
        attendanceReports: {
          ...state.attendanceReports,
          attendanceHistory: getReports(
            types.membersAttendanceHistory,
            action.actionType,
            state.attendanceReports.attendanceHistory,
            action.payload
          )
        }
      };
    case types.membersAttendanceHistoryFil:
      return {
        ...state,
        attendanceReports: {
          ...state.attendanceReports,
          attendanceHistoryFil: filterList(
            state.attendanceReports.attendanceHistoryFil,
            action.filter,
            action.payload
          )
        }
      };
    case types.attendanceSummary:
      return {
        ...state,
        attendanceReports: {
          ...state.attendanceReports,
          attendanceSummary: getReports(
            types.attendanceSummary,
            action.actionType,
            state.attendanceReports.attendanceSummary,
            action.payload
          )
        }
      };
    case types.attendanceSummaryinitalFil:
      return {
        ...state,
        attendanceReports: {
          ...state.attendanceReports,
          attendanceSummaryFil: {
            initialFilter:
              action.payload ||
              state.attendanceReports.attendanceSummaryFil.initialFilter,
            filterAdded: getAttendancefilterAdded(
              action.payload ||
                state.attendanceReports.attendanceSummaryFil.initialFilter,
              action.filter,
              state.attendanceReports.attendanceSummaryFil.filterAdded
            ),
            tags: getAddedTags(
              action.filter,
              action.payload,
              state.attendanceReports.attendanceSummaryFil.tags,
              action.date
            )
          }
        }
      };
    case types.userHistoryFil:
      return {
        ...state,
        attendanceReports: {
          ...state.attendanceReports,
          userHistoryFil: filterList(
            state.attendanceReports.userHistoryFil,
            action.filter,
            action.payload
          )
        }
      };
    case types.getUserHistory:
      return {
        ...state,
        attendanceReports: {
          ...state.attendanceReports,
          userHistory: getReports(
            types.getUserHistory,
            action.actionType,
            state.attendanceReports.userHistory,
            action.payload
          )
        }
      };
    case types.allinvoicereports:
      return {
        ...state,
        financialReports: {
          ...state.financialReports,
          allInvoice: getReports(
            types.allinvoicereports,
            action.actionType,
            state.financialReports.allInvoice,
            action.payload
          )
        }
      };
    case types.revenuebymembership:
      return {
        ...state,
        financialReports: {
          ...state.financialReports,
          revenueMembership: getReports(
            types.revenuebymembership,
            action.actionType,
            state.financialReports.revenueMembership,
            action.payload
          )
        }
      };

    case types.expirecreditcards:
      return {
        ...state,
        financialReports: {
          ...state.financialReports,
          expireCards: getReports(
            types.expireMembership,
            action.actionType,
            state.financialReports.expireCards,
            action.payload
          )
        }
      };

    case types.revenueCategory:
      return {
        ...state,
        financialReports: {
          ...state.financialReports,
          revenueCategory: getReports(
            types.revenueCategory,
            action.actionType,
            state.financialReports.revenueCategory,
            action.payload
          )
        }
      };

    case types.revenueCategoryFil:
      return {
        ...state,
        financialReports: {
          ...state.financialReports,
          revenueCategoryFil: filterList(
            state.financialReports.revenueCategoryFil,
            action.filter,
            action.payload
          )
        }
      };

    case types.expirecreditcardsFil:
      return {
        ...state,
        financialReports: {
          ...state.financialReports,
          expirecardsFil: filterList(
            state.financialReports.expirecardsFil,
            action.filter,
            action.payload
          )
        }
      };

    case types.revenuebymembershipFilter:
      return {
        ...state,
        financialReports: {
          ...state.financialReports,
          revenueMembershipFil: filterList(
            state.financialReports.revenueMembershipFil,
            action.filter,
            action.payload
          )
        }
      };

    case types.allinvoicereportsFilter:
      return {
        ...state,
        financialReports: {
          ...state.financialReports,
          allInvoiceFil: filterList(
            state.financialReports.allInvoiceFil,
            action.filter,
            action.payload
          )
        }
      };

    case types.overdueaccount:
      return {
        ...state,
        financialReports: {
          ...state.financialReports,
          overdueAccounts: getReports(
            types.overdueaccount,
            action.actionType,
            state.financialReports.overdueAccounts,
            action.payload
          )
        }
      };

    case types.overdueaccountFilter:
      return {
        ...state,
        financialReports: {
          ...state.financialReports,
          overdueAccountsFil: filterList(
            state.financialReports.overdueAccountsFil,
            action.filter,
            action.payload
          )
        }
      };

    case types.attendanceTrends:
      return {
        ...state,
        attendanceReports: {
          ...state.attendanceReports,
          attendanceTrends: getReports(
            types.attendanceTrends,
            action.actionType,
            state.attendanceReports.attendanceTrends,
            action.payload
          )
        }
      };

    case types.attendanceTrendsFilter:
      return {
        ...state,
        attendanceReports: {
          ...state.attendanceReports,
          attendanceTrendsFil: filterList(
            state.attendanceReports.attendanceTrendsFil,
            action.filter,
            action.payload
          )
        }
      };
    case types.valueEeachMember:
      return {
        ...state,
        financialReports: {
          ...state.financialReports,
          membersValue: getReports(
            types.valueEeachMember,
            action.actionType,
            state.financialReports.membersValue,
            action.payload
          )
        }
      };
    case types.valueEeachMemberFil:
      return {
        ...state,
        financialReports: {
          ...state.financialReports,
          membersValueFil: filterList(
            state.financialReports.membersValueFil,
            action.filter,
            action.payload
          )
        }
      };
    case types.leadInsights:
      return {
        ...state,
        leadReports: {
          ...state.leadReports,
          leadInsites: getReports(
            types.leadInsights,
            action.actionType,
            state.leadReports.leadInsites,
            action.payload
          )
        }
      };
    case types.leadInsightsFil:
      return {
        ...state,
        leadReports: {
          ...state.leadReports,
          leadInsitesFil: filterList(
            state.leadReports.leadInsitesFil,
            action.filter,
            action.payload
          )
        }
      };
    case types.getreportList:
      return {
        ...state,
        reportList: getReports(
          types.getreportList,
          action.actionType,
          state.reportList,
          action.payload
        )
      };
    default:
      return state;
  }
};
