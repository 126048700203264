export const TobeFilter = [
  { id: 1, filter: 'Program' },
  { id: 2, filter: 'Date Range' },
  { id: 3, filter: 'Starred' },
  { id: 4, filter: 'WOD tag' }
];

export const StarredFilter = [
  { id: 0, starred: 'is Not Starred' },
  { id: 1, starred: 'isStarred' }
];

export const getTags = (tags) => {
  const result = [];
  // eslint-disable-next-line
  for (let i = 0; i < tags.length; i++) {
    const b = {
      id: tags[i],
      name: tags[i]
    };
    result.push(b);
  }
  return result;
};
