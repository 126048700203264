export const TobeFilter = [
  { id: 1, filter: 'Ally' },
  { id: 2, filter: 'Ally status' },
  { id: 3, filter: 'Invoice Status' },
  { id: 4, filter: 'Date Range' }
];

export const FilterAutobill = [
  { id: 0, name: 'Inactive' },
  { id: 1, name: 'Active' }
];

export const FilterStatus = [
  { id: 1, name: 'Unpaid' },
  { id: 2, name: 'Paid' },
  { id: 3, name: 'Voided' },
  { id: 5, name: 'Refunded' }
];

export const isNotData = [
  { id: 0, name: 'is Not' },
  { id: 1, name: 'is' }
];
