import { checkValidString } from '../../../utils/validations';
import { SessionMeta } from '../MembershipTemplate/SessionTemplates/SessionTemplate.utils';

// eslint-disable-next-line
export const editPlanValidate = (data) => {
  if (checkValidString(data.name)) {
    return { error: 'Please specify valid name' };
  }
  if (data.no_of_sessions === 0 || data.no_of_sessions === '') {
    return { error: 'Please specify valid sessions' };
  }
  if (!data.reve_cat_id) {
    return { error: 'Please select revenue category' };
  }
  if (!data.attendance_limit) {
    return { error: 'Please select expiration limit' };
  }
  if (data.attendance_limit && data.attendance_limit.id === 1) {
    if (
      data.attendance_limited_meta.uses.length === 0
      || !data.attendance_limited_meta.uses
    ) {
      return { error: 'Please specify valid attendance limit uses' };
    }
    if (!data.attendance_limited_meta.type) {
      return { error: 'Please select attendance limit type' };
    }
  }

  if (
    data.description
    && data.description.trim().length > 0
    && checkValidString(data.description)
  ) {
    return { error: 'Please specify valid Desciption' };
  }
  if (data.program_ids.length === 0) {
    return { error: 'Please Select atleast one Program' };
  }
  if (data.meta.length === 0) {
    return { error: 'Please create atleast one payment plan' };
  }
  if (data.contract_id.length === 0) {
    return { error: 'Please Select the Contract' };
  }
  return { value: data };
};

export const getUpdateData = (data) => {
  let value = {
    membership_type: 'session',
    no_of_sessions: data.no_of_sessions,
    name: data.name,
    reve_cat_id: data.reve_cat_id.id,
    attendance_limit: data.attendance_limit.id,
    program_ids: data.program_ids.join(','),
    meta: JSON.stringify(SessionMeta(data.meta)),
    contract_id: data.contract_id,
    description: null
  };
  if (data.description && data.description.trim().length > 0) {
    value = {
      ...value,
      description: data.description
    };
  }

  if (data.attendance_limit.id === 1) {
    value = {
      ...value,
      attendance_limited_meta: JSON.stringify({
        ...data.attendance_limited_meta,
        type: data.attendance_limited_meta.type.id
      })
    };
  }
  return value;
};
