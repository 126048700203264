import React from 'react';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import clsx from 'clsx';
import { Box, withStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';

const styles = () => ({
  // root: {
  //   height: '100%'
  // }
});

// const data = {
//   labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
//   datasets: [
//     {
//       label: '# of Red Votes',
//       data: [12, 19, 3, 5, 2, 3],
//       backgroundColor: 'rgb(255, 99, 132)'
//     },
//     {
//       label: '# of Blue Votes',
//       data: [2, 3, 20, 5, 1, 4],
//       backgroundColor: 'rgb(54, 162, 235)'
//     },
//     {
//       label: '# of Green Votes',
//       data: [3, 10, 13, 15, 22, 30],
//       backgroundColor: 'rgb(75, 192, 192)'
//     }
//   ]
// };

// const Leftlabel = options.scales.xAxes[0].scaleLabel.labelString;

// const leftlabel = leftlabeltitle;

const BarChart = ({
  classes,
  data,
  children,
  leftlabel,
  xlabel,
  ylabel,
  ...props
}) => {
  const currency = useSelector((state) => state.SettingReducer.currency);
  const options = {
    animation: {
      // onProgress(animation) {
      //   data.value =
      //     animation.animationObject.currentStep /
      //     animation.animationObject.numSteps;
      // }
    },
    scales: {
      yAxes: [
        {
          barPercentage: 0.4,
          categoryPercentage: 0.4,
          display: true,
          position: 'left',
          scaleLabel: {
            display: true,
            labelString: ylabel
          }
        }
      ],
      xAxes: [
        {
          display: true,
          position: 'left',
          scaleLabel: {
            display: true,
            labelString: xlabel
          }
        }
      ]
    },
    legend: {
      display: false,
      position: 'bottom'
      // labels: {
      //   fontColor: 'rgb(255, 99, 132)'
      // }
    },
    tooltips: {
      enabled: true,
      mode: 'single',
      callbacks: {
        label: (tooltipItems) => {
          return `${currency.data.currency_symbol}${tooltipItems.yLabel.toFixed(
            2
          )}`;
        }
      }
    },
    maintainAspectRatio: false,
    responsive: true
  };

  return (
    <Box
      height={400}
      width={800}
      position="relative"
      display="inline-block"
      className={clsx(classes.root)}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {children}
      <Bar
        data={data}
        options={options}
        leftlabel={leftlabel}
        ylabel={ylabel}
        xlabel={xlabel}
      />
    </Box>
  );
};

BarChart.defaultProps = {
  ylabel: 'REVENUE',
  xlabel: 'REVENUE CATEGORY'
};

BarChart.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  leftlabel: PropTypes.any.isRequired,
  ylabel: PropTypes.string,
  xlabel: PropTypes.string,
  children: PropTypes.string.isRequired
};

export default withStyles(styles)(BarChart);
