export const tableHead = [
  {
    id: 1,
    label: 'Name',
    cellName: 'name'
  },
  {
    id: 2,
    label: 'Membership attendance limitation',
    cellName: 'membership_type'
  }
];

export const Dummydata = [
  {
    id: 1,
    name: 'Mukul',
    membership_type: 'Whatever'
  },
  {
    id: 2,
    name: 'Mukul',
    membership_type: 'Whatever'
  }
];
