const constType = {
  page: 'PAGE',
  search: 'SEARCH',
  futureClass: 'FUTURECLASS',
  allClass: 'ALLCLASSES',
  recurrClass: 'RECURRCLASS',
  isFetching: 'CLASS_FETCHING_START',
  fetchSucess: 'CLASS_DATA_FETCHED_SUCCESSFUL',
  Errorfetch: 'ERROR_OCCURED_WHEN_FETCHING'
};
export default constType;
