import React, { createContext, useReducer } from 'react';
import PropType from 'prop-types';
import MembershipReducer, { initialValue } from './membershiptemplate.reducer';

export const MembershipTemplate = createContext();

export const MembershipContext = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(MembershipReducer, initialValue);
  return (
    <MembershipTemplate.Provider value={[state, dispatch]}>
      {children}
    </MembershipTemplate.Provider>
  );
};

MembershipContext.propTypes = {
  children: PropType.node.isRequired
};
