export const INACTIVE_ALLIES = 'INACTIVE_ALLIES';
export const ACTIVE_ALLIES = 'ACTIVE_ALLIES';
export const ALL_ALLIES = 'ALL_ALLIES';
export const ACTIVE_ALLIES_FIL = 'ACTIVE_ALLIES_FILTER';
export const INACTIVE_ALLIES_FIL = 'INACTIVE_ALLIES_FILTER';
export const ALL_ALLIES_FIL = 'ALL_ALLIES_FIL';

export const ALLIES_ACTIONTYPE = {
  is_Fetching: 'IS_FETCHING',
  fetchSucess: 'ALLIES_COMPLETED',
  Errorfetch: 'ALLIES_ERROR_IN_FETCHING'
};
