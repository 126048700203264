import React from 'react';
import PropType from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  withStyles,
  TableSortLabel
} from '@material-ui/core';
import GetPrice from 'src/views/common/CurrencySymbol/GetPrice';
import CheckIcon from 'src/assets/image/check.png';
import filterType from 'src/Redux/Reducer.constants';
import { ROUTES } from 'src/constant/Constants';

const styles = () => ({
  table: {
    minWidth: '100%',
    wordBreak: 'break-word',
    '& td': {
      padding: '10px'
    },
    '@media (max-width: 1366px)': {
      '& td': {
        fontSize: '13px'
      }
    }
  },
  tableLink: {
    color: '#01847A',
    fontWeight: '700'
  },
  TableCell: {
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px',
    height: '56px',
    minHeight: '56px',
    padding: '10px',
    '@media (max-width: 1366px)': {
      fontSize: '13px',
      wordBreak: 'keep-all'
    }
  },
  Tablemian: {}
});

const MembershipDetailsTable = (props) => {
  const { values, classes, isActive, setDirection, setSort } = props;
  const {
    sort_members,
    sort_memberships,
    sortmembershiptype,
    sort_billing_days,
    sort_commitment_type,
    sort_payment_method,
    // sort_commitment_total,
    sort_commitment_total_for_memDeTable,
    sort_auto_renew,
    sort_auto_renew_type,
    sort_renew_total,
    sort_user_status
  } = filterType;
  return (
    <TableContainer
      component={Paper}
      elevation={0}
      className={classes.Tablemian}
    >
      <Table className={classes.table} aria-label="simple table">
        <TableHead
          style={{ background: '#F9F9FA', backgroundColor: '#F9F9FA' }}
        >
          <TableRow>
            <TableCell className={classes.TableCell}>
              <TableSortLabel
                active={isActive(sort_members)}
                direction={setDirection(sort_members)}
                onClick={() => setSort(sort_members)}
              >
                Member
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell}>
              <TableSortLabel
                active={isActive(sort_memberships)}
                direction={setDirection(sort_memberships)}
                onClick={() => setSort(sort_memberships)}
              >
                Membership
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell}>
              <TableSortLabel
                active={isActive(sortmembershiptype)}
                direction={setDirection(sortmembershiptype)}
                onClick={() => setSort(sortmembershiptype)}
              >
                Membership type
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell}>
              <TableSortLabel
                active={isActive(sort_billing_days)}
                direction={setDirection(sort_billing_days)}
                onClick={() => setSort(sort_billing_days)}
              >
                Billing day
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell}>
              <TableSortLabel
                active={isActive(sort_payment_method)}
                direction={setDirection(sort_payment_method)}
                onClick={() => setSort(sort_payment_method)}
              >
                Payment method
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell}>
              <TableSortLabel
                active={isActive(sort_commitment_type)}
                direction={setDirection(sort_commitment_type)}
                onClick={() => setSort(sort_commitment_type)}
              >
                Commitment type
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell}>
              <TableSortLabel
                // active={isActive(sort_commitment_total)}
                // direction={setDirection(sort_commitment_total)}
                // onClick={() => setSort(sort_commitment_total)}
                active={isActive(sort_commitment_total_for_memDeTable)}
                direction={setDirection(sort_commitment_total_for_memDeTable)}
                onClick={() => setSort(sort_commitment_total_for_memDeTable)}
              >
                Commitment total
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell} align="center">
              <TableSortLabel
                active={isActive(sort_auto_renew)}
                direction={setDirection(sort_auto_renew)}
                onClick={() => setSort(sort_auto_renew)}
              >
                Auto renew
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell}>
              <TableSortLabel
                active={isActive(sort_auto_renew_type)}
                direction={setDirection(sort_auto_renew_type)}
                onClick={() => setSort(sort_auto_renew_type)}
              >
                Auto renew type
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell}>
              <TableSortLabel
                active={isActive(sort_renew_total)}
                direction={setDirection(sort_renew_total)}
                onClick={() => setSort(sort_renew_total)}
              >
                Renew total
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.TableCell} align="center">
              <TableSortLabel
                active={isActive(sort_user_status)}
                direction={setDirection(sort_user_status)}
                onClick={() => setSort(sort_user_status)}
              >
                Active
              </TableSortLabel>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {values.map((row) => (
            <TableRow
              hover
              tabIndex={-1}
              key={row.id}
              style={{
                backgroundColor: '#FFF'
              }}
            >
              <TableCell
                style={{
                  width: '9.0909%'
                }}
              >
                <Link
                  className={classes.tableLink}
                  to={{
                    pathname: ROUTES.PEOPLE_MEMBER_PROFILE,
                    search: `memberId=${btoa(row.id)}`
                  }}
                >
                  {row?.member}
                </Link>
              </TableCell>
              <TableCell
                style={{
                  width: '9.0909%'
                }}
                className={classes.tableLink}
              >
                {row?.membership}
              </TableCell>
              <TableCell
                style={{
                  width: '9.0909%'
                }}
              >
                {row?.memberships_type}
              </TableCell>
              <TableCell
                style={{
                  width: '9.0909%'
                }}
              >
                {row?.billing_days}
              </TableCell>
              <TableCell
                style={{
                  width: '9.0909%'
                }}
              >
                {row?.payment_method}
              </TableCell>
              <TableCell
                style={{
                  width: '9.0909%',
                  textTransform: 'capitalize'
                }}
              >
                {row.commitment_type}
              </TableCell>
              <TableCell
                style={{
                  width: '9.0909%'
                }}
              >
                <GetPrice value={parseInt(row?.commitment_total, 10)} />
                {/* <GetPrice value={parseInt(row?.commitement_total, 10)} /> */}
              </TableCell>
              <TableCell
                align="center"
                style={{
                  width: '7%'
                }}
              >
                {row?.auto_renew === 'true' && (
                  <img src={CheckIcon} alt="img" />
                )}
                {/* {row.autorenew} */}
              </TableCell>
              <TableCell
                style={{
                  width: '9.0909%',
                  textTransform: 'capitalize'
                }}
              >
                {row?.auto_renew_type}
              </TableCell>
              <TableCell
                style={{
                  width: '9.0909%'
                }}
              >
                {row.renew_total && (
                  <GetPrice value={parseInt(row?.renew_total, 10)} />
                )}
              </TableCell>
              <TableCell
                align="center"
                style={{
                  width: '7%'
                }}
              >
                {row?.user_status === 'true' && (
                  <img src={CheckIcon} alt="img" />
                )}
                {/* {row.Active} */}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {values?.length === 0 && (
        <p
          style={{
            margin: '10px 15px'
          }}
        >
          No Members and Membership Details to show...
        </p>
      )}
    </TableContainer>
  );
};
MembershipDetailsTable.propTypes = {
  values: PropType.array.isRequired,
  classes: PropType.object.isRequired,
  isActive: PropType.func.isRequired,
  setDirection: PropType.func.isRequired,
  setSort: PropType.func.isRequired
};

export default withStyles(styles)(MembershipDetailsTable);
