/* eslint-disable no-confusing-arrow */
import React, { useState, useEffect } from 'react';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Drawer,
  Hidden,
  List,
  ListItem,
  Popover,
  ListSubheader
} from '@material-ui/core';
import { getRouteIndex } from 'src/utils/someCommon';
import Collapse from '@material-ui/core/Collapse';
// import NavItem from './NavItem';
import clsx from 'clsx';
import useStyles from './navBar.style';
import NavRoutes from './routes';

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();

  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState('');
  const [anchorEl, setAnchorEl] = React.useState(selectedIndex);
  const activeMenu = 'main';
  const miniMenu = localStorage.getItem('mini-menu');
  const handleListItemClick = (event, index) => {
    setAnchorEl(event.currentTarget);
    setSelectedIndex(index);
    setOpen(!open);
  };

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  // openPopover
  const handleClose = () => {
    setAnchorEl(null);
  };
  const openPopover = Boolean(anchorEl);

  const roleid = { role_id: 1 };
  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Box p={2}>
        {NavRoutes.map((route) => (
          <div key={route.id}>
            {/* eslint-disable-next-line no-nested-ternary */}
            {route.roles.includes(roleid?.role_id || 3) ? (
              <List
                disableGutters
                key={route.disableGutters}
                className={classes.navitems}
                disablePadding
              >
                <ListItem
                  in={activeMenu === 'main'}
                  selected={selectedIndex === route.id}
                  onClick={(event) => handleListItemClick(event, route.id)}
                  className="list-items"
                  disableGutters
                  unmountOnExit
                >
                  {route.href ? (
                    <Button
                      component={RouterLink}
                      to={route.href}
                      className={clsx(
                        classes.button,
                        route.name === 'WORKOUTS' || route.name === 'GYM'
                          ? 'SvgStyle'
                          : null
                      )}
                    >
                      {route.icon}
                      <span className={classes.listName}>{route.name}</span>
                    </Button>
                  ) : (
                    <Button
                      className={clsx(
                        classes.button,
                        route.name === 'WORKOUTS' || route.name === 'GYM'
                          ? 'SvgStyle'
                          : null
                      )}
                    >
                      {route.icon}
                      <span className={classes.listName}>{route.name}</span>
                    </Button>
                  )}
                </ListItem>
              </List>
            ) : null}
            {route.nested.length > 0 && (
              <div>
                {/* Popover over menu -(in progress) */}
                {miniMenu === 'false' ? (
                  <Collapse
                    in={selectedIndex === route.id}
                    timeout="auto"
                    unmountOnExit
                    className="list-collapse-mimiMenu"
                  >
                    <Popover
                      id={openPopover === selectedIndex}
                      open={openPopover}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        // vertical: 'bottom',
                        // horizontal: 'left'
                        vertical: 'top',
                        horizontal: 'right'
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left'
                      }}
                      PaperProps={{
                        style: {
                          // backgroundColor: 'transparent'
                          // boxShadow: 'none',
                          // borderRadius: 0
                        }
                      }}
                      disablePortal
                    >
                      <Box className={classes.PopoverDiv} />
                      <List
                        component="div"
                        subheader={<ListSubheader>{route.name}</ListSubheader>}
                      >
                        {route.nested.map((nestedRoute) =>
                          nestedRoute.roles >= roleid ? (
                            <ListItem
                              className={classes.nested}
                              key={nestedRoute.id}
                              onClick={handleClose}
                            >
                              <Button
                                activeClassName={classes.active}
                                className={classes.button}
                                exact
                                component={RouterLink}
                                to={`${nestedRoute.href}`}
                              >
                                <span className={classes.collapselistName}>
                                  {nestedRoute.name}
                                </span>
                              </Button>
                            </ListItem>
                          ) : null
                        )}
                      </List>
                    </Popover>
                    {/* Popover over menu End */}
                  </Collapse>
                ) : (
                  <Collapse
                    in={selectedIndex === route.id}
                    timeout="auto"
                    unmountOnExit
                    className="list-collapse"
                  >
                    <List component="div" disablePadding>
                      {/* eslint-disable-next-line no-confusing-arrow */}
                      {route.nested.map((nestedRoute) =>
                        nestedRoute.roles.includes(roleid.role_id || 3) ? (
                          <ListItem
                            className={classes.nested}
                            key={nestedRoute.id}
                          >
                            <Button
                              activeClassName={classes.active}
                              className={classes.button}
                              exact
                              component={RouterLink}
                              to={`${nestedRoute.href}`}
                            >
                              <span className={classes.collapselistName}>
                                {nestedRoute.name}
                              </span>
                            </Button>
                          </ListItem>
                        ) : null
                      )}
                    </List>
                  </Collapse>
                )}
              </div>
            )}
          </div>
        ))}
      </Box>
      <Box flexGrow={1} />
    </Box>
  );
  // Disabling for developing Environment
  if (!selectedIndex && selectedIndex !== null) {
    setSelectedIndex(getRouteIndex(NavRoutes, location.pathname));
  }
  return (
    <>
      <div>
        <Hidden lgUp>
          <Drawer
            anchor="left"
            classes={{ paper: classes.mobileDrawer }}
            onClose={onMobileClose}
            open={openMobile}
            variant="temporary"
          >
            {content}
          </Drawer>
        </Hidden>
        <Hidden mdDown>
          <Drawer
            className={clsx('desktopMenu')}
            anchor="left"
            classes={{ paper: classes.desktopDrawer }}
            open
            variant="persistent"
          >
            {content}
          </Drawer>
        </Hidden>
        {/* <DashboardNav /> */}
      </div>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

export default NavBar;
