export const AllGender = [
  { id: 'male', name: 'Male' },
  { id: 'female', name: 'Female' },
  { id: 'non_binary', name: 'Non binary' },
  { id: 'prefer_not_to_say', name: 'Prefer not to say' }

  // { id: 'no', name: 'Dont want to specify' }
];

export const ALlStatus = [
  { id: 0, name: 'In Active' },
  { id: 1, name: 'Active' }
];

export const ALLCountries = [
  { id: 'AF', code: 'AFG', name: 'Afghanistan', codenum: '004' },
  { id: 'AL', code: 'ALB', name: 'Albania', codenum: '008' },
  { id: 'DZ', code: 'DZA', name: 'Algeria', codenum: '012' },
  { id: 'AS', code: 'ASM', name: 'American Samoa', codenum: '016' },
  { id: 'AD', code: 'AND', name: 'Andorra', codenum: '020' },
  { id: 'AO', code: 'AGO', name: 'Angola', codenum: '024' },
  { id: 'AI', code: 'AIA', name: 'Anguilla', codenum: '660' },
  { id: 'AQ', code: 'ATA', name: 'Antarctica', codenum: '010' },
  { id: 'AG', code: 'ATG', name: 'Antigua and Barbuda', codenum: '028' },
  { id: 'AR', code: 'ARG', name: 'Argentina', codenum: '032' },
  { id: 'AM', code: 'ARM', name: 'Armenia', codenum: '051' },
  { id: 'AW', code: 'ABW', name: 'Aruba', codenum: '533' },
  { id: 'AU', code: 'AUS', name: 'Australia', codenum: '036' },
  { id: 'AT', code: 'AUT', name: 'Austria', codenum: '040' },
  { id: 'AZ', code: 'AZE', name: 'Azerbaijan', codenum: '031' },
  { id: 'BS', code: 'BHS', name: 'Bahamas (the)', codenum: '044' },
  { id: 'BH', code: 'BHR', name: 'Bahrain', codenum: '048' },
  { id: 'BD', code: 'BGD', name: 'Bangladesh', codenum: '050' },
  { id: 'BB', code: 'BRB', name: 'Barbados', codenum: '052' },
  { id: 'BY', code: 'BLR', name: 'Belarus', codenum: '112' },
  { id: 'BE', code: 'BEL', name: 'Belgium', codenum: '056' },
  { id: 'BZ', code: 'BLZ', name: 'Belize', codenum: '084' },
  { id: 'BJ', code: 'BEN', name: 'Benin', codenum: '204' },
  { id: 'BM', code: 'BMU', name: 'Bermuda', codenum: '060' },
  { id: 'BT', code: 'BTN', name: 'Bhutan', codenum: '064' },
  {
    id: 'BO',
    code: 'BOL',
    name: 'Bolivia (Plurinational State of)',
    codenum: '068'
  },
  {
    id: 'BQ',
    code: 'BES',
    name: 'Bonaire, Sint Eustatius and Saba',
    codenum: '535'
  },
  { id: 'BA', code: 'BIH', name: 'Bosnia and Herzegovina', codenum: '070' },
  { id: 'BW', code: 'BWA', name: 'Botswana', codenum: '072' },
  { id: 'BV', code: 'BVT', name: 'Bouvet Island', codenum: '074' },
  { id: 'BR', code: 'BRA', name: 'Brazil', codenum: '076' },
  {
    id: 'IO',
    code: 'IOT',
    name: 'British Indian Ocean Territory (the)',
    codenum: '086'
  },
  { id: 'BN', code: 'BRN', name: 'Brunei Darussalam', codenum: '096' },
  { id: 'BG', code: 'BGR', name: 'Bulgaria', codenum: '100' },
  { id: 'BF', code: 'BFA', name: 'Burkina Faso', codenum: '854' },
  { id: 'BI', code: 'BDI', name: 'Burundi', codenum: '108' },
  { id: 'CV', code: 'CPV', name: 'Cabo Verde', codenum: '132' },
  { id: 'KH', code: 'KHM', name: 'Cambodia', codenum: '116' },
  { id: 'CM', code: 'CMR', name: 'Cameroon', codenum: '120' },
  { id: 'CA', code: 'CAN', name: 'Canada', codenum: '124' },
  { id: 'KY', code: 'CYM', name: 'Cayman Islands (the)', codenum: '136' },
  {
    id: 'CF',
    code: 'CAF',
    name: 'Central African Republic (the)',
    codenum: '140'
  },
  { id: 'TD', code: 'TCD', name: 'Chad', codenum: '148' },
  { id: 'CL', code: 'CHL', name: 'Chile', codenum: '152' },
  { id: 'CN', code: 'CHN', name: 'China', codenum: '156' },
  { id: 'CX', code: 'CXR', name: 'Christmas Island', codenum: '162' },
  {
    id: 'CC',
    code: 'CCK',
    name: 'Cocos (Keeling) Islands (the)',
    codenum: '166'
  },
  { id: 'CO', code: 'COL', name: 'Colombia', codenum: '170' },
  { id: 'KM', code: 'COM', name: 'Comoros (the)', codenum: '174' },
  {
    id: 'CD',
    code: 'COD',
    name: 'Congo (the Democratic Republic of the)',
    codenum: '180'
  },
  { id: 'CG', code: 'COG', name: 'Congo (the)', codenum: '178' },
  { id: 'CK', code: 'COK', name: 'Cook Islands (the)', codenum: '184' },
  { id: 'CR', code: 'CRI', name: 'Costa Rica', codenum: '188' },
  { id: 'HR', code: 'HRV', name: 'Croatia', codenum: '191' },
  { id: 'CU', code: 'CUB', name: 'Cuba', codenum: '192' },
  { id: 'CW', code: 'CUW', name: 'Curaçao', codenum: '531' },
  { id: 'CY', code: 'CYP', name: 'Cyprus', codenum: '196' },
  { id: 'CZ', code: 'CZE', name: 'Czechia', codenum: '203' },
  { id: 'CI', code: 'CIV', name: "Côte d'Ivoire", codenum: '384' },
  { id: 'DK', code: 'DNK', name: 'Denmark', codenum: '208' },
  { id: 'DJ', code: 'DJI', name: 'Djibouti', codenum: '262' },
  { id: 'DM', code: 'DMA', name: 'Dominica', codenum: '212' },
  { id: 'DO', code: 'DOM', name: 'Dominican Republic (the)', codenum: '214' },
  { id: 'EC', code: 'ECU', name: 'Ecuador', codenum: '218' },
  { id: 'EG', code: 'EGY', name: 'Egypt', codenum: '818' },
  { id: 'SV', code: 'SLV', name: 'El Salvador', codenum: '222' },
  { id: 'GQ', code: 'GNQ', name: 'Equatorial Guinea', codenum: '226' },
  { id: 'ER', code: 'ERI', name: 'Eritrea', codenum: '232' },
  { id: 'EE', code: 'EST', name: 'Estonia', codenum: '233' },
  { id: 'SZ', code: 'SWZ', name: 'Eswatini', codenum: '748' },
  { id: 'ET', code: 'ETH', name: 'Ethiopia', codenum: '231' },
  {
    id: 'FK',
    code: 'FLK',
    name: 'Falkland Islands (the) [Malvinas]',
    codenum: '238'
  },
  { id: 'FO', code: 'FRO', name: 'Faroe Islands (the)', codenum: '234' },
  { id: 'FJ', code: 'FJI', name: 'Fiji', codenum: '242' },
  { id: 'FI', code: 'FIN', name: 'Finland', codenum: '246' },
  { id: 'FR', code: 'FRA', name: 'France', codenum: '250' },
  { id: 'GF', code: 'GUF', name: 'French Guiana', codenum: '254' },
  { id: 'PF', code: 'PYF', name: 'French Polynesia', codenum: '258' },
  {
    id: 'TF',
    code: 'ATF',
    name: 'French Southern Territories (the)',
    codenum: '260'
  },
  { id: 'GA', code: 'GAB', name: 'Gabon', codenum: '266' },
  { id: 'GM', code: 'GMB', name: 'Gambia (the)', codenum: '270' },
  { id: 'GE', code: 'GEO', name: 'Georgia', codenum: '268' },
  { id: 'DE', code: 'DEU', name: 'Germany', codenum: '276' },
  { id: 'GH', code: 'GHA', name: 'Ghana', codenum: '288' },
  { id: 'GI', code: 'GIB', name: 'Gibraltar', codenum: '292' },
  { id: 'GR', code: 'GRC', name: 'Greece', codenum: '300' },
  { id: 'GL', code: 'GRL', name: 'Greenland', codenum: '304' },
  { id: 'GD', code: 'GRD', name: 'Grenada', codenum: '308' },
  { id: 'GP', code: 'GLP', name: 'Guadeloupe', codenum: '312' },
  { id: 'GU', code: 'GUM', name: 'Guam', codenum: '316' },
  { id: 'GT', code: 'GTM', name: 'Guatemala', codenum: '320' },
  { id: 'GG', code: 'GGY', name: 'Guernsey', codenum: '831' },
  { id: 'GN', code: 'GIN', name: 'Guinea', codenum: '324' },
  { id: 'GW', code: 'GNB', name: 'Guinea-Bissau', codenum: '624' },
  { id: 'GY', code: 'GUY', name: 'Guyana', codenum: '328' },
  { id: 'HT', code: 'HTI', name: 'Haiti', codenum: '332' },
  {
    id: 'HM',
    code: 'HMD',
    name: 'Heard Island and McDonald Islands',
    codenum: '334'
  },
  { id: 'VA', code: 'VAT', name: 'Holy See (the)', codenum: '336' },
  { id: 'HN', code: 'HND', name: 'Honduras', codenum: '340' },
  { id: 'HK', code: 'HKG', name: 'Hong Kong', codenum: '344' },
  { id: 'HU', code: 'HUN', name: 'Hungary', codenum: '348' },
  { id: 'IS', code: 'ISL', name: 'Iceland', codenum: '352' },
  { id: 'IN', code: 'IND', name: 'India', codenum: '356' },
  { id: 'ID', code: 'IDN', name: 'Indonesia', codenum: '360' },
  { id: 'IR', code: 'IRN', name: 'Iran (Islamic Republic of)', codenum: '364' },
  { id: 'IQ', code: 'IRQ', name: 'Iraq', codenum: '368' },
  { id: 'IE', code: 'IRL', name: 'Ireland', codenum: '372' },
  { id: 'IM', code: 'IMN', name: 'Isle of Man', codenum: '833' },
  { id: 'IL', code: 'ISR', name: 'Israel', codenum: '376' },
  { id: 'IT', code: 'ITA', name: 'Italy', codenum: '380' },
  { id: 'JM', code: 'JAM', name: 'Jamaica', codenum: '388' },
  { id: 'JP', code: 'JPN', name: 'Japan', codenum: '392' },
  { id: 'JE', code: 'JEY', name: 'Jersey', codenum: '832' },
  { id: 'JO', code: 'JOR', name: 'Jordan', codenum: '400' },
  { id: 'KZ', code: 'KAZ', name: 'Kazakhstan', codenum: '398' },
  { id: 'KE', code: 'KEN', name: 'Kenya', codenum: '404' },
  { id: 'KI', code: 'KIR', name: 'Kiribati', codenum: '296' },
  {
    id: 'KP',
    code: 'PRK',
    name: "Korea (the Democratic People's Republic of)",
    codenum: '408'
  },
  { id: 'KR', code: 'KOR', name: 'Korea (the Republic of)', codenum: '410' },
  { id: 'KW', code: 'KWT', name: 'Kuwait', codenum: '414' },
  { id: 'KG', code: 'KGZ', name: 'Kyrgyzstan', codenum: '417' },
  {
    id: 'LA',
    code: 'LAO',
    name: "Lao People's Democratic Republic (the)",
    codenum: '418'
  },
  { id: 'LV', code: 'LVA', name: 'Latvia', codenum: '428' },
  { id: 'LB', code: 'LBN', name: 'Lebanon', codenum: '422' },
  { id: 'LS', code: 'LSO', name: 'Lesotho', codenum: '426' },
  { id: 'LR', code: 'LBR', name: 'Liberia', codenum: '430' },
  { id: 'LY', code: 'LBY', name: 'Libya', codenum: '434' },
  { id: 'LI', code: 'LIE', name: 'Liechtenstein', codenum: '438' },
  { id: 'LT', code: 'LTU', name: 'Lithuania', codenum: '440' },
  { id: 'LU', code: 'LUX', name: 'Luxembourg', codenum: '442' },
  { id: 'MO', code: 'MAC', name: 'Macao', codenum: '446' },
  { id: 'MG', code: 'MDG', name: 'Madagascar', codenum: '450' },
  { id: 'MW', code: 'MWI', name: 'Malawi', codenum: '454' },
  { id: 'MY', code: 'MYS', name: 'Malaysia', codenum: '458' },
  { id: 'MV', code: 'MDV', name: 'Maldives', codenum: '462' },
  { id: 'ML', code: 'MLI', name: 'Mali', codenum: '466' },
  { id: 'MT', code: 'MLT', name: 'Malta', codenum: '470' },
  { id: 'MH', code: 'MHL', name: 'Marshall Islands (the)', codenum: '584' },
  { id: 'MQ', code: 'MTQ', name: 'Martinique', codenum: '474' },
  { id: 'MR', code: 'MRT', name: 'Mauritania', codenum: '478' },
  { id: 'MU', code: 'MUS', name: 'Mauritius', codenum: '480' },
  { id: 'YT', code: 'MYT', name: 'Mayotte', codenum: '175' },
  { id: 'MX', code: 'MEX', name: 'Mexico', codenum: '484' },
  {
    id: 'FM',
    code: 'FSM',
    name: 'Micronesia (Federated States of)',
    codenum: '583'
  },
  { id: 'MD', code: 'MDA', name: 'Moldova (the Republic of)', codenum: '498' },
  { id: 'MC', code: 'MCO', name: 'Monaco', codenum: '492' },
  { id: 'MN', code: 'MNG', name: 'Mongolia', codenum: '496' },
  { id: 'ME', code: 'MNE', name: 'Montenegro', codenum: '499' },
  { id: 'MS', code: 'MSR', name: 'Montserrat', codenum: '500' },
  { id: 'MA', code: 'MAR', name: 'Morocco', codenum: '504' },
  { id: 'MZ', code: 'MOZ', name: 'Mozambique', codenum: '508' },
  { id: 'MM', code: 'MMR', name: 'Myanmar', codenum: '104' },
  { id: 'NA', code: 'NAM', name: 'Namibia', codenum: '516' },
  { id: 'NR', code: 'NRU', name: 'Nauru', codenum: '520' },
  { id: 'NP', code: 'NPL', name: 'Nepal', codenum: '524' },
  { id: 'NL', code: 'NLD', name: 'Netherlands (the)', codenum: '528' },
  { id: 'NC', code: 'NCL', name: 'New Caledonia', codenum: '540' },
  { id: 'NZ', code: 'NZL', name: 'New Zealand', codenum: '554' },
  { id: 'NI', code: 'NIC', name: 'Nicaragua', codenum: '558' },
  { id: 'NE', code: 'NER', name: 'Niger (the)', codenum: '562' },
  { id: 'NG', code: 'NGA', name: 'Nigeria', codenum: '566' },
  { id: 'NU', code: 'NIU', name: 'Niue', codenum: '570' },
  { id: 'NF', code: 'NFK', name: 'Norfolk Island', codenum: '574' },
  {
    id: 'MP',
    code: 'MNP',
    name: 'Northern Mariana Islands (the)',
    codenum: '580'
  },
  { id: 'NO', code: 'NOR', name: 'Norway', codenum: '578' },
  { id: 'OM', code: 'OMN', name: 'Oman', codenum: '512' },
  { id: 'PK', code: 'PAK', name: 'Pakistan', codenum: '586' },
  { id: 'PW', code: 'PLW', name: 'Palau', codenum: '585' },
  { id: 'PS', code: 'PSE', name: 'Palestine, State of', codenum: '275' },
  { id: 'PA', code: 'PAN', name: 'Panama', codenum: '591' },
  { id: 'PG', code: 'PNG', name: 'Papua New Guinea', codenum: '598' },
  { id: 'PY', code: 'PRY', name: 'Paraguay', codenum: '600' },
  { id: 'PE', code: 'PER', name: 'Peru', codenum: '604' },
  { id: 'PH', code: 'PHL', name: 'Philippines (the)', codenum: '608' },
  { id: 'PN', code: 'PCN', name: 'Pitcairn', codenum: '612' },
  { id: 'PL', code: 'POL', name: 'Poland', codenum: '616' },
  { id: 'PT', code: 'PRT', name: 'Portugal', codenum: '620' },
  { id: 'PR', code: 'PRI', name: 'Puerto Rico', codenum: '630' },
  { id: 'QA', code: 'QAT', name: 'Qatar', codenum: '634' },
  {
    id: 'MK',
    code: 'MKD',
    name: 'Republic of North Macedonia',
    codenum: '807'
  },
  { id: 'RO', code: 'ROU', name: 'Romania', codenum: '642' },
  { id: 'RU', code: 'RUS', name: 'Russian Federation (the)', codenum: '643' },
  { id: 'RW', code: 'RWA', name: 'Rwanda', codenum: '646' },
  { id: 'RE', code: 'REU', name: 'Réunion', codenum: '638' },
  { id: 'BL', code: 'BLM', name: 'Saint Barthélemy', codenum: '652' },
  {
    id: 'SH',
    code: 'SHN',
    name: 'Saint Helena, Ascension and Tristan da Cunha',
    codenum: '654'
  },
  { id: 'KN', code: 'KNA', name: 'Saint Kitts and Nevis', codenum: '659' },
  { id: 'LC', code: 'LCA', name: 'Saint Lucia', codenum: '662' },
  { id: 'MF', code: 'MAF', name: 'Saint Martin (French part)', codenum: '663' },
  { id: 'PM', code: 'SPM', name: 'Saint Pierre and Miquelon', codenum: '666' },
  {
    id: 'VC',
    code: 'VCT',
    name: 'Saint Vincent and the Grenadines',
    codenum: '670'
  },
  { id: 'WS', code: 'WSM', name: 'Samoa', codenum: '882' },
  { id: 'SM', code: 'SMR', name: 'San Marino', codenum: '674' },
  { id: 'ST', code: 'STP', name: 'Sao Tome and Principe', codenum: '678' },
  { id: 'SA', code: 'SAU', name: 'Saudi Arabia', codenum: '682' },
  { id: 'SN', code: 'SEN', name: 'Senegal', codenum: '686' },
  { id: 'RS', code: 'SRB', name: 'Serbia', codenum: '688' },
  { id: 'SC', code: 'SYC', name: 'Seychelles', codenum: '690' },
  { id: 'SL', code: 'SLE', name: 'Sierra Leone', codenum: '694' },
  { id: 'SG', code: 'SGP', name: 'Singapore', codenum: '702' },
  { id: 'SX', code: 'SXM', name: 'Sint Maarten (Dutch part)', codenum: '534' },
  { id: 'SK', code: 'SVK', name: 'Slovakia', codenum: '703' },
  { id: 'SI', code: 'SVN', name: 'Slovenia', codenum: '705' },
  { id: 'SB', code: 'SLB', name: 'Solomon Islands', codenum: '090' },
  { id: 'SO', code: 'SOM', name: 'Somalia', codenum: '706' },
  { id: 'ZA', code: 'ZAF', name: 'South Africa', codenum: '710' },
  {
    id: 'GS',
    code: 'SGS',
    name: 'South Georgia and the South Sandwich Islands',
    codenum: '239'
  },
  { id: 'SS', code: 'SSD', name: 'South Sudan', codenum: '728' },
  { id: 'ES', code: 'ESP', name: 'Spain', codenum: '724' },
  { id: 'LK', code: 'LKA', name: 'Sri Lanka', codenum: '144' },
  { id: 'SD', code: 'SDN', name: 'Sudan (the)', codenum: '729' },
  { id: 'SR', code: 'SUR', name: 'Suriname', codenum: '740' },
  { id: 'SJ', code: 'SJM', name: 'Svalbard and Jan Mayen', codenum: '744' },
  { id: 'SE', code: 'SWE', name: 'Sweden', codenum: '752' },
  { id: 'CH', code: 'CHE', name: 'Switzerland', codenum: '756' },
  { id: 'SY', code: 'SYR', name: 'Syrian Arab Republic', codenum: '760' },
  { id: 'TW', code: 'TWN', name: 'Taiwan', codenum: '158' },
  { id: 'TJ', code: 'TJK', name: 'Tajikistan', codenum: '762' },
  {
    id: 'TZ',
    code: 'TZA',
    name: 'Tanzania, United Republic of',
    codenum: '834'
  },
  { id: 'TH', code: 'THA', name: 'Thailand', codenum: '764' },
  { id: 'TL', code: 'TLS', name: 'Timor-Leste', codenum: '626' },
  { id: 'TG', code: 'TGO', name: 'Togo', codenum: '768' },
  { id: 'TK', code: 'TKL', name: 'Tokelau', codenum: '772' },
  { id: 'TO', code: 'TON', name: 'Tonga', codenum: '776' },
  { id: 'TT', code: 'TTO', name: 'Trinidad and Tobago', codenum: '780' },
  { id: 'TN', code: 'TUN', name: 'Tunisia', codenum: '788' },
  { id: 'TR', code: 'TUR', name: 'Turkey', codenum: '792' },
  { id: 'TM', code: 'TKM', name: 'Turkmenistan', codenum: '795' },
  {
    id: 'TC',
    code: 'TCA',
    name: 'Turks and Caicos Islands (the)',
    codenum: '796'
  },
  { id: 'TV', code: 'TUV', name: 'Tuvalu', codenum: '798' },
  { id: 'UG', code: 'UGA', name: 'Uganda', codenum: '800' },
  { id: 'UA', code: 'UKR', name: 'Ukraine', codenum: '804' },
  { id: 'AE', code: 'ARE', name: 'United Arab Emirates (the)', codenum: '784' },
  {
    id: 'GB',
    code: 'GBR',
    name: 'United Kingdom of Great Britain and Northern Ireland (the)',
    codenum: '826'
  },
  {
    id: 'UM',
    code: 'UMI',
    name: 'United States Minor Outlying Islands (the)',
    codenum: '581'
  },
  {
    id: 'US',
    code: 'USA',
    name: 'United States of America (the)',
    codenum: '840'
  },
  { id: 'UY', code: 'URY', name: 'Uruguay', codenum: '858' },
  { id: 'UZ', code: 'UZB', name: 'Uzbekistan', codenum: '860' },
  { id: 'VU', code: 'VUT', name: 'Vanuatu', codenum: '548' },
  {
    id: 'VE',
    code: 'VEN',
    name: 'Venezuela (Bolivarian Republic of)',
    codenum: '862'
  },
  { id: 'VN', code: 'VNM', name: 'Viet Nam', codenum: '704' },
  { id: 'VG', code: 'VGB', name: 'Virgin Islands (British)', codenum: '092' },
  { id: 'VI', code: 'VIR', name: 'Virgin Islands (U.S.)', codenum: '850' },
  { id: 'WF', code: 'WLF', name: 'Wallis and Futuna', codenum: '876' },
  { id: 'EH', code: 'ESH', name: 'Western Sahara', codenum: '732' },
  { id: 'YE', code: 'YEM', name: 'Yemen', codenum: '887' },
  { id: 'ZM', code: 'ZMB', name: 'Zambia', codenum: '894' },
  { id: 'ZW', code: 'ZWE', name: 'Zimbabwe', codenum: '716' },
  { id: 'AX', code: 'ALA', name: 'Åland Islands', codenum: '248' }
];
