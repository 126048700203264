import React, { useState, useContext } from 'react';
// import PropType from 'prop-types';
import { VanguardMem } from 'src/Context/VanguardMembershipContext';
import {
  setAuto,
  setPytOpt,
  setRenewal,
  setRenewOpt,
  setRenewalBilled
} from 'src/Context/VanguardMembership/VanguardMembership.actions';
import GetPrice from 'src/views/common/CurrencySymbol/GetPrice';
import clsx from 'clsx';
import {
  Typography,
  makeStyles,
  Grid,
  RadioGroup,
  Radio,
  FormControlLabel,
  Box,
  Button,
  InputLabel,
  Checkbox
} from '@material-ui/core';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CustomAutocomplete from 'src/views/common/CustomAutocomplete/commonAutocomplete';
import CheckIcon from '@material-ui/icons/Check';
import FormControl from 'src/views/common/FormControl/formControl';
import { checkValidRenewCommitement } from '../Vanguard.utills';
// import JBDatePicker from 'src/views/common/FormControl/JBDatePicker';
import { ReactComponent as DownIcon } from '../../../../assets/image/Vanguard/downIcon.svg';

const useStyles = makeStyles(() => ({
  mainTitle: {
    fontWeight: '500',
    fontSize: '48px',
    lineHeight: '44px',
    color: '#FFFFFF',
    fontFamily: 'DDC Hardware',
    textAlign: 'center',
    margin: '38px 0 0 0',
    '@media (max-width: 992px)': {
      fontSize: '35px'
    }
  },
  subTitle: {
    fontWeight: 'bold',
    fontSize: '24px',
    lineHeight: '38px',
    color: '#FFFFFF',
    fontFamily: 'Lato',
    textAlign: 'center',
    margin: '38px 0 0 0',
    letterSpacing: '0.1px'
  },

  cutom_select_Label: {
    fontFamily: 'DDC Hardware',
    fontStyle: 'normal',
    fontWeight: '500;',
    fontSize: '22px',
    lineHeight: '32px',
    letterSpacing: '0.2px',
    color: '#FFFFFF',
    '&:focus ': {
      outline: '0'
    },
    '& .MuiFormLabel-asterisk': {
      color: 'transparent'
    },
    '& .MuiFormLabel-asterisk:after': {
      content: '""',
      background: '#2CE9DA',
      display: 'inline-block',
      position: 'relative',
      width: '8px',
      height: '8px',
      borderRadius: '50%',
      top: '-14px',
      right: '10px'
    },
    '@media (max-width: 850px)': {
      fontSize: '18.3px'
    }
  },
  cutom_vanguard_FormControl: {
    '& .fd-input input': {
      background: 'rgba(196, 196, 196, 0.05)',
      color: '#fff',
      borderRadius: '6px',
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '20px',
      height: '45px',
      border: '2px solid #C8C8C8',
      padding: '8px 20px 8px',
      '&::placeholder ': {
        color: '#fff',
        fontWeight: 'bold',
        fontSize: '15px'
      }
    },
    '&  .MuiPaper-root': {
      backgroundColor: '#2d2d2d',
      marginTop: '0',
      marginBottom: '0',
      border: '2px solid #C8C8C8',
      borderTop: 'transparent',
      boxShadow: 'none',
      // borderRadius: '0',
      borderTopLeftRadius: '0',
      borderTopRightRadius: '0'
    },
    '& .MuiAutocomplete-option': {
      color: '#fff',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '20px',
      height: '66px'
    },
    '& .MuiAutocomplete-popper': {
      width: '100% !important',
      marginTop: '-2px'
    },
    '& .SelectDrop[aria-expanded="true"]': {
      borderBottom: '2px solid transparent',
      borderBottomLeftRadius: '0',
      borderBottomRightRadius: '0'
    }
  },

  cutom_vanguard_Drop: {
    '& .MuiAutocomplete-popper ': {
      width: 'calc(100% - 16px) !important'
    },
    '& .MuiAutocomplete-hasPopupIcon .MuiAutocomplete-inputRoot, .MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot': {
      paddingRight: '50px !important'
    }
  },
  cutom_vanguard_Autocomplete: {
    background: 'rgba(196, 196, 196, 0.05)',
    border: '2px solid #C8C8C8',
    color: '#fff',
    borderRadius: '6px',
    '& .cutom_select div': {
      minHeight: '62px'
    },
    '& .cutom_select div input ': {
      color: '#ffffff !important',
      fontWeight: 'bold',
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontSize: '20px',
      '&::placeholder': {
        fontSize: '20px !important',
        color: '#b9b9ba !important',
        fontWeight: 'bold !important'
      }
    },
    '& .cutom_select div input::placeholder': {
      fontSize: '20px !important',
      color: '#b9b9ba !important',
      fontWeight: 'bold !important'
    },
    '& .MuiAutocomplete-endAdornment': {
      minHeight: ' auto !important',
      top: 'calc(50% - 12px)'
    }
  },
  customRadio: {
    '& .MuiFormControlLabel-root svg.MuiSvgIcon-root': {
      width: '30px',
      height: '30px',
      fill: 'none',
      borderRadius: '50%',
      border: '2px solid #4B4B4B'
    },
    '& .MuiFormControlLabel-root.checked svg.MuiSvgIcon-root': {
      background: '#67e9da',
      padding: '4px',
      fill: '#000',
      color: '#060606',
      border: '0px solid #4B4B4B'
    },
    '& .MuiRadio-root': {
      padding: '20px'
    },
    '& .MuiFormControlLabel-label': {
      color: '#fff',
      fontSize: '20px',
      lineHeight: '26px',
      fontWeight: 'bold',
      WebkitLineClamp: '2',
      WebkitBoxOrient: 'vertical',
      display: '-webkit-box',
      whiteSpace: 'normal',
      overflow: 'hidden',
      height: 'auto',
      minHeight: 'auto',
      width: '245px',
      textAlign: 'left',
      textTransform: 'initial',
      '@media (max-width: 850px)': {
        width: '130px'
      }
    }
  },
  customGrid: {
    '& .customGridData': {
      background: 'rgba(196, 196, 196, 0.05)',
      border: '2px solid #7B7B7B',
      borderRadius: '6px',
      height: '70px',
      margin: '0 0 15px',
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      textTransform: 'capitalize'
    },
    '& .customGridData.checked': {
      background: 'rgba(44, 233, 218, 0.15)',
      border: '2px solid #2CE9DA',
      borderRadius: '6px'
    }
  },
  customGridItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '140px',
    color: '#fff',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '26px',
    alignItems: 'flex-start',
    '& h5 ': {
      fontFamily: 'Lato',
      color: 'rgb(255 255 255 / 60%)',
      fontWeight: 'normal',
      fontSize: '20px',
      lineHeight: '32px'
    }
  },
  VanguardCutom_date: {
    '& .MuiInput-root': {
      color: '#fff',
      height: '65px',
      fontWeight: '600',
      fontSize: '20px',
      lineHeight: '18px',
      marginTop: '5px !important',
      border: '2px solid #C8C8C8',
      borderRadius: '6px'
    }
  },
  labelInfo: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '20px',
    lineHeight: '26px',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.2px',
    color: '#FFFFFF',
    paddingLeft: '6px'
  },
  cutom_Text_label: {
    background: 'rgba(196, 196, 196, 0.05)',
    border: '2px solid rgba(200, 200, 200, 0.2)',
    borderRadius: '6px',
    '& label': {
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '20px',
      display: 'flex',
      alignItems: 'center',
      letterSpacing: '0.2px',
      color: '#8f8f8f',
      minHeight: '62px',
      padding: '0 15px'
    }
  },
  VanCustomcheckbox: {
    width: '100%',
    '& .MuiFormControlLabel-root svg.MuiSvgIcon-root': {
      width: '30px',
      height: '30px',
      fill: 'none',
      borderRadius: '6px',
      border: '2px solid #4B4B4B'
    },
    '& .MuiFormControlLabel-root.checked svg.MuiSvgIcon-root': {
      background: '#67e9da',
      padding: '4px',
      fill: '#000',
      color: '#060606',
      border: '0px solid #4B4B4B'
    },
    '& .MuiTypography-root': {
      padding: '20px 15px'
    },
    '& .MuiFormControlLabel-label': {
      color: '#fff',
      fontSize: '20px',
      lineHeight: '26px',
      fontWeight: 'bold'
    }
  },
  cutomWrapper: {
    width: 'calc(100% - 62%)'
  },
  cutomWrapper2: {
    width: 'calc(100% - 88%)',
    textAlign: 'center'
  }
}));

export const billedWeek = [
  { id: 2, name: '1' },
  { id: 0, name: '2' },
  { id: 1, name: '4' }
];

const PaymentAutoRenew = () => {
  const classes = useStyles();
  const [state, dispatch] = useContext(VanguardMem);
  const {
    memebership_data: {
      membershipdata: { payment_data }
    }
  } = state;
  // eslint-disable-next-line no-unused-vars
  const [paymentData, setPaymentData] = useState(() => payment_data);
  const [, setData] = useState({
    value: ''
  });
  // eslint-disable-next-line no-unused-vars
  const [check, setcheck] = useState(() => Boolean(payment_data.auto_renew));

  return (
    <div>
      <Typography variant="h3" className={classes.mainTitle}>
        Payment & Auto-renew
      </Typography>
      <Typography variant="h5" className={classes.subTitle}>
        Select the payment option type and pricing for this member plan.
      </Typography>
      <Grid
        container
        xs={10}
        justify="center"
        style={{
          margin: '45px auto'
        }}
      >
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={2}
          // style={{ margin: '20px 0 20px' }}
        >
          <Grid xs={12}>
            <RadioGroup
              value={String(payment_data.paymentOpt)}
              onChange={(e) => {
                // eslint-disable-next-line radix
                dispatch(setPytOpt(parseInt(e.target.value)));
              }}
              className={classes.customRadio}
            >
              <Grid xs={12} className={classes.customGrid}>
                <Button
                  className={clsx(
                    'customGridData',
                    `${
                      String(payment_data.paymentOpt) === '1' ? 'checked' : ''
                    }`
                  )}
                  onClick={() => {
                    setData({
                      value: '1'
                    });
                  }}
                >
                  <Box>
                    <FormControlLabel
                      value="1"
                      control={
                        <Radio
                          color="primary"
                          checkedIcon={
                            <CheckIcon
                              htmlColor="#2CE9DA"
                              width="30px"
                              height="30px"
                            />
                          }
                          icon={
                            <RadioButtonUncheckedIcon
                              width="30px"
                              height="30px"
                            />
                          }
                        />
                      }
                      label="Pay in Full"
                      className={`${
                        String(payment_data.paymentOpt) === '1' ? 'checked' : ''
                      }`}
                    />
                  </Box>
                  <Box className={classes.customGridItem}>
                    Setup fee
                    <h5>
                      <GetPrice
                        value={
                          payment_data.initial_commitment_pricing
                            .full_setup_fees
                        }
                      />
                    </h5>
                  </Box>
                  <Box className={classes.customGridItem}>
                    Fee
                    <h5>
                      <GetPrice
                        value={
                          payment_data.initial_commitment_pricing
                            .full_membership_fees
                        }
                      />
                    </h5>
                  </Box>
                  <Box className={classes.customGridItem}>
                    Tax
                    <h5>
                      <GetPrice
                        value={
                          payment_data.initial_commitment_pricing
                            .full_tax_amount
                        }
                      />
                    </h5>
                  </Box>
                  <Box className={classes.customGridItem}>
                    Total
                    <h5 style={{ color: '#fff' }}>
                      <GetPrice
                        value={
                          payment_data.initial_commitment_pricing.full_total
                        }
                      />
                    </h5>
                  </Box>
                </Button>
              </Grid>
              <Grid xs={12} className={classes.customGrid}>
                <Button
                  className={clsx(
                    'customGridData',
                    `${
                      String(payment_data.paymentOpt) === '2' ? 'checked' : ''
                    }`
                  )}
                  onClick={() => {
                    setData({
                      value: '2'
                    });
                  }}
                >
                  <Box>
                    <FormControlLabel
                      value="2"
                      control={
                        <Radio
                          color="primary"
                          checkedIcon={
                            <CheckIcon
                              htmlColor="#2CE9DA"
                              width="30px"
                              height="30px"
                            />
                          }
                          icon={
                            <RadioButtonUncheckedIcon
                              width="30px"
                              height="30px"
                            />
                          }
                        />
                      }
                      label="Weekly"
                      className={`${
                        String(payment_data.paymentOpt) === '2' ? 'checked' : ''
                      }`}
                    />
                  </Box>
                  <Box className={classes.customGridItem}>
                    Setup fee
                    <h5>
                      <GetPrice
                        value={
                          payment_data.initial_commitment_pricing
                            .week_setup_fees
                        }
                      />
                    </h5>
                  </Box>
                  <Box className={classes.customGridItem}>
                    Fee
                    <h5>
                      <GetPrice
                        value={
                          payment_data.initial_commitment_pricing
                            .week_membership_fees
                        }
                      />
                    </h5>
                  </Box>
                  <Box className={classes.customGridItem}>
                    Tax
                    <h5>
                      <GetPrice
                        value={
                          payment_data.initial_commitment_pricing
                            .week_tax_amount
                        }
                      />
                    </h5>
                  </Box>
                  <Box className={classes.customGridItem}>
                    Total
                    <h5 style={{ color: '#fff' }}>
                      {' '}
                      <GetPrice
                        value={
                          payment_data.initial_commitment_pricing.week_total
                        }
                      />
                    </h5>
                  </Box>
                </Button>
              </Grid>
            </RadioGroup>
          </Grid>
        </Grid>
        {check && (
          <>
            <Typography variant="h5" className={classes.subTitle}>
              Configure the auto-renew options for this member plan.
            </Typography>
            <Box mt="10px" className={classes.VanCustomcheckbox}>
              <FormControlLabel
                // onChange={(e) => {
                //   setData({ ...data, value: e.target.value });
                // }}
                control={
                  <Checkbox
                    checked={payment_data.auto_renew === 1}
                    onChange={() =>
                      dispatch(setAuto(payment_data.auto_renew ? 0 : 1))
                    }
                    name="checkedB"
                    color="primary"
                    checkedIcon={
                      <CheckIcon
                        htmlColor="#2CE9DA"
                        width="30px"
                        height="30px"
                      />
                    }
                  />
                }
                label="Enable Auto-renew"
                className={`${payment_data.auto_renew === 1 ? 'checked' : ''}`}
              />
            </Box>
          </>
        )}
        {payment_data.auto_renew === 1 && (
          <>
            <Grid
              container
              direction="row"
              // justify="center"
              alignItems="center"
              // spacing={2}
              style={{ margin: '24px 0 0px' }}
            >
              <Grid
                item
                className={clsx(
                  classes.cutom_vanguard_FormControl,
                  // classes.cutom_vanguard_Drop,
                  classes.cutomWrapper
                )}
                style={{
                  position: 'relative'
                }}
              >
                <InputLabel
                  htmlFor="age-required"
                  className={classes.cutom_select_Label}
                  style={{ margin: '0px 0 4px' }}
                >
                  Renewal commitment
                </InputLabel>

                <FormControl
                  control="input"
                  value={payment_data.auto_renew_meta.renewal}
                  error={checkValidRenewCommitement(payment_data)}
                  helperText={
                    checkValidRenewCommitement(payment_data)
                      ? 'renewal commitment should not be less than billing cycle'
                      : null
                  }
                  onChange={(e) => dispatch(setRenewal(e.target.value))}
                  label=""
                  maxLength={50}
                  style={{
                    margin: '0 0'
                  }}
                />
              </Grid>
              <Grid
                item
                // xs={1}
                className={clsx(
                  classes.cutom_vanguard_FormControl,
                  classes.cutomWrapper2
                )}
                style={{
                  margin: '0px 0 0px'
                }}
              >
                <InputLabel
                  htmlFor="age-required"
                  className={classes.cutom_select_Label}
                  style={{ margin: '0px 0 6px' }}
                >
                  &nbsp;
                </InputLabel>
                <label
                  className={classes.labelInfo}
                  style={{
                    display: 'block',
                    padding: '0'
                  }}
                >
                  Weeks
                </label>
              </Grid>
              <Grid
                item
                className={clsx(
                  classes.cutom_vanguard_FormControl,
                  // classes.cutom_vanguard_Drop
                  classes.cutomWrapper
                )}
                style={{
                  position: 'relative'
                }}
              >
                <InputLabel
                  htmlFor="age-required"
                  required
                  className={classes.cutom_select_Label}
                  style={{ margin: '0px 0 6px' }}
                >
                  Member is billed every
                </InputLabel>
                <CustomAutocomplete
                  disablePortal
                  value={billedWeek}
                  Options="name"
                  data={payment_data.auto_renew_meta.billed_every}
                  popupIcon={<DownIcon />}
                  Change={(val) => dispatch(setRenewalBilled(val))}
                  disableClearable
                  // className={classes.cutom_vanguard_Autocomplete}
                  className={clsx(
                    classes.cutom_vanguard_Autocomplete,
                    'SelectDrop'
                  )}
                  // Change={(val) => {
                  //   setData1({ ...data1, lead_source: val });
                  // }}
                />
              </Grid>
              <Grid
                item
                xs
                className={clsx(
                  classes.cutom_vanguard_FormControl,
                  classes.cutomWrapper2
                )}
                style={{
                  margin: '0px 0 0px'
                }}
              >
                <InputLabel
                  htmlFor="age-required"
                  className={classes.cutom_select_Label}
                  style={{ margin: '0px 0 6px' }}
                >
                  &nbsp;
                </InputLabel>
                <label
                  className={classes.labelInfo}
                  style={{
                    display: 'block',
                    padding: '0'
                  }}
                >
                  Weeks
                </label>
              </Grid>
              <Box
                mt="25px"
                css={{
                  width: '100%'
                }}
              />
            </Grid>
            <Grid xs={12}>
              <RadioGroup
                value={String(payment_data.renewOpt)}
                onChange={(e) => {
                  // eslint-disable-next-line radix
                  dispatch(setRenewOpt(parseInt(e.target.value)));
                }}
                className={classes.customRadio}
              >
                <Grid xs={12} className={classes.customGrid}>
                  <Button
                    className={clsx(
                      'customGridData',
                      `${
                        String(payment_data.renewOpt) === '1' ? 'checked' : ''
                      }`
                    )}
                    onClick={() => {
                      setData({
                        value: '1'
                      });
                    }}
                  >
                    <Box>
                      <FormControlLabel
                        value="1"
                        control={
                          <Radio
                            color="primary"
                            checkedIcon={
                              <CheckIcon
                                htmlColor="#2CE9DA"
                                width="30px"
                                height="30px"
                              />
                            }
                            icon={
                              <RadioButtonUncheckedIcon
                                width="30px"
                                height="30px"
                              />
                            }
                          />
                        }
                        label="Pay in Full"
                        className={`${
                          String(payment_data.renewOpt) === '1' ? 'checked' : ''
                        }`}
                      />
                    </Box>

                    <Box className={classes.customGridItem}>
                      Fee
                      <h5>
                        {' '}
                        <GetPrice
                          value={
                            payment_data.auto_renewal_pricing
                              .full_membership_fees
                          }
                        />
                      </h5>
                    </Box>
                    <Box className={classes.customGridItem}>
                      Tax
                      <h5>
                        <GetPrice
                          value={
                            payment_data.auto_renewal_pricing.full_tax_amount
                          }
                        />
                      </h5>
                    </Box>
                    <Box className={classes.customGridItem}>
                      Total
                      <h5 style={{ color: '#fff' }}>
                        <GetPrice
                          value={payment_data.auto_renewal_pricing.full_total}
                        />
                      </h5>
                    </Box>
                  </Button>
                </Grid>
                <Grid xs={12} className={classes.customGrid}>
                  <Button
                    className={clsx(
                      'customGridData',
                      `${
                        String(payment_data.renewOpt) === '2' ? 'checked' : ''
                      }`
                    )}
                    onClick={() => {
                      setData({
                        value: '2'
                      });
                    }}
                  >
                    <Box>
                      <FormControlLabel
                        value="2"
                        control={
                          <Radio
                            color="primary"
                            checkedIcon={
                              <CheckIcon
                                htmlColor="#2CE9DA"
                                width="30px"
                                height="30px"
                              />
                            }
                            icon={
                              <RadioButtonUncheckedIcon
                                width="30px"
                                height="30px"
                              />
                            }
                          />
                        }
                        label="Weekly"
                        className={`${
                          String(payment_data.renewOpt) === '2' ? 'checked' : ''
                        }`}
                      />
                    </Box>

                    <Box className={classes.customGridItem}>
                      Fee
                      <h5>
                        <GetPrice
                          value={
                            payment_data.auto_renewal_pricing
                              .week_membership_fees
                          }
                        />
                      </h5>
                    </Box>
                    <Box className={classes.customGridItem}>
                      Tax
                      <h5>
                        <GetPrice
                          value={
                            payment_data.auto_renewal_pricing.week_tax_amount
                          }
                        />
                      </h5>
                    </Box>
                    <Box className={classes.customGridItem}>
                      Total
                      <h5 style={{ color: '#fff' }}>
                        <GetPrice
                          value={payment_data.auto_renewal_pricing.week_total}
                        />
                      </h5>
                    </Box>
                  </Button>
                </Grid>
              </RadioGroup>
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
};

// PaymentAutoRenew.propTypes = {
// setOpen: PropType.bool.isRequired,
// handleClose: PropType.func.isRequired
// handleBack: PropType.func,
// handleHistory: PropType.string
// };

export default PaymentAutoRenew;
