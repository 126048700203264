/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import clsx from 'clsx';
import { Box, withStyles } from '@material-ui/core';

const styles = () => ({
  // root: {
  //   height: '100%'
  // }
});

// const data = {
//   labels: ['1', '2', '3', '4', '5', '6'],
//   datasets: [
//     {
//       label: '# of Red Votes',
//       data: [12, 19, 3, 5, 2, 3],
//       backgroundColor: 'rgb(255, 99, 132)'
//     },
//     {
//       label: '# of Blue Votes',
//       data: [2, 3, 20, 5, 1, 4],
//       backgroundColor: 'rgb(54, 162, 235)'
//     },
//     {
//       label: '# of Green Votes',
//       data: [3, 10, 13, 15, 22, 30],
//       backgroundColor: 'rgb(75, 192, 192)'
//     }
//   ]
// };

const StackedBarChart = ({
  data,
  classes,
  children,
  xlabel,
  ylabel,
  ...props
}) => {
  const options = {
    scales: {
      yAxes: [
        {
          stacked: true,
          ticks: {
            beginAtZero: true
          },
          scaleLabel: {
            display: true,
            labelString: ylabel
          }
        }
      ],
      xAxes: [
        {
          stacked: true,
          scaleLabel: {
            display: true,
            labelString: xlabel
          }
        }
      ]
    },

    legend: {
      display: true,
      position: 'bottom'
      // labels: {
      //   fontColor: 'rgb(255, 99, 132)'
      // }
    },
    responsive: true,
    maintainAspectRatio: false
  };

  return (
    <Box
      height={300}
      width={460}
      position="relative"
      display="inline-block"
      className={clsx(classes.root)}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {children}
      <Bar
        data={data}
        options={{
          ...options,
          // eslint-disable-next-line react/prop-types
          legend: { display: props.display, position: 'bottom' }
        }}
        ylabel={ylabel}
        xlabel={xlabel}
      />
    </Box>
  );
};

StackedBarChart.defaultProps = {
  ylabel: 'REVENUE',
  xlabel: 'REVENUE CATEGORY'
};

StackedBarChart.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  children: PropTypes.string.isRequired,
  ylabel: PropTypes.string,
  xlabel: PropTypes.string
};

export default withStyles(styles)(StackedBarChart);
