/* eslint-disable operator-linebreak */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropType from 'prop-types';
import { getLeadSrc } from 'src/services/Leadmodule.services';
import { quickCreateNewLead } from 'src/services/vanguard.services';
import { CheckEmailAvaibility } from 'src/services/Members.module';
import { openNoti } from 'src/Redux/global/global.actions';
import { checkEmail, isValidMobile, validMobile } from 'src/utils/validations';
import {
  Dialog,
  Button,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  makeStyles,
  Card,
  Grid,
  Box,
  InputLabel
} from '@material-ui/core';
import Slide from '@material-ui/core/Slide';
import clsx from 'clsx';
import VanguardListLoadingView from '../common/loading/VanguardListLoadingView';
import { ReactComponent as BackArrow } from '../../assets/image/Vanguard/icon_back_vanguard.svg';
import { ReactComponent as DownIcon } from '../../assets/image/Vanguard/downIcon.svg';
import FormControl from '../common/FormControl/formControl';
import CustomAutocomplete from '../common/CustomAutocomplete/commonAutocomplete';
import JBVanguardButton from '../common/JBVanguardButton/JBButton';
import SuccessPopup from './successPopup';
import LeavePagePopup from './LeavepagePopup';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    background: 'rgba(29, 29, 29, 0.94)',
    boxShadow: '0px 0.33333px 0px rgb(255 255 255 / 15%)',
    backdropFilter: 'blur(54.3656px)'
  },
  appToolbar: {
    minHeight: '75px',
    justifyContent: 'space-between'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
  mainTitle: {
    fontWeight: '500',
    fontSize: '48px',
    lineHeight: '44px',
    color: '#FFFFFF',
    fontFamily: 'DDC Hardware',
    textAlign: 'center',
    margin: '48px 0 0 0',
    '@media (max-width: 992px)': {
      fontSize: '35px'
    }
  },
  MainCard: {
    height: 'calc(100vh - 75px )',
    background:
      'linear-gradient(180deg, rgba(41, 41, 41, 0.95) 0%, rgba(4, 4, 4, 0.95) 100%)',
    borderRadius: '0',
    flex: '1 1 auto',
    // height: '100%',
    overflow: 'auto'
  },
  HomeButton: {
    fontFamily: 'Lato',
    color: '#2CE9DA',
    fontWeight: 'bold',
    fontSize: '22px',
    lineHeight: '32px',
    textTransform: 'capitalize'
  },
  cutom_select_Label: {
    fontFamily: 'DDC Hardware',
    fontStyle: 'normal',
    fontWeight: '500;',
    fontSize: '22px',
    lineHeight: '32px',
    letterSpacing: '0.2px',
    color: '#FFFFFF',
    '&:focus ': {
      outline: '0'
    },
    '& .MuiFormLabel-asterisk': {
      color: 'transparent'
    },
    '& .MuiFormLabel-asterisk:after': {
      content: '""',
      background: '#2CE9DA',
      display: 'inline-block',
      position: 'relative',
      width: '8px',
      height: '8px',
      borderRadius: '50%',
      top: '-14px',
      right: '10px'
    }
  },
  cutom_vanguard_FormControl: {
    '& .fd-input input': {
      background: 'rgba(196, 196, 196, 0.05)',
      color: '#fff',
      borderRadius: '6px',
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '20px',
      height: '45px',
      border: '2px solid #C8C8C8',
      padding: '8px 20px 8px',
      '&::placeholder ': {
        color: '#fff',
        fontWeight: 'bold',
        fontSize: '15px'
      }
    },
    '&  .MuiPaper-root': {
      backgroundColor: '#2d2d2d',
      marginTop: '0',
      marginBottom: '0',
      border: '2px solid #C8C8C8',
      borderTop: 'transparent',
      boxShadow: 'none',
      // borderRadius: '0',
      borderTopLeftRadius: '0',
      borderTopRightRadius: '0'
    },
    '& .MuiAutocomplete-option': {
      color: '#fff',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '20px',
      height: '66px'
    },
    '& .MuiAutocomplete-popper': {
      width: '100% !important',
      marginTop: '-2px'
    },
    '& .SelectDrop[aria-expanded="true"]': {
      borderBottom: '2px solid transparent',
      borderBottomLeftRadius: '0',
      borderBottomRightRadius: '0'
    }
  },
  cutom_vanguard_Drop: {
    '& .MuiAutocomplete-popper ': {
      width: 'calc(100% - 16px) !important'
    }
  },
  cutom_vanguard_Autocomplete: {
    background: 'rgba(196, 196, 196, 0.05)',
    border: '2px solid #C8C8C8',
    color: '#fff',
    borderRadius: '6px',
    '& .cutom_select div': {
      minHeight: '65px'
    },
    '& .cutom_select div input ': {
      color: '#ffffff !important',
      fontWeight: 'bold',
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontSize: '20px'
    },
    '& .MuiAutocomplete-endAdornment': {
      minHeight: ' auto !important',
      top: 'calc(50% - 12px)'
    }
  }
}));

const Transition = React.forwardRef((props, ref) => {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Slide direction="up" ref={ref} {...props} />;
});

export const Data = [
  { id: 0, label: 'Inactive' },
  { id: 1, label: 'Active' },
  { id: 2, label: 'Suspended' }
];

const Potential = (props) => {
  const { handleClose, setOpen, openNotify } = props;
  const classes = useStyles();
  const [data, setData] = useState({
    first_name: null,
    last_name: null,
    lead_status: null,
    email: null,
    mobile_no: null,
    leadOpt: [],
    lead_source: null
  });
  const [Sussopen, setSussopen] = useState(false);
  const [LeavePageopen, setLeavePageopen] = useState(false);
  const [isprocessing, setprocessing] = useState(false);

  useEffect(() => {
    getLeadSrc()
      .then((res) => {
        setData({
          leadOpt: res.filter((dataleadOpt) => dataleadOpt.status === 1),
          lead_source: res.filter(
            // eslint-disable-next-line radix
            (list) => list.id === parseInt(res.lead_source)
          )
        });
      })
      .catch((err) => {
        console.warn(err);
      });
  }, []);

  const handleCreateLead = () => {
    const dataVal = {
      first_name: data.first_name,
      last_name: data.last_name,
      lead_source: data.lead_source?.id,
      mobile_no: data.mobile_no,
      email: data.email
    };
    if (!data.first_name.trim().length) {
      openNotify('First name is empty', 'error');
    } else if (!data.last_name.trim().length) {
      openNotify('Last name is empty', 'error');
    } else if (data.email?.trim().length > 0 && checkEmail(data.email)) {
      openNotify('Please provide valid email ', 'error');
    } else if (
      (!data.mobile_no && !isValidMobile(data.mobile_no)) ||
      (data.mobile_no &&
        (data.mobile_no?.replace(/[^a-zA-Z0-9]/g, '').length < 10 ||
          data.mobile_no?.replace(/[^a-zA-Z0-9]/g, '').trim().length > 16))
    ) {
      openNotify('Please provide valid mobile number', 'error');
    } else if (data.lead_source.length === 0 || !data.lead_source) {
      openNotify('Please provide lead source', 'error');
    } else if (data.email) {
      setprocessing(true);
      CheckEmailAvaibility({ email: data.email })
        .then(() => {
          quickCreateNewLead(dataVal)
            .then((res) => {
              if (res.success) {
                setSussopen(true);
                setTimeout(() => {
                  setSussopen(false);
                  setprocessing(false);
                }, 3000);
                handleClose();
              }
              setData({
                ...data,
                first_name: '',
                last_name: '',
                lead_source: null,
                mobile_no: '',
                email: ''
              });
            })
            .catch((err) => {
              setprocessing(false);
              openNotify(err.response.data.message, 'error');
            });
        })
        .catch((err) => {
          openNotify(err.response.data.message, 'error');
          setprocessing(false);
        });
    } else {
      setprocessing(true);
      quickCreateNewLead(dataVal)
        .then((res) => {
          // openNotify('Successfully Created');
          if (res.success) {
            setSussopen(true);
            setTimeout(() => {
              setSussopen(false);
              setprocessing(false);
            }, 3000);
            handleClose();
          }
          setData({
            ...data,
            first_name: '',
            last_name: '',
            lead_source: null,
            mobile_no: '',
            email: ''
          });
        })
        .catch((err) => {
          setprocessing(false);
          openNotify(err.response.data.message, 'error');
        });
    }
  };

  if (!data) return <VanguardListLoadingView />;

  return (
    <div>
      <Dialog
        fullScreen
        open={setOpen}
        onClose={() => handleClose()}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar className={classes.appToolbar}>
            <IconButton
              edge="start"
              color="inherit"
              // onClick={handleClose}
              aria-label="close"
              onClick={() => {
                setLeavePageopen(true);
              }}
            >
              <BackArrow />
            </IconButton>
            <Button
              color="inherit"
              className={classes.HomeButton}
              onClick={() => {
                setData({
                  ...data,
                  first_name: '',
                  last_name: '',
                  lead_source: null,
                  mobile_no: '',
                  email: ''
                });
                handleClose();
              }}
            >
              Home
            </Button>
          </Toolbar>
        </AppBar>
        <Card className={classes.MainCard}>
          <Typography variant="h3" className={classes.mainTitle}>
            QUICK ADD LEAD
          </Typography>
          <Grid
            container
            xs={10}
            justify="center"
            style={{
              margin: '45px auto'
            }}
          >
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={2}
              // style={{ margin: '20px 0 20px' }}
            >
              <Grid item xs={6} className={classes.cutom_vanguard_FormControl}>
                <InputLabel
                  htmlFor="age-required"
                  required
                  className={classes.cutom_select_Label}
                  // style={{ margin: '0px 0 5px' }}
                >
                  FIRST Name
                </InputLabel>
                <FormControl
                  control="input"
                  value={data.first_name}
                  onChange={(e) => {
                    setData({ ...data, first_name: e.target.value });
                  }}
                  maxLength={50}
                  style={{
                    margin: '0 0'
                  }}
                />
              </Grid>
              <Grid item xs={6} className={classes.cutom_vanguard_FormControl}>
                <InputLabel
                  htmlFor="age-required"
                  required
                  className={classes.cutom_select_Label}
                  // style={{ margin: '0px 0 5px' }}
                >
                  LAST Name
                </InputLabel>
                <FormControl
                  control="input"
                  value={data.last_name}
                  onChange={(e) => {
                    setData({ ...data, last_name: e.target.value });
                  }}
                  maxLength={50}
                  style={{
                    margin: '0 0'
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                className={classes.cutom_vanguard_FormControl}
                style={{
                  margin: '10px 0'
                }}
              >
                <InputLabel
                  htmlFor="age-required"
                  required
                  className={classes.cutom_select_Label}
                  // style={{ margin: '0px 0 5px' }}
                >
                  Phone Number
                </InputLabel>
                <FormControl
                  control="input"
                  inputMode="numeric"
                  value={data.mobile_no}
                  maxLength={20}
                  onChange={(e) => {
                    setData({
                      ...data,
                      mobile_no: validMobile(e.target.value)
                    });
                  }}
                  style={{
                    margin: '0 0'
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                className={classes.cutom_vanguard_FormControl}
                style={{
                  margin: '0px 0 10px'
                }}
              >
                <InputLabel
                  htmlFor="age-required"
                  className={classes.cutom_select_Label}
                  // style={{ margin: '0px 0 5px' }}
                >
                  Email
                </InputLabel>
                <FormControl
                  control="input"
                  maxLength={60}
                  value={data.email}
                  onChange={(e) => {
                    setData({ ...data, email: e.target.value });
                  }}
                  style={{
                    margin: '0 0'
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                className={clsx(
                  classes.cutom_vanguard_FormControl,
                  classes.cutom_vanguard_Drop
                )}
                style={{
                  position: 'relative'
                }}
              >
                <InputLabel
                  htmlFor="age-required"
                  className={classes.cutom_select_Label}
                  style={{ margin: '0px 0 8px' }}
                  required
                >
                  Lead Source
                </InputLabel>
                <CustomAutocomplete
                  disablePortal
                  popupIcon={<DownIcon />}
                  disableClearable
                  value={data.leadOpt || []}
                  Options="label"
                  data={data.lead_source}
                  Change={(val) => {
                    setData({ ...data, lead_source: val });
                  }}
                  className={clsx(
                    classes.cutom_vanguard_Autocomplete,
                    'SelectDrop'
                  )}
                />
              </Grid>
            </Grid>

            <Box mt="50px" />
            <Grid
              container
              direction="row"
              justify="flex-end"
              alignItems="center"
            >
              <JBVanguardButton
                type="primary"
                style={{
                  marginTop: '45px',
                  width: '250px',
                  minHeight: '70px'
                }}
                // onClick={() => setSussopen(true)}
                onClick={() => handleCreateLead()}
                disabled={
                  !data.first_name ||
                  !data.last_name ||
                  !data.mobile_no ||
                  !data.lead_source?.id ||
                  isprocessing
                }
              >
                Submit
              </JBVanguardButton>
            </Grid>
          </Grid>
        </Card>
      </Dialog>
      <SuccessPopup
        Dialogtitle="Lead created successfully"
        setOpen={Sussopen}
        handleClose={() => setSussopen(false)}
      />
      <LeavePagePopup
        setOpen={LeavePageopen}
        handleClose={() => setLeavePageopen(false)}
        handleBack={() => {
          setLeavePageopen(false);
          handleClose();
          setData({
            ...data,
            first_name: '',
            last_name: '',
            lead_source: null,
            mobile_no: '',
            email: ''
          });
        }}
        // handleHistory={false}
      />
    </div>
  );
};

Potential.propTypes = {
  setOpen: PropType.bool.isRequired,
  handleClose: PropType.func.isRequired,
  openNotify: PropType.func.isRequired
};

const mapToDispatch = (dispatch) => ({
  openNotify: (msg, type) => dispatch(openNoti(msg, type))
});
export default connect(null, mapToDispatch)(Potential);
