import React from 'react';
import Box from '@material-ui/core/Box';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/styles';

// Renders the amount of Rows with Loading View
const items = [{ id: 1 }, { id: 2 }, { id: 3 }];

const useStyles = makeStyles({
  root: {
    backgroundColor: 'rgba(38, 50, 56, 0.30)'
  },
  text: {
    transform: 'none'
  },
  userInBox: {
    width: '189px',
    height: '227px',
    borderRadius: '5px',
    marginRight: '15px',
    marginBottom: '15px'
  }
});

export default function VanguardMemberLoadingView() {
  const classes = useStyles();
  return (
    <Box display="flex" width="100%" alignItems="stretch">
      {items.map((item) => (
        <Box marginRight={1.5} id={item.id} className={classes.userInBox}>
          <Skeleton
            width="100%"
            height="100%"
            classes={{ root: classes.root, text: classes.text }}
          />
        </Box>
      ))}
    </Box>
  );
}
