import React, { useEffect, useState, useCallback, useRef, memo } from 'react';
import PropType from 'prop-types';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Dialog,
  Grid,
  DialogContent,
  withStyles,
  InputBase,
  Box
} from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import { debounce } from 'lodash';
import { searchMember } from 'src/services/Members.module';
import DefaultUser from 'src/assets/image/defaultUser.png';
import { ROUTES } from 'src/constant/Constants';
import clsx from 'clsx';
import EmaiIcon from '../../../assets/image/mail.png';
import styles from './SearchBoxDialog.style';
import { getRegex } from './SearchBox.utils';
import { ReactComponent as DcloseIcon } from '../../../assets/image/xClose.svg';
import { rolesList } from './SearchBoxConstants';
import CancelDialogTwo from '../CommonDialogBox/CancelDialogTwo';

function SearchBoxDialog({ classes }) {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [Cancelopen, setCancelOpen] = useState(false);
  const [search, setSearch] = useState(() => '');
  const [data, setdata] = useState(() => null);
  const [selected, setselected] = useState(0);
  const [profileRoute, setProfileRoute] = useState('');
  const leftBarRef = useRef();
  const inputRef = useRef();

  const handleClose = () => {
    setOpen(false);
  };

  const handleBack = () => {
    history.push(profileRoute);
    setCancelOpen(false);
    setOpen(false);
    history.go(0);
  };

  const SearchQuery = useCallback(
    debounce((q) => {
      if (q.length > 0 && q.trim().length > 0) {
        if (selected !== 0) {
          setselected(0);
        }
        setselected(0);
        searchMember(q)
          .then((res) => {
            setdata(res);
            // Scroll to top on new search
            leftBarRef.current.scrollTo(0, 0);
          })
          .catch(() => setdata([]));
      } else {
        setdata([]);
      }
    }, 1000),
    []
  );

  const handleShortCut = (e) => {
    /* eslint-disable */

    const shortCutPressed =
      e.shiftKey &&
      !open &&
      e.keyCode === 83 &&
      e.target?.localName !== 'input';
    /* eslint-enable */

    if (shortCutPressed) {
      setOpen(true);

      setTimeout(() => {
        inputRef.current.firstChild.focus();
      }, 100);
    }
  };

  useEffect(() => {
    if (!open) {
      document.addEventListener('keydown', handleShortCut);
      setdata(null);
      setselected(0);
      setSearch(() => '');
    }
    return () => document.removeEventListener('keydown', handleShortCut);
  }, [open]); // eslint-disable-line

  const handleErrorImg = (e) => {
    e.target.src = DefaultUser;
  };

  return (
    <div>
      {open && (
        <Button className={classes.btnClose} onClick={handleClose}>
          <DcloseIcon />
        </Button>
      )}
      <Dialog
        open={open}
        fullWidth
        maxWidth="md"
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick
      >
        <div>
          <DialogContent
            style={{
              maxHeight: '541px',
              paddingBottom: '20px',
              paddingTop: '30px'
            }}
          >
            <Grid container spacing={0}>
              <Grid item xs={12} style={{}}>
                <div className={classes.search}>
                  <InputBase
                    ref={inputRef}
                    fullWidth
                    placeholder="Search by name..."
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                      SearchQuery(e.target.value);
                    }}
                    classes={{
                      root: classes.inputRoot,
                      input: classes.searchInput
                    }}
                    InputProps={{
                      'aria-label': 'search'
                    }}
                  />
                </div>
              </Grid>
              {data && (
                <Grid container spacing={0} style={{ marginTop: '30px' }}>
                  <Grid
                    item
                    xs={4}
                    style={{
                      marginTop: '10px',
                      maxHeight: '350px',
                      overflowY: 'scroll'
                    }}
                    ref={leftBarRef}
                  >
                    {data?.map((row, i) => (
                      <div key={row.id}>
                        <Box>
                          <Box
                            // className={classes.media}
                            className={clsx(
                              classes.media,
                              selected === i ? classes.ListSelected : null
                            )}
                            style={{
                              cursor: 'pointer',
                              padding: '5px 10px'
                            }}
                            onClick={() => setselected(i)}
                          >
                            <Box display="flex">
                              {row.profile_pic ? (
                                <img
                                  src={row.profile_pic || DefaultUser}
                                  onError={handleErrorImg}
                                  alt="UserIcon"
                                  style={{
                                    verticalAlign: 'sub',
                                    width: '40px',
                                    height: '40px',
                                    borderRadius: '50%',
                                    objectFit: 'cover'
                                  }}
                                />
                              ) : (
                                <Avatar
                                  className={clsx(classes.uAvatar, 'listAv')}
                                >
                                  {row.first_name[0] + ' ' + row.last_name[0]}
                                </Avatar>
                              )}
                            </Box>
                            <Box className={classes.mediabody}>
                              <Box className={classes.uName}>
                                {getRegex(
                                  row?.first_name + ' ' + row?.last_name,
                                  search
                                )}
                              </Box>
                              <Box className={classes.uEmail}>
                                {row.email ? getRegex(row.email, search) : '-'}
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                        <div className={classes.hr} />
                      </div>
                    ))}
                  </Grid>
                  {data?.length > 0 ? (
                    <Grid item xs={8} style={{}}>
                      <Box className={classes.userInfo}>
                        <Box className={classes.userWrapper}>
                          <Box
                            className={classes.media}
                            style={{ alignItems: 'center' }}
                          >
                            <Box display="flex">
                              {data[selected]?.profile_pic ? (
                                <img
                                  src={data[selected]?.profile_pic}
                                  onError={handleErrorImg}
                                  alt="UserIcon"
                                  style={{
                                    verticalAlign: 'sub'
                                  }}
                                  className={classes.UserAvatar}
                                />
                              ) : (
                                <Avatar className={classes.Avataruname}>
                                  {data[selected]?.first_name[0] + // eslint-disable-line
                                  ' ' + // eslint-disable-line
                                    data[selected]?.last_name[0]}
                                </Avatar>
                              )}
                            </Box>
                            <Box className={classes.mediabody}>
                              <div
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                  // data[selected]?.is_lead
                                  //   ? `leadId=${data[selected]?.id}`
                                  //   : `memberId=${data[selected]?.id}
                                  const profilePath = `${
                                    data[selected]?.is_lead
                                      ? ROUTES.PEOPLE_LEAD_PROFILE
                                      : ROUTES.PEOPLE_MEMBER_PROFILE
                                  }?${
                                    data[selected]?.is_lead
                                      ? `leadId=${btoa(data[selected]?.id)}`
                                      : `memberId=${btoa(data[selected]?.id)}`
                                  }`;
                                  setProfileRoute(profilePath);
                                  if (
                                    localStorage.getItem('formcomponent') === // eslint-disable-line
                                    'true'
                                  ) {
                                    setCancelOpen(true);
                                  } else {
                                    history.push(profilePath);
                                    handleClose();
                                  }
                                }}
                              >
                                <h3>
                                  {data[selected].first_name
                                    + ' '
                                    + data[selected].last_name}
                                </h3>
                              </div>
                              {data[selected]?.is_lead === 0 && (
                                <div className={classes.lastattendance}>
                                  <div>
                                    <span>Last Attendance </span>
                                    <label>
                                      {/* eslint-disable */}
                                      {data[selected].last_attend_date === '-'
                                        ? data[selected].last_attend_date
                                        : data[selected].last_attend_date
                                      // moment(
                                      //     data[selected].last_attend_date
                                      //   ).format(`${dateformat}`)
                                      }
                                      {/* eslint-enable */}
                                    </label>
                                  </div>
                                </div>
                              )}
                              <Box className={classes.textoutline}>
                                <ul>
                                  {data[selected].is_lead === 0 ? (
                                    <li>{rolesList[data[selected].role_id]}</li>
                                  ) : (
                                    <li>Lead</li>
                                  )}
                                </ul>
                                {data[selected].tags && (
                                  <ul>
                                    {data[selected].tags
                                      .split(',')
                                      .map((list) => (
                                        <li>#{list}</li>
                                      ))}
                                  </ul>
                                )}
                              </Box>
                            </Box>
                            <div>
                              <Button
                                variant="contained"
                                disableRipple
                                disableElevation
                                className={classes.userStatus}
                                onClick={(e) => {
                                  e.preventDefault();
                                }}
                                style={{
                                  backgroundColor: '#fff',
                                  cursor: 'auto'
                                }}
                              >
                                {data[selected].status === 1
                                  ? 'Active'
                                  : 'Inactive'}
                              </Button>
                            </div>
                          </Box>
                        </Box>
                        <Box className={classes.userContectInfo}>
                          <ul>
                            <li>Email</li>
                            <li>
                              <strong>{data[selected].email || '-'}</strong>
                            </li>
                            <li
                              style={{
                                textAlign: 'right',
                                minWidth: '5%',
                                float: 'right'
                              }}
                            >
                              <img
                                src={EmaiIcon}
                                alt="Email"
                                style={{
                                  verticalAlign: 'sub'
                                }}
                              />
                            </li>
                          </ul>
                          <ul>
                            <li>Mobile</li>
                            <li>
                              <strong>{data[selected].mobile_no || '-'}</strong>
                            </li>
                          </ul>
                          <ul>
                            <li>Membership type</li>
                            <li>
                              <strong
                                style={{
                                  textTransform: 'capitalize'
                                }}
                              >
                                {data[selected].membership_type || '-'}
                              </strong>
                            </li>
                          </ul>
                          <ul>
                            <li>Payment plan</li>
                            <li>
                              <strong>
                                {data[selected].membership_payment_name || '-'}
                              </strong>
                            </li>
                          </ul>
                        </Box>
                      </Box>
                    </Grid>
                  ) : (
                    <p
                      style={{
                        textAlign: 'left'
                      }}
                    >
                      Search result not found
                    </p>
                  )}
                </Grid>
              )}
            </Grid>
          </DialogContent>
        </div>
      </Dialog>
      <CancelDialogTwo
        setOpen={Cancelopen}
        handleBack={handleBack}
        handleClose={() => setCancelOpen(false)}
      />
    </div>
  );
}

SearchBoxDialog.propTypes = {
  classes: PropType.object.isRequired
  // handleDelete: PropType.func.isRequired,
  // refs: PropType.any.isRequired
};
export default withStyles(styles)(memo(SearchBoxDialog));
