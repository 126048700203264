const Types = {
  MissWav: 'MISSING_WAIVERS',
  Signed: 'SIGNED_WAIVERS',
  TempWav: 'TEMPLATE_WAIVERS',
  TempWavFilter: 'TEMPLATE_WAIVERS_FILTER',
  MissWavFilter: 'ACTIVE_MISSING_WAIVER_FILTER',
  SignedFilter: 'SIGNED_FILTER_WAIVERS',
  isFetching: 'WAIVERS_FETCHING_START',
  fetchSucess: 'WAIVERS_FETCHED_SUCCESSFUL',
  Errorfetch: 'ERROR_OCCURED_WHEN_FETCHING'
};

export default Types;
