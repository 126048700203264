import { getUpdatedValueObj } from 'src/Redux/reducer.default.data';
import { getMasterhqAllies } from 'src/services/masterhq.allies.services';
import { getErrorMsg } from 'src/utils/ErrorHandling';
import {
  ALLIES_ACTIONTYPE,
  ACTIVE_ALLIES_FIL,
  ACTIVE_ALLIES,
  INACTIVE_ALLIES,
  INACTIVE_ALLIES_FIL,
  ALL_ALLIES,
  ALL_ALLIES_FIL
} from './allies.reducer.types';
import { getAlliesState } from './allies.reducer.utils';

const fetchingAllies = (type) => ({
  type,
  actionType: ALLIES_ACTIONTYPE.isFetching
});

const fetchComplete = (type, payload) => ({
  type,
  actionType: ALLIES_ACTIONTYPE.fetchSucess,
  payload
});

const ErrorFetching = (type, payload) => ({
  type,
  actionType: ALLIES_ACTIONTYPE.Errorfetch,
  payload
});

const activeAlliesFil = (filter, payload) => ({
  type: ACTIVE_ALLIES_FIL,
  filter,
  payload
});

const activeAllies = (values) => {
  const type = ACTIVE_ALLIES;
  return async (dispatch) => {
    dispatch(fetchingAllies(type));
    try {
      const data = await getMasterhqAllies(values);
      dispatch(fetchComplete(type, data));
    } catch (err) {
      dispatch(ErrorFetching(type, getErrorMsg(err)));
    }
  };
};

export const getActiveAllies = (filter, data) => {
  return (dispatch, getState) => {
    dispatch(activeAlliesFil(filter, data));
    const updatedValues = getUpdatedValueObj(
      getAlliesState(getState, 'activeAlliesFilters')
    );
    dispatch(activeAllies(updatedValues));
  };
};

const inActiveAlliesFil = (filter, payload) => ({
  type: INACTIVE_ALLIES_FIL,
  filter,
  payload
});

const inActiveAllies = (values) => {
  const type = INACTIVE_ALLIES;
  return async (dispatch) => {
    dispatch(fetchingAllies(type));
    try {
      const data = await getMasterhqAllies(values);
      dispatch(fetchComplete(type, data));
    } catch (err) {
      dispatch(ErrorFetching(type, getErrorMsg(err)));
    }
  };
};

export const getInActiveAllies = (filter, data) => {
  return (dispatch, getState) => {
    dispatch(inActiveAlliesFil(filter, data));
    const updatedValues = getUpdatedValueObj(
      getAlliesState(getState, 'inActiveAlliesFilters')
    );
    dispatch(inActiveAllies(updatedValues));
  };
};

const allAlliesFil = (filter, payload) => ({
  type: ALL_ALLIES_FIL,
  filter,
  payload
});

const allAllies = (values) => {
  const type = ALL_ALLIES;
  return async (dispatch) => {
    dispatch(fetchingAllies(type));
    try {
      const data = await getMasterhqAllies(values);
      dispatch(fetchComplete(type, data));
    } catch (err) {
      dispatch(ErrorFetching(type, getErrorMsg(err)));
    }
  };
};

export const getAllAllies = (filter, data) => {
  return (dispatch, getState) => {
    dispatch(allAlliesFil(filter, data));
    const updatedValues = getUpdatedValueObj(
      getAlliesState(getState, 'allAlliesFilters')
    );
    dispatch(allAllies(updatedValues));
  };
};
