import React, { useState } from 'react';
import { Input, FormControl, InputAdornment } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropType from 'prop-types';
import { useSelector } from 'react-redux';
import LoadingView from '../loading';

const styles = () => ({
  input: {
    color: '#292929',
    fontSize: '16px',
    '&::placeholder': {
      color: '#7F7F7F',
      fontSize: '16px',
      fontWeight: '500',
      opacity: '0.5'
    }
  },
  CurrencyInputs: {
    color: '#292929',
    fontSize: '16px',
    border: '1px solid #a9a9a9',
    padding: '8px 15px 8px',
    // marginTop: '22px !important',
    maxHeight: '48px',
    '&::placeholder': {
      color: '#7F7F7F',
      fontSize: '16px',
      fontWeight: '500',
      opacity: '0.5'
    },
    '&.MuiInput-underline.Mui-error': {
      border: '1px solid #f44336'
    },
    '&.MuiInput-underline.Mui-error input[type="text"]': {
      border: '0px solid #f44336'
    },
    '&.MuiInput-underline.Mui-disabled:before': {
      borderBottomStyle: 'none'
    }
  },
  CurrencySymbol: {
    color: '#292929',
    marginRight: '0'
  }
  // inputLabel: {
  //   color: 'red'
  // }
});
const CurrencyInputs = ({
  label,
  name,
  classes,
  seterrors,
  seterrortext,
  maxLength,
  ...props
}) => {
  const [refs, setRefs] = useState(null);
  const { isFetching, data, error } = useSelector(
    (state) => state.SettingReducer.currency
  );

  if (error) return <p>{error}</p>;
  if (isFetching) return <LoadingView />;

  return (
    <FormControl fullWidth margin="none" error={refs && seterrors}>
      {/* <InputLabel className={classes.inputLabel}>{label}</InputLabel> */}
      <Input
        name={name}
        onFocus={() => setRefs(true)}
        id="standard-adornment-weight"
        aria-describedby="standard-weight-helper-text"
        placeholder="hello"
        className={classes.CurrencyInputs}
        startAdornment={
          <InputAdornment position="start" className={classes.CurrencySymbol}>
            <span style={{ margin: '-1px 0 0' }}>{data?.currency_symbol}</span>
          </InputAdornment>
        }
        inputProps={{
          'aria-label': 'weight',
          className: classes.input,
          maxLength
        }}
        // error={refs && seterrors}
        // helperText={refs && seterrors ? seterrortext : null}
        {...props} // eslint-disable-line
      />
    </FormControl>
  );
};

CurrencyInputs.defaultProps = {
  maxLength: 10,
  seterrors: false,
  name: 'Name',
  seterrortext: 'Required Fields'
};

CurrencyInputs.propTypes = {
  classes: PropType.object.isRequired,
  maxLength: PropType.number,
  label: PropType.string.isRequired,
  name: PropType.string,
  seterrors: PropType.bool,
  seterrortext: PropType.string
};

export default withStyles(styles)(CurrencyInputs);
