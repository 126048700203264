const Types = {
  futureClass: 'FUTURE_CLASS_DATA',
  AllClass: 'ALL_ONE_TIME__CLASS',
  recurrClass: 'RECURR_CLASS',
  futureFilter: 'FUTURE_CLASS_FILTER',
  allFilter: 'ONE_TIME_ALL_CLASS_FILTER',
  recurrFilter: 'RECCURR_CLASS_FILTER',
  getCoaches: 'RECIEVING_ALL_COACHES',
  allSelected: 'ALL_SELECTED'
};

export default Types;
