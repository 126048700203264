/* eslint-disable function-paren-newline */
/* eslint-disable react/no-unescaped-entities */
import React, { memo, useEffect } from 'react';
import { Box, Container, makeStyles, Toolbar, Typography } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import propTypes from 'prop-types';
import queryString from 'query-string';
import { useDispatch } from 'react-redux';
import { activatedStripeLink } from 'src/services/masterhq.allies.services';
import Logo from 'src/components/Logo';
import Page from 'src/components/Page';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { openNoti } from 'src/Redux/global/global.actions';

const useStyles = makeStyles((theme) => ({
  root: {
    height: 'auto',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    backgroundColor: '#f9f9fa'
  },
  image: {
    marginTop: 10,
    display: 'inline-block',
    maxWidth: '100%',
    width: 560,
    fontSize: '135px'
  },
  toolbar: {
    height: 64
  },
  divContainer: {
    height: 'calc(100vh - 111px)',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'center'
  },
  subtext: {
    fontSize: '20px',
    marginTop: '15px'
  }
}));

const VerificationLinkView = (props) => {
  const classes = useStyles();

  const {
    location: { search }
  } = props;
  const dispatch = useDispatch();

  const { status, aff } = queryString.parse(search);

  useEffect(() => {
    if (status && aff) {
      activatedStripeLink(aff)
        .then(() =>
          dispatch(openNoti('Successfully activated your stripe account'))
        )
        .catch(() => openNoti('error ocurred please check again', 'error'));
    }
  }, [status, aff, dispatch]);

  if (!status || !aff) {
    return null;
  }

  return (
    <>
      <Toolbar className={classes.toolbar}>
        <RouterLink to="/">
          <Logo />
        </RouterLink>
      </Toolbar>
      <Page className={classes.root} title="verification successful">
        <Box
          display="flex"
          flexDirection="column"
          height="100%"
          justifyContent="center"
        >
          <Container maxWidth="md" className={classes.divContainer}>
            <Box textAlign="center">
              {/* <LinkExpired className={classes.image} /> */}
              <CheckCircleIcon
                color="primary"
                fontSize="50px"
                className={classes.image}
              />
            </Box>
            {/* <Typography align="center" color="textPrimary" variant="h1">
              Stripe verification successful
            </Typography> */}
            <Typography
              align="center"
              color="textPrimary"
              variant="subtitle2"
              className={classes.subtext}
            >
              Your Stripe account is connected successfully. Kindly upload the
              KYC documents on Stripe
              <a href="https://stripe.com">
                (https://stripe.com)
              </a>
              to complete your account verification.
            </Typography>
          </Container>
        </Box>
      </Page>
    </>
  );
};

VerificationLinkView.propTypes = {
  location: propTypes.object.isRequired
};

export default memo(VerificationLinkView);
