/* eslint-disable operator-linebreak */
import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import PropType from 'prop-types';
import { Card, withStyles } from '@material-ui/core';
import withDND from 'react-big-calendar/lib/addons/dragAndDrop';
import LoadingView from 'src/views/common/loading';
// import events from './events';
import { checkClassName, getTime } from 'src/utils/someCommon';
import {
  AllClassdata,
  futureddata
} from 'src/Redux/ClassReducer/classReducer.actions';
import { updateSigClass } from 'src/services/classModule.services';
import { openNoti } from 'src/Redux/global/global.actions';
import { get } from 'js-cookie';
import { getErrorMsg } from 'src/utils/ErrorHandling';
import {
  changeDateCalender,
  getCalenderData
} from 'src/Redux/CalenderReducer/Calender.actions';
import DialogEdit from '../DialogBox/DialogEdit';
import DialogCreateClass from '../DialogBox/DialogCreateClass';
import styles from './Calendar.style';
import CustomToolbar from './cutomToolbar';
import CustomTesting from './CustomAgenda';
import CustomAgent from './Agenda/CustomAgent';
import CustomTime from './Agenda/CustomTime';
import CustomDate from './Agenda/CustomDate';
import Customevent from './Event/Customevent';
import CustomPopupManager from '../../common/CustomPopup/CustomPopupManager';
import './style.css';
import { getUpdateDiag } from '../DialogBox/Dialog.utils';
import CalenderView from './CalenderView';

moment.locale('en-in', {
  week: {
    dow: 1,
    doy: 1
  }
});

const localizer = momentLocalizer(moment);

const formats = {
  dateFormat: 'ddd',
  dayFormat: 'dddd',
  weekdayFormat: 'dddd',

  // agendaTimeFormat: 'hh:mm tt',
  dayHeaderFormat: 'MMMM DD,YYYY',
  agendaDateFormat: 'dddd',
  agendaHeaderFormat: ({ start, end }) => {
    return (
      moment.utc(start).format('MMMM DD') +
      ' - ' +
      moment.utc(end).format('DD,YYYY')
    );
  },
  dayRangeHeaderFormat: ({ start, end }) => {
    return (
      moment
        .utc(start)
        .add(1, 'days')
        .format('MMMM DD') +
      ' - ' +
      moment
        .utc(end)
        .add(1, 'days')
        .format('DD, YYYY')
    );
  }
};

const MainCalendar = (props) => {
  const { classes, allevents, calenderDate, currentView } = props;
  const DNDCalender = withDND(Calendar);
  const Calref = useRef();
  const roleid = JSON.parse(get('jbrole') || '{}')?.role_id || 3;
  const [data, setData] = useState({
    data: null,
    show: false,
    location: null
  });
  const CloseDialog = () => {
    setData({ data: null, show: false, location: null });
  };

  const onSelectSlot = (slotsinfo) => {
    let locObject;
    const scrollPosition = props.onCalendarClick();
    if (roleid > 2) {
      return null;
    }
    if (data.data !== null) {
      return CloseDialog();
    }
    if (slotsinfo.box) {
      if (typeof slotsinfo.box.x !== 'undefined') {
        locObject = {
          x: slotsinfo.box.x,
          y: slotsinfo.box.y,
          scroll: scrollPosition
        };
        return setData({
          data: slotsinfo.slots,
          show: true,
          location: locObject
        });
      }
    }
    return null;
  };

  const handleDND = ({ event, start, end }) => {
    const { OpenNotiDisp, reloadCalender, getAllClass, getFuture } = props;
    if (roleid <= 2) {
      if (
        event.start_time.getTime() !== start.getTime() &&
        event.start_time.getTime() > new Date().getTime()
      ) {
        let updatedVal = {
          ...event,
          start_time: start,
          end_time: end
        };
        if (checkClassName(event.class_name, event.program_name)) {
          updatedVal = {
            ...updatedVal,
            class_name: event.program_name + ' ' + getTime(start)
          };
        }
        const value = getUpdateDiag(updatedVal);
        if (!value) {
          OpenNotiDisp(
            'You cant set Start Time as time is already passed',
            'error'
          );
        } else {
          const { changeDate } = props;
          if (currentView === 'day') {
            changeDate(start);
          }
          updateSigClass(value, event.id)
            .then(() => reloadCalender())
            .then(() => getAllClass())
            .then(() => getFuture())
            .then(() => OpenNotiDisp('Successfully updated the class', 'info'))
            .catch((err) => OpenNotiDisp(getErrorMsg(err), 'error'));
        }
      } else if (event.start_time.getTime() < new Date().getTime()) {
        OpenNotiDisp(
          'You cant move the class to which time is already passed',
          'error'
        );
      }
    }
  };

  const handleSelectEvent = (e, e2) => {
    if (data.data) {
      return CloseDialog();
    }
    const scrollPosition = props.onCalendarClick();

    if (e2.clientX && e2.clientY) {
      const locObject = {
        x: e2.clientX,
        y: e2.clientY,
        scroll: scrollPosition
      };
      return setData({
        data: e,
        show: true,
        location: locObject,
        isEditing: true
      });
    }
    return null;
  };
  return (
    <div className={classes.root}>
      <CalenderView />
      <Card
        className={classes.mainCard}
        ref={Calref}
        style={{ position: 'relative' }}
      >
        {allevents.isFetching ? (
          <LoadingView />
        ) : (
          <DNDCalender
            selectable
            defaultView={currentView}
            min={new Date(0, 0, 0, 5, 0, 0)}
            max={new Date(0, 0, 0, 22, 0, 0)}
            localizer={localizer}
            format="DD/MM/YYYY HH:mm"
            events={allevents.data}
            formats={formats}
            defaultDate={calenderDate}
            length={7}
            step={60}
            timeslots={1}
            tooltipAccessor="class_name"
            titleAccessor={(e) => {
              if (e.head_coach) return e.class_name + ' ' + e.head_coach.name;
              return e.class_name;
            }}
            onSelectSlot={onSelectSlot}
            onSelectEvent={handleSelectEvent}
            onEventDrop={handleDND}
            startAccessor="start_time"
            endAccessor="end_time"
            messages={{
              agenda: 'TIME',
              day: 'DAY',
              week: 'WEEK'
            }}
            views={['week', 'day', 'agenda']}
            components={{
              toolbar: CustomToolbar,
              week: {
                header: ({ date, localizer: TimeChange }) =>
                  TimeChange.format(date, 'dddd(MM/DD)')
              },
              day: {
                header: CustomTesting
              },
              event: Customevent,
              agenda: {
                date: CustomDate,
                event: CustomAgent,
                time: CustomTime
              }
            }}
            style={{ height: '85rem' }}
            eventPropGetter={(event) => {
              const background = event.color;
              const style = {
                backgroundColor: background,
                borderRadius: '0px',
                opacity: 1,
                color: background === '#000000' ? '#fff' : 'black',
                border: '#f1f1f1 solid 0.1px',
                margin: '0 10px',
                display: 'block',
                cursor: 'pointer',
                textDecoration: event.status === 0 ? 'line-through' : null,
                minHeight: '5.55556%'
              };
              return {
                style
              };
            }}
          />
        )}

        <CustomPopupManager
          show={data.show}
          location={data.location}
          hideCustomPopup={() => CloseDialog()}
          // forwardRef={Calref}
        >
          {data.isEditing ? (
            <DialogEdit data={data.data} onClose={() => CloseDialog()} />
          ) : (
            <DialogCreateClass
              slots={data.data}
              onClose={() => CloseDialog()}
            />
          )}
        </CustomPopupManager>
      </Card>
    </div>
  );
};
MainCalendar.propTypes = {
  classes: PropType.object.isRequired,
  allevents: PropType.object.isRequired,
  calenderDate: PropType.object.isRequired,
  OpenNotiDisp: PropType.func.isRequired,
  reloadCalender: PropType.func.isRequired,
  currentView: PropType.string.isRequired,
  getFuture: PropType.func.isRequired,
  getAllClass: PropType.func.isRequired,
  changeDate: PropType.func.isRequired,
  onCalendarClick: PropType.func.isRequired
};

const mapToState = (state) => ({
  allevents: state.calenderReducer,
  calenderDate: state.calenderReducer.defaultDate,
  currentView: state.calenderReducer.currentView
});

const mapToDispatch = (dispatch) => ({
  OpenNotiDisp: (msg, type) => dispatch(openNoti(msg, type)),
  reloadCalender: () => dispatch(getCalenderData()),
  getFuture: (filter, type) => dispatch(futureddata(filter, type)),
  changeDate: (date) => dispatch(changeDateCalender(date)),
  getAllClass: (filter, type) => dispatch(AllClassdata(filter, type))
});

export default connect(
  mapToState,
  mapToDispatch
)(withStyles(styles)(MainCalendar));
