import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ROUTES } from 'src/constant/Constants';
import { openNoti } from 'src/Redux/global/global.actions';
import { getValuefromObject } from 'src/utils/someCommon';
import {
  AllClassdata,
  futureddata
} from 'src/Redux/ClassReducer/classReducer.actions';
import { changeBulkCoach } from 'src/services/classModule.services';
import PropType from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  withStyles,
  Grid,
  InputLabel
} from '@material-ui/core';
import dcloseicon from '../../../../assets/image/closeicon.png';
import JBButton from '../../../common/JBButton/JBButton';
import CustomAutocomplete from '../../../common/CustomAutocomplete/commonAutocomplete';

const styles = () => ({
  dtitle: {
    color: '#292929',
    fontWeight: 'bold',
    fontSize: '33px',
    lineHeight: '26px'
  },
  cutom_select_Label: {
    fontSize: '12px',
    padding: '7px 0'
  }
});

function ChangeCoachDialog(props) {
  const { classes, handleClose, setOpen, coaches, allCoach, classids } = props;
  const [currCoach, setCurrCoach] = useState(null);
  const [newCoach, setNewCoach] = useState(null);
  const history = useHistory();

  const handleSave = () => {
    const { OpenNotiDisp, getAllClass, getFuture } = props;
    if (currCoach && newCoach) {
      const values = {
        class_ids: classids.join(','),
        old_coach_id: getValuefromObject(currCoach),
        new_coach_id: getValuefromObject(newCoach)
      };
      changeBulkCoach(values)
        .then(() => getFuture())
        .then(() => getAllClass())
        .then(() => OpenNotiDisp('Successfully changed the coach', 'info'))
        .then(() => history.push(ROUTES.CLASSES_LIST))
        .catch((err) =>
          OpenNotiDisp(JSON.stringify(err.response.data.message), 'error'));
    } else {
      OpenNotiDisp('Please Select the Coach you want to change', 'error');
    }
  };
  return (
    <div>
      <Dialog
        open={setOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div style={{ width: '548px' }}>
          <Grid alignItems="center" container direction="row">
            <Grid item xs>
              <DialogTitle
                id="alert-dialog-title"
                className={classes.titlebk}
                style={{
                  display: 'inline-block',
                  padding: '25px 24px'
                }}
              >
                <h4 className={classes.dtitle}> Change Coach</h4>
              </DialogTitle>
            </Grid>
            <Grid
              item
              xs={2}
              style={{ textAlign: 'right', marginRight: '10px' }}
            >
              <Button className={classes.btns} onClick={handleClose}>
                <img alt="img" src={dcloseicon} />
              </Button>
            </Grid>
          </Grid>

          <DialogContent>
            <Grid container spacing={2} style={{ marginTop: '5px' }}>
              <Grid item xs={12} style={{}}>
                <InputLabel
                  htmlFor="age-required"
                  required
                  className={classes.cutom_select_Label}
                  style={{ padding: '5px 0' }}
                >
                  Current coach
                </InputLabel>
                <CustomAutocomplete
                  holder="Current Coach"
                  data={currCoach}
                  value={coaches}
                  Options="name"
                  Change={(val) => setCurrCoach(val)}
                />
              </Grid>
              {currCoach && (
                <Grid item xs={12}>
                  <InputLabel
                    htmlFor="age-required"
                    required
                    className={classes.cutom_select_Label}
                    style={{ padding: '5px 0' }}
                  >
                    New coach
                  </InputLabel>
                  <CustomAutocomplete
                    holder="Select New Coach"
                    value={allCoach.filter((list) => list.id !== currCoach.id)}
                    Options="name"
                    data={newCoach}
                    Change={(val) => setNewCoach(val)}
                  />
                </Grid>
              )}
            </Grid>
          </DialogContent>
          <DialogActions
            style={{
              padding: '20px 20px'
            }}
          >
            <JBButton type="primary" onClick={handleSave}>
              Save
            </JBButton>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}
ChangeCoachDialog.propTypes = {
  classes: PropType.object.isRequired,
  handleClose: PropType.func.isRequired,
  setOpen: PropType.bool.isRequired,
  coaches: PropType.array.isRequired,
  classids: PropType.array.isRequired,
  allCoach: PropType.array.isRequired,
  getFuture: PropType.func.isRequired,
  getAllClass: PropType.func.isRequired,
  OpenNotiDisp: PropType.func.isRequired
};
const mapToState = (state) => ({
  allCoach: state.classReducer.coaches
});
const mapToDispatch = (dispatch) => ({
  OpenNotiDisp: (msg, type) => dispatch(openNoti(msg, type)),
  getFuture: () => dispatch(futureddata()),
  getAllClass: () => dispatch(AllClassdata())
});
export default connect(
  mapToState,
  mapToDispatch
)(withStyles(styles)(ChangeCoachDialog));
