/* eslint-disable arrow-parens */
import React, { Component } from 'react';
import { ROUTES } from 'src/constant/Constants';
import PropTypes from 'prop-types';
import { Link, Route, Switch } from 'react-router-dom';
import {
  AppBar,
  Breadcrumbs,
  Card,
  Grid,
  Tab,
  Tabs,
  Typography,
  withStyles
} from '@material-ui/core';
import JBButton from 'src/views/common/JBButton/JBButton';
import { get } from 'js-cookie';
import styles from './invoices.style';
import UnpaidTab from './UnpaidTab';
import PaidTab from './PaidTab';
import Plusicon from '../../../assets/image/plusicon.png';
import OverdueTab from './OverdueTab';
import AllTab from './AllTab';
import { getActiveVal } from './invoices.utils';
import CreateInvoice from './CreateInvoice';
import ProcessingTab from './ProcessingTab';
import FailedTab from './FailedTab';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

class Invoices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openDiag: false
    };
  }

  render() {
    const { classes, location } = this.props;
    const { openDiag } = this.state;
    const roleid = JSON.parse(get('jbrole') || '{}')?.role_id || 3;

    const Tabslocation = getActiveVal(location.pathname);

    return (
      <div className={classes.root}>
        <div className="title-bk">
          <div className={classes.new_programs} style={{ marginBottom: 15 }}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={0}
            >
              <Grid item xs={6}>
                <Breadcrumbs aria-label="breadcrumb">
                  <div color="inherit">financial</div>
                  <div color="inherit">invoices</div>
                  <Typography variant="h3" color="Primary">
                    {(() => {
                      switch (Tabslocation) {
                        case 0:
                          return <span>unpaid this month</span>;
                        case 1:
                          return <span>paid this month</span>;
                        case 2:
                          return <span>Overdue</span>;
                        case 3:
                          return <span>Processing</span>;
                        case 4:
                          return <span>Failed</span>;
                        case 5:
                          return <span>All</span>;
                        default:
                          return null;
                      }
                    })()}
                  </Typography>
                </Breadcrumbs>
              </Grid>
              <Grid
                item
                xs={6}
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end'
                }}
              >
                {roleid === 1 && (
                  <JBButton
                    type="primary"
                    isImg={Plusicon}
                    onClick={() => this.setState({ openDiag: true })}
                  >
                    new Invoice
                  </JBButton>
                )}
              </Grid>
            </Grid>
          </div>
        </div>

        <Card className={classes.mainCard}>
          <AppBar
            position="static"
            style={{
              backgroundColor: 'transparent',
              boxShadow: 'none'
            }}
          >
            <Tabs
              value={Tabslocation}
              indicatorColor="primary"
              textColor="primary"
              fullWidth
              // onChange={(val) => changeTab(val)}
            >
              <Tab
                label="unpaid this month"
                component={Link}
                to={ROUTES.FINANCIAL_INVOICES}
                className={classes.p_tabs}
                {...a11yProps(0)} // eslint-disable-line
              />
              <Tab
                label="paid this month"
                component={Link}
                to={ROUTES.FINANCIAL_INVOICES_PAID}
                className={classes.p_tabs}
                {...a11yProps(1)} // eslint-disable-line
              />
              <Tab
                label="Overdue"
                component={Link}
                to={ROUTES.FINANCIAL_INVOICES_OVERDUE}
                className={classes.p_tabs}
                {...a11yProps(2)} // eslint-disable-line
              />
              <Tab
                label="Processing"
                component={Link}
                to={ROUTES.FINANCIAL_INVOICES_PROCESSING}
                className={classes.p_tabs}
                {...a11yProps(3)} // eslint-disable-line
              />
              <Tab
                label="Failed"
                component={Link}
                to={ROUTES.FINANCIAL_INVOICES_FAILED}
                className={classes.p_tabs}
                {...a11yProps(4)} // eslint-disable-line
              />
              <Tab
                label="All"
                component={Link}
                to={ROUTES.FINANCIAL_INVOICES_ALL}
                className={classes.p_tabs}
                {...a11yProps(5)} // eslint-disable-line
              />
            </Tabs>
          </AppBar>

          <Switch>
            <Route path={ROUTES.FINANCIAL_INVOICES_ALL} component={AllTab} />
            <Route
              path={ROUTES.FINANCIAL_INVOICES_OVERDUE}
              component={OverdueTab}
            />

            <Route path={ROUTES.FINANCIAL_INVOICES_PAID} component={PaidTab} />
            <Route
              path={ROUTES.FINANCIAL_INVOICES_PROCESSING}
              component={ProcessingTab}
            />
            <Route
              path={ROUTES.FINANCIAL_INVOICES_FAILED}
              component={FailedTab}
            />
            <Route path={ROUTES.FINANCIAL_INVOICES} component={UnpaidTab} />
          </Switch>
        </Card>
        <CreateInvoice
          open={openDiag}
          handleClose={() => this.setState({ openDiag: false })}
          label="new Invoice"
          Dialogtitle="Create New Invoice"
        />
      </div>
    );
  }
}

Invoices.propTypes = {
  classes: PropTypes.object.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  location: PropTypes.any.isRequired,
  pathname: PropTypes.any.isRequired,
  toggleAreAllSelected: PropTypes.func.isRequired
};

export default withStyles(styles, { withTheme: true })(Invoices);
