import {
  getPaginateData,
  getProgramData,
  ProgramActiveInActive
} from 'src/services/programModule.services';

import types from './programactiontypes';
import authhttp from '../../services/authHttp.services';
import filterType from './programReducer.constants';
import { store } from '../rootReducer';
import { getErrorMsg } from '../../utils/ErrorHandling';

const Url = 'api/getallprogrames';

export const fetchProgramsStart = () => ({
  type: types.fetchprogramstart
});

export const fetchProgramsSuccess = (programs) => ({
  type: types.fecthprogramsuccess,
  payload: programs
});

export const fetchProgramsfailure = (errorMessage) => ({
  type: types.fetchprogramfailure,
  payload: errorMessage
});

export const getListPrograms = (programs) => ({
  type: types.getListPrograms,
  payload: programs
});

export const fetchProgramsAsync = () => {
  return async (dispatch) => {
    dispatch(fetchProgramsStart());
    try {
      const {
        data: { data }
      } = await authhttp.get(Url);
      dispatch(fetchProgramsSuccess(data));
    } catch (err) {
      if (err.response) {
        dispatch(fetchProgramsfailure(getErrorMsg(err)));
      } else {
        // We can serve as Something went wrong also
        dispatch(fetchProgramsfailure('Server Error'));
      }
    }
  };
};

export const getListProgramsAsync = (url) => {
  return (dispatch) => {
    getPaginateData(url)
      .then((res) => dispatch(getListPrograms(res)))
      .catch((err) => console.warn(err));
  };
};

const ActiveProgramFilter = (filter, data) => ({
  type: types.activeFilter,
  filter,
  data
});

const InActiveProgramFilter = (filter, data) => ({
  type: types.inactiveFilter,
  filter,
  data
});

const fetchingProgram = (type) => ({
  type,
  actionType: filterType.isFetching
});

const fetchComplete = (type, payload) => ({
  type,
  actionType: filterType.fetchSucess,
  payload
});

const ErrorFetching = (type, payload) => ({
  type,
  actionType: filterType.Errorfetch,
  payload
});

// get Classes function
const getActiveProgram = (val) => {
  const type = types.getActiveProgram;
  return (dispatch) => {
    dispatch(fetchingProgram(type));
    getProgramData(val)
      .then((resp) => {
        dispatch(fetchComplete(type, resp));
      })
      .catch((err) => dispatch(ErrorFetching(type, getErrorMsg(err))));
  };
};

const getInActiveProgram = (val) => {
  const type = types.getInActiveProgram;
  return (dispatch) => {
    dispatch(fetchingProgram(type));
    getProgramData(val)
      .then((res) => dispatch(fetchComplete(type, res)))
      .catch((err) => dispatch(ErrorFetching(type, getErrorMsg(err))));
  };
};

// filter Async
export const ActiveProgram = (filter, data) => {
  return (dispatch) => {
    dispatch(ActiveProgramFilter(filter, data));
    const filData = store.getState().allprograms.programListFilter;
    const updatedValue = {
      page: filData.page,
      search: filData.search,
      status: filData.status,
      ...filData.sorting
    };

    dispatch(getActiveProgram(updatedValue));
  };
};

export const InActiveProgram = (filter, data) => {
  return (dispatch) => {
    dispatch(InActiveProgramFilter(filter, data));
    const filData = store.getState().allprograms.programListInActiveFilter;
    const updatedValue = {
      page: filData.page,
      search: filData.search,
      status: filData.status,
      ...filData.sorting
    };
    dispatch(getInActiveProgram(updatedValue));
  };
};

export const ProgAcDc = async (id, status) => {
  const val = {
    id,
    status
  };
  // eslint-disable-next-line
  try {
    await ProgramActiveInActive(val);
    store.dispatch(ActiveProgram());
    store.dispatch(InActiveProgram());
    store.dispatch(fetchProgramsAsync());
  } catch (err) {
    throw err;
  }
};
