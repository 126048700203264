import React, { useState } from 'react';
import PropType from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  DialogTitle,
  withStyles,
  Radio,
  RadioGroup,
  FormControlLabel,
  Button,
  Grid
} from '@material-ui/core';
import JBButton from '../../common/JBButton/JBButton';
import dcloseicon from '../../../assets/image/closeicon.png';

const styles = () => ({
  title: {
    fontWeight: 'bold',
    fontSize: '32px',
    lineHeight: '42px',
    color: '#292929'
  },
  bodytxt: {
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '0.002em',
    color: '#7F7F7F',
    padding: '10px 0'
  },
  Radiobtn: {
    padding: '8px 9px'
  }
});

function UpdateWaiversDialog({
  classes,
  values,
  handleValidate,
  handleStatus,
  handleSave
}) {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div style={{ marginLeft: '10px' }}>
      <JBButton
        type="primary"
        onClick={() => {
          if (handleValidate()) handleClickOpen();
        }}
      >
        Save waiver
      </JBButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick
      >
        <div style={{ width: 548 }}>
          <Grid alignItems="center" container direction="row">
            <Grid item xs>
              <DialogTitle id="alert-dialog-title">
                <Typography
                  color="#000"
                  variant="h5"
                  component="div"
                  className={classes.title}
                >
                  Update Waiver Template
                </Typography>
              </DialogTitle>
            </Grid>
            <Grid item style={{ textAlign: 'right', marginRight: '10px' }}>
              <Button onClick={handleClose} className={classes.btns}>
                <img alt="img" src={dcloseicon} />
              </Button>
            </Grid>
          </Grid>

          <DialogContent>
            <p className={classes.bodytxt}>
              You are creating a new version of this waiver template that will
              apply to any athletes and leads who are assigned a membership that
              provides access to the programs and/or services configured, and
              athletes who select a configured program as their default.
            </p>
            <p className={classes.bodytxt}>
              Additionally, there are {values.total_signed_users} people who
              have already signed an older version of this template. Should they
              re-sign the new version?
            </p>
            <RadioGroup
              defaultValue="0"
              value={String(values.need_resign)}
              onChange={(e) => {
                handleStatus(e.target.value * 1);
              }}
            >
              <FormControlLabel
                value="0"
                control={<Radio color="primary" className={classes.Radiobtn} />}
                label="No, they don’t need to re-sign the new version"
              />
              <FormControlLabel
                value="1"
                control={<Radio color="primary" className={classes.Radiobtn} />}
                label="Yes, they should be assigned missing waivers to re-sign"
              />
            </RadioGroup>
          </DialogContent>
          <DialogActions style={{ padding: '20px 20px' }}>
            <JBButton type="outlined" onClick={handleClose}>
              discard
            </JBButton>
            <JBButton
              type="primary"
              onClick={() => {
                handleSave();
                handleClose();
              }}
            >
              Confirm
            </JBButton>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}

UpdateWaiversDialog.propTypes = {
  classes: PropType.object.isRequired
};
export default withStyles(styles)(UpdateWaiversDialog);
