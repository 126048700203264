export const HoldTypestableHead = [
  {
    id: 1,
    label: 'Label',
    cellName: 'label'
  }
];

export const HoldTypesDummydata = [
  {
    id: 1,
    label: 'Vacation',
    members: 'Whatever'
  },
  {
    id: 2,
    label: 'Injury',
    members: 'Whatever'
  },
  {
    id: 3,
    label: 'Illness',
    members: 'Whatever'
  },
  {
    id: 4,
    label: 'Financial',
    members: 'Whatever'
  }
];
