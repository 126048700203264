import React, { useEffect } from 'react';
import {
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  withStyles,
  Grid,
  FormControlLabel,
  Radio,
  RadioGroup,
  Input,
  InputAdornment
} from '@material-ui/core';
import PropType from 'prop-types';
import GetPrice from 'src/views/common/CurrencySymbol/GetPrice';
import CurrencyInputs from 'src/views/common/CurrencyControlForm/CurrencyInputs';
import PercentageFormControl from '@material-ui/core/FormControl';
import clsx from 'clsx';
import styles from '../../../../table.style';
import {
  chngeinidisamt,
  chngeinidistype,
  chngesetdisamt,
  chngesetdistype,
  changeinifulltax
} from '../../NewMembership.actions';
import {
  getmemdiscount,
  getsetupdiscount,
  updateIniTaxes
} from '../FinalPrice.utils';

function DiscountTable({ classes, reducer, isEditable }) {
  const [state, dispatch] = reducer;

  useEffect(() => {
    dispatch(changeinifulltax(updateIniTaxes(state.data, 'session')));
    // eslint-disable-next-line
  }, [state.data.discount.initial, state.data.discount.setup]);
  const handleChange = (e) => {
    dispatch(chngeinidistype(e.target.value));
  };
  const handleChangeSetupFee = (e) => {
    dispatch(chngesetdistype(e.target.value));
  };

  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      spacing={0}
      style={{ margin: '0px 0px 40px' }}
    >
      <TableContainer
        style={{ border: '1px solid #A8A8A8', borderRadius: '5px' }}
      >
        <Table className={classes.table} aria-label="simple table">
          <TableHead className={classes.table_th}>
            <TableRow>
              <TableCell className={classes.TableCell} style={{ width: '30%' }}>
                Discount name
              </TableCell>
              <TableCell className={classes.TableCell} style={{ width: '35%' }}>
                &nbsp;
              </TableCell>
              <TableCell
                className={classes.TableCell}
                align="right"
                style={{ width: '10%' }}
              >
                &nbsp;
              </TableCell>
              <TableCell
                className={classes.TableCell}
                align="right"
                style={{ width: '10%' }}
              >
                Amount
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell className={classes.Table_td}>Initial</TableCell>
              <TableCell className={classes.Table_td}>
                <RadioGroup
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'center'
                  }}
                  onChange={handleChange}
                  value={state.data.discount.initial.type}
                  disabled={!isEditable}
                >
                  <FormControlLabel
                    value="percentage"
                    label="Percentage"
                    control={<Radio color="primary" />}
                    style={{ margin: '0 0' }}
                    disabled={!isEditable}
                  />
                  <FormControlLabel
                    value="fixed"
                    label="Fixed"
                    control={<Radio color="primary" />}
                    style={{ margin: '0px 0px 0px 25px' }}
                    disabled={!isEditable}
                  />
                </RadioGroup>
              </TableCell>
              <TableCell className={classes.Table_td}>
                {state.data.discount.initial.type === 'fixed' ? (
                  <CurrencyInputs
                    value={state.data.discount.initial.amount}
                    onChange={(e) => dispatch(chngeinidisamt(e.target.value))}
                    placeholder=""
                    maxLength={10}
                    currencySymbol="A$"
                    style={{
                      margin: '0 0',
                      width: '80px',
                      maxWidth: '80px',
                      backgroundColor: !isEditable ? 'rgb(0 0 0 / 7%)' : null,
                      opacity: !isEditable ? '0.6' : null
                    }}
                    disabled={!isEditable}
                  />
                ) : (
                  <PercentageFormControl
                    className={clsx(
                      classes.PercentageFormControl,
                      state.data.discount.initial.amount <= 100
                        ? null
                        : 'MuiInput-underline Mui-error'
                    )}
                    style={{
                      backgroundColor: !isEditable ? 'rgb(0 0 0 / 7%)' : null,
                      opacity: !isEditable ? '0.6' : null
                    }}
                    disabled={!isEditable}
                  >
                    <Input
                      id="standard-adornment-weight"
                      value={state.data.discount.initial.amount}
                      onChange={(e) => dispatch(chngeinidisamt(e.target.value))}
                      maxLength={10}
                      endAdornment={
                        <InputAdornment
                          position="end"
                          style={{
                            color: '#292929',
                            margin: '-1px 0 0 0px'
                          }}
                        >
                          <span>%</span>
                        </InputAdornment>
                      }
                      aria-describedby="standard-weight-helper-text"
                      inputProps={{
                        'aria-label': 'weight'
                      }}
                      style={{
                        color: '#292929'
                      }}
                      disabled={!isEditable}
                    />
                  </PercentageFormControl>
                )}
              </TableCell>
              <TableCell className={classes.Table_td} align="right">
                <GetPrice value={getmemdiscount(state.data)} />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell className={classes.Table_td}>Setup Fee</TableCell>
              <TableCell className={classes.Table_td}>
                <RadioGroup
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'center'
                  }}
                  onChange={handleChangeSetupFee}
                  value={state.data.discount.setup.type}
                  disabled={!isEditable}
                >
                  <FormControlLabel
                    value="percentage"
                    label="Percentage"
                    control={<Radio color="primary" />}
                    style={{ margin: '0 0' }}
                    disabled={!isEditable}
                  />
                  <FormControlLabel
                    value="fixed"
                    label="Fixed"
                    control={<Radio color="primary" />}
                    style={{ margin: '0px 0px 0px 25px' }}
                    disabled={!isEditable}
                  />
                </RadioGroup>
              </TableCell>
              <TableCell className={classes.Table_td}>
                {state.data.discount.setup.type === 'fixed' ? (
                  <CurrencyInputs
                    value={state.data.discount.setup.amount}
                    onChange={(e) => dispatch(chngesetdisamt(e.target.value))}
                    placeholder=""
                    maxLength={10}
                    currencySymbol="A$"
                    style={{
                      margin: '0 0',
                      width: '80px',
                      maxWidth: '80px',
                      backgroundColor: !isEditable ? 'rgb(0 0 0 / 7%)' : null,
                      opacity: !isEditable ? '0.6' : null
                    }}
                    disabled={!isEditable}
                  />
                ) : (
                  <PercentageFormControl
                    className={clsx(
                      classes.PercentageFormControl,
                      state.data.discount.setup.amount <= 100
                        ? null
                        : 'MuiInput-underline Mui-error'
                    )}
                    style={{
                      backgroundColor: !isEditable ? 'rgb(0 0 0 / 7%)' : null,
                      opacity: !isEditable ? '0.6' : null
                    }}
                    disabled={!isEditable}
                  >
                    <Input
                      id="standard-adornment-weight"
                      value={state.data.discount.setup.amount}
                      maxLength={10}
                      onChange={(e) => dispatch(chngesetdisamt(e.target.value))}
                      endAdornment={
                        <InputAdornment
                          position="end"
                          style={{
                            color: '#292929',
                            margin: '-1px 0 0 0px'
                          }}
                        >
                          <span>%</span>
                        </InputAdornment>
                      }
                      aria-describedby="standard-weight-helper-text"
                      inputProps={{
                        'aria-label': 'weight'
                      }}
                      style={{
                        color: '#292929'
                      }}
                      disabled={!isEditable}
                    />
                  </PercentageFormControl>
                )}
              </TableCell>
              <TableCell className={classes.Table_td} align="right">
                <GetPrice value={getsetupdiscount(state.data)} />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}

DiscountTable.defaultProps = {
  isEditable: true
};

DiscountTable.propTypes = {
  classes: PropType.object.isRequired,
  reducer: PropType.array.isRequired,
  isEditable: PropType.bool
};

export default withStyles(styles)(DiscountTable);
