import React, { useState } from 'react';
import PropType from 'prop-types';
import {
  Paper,
  // Checkbox,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  makeStyles,
  Button
} from '@material-ui/core';
import { getInvoiceStatus } from 'src/views/financial/invoices/Invoice/InvoiceView.utils';
// import filterType from 'src/Redux/Reducer.constants';
import { AffiliateInvoiceDownload } from 'src/services/gym.services';
import { ReactComponent as DownloadIcon } from '../../../../assets/image/downloadGray.svg';

const useStyles = makeStyles({
  table: {
    minWidth: '100%'
  },
  tableLink: {
    color: '#01847A',
    fontWeight: '700'
  },
  TableCell: {
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px',
    borderBottom: '1px solid #A8A8A8',
    height: '56px',
    minHeight: '56px',
    backgroundColor: '#F9F9FA'
  },
  TableBodyCell: {
    padding: '10px 16px'
  },
  Tablemian: {
    maxHeight: '300px'
  }
});

const CheckBoxTable = (props) => {
  const classes = useStyles();
  const { values } = props;

  const [selected] = useState([]);

  // const handleClick = (name) => {
  //   const selectedIndex = selected.indexOf(name);
  //   let newSelected = [];
  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, name);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1)
  //     );
  //   }
  //   setSelected(newSelected);
  // };
  // const handleSelectAllClick = (event) => {
  //   if (event.target.checked) {
  //     const newSelecteds = values.map((n) => n.id);
  //     setSelected(newSelecteds);
  //     return;
  //   }
  //   setSelected([]);
  // };
  // const ClearCheckbox = () => {
  //   setSelected([]);
  // };

  const isSelected = (name) => selected.indexOf(name) !== -1;
  return (
    <TableContainer
      component={Paper}
      elevation={0}
      className={classes.Tablemian}
    >
      {/* {selected.length > 0 && (
          <ClassTableToolbar
            numSelected={selected}
            selectedTags={[]}
            ClearChecks={ClearCheckbox}
            module="class"
          />
        )} */}
      <Table className={classes.table} stickyHeader aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell
              padding="checkbox"
              className={classes.TableCell}
              style={{ width: '5%' }}
            >
              {/* <Checkbox color="primary" onChange={handleSelectAllClick} /> */}
            </TableCell>
            <TableCell style={{ width: '20%' }} className={classes.TableCell}>
              Payment due
            </TableCell>
            <TableCell style={{ width: '20%' }} className={classes.TableCell}>
              Location
            </TableCell>
            <TableCell style={{ width: '20%' }} className={classes.TableCell}>
              Amount
            </TableCell>
            <TableCell style={{ width: '15%' }} className={classes.TableCell}>
              Status
            </TableCell>
            <TableCell
              style={{ width: '10%' }}
              align="center"
              className={classes.TableCell}
            >
              Download
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {values.map((row) => (
            <TableRow
              hover
              // role="checkbox"
              aria-checked={isSelected(row.id)}
              tabIndex={-1}
              key={row.id}
              selected={isSelected(row.id)}
              style={{
                backgroundColor: '#FFF'
              }}
            >
              <TableCell padding="checkbox">
                {/* <Checkbox
                  onClick={() => handleClick(row.id)}
                  color="primary"
                  checked={isSelected(row.id)}
                  // className={classes.TableBodyCell}
                /> */}
              </TableCell>
              <TableCell className={classes.TableBodyCell}>
                {row.due_date_on}
              </TableCell>
              <TableCell className={classes.TableBodyCell}>
                {row.location}
              </TableCell>
              <TableCell className={classes.TableBodyCell}>
                {row.final_amount}
              </TableCell>
              <TableCell className={classes.TableBodyCell}>
                {getInvoiceStatus(row.status)}
              </TableCell>
              <TableCell align="center" className={classes.TableBodyCell}>
                <Button
                  onClick={() => AffiliateInvoiceDownload(row.invoice_id)}
                >
                  <DownloadIcon />
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {values?.length === 0 && (
        <p style={{ padding: '10px 15px' }}>No invoices to show...</p>
      )}
    </TableContainer>
  );
};
CheckBoxTable.propTypes = {
  values: PropType.array.isRequired
};

export default CheckBoxTable;
