const Types = {
  activeMiss: 'ACTIVE_MISSING_CONTRACTS',
  inactiveMiss: 'INACTIVE_MISSING_CONTRACTS',
  allMiss: 'ALL_MISSING_CONTRACTS',
  Signed: 'SIGNED_CONTRACTS',
  activeMissFilter: 'ACTIVE_MISSING_CONTRACT_FILTER',
  inactiveMissFilter: 'INACTIVE_MISSING_CONTRACT_FILTER',
  allMissFilter: 'ALL_MISSING_FILTER',
  SignedFilter: 'SIGNED_FILTER_CONTRACTS',
  contTempFil: 'CONTRACT_TEMPLATE_FILTER',
  contTemp: 'GET_CONTRACT_TEMPLATE',
  isFetching: 'CONTRACTS_FETCHING_START',
  fetchSucess: 'CONTRACTS_FETCHED_SUCCESSFUL',
  Errorfetch: 'ERROR_OCCURED_WHEN_FETCHING'
};

export default Types;
