/* eslint-disable function-paren-newline */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useCallback } from 'react';
import PropType from 'prop-types';
import {
  Grid,
  Card,
  Box,
  InputBase,
  withStyles,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  TableContainer,
  TableSortLabel
} from '@material-ui/core';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import { get as cookieget, remove as cookieremove } from 'js-cookie';
import { openNoti } from 'src/Redux/global/global.actions';
import {
  getAddMemberProgram,
  getAllMembership,
  getMembershipMember,
  removeProgramMember
} from 'src/services/Membersip.services';
// import NotFoundView from 'src/views/errors/NotFoundView';
import { getErrorMsg } from 'src/utils/ErrorHandling';
import LoadingView from 'src/views/common/loading';
import JBStepper from 'src/views/common/JBGridStepper';
import ColorlibStepIcon from 'src/views/common/JBGridStepper/ColorlibStepIcon';
import CheckBoxTable from 'src/views/common/CheckBoxTable/checkBoxTable';
import { ReactComponent as Checkmark } from 'src/assets/image/JBSteppericon/check.svg';
import { ReactComponent as layers } from 'src/assets/image/JBSteppericon/layers.svg';
import { ReactComponent as Usersquare } from 'src/assets/image/JBSteppericon/user-square.svg';
import { tableHead, DatatableHead, stDatatableHead } from './staticData';
import styles from './MembershipProgramsTab.style';
import MemberProgramTable from './MembershipProgramTable';
import DeleteDialog from './DeleteDialog';

function getSteps() {
  return ['Select Program', 'Membership Templates', 'Members Memberships'];
}

function MembershipPrograms(props) {
  const { classes, allprograms } = props;

  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const [memberships, setMemberships] = useState({
    isLoading: false,
    data: null,
    error: null
  });
  const [ids, setIds] = useState({
    programId: null,
    membershipTemplateIds: []
  });
  const [members, setmembers] = useState({
    isLoading: false,
    data: null,
    error: null
  });
  const [searchField, setSearchField] = useState('');
  const [selectedMembership, setSelectedMembership] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);

  /* eslint-disable */
  const filteredMembers =
    members.data &&
    members?.data.filter((member) =>
      member.members.toLowerCase().includes(searchField.toLowerCase())
    );
  /* eslint-enable */
  const cookieprog = cookieget('membershipprog');
  const handleNext = () => {
    const { OpenNoti } = props;
    if (activeStep === 1) {
      if (selectedMembership.length > 0) {
        setmembers({ ...members, isLoading: true });
        getMembershipMember({
          mids: selectedMembership.join(','),
          proId: ids.programId // JB-1259
        })
          .then((res) => {
            setmembers({ ...members, isLoading: false, data: res });
            setActiveStep((prev) => prev + 1);
          })
          .catch(
            (err) =>
              setmembers({
                isLoading: false,
                data: null,
                error: getErrorMsg(err)
              })
            // eslint-disable-next-line function-paren-newline
          );
      }
    } else if (activeStep === 2) {
      setIsProcessing(true);
      if (selectedMembers.length > 0) {
        getAddMemberProgram(selectedMembers.join(','), ids.programId)
          .then(() => {
            OpenNoti('Successfully added Program to membership', 'info');
            setActiveStep(0);
            setSelectedMembership([]);
            setIsProcessing(false);
          })
          .catch((err) => {
            setIsProcessing(false);
            OpenNoti(getErrorMsg(err), 'error');
          });
      }
    } else {
      setActiveStep((prev) => prev + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prev) => prev - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleProgram = (programId) => {
    if (programId !== ids.programId) {
      setIds({
        ...ids,
        programId
      });
    }
    handleNext();
  };

  const debounceSearch = useCallback(
    debounce((search) => setSearchField(search), 500),
    []
  );

  const handleOnSearch = (event) => {
    debounceSearch(event.target.value);
  };

  const handleRemoveProgram = () => {
    const { OpenNoti } = props;
    if (selectedMembers.length > 0) {
      removeProgramMember(selectedMembers.join(','), ids.programId)
        .then(() => {
          OpenNoti('Successfully removed the program', 'info');
          setActiveStep(0);
          setSelectedMembers([]);
        })
        .catch((err) => OpenNoti(getErrorMsg(err), 'error'));
    } else {
      OpenNoti('Please select atleast one member', 'error');
    }
  };
  const getMembershipData = (res) => {
    const result = [];
    for (let i = 0; i < res.length; i += 1) {
      let b = {
        id: res[i].id,
        name: res[i].name,
        membershiptype: res[i].membership_type,
        attendance: 'Unlimited',
        attendance_meta: res[i].attendance_limited_meta
      };
      if (res[i].attendance_limit && res[i].attendance_limited_meta) {
        const local = JSON.parse(res[i].attendance_limited_meta);
        b = { ...b, attendance: `Limited for ${local.uses} ${local.type} ` };
      }

      result.push(b);
    }
    return result;
  };

  useEffect(() => {
    setMemberships({ ...memberships, isLoading: true });
    getAllMembership()
      .then((res) => {
        setMemberships({
          isLoading: false,
          data: getMembershipData(res),
          error: null
        });
      })
      .catch(
        (err) =>
          setMemberships({
            isLoading: false,
            data: null,
            error: getErrorMsg(err)
          })
        // eslint-disable-next-line function-paren-newline
      );
    if (cookieprog) {
      setIds(() => ({ ...ids, programId: cookieprog }));
      handleNext();
      cookieremove('membershipprog');
    }
  }, []); // eslint-disable-line

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <div>
            <Box mt={0} mb={2}>
              <p className={classes.ptext}>
                Add or delete programs from memberships.
              </p>
            </Box>

            <Grid container spacing={0} direction="column">
              <Grid item xs={12} className={classes.SimpleTablestyle}>
                <Paper elevation={0}>
                  <TableContainer
                    component={Paper}
                    elevation={0}
                    className={classes.table}
                    style={{
                      maxHeight: '550px'
                    }}
                  >
                    <Table stickyHeader>
                      <TableHead className={classes.table_th}>
                        <TableRow>
                          {tableHead.map((headCell) => (
                            <TableCell
                              style={{ width: '10%' }}
                              className={classes.TableCell}
                              key={headCell.id}
                            >
                              <TableSortLabel>{headCell.label}</TableSortLabel>
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody className={classes.Table_Body}>
                        {allprograms?.map((row) => (
                          <TableRow key={row.Classname}>
                            {/* eslint-disable-next-line no-confusing-arrow */}
                            {tableHead.map(
                              // eslint-disable-next-line no-confusing-arrow
                              (cells) =>
                                cells.href ? (
                                  <TableCell
                                    scope="row"
                                    key={cells.id}
                                    className={
                                      (classes.Table_td, classes.Table_td_link)
                                    }
                                    onClick={() => handleProgram(row.id)}
                                  >
                                    {row[cells.cellName]}
                                  </TableCell>
                                ) : (
                                  <TableCell
                                    scope="row"
                                    className={classes.Table_td}
                                    key={cells.id}
                                  >
                                    {row[cells.cellName]}
                                  </TableCell>
                                )
                              // eslint-disable-next-line function-paren-newline
                            )}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    {allprograms?.length === 0 && (
                      <p style={{ margin: '10px 15px' }}>
                        No programs to show...
                      </p>
                    )}
                  </TableContainer>
                </Paper>
              </Grid>
            </Grid>
          </div>
        );
      case 1:
        return (
          <div>
            <p className={classes.ptext}>
              Add or delete programs from memberships.
            </p>
            <Grid
              container
              spacing={0}
              xs={12}
              disableGutters
              direction="row"
              style={{
                margin: '15px 0'
              }}
              className={classes.MassMembershipsTable}
            >
              <Grid item xs style={{ marginTop: '0px' }}>
                {memberships.isLoading ? (
                  <LoadingView />
                ) : memberships.error ? (
                  <p>{memberships.error}</p>
                ) : (
                  <CheckBoxTable
                    values={memberships.data}
                    headNames={DatatableHead}
                    initialSelected={selectedMembership}
                    getSelected={(val) => {
                      // eslint-disable-next-line no-unused-expressions
                      selectedMembers !== val && setSelectedMembership(val);
                    }}
                  />
                )}
              </Grid>
            </Grid>
          </div>
        );

      case 2:
        return (
          <div>
            <Grid
              container
              spacing={0}
              xs={12}
              disableGutters
              direction="row"
              style={{
                margin: '0px 0'
              }}
            >
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
                style={{
                  margin: '15px 0'
                }}
              >
                <Grid
                  item
                  xs={6}
                  style={{ marginTop: '0px', paddingRight: '15px' }}
                >
                  <p className={classes.ptext}>
                    Add or delete programs from memberships.
                  </p>
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{ marginTop: '0px', paddingRight: '0px' }}
                >
                  <div className={classes.search}>
                    <InputBase
                      onChange={handleOnSearch}
                      fullWidth
                      placeholder="Search…"
                      classes={{
                        root: classes.inputRoot,
                        input: classes.searchInput
                      }}
                      InputProps={{
                        'aria-label': 'search'
                      }}
                    />
                  </div>
                </Grid>
              </Grid>

              <Grid
                item
                xs
                style={{ marginTop: '0px' }}
                className={classes.MassMembershipsTable}
              >
                {members.isLoading ? (
                  <LoadingView />
                ) : members.error ? (
                  <p>{members.error}</p>
                ) : (
                  <MemberProgramTable
                    values={filteredMembers}
                    headNames={stDatatableHead}
                    initialSelected={selectedMembers}
                    // eslint-disable-next-line no-confusing-arrow
                    getSelected={(val) =>
                      val !== selectedMembers && setSelectedMembers(val)
                    }
                  />
                )}
                <DeleteDialog
                  filteredMembers={filteredMembers}
                  selectedMembers={selectedMembers}
                  handleDelete={handleRemoveProgram}
                />
                {/* <AddDialog /> */}
              </Grid>
            </Grid>
          </div>
        );

      default:
        return 'unknown Step';
    }
  }

  return (
    <div>
      <div>
        <Card elevation={0}>
          <JBStepper
            className={classes.DialogJBStepper}
            steps={steps}
            activeStep={activeStep}
            handleBack={handleBack}
            handleNext={handleNext}
            handleReset={handleReset}
            // eslint-disable-next-line react/jsx-no-bind
            handleStepContent={getStepContent}
            defaultButton={activeStep === 0}
            isprocessing={isProcessing}
            processingLabel="Adding programs to plan..."
            prevLabel="Add programs to plan"
            nextLabel="NEXT STEP"
            validateBtn={[
              !ids.programId,
              selectedMembership.length === 0,
              selectedMembers.length === 0
            ]}
            CustComp={(prop) => (
              <ColorlibStepIcon
                FirstIcon={Checkmark}
                SecondIcon={layers}
                ThirdIcon={Usersquare}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...prop}
              />
            )}
          />
        </Card>
      </div>
    </div>
  );
}

MembershipPrograms.propTypes = {
  classes: PropType.object.isRequired,
  allprograms: PropType.array.isRequired,
  OpenNoti: PropType.func.isRequired
};
const mapToState = (state) => ({
  allprograms: state.allprograms.programs
});
const mapToDispatch = (dispatch) => ({
  OpenNoti: (msg, type) => dispatch(openNoti(msg, type))
});

export default connect(
  mapToState,
  mapToDispatch
)(withStyles(styles)(MembershipPrograms));
