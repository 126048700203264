const styles = () => ({
  p_tabs: {
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px'
  },
  table: {
    fontSize: '14px',
    lineHeight: '22px',
    border: '1px solid #A8A8A8',
    borderRadius: '4px',
    marginTop: '20px'
  },
  table_th: {
    backgroundColor: '#F9F9FA',
    color: '#292929'
  },
  TableCell: {
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px',
    borderBottom: '1px solid #A8A8A8',
    height: '56px',
    minHeight: '56px'
  },
  Table_td: {
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '22px',
    position: 'relative',
    padding: '12px 15px 13px 20px',
    height: '56px',
    minHeight: '56px'
  },
  Table_Body: {}
});
export default styles;
