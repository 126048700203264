/* eslint-disable arrow-parens */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import {
  Grid,
  withStyles,
  Card,
  Breadcrumbs,
  Typography,
  Box
} from '@material-ui/core';
import FormControl from 'src/views/common/FormControl/formControl';
import { isEqual } from 'lodash';
import { checkValidString } from 'src/utils/validations';
import { checkQuillValid } from 'src/utils/someCommon';
import NotFoundView from 'src/views/errors/NotFoundView';
import LoadingView from 'src/views/common/loading';
import { connect } from 'react-redux';
import {
  commuSettings,
  creatcommuSettings
} from 'src/services/Communication.service';
import { getErrorMsg } from 'src/utils/ErrorHandling';
import { openNoti } from 'src/Redux/global/global.actions';
import { getGymSetup } from 'src/Redux/GymSetupReducer/GymSetup.actions';
import { ROUTES } from 'src/constant/Constants';
import JBCKEditorPlaceholder from 'src/views/common/JBCKEditorPlaceholder';
import JBButton from 'src/views/common/JBButton/JBButton';
import useChangeTitle from 'src/views/common/CustomHooks/useTitleChange';
import styles from './ComSettings.style';
import Userimage from '../userimage';

const ComSettings = ({ classes, openNotify, gymSteps, getGymSteps }) => {
  const [data, setData] = useState(() => null);
  const [error, setError] = useState(() => false);
  const [IsimageUpdate, setIsimageUpdate] = useState(() => false);
  const [isProcessing, setProcessing] = useState(() => false);
  const history = useHistory();

  const placeholdersList = [
    'gymname',
    'gymaddressm',
    'gymemail',
    'gymwebsite',
    'gymlogo',
    'gymaddressi',
    'gymphone',
    'signature'
  ];
  useChangeTitle('Communication setting | Jungle Alliance');
  useEffect(() => {
    commuSettings()
      .then((res) => {
        setData({
          ...res,
          meta: {
            email_from_label: res.meta.email_from_label,
            signature: res.meta.signature
          },
          email_sign_logo: res.meta.email_sign_logo,
          reset: {
            email_from_label: res.meta.email_from_label,
            signature: res.meta.signature
          }
        });
      })
      .catch(() => setError(true));
  }, []);

  const handleCreate = () => {
    const formData = new FormData();
    setProcessing(() => true);
    formData.append('module_name', 'communication');
    formData.append('meta', JSON.stringify(data.meta));
    // formData.append('email_sign_logo', data.email_sign_logo);
    if (IsimageUpdate) {
      formData.append('email_sign_logo', data.email_sign_logo);
    }
    creatcommuSettings(formData) // pass id
      .then(() => {
        if (gymSteps.process_type.gym_info === 0) {
          getGymSteps();
        }
        openNotify('Email template successfully saved');
        history.push(ROUTES.COMMUNICATION_SETTINGS);
        setProcessing(() => false);
      })
      .catch((err) => {
        openNotify(getErrorMsg(err), 'error');
        setProcessing(() => false);
      });
  };

  const handleSave = () => {
    if (!data.email_sign_logo) {
      openNotify('Please upload one image', 'error');
    } else if (!data.meta.email_from_label) {
      openNotify('Email label  is empty', 'error');
    } else if (checkValidString(data.meta.email_from_label)) {
      openNotify('Email label is not valid', 'error');
    } else if (!data.meta.signature) {
      openNotify('Email body is empty', 'error');
    } else if (!checkQuillValid(data.meta.signature)) {
      openNotify('Email body is not valid', 'error');
    } else {
      handleCreate();
    }
  };
  const handlereset = () => {
    if (!isEqual(data.meta.email_from_label, data.reset.email_from_label)) {
      return false;
    }
    if (!isEqual(data.meta.signature, data.reset.signature)) return false;
    return true;
  };

  if (error) return <NotFoundView />;
  if (!data) return <LoadingView />;

  return (
    <div className={classes.root}>
      <div className="title-bk">
        <div style={{ marginBottom: '20px' }}>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={0}
          >
            <Grid item xs={12}>
              <Breadcrumbs aria-label="breadcrumb">
                <Link
                  color="inherit"
                  // to={ROUTES.COMMUNICATION_AUTOMATED_EMAILS}
                >
                  communication
                </Link>
                <Typography variant="h3" color="Primary">
                  <span>settings</span>
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
        </div>
      </div>

      <Card className={classes.mainCard}>
        <Grid
          container
          direction="row"
          spacing={0}
          style={{ margin: '10px 0 0px' }}
        >
          <Grid item xs={7}>
            <Box mt="15px" mb="15px">
              <Typography
                color="#000"
                variant="h5"
                component="div"
                className={classes.bktitle}
              >
                Email Signature Logo
              </Typography>
              <p className={classes.ptext}>
                Choose your email logo to provide branding in your communication
                with your members and leads.
              </p>
            </Box>
          </Grid>
          <Grid item xs={5}>
            <Box mt="15px" justifyContent="flex-end" display="flex">
              <Grid item xs={10} className={classes.emailLogo}>
                <Userimage
                  setImage={(val) => {
                    setData({ ...data, email_sign_logo: val });
                    setIsimageUpdate(() => true);
                  }} // eslint-disable-line
                  imagesrc={data.email_sign_logo}
                  isuploaded={Boolean(data.email_sign_logo)}
                />
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <div className={classes.hr} />

        <Grid
          container
          direction="row"
          spacing={0}
          style={{ margin: '10px 0 20px' }}
        >
          <Grid item xs={7}>
            <Box mt="15px" mb="15px">
              <Typography
                color="#000"
                variant="h5"
                component="div"
                className={classes.bktitle}
              >
                Email From Label
              </Typography>
              <p className={classes.ptext}>
                Customize the ”From” label that will be displayed in your email
                &nbsp;
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                recipient's mailbox (ie. the name of your gym).
              </p>
            </Box>
          </Grid>
          <Grid item xs={5}>
            <Box mt="15px" mb="15px" justifyContent="flex-end" display="flex">
              <Grid item xs={10}>
                <Box mb="30px">
                  <FormControl
                    control="input"
                    label="From label"
                    value={data.meta.email_from_label}
                    onChange={(e) => {
                      setData({
                        ...data,
                        meta: { ...data.meta, email_from_label: e.target.value }
                      });
                    }}
                    style={{ margin: '0 0' }}
                  />
                </Box>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <div className={classes.hr} />
        <Box mt="10px">
          {/* <JBEmailQuillEditor
            value={data.meta.signature}
            onChange={(e) => {
              setData({ ...data, meta: { ...data.meta, signature: e } });
            }}
          /> */}
          <JBCKEditorPlaceholder
            holder={placeholdersList}
            data={data.meta.signature}
            onChange={(e) => {
              setData({
                ...data,
                meta: { ...data.meta, signature: e.editor.getData() }
              });
            }}
          />
        </Box>
        <Box mt="40px" />
        <Grid container direction="row" justify="flex-end" alignItems="center">
          <Box ml="10px">
            <JBButton
              type="primary"
              onClick={handleSave}
              disabled={isProcessing || (!IsimageUpdate && handlereset())}
            >
              Save
            </JBButton>
          </Box>
        </Grid>
      </Card>
    </div>
  );
};

ComSettings.propTypes = {
  classes: PropTypes.object.isRequired,
  openNotify: PropTypes.func.isRequired,
  gymSteps: PropTypes.number.isRequired,
  getGymSteps: PropTypes.func.isRequired
};
const mapToState = (state) => ({
  gymSteps: state.GymSetupReducer.data
});
const mapToDispatch = (dispatch) => ({
  openNotify: (msg, type) => dispatch(openNoti(msg, type)),
  getGymSteps: () => dispatch(getGymSetup())
});
export default connect(
  mapToState,
  mapToDispatch
)(withStyles(styles)(ComSettings));
