import authhttp from './authHttp.services';

export const getInvoices = async (values) => {
  const getInvoiceUrl = 'api/invoices';
  const {
    data: { data }
  } = await authhttp.get(getInvoiceUrl, { params: values });
  return data;
};

export const CreatenwInvoice = async (values) => {
  const createUrl = 'api/invoices';
  const {
    data: { data }
  } = await authhttp.post(createUrl, values);
  return data;
};

export const editInvoice = async (id) => {
  const editUrl = `api/invoices/${id}/edit`;
  const {
    data: { data }
  } = await authhttp.get(editUrl);
  return data;
};

export const addLineInvoice = async (values, id) => {
  const lineUrl = 'api/lineitems';
  const val = { ...values, invoice_id: id };
  const {
    data: { data }
  } = await authhttp.post(lineUrl, val);
  return data;
};

export const delLineInvoice = async (id) => {
  const delUrl = `api/lineitems/${id}`;
  const {
    data: { data }
  } = await authhttp.del(delUrl);
  return data;
};

export const updateLineInv = async (values, id) => {
  const updateUrl = `api/lineitems/${id}`;
  const {
    data: { data }
  } = await authhttp.put(updateUrl, values);
  return data;
};

export const updateInvoice = async (val, id) => {
  const updateUrl = `api/invoices/${id}`;
  const {
    data: { data }
  } = await authhttp.put(updateUrl, val);
  return data;
};

export const addInvDiscnt = async (val) => {
  const discountUrl = 'api/add_invoice_discount';
  const {
    data: { data }
  } = await authhttp.post(discountUrl, val);
  return data;
};

export const VoidInvoice = async (id) => {
  const VoidInvUrl = 'api/add_void_invoice';
  const val = {
    invoice_id: id
  };
  const {
    data: { data }
  } = await authhttp.post(VoidInvUrl, val);
  return data;
};

export const getUserInvoices = async (values) => {
  const invUrl = 'api/get_invoice_User';
  const {
    data: { data }
  } = await authhttp.get(invUrl, { params: values });
  return data;
};

// export const getProcessingInvoice = async (values) => {
//   const invUrl = 'api/get_invoice_User';
//   const {
//     data: { data }
//   } = await authhttp.get(invUrl, { params: values });
//   return data;
// };

export const exportInvoices = async (ids) => {
  const exportUrl = 'api/invoice_export';
  const Values = {
    ids
  };
  authhttp
    .post(exportUrl, Values, {
      responseType: 'blob'
    })
    .then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'invoice.xlsx');
      document.body.appendChild(link);
      link.click();
    });
};

export const exportAllInvoices = async (tabs) => {
  const exportUrl = `api/invoices?tab=${tabs}&export_all=1`;

  authhttp
    .get(exportUrl, {
      responseType: 'blob'
    })
    .then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'all_invoice.xlsx');
      document.body.appendChild(link);
      link.click();
    });
};

export const toPayInvoice = async (vals) => {
  const topayUrl = 'api/pay_invoice';
  const {
    data: { data }
  } = await authhttp.post(topayUrl, vals);
  return data;
};

export const getTransactionDetail = async (invoice_id) => {
  const detailUrl = 'api/transactions';
  const {
    data: { data }
  } = await authhttp.get(detailUrl, {
    params: { invoice_id }
  });
  return data;
};

export const refundPayment = async (id) => {
  const PaymentUrl = `api/refundInvoice/${id}`;
  const { data } = await authhttp.post(PaymentUrl);
  return data;
};

export const emailInvoice = async (values) => {
  const emailUrl = 'api/send_email_invoice';
  const { data } = await authhttp.post(emailUrl, values);
  return data;
};

export const getEmailInvoice = async (values) => {
  const emailUrl = `api/getEmailInvoiceTemplate/${values}`;
  const { data } = await authhttp.get(emailUrl);
  return data;
};

export const resendPaymentLink = async (id) => {
  const val = {
    invoice_id: id
  };
  const linkUrl = 'api/resend_payment_link';
  const { data } = await authhttp.post(linkUrl, val);
  return data;
};
