/* eslint-disable indent */
import { getClassData } from './classReducer.utils';
import constType from './class.constant';
import type from './classReducer.Types';
import filterList from '../Reducers.utils';
// initial Value
// TodayFutureClassFilter ,TodayFutureClass , OneTimeAllClass ,FutureClass

// OneTimeClassFilter = { pageNum: , search: '' , customFilter: {...custom} ,
// sort: {}, tags:[...tags] }

// OneTimeClass: null

// Future Class ={ pageNum: , search: '' ,
// sort:{ ...sort} , default: { ...default} ,classes:{}};

// switch(type,data){
// const values = state.getData();
// case: 'pagenum':
// return values = {
// ...values, page: data,
// };
//
// }
const initialValue = {
  futureClassFilter: {
    page: 1,
    search: '',
    customFilter: {},
    tags: [],
    sorting: {},
    default: {
      today_future_class: 1
    }
  },
  allClassFilter: {
    page: 1,
    search: '',
    customFilter: {},
    tags: [],
    sorting: {},
    default: {
      today_future_class: 0
    }
  },
  recurrFilter: {
    page: 1,
    search: '',
    customFilter: {},
    tags: [],
    sorting: {}
  },
  futureClass: { isFetching: false, error: null, data: null },
  allClass: { isFetching: false, error: null, data: null },
  recurrClass: { isFetching: false, error: null, data: null },
  coaches: null,
  areAllSelected: false
};

const classReducer = (state = initialValue, action) => {
  switch (action.type) {
    case type.futureFilter:
      return {
        ...state,
        futureClassFilter:
          action.data && action?.data[0]?.recurring_id
            ? {
                page: 1,
                search: '',
                customFilter: {
                  ...action.data[0]
                },
                tags: [action.data[1]],
                sorting: {},
                default: {
                  today_future_class: 1
                }
              }
            : filterList(state.futureClassFilter, action.filter, action.data)
      };
    case type.allFilter:
      return {
        ...state,
        allClassFilter:
          action.data && action?.data[0]?.recurring_id
            ? {
                page: 1,
                search: '',
                customFilter: {
                  ...action.data[0]
                },
                tags: [action.data[1]],
                sorting: {},
                default: {
                  today_future_class: 0
                }
              }
            : filterList(state.allClassFilter, action.filter, action.data)
      };
    case type.recurrFilter:
      return {
        ...state,
        recurrFilter: filterList(state.recurrFilter, action.filter, action.data)
      };
    case type.futureClass:
      return {
        ...state,
        futureClass: getClassData(
          constType.futureClass,
          action.actionType,
          state.futureClass,
          action.payload
        )
      };
    case type.AllClass:
      return {
        ...state,
        allClass: getClassData(
          constType.allClass,
          action.actionType,
          state.allClass,
          action.payload
        )
      };
    case type.recurrClass:
      return {
        ...state,
        recurrClass: getClassData(
          constType.recurrClass,
          action.actionType,
          state.recurrClass,
          action.payload
        )
      };
    case type.getCoaches:
      return {
        ...state,
        coaches: action.payload
      };
    case type.allSelected:
      return {
        ...state,
        areAllSelected: action.payload
      };
    default:
      return state;
  }
};

export default classReducer;
