import React, { PureComponent } from 'react';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import PropType from 'prop-types';
import LoadingView from 'src/views/common/loading';
import {
  FavWOD,
  getWOD,
  getWODTags
} from '../Redux/WODReducers/wodReducer.actions';
import Wod from '../views/wod/CreateWOD/Wod';
import ListView from '../views/wod/List/ListView';
import DetailView from '../views/wod/DetailView/DetailView';
import WodEditView from '../views/wod/EditView/wodEditView';
import CopyWod from '../views/wod/CopyWOD/CopyWod';
import WodSettings from '../views/wod/WodSettings';

class WODContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      fetched: false
    };
  }

  componentDidMount() {
    const { WOD, WODfav, getTags } = this.props;
    WOD();
    WODfav();
    getTags();
    this.setState({ fetched: true });
  }

  render() {
    const {
      match: { url }
    } = this.props;

    const { fetched } = this.state;

    if (!fetched) {
      return <LoadingView />;
    }
    return (
      <Switch>
        <Route exact path={`${url}/create`} component={Wod} />
        <Route exact path={`${url}/detailview`} component={DetailView} />
        <Route path={`${url}/copywod`} component={CopyWod} />
        <Route path={`${url}/editview`} component={WodEditView} />
        <Route path={`${url}/setting`} component={WodSettings} />
        <Route path={`${url}`} component={ListView} />
      </Switch>
    );
  }
}
WODContainer.propTypes = {
  match: PropType.object.isRequired,
  WOD: PropType.func.isRequired,
  WODfav: PropType.func.isRequired,
  getTags: PropType.func.isRequired
};

const mapToDispatch = (dispatch) => ({
  WOD: () => dispatch(getWOD()),
  WODfav: () => dispatch(FavWOD()),
  getTags: () => dispatch(getWODTags())
});

export default connect(null, mapToDispatch)(WODContainer);
