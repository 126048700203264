export const changetemplatename = 'CHANGE_TEMPLATE_NAME';
export const changeattendncelmt = 'CHANGE_TEMPLATE_ATTENDANCE_LIMIT';
export const changeattendancelmtuses = 'CHANGE_TEMPLATE_ATTENDANCE_META_USES';
export const changeattendancelmttype = 'CHANGE_ATTENDANCE_LIMIT_META_TYPE';
export const changetemplaterevecat = 'CHANGE_TEMPLATE_REVENVUE_CATEGORY';
export const changetemplatedesc = 'CHANGE_TEMPLATE_DESCRIPTION';
export const changetemplatepayment = 'CHANGE_TEMPLATE_PAYMENT_PLAN';
export const addtemplatepayment = 'ADD_TEMPLATE_PAYMENT_PLAN';
export const changetemplateprogram = 'CHANGE_TEMPLATE_PROGRAM_ACCESS';
export const changetemplatecontract = 'CHANGE_TEMPLATE_CONTRACT_ID';
export const changeNoSession = 'CHANGE_NUMBER_OF_SESSIONS';
