import React, { useEffect } from 'react';
import PropType from 'prop-types';
import {
  Paper,
  Table,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
  Checkbox
} from '@material-ui/core';
import EnhancedTableHead from '../JBTables/enhanceTableHead';
import TableHeader from './TableHead';
import useStyles from './table.style';

const CheckBoxTable = ({
  headNames,
  values,
  includeHeader,
  initialSelected,
  getSelected
}) => {
  const [selected, setSelected] = React.useState(initialSelected);
  const classes = useStyles();

  const handleClick = (name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = values.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  useEffect(() => {
    getSelected(selected);
  }, [selected]); // eslint-disable-line

  const isSelected = (name) => selected.indexOf(name) !== -1;
  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={0}>
        {includeHeader && <TableHeader numSelected={selected} />}
        <TableContainer
          style={{ border: '1px solid #A8A8A8', borderRadius: '5px' }}
          className={classes.Tablemian}
        >
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
            style={{
              backgroundColor: '#F9F9FA'
            }}
            stickyHeader
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              // order={order}
              // orderBy={orderBy}
              headCells={headNames}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={false}
              rowCount={values.length}
              className={classes.thead}
            />
            <TableBody>
              {values.map((row) => (
                <TableRow
                  hover
                  onClick={() => handleClick(row.id)}
                  role="checkbox"
                  aria-checked={isSelected(row.id)}
                  tabIndex={-1}
                  key={row.id}
                  selected={isSelected(row.id)}
                  style={{
                    backgroundColor: '#FFF'
                  }}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      checked={isSelected(row.id)}
                      inputProps={{ 'aria-labelledby': 'IDN' }}
                    />
                  </TableCell>
                  {headNames.map((cells) => (
                    <TableCell align="left" key={cells.id}>
                      {Array.isArray(row[cells.cellName])
                        ? row[cells.cellName].join(' ,')
                        : row[cells.cellName]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
};

CheckBoxTable.defaultProps = {
  includeHeader: false
};

CheckBoxTable.propTypes = {
  headNames: PropType.array.isRequired,
  values: PropType.array.isRequired,
  includeHeader: PropType.bool,
  initialSelected: PropType.array.isRequired,
  getSelected: PropType.func.isRequired
};

export default CheckBoxTable;
