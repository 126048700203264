import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropType from 'prop-types';
import {
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  makeStyles,
  TableSortLabel
} from '@material-ui/core';
import filterType from 'src/Redux/Reducer.constants';
import LoadingView from '../common/loading';

const useStyles = makeStyles({
  table: {
    wordBreak: 'break-word'
  },
  tableLink: {
    color: '#01847A',
    fontWeight: '700'
  },
  dayofweek: {
    background: '#A9BFEF',
    borderRadius: '50px',
    padding: '8px',
    color: '#FFF'
  },
  TooltipLink: {
    '&:hover': {
      '& svg path': {
        stroke: '#01847A'
      }
    }
  },
  TableCell: {
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px',
    borderBottom: '1px solid #A8A8A8',
    height: '56px',
    minHeight: '56px'
  }
});

const Templatestable = (props) => {
  const classes = useStyles();
  const {
    isActive,
    setDirection,
    setSort,
    tempState: { isFetching, data }
  } = props;
  const { sortname, sort_updated, sort_visibility } = filterType;

  return (
    <div>
      {isFetching ? (
        <LoadingView />
      ) : (
        <TableContainer component={Paper} elevation={0}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow style={{ background: '#F9F9FA' }}>
                <TableCell
                  style={{ width: '30%' }}
                  className={classes.TableCell}
                >
                  <TableSortLabel
                    active={isActive(sortname)}
                    direction={setDirection(sortname)}
                    onClick={() => setSort(sortname)}
                  >
                    Contract Name
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  className={classes.TableCell}
                  style={{ width: '24%' }}
                >
                  Assigned Membership Template
                </TableCell>
                <TableCell
                  className={classes.TableCell}
                  style={{ width: '20%' }}
                >
                  <TableSortLabel
                    active={isActive(sort_updated)}
                    direction={setDirection(sort_updated)}
                    onClick={() => setSort(sort_updated)}
                  >
                    Updated
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  className={classes.TableCell}
                  align="right"
                  style={{ width: '10%' }}
                >
                  <TableSortLabel
                    active={isActive(sort_visibility)}
                    direction={setDirection(sort_visibility)}
                    onClick={() => setSort(sort_visibility)}
                  >
                    Visibility
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>

            <>
              {data && data.length > 0 ? (
                <TableBody>
                  {data.map((row) => (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={row.id}
                      style={{
                        backgroundColor: '#FFF'
                      }}
                    >
                      <TableCell
                        style={{
                          position: 'relative',
                          color: '#01847A',
                          fontWeight: '700'
                        }}
                      >
                        <Link
                          className={classes.tableLink}
                          to={{
                            pathname: '/app/contracttemplates',
                            search: `contractId=${row.id}`
                          }}
                        >
                          {row.name}
                        </Link>
                      </TableCell>
                      <TableCell>
                        {row.memberships_name
                          && row.memberships_name.split(',').join(', ')}
                      </TableCell>
                      <TableCell>{row.updated}</TableCell>
                      <TableCell align="right">
                        {row.visibility === 1 ? 'Published' : 'Draft'}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              ) : (
                <p>No Templates to show...</p>
              )}
            </>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

Templatestable.propTypes = {
  isActive: PropType.func.isRequired,
  setDirection: PropType.func.isRequired,
  setSort: PropType.func.isRequired,
  tempState: PropType.object.isRequired
};

const mapToState = (state) => ({
  tempState: state.ContractReducer.Templates
});

export default connect(mapToState)(Templatestable);
