import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import PropType from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  withStyles,
  Grid,
  FormControlLabel,
  InputLabel,
  Box
} from '@material-ui/core';
import moment from 'moment';
import IOSSwitch from 'src/views/common/FormControl/IOSSwitch';
import { ROUTES } from 'src/constant/Constants';
import { openNoti } from 'src/Redux/global/global.actions';
import { getErrorMsg } from 'src/utils/ErrorHandling';
import JBDatePicker from 'src/views/common/FormControl/JBDatePicker';
import { CreatenwInvoice } from 'src/services/Invoice.services';
import dcloseicon from '../../../assets/image/closeicon.png';
import JBButton from '../../common/JBButton/JBButton';
import CustomAutoComplete from '../../common/CustomAutocomplete/commonAutocomplete';

const styles = () => ({
  dtitle: {
    color: '#292929',
    fontWeight: 'bold',
    fontSize: '33px',
    lineHeight: '26px'
  },
  cutom_select_Label: {
    fontSize: '12px',
    padding: '7px 0'
  },
  cutom_date: {
    '& .MuiInput-root': {
      color: '#545454',
      height: '60px',
      fontWeight: '600',
      fontSize: '14px',
      lineHeight: '18px',
      marginTop: '0px !important'
    }
  }
});

function CreateInvoice(props) {
  const { classes, Dialogtitle, handleClose, open, AllMembers } = props;
  const [data, setData] = useState({
    user: null,
    dueDate: new Date(),
    autoNew: false
  });
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    return () => setData({ user: null, dueDate: new Date(), autoNew: false });
  }, [open]);

  const handleSave = () => {
    const { OpenNoti } = props;
    if (!data.user) {
      OpenNoti('Please select the Member', 'error');
    } else {
      const val = {
        user_id: data.user.id,
        due_date: moment(data.dueDate).format('YYYY-MM-DD'),
        auto_bill: data.autoNew ? 1 : 0
      };
      setIsLoading(true);
      CreatenwInvoice(val)
        .then(({ id }) => {
          setIsLoading(false);
          OpenNoti('New Invoice created successfully', 'info');
          history.push({
            pathname: ROUTES.FINANCIAL_INVOICES_VIEW,
            search: `Invoice=${btoa(id)}`,
            state: { isNew: true }
          });
        })
        .catch((err) => {
          setIsLoading(false);
          OpenNoti(getErrorMsg(err), 'error');
        });
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick
      >
        <div style={{ width: '548px' }}>
          <Grid alignItems="center" container direction="row">
            <Grid item xs>
              <DialogTitle
                id="alert-dialog-title"
                className={classes.titlebk}
                style={{
                  display: 'inline-block'
                  // padding: '25px 24px 10px'
                }}
              >
                <h4 className={classes.dtitle}>{Dialogtitle}</h4>
              </DialogTitle>
            </Grid>
            <Grid
              item
              xs={2}
              style={{
                textAlign: 'right',
                marginRight: '10px',
                marginTop: '10px'
              }}
            >
              <Button className={classes.btns} onClick={handleClose}>
                <img alt="img" src={dcloseicon} />
              </Button>
            </Grid>
          </Grid>

          <DialogContent>
            <Grid container spacing={2} style={{ marginTop: '5px' }}>
              <Grid item xs={12} style={{}}>
                <Box>
                  <InputLabel
                    htmlFor="age-required"
                    className={classes.cutom_select_Label}
                    style={{ margin: '0px 0 5px' }}
                    required
                  >
                    Select member
                  </InputLabel>
                  <CustomAutoComplete
                    holder=""
                    Options="name"
                    data={data.user}
                    value={AllMembers.data || []}
                    style={{ marginBottom: '15px' }}
                    Change={(val) => setData({ ...data, user: val })}
                  />
                </Box>
                <Box>
                  <InputLabel
                    htmlFor="age-required"
                    className={classes.cutom_select_Label}
                    style={{ margin: '0px 0 5px' }}
                    required
                  >
                    Payment due date
                  </InputLabel>
                  <JBDatePicker
                    value={data.dueDate}
                    onChange={(val) => setData({ ...data, dueDate: val })}
                    style={{
                      margin: '0 0 !important'
                    }}
                    className={classes.cutom_date}
                  />
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                className="switch-btn"
                style={{ marginBottom: '15px' }}
              >
                <FormControlLabel
                  control={
                    <IOSSwitch
                      checked={data.autoNew}
                      onChange={() =>
                        setData({ ...data, autoNew: !data.autoNew })
                      }
                    />
                  }
                  label="Auto bill"
                  style={{ margin: '0 0' }}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions
            style={{
              padding: '20px 20px'
            }}
          >
            <JBButton type="primary" onClick={handleSave} disabled={isLoading}>
              {isLoading ? 'Creating' : 'Create'}
            </JBButton>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}
CreateInvoice.propTypes = {
  classes: PropType.object.isRequired,
  Dialogtitle: PropType.string.isRequired,
  AllMembers: PropType.object.isRequired,
  open: PropType.bool.isRequired,
  handleClose: PropType.func.isRequired,
  OpenNoti: PropType.func.isRequired
};

const mapToState = (state) => ({
  AllMembers: state.memberReducer.allActiveMembers
});

const mapToDispatch = (dispatch) => ({
  OpenNoti: (msg, type) => dispatch(openNoti(msg, type))
});

export default connect(
  mapToState,
  mapToDispatch
)(withStyles(styles)(CreateInvoice));
