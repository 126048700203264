import { store } from '../../rootReducer';

// eslint-disable-next-line import/prefer-default-export
export function getreportState(filter) {
  const state = store.getState().reportsReducer?.membersReports[filter];
  return state;
}

export function getAttenState(filter) {
  const state = store.getState().reportsReducer?.attendanceReports[filter];
  return state;
}

export function getAttenSummaryState() {
  const state = store.getState().reportsReducer?.attendanceReports
    .attendanceSummaryFil.filterAdded;
  return state;
}

export function getFinancialState(filter) {
  const state = store.getState().masterHqReducer?.reports.financialReports[
    filter
  ];
  return state;
}

export function getLeadState(filter) {
  const state = store.getState().reportsReducer?.leadReports[filter];
  return state;
}
