import types from './VanguardWaiver.constants';

export const initialState = {
  isLoading: false,
  activeStep: 0,
  selectedWaiver: null,
  signStatus: false,
  isSigned: false,
  personal_info: {
    id: null,
    first_name: '',
    last_name: '',
    email: '',
    gender: '',
    address: '',
    city: '',
    province: '',
    country: null,
    postal_code: '',
    mobile_no: null,
    dob: new Date(),
    eme_contact_name: '',
    eme_contact_phone: null,
    waiver_terms_initial: {
      sign_initials: '',
      initial_box: null
    },
    questionnaire_meta: [],
    signature: ''
  }
};

export const VanguardWaiverReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.setIsLoading:
      return {
        ...state,
        isLoading: action.payload
      };
    case types.ResetStep:
      return {
        ...state,
        activeStep: 0
      };
    case types.NextStep:
      return {
        ...state,
        activeStep: state.activeStep + 1
      };
    case types.StepBack:
      return {
        ...state,
        activeStep: state.activeStep - 1
      };
    case types.SetActiveStep:
      return {
        ...state,
        activeStep: action.payload
      };
    case types.ResetData:
      return {
        ...state,
        personal_info: initialState.personal_info
      };
    case types.SetMember:
      return {
        ...state,
        personal_info: {
          ...state.personal_info,
          ...action.payload
        }
      };
    case types.SetSelectedWaiver:
      return {
        ...state,
        selectedWaiver: action.payload
      };
    case types.toggleSignStatus:
      return {
        ...state,
        signStatus: !state.signStatus
      };
    case types.setSignStatus:
      return {
        ...state,
        signStatus: action.payload
      };
    case types.setSignInitials:
      return {
        ...state,
        personal_info: {
          ...state.personal_info,
          waiver_terms_initial: {
            ...state.personal_info.waiver_terms_initial,
            sign_initials: action.payload
          }
        }
      };
    case types.setQuestionnaire:
      return {
        ...state,
        personal_info: {
          ...state.personal_info,
          questionnaire_meta: action.payload
        }
      };
    case types.setSignature:
      return {
        ...state,
        personal_info: {
          ...state.personal_info,
          signature: action.payload
        }
      };
    case types.setIsSigned:
      return {
        ...state,
        isSigned: action.payload
      };

    case types.setWaiverInitialBox:
      return {
        ...state,
        personal_info: {
          ...state.personal_info,
          waiver_terms_initial: {
            ...state.personal_info.waiver_terms_initial,
            initial_box: action.payload.initial
          }
        }
      };
    default:
      return state;
  }
};
