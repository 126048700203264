export default {
  getDailyListLead: 'FETCH_DAILY_LIST_LEAD',
  getNEWleadlist: 'FETCH_NEW_LEAD_LIST',
  getfollowuplist: 'FETCH_LEADS_FOLLOW_UP_LIST',
  dailylistfilter: 'LEADS_DAILY_LIST_FILTER',
  newleadsfilter: 'LEADS_NEW_LIST_FILTER',
  followupfilter: 'LEADS_FOLLOW_UP_FILTER',
  getNewLead: 'FETCH_NEW_LEAD',
  getConsultBooked: 'FETCH_CONSULT_BOOKED',
  ConsultBookedfilter: 'LEADS_CONSULT_BOOKED_FILTER',
  getOnboardbooked: 'FETCH_ONBOARD_BOOKED',
  Onboardbookedfilter: 'LEADS_ONBOARD_BOOKED_FILTER',
  getConvertedLead: 'FETCH_CONVERTED_LEAD',
  ConvertedLeadfilter: 'LEADS_CONVERTED_FILTER',
  getAllLead: 'FETCH_ALL_LEAD',
  AllLeadfilter: 'LEADS_ALL_FILTER',
  getArchievedLead: 'FETCH_ARCHIEVED_LEAD',
  ArchievedLeadFilter: 'LEADS_ARCHIEVED_FILTER',
  isFetching: 'LEADS_FETCHING_START',
  fetchSucess: 'LEADS_FETCHED_SUCCESSFUL',
  Errorfetch: 'LEADS_OCCURED_WHEN_FETCHING',
  getLeadSource: 'FETCH_LEAD_SOURCE',
  getLeadStatus: 'FETCH_LEAD_STATUS',
  getLeadDefaultMemberStatus: 'FETCH_LEAD_DEFAULT_MEMBER_STATUS',
  getTags: 'FETCH_TAGS',
  selectAll: 'SELECT_ALL'
};
