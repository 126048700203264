/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import PropType from 'prop-types';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { Grid, Box, Button, Card, Breadcrumbs } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import DOMPurify from 'dompurify';
import queryString from 'query-string';
import moment from 'moment';
import { getErrorMsg } from 'src/utils/ErrorHandling';
import { FavWOD, getWOD } from 'src/Redux/WODReducers/wodReducer.actions';
import { openNoti } from 'src/Redux/global/global.actions';
import { getAllGymSet } from 'src/Redux/SettingReducer/SettingReducer.actions';
import NotFoundView from 'src/views/errors/NotFoundView';
import { delWOD, detailWOD } from 'src/services/WODmodule.services';
import { GymdateFormat } from 'src/constant/GymDateTime';
import useChangeTitle from 'src/views/common/CustomHooks/useTitleChange';
import ClassTable from '../ClassTable/ClassTable';
import DeleteworkoutDialog from '../DeleteworkoutDialog';
import ArrowLeft from '../../../assets/image/arrow_left.png';
import styles from './detailView.style';
import LoadingView from '../../common/loading/index';
import { ROUTES } from '../../../constant/Constants';
import { getTagfromStr } from '../WOD.utils';

const DetailView = (props) => {
  const [data, setData] = useState(null);
  const [errorFetch, setErrorFetch] = useState(false);
  const { classes, history, location, gymSetting, getGymSet } = props;
  const { workout } = queryString.parse(location.search);
  useChangeTitle('Workout Details | Jungle Alliance');
  useEffect(() => {
    detailWOD(workout)
      .then(
        (res) =>
          setData({
            ...res,
            workout_details: res.workout_details.replace(/\\/g, ''),
            coach_notes: res.coach_notes
              ? res.coach_notes?.replace(/\\/g, '')
              : null
          })
        // eslint-disable-next-line function-paren-newline
      )
      .catch(() => setErrorFetch(true));
    if (gymSetting.error) {
      getGymSet();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workout]);

  const deleteWOD = () => {
    const { reloadWOD, reloadFavWOD, OpenNoti } = props;
    delWOD(workout)
      .then(() => {
        reloadWOD();
        reloadFavWOD();
      })
      .then(() => history.push(ROUTES.WORKOUT_LIST))
      .then(() => OpenNoti('Workout Deleted Successfully', 'info'))
      .catch((err) => OpenNoti(getErrorMsg(err), 'error'));
  };

  let copyWOD = null;
  if (data !== null) {
    copyWOD = {
      name: data.name,
      class_list: null,
      workout_details: data.workout_details,
      coach_notes: data.coach_notes,
      tags: getTagfromStr(data.tags),
      secondary_tags: getTagfromStr(data.secondary_tags),
      program: { id: data.programe_id, name: data.programe }
    };
  }

  const preLoadData = (
    <div className="title-bk">
      <div style={{ marginBottom: 15 }}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={0}
        >
          <Grid item xs={8}>
            <Breadcrumbs aria-label="breadcrumb">
              <div color="inherit">Workouts</div>
              <Link color="inherit" to={ROUTES.WORKOUT_LIST}>
                List
              </Link>
              <Typography
                variant="h3"
                color="primary"
                // color="Primary"
                className={classes.Bredtitle}
              >
                <span>{data ? data.name : 'Workout'}</span>
              </Typography>
            </Breadcrumbs>
          </Grid>

          <Grid item xs={4} className={classes.btnBlock}>
            <DeleteworkoutDialog handleDelete={deleteWOD} />
            <Button
              color="primary"
              variant="outlined"
              className={classes.Button}
              disabled={!copyWOD}
              onClick={() =>
                history.push({
                  pathname: ROUTES.WORKOUT_COPY_VIEW,
                  state: copyWOD
                })
              } // eslint-disable-line
            >
              <span style={{ fontWeight: '700' }}>COPY</span>
            </Button>
            <Button
              color="primary"
              className={classes.button}
              onClick={() =>
                history.push({
                  pathname: ROUTES.WORKOUT_EDIT_VIEW,
                  search: `workout=${workout}`
                })
              } // eslint-disable-line
            >
              <span>EDIT</span>
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
  if (errorFetch || !workout) return <NotFoundView />;
  if (!data) {
    return (
      <div className={classes.root}>
        {preLoadData}
        <LoadingView />
      </div>
    );
  }

  const {
    date,
    programe,
    name,
    class_list,
    tags,
    workout_details,
    secondary_tags,
    coach_notes
  } = data;

  const workoutData = DOMPurify.sanitize(workout_details);
  const CoachNotes = DOMPurify.sanitize(coach_notes);
  // eslint-disable-next-line operator-linebreak
  const DateFormat =
    GymdateFormat.filter((l) => l.format === gymSetting?.data?.date_format)[0]
      ?.value || 'DD/MM/YYYY';

  return (
    <div className={classes.root}>
      {preLoadData}
      <Card className={classes.mainCard}>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
        >
          <div>
            <Button
              className={classes.ArrowLeft}
              onClick={() => history.goBack()}
            >
              <img alt="Plusicon" src={ArrowLeft} />
            </Button>
          </div>
          <Grid item xs>
            <Typography
              // color="#000"
              variant="h3"
              component="div"
              className={classes.title}
            >
              {name}
            </Typography>
            <div className={classes.Dinfo}>
              <Box display="inline-block" mr={1}>
                <span>Date: </span>
                <label>
                  {/* {date} */}
                  {moment(date).format(`${DateFormat}`)}
                </label>
              </Box>
              <Box display="inline-block">
                <span>Program: </span>
                <label>{programe}</label>
              </Box>
            </div>
            {tags && (
              <div>
                <div
                  style={{ marginTop: '20px', fontWeight: '600' }}
                  className={classes.Tagstitle}
                >
                  Primary Tags:
                </div>
                <div className={classes.DTags}>
                  {tags.split(',').map((tag, i) => (
                    <Box display="inline-block" mr={1} mb={1} key={i}>
                      <label>{tag}</label>
                    </Box>
                  ))}
                </div>
              </div>
            )}
            {secondary_tags && ( // eslint-disable-line
              <div>
                <div
                  style={{ marginTop: '20px', fontWeight: '600' }}
                  className={classes.Tagstitle}
                >
                  Secondary Tags:
                </div>
                <div className={classes.DTags}>
                  {secondary_tags.split(',').map((tag, i) => (
                    <Box display="inline-block" mr={1} mb={1} key={i}>
                      <label>{tag}</label>
                    </Box>
                  ))}
                </div>
              </div>
            )}

            <Typography
              // color="#000"
              variant="h5"
              component="div"
              className={classes.title}
              style={{
                fontSize: '20px',
                marginTop: '20px',
                marginBottom: '24px'
              }}
            >
              Selected classes for this workout
            </Typography>
            {class_list.length > 0 && <ClassTable values={class_list} />}
            {workoutData.length > 0 && (
              <Box display="block" mt={3}>
                <Typography
                  // color="#000"
                  variant="h5"
                  component="div"
                  className={classes.title}
                  style={{ fontSize: '20px', marginBottom: '24px' }}
                >
                  Workout details
                </Typography>
                <div
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: workoutData }}
                  style={{
                    border: '1px solid #a8a8a8',
                    borderRadius: '4px',
                    padding: '15px',
                    lineHeight: '1.6'
                  }}
                  className="cke_contents_ltr cke_editable cke_editable_themed cke_show_borders"
                />
              </Box>
            )}
            {CoachNotes.length > 0 && (
              <Box display="block" mt={3}>
                <Typography
                  // color="#000"
                  variant="h5"
                  component="div"
                  className={classes.title}
                  style={{ fontSize: '20px', marginBottom: '24px' }}
                >
                  Coach Notes
                </Typography>
                <div
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: CoachNotes }}
                  style={{
                    border: '1px solid #a8a8a8',
                    borderRadius: '4px',
                    padding: '15px',
                    lineHeight: '1.6'
                  }}
                  className="cke_contents_ltr cke_editable cke_editable_themed cke_show_borders"
                />
              </Box>
            )}
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};

DetailView.propTypes = {
  classes: PropType.object.isRequired,
  // openDelete: PropType.func,
  history: PropType.any.isRequired,
  location: PropType.any.isRequired,
  reloadFavWOD: PropType.func.isRequired,
  reloadWOD: PropType.func.isRequired,
  OpenNoti: PropType.func.isRequired,
  gymSetting: PropType.object.isRequired,
  getGymSet: PropType.func.isRequired
};

const mapToState = (state) => ({
  gymSetting: state.SettingReducer.date
});
const mapToDispatch = (dispatch) => ({
  reloadWOD: () => dispatch(getWOD()),
  reloadFavWOD: () => dispatch(FavWOD()),
  getGymSet: () => dispatch(getAllGymSet()),
  OpenNoti: (msg, type) => dispatch(openNoti(msg, type))
});

export default connect(
  mapToState,
  mapToDispatch
)(withStyles(styles)(DetailView));
