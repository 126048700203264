import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ROUTES } from 'src/constant/Constants';
import PropType from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { openNoti } from 'src/Redux/global/global.actions';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import {
  cancelClasses,
  deleteClasses
} from 'src/services/classModule.services';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import {
  AllClassdata,
  futureddata,
  reccurdata
} from 'src/Redux/ClassReducer/classReducer.actions';
import filterType from 'src/Redux/Reducer.constants';
import X from '../../../../assets/image/x.png';
import { getErrorMsg } from '../../../../utils/ErrorHandling';

const styles = () => ({
  Deletebtn: {
    background: '#FB4E4E',
    boxShadow: '0px 8px 16px rgba(251, 78, 78, 0.16)',
    borderRadius: '2px',
    height: '48px',
    padding: '10px 15px',
    color: '#fff',
    fontWeight: 'bold',
    '&:hover': {
      background: '#FB4E4E'
    }
  }
});

function WarningDialog(props) {
  const { classes, handleClose, setOpen, records, DeleteDialog } = props;
  const history = useHistory();
  const handleDialog = () => {
    const { OpenNoti, getAllClass, getFuture, getReccClass } = props;
    if (records.length > 0) {
      if (DeleteDialog) {
        deleteClasses(records)
          .then(() => getAllClass(filterType.page, 1))
          .then(() => getFuture(filterType.page, 1))
          .then(() => getReccClass())
          .then(() => history.push(ROUTES.CLASSES_LIST))
          .then(() => OpenNoti('Sucessfully deleted the classes', 'info'))
          .catch((err) => OpenNoti(getErrorMsg(err), 'error'));
      } else {
        cancelClasses(records)
          .then(() => getAllClass(filterType.page, 1))
          .then(() => getFuture(filterType.page, 1))
          .then(() => getReccClass())
          .then(() => history.push(ROUTES.CLASSES_LIST))
          .then(() => OpenNoti('Sucessfully Cancel the classes', 'info'))
          .catch((err) => OpenNoti(getErrorMsg(err), 'error'));
      }
    } else {
      OpenNoti('Please Add Records for taking Actions', 'error');
    }
  };
  return (
    <div>
      <Dialog
        open={setOpen}
        onClose={() => handleClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div style={{ width: 394 }}>
          <DialogTitle id="alert-dialog-title">
            <img
              alt="Plusicon"
              src={X}
              style={{
                display: 'inline-block',
                verticalAlign: 'sub',
                position: 'absolute'
              }}
            />
            <Typography
              color="#000"
              variant="h5"
              component="div"
              className={classes.title}
              style={{ display: 'inline-block', marginLeft: '30px' }}
            >
              Warning
            </Typography>
          </DialogTitle>
          <DialogContent>
            <p
              style={{
                fontWeight: 'normal',
                fontSize: '14px',
                lineHeight: '18px',
                letterSpacing: '0.002em',
                color: '#7F7F7F',
                padding: '10px 0'
              }}
            >
              Are you sure you want to {DeleteDialog ? 'Delete' : 'Cancel'} the{' '}
              {records.length > 0 ? records.length + ' selected' : null}{' '}
              records? Closing this popup window while the bulk action is in
              progress will prevent it from processing the records remaining,
              but those already processed will not be rolled back.
            </p>
          </DialogContent>
          <DialogActions
            style={{
              padding: '20px 20px'
            }}
          >
            <Button
              variant="outlined"
              onClick={() => handleClose()}
              style={{
                height: '48px',
                color: '#7F7F7F',
                fontWeight: 'bold'
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleDialog}
              autoFocus
              className={classes.Deletebtn}
            >
              OK
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}

WarningDialog.defaultProps = {
  records: [],
  DeleteDialog: false
};

WarningDialog.propTypes = {
  classes: PropType.object.isRequired,
  handleClose: PropType.func.isRequired,
  setOpen: PropType.bool.isRequired,
  DeleteDialog: PropType.bool,
  OpenNoti: PropType.func.isRequired,
  records: PropType.array,
  getFuture: PropType.func.isRequired,
  getAllClass: PropType.func.isRequired,
  getReccClass: PropType.func.isRequired
};
const mapToDispatch = (dispatch) => ({
  OpenNoti: (msg, type) => dispatch(openNoti(msg, type)),
  getFuture: (filter, data) => dispatch(futureddata(filter, data)),
  getAllClass: (filter, data) => dispatch(AllClassdata(filter, data)),
  getReccClass: () => dispatch(reccurdata())
});
export default connect(null, mapToDispatch)(withStyles(styles)(WarningDialog));
