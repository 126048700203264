import React, { useState, useEffect, useContext } from 'react';
import PropType from 'prop-types';
import { connect } from 'react-redux';
import JBButton from 'src/views/common/JBButton/JBButton';
import { InputLabel, withStyles } from '@material-ui/core';
import { getValuefromObject } from 'src/utils/someCommon';
import { getleadStatus } from 'src/services/Leadmodule.services';
import { FilterContext } from 'src/views/common/ApplyFilter/applyFilter';
import { TobeFilter, isNotData, FilterStatus } from './static.Data';
import CustomAutoComplete from '../../../common/CustomAutocomplete/commonAutocomplete';
import styles from './filters.style';
import { getFilter, getfilterLabel, checkData } from './WaiversFilters.utils';

const MissingTabFilters = (props) => {
  const { getwaiversPublished, classes, getFilData } = props;
  const [data, setData] = useState(null);
  const [selectis, setSelect] = useState(isNotData[1]);
  const [filter, setFilter] = useState(null);
  const [leadStatus, setleadStatus] = useState(null);
  const filteredData = TobeFilter.filter((fil) => fil.id === filter)[0];
  const [open, setOpen] = useContext(FilterContext);
  useEffect(() => {
    if (filter === 4) {
      setData(new Date());
    } else {
      setData(null);
    }
  }, [filter]);

  useEffect(() => {
    getleadStatus()
      .then((res) => {
        setleadStatus(res.data);
      })
      .catch((err) => console.warn(err));
  }, []);

  return (
    <div>
      <CustomAutoComplete
        holder="Select Filter"
        style={{ marginBottom: '15px' }}
        value={TobeFilter}
        Options="filter"
        data={filteredData}
        Change={(val) => setFilter(getValuefromObject(val))}
      />

      <div>
        {(() => {
          switch (filter) {
            case 1:
              return (
                <div>
                  <InputLabel
                    htmlFor="age-required"
                    className={classes.cutom_select_Label}
                    style={{ margin: '0px 0 5px' }}
                  >
                    Published Waiver
                  </InputLabel>
                  <CustomAutoComplete
                    holder="Select List"
                    Options="name"
                    data={selectis}
                    value={isNotData}
                    Change={(val) => setSelect(val)}
                  />
                  {selectis && (
                    <CustomAutoComplete
                      holder="Select List"
                      Options="name"
                      Change={(val) => setData(val)}
                      value={getwaiversPublished}
                      data={data || null}
                      style={{ marginBottom: '15px', marginTop: '15px' }}
                    />
                  )}
                </div>
              );
            case 2:
              return (
                <div>
                  <InputLabel
                    htmlFor="age-required"
                    className={classes.cutom_select_Label}
                    style={{ margin: '0px 0 5px' }}
                  >
                    Member Status
                  </InputLabel>
                  <CustomAutoComplete
                    holder="Select List"
                    Options="name"
                    data={selectis}
                    value={isNotData}
                    Change={(val) => setSelect(val)}
                  />
                  {selectis && (
                    <CustomAutoComplete
                      holder="Select List"
                      Options="name"
                      value={FilterStatus}
                      data={data || null}
                      Change={(val) => setData(val)}
                      style={{ marginBottom: '15px', marginTop: '15px' }}
                    />
                  )}
                </div>
              );
            case 3:
              return (
                <div>
                  <InputLabel
                    htmlFor="age-required"
                    className={classes.cutom_select_Label}
                    style={{ margin: '0px 0 5px' }}
                  >
                    Lead Status
                  </InputLabel>
                  <CustomAutoComplete
                    holder="includes"
                    Options="name"
                    data={selectis}
                    value={isNotData}
                    Change={(val) => setSelect(val)}
                  />
                  {selectis && (
                    <CustomAutoComplete
                      holder="Select List"
                      Options="label"
                      value={leadStatus}
                      data={data || null}
                      Change={(val) => setData(val)}
                      style={{ marginBottom: '15px', marginTop: '15px' }}
                    />
                  )}
                </div>
              );
            default:
              return null;
          }
        })()}
      </div>

      <JBButton
        type="primary"
        style={{
          width: '100%',
          minHeight: '22px',
          padding: '7px 25px',
          marginTop: '10px'
        }}
        disabled={checkData(filter, data, selectis)}
        onClick={() => {
          getFilData(
            getFilter(filter, data, selectis),
            getfilterLabel(filter, data, selectis)
          );
          setOpen(!open);
        }} // eslint-disable-line
      >
        Apply Filter
      </JBButton>
    </div>
  );
};
MissingTabFilters.propTypes = {
  getwaiversPublished: PropType.array.isRequired,
  getFilData: PropType.func.isRequired,
  classes: PropType.object.isRequired
  // memFilter: PropType.func.isRequired
};

const mapToState = (state) => ({
  getwaiversPublished: state.WaiverReducer.Templates.data
});

export default connect(
  mapToState
  // mapToDispatch
)(withStyles(styles)(MissingTabFilters));
