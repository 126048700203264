const styles = () => ({
  dtitle: {
    color: '#292929',
    fontWeight: 'bold',
    fontSize: '33px',
    lineHeight: '26px'
  },
  cutom_select_Label: {
    fontSize: '12px',
    padding: '7px 0'
  },
  cutom_select_border: {
    border: '1px solid #a9a9a9'
  },
  ptext: {
    fontSize: '16px',
    lineHeight: '24px',
    color: '#7F7F7F',
    fontWeight: 'normal'
  },
  TabulationCss: {
    '& .MuiPaper-root.MuiCard-root': {
      boxShadow: 'none !important',
      padding: '0 0',
      margin: '10px 0'
    },
    '& .MuiStepper-vertical': {
      position: 'relative',
      top: '-230px'
    }
  },
  TbActionBtn: {
    textTransform: 'capitalize',
    color: '#7F7F7F',
    fontSize: '12px'
  },
  signcontractStepper: {
    '& .MuiPaper-root.MuiCard-root': {
      boxShadow: 'none !important',
      padding: '0 0',
      margin: '10px 0'
    }
  },
  PlanTerms: {
    border: '1px solid #A8A8A8',
    borderRadius: '4px',
    marginBottom: '15px',
    '& tr': {
      '& td': {
        fontSize: '14px',
        color: '#292929',
        lineHeight: '22px',
        '&:nth-of-type(odd)': {
          color: '#292929',
          minWidth: '250px',
          fontWeight: '700',
          width: 'calc(100% - 70%)'
        }
      }
    }
  },
  termsagree: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    background: '#F9F9FA',
    borderRadius: '8px',
    padding: '20px'
  },
  scrollBox: {
    border: '1px solid #A8A8A8',
    boxSizing: 'border-box',
    borderRadius: '4px',
    padding: '10px',
    overflowY: 'scroll',
    height: 'calc(100vh - 416px)',
    marginBottom: '15px'
  },
  StTitle: {
    fontSize: '14px',
    lineHeight: '22px',
    color: '#000',
    fontWeight: 'bold'
  },
  RadioControl: {
    marginRight: '40px'
  },
  signatureGrid: {
    background: '#F9F9FA',
    borderRadius: '8px',
    padding: '20px',
    textAlign: 'center',
    marginBottom: '10px'
  },
  signatureBox: {
    backgroundColor: 'rgb(249 249 250)',
    borderBottom: '1px solid #000'
  },
  btClearsignature: {
    padding: '0px 5px',
    textTransform: 'inherit',
    float: 'right',
    margin: '20px 0 0'
  },
  TextareaControlBox: {
    '& textarea': {
      color: '#292929'
    },
    '& textarea::placeholder': {
      color: '#7F7F7F !important',
      fontWeight: '500',
      fontSize: '16px',
      opacity: '0.5'
    }
  }
});

export default styles;
