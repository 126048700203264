const styles = () => ({
  buttonprimary: {
    background: '#01847A',
    color: '#FFF',
    padding: '12px 24px',
    borderRadius: '2px',
    minHeight: '48px',
    fontWeight: '700',
    boxShadow: '0px 8px 16px rgba(0, 126, 255, 0.16);',
    '&:hover': {
      backgroundColor: '#01847A'
    }
  },
  deletebutton: {
    color: '#FB4E4E',
    border: '1px solid',
    borderRadius: '2px',
    minHeight: '48px',
    fontWeight: '700',
    '&:hover': {
      borderColor: '#FB4E4E'
    }
  },
  Outlinebutton: {
    minHeight: '48px',
    borderRadius: '2px',
    fontWeight: '700'
  },
  imgMargin: {
    marginRight: 10
  }
});

export default styles;
