/* eslint-disable operator-linebreak */
/* eslint-disable indent */
import {
  checkValidString,
  checkEmail,
  isPhoneNumberInvalid,
  isValidPostal,
  validPhoneRegEx
} from 'src/utils/validations';
import moment from 'moment';

export const checkValidStartDate = (endDate) => {
  if (moment(endDate).isBefore(moment().subtract(1, 'day'))) return true;
  return false;
};
export const getsetupdiscount = (data, payment_data) => {
  if (payment_data.paymentOpt === 1) {
    if (
      typeof data.setup.amount !== 'number' ||
      typeof payment_data.initial_commitment_pricing.full_setup_fees !==
        'number' ||
      (data.setup.type === 'fixed' &&
        payment_data.initial_commitment_pricing.full_setup_fees <
          data.setup.amount) ||
      (data.setup.type === 'percentage' && data.setup.amount > 100)
    ) {
      return 0;
    }
  } else if (payment_data.paymentOpt === 2) {
    if (
      typeof data.setup.amount !== 'number' ||
      typeof payment_data.initial_commitment_pricing.week_setup_fees !==
        'number' ||
      (data.setup.type === 'fixed' &&
        payment_data.initial_commitment_pricing.week_setup_fees <
          data.setup.amount) ||
      (data.setup.type === 'percentage' && data.setup.amount > 100)
    ) {
      return 0;
    }
  }
  let result = 0;
  if (data.setup.type === 'fixed') {
    result = data.setup.amount;
  } else {
    // eslint-disable-next-line no-lonely-if
    if (payment_data.paymentOpt === 1) {
      result =
        payment_data.initial_commitment_pricing.full_setup_fees *
        (data.setup.amount / 100);
    } else if (payment_data.paymentOpt === 2) {
      result =
        payment_data.initial_commitment_pricing.week_setup_fees *
        (data.setup.amount / 100);
    }
  }
  return result;
};
const getPaymentValue = (data) => {
  let result = {};
  if (data.paymentOpt === 1) {
    result = {
      setup_fee: data.initial_commitment_pricing.full_setup_fees,
      fee: data.initial_commitment_pricing.full_membership_fees
    };
  } else if (data.paymentOpt === 2) {
    result = {
      setup_fee: data.initial_commitment_pricing.week_setup_fees,
      fee: data.initial_commitment_pricing.week_membership_fees
    };
  }
  if (data.auto_renew) {
    if (data.renewOpt === 1) {
      result = {
        ...result,
        renew_setup_fee: data.auto_renewal_pricing.full_setup_fees,
        renew_fee: data.auto_renewal_pricing.full_membership_fees
      };
    } else if (data.renewOpt === 2) {
      result = {
        ...result,
        renew_setup_fee: data.auto_renewal_pricing.week_setup_fees,
        renew_fee: data.auto_renewal_pricing.week_membership_fees
      };
    }
  }

  return result;
};
export const getUserData = (data, id) => {
  const result = {
    user_id: id,
    first_name: data.first_name,
    last_name: data.last_name,
    email: data.email,
    country: data.country?.code,
    city: data.city,
    province: data.province,
    address: data.address,
    gender: data.gender.label.toLowerCase(),
    dob: moment(data.date).format('YYYY-MM-DD'),
    mobile_no: data.phone_num,
    postal_code: data.postal,
    emergency_contact_name: data.eme_contact,
    emergency_contact_phone: data.eme_phone
  };
  return result;
};
export const getLeadData = (data) => {
  const result = {
    first_name: data.first_name,
    last_name: data.last_name,
    email: data.email,
    country: data.country.code,
    city: data.city,
    province: data.province,
    address: data.address,
    gender: data.gender.label.toLowerCase(),
    dob: moment(data.date).format('YYYY-MM-DD'),
    mobile_no: data.phone_num,
    postal_code: data.postal,
    lead_source: data.lead_source,
    profile_pic: data.profile_pic,
    location_id: data.location.id,
    member_login: data.member_login ? 1 : 0
  };
  return result;
};
export const updateUserData = (data, id) => {
  const result = {
    user_id: id,
    first_name: data.first_name,
    last_name: data.last_name,
    email: data.email,
    country: data.country.code,
    city: data.city,
    province: data.province,
    address: data.address,
    gender: data.gender.label.toLowerCase(),
    dob: moment(data.date).format('YYYY-MM-DD'),
    mobile_no: data.phone_num,
    postal_code: data.postal,
    eme_contact_name: data.eme_contact,
    eme_contact_phone: data.eme_phone
  };
  return result;
};
export const getMembershipData = (data) => {
  const {
    memebership_data: { membershipdata }
  } = data;
  const plan = getPaymentValue(membershipdata.payment_data);
  let result = {};
  if (data.membership_type === 0) {
    result = {
      membership_id: data.membership_id,
      membership_payment_id: data.membership_payment_id,
      type: data.membership_type === 1 ? 'session' : 'plan',
      contract_id: membershipdata.payment_data.membership.contract_id,
      start_date: moment(membershipdata.start_date).format('YYYY/MM/DD'),
      attendance_limit:
        membershipdata.payment_data.membership.attendance_limit.id,
      initial_pricing:
        membershipdata.payment_data.paymentOpt === 1 ? 'full' : 'weekly',
      auto_renew: membershipdata.payment_data.auto_renew,
      programe_access: membershipdata.program_access.join(),
      initial_billed_every:
        membershipdata.payment_data.initial_commitment.billed_every.name,
      initial_commitment:
        membershipdata.payment_data.initial_commitment.initial,
      discount: JSON.stringify(membershipdata.discount),
      membership_contract: membershipdata.membership_contract,
      member_plan: JSON.stringify({
        payment_plan: { setup_fee: plan.setup_fee, fee: plan.fee },
        payment_renewal: { fee: 0 }
      })
    };
  } else if (data.membership_type === 1) {
    result = {
      membership_id: data.membership_id,
      membership_payment_id: data.membership_payment_id,
      type: data.membership_type === 1 ? 'session' : 'plan',
      contract_id: membershipdata.payment_data.membership.contract_id,
      start_date: moment(membershipdata.start_date).format('YYYY/MM/DD'),
      attendance_limit:
        membershipdata.payment_data.membership.attendance_limit.id,
      initial_pricing:
        membershipdata.payment_data.paymentOpt === 1 ? 'full' : 'weekly',
      auto_renew: membershipdata.payment_data.auto_renew,
      programe_access: membershipdata.program_access.join(),
      discount: JSON.stringify(membershipdata.discount),
      membership_contract: membershipdata.membership_contract,
      used_session: membershipdata.usedsession,
      no_of_session: membershipdata.payment_data.membership.no_of_sessions,
      member_plan: JSON.stringify({
        payment_plan: { setup_fee: plan.setup_fee, fee: plan.fee },
        payment_renewal: { fee: 0 }
      })
    };
  } else if (data.membership_type === 2) {
    result = {
      membership_id: data.membership_id,
      membership_payment_id: data.membership_payment_id,
      type: 'drop_in',

      start_date: moment(membershipdata.start_date).format('YYYY/MM/DD'),
      attendance_limit: 0,
      initial_pricing: membershipdata.payment_data.paymentOpt === 1 && 'full',
      auto_renew: membershipdata.payment_data.auto_renew,
      programe_access: membershipdata.program_access.join(),
      discount: JSON.stringify(membershipdata.discount),
      membership_contract: membershipdata.membership_contract,
      member_plan: JSON.stringify({
        payment_plan: { setup_fee: plan.setup_fee, fee: plan.fee },
        payment_renewal: { fee: 0 }
      }),
      payment_method: data.payment_method,
      payment_method_id: data.payment_method_id,
      user_payment_method: data.user_payment_method
    };
  }
  if (membershipdata.payment_data.membership.attendance_limit.id === 1) {
    result = {
      ...result,
      attendance_limited_meta: JSON.stringify({
        uses:
          membershipdata.payment_data.membership.attendance_limited_meta.uses,
        type:
          membershipdata.payment_data.membership.attendance_limited_meta.type.id
      })
    };
  }
  if (membershipdata.payment_data.auto_renew) {
    result = {
      ...result,
      renewal_pricing:
        membershipdata.payment_data.renewOpt === 1 ? 'full' : 'weekly',
      renewal_commitment: membershipdata.payment_data.auto_renew_meta.renewal,
      renewal_billed_every:
        membershipdata.payment_data.auto_renew_meta.billed_every.name,
      member_plan: JSON.stringify({
        payment_plan: { setup_fee: plan.setup_fee, fee: plan.fee },
        payment_renewal: {
          fee: plan.renew_fee,
          setup_fee: plan.renew_setup_fee
        }
      })
    };
  }
  return result;
};
export const verifyInfo = (data) => {
  if (!data.first_name.trim().length) {
    return true;
  }
  if (!data.last_name.trim().length) {
    return true;
  }
  if (!data.email || checkEmail(data.email)) {
    return true;
  }
  if (!data.gender) return true;
  if (!data.phone_num || isPhoneNumberInvalid(String(data.phone_num))) {
    return true;
  }
  if (!data.address || checkValidString(data.address)) return true;
  if (!data.city || checkValidString(data.city)) return true;
  if (!data.province || checkValidString(data.province)) return true;
  if (!data.postal || !isValidPostal(data.postal)) return true;
  if (!data.eme_phone || isPhoneNumberInvalid(String(data.eme_phone))) {
    return true;
  }
  if (!data.eme_contact || checkValidString(data.eme_contact)) return true;
  if (!data.date) return true;
  if (!data.country) return true;
  return false;
};

export const getEndDate = (start_date, weeks) => {
  return start_date
    ? moment(start_date)
        // eslint-disable-next-line indent
        .add(weeks, 'weeks')
        // eslint-disable-next-line indent
        .format('DD/MM/YYYY')
    : '';
};
export const getInitialPrice = (cost, tax) => cost + tax;
export const getmemdiscount = (data, payment_data) => {
  if (payment_data.paymentOpt === 1) {
    if (
      typeof data.initial.amount !== 'number' ||
      typeof payment_data.initial_commitment_pricing.full_membership_fees !==
        'number' ||
      (data.initial.type === 'fixed' &&
        payment_data.initial_commitment_pricing.full_membership_fees <
          data.initial.amount) ||
      (data.initial.type === 'percentage' && data.initial.amount > 100)
    ) {
      return 0;
    }
  } else if (payment_data.paymentOpt === 2) {
    if (
      typeof data.initial.amount !== 'number' ||
      typeof payment_data.initial_commitment_pricing.week_membership_fees !==
        'number' ||
      (data.initial.type === 'fixed' &&
        payment_data.initial_commitment_pricing.week_membership_fees <
          data.initial.amount) ||
      (data.initial.type === 'percentage' && data.initial.amount > 100)
    ) {
      return 0;
    }
  }
  let result = 0;
  if (data.initial.type === 'fixed') {
    result = data.initial.amount;
  } else {
    // eslint-disable-next-line no-lonely-if
    if (payment_data.paymentOpt === 1) {
      result =
        payment_data.initial_commitment_pricing.full_membership_fees *
        (data.initial.amount / 100);
    } else if (payment_data.paymentOpt === 2) {
      result =
        payment_data.initial_commitment_pricing.week_membership_fees *
        (data.initial.amount / 100);
    }
  }
  return result;
};
export const getRenewalDiscount = (data, payment_data) => {
  if (payment_data.renewOpt === 1) {
    if (
      typeof data.renewal.amount !== 'number' ||
      typeof payment_data.auto_renewal_pricing.full_membership_fees !==
        'number' ||
      (data.renewal.type === 'fixed' &&
        payment_data.auto_renewal_pricing.full_membership_fees <
          data.renewal.amount) ||
      (data.renewal.type === 'percentage' && data.renewal.amount > 100)
    ) {
      return 0;
    }
  } else if (payment_data.renewOpt === 2) {
    if (
      typeof data.renewal.amount !== 'number' ||
      typeof payment_data.auto_renewal_pricing.week_membership_fees !==
        'number' ||
      (data.renewal.type === 'fixed' &&
        payment_data.auto_renewal_pricing.week_membership_fees <
          data.renewal.amount) ||
      (data.renewal.type === 'percentage' && data.renewal.amount > 100)
    ) {
      return 0;
    }
  }
  let result = 0;
  if (data.renewal.type === 'fixed') {
    result = data.renewal.amount;
  } else {
    // eslint-disable-next-line no-lonely-if
    if (payment_data.renewOpt === 1) {
      result =
        payment_data.auto_renewal_pricing.full_membership_fees *
        (data.renewal.amount / 100);
    } else if (payment_data.renewOpt === 2) {
      result =
        payment_data.auto_renewal_pricing.week_membership_fees *
        (data.renewal.amount / 100);
    }
  }
  return result;
};
export const getRenTotalDiscount = (data, discount) => {
  const rendiscount = getRenewalDiscount(discount, data);
  let result = rendiscount;
  if (data.renewOpt === 2) {
    const totalweek =
      parseInt(data.auto_renew_meta.renewal, 10) /
      parseInt(data.auto_renew_meta.billed_every.name, 10);
    result *= totalweek;
  }
  return result;
};
export const getIniTotaldiscount = (data, discount) => {
  const setupdiscount = getsetupdiscount(discount, data);
  const memdiscount = getmemdiscount(discount, data);
  let totalDiscount = setupdiscount + memdiscount;

  if (data.paymentOpt === 2) {
    const totalweek =
      parseInt(data.initial_commitment.initial, 10) /
      parseInt(data.initial_commitment.billed_every.name, 10);

    totalDiscount = totalweek * memdiscount + setupdiscount;
  }
  return totalDiscount;
};
export const getTotal = (data, discount) => {
  let total = 0;
  if (data.paymentOpt === 1) {
    total =
      data.initial_commitment_pricing.full_membership_fees +
      data.initial_commitment_pricing.full_setup_fees;
  } else if (data.paymentOpt === 2) {
    total =
      data.initial_commitment_pricing.week_membership_fees +
      data.initial_commitment_pricing.week_setup_fees;
  }

  const totalDiscount = getIniTotaldiscount(data, discount);
  return total - totalDiscount;
};

export const getTotalDue = (data, discount) => {
  let total = 0;
  if (data.paymentOpt === 1) {
    total =
      data.initial_commitment_pricing.full_membership_fees +
      data.initial_commitment_pricing.full_setup_fees;
  } else if (data.paymentOpt === 2) {
    const totalweek =
      parseInt(data.initial_commitment.initial, 10) /
      parseInt(data.initial_commitment.billed_every.name, 10);
    total = data.initial_commitment_pricing.week_membership_fees * totalweek;
    total += data.initial_commitment_pricing.week_setup_fees;
  }
  const totalDiscount = getIniTotaldiscount(data, discount);
  return total - totalDiscount;
};
export const setupTotal = (data, discount) => {
  let total = 0;
  if (data.paymentOpt === 1) {
    total = data.initial_commitment_pricing.full_setup_fees;
  } else if (data.paymentOpt === 2) {
    total = data.initial_commitment_pricing.week_setup_fees;
  }
  return total - getsetupdiscount(discount, data);
};
export const Commitmentcost = (data, discount) => {
  let total = 0;
  if (data.paymentOpt === 1) {
    total =
      data.initial_commitment_pricing.full_membership_fees -
      getmemdiscount(discount, data);
  } else {
    total =
      data.initial_commitment_pricing.week_membership_fees -
      getmemdiscount(discount, data);
  }
  return total;
};
export const CommitmentTotal = (data, discount) => {
  let total = 0;
  if (data.paymentOpt === 1) {
    total = Commitmentcost(data, discount);
  } else if (data.paymentOpt === 2) {
    const totalweek =
      parseInt(data.initial_commitment.initial, 10) /
      parseInt(data.initial_commitment.billed_every.name, 10);
    total = Commitmentcost(data, discount) * totalweek;
  }
  return total;
};

export const RenewalTotal = (data, discount) => {
  let total = 0;
  if (data.renewOpt === 1) {
    total = data.auto_renewal_pricing.full_membership_fees;
  } else if (data.renewOpt === 2) {
    const totalweek =
      parseInt(data.auto_renew_meta.renewal, 10) /
      parseInt(data.auto_renew_meta.billed_every.name, 10);
    total = data.auto_renewal_pricing.week_membership_fees * totalweek;
  }
  return total - getRenTotalDiscount(data, discount);
};
export const RenewalCost = (data, discount) => {
  let total = 0;
  if (data.renewOpt === 1) {
    total = data.auto_renewal_pricing.full_membership_fees;
  } else {
    total = data.auto_renewal_pricing.week_membership_fees;
  }
  return total - getRenewalDiscount(discount, data);
};
export const getTaxTotal = (data, taxData, discount) => {
  const rate = 1 + taxData.rate / 100;
  let setupfee = 0;
  let memfee = 0;
  if (data.paymentOpt === 1) {
    setupfee =
      data.initial_commitment_pricing.full_setup_fees -
      getsetupdiscount(discount, data);
    memfee =
      data.initial_commitment_pricing.full_membership_fees -
      getmemdiscount(discount, data);
  } else if (data.paymentOpt === 2) {
    const totalweek =
      parseInt(data.initial_commitment.initial, 10) /
      parseInt(data.initial_commitment.billed_every.name, 10);
    setupfee =
      data.initial_commitment_pricing.week_setup_fees -
      getsetupdiscount(discount, data);
    const memdiscount = getmemdiscount(discount, data) * totalweek;
    memfee = data.initial_commitment_pricing.week_membership_fees - memdiscount;
  }
  const setupdis = setupfee - setupfee / rate;
  const fulldis = memfee - memfee / rate;
  let result = 0;
  result = setupdis + fulldis;
  return result;
};
export const getSetupTax = (data, taxData, discount) => {
  const rate = 1 + taxData.rate / 100;
  let setupfee = 0;
  if (data.paymentOpt === 1) {
    setupfee =
      data.initial_commitment_pricing.full_setup_fees -
      getsetupdiscount(discount, data);
  } else if (data.paymentOpt === 2) {
    setupfee =
      data.initial_commitment_pricing.week_setup_fees -
      getsetupdiscount(discount, data);
  }
  const setupdis = setupfee - setupfee / rate;
  return setupdis;
};
export const getRenewalTax = (data, taxData, discount) => {
  const rate = 1 + taxData.rate / 100;
  let setupfee = 0;
  if (data.renewOpt === 1) {
    setupfee =
      data.auto_renewal_pricing.full_membership_fees -
      getRenewalDiscount(discount, data);
  } else if (data.renewOpt === 2) {
    setupfee =
      data.auto_renewal_pricing.week_membership_fees -
      getRenewalDiscount(discount, data);
  }
  const setupdis = setupfee - setupfee / rate;
  return setupdis;
};
export const getFeeTax = (data, taxData, discount) => {
  const rate = 1 + taxData.rate / 100;
  let memfee = 0;
  if (data.paymentOpt === 1) {
    memfee =
      data.initial_commitment_pricing.full_membership_fees -
      getmemdiscount(discount, data);
  } else if (data.paymentOpt === 2) {
    memfee =
      data.initial_commitment_pricing.week_membership_fees -
      getmemdiscount(discount, data);
  }
  const fulldis = memfee - memfee / rate;
  return fulldis;
};

export const stripeData = (data) => {
  const b = {
    name: data.first_name,
    phone: data.phone_num,
    email: data.email,
    address: {
      line1: data.address,
      line2: data.address2,
      city: data.city,
      postal_code: data.postal,
      state: data.province,
      country: data.country.code
    }
  };
  return b;
};
export const getContractData = (user_data, contract_data) => {
  const result = {
    first_name: user_data.first_name,
    last_name: user_data.last_name,
    email: user_data.email,
    gender: user_data.gender.label.toLowerCase(),
    city: user_data.city,
    address: user_data.address,
    province: user_data.province,
    postal_code: user_data.postal,
    country: user_data.country.code,
    mobile_no: user_data.phone_num,
    signature: contract_data.signature.toDataURL(),
    dob: moment(user_data.date).format('YYYY-MM-DD'),
    payment_plan_terms_user_intial: JSON.stringify({
      sign_initials: contract_data.sign_initials
    }),
    membership_policy_user_intial: JSON.stringify({
      sign_initials: contract_data.sign_initials
    })
  };
  return result;
};
export const getFullAddress = (data) => {
  let { country } = data;
  country = '';
  if (typeof data.country === 'object' && data.country) {
    country = data.country.name;
  }
  const updated = {
    address: data.address,
    address2: data.address2,
    city: data.city,
    province: data.province,
    postal: data.postal,
    country
  };
  const value = Object.values(updated)
    .filter((l) => l)
    .join(', ');
  return value;
};
export const validStep2 = (data) => {
  // eslint-disable-next-line no-unneeded-ternary
  return data.membership_id ? false : true;
};

export const checkInitialCommitmemnt = (data) => {
  if (parseInt(data.initial, 10) < parseInt(data.billed_every.name, 10)) {
    return true;
  }
  return false;
};
export const validStep3 = (state, data) => {
  if (state.membership_type === 0) {
    if (
      !state.membership_payment_id ||
      !data.start_date ||
      !data.payment_data.initial_commitment ||
      !data.payment_data.membership.attendance_limit
    ) {
      return true;
    }
    if (data.payment_data.initial_commitment) {
      if (
        moment(data.start_date)
          .add(data.payment_data.initial_commitment.initial, 'weeks')
          .isBefore(moment().subtract(1, 'day')) ||
        !data.payment_data.initial_commitment.initial ||
        !data.payment_data.initial_commitment.billed_every ||
        checkInitialCommitmemnt(data.payment_data.initial_commitment)
      ) {
        return true;
      }
    }
  } else {
    if (
      !state.membership_payment_id ||
      !data.start_date ||
      !data.payment_data.membership.attendance_limit ||
      !data.payment_data.paymentOpt
    ) {
      return true;
    }
    if (
      data.payment_data.membership.attendance_limit.id &&
      checkValidStartDate(data.expires_date)
    ) {
      return true;
    }
  }
  if (
    data.payment_data.membership.attendance_limit.id === 1 &&
    (!data.payment_data.membership.attendance_limited_meta.uses ||
      !data.payment_data.membership.attendance_limited_meta.type)
  ) {
    return true;
  }
  return false;
};
export const checkValidRenewCommitement = (data) => {
  if (
    parseInt(data.auto_renew_meta.renewal, 10) <
    parseInt(data.auto_renew_meta.billed_every.name, 10)
  ) {
    return true;
  }
  return false;
};
export const validStep4 = (data, type) => {
  if (type === 0) {
    if (!data.paymentOpt) return true;
    if (data.auto_renew) {
      if (!data.renewOpt) return true;
      if (!data.auto_renew_meta.renewal || !data.auto_renew_meta.billed_every) {
        return true;
      }
      if (checkValidRenewCommitement(data)) return true;
    }
    return false;
    // eslint-disable-next-line no-else-return
  } else {
    if (
      data.initial_commitment_pricing.full_setup_fees < 0 ||
      data.initial_commitment_pricing.full_membership_fees < 0
    ) {
      return true;
    }
    return false;
  }
};
export const planStep5 = (data) => {
  if (
    data.initial_commitment_pricing.full_setup_fees < 0 ||
    data.initial_commitment_pricing.full_membership_fees < 0
  ) {
    return true;
  }
  return false;
};
export const validStep5 = (data) => {
  if (data.program_access.length === 0 || !data.membership_contract) {
    return true;
  }
  return false;
};
export const validStep6 = (data, cardData, paymentMe) => {
  if (!data.first_name.trim().length) {
    return true;
  }
  if (!data.last_name.trim().length) {
    return true;
  }
  if (!data.phone_num || isPhoneNumberInvalid(String(data.phone_num))) {
    return true;
  }
  if (!data.address || checkValidString(data.address)) return true;
  if (!data.city || checkValidString(data.city)) return true;
  if (!data.province || checkValidString(data.province)) return true;
  if (!data.postal || !isValidPostal(data.postal)) return true;
  if (!data.country) return true;
  if (paymentMe === 0) {
    if (!cardData.card_name || checkValidString(cardData.card_name)) {
      return true;
    }
  } else if (paymentMe === 2) {
    if (
      !cardData.bank_details.account_name ||
      checkValidString(cardData.bank_details.account_name)
    ) {
      return true;
    }
    // if (
    //   !cardData.bank_details.account_number ||
    //   !validPhoneRegEx.test(cardData.bank_details.account_number)
    // ) {
    //   return true;
    // }
    // if (
    //   !cardData.bank_details.bsb_number ||
    //   !validPhoneRegEx.test(cardData.bank_details.bsb_number)
    // ) {
    //   return true;
    // }
  }
  return false;
};
export const validStep7 = (data) => {
  if (!data.sign_initials) return true;
  return false;
};
export const validStep9 = (data) => {
  if (!data.sign_status || !data.signature) return true;
  return false;
};
export const validStep0 = (data) => {
  if (!data) return true;
  return false;
};

export const getDropInData = (data) => {
  return {
    user_id: data.id,
    first_name: data.first_name,
    last_name: data.last_name,
    email: data.email,
    gender: data.gender.label.toLowerCase(),
    address: data.address,
    city: data.city,
    province: data.province,
    country: data.country.code,
    postal_code: data.postal,
    mobile_no: data.phone_num,
    dob: moment(data.date).format('YYYY-MM-DD'),
    eme_contact_name: data.eme_contact,
    eme_contact_phone: data.eme_phone,
    waiver_terms_initial: JSON.stringify(data.waiver_terms_initial),
    questionnaire_meta: JSON.stringify(data.questionnaire_meta),
    signature: data.signature
  };
};
