import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropType from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  withStyles,
  Grid,
  FormControlLabel,
  InputLabel,
  Typography,
  Box,
  Radio,
  RadioGroup,
  OutlinedInput,
  InputAdornment
} from '@material-ui/core';
import DiscounFormControl from '@material-ui/core/FormControl';
import { validateInt } from 'src/utils/validations';
import { openNoti } from 'src/Redux/global/global.actions';
import dcloseicon from '../../../../assets/image/closeicon.png';
import JBButton from '../../../common/JBButton/JBButton';
import FormControl from '../../../common/FormControl/formControl';
import CustomAutoComplete from '../../../common/CustomAutocomplete/commonAutocomplete';
import { invoiceFinal } from './InvoiceView.utils';
import { fetcheditItem, validateItems } from './AddItem.validation';

const styles = () => ({
  dtitle: {
    color: '#292929',
    fontWeight: 'bold',
    fontSize: '33px',
    lineHeight: '26px'
  },
  cutom_select_Label: {
    fontSize: '12px',
    padding: '7px 0'
  },
  Discountinput: {
    margin: '0px',
    display: 'inline-flex',
    width: '97px',
    marginLeft: '15px',
    border: '1px solid #a9a9a9',
    height: '48px',
    padding: '9px 15px 7px'
    // '&:focus': {
    //   border: '1px solid #01847a'
    // }
  },
  DiscounFormControl: {
    width: '95px',
    height: '48px',
    marginLeft: '20px',
    '& .MuiOutlinedInput-input': {
      padding: '14.5px 8px'
    }
  },
  Discounsign: {
    '& p': {
      color: 'rgb(41 41 41 / 0.4)'
    }
  },
  LabelControl: {
    width: '130px',
    marginRight: '0'
  },
  finalCharge: {
    width: '333px',
    height: '40px',
    background: '#F9F9F9',
    float: 'right',
    display: 'flex',
    alignItems: 'center',
    padding: '7px 16px',
    color: '#292929',
    '& h6': {
      fontWeight: '700',
      fontSize: '14px',
      lineHeight: '18px',
      width: '100%'
    }
  },
  Totalcharge: {
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '22px'
  }
});

function EditItem(props) {
  const {
    classes,
    handleClose,
    setOpen,
    item,
    taxes,
    Revens,
    currencies: { data: currency, error: currerror }
  } = props;
  const [data, setdata] = useState(
    fetcheditItem(item, Revens.data, taxes.data)
  );

  useEffect(() => {
    return () =>
      setdata({
        // name: null,
        // reven: null,
        // saleprice: 0,
        // qty: 1,
        // tax: null,
        // discount: 0,
        // discount_type: '1'
      });
  }, [setOpen]);

  if (currerror) return <p>Something went wrong please try again...</p>;
  if (!currency) return null;

  const handleSave = () => {
    const { OpenNoti, getValues } = props;
    const { value, error } = validateItems(data, currency.currency_symbol);
    if (error) {
      OpenNoti(error, 'error');
    } else {
      getValues(value);
    }
  };

  return (
    <div>
      <Dialog
        open={setOpen}
        onClose={() => handleClose()}
        fullWidth
        maxWidth="lg"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div>
          <Grid alignItems="center" container direction="row">
            <Grid item xs>
              <DialogTitle
                id="alert-dialog-title"
                className={classes.titlebk}
                style={{
                  display: 'inline-block',
                  padding: '25px 24px'
                }}
              >
                <h4 className={classes.dtitle}>Edit line Item</h4>
              </DialogTitle>
            </Grid>
            <Grid
              item
              xs={2}
              style={{ textAlign: 'right', marginRight: '10px' }}
            >
              <Button className={classes.btns} onClick={handleClose}>
                <img alt="img" src={dcloseicon} />
              </Button>
            </Grid>
          </Grid>

          <DialogContent>
            <Grid container style={{ marginTop: '5px' }}>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={6}
                  // style={{ marginBottom: '15px' }}
                >
                  <InputLabel
                    htmlFor="age-required"
                    className={classes.cutom_select_Label}
                    required
                    style={{ margin: '0 0', padding: '0 0' }}
                  >
                    Product name
                  </InputLabel>
                  <FormControl
                    control="input"
                    placeholder=""
                    required
                    style={{ margin: '0 0' }}
                    value={data.name}
                    onChange={(e) => setdata({ ...data, name: e.target.value })}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  // style={{ marginBottom: '15px' }}
                >
                  <InputLabel
                    htmlFor="age-required"
                    className={classes.cutom_select_Label}
                    style={{ margin: '0px 0px 5px', padding: '0 0' }}
                    required
                  >
                    Revenue category
                  </InputLabel>
                  <CustomAutoComplete
                    holder="-"
                    Options="name"
                    data={data.reven}
                    value={Revens.data || []}
                    Change={(val) => setdata({ ...data, reven: val })}
                    // style={{ marginBottom: '15px' }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputLabel
                    htmlFor="age-required"
                    className={classes.cutom_select_Label}
                    style={{ margin: '0 0', padding: '0 0' }}
                  >
                    Sales price
                  </InputLabel>
                  <FormControl
                    control="input"
                    placeholder=""
                    style={{ margin: '0 0' }}
                    value={data.saleprice}
                    onChange={(e) =>
                      setdata({
                        ...data,
                        saleprice: validateInt(e.target.value)
                      })
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputLabel
                    htmlFor="age-required"
                    className={classes.cutom_select_Label}
                    style={{ margin: '0 0', padding: '0 0' }}
                  >
                    Quantity
                  </InputLabel>
                  <FormControl
                    control="input"
                    placeholder=""
                    style={{ margin: '0 0' }}
                    value={data.qty}
                    onChange={(e) =>
                      setdata({ ...data, qty: validateInt(e.target.value) })
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputLabel
                    htmlFor="age-required"
                    className={classes.cutom_select_Label}
                    style={{ margin: '0 0', padding: '0px 0 5px' }}
                  >
                    Tax category
                  </InputLabel>
                  <CustomAutoComplete
                    holder="No tax"
                    value={taxes.data || []}
                    data={data.tax}
                    Options="name"
                    Change={(val) => setdata({ ...data, tax: val })}
                  />
                </Grid>
              </Grid>
              <Box mt="30px">
                <Typography
                  variant="h5"
                  style={{
                    color: '#000',
                    marginBottom: '15px'
                  }}
                >
                  Discount
                </Typography>
                <RadioGroup
                  value={data.discount_type}
                  onChange={(e) =>
                    setdata({ ...data, discount_type: e.target.value })
                  }
                >
                  <Box display="inline-flex" mb="15px">
                    <FormControlLabel
                      value="1"
                      control={<Radio color="primary" />}
                      label="Fix value"
                      className={classes.LabelControl}
                    />
                    <DiscounFormControl
                      className={classes.DiscounFormControl}
                      variant="outlined"
                      control="input"
                    >
                      <OutlinedInput
                        id="outlined-adornment-weight"
                        value={data.discount_type === '1' ? data.discount : 0}
                        disabled={data.discount_type !== '1'}
                        onChange={(e) =>
                          setdata({ ...data, discount: e.target.value })
                        }
                        endAdornment={
                          <InputAdornment
                            position="end"
                            className={classes.Discounsign}
                          >
                            {currency ? currency.currency_symbol : 'A$'}
                          </InputAdornment>
                        }
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          'aria-label': 'weight'
                        }}
                        labelWidth={0}
                      />
                    </DiscounFormControl>
                  </Box>
                  <Box display="inline-flex">
                    <FormControlLabel
                      value="2"
                      control={<Radio color="primary" />}
                      label="Percentage"
                      className={classes.LabelControl}
                    />
                    <DiscounFormControl
                      className={classes.DiscounFormControl}
                      variant="outlined"
                      control="input"
                    >
                      <OutlinedInput
                        id="outlined-adornment-weight"
                        value={data.discount_type === '2' ? data.discount : 0}
                        disabled={data.discount_type !== '2'}
                        onChange={(e) =>
                          setdata({
                            ...data,
                            discount: validateInt(e.target.value)
                          })
                        }
                        endAdornment={
                          <InputAdornment
                            position="end"
                            className={classes.Discounsign}
                          >
                            %
                          </InputAdornment>
                        }
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          'aria-label': 'weight'
                        }}
                        labelWidth={0}
                      />
                    </DiscounFormControl>
                  </Box>
                </RadioGroup>
              </Box>
            </Grid>
            <Box mt="20px" />
            <Grid
              alignItems="center"
              justify="flex-end"
              container
              direction="row"
            >
              <Grid item xs={4}>
                <Box className={classes.finalCharge}>
                  <Typography variant="h6">Final Charge</Typography>
                  <Box className={classes.Totalcharge}>
                    {currency.currency_symbol
                      + parseFloat(invoiceFinal(data)).toFixed(currency.decimals)}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions
            style={{
              padding: '20px 24px 30px'
            }}
          >
            <JBButton type="primary" onClick={handleSave}>
              Save
            </JBButton>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}

EditItem.propTypes = {
  classes: PropType.object.isRequired,
  setOpen: PropType.bool.isRequired,
  handleClose: PropType.func.isRequired,
  item: PropType.object.isRequired,
  Revens: PropType.object.isRequired,
  taxes: PropType.object.isRequired,
  currencies: PropType.object.isRequired,
  OpenNoti: PropType.func.isRequired,
  getValues: PropType.func.isRequired
};

const mapToState = (state) => ({
  taxes: state.SettingReducer.taxRates,
  Revens: state.SettingReducer.revenueCtgry,
  currencies: state.SettingReducer.currency
});

const mapToDispatch = (dispatch) => ({
  OpenNoti: (msg, type) => dispatch(openNoti(msg, type))
});

export default connect(mapToState, mapToDispatch)(withStyles(styles)(EditItem));
