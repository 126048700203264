import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  AppBar,
  Tabs,
  Tab,
  Typography,
  Box,
  ThemeProvider,
  withStyles
} from '@material-ui/core';
import moment from 'moment';
import { Link, Route, Switch, useLocation, withRouter } from 'react-router-dom';
import { Chalk } from 'src/Context/chalkboardContext';
import { ROUTES } from '../../constant/Constants';
import { ReactComponent as TabeditIcon } from '../../assets/image/tabediticon.svg';
import styles from './Chalkboard.style';
import WorkoutDetail from './Workout';
import SignIn from './SignIn';

const themeInstance = {
  overrides: {
    MuiTabs: {
      indicator: {
        backgroundColor: 'transparent'
      }
    },
    MuiTab: {
      root: {
        maxWidth: '100%',
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
        border: '1px solid #EAEAEA',
        borderBottom: '0',
        margin: '0px 5px 0 0',
        '&$selected': {
          backgroundColor: 'white',
          color: '#000 ',
          '&:hover': {
            color: '#37847a'
          },
          '&$textColorPrimary': {
            color: '#01847A'
          },
          '& svg path': {
            stroke: '#01847A'
          }
        },
        '@media (max-width: 992px)': {
          minWidth: '140px'
        }
      },
      wrapper: {
        flexDirection: 'row',
        justifyContent: 'space-around'
      },
      labelIcon: {
        minHeight: '55px'
      },
      textColorPrimary: {
        color: '#545454',
        fontSize: '14px',
        fontWeight: '900',
        lineHeight: '18px',
        letterSpacing: '0.5px'
      }
    }
  }
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other} // eslint-disable-line
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

const ChalkboardTabs = ({ classes }) => {
  // const classes = useStyles();
  // const [value, changeTab] = useState(0);
  // const match = useRouteMatch();
  const [state] = useContext(Chalk);
  const location = useLocation();
  const tabValue = location.pathname === ROUTES.CHALKBOARD_WORKOUT ? 1 : 0;

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    };
  }

  return (
    <div className={classes.root}>
      <Grid container>
        <Box mb={{ xs: '20px', md: '20px', lg: '30px' }}>
          <Typography
            variant="h2"
            color="Primary"
            style={{ fontWeight: 900, zIndex: '99999', position: 'relative' }}
            className={classes.ChalkboardTitle}
          >
            <span>Chalkboard</span>
            <label style={{ color: '#000', marginLeft: '15px' }}>
              {moment(tabValue === 1 ? state.date : state.SignIn.date).format(
                'ddd, DD MMMM YYYY'
              )}
            </label>
          </Typography>
        </Box>

        <ThemeProvider theme={themeInstance}>
          <AppBar
            position="static"
            style={{
              backgroundColor: 'transparent',
              boxShadow: 'none'
            }}
          >
            <Tabs
              value={tabValue}
              indicatorColor="primary"
              textColor="primary"
              color="primary"
              fullWidth
              aria-label="simple tabs example"
            >
              <Tab
                label="Sign In"
                component={Link}
                to={ROUTES.CHALKBOARD}
                icon={<TabeditIcon />}
                {...a11yProps(0)} // eslint-disable-line
              />
              <Tab
                label="Workout"
                component={Link}
                to={ROUTES.CHALKBOARD_WORKOUT}
                icon={<TabeditIcon />}
                {...a11yProps(1)} // eslint-disable-line
              />
            </Tabs>
          </AppBar>
        </ThemeProvider>
        <div className={classes.STabPanel}>
          <Switch>
            <Route
              exact
              path={ROUTES.CHALKBOARD_WORKOUT}
              component={WorkoutDetail}
            />
            <Route path={ROUTES.CHALKBOARD} component={SignIn} />
          </Switch>
        </div>
      </Grid>
    </div>
  );
};

ChalkboardTabs.propTypes = {
  match: PropTypes.any.isRequired,
  classes: PropTypes.object.isRequired
};

export default withRouter(withStyles(styles)(ChalkboardTabs));
