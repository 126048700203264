import { getReducerData } from '../../reducer.common.utils';
import types from './reports.action.type';

export default (type, action, state, data) => {
  switch (type) {
    case types.membershipDetails:
      return getReducerData(action, state, data, types);
    case types.expireMembership:
      return getReducerData(action, state, data, types);
    case types.membersMembershipType:
      return getReducerData(action, state, data, types);
    case types.membersPaymentPlan:
      return getReducerData(action, state, data, types);
    case types.membersLastAttendance:
      return getReducerData(action, state, data, types);
    case types.membersAttendanceHistory:
      return getReducerData(action, state, data, types);
    case types.membershipsOnHold:
      return getReducerData(action, state, data, types);
    case types.attendanceSummary:
      return getReducerData(action, state, data, types);
    case types.allinvoicereports:
      return getReducerData(action, state, data, types);
    case types.revenuebymembership:
      return getReducerData(action, state, data, types);
    case types.overdueaccount:
      return getReducerData(action, state, data, types);
    case types.attendanceTrends:
      return getReducerData(action, state, data, types);
    case types.expirecreditcards:
      return getReducerData(action, state, data, types);
    case types.revenuePerMonth:
      return getReducerData(action, state, data, types);
    case types.leadInsights:
      return getReducerData(action, state, data, types);
    case types.getreportList:
      return getReducerData(action, state, data, types);
    case types.valueEeachMember:
      return getReducerData(action, state, data, types);
    case types.getUserHistory:
      return getReducerData(action, state, data, types);
    default:
      return state;
  }
};
