export const TobeFilter = [
  { id: 1, filter: 'Member Status' },
  { id: 2, filter: 'Contract' },
  { id: 3, filter: 'Membership Template' }
];

export const isNotData = [
  { id: 0, name: 'is Not' },
  { id: 1, name: 'is' }
];

export const FilterStatus = [
  { id: 0, name: 'Inactive' },
  { id: 1, name: 'Active' }
];
