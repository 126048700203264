/* eslint-disable operator-linebreak */
/* eslint-disable indent */
import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch, connect } from 'react-redux';
import {
  Grid,
  withStyles,
  Card,
  Breadcrumbs,
  Typography,
  Box
} from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
import ApplyFilter from 'src/views/common/ApplyFilter/applyFilter';
import { AttendanceContext } from 'src/Context/attendanceSummaryContext';
import { initialValue } from 'src/Context/AttendanceSummary/attendanceReducer';
import { attendanceSummaryReports } from 'src/Redux/ReportsReducer/reports.reducer.actions';
import {
  AttendanceSetNextDate,
  AttendanceSetProgram,
  AttendanceSetPrevDate,
  AttendanceSetDay,
  AttendanceSetStartTime,
  Reset
} from 'src/Context/AttendanceSummary/attendanceReducer.actions';
import LoadingView from 'src/views/common/loading';
import { getAttedanceSummaryChartData } from 'src/views/reports/Reports.utils';
import CancelDialogTwo from 'src/views/common/CommonDialogBox/CancelDialogTwo';
import ReducerConstants from 'src/Redux/Reducer.constants';
import useChangeTitle from 'src/views/common/CustomHooks/useTitleChange';
import JBButton from '../../common/JBButton/JBButton';
import AttendanceSummaryFilters from './Filters/AttendanceSummaryFilters';
import styles from './AttendanceReports.style';
import BarChartLine from '../chart/BarChartLine';

const AttendanceSummary = ({ classes, filterdTags }) => {
  const dispatch = useDispatch();
  useChangeTitle('Class Attendance Summary | Jungle Alliance');
  const [, datadispatch] = useContext(AttendanceContext);
  const [open, setOpen] = useState(false);
  const { isFetching, data } = useSelector(
    (state) => state.reportsReducer.attendanceReports?.attendanceSummary
  );
  const { data: data_reports } = useSelector(
    (state) => state.reportsReducer.reportList
  );
  const isStarred = data_reports?.attendance_reports.filter(
    (report) => report.id === 7
  )[0].is_starred;
  useEffect(() => {
    return () => {
      dispatch(attendanceSummaryReports(initialValue));
    };
  }, [dispatch]);
  if (!data_reports || !data) return <LoadingView />;
  return (
    <div className={classes.root}>
      <div className="title-bk">
        <Box css={{ marginBottom: '20px' }}>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={0}
          >
            <Grid item xs={12}>
              <Box display="inline-flex">
                <Breadcrumbs aria-label="breadcrumb">
                  <div color="inherit">Reports</div>
                  <div
                    color="inherit"
                    onClick={() => setOpen(true)}
                    style={{
                      cursor: 'pointer'
                    }}
                  >
                    attendance reports
                  </div>
                  <Typography variant="h3" color="Primary">
                    <span>Class Attendance Summary</span>
                  </Typography>
                </Breadcrumbs>
              </Box>
              <Box
                display="inline-flex"
                ml="10px"
                css={{ verticalAlign: 'text-bottom' }}
              >
                {isStarred === 1 && (
                  <StarIcon
                    style={{
                      color: '#FFC700'
                    }}
                  />
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </div>

      <Card className={classes.mainCard}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={0}
        >
          <Grid item xs={12}>
            <Grid container direction="row" alignItems="center" spacing={0}>
              {/* <Box css={{ width: '240px' }}>
                <CustomAutoComplete holder="Program: All" />
              </Box> */}
              <Box css={{ width: '240px' }}>
                <ApplyFilter>
                  <AttendanceSummaryFilters />
                </ApplyFilter>
              </Box>
              <Grid item xs>
                <JBButton
                  type="outlined"
                  onClick={() => {
                    dispatch(attendanceSummaryReports(initialValue));
                    datadispatch(Reset());
                  }}
                  style={{ marginLeft: '10px' }}
                >
                  reset
                </JBButton>
              </Grid>
            </Grid>
            <Box mt="25px" />
          </Grid>
          <Grid item xs={12}>
            <div>
              {filterdTags?.map((tags) => (
                <div
                  className={classes.DTags}
                  key={tags.id}
                  style={{ display: 'inline-block' }}
                >
                  <Box display="inline-block" mr={1}>
                    <label>
                      {tags.name}
                      <span
                        className={classes.DTagsClose}
                        onClick={() => {
                          dispatch(
                            attendanceSummaryReports(
                              ReducerConstants.removecustom,
                              tags
                            )
                          );
                          if (tags.filter === 'programe_id') {
                            datadispatch(
                              AttendanceSetProgram(initialValue.program)
                            );
                            return;
                          }
                          if (tags.filter === 'date_range') {
                            datadispatch(AttendanceSetDay(initialValue.day));
                            datadispatch(
                              AttendanceSetNextDate(initialValue.nextdate)
                            );
                            datadispatch(
                              AttendanceSetPrevDate(initialValue.prevdate)
                            );
                            return;
                          }
                          if (tags.filter === 'start_time') {
                            datadispatch(
                              AttendanceSetStartTime(initialValue.start_time)
                            );
                          }
                        }}
                      >
                        ×
                      </span>
                    </label>
                  </Box>
                </div>
              ))}
            </div>
          </Grid>
          <Grid item xs={12} alignItems="center" justify="center">
            {data.start_time.length === 0 &&
            data.attendance_summary.length === 0 ? null : (
              <Box css={{ textAlign: 'center' }} mb="25px">
                <Box mb="15px">
                  <Typography variant="h6" className={classes.BkChartTitle}>
                    <span>Average Attendance Per Class</span>
                  </Typography>
                </Box>
                {isFetching ? (
                  <LoadingView />
                ) : (
                  <BarChartLine
                    chartData={getAttedanceSummaryChartData(
                      data.start_time,
                      data.attendance_summary
                    )}
                  />
                )}
              </Box>
            )}
            {data.start_time.length === 0 &&
              data.attendance_summary.length === 0 && (
                <p
                  style={{
                    margin: '10px 15px 15px 0'
                  }}
                >
                  No Class Attendance Summary to show...
                </p>
              )}
          </Grid>
        </Grid>
      </Card>
      <CancelDialogTwo setOpen={open} handleClose={() => setOpen(false)} />
    </div>
  );
};
const mapToState = (state) => ({
  filterdTags: state.reportsReducer.attendanceReports.attendanceSummaryFil.tags
});
AttendanceSummary.propTypes = {
  classes: PropTypes.object.isRequired,
  filterdTags: PropTypes.func.isRequired
};

export default connect(mapToState)(withStyles(styles)(AttendanceSummary));
