import React, { useEffect, useState, useRef, useCallback } from 'react';
import {
  withStyles,
  Grid,
  Typography,
  Box,
  InputBase
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import PropType from 'prop-types';
import {
  automatedList,
  getAutomated
} from 'src/services/Communication.service';
import _ from 'lodash';
import LoadingView from 'src/views/common/loading';
import useChangeTitle from 'src/views/common/CustomHooks/useTitleChange';
import styles from '../automatedemails.style';
import {
  getGeneral,
  getGeneralRoutes,
  getInactiveLabels,
  getLabels
} from '../automatedemails.utils';

const General = ({ classes }) => {
  const [data, setData] = useState(null);
  const [search, setSearch] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  useChangeTitle('General Email templates | Jungle Alliance');
  const routes = useRef([]);
  const labels = useRef(null);
  const inactiveLables = useRef(null);
  const searchQuery = useCallback(
    _.debounce((q) => {
      setIsFetching(true);
      getAutomated({ search: q, email_general_class: 1 })
        .then((res) => {
          const searchData = res.filter(
            (item) => item.category !== 'Anonymous Payments'
          );
          setData(getGeneral(searchData));
          setIsFetching(false);
        })
        .catch((err) => {
          console.log(err);
          setIsFetching(false);
        });
    }, 1500),
    []
  );
  useEffect(() => {
    async function getList() {
      const res = await automatedList({ email_general_class: 1 });
      const generalData = res.data.filter(
        (item) => item.category !== 'Anonymous Payments'
      );
      labels.current = getLabels(generalData);
      inactiveLables.current = getInactiveLabels(generalData);
      const result = getGeneral(generalData);
      routes.current = getGeneralRoutes(result);
      setData(result);
    }
    getList();
  }, []);
  return (
    <Box>
      <Grid container direction="row" spacing={0}>
        <Grid
          container
          direction="row"
          spacing={0}
          style={{ margin: '10px 0 20px' }}
        >
          <Grid item xs={12}>
            <Typography
              variant="h3"
              component="h3"
              style={{ color: '#292929' }}
            >
              Email templates
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box mt="8px">
              <p className={classes.ptext}>
                Enable and customize the automated email templates that are sent
                to your members and staff.
              </p>
            </Box>
          </Grid>

          <Grid item xs>
            <Box className={classes.search} mt="15px">
              <InputBase
                fullWidth
                placeholder="Search for automated emails..."
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                  searchQuery(e.target.value);
                }}
                classes={{
                  root: classes.inputRoot,
                  input: classes.searchInput
                }}
                InputProps={{
                  'aria-label': 'search'
                }}
              />
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          spacing={2}
          // style={{ margin: '10px 0 20px' }}
        >
          {data && !isFetching ? (
            data.map((item) => (
              <Grid item xs={6}>
                <Link
                  to={{
                    pathname: routes.current[item.id]
                  }}
                >
                  <Box className={classes.wrapperBox}>
                    <Box className={classes.wraBoxTitle}>
                      <Grid container direction="row">
                        <Grid item xs={9}>
                          <Typography
                            variant="h5"
                            component="h5"
                            style={{ color: '#292929' }}
                          >
                            {item.category}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          justify="flex-end"
                          className="MuiGrid-container"
                        >
                          {labels.current[item.category] === 1 ? (
                            <>
                              {inactiveLables.current[item.category] ? (
                                <Box className={classes.InactiveBox}>
                                  <label>Inactive</label>
                                </Box>
                              ) : null}
                            </>
                          ) : (
                            <>
                              {inactiveLables.current[item.category] ? (
                                <Box className={classes.InactiveBox}>
                                  <label>
                                    {inactiveLables.current[item.category]} of{' '}
                                    {labels.current[item.category]} inactive
                                  </label>
                                </Box>
                              ) : null}
                            </>
                          )}
                        </Grid>
                      </Grid>
                    </Box>
                    <Box mt="8px">
                      <p className={classes.ptext}>
                        {item.description}
                        {item.id === 8 && 's'}.
                      </p>
                    </Box>
                  </Box>
                </Link>
              </Grid>
            ))
          ) : (
            <LoadingView />
          )}
          {data?.length === 0 && !isFetching && (
            <p style={{ padding: '0 10px' }}>No Email templates to show...</p>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

General.propTypes = {
  classes: PropType.object.isRequired
};

export default withStyles(styles)(General);
