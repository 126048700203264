import React from 'react';
import Proptypes from 'prop-types';
import { connect } from 'react-redux';
import LoadingView from '../loading';

// neg = negative
const GetPrices = (props) => {
  const {
    currencyState: { isFetching, data: currency, error },
    value,
    valuetype
  } = props;
  const getValue = () => {
    const curr = currency.currency_symbol;
    let result = curr
      + parseFloat(value).toLocaleString(undefined, {
        minimumFractionDigits: currency.decimals
      });
    if (valuetype) {
      if (valuetype === 'neg') {
        result = curr
          + '-'
          + parseFloat(value).toLocaleString(undefined, {
            minimumFractionDigits: currency.decimals
          });
      } else {
        result = curr
          + valuetype
          + parseFloat(value).toLocaleString(undefined, {
            minimumFractionDigits: currency.decimals
          });
      }
    }
    return result;
  };

  if (error) return <p>{error}</p>;
  if (!currency) return '$A0.00';
  if (isFetching) return <LoadingView />;

  return <span>{getValue()}</span>;
};

const mapToState = (state) => ({
  currencyState: state.SettingReducer.currency
});

GetPrices.defaultProps = {
  valuetype: null
};

GetPrices.propTypes = {
  currencyState: Proptypes.object.isRequired,
  value: Proptypes.string.isRequired,
  valuetype: Proptypes.string
};
export default connect(mapToState)(GetPrices);
