import colors from 'src/constant/colors';
import { ROUTES } from 'src/constant/Constants';
import moment from 'moment';

export const getActiveVal = (pathname) => {
  switch (pathname) {
    case ROUTES.REPORTS_STARRED:
      return 0;
    case ROUTES.REPORTS_MEMBERS:
      return 1;
    case ROUTES.REPORTS_ATTENDANCE:
      return 2;
    case ROUTES.REPORTS_FINANCIAL:
      return 3;
    case ROUTES.REPORTS_LEAD:
      return 4;
    default:
      return 0;
  }
};

function getReportsData(labels, data) {
  return {
    labels: [...labels],
    datasets: [
      {
        data: [...data],
        backgroundColor: colors.slice(0, data.length)
      }
    ]
  };
}

function getReportslabels(data, label, value) {
  const labels = [];
  const values = [];
  for (let i = 0; i < data?.length; i += 1) {
    labels.push(data[i][label]);
    values.push(data[i][value]);
  }
  return [labels, values];
}

export const getmembershipPieData = (data) => {
  const [labels, values] = getReportslabels(data, 'memberships_type', 'total');
  return getReportsData(labels, values);
};

export const getMembershipTypePieData = (data) => {
  const [labels, values] = getReportslabels(data, 'membership', 'total');
  return getReportsData(labels, values);
};

export const getMembershipPaymentPieData = (data) => {
  const [labels, values] = getReportslabels(data, 'payment_plan', 'total');
  return getReportsData(labels, values);
};

export const getRevenueChart = (labels, values) => {
  if (labels.length === values.length) {
    return getReportsData(labels, values);
  }
  return [];
};

export const getOverdueAccountReceivableChart = (data) => {
  const [labels, values] = getReportslabels(data, 'month', 'unpaid_amount');
  return getReportsData(labels, values);
};

export const getAttendancePieData = (data) => {
  const [labels, values] = getReportslabels(data, 'trend', 'total_count');
  return getReportsData(labels, values);
};

export const getLeadPieData = (data) => {
  const labels = Object.keys(data);
  const values = labels.map((l) => data[l]);
  return getReportsData(labels, values);
};
const dayColor = {
  Monday: 'rgb(66, 141, 224)',
  Tuesday: 'rgb(224, 78, 60)',
  Wednesday: 'rgb(64, 163, 106)',
  Thursday: 'rgb(130, 101, 163)',
  Friday: 'rgb(64, 150, 168)',
  Saturday: 'rgb(247, 161, 13)',
  Sunday: 'rgb(175, 213, 75)'
};

export const getAttedanceSummaryChartData = (labels, values) => {
  const mutatedValues = values.reduce((accumulator, currentValue) => {
    if (!accumulator[currentValue.days]) accumulator[currentValue.days] = [];

    accumulator[currentValue.days].push(currentValue);
    return accumulator;
  }, {});

  /*eslint-disable */
  const dataSets = [];
  for (let obj in mutatedValues) {
    dataSets.push({
      label: obj,
      data: mutatedValues[obj].reduce((acc, currVal) => {
        acc.push({
          x: moment(currVal.start_time, 'hh:mm A'),
          y: currVal.count
        });

        return acc;
      }, []),
      maxBarThickness: 5,
      backgroundColor: dayColor[obj],
      grouped: false
    });
  }

  return {
    labels: labels.map((l) => moment(l, 'hh:mm A')),
    datasets: dataSets
  };
  /* eslint-enable */
};
const trendColors = {
  up: 'rgb(255, 99, 132)',
  down: 'rgb(75, 192, 192)',
  no_change: 'rgb(54, 162, 235)'
};
export const getAttendanceTrendBar = (data) => {
  const labels = [...Array(data.length + 1).keys()].slice(1);
  const trends = ['up', 'down', 'no_change'];
  const dataSet = trends.map((trend) => ({
    label: trend,
    data: data.map((i) => i[trend]),
    backgroundColor: trendColors[trend]
  }));
  return {
    labels,
    datasets: dataSet
  };
};
