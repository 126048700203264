import React from 'react';
import PropType from 'prop-types';
import {
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  makeStyles
} from '@material-ui/core';
import GetPrice from 'src/views/common/CurrencySymbol/GetPrice';

const useStyles = makeStyles({
  table: {
    minWidth: '100%'
  },
  tableLink: {
    color: '#01847A',
    fontWeight: '700'
  },
  TableCell: {
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px',
    height: '56px',
    minHeight: '56px'
  },
  // Tablemian: {
  //   borderRadius: '4px'
  // },
  Tablemian: {
    borderRadius: '0px',
    backgroundColor: 'transparent',
    '& .MuiTableCell-head': {
      color: '#FFF',
      backgroundColor: 'rgb(59, 60, 78)'
    },
    '& .MuiTableSortLabel-root:hover': {
      color: '#01847A'
    },
    '& .MuiTableSortLabel-root.MuiTableSortLabel-active': {
      color: '#ffffff'
    },
    '& .MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon': {
      color: '#ffffff'
    },
    '& .MuiTableCell-body': {
      color: '#FFF'
    },
    '& .MuiTableBody-root .MuiTableCell-root': {
      borderBottom: '1px solid rgba(234, 234, 234, 0.2)'
    }
  },
  button_status_deac: {
    fontWeight: 'bold',
    fontSize: '11px',
    color: '#01847A',
    border: '1px solid rgba(1, 132, 122, 1)',
    width: '100px',
    height: '24px',
    padding: '0 0',
    borderRadius: '2px'
  },
  button_status_act: {
    fontWeight: 'bold',
    fontSize: '11px',
    color: '#9B9B9B',
    border: '1px solid #949494',
    width: '100px',
    height: '24px',
    padding: '0 0',
    borderRadius: '2px',
    '&$hover': {
      backgroundColor: '#1976d2'
    }
  }
});

const TransactionsTable = (props) => {
  const classes = useStyles();
  const { values } = props;

  return (
    <TableContainer
      component={Paper}
      elevation={0}
      className={classes.Tablemian}
    >
      <Table className={classes.table} aria-label="simple table">
        <TableHead
          style={{ background: '#F9F9FA', backgroundColor: '#F9F9FA' }}
        >
          <TableRow>
            <TableCell className={classes.TableCell}>Created On</TableCell>
            <TableCell className={classes.TableCell}>Created By</TableCell>
            <TableCell className={classes.TableCell}>Payment Method</TableCell>
            <TableCell className={classes.TableCell}>Amount</TableCell>
            <TableCell className={classes.TableCell}>
              Transaction Type
            </TableCell>
            <TableCell className={classes.TableCell}>
              Transaction Result
            </TableCell>
            <TableCell className={classes.TableCell}>Error Text</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {values?.map((row) => (
            <TableRow
              hover
              tabIndex={-1}
              key={row.id}
              style={{
                backgroundColor: '#26273B'
              }}
            >
              <TableCell
                style={{
                  width: '10%'
                }}
              >
                {row.created_on}
              </TableCell>
              <TableCell
                style={{
                  width: '10%'
                }}
              >
                {row.created_by_str}
              </TableCell>
              <TableCell
                style={{
                  width: '10%'
                }}
              >
                {row.payment_method_str}
              </TableCell>
              <TableCell
                style={{
                  width: '10%'
                }}
              >
                <GetPrice value={row.amount} />
              </TableCell>
              <TableCell
                style={{
                  width: '10%'
                }}
              >
                {row.transaction_type}
              </TableCell>
              <TableCell
                style={{
                  width: '10%'
                }}
              >
                {row.status === 'succeeded' ? 'Success' : row.status}
              </TableCell>
              <TableCell
                style={{
                  width: '10%'
                }}
              >
                {row.message || '-'}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {!values || values?.length === 0 ? (
        <p
          style={{
            color: '#fff'
          }}
        >
          No transactions made...
        </p>
      ) : null}
    </TableContainer>
  );
};
TransactionsTable.propTypes = {
  values: PropType.array.isRequired
};

export default TransactionsTable;
