import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropType from 'prop-types';
import {
  Paper,
  Checkbox,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  makeStyles,
  TableSortLabel,
  Box
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import filterType from 'src/Redux/Reducer.constants';
import { ROUTES } from 'src/constant/Constants';
import ClassTableToolbar from './InvoicesTableHead';
import CheckIcon from '../../../../assets/image/check.png';

const useStyles = makeStyles({
  table: {
    minWidth: '100%',
    wordBreak: 'break-word',
    '@media (max-width: 1440px)': {
      '& td': {
        padding: '10px 6px 10px 6px'
      }
    },
    '& .MuiTableCell-head': {
      color: '#FFF'
    },
    '& .MuiTableSortLabel-root:hover': {
      color: '#01847A'
    },
    '& .MuiTableSortLabel-root.MuiTableSortLabel-active': {
      color: '#ffffff'
    },
    '& .MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon': {
      color: '#ffffff'
    },
    '& .MuiTableCell-body': {
      color: '#FFF'
    },
    '& .MuiTableBody-root .MuiTableCell-root': {
      borderBottom: '1px solid rgba(234, 234, 234, 0.2)'
    }
  },
  tableLink: {
    color: '#01847A',
    fontWeight: '700'
  },
  dayofweek: {
    background: '#A9BFEF',
    borderRadius: '50px',
    padding: '8px',
    color: '#FFF'
  },
  TooltipLink: {
    '&:hover': {
      '& svg path': {
        stroke: '#01847A'
      }
    }
  },
  TableCell: {
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px',
    borderBottom: '1px solid #A8A8A8',
    height: '56px',
    minHeight: '56px',
    '@media (max-width: 1440px)': {
      padding: '10px 6px 10px 6px'
    }
  }
});

const Invoicestable = (props) => {
  const classes = useStyles();
  const {
    values,
    isActive,
    setDirection,
    setSort,
    total,
    selected,
    setSelected
  } = props;
  const {
    sort_invoice_no,
    sort_allies_name,
    sort_product_name,
    sortstatus,
    sort_final_amount,
    sort_due_date,
    sort_is_active
  } = filterType;

  // const [selected, setSelected] = useState([]);

  const areAllSelected = useSelector(
    (state) => state.masterHqReducer.financialReducer.areAllSelected
  );

  useEffect(() => {
    if (areAllSelected) {
      setSelected(values.map((n) => n.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areAllSelected, values]);

  const handleClick = (name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = values.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  const ClearCheckbox = () => {
    setSelected([]);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  return (
    <Box
      css={{
        width: '100%'
      }}
    >
      <TableContainer
        component={Paper}
        elevation={0}
        style={{ borderRadius: '0', backgroundColor: 'transparent' }}
      >
        {selected.length > 0 && (
          <ClassTableToolbar
            numSelected={selected}
            selectedTags={[]}
            ClearChecks={ClearCheckbox}
            module="class"
            total={total}
            values={values}
            setSelected={setSelected}
          />
        )}
        <Table className={classes.table} aria-label="simple table">
          {selected.length === 0 && (
            <TableHead>
              <TableRow style={{ background: '#3B3C4E' }}>
                <TableCell
                  padding="checkbox"
                  className={classes.TableCell}
                  style={{ width: '1%' }}
                >
                  <Checkbox
                    color="primary"
                    onChange={handleSelectAllClick}
                    inputProps={{ 'aria-labelledby': 'IDN' }}
                  />
                </TableCell>
                <TableCell
                  style={{ width: '10%' }}
                  className={classes.TableCell}
                >
                  <TableSortLabel
                    active={isActive(sort_invoice_no)}
                    direction={setDirection(sort_invoice_no)}
                    onClick={() => setSort(sort_invoice_no)}
                  >
                    Invoice
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  className={classes.TableCell}
                  style={{ width: '15%' }}
                >
                  <TableSortLabel
                    active={isActive(sort_allies_name)}
                    direction={setDirection(sort_allies_name)}
                    onClick={() => setSort(sort_allies_name)}
                  >
                    Ally
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  className={classes.TableCell}
                  style={{ width: '13%' }}
                >
                  <TableSortLabel
                    active={isActive(sort_product_name)}
                    direction={setDirection(sort_product_name)}
                    onClick={() => setSort(sort_product_name)}
                  >
                    Product
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  className={classes.TableCell}
                  style={{ width: '10%' }}
                >
                  <TableSortLabel
                    active={isActive(sortstatus)}
                    direction={setDirection(sortstatus)}
                    onClick={() => setSort(sortstatus)}
                  >
                    Status
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  className={classes.TableCell}
                  style={{ width: '15%' }}
                >
                  Default payment method
                </TableCell>
                <TableCell
                  className={classes.TableCell}
                  style={{ width: '9%' }}
                  align="center"
                >
                  <TableSortLabel
                    active={isActive(sort_is_active)}
                    direction={setDirection(sort_is_active)}
                    onClick={() => setSort(sort_is_active)}
                  >
                    Is Active
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  className={classes.TableCell}
                  style={{ width: '10%' }}
                  align="right"
                >
                  <TableSortLabel
                    active={isActive(sort_final_amount)}
                    direction={setDirection(sort_final_amount)}
                    onClick={() => setSort(sort_final_amount)}
                  >
                    Final charge
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  className={classes.TableCell}
                  style={{ width: '12%' }}
                  align="right"
                >
                  <TableSortLabel
                    active={isActive(sort_due_date)}
                    direction={setDirection(sort_due_date)}
                    onClick={() => setSort(sort_due_date)}
                  >
                    Payment due
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {values.map((row) => (
              <TableRow
                hover
                // role="checkbox"
                aria-checked={isSelected(row.id)}
                tabIndex={-1}
                key={row.id}
                selected={isSelected(row.id)}
                style={{
                  backgroundColor: '#26273B'
                }}
              >
                <TableCell padding="checkbox" style={{ width: '1%' }}>
                  <Checkbox
                    onClick={() => handleClick(row.id)}
                    color="primary"
                    checked={isSelected(row.id)}
                    inputProps={{ 'aria-labelledby': 'IDN' }}
                  />
                </TableCell>

                <TableCell
                  style={{
                    position: 'relative',
                    color: '#01847A',
                    fontWeight: '700',
                    width: '10%'
                  }}
                >
                  <Link
                    className={classes.tableLink}
                    to={{
                      pathname:
                        row.status === 1 || row.status === 3
                          ? ROUTES.MASTER_HQ_FINANCIAL_INVOICES_VIEW
                          : ROUTES.MASTER_HQ_FINANCIAL_INVOICES_PAIND_VIEW,
                      search: `?Invoice=${btoa(row.id)}`
                    }}
                  >
                    {row.invoice_no}
                  </Link>
                </TableCell>
                <TableCell
                  style={{
                    width: '15%'
                  }}
                >
                  <Link
                    className={classes.tableLink}
                    // to={ROUTES.MASTER_HQ_AFFILIATES_PROFILE}
                    to={{
                      pathname: ROUTES.MASTER_HQ_AFFILIATES_PROFILE,
                      search: `affiliateId=${row.affiliate_id}`
                    }}
                  >
                    {row.allies_name}
                  </Link>
                </TableCell>
                <TableCell
                  style={{
                    width: '13%'
                  }}
                >
                  {row.product_name}
                </TableCell>
                <TableCell
                  style={{
                    width: '10%'
                  }}
                >
                  {(() => {
                    switch (row.status) {
                      case 1:
                        return 'Unpaid';
                      case 2:
                        return 'Paid';
                      case 3:
                        return 'Voided';
                      case 5:
                        return 'Refunded';
                      default:
                        return '-';
                    }
                  })()}
                </TableCell>
                <TableCell
                  style={{
                    width: '15%'
                  }}
                >
                  {row.default_method_str}
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    width: '9%'
                  }}
                >
                  {row.is_active === 1 ? (
                    <img
                      src={CheckIcon}
                      alt="img"
                      style={{
                        marginRight: '20px'
                      }}
                    />
                  ) : null}

                  {/* {row.autobill} */}
                </TableCell>
                <TableCell
                  align="right"
                  style={{
                    width: '10%'
                  }}
                >
                  {row.final_amount}
                </TableCell>
                <TableCell
                  align="right"
                  style={{
                    width: '12%'
                  }}
                >
                  {row.due_date}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {values.length === 0 && (
          <p
            style={{
              margin: '10px 15px',
              color: '#fff'
            }}
          >
            No invoices to show...
          </p>
        )}
      </TableContainer>
    </Box>
  );
};
Invoicestable.propTypes = {
  values: PropType.array.isRequired,
  isActive: PropType.func.isRequired,
  setDirection: PropType.func.isRequired,
  setSort: PropType.func.isRequired,

  total: PropType.number.isRequired,
  selected: PropType.array.isRequired,
  setSelected: PropType.func.isRequired
};

export default Invoicestable;
