import React from 'react';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import clsx from 'clsx';
import { Box, withStyles } from '@material-ui/core';

const styles = () => ({
  // root: {
  //   height: '100%'
  // }
});

const data = {
  labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
  datasets: [
    {
      label: 'tuesday',
      data: [{ x: 'yellow', y: 1 }, { x: 'Purple', y: 1 }, 1],
      // barPercentage: 0.5,
      // barThickness: 6,
      maxBarThickness: 5,
      // minBarLength: 2,
      backgroundColor: 'rgb(255, 99, 132)'
    },
    {
      label: 'wednsday',
      data: [3, 3, 2, 1],
      // barPercentage: 0.5,
      // barThickness: 6,
      maxBarThickness: 5,
      // minBarLength: 2,
      backgroundColor: 'rgb(54, 162, 235)'
    }
  ]
};

const options = {
  animation: {
    onProgress(animation) {
      data.value = animation.animationObject.currentStep
        / animation.animationObject.numSteps;
    }
  },
  scales: {
    xAxes: [
      {
        type: 'time',
        time: {
          unit: 'hour',
          displayFormats: {
            hour: 'hh:mm A'
          }
        },
        scaleLabel: {
          display: true,
          labelString: 'CLASS'
        }
      }
    ],
    yAxes: [
      {
        scaleLabel: {
          display: true,
          labelString: 'ATTENDANCE'
        },
        barPercentage: 0.9,
        categoryPercentage: 0.4,
        ticks: {
          beginAtZero: true
        }
      }
    ]
  },
  legend: {
    display: true,
    position: 'right'
    // labels: {
    //   fontColor: 'rgb(255, 99, 132)'
    // }
  },
  maintainAspectRatio: false,
  responsive: true
};

// eslint-disable-next-line react/prop-types
const BarChartLine = ({ classes, children, chartData, ...props }) => (
  <Box
    height={400}
    width={800}
    position="relative"
    display="inline-block"
    className={clsx(classes.root)}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  >
    {children}
    <Bar data={chartData} options={options} />
  </Box>
);

BarChartLine.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.string.isRequired
};

export default withStyles(styles)(BarChartLine);
