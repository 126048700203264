/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState, useContext } from 'react';
// import { useHistory } from 'react-router-dom';
import PropType from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  withStyles,
  Grid,
  InputLabel
} from '@material-ui/core';
import { MemProfile } from 'src/Context/MemberProContext';
import ReduceType from 'src/Context/MemberProfileReducer/MemProfile.types';
import {
  getMemHolds,
  // updateMemHoldFil,
  fetchingInvoice,
  fetchComplete,
  ErrorFetching
} from 'src/Context/MemberProfileReducer/MemProfileReducer.actions';
import JBDatePicker from 'src/views/common/FormControl/JBDatePicker';
import useGymSetting from 'src/views/common/CustomHooks/useGymSettingRefresh';
import dcloseicon from 'src/assets/image/closeicon.png';
import JBButton from 'src/views/common/JBButton/JBButton';
import CustomAutocomplete from 'src/views/common/CustomAutocomplete/commonAutocomplete';
import {
  getMemholdType,
  getMemHoldData
} from 'src/services/Membersip.services';
import NotFoundView from 'src/views/errors/NotFoundView';
import LoadingView from 'src/views/common/loading';
// import { data } from 'src/views/reports/AttendanceReports/chartData/AttendanceTrendData';
import moment from 'moment';
import { openNoti } from 'src/Redux/global/global.actions';
import { connect } from 'react-redux';
import {
  editHold,
  deleteHold,
  getUserMemhold
} from 'src/services/Members.module';
// import {
//   getActiveMem,
//   getAllMem
// } from 'src/Redux/MemberReducer/memberReducer.actions';
import { getErrorMsg } from 'src/utils/ErrorHandling';
import { getusermembership } from 'src/services/user.service';
import { updateAllData } from '../Memberships/NewMembership/NewMembership.actions';
import { updatemem } from './EditMembership.utils';

const styles = () => ({
  dtitle: {
    color: '#292929',
    fontWeight: 'bold',
    fontSize: '33px',
    lineHeight: '26px'
  },
  cutom_select_Label: {
    fontSize: '12px',
    padding: '7px 0'
  },
  ptext: {
    fontSize: '14px',
    lineHeight: '24px',
    color: '#7F7F7F',
    fontWeight: 'normal'
  },
  DialogActionsBk: {
    padding: '30px 30px'
  },
  dateCss: {
    '& .MuiInput-root': {
      color: '#292929'
    }
  }
});

function EditMemberHold({
  classes,
  open1,
  id,
  openNotify,
  onClose,
  onEdit,
  onDelete,
  start,
  reducer,
  editholdBtn
}) {
  const { dateformat } = useGymSetting();
  const [data, setData] = useState({
    // hold_start_date: moment(id.hold_start_date).toDate(),
    // hold_end_date: moment(id.hold_end_date).toDate(),
    hold_start_date: null,
    hold_end_date: null,
    hold_reason: null,
    is_deletable: null
  });
  const [state, dispatch] = reducer;
  const [statedata, dispatchdata] = useContext(MemProfile);
  const [open, setOpen] = useState(open1);
  const [options, setoptions] = useState(null);
  const [error, setError] = useState(false);
  // const history = useHistory();

  useEffect(() => {
    getMemholdType()
      .then((res) => {
        setoptions(res);
        // setData({
        //   ...data,
        //   hold_reason: res.filter((item) => item.label === id.hold_reason)[0]
        // });
        getMemHoldData(id.id)
          .then((ress) => {
            // setoptions(res);
            setData({
              ...data,
              hold_start_date: ress[0].hold_start_date,
              hold_end_date: ress[0].hold_end_date,
              is_deletable: ress[0].is_deletable,
              hold_reason: res.filter(
                (item) => item.label === ress[0].hold_reason
              )[0]
            });
          })
          .catch(() => {
            // setError(true)
          });
      })
      .catch(() => setError(true));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleClose = () => {
    setOpen(false);
    // setData({
    //   hold_start_date:null,
    //   hold_end_date: null,
    //   hold_reason: null
    // });
    onClose();
  };
  const handleDelete = () => {
    deleteHold(id.id)
      .then(() => {
        getusermembership(id.user_member_id)
          .then((res1) => {
            openNotify('Membership hold successfully removed');
            dispatch(
              updateAllData({ ...updatemem(res1), reset: updatemem(res1) })
            );
          })
          .catch((err) => openNotify(getErrorMsg(err), 'error'));
        onDelete();
        onClose();
      })
      .catch((err) => openNotify(getErrorMsg(err), 'error'));
  };
  const handleCreate = () => {
    const result = {
      // user_member_id: Number(value.user_id),
      // user_id: Number(value.user_member_id),
      hold_start_date: moment(data.hold_start_date).format('YYYY-MM-DD'),
      hold_end_date: moment(data.hold_end_date).format('YYYY-MM-DD'),
      // hold_start_date: data.hold_start_date,
      // hold_end_date: data.hold_end_date,
      hold_reason: data.hold_reason.id
    };
    editHold(result, id.id)
      .then(() => {
        getusermembership(id.user_member_id)
          .then((res1) => {
            const type = ReduceType.getMemHold;
            const val = getMemHolds(id.user_id, statedata);
            dispatchdata(fetchingInvoice(type));
            getUserMemhold(val)
              .then((res) => {
                dispatchdata(fetchComplete(type, res));
                openNotify('Membership hold successfully updated');
                dispatch(
                  updateAllData({ ...updatemem(res1), reset: updatemem(res1) })
                );
              })
              .catch((err) =>
                dispatchdata(ErrorFetching(type, getErrorMsg(err))));
          })
          .catch((err) => openNotify(getErrorMsg(err), 'error'));
        onEdit(
          moment(data.hold_start_date).format(dateformat),
          moment(data.hold_end_date).format(dateformat)
        );

        onClose();
      })
      .catch((err) => openNotify(getErrorMsg(err), 'error'));
  };
  const handleSave = () => {
    if (!data.hold_end_date) {
      openNotify('End date is not valid ', 'error');
    } else if (!data.hold_reason) {
      openNotify('Hold reason is not valid ', 'error');
    } else {
      handleCreate();
    }
  };
  if (error) {
    return (
      <Dialog>
        <NotFoundView />
      </Dialog>
    );
  }
  if (!options) {
    return (
      <Dialog>
        <LoadingView />
      </Dialog>
    );
  }
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick
      >
        <div style={{ width: '548px' }}>
          <Grid alignItems="center" container direction="row">
            <Grid item xs>
              <DialogTitle
                id="alert-dialog-title"
                className={classes.titlebk}
                style={{
                  display: 'inline-block',
                  padding: '35px 15px 25px 28px'
                }}
              >
                {editholdBtn ? (
                  <h4 className={classes.dtitle}>
                    Edit Member {id.type === 'plan' ? 'Plan' : 'Session'} Hold
                  </h4>
                ) : (
                  <h4 className={classes.dtitle}>
                    Edit Member{' '}
                    {state.data.type === 'plan' ? 'Plan' : 'Session'} Hold
                  </h4>
                )}
              </DialogTitle>
            </Grid>
            <Grid
              item
              // xs={2}
              style={{
                textAlign: 'right',
                marginRight: '20px',
                marginTop: '10px'
              }}
            >
              <Button onClick={handleClose} className={classes.btns}>
                <img alt="img" src={dcloseicon} />
              </Button>
            </Grid>
          </Grid>

          <DialogContent>
            <Grid
              container
              spacing={2}
              style={{ marginTop: '5px', padding: '0 15px' }}
            >
              <Grid
                item
                xs={12}
                style={{ padding: '0 0', marginBottom: '20px' }}
              >
                <p className={classes.ptext} style={{ marginBottom: '15px' }}>
                  Adding a hold to a membership will change it's billing date,
                  after the hold period is over. We recommend you check the new
                  due date for the membership invoices, after adding the hold,
                  and communicate the change to the member.
                </p>
                <p className={classes.ptext}>
                  If the member's hold start date is the same as their billing
                  date, the member will be charged and their next invoice due
                  date will be pushed back to account for the time missed.
                </p>
              </Grid>
              <Grid alignItems="center" spacing={2} container direction="row">
                <Grid item xs={6} className={classes.dateCss}>
                  <JBDatePicker
                    label="Start date"
                    minDate={start}
                    // value={startDate}
                    value={data.hold_start_date}
                    // value={moment(data.hold_start_date).format('YYYY-MM-DD')}
                    onChange={(val) => {
                      setData({ ...data, hold_start_date: val });
                    }}
                    required
                  />
                </Grid>
                <Grid item xs={6} className={classes.dateCss}>
                  <JBDatePicker
                    label="End date"
                    minDate={data.hold_start_date}
                    // value={endDate}
                    value={data.hold_end_date}
                    // value={moment(data.hold_end_date).format('YYYY-MM-DD')}
                    onChange={(val) => {
                      setData({ ...data, hold_end_date: val });
                    }}
                    required
                  />
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                style={{
                  marginTop: '15px',
                  marginBottom: '15px',
                  padding: '0 0'
                }}
              >
                <InputLabel
                  htmlFor="age-required"
                  required
                  className={classes.cutom_select_Label}
                  style={{ padding: '5px 0' }}
                >
                  Hold reason
                </InputLabel>
                <CustomAutocomplete
                  holder={data.hold_reason}
                  data={data.hold_reason}
                  Options="label"
                  value={options}
                  Change={(val) => {
                    setData({ ...data, hold_reason: val });
                  }}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions className={classes.DialogActionsBk}>
            {/* {moment(id?.hold_start_date, 'DD-MM-YYYY')
              .toDate()
              .getTime() > new Date().getTime() && (
              <JBButton onClick={handleDelete} type="outlined">
                Remove hold
              </JBButton>
            )} */}

            {data.is_deletable === 1 && (
              <JBButton onClick={handleDelete} type="outlined">
                Remove hold
              </JBButton>
            )}
            <JBButton
              onClick={handleSave}
              type="primary"
              style={{
                marginLeft: '15px'
              }}
            >
              Save
            </JBButton>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}
EditMemberHold.defaultProps = {
  // handleHistory: null
};

EditMemberHold.propTypes = {
  classes: PropType.object.isRequired,
  // handleDelete: PropType.func.isRequired,
  // handleHistory: PropType.string,
  id: PropType.object.isRequired,
  openNotify: PropType.func.isRequired,
  onClose: PropType.func.isRequired,
  open1: PropType.bool.isRequired,
  onEdit: PropType.func.isRequired,
  onDelete: PropType.func.isRequired,
  start: PropType.any.isRequired,
  reducer: PropType.object.isRequired,
  editholdBtn: PropType.bool.isRequired
};
const mapToDispatch = (dispatch) => ({
  openNotify: (msg, type) => dispatch(openNoti(msg, type))
});
export default connect(null, mapToDispatch)(withStyles(styles)(EditMemberHold));
