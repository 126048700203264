import React from 'react';
import PropType from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  DialogTitle,
  withStyles
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { updateRevenueCtgry } from 'src/Redux/SettingReducer/SettingReducer.actions';
import JBButton from '../../common/JBButton/JBButton';
import Qst from '../../../assets/image/questionmark.png';

const styles = () => ({
  bodytxt: {
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '0.002em',
    color: '#7F7F7F',
    padding: '10px 0'
  },
  titlebk: {
    padding: '16px 24px 5px'
  }
});

function ChangeStatusDialog({ classes, item, open, handleClose }) {
  const dispatch = useDispatch();
  const handleActivate = () => {
    dispatch(updateRevenueCtgry(item.id, { ...item, status: 1 }));
  };
  const handleDeactivate = () => {
    dispatch(updateRevenueCtgry(item.id, { ...item, status: 0 }));
  };
  const handleUpdate = () => {
    if (item.status) {
      handleDeactivate();
    } else {
      handleActivate();
    }
    handleClose();
  };
  return (
    <div>
      <Dialog
        open={open}
        onClose={() => handleClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div style={{ width: 394 }}>
          <DialogTitle id="alert-dialog-title" className={classes.titlebk}>
            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
              <img
                alt="Plusicon"
                src={Qst}
                style={{
                  display: 'inline-block',
                  verticalAlign: 'sub',
                  marginTop: '5px'
                }}
              />
              <Typography
                color="#000"
                variant="h5"
                component="div"
                className={classes.title}
                style={{ display: 'inline-block', marginLeft: '10px' }}
              >
                Do you want to change revenue category status?
              </Typography>
            </div>
          </DialogTitle>
          <DialogContent>
            <p className={classes.bodytxt}>
              Are you sure you want to change revenue category status?
            </p>
          </DialogContent>
          <DialogActions
            style={{
              padding: '5px 20px 20px'
            }}
          >
            <Button
              variant="outlined"
              onClick={handleClose}
              style={{
                height: '48px',
                color: '#7F7F7F',
                fontWeight: 'bold'
              }}
            >
              Cancel
            </Button>
            <JBButton type="primary" onClick={handleUpdate}>
              Confirm
            </JBButton>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}

ChangeStatusDialog.propTypes = {
  classes: PropType.object.isRequired,
  handleClose: PropType.func.isRequired,
  item: PropType.object.isRequired,
  open: PropType.bool.isRequired
};
export default withStyles(styles)(ChangeStatusDialog);
