import React from 'react';
import PropTypes from 'prop-types';

import Skeleton from '@material-ui/lab/Skeleton';

const LoadingView = (props) => {
  const { showFullLoading } = props;

  const view = showFullLoading ? (
    <React.Fragment>
      <Skeleton width="90%" />
      <Skeleton width="95%" />
      <Skeleton />
      <Skeleton />
      <Skeleton />
      <Skeleton />
      <Skeleton />
      <Skeleton />
    </React.Fragment>
  ) : (
    <React.Fragment>
      <Skeleton width="50%" />
      <Skeleton width="50%" />
      <Skeleton />
      <Skeleton />
      <Skeleton />
      <Skeleton />
      <Skeleton />
      <Skeleton />
    </React.Fragment>
  );
  return view;
};

LoadingView.defaultProps = {
  showFullLoading: true
};
LoadingView.propTypes = {
  showFullLoading: PropTypes.bool
};

export default LoadingView;
