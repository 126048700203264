import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 40,
    height: 40,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  active: {
    backgroundColor: '#01847A'
  },
  completed: {
    backgroundColor: '#01847A'
  }
});

const ColorlibStepIcon = (props) => {
  const classes = useColorlibStepIconStyles();
  const {
    active,
    completed,
    icon,
    FirstIcon,
    SecondIcon,
    ThirdIcon,
    FourthIcon,
    FifthIcon
  } = props;
  const icons = {
    1: <FirstIcon />,
    2: <SecondIcon />,
    3: <ThirdIcon />,
    4: <FourthIcon />,
    5: <FifthIcon />
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed
      })}
    >
      {icons[String(icon)]}
    </div>
  );
};

ColorlibStepIcon.defaultProps = {
  active: false,
  completed: false,
  icon: null,
  FirstIcon: null,
  SecondIcon: null,
  ThirdIcon: null,
  FourthIcon: null,
  FifthIcon: null
};

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
  // FirstIcon: PropTypes.element,
  // SecondIcon: PropTypes.element,
  // ThirdIcon: PropTypes.element,
  // FourthIcon: PropTypes.element,
  // FifthIcon: PropTypes.element
  FirstIcon: PropTypes.object,
  SecondIcon: PropTypes.object,
  ThirdIcon: PropTypes.object,
  FourthIcon: PropTypes.object,
  FifthIcon: PropTypes.object
};

export default ColorlibStepIcon;
