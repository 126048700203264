import React from 'react';
import clsx from 'clsx';
import PropType from 'prop-types';
import { Checkbox, Grid, makeStyles, MenuItem, Toolbar, Typography } from '@material-ui/core';
import BulkActions from 'src/views/common/JBTables/BulkActions';
import { exportContract } from 'src/services/Contracts.services';
import { get } from 'js-cookie';

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    padding: '0 5px',
    backgroundColor: '#F9F9FA',
    color: '#000'
  },
  highlight:
    theme.palette.type === 'light'
      ? { color: '#292929', backgroundColor: '#F9F9FA' }
      : {
          color: theme.palette.text.primary, // eslint-disable-line
          backgroundColor: theme.palette.secondary.dark // eslint-disable-line
        }, // eslint-disable-line
  title: {
    flex: '1 1 100%'
  },
  bkselected: {
    minWidth: '185px',
    textAlign: 'center',
    color: '#292929',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '0.2px'
  }
}));

const EnhancedTableToolbar = (props) => {
  const { ClearChecks, numSelected, signeTabs } = props;
  const roleid = JSON.parse(get('jbrole') || '{}')?.role_id || 3;
  const classes = useToolbarStyles();
  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0
      })}
      style={{
        backgroundColor: '#F9F9FA'
      }}
    >
      <Checkbox
        defaultChecked
        indeterminate
        onClick={() => ClearChecks()}
        inputProps={{ 'aria-label': 'indeterminate checkbox' }}
        color="primary"
      />
      <Grid
        spacing={0}
        disableGutters
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
      >
        <Grid item>
          <Typography
            color="inherit"
            variant="subtitle1"
            component="div"
            className={classes.bkselected}
          >
            {numSelected.length} contracts selected
          </Typography>
        </Grid>
        <Grid item xs={2}>
          {roleid === 1 && (
            <BulkActions>
              {signeTabs === '1' ? (
                <MenuItem
                  onClick={() => {
                    exportContract(numSelected.join(','), 1);
                  }}
                >
                  <div>Export</div>
                </MenuItem>
              ) : (
                <MenuItem
                  onClick={() => {
                    exportContract(numSelected.join(','), 0);
                  }}
                >
                  <div>Export</div>
                </MenuItem>
              )}
            </BulkActions>
          )}
        </Grid>
      </Grid>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  signeTabs: '0'
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropType.number.isRequired,
  ClearChecks: PropType.func.isRequired,
  signeTabs: PropType.number.isRequired
};

export default EnhancedTableToolbar;
