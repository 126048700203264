const styles = () => ({
  cutom_select_Label: {
    fontSize: '12px',
    '&:focus ': {
      outline: '0'
    }
  },
  FiltersWrapper: {
    background: '#fff'
  },

  // not working css but try to implement
  '& body div[role="presentation"]': {
    background: '#3c3d4f',
    '& .MuiAutocomplete-paper ': {
      background: '#3c3d4f'
    }
  }
});
export default styles;
