import React, { useState } from 'react';
import PropType from 'prop-types';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  withStyles,
  Grid,
  Box
} from '@material-ui/core';
import CancelDialogTwo from 'src/views/common/CommonDialogBox/CancelDialogTwo';
import dcloseicon from '../../../../assets/image/closeicon.png';
import NewContract from '../../../Documents/NewContract';
import styles from './ContractDialog.style';

function NewContractDialog(props) {
  const { classes, id, open, setClose, handleHistory, reloadFunc } = props;
  const [cancelDialog, setcancelDialog] = useState(false);
  const handleClose = (e) => {
    e.stopPropagation();
    setClose();
  };
  return (
    <Box display="inline-block">
      <Dialog
        open={open}
        fullWidth
        maxWidth="lg"
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick
        disableEnforceFocus
      >
        <div>
          <Grid alignItems="center" container direction="row">
            <Grid item xs>
              <DialogTitle
                id="alert-dialog-title"
                className={classes.titlebk}
                style={{
                  display: 'inline-block',
                  padding: '25px 24px'
                }}
              >
                <h4 className={classes.dtitle}>New Membership Contract</h4>
              </DialogTitle>
            </Grid>
            <Grid
              item
              xs={2}
              style={{ textAlign: 'right', marginRight: '10px' }}
            >
              <Button
                className={classes.btns}
                onClick={() => setcancelDialog(true)}
              >
                <img alt="img" src={dcloseicon} />
              </Button>
            </Grid>
          </Grid>

          <DialogContent
            style={{ padding: '15px 24px', overflowY: 'hidden' }}
            className={classes.NewContractBtn}
          >
            <Grid container spacing={0} style={{ marginTop: '0px' }}>
              <Grid item style={{ margin: '0 0 0px' }} />
              <NewContract
                id={id}
                goToHistory={handleHistory}
                reloadFunc={reloadFunc}
              />
            </Grid>
          </DialogContent>
          <CancelDialogTwo
            setOpen={cancelDialog}
            handleClose={() => setcancelDialog(false)}
          />
        </div>
      </Dialog>
    </Box>
  );
}
NewContractDialog.defaultProps = {
  handleHistory: null,
  reloadFunc: () =>
    console.warn('function called but please mention the function')
};

NewContractDialog.propTypes = {
  classes: PropType.object.isRequired,
  // handleDelete: PropType.func.isRequired,
  setClose: PropType.func.isRequired,
  open: PropType.bool.isRequired,
  id: PropType.number.isRequired,
  handleHistory: PropType.string,
  reloadFunc: PropType.func
};
export default withStyles(styles)(NewContractDialog);
