/* eslint-disable function-paren-newline */
import React, { useEffect, useReducer, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  InputLabel,
  FormControlLabel,
  Button,
  withStyles,
  Card
} from '@material-ui/core';
import { debounce } from 'lodash';
import IOSSwitch from 'src/views/common/FormControl/IOSSwitch';
import useChangeTitle from 'src/views/common/CustomHooks/useTitleChange';
import { getClassSetting } from 'src/services/classModule.services';
import FormControl from 'src/views/common/FormControl/formControl';
import { updateSettingsByModule } from 'src/services/Setting.services';
import { getErrorMsg } from 'src/utils/ErrorHandling';
import { validateInt } from 'src/utils/validations';
import { useDispatch } from 'react-redux';
import { openNoti } from 'src/Redux/global/global.actions';
import JBCKEditor from 'src/views/common/JBCKEditor';
import Closeicon from 'src/assets/image/closeicon.png';
import edit from 'src/assets/image/edit.png';
import JBButton from 'src/views/common/JBButton/JBButton';
import { Skeleton } from '@material-ui/lab';
import CustomAutoComplete from '../../calendar/commonAutocomplete';
import { getSetHour } from './ClassSetting.Data';
import {
  ClassSettingreducer,
  initialValue,
  SettingReducerTypes as reducerType
} from './classSetting.reducer';
import { getValidData } from './classSetting.utils';
import { classSettingValidData } from './ClassSettingTab.utils';

const styles = () => ({
  // const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1
  },
  STabPanel: {
    backgroundColor: '#fff',
    boxShadow: '4px 0px 16px rgba(37, 40, 43, 0.08)',
    '& .MuiCard-root.Tabulation-mainCard-136': {
      boxShadow: 'none',
      padding: '0 0'
    }
  },
  hr: {
    border: '1px solid rgb(196 196 196 / 0.4)',
    display: 'inline-block',
    width: '100%',
    margin: '10px 0'
  },
  ptext: {
    fontSize: '16px',
    lineHeight: '24px',
    color: '#7F7F7F',
    fontWeight: 'normal'
  },
  cutom_Switch_Label: {
    display: 'inline-block',
    marginRight: '30px',
    verticalAlign: 'middle',
    fontSize: '14px',
    fontWeight: '700',
    color: '#000'
  },
  mainCard: {
    boxShadow: '4px 0px 16px rgba(37, 40, 43, 0.08)',
    borderRadius: '5px',
    background: '#FFFF',
    padding: 20
  }
});
const SettingClassTab = ({ classes }) => {
  const [state, dispatch] = useReducer(ClassSettingreducer, initialValue);
  const [RadioLoader, setRadioLoader] = useState(true);
  const reduxDispatch = useDispatch();

  useChangeTitle('Classes Settings');
  useEffect(() => {
    getClassSetting()
      .then((res) => {
        dispatch({ type: reducerType.getData, payload: getValidData(res) });
        setRadioLoader(false);
      })
      .catch((err) => console.warn(err));
  }, []);

  const [Edit, setEdit] = useState(false);
  const [EditEmail, setEditEmail] = useState(false);
  const [EditNoShowEmail, setEditNoShowEmail] = useState(false);
  const [EditSignin, setEditSignin] = useState(false);

  const {
    data: {
      late_cancel_email_template,
      late_cancel_settings,
      no_show_email_template,
      no_show_settings,
      default_reservation_settings: {
        login_open_time,
        login_open_unit,
        res_open_time,
        res_open_unit,
        res_cancel_time,
        res_cancel_unit,
        res_close_time,
        res_close_unit
      }
    }
  } = state;
  const nonEditorApi = useCallback(
    debounce((action, value) => {
      const { error, data } = classSettingValidData(value, action);
      if (error) {
        reduxDispatch(openNoti(error, 'error'));
      } else {
        updateSettingsByModule('class', data)
          .then(() =>
            reduxDispatch(openNoti('Successfully saved Class setting'))
          )
          .catch((err) => reduxDispatch(openNoti(getErrorMsg(err), 'error')));
      }
    }, 200),
    []
  );

  const EditorApi = useCallback(
    debounce((action, value) => {
      const { error, data } = classSettingValidData(value, action);
      if (error) {
        reduxDispatch(openNoti(error, 'error'));
      } else {
        updateSettingsByModule('class', data)
          .then(() =>
            reduxDispatch(openNoti('Successfully saved Class setting'))
          )
          .catch((err) => reduxDispatch(openNoti(getErrorMsg(err), 'error')));
      }
    }, 600),
    []
  );

  const handleNonEditor = ({ type, subType, payload }) => {
    dispatch({
      type,
      subType,
      payload
    });
    nonEditorApi(
      {
        type,
        subType,
        payload
      },
      state
    );
  };
  const handleEditor = ({ type, subType, payload }) => {
    dispatch({
      type,
      subType,
      payload
    });
    EditorApi(
      {
        type,
        subType,
        payload
      },
      state
    );
  };
  return (
    <div className={classes.root}>
      <Card className={classes.mainCard}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={0}
          style={{ margin: '20px 0 20px' }}
        >
          <Grid item xs={9}>
            <h3 style={{ margin: '0 0' }}>Default Reservation Settings</h3>
            <p className={classes.ptext}>
              Sets default for new reservation-enabled classes you create in the
              future
            </p>
          </Grid>

          {!Edit ? (
            <Grid item xs={3} className={classes.btnBlock}>
              <JBButton
                type="outline"
                isImg={edit}
                style={{ float: 'right', padding: '11px 15px' }}
                onClick={() => setEdit(true)}
              >
                Edit
              </JBButton>
            </Grid>
          ) : (
            <Grid item xs={3} className={classes.btnBlock}>
              <Button onClick={() => setEdit(false)} style={{ float: 'right' }}>
                <img alt="img" src={Closeicon} />
              </Button>
            </Grid>
          )}
          {!Edit ? null : (
            <Grid item xs={12}>
              <Grid
                container
                spacing={0}
                xs={12}
                disableGutters
                direction="row"
                style={{
                  margin: '15px 0'
                }}
              >
                <Grid
                  item
                  xs={2}
                  style={{
                    marginTop: '0px',
                    paddingRight: '10px',
                    display: 'flex'
                  }}
                >
                  <h4 style={{ alignSelf: 'center' }}>Reservation open</h4>
                </Grid>
                <Grid
                  item
                  xs={3}
                  className={classes.time_box}
                  style={{ marginTop: '0px', paddingRight: '15px' }}
                >
                  <InputLabel
                    htmlFor="age-required"
                    className={classes.cutom_select_Label}
                    style={{ margin: '0px 0' }}
                  >
                    &nbsp;
                  </InputLabel>
                  <FormControl
                    control="input"
                    label=""
                    placeholder="Reservation Open"
                    value={res_open_time}
                    required
                    onChange={(e) =>
                      handleNonEditor({
                        type: reducerType.reservation,
                        subType: reducerType.resOpenTime,
                        payload: validateInt(e.target.value)
                      })
                    }
                    style={{
                      margin: '0 0'
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={3}
                  className={classes.time_box}
                  style={{ marginTop: '0px', paddingRight: '15px' }}
                >
                  <InputLabel
                    htmlFor="age-required"
                    className={classes.cutom_select_Label}
                    style={{ margin: '3px 0' }}
                  >
                    &nbsp;
                  </InputLabel>
                  <CustomAutoComplete
                    holder="Select Hours"
                    value={getSetHour}
                    Options="name"
                    data={res_open_unit}
                    Change={(val) =>
                      handleNonEditor({
                        type: reducerType.reservation,
                        subType: reducerType.resOpenUnit,
                        payload: val
                      })
                    }
                    style={{ marginbottom: '0' }}
                  />
                </Grid>
                <Grid
                  item
                  xs={3}
                  className={classes.time_box}
                  style={{
                    marginTop: '0px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignSelf: 'center'
                  }}
                >
                  <h4 style={{ marginBottom: '0px' }}>prior to start time</h4>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={0}
                xs={12}
                disableGutters
                direction="row"
                style={{
                  margin: '15px 0'
                }}
              >
                <Grid
                  item
                  xs={2}
                  style={{
                    marginTop: '0px',
                    paddingRight: '10px',
                    display: 'flex'
                  }}
                >
                  <h4 style={{ alignSelf: 'center' }}>Reservation close</h4>
                </Grid>
                <Grid
                  item
                  xs={3}
                  className={classes.time_box}
                  style={{ marginTop: '0px', paddingRight: '15px' }}
                >
                  <InputLabel
                    htmlFor="age-required"
                    className={classes.cutom_select_Label}
                    style={{ margin: '0px 0' }}
                  >
                    &nbsp;
                  </InputLabel>
                  <FormControl
                    control="input"
                    label=""
                    placeholder="Reservation Close"
                    required
                    value={res_close_time}
                    onChange={(e) =>
                      handleNonEditor({
                        type: reducerType.reservation,
                        subType: reducerType.resCloseTime,
                        payload: validateInt(e.target.value)
                      })
                    }
                    style={{
                      margin: '0 0'
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={3}
                  className={classes.time_box}
                  style={{ marginTop: '0px', paddingRight: '15px' }}
                >
                  <InputLabel
                    htmlFor="age-required"
                    className={classes.cutom_select_Label}
                    style={{ margin: '3px 0' }}
                  >
                    &nbsp;
                  </InputLabel>
                  <CustomAutoComplete
                    holder="Select Hours"
                    Options="name"
                    value={getSetHour}
                    data={res_close_unit}
                    Change={(val) =>
                      handleNonEditor({
                        type: reducerType.reservation,
                        subType: reducerType.resCloseUnit,
                        payload: val
                      })
                    }
                    style={{ marginbottom: '0' }}
                  />
                </Grid>
                <Grid
                  item
                  xs={3}
                  className={classes.time_box}
                  style={{
                    marginTop: '0px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignSelf: 'center'
                  }}
                >
                  <h4 style={{ marginBottom: '0px' }}>prior to start time</h4>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={0}
                xs={12}
                disableGutters
                direction="row"
                style={{
                  margin: '15px 0'
                }}
              >
                <Grid
                  item
                  xs={2}
                  style={{
                    marginTop: '0px',
                    paddingRight: '10px',
                    display: 'flex'
                  }}
                >
                  <h4 style={{ alignSelf: 'center' }}>Cancellation</h4>
                </Grid>
                <Grid
                  item
                  xs={3}
                  className={classes.time_box}
                  style={{ marginTop: '0px', paddingRight: '15px' }}
                >
                  <InputLabel
                    htmlFor="age-required"
                    className={classes.cutom_select_Label}
                    style={{ margin: '0px 0' }}
                  >
                    &nbsp;
                  </InputLabel>
                  <FormControl
                    control="input"
                    label=""
                    value={res_cancel_time}
                    placeholder="Reservation Cancel Time"
                    required
                    onChange={(e) =>
                      handleNonEditor({
                        type: reducerType.reservation,
                        subType: reducerType.resCancelTime,
                        payload: validateInt(e.target.value)
                      })
                    }
                    style={{
                      margin: '0 0'
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={3}
                  className={classes.time_box}
                  style={{ marginTop: '0px', paddingRight: '15px' }}
                >
                  <InputLabel
                    htmlFor="age-required"
                    className={classes.cutom_select_Label}
                    style={{ margin: '3px 0' }}
                  >
                    &nbsp;
                  </InputLabel>
                  <CustomAutoComplete
                    holder="Select Hours"
                    Options="name"
                    value={getSetHour}
                    data={res_cancel_unit}
                    Change={(val) =>
                      handleNonEditor({
                        type: reducerType.reservation,
                        subType: reducerType.resCancelUnit,
                        payload: val
                      })
                    }
                    style={{ marginbottom: '0' }}
                  />
                </Grid>
                <Grid
                  item
                  xs={3}
                  className={classes.time_box}
                  style={{
                    marginTop: '0px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignSelf: 'center'
                  }}
                >
                  <h4 style={{ marginBottom: '0px' }}>
                    prior to class are marked as Late Cancellation
                  </h4>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
        <div className={classes.hr} />

        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={0}
          style={{ margin: '20px 0 20px' }}
        >
          <Grid item xs={9}>
            <h3 style={{ margin: '0 0' }}>Default Sign In Settings</h3>
            <p className={classes.ptext}>
              Sets default for new reservation-enabled classes you create in the
              future
            </p>
          </Grid>

          {!EditSignin ? (
            <Grid item xs={3} className={classes.btnBlock}>
              <JBButton
                type="outline"
                isImg={edit}
                style={{ float: 'right', padding: '11px 15px' }}
                onClick={() => setEditSignin(true)}
              >
                Edit
              </JBButton>
            </Grid>
          ) : (
            <Grid item xs={3} className={classes.btnBlock}>
              <Button
                onClick={() => setEditSignin(false)}
                style={{ float: 'right' }}
              >
                <img alt="img" src={Closeicon} />
              </Button>
            </Grid>
          )}
          {!EditSignin ? null : (
            <Grid item xs={12}>
              <Grid
                container
                spacing={0}
                xs={12}
                disableGutters
                direction="row"
                style={{
                  margin: '15px 0'
                }}
              >
                <Grid
                  item
                  xs={2}
                  style={{
                    marginTop: '0px',
                    paddingRight: '10px',
                    display: 'flex'
                  }}
                >
                  <h4 style={{ alignSelf: 'center' }}>Sign In Start </h4>
                </Grid>
                <Grid
                  item
                  xs={3}
                  className={classes.time_box}
                  style={{ marginTop: '0px', paddingRight: '15px' }}
                >
                  <InputLabel
                    htmlFor="age-required"
                    className={classes.cutom_select_Label}
                    style={{ margin: '0px 0' }}
                  >
                    &nbsp;
                  </InputLabel>
                  <FormControl
                    control="input"
                    label=""
                    placeholder="Reservation Open"
                    value={login_open_time}
                    required
                    onChange={(e) =>
                      handleNonEditor({
                        type: reducerType.reservation,
                        subType: reducerType.loginOpenTime,
                        payload: validateInt(e.target.value)
                      })
                    }
                    style={{
                      margin: '0 0'
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={3}
                  className={classes.time_box}
                  style={{ marginTop: '0px', paddingRight: '15px' }}
                >
                  <InputLabel
                    htmlFor="age-required"
                    className={classes.cutom_select_Label}
                    style={{ margin: '3px 0' }}
                  >
                    &nbsp;
                  </InputLabel>
                  <CustomAutoComplete
                    holder="Select Hours"
                    value={getSetHour}
                    Options="name"
                    data={login_open_unit}
                    Change={(val) =>
                      handleNonEditor({
                        type: reducerType.reservation,
                        subType: reducerType.loginOpenUnit,
                        payload: val
                      })
                    }
                    style={{ marginbottom: '0' }}
                  />
                </Grid>
                <Grid
                  item
                  xs={3}
                  className={classes.time_box}
                  style={{
                    marginTop: '0px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignSelf: 'center'
                  }}
                >
                  <h4 style={{ marginBottom: '0px' }}>prior to start time</h4>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
        <div className={classes.hr} />
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={0}
          style={{ margin: '20px 0 20px' }}
        >
          <Grid item xs={9} lg={8}>
            <h3 style={{ margin: '0 0' }}>Late Cancellation Settings</h3>
            <p className={classes.ptext}>
              Determine if Late Cancellation count against a members attendance
              limit.
            </p>
          </Grid>
          <Grid item xs={3} lg={4} style={{ textAlign: 'right' }}>
            {RadioLoader ? (
              <>
                <Skeleton />
                <Skeleton />
              </>
            ) : (
              <>
                <InputLabel
                  htmlFor="age-required"
                  className={classes.cutom_Switch_Label}
                >
                  Count against attendance limit
                </InputLabel>
                <FormControlLabel
                  control={
                    <IOSSwitch
                      checked={late_cancel_settings === 1}
                      onChange={() =>
                        handleNonEditor({
                          type: reducerType.lateCancel,
                          payload: late_cancel_settings === 1 ? 0 : 1
                        })
                      }
                    />
                  }
                  style={{ display: 'inline-block', marginRight: '0' }}
                />
              </>
            )}
          </Grid>
        </Grid>
        <div className={classes.hr} />
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={0}
          style={{ margin: '20px 0 20px' }}
        >
          <Grid item xs={9}>
            <h3 style={{ margin: '0 0' }}>Late Cancellation Email Template</h3>
            <p className={classes.ptext}>
              Setup your email to Late Cancellation members.
            </p>
          </Grid>
          {!EditEmail ? (
            <Grid item xs={3} className={classes.btnBlock}>
              <JBButton
                type="outline"
                isImg={edit}
                style={{ float: 'right', padding: '11px 15px' }}
                onClick={() => setEditEmail(true)}
              >
                Edit
              </JBButton>
            </Grid>
          ) : (
            <Grid item xs={3} className={classes.btnBlock}>
              <Button
                onClick={() => setEditEmail(false)}
                style={{ float: 'right' }}
              >
                <img alt="img" src={Closeicon} />
              </Button>
            </Grid>
          )}
          {!EditEmail ? null : (
            <Grid
              container
              spacing={0}
              xs={12}
              disableGutters
              direction="row"
              style={{
                margin: '15px 0'
              }}
            >
              <Grid
                item
                xs={12}
                style={{
                  padding: '10px 0'
                }}
              >
                <FormControlLabel
                  control={
                    <IOSSwitch
                      checked={late_cancel_email_template.visibility === 1}
                      onChange={() =>
                        handleNonEditor({
                          type: reducerType.lateEmail,
                          subType: reducerType.enableEmail,
                          payload:
                            late_cancel_email_template.visibility === 1 ? 0 : 1
                        })
                      } // eslint-disable-line
                    />
                  }
                  style={{ display: 'inline-block', margin: '0 0' }}
                />
                <InputLabel
                  htmlFor="age-required"
                  className={classes.cutom_Switch_Label}
                >
                  Enable this email template
                </InputLabel>
              </Grid>
              <Grid
                item
                xs={12}
                className={classes.time_box}
                style={{ marginTop: '0px' }}
              >
                <FormControl
                  control="input"
                  label="Subject"
                  placeholder="Select the Subject"
                  value={late_cancel_email_template.subject}
                  onChange={(e) => {
                    handleEditor({
                      type: reducerType.lateEmail,
                      subType: reducerType.emailSub,
                      payload: e.target.value
                    });
                  }} // eslint-disable-line
                  style={{
                    margin: '0 0'
                  }}
                />
              </Grid>
              <Grid item xs style={{ marginTop: '15px' }}>
                <JBCKEditor
                  data={late_cancel_email_template.text}
                  onChange={(e) => {
                    handleEditor({
                      type: reducerType.lateEmail,
                      subType: reducerType.emailText,
                      payload: e.editor.getData()
                    });
                  }}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
        <div className={classes.hr} />
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={0}
          style={{ margin: '20px 0 20px' }}
        >
          <Grid item xs={9} lg={8}>
            <h3 style={{ margin: '0 0' }}>No Show Settings</h3>
            <p className={classes.ptext}>
              Determine if No Shows against a members attendance limit.
            </p>
          </Grid>

          <Grid item xs={3} lg={4} style={{ textAlign: 'right' }}>
            {RadioLoader ? (
              <>
                <Skeleton /> <Skeleton />
              </>
            ) : (
              <>
                <InputLabel
                  htmlFor="age-required"
                  className={classes.cutom_Switch_Label}
                >
                  Count against attendance limit
                </InputLabel>
                <FormControlLabel
                  control={
                    <IOSSwitch
                      checked={no_show_settings === 1}
                      onChange={() =>
                        handleNonEditor({
                          type: reducerType.noShow,
                          payload: no_show_settings === 1 ? 0 : 1
                        })
                      }
                    />
                  }
                  style={{ display: 'inline-block', marginRight: '0' }}
                />
              </>
            )}
          </Grid>
        </Grid>
        <div className={classes.hr} />
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={0}
          style={{ margin: '20px 0 20px' }}
        >
          <Grid item xs={9}>
            <h3 style={{ margin: '0 0' }}>No Show Email Template</h3>
            <p className={classes.ptext}>
              Setup your email to No Show members.
            </p>
          </Grid>
          {!EditNoShowEmail ? (
            <Grid item xs={3} className={classes.btnBlock}>
              <JBButton
                type="outline"
                isImg={edit}
                style={{ float: 'right', padding: '11px 15px' }}
                onClick={() => setEditNoShowEmail(true)}
              >
                Edit
              </JBButton>
            </Grid>
          ) : (
            <Grid item xs={3} className={classes.btnBlock}>
              <Button
                onClick={() => setEditNoShowEmail(false)}
                style={{ float: 'right' }}
              >
                <img alt="img" src={Closeicon} />
              </Button>
            </Grid>
          )}
          {!EditNoShowEmail ? null : (
            <Grid
              container
              spacing={0}
              xs={12}
              disableGutters
              direction="row"
              style={{
                margin: '15px 0'
              }}
            >
              <Grid
                item
                xs={12}
                style={{
                  padding: '10px 0'
                }}
              >
                <FormControlLabel
                  control={
                    <IOSSwitch
                      checked={no_show_email_template.visibility === 1}
                      onChange={() =>
                        handleNonEditor({
                          type: reducerType.noShowEmail,
                          subType: reducerType.enableEmail,
                          payload:
                            no_show_email_template.visibility === 1 ? 0 : 1
                        })
                      } // eslint-disable-line
                    />
                  }
                  style={{ display: 'inline-block', margin: '0 0' }}
                />
                <InputLabel
                  htmlFor="age-required"
                  className={classes.cutom_Switch_Label}
                >
                  Enable this email template
                </InputLabel>
              </Grid>
              <Grid
                item
                xs={12}
                className={classes.time_box}
                style={{ marginTop: '0px' }}
              >
                <FormControl
                  control="input"
                  label="Subject"
                  placeholder="Select Subject"
                  value={no_show_email_template.subject}
                  onChange={(e) => {
                    handleEditor({
                      type: reducerType.noShowEmail,
                      subType: reducerType.emailSub,
                      payload: e.target.value
                    });
                  }} // eslint-disable-line
                  style={{
                    margin: '0 0'
                  }}
                />
              </Grid>
              <Grid item xs style={{ marginTop: '15px' }}>
                <JBCKEditor
                  data={no_show_email_template.text}
                  onChange={(e) => {
                    handleEditor({
                      type: reducerType.noShowEmail,
                      subType: reducerType.emailText,
                      payload: e.editor.getData()
                    });
                  }}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
        <div className={classes.hr} />
      </Card>
    </div>
  );
};

SettingClassTab.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SettingClassTab);
