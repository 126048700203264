import { ROUTES } from 'src/constant/Constants';

// eslint-disable-next-line
export const getActiveVal = (pathname) => {
  switch (pathname) {
    case ROUTES.FINANCIAL_SETTINGS:
      return 0;
    case ROUTES.FINANCIAL_SETTINGS_REVENUECATEGORY:
      return 1;
    default:
      return 0;
  }
};
export const InvoiceAutoBill = [
  { id: 1, value: '1 time' },
  { id: 2, value: '2 times' },
  { id: 3, value: '3 times' },
  { id: 4, value: '4 times' },
  { id: 5, value: '5 times' }
];

export const Memberinvoicedate = [
  { id: 1, value: 'First of the Month' },
  { id: 2, value: '15th of the Month' },
  { id: 3, value: 'Last of the Month' },
  { id: 4, value: 'Membership Start Date' }
];
export const getCurrencyVal = (value, currency, OrCurrency) => {
  const curr = currency?.currency_symbol;
  const result = curr
    + parseFloat(value)
      .toFixed(currency?.decimals)
      .replace(OrCurrency?.decimal_symbol, currency?.decimal_symbol)
      .replace(/\B(?=(\d{3})+(?!\d))/g, currency?.group_seperator);
  return result;
};
export const getInvoiceData = (data) => {
  return {
    member_invoice_date: data.member_invoice_date.value,
    invoice_auto_bill: data.invoice_auto_bill.id,
    currency_config: data.currency_config,
    invoice_footer: data.invoice_footer
  };
};
