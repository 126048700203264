/* eslint-disable indent */
import moment from 'moment';
import {
  SessionDay,
  AllDayType,
  AllAttendanceLmt,
  AllSessionlmt
} from 'src/views/memberships/MembershipTemplate/Membership.data';
import {
  getpaymentvalue,
  getrenpaymentvalue
} from './AllTable/FinalPrice.utils';

export const updateplan = (data) => {
  let b = {
    ...data,
    initial_commitment: {
      ...data.initial_commitment,
      billed_every: {
        id: data.initial_commitment.billed_every,
        name: String(data.initial_commitment.billed_every)
      }
    },
    auto_renew_meta: {
      billed_every: null,
      renewal: null
    },
    membership: {
      ...data.membership,
      attendance_limit:
        AllAttendanceLmt.filter(
          (res) => res.id === data.membership.attendance_limit
        )[0] || null,
      attendance_limited_meta: data.membership.attendance_limit
        ? {
            uses: data.membership.attendance_limited_meta.uses,
            type:
              AllDayType.filter(
                (res) => res.id === data.membership.attendance_limited_meta.type
              )[0] || null
          }
        : {}
    }
  };
  if (data.auto_renew) {
    b = {
      ...b,
      auto_renew: true,
      auto_renew_meta: {
        renewal: data.auto_renew_meta.renewal,
        billed_every: {
          id: data.auto_renew_meta.billed_every,
          name: String(data.auto_renew_meta.billed_every)
        }
      }
    };
  }
  return b;
};

export const updatesession = (data) => {
  const b = {
    ...data,
    membership: {
      ...data.membership,
      attendance_limit:
        AllSessionlmt.filter(
          (res) => res.id === data.membership.attendance_limit
        )[0] || null,
      attendance_limited_meta: data.membership.attendance_limit
        ? {
            uses: data.membership.attendance_limited_meta.uses,
            type:
              SessionDay.filter(
                (res) => res.id === data.membership.attendance_limited_meta.type
              )[0] || null
          }
        : {}
    }
  };
  return b;
};

export const sendcreatemem = (data) => {
  const initialval = getpaymentvalue(data);
  let b = {
    membership_id: data.membership_id,
    membership_contract: data.membership_contract,
    membership_payment_id: data.membership_payment_id,
    contract_id: data.payment_data.membership.contract_id,
    type: data.type,
    start_date: moment(data.start_date).format('YYYY/MM/DD'),
    attendance_limit: data.payment_data.membership.attendance_limit.id,
    initial_pricing: data.initial_pricing,
    discount: JSON.stringify(data.discount),
    auto_renew: data.payment_data.auto_renew ? 1 : 0,
    programe_access: data.program_access.join(','),
    member_plan: JSON.stringify({
      payment_plan: {
        setup_fee: initialval.setup_fee,
        fee: initialval.full_fee
      },
      payment_renewal: {
        fee: 0
      }
    })
  };
  if (data.type === 'plan') {
    b = {
      ...b,
      initial_commitment: data.payment_data.initial_commitment.initial
    };
  }
  if (data.payment_data.membership.attendance_limit.id) {
    b = {
      ...b,
      attendance_limited_meta: JSON.stringify({
        uses: data.payment_data.membership.attendance_limited_meta.uses,
        type: data.payment_data.membership.attendance_limited_meta.type.id
      })
    };
  }
  if (data.initial_pricing === 'weekly') {
    b = {
      ...b,
      initial_billed_every: data.payment_data.initial_commitment.billed_every.id
    };
  }
  if (data.payment_data.auto_renew) {
    const renval = getrenpaymentvalue(data);
    b = {
      ...b,
      renewal_pricing: data.renewal_pricing,
      renewal_commitment: data.payment_data.auto_renew_meta.renewal,
      member_plan: JSON.stringify({
        payment_plan: {
          setup_fee: initialval.setup_fee,
          fee: initialval.full_fee
        },
        payment_renewal: {
          fee: renval.full_fee
        }
      })
    };
    if (data.renewal_pricing && data.renewal_pricing === 'weekly') {
      b = {
        ...b,
        renewal_billed_every: data.payment_data.auto_renew_meta.billed_every.id
      };
    }
  }
  if (data.type === 'session') {
    b = {
      ...b,
      used_session: data.usedsession,
      no_of_session: data.payment_data.membership.no_of_sessions
    };
  }
  return b;
};

export const sendcreatedropinmem = (data) => {
  const initialval = getpaymentvalue(data);
  const b = {
    membership_id: data.membership_id,
    membership_payment_id: data.membership_payment_id,
    type: data.type,
    start_date: moment(data.start_date).format('YYYY/MM/DD'),
    initial_pricing: data.initial_pricing,
    discount: JSON.stringify(data.discount),
    attendance_limit: 0,
    auto_renew: data.payment_data.auto_renew ? 1 : 0,
    programe_access: data.program_access.join(','),
    member_plan: JSON.stringify({
      payment_plan: {
        setup_fee: initialval.setup_fee,
        fee: initialval.full_fee
      },
      payment_renewal: {
        fee: 0
      }
    })
  };
  return b;
};

export const getValidStep1 = (data) => {
  if (!data.membership_id) {
    return true;
  }
  return false;
};

export const getValidStep2 = (data) => {
  if (
    !data.membership_payment_id
    || !data.start_date
    || !data.payment_data?.initial_commitment?.billed_every
    || !data.payment_data?.membership?.attendance_limit
    || !data.payment_data?.initial_commitment?.initial
  ) {
    return true;
  }
  if (data.payment_data.membership.attendance_limit.id) {
    if (
      !data.payment_data.membership.attendance_limited_meta.uses
      || !data.payment_data.membership.attendance_limited_meta.type
    ) {
      return true;
    }
  }
  return false;
};

export const getValidStep3 = (data) => {
  if (!data.initial_pricing) {
    return true;
  }
  if (data.payment_data.auto_renew) {
    if (
      !data.payment_data.auto_renew_meta.renewal
      || !data.payment_data.auto_renew_meta.billed_every
      || !data.renewal_pricing
    ) {
      return true;
    }
  }
  return false;
};

export const getValidStep4 = (data) => {
  if (!data) {
    return true;
  }
  if (data.payment_data.auto_renew === true) {
    if (!data) {
      return true;
    }
  }

  return false;
};

export const getValidStep5 = (data) => {
  if (!data.membership_contract || data.program_access.length === 0) {
    return true;
  }
  return false;
};

export const sessgetValidStep2 = (data) => {
  if (
    !data.membership_payment_id
    || !data.start_date
    || !data.payment_data.membership.no_of_sessions
    || String(data.usedsession).length === 0
    || !data.payment_data.membership.attendance_limit
    || !data.initial_pricing
  ) {
    return true;
  }
  if (data.payment_data.membership.attendance_limit.id) {
    if (
      !data.payment_data.membership.attendance_limited_meta.uses
      || !data.payment_data.membership.attendance_limited_meta.type
    ) {
      return true;
    }
  }

  return false;
};
