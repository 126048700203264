/* eslint-disable operator-linebreak */
/* eslint-disable indent */
/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react';
import PropType from 'prop-types';
import { connect } from 'react-redux';
import queryString from 'query-string';
import filterType from 'src/Redux/Reducer.constants';
import { useLocation, useHistory } from 'react-router-dom';
import {
  Grid,
  Typography,
  Card,
  Paper,
  FormControlLabel,
  InputLabel,
  Radio,
  RadioGroup,
  withStyles,
  Breadcrumbs
} from '@material-ui/core';
import CancelDialog from 'src/views/common/CommonDialogBox/CancelDialog';
import IOSSwitch from 'src/views/common/FormControl/IOSSwitch';
import FormControl from 'src/views/common/FormControl/formControl';
import JBDatePicker from 'src/views/common/FormControl/JBDatePicker';
import { ROUTES } from 'src/constant/Constants';
import LoadingView from 'src/views/common/loading';
import {
  getOneTimeEdit,
  getReccEdit,
  updateReccClass,
  updateSigClass
} from 'src/services/classModule.services';
import JBButton from 'src/views/common/JBButton/JBButton';
import CancelDialogTwo from 'src/views/common/CommonDialogBox/CancelDialogTwo';
import { openNoti } from 'src/Redux/global/global.actions';
import NotFoundView from 'src/views/errors/NotFoundView';
import {
  checkClassName,
  checkEndTime,
  checkStartTime,
  getMinDate,
  getTime
} from 'src/utils/someCommon';
import {
  AllClassdata,
  futureddata,
  reccurdata
} from 'src/Redux/ClassReducer/classReducer.actions';
import useChangeTitle from 'src/views/common/CustomHooks/useTitleChange';
import {
  checkValidNum,
  checkValidString,
  validateInt
} from 'src/utils/validations';
import { getErrorMsg } from 'src/utils/ErrorHandling';
import JBTimePicker from 'src/views/common/FormControl/JBTimePicker';
import { getCalenderData } from 'src/Redux/CalenderReducer/Calender.actions';
import WarningDialog from 'src/views/common/CommonDialogBox/WarningDialogPopup';
import { addHours, addYears } from 'date-fns';
import CustomAutoComplete from '../commonAutocomplete';
import RepeatWeek from './EditRepeatWeek';
import styles from './Class.style';
import './CutomSelect.css';
import WeekButton from './weekButton';
import {
  EndTypeData,
  getRepeatWeek,
  Repeatdata,
  WeekData
} from './createClass.Data';
import DeleteDialog from './DeleteDialog';
import {
  getNonReccEdit,
  getReccurEdit,
  getRepWeek,
  getUpdateClass,
  removeWeekdays
} from './Class.utils';
import { addReccIdFil } from '../Classes/List/ListFilters/classFilters.utils';

const EditClass = (props) => {
  const { classes, allcoaches } = props;
  const location = useLocation();
  const history = useHistory();
  const [data, setData] = useState({});
  const [errorFetch, setErrorFetch] = useState(false);
  const [open, setOpen] = useState(false);
  const [openWarningDialog, setWarningDialog] = useState({
    open: false,
    msg: null
  });
  useChangeTitle('Edit the class');
  const { Classid, RecurrId } = queryString.parse(location.search);
  const recurring = RecurrId ? true : false; // eslint-disable-line

  useEffect(() => {
    if ((Classid || RecurrId) && allcoaches) {
      if (Classid) {
        getOneTimeEdit(Classid)
          .then((res) => setData(getNonReccEdit(res)))
          .catch(() => setErrorFetch(true));
      } else {
        getReccEdit(RecurrId)
          .then((res) => setData(getReccurEdit(res, allcoaches)))
          .catch(() => setErrorFetch(true));
      }
    }
  }, [Classid, RecurrId, allcoaches]);

  const handleSave = () => {
    const {
      OpenNoti,
      getFuture,
      getAllClass,
      getReccClass,
      reloadCalendar
    } = props;
    const values = getUpdateClass(data, Classid);
    if (!values) {
      OpenNoti('Please fill all required fields', 'error');
    } else if (Classid) {
      updateSigClass(values, data.id)
        .then(() => getFuture())
        .then(() => getAllClass())
        .then(() => OpenNoti('Successfully Saved the Class', 'info'))
        .then(() => reloadCalendar())
        .then(() => history.goBack())
        .catch((err) => OpenNoti(getErrorMsg(err), 'error'));
    } else {
      updateReccClass(values, data.id)
        .then(() => getReccClass())
        .then(() => OpenNoti('Successfully saved the classes'))
        .then(() => reloadCalendar())
        .then(() => history.push(ROUTES.CLASSES_RECURRING))
        .catch((err) => {
          if (err?.response?.status === 418) {
            getFuture();
            getAllClass();
            reloadCalendar();
            setWarningDialog(() => ({
              open: true,
              msg: err.response.data.message
            }));
          } else {
            OpenNoti(getErrorMsg(err), 'error');
          }
        });
    }
  };

  const handleShowClass = () => {
    const { getAllClass, getFuture } = props;
    getAllClass(filterType.addcustom, addReccIdFil(RecurrId));
    getFuture(filterType.addcustom, addReccIdFil(RecurrId));
    history.push(ROUTES.CLASSES_LIST_All);
  };

  if (errorFetch || (!Classid && !RecurrId)) return <NotFoundView />;
  if (Object.keys(data).length === 0) return <LoadingView />;

  const isEdible =
    data.start_time.getTime() > new Date().getTime() && data.status === 1;

  return (
    <div className={classes.root}>
      <div className="title-bk">
        <Breadcrumbs aria-label="breadcrumb" style={{ marginBottom: '21px' }}>
          <div
            color="inherit"
            onClick={() => setOpen(true)}
            style={{
              cursor: 'pointer'
            }}
          >
            Classes
          </div>
          <Typography variant="h3" color="Primary">
            <span>Calendar</span>
          </Typography>
        </Breadcrumbs>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={0}
          style={{ marginBottom: '15px' }}
        >
          <Grid item xs={6}>
            <h1 style={{ marginBottom: 0 }}>Edit Class</h1>
          </Grid>
          <Grid
            item
            xs={6}
            alignItems="center"
            style={{
              justifyContent: 'flex-end',
              display: 'flex'
            }}
          >
            {RecurrId && (
              <JBButton
                type="outlined"
                style={{ marginLeft: '15px' }}
                onClick={handleShowClass}
              >
                Show Classes
              </JBButton>
            )}
            <DeleteDialog
              recurr={RecurrId}
              id={data.id}
              openWarnigDialog={(msg) => {
                const {
                  getFuture,
                  getAllClass,
                  getReccClass,
                  reloadCalendar
                } = props;
                getFuture();
                getAllClass();
                getReccClass();
                reloadCalendar();
                setWarningDialog(() => ({ open: true, msg }));
              }}
            />
          </Grid>
        </Grid>
      </div>
      <Card className={classes.mainCard}>
        <h3 style={{ marginBottom: '10px' }}>
          Specify the name, location, program and start/end times for the class
        </h3>
        <Grid
          item
          xs={12}
          className="switch-btn"
          style={{ marginBottom: '0px' }}
        >
          <FormControlLabel
            control={<IOSSwitch checked={recurring} disabled />}
            label="Recurring/Repeating class"
          />
        </Grid>
        <Grid container spacing={0} direction="column">
          <Grid item xs={4}>
            <InputLabel
              htmlFor="age-required"
              className={classes.cutom_select_Label}
            >
              Program
            </InputLabel>
            <Typography variant="h6" gutterBottom>
              {data.program}
            </Typography>
          </Grid>
          {!recurring && (
            <Grid item xs={4} style={{ marginTop: '0px' }} spacing={1}>
              <JBDatePicker
                label="Start date"
                minDate={isEdible && new Date()}
                disabled={!isEdible}
                value={data.start_time}
                onChange={(val) =>
                  setData({
                    ...data,
                    start_time: val,
                    end_time: addHours(val, 1)
                  })
                } // eslint-disable-line
              />
            </Grid>
          )}

          <Grid
            container
            spacing={0}
            xs={4}
            disableGutters
            direction="row"
            style={{ marginTop: '0px' }}
          >
            <Grid
              item
              xs
              className={classes.time_box}
              style={{ marginTop: '15px' }}
            >
              <JBTimePicker
                label="Start time"
                error={
                  checkStartTime(data.start_time) && (isEdible || recurring)
                }
                disabled={!isEdible && !recurring}
                value={data.start_time}
                onChange={(val) => {
                  if (checkClassName(data.name, data.program)) {
                    setData({
                      ...data,
                      start_time: val,
                      end_time: addHours(val, 1),
                      name: data.program + ' ' + getTime(val)
                    });
                  } else {
                    setData({
                      ...data,
                      start_time: val,
                      end_time: addHours(val, 1)
                    });
                  }
                }}
              />
              {checkStartTime(data.start_time) && (isEdible || recurring) ? (
                <p
                  className="Mui-error"
                  style={{
                    fontSize: '0.75rem',
                    marginTop: '3px',
                    textAlign: 'left',
                    fontFamily: 'Lato',
                    fontWeight: '400',
                    lineHeight: '1.66',
                    color: '#f44336'
                  }}
                >
                  Can't create the Class of past date
                </p>
              ) : null}
            </Grid>
          </Grid>

          <Grid
            container
            spacing={0}
            xs={4}
            disableGutters
            direction="row"
            className="stap-3"
          >
            <Grid
              item
              xs
              className={classes.time_box}
              style={{ marginTop: '6px' }}
            >
              <JBTimePicker
                label="End time"
                error={
                  checkEndTime(data.start_time, data.end_time) &&
                  (isEdible || recurring)
                }
                value={data.end_time}
                disabled={!isEdible && !recurring}
                onChange={(val) => setData({ ...data, end_time: val })}
              />
              {checkEndTime(data.start_time, data.end_time) &&
              (isEdible || recurring) ? (
                <p
                  className="Mui-error"
                  style={{
                    fontSize: '0.75rem',
                    marginTop: '3px',
                    textAlign: 'left',
                    fontFamily: 'Lato',
                    fontWeight: '400',
                    lineHeight: '1.66',
                    color: '#f44336'
                  }}
                >
                  End Time cannot equal or less than Start Time
                </p>
              ) : null}
            </Grid>
          </Grid>

          {recurring && (
            <Grid style={{ marginTop: '0' }}>
              <Grid
                item
                xs={4}
                style={{
                  marginTop: '15px',
                  marginRight: '10px',
                  float: 'none'
                }}
              >
                <JBDatePicker
                  label="Start date"
                  value={data.start_date}
                  disabled
                  onChange={(val) =>
                    setData({
                      ...data,
                      start_date: val,
                      end_date: getMinDate(val)
                    })
                  } // eslint-disable-line
                />
              </Grid>
              <Grid xs={12} style={{ marginTop: '0', display: 'flex' }}>
                <Grid
                  item
                  xs={4}
                  style={{ marginTop: '12px', marginRight: '10px' }}
                >
                  <InputLabel
                    htmlFor="age-required"
                    className={classes.cutom_select_Label}
                    // style={{ padding: '0 0' }}
                  >
                    End Date
                  </InputLabel>
                  <CustomAutoComplete
                    holder="end type"
                    Options="name"
                    data={data.end_type}
                    value={EndTypeData}
                    onChange={(_, val) => setData({ ...data, end_type: val })} // eslint-disable-line
                  />
                </Grid>
                {data.end_type && data.end_type.id === 'ondate' ? (
                  <Grid
                    item
                    xs={4}
                    style={{ marginTop: '15px', marginRight: '0px' }}
                  >
                    <JBDatePicker
                      label=""
                      minDate={new Date()}
                      maxDate={addYears(new Date(), 1)}
                      maxDateMessage="Invalid Date cannot greater than 1 year"
                      value={data.end_date}
                      onChange={(val) => setData({ ...data, end_date: val })}
                    />
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
          )}

          <Grid
            item
            xs={12}
            className="switch-btn"
            style={{ marginTop: '10px', marginBottom: '15px' }}
          >
            <FormControlLabel
              control={
                <IOSSwitch
                  checked={data.attendence_limit}
                  disabled={!isEdible && !recurring}
                  onChange={(e) =>
                    setData({ ...data, attendence_limit: e.target.checked })
                  } // eslint-disable-line
                  name="checkedB"
                />
              }
              label="Count towards attendance limits"
            />
          </Grid>
          <Grid item lg={10} xl={8} style={{ padding: 0, margin: '0px' }}>
            <FormControl
              control="input"
              label="Class name"
              placeholder="Create class name"
              disabled={!isEdible && !recurring}
              value={data.name}
              required
              onChange={(e) => setData({ ...data, name: e.target.value })}
              seterrors={checkValidString(data.name) || data.name.length === 0}
              seterrortext="Please provide a valid class name"
            />
          </Grid>
        </Grid>

        {recurring && (
          <div>
            <Grid item xs={12} style={{ marginTop: '30px' }}>
              <h3 style={{ marginBottom: '10px' }}>
                Assign a period for this repetition
              </h3>
            </Grid>

            <Grid
              xs={12}
              container
              direction="row"
              alignItems="center"
              style={{ marginTop: '0', display: 'flex' }}
            >
              <Grid
                item
                xs={4}
                style={{ marginTop: '0px', marginRight: '10px' }}
              >
                <InputLabel
                  htmlFor="age-required"
                  className={classes.cutom_select_Label}
                  required
                >
                  Repeat
                </InputLabel>
                <CustomAutoComplete
                  holder="Repeat Type"
                  data={data.repeat_type}
                  Options="name"
                  value={Repeatdata}
                  Change={(val) => setData({ ...data, repeat_type: val })}
                />
              </Grid>
              {data.repeat_type && data.repeat_type.id === 'custom' ? (
                <Grid
                  item
                  xs={3}
                  style={{
                    marginTop: '0px',
                    marginRight: '10px',
                    display: 'inline-flex',
                    alignItems: 'center'
                  }}
                >
                  <Paper
                    elevation={0}
                    style={{ width: '280px', maxWidth: '280px' }}
                  >
                    <InputLabel
                      htmlFor="age-required"
                      className={classes.cutom_select_Label}
                    >
                      &nbsp;
                    </InputLabel>
                    <p
                      style={{
                        textAlign: 'center',
                        fontWeight: 'bold',
                        padding: '0 10px'
                      }}
                    >
                      Repetition period
                    </p>
                  </Paper>

                  <Grid item xs={8} style={{ marginTop: '0px' }}>
                    <InputLabel
                      htmlFor="age-required"
                      className={classes.cutom_select_Label}
                    >
                      &nbsp;
                    </InputLabel>
                    <CustomAutoComplete
                      holder="repetition_period"
                      data={data.repetition_period}
                      Options="name"
                      value={getRepeatWeek()}
                      Change={(val) =>
                        setData({ ...data, repetition_period: val })
                      } // eslint-disable-line
                    />
                  </Grid>
                </Grid>
              ) : null}
              <Paper elevation={0}>
                <InputLabel
                  htmlFor="age-required"
                  className={classes.cutom_select_Label}
                >
                  &nbsp;
                </InputLabel>
                <p
                  style={{
                    textAlign: 'center',
                    fontWeight: 'bold',
                    padding: '0 10px'
                  }}
                >
                  Week(s)
                </p>
              </Paper>
            </Grid>
            <Grid item xs={12} style={{ marginTop: '30px' }}>
              <h3 style={{ marginBottom: '10px' }}>
                Choose days, assign a class limit and coaches, and enable a
                waitlist for the recurring class
              </h3>
            </Grid>
            {/* <Grid container xs={12} style={{ marginTop: '20px' }}>
              <div> Weeks</div>
            </Grid> */}
            <Grid container xs={12} style={{ marginTop: '20px' }}>
              {WeekData.map((weeks) => (
                <WeekButton
                  key={weeks.id}
                  label={weeks}
                  data={data.day_of_week.filter((list) => list.id === weeks.id)}
                  setWeeks={(val) => {
                    // eslint-disable-next-line
                    if (val)
                      // eslint-disable-next-line
                      setData({
                        ...data,
                        day_of_week: [...data.day_of_week, val]
                      });
                    else {
                      setData({
                        ...data,
                        day_of_week: data.day_of_week.filter(
                          (list) => list.id !== weeks.id
                        ),
                        meta: removeWeekdays(data.meta, weeks)
                      });
                    }
                  }}
                />
              ))}
              {data.day_of_week.map((row, i) => (
                <RepeatWeek
                  key={row.id}
                  dataweek={row}
                  weekdata={data.meta[i]}
                  getRepeatWeek={(val) => {
                    setData({
                      ...data,
                      meta: getRepWeek(data.meta, val)
                    });
                  }}
                />
              ))}
            </Grid>
          </div>
        )}

        {!recurring && (
          <div>
            <Grid item xs={12} style={{ marginTop: '30px' }}>
              <h3 style={{ marginBottom: '10px' }}>
                Specify reservation settings and coaches for the class
              </h3>
            </Grid>
            <Grid
              item
              xs={12}
              className="switch-btn"
              style={{ marginBottom: '15px' }}
            >
              <FormControlLabel
                control={
                  <IOSSwitch
                    checked={data.is_limit}
                    disabled={!isEdible}
                    onChange={(e) =>
                      setData({ ...data, is_limit: e.target.checked })
                    } // eslint-disable-line
                  />
                }
                label="Class limit/Reservation"
              />
            </Grid>

            {data.is_limit && (
              <Grid xs={12} style={{ marginTop: '0' }}>
                <Grid item xs={4} style={{ padding: 0, margin: '0px' }}>
                  <FormControl
                    control="input"
                    disabled={!isEdible}
                    value={data.class_limit}
                    onChange={(e) =>
                      setData({
                        ...data,
                        class_limit: validateInt(e.target.value)
                      })
                    } // eslint-disable-line
                    label="Class limit"
                    required
                    seterrors={checkValidNum(data.class_limit)}
                    seterrortext="Please provide valid Class Limit"
                    placeholder="Specify max amount of members"
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  className="switch-btn"
                  style={{
                    display: 'flex',
                    margin: '15px 0'
                  }}
                >
                  <FormControlLabel
                    control={
                      <IOSSwitch
                        checked={data.allow_waitlist}
                        disabled={!isEdible}
                        onChange={(e) =>
                          setData({
                            ...data,
                            allow_waitlist: e.target.checked
                          })
                        } // eslint-disable-line
                        name="checkedB"
                      />
                    }
                    label="Allow waitlist"
                  />
                </Grid>
                {data.allow_waitlist && (
                  <div>
                    <Grid
                      item
                      xs={12}
                      style={{
                        display: 'flex',
                        margin: '10px 0'
                      }}
                    >
                      <Paper elevation={0}>
                        <p className={classes.ptext}>
                          When a spot in the class opens, people on the waitlist
                          are:
                        </p>
                      </Paper>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      style={{
                        display: 'flex',
                        margin: '10px 0'
                      }}
                    >
                      <RadioGroup
                        aria-label="quiz"
                        name="quiz"
                        value={data.class_open}
                        onChange={(e) =>
                          setData({ ...data, class_open: e.target.value })
                        } // eslint-disable-line
                      >
                        <FormControlLabel
                          disabled={!isEdible}
                          value="1"
                          control={<Radio color="primary" />}
                          label="All emailed, first to reply gets added to the class."
                        />
                        <FormControlLabel
                          disabled={!isEdible}
                          value="0"
                          control={<Radio color="primary" />}
                          label="Automatically added to the class in the order they signed up."
                        />
                      </RadioGroup>
                    </Grid>
                  </div>
                )}
              </Grid>
            )}
            <Grid xs={12} style={{ marginTop: '0', display: 'flex' }}>
              <Grid
                item
                xs={4}
                style={{ marginTop: '0px', marginRight: '10px' }}
              >
                <InputLabel
                  htmlFor="age-required"
                  className={classes.cutom_select_Label}
                >
                  Head coach
                </InputLabel>
                <CustomAutoComplete
                  holder="Head Coach"
                  data={data.head_coach}
                  disabled={!isEdible}
                  value={
                    data.co_coach
                      ? allcoaches.filter(
                          (list) => list.id !== data.co_coach.id // eslint-disable-line
                        ) // eslint-disable-line
                      : allcoaches
                  }
                  Options="name"
                  Change={(val) => setData({ ...data, head_coach: val })}
                  seterrors={!data.head_coach}
                  seterrortext="Please Select the Head Coach"
                />
              </Grid>
              <Grid item xs={4} style={{ marginTop: '0px' }}>
                <InputLabel
                  htmlFor="age-required"
                  className={classes.cutom_select_Label}
                >
                  Co-Coach
                </InputLabel>
                <CustomAutoComplete
                  holder="Select Co-Coach"
                  disabled={!isEdible}
                  Options="name"
                  value={
                    data.head_coach
                      ? allcoaches.filter(
                          (list) => list.id !== data.head_coach.id // eslint-disable-line
                        ) // eslint-disable-line
                      : allcoaches
                  }
                  data={data.co_coach}
                  Change={(val) => setData({ ...data, co_coach: val })}
                />
              </Grid>
            </Grid>
          </div>
        )}

        <Grid
          item
          xs={12}
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            margin: '30px 0 0'
          }}
        >
          <CancelDialog />
          {isEdible || recurring ? (
            <JBButton
              type="primary"
              onClick={handleSave}
              style={{ marginLeft: '15px' }}
            >
              Save Class
            </JBButton>
          ) : null}
        </Grid>
        {openWarningDialog.open && (
          <WarningDialog
            open={openWarningDialog.open}
            dialogStatus="WARNING"
            dialogerrmsg={openWarningDialog.msg}
            handleOk={() => {
              setWarningDialog(() => ({ open: false, msg: null }));
              history.goBack();
            }}
          />
        )}
      </Card>
      <CancelDialogTwo setOpen={open} handleClose={() => setOpen(false)} />
    </div>
  );
};

EditClass.propTypes = {
  classes: PropType.object.isRequired,
  allcoaches: PropType.array.isRequired,
  OpenNoti: PropType.func.isRequired,
  getFuture: PropType.func.isRequired,
  getAllClass: PropType.func.isRequired,
  getReccClass: PropType.func.isRequired,
  reloadCalendar: PropType.func.isRequired
};

const mapToState = (state) => ({
  allcoaches: state.classReducer.coaches
});

const mapToDispatch = (dispatch) => ({
  OpenNoti: (msg, type) => dispatch(openNoti(msg, type)),
  getFuture: (filter, type) => dispatch(futureddata(filter, type)),
  reloadCalendar: () => dispatch(getCalenderData()),
  getAllClass: (filter, type) => dispatch(AllClassdata(filter, type)),
  getReccClass: () => dispatch(reccurdata())
});

export default connect(
  mapToState,
  mapToDispatch
)(withStyles(styles)(EditClass));
