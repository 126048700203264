const styles = () => ({
  warningInfo: {
    textAlign: 'center',
    '& p': {
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '22px',
      '& span': {
        color: 'red',
        display: 'block',
        textAlign: 'center'
      }
    }
  },
  timeinput: {
    border: '1px solid rgb(169, 169, 169)',
    padding: '15px 10px',
    width: '100%',
    outline: '0',
    cursor: 'pointer'
  },
  Dateinput: {
    border: '0',
    width: '100%',
    outline: '0',
    color: '#000',
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '18px',
    cursor: 'pointer'
  }
});

export default styles;
