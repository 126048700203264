import React, { useContext } from 'react';
import { connect } from 'react-redux';
import { Grid, withStyles } from '@material-ui/core';
import PropType from 'prop-types';
import { MembershipTemplate as MembershipContext } from 'src/Context/MembershipTemplate/MembershipTemplateContext';
import { SessionTemplateContext } from 'src/Context/MembershipTemplate/SessionTemplate.Context';
import { changeprogramaccess } from 'src/Context/MembershipTemplate/membershiptemplate.actions';
import { openNoti } from 'src/Redux/global/global.actions';
import CheckBoxTable from '../../../common/CheckBoxTable/checkBoxTable';
import { DummyDataCB } from '../staticData';
import '../CutomSelect.css';
// import styles from '../MembershipTemplate.style';

const styles = () => ({
  checkBoxTable: {
    '& thead th': {
      padding: '7px 5px'
    },
    '& thead th:nth-child(2)': {
      paddingLeft: '15px'
    },
    '& thead th.MuiTableCell-head': {
      backgroundColor: '#F9F9FA'
    }
  }
});

const ProgramAccessStep = ({ programData, classes, session }) => {
  const [state, dispatch] = useContext(
    !session ? MembershipContext : SessionTemplateContext
  );

  return (
    <div>
      <h3 style={{ marginBottom: '30px' }}>
        Select the programs included with this membership
      </h3>
      <Grid
        xs={12}
        style={{
          marginTop: '0',
          display: 'inline-flex',
          width: '100%',
          marginBottom: programData.length === 0 ? null : '30px'
        }}
      >
        <Grid
          item
          xs
          style={{ marginTop: '0px' }}
          className={classes.checkBoxTable}
        >
          <CheckBoxTable
            values={programData}
            headNames={DummyDataCB}
            initialSelected={state?.program_ids}
            getSelected={(val) => dispatch(changeprogramaccess(val))}
          />
        </Grid>
      </Grid>
      {programData.length === 0 && (
        <p
          style={{
            margin: '-10px 0 0 0'
          }}
        >
          No programs to shows..
        </p>
      )}
    </div>
  );
};

ProgramAccessStep.defaultProps = {
  session: false
};

ProgramAccessStep.propTypes = {
  programData: PropType.array.isRequired,
  classes: PropType.object.isRequired,
  session: PropType.bool
};

const mapToState = (state) => ({
  programData: state.allprograms.programs
});

const mapToDispatch = (dispatch) => ({
  OpenNoti: (msg, type) => dispatch(openNoti(msg, type))
});

export default connect(
  mapToState,
  mapToDispatch
)(withStyles(styles)(ProgramAccessStep));
