// Do not update this text give by BE
const dodydata = `
<p>Hi [memfirstname],

<br/>

<br/>

The JBs embrace digital technologies to make time for analogue activities!

<br/>

<br/>

Please take the time to fill in our Waiver & Pre-Activity Questionnaire form. Please contact any of our coaches to fill and sign the waiver.

<br/>

<br/>

If you have any questions, don't hesitate to reach out to us by replying to this email or calling us at [gymphone]

<br/>

<br/>

[signature]

<br/>

[gymname]

</p>
`;
export const dummyDataNewWaiver = {
  name: null,
  waiver_terms_meta: { terms: '', initial: 1 },
  questionnaire_meta: [],
  // 'test,test1,test2'
  email_template: {
    subject: 'You have a waiver to sign at [gymname]',
    body: dodydata
  },
  is_published: 0
};
export const AllQue = [
  { id: 1, name: 'Yes/No' },
  { id: 2, name: 'Freeform Answer Box' },
  { id: 3, name: 'Multiple Choice' },
  { id: 4, name: 'Yes/No with follow-up' }
];
// const a = {
//   que_id: null,
//   que_type: null,
//   que: null,
//   required: 1,
//   answer_choices: null
// };
