import React from 'react';
import PropType from 'prop-types';
import { withStyles, Card, Grid, Tabs, Tab, AppBar } from '@material-ui/core';

const styles = () => ({
  mainCard: {
    boxShadow: '4px 0px 16px rgba(37, 40, 43, 0.08)',
    borderRadius: '5px',
    background: '#FFFF',
    padding: 30
  },
  p_tabs: {
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px'
  }
});

const Tabulation = (props) => {
  const {
    classes,
    label1,
    label2,
    value,
    Change,
    children,
    label3,
    label4,
    label5,
    label6
  } = props;
  return (
    <Card className={classes.mainCard}>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <AppBar
            position="static"
            style={{
              backgroundColor: 'transparent',
              boxShadow: 'none'
            }}
          >
            <Tabs
              value={value}
              onChange={(_, val) => Change(val)}
              indicatorColor="primary"
              textColor="primary"
              fullWidth
            >
              {label1 && <Tab label={label1} className={classes.p_tabs} />}
              {label2 && <Tab label={label2} className={classes.p_tabs} />}
              {label3 && <Tab label={label3} className={classes.p_tabs} />}
              {label4 && <Tab label={label4} className={classes.p_tabs} />}
              {label5 && <Tab label={label5} className={classes.p_tabs} />}
              {label6 && <Tab label={label6} className={classes.p_tabs} />}
            </Tabs>
          </AppBar>
          {children}
        </Grid>
      </Grid>
    </Card>
  );
};

Tabulation.defaultProps = {
  label1: null,
  label2: null,
  label3: null,
  label4: null,
  label5: null,
  label6: null
};

Tabulation.propTypes = {
  label1: PropType.string,
  label2: PropType.string,
  label3: PropType.string,
  label4: PropType.string,
  label5: PropType.string,
  label6: PropType.string,
  value: PropType.any.isRequired,
  Change: PropType.func.isRequired,
  classes: PropType.object.isRequired,
  children: PropType.any.isRequired
};

export default withStyles(styles)(Tabulation);
