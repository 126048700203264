import React, { useState, useContext, useEffect } from 'react';
import PropType from 'prop-types';
import { VanguardMem } from 'src/Context/VanguardMembershipContext';
import { setMembershipType } from 'src/Context/VanguardMembership/VanguardMembership.actions';
import { getAllVanguardMembership } from 'src/services/Membersip.services';
import LoadingView from 'src/views/common/loading';
import {
  Typography,
  makeStyles,
  Grid,
  Tabs,
  Tab,
  Box
} from '@material-ui/core';
import { getMemberships } from './VanguardMembership.utils';
import VanguardAccordion from './vanguardAccordion';

const useStyles = makeStyles(() => ({
  mainTitle: {
    fontWeight: '500',
    fontSize: '48px',
    lineHeight: '44px',
    color: '#FFFFFF',
    fontFamily: 'DDC Hardware',
    textAlign: 'center',
    margin: '38px 0 0 0',
    '@media (max-width: 992px)': {
      fontSize: '35px'
    }
  },
  p_tabs: {
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px',
    textTransform: 'capitalize',
    minHeight: '30px',
    width: 'calc(100% / 2)',
    letterSpacing: '0.2px',
    color: '#fff',
    '&.Mui-selected': {
      background: '#FFFFFF',
      border: '2px solid #EAEAEA',
      color: '#292929'
    }
  },
  tabSwich: {
    '& .MuiTabs-flexContainer': {
      justifyContent: 'center',
      border: '2px solid #EAEAEA',
      margin: '0 auto',
      borderRadius: '5px',
      width: '407px'
    },
    '& .MuiTabs-indicator': {
      display: 'none'
    }
  }
}));

const SelectWaiver = (props) => {
  const classes = useStyles();
  const { TabValue } = props;
  // eslint-disable-next-line no-unused-vars
  const [state, dispatch] = useContext(VanguardMem);
  const [memberships, setMemberships] = useState(null);
  const [loading, setLoading] = useState(false);
  // const [MembershipTabValue, setMembershipTabValue] = useState(0);
  useEffect(() => {
    setLoading(true);
    getAllVanguardMembership().then((res) => {
      setMemberships(res);
      setLoading(false);
    });
  }, []);
  if (loading) return <LoadingView />;
  return (
    <div>
      <Typography variant="h3" className={classes.mainTitle}>
        SELECT THE PLAN OR SESSION MEMBERSHIP
      </Typography>
      <Grid
        container
        xs={10}
        justify="center"
        style={{
          margin: '45px auto'
        }}
      >
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          // style={{ margin: '20px 0 20px' }}
        >
          <Grid xs={12}>
            <Box>
              <Tabs
                value={TabValue}
                onChange={(_, val) => dispatch(setMembershipType(val))}
                indicatorColor="primary"
                textColor="primary"
                fullWidth
                className={classes.tabSwich}
              >
                <Tab label="Plan" className={classes.p_tabs} />
                <Tab label="Session" className={classes.p_tabs} />
              </Tabs>
              <Box mt="45px">
                {(() => {
                  switch (TabValue) {
                    case 0:
                      return (
                        <VanguardAccordion
                          values={getMemberships(TabValue, memberships)}
                        />
                      );
                    case 1:
                      return (
                        <VanguardAccordion
                          values={getMemberships(TabValue, memberships)}
                        />
                      );
                    default:
                      return null;
                  }
                })()}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

SelectWaiver.propTypes = {
  TabValue: PropType.number.isRequired
  // setOpen: PropType.bool.isRequired,
  // handleClose: PropType.func.isRequired
  // handleBack: PropType.func,
  // handleHistory: PropType.string
};

export default SelectWaiver;
