import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  root: {
    backgroundColor: 'rgba(38, 50, 56, 0.30)'
  }
});

// Renders the amount of Rows with Loading View
const items = [
  { id: 1 },
  { id: 2 },
  { id: 3 },
  { id: 4 },
  { id: 5 },
  { id: 6 },
  { id: 7 },
  { id: 8 },
  { id: 9 }
];

export default function VanguardTextLoadingView(props) {
  const classes = useStyles();
  const { showFullLoading } = props;

  const view = showFullLoading ? (
    <React.Fragment>
      {items.map((item) => (
        <Skeleton key={item.id} width="100%" classes={{ root: classes.root }} />
      ))}
    </React.Fragment>
  ) : (
    <React.Fragment>
      {items.map((item) => (
        <Skeleton key={item.id} width="100%" classes={{ root: classes.root }} />
      ))}
    </React.Fragment>
  );
  return view;
}
