import React, { useEffect, useState, useCallback } from 'react';
import {
  withStyles,
  InputLabel,
  Grid,
  Typography,
  Box,
  ThemeProvider
} from '@material-ui/core';
import PropType from 'prop-types';
import { connect } from 'react-redux';
import { openNoti } from 'src/Redux/global/global.actions';
import { getErrorMsg } from 'src/utils/ErrorHandling';
import TextareaControl from 'src/views/common/FormControl/TextareaControl';
import FormControl from 'src/views/common/FormControl/formControl';
import { updateAffiliateInvoiceSettings } from 'src/services/masterHQ.services';
import { getInvoiceSettings } from 'src/Redux/MasterHQ/FinancialReducer/financial.reducer.actions';
import { getCurrencyVal } from 'src/views/financial/settings/FinancialSettings.utils';
import LoadingView from 'src/views/common/loading';
import _ from 'lodash';
import { validateInt, validPrice } from 'src/utils/validations';
import NotFoundView from 'src/views/errors/NotFoundView';
import useChangeTitle from 'src/views/common/CustomHooks/useTitleChange';
import CustomAutoComplete from '../../common/CustomAutocomplete/commonAutocomplete';
import styles from './settings.style';

const InvoiceAutoBill = [
  { id: 1, value: '1 time' },
  { id: 2, value: '2 times' },
  { id: 3, value: '3 times' },
  { id: 4, value: '4 times' },
  { id: 5, value: '5 times' }
];
const SettingsTab = ({
  classes,
  InvoiceSettings,
  OpenNoti,
  RefreshSettings
}) => {
  const [data, setData] = useState(null);
  useChangeTitle('Settings | Jungle Alliance');
  // const [Taxedata, setTaxedata] = useState(null);

  const [error] = useState(false);
  const ApplyFilterStyle = {
    overrides: {
      MuiSvgIcon: {
        root: {
          color: '#ffffff'
        }
      },
      MuiAutocomplete: {
        root: {
          color: '#ffffff',
          background: 'rgba(255, 255, 255, 0.1)',
          borderRadius: '2px',
          '& .cutom_select div input': {
            color: '#ffffff !important',
            fontWeight: 'bold',
            '&::placeholder': {
              color: '#ffffff !important',
              fontSize: '16px'
            }
          }
        }
      },
      MuiPaper: {
        root: {
          backgroundColor: '#3c3d4f',
          color: '#ffffff'
        }
      }
    }
  };

  const updateApi = useCallback(
    // eslint-disable-next-line no-shadow
    _.debounce((data) => {
      const value = {
        ...data,
        invoice_auto_bill: data?.invoice_auto_bill?.id
      };
      updateAffiliateInvoiceSettings(value)
        .then(() => {
          OpenNoti('Invoice Settings updated successfully');
          RefreshSettings();
        })
        .catch((err) => {
          OpenNoti(getErrorMsg(err), 'error');
        });
    }, 1400),
    []
  );

  useEffect(() => {
    setData({
      tax: InvoiceSettings.data?.tax,
      invoice_auto_bill: InvoiceAutoBill.filter(
        // eslint-disable-next-line eqeqeq
        (i) => i.id == InvoiceSettings.data?.invoice_auto_bill
      )[0],
      invoice_footer: InvoiceSettings.data?.invoice_footer,
      currency_config: InvoiceSettings.data?.currency_config
    });
  }, [InvoiceSettings.data]);

  if (error) return <NotFoundView />;
  if (!data) return <LoadingView />;
  return (
    <Box p="15px">
      <Grid
        container
        direction="row"
        spacing={0}
        className={classes.settingsTab}
      >
        <Grid
          container
          direction="row"
          spacing={0}
          style={{ margin: '10px 0 20px' }}
        >
          <Grid item xs={7}>
            <Box mt="15px" mb="15px">
              <Typography
                color="#000"
                variant="h5"
                component="div"
                className={classes.bktitle}
              >
                Invoice Auto Bill
              </Typography>
              <p className={classes.ptext}>
                Every time an invoice payment fails, we retry to collect
                payment. This process has a payment processor fee associated.
                You can choose how many times to attempt to collect payment.
                Once the number of attempts defined is reached, auto bill is
                automatically set to off.
              </p>
            </Box>
          </Grid>
          <Grid item xs={5}>
            <Box mt="15px" mb="15px" justifyContent="flex-end" display="flex">
              <Grid item xs={10} className={classes.masterhqAutocomplete}>
                <InputLabel
                  htmlFor="age-required"
                  className={classes.cutom_select_Label}
                  style={{ padding: '5px 0' }}
                >
                  Number of Auto-bill payment attemps (credit card)
                </InputLabel>
                <ThemeProvider theme={ApplyFilterStyle}>
                  <CustomAutoComplete
                    holder="3 times"
                    data={data.invoice_auto_bill}
                    value={InvoiceAutoBill}
                    Options="value"
                    Change={(val) => {
                      setData({ ...data, invoice_auto_bill: val });
                      updateApi({ ...data, invoice_auto_bill: val });
                    }}
                  />
                </ThemeProvider>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <div className={classes.hr} />
        <Grid
          container
          direction="row"
          spacing={0}
          style={{ margin: '10px 0 20px' }}
        >
          <Grid item xs={7}>
            <Box mt="15px" mb="5px">
              <Typography
                color="#000"
                variant="h5"
                component="div"
                className={classes.bktitle}
              >
                Invoice Footer
              </Typography>
              <p className={classes.ptext}>
                Add a custom message to your invoice receipts.
              </p>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <TextareaControl
              rows={8}
              label=""
              value={data.invoice_footer}
              onChange={(e) => {
                setData({ ...data, invoice_footer: e.target.value });
                updateApi({ ...data, invoice_footer: e.target.value });
              }}
              className={classes.TextareaControlBox}
            />
          </Grid>
        </Grid>
        <div className={classes.hr} />
        <Grid
          container
          direction="row"
          spacing={0}
          style={{ margin: '10px 0 20px' }}
        >
          <Grid item xs={7}>
            <Box mt="15px" mb="15px">
              <Typography
                color="#000"
                variant="h5"
                component="div"
                className={classes.bktitle}
              >
                Currency Configuration
              </Typography>
              <p className={classes.ptext}>
                Select currency symbol, precision, decimal symbol, and
                separator.
              </p>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Grid container direction="row" spacing={2}>
              <Grid item xs>
                <FormControl
                  control="input"
                  label="Currency symbol"
                  required
                  value={data.currency_config?.currency_symbol}
                  onChange={(val) => {
                    setData({
                      ...data,
                      currency_config: {
                        ...data.currency_config,
                        currency_symbol: val.target.value
                      }
                    });
                    updateApi({
                      ...data,
                      currency_config: {
                        ...data.currency_config,
                        currency_symbol: val.target.value
                      }
                    });
                  }}
                />
              </Grid>
              <Grid item xs>
                <FormControl
                  control="input"
                  label="Digits after the decimal"
                  value={data.currency_config?.decimals}
                  onChange={(val) => {
                    if (parseInt(val.target.value, 10) < 100) {
                      setData({
                        ...data,
                        currency_config: {
                          ...data.currency_config,
                          decimals: validPrice(validateInt(val.target.value))
                        }
                      });
                      updateApi({
                        ...data,
                        currency_config: {
                          ...data.currency_config,
                          decimals: validPrice(validateInt(val.target.value))
                        }
                      });
                    }
                  }}
                />
              </Grid>
              <Grid item xs>
                <FormControl
                  control="input"
                  label="Decimal symbol"
                  value={data.currency_config?.decimal_symbol}
                  onChange={(val) => {
                    setData({
                      ...data,
                      currency_config: {
                        ...data.currency_config,
                        decimal_symbol: val.target.value
                      }
                    });
                    updateApi({
                      ...data,
                      currency_config: {
                        ...data.currency_config,
                        decimal_symbol: val.target.value
                      }
                    });
                  }}
                />
              </Grid>
              <Grid item xs>
                <FormControl
                  control="input"
                  label="Group separator"
                  value={data.currency_config?.group_seperator}
                  onChange={(val) => {
                    setData({
                      ...data,
                      currency_config: {
                        ...data.currency_config,
                        group_seperator: val.target.value
                      }
                    });
                    updateApi({
                      ...data,
                      currency_config: {
                        ...data.currency_config,
                        group_seperator: val.target.value
                      }
                    });
                  }}
                />
              </Grid>
              <Grid item xs>
                {/* if input is disabled  */}
                <Box className={classes.inputdisabled}>
                  <FormControl
                    control="input"
                    label="Example"
                    value={getCurrencyVal(
                      1000,
                      data?.currency_config,
                      InvoiceSettings.data?.currency_config
                    )}
                    disabled
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <div className={classes.hr} />
        <Grid
          container
          direction="row"
          spacing={0}
          style={{ margin: '10px 0 20px' }}
        >
          <Grid item xs={7}>
            <Box mt="15px" mb="15px">
              <Typography
                color="#000"
                variant="h5"
                component="div"
                className={classes.bktitle}
              >
                Tax Rates
              </Typography>
              <p className={classes.ptext}>Customize the tax rate.</p>
            </Box>
          </Grid>
          <Grid item xs={8}>
            <div>
              <Grid
                container
                direction="row"
                spacing={2}
                alignItems="center"
                // key={row.id}
              >
                <Grid item xs>
                  <FormControl
                    control="input"
                    margin="dense"
                    label="Tax rate name"
                    required
                    disabled
                    value={data.tax?.tax_name}
                    onChange={(e) =>
                      setData({
                        ...data,
                        tax: { ...data.tax, tax_name: e.target.value }
                      })
                    }
                    maxLength={50}
                  />
                </Grid>
                <Grid item xs>
                  <FormControl
                    control="input"
                    margin="dense"
                    label="Tax rate (%)"
                    disabled
                    required
                    value={data.tax?.tax_value}
                    onChange={(e) =>
                      setData({
                        ...data,
                        tax: { ...data.tax, tax_value: e.target.value }
                      })
                    }
                  />
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
        <Box mt="40px" />
      </Grid>
    </Box>
  );
};
const mapToState = (state) => ({
  InvoiceSettings: state.masterHqReducer.financialReducer.Settings
});
const mapToDispatch = (dispatch) => ({
  OpenNoti: (msg, type) => dispatch(openNoti(msg, type)),
  RefreshSettings: () => dispatch(getInvoiceSettings())
});
SettingsTab.propTypes = {
  classes: PropType.object.isRequired,
  InvoiceSettings: PropType.object.isRequired,
  OpenNoti: PropType.func.isRequired,
  RefreshSettings: PropType.func.isRequired
};

export default connect(
  mapToState,
  mapToDispatch
)(withStyles(styles)(SettingsTab));
