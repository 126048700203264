import types from './WaiverReducer.types';
import { getErrorMsg } from '../../utils/ErrorHandling';
import { store } from '../rootReducer';
import {
  getAllWaivers,
  getWaiverTemp
} from '../../services/Contracts.services';

const fetchingWaivers = (type) => ({
  type,
  actionType: types.isFetching
});

const fetchComplete = (type, payload) => ({
  type,
  actionType: types.fetchSucess,
  payload
});

const ErrorFetching = (type, payload) => ({
  type,
  actionType: types.Errorfetch,
  payload
});

const MisingFilter = (filter, payload) => ({
  type: types.MissWavFilter,
  filter,
  payload
});

const SignedFilter = (filter, payload) => ({
  type: types.SignedFilter,
  filter,
  payload
});

const TempWavFilter = (filter, payload) => ({
  type: types.TempWavFilter,
  filter,
  payload
});

const getMissWaiver = (val) => {
  const type = types.MissWav;
  return (dispatch) => {
    dispatch(fetchingWaivers(type));
    getAllWaivers(val)
      .then((resp) => dispatch(fetchComplete(type, resp)))
      .catch((err) => dispatch(ErrorFetching(type, getErrorMsg(err))));
  };
};

const getSigned = (val) => {
  const type = types.Signed;
  return (dispatch) => {
    dispatch(fetchingWaivers(type));
    getAllWaivers(val)
      .then((resp) => dispatch(fetchComplete(type, resp)))
      .catch((err) => dispatch(ErrorFetching(type, getErrorMsg(err))));
  };
};

const getTemplates = (val) => {
  const type = types.TempWav;
  return (dispatch) => {
    dispatch(fetchingWaivers(type));
    getWaiverTemp(val)
      .then((res) => dispatch(fetchComplete(type, res)))
      .catch((err) => dispatch(ErrorFetching(type, getErrorMsg(err))));
  };
};

export const getMissWav = (filter, data) => {
  return (dispatch) => {
    dispatch(MisingFilter(filter, data));
    const filData = store.getState().WaiverReducer.MissingWavFilter;
    let updatedValue = {
      page: filData.page,
      ...filData.default,
      ...filData.sorting,
      ...filData.customFilter
    };
    if (filData.search.length > 0) {
      updatedValue = {
        ...updatedValue,
        search: filData.search
      };
    }
    dispatch(getMissWaiver(updatedValue));
  };
};

export const getSignedWav = (filter, data) => {
  return (dispatch) => {
    dispatch(SignedFilter(filter, data));
    const filData = store.getState().WaiverReducer.SignedFilters;
    let updatedValue = {
      page: filData.page,
      ...filData.default,
      ...filData.sorting,
      ...filData.customFilter
    };
    if (filData.search.length > 0) {
      updatedValue = {
        ...updatedValue,
        search: filData.search
      };
    }
    dispatch(getSigned(updatedValue));
  };
};

export const getTempWav = (filter, data) => {
  return (dispatch) => {
    dispatch(TempWavFilter(filter, data));
    const filData = store.getState().WaiverReducer.TempFilter;
    const updatedValue = {
      ...filData.sorting
    };
    dispatch(getTemplates(updatedValue));
  };
};
