/* eslint-disable function-paren-newline */
/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
import React, { Fragment, useContext, useState } from 'react';
import PropType from 'prop-types';
import { Grid, withStyles, Box } from '@material-ui/core';
import { Chalk } from 'src/Context/chalkboardContext';
import {
  addLoginedUser,
  setSignDialog
} from 'src/Context/ChalkBoardReducer/chalkBoard.actions';
import { chalkBoardDialogStatus } from 'src/Context/ChalkBoardReducer/chalBoard.reducer.data';
import { getClassLogin } from 'src/services/chalkBoard.services';
import { getErrorMsg } from 'src/utils/ErrorHandling';
import Userpic from 'src/assets/image/defaultUser.png';
// import { chalkBoardValidation } from './chalkBoard.validate';
import { getReservedData } from './chalkBoard.utils';
import styles from './Chalkboard.style';
import RemoveMemberDialog from './RemoveMemberDialog';
import JBButton from '../common/JBButton/JBButton';
import ChalkBoardDialog from './ChalkBoardDialog';
import useChangeTitle from '../common/CustomHooks/useTitleChange';

const SignIn = ({ classes }) => {
  const [state, dispatch] = useContext(Chalk);
  const [processing, setProcessing] = useState(() =>
    Array.from({ length: state.SignIn.logined_users.reserved_users }, () => 0)
  );
  useChangeTitle('Sign In | Jungle Alliance');
  const handleSignIn = async (user, i) => {
    const processed = [...processing];
    processed[i] = 1;
    setProcessing(() => processed);
    try {
      // await chalkBoardValidation.validate(state.SignIn);
      const values = getReservedData(user);
      const data = await getClassLogin(values);
      if (data.warnings) {
        dispatch(
          setSignDialog(true, data.warnings, chalkBoardDialogStatus.warning)
        );
        processed[i] = 0;
        setProcessing(() => processed);
      } else {
        processed[i] = 0;
        setProcessing(() => processed);
        dispatch(
          setSignDialog(
            true,
            'Successfully Added the User',
            chalkBoardDialogStatus.success
          )
        );
      }
      dispatch(addLoginedUser(data.user_details));
    } catch (err) {
      if (err.errors) console.log(err.errors);
      else {
        dispatch(
          setSignDialog(true, getErrorMsg(err), chalkBoardDialogStatus.error)
        );
        processed[i] = 0;
        setProcessing(processed);
      }
    }
  };
  return (
    <Box m="10px">
      <Grid
        container
        direction="row"
        justify="start"
        alignItems="center"
        spacing={0}
      >
        {/* eslint-disable-next-line no-nested-ternary */}
        <Box className={classes.SignBox}>
          <h4>Signed In Members</h4>
        </Box>
        {state.SignIn?.error ? (
          <h2>{state.SignIn?.error}</h2>
        ) : state.SignIn?.logined_users.sign_in_users?.length === 0 ? (
          <h2>No Member logged in at this class</h2>
        ) : (
          <Fragment>
            {state.SignIn?.logined_users.sign_in_users?.map((user) => (
              <Box className={classes.siBox} key={user?.id}>
                <Box className={classes.closebk}>
                  <RemoveMemberDialog user_id={user?.user_id} id={user?.id} />
                </Box>
                <img src={user.profile_pic || Userpic} alt="img" />
                <div className={classes.siBoxinfo}>
                  <h3>{user.name?.split(' ')[0]}</h3>
                  <h3>{user.name?.split(' ')[1]}</h3>
                </div>
              </Box>
            ))}
          </Fragment>
        )}
        <Box className={classes.ReservedBox}>
          {state.SignIn.error ? null : state.SignIn.logined_users.reserved_users
              ?.length === 0 ? null : (
            <Fragment>
              <Box>
                <h4>Reserved Members</h4>
              </Box>
              {state.SignIn.logined_users.reserved_users?.map((user, i) => (
                <Box className={classes.siBox} key={user.id}>
                  <Box className={classes.closebk}>
                    <RemoveMemberDialog
                      user_id={user.user_id}
                      id={user.id}
                      isReserved
                    />
                  </Box>
                  <img src={user.profile_pic || Userpic} alt="img" />
                  <div
                    className={classes.siBoxinfo}
                    style={{
                      padding: '35px 15px 15px'
                    }}
                  >
                    <h3>{user.name?.split(' ')[0]}</h3>
                    <h3>{user.name?.split(' ')[1]}</h3>
                    {user.is_waitlist && user.status === 'reserved' ? (
                      <p style={{ marginTop: '10px' }}> In waitlist</p>
                    ) : (
                      <JBButton
                        type="primary"
                        // disabled={
                        //   state.SignIn.disableButton || state.SignIn.isProcessing
                        //   // || getDays(state.SignIn.date, new Date()) !== 0
                        // }
                        style={{
                          padding: '5px 10px',
                          minHeight: 'auto',
                          margin: '10px 0 0 0'
                        }}
                        onClick={() => handleSignIn(user, i)}
                        disabled={processing[i]}
                      >
                        {processing[i] ? 'Signing in...' : 'Sign In'}
                      </JBButton>
                    )}
                  </div>
                </Box>
              ))}
            </Fragment>
          )}
        </Box>
      </Grid>
      {state.SignIn.openDialog && (
        <ChalkBoardDialog open={state.SignIn.openDialog} />
      )}
    </Box>
  );
};

SignIn.propTypes = {
  classes: PropType.object.isRequired
};
export default withStyles(styles)(SignIn);
