/* eslint-disable operator-linebreak */
/* eslint-disable function-paren-newline */
/* eslint-disable indent */
/* eslint-disable arrow-parens */
import React, { PureComponent } from 'react';
import { ROUTES } from 'src/constant/Constants';
import PropTypes from 'prop-types';
import { Link, Route, Switch } from 'react-router-dom';
import {
  Grid,
  withStyles,
  AppBar,
  Tabs,
  Tab,
  Card,
  Breadcrumbs,
  Typography
} from '@material-ui/core';
import ErrorUserView from 'src/views/errorUserPermission/errorUserView';
import { getAffiliatePayments } from 'src/services/gym.services';
import { get } from 'js-cookie';
import styles from '../GeneralInfo.style';
import { getActiveVal } from './GeneralInfo.utils';
import InfoLocations from './InfoLocations';
import PaymentBilling from './PaymentBilling';
import AddPaymentMethod from './AddPaymentMethod/AddPaymentMethod';
import WebIntegrations from './WebIntegrations';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

class GeneralInfo extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { isFetching: false, data: null, error: null };
  }

  componentDidMount() {
    this.setState((prevState) => ({ ...prevState, isFetching: true }));
    getAffiliatePayments(Number(get('affiliate')))
      .then((res) =>
        this.setState((prevState) => ({
          ...prevState,
          data: {
            ...res
            // payment_method: res.payment_method?.filter(
            //   (l) => l.payment_method && l.default_method
            // )
          },
          isFetching: false
        }))
      )
      .catch((err) =>
        this.setState((prevState) => ({
          ...prevState,
          error: err,
          isFetching: false
        }))
      );
  }

  render() {
    const { classes, location } = this.props;
    // eslint-disable-next-line no-unused-vars
    const { data } = this.state;
    const isAffiliate = Number(get('affiliate'));
    const Tabslocation = getActiveVal(location.pathname, isAffiliate);
    return (
      <div className={classes.root}>
        <div className="title-bk">
          <div
            className={classes.new_programs}
            style={{ marginBottom: '20px' }}
          >
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={0}
            >
              <Grid item xs={8}>
                <Breadcrumbs aria-label="breadcrumb">
                  <Link
                    color="inherit"
                    to={ROUTES.GYM_GENERAL_INFO_INFOLOCATIONS}
                  >
                    Gym
                  </Link>
                  <Link
                    color="inherit"
                    to={ROUTES.GYM_GENERAL_INFO_INFOLOCATIONS}
                  >
                    general info
                  </Link>
                  <Typography variant="h3" color="Primary">
                    {(() => {
                      switch (Tabslocation) {
                        case 0:
                          return <span>info & locations</span>;
                        case 1:
                          return <span>Payment & billing</span>;
                        case 2:
                          return <span>web integrations</span>;
                        default:
                          return null;
                      }
                    })()}
                  </Typography>
                </Breadcrumbs>
              </Grid>

              <Grid
                item
                xs={4}
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end'
                }}
              >
                {location.pathname ===
                ROUTES.GYM_GENERAL_INFO_PAYMENT_BILLING ? (
                  // && data?.payment_method.length === 0
                  <AddPaymentMethod
                    updateData={(res) => {
                      this.setState((prevState) => ({
                        ...prevState,
                        data: {
                          ...res,
                          payment_method: res.payment_method?.filter(
                            (l) => l.payment_method === 1
                          )
                        }
                      }));
                    }}
                  />
                ) : null}
              </Grid>
            </Grid>
          </div>
        </div>

        <Card className={classes.mainCard}>
          <AppBar
            position="static"
            style={{
              backgroundColor: 'transparent',
              boxShadow: 'none'
            }}
          >
            <Tabs
              value={Tabslocation}
              indicatorColor="primary"
              textColor="primary"
              fullWidth
            >
              <Tab
                label="info & locations"
                component={Link}
                to={ROUTES.GYM_GENERAL_INFO_INFOLOCATIONS}
                className={classes.p_tabs}
                {...a11yProps(0)} // eslint-disable-line
              />
              {isAffiliate && (
                <Tab
                  label="Payment & billing"
                  component={Link}
                  to={ROUTES.GYM_GENERAL_INFO_PAYMENT_BILLING}
                  className={classes.p_tabs}
                  {...a11yProps(1)} // eslint-disable-line
                />
              )}
              <Tab
                label="web integrations"
                component={Link}
                to={ROUTES.GYM_GENERAL_INFO_WEBINTEGRATIONS}
                className={classes.p_tabs}
                {...a11yProps(2)} // eslint-disable-line
              />
            </Tabs>
          </AppBar>

          <Switch>
            <Route
              path={ROUTES.GYM_GENERAL_INFO_WEBINTEGRATIONS}
              component={WebIntegrations}
            />
            <Route
              path={ROUTES.GYM_GENERAL_INFO_PAYMENT_BILLING}
              render={() => {
                if (isAffiliate) {
                  return (
                    <PaymentBilling
                      data={this.state}
                      handleRefresh={(res) => {
                        this.setState((prevState) => ({
                          ...prevState,
                          data: {
                            ...res,
                            payment_method: res.payment_method?.filter(
                              (l) => l.payment_method === 1
                            )
                          }
                        }));
                      }}
                    />
                  );
                }
                return <ErrorUserView />;
              }}
            />
            <Route
              path={ROUTES.GYM_GENERAL_INFO_INFOLOCATIONS}
              component={InfoLocations}
            />
          </Switch>
        </Card>
      </div>
    );
  }
}

GeneralInfo.propTypes = {
  location: PropTypes.any.isRequired,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(GeneralInfo);
