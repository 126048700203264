import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropType from 'prop-types';
import {
  Typography,
  Grid,
  Toolbar,
  makeStyles,
  Checkbox
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { toggleSelectAll } from 'src/Redux/WODReducers/wodReducer.actions';
import BulkActions from '../../wod/List/BulkActions';

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    padding: '0 5px',
    backgroundColor: '#F9F9FA',
    color: '#000'
  },
  highlight:
    theme.palette.type === 'light'
      ? { color: '#292929', backgroundColor: '#F9F9FA' }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark
      },
  title: {
    flex: '1 1 100%'
  },
  bkselected: {
    minWidth: '185px',
    textAlign: 'center',
    color: '#292929',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '0.2px'
  }
}));

const EnhancedTableToolbar = (props) => {
  const {
    ClearChecks,
    numSelected,
    selectedTags,
    module,
    total,
    setSelected,
    allSelected,
    values
  } = props;
  const classes = useToolbarStyles();
  const dispatch = useDispatch();
  const [isAllSelected, setIsAllSelected] = useState(0);
  const [totalSelected, setTotalSelected] = useState(0);

  useEffect(() => {
    if (allSelected) {
      setIsAllSelected(1);
      setTotalSelected(total);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const selectAll = () => {
    if (isAllSelected === 0) {
      setIsAllSelected(1);
      dispatch(toggleSelectAll(true));
      const newSelected = values.map((n) => n.id);
      setSelected(newSelected);
    } else {
      setIsAllSelected(0);
      dispatch(toggleSelectAll(false));
      setSelected([]);
    }
    setTotalSelected(total);
  };
  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0
      })}
      style={{
        backgroundColor: '#F9F9FA'
      }}
    >
      <Checkbox
        defaultChecked
        indeterminate
        onClick={() => ClearChecks()}
        inputProps={{ 'aria-label': 'indeterminate checkbox' }}
        color="primary"
      />
      <Grid
        spacing={0}
        disableGutters
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
      >
        <Grid item>
          <Typography
            // className={classes.title}
            color="inherit"
            variant="subtitle1"
            component="div"
            className={classes.bkselected}
          >
            {isAllSelected === 0 ? numSelected.length : totalSelected} workouts
            selected
          </Typography>
        </Grid>
        <Grid item xl={2} lg={3} sm={3}>
          <BulkActions
            wodsSelected={numSelected}
            selectedTags={selectedTags}
            module={module}
          />
        </Grid>
        {numSelected.length >= 25 ? (
          <Grid item>
            <Typography
              variant="subtitle1"
              color="inherit"
              component="div"
              className={classes.bkselected}
            >
              <span style={{ marginRight: '10px' }}>
                {isAllSelected === 0
                  ? 'All workouts on this page are selected'
                  : 'All workouts are selected'}
              </span>
              {isAllSelected === 0 ? (
                <span
                  style={{
                    textDecoration: 'underline',
                    color: '#37847c',
                    cursor: 'pointer'
                  }}
                  onClick={selectAll}
                >
                  Select all {total} workouts
                </span>
              ) : (
                <span
                  style={{
                    textDecoration: 'underline',
                    color: '#37847c',
                    cursor: 'pointer'
                  }}
                  onClick={selectAll}
                >
                  Clear selection
                </span>
              )}
            </Typography>
          </Grid>
        ) : null}
      </Grid>
      <div>
        {/* <Tooltip title="Delete" xs>
          <IconButton aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip> */}
      </div>
      {/* // <Tooltip title="Filter list">
      //   <IconButton aria-label="filter list">
      //     <FilterListIcon />
      //   </IconButton>
      // </Tooltip> */}
    </Toolbar>
  );
};

EnhancedTableToolbar.defaultProps = {
  module: 'WOD'
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropType.number.isRequired,
  ClearChecks: PropType.func.isRequired,
  module: PropType.string,
  selectedTags: PropType.array.isRequired,
  total: PropType.number.isRequired,
  setSelected: PropType.func.isRequired,
  allSelected: PropType.bool.isRequired,
  values: PropType.array.isRequired
};

export default EnhancedTableToolbar;
