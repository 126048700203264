import { checkValidString } from 'src/utils/validations';

const addlistInv = (data) => {
  const b = {
    revenue_cat_id: data.reven.id,
    sale_price: data.saleprice,
    discount_type: data.discount_type === '2' ? 'percentage' : 'fixed',
    line_discount: data.discount,
    tax_id: data.tax.id,
    qty: data.qty,
    name: data.name
  };
  return b;
};

// eslint-disable-next-line
export const validateItems = (data, symbol) => {
  if (checkValidString(data.name)) {
    return { error: 'Please specify valid name' };
  }
  if (!data.reven) {
    return { error: 'Please do enter revenue category' };
  }
  if (!data.tax) {
    return { error: 'Please specify tax' };
  }
  if (data.discount === '') {
    return { error: 'Please do enter discount value' };
  }
  if (
    data.discount_type === '1'
    && +data.discount > data.saleprice * data.qty
  ) {
    return {
      error: `Discount cannot be higher than the Price Total (${data.saleprice
        * data.qty} ${symbol})`
    };
  }
  if (data.discount_type === '2' && +data.discount > 100) {
    return { error: 'Discount cannot be higher than 100%' };
  }

  if (data.qty === '' || data.qty <= 0) {
    return { error: 'the Quantity must be at least 1' };
  }
  if (data.saleprice === '') {
    return { error: 'Please do speacify the sale Price' };
  }
  return { value: addlistInv(data) };
};

export const getEditItem = (data) => {
  const b = {
    discount_type: data.discount_type === 'percentage' ? '2' : '1',
    discount: parseInt(data.line_discount, 10),
    name: data.name,
    qty: data.qty,
    saleprice: data.sale_price,
    tax: data.tax_id,
    reven: data.revenue_cat_id
  };
  return b;
};

export const fetcheditItem = (data, revens, taxs) => {
  const b = {
    ...data,
    reven: revens.filter((res) => res.id === data.reven)[0] || null,
    tax: taxs.filter((res) => res.id === data.tax)[0] || null
  };
  return b;
};
