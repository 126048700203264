/* eslint-disable no-lonely-if */
import { ROUTES } from 'src/constant/Constants';

// eslint-disable-next-line
export const getActiveVal = (pathname, isAffiliate) => {
  if (isAffiliate) {
    switch (pathname) {
      case ROUTES.GYM_GENERAL_INFO_INFOLOCATIONS:
        return 0;
      case ROUTES.GYM_GENERAL_INFO_PAYMENT_BILLING:
        return 1;
      case ROUTES.GYM_GENERAL_INFO_WEBINTEGRATIONS:
        return 2;
      default:
        return 0;
    }
  } else {
    if (pathname === ROUTES.GYM_GENERAL_INFO_INFOLOCATIONS) {
      return 0;
      // eslint-disable-next-line no-else-return
    } else {
      return 1;
    }
  }
};
