import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  AppBar,
  Tabs,
  Tab,
  Typography,
  makeStyles,
  ThemeProvider
} from '@material-ui/core';
import { Link, Route, Switch, useLocation } from 'react-router-dom';
import { ROUTES } from '../../../../constant/Constants';
import { ReactComponent as TabeditIcon } from '../../../../assets/image/tabediticon.svg';
import ClassesTabs from './ClassesTabs';
import RecurringTab from './RecurringTab';

const themeInstance = {
  overrides: {
    MuiTabs: {
      indicator: {
        backgroundColor: 'transparent'
      }
    },
    MuiTab: {
      root: {
        maxWidth: '100%',
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
        border: '1px solid #EAEAEA',
        borderBottom: '0',
        margin: '0px 5px 0 0',
        '&$selected': {
          backgroundColor: 'white',
          color: '#000 ',
          '&:hover': {
            color: '#37847a'
          },
          '&$textColorPrimary': {
            color: '#01847A'
          },
          '& svg path': {
            stroke: '#01847A'
          }
        }
      },
      wrapper: {
        flexDirection: 'row',
        justifyContent: 'space-around'
      },
      labelIcon: {
        minHeight: '55px'
      },
      textColorPrimary: {
        color: '#545454',
        fontSize: '14px',
        fontWeight: '900',
        lineHeight: '18px',
        letterSpacing: '0.5px'
      }
    }
  }
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other} // eslint-disable-line
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1
  },
  STabPanel: {
    backgroundColor: '#fff',
    boxShadow: '4px 0px 16px rgba(37, 40, 43, 0.08)',
    '& .MuiCard-root.MuiPaper-rounded': {
      boxShadow: 'none',
      padding: '0 10px'
    },
    overflowY: 'auto',
    paddingTop: '20px',
    paddingLeft: '20px',
    paddingRight: '20px',
    // paddingBottom: '20px',
    borderRadius: '5px',
    borderTopLeftRadius: '0',
    border: '1px solid #EAEAEA',
    borderTop: '0'
  },
  hr: {
    border: '1px solid rgb(196 196 196 / 0.4)',
    display: 'inline-block',
    width: '100%',
    margin: '10px 0'
  },
  ptext: {
    fontSize: '16px',
    lineHeight: '24px',
    color: '#7F7F7F',
    fontWeight: 'normal'
  },
  cutom_Switch_Label: {
    display: 'inline-block',
    marginRight: '30px',
    verticalAlign: 'middle',
    fontSize: '14px',
    fontWeight: '700',
    color: '#000'
  }
}));

export default function ClassTabulation() {
  const classes = useStyles();
  const location = useLocation();
  const recurrValue = location.pathname === ROUTES.CLASSES_RECURRING ? 1 : 0;
  return (
    <div className={classes.root}>
      <ThemeProvider theme={themeInstance}>
        <AppBar
          position="static"
          style={{
            backgroundColor: 'transparent',
            boxShadow: 'none'
          }}
        >
          <Tabs
            // value={Recurr}
            value={recurrValue}
            indicatorColor="primary"
            textColor="primary"
            color="primary"
            fullWidth
            aria-label="simple tabs example"
          >
            <Tab
              label="Classes"
              component={Link}
              to={ROUTES.CLASSES_LIST}
              icon={<TabeditIcon />}
              {...a11yProps(0)} // eslint-disable-line
            />
            <Tab
              label="Recurring"
              component={Link}
              to={ROUTES.CLASSES_RECURRING}
              icon={<TabeditIcon />}
              {...a11yProps(1)} // eslint-disable-line
            />
          </Tabs>
        </AppBar>
      </ThemeProvider>
      <div className={classes.STabPanel}>
        <Switch>
          <Route
            exact
            path={ROUTES.CLASSES_RECURRING}
            component={RecurringTab}
          />
          <Route path={ROUTES.CLASSES_LIST} component={ClassesTabs} />
        </Switch>
      </div>
    </div>
  );
}

ClassTabulation.propTypes = {};
