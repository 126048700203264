import React, { useState, useContext } from 'react';
import { VanguardMem } from 'src/Context/VanguardMembershipContext';
import { useSelector } from 'react-redux';
import {
  changeSignContract,
  addProgram,
  selectAllPrograms
} from 'src/Context/VanguardMembership/VanguardMembership.actions';
// import PropType from 'prop-types';
import clsx from 'clsx';
import {
  Typography,
  makeStyles,
  Grid,
  RadioGroup,
  Radio,
  Checkbox,
  FormControlLabel
} from '@material-ui/core';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckIcon from '@material-ui/icons/Check';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    background: 'rgba(29, 29, 29, 0.94)',
    boxShadow: '0px 0.33333px 0px rgb(255 255 255 / 15%)',
    backdropFilter: 'blur(54.3656px)'
  },
  appToolbar: {
    minHeight: '75px',
    justifyContent: 'space-between'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
  mainTitle: {
    fontWeight: '500',
    fontSize: '48px',
    lineHeight: '44px',
    color: '#FFFFFF',
    fontFamily: 'DDC Hardware',
    textAlign: 'center',
    margin: '38px 0 0 0',
    '@media (max-width: 992px)': {
      fontSize: '35px'
    }
  },
  MainCard: {
    height: 'calc(100vh - 75px )',
    background:
      'linear-gradient(180deg, rgba(41, 41, 41, 0.95) 0%, rgba(4, 4, 4, 0.95) 100%)',
    borderRadius: '0',
    flex: '1 1 auto',
    // height: '100%',
    overflow: 'auto'
  },
  HomeButton: {
    fontFamily: 'Lato',
    color: '#2CE9DA',
    fontWeight: 'bold',
    fontSize: '22px',
    lineHeight: '32px',
    textTransform: 'capitalize'
  },
  cutom_select_Label: {
    fontFamily: 'DDC Hardware',
    fontStyle: 'normal',
    fontWeight: '500;',
    fontSize: '22px',
    lineHeight: '32px',
    letterSpacing: '0.2px',
    color: '#FFFFFF',
    '&:focus ': {
      outline: '0'
    }
  },
  cutom_vanguard_FormControl: {
    '& .fd-input input': {
      background: 'rgba(196, 196, 196, 0.05)',
      color: '#fff',
      borderRadius: '6px',
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '20px',
      height: '45px',
      border: '2px solid #C8C8C8',
      padding: '8px 20px 8px',
      '&::placeholder ': {
        color: '#fff',
        fontWeight: 'bold',
        fontSize: '15px'
      }
    }
  },
  cutom_vanguard_Autocomplete: {
    background: 'rgba(196, 196, 196, 0.05)',
    border: '2px solid #C8C8C8',
    color: '#fff',
    borderRadius: '6px',
    '& .cutom_select div': {
      minHeight: '62px'
    },
    '& .cutom_select div input ': {
      color: '#ffffff !important',
      fontWeight: 'bold',
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontSize: '20px'
    },
    '& .MuiAutocomplete-endAdornment': {
      minHeight: ' auto !important',
      top: 'calc(50% - 12px)'
    }
  },
  customRadio: {
    '& .MuiFormControlLabel-root': {
      background: 'rgba(196, 196, 196, 0.05)',
      border: '2px solid #7B7B7B',
      borderRadius: '6px',
      height: '70px',
      margin: '0 0 15px'
    },
    '& .MuiFormControlLabel-root.checked': {
      background: 'rgba(44, 233, 218, 0.15)',
      border: '2px solid #2CE9DA',
      borderRadius: '6px'
    },
    '& .MuiFormControlLabel-root svg.MuiSvgIcon-root': {
      width: '30px',
      height: '30px',
      fill: 'none',
      borderRadius: '50%',
      border: '2px solid #4B4B4B'
    },
    '& .MuiFormControlLabel-root.checked svg.MuiSvgIcon-root': {
      background: '#67e9da',
      padding: '4px',
      fill: '#000',
      color: '#060606',
      border: '0px solid #4B4B4B'
    },
    '& .MuiRadio-root': {
      padding: '20px'
    },
    '& .MuiFormControlLabel-label': {
      color: '#fff',
      fontSize: '20px',
      lineHeight: '26px',
      fontWeight: 'bold'
    }
  },
  subTitle: {
    fontWeight: 'bold',
    fontSize: '24px',
    lineHeight: '38px',
    color: '#FFFFFF',
    fontFamily: 'Lato',
    textAlign: 'center',
    margin: '38px 0 0 0',
    letterSpacing: '0.1px'
  },
  VanCustomcheckbox: {
    '& .MuiFormControlLabel-root': {
      background: 'rgba(196, 196, 196, 0.05)',
      border: '2px solid #7B7B7B',
      borderRadius: '6px',
      height: '70px',
      margin: '0 0 15px'
    },
    '& .MuiFormControlLabel-root.checked': {
      background: 'rgba(44, 233, 218, 0.15)',
      border: '2px solid #2CE9DA',
      borderRadius: '6px'
    },
    '& .MuiFormControlLabel-root svg.MuiSvgIcon-root': {
      width: '30px',
      height: '30px',
      fill: 'none',
      borderRadius: '6px',
      border: '2px solid #4B4B4B',
      margin: '0 0px 0 10px'
    },
    '& .MuiFormControlLabel-root.checked svg.MuiSvgIcon-root': {
      background: '#67e9da',
      padding: '4px',
      fill: '#000',
      color: '#060606',
      border: '0px solid #4B4B4B'
    },
    '& .MuiTypography-root': {
      padding: '20px 15px'
    },
    '& .MuiFormControlLabel-label': {
      color: '#fff',
      fontSize: '20px',
      lineHeight: '26px',
      fontWeight: 'bold'
    }
  },
  SelectAllCheckbox: {
    flexDirection: 'row',
    '& .MuiFormControlLabel-root': {
      border: '0 !important',
      background: 'transparent'
    }
  }
}));

const ProgramAccess = () => {
  const classes = useStyles();
  const allPrograms = useSelector((state) => state.allprograms.programs);
  const [check, setCheck] = useState(false);
  const [state, dispatch] = useContext(VanguardMem);
  const {
    memebership_data: {
      // eslint-disable-next-line no-unused-vars
      membershipdata: { program_access, membership_contract, payment_data }
    }
  } = state;
  // eslint-disable-next-line no-unused-vars
  const [data, setData] = useState({
    value: ''
  });

  // eslint-disable-next-line no-unused-vars
  const isSelected = (item) => program_access.indexOf(item) !== -1;
  return (
    <div>
      <Typography variant="h3" className={classes.mainTitle}>
        Program Access
      </Typography>
      <Typography variant="h5" className={classes.subTitle}>
        Select the programs included with this membership
      </Typography>
      <Grid
        container
        xs={10}
        justify="center"
        style={{
          margin: '45px auto'
        }}
      >
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={2}
          // style={{ margin: '20px 0 20px' }}
        >
          <Grid xs={12}>
            <RadioGroup
              className={clsx(
                classes.VanCustomcheckbox,
                classes.SelectAllCheckbox
              )}
            >
              {allPrograms.length === 0 ? null : (
                <FormControlLabel
                  checked={program_access.length >= allPrograms.length}
                  value="1"
                  control={
                    <Checkbox
                      color="primary"
                      // onChange={() => dispatch(addProgram(item.id))}
                      onChange={(e) => {
                        setCheck(!check);
                        dispatch(
                          selectAllPrograms({
                            programs: allPrograms,
                            checked: e.target.checked
                          })
                        );
                      }}
                      checkedIcon={
                        <CheckIcon
                          htmlColor="#2CE9DA"
                          width="30px"
                          height="30px"
                        />
                      }
                    />
                  }
                  label="Select All"
                  className={`${
                    program_access.length >= allPrograms.length ? 'checked' : ''
                  }`}
                />
              )}
            </RadioGroup>
          </Grid>
          <Grid xs={12}>
            <RadioGroup className={classes.VanCustomcheckbox}>
              {allPrograms.map((item) => (
                <FormControlLabel
                  checked={isSelected(item.id)}
                  control={
                    <Checkbox
                      color="primary"
                      onChange={() => dispatch(addProgram(item.id))}
                      checkedIcon={
                        <CheckIcon
                          htmlColor="#2CE9DA"
                          width="30px"
                          height="30px"
                        />
                      }
                    />
                  }
                  label={item.name}
                  // checked
                  className={`${isSelected(item.id) ? 'checked' : ''}`}
                />
              ))}
            </RadioGroup>
          </Grid>
        </Grid>
        <Grid
          md={10}
          style={{
            margin: '0 0px 24px'
          }}
        >
          <Typography variant="h5" className={classes.subTitle}>
            Membership contract. Sign this member plan contract now or email to
            be signed later.
          </Typography>
        </Grid>
        <Grid xs={12}>
          <RadioGroup
            value={membership_contract}
            onChange={(e) => {
              dispatch(changeSignContract(e.target.value));
            }}
            className={classes.customRadio}
          >
            <FormControlLabel
              value="sign_contract"
              control={
                <Radio
                  color="primary"
                  checkedIcon={
                    <CheckIcon htmlColor="#2CE9DA" width="30px" height="30px" />
                  }
                  icon={<RadioButtonUncheckedIcon width="30px" height="30px" />}
                />
              }
              label="Sign now"
              // checked
              className={`${
                membership_contract === 'sign_contract' ? 'checked' : ''
              }`}
            />
            <FormControlLabel
              value="send_contract"
              control={
                <Radio
                  color="primary"
                  icon={<RadioButtonUncheckedIcon width="30px" height="30px" />}
                  checkedIcon={
                    <CheckIcon htmlColor="#2CE9DA" width="30px" height="30px" />
                  }
                  style={{}}
                />
              }
              label="Email"
              className={`${
                membership_contract === 'send_contract' ? 'checked' : ''
              }`}
            />
          </RadioGroup>
        </Grid>
      </Grid>
    </div>
  );
};

// ProgramAccess.propTypes = {
//   setOpen: PropType.bool.isRequired,
//   handleClose: PropType.func.isRequired
//   handleBack: PropType.func,
//   handleHistory: PropType.string
// };

export default ProgramAccess;
