// eslint-disable-next-line import/prefer-default-export
import { getTime, backendDate } from 'src/utils/someCommon';
import moment from 'moment';
import ReducerConstants from '../Reducer.constants';

export function getAttendancefilterAdded(filter, filterType, state) {
  switch (filterType) {
    case ReducerConstants.addcustom: {
      const getFilter = () => {
        const finalObj = {};
        if (filter?.program) {
          finalObj.programe_id = filter.program.id;
        }
        if (filter?.day && filter.day?.filter !== 'custom') {
          finalObj.date_range = filter.day.filter;
        }
        if (filter?.day && filter.day?.filter === 'custom') {
          finalObj.date_range = filter.day.filter;
          finalObj.custom = backendDate(filter.prevdate) + '-' + backendDate(filter.nextdate);
        }
        if (filter.start_time) {
          finalObj.start_time = moment(
            getTime(filter.start_time),
            'h:mm:ss A'
          ).format('HH:mm:ss');
        }
        return finalObj;
      };
      return getFilter();
    }
    case ReducerConstants.removecustom: {
      const removeFilter = () => {
        const finalObj = { ...state };
        delete finalObj[filter.filter];
        return finalObj;
      };
      return removeFilter();
    }
    default:
      return null;
  }
}
export const getAddedTags = (filter, data, state, date) => {
  switch (filter) {
    case ReducerConstants.addcustom: {
      const getTags = () => {
        const tag = [];
        if (data && data.program) {
          tag.push({
            id: 1000,
            name: `Program is ${data.program.name}`,
            filter: 'programe_id'
          });
        }
        if (data && data.day.filter !== 'custom') {
          tag.push({
            id: 1001,
            name: `Date range is ${data.day.name}`,
            filter: 'date_range'
          });
        }
        if (data && data.day.filter === 'custom') {
          tag.push({
            id: 1001,
            name: `Date range is ${moment(data.prevdate).format(
              date.dateformat
            )} - ${moment(data.nextdate).format(date.dateformat)}`,
            filter: 'date_range'
          });
        }
        if (data && data.start_time) {
          tag.push({
            id: 1003,
            name: `Start time is ${moment(
              getTime(data.start_time),
              'h:mm:ss A'
            ).format(date.ampm ? 'h:mm A' : 'HH:mm')}`,
            filter: 'start_time'
          });
        }
        return tag || null;
      };
      return getTags();
    }
    case ReducerConstants.removecustom: {
      const removeTags = () => {
        let tag = [];
        tag = state.filter((item) => item.id !== data.id);
        return tag;
      };
      return removeTags();
    }
    default:
      return null;
  }
};
