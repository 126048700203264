import React, { useState, useEffect } from 'react';
import { ROUTES } from 'src/constant/Constants';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import { withStyles, Card, Box, Button } from '@material-ui/core';
import { commuSettings } from 'src/services/Communication.service';
import VanguardTopBar from './TopBar';
import Potential from './potential';
import styles from './vanguardIndex.style';
// import Plusicon from '../../assets/image/plusicon.png';
import WaiverImg from '../../assets/image/Vanguard/waiverImg.png';
import MembershipImg from '../../assets/image/Vanguard/membershipImg.png';
import ChalkboardImg from '../../assets/image/Vanguard/ChalkboardImg.png';
import MovementImg from '../../assets/image/Vanguard/movementlibraryImg.png';
import OnboardingMemberImg from '../../assets/image/Vanguard/onboardingMemberImg.png';
import { ReactComponent as ArrowRight } from '../../assets/image/Vanguard/Varrow-right.svg';
import { ReactComponent as LockIcon } from '../../assets/image/Vanguard/icon/Lock.svg';
import { ReactComponent as PlusIcon } from '../../assets/image/Vanguard/plusIcon.svg';
// import { ReactComponent as LOGO } from '../../assets/image/Vanguard/LOGO.svg';
import EnterPassword from './EnterPassword';
import VanguardListLoadingView from '../common/loading/VanguardListLoadingView';
import useChangeTitle from '../common/CustomHooks/useTitleChange';

// eslint-disable-next-line no-unused-vars
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

const VanguardIndex = (props) => {
  const { classes, currentUser } = props;
  const [open, setOpen] = useState(false);
  const [authPass, setauthPass] = useState({
    val: false,
    id: null
  });
  useChangeTitle('Vanguard | Jungle Alliance');
  const [data, setData] = useState(() => null);
  const history = useHistory();
  useEffect(() => {
    commuSettings()
      .then((res) => {
        setData({
          email_sign_logo: res.meta.email_sign_logo
        });
      })
      .catch((err) => console.warn(err));
  }, []);
  if (!data) return <VanguardListLoadingView />;
  return (
    <>
      <div>
        <VanguardTopBar currentUser={currentUser} />
      </div>
      <Box className={classes.logoMsize}>
        {/* <img src={Logobotany} alt="logo" /> */}
        {data.email_sign_logo === null || data.email_sign_logo === '' ? null : (
          <img
            src={data.email_sign_logo}
            alt="logo"
            style={{
              filter: 'invert(1)',
              width: '200px',
              height: '86px',
              objectFit: 'contain'
            }}
          />
        )}

        {/* <LOGO /> */}
      </Box>
      <div className={classes.root}>
        <Card className={classes.mainCard} elevation="0">
          <Button
            className={classes.mainList}
            component={Link}
            to={ROUTES.VANGUARD_WAIVER}
            // onClick={() =>
            //   setauthPass({
            //     val: true,
            //     id: 1
            //   })
            // }
            style={{
              backgroundImage: `url('${WaiverImg}')`
            }}
          >
            {/* <LockIcon className={classes.LockIcon} /> */}
            <Box>
              <h4 className={classes.mainTitle}>WAIVER</h4>
            </Box>
            <Box className={classes.mainArrowRight}>
              <ArrowRight />
            </Box>
          </Button>
          <Button
            className={classes.mainList}
            onClick={() =>
              setauthPass({
                val: true,
                id: 2
              })
            }
            style={{
              backgroundImage: `url('${MembershipImg}')`
            }}
          >
            <LockIcon className={classes.LockIcon} />
            <Box>
              <h4 className={classes.mainTitle}>Create Membership</h4>
            </Box>
            <Box className={classes.mainArrowRight}>
              <ArrowRight />
            </Box>
          </Button>

          <Button
            className={classes.mainList}
            component={Link}
            to={ROUTES.VANGUARD_MEMBERSHIP_DROPIN}
            style={{
              backgroundImage: `url('${OnboardingMemberImg}')`
            }}
          >
            <Box>
              <h4 className={classes.mainTitle}>Drop In</h4>
            </Box>
            <Box className={classes.mainArrowRight}>
              <ArrowRight />
            </Box>
          </Button>

          <Button
            className={classes.mainList}
            component={Link}
            to={ROUTES.VANGUARD_CHALKBOARD}
            style={{
              backgroundImage: `url('${ChalkboardImg}')`
            }}
          >
            <Box>
              <h4 className={classes.mainTitle}>Chalkboard</h4>
            </Box>
            <Box className={classes.mainArrowRight}>
              <ArrowRight />
            </Box>
          </Button>
          <Button
            className={classes.mainList}
            style={{
              backgroundImage: `url('${MovementImg}')`
            }}
          >
            {/* <LockIcon className={classes.LockIcon} /> */}
            <Box>
              <h4 className={classes.mainTitle}>movement library</h4>
            </Box>
            <Box className={classes.mainArrowRight}>
              <ArrowRight />
            </Box>
          </Button>
        </Card>
      </div>
      <Box className={classes.potential} onClick={() => setOpen(true)}>
        <h4> POTENTIAL </h4>
        <PlusIcon className={classes.poteIcon} />
      </Box>
      <EnterPassword
        Dialogtitle="Please enter password"
        setOpen={authPass.val === true}
        handleClose={() =>
          setauthPass({
            val: false
          })
        }
        handleBack={() => {
          setauthPass({
            val: false
          });
          if (authPass.id === 1) {
            history.push(ROUTES.VANGUARD_WAIVER);
          } else {
            history.push(ROUTES.VANGUARD_MEMBERSHIP);
          }
        }}
      />
      <Potential setOpen={open} handleClose={() => setOpen(false)} />
    </>
  );
};

VanguardIndex.propTypes = {
  classes: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired
  // eslint-disable-next-line react/no-unused-prop-types
};

export default withStyles(styles, { withTheme: true })(VanguardIndex);
