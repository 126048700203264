import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import queryString from 'query-string';
import {
  Breadcrumbs,
  Collapse,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { get, set } from 'js-cookie';
import { Link } from 'react-router-dom';
import { openNoti } from 'src/Redux/global/global.actions';
import { getProData } from 'src/services/programModule.services';
import ProgramForm from './commonform';
import DeactivateprogramDialog from './DeactivateprogramDialog';
import CancelDialogTwo from '../common/CommonDialogBox/CancelDialogTwo';
import styles from './EditProgram/programedit.styles';
import { ROUTES } from '../../constant/Constants';
import NotFoundView from '../errors/NotFoundView';
import LoadingView from '../common/loading';
import './Program.css';

class EditProgram extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      actionList: false,
      data: null,
      errorfetch: false,
      openDial: false,
      cancelDialog: false
    };
  }

  componentDidMount() {
    const { location } = this.props;
    const { program } = queryString.parse(location.search);
    getProData(program)
      .then((res) => this.setState({ data: res }))
      .catch(() => this.setState({ errorfetch: true }));
  }

  handleExpandClick = () => {
    this.setState((state) => ({ expanded: !state.expanded }));
  };

  handleClickAction = () => {
    this.setState((state) => ({ actionList: !state.actionList }));
  };

  handleClickOpen = () => {
    this.setState(() => ({ openDial: true }));
  };

  handleCancelDialog = () => {
    this.setState(() => ({ cancelDialog: true }));
  };

  handleMassUpdateProgram = () => {
    const { location, history } = this.props;
    const { program } = queryString.parse(location.search);
    set('membershipprog', program);
    history.push(ROUTES.CLASSES_SETTINGS_MEMBERSHIP_MASS_UPDATE);
  };

  render() {
    const { classes, location } = this.props;
    const { actionList, data, errorfetch, openDial, cancelDialog } = this.state;
    const { program } = queryString.parse(location.search);
    const roleid = JSON.parse(get('jbrole') || '{}')?.role_id || 3;

    const preLoadData = (
      <Breadcrumbs aria-label="breadcrumb" style={{ margin: '0px 0 21px' }}>
        <Link color="inherit" to={ROUTES.CLASSES_PROGRAMS}>
          Classes
        </Link>
        <div
          color="inherit"
          onClick={this.handleCancelDialog}
          style={{
            cursor: 'pointer'
          }}
        >
          Programs
        </div>
        <Typography variant="h3" color="Primary">
          <span>Edit Program: {data ? data.name : 'Program Name'}</span>
        </Typography>
      </Breadcrumbs>
    );

    if (errorfetch || !program) return <NotFoundView />;
    if (!data) {
      return (
        <div className={classes.root}>
          <div className="title-bk">{preLoadData}</div>
          <LoadingView />
        </div>
      );
    }
    return (
      <div className={classes.root}>
        <div className="title-bk">
          {preLoadData}

          <div>
            <Grid
              container
              direction="row"
              alignItems="center"
              style={{ marginBottom: '20px' }}
            >
              <Grid item xs={10}>
                <h1 style={{ marginBottom: '0' }}>Edit Program</h1>
              </Grid>

              <Grid
                item
                xs={2}
                direction="row"
                justify="flex-end"
                alignItems="center"
              >
                <div style={{ width: '115px', float: 'right' }}>
                  <ListItem
                    button
                    onClick={this.handleClickAction}
                    className={classes.action_btn}
                  >
                    <label
                      color="primary"
                      style={{
                        width: '100%',
                        cursor: 'pointer',
                        textTransform: 'uppercase'
                      }}
                    >
                      Actions
                    </label>
                    {actionList ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse
                    in={actionList}
                    timeout="auto"
                    unmountOnExit
                    style={{
                      position: 'absolute',
                      width: '260px',
                      right: '38px',
                      backgroundColor: '#FFF',
                      marginTop: '4px',
                      zIndex: '9',
                      boxShadow: '0px 0px 8px rgba(37, 40, 43, 0.12)',
                      borderRadius: '2px',
                      '&:hover': {
                        backgroundColor: 'red'
                      }
                    }}
                  >
                    <List component="li" disablePadding>
                      <ListItem
                        button
                        className={classes.nested}
                        disableGutters
                        style={{
                          padding: '0px 0px'
                        }}
                      >
                        <ListItemText
                          disableTypography
                          primary={
                            data.status
                              ? 'Deactivate program'
                              : 'Activate Program'
                          }
                          onClick={this.handleClickOpen}
                          className={classes.Listbutton}
                          style={{
                            fontWeight: 'bold'
                          }}
                        />
                      </ListItem>
                      {roleid === 1 && (
                        <ListItem
                          button
                          className={classes.nested}
                          style={{
                            padding: '0px 0px'
                          }}
                        >
                          <ListItemText
                            disableTypography
                            primary="Mass Update Member Programs"
                            onClick={this.handleMassUpdateProgram}
                            className={classes.Listbutton}
                          />
                        </ListItem>
                      )}
                    </List>
                  </Collapse>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
        <DeactivateprogramDialog
          setOpen={openDial}
          handleClose={() => this.setState({ openDial: false })}
          data={data}
        />
        <CancelDialogTwo
          setOpen={cancelDialog}
          handleClose={() => this.setState({ cancelDialog: false })}
        />
        <ProgramForm editProg info={data} />
      </div>
    );
  }
}

EditProgram.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

const mapToDispatch = (dispatch) => ({
  OpenNotiDisp: (msg, type) => dispatch(openNoti(msg, type))
});

export default connect(null, mapToDispatch)(withStyles(styles)(EditProgram));
