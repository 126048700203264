import React, { useCallback, useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid, Paper, InputBase, Box, withStyles } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import {
  getOverdueInvs,
  toggleAreAllSelected
} from 'src/Redux/InvoiceReducer/InvoiceReducer.actions';
import LoadingView from 'src/views/common/loading';
import ApplyFilter from 'src/views/common/ApplyFilter/applyFilter';
import _ from 'lodash';
import filterType from 'src/Redux/Reducer.constants';
import useChangeTitle from 'src/views/common/CustomHooks/useTitleChange';
import Invoicestable from './Invoicestable';
import styles from './invoices.style';
import OverdueTabFilters from './Filters/OverdueTabFilters';

const OverDueTab = (props) => {
  const {
    InvFilter,
    InvSort,
    getIvoices: { isFetching, data, error },
    InvSearch,
    classes,
    filteredTags,
    toggleAreAllSelectedData
  } = props;
  const [search, setSearch] = useState(InvSearch);
  const [selected, setSelected] = useState([]);
  useChangeTitle('Overdue | Jungle Alliance');
  useEffect(() => {
    toggleAreAllSelectedData(false);
    setSelected([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const SearchQuery = useCallback(
    _.debounce((q) => InvFilter(filterType.search, q), 1500),
    []
  );

  useEffect(() => {
    InvFilter();
  }, []); // eslint-disable-line

  const setDirection = (name) => {
    if (InvSort.sort_name === name) {
      return InvSort.sort_by === 'asc' ? 'asc' : 'dsc';
    }
    return 'asc';
  };

  const isActive = (sortlabel) => {
    if (InvSort.sort_name === sortlabel) return true;
    return false;
  };

  const setSort = (sortlabel) => {
    InvFilter(filterType.sorting, sortlabel);
  };

  const Paginate = (val) => {
    if (val !== data.current_page) {
      return InvFilter(filterType.page, val);
    }
    return null;
  };

  if (!data) return <LoadingView />;

  return (
    <Box p="15px 0">
      <Grid
        container
        direction="row"
        justify="start"
        alignItems="center"
        spacing={0}
      >
        <Grid container disableGutters style={{ marginBottom: '15px' }}>
          <Box mr="15px">
            <ApplyFilter>
              <OverdueTabFilters
                getFilData={(val, val2) =>
                  InvFilter(filterType.addcustom, [val, val2])
                }
              />
            </ApplyFilter>
          </Box>
          <Grid item xs>
            <div className={classes.search}>
              <InputBase
                fullWidth
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                  SearchQuery(e.target.value);
                }}
                placeholder="Search…"
                classes={{
                  root: classes.inputRoot,
                  input: classes.searchInput
                }}
                InputProps={{
                  'aria-label': 'search'
                }}
              />
            </div>
          </Grid>
        </Grid>
        <div>
          {filteredTags.map((tags) => (
            <div
              className={classes.DTags}
              key={tags.id}
              style={{ margin: '0 0 15px', display: 'inline-block' }}
            >
              <Box display="inline-block" mr={1}>
                <label>
                  {tags.name}
                  <span
                    className={classes.DTagsClose}
                    onClick={() => InvFilter(filterType.removecustom, tags)}
                  >
                    ×
                  </span>
                </label>
              </Box>
            </div>
          ))}
        </div>
        {isFetching ? (
          <LoadingView />
        ) : error ? (
          <p>{error}</p>
        ) : (
          <Fragment>
            {' '}
            <Grid item xs={12} className={classes.SimpleTablestyle}>
              <Invoicestable
                values={data ? data.data : []}
                setDirection={setDirection}
                isActive={isActive}
                setSort={setSort}
                tabs={3}
                total={data?.total}
                selected={selected}
                setSelected={setSelected}
              />
            </Grid>
            <Grid
              item
              xs={12}
              container
              direction="row"
              alignItems="center"
              style={{ marginTop: 24 }}
            >
              <Grid item xs={6} justify="flex-start">
                {data && data.from && data.to >= 1 ? (
                  <Paper elevation={0} style={{ color: '#A9A9A9' }}>
                    {`${data.from} - ${data.to} of ${data.total}`}
                  </Paper>
                ) : null}
              </Grid>
              <Grid item xs={6} justify="flex-end" style={{ display: 'flex' }}>
                <Pagination
                  count={data.last_page}
                  color="primary"
                  variant="outlined"
                  page={data.current_page}
                  onChange={(_, val) => Paginate(val)} // eslint-disable-line
                  shape="rounded"
                />
              </Grid>
            </Grid>
          </Fragment>
        )}
      </Grid>
    </Box>
  );
};
OverDueTab.propTypes = {
  InvFilter: PropTypes.func.isRequired,
  getIvoices: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  InvSort: PropTypes.object.isRequired,
  InvSearch: PropTypes.string.isRequired,
  filteredTags: PropTypes.array.isRequired,
  toggleAreAllSelectedData: PropTypes.func.isRequired
};

const mapToDispatch = (dispatch) => ({
  InvFilter: (filter, data) => dispatch(getOverdueInvs(filter, data)),
  toggleAreAllSelectedData: (value) => dispatch(toggleAreAllSelected(value))
});

const mapToState = (state) => ({
  getIvoices: state.InvoiceReducer.overdue,
  InvSearch: state.InvoiceReducer.overdueFilter.search,
  InvSort: state.InvoiceReducer.overdueFilter.sorting,
  filteredTags: state.InvoiceReducer.overdueFilter.tags
});

export default connect(
  mapToState,
  mapToDispatch
)(withStyles(styles)(OverDueTab));
