/* eslint-disable operator-linebreak */
/* eslint-disable indent */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import useGymSettingRefresh from 'src/views/common/CustomHooks/useGymSettingRefresh';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropType from 'prop-types';
import { Grid, Box, InputLabel } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import {
  checkClassName,
  getMinTimeErr,
  setMinStartTime
} from 'src/utils/someCommon';
import Link from '@material-ui/core/Link';
import FormControl from 'src/views/common/FormControl/formControl';
import {
  addDays,
  addHours,
  addMonths,
  addYears,
  setHours,
  setMinutes
} from 'date-fns';
import {
  createOneTime,
  createReccClass
} from 'src/services/classModule.services';
import CustomAutoComplete from 'src/views/common/CustomAutocomplete/commonAutocomplete';
import { getCalenderData } from 'src/Redux/CalenderReducer/Calender.actions';
import { openNoti } from 'src/Redux/global/global.actions';
import {
  AllClassdata,
  futureddata,
  reccurdata
} from 'src/Redux/ClassReducer/classReducer.actions';
import { getErrorMsg } from 'src/utils/ErrorHandling';
import { checkValidString } from 'src/utils/validations';
import JBButton from 'src/views/common/JBButton/JBButton';
import arrowleft2 from '../../../assets/image/arrowleft_v2.png';
import WeekButton from '../Class/weekButton';
import TimePicker from './TimePicker';
import DatePicker from './DatePicker';
import {
  getDOWcolor,
  getRepeatStr,
  getValWeek,
  validateCreate
} from './Dialog.utils';
import dcloseicon from '../../../assets/image/closeicon.png';
import styles from './Dialog.style';
import { dialogRepeat, Weekdata } from './Dialog.Data';
import { EndTypeData, getRepeatWeek } from '../Class/createClass.Data';

function CreateClassDialog(props) {
  const { classes, slots, getCoaches, getprograms, onClose } = props;
  const [editTime, setEditTime] = useState(false);
  const [data, setData] = useState({
    program: null,
    name: '',
    coach: null,
    repeat_type: dialogRepeat[0],
    start_time: slots[0],
    end_time:
      slots[1] ||
      moment(slots[0])
        .add(1, 'hours')
        .toDate()
  });
  const [processing, setProcessing] = useState(false);
  const [reccurrdata, setReccData] = useState({
    end_type: EndTypeData[0],
    day_of_week: getDOWcolor(data.start_time),
    repeatition_week: { id: 1, name: '1' },
    end_date: addMonths(slots[0], 1)
  });

  useEffect(() => {
    if (
      data.program &&
      data.start_time &&
      checkClassName(data.name, data.program)
    ) {
      setData({
        ...data,
        name: data.program.name + ' ' + moment(data.start_time).format('LT')
      });
    }
  }, [data.program, data.start_time]); // eslint-disable-line

  const { dateformat } = useGymSettingRefresh();

  const handleCreate = () => {
    setProcessing(true);
    const values = validateCreate(data, reccurrdata);
    const {
      OpenNotiDisp,
      reloadCalender,
      getFuture,
      getReccClass,
      getAllClass
    } = props;
    if (!values) {
      if (data.start_time.getTime() < new Date().getTime()) {
        OpenNotiDisp('You cant create class in past time', 'error');
        setProcessing(false);
      } else {
        OpenNotiDisp(
          'Please fill valid data and fill all required fields',
          'error'
        );
        setProcessing(false);
      }
    } else if (data.repeat_type.id === 0) {
      createOneTime(values)
        .then(() => reloadCalender())
        .then(() => getFuture())
        .then(() => getAllClass())
        .then(() => onClose())
        .then(() => setProcessing(false))
        .then(() => OpenNotiDisp('Successfully created the class', 'info'))
        .catch((err) => {
          OpenNotiDisp(getErrorMsg(err), 'error');
          setProcessing(false);
        });
    } else {
      createReccClass(values)
        .then(() => reloadCalender())
        .then(() => getFuture())
        .then(() => getAllClass())
        .then(() => getReccClass())
        .then(() => onClose())
        .then(() => setProcessing(false))
        .then(
          () => OpenNotiDisp('Successfully created all the classes', 'info')
          // eslint-disable-next-line function-paren-newline
        )
        .catch((err) => {
          OpenNotiDisp(getErrorMsg(err), 'error');
          setProcessing(false);
        });
    }
  };

  let TimeErr = null;
  const { error } = getMinTimeErr(data.start_time, data.end_time);
  if (error) {
    TimeErr = error;
  }

  return (
    <div>
      {editTime ? (
        <div style={{ width: '280px' }} className={classes.dmainbk}>
          <Grid alignItems="center" container direction="row">
            <Grid
              item
              xs
              onClick={() => setEditTime(false)}
              style={{ cursor: 'pointer' }}
            >
              <Button
                className={classes.btns}
                style={{
                  display: 'inline-block',
                  marginLeft: '11px',
                  marginRight: '0',
                  '&.MuiTouchRipple-root': {
                    display: 'none '
                  }
                }}
              >
                <img alt="img" src={arrowleft2} />
              </Button>
              <DialogTitle
                id="alert-dialog-title"
                color="primary"
                className={classes.titlebk}
                style={{
                  display: 'inline-block'
                }}
              >
                <h4 className={classes.dtitle}>Back to Settings</h4>
              </DialogTitle>
            </Grid>
            <Grid
              item
              xs={2}
              style={{ textAlign: 'right', marginRight: '10px' }}
            >
              <Button className={classes.btns} onClick={() => onClose()}>
                <img alt="img" src={dcloseicon} />
              </Button>
            </Grid>
          </Grid>

          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Grid alignItems="center" spacing={1} container direction="row">
                <Grid item xs={6}>
                  <Box display="block">
                    <label className={classes.dlabel}>Start date</label>
                    <DatePicker
                      value={data.start_time}
                      Change={(val) => {
                        setData({
                          ...data,
                          start_time: val,
                          end_time: addHours(val, 1)
                        });
                        setReccData({
                          ...reccurrdata,
                          day_of_week: getDOWcolor(val),
                          end_date: addMonths(val, 1)
                        });
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box display="block">
                    <label className={classes.dlabel}>End date</label>
                    <h3
                      className={classes.dh3}
                      style={{ display: 'inline-block' }}
                    >
                      {moment(data.start_time).format(`${dateformat}`)}
                    </h3>
                  </Box>
                </Grid>
              </Grid>

              <Grid alignItems="center" spacing={2} container direction="row">
                <Grid item xs={6}>
                  <Box display="block">
                    <label className={classes.dlabel}>Start time</label>
                    <TimePicker
                      value={data.start_time}
                      minTime={setMinStartTime(data.start_time)}
                      maxTime={setHours(setMinutes(data.start_time, 0), 22)}
                      Change={(val) =>
                        setData({
                          ...data,
                          start_time: val,
                          end_time: addHours(val, 1)
                        })
                      } // eslint-disable-line
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box display="block">
                    <label className={classes.dlabel}>End time</label>
                  </Box>
                  <div>
                    <TimePicker
                      value={data.end_time}
                      minTime={data.start_time}
                      maxTime={setHours(setMinutes(data.end_time, 0), 23)}
                      Change={(val) => setData({ ...data, end_time: val })}
                    />
                  </div>
                </Grid>
              </Grid>

              <Box display="block" mt={1} mb={1}>
                <label className={classes.dlabel}>Repeat</label>
                <CustomAutoComplete
                  id="ignorepopup_repeat"
                  holder="Repeatition"
                  value={dialogRepeat}
                  data={data.repeat_type}
                  Options="name"
                  Change={(val) => setData({ ...data, repeat_type: val })}
                  style={{ marginbottom: '0' }}
                />
              </Box>
              {data.repeat_type && data.repeat_type.id !== 0 ? (
                <div>
                  {data.repeat_type.id === 'custom' && (
                    <div>
                      <Grid
                        alignItems="center"
                        spacing={2}
                        container
                        direction="row"
                      >
                        <Grid item xs={5}>
                          <label className={classes.dlabel}>Every</label>
                          <Box display="block">
                            <CustomAutoComplete
                              id="ignorepopup_selectweeks"
                              holder="Select Weeks"
                              value={getRepeatWeek()}
                              Options="name"
                              data={reccurrdata.repeatition_week}
                              Change={(val) =>
                                setReccData({
                                  ...reccurrdata,
                                  repeatition_week: val
                                })
                              } // eslint-disable-line
                              style={{ marginbottom: '0' }}
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={7}>
                          <label className={classes.dlabel}>&nbsp;</label>
                          <Box display="block">
                            <CustomAutoComplete
                              id="ignorepopup_weeks"
                              holder="Weeks"
                              value={[]}
                              data={{ id: 'week', name: 'Weeks' }}
                              Options="name"
                              style={{ marginbottom: '0' }}
                            />
                          </Box>
                        </Grid>
                      </Grid>

                      <div className={classes.weekButtons}>
                        <Box display="block" mt={3} mb={1}>
                          {Weekdata.map((week) => (
                            <WeekButton
                              key={week.id}
                              label={week}
                              data={reccurrdata.day_of_week.filter(
                                (list) => list.id === week.id
                              )}
                              setWeeks={(val) =>
                                setReccData({
                                  ...reccurrdata,
                                  day_of_week: getValWeek(
                                    val,
                                    reccurrdata.day_of_week,
                                    week
                                  )
                                })
                              } // eslint-disable-line
                            />
                          ))}
                        </Box>
                      </div>
                    </div>
                  )}

                  <Grid
                    alignItems="center"
                    spacing={2}
                    container
                    direction="row"
                  >
                    <Grid item xs={7}>
                      <label className={classes.dlabel}>End</label>
                      <CustomAutoComplete
                        id="ignorepopup_selectend"
                        holder="Select End Type"
                        value={EndTypeData}
                        Options="name"
                        data={reccurrdata.end_type}
                        Change={(val) =>
                          setReccData({ ...reccurrdata, end_type: val })
                        } // eslint-disable-line
                        style={{ marginbottom: '0' }}
                      />
                    </Grid>
                    {reccurrdata.end_type &&
                    reccurrdata.end_type.id === 'ondate' ? (
                      <Grid item xs={5}>
                        <label className={classes.dlabel}>&nbsp;</label>
                        <Box display="block">
                          <h3 className={classes.dh3}>
                            <DatePicker
                              value={reccurrdata.end_date}
                              minDate={addDays(data.start_time, 1)}
                              maxDate={addYears(data.start_time, 1)}
                              Change={(val) =>
                                setReccData({ ...reccurrdata, end_date: val })
                              } // eslint-disable-line
                            />
                          </h3>
                        </Box>
                      </Grid>
                    ) : null}
                  </Grid>

                  <div className={classes.ptag}>
                    {reccurrdata.end_type && (
                      <Box display="block">
                        <p style={{ wordWrap: 'break-word' }}>
                          {getRepeatStr(
                            data.repeat_type,
                            reccurrdata,
                            data.start_time
                          )}
                        </p>
                      </Box>
                    )}
                  </div>
                </div>
              ) : null}
            </DialogContentText>
          </DialogContent>
        </div>
      ) : (
        <div style={{ width: '280px' }} className={classes.dmainbk}>
          <Grid alignItems="center" container direction="row">
            <Grid item xs>
              <DialogTitle
                id="alert-dialog-title"
                color="primary"
                className={classes.titlebk}
              >
                <h4 className={classes.dtitle}>Create Class</h4>
              </DialogTitle>
            </Grid>
            <Grid
              item
              xs={2}
              style={{ textAlign: 'right', marginRight: '10px' }}
            >
              <Button className={classes.btns} onClick={() => onClose()}>
                <img alt="img" src={dcloseicon} />
              </Button>
            </Grid>
          </Grid>

          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Box display="block">
                {/* <label className={classes.dlabel} required>
                  Program
                </label> */}
                <InputLabel
                  htmlFor="age-required"
                  required
                  className={classes.dlabel}
                  style={{ padding: '5px 0' }}
                >
                  Program
                </InputLabel>
                <CustomAutoComplete
                  id="ignorepopup_selectend"
                  holder="Choose a program"
                  Options="name"
                  data={data.program}
                  value={getprograms}
                  Change={(val) =>
                    setData({
                      ...data,
                      program: val
                    })
                  } // eslint-disable-line
                  style={{ marginbottom: '0' }}
                />
              </Box>

              <Box display="block" mt={1} mb={1}>
                {/* <label className={classes.dlabel}>Date & time</label> */}
                <InputLabel
                  htmlFor="age-required"
                  required
                  className={classes.dlabel}
                  style={{ padding: '5px 0' }}
                >
                  Date & time
                </InputLabel>
                <Link
                  component="button"
                  onClick={() => setEditTime(true)}
                  className={classes.dLink}
                >
                  Edit
                </Link>
                <h3 className={classes.dh3}>
                  {moment(data.start_time).format('MMM DD,') +
                    ' ' +
                    moment(data.start_time).format('LT') +
                    ' - ' +
                    moment(
                      data.repeat_type && data.repeat_type.id === 0
                        ? data.start_time
                        : reccurrdata.end_date
                    ).format('MMM DD,') +
                    ' ' +
                    moment(data.end_time).format('LT')}
                </h3>
              </Box>
              {TimeErr && (
                <p style={{ color: 'red', fontSize: '12px' }}>{TimeErr}</p>
              )}
              <Box display="block" mt={1} mb={1}>
                {data.repeat_type && data.repeat_type.id !== 0 ? (
                  <p style={{ fontSize: '12px', wordWrap: 'break-word' }}>
                    {getRepeatStr(
                      data.repeat_type,
                      reccurrdata,
                      data.start_time
                    )}
                  </p>
                ) : null}
                {/* <label className={classes.dlabel}>Name</label> */}
                <InputLabel
                  htmlFor="age-required"
                  required
                  className={classes.dlabel}
                  style={{ padding: '0px 0' }}
                >
                  Name
                </InputLabel>
                <FormControl
                  control="input"
                  label=""
                  value={data.name}
                  onChange={(e) => setData({ ...data, name: e.target.value })}
                  required
                  style={{ margin: '0 0' }}
                  seterrors={
                    checkValidString(data.name) || data.name.length === 0
                  }
                  seterrortext="Please provide valid class name"
                />
              </Box>

              <Box display="block" mt={1} mb={1}>
                <label className={classes.dlabel}>Coach</label>

                <CustomAutoComplete
                  id="ignorepopup_coach"
                  holder="Pick a coach"
                  value={getCoaches}
                  Options="name"
                  data={data.coach}
                  Change={(val) => {
                    setData({ ...data, coach: val });
                  }}
                  style={{ marginbottom: '0' }}
                />
              </Box>

              <div className={classes.d_button}>
                <Box display="block" mt={2} mb={1}>
                  <JBButton
                    type="outlined"
                    fullWidth
                    onClick={handleCreate}
                    disabled={processing}
                  >
                    Create
                  </JBButton>
                </Box>
              </div>
            </DialogContentText>
          </DialogContent>
        </div>
      )}
    </div>
  );
}

CreateClassDialog.propTypes = {
  classes: PropType.object.isRequired,
  slots: PropType.array.isRequired,
  getprograms: PropType.array.isRequired,
  getCoaches: PropType.array.isRequired,
  OpenNotiDisp: PropType.func.isRequired,
  getReccClass: PropType.func.isRequired,
  getAllClass: PropType.func.isRequired,
  getFuture: PropType.func.isRequired,
  reloadCalender: PropType.func.isRequired,
  onClose: PropType.func.isRequired
};

const mapToState = (state) => ({
  getprograms: state.allprograms.programs,
  getCoaches: state.classReducer.coaches
});

const mapToDispatch = (dispatch) => ({
  OpenNotiDisp: (msg, type) => dispatch(openNoti(msg, type)),
  getFuture: () => dispatch(futureddata()),
  getAllClass: () => dispatch(AllClassdata()),
  getReccClass: () => dispatch(reccurdata()),
  reloadCalender: () => dispatch(getCalenderData())
});

export default connect(
  mapToState,
  mapToDispatch
)(withStyles(styles)(CreateClassDialog));
