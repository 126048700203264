const styles = () => ({
  root: {
    marginTop: 0,
    margin: '0px 20px 20px',
    padding: '0px 20px 20px'
  },
  mainCard: {
    // boxShadow: '4px 0px 16px rgba(37, 40, 43, 0.08)',
    // borderRadius: '5px',
    backgroundColor: 'transparent'
    // padding: 20
  },
  mainList: {
    width: 'calc(100% - 10%)',
    height: '160px',
    textAlign: 'left',
    display: 'flex',
    justifyContent: 'start',
    margin: '0 auto 30px',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    padding: '40px',
    borderRadius: '10px',
    '@media (max-width: 992px)': {
      padding: '30px',
      height: '120px'
    }
  },
  mainTitle: {
    fontWeight: '500',
    fontSize: '48px',
    lineHeight: '44px',
    color: '#FFFFFF',
    fontFamily: 'DDC Hardware',
    '@media (max-width: 992px)': {
      fontSize: '35px'
    }
  },
  mainArrowRight: {
    position: 'absolute',
    right: '35px'
  },
  LockIcon: {
    marginRight: '25px'
  },
  logoMsize: {
    margin: '30px auto 50px',
    textAlign: 'center',
    '@media (max-width: 992px)': {
      margin: '30px auto 30px'
    }
  },
  potential: {
    background: 'rgba(29, 29, 29, 0.94)',
    boxShadow: '0px 0.33333px 0px rgba(255, 255, 255, 0.15)',
    width: '100%',
    height: '92px',
    position: 'fixed',
    bottom: '0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    left: '0',
    right: '0',
    cursor: 'pointer',
    '& h4': {
      fontWeight: '500',
      fontSize: '32px',
      lineHeight: '44px',
      color: '#FFFFFF',
      fontFamily: 'DDC Hardware'
    }
  },
  poteIcon: {
    marginLeft: '15px'
  },
  appBar: {
    position: 'relative',
    background: 'rgba(29, 29, 29, 0.94)',
    boxShadow: '0px 0.33333px 0px rgb(255 255 255 / 15%)',
    backdropFilter: 'blur(54.3656px)'
  },
  appToolbar: {
    minHeight: '75px',
    justifyContent: 'space-between'
  },
  // title: {
  //   marginLeft: theme.spacing(2),
  //   flex: 1
  // },
  // mainTitle: {
  //   fontWeight: '500',
  //   fontSize: '48px',
  //   lineHeight: '44px',
  //   color: '#FFFFFF',
  //   fontFamily: 'DDC Hardware',
  //   textAlign: 'center',
  //   margin: '48px 0 0 0'
  // },
  MainCard: {
    height: 'calc(100vh - 75px )',
    background:
      'linear-gradient(180deg, rgba(41, 41, 41, 0.95) 0%, rgba(4, 4, 4, 0.95) 100%)',
    borderRadius: '0',
    flex: '1 1 auto',
    // height: '100%',
    overflow: 'auto',
    position: 'relative'
  },
  HomeButton: {
    fontFamily: 'Lato',
    color: '#2CE9DA',
    fontWeight: 'bold',
    fontSize: '22px',
    lineHeight: '32px',
    textTransform: 'capitalize'
  },
  headerTitle: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '26px'
  }
});
export default styles;
