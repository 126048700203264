import types from './MemProfile.types';

export const getUser = (data, action) => {
  const { payload, actionType } = action;
  switch (actionType) {
    case types.fetchingUser:
      return {
        ...data,
        isFetching: true
      };
    case types.fetchdata:
      return {
        ...data,
        isFetching: false,
        data: payload
      };
    case types.errorUser:
      return {
        ...data,
        isFetching: false,
        error: payload
      };
    default:
      return data;
  }
};

export const fetchedUser = (payload) => ({
  type: types.fetchdata,
  actionType: types.fetchdata,
  payload
});

export const errorUser = (payload) => ({
  type: types.fetchdata,
  actionType: types.errorUser,
  payload
});

export const fetchingUser = () => ({
  type: types.fetchdata,
  actionType: types.fetchingUser
});

export const changeMemTab = (payload) => ({
  type: types.changeTab,
  payload
});

export const updateunpaidFil = (filter, payload) => ({
  type: types.updateunpaidFil,
  filter,
  payload
});

export const updatepaidFil = (filter, payload) => ({
  type: types.updatepaidFil,
  filter,
  payload
});

export const updateallFil = (filter, payload) => ({
  type: types.updateallFil,
  filter,
  payload
});

export const updateFailedFil = (filter, payload) => ({
  type: types.updateFailedFil,
  filter,
  payload
});

export const updateOverdueFil = (filter, payload) => ({
  type: types.updateOverdueFil,
  filter,
  payload
});

export const updateProcesingFil = (filter, payload) => ({
  type: types.updateProcessingFil,
  filter,
  payload
});

export const updateActMemFil = (filter, payload) => ({
  type: types.updateActiveMemFil,
  filter,
  payload
});

export const updateInActMemFil = (filter, payload) => ({
  type: types.updateInActMemFil,
  filter,
  payload
});

export const updateMemHoldFil = (filter, payload) => ({
  type: types.updateMemHoldFil,
  filter,
  payload
});

export const ActivityHistoryFilter = (filter, payload) => ({
  type: types.ActivityHistoryFilter,
  filter,
  payload
});

export const AttendanceFilter = (filter, payload) => ({
  type: types.attendanceFilter,
  filter,
  payload
});

export const fetchingInvoice = (type) => ({
  type,
  actionType: types.isFetching
});

export const fetchComplete = (type, payload) => ({
  type,
  actionType: types.fetchSucess,
  payload
});

export const ErrorFetching = (type, payload) => ({
  type,
  actionType: types.Errorfetch,
  payload
});

export const updateStatus = (payload) => ({
  type: types.updateStatus,
  payload
});

export const ProfileChange = (payload) => ({
  type: types.ProfileChange,
  payload
});

export const getUnpaids = (id, state) => {
  const fildata = state.unpaidFilter;
  const updatedValue = {
    ...fildata.default,
    ...fildata.sorting,
    page: fildata.page,
    user_id: id
  };
  return updatedValue;
};

export const getpaids = (id, state) => {
  const fildata = state.paidFilter;
  const updatedValue = {
    ...fildata.default,
    ...fildata.sorting,
    page: fildata.page,
    user_id: id
  };
  return updatedValue;
};

export const getProcessing = (id, state) => {
  const fildata = state.processingFilter;
  const updatedValue = {
    ...fildata.default,
    ...fildata.sorting,
    page: fildata.page,
    user_id: id
  };
  return updatedValue;
};

export const getFailed = (id, state) => {
  const fildata = state.failedFilter;
  const updatedValue = {
    ...fildata.default,
    ...fildata.sorting,
    page: fildata.page,
    user_id: id
  };
  return updatedValue;
};

export const getOverdue = (id, state) => {
  const fildata = state.overdueFilter;
  const updatedValue = {
    ...fildata.default,
    ...fildata.sorting,
    page: fildata.page,
    user_id: id
  };
  return updatedValue;
};

export const getAllInvs = (id, state) => {
  const fildata = state.allFilter;
  const updatedValue = {
    ...fildata.default,
    ...fildata.sorting,
    page: fildata.page,
    user_id: id
  };
  return updatedValue;
};

export const getActMems = (id, state) => {
  const filData = state.ActmembershipFil;
  const updateVal = {
    ...filData.default,
    ...filData.sorting,
    user_id: id
  };
  return updateVal;
};

export const getInActMems = (id, state) => {
  const filData = state.InActmembershipFil;
  const updateVal = {
    ...filData.default,
    ...filData.sorting,
    user_id: id
  };
  return updateVal;
};

export const getMemHolds = (id, state) => {
  const filData = state.MemholdFil;
  const updateVal = {
    ...filData.default,
    ...filData.sorting,
    user_id: id
  };
  return updateVal;
};

export const getActivityHistory = (id, state) => {
  const filData = state.activityHistoryFilter;
  const updateVal = {
    ...filData.default,
    ...filData.sorting,
    page: filData.page,
    user_id: id
  };
  return updateVal;
};

export const getAttendance = (id, state) => {
  const filData = state.attendFilter;
  const updateVal = {
    ...filData.default,
    ...filData.sorting,
    ...filData.customFilter,
    page: filData.page,
    user_id: id
  };
  if (filData.search.length > 0) {
    updateVal.search = filData.search;
  }
  return updateVal;
};
