import moment from 'moment';
import { GymTimeFormat } from 'src/constant/GymDateTime';
// eslint-disable-next-line
export const getInvoiceData = (data) => {
  const b = {
    ...data,
    get_user_name: {
      id: data.get_user_name.id,
      name: data.get_user_name.first_name + ' ' + data.get_user_name.last_name,
      email: data?.get_user_name?.email
    },
    due_date: moment(data.due_date, 'YYYY-MM-DD').toDate(),
    auto_bill: data.auto_bill === 1
  };
  return b;
};

export const myInvoiceFinal = (data) => {
  if (!data.saleprice || !data.qty || data.discount === '') {
    return 0;
  }

  let tax = 0;
  if (data.tax) {
    tax = data.tax.rate;
  }
  const salesPrice = parseInt(data.saleprice, 10);
  const quantity = parseInt(data.qty, 10);
  const totalPrice = salesPrice * quantity;
  const priceWidouthTax = totalPrice / (1 + tax / 100);
  // const gts = totalPrice - priceWidouthTax;
  let finalPrice = priceWidouthTax;

  if (parseInt(data.discount, 10) > 0) {
    if (data.discount_type === '2') {
      finalPrice -= finalPrice * (parseInt(data.discount, 10) / 100);
    } else {
      finalPrice -= parseInt(data.discount, 10);
    }
  }

  return finalPrice;
};

export const invoiceFinal = (data) => {
  if (!data.saleprice || !data.qty || data.discount === '') {
    return 0;
  }
  let tax = null;
  if (data.tax) {
    tax = data.tax.rate;
  }
  const salesP = parseInt(data.saleprice, 10);
  const qty = parseInt(data.qty, 10);
  const pricesum = salesP * qty;
  let taxed = pricesum;
  if (tax !== null || tax !== 0) {
    taxed = pricesum * (tax / 100);
    taxed += pricesum;
  }
  let final = taxed;
  if (parseInt(data.discount, 10) > 0) {
    if (data.discount_type === '2') {
      final = taxed * (parseInt(data.discount, 10) / 100);
      final = taxed - final;
    } else {
      final -= parseInt(data.discount, 10);
    }
  }
  return final;
};

export const getInvoiceStatus = (status) => {
  switch (status) {
    case 1:
      return 'Unpaid';
    case 2:
      return 'Paid';
    case 3:
      return 'Void';
    case 5:
      return 'Refunded';
    case 6:
      return 'Processing';
    case 7:
      return 'Failed';
    case 8:
      return 'Required Action';
    case 9:
      return 'Refund Pending';
    default:
      return 'UNKNOWN';
  }
};

export function getCreatedOn(data, timefor) {
  const createdon = data?.created_on?.split(' ');
  const time = timefor === GymTimeFormat.twelve
    ? moment(createdon[1], 'HH:mm:ss').format('h:mm A')
    : createdon[1];
  const date = createdon[0] + ' ' + time;
  const name = data.get_created_user_name
    ? data.get_created_user_name?.first_name
      + ' '
      + data.get_created_user_name?.last_name
    : 'Admin';

  return date + ' by ' + name;
}

export function getUpdatedOn(data, timefor) {
  const updated_on = data?.updated_on?.split(' ');
  const time = timefor === GymTimeFormat.twelve
    ? moment(updated_on[1], 'HH:mm:ss').format('h:mm A')
    : updated_on[1];
  const date = updated_on[0] + ' ' + time;
  const name = data.get_updated_user_name
    ? data.get_updated_user_name?.first_name
      + ' '
      + data.get_updated_user_name?.last_name
    : 'Admin';
  return date + ' by ' + name;
}
