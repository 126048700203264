import React from 'react';
import PropType from 'prop-types';
import Inputs from './inputControl';

const FormControl = ({ control, ...props }) => {
  switch (control) {
    case 'input':
      return <Inputs {...props} />;
    case 'textarea':
      return <Inputs as="textarea" {...props} />;
    default:
      return null;
  }
};

FormControl.propTypes = {
  control: PropType.string.isRequired
};

export default FormControl;
