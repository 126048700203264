/**
 * Noti control:
 * This control is a notification control.
 * To be used as a notification alert system wide.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'redux';
// import Slide from '@material-ui/core/Slide';
import Snackbar from '@material-ui/core/Snackbar';
import Fade from '@material-ui/core/Fade';
// import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { CloseIcon } from '../../../icons/common';
import { NOTI } from '../../../constant/Constants';

const styles = (theme) => ({
  rootInfo: {
    background: theme.palette.primary.main
  },
  rootError: {
    background: 'red',
    color: 'white'
  },
  rootWarning: {
    background: '#f79517',
    color: 'white'
  },
  close: {
    width: theme.spacing(1) * 4,
    height: theme.spacing(1) * 4
  },
  snackbar: {
    margin: theme.spacing(1),
    alignItems: 'flex-start',
    '& .MuiSnackbarContent-message': {
      width: 'calc(100% - 40px)'
    }
  }
});

// const transitionDirection = {
//   down: 'down',
//   up: 'up',
//   left: 'left',
//   right: 'right',
// };

const notiTypes = {
  error: 'error',
  info: 'information',
  warning: 'warning'
};
const Noti = (props) => {
  let vertical = '';
  let horizontal = '';
  const {
    open,
    autoHideDuration,
    handleOnRequestClose,
    handleOnRequestExited,
    message,
    type,
    classes,
    marginDiff
  } = props;

  switch (props.position) {
    case NOTI.POSITION_BOTTOM_CENTER:
      vertical = 'bottom';
      horizontal = 'center';
      break;
    case NOTI.POSITION_BOTTOM_LEFT:
      vertical = 'bottom';
      horizontal = 'left';
      break;
    case NOTI.POSITION_BOTTOM_RIGHT:
      vertical = 'bottom';
      horizontal = 'right';
      break;
    case NOTI.POSITION_TOP_CENTER:
      vertical = 'top';
      horizontal = 'center';
      break;
    case NOTI.POSITION_TOP_LEFT:
      vertical = 'top';
      horizontal = 'left';
      break;
    case NOTI.POSITION_TOP_RIGHT:
      vertical = 'top';
      horizontal = 'right';
      break;
    default:
      break;
  }
  let notiClass = '';
  if (type === notiTypes.error) {
    notiClass = classes.rootError;
  } else if (type === notiTypes.warning) {
    notiClass = classes.rootWarning;
  } else {
    notiClass = classes.rootInfo;
  }
  notiClass = `${notiClass} ${classes.snackbar}`;
  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={handleOnRequestClose}
      onExited={handleOnRequestExited}
      message={message}
      ContentProps={{
        classes: {
          root: notiClass
        }
      }}
      style={marginDiff > 0 ? { marginBottom: marginDiff } : null}
      action={[
        // <Button key="undo" color="secondary" dense onClick={props.handleOnRequestClose}>
        //   UNDO
        // </Button>,
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={handleOnRequestClose}
        >
          <CloseIcon />
        </IconButton>
      ]}
      TransitionComponent={Fade}
    />
  );
};
// transition={<Slide direction={transitionDirection.down} />}

Noti.defaultProps = {
  open: true,
  position: NOTI.POSITION_BOTTOM_CENTER,
  autoHideDuration: null, // null will don't autohide the noti
  type: notiTypes.info,
  marginDiff: 0,
  handleOnRequestExited: null
};

Noti.propTypes = {
  open: PropTypes.bool,
  type: PropTypes.string,
  autoHideDuration: PropTypes.number, // pass null to disable autohide
  position: PropTypes.string,
  message: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  marginDiff: PropTypes.number,
  classes: PropTypes.object.isRequired,
  handleOnRequestClose: PropTypes.func.isRequired,
  handleOnRequestExited: PropTypes.func
};

export default compose(withStyles(styles))(Noti);
