const paymentdata = {
  id: 78,
  membership_id: 37,
  name: '',
  location_id: null,
  tax_id: 1,
  initial_commitment: {
    initial: 10,
    billed_every: { id: 2, name: '2' }
  },
  auto_renew: 0,
  auto_renew_meta: {
    billed_every: null,
    renewal: null
  },
  initial_commitment_pricing: {
    full_setup_fees: 0,
    full_membership_fees: 5,
    full_total: 5,
    full_tax_amount: 0
  },
  auto_renewal_pricing: {},
  status: 1,
  programes: [],
  membership: {
    id: 37,
    contract_id: 10,
    reve_cat_id: 1,
    name: '',
    description: '',
    membership_type: '',
    attendance_limit: { id: 0, name: 'Unlimited' },
    attendance_limited_meta: null,
    no_of_sessions: 2,
    status: 1,
    program_ids: '1,3'
  }
};

const discounts = {
  initial: {
    amount: 0,
    type: 'percentage'
  },
  setup: {
    amount: 0,
    type: 'percentage'
  },
  renewal: {
    amount: 0,
    type: 'percentage'
  }
};

export const initialValue = {
  data: {
    type: 'plan',
    membership_id: null,
    membership_payment_id: null,
    error: null,
    payments: [],
    start_date: null,
    initial_pricing: null,
    renewal_pricing: null,
    usedsession: 0,
    discount: discounts,
    expires_date: null,
    program_access: [],
    payment_data: paymentdata,
    membership_contract: null
  }
};

export const memshipType = {
  membershipid: 'CHANGE_MEMBERSHIP_ID',
  getallpayments: 'FETCHED_PAYMENTS',
  updatepaymentid: 'UPDATE_PAYMENT_ID',
  fetchpaymentdata: 'FETCH_MEMBERSHIP_PAYMENT_DATA',
  changestartdate: 'CHANGE_PAYMENT_DATA_START_DATE',
  changeenddate: 'CHANGE_PAYMENT_DATA_END_DATE',
  editusedsession: 'CHANGE_DATA_USED_SESSION',
  editnumsession: 'CHANGE_DATA_NO_OF_SESSION',
  editattendlmt: 'CHANGE_ATTENDANCE_DATA_LIMIT',
  editinitialsweek: 'CHANGE_PAYMENT_DATA_INITIALS',
  editinitialbilled: 'CHANGE_INITIAL_BILLED_EVERY',
  editattuses: 'CHANGE_ATTENDANCE_USES',
  editatttype: 'CHANGE_ATTENDANCE_TYPE',
  editnofsess: 'CHANGE_NO_OF_SESSIONS',
  chngeinitialPrice: 'CHANGE_INITIAL_PRICING',
  chngerenwalPrice: 'CHANGE_RENEWAL_PRICING',
  chngeautorenew: 'CHANGE_AUTO_RENEW',
  chngeautorenwal: 'CHANGE_AUTO_RENEW_RENEWAL',
  chngeautorenbilled: 'CHANGE_AUTO_RENEW_BILLED',
  changeinidistype: 'CHANGE_INITIAL_DISCOUNT_TYPE',
  changeinidisamount: 'CHANGE_INITIAL_DISCOUNT_AMOUNT',
  changesetdistype: 'CHANGE_SETUP_DISCOUNT_TYPE',
  changesetdisamount: 'CHANGE_SETUP_DISCOUNT_AMOUNT',
  changerendistype: 'CHANGE_RENEWAL_DISCOUNT_TYPE',
  changerendisamount: 'CHANGE_RENEWAL_DISCOUNT_AMOUNT',
  chngesetupprice: 'CHANGE_MEMBERSHIP_SETUP_PRICE',
  chngeiniprice: 'CHANGE_MEMBERSHIP_INITIAL_PRICE',
  chngerenewalfee: 'CHANGE_AUTOREWAL_MEMBERSHIP_PRICE',
  acesss_program: 'CHANGE_TO_ACESS_PROGRAMS',
  changesigncontract: 'CHANGE_MEMBERSHIP_CONTRACT',
  changeinitaxes: 'CHANGE_INITIAL_TAXES',
  changerenewaltaxes: 'CHANGE_RENEWAL_TAXES',
  updateAlldate: 'UPDATE_ALL_DATE'
};

const getMembershipType = (val) => {
  switch (val) {
    case 0:
      return 'plan';

    case 1:
      return 'session';

    case 2:
      return 'drop_in';

    default:
      return 'plan';
  }
};

export const membershipReducer = (state = initialValue, action) => {
  switch (action.type) {
    case memshipType.membershipid:
      return {
        ...state,
        data: {
          ...state.data,
          membership_payment_id: null,
          membership_id: action.payload,
          type: getMembershipType(action.memType),
          // type: action.memType === 0 ? 'plan' : 'session',
          payments: [],
          payment_data: paymentdata,
          start_date: null,
          initial_pricing: null,
          renewal_pricing: null,
          discount: discounts,
          program_access: [],
          membership_contract: null
        }
      };
    case memshipType.updateAlldate:
      return {
        ...state,
        data: action.payload
      };
    case memshipType.getallpayments:
      return {
        ...state,
        data: {
          ...state.data,
          payments: action.payload
        }
      };
    case memshipType.updatepaymentid:
      return {
        ...state,
        data: {
          ...state.data,
          membership_payment_id: action.payload
        }
      };
    case memshipType.fetchpaymentdata:
      return {
        ...state,
        data: {
          ...state.data,
          payment_data: action.payload
        }
      };
    case memshipType.changestartdate:
      return {
        ...state,
        data: {
          ...state.data,
          start_date: action.payload
        }
      };
    case memshipType.changeenddate:
      return {
        ...state,
        data: {
          ...state.data,
          expires_date: action.payload
        }
      };
    case memshipType.editattendlmt:
      return {
        ...state,
        data: {
          ...state.data,
          payment_data: {
            ...state.data.payment_data,
            membership: {
              ...state.data.payment_data.membership,
              attendance_limit: action.payload,
              attendance_limited_meta: { uses: null, type: null }
            }
          }
        }
      };
    case memshipType.editinitialbilled:
      return {
        ...state,
        data: {
          ...state.data,
          payment_data: {
            ...state.data.payment_data,
            initial_commitment: {
              ...state.data.payment_data.initial_commitment,
              billed_every: action.payload
            }
          }
        }
      };
    case memshipType.editinitialsweek:
      return {
        ...state,
        data: {
          ...state.data,
          payment_data: {
            ...state.data.payment_data,
            initial_commitment: {
              ...state.data.payment_data.initial_commitment,
              initial: action.payload
            }
          }
        }
      };
    case memshipType.editnumsession:
      return {
        ...state,
        data: {
          ...state.data,
          payment_data: {
            ...state.data.payment_data,
            membership: {
              ...state.data.payment_data.membership,
              no_of_sessions: action.payload
            }
          }
        }
      };
    case memshipType.editusedsession:
      return {
        ...state,
        data: {
          ...state.data,
          usedsession: action.payload
        }
      };
    case memshipType.editattuses:
      return {
        ...state,
        data: {
          ...state.data,
          payment_data: {
            ...state.data.payment_data,
            membership: {
              ...state.data.payment_data.membership,
              attendance_limited_meta: {
                ...state.data.payment_data.membership.attendance_limited_meta,
                uses: action.payload
              }
            }
          }
        }
      };
    case memshipType.editatttype:
      return {
        ...state,
        data: {
          ...state.data,
          payment_data: {
            ...state.data.payment_data,
            membership: {
              ...state.data.payment_data.membership,
              attendance_limited_meta: {
                ...state.data.payment_data.membership.attendance_limited_meta,
                type: action.payload
              }
            }
          }
        }
      };
    case memshipType.chngeinitialPrice:
      return {
        ...state,
        data: {
          ...state.data,
          initial_pricing: action.payload
        }
      };
    case memshipType.chngerenwalPrice:
      return {
        ...state,
        data: {
          ...state.data,
          renewal_pricing: action.payload
        }
      };
    case memshipType.chngeautorenwal:
      return {
        ...state,
        data: {
          ...state.data,
          payment_data: {
            ...state.data.payment_data,
            auto_renew_meta: {
              ...state.data.payment_data.auto_renew_meta,
              renewal: action.payload
            }
          }
        }
      };
    case memshipType.chngeautorenbilled:
      return {
        ...state,
        data: {
          ...state.data,
          payment_data: {
            ...state.data.payment_data,
            auto_renew_meta: {
              ...state.data.payment_data.auto_renew_meta,
              billed_every: action.payload
            }
          }
        }
      };
    case memshipType.chngeautorenew:
      return {
        ...state,
        data: {
          ...state.data,
          payment_data: {
            ...state.data.payment_data,
            auto_renew: !state.data.payment_data.auto_renew
          }
        }
      };
    case memshipType.changeinidistype:
      return {
        ...state,
        data: {
          ...state.data,
          discount: {
            ...state.data.discount,
            initial: {
              ...state.data.discount.initial,
              type: action.payload
            }
          }
        }
      };
    case memshipType.changeinidisamount:
      return {
        ...state,
        data: {
          ...state.data,
          discount: {
            ...state.data.discount,
            initial: {
              ...state.data.discount.initial,
              amount: action.payload
            }
          }
        }
      };
    case memshipType.changesetdistype:
      return {
        ...state,
        data: {
          ...state.data,
          discount: {
            ...state.data.discount,
            setup: {
              ...state.data.discount.setup,
              type: action.payload
            }
          }
        }
      };
    case memshipType.changesetdisamount:
      return {
        ...state,
        data: {
          ...state.data,
          discount: {
            ...state.data.discount,
            setup: {
              ...state.data.discount.setup,
              amount: action.payload
            }
          }
        }
      };
    case memshipType.changerendistype:
      return {
        ...state,
        data: {
          ...state.data,
          discount: {
            ...state.data.discount,
            renewal: {
              ...state.data.discount.renewal,
              type: action.payload
            }
          }
        }
      };
    case memshipType.changerendisamount:
      return {
        ...state,
        data: {
          ...state.data,
          discount: {
            ...state.data.discount,
            renewal: {
              ...state.data.discount.renewal,
              amount: action.payload
            }
          }
        }
      };
    case memshipType.chngeiniprice:
      return {
        ...state,
        data: {
          ...state.data,
          payment_data: {
            ...state.data.payment_data,
            initial_commitment_pricing: {
              ...state.data.payment_data.initial_commitment_pricing,
              [action.actionKey]: action.payload
            }
          }
        }
      };
    case memshipType.chngesetupprice:
      return {
        ...state,
        data: {
          ...state.data,
          payment_data: {
            ...state.data.payment_data,
            initial_commitment_pricing: {
              ...state.data.payment_data.initial_commitment_pricing,
              [action.actionKey]: action.payload
            }
          }
        }
      };
    case memshipType.chngerenewalfee:
      return {
        ...state,
        data: {
          ...state.data,
          payment_data: {
            ...state.data.payment_data,
            auto_renewal_pricing: {
              ...state.data.payment_data.auto_renewal_pricing,
              [action.actionKey]: action.payload
            }
          }
        }
      };
    case memshipType.changeinitaxes:
      return {
        ...state,
        data: {
          ...state.data,
          payment_data: {
            ...state.data.payment_data,
            initial_commitment_pricing: {
              ...state.data.payment_data.initial_commitment_pricing,
              ...action.payload
            }
          }
        }
      };
    case memshipType.changerenewaltaxes:
      return {
        ...state,
        data: {
          ...state.data,
          payment_data: {
            ...state.data.payment_data,
            auto_renewal_pricing: {
              ...state.data.payment_data.auto_renewal_pricing,
              ...action.payload
            }
          }
        }
      };
    case memshipType.acesss_program:
      return {
        ...state,
        data: {
          ...state.data,
          program_access: action.payload
        }
      };
    case memshipType.changesigncontract:
      return {
        ...state,
        data: {
          ...state.data,
          membership_contract: action.payload
        }
      };
    default:
      return state;
  }
};
