import React from 'react';
import PropType from 'prop-types';

const CalendeDayHeader = (props) => {
  const { label } = props;
  return (
    <div>
      <div>{label}</div>
    </div>
  );
};

CalendeDayHeader.propTypes = {
  label: PropType.string.isRequired
};

export default CalendeDayHeader;
