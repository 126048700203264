import React, { createContext } from 'react';
import {
  Button,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuList
} from '@material-ui/core';
import PropType from 'prop-types';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import useStyles from './applyfilter.style';

export const FilterContext = createContext();

const ApplyFilter = (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }
  return (
    <div className={classes.root}>
      <Button
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={() => setOpen(!open)}
        variant="outlined"
        style={{
          borderRadius: '2px',
          zIndex: '9',
          width: '235px',
          height: '48px',
          color: '#000',
          maxWidth: '235px',
          justifyContent: 'flex-start'
        }}
      >
        <span
          style={{
            width: '100%',
            textAlign: 'left',
            color: '#000000',
            textTransform: 'capitalize',
            fontWeight: 'bold',
            fontSize: '14px',
            lineHeight: '18px'
          }}
        >
          Apply filter
        </span>
        {open ? <ExpandLess /> : <ExpandMore />}
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{
          zIndex: '9',
          minWidth: '235px',
          maxWidth: '235px',
          width: '235px',
          outline: 'none !important'
        }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
              padding: '10px',
              outline: 'none!important'
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  <FilterContext.Provider value={[open, setOpen]}>
                    {props.children}
                  </FilterContext.Provider>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

ApplyFilter.propTypes = {
  children: PropType.node.isRequired
};

export default ApplyFilter;
