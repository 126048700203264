/* eslint-disable no-else-return */
import React, { useEffect, useState } from 'react';
import PropType from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import {
  Grid,
  InputLabel,
  Breadcrumbs,
  Typography,
  Box,
  withStyles
} from '@material-ui/core';
import {
  createAffiliate,
  checkEmailExistsAlly,
  getAffiliateCurrency
} from 'src/services/masterHQ.services';
import FormControl from 'src/views/common/FormControl/formControl';
import { useHistory } from 'react-router-dom';
import { openNoti } from 'src/Redux/global/global.actions';
import { ReactComponent as BasicInformation } from 'src/assets/image/JBSteppericon/BasicInformation.svg';
import { ReactComponent as MapPin } from 'src/assets/image/mappin.svg';
import CustomAutoComplete from 'src/views/common/CustomAutocomplete/commonAutocomplete';
import JBStepper from 'src/views/common/JBGridStepper';
import ColorlibStepIcon from 'src/views/common/JBGridStepper/ColorlibStepIcon';
import { ALlStatus } from 'src/views/people/Members/CreateMember/CreateMember.data';
import CancelDialog from 'src/views/common/CommonDialogBox/CancelDialog';
import { getErrorMsg } from 'src/utils/ErrorHandling';
import {
  getActiveAllies,
  getAllAllies,
  getInActiveAllies
} from 'src/Redux/MasterHQ/AlliesReducer/allies.reducer.actions';
import { ROUTES } from 'src/constant/Constants';
import {
  postalRegEx,
  validEmailRegEx,
  validMobile,
  validPhoneRegEx
} from 'src/utils/validations';
import CancelDialogTwo from 'src/views/common/CommonDialogBox/CancelDialogTwo';
import useChangeTitle from 'src/views/common/CustomHooks/useTitleChange';
import styles from './affiliatestable.style';
import {
  isValidAllyStep1,
  isValidAllyStep2,
  isvalidStep1,
  isvalidStep2,
  Step1Errors,
  Step2Errors,
  getErrorFieldName
} from './AffiliatesCreate.validations';

function getSteps() {
  return ['Main Contact Information', 'Location'];
}

const AffiliatesCreate = (props) => {
  const { classes } = props;
  useChangeTitle('Create New Ally | Jungle Alliance');
  const countriesData = useSelector(
    (state) => state.SettingReducer.countries?.data
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const [processing, setProcessing] = useState(() => false);
  const [cancelDialog, setcancelDialog] = useState(false);
  const [currencyData, setcurrencyData] = useState();

  const [data, setData] = useState(() => ({
    gymname: '',
    firstname: '',
    lastname: '',
    email: '',
    status: ALlStatus.filter((l) => l.id)[0],
    contactnum: '',
    website: '',
    country: null,
    province: '',
    city: '',
    postal: '',
    addrress1: '',
    addrress2: '',
    currency: null
  }));
  const [touched, setTouched] = useState(() => ({
    gymname: false,
    firstname: false,
    lastname: false,
    email: false,
    contactnum: false,
    province: false,
    city: false,
    addrress1: false,
    postal: false
  }));

  const [errors, setErrors] = useState(() => ({
    gymname: null,
    firstname: null,
    lastname: null,
    email: null,
    contactnum: null,
    province: null,
    city: null,
    addrress1: null,
    postal: null
  }));

  const handleTouchError = (name) => {
    if (name === 'email') {
      if (!data.email) {
        return 'Email is required field';
      } else if (!validEmailRegEx.test(data.email)) {
        return 'Please enter valid email';
      } else {
        return null;
      }
    } else if (name === 'contactnum') {
      if (!data.contactnum) {
        return 'Contact number is required field';
      } else if (!validPhoneRegEx.test(data.contactnum)) {
        return 'Please enter valid Contact number';
      } else {
        return null;
      }
    } else if (name === 'postal') {
      if (!data.postal) {
        return 'Postal is required field';
      } else if (!postalRegEx.test(data.postal)) {
        return 'Please enter valid Postal';
      } else {
        return null;
      }
    } else if (Object.keys(errors).includes(name)) {
      if (!data[name]) {
        return `${getErrorFieldName(name)} is required field`;
      } else if (!/([A-Z][a-z])/i.test(data[name])) {
        return `Please enter valid ${name}`;
      } else {
        return null;
      }
    }
    return null;
  };

  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();

  const handleNext = async () => {
    if (activeStep === 0) {
      const errorObj = { ...Step1Errors() };
      Object.keys(errorObj).forEach((l) => {
        errorObj[l] = handleTouchError(l);
      });
      if (!Object.values(errorObj).every((l) => l === null)) {
        setErrors(() => ({ ...errors, ...errorObj }));
      } else {
        try {
          isValidAllyStep1(data);
          await checkEmailExistsAlly(data.email);
          setActiveStep((prev) => prev + 1);
        } catch (err) {
          if (err.message && !err.response && !err.request) {
            dispatch(openNoti(err.message, 'error'));
          } else {
            dispatch(openNoti(getErrorMsg(err), 'error'));
          }
        }
      }
    } else {
      const errorObj = { ...Step2Errors() };
      Object.keys(errorObj).forEach((l) => {
        errorObj[l] = handleTouchError(l);
      });
      if (!Object.values(errorObj).every((l) => l === null)) {
        setErrors(() => ({ ...errors, ...errorObj }));
      } else {
        try {
          isValidAllyStep2(data);
          setProcessing(() => true);
          const formData = new FormData();
          formData.append('first_name', data.firstname);
          formData.append('last_name', data.lastname);
          formData.append('gym_name', data.gymname);
          formData.append('email', data.email);
          formData.append('status', data.status.id);
          formData.append('mobile_no', data.contactnum);
          formData.append('url', data.website);
          formData.append('country', data.country.code);
          formData.append('province', data.province);
          formData.append('city', data.city);
          formData.append('postal_code', data.postal);
          formData.append('address', data.addrress1);
          formData.append('address2', data.addrress2);
          formData.append('curr_id', data.currency.id);
          await createAffiliate(formData);
          setProcessing(() => false);
          dispatch(openNoti('Successfully Created the Ally', 'info'));
          dispatch(getActiveAllies());
          dispatch(getInActiveAllies());
          dispatch(getAllAllies());
          history.push(ROUTES.MASTER_HQ_AFFILIATES);
        } catch (err) {
          if (err.message && !err.response && !err.request) {
            dispatch(openNoti(err.message, 'error'));
          } else {
            dispatch(openNoti(getErrorMsg(err), 'error'));
          }
          setProcessing(() => false);
        }
      }
    }
  };

  const handleBack = () => {
    setActiveStep((prev) => prev - 1);
  };

  const handleReset = () => {
    setActiveStep(() => 0);
  };

  const handleChangeError = (name, value) => {
    if (name === 'email' && touched.email) {
      if (!value) {
        return 'Email is required field';
      } else if (!validEmailRegEx.test(value)) {
        return 'Please enter valid email';
      } else {
        return null;
      }
    } else if (name === 'contactnum' && touched.contactnum) {
      if (!value) {
        return 'Contact number is required field';
      } else if (!validPhoneRegEx.test(value)) {
        return 'Please enter valid Contact number';
      } else {
        return null;
      }
    } else if (name === 'postal' && touched.postal) {
      if (!value) {
        return 'Postal is required field';
      } else if (!postalRegEx.test(value)) {
        return 'Please enter valid Postal';
      } else {
        return null;
      }
    } else if (touched[name]) {
      if (!value) {
        return `${getErrorFieldName(name)} is required field`;
      } else if (!/([A-Z][a-z])/i.test(value)) {
        return `Please enter valid ${name}`;
      } else {
        return null;
      }
    }
    return null;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'contactnum') {
      setData(() => ({ ...data, [name]: validMobile(value) }));
    } else {
      setData(() => ({ ...data, [name]: value }));
    }
    if (Object.keys(errors).includes(name)) {
      const error = handleChangeError(name, value);
      setErrors(() => ({ ...errors, [name]: error }));
    }
  };

  const handleTouch = (e) => {
    const { name } = e.target;
    setTouched(() => ({ ...touched, [name]: true }));
    const error = handleTouchError(name);
    setErrors(() => ({ ...errors, [name]: error }));
  };

  useEffect(() => {
    getAffiliateCurrency()
      .then((res) => {
        setcurrencyData(res);
      })
      .catch(() => console.warn('error'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Grid
            xs
            container
            spacing={0}
            disableGutters
            direction="column"
            style={{ margin: '0 0' }}
          >
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                // style={{
                //   paddingBottom: '3px'
                // }}
              >
                <FormControl
                  control="input"
                  label="Gym name"
                  name="gymname"
                  onChange={handleChange}
                  onBlur={handleTouch}
                  value={data.gymname}
                  required
                  style={{ margin: '0 0' }}
                  error={Boolean(errors.gymname)}
                  helperText={errors.gymname}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl
                  control="input"
                  label="First name"
                  name="firstname"
                  onChange={handleChange}
                  onBlur={handleTouch}
                  value={data.firstname}
                  required
                  style={{ margin: '0 0' }}
                  error={Boolean(errors.firstname)}
                  helperText={errors.firstname}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl
                  control="input"
                  label="Last name"
                  name="lastname"
                  onChange={handleChange}
                  onBlur={handleTouch}
                  value={data.lastname}
                  required
                  style={{ margin: '0 0' }}
                  error={Boolean(errors.lastname)}
                  helperText={errors.lastname}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl
                  control="input"
                  label="Email"
                  name="email"
                  onChange={handleChange}
                  onBlur={handleTouch}
                  value={data.email}
                  required
                  style={{ margin: '0 0' }}
                  error={Boolean(errors.email)}
                  helperText={errors.email}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl
                  required
                  control="input"
                  label="Contact number"
                  name="contactnum"
                  onChange={handleChange}
                  onBlur={handleTouch}
                  value={data.contactnum}
                  error={Boolean(errors.contactnum)}
                  helperText={errors.contactnum}
                  style={{ margin: '0 0' }}
                />
              </Grid>

              <Grid
                container
                spacing={2}
                disableGutters
                style={{ margin: '0 0' }}
              >
                <Grid item xs={6}>
                  <InputLabel
                    htmlFor="age-required"
                    className={classes.cutom_select_Label}
                  >
                    Status
                  </InputLabel>
                  <p
                    style={{
                      color: '#fff'
                    }}
                  >
                    {data.status.name}
                  </p>
                  {/* <CustomAutoComplete
                    holder="Select Status"
                    Options="name"
                    style={{ marginbottom: '0' }}
                  /> */}
                </Grid>
                <Grid item xs={6}>
                  <FormControl
                    control="input"
                    label="Website"
                    placeholder=""
                    name="website"
                    onChange={handleChange}
                    value={data.website}
                    maxLength={200}
                    style={{ margin: '4px 0 0' }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Box className="clearfix" mb="20px" />
          </Grid>
        );
      case 1:
        return (
          <Grid
            xs
            container
            spacing={0}
            disableGutters
            direction="column"
            style={{ margin: '0 0' }}
          >
            <Grid container spacing={2}>
              {/* <Grid
                item
                xs={12}
                style={{
                  paddingBottom: '3px'
                }}
              >
                <FormControl
                  control="input"
                  label="Gym name"
                  // value={baseinfo.name}
                  required
                  style={{ margin: '0 0' }}
                />
              </Grid> */}
              <Grid item xs={6} className={classes.masterhqAutocomplete}>
                <InputLabel
                  htmlFor="age-required"
                  className={classes.cutom_select_Label}
                  required
                >
                  Country
                </InputLabel>
                <CustomAutoComplete
                  holder="Select Country"
                  Options="name"
                  value={countriesData || []}
                  data={data.country}
                  Change={(value) => setData({ ...data, country: value })}
                  style={{ marginbottom: '0' }}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl
                  required
                  control="input"
                  label="Province"
                  name="province"
                  onChange={handleChange}
                  onBlur={handleTouch}
                  value={data.province}
                  error={Boolean(errors.province)}
                  helperText={errors.province}
                  style={{ margin: '3px 0 0' }}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl
                  required
                  control="input"
                  label="City"
                  name="city"
                  onChange={handleChange}
                  onBlur={handleTouch}
                  value={data.city}
                  error={Boolean(errors.city)}
                  helperText={errors.city}
                  style={{ margin: '0 0' }}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl
                  required
                  control="input"
                  label="Postal code"
                  name="postal"
                  onChange={handleChange}
                  onBlur={handleTouch}
                  value={data.postal}
                  error={Boolean(errors.postal)}
                  helperText={errors.postal}
                  style={{ margin: '0 0' }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  required
                  control="input"
                  label="Street address line 1"
                  name="addrress1"
                  onChange={handleChange}
                  onBlur={handleTouch}
                  value={data.addrress1}
                  error={Boolean(errors.addrress1)}
                  helperText={errors.addrress1}
                  style={{ margin: '0 0' }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  control="input"
                  label="Street address line 2"
                  name="addrress2"
                  onChange={handleChange}
                  value={data.addrress2}
                  style={{ margin: '0 0' }}
                />
              </Grid>
              <Grid item xs={6} className={classes.masterhqAutocomplete}>
                <InputLabel
                  htmlFor="age-required"
                  className={classes.cutom_select_Label}
                  required
                  style={{
                    paddingTop: '0'
                  }}
                >
                  Currency
                </InputLabel>
                <CustomAutoComplete
                  holder="Select Currency"
                  Options="currency_code"
                  value={currencyData || []}
                  data={data.currency}
                  Change={(value) => setData({ ...data, currency: value })}
                  disableClearable
                  style={{ marginbottom: '0' }}
                />
              </Grid>
            </Grid>
            <Box className="clearfix" mb="20px" />
          </Grid>
        );
      default:
        return 'Unknown step';
    }
  };

  return (
    <div className={classes.root}>
      <div className="title-bk">
        <Breadcrumbs
          aria-label="breadcrumb"
          style={{
            margin: '0px 0 21px'
          }}
        >
          <div
            color="inherit"
            onClick={() => setcancelDialog(true)}
            style={{
              cursor: 'pointer'
            }}
          >
            Allies
          </div>
          <Typography variant="h3" color="Primary">
            <span>NEW Ally</span>
          </Typography>
        </Breadcrumbs>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={0}
          style={{ margin: '0 0 20px' }}
        >
          <Grid item xs={6}>
            <h1 style={{ margin: '0 0', color: '#fff' }}>Create New Ally</h1>
          </Grid>
          <Grid
            item
            xs={6}
            className={classes.btnBlock}
            justify="flex-end"
            alignItems="flex-start"
            style={{
              display: 'flex'
            }}
          >
            <CancelDialog />
          </Grid>
        </Grid>
      </div>
      <div className="CreatStepper">
        <JBStepper
          steps={steps}
          activeStep={activeStep}
          handleBack={handleBack}
          handleNext={handleNext}
          handleReset={handleReset}
          handleStepContent={getStepContent}
          isLoading={false}
          hasErrored={false}
          validateBtn={[isvalidStep1(errors), isvalidStep2(errors)]}
          prevLabel="Create Ally"
          nextLabel="NEXT STEP"
          processingLabel="Creating..."
          isprocessing={processing}
          CustComp={(prop) => (
            <ColorlibStepIcon
              FirstIcon={BasicInformation}
              SecondIcon={MapPin}
              {...prop} // eslint-disable-line
            />
          )}
        />
      </div>
      <CancelDialogTwo
        setOpen={cancelDialog}
        handleClose={() => setcancelDialog(false)}
      />
    </div>
  );
};

AffiliatesCreate.propTypes = {
  classes: PropType.object.isRequired
};

export default withStyles(styles)(AffiliatesCreate);
