/* eslint-disable operator-linebreak */
import React from 'react';
import { connect } from 'react-redux';
import { Grid, InputLabel, withStyles } from '@material-ui/core';
import '../CutomSelect.css';
import // addpaymentmethod
// changepaymentmethod
'src/Context/MembershipTemplate/membershiptemplate.actions';
// import { SessionTemplateContext } from 'src/Context/MembershipTemplate/SessionTemplate.Context';
// import PaymentPlanTable from '../PlanTemplates/paymentplanTable';
// import PaymentPlan from '../../PaymentPlan/SessionPaymentPlan/Sessionpaymentplan';
import PropType from 'prop-types';
import { validateFloat, validPrice } from 'src/utils/validations';
import CurrencyInputs from 'src/views/common/CurrencyControlForm/CurrencyInputs';
import styles from '../MembershipTemplate.style';
import CustomAutoComplete from '../../../calendar/commonAutocomplete';
// import {
//   Delpayment,
//   Editpayment
// } from '../../PaymentPlan/PaymentPlan.validation';
import { openNoti } from '../../../../Redux/global/global.actions';
import {
  changeTax,
  chngeInitialFull,
  chngeInitialFullmem
} from '../../PaymentPlan/paymentReducer.actions';
// import {
//   sessionReducer,
//   initialValue
// } from '../../PaymentPlan/SessionPaymentPlan/Sessionpaymentplan.Reducer';
// import { includeTaxinitials } from '../MemTemplate.utils';

function DropinPaymentStep(props) {
  const {
    classes,
    // reducer: [state, dispatch],
    currencyrates: { data: currencies },
    allSerTaxes: { data: allTaxes },
    // changeInitialFull,
    reducer: [sessionState, sessionDispatch],
    OpenNoti
  } = props;
  // const [openDialog, setOpenDialog] = useState(false);
  // const [state, dispatch] = useContext(SessionTemplateContext);
  // const [state, dispatch] = useReducer(
  //   sessionReducer,
  //   includeTaxinitials(initialValue, allTaxes || []) // eslint-disable-line
  // );

  const {
    full_setup_fees,
    full_membership_fees
  } = sessionState.initial_commitment_pricing;

  return (
    <div>
      <h3 style={{ marginBottom: '20px' }}>
        Create payment Details for Drop In
      </h3>

      <Grid container spacing={0} xs={12} disableGutters direction="row">
        <Grid item xs={12} style={{ marginTop: '0px', marginBottom: '20px' }}>
          <Grid
            item
            xs={3}
            className={classes.time_box}
            style={{ marginTop: '0px' }}
          >
            {/* <InputLabel
              htmlFor="age-required"
              className={classes.cutom_select_Label}
            >
              &nbsp;
            </InputLabel> */}
            <CustomAutoComplete
              holder="Select Service Tax"
              value={allTaxes || []}
              data={sessionState.tax}
              Options="name"
              style={{ marginbottom: '0' }}
              Change={(val) => {
                if (!val) OpenNoti('Please specify the service tax', 'error');
                sessionDispatch(changeTax(val));
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={0}
        xs={12}
        disableGutters
        direction="row"
        style={{
          margin: '15px 0'
        }}
      >
        <Grid
          item
          xs={2}
          style={{
            marginTop: '0px',
            paddingRight: '15px',
            display: 'flex'
          }}
        >
          <h4 style={{ alignSelf: 'center' }}>When paid in Full</h4>
        </Grid>
        <Grid
          item
          xs={3}
          className={classes.time_box}
          style={{ marginTop: '0px', paddingRight: '15px' }}
        >
          <InputLabel
            htmlFor="age-required"
            className={classes.cutom_select_Label}
            style={{ padding: '0px 0 10px' }}
          >
            One-time setup fee
          </InputLabel>
          <CurrencyInputs
            placeholder="1"
            currencySymbol={currencies.currency_symbol}
            value={full_setup_fees}
            style={{
              margin: '0 0'
            }}
            onChange={
              (e) =>
                sessionDispatch(
                  chngeInitialFull(validPrice(validateFloat(e.target.value)))
                )
              // changeInitialFull(e.target.value)
            }
          />
        </Grid>
        <Grid
          item
          xs={3}
          className={classes.time_box}
          style={{ marginTop: '0px', paddingRight: '15px' }}
        >
          <InputLabel
            htmlFor="age-required"
            className={classes.cutom_select_Label}
            style={{ padding: '0px 0 10px' }}
            required
          >
            Full Drop In fee
          </InputLabel>
          <CurrencyInputs
            currencySymbol={currencies.currency_symbol}
            value={full_membership_fees}
            placeholder="1"
            style={{
              margin: '0 0'
            }}
            onChange={(e) =>
              sessionDispatch(
                chngeInitialFullmem(validPrice(validateFloat(e.target.value)))
              )
            }
          />
        </Grid>
        <Grid
          item
          xs={3}
          className={classes.time_box}
          style={{
            marginTop: '20px',
            display: 'flex',
            flexDirection: 'column',
            alignSelf: 'center'
          }}
        >
          <h3 style={{ marginBottom: '0px' }}>
            {currencies.currency_symbol +
              ' ' +
              parseFloat(
                parseFloat(full_setup_fees) + parseFloat(full_membership_fees)
              ).toLocaleString(undefined, {
                minimumFractionDigits: currencies.decimals
              })}
          </h3>
          <InputLabel
            htmlFor="age-required"
            className={classes.cutom_select_Label}
          >
            Total cost (including tax)
          </InputLabel>
        </Grid>
      </Grid>
    </div>
  );
}

DropinPaymentStep.propTypes = {
  classes: PropType.object.isRequired,
  currencyrates: PropType.object.isRequired,
  allSerTaxes: PropType.array.isRequired,
  // changeInitialFull: PropType.func.isRequired,
  reducer: PropType.array.isRequired,
  OpenNoti: PropType.func.isRequired
};

const mapToDispatch = (dispatch) => ({
  OpenNoti: (msg, type) => dispatch(openNoti(msg, type))
});

const mapToState = (state) => ({
  currencyrates: state.SettingReducer.currency,
  allSerTaxes: state.SettingReducer.taxRates
});

export default connect(
  mapToState,
  mapToDispatch
)(withStyles(styles)(DropinPaymentStep));
