import React, { useEffect } from 'react';
import { ROUTES } from 'src/constant/Constants';
import { Route, Switch } from 'react-router-dom';
import { AlliesConext } from 'src/Context/AlliesContext';
import { useDispatch } from 'react-redux';
import { getInvoiceSettings } from 'src/Redux/MasterHQ/FinancialReducer/financial.reducer.actions';
import AffiliatesTabs from './affiliates/affiliatesTabs';
import FinancialTabs from './financial/financialTabs';
import AffiliatesCreate from './affiliates/AffiliatesCreate';
import AffiliateProfile from './affiliates/Profile/AffiliateProfile';
import InvoiceView from './financial/Invoice/InvoiceView';
import PaindInvoiceView from './financial/Invoice/PaindInvoiceView';
import ReportsContainer from './ReportsContainer';

const MasterHQ = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getInvoiceSettings());
  }, [dispatch]);
  return (
    <div>
      <Switch>
        <Route path={ROUTES.MASTER_HQ_REPORTS} component={ReportsContainer} />
        <Route
          path={ROUTES.MASTER_HQ_AFFILIATES_CREATE}
          component={AffiliatesCreate}
        />
        <Route
          path={ROUTES.MASTER_HQ_AFFILIATES_PROFILE}
          component={() => (
            <AlliesConext>
              <AffiliateProfile />
            </AlliesConext>
          )}
        />
        <Route
          path={ROUTES.MASTER_HQ_FINANCIAL_INVOICES_VIEW}
          component={InvoiceView}
        />
        <Route
          path={ROUTES.MASTER_HQ_FINANCIAL_INVOICES_PAIND_VIEW}
          component={PaindInvoiceView}
        />
        <Route path={ROUTES.MASTER_HQ_AFFILIATES} component={AffiliatesTabs} />
        <Route path={ROUTES.MASTER_HQ_FINANCIAL} component={FinancialTabs} />
      </Switch>
    </div>
  );
};

export default MasterHQ;
