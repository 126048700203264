import React, { useState, useEffect, useContext } from 'react';
import PropType from 'prop-types';
import { connect } from 'react-redux';
import JBButton from 'src/views/common/JBButton/JBButton';
import { InputLabel, withStyles, ThemeProvider } from '@material-ui/core';
import { getValuefromObject, getMinDate } from 'src/utils/someCommon';
import { FilterContext } from 'src/views/common/ApplyFilter/applyFilter';
import JBDatePicker from 'src/views/common/FormControl/JBDatePicker';
// import { getActiveMem } from 'src/services/Members.module';
import { getAlliesNames } from 'src/services/masterHQ.services';
import useGymSettingRefresh from 'src/views/common/CustomHooks/useGymSettingRefresh';
import {
  TobeFilter,
  isNotData,
  FilterAutobill,
  FilterStatus
} from './static.Data';
import CustomAutoComplete from '../../../../common/CustomAutocomplete/commonAutocomplete';
import styles from './filters.style';
import { getFilter, getfilterLabel, checkData } from './Filters.utils';

const InvoiceFilters = (props) => {
  const { classes, getFilData } = props;
  const [data, setData] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [selectis, setSelect] = useState(isNotData[1]);
  const [filter, setFilter] = useState(null);
  const [endDate, setendDate] = useState(() => getMinDate(new Date()));
  const [alliesdata, setAlliesdata] = useState(null);
  const filteredData = TobeFilter.filter((fil) => fil.id === filter)[0];
  const [open, setOpen] = useContext(FilterContext);
  const { dateformat } = useGymSettingRefresh();

  useEffect(() => {
    if (filter === 4) {
      setData(new Date());
    } else {
      setData(null);
    }
  }, [filter]);

  useEffect(() => {
    getAlliesNames()
      .then((res) => {
        setAlliesdata(res);
      })
      .catch((err) => console.warn(err));
  }, []);

  const ApplyFilterStyle = {
    overrides: {
      MuiPaper: {
        root: {
          backgroundColor: '#3c3d4f',
          color: '#ffffff'
        }
      }
    }
  };
  const DatePickerStyle = {
    overrides: {
      MuiPaper: {
        root: {
          backgroundColor: '#fff',
          color: '#000'
        }
      }
    }
  };

  return (
    <div>
      <ThemeProvider theme={ApplyFilterStyle}>
        <CustomAutoComplete
          holder="Select Filter"
          style={{ marginBottom: '15px' }}
          value={TobeFilter}
          Options="filter"
          data={filteredData}
          Change={(val) => setFilter(getValuefromObject(val))}
        />

        <div>
          {(() => {
            switch (filter) {
              case 1:
                return (
                  <div>
                    <InputLabel
                      htmlFor="age-required"
                      className={classes.cutom_select_Label}
                      style={{ margin: '0px 0 5px' }}
                    >
                      Ally
                    </InputLabel>
                    <CustomAutoComplete
                      holder="Select Ally"
                      Options="ally_name"
                      data={data || null}
                      value={alliesdata}
                      Change={(val) => setData(val)}
                    />
                  </div>
                );
              case 2:
                return (
                  <div>
                    <InputLabel
                      htmlFor="age-required"
                      className={classes.cutom_select_Label}
                      style={{ margin: '0px 0 5px' }}
                    >
                      Ally status
                    </InputLabel>
                    <CustomAutoComplete
                      holder="Select ally status"
                      Options="name"
                      data={data || null}
                      value={FilterAutobill}
                      Change={(val) => setData(val)}
                      style={{ marginBottom: '15px', marginTop: '0px' }}
                    />
                  </div>
                );
              case 3:
                return (
                  <div>
                    <InputLabel
                      htmlFor="age-required"
                      className={classes.cutom_select_Label}
                      style={{ margin: '0px 0 5px' }}
                    >
                      Invoice Status
                    </InputLabel>
                    <CustomAutoComplete
                      holder="Select invoice status"
                      Options="name"
                      data={data || null}
                      value={FilterStatus}
                      Change={(val) => setData(val)}
                      style={{ marginBottom: '15px', marginTop: '0px' }}
                    />
                  </div>
                );
              case 4:
                return (
                  <ThemeProvider theme={DatePickerStyle}>
                    <div>
                      <>
                        <InputLabel
                          htmlFor="age-required"
                          className={classes.cutom_select_Label}
                          style={{ margin: '5px 0' }}
                        >
                          From
                        </InputLabel>
                        <JBDatePicker
                          label="Start Date"
                          value={data}
                          onChange={(val) => {
                            setData(val);
                            setendDate(getMinDate(val));
                          }}
                        />
                        <InputLabel
                          htmlFor="age-required"
                          className={classes.cutom_select_Label}
                          style={{ padding: '20px 0px 5px' }}
                        >
                          To
                        </InputLabel>
                        <JBDatePicker
                          label="End Date"
                          minDate={
                            data ? getMinDate(data) : getMinDate(new Date())
                          }
                          value={endDate}
                          onChange={(val) => setendDate(val)}
                        />
                      </>
                    </div>
                  </ThemeProvider>
                );
              default:
                return null;
            }
          })()}
        </div>
      </ThemeProvider>

      <JBButton
        type="primary"
        style={{
          width: '100%',
          minHeight: '22px',
          padding: '7px 25px',
          marginTop: '10px'
        }}
        disabled={checkData(filter, data, endDate, selectis)}
        onClick={() => {
          getFilData(
            getFilter(filter, data, endDate, selectis),
            getfilterLabel(filter, data, endDate, selectis, dateformat)
          );
          setOpen(!open);
        }} // eslint-disable-line
      >
        Apply Filter
      </JBButton>
    </div>
  );
};
InvoiceFilters.propTypes = {
  getFilData: PropType.func.isRequired,
  classes: PropType.object.isRequired
  // memFilter: PropType.func.isRequired
};

export default connect()(withStyles(styles)(InvoiceFilters));
