import Types from './WaiverReducer.types';
import getWaivers from './WaiverReducer.utils';
import filterlist from '../Reducers.utils';

const initialValue = {
  Missing: { isFetching: false, data: null, error: null },
  Signed: { isFetching: false, data: null, error: null },
  Templates: { isFetching: false, data: null, error: null },
  TempFilter: {
    sorting: {}
  },
  MissingWavFilter: {
    page: 1,
    search: '',
    sorting: {},
    tags: [],
    customFilter: {},
    default: {
      sign_status: 0
    }
  },
  SignedFilters: {
    page: 1,
    search: '',
    sorting: {},
    tags: [],
    customFilter: {},
    default: {
      sign_status: 1
    }
  }
};

export default (state = initialValue, action) => {
  switch (action.type) {
    case Types.MissWav:
      return {
        ...state,
        Missing: getWaivers(
          Types.MissWav,
          action.actionType,
          state.Missing,
          action.payload
        )
      };
    case Types.Signed:
      return {
        ...state,
        Signed: getWaivers(
          Types.Signed,
          action.actionType,
          state.Signed,
          action.payload
        )
      };
    case Types.TempWav:
      return {
        ...state,
        Templates: getWaivers(
          Types.Signed,
          action.actionType,
          state.Templates,
          action.payload
        )
      };
    case Types.MissWavFilter:
      return {
        ...state,
        MissingWavFilter: filterlist(
          state.MissingWavFilter,
          action.filter,
          action.payload
        )
      };
    case Types.SignedFilter:
      return {
        ...state,
        SignedFilters: filterlist(
          state.SignedFilters,
          action.filter,
          action.payload
        )
      };
    case Types.TempWavFilter:
      return {
        ...state,
        TempFilter: filterlist(state.TempFilter, action.filter, action.payload)
      };
    default:
      return state;
  }
};
