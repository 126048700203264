import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { openNoti } from 'src/Redux/global/global.actions';
import { isValidPhoto } from 'src/utils/validations';
import PropType from 'prop-types';
import { withStyles, Button } from '@material-ui/core';
import imgplusicon from '../../assets/image/imgplusicon.png';
import { ReactComponent as Delicon } from '../../assets/image/del-icon.svg';

const styles = () => ({
  btnReset: {
    position: 'absolute',
    right: '-10px',
    cursor: 'pointer',
    minWidth: '20px',
    padding: '0',
    borderRadius: '50%',
    marginTop: '-10px',
    zIndex: '99',
    color: '#000'
  }
});

const Userimage = (props) => {
  const { classes, OpenNoti, setImage, imagesrc, isuploaded } = props;
  const uploadedImage = useRef(null);
  const imageUploader = useRef(null);
  const [uploaded, setUploaded] = useState(() => isuploaded);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const { error, value } = isValidPhoto(file);
      if (value) {
        const reader = new FileReader();
        const { current } = uploadedImage;
        current.file = file;
        reader.onload = (e2) => {
          current.src = e2.target.result;
        };
        reader.readAsDataURL(file);
        setImage(file);
        setUploaded(true);
      } else if (error) {
        OpenNoti(error, 'error');
      }
    }
  };
  const handleReset = () => {
    uploadedImage.current.file = null;
    uploadedImage.current.src = 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs%3D';
    setImage(null);
    setUploaded(false);
  };
  const handleError = (e) => {
    e.target.src = 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs%3D';
  };

  // useEffect(() => {
  //   if (isuploaded && uploadedImage.current) {
  //     const reader = new FileReader();
  //     const { current } = uploadedImage;
  //     current.file = imageRef;
  //     reader.onload = (e2) => {
  //       current.src = e2.target.result;
  //     };
  //     reader.readAsDataURL(imageRef);
  //   }
  // }, [isuploaded, imageRef]);

  useEffect(() => {
    if (imagesrc && uploadedImage.current) {
      uploadedImage.current.src = imagesrc;
    }
  }, [imagesrc]);

  const handleDragOver = (e) => {
    e.preventDefault();
  };
  const handleDragEnter = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    if (e.dataTransfer) {
      if (e.dataTransfer.files.length > 1) {
        OpenNoti('Please upload one at time', 'error');
      } else {
        const data = {
          target: e.dataTransfer
        };
        handleImageUpload(data);
      }
    }
  };
  const handleLeave = (e) => {
    e.preventDefault();
  };

  return (
    <div
      className="wrapperimgBox"
      onDragOver={handleDragOver}
      onDragEnter={handleDragEnter}
      onDrop={handleDrop}
      onDragLeave={handleLeave}
    >
      <input
        type="file"
        accept="image/*"
        onChange={handleImageUpload}
        onClick={(e) => {
          e.stopPropagation();
          imageUploader.current.value = null;
        }}
        ref={imageUploader}
        style={{
          display: 'none'
        }}
      />
      {!uploaded ? null : (
        <Button
          color="primary"
          onClick={handleReset}
          className={classes.btnReset}
        >
          <Delicon fontSize="small" />
        </Button>
      )}
      <div
        className="imageUploaderBox"
        onClick={() => imageUploader.current.click()}
      >
        <img
          alt=""
          ref={uploadedImage}
          onError={handleError}
          style={{
            width: '100%',
            height: '100%',
            // position: 'acsolute',
            borderRadius: '8px',
            objectFit: 'contain'
          }}
        />
        {!uploaded ? (
          <span className="Clicktextinfo">
            <img
              src={imgplusicon}
              alt="img"
              style={{
                display: 'block',
                margin: '0 auto',
                cursor: 'pointer',
                objectFit: 'contain'
              }}
            />
            Click or drag file <br /> to this area
          </span>
        ) : null}
      </div>
    </div>
  );
};

Userimage.propTypes = {
  classes: PropType.object.isRequired,
  OpenNoti: PropType.func.isRequired,
  setImage: PropType.func.isRequired,
  isuploaded: PropType.bool.isRequired,
  imagesrc: PropType.any.isRequired
};

const mapToDispatch = (dispatch) => ({
  OpenNoti: (msg, type) => dispatch(openNoti(msg, type))
});

export default connect(null, mapToDispatch)(withStyles(styles)(Userimage));
