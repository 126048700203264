/* eslint-disable function-paren-newline */
/* eslint-disable no-confusing-arrow */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropType from 'prop-types';
import { Grid, withStyles, InputLabel } from '@material-ui/core';
import FormControl from 'src/views/common/FormControl/formControl';
import JBButton from 'src/views/common/JBButton/JBButton';
import Plusicon from '../../../assets/image/plusicon.png';
import Styles from './MembershipTabs.style';
import MemTable from './MembershipHoldTypeTable';
import { HoldTypestableHead } from './staticDataHoldTypes';
import {
  addMemHold,
  getMemholdType,
  updateMemHold
} from '../../../services/Membersip.services';
import { openNoti } from '../../../Redux/global/global.actions';
import { getErrorMsg } from '../../../utils/ErrorHandling';

const MembershipHoldTypes = (props) => {
  const { classes, OpenNoti } = props;
  const [data, setData] = useState(null);
  const [addLabel, setaddLabel] = useState('');
  const [error, setError] = useState(false);

  useEffect(() => {
    getMemholdType()
      .then((res) => setData(res))
      .catch(() => setError(true));
  }, []);

  const handleAdd = () => {
    if (addLabel.length > 0) {
      addMemHold(addLabel)
        .then((res) => {
          setData([...data, res]);
          OpenNoti('Hold Type Added Successfully');
          setaddLabel('');
        })
        .catch((err) => OpenNoti(getErrorMsg(err), 'error'));
    }
  };

  if (error) return <p>Something went wrong</p>;

  return (
    <div className={classes.root}>
      {/*= =============== membership hold types==================== */}
      <Grid
        container
        // direction="column"
        justify="flex-start"
        alignItems="flex-start"
        spacing={0}
        style={{ margin: '20px 0 20px' }}
      >
        <p className={classes.ptext}>
          Customize the reasons why a members membership may be placed on hold.
        </p>

        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          spacing={0}
          style={{ margin: '20px 0 20px' }}
        >
          {/* Disable for Phase 1 */}
          <Grid item xs={3} style={{ padding: 0, margin: '0px' }}>
            <FormControl
              control="input"
              label="Add new reason"
              placeholder="Reason label"
              style={{ margin: '0px 0' }}
              value={addLabel}
              onChange={(e) => setaddLabel(e.target.value)}
            />
          </Grid>

          <Grid item xs={3} style={{ padding: 0, margin: '0px' }}>
            <InputLabel
              htmlFor="age-required"
              className={classes.cutom_Switch_Label}
            >
              &nbsp;
            </InputLabel>
            <JBButton
              type="primary"
              style={{ float: 'left', marginTop: '20px', marginLeft: '15px' }}
              isImg={Plusicon}
              onClick={handleAdd}
              disabled={!addLabel}
            >
              Add reason
            </JBButton>
          </Grid>
        </Grid>

        <Grid item xs={12} style={{ marginTop: '0px' }}>
          {data && (
            <MemTable
              handleDelete={(editlabel, id) => {
                updateMemHold({ label: editlabel, status: 0 }, id)
                  .then(() => {
                    setData(data.filter((item) => item.id !== id));
                    OpenNoti('Delete Successfully', 'info');
                  })
                  .catch((err) => {
                    OpenNoti(getErrorMsg(err), 'error');
                  });
              }}
              handleEdit={(editlabel, id) => {
                updateMemHold({ label: editlabel, status: 1 }, id)
                  .then(() =>
                    setData(
                      data.map((item) =>
                        item.id === id ? { ...item, label: editlabel } : item
                      )
                    )
                  )
                  .catch((err) => {
                    console.warn(getErrorMsg(err), 'error');
                  });
              }}
              values={data}
              tableHead={HoldTypestableHead}
            />
          )}
        </Grid>
      </Grid>
      {/*= ======================End membership hold types =========== */}
    </div>
  );
};

MembershipHoldTypes.propTypes = {
  classes: PropType.object.isRequired,
  OpenNoti: PropType.func.isRequired
};

const mapToDispatch = (dispatch) => ({
  OpenNoti: (msg, type) => dispatch(openNoti(msg, type))
});

export default connect(
  null,
  mapToDispatch
)(withStyles(Styles)(MembershipHoldTypes));
