const styles = (theme) => ({
  root: {
    marginTop: 0,
    margin: '0px 20px 20px',
    padding: '0px 20px 20px'
  },
  bktitle: {
    color: '#fff'
  },
  mainCard: {
    boxShadow: '4px 0px 16px rgba(37, 40, 43, 0.08)',
    borderRadius: '5px',
    background: '#FFFF',
    padding: 30,
    overflow: 'visible'
  },
  searchInput: {
    padding: '6px 20px 6px !important;',
    paddingRight: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    height: '35px',
    border: '1px solid #a9a9a9'
  },
  actionsContainer: {
    marginBottom: theme.spacing(2)
  },
  resetContainer: {
    padding: theme.spacing(3)
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '25ch'
  },
  time_box: {
    marginTop: '15px',
    marginRight: '0px'
  },
  cutom_select_border: {
    border: '1px solid #a9a9a9'
  },
  cutom_select_Label: {
    fontSize: '12px',
    padding: '7px 0'
  },
  ptext: {
    fontSize: '16px',
    lineHeight: '24px',
    color: '#7F7F7F',
    fontWeight: 'normal'
  },
  media: {
    display: '-ms-flexbox',
    // eslint-disable-next-line no-dupe-keys
    display: 'flex',
    msFlexSlign: 'start',
    alignItems: 'flex-start'
  },
  mediabody: {
    flex: '1',
    fontSize: '14px',
    marginLeft: '15px'
  },
  memberWrapper: {
    background: '#01847A',
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
    paddingBottom: '60px'
  },
  userInfo: {
    // padding: '15px'
    // '& ul': {
    //   fontSize: '14px',
    //   padding: '15px',
    //   borderBottom: '1px solid #EAEAEA',
    //   '& li': {
    //     display: 'inline-block',
    //     minWidth: 'calc(100% / 3)'
    //   }
    // }
  },
  userWrapper: {
    borderRadius: '8px',
    borderBottomLeftRadius: '0',
    borderBottomRightRadius: '0',
    padding: '15px',
    '& h3': {
      color: '#FFF',
      fontSize: '20px'
    }
  },
  titlelabel: {
    fontSize: '14px',
    color: 'rgb(255 255 255 / 0.5)',
    fontWeight: '700'
  },
  lbinfo: {
    fontSize: '14px',
    color: '#FFFFFF',
    marginLeft: '5px',
    fontWeight: '700'
  },
  infoul: {
    fontSize: '14px',
    fontWeight: '700',
    '& li': {
      display: 'inline-block',
      minWidth: '115px',
      marginRight: '0px'
    }
  },
  vhr: {
    background: 'rgb(255 255 255 / 0.2)',
    position: 'absolute',
    width: '1px',
    height: '66px'
  },

  // lastattendance: {
  //   fontSize: '14px',
  //   '& span': {
  //     color: 'rgb(255 255 255 / 0.5)'
  //   },
  //   '& label': {
  //     color: '#FFFFFF',
  //     marginLeft: '5px'
  //   }
  // }

  // Table styles
  table: {
    fontSize: '14px',
    lineHeight: '22px'
  },
  table_th: {
    backgroundColor: '#F9F9FA',
    color: '#292929'
  },
  Table_td_link: {
    color: '#01847A',
    fontWeight: '700'
  },
  TableCell: {
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px',
    borderBottom: '1px solid #A8A8A8',
    height: '56px',
    minHeight: '56px'
  },
  Table_td: {
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '22px',
    position: 'relative',
    padding: '12px 15px 13px 15px',
    height: '56px',
    minHeight: '56px'
  },
  TbActionBtn: {
    textTransform: 'capitalize',
    color: '#7F7F7F',
    fontSize: '12px'
  },
  actionbtn: {
    padding: '6px 5px',
    minWidth: 'auto',
    margin: '0 5px',
    textAlign: 'center',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  // end table
  ProfileTagCss: {
    margin: '10px 10px 0',
    minHeight: '3px',
    overflowY: 'auto',
    height: '65px',
    color: '#FFF'
  },
  NotesTabsTextareaControl: {
    color: '#fff',
    fontSize: '16px',
    border: '1px solid rgba(255, 255, 255, 0.5)',
    padding: '9px 15px 7px'
  },
  TagBlock: {
    width: '100%',
    '& .ReactTags__tags': {
      border: '1px solid rgba(255, 255, 255, 0.5)'
    },
    '& .ReactTags__tagInputField': {
      background: 'transparent',
      color: '#fff',
      '&::placeholder': {
        color: '#fff'
      }
    }
  },
  TagGreen: {
    color: '#fff !important',
    background: 'rgba(255, 255, 255, 0.2) !important',
    border: '1px solid rgba(255, 255, 255, 0.0) !important',
    '& .ReactTags__remove': {
      color: '#fff !important',
      border: '1px solid #fff !important'
    }
  },
  proimage: {
    position: 'relative',
    '&:hover .editimageup , &:hover .imgDelBtnup , &:hover .EditImageBk': {
      display: 'block'
    },
    '& img': {
      width: '80px',
      height: '80px',
      borderRadius: '50%'
    }
  },
  Avataruname: {
    width: '80px',
    height: '80px',
    fontSize: '24px',
    fontWeight: 'bold',
    lineHeight: '16px',
    backgroundColor: '#CCE6E4',
    color: '#37847a'
  },
  cutom_DatePicker: {
    '& .MuiInput-root ': {
      marginTop: '5px !important'
    }
  },
  cutomAddressBtn: {
    padding: '5px 11px',
    minHeight: '48px',
    fontWeight: '700',
    borderRadius: '2px'
  },
  Profileinfobk: {
    '& .MuiFormHelperText-root.MuiFormHelperText-root.Mui-error ': {
      position: 'relative',
      bottom: 'auto'
    }
  },
  Alertslist: {
    marginLeft: '15px'
  },
  masterhqAutocomplete: {
    '& .MuiAutocomplete-root': {
      color: '#ffffff',
      background: 'rgba(255, 255, 255, 0.1)',
      borderRadius: '2px',
      '& .MuiIconButton-root': {
        color: 'rgb(255 255 255 / 100%)'
      }
    }
  }
});

export default styles;
