import React, { useState, useContext } from 'react';
import { setPaymentTab } from 'src/Context/VanguardMembership/VanguardMembership.actions';
import { VanguardMem } from 'src/Context/VanguardMembershipContext';
import {
  Typography,
  makeStyles,
  Grid,
  Box,
  Tabs,
  Tab,
  ThemeProvider
} from '@material-ui/core';
import { ReactComponent as CreditCard } from 'src/assets/image/JBSteppericon/CreditCard.svg';
import { ReactComponent as CashIcon } from 'src/assets/image/JBSteppericon/CashIcon.svg';
import AccountBalanceOutlinedIcon from '@material-ui/icons/AccountBalanceOutlined';
import clsx from 'clsx';
import PropType from 'prop-types';
import AddPaymentMethodTabs from './AddPaymentMethodTabs';

const themeInstance = {
  overrides: {
    MuiTabs: {
      indicator: {
        backgroundColor: 'transparent'
      }
    },
    MuiTab: {
      root: {
        maxWidth: '100%',
        margin: '0px 30px 0 0',
        width: 'calc(100% / 3.3)',
        height: '200px',
        borderRadius: '12px',
        border: '1px solid #949494',
        '&:last-child': {
          margin: '0px 0px 0 0'
        },
        '&$selected': {
          backgroundColor: '#2CE9DA',
          border: '1px solid #2CE9DA',
          color: '#fff ',
          '&:hover': {
            color: '#37847a'
          },
          '&$textColorPrimary': {
            color: '#000000'
          },
          '& svg path': {
            fill: '#000000'
          }
        }
      },
      wrapper: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex'
      },
      labelIcon: {
        minHeight: '55px',
        '& svg': {
          marginBottom: '50px !important'
        },
        '& .BankSVg': {
          fontSize: '88px',
          color: 'rgb(148, 148, 148)',
          padding: '0px',
          marginBottom: '30px!important'
        }
      },
      textColorPrimary: {
        fontFamily: 'DDC Hardware',
        color: '#949494',
        fontSize: '22px',
        fontWeight: '700',
        lineHeight: '18px',
        letterSpacing: '0.5px',
        backgroundColor: '#3c3c3c',
        border: '1px solid #3c3c3c',
        borderRadius: '12px'
      }
    }
  }
};

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    background: 'rgba(29, 29, 29, 0.94)',
    boxShadow: '0px 0.33333px 0px rgb(255 255 255 / 15%)',
    backdropFilter: 'blur(54.3656px)'
  },
  appToolbar: {
    minHeight: '75px',
    justifyContent: 'space-between'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
  mainTitle: {
    fontWeight: '500',
    fontSize: '48px',
    lineHeight: '44px',
    color: '#FFFFFF',
    fontFamily: 'DDC Hardware',
    textAlign: 'center',
    margin: '38px 0 0 0',
    '@media (max-width: 992px)': {
      fontSize: '35px'
    }
  },
  MainCard: {
    height: 'calc(100vh - 75px )',
    background:
      'linear-gradient(180deg, rgba(41, 41, 41, 0.95) 0%, rgba(4, 4, 4, 0.95) 100%)',
    borderRadius: '0',
    flex: '1 1 auto',
    // height: '100%',
    overflow: 'auto'
  },
  HomeButton: {
    fontFamily: 'Lato',
    color: '#2CE9DA',
    fontWeight: 'bold',
    fontSize: '22px',
    lineHeight: '32px',
    textTransform: 'capitalize'
  },
  cutom_select_Label: {
    fontFamily: 'DDC Hardware',
    fontStyle: 'normal',
    fontWeight: '500;',
    fontSize: '22px',
    lineHeight: '32px',
    letterSpacing: '0.2px',
    color: '#FFFFFF',
    '&:focus ': {
      outline: '0'
    }
  },
  cutom_vanguard_FormControl: {
    '& .fd-input input': {
      background: 'rgba(196, 196, 196, 0.05)',
      color: '#fff',
      borderRadius: '6px',
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '20px',
      height: '45px',
      border: '2px solid #C8C8C8',
      padding: '8px 20px 8px',
      '&::placeholder ': {
        color: '#fff',
        fontWeight: 'bold',
        fontSize: '15px'
      }
    }
  },
  cutom_vanguard_Autocomplete: {
    background: 'rgba(196, 196, 196, 0.05)',
    border: '2px solid #C8C8C8',
    color: '#fff',
    borderRadius: '6px',
    '& .cutom_select div': {
      minHeight: '62px'
    },
    '& .cutom_select div input ': {
      color: '#ffffff !important',
      fontWeight: 'bold',
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontSize: '20px'
    },
    '& .MuiAutocomplete-endAdornment': {
      minHeight: ' auto !important',
      top: 'calc(50% - 12px)'
    }
  },
  customRadio: {
    '& .MuiFormControlLabel-root': {
      background: 'rgba(196, 196, 196, 0.05)',
      border: '2px solid #7B7B7B',
      borderRadius: '6px',
      height: '70px',
      margin: '0 0 15px'
    },
    '& .MuiFormControlLabel-root.checked': {
      background: 'rgba(44, 233, 218, 0.15)',
      border: '2px solid #2CE9DA',
      borderRadius: '6px'
    },
    '& .MuiFormControlLabel-root svg.MuiSvgIcon-root': {
      width: '30px',
      height: '30px',
      fill: 'none',
      borderRadius: '50%',
      border: '2px solid #4B4B4B'
    },
    '& .MuiFormControlLabel-root.checked svg.MuiSvgIcon-root': {
      background: '#67e9da',
      padding: '4px',
      fill: '#000',
      color: '#060606',
      border: '0px solid #4B4B4B'
    },
    '& .MuiRadio-root': {
      padding: '20px'
    },
    '& .MuiFormControlLabel-label': {
      color: '#fff',
      fontSize: '20px',
      lineHeight: '26px',
      fontWeight: 'bold'
    }
  },
  subTitle: {
    fontWeight: 'bold',
    fontSize: '24px',
    lineHeight: '38px',
    color: '#FFFFFF',
    fontFamily: 'Lato',
    textAlign: 'center',
    margin: '38px 0 0 0',
    letterSpacing: '0.1px'
  }
}));

const AddPaymentMethod = ({ handleClose, info, Type }) => {
  const classes = useStyles();
  const [state, dispatch] = useContext(VanguardMem);
  const { paymentMeTab } = state;
  const [debitsteps, setdebitsteps] = useState(0);
  return (
    <div>
      <Typography variant="h3" className={classes.mainTitle}>
        ADD PAYMENT METHOD
      </Typography>
      <Typography variant="h5" className={classes.subTitle}>
        Select Account Type
      </Typography>
      <Grid
        container
        xs={10}
        justify="center"
        style={{
          margin: '45px auto'
        }}
      >
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={2}
          // style={{ margin: '20px 0 20px' }}
        >
          {debitsteps === 0 && (
            <Box
              css={{
                width: '100%'
              }}
            >
              <ThemeProvider theme={themeInstance}>
                <Tabs
                  value={paymentMeTab}
                  onChange={(_, val) => dispatch(setPaymentTab(val))}
                  indicatorColor="primary"
                  textColor="primary"
                  fullWidth
                >
                  <Tab
                    label="Credit Card"
                    icon={<CreditCard />}
                    className={classes.p_tabs}
                  />
                  {!Type && (
                    <Tab
                      label="Cash"
                      icon={<CashIcon />}
                      className={classes.p_tabs}
                    />
                  )}
                  {!Type && (
                    <Tab
                      label="Bank Account"
                      icon={<AccountBalanceOutlinedIcon className="BankSVg" />}
                      className={classes.p_tabs}
                    />
                  )}
                </Tabs>
              </ThemeProvider>
            </Box>
          )}
          <Grid item xs={12}>
            <Box
              className={clsx(
                classes.TabulationCss,
                debitsteps === 0 ? classes.step0css : null
              )}
            >
              <AddPaymentMethodTabs
                Tabs={paymentMeTab}
                activeSteps={[debitsteps, setdebitsteps]}
                handleClose={handleClose}
                info={info}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

AddPaymentMethod.defaultProps = {
  Type: null
};

AddPaymentMethod.propTypes = {
  handleClose: PropType.func.isRequired,
  // handleBack: PropType.func,
  // handleHistory: PropType.string
  info: PropType.object.isRequired,
  Type: PropType.number
};

export default AddPaymentMethod;
