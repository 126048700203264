import Types from './lead.Types';

// eslint-disable-next-line
export const getLeads = (type, action, state, data) => {
  switch (type) {
    case Types.getDailyListLead: {
      const Data = () => {
        switch (action) {
          case Types.isFetching:
            return {
              ...state,
              isFetching: true
            };
          case Types.fetchSucess:
            return {
              ...state,
              isFetching: false,
              error: null,
              data
            };
          case Types.Errorfetch:
            return {
              ...state,
              isFetching: false,
              error: data
            };
          default:
            return state;
        }
      };
      return Data();
    }
    case Types.getNewLead: {
      const Data = () => {
        switch (action) {
          case Types.isFetching:
            return {
              ...state,
              isFetching: true
            };
          case Types.fetchSucess:
            return {
              ...state,
              isFetching: false,
              error: null,
              data
            };
          case Types.Errorfetch:
            return {
              ...state,
              isFetching: false,
              error: data
            };
          default:
            return state;
        }
      };
      return Data();
    }
    case Types.getfollowuplist: {
      const Data = () => {
        switch (action) {
          case Types.isFetching:
            return {
              ...state,
              isFetching: true
            };
          case Types.fetchSucess:
            return {
              ...state,
              isFetching: false,
              error: null,
              data
            };
          case Types.Errorfetch:
            return {
              ...state,
              isFetching: false,
              error: data
            };
          default:
            return state;
        }
      };
      return Data();
    }
    case Types.getConsultBooked: {
      const Data = () => {
        switch (action) {
          case Types.isFetching:
            return {
              ...state,
              isFetching: true
            };
          case Types.fetchSucess:
            return {
              ...state,
              isFetching: false,
              error: null,
              data
            };
          case Types.Errorfetch:
            return {
              ...state,
              isFetching: false,
              error: data
            };
          default:
            return state;
        }
      };
      return Data();
    }
    case Types.getOnboardbooked: {
      const Data = () => {
        switch (action) {
          case Types.isFetching:
            return {
              ...state,
              isFetching: true
            };
          case Types.fetchSucess:
            return {
              ...state,
              isFetching: false,
              error: null,
              data
            };
          case Types.Errorfetch:
            return {
              ...state,
              isFetching: false,
              error: data
            };
          default:
            return state;
        }
      };
      return Data();
    }
    case Types.getConvertedLead: {
      const Data = () => {
        switch (action) {
          case Types.isFetching:
            return {
              ...state,
              isFetching: true
            };
          case Types.fetchSucess:
            return {
              ...state,
              isFetching: false,
              error: null,
              data
            };
          case Types.Errorfetch:
            return {
              ...state,
              isFetching: false,
              error: data
            };
          default:
            return state;
        }
      };
      return Data();
    }
    case Types.getAllLead: {
      const Data = () => {
        switch (action) {
          case Types.isFetching:
            return {
              ...state,
              isFetching: true
            };
          case Types.fetchSucess:
            return {
              ...state,
              isFetching: false,
              error: null,
              data
            };
          case Types.Errorfetch:
            return {
              ...state,
              isFetching: false,
              error: data
            };
          default:
            return state;
        }
      };
      return Data();
    }
    case Types.getArchievedLead: {
      const Data = () => {
        switch (action) {
          case Types.isFetching:
            return {
              ...state,
              isFetching: true
            };
          case Types.fetchSucess:
            return {
              ...state,
              isFetching: false,
              error: null,
              data
            };
          case Types.Errorfetch:
            return {
              ...state,
              isFetching: false,
              error: data
            };
          default:
            return state;
        }
      };
      return Data();
    }
    default:
      return state;
  }
};
export const getFilters = (type, action, state, data) => {
  switch (type) {
    case Types.getLeadSource: {
      const Data = () => {
        switch (action) {
          case Types.isFetching:
            return {
              ...state,
              isFetching: true
            };

          case Types.fetchSucess:
            return {
              ...state,
              isFetching: false,
              data
            };
          default:
            return state;
        }
      };
      return Data();
    }
    case Types.getLeadStatus: {
      const Data = () => {
        switch (action) {
          case Types.isFetching:
            return {
              ...state,
              isFetching: true
            };

          case Types.fetchSucess:
            return {
              ...state,
              isFetching: false,
              data
            };
          default:
            return state;
        }
      };
      return Data();
    }
    case Types.getTags: {
      const Data = () => {
        switch (action) {
          case Types.isFetching:
            return {
              ...state,
              isFetching: true
            };

          case Types.fetchSucess:
            return {
              ...state,
              isFetching: false,
              data: data.map((item) => ({ name: item }))
            };
          default:
            return state;
        }
      };
      return Data();
    }
    case Types.getLeadDefaultMemberStatus: {
      const Data = () => {
        switch (action) {
          case Types.isFetching:
            return {
              ...state,
              isFetching: true
            };

          case Types.fetchSucess:
            return {
              ...state,
              isFetching: false,
              data
            };
          default:
            return state;
        }
      };
      return Data();
    }
    default:
      return state;
  }
};
