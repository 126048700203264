import React, { useState } from 'react';
import PropType from 'prop-types';
import { ROUTES } from 'src/constant/Constants';
import { Link } from 'react-router-dom';
import {
  AppBar,
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  Toolbar,
  withStyles
} from '@material-ui/core';
import { get } from 'js-cookie';
import useChangeTitle from 'src/views/common/CustomHooks/useTitleChange';
import { ReactComponent as BackArrow } from '../../../assets/image/Vanguard/icon_back_vanguard.svg';
import { ReactComponent as ArrowRight } from '../../../assets/image/Vanguard/Varrow-right.svg';
import waiverNew from '../../../assets/image/Vanguard/waiver-new.png';
import ReturningImg from '../../../assets/image/Vanguard/returning.png';
import OnboardingMemberImg from '../../../assets/image/Vanguard/onboardingMemberImg.png';
import InactiveMemberImg from '../../../assets/image/Vanguard/inactivememberImg.png';
import LeavePagePopup from '../LeavepagePopup';
import styles from './membershipIndex.style';

const WaiverIndex = ({ classes }) => {
  const [LeavePageopen, setLeavePageopen] = useState(false);
  const roleid = JSON.parse(get('jbrole') || '{}')?.role_id || 3;
  useChangeTitle('Create Membership | Jungle Alliance');
  return (
    <div>
      <AppBar className={classes.appBar}>
        <Toolbar className={classes.appToolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="close"
            onClick={() => {
              setLeavePageopen(true);
            }}
          >
            <BackArrow />
          </IconButton>
          <h5 className={classes.headerTitle}>Create Membership</h5>
          <Button
            color="inherit"
            className={classes.HomeButton}
            component={Link}
            to={ROUTES.VANGUARD}
          >
            Home
          </Button>
        </Toolbar>
      </AppBar>

      <Card className={classes.MainCard}>
        <Grid
          container
          xs={10}
          justify="center"
          style={{
            margin: '50px auto'
          }}
        >
          <Button
            className={classes.mainList}
            component={Link}
            to={ROUTES.VANGUARD_MEMBERSHIP_NEW}
            style={{
              backgroundImage: `url('${waiverNew}')`
            }}
          >
            <Box>
              <h4 className={classes.mainTitle}>NEW</h4>
            </Box>
            <Box className={classes.mainArrowRight}>
              <ArrowRight />
            </Box>
          </Button>

          <Button
            className={classes.mainList}
            component={Link}
            to={{
              pathname: ROUTES.VANGUARD_MEMBERSHIP_RETURNING,
              state: { type: 'active' }
            }}
            style={{
              backgroundImage: `url('${ReturningImg}')`
            }}
          >
            <Box>
              <h4 className={classes.mainTitle}>ACTIVE MEMBER</h4>
            </Box>
            <Box className={classes.mainArrowRight}>
              <ArrowRight />
            </Box>
          </Button>

          {roleid === 1 && (
            <Button
              className={classes.mainList}
              component={Link}
              to={{
                pathname: ROUTES.VANGUARD_MEMBERSHIP_RETURNING,
                state: { type: 'lead' }
              }}
              style={{
                backgroundImage: `url('${OnboardingMemberImg}')`
              }}
            >
              <Box>
                <h4 className={classes.mainTitle}>ONBOARDING MEMBER</h4>
              </Box>
              <Box className={classes.mainArrowRight}>
                <ArrowRight />
              </Box>
            </Button>
          )}
          <Button
            className={classes.mainList}
            component={Link}
            to={{
              pathname: ROUTES.VANGUARD_MEMBERSHIP_RETURNING,
              state: { type: 'inactive' }
            }}
            style={{
              backgroundImage: `url('${InactiveMemberImg}')`
            }}
          >
            <Box>
              <h4 className={classes.mainTitle}>INACTIVE MEMBER</h4>
            </Box>
            <Box className={classes.mainArrowRight}>
              <ArrowRight />
            </Box>
          </Button>
        </Grid>
      </Card>
      <LeavePagePopup
        setOpen={LeavePageopen}
        handleClose={() => setLeavePageopen(false)}
        handleHistory={ROUTES.VANGUARD}
      />
    </div>
  );
};

WaiverIndex.propTypes = {
  classes: PropType.object.isRequired
};
export default withStyles(styles)(WaiverIndex);
