/* eslint-disable arrow-parens */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Breadcrumbs, Grid, Typography, withStyles } from '@material-ui/core';
import { ROUTES } from 'src/constant/Constants';
import { get } from 'js-cookie';
import styles from './Documents.style';
import DocumentsTabs from './DocumentsTabs';
import { getActiveVal, getMissingVal } from './Documents.utils';

class Documents extends React.PureComponent {
  render() {
    const { classes, location } = this.props;
    const Tabslocation = getActiveVal(location.pathname);
    const Missinlocation = getMissingVal(location.pathname);
    const roleid = JSON.parse(get('jbrole') || '{}')?.role_id || 3;

    return (
      <div className={classes.root}>
        <div className="title-bk">
          <div className={classes.new_programs} style={{ marginBottom: 20 }}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={0}
            >
              <Grid item xs={12}>
                <Breadcrumbs aria-label="breadcrumb">
                  <div color="inherit">documents</div>
                  <Link color="inherit" to={ROUTES.DOCUMENTS_CONTRACTS_MISSING}>
                    Contracts
                  </Link>
                  <Typography variant="h3" color="Primary">
                    {(() => {
                      switch (Tabslocation) {
                        case 0:
                          return (
                            <Fragment>
                              Missing
                              <label>/</label>
                              {(() => {
                                switch (Missinlocation) {
                                  case 0:
                                    return <span>Active</span>;
                                  case 1:
                                    return <span>Inactive</span>;
                                  case 2:
                                    return <span>All</span>;
                                  default:
                                    return null;
                                }
                              })()}
                            </Fragment>
                          );
                        case 1:
                          return <span>signed</span>;
                        case 2:
                          return roleid === 1 ? <span>Templates</span> : null;
                        default:
                          return null;
                      }
                    })()}
                  </Typography>
                </Breadcrumbs>
              </Grid>
              {/* <Grid item xs={6}>
                <JBButton
                  type="primary"
                  isImg={Plusicon}
                  component={Link}
                  to="/app/class/create"
                  style={{ float: 'right' }}
                >
                  new contract
                </JBButton>
              </Grid> */}
            </Grid>
          </div>
        </div>

        <DocumentsTabs />
      </div>
    );
  }
}

Documents.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.any.isRequired
};

export default withStyles(styles)(Documents);
