import { validSign } from 'src/utils/validations';
import types from './VanguardWaiver.constants';

export const setIsLoading = (payload) => ({
  type: types.setIsLoading,
  payload
});

export const NextStep = () => ({
  type: types.NextStep
});
export const ResetStep = () => ({
  type: types.ResetStep
});
export const StepBack = () => ({
  type: types.StepBack
});

export const SetActiveStep = (payload) => ({
  type: types.SetActiveStep,
  payload
});

export const ResetData = () => ({
  type: types.ResetData
});

export const SetMemberData = (payload) => ({
  type: types.SetMember,
  payload
});

export const SelectWaiverId = (payload) => ({
  type: types.SetSelectedWaiver,
  payload
});

export const toggleSignStatus = () => ({
  type: types.toggleSignStatus
});

export const setSignStatus = (payload) => ({
  type: types.setSignStatus,
  payload
});

export const setSignInitials = (payload) => ({
  type: types.setSignInitials,
  payload: validSign(payload)
});

export const setQuestionnaire = (payload) => ({
  type: types.setQuestionnaire,
  payload
});

export const setSignature = (payload) => ({
  type: types.setSignature,
  payload
});

export const setIsSigned = (payload) => ({
  type: types.setIsSigned,
  payload
});

export const setWaiverInitialBox = (payload) => ({
  type: types.setWaiverInitialBox,
  payload
});
