import authhttp from './authHttp.services';

// eslint-disable-next-line import/prefer-default-export
export const getMasterhqAllies = async (values) => {
  const url = 'api/affiliate_list';
  const {
    data: {
      data: { data }
    }
  } = await authhttp.post(url, values);
  return data;
};

export const StripeLinkSend = async (id) => {
  const url = `api/affiliate_stripe_link/${id}`;
  const data = await authhttp.get(url);
  return data;
};

export const sendExpiredLink = async (id) => {
  const url = `api/affiliate_stripe_link/${id}`;
  const { data } = await authhttp.get(url);
  return data;
};

export const activatedStripeLink = async (id) => {
  const url = `api/stripe_account_setup_verify/${id}`;
  const { data } = await authhttp.get(url);
  return data;
};

export const getDeactivationDates = async (id) => {
  const url = `api/getDateLists/${id}`;
  const {
    data: { data }
  } = await authhttp.get(url);
  return data;
};

export const deactivateAlly = async (values) => {
  const url = 'api/setdeactivationdate';
  const { data } = await authhttp.post(url, values);
  return data;
};

export const reactivationAlly = async (affiliate_id) => {
  const url = 'api/affiliate_reactivate';
  const values = {
    affiliate_id
  };
  const { data } = await authhttp.post(url, values);
  return data;
};
