import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import MasterHQTabs from '../../views/masterHQ/masterHQTabs';
import NavBar from './MasterNav';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.masterhq.root.backgroundColor,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%',
    '& header': {
      backgroundColor: theme.masterhq.root.backgroundColor
    }
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    height: 'calc(100vh)',
    paddingTop: 104,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 250
    }
  },
  wrapperLeft: {
    display: 'flex',
    // overflow: 'hidden',
    paddingTop: 140,
    wordBreak: 'break-all',
    width: '285px',
    // height: 'calc(100% - 195px)',
    minHeight: '200px',
    borderRight: '0',
    backgroundColor: '#1B1C30',
    position: 'fixed',
    [theme.breakpoints.up('lg')]: {
      // paddingLeft: 272
    }
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}));

const MasterHQLayout = () => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const miniMenu = localStorage.getItem('mini-menu');
  return (
    <div className={classes.root}>
      <div
        className={classes.wrapperLeft}
        style={{
          width: miniMenu === 'false' ? 'auto' : null
        }}
      >
        <NavBar
          onMobileClose={() => setMobileNavOpen(false)}
          openMobile={isMobileNavOpen}
        />
      </div>

      <div
        // className={classes.wrapper}
        className={clsx(
          classes.wrapper,
          miniMenu === 'false' ? 'wrapperContent' : null
        )}
      >
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            <MasterHQTabs />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MasterHQLayout;
