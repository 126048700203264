import { getValuefromObject } from 'src/utils/someCommon';

export const getFilter = (id, data, selected) => {
  switch (id) {
    case 1:
      return {
        filter_by_status: getValuefromObject(data),
        status_operator: getValuefromObject(selected)
      };
    case 2:
      return {
        filter_by_contract: getValuefromObject(data),
        contract_operator: getValuefromObject(selected)
      };
    case 3:
      return {
        filter_by_membership: getValuefromObject(data),
        membership_operator: getValuefromObject(selected)
      };

    default:
      return null;
  }
};

export const getfilterLabel = (id, data, selected) => {
  switch (id) {
    case 1:
      return {
        id,
        name: `Member Status ${Object.values(selected)[1]} ${
          Object.values(data)[1]
        }`,
        filter: 'filter_by_status',
        connetor: 'status_operator'
      };
    case 2:
      return {
        id,
        name: `Contract ${Object.values(selected)[1]} ${
          Object.values(data)[1]
        }`,
        filter: 'filter_by_contract',
        connetor: 'contract_operator'
      };
    case 3:
      return {
        id,
        name: `Membership Template ${Object.values(selected)[1]} ${data.name}`,
        filter: 'filter_by_membership',
        connetor: 'membership_operator'
      };

    default:
      return null;
  }
};

export const checkData = (filter, data) => {
  if (!filter || !data || String(data).includes('Invalid')) {
    return true;
  }
  return false;
};
