import { API_KEY } from 'src/constant/Constants';
import authhttp from './authHttp.services';

// eslint-disable-next-line
export const getGooglePlaceId = async (search) => {
  // const proxyUrl = 'https://cors-anywhere.herokuapp.com/';
  const GoogleUrl =
    'https://maps.googleapis.com/maps/api/place/autocomplete/json';
  let res = await fetch(`${GoogleUrl}?input=${search}&key=${API_KEY}`);
  res = res.json();
  return res;
};

export const getPlaceDetails = async (place_id) => {
  const GoogleUrl = 'https://maps.googleapis.com/maps/api/place/details/json';
  const {
    result: { address_components }
  } = await fetch(
    `${GoogleUrl}?place_id=${place_id}&key=${API_KEY}`
  ).then((res) => res.json());
  return address_components;
};

export const getlocMarks = async (address) => {
  const GoogleUrl = 'https://maps.googleapis.com/maps/api/geocode/json';
  const { results } = await fetch(
    `${GoogleUrl}?address=${address}&key=${API_KEY}`
  ).then((res) => res.json());
  if (results.length > 0) {
    return results[0].geometry.location;
  }
  return { lat: null, lng: null };
};

export const getCountries = async () => {
  const CountUrl = 'api/countries';
  const {
    data: { data }
  } = await authhttp.get(CountUrl);
  return data;
};

export const getGymloc = async () => {
  const LocUrl = 'api/gym_locations';
  const {
    data: { data }
  } = await authhttp.get(LocUrl);
  return data;
};

export const getActiveMem = async (search) => {
  const ActiveMemUrl = 'api/get_guardians_list';
  const {
    data: { data }
  } = await authhttp.get(ActiveMemUrl, {
    params: {
      search
    }
  });
  return data;
};
export const addNotesforUser = async (val) => {
  const notesUrl = 'api/add_notes_for_users';
  const {
    data: { data }
  } = await authhttp.post(notesUrl, val);
  return data;
};
export const createUser = async (formData) => {
  const createUserUrl = 'api/users';
  const {
    data: { data }
  } = await authhttp.post(createUserUrl, formData);
  return data;
};
export const createHold = async (values) => {
  const createUserUrl = 'api/user_membership_hold';
  const {
    data: { data }
  } = await authhttp.post(createUserUrl, values);
  return data;
};
export const stopAutoRenew = async (id, values) => {
  const createUserUrl = `api/stopautorenew/${id}`;
  const {
    data: { data }
  } = await authhttp.post(createUserUrl, values);
  return data;
};

export const deactivateMemberProfile = async (values) => {
  const createUserUrl = 'api/member_deactivate_profile';
  const {
    data: { data }
  } = await authhttp.post(createUserUrl, values);
  return data;
};
export const reactivateMemberProfile = async (values) => {
  const createUserUrl = 'api/member_reactivate_profile';
  const {
    data: { data }
  } = await authhttp.post(createUserUrl, values);
  return data;
};
export const getHoldList = async (values) => {
  const CountUrl = 'api/user_membership_hold';
  const {
    data: { data }
  } = await authhttp.get(CountUrl, { params: values });
  return data;
};

export const getHoldItem = async (id) => {
  const CountUrl = `api/user_membership_hold/${id}/edit`;
  const {
    data: { data }
  } = await authhttp.get(CountUrl);
  return data;
};
export const editHold = async (values, id) => {
  const updateUrl = `api/user_membership_hold/${id}`;
  const {
    data: { data }
  } = await authhttp.put(updateUrl, values);
  return data;
};

export const deleteHold = async (id) => {
  const ContUrl = `api/user_membership_hold/${id}`;
  const { data } = await authhttp.del(ContUrl);
  return data;
};

export const deleteMember = async (id) => {
  const ContUrl = `api/user_member/${id}`;
  const { data } = await authhttp.del(ContUrl);
  return data;
};
export const getMembers = async (values) => {
  const getMemUrl = 'api/listofuser';
  const {
    data: { data }
  } = await authhttp.get(getMemUrl, {
    params: values
  });
  return data;
};

export const editMember = async (id) => {
  const editMemUrl = `api/users/${id}/edit`;
  const {
    data: { data }
  } = await authhttp.get(editMemUrl);
  return data;
};
export const resetUserPassword = async (id, val) => {
  const resetUrl = `api/reset_password/${id}`;
  const {
    data: { data }
  } = await authhttp.post(resetUrl, null, { params: val });
  return data;
};
export const updateUser = async (values, id) => {
  const updateUrl = `api/users/${id}`;
  const {
    data: { data }
  } = await authhttp.put(updateUrl, values);
  return data;
};

export const getUserMembership = async (values) => {
  const getMemUrl = 'api/user_member';

  const {
    data: { data }
  } = await authhttp.get(getMemUrl, { params: values });
  return data;
};

export const getUserMemhold = async (values) => {
  const getMemUrl = 'api/user_membership_hold';

  const {
    data: { data }
  } = await authhttp.get(getMemUrl, { params: values });
  return data;
};

export const searchMember = async (search) => {
  const url = 'api/quick_user_search';
  const {
    data: { data }
  } = await authhttp.get(url, { params: { search } });
  return data;
};

// Activity History tabs
export const getUserActivityHistory = async (val) => {
  const getHistoryUrl = 'api/user_activity_history';
  const {
    data: { data }
  } = await authhttp.get(getHistoryUrl, {
    params: val
  });
  return data;
};
export const addUserActivity = async (values) => {
  const getHistoryUrl = 'api/user_activity_history';
  const {
    data: { data }
  } = await authhttp.post(getHistoryUrl, values);
  return data;
};
export const getUserAttendance = async (val) => {
  const getAttendanceUrl = 'api/get_attendance';
  const {
    data: { data }
  } = await authhttp.get(getAttendanceUrl, {
    params: val
  });
  return data;
};

// user image
export const ChangeProfilePic = async (val) => {
  const ProfilePicUrl = 'api/change_image_upload';
  const {
    data: { data }
  } = await authhttp.post(ProfilePicUrl, val);
  return data;
};

export const CheckEmailAvaibility = async (val) => {
  const CheckEmailUrl = 'api/email_avaibility';
  const {
    data: { data }
  } = await authhttp.get(CheckEmailUrl, { params: val });
  return data;
};

export const searchUserByEmail = async (val) => {
  const SearchEmailUrl = 'api/search_user_email';
  const {
    data: { data }
  } = await authhttp.post(SearchEmailUrl, val);
  return data;
};

export const deleteUserImage = async (id) => {
  const deleteUrl = `api/remove_image/${id}`;
  const data = await authhttp.del(deleteUrl);
  return data;
};

export const fetchAllRoles = async () => {
  const rolesUrl = 'api/roles';
  const {
    data: { data }
  } = await authhttp.get(rolesUrl);
  return data;
};
export const exportUsers = async (values) => {
  const exportUrl = 'api/export_all_users';

  authhttp
    .post(exportUrl, values, {
      responseType: 'blob'
    })
    .then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'users.xlsx');
      document.body.appendChild(link);
      link.click();
    });
};
