/* eslint-disable operator-linebreak */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import PropType from 'prop-types';
import {
  Grid,
  Typography,
  Box,
  withStyles,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import FormControl from 'src/views/common/FormControl/formControl';
import CancelDialog from 'src/views/common/CommonDialogBox/CancelDialog';
import IOSSwitch from 'src/views/common/FormControl/IOSSwitch';
import { checkValidString } from 'src/utils/validations';
import { connect } from 'react-redux';
import { openNoti } from 'src/Redux/global/global.actions';
import LoadingView from 'src/views/common/loading';
import { checkQuillValid } from 'src/utils/someCommon';
import { createMemberSuccess } from 'src/services/Communication.service';
// import JBCKEditor from 'src/views/common/JBCKEditor';
import JBCKEditorPlaceholder from 'src/views/common/JBCKEditorPlaceholder';
import useChangeTitle from 'src/views/common/CustomHooks/useTitleChange';
import ResettemplateDialog from '../../ResettemplateDialog';
import JBButton from '../../../../common/JBButton/JBButton';
import styles from '../../automatedemails.style';
import { getErrorMsg } from '../../automatedemails.utils';

const MemberPaymentRefund = ({
  classes,
  info,
  openNotify,
  reset,
  isFetching
}) => {
  const [data, setData] = useState(info);
  useChangeTitle('Member payment refund | Jungle Alliance');
  const holderList = [
    'memfirstname',
    'memlastname',
    'gymname',
    'invoiceduedate',
    'invoicefinalcharge',
    'invoicefooter',
    'invoiceid',
    'invoicenotes',
    'invoice',
    'invoiceunpaidamount',
    'paymentmethodelast4',
    'paymentmethodetype',
    'transactionamount',
    'signature'
  ];
  const handleCreate = () => {
    const result = {
      setting: JSON.stringify(data.setting),
      subject: data.email_template.subject,
      body: data.email_template.body
    };
    createMemberSuccess(result, 5) // pass id
      .then(() => {
        openNotify('Email template successfully saved');
      })
      .catch((error) => openNotify(getErrorMsg(error), 'error'));
  };
  const handleSave = () => {
    if (
      !data.email_template.subject ||
      checkValidString(data.email_template.subject)
    ) {
      openNotify('Please enter a valid subject', 'error');
    } else if (
      !data.email_template.body ||
      !checkQuillValid(data.email_template.body)
    ) {
      openNotify('Please enter a valid email body', 'error');
    } else {
      handleCreate();
    }
  };
  if (isFetching) return <LoadingView />;
  return (
    <Box>
      <Grid container direction="row">
        <Grid container direction="row">
          <Grid
            item
            xs={6}
            style={{
              alignItems: 'center',
              display: 'flex'
            }}
          >
            <Typography variant="h5" style={{ color: '#000' }}>
              When should this email be sent?
            </Typography>
          </Grid>
          <Grid item xs={6} justify="flex-end" className="MuiGrid-container">
            <FormControlLabel
              disabled={!data.setting.send_after_success_refund_editable}
              checked={data.setting.send_after_success_refund}
              control={
                <Checkbox
                  name="checkedB"
                  color="primary"
                  style={{ color: '#949494' }}
                />
              }
              label="Send after each successful refund"
              className={classes.ckControlLabel}
            />
          </Grid>
        </Grid>
        <div className={classes.hr} />
        <Grid item xs={12}>
          <Grid
            item
            xs={12}
            className="switch-btn"
            style={{ marginBottom: '15px' }}
          >
            <FormControlLabel
              control={
                <IOSSwitch
                  checked={data.setting.enabled}
                  onChange={() => {
                    setData({
                      ...data,
                      setting: {
                        ...data.setting,
                        enabled: !data.setting.enabled * 1
                      }
                    });
                  }}
                />
              }
              label="Enabled?"
            />
          </Grid>
          <Box mb="30px">
            <FormControl
              value={data.email_template.subject}
              onChange={(val) => {
                setData({
                  ...data,
                  email_template: {
                    ...data.email_template,
                    subject: val.target.value
                  }
                });
              }}
              control="input"
              label="Subject"
              style={{ margin: '0 0' }}
            />
          </Box>
          <Box>
            {/* <JBEmailQuillEditor
              value={data.email_template.body}
              onChange={(val) => {
                setData({
                  ...data,
                  email_template: {
                    ...data.email_template,
                    body: val
                  }
                });
              }}
            /> */}
            {/* <JBCKEditor
              data={data.email_template.body}
              onChange={(e) => {
                setData({
                  ...data,
                  email_template: {
                    ...data.email_template,
                    body: e.editor.getData()
                  }
                });
              }}
            /> */}
            <JBCKEditorPlaceholder
              data={data.email_template.body}
              holder={holderList}
              onChange={(e) => {
                setData({
                  ...data,
                  email_template: {
                    ...data.email_template,
                    body: e.editor.getData()
                  }
                });
              }}
            />
          </Box>
        </Grid>
      </Grid>

      <Box mt="30px" />

      <Grid container direction="row" justify="flex-end" alignItems="center">
        <CancelDialog />
        <Box ml="10px">
          <ResettemplateDialog
            onReset={() => {
              setData({
                ...reset,
                email_template: JSON.parse(reset.default_email_template)
              });
              openNotify('Email template reseted successfuly');
            }}
          />
        </Box>
        <Box ml="10px">
          <JBButton
            type="primary"
            onClick={handleSave}
            // component={Link}
            // to={ROUTES.PEOPLE_CREATE_MEMBER}
          >
            Save
          </JBButton>
        </Box>
      </Grid>
    </Box>
  );
};

MemberPaymentRefund.propTypes = {
  classes: PropType.object.isRequired,
  info: PropType.object.isRequired,
  openNotify: PropType.func.isRequired
};
const mapToDispatch = (dispatch) => ({
  openNotify: (msg, type) => dispatch(openNoti(msg, type))
});
export default connect(
  null,
  mapToDispatch
)(withStyles(styles)(MemberPaymentRefund));
