/* eslint-disable react/require-default-props */
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropType from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Checkbox,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  ThemeProvider,
  Tooltip,
  withStyles
} from '@material-ui/core';
import filterType from 'src/Redux/Reducer.constants';
import { ROUTES } from 'src/constant/Constants';
import { get } from 'js-cookie';
import ClassTableToolbar from './InvoicesTableHead';
import CheckIcon from '../../../assets/image/check.png';
import { ReactComponent as InfoIcon } from '../../../assets/image/infoicon.svg';

const useStyles = makeStyles({
  table: {
    minWidth: '100%',
    wordBreak: 'break-word',
    '@media (max-width: 1440px)': {
      '& td': {
        padding: '10px 6px 10px 6px'
      }
    }
  },
  tableLink: {
    color: '#01847A',
    fontWeight: '700'
  },
  dayofweek: {
    background: '#A9BFEF',
    borderRadius: '50px',
    padding: '8px',
    color: '#FFF'
  },
  TooltipLink: {
    '&:hover': {
      '& svg path': {
        stroke: '#01847A'
      }
    }
  },
  TableCell: {
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px',
    borderBottom: '1px solid #A8A8A8',
    height: '56px',
    minHeight: '56px',
    '@media (max-width: 1440px)': {
      padding: '10px 6px 10px 6px'
    }
  },
  selected: {
    backgroundColor: '#E6F3F2 !important'
  }
});

const styles = () => ({
  TooltipLink: {
    display: '-webkit-box',
    border: '1px solid #cccccc',
    padding: '9px',
    width: '150px',
    height: '45px',
    overflow: 'hidden',
    TextOverflow: 'ellipsis',
    '& svg path': {
      stroke: '#949494'
    },
    '&:hover': {
      '& svg path': {
        stroke: '#01847A'
      }
    }
  }
});

const tooltiptheme = {
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: '14px',
        fontWeight: '700',
        color: '#FFF',
        backgroundColor: '#01847A',
        borderRadius: '2px',
        padding: '10px 8px'
      },
      arrow: {
        color: '#01847A'
      }
    }
  }
};

const InvoiceTable = (props) => {
  const classes = useStyles();
  const {
    values,
    isActive,
    setDirection,
    setSort,
    tabs,
    total,
    selected,
    setSelected
  } = props;
  const {
    sort_invoiceno,
    sort_member,
    sort_product_name,
    sortstatus,
    sort_autobill,
    sort_final_charge,
    sort_payment_method,
    sort_payment_due
  } = filterType;
  const areAllSelected = useSelector(
    (state) => state.InvoiceReducer.areAllSelected
  );
  const roleid = JSON.parse(get('jbrole') || '{}')?.role_id || 3;
  // const [selected, setSelected] = useState([]);

  useEffect(() => {
    if (areAllSelected) {
      setSelected(values.map((n) => n.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areAllSelected, values]);
  const handleClick = (name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = values.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  const ClearCheckbox = () => {
    setSelected([]);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  return (
    <div>
      <TableContainer component={Paper} elevation={0}>
        {selected.length > 0 && (
          <ClassTableToolbar
            numSelected={selected}
            selectedTags={[]}
            ClearChecks={ClearCheckbox}
            module="class"
            tabs={tabs}
            total={total}
            values={values}
            setSelected={setSelected}
          />
        )}
        <Table className={classes.table} aria-label="simple table">
          {selected.length === 0 && (
            <TableHead>
              <TableRow style={{ background: '#F9F9FA' }}>
                <TableCell
                  padding="checkbox"
                  className={classes.TableCell}
                  style={{ width: '1%' }}
                >
                  <Checkbox
                    color="primary"
                    onChange={handleSelectAllClick}
                    inputProps={{ 'aria-labelledby': 'IDN' }}
                  />
                </TableCell>
                <TableCell
                  style={{ width: '10%' }}
                  className={classes.TableCell}
                >
                  <TableSortLabel
                    active={isActive(sort_invoiceno)}
                    direction={setDirection(sort_invoiceno)}
                    onClick={() => setSort(sort_invoiceno)}
                  >
                    Invoice
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  className={classes.TableCell}
                  style={{ width: '15%' }}
                >
                  <TableSortLabel
                    active={isActive(sort_member)}
                    direction={setDirection(sort_member)}
                    onClick={() => setSort(sort_member)}
                  >
                    Member name
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  className={classes.TableCell}
                  style={{ width: '13%' }}
                >
                  <TableSortLabel
                    active={isActive(sort_product_name)}
                    direction={setDirection(sort_product_name)}
                    onClick={() => setSort(sort_product_name)}
                  >
                    Product name
                  </TableSortLabel>
                </TableCell>
                {tabs === 5 ? null : (
                  <TableCell
                    className={classes.TableCell}
                    style={{ width: '10%' }}
                  >
                    <TableSortLabel
                      active={isActive(sortstatus)}
                      direction={setDirection(sortstatus)}
                      onClick={() => setSort(sortstatus)}
                    >
                      Status
                    </TableSortLabel>
                  </TableCell>
                )}
                <TableCell
                  className={classes.TableCell}
                  style={{ width: '15%' }}
                >
                  <TableSortLabel
                    active={isActive(sort_payment_method)}
                    direction={setDirection(sort_payment_method)}
                    onClick={() => setSort(sort_payment_method)}
                  >
                    {tabs === 2 || tabs === 4 || tabs === 5 || tabs === 0
                      ? 'Payment method'
                      : 'Default payment method'}
                  </TableSortLabel>
                </TableCell>

                <TableCell
                  className={classes.TableCell}
                  style={{ width: '10%' }}
                  align="center"
                >
                  {tabs === 5 ? (
                    <TableSortLabel
                      active={isActive(sort_payment_method)}
                      direction={setDirection(sort_payment_method)}
                      onClick={() => setSort(sort_payment_method)}
                    >
                      Failed Reason
                      <ThemeProvider theme={tooltiptheme}>
                        <Tooltip
                          title="Please hover over the text to view more details."
                          arrow
                          placement="top"
                          style={{
                            verticalAlign: 'middle',
                            marginLeft: '2px'
                          }}
                        >
                          <span className={classes.TooltipLink}>
                            <InfoIcon />
                          </span>
                        </Tooltip>
                      </ThemeProvider>
                    </TableSortLabel>
                  ) : (
                    <TableSortLabel
                      active={isActive(sort_autobill)}
                      direction={setDirection(sort_autobill)}
                      onClick={() => setSort(sort_autobill)}
                    >
                      Auto bill
                    </TableSortLabel>
                  )}
                </TableCell>
                <TableCell
                  className={classes.TableCell}
                  style={{ width: '10%' }}
                  align="right"
                >
                  <TableSortLabel
                    active={isActive(sort_final_charge)}
                    direction={setDirection(sort_final_charge)}
                    onClick={() => setSort(sort_final_charge)}
                  >
                    Final charge
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  className={classes.TableCell}
                  style={{ width: '12%' }}
                  align="right"
                >
                  <TableSortLabel
                    active={isActive(sort_payment_due)}
                    direction={setDirection(sort_payment_due)}
                    onClick={() => setSort(sort_payment_due)}
                  >
                    Payment due
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {values.map((row) => (
              <TableRow
                hover
                // role="checkbox"
                aria-checked={isSelected(row.id)}
                tabIndex={-1}
                key={row.id}
                classes={{ selected: classes.selected }}
                selected={isSelected(row.id)}
                style={{ backgroundColor: '#FFF' }}
              >
                <TableCell padding="checkbox" style={{ width: '1%' }}>
                  <Checkbox
                    onClick={() => handleClick(row.id)}
                    color="primary"
                    checked={isSelected(row.id)}
                    inputProps={{ 'aria-labelledby': 'IDN' }}
                  />
                </TableCell>

                <TableCell
                  style={{
                    position: 'relative',
                    color: '#01847A',
                    fontWeight: '700',
                    width: '10%'
                  }}
                >
                  {roleid === 1 ? (
                    <Link
                      className={classes.tableLink}
                      to={{
                        pathname:
                          row.invoice_status !== 1
                            ? ROUTES.FINANCIAL_INVOICES_EDIT
                            : ROUTES.FINANCIAL_INVOICES_VIEW,
                        search: `?Invoice=${btoa(row.id)}`,
                        state: row.invoice_status
                      }}
                    >
                      {row.invoice_no}
                    </Link>
                  ) : (
                    <span className={classes.tableLink}>{row.invoice_no}</span>
                  )}
                </TableCell>
                <TableCell
                  style={{
                    width: '15%'
                  }}
                >
                  <Link
                    className={classes.tableLink}
                    // to={ROUTES.PEOPLE_MEMBERS}
                    to={{
                      pathname: ROUTES.PEOPLE_MEMBER_PROFILE,
                      search: `memberId=${btoa(row.user_id)}`
                    }}
                  >
                    {row.member_name}
                  </Link>
                </TableCell>
                <TableCell
                  style={{
                    width: '13%'
                  }}
                >
                  {row.invoice_name}
                </TableCell>
                {tabs === 5 ? null : (
                  <TableCell
                    style={{
                      width: '10%'
                    }}
                  >
                    {row.invoice_status_str}
                  </TableCell>
                )}
                <TableCell
                  style={{
                    width: '15%'
                  }}
                >
                  {row.default_method_str}
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    width: '9%'
                  }}
                >
                  {tabs === 5 ? (
                    row.trans_message ? (
                      <ThemeProvider theme={tooltiptheme}>
                        <Tooltip
                          title={row.trans_message}
                          arrow
                          placement="top"
                          style={{
                            verticalAlign: 'middle',
                            marginLeft: '8px'
                          }}
                        >
                          <span
                            className={classes.TooltipLink}
                            style={{
                              cursor: 'pointer',
                              display: '-webkit-box',
                              height: '43px',
                              overflow: 'hidden',
                              TextOverflow: 'ellipsis',
                              width: '150px',
                              WebkitLineClamp: 2,
                              WebkitBoxOrient: 'vertical'
                            }}
                          >
                            {row.trans_message}
                          </span>
                        </Tooltip>
                      </ThemeProvider>
                    ) : (
                      ''
                    )
                  ) : (
                    <>
                      {row.auto_bill === 1 ? (
                        <img
                          src={CheckIcon}
                          alt="img"
                          style={{
                            marginRight: '20px'
                          }}
                        />
                      ) : null}
                    </>
                  )}
                </TableCell>
                <TableCell
                  align="right"
                  style={{
                    width: '10%'
                  }}
                >
                  {row.final_amount}
                </TableCell>
                <TableCell
                  align="right"
                  style={{
                    width: '12%'
                  }}
                >
                  {row.due_date}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {values.length === 0 && (
          <p
            style={{
              margin: '10px 15px'
            }}
          >
            No invoices to show...
          </p>
        )}
      </TableContainer>
    </div>
  );
};
InvoiceTable.propTypes = {
  values: PropType.array.isRequired,
  isActive: PropType.func.isRequired,
  setDirection: PropType.func.isRequired,
  setSort: PropType.func.isRequired,
  tabs: PropType.number,
  total: PropType.number,
  selected: PropType.array,
  setSelected: PropType.func
};

export default withStyles(styles)(InvoiceTable);
