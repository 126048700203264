import React, { useEffect } from 'react';
import PropType from 'prop-types';
import { connect } from 'react-redux';
import { Breadcrumbs, Grid, Typography, withStyles } from '@material-ui/core';
import JBButton from 'src/views/common/JBButton/JBButton';
import { ROUTES } from 'src/constant/Constants';
import { Link } from 'react-router-dom';
import { get } from 'js-cookie';
import useChangeTitle from 'src/views/common/CustomHooks/useTitleChange';
import Plusicon from '../../../assets/image/plusicon.png';
import Embed from '../../../assets/image/code.png';
import { ChangeViewCal } from '../../../Redux/CalenderReducer/Calender.actions';

const styles = () => ({
  btnEmbed: {
    '& .MuiButton-outlined.Mui-disabled': {
      '& img': {
        opacity: '0.3'
      }
    }
  }
});

const CalenderView = ({ currentView, changeView, classes }) => {
  const roleid = JSON.parse(get('jbrole') || '{}')?.role_id || 3;
  useChangeTitle('Calendar');
  useEffect(() => {
    localStorage.setItem('calendarOpen', 'true');

    return () => localStorage.removeItem('calendarOpen');
  });
  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      style={{ marginBottom: '15px' }}
    >
      <Grid item xs={6}>
        <div className="title-bk">
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" to={ROUTES.CLASSES_CALENDAR}>
              Classes
            </Link>
            <Link
              color="inherit"
              to={ROUTES.CLASSES_CALENDAR}
              onClick={() => changeView('week')}
            >
              Calendar
            </Link>
            <Typography variant="h3" color="Primary">
              <span>{currentView === 'agenda' ? 'time' : currentView}</span>
            </Typography>
          </Breadcrumbs>
        </div>
      </Grid>
      <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <div className={classes.btnEmbed}>
          <JBButton type="outlined" isImg={Embed} disabled>
            EMBED
          </JBButton>
        </div>
        {roleid <= 2 && (
          <JBButton
            type="primary"
            isImg={Plusicon}
            component={Link}
            to={ROUTES.CLASSES_CREATE_NEW_CLS}
            style={{ marginLeft: '10px' }}
          >
            New Class
          </JBButton>
        )}
      </Grid>
    </Grid>
  );
};

CalenderView.propTypes = {
  currentView: PropType.string.isRequired,
  changeView: PropType.func.isRequired,
  classes: PropType.object.isRequired
};

const mapToState = (state) => ({
  currentView: state.calenderReducer.currentView
});

const mapToDispatch = (dispatch) => ({
  changeView: (view) => dispatch(ChangeViewCal(view))
});

// export default connect(mapToState, mapToDispatch)(CalenderView);
export default connect(
  mapToState,
  mapToDispatch
)(withStyles(styles)(CalenderView));
