const styles = (theme) => ({
  table: {
    fontSize: '14px',
    lineHeight: '22px'
  },
  table_th: {
    backgroundColor: '#F9F9FA',
    color: '#292929'
  },
  Table_td_link: {
    color: '#01847A',
    fontWeight: '700',
    cursor: 'pointer'
  },
  TableCell: {
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px',
    borderBottom: '1px solid #A8A8A8',
    height: '56px',
    minHeight: '56px'
  },
  Table_td: {
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '22px',
    position: 'relative',
    padding: '12px 15px 13px 25px',
    height: '56px',
    minHeight: '56px'
  },
  colorcode: {
    width: '10px',
    height: '10px',
    position: 'absolute',
    left: '0',
    top: '21px',
    borderRadius: '50%'
  },
  button_status: {
    fontWeight: 'bold',
    fontSize: '11px'
  },
  button_status_act: {
    fontWeight: 'bold',
    fontSize: '11px',
    color: '#9B9B9B',
    border: '1px solid #949494',
    width: '100px',
    height: '24px',
    padding: '0 0',
    borderRadius: '2px',
    '&$hover': {
      backgroundColor: '#1976d2'
    }
  },
  button_status_deac: {
    fontWeight: 'bold',
    fontSize: '11px',
    color: '#01847A',
    border: '1px solid rgba(1, 132, 122, 1)',
    width: '100px',
    height: '24px',
    padding: '0 0',
    borderRadius: '2px'
  },
  selected: {
    backgroundColor: 'red !important'
  },
  root: {
    marginTop: 30,
    margin: 20,
    padding: 20,
    root: {
      padding: theme.spacing(4)
    }
  },
  mainCard: {
    boxShadow: '4px 0px 16px rgba(37, 40, 43, 0.08)',
    borderRadius: '5px',
    background: '#FFFF',
    padding: 30,
    overflow: 'visible'
  },
  button: {
    background: '#01847A',
    color: '#FFF',
    padding: '15px 24px',
    boxShadow: '0px 8px 16px rgba(0, 126, 255, 0.16);',
    float: 'right;',
    '&:hover': {
      backgroundColor: '#01847A'
    }
  },
  btn_previou: {
    color: '#01847a',
    border: '1px solid #01847a',
    borderRadius: '0',
    minHeight: '48px',
    padding: '15px 16px'
  },
  cutom_select_border: {
    border: '1px solid #a9a9a9'
  },
  cutom_select_Label: {
    fontSize: '12px',
    padding: '7px 0'
  },
  ptext: {
    fontSize: '16px',
    lineHeight: '24px',
    color: '#7F7F7F',
    fontWeight: 'normal'
  },
  dialogtitle: {
    fontSize: '32px'
  },
  DialogJBStepper: {
    padding: '0 0'
  },
  hr: {
    border: '1px solid rgb(196 196 196 / 0.4)',
    display: 'inline-block',
    width: '100%',
    margin: '10px 0'
  },
  SimpleTablestyle: {
    '& .MuiTableContainer-root': {
      border: '1px solid #9b9b9b'
    },
    '& .MuiTableCell-stickyHeader': {
      backgroundColor: '#f9f9fa'
    }
  },
  searchInput: {
    padding: '6px 20px 6px !important;',
    paddingRight: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    height: '35px',
    border: '1px solid #a9a9a9'
  },
  MassMembershipsTable: {
    '& .MuiTableCell-stickyHeader': {
      backgroundColor: '#f9f9fa'
    }
  }
});

export default styles;
