import React from 'react';
import PropType from 'prop-types';
import {
  withStyles,
  DialogTitle,
  Typography,
  DialogContent,
  DialogActions,
  Dialog
} from '@material-ui/core';
import { chalkBoardDialogStatus } from 'src/Context/ChalkBoardReducer/chalBoard.reducer.data';
import Questionmark from 'src/assets/image/questionmark.png';
import X from 'src/assets/image/x.png';
import { ReactComponent as CheckIcon } from 'src/assets/image/checkicon.svg';
import JBButton from 'src/views/common/JBButton/JBButton';
import styles from './WarningDialog.style';

const WarningDialogPopup = React.memo((props) => {
  const {
    classes,
    open,
    dialogerrmsg,
    dialogStatus,
    isCancelDialog,
    handleOk,
    handleClose
  } = props;

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick
      >
        <div style={{ width: 394 }}>
          <DialogTitle
            id="alert-dialog-title"
            style={{
              padding: '16px 24px 0'
            }}
          >
            {(() => {
              switch (dialogStatus) {
                case chalkBoardDialogStatus.success:
                  return (
                    <CheckIcon
                      style={{ display: 'inline-block', verticalAlign: 'top' }}
                    />
                  );
                case chalkBoardDialogStatus.warning:
                  return (
                    <img
                      alt="Warning"
                      src={Questionmark}
                      style={{ display: 'inline-block', verticalAlign: 'sub' }}
                    />
                  );
                case chalkBoardDialogStatus.error:
                  return (
                    <img
                      alt="Error"
                      src={X}
                      style={{ display: 'inline-block', verticalAlign: 'sub' }}
                    />
                  );
                default:
                  return <p>Unknown</p>;
              }
            })(dialogStatus)}
            <Typography
              color="#000"
              variant="h5"
              component="div"
              className={classes.title}
              style={{ display: 'inline-block', marginLeft: '10px' }}
            >
              {(() => {
                switch (dialogStatus) {
                  case chalkBoardDialogStatus.success:
                    return 'Success';
                  case chalkBoardDialogStatus.warning:
                    return 'Warning';
                  case chalkBoardDialogStatus.error:
                    return 'Error';
                  default:
                    return 'Undefined';
                }
              })(dialogStatus)}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <p
              style={{
                fontWeight: 'normal',
                fontSize: '14px',
                lineHeight: '18px',
                letterSpacing: '0.002em',
                color: '#7F7F7F',
                padding: '10px 0'
              }}
            >
              {dialogerrmsg}
            </p>
          </DialogContent>
          <DialogActions
            style={{
              padding: '5px 20px 20px'
            }}
          >
            {isCancelDialog && (
              <JBButton
                type="outlined"
                onClick={handleClose}
                style={{
                  height: '48px',
                  color: '#7F7F7F',
                  fontWeight: 'bold'
                }}
              >
                Cancel
              </JBButton>
            )}
            <JBButton type="primary" onClick={handleOk}>
              OK
            </JBButton>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
});

WarningDialogPopup.defaultProps = {
  handleClose: () => {},
  isCancelDialog: false
};

WarningDialogPopup.propTypes = {
  classes: PropType.object.isRequired,
  open: PropType.bool.isRequired,
  dialogStatus: PropType.oneOf([
    chalkBoardDialogStatus.success,
    chalkBoardDialogStatus.error,
    chalkBoardDialogStatus.warning
  ]).isRequired,
  handleClose: PropType.func,
  isCancelDialog: PropType.bool,
  handleOk: PropType.func.isRequired,
  dialogerrmsg: PropType.string.isRequired
};

export default withStyles(styles)(WarningDialogPopup);
