import React from 'react';
import PropType from 'prop-types';
import { Typography, makeStyles, Grid, InputLabel } from '@material-ui/core';
import { deviceDetect } from 'react-device-detect';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { checkValidString, isValidPostal } from 'src/utils/validations';
import { ReactComponent as DownIcon } from '../../../../assets/image/Vanguard/downIcon.svg';
import FormControl from '../../../common/FormControl/formControl';
import CustomAutocomplete from '../../../common/CustomAutocomplete/commonAutocomplete';

const useStyles = makeStyles(() => ({
  mainTitle: {
    fontWeight: '500',
    fontSize: '48px',
    lineHeight: '44px',
    color: '#FFFFFF',
    fontFamily: 'DDC Hardware',
    textAlign: 'center',
    margin: '38px 0 0 0',
    '@media (max-width: 992px)': {
      fontSize: '35px'
    }
  },

  cutom_select_Label: {
    fontFamily: 'DDC Hardware',
    fontStyle: 'normal',
    fontWeight: '500;',
    fontSize: '22px',
    lineHeight: '32px',
    letterSpacing: '0.2px',
    color: '#FFFFFF',
    '&:focus ': {
      outline: '0'
    },
    '& .MuiFormLabel-asterisk': {
      color: 'transparent'
    },
    '& .MuiFormLabel-asterisk:after': {
      content: '""',
      background: '#2CE9DA',
      display: 'inline-block',
      position: 'relative',
      width: '8px',
      height: '8px',
      borderRadius: '50%',
      top: '-14px',
      right: '10px'
    }
  },
  cutom_vanguard_FormControl: {
    '& .fd-input input': {
      background: 'rgba(196, 196, 196, 0.05)',
      color: '#fff',
      borderRadius: '6px',
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '20px',
      height: '45px',
      border: '2px solid #C8C8C8',
      padding: '8px 20px 8px',
      '&::placeholder ': {
        color: '#fff',
        fontWeight: 'bold',
        fontSize: '15px'
      }
    },
    '&  .MuiPaper-root': {
      backgroundColor: '#2d2d2d',
      marginTop: '0',
      marginBottom: '0',
      border: '2px solid #C8C8C8',
      borderTop: 'transparent',
      boxShadow: 'none',
      // borderRadius: '0',
      borderTopLeftRadius: '0',
      borderTopRightRadius: '0'
    },
    '& .MuiAutocomplete-option': {
      color: '#fff',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '20px',
      height: '66px'
    },
    '& .MuiAutocomplete-popper': {
      width: '100% !important',
      marginTop: '-2px'
    },
    '& .SelectDrop[aria-expanded="true"]': {
      borderBottom: '2px solid transparent',
      borderBottomLeftRadius: '0',
      borderBottomRightRadius: '0'
    }
  },
  cutom_vanguard_Drop: {
    '& .MuiAutocomplete-popper ': {
      width: 'calc(100% - 16px) !important'
    }
  },
  cutom_vanguard_Autocomplete: {
    background: 'rgba(196, 196, 196, 0.05)',
    border: '2px solid #C8C8C8',
    color: '#fff',
    borderRadius: '6px',
    '& .cutom_select div': {
      minHeight: '62px'
    },
    '& .cutom_select div input ': {
      color: '#ffffff !important',
      fontWeight: 'bold',
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontSize: '20px'
    },
    '& .MuiAutocomplete-endAdornment': {
      minHeight: ' auto !important',
      top: 'calc(50% - 12px)'
    }
  },
  VanguardCutom_date: {
    '& .MuiInput-root': {
      color: '#fff',
      height: '65px',
      fontWeight: '600',
      fontSize: '20px',
      lineHeight: '18px',
      marginTop: '5px !important',
      border: '2px solid #C8C8C8',
      borderRadius: '6px'
    }
  }
}));

export const LocationData = [
  { id: 0, label: 'India' },
  { id: 1, label: 'USA' }
];

export const Data = [
  { id: 0, label: 'Male' },
  { id: 1, label: 'Female' }
];

const Location = (props) => {
  const classes = useStyles();
  const { Maintitle, data, setData, allCountries, allLocs } = props;
  const userAgent = deviceDetect();
  const [touched, setTouched] = React.useState({
    province: false,
    city: false,
    address: false,
    postal: false
  });
  const handleBlur = (e) => {
    const { name } = e.target;
    setTouched({ ...touched, [name]: true });
  };

  return (
    <div>
      <Typography variant="h3" className={classes.mainTitle}>
        {Maintitle}
      </Typography>
      <Grid
        container
        xs={10}
        justify="center"
        style={{
          margin: '45px auto'
        }}
      >
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={2}
          // style={{ margin: '20px 0 20px' }}
        >
          <Grid
            item
            xs={12}
            className={clsx(
              classes.cutom_vanguard_FormControl,
              classes.cutom_vanguard_Drop
            )}
            style={{
              position: 'relative'
            }}
          >
            <InputLabel
              htmlFor="age-required"
              required
              className={classes.cutom_select_Label}
              style={{ margin: '0px 0 6px' }}
            >
              LOCATION
            </InputLabel>
            <CustomAutocomplete
              // holder="Lead source"
              disablePortal
              value={allLocs}
              Options="gym_name"
              data={data.location || null}
              popupIcon={<DownIcon />}
              Change={(val) => setData({ ...data, location: val })}
              disableClearable
              // className={classes.cutom_vanguard_Autocomplete}
              className={clsx(
                classes.cutom_vanguard_Autocomplete,
                'SelectDrop'
              )}
              // Change={(val) => {
              //   console.log(val);
              //   setData1({ ...data1, lead_source: val });
              // }}
              style={{
                marginBottom: '10px'
              }}
            />
          </Grid>

          <Grid
            item
            xs={6}
            className={clsx(
              classes.cutom_vanguard_FormControl,
              classes.cutom_vanguard_Drop
            )}
            style={{
              position: 'relative'
            }}
          >
            <InputLabel
              htmlFor="age-required"
              required
              className={classes.cutom_select_Label}
              // style={{ margin: '0px 0 5px' }}
            >
              COUNTRY
            </InputLabel>
            <CustomAutocomplete
              // holder="Lead source"
              disablePortal
              value={allCountries}
              Options="name"
              data={data.country || null}
              popupIcon={<DownIcon />}
              Change={(val) => setData({ ...data, country: val })}
              disableClearable
              // className={classes.cutom_vanguard_Autocomplete}
              className={clsx(
                classes.cutom_vanguard_Autocomplete,
                'SelectDrop'
              )}
              // Change={(val) => {
              //   console.log(val);
              //   setData1({ ...data1, lead_source: val });
              // }}
            />
          </Grid>
          <Grid item xs={6} className={classes.cutom_vanguard_FormControl}>
            <InputLabel
              htmlFor="age-required"
              required
              className={classes.cutom_select_Label}
              // style={{ margin: '0px 0 5px' }}
            >
              State
            </InputLabel>
            <FormControl
              control="input"
              value={data.province}
              name="province"
              onBlur={handleBlur}
              error={touched.province && checkValidString(data.province)}
              helperText={
                touched.province && checkValidString(data.province)
                  ? 'please enter a valid state '
                  : null
              }
              onChange={(e) => setData({ ...data, province: e.target.value })}
              maxLength={50}
              style={{
                margin: '5px 0'
              }}
            />
          </Grid>
          <Grid
            item
            xs={6}
            className={classes.cutom_vanguard_FormControl}
            style={{
              margin: '5px 0'
            }}
          >
            <InputLabel
              htmlFor="age-required"
              required
              className={classes.cutom_select_Label}
              // style={{ margin: '0px 0 5px' }}
            >
              CITY
            </InputLabel>
            <FormControl
              control="input"
              value={data.city}
              name="city"
              onBlur={handleBlur}
              error={touched.city && checkValidString(data.city)}
              helperText={
                touched.city && checkValidString(data.city)
                  ? 'please enter valid city'
                  : null
              }
              onChange={(e) => setData({ ...data, city: e.target.value })}
              // maxLength={50}
              style={{
                margin: '5px 0'
              }}
            />
          </Grid>
          <Grid
            item
            xs={6}
            className={classes.cutom_vanguard_FormControl}
            style={{
              margin: '5px 0'
            }}
          >
            <InputLabel
              htmlFor="age-required"
              className={classes.cutom_select_Label}
              required
              // style={{ margin: '0px 0 5px' }}
            >
              POSTAL CODE
            </InputLabel>
            <FormControl
              control="input"
              inputMode="numeric"
              value={data.postal}
              error={touched.postal && !isValidPostal(data.postal)}
              name="postal"
              onBlur={handleBlur}
              helperText={
                touched.postal && !isValidPostal(data.postal)
                  ? 'please enter a valid postal'
                  : null
              }
              onChange={(e) => setData({ ...data, postal: e.target.value })}
              maxLength={50}
              style={{
                margin: '0 0'
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            className={classes.cutom_vanguard_FormControl}
            style={{
              margin: '0px 0 0px'
            }}
          >
            <InputLabel
              htmlFor="age-required"
              required
              className={classes.cutom_select_Label}
              // style={{ margin: '0px 0 5px' }}
            >
              STREET ADDRESS LINE 1
            </InputLabel>
            <FormControl
              control="input"
              // inputMode="numeric"
              inputMode={userAgent.os === 'Android' ? '' : 'numeric'}
              value={data.address}
              name="address"
              onBlur={handleBlur}
              error={touched.address && checkValidString(data.address)}
              helperText={
                touched.address && checkValidString(data.address)
                  ? 'please enter a valid address'
                  : null
              }
              onChange={(e) => setData({ ...data, address: e.target.value })}
              maxLength={50}
              style={{
                margin: '5px 0'
              }}
            />
          </Grid>

          <Grid
            item
            xs={12}
            className={classes.cutom_vanguard_FormControl}
            style={{
              margin: '0px 0 0px'
            }}
          >
            <InputLabel
              htmlFor="age-required"
              className={classes.cutom_select_Label}
              // style={{ margin: '0px 0 5px' }}
            >
              STREET ADDRESS LINE 2
            </InputLabel>
            <FormControl
              control="input"
              // value={description}
              // onChange={(e) =>
              //   setFormState({
              //     ...formState,
              //     description: validateString(e.target.value)
              //   })
              // } // eslint-disable-line
              // label="Description"
              // maxLength={50}
              style={{
                margin: '5px 0'
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

Location.defaultProps = {
  Maintitle: 'PERSONAL INFORMATION',
  allCountries: [],
  allLocs: []
};
Location.propTypes = {
  Maintitle: PropType.string,
  data: PropType.object.isRequired,
  allCountries: PropType.array,
  setData: PropType.func.isRequired,
  allLocs: PropType.array
};
const mapToState = (state) => ({
  allCountries: state.SettingReducer.countries.data,
  allLocs: state.SettingReducer.allGymloc.data
});

export default connect(mapToState)(Location);
