import React, { useState, useContext, useEffect } from 'react';
import PropType from 'prop-types';
import JBButton from 'src/views/common/JBButton/JBButton';
import JBDatePicker from 'src/views/common/FormControl/JBDatePicker';
import { InputLabel, withStyles } from '@material-ui/core';
import { FilterContext } from 'src/views/common/ApplyFilter/applyFilter';
import { useSelector } from 'react-redux';
import { getMinDate } from 'src/utils/someCommon';
import CustomAutoComplete from 'src/views/common/CustomAutocomplete/commonAutocomplete';
import styles from './filters.style';
import {
  getFilter,
  getfilterLabel,
  NextdayFilter,
  tobeFilters,
  checkData
} from './Filter.utils';

const AllInvoicesFilters = ({ classes, getFilData }) => {
  const allPrograms = useSelector((state) => state.allprograms.programs);
  const [open, setOpen] = useContext(FilterContext);
  const [data, setData] = useState(null);
  const [filterDate, setFilterDate] = useState(null);
  const [filter, setFilter] = useState(null);
  const [endDate, setendDate] = useState(() => getMinDate(new Date()));
  useEffect(() => {
    setData(null);
  }, [filter]);
  return (
    <div>
      <CustomAutoComplete
        holder="Select Filter"
        style={{ marginBottom: '15px' }}
        value={tobeFilters}
        Options="filter"
        data={filter}
        Change={(val) => {
          setFilter(val);
        }}
      />
      <div>
        {(() => {
          switch (filter?.id) {
            case 1:
              return (
                <>
                  <InputLabel
                    htmlFor="age-required"
                    className={classes.cutom_select_Label}
                    style={{ margin: '0px 0 5px' }}
                  >
                    Next Day
                  </InputLabel>
                  <CustomAutoComplete
                    holder="This Month"
                    Options="filter"
                    value={NextdayFilter}
                    data={filterDate}
                    Change={(val) => setFilterDate(val)}
                    style={{ marginBottom: '15px' }}
                  />
                  {filterDate?.id === 'custom' && (
                    <>
                      <InputLabel
                        htmlFor="age-required"
                        className={classes.cutom_select_Label}
                        style={{ margin: '5px 0' }}
                      >
                        From
                      </InputLabel>
                      <JBDatePicker
                        label="Start Date"
                        value={data}
                        onChange={(val) => {
                          setData(val);
                          setendDate(getMinDate(val));
                        }}
                      />
                      <InputLabel
                        htmlFor="age-required"
                        className={classes.cutom_select_Label}
                        style={{ padding: '20px 0px 5px' }}
                      >
                        To
                      </InputLabel>
                      <JBDatePicker
                        label="End Date"
                        minDate={
                          data ? getMinDate(data) : getMinDate(new Date())
                        }
                        value={endDate}
                        onChange={(val) => setendDate(val)}
                      />
                    </>
                  )}
                </>
              );
            case 2:
              return (
                <CustomAutoComplete
                  holder="All"
                  value={[...allPrograms, { id: 0, name: 'All' }]}
                  Options="name"
                  data={data}
                  Change={(val) => setData(val)}
                  style={{ marginBottom: '15px' }}
                />
              );
            default:
              return null;
          }
        })()}
      </div>
      <JBButton
        type="primary"
        onClick={() => {
          getFilData(
            getFilter(filter, filterDate, data, endDate),
            getfilterLabel(filter, filterDate, data, endDate)
          );
          setOpen(!open);
        }}
        disabled={checkData(filter, data, filterDate, endDate)}
        style={{
          width: '100%',
          // minHeight: '22px',
          padding: '7px 25px',
          marginTop: '10px'
        }}
      >
        Apply filter
      </JBButton>
    </div>
  );
};
AllInvoicesFilters.propTypes = {
  classes: PropType.object.isRequired,
  getFilData: PropType.func.isRequired
};

export default withStyles(styles)(AllInvoicesFilters);
