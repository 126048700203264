import React from 'react';
import PropType from 'prop-types';
import { connect } from 'react-redux';
import { Grid, InputLabel, withStyles } from '@material-ui/core';
import FormControl from 'src/views/common/FormControl/formControl';
import CustomAutoComplete from '../../../calendar/commonAutocomplete';
import styles from '../PaymentPlan.style';
import { changeName, changeTax, changeLoc } from '../paymentReducer.actions';

function PlanBasicDetails(props) {
  const {
    classes,
    allGym: { data: allGymLoc },
    allSerTaxes: { data: allTaxes },
    reducer: [state, dispatch]
  } = props;
  return (
    <div>
      <h3>Name</h3>
      <p className={classes.ptext}>
        Describe this payment plan. This name will be displayed on members'
        contracts.
      </p>
      <Grid container spacing={0} direction="column">
        <Grid item xs={12}>
          <FormControl
            control="input"
            label="Plan Name"
            placeholder="e.g. 10 Session Pack Paid in Full"
            required
            value={state.name}
            onChange={(e) => dispatch(changeName(e.target.value))}
          />
        </Grid>
        <Grid item xs={12} style={{ marginTop: '20px' }}>
          <h3>Locations of Sale</h3>
          <p className={classes.ptext}>
            Choose the location from which this payment plan may be sold. The
            location selected when selling a membership will drive it's revenue
            reporting.
          </p>
        </Grid>

        <Grid
          container
          spacing={0}
          xs={12}
          disableGutters
          direction="row"
          style={{ marginTop: '20px', marginbottom: '20px' }}
        >
          <Grid container spacing={0} xs={12} disableGutters direction="row">
            <Grid
              item
              xs={6}
              className={classes.time_box}
              style={{ marginTop: '0px', paddingRight: '15px' }}
            >
              <InputLabel
                htmlFor="age-required"
                className={classes.cutom_select_Label}
              >
                Location(s)
              </InputLabel>
              <CustomAutoComplete
                holder="Select Location"
                value={allGymLoc}
                data={state.location}
                Options="gym_name"
                style={{ marginbottom: '0' }}
                Change={(val) => dispatch(changeLoc(val))}
              />
            </Grid>
            <Grid
              item
              xs={6}
              className={classes.time_box}
              style={{ marginTop: '0px' }}
            >
              <InputLabel
                htmlFor="age-required"
                className={classes.cutom_select_Label}
              >
                &nbsp;
              </InputLabel>
              <CustomAutoComplete
                holder="Select Service Tax"
                value={allTaxes}
                data={state.tax}
                Options="name"
                style={{ marginbottom: '0' }}
                Change={(val) => dispatch(changeTax(val))}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

PlanBasicDetails.propTypes = {
  classes: PropType.object.isRequired,
  allGym: PropType.array.isRequired,
  allSerTaxes: PropType.array.isRequired,
  reducer: PropType.array.isRequired
};

const mapToState = (state) => ({
  allGym: state.SettingReducer.allGymloc,
  allSerTaxes: state.SettingReducer.taxRates
});

export default connect(mapToState)(withStyles(styles)(PlanBasicDetails));
