import React, { useContext } from 'react';
import PropType from 'prop-types';
import { useDispatch } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import {
  withStyles,
  DialogTitle,
  Typography,
  DialogContent,
  DialogActions,
  Dialog,
  Button
} from '@material-ui/core';
import { Chalk } from 'src/Context/chalkboardContext';
import { openNoti } from 'src/Redux/global/global.actions';
import { getErrorMsg } from 'src/utils/ErrorHandling';
import {
  removedloginedUser,
  removedResevedMembers
} from 'src/Context/ChalkBoardReducer/chalkBoard.actions';
import { removeUserFromClass } from 'src/services/chalkBoard.services';
import styles from './Chalkboard.style';
import X from '../../assets/image/x.png';

function RemoveMemberDialog(props) {
  const { classes } = props;
  const [open, setOpen] = React.useState(false);
  const Reduxdispatch = useDispatch();
  const [__, dispatch] = useContext(Chalk); // eslint-disable-line

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleRemove = () => {
    const { id, user_id, isReserved } = props;
    removeUserFromClass(id)
      .then(() => {
        if (isReserved) {
          dispatch(removedResevedMembers(id));
        } else {
          dispatch(removedloginedUser(user_id));
        }
        Reduxdispatch(openNoti('Successfully removed the user'));
        setOpen(() => false);
      })
      .catch((err) => Reduxdispatch(openNoti(getErrorMsg(err), 'error')));
  };

  return (
    <div>
      <Button color="primary" onClick={handleClickOpen}>
        <CloseIcon />
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div style={{ width: 394 }}>
          <DialogTitle
            id="alert-dialog-title"
            style={{
              padding: '16px 24px 0'
            }}
          >
            <img
              alt="Plusicon"
              src={X}
              style={{ display: 'inline-block', verticalAlign: 'sub' }}
            />
            <Typography
              color="#000"
              variant="h5"
              component="div"
              className={classes.title}
              style={{ display: 'inline-block', marginLeft: '10px' }}
            >
              Remove member
            </Typography>
          </DialogTitle>
          <DialogContent>
            <p
              style={{
                fontWeight: 'normal',
                fontSize: '14px',
                lineHeight: '18px',
                letterSpacing: '0.002em',
                color: '#7F7F7F',
                padding: '10px 0'
              }}
            >
              Are you sure you want to remove this member?
            </p>
          </DialogContent>
          <DialogActions
            style={{
              padding: '5px 20px 20px'
            }}
          >
            <Button
              variant="outlined"
              onClick={handleClose}
              style={{
                height: '48px',
                color: '#7F7F7F',
                fontWeight: 'bold'
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleRemove}
              autoFocus
              className={classes.Deletebtn}
            >
              REMOVE
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}

RemoveMemberDialog.defaultProps = {
  isReserved: false
};

RemoveMemberDialog.propTypes = {
  classes: PropType.object.isRequired,
  user_id: PropType.number.isRequired,
  id: PropType.number.isRequired,
  isReserved: PropType.bool
};
export default withStyles(styles)(RemoveMemberDialog);
