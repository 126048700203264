export const initialValue = {
  data: {
    membership_enforcement: {
      visibility: false,
      limited_plan_membership: null,
      session_membership: null,
      show_classes_in_athlete_app: null
    },
    contract_enforcement: false,
    waiver_enforcement: false
  }
};

export const AttendanceTypes = {
  getData: 'GET_UPDATED_DATA',
  chngeplanmem: 'CHANGE_LIMITED_PLAN_MEMBERSHIP',
  chngesessmem: 'CHANGE_CHANGE_SESSION_MEMBERSHIP',
  showclasses: 'SHOW_CLASSES_IN_ATHLETE_APP',
  chngememvis: 'CHANGE_MEMBERSHIP_VISIBILITY',
  chngecontrt: 'CHANGE_CONTRACT_ENFORCEMENT',
  chngewaiver: 'CHANGE_WAIVER_ENFORCEMENT'
};

export const attendanceReducer = (state = initialValue, action) => {
  switch (action.type) {
    case AttendanceTypes.getData:
      return {
        ...state,
        data: action.payload
      };
    case AttendanceTypes.chngememvis:
      return {
        ...state,
        data: {
          ...state.data,
          membership_enforcement: {
            ...state.data.membership_enforcement,
            visibility: !state.data.membership_enforcement.visibility
          }
        }
      };
    case AttendanceTypes.chngeplanmem:
      return {
        ...state,
        data: {
          ...state.data,
          membership_enforcement: {
            ...state.data.membership_enforcement,
            limited_plan_membership: action.payload
          }
        }
      };
    case AttendanceTypes.chngesessmem:
      return {
        ...state,
        data: {
          ...state.data,
          membership_enforcement: {
            ...state.data.membership_enforcement,
            session_membership: action.payload
          }
        }
      };
    case AttendanceTypes.showclasses:
      return {
        ...state,
        data: {
          ...state.data,
          membership_enforcement: {
            ...state.data.membership_enforcement,
            show_classes_in_athlete_app: action.payload
          }
        }
      };
    case AttendanceTypes.chngecontrt:
      return {
        ...state,
        data: {
          ...state.data,
          contract_enforcement: !state.data.contract_enforcement
        }
      };
    case AttendanceTypes.chngewaiver:
      return {
        ...state,
        data: {
          ...state.data,
          waiver_enforcement: !state.data.waiver_enforcement
        }
      };
    default:
      return state;
  }
};
