import React from 'react';
import PropType from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import X from 'src/assets/image/x.png';
import JBButton from 'src/views/common/JBButton/JBButton';
import styles from './NewTemplate.style';

function DeleteDialog({
  classes,
  handleDelete,
  checkPaid,
  checkStatus,
  checkMembershipTitle
}) {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      {/* <Button className={classes.Deletebutton} onClick={handleClickOpen}>
        DELETE
      </Button> */}
      <JBButton
        type="delete"
        style={{ marginLeft: '15px' }}
        onClick={handleClickOpen}
      >
        {checkStatus === 2 ? 'DELETE' : !checkPaid ? 'DELETE' : 'DEACTIVATE'}
      </JBButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick
      >
        <div style={{ width: 394 }}>
          <DialogTitle id="alert-dialog-title" className={classes.Dialogtitle}>
            <img
              alt="Plusicon"
              src={X}
              style={{ display: 'inline-block', verticalAlign: 'sub' }}
            />
            <Typography
              color="#000"
              variant="h5"
              component="div"
              className={classes.title}
              style={{ display: 'inline-block', marginLeft: '10px' }}
            >
              {checkStatus === 2
                ? 'Delete membership'
                : !checkPaid // eslint-disable-next-line indent
                ? 'Delete membership' // eslint-disable-next-line indent
                : 'Deactivate membership'}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <p
              style={{
                fontWeight: 'normal',
                fontSize: '14px',
                lineHeight: '18px',
                letterSpacing: '0.002em',
                color: '#7F7F7F',
                padding: '10px 0'
              }}
            >
              {/* Are you sure you want to permanently{' '} */}
              {checkStatus === 2
                ? `Are you sure you want to delete the "${checkMembershipTitle}" Membership? Deleting this Membership will also delete any open invoices related to this plan. THIS CANNOT BE UNDONE.`
                : !checkPaid // eslint-disable-next-line indent
                ? `Are you sure you want to delete the "${checkMembershipTitle}" Membership? Deleting this Membership will also delete any open invoices related to this plan. THIS CANNOT BE UNDONE. ` // eslint-disable-next-line indent
                : `Are you sure you want to deactivate the "${checkMembershipTitle}" Membership? `}{' '}
              {/* this membership template? */}
            </p>
          </DialogContent>
          <DialogActions className={classes.DialogActionsBk}>
            <Button
              variant="outlined"
              onClick={handleClose}
              style={{
                height: '48px',
                color: '#7F7F7F',
                fontWeight: 'bold'
              }}
            >
              Cancel
            </Button>
            <JBButton
              type="delete"
              className={classes.Deletebtn}
              onClick={() => {
                handleDelete();
                handleClose();
              }}
              autoFocus
            >
              {checkStatus === 2
                ? 'DELETE'
                : !checkPaid // eslint-disable-next-line indent
                ? 'DELETE' // eslint-disable-next-line indent
                : 'DEACTIVATE'}
            </JBButton>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}

DeleteDialog.propTypes = {
  classes: PropType.object.isRequired,
  handleDelete: PropType.func.isRequired,
  checkPaid: PropType.bool.isRequired,
  checkStatus: PropType.number.isRequired,
  checkMembershipTitle: PropType.string.isRequired
};
export default withStyles(styles)(DeleteDialog);
