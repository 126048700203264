import React, { useEffect } from 'react';
import PropType from 'prop-types';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  TableContainer,
  FormControlLabel,
  Radio,
  RadioGroup
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import styles from './table.style';

const RadioButtonTable = ({ classes, values, tableHead, getData, initial }) => {
  const [value, setValue] = React.useState(initial);
  const handleRadioChange = (event) => {
    setValue(event.target.value);
  };
  useEffect(() => {
    getData(value);
  }, [value]); // eslint-disable-line
  return (
    <Paper elevation={0}>
      <TableContainer
        style={{ border: '1px solid #A8A8A8', borderRadius: '5px' }}
        className={classes.Tablemian}
      >
        <Table stickyHeader>
          <TableHead className={classes.table_th}>
            <TableRow>
              <TableCell
                style={{ width: '55px' }}
                className={classes.TableCell}
              >
                &nbsp;
              </TableCell>
              {tableHead.map((headCell) => (
                <TableCell
                  style={{}}
                  className={classes.TableCell}
                  key={headCell.id}
                >
                  {headCell.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className={classes.Table_Body}>
            <RadioGroup
              aria-label="quiz"
              name="quiz"
              value={value}
              onChange={handleRadioChange}
              style={{
                display: 'contents'
              }}
            >
              {values.map((row) => (
                <TableRow key={row.id}>
                  <TableCell scope="row" className={classes.Table_td}>
                    <FormControlLabel
                      value={String(row.id)}
                      control={<Radio color="primary" />}
                      style={{ margin: '0 0' }}
                    />
                  </TableCell>

                  {tableHead.map((cells) => {
                    return (
                      <TableCell
                        scope="row"
                        className={classes.Table_td}
                        key={cells.id}
                      >
                        {row[cells.cellName]}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </RadioGroup>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

RadioButtonTable.propTypes = {
  classes: PropType.object.isRequired,
  values: PropType.array.isRequired,
  tableHead: PropType.array.isRequired,
  getData: PropType.func.isRequired,
  initial: PropType.string.isRequired
};
export default withStyles(styles)(RadioButtonTable);
