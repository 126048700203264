import React, { useContext } from 'react';
import { connect } from 'react-redux';
import { Grid, InputLabel, withStyles } from '@material-ui/core';
import PropType from 'prop-types';
import '../CutomSelect.css';
import FormControl from 'src/views/common/FormControl/formControl';

import {
  templatechangename,
  changerevencat
} from 'src/Context/MembershipTemplate/membershiptemplate.actions';

import { MembershipTemplate as MembershipContext } from '../../../../Context/MembershipTemplate/MembershipTemplateContext';
import CustomAutoComplete from '../../../calendar/commonAutocomplete';
import styles from '../MembershipTemplate.style';
// import { AllSessionlmt, SessionDay } from '../Membership.data';
import { openNoti } from '../../../../Redux/global/global.actions';
// import { validateInt } from '../../../../utils/validations';

const DropinDetailStep = (props) => {
  const {
    classes,
    allrevenCat: { data: revenCats }
  } = props;

  const [state, dispatch] = useContext(MembershipContext);

  const activeRevenCat = revenCats?.filter((cat) => cat.status === 1);
  return (
    <div>
      <h3>Name this Drop In, set the revenue category.</h3>
      <Grid container spacing={0} direction="column">
        <Grid item xs={12}>
          <FormControl
            control="input"
            label="Drop In name"
            placeholder="e.g. 1 Day Drop In Pack"
            required
            value={state?.name}
            // onChange={(e) => setdata({ ...data, name: e.target.value })}
            onChange={(e) => dispatch(templatechangename(e.target.value))}
          />
        </Grid>
        <Grid container spacing={0} xs={12} disableGutters direction="row">
          <Grid container spacing={0} xs={12} disableGutters direction="row">
            <Grid
              item
              xs={12}
              className={classes.time_box}
              style={{ marginTop: '5px' }}
            >
              <InputLabel
                htmlFor="age-required"
                className={classes.cutom_select_Label}
                required
              >
                Revenue category
              </InputLabel>
              <CustomAutoComplete
                holder="Select Revenue Category"
                value={activeRevenCat || []}
                Options="label"
                data={state?.reveCat}
                style={{ marginbottom: '0' }}
                // Change={(val) => setdata({ ...data, reveCat: val })}
                Change={(val) => dispatch(changerevencat(val))}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

DropinDetailStep.propTypes = {
  classes: PropType.object.isRequired,
  allrevenCat: PropType.object.isRequired
};

const mapToState = (state) => ({
  allrevenCat: state.SettingReducer?.revenueCtgry
});

const mapToDispatch = (dispatch) => ({
  OpenNoti: (msg, type) => dispatch(openNoti(msg, type))
});

export default connect(
  mapToState,
  mapToDispatch
)(withStyles(styles)(DropinDetailStep));
