/* eslint-disable operator-linebreak */
export const validateMeta = (data) => {
  const result = [];
  for (let i = 0; i < data.length; i += 1) {
    let b = {
      name: data[i].name,
      auto_renew: data[i].auto_renew ? 1 : 0,
      initial_commitment_pricing: {
        ...data[i].initial_commitment_pricing
      }
    };
    if (data[i].initial_commitment) {
      b = {
        ...b,
        initial_commitment: {
          initial: data[i].initial_commitment.initial,
          billed_every: data[i].initial_commitment.billed_every.id
        }
      };
    }
    if (data[i].id) {
      b = {
        ...b,
        id: data[i].id
      };
    }
    if (data[i].auto_renew) {
      b = {
        ...b,
        auto_renew_meta: {
          ...data[i].auto_renew_meta,
          billed_every: data[i].auto_renew_meta.billed_every.id
        },
        auto_renewal_pricing: {
          ...data[i].auto_renewal_pricing
        }
      };
    }
    if (data[i].location) {
      b = {
        ...b,
        location_id: data[i].location.id
      };
    }
    if (data[i].tax) {
      b = {
        ...b,
        tax_id: data[i].tax.id
      };
    }
    result.push(b);
  }
  return result;
};

// eslint-disable-next-line
export const getMemData = (state) => {
  let value = {
    membership_type: 'plan',
    name: state.name,
    reve_cat_id: state.reveCat.id,
    attendance_limit: state.attLmt.id,
    program_ids: state.program_ids.join(','),
    meta: JSON.stringify(validateMeta(state.payment_plans)),
    contract_id: state.contract_id
  };
  if (state.desc && state.desc.trim().length > 0) {
    value = {
      ...value,
      description: state.desc
    };
  }
  if (state.attLmt.id === 1) {
    value = {
      ...value,
      attendance_limited_meta: JSON.stringify({
        ...state.attendance_limited_meta,
        type: state.attendance_limited_meta.type.id
      })
    };
  }
  return value;
};

export const includeTaxinitials = (initial, arr) => {
  const defaultTax = arr.filter((list) => list.id === 1)[0] || null;
  const b = {
    ...initial,
    tax: defaultTax
  };
  return b;
};

export const validSteps = (state, activeStep) => {
  const arr = new Array(4).map(() => Boolean(false));
  switch (activeStep) {
    case 0: {
      if (!state.name || !state.attLmt || !state.reveCat) {
        arr[0] = true;
        return arr;
      }
      if (
        state.attLmt?.id === 1 &&
        (!state.attendance_limited_meta.uses ||
          !state.attendance_limited_meta.type)
      ) {
        arr[0] = true;
        return arr;
      }
      return arr;
    }

    case 1: {
      if (state.program_ids?.length === 0) {
        arr[1] = true;
        return arr;
      }
      return arr;
    }
    case 2: {
      if (state.payment_plans?.length === 0) {
        arr[2] = true;
        return arr;
      }
      return arr;
    }

    case 3: {
      if (!state.contract_id) {
        arr[3] = true;
        return arr;
      }
      return arr;
    }

    default:
      return arr;
  }
};

export const SessionvalidSteps = (state, activeStep) => {
  const arr = new Array(4).map(() => Boolean(false));
  switch (activeStep) {
    case 0: {
      if (!state.name || !state.attLmt || !state.reveCat) {
        arr[0] = true;
        return arr;
      }
      if (
        state.attLmt?.id === 1 &&
        (!state.attendance_limited_meta.uses ||
          !state.attendance_limited_meta.type)
      ) {
        arr[0] = true;
        return arr;
      }
      return arr;
    }

    case 1: {
      if (state.program_ids?.length === 0) {
        arr[1] = true;
        return arr;
      }
      return arr;
    }

    case 2: {
      if (state.payment_plans?.length === 0) {
        arr[2] = true;
        return arr;
      }
      return arr;
    }

    case 3: {
      if (!state.contract_id) {
        arr[3] = true;
        return arr;
      }
      return arr;
    }

    default:
      return arr;
  }
};

export const dropInValidSteps = (state, activeStep, sessionState) => {
  const {
    initial_commitment_pricing: { full_membership_fees }
  } = sessionState;
  const arr = new Array(4).map(() => Boolean(false));
  switch (activeStep) {
    case 0: {
      if (!state.name || !state.reveCat) {
        arr[0] = true;
        return arr;
      }

      return arr;
    }

    // case 1: {
    //   if (state.program_ids?.length === 0) {
    //     arr[1] = true;
    //     return arr;
    //   }
    //   return arr;
    // }

    case 1: {
      if (!sessionState.tax || !full_membership_fees) {
        arr[1] = true;
        return arr;
      }
      return arr;
    }

    default:
      return arr;
  }
};
