export default {
  getUnpaid: 'GET_UNPAID_INVOICES',
  getpaid: 'GET_PAID_INVOICES',
  getoverdue: 'GET_OVERDUE_INVOICES',
  getallIn: 'GET_ALL_INVOICES',
  getprocessingIn: 'GET_PROCESSING_INVOICES',
  getfailedIn: 'GET_FAILED_INVOICES',
  setunpaidFil: 'SET_UNPAID_FILTERS',
  setpaidFil: 'SET_PAID_FILTERS',
  setDueFil: 'SET_OVERDUE_FILTERS',
  setAllFil: 'SET_ALL_FILTERS',
  isFetching: 'INVOICES_FETCHING_START',
  fetchSucess: 'INVOICES_FETCHED_SUCCESSFUL',
  Errorfetch: 'ERROR_OCCURED_WHEN_FETCHING',
  setAreAllSelected: 'TOGGLE_SELECT_ALL',
  setProcessFil: 'SET_PROCESS_FILTERS',
  setFailedFil: 'SET_FAILED_FILTERS'
};
