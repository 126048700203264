export const ACTION_TYPES = {
  isFetching: 'INVOICES_FETCHING',
  fetchSucess: 'INVOICES_FETCHING_COMPLETED',
  Errorfetch: 'INVOICES_ERROR_IN_FETCHING',
  setAreAllSelected: 'TOGGLE_SELECT_ALL'
};

export const MASTER_INVOICE = 'MASTERHQ_INVOICES';
export const MASTER_INVOICE_FIL = 'MASTERHQ_INVOICES_FILTER';
export const INVOICE_SETTINGS = 'INVOICE_SETTINGS';
