import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getAllGymSet } from 'src/Redux/SettingReducer/SettingReducer.actions';
import { GymdateFormat, GymTimeFormat } from 'src/constant/GymDateTime';

function useGymSettingReducer() {
  const gymSetting = useSelector((state) => state.SettingReducer.date);
  const dispatch = useDispatch();
  useEffect(() => {
    if (gymSetting.error) {
      dispatch(getAllGymSet());
    }
  }, []); // eslint-disable-line
  // eslint-disable-next-line operator-linebreak
  const DateFormat =
    GymdateFormat.filter(
      (l) => l.format === gymSetting?.data?.date_format
    )[0] || null;
  return {
    dateformat: DateFormat?.value || 'DD/MM/YYYY',
    ampm: gymSetting?.data?.time_format === GymTimeFormat.twelve,
    pickerDate: DateFormat?.picker || 'dd/MM/yyyy',
    timeformat: gymSetting?.data?.time_format
  };
}

export default useGymSettingReducer;
