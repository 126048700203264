/* eslint-disable operator-linebreak */
import {
  validEmailRegEx,
  validPhoneRegEx,
  noSpecialCharsRegEx,
  // UrlRegex,
  postalRegEx
  // isValidPostal
} from 'src/utils/validations';

export const isValidAllyStep1 = (data) => {
  let websitetest = false;

  if (data.website.trim().length > 0) {
    websitetest = true; // UrlRegex.test(data.website);
  }

  if (data.gymname && !/([A-Z][a-z])/i.test(data.gymname)) {
    throw new Error('Gym name is not valid');
  } else if (!data.firstname.trim().length) {
    throw new Error('First name is not valid');
  } else if (!data.lastname.trim().length) {
    throw new Error('Last Name is not valid');
  } else if (data.email && !validEmailRegEx.test(data.email)) {
    throw new Error('Email is not valid');
  } else if (
    data.contactnum?.trim().length > 0 &&
    data.contactnum?.length < 10
  ) {
    throw new Error('Contact number length is less than 10');
  } else if (
    data.contactnum?.trim().length > 0 &&
    data.contactnum?.length > 15
  ) {
    throw new Error('Contact number length is greater than 15');
  } else if (
    data.contactnum?.trim().length > 0 &&
    !validPhoneRegEx.test(data.contactnum)
  ) {
    throw new Error('Contact number is not valid');
  } else if (data.website.trim().length > 0 && !websitetest) {
    throw new Error('Website url is not valid');
  }
};

export const isValidAllyStep2 = (data) => {
  if (!data.country) {
    throw new Error('Please Enter your Country');
  }
  if (
    data.province?.trim().length > 0 &&
    !/([A-Z][a-z])/i.test(data.province)
  ) {
    throw new Error('Province is not valid');
  }
  if (data.city?.trim().length > 0 && !/([A-Z][a-z])/i.test(data.city)) {
    throw new Error('City is not valid');
  }
  if (data.postal?.trim().length > 0 && !postalRegEx.test(data.postal)) {
    throw new Error('Postal Code is not valid');
  }
  if (
    data.address1?.trim().length > 0 &&
    !noSpecialCharsRegEx.test(data.postal)
  ) {
    throw new Error('Address is not valid');
  }
  if (!data.currency) {
    throw new Error('Please Select Currency');
  }
};

export const Step2Errors = () => ({
  province: null,
  city: null,
  postal: null
});

export const Step1Errors = () => ({
  gymname: null,
  firstname: null,
  lastname: null,
  email: null,
  contactnum: null
});

export const isvalidStep1 = (errors) => {
  const errorObj = { ...Step1Errors() };
  const errkeys = Object.keys(errorObj);
  for (let i = 0; i < errkeys.length; i += 1) {
    errorObj[errkeys[i]] = errors[errkeys[i]];
    if (errors[errkeys[i]]) {
      return true;
    }
  }
  return false;
};

export const isvalidStep2 = (errors) => {
  const errorObj = { ...Step2Errors() };
  const errkeys = Object.keys(errorObj);
  for (let i = 0; i < errkeys.length; i += 1) {
    errorObj[errkeys[i]] = errors[errkeys[i]];
    if (errors[errkeys[i]]) {
      return true;
    }
  }
  return false;
};

export const getErrorFieldName = (name) => {
  switch (name) {
    case 'gymname':
      return 'Gym name';

    case 'firstname':
      return 'First name';

    case 'lastname':
      return 'Last name';

    case 'province':
      return 'Province';

    case 'city':
      return 'City';

    case 'addrress1':
      return 'Address';

    default:
      return '';
  }
};
