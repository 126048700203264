const types = {
  fetchprogramstart: 'FETCHPROGRAMSTART',
  fecthprogramsuccess: 'FETCHPROGRAMSUCESS',
  fetchprogramfailure: 'FETCHPROGRAMFAILURE',
  getListPrograms: 'GETALLLISTPROGRAMS',
  getActiveProgram: 'GET_ACTIVE_PROGRAM',
  getInActiveProgram: 'GET_INACTIVE_PROGRAM',
  activeFilter: 'ACTIVE_PROGARM_FILTER',
  inactiveFilter: 'INACTIVE_PROGRAM_FILTER'
};
export default types;
