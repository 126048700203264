/* eslint-disable indent */
import React, { useEffect, useState } from 'react';
import PropType from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import moment from 'moment';
import {
  Grid,
  Card,
  Typography,
  Box,
  Breadcrumbs,
  withStyles,
  TableRow,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  TableHead,
  Checkbox,
  FormControlLabel,
  Avatar
} from '@material-ui/core';
import FormControl from 'src/views/common/FormControl/formControl';
import { openNoti } from 'src/Redux/global/global.actions';
import queryString from 'query-string';
import { ROUTES } from 'src/constant/Constants';
import JBButton from '../common/JBButton/JBButton';
import ImageNotFound from '../../assets/image/image-not-found.jpg';
// import { getBilled } from './SignContract/SignContract.utils';
import DownloadIcon from '../../assets/image/download.png';
import { ReactComponent as Emailicon } from '../../assets/image/email.svg';
import styles from './Documents.style';
import {
  ContractEmail,
  exportMembershipContractPDF,
  getMemContract
} from '../../services/Contracts.services';
import LoadingView from '../common/loading';
import NotFoundView from '../errors/NotFoundView';
import useGymSettingReducer from '../common/CustomHooks/useGymSettingRefresh';

const MembershipContract = ({ classes, location, openNotify }) => {
  const [data, setData] = useState(null);
  const [errorFetch, seterrfetch] = useState(false);
  const { contractId } = queryString.parse(location.search);
  const { dateformat } = useGymSettingReducer();

  useEffect(() => {
    getMemContract(contractId)
      .then((res) => setData(res))
      .catch(() => seterrfetch(true));
  }, [contractId]);

  if (errorFetch || !contractId) return <NotFoundView />;
  const handleEmailSend = (dataval) => {
    ContractEmail(dataval)
      .then(() => {
        openNotify('Email send successfully');
      })
      .catch((err) => console.log(err));
  };
  return (
    <div className={classes.root}>
      <div className="title-bk">
        <Breadcrumbs aria-label="breadcrumb">
          <div color="inherit">documents</div>
          <div color="inherit">contracts</div>
          <Link color="inherit" to={ROUTES.DOCUMENTS_CONTRACTS_SIGNED}>
            Signed
          </Link>
          <Typography variant="h3" color="Primary">
            <span>
              {data
                ? data.user_details.first_name
                  + ' '
                  + data.user_details.last_name
                : 'Contract Name'}
            </span>
          </Typography>
        </Breadcrumbs>
      </div>
      {data ? (
        <div>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={0}
            style={{ margin: '20px 0 20px' }}
          >
            <Grid item xs={6} lg={8}>
              <h1 style={{ margin: '0 0', display: 'flex' }}>
                {/* <img
                  src={
                    data.user_details.profile_pic === null
                      ? UserIcon
                      : data.user_details.profile_pic
                  }
                  className={classes.useravatar}
                  alt="UserIcon"
                /> */}
                {data.user_details.profile_pic ? (
                  <img
                    src={data.user_details.profile_pic}
                    alt="UserIcon"
                    className={classes.useravatar}
                  />
                ) : (
                  <Avatar
                    className={classes.Avataruname}
                    style={{
                      fontSize: '1.1rem',
                      marginRight: '15px'
                    }}
                  >
                    {data.user_details.first_name[0]
                      + ' '
                      + data.user_details.last_name[0]}
                  </Avatar>
                )}
                {data.user_details.first_name
                  + ' '
                  + data.user_details.last_name}
                : Membership Contract
              </h1>
            </Grid>
            <Grid
              item
              xs={6}
              lg={4}
              className={classes.btnBlock}
              style={{ justifyContent: 'flex-end', display: 'flex' }}
            >
              <JBButton
                type="outlined"
                onClick={() => {
                  exportMembershipContractPDF(contractId);
                }}
              >
                download
                <img
                  src={DownloadIcon}
                  alt="img"
                  style={{ verticalAlign: 'sub', marginLeft: '10px' }}
                />
              </JBButton>
              <JBButton
                type="outlined"
                style={{ marginLeft: '15px' }}
                onClick={() => {
                  handleEmailSend(data.id);
                }}
              >
                send
                <Emailicon
                  style={{ verticalAlign: 'sub', marginLeft: '10px' }}
                />
              </JBButton>
            </Grid>
          </Grid>
          <Card className={classes.mainCard}>
            <Grid container direction="row">
              <Grid item xs={3}>
                &nbsp;
              </Grid>
              <Grid item xs={9}>
                <p
                  style={{
                    fontSize: '14px',
                    lineHeight: '18px',
                    color: '#7F7F7F',
                    fontWeight: 'normal',
                    marginBottom: '20px'
                  }}
                >
                  {data.payment_plan_meta.name_of_membership} - READ ONLY
                </p>
              </Grid>
            </Grid>
            <Grid container direction="row" style={{ marginBottom: '40px' }}>
              <Grid item xs={3}>
                <h3 className={classes.headingTitle}>
                  Personal Information <br />
                  &amp; Location
                </h3>
              </Grid>
              <Grid item xs={9}>
                <TableContainer
                  className={clsx(classes.PlanTerms, classes.Pinfo)}
                >
                  <Table className={classes.table}>
                    <TableHead className={classes.tbhead}>
                      <TableRow>
                        <TableCell>Full Name</TableCell>
                        <TableCell>Email Address</TableCell>
                        <TableCell>Phone</TableCell>
                        <TableCell>Gender</TableCell>
                        <TableCell>DOB</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell className={classes.TableCell}>
                          {data.user_details.first_name
                            + ' '
                            + data.user_details.last_name}
                        </TableCell>
                        <TableCell className={classes.TableCell}>
                          {data.user_details.email}
                        </TableCell>
                        <TableCell className={classes.TableCell}>
                          {data.user_details.mobile_no || null}
                        </TableCell>
                        <TableCell
                          className={classes.TableCell}
                          style={{
                            textTransform: 'capitalize'
                          }}
                        >
                          {data.user_details.gender}
                        </TableCell>
                        <TableCell className={classes.TableCell}>
                          {moment(data.user_details.dob).format(
                            `${dateformat}`
                          )}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <TableContainer
                  className={clsx(classes.PlanTerms, classes.Pinfo)}
                >
                  <Table className={classes.table}>
                    <TableHead className={classes.tbhead}>
                      <TableRow>
                        <TableCell>Street Address</TableCell>
                        <TableCell>City</TableCell>
                        <TableCell>Province</TableCell>
                        <TableCell>Zip</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell className={classes.TableCell}>
                          {data.user_details.address}
                          {!data.user_details.address2
                            ? '  '
                            : ',' + data.user_details.address2}
                        </TableCell>
                        <TableCell className={classes.TableCell}>
                          {data.user_details.city}
                        </TableCell>
                        <TableCell className={classes.TableCell}>
                          {data.user_details.province}
                        </TableCell>
                        <TableCell className={classes.TableCell}>
                          {data.user_details.postal_code}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
            <Grid container direction="row" style={{ marginBottom: '50px' }}>
              <Grid item xs={3}>
                <h3 className={classes.headingTitle}>Payment Plan Terms</h3>
              </Grid>
              <Grid item xs={9}>
                <Grid container spacing={0} style={{ alignItems: 'center' }}>
                  <TableContainer className={classes.PlanTerms}>
                    <Table className={classes.table}>
                      <TableBody>
                        <TableRow>
                          <TableCell className={classes.TableCell}>
                            Name of membership
                          </TableCell>
                          <TableCell className={classes.TableCell}>
                            {data.payment_plan_meta.name_of_membership}
                          </TableCell>
                        </TableRow>
                        {data.payment_plan_meta.number_of_session ? (
                          <TableRow>
                            <TableCell className={classes.TableCell}>
                              Number of Sessions
                            </TableCell>
                            <TableCell className={classes.TableCell}>
                              {data.payment_plan_meta.number_of_session}
                            </TableCell>
                          </TableRow>
                        ) : null}
                        {!data.payment_plan_meta.number_of_session ? (
                          <TableRow>
                            <TableCell className={classes.TableCell}>
                              Attendance limit
                            </TableCell>
                            <TableCell className={classes.TableCell}>
                              {data.payment_plan_meta.attedence_limit}
                            </TableCell>
                          </TableRow>
                        ) : null}
                        <TableRow>
                          <TableCell className={classes.TableCell}>
                            Expiration
                          </TableCell>
                          <TableCell className={classes.TableCell}>
                            {data.payment_plan_meta.expiration}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className={classes.TableCell}>
                            Programs
                          </TableCell>
                          <TableCell className={classes.TableCell}>
                            {data.payment_plan_meta.programe.join(',')}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className={classes.TableCell}>
                            Discount
                          </TableCell>
                          <TableCell className={classes.TableCell}>
                            {data.payment_plan_meta.discount}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className={classes.TableCell}>
                            Auto-Renew
                          </TableCell>
                          <TableCell className={classes.TableCell}>
                            {data.payment_plan_meta.auto_renew || 'No'}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>

                  <TableContainer className={classes.PlanTerms}>
                    <Table className={classes.table}>
                      <TableBody>
                        <TableRow>
                          <TableCell>Initial commitment</TableCell>
                          <TableCell>
                            {data.payment_plan_meta.initial_commitment}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Payment option</TableCell>
                          <TableCell>
                            {data.payment_plan_meta.payment_option}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Start date</TableCell>
                          <TableCell>
                            {data.payment_plan_meta.start_date}
                            {/* {moment(data.payment_plan_meta.start_date).format(
                              `${dateformat}`
                            )} */}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Expiration date</TableCell>
                          <TableCell>
                            {/* {moment(
                            data.payment_plan_meta.expiration_date
                          ).format('DD/MM/YYYY')} */}
                            {data.payment_plan_meta.expiration_date === '-'
                              ? '-'
                              : data.payment_plan_meta.expiration_date

                            // moment(
                            //     data.payment_plan_meta.expiration_date ||
                            //       new Date() // eslint-disable-next-line indent
                            //   ).format(`${dateformat}`)
                            }
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>

                  <TableContainer className={classes.PlanTerms}>
                    <Table className={classes.table}>
                      <TableBody>
                        <TableRow>
                          <TableCell>Setup cost (Including Tax)</TableCell>
                          <TableCell>
                            {data.payment_plan_meta.setup_cost || 'No'}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Setup tax</TableCell>
                          <TableCell>
                            {data.payment_plan_meta.setup_tax || 'No'}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TableContainer className={classes.PlanTerms}>
                    <Table className={classes.table}>
                      <TableBody>
                        <TableRow>
                          <TableCell>Commitment cost (Including Tax)</TableCell>
                          <TableCell>
                            {data.payment_plan_meta.commitment_cost}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Commitment tax</TableCell>
                          <TableCell>
                            {data.payment_plan_meta.commitment_tax}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            Commitment total (Including Tax)
                          </TableCell>
                          <TableCell>
                            {data.payment_plan_meta.commitment_total}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Billed on</TableCell>
                          <TableCell>
                            {/* {data.payment_plan_meta.billed_on} */}
                            {/* {moment(data.payment_plan_meta.billed_on).format(
                              'DD/MM/YYYY'
                            )} */}
                            {/* {getBilled(data.payment_plan_meta.billed_on)} */}
                            {data.payment_plan_meta.billed_on}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {data.payment_plan_meta.auto_renew === 'No' ? null : (
                    <>
                      {!data.payment_plan_meta.number_of_session ? (
                        <TableContainer className={classes.PlanTerms}>
                          <Table className={classes.table}>
                            <TableBody>
                              <TableRow>
                                <TableCell>Renewal commitment</TableCell>
                                <TableCell>
                                  {data.payment_plan_meta.renewal_commitment
                                    || 'No'}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>Payment option</TableCell>
                                <TableCell>
                                  {data.payment_plan_meta
                                    .renewal_payment_option || 'No'}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>
                                  Renewal cost (Including Tax)
                                </TableCell>
                                <TableCell>
                                  {data.payment_plan_meta.renewal_cost || 'No'}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>Renewal tax</TableCell>
                                <TableCell>
                                  {data.payment_plan_meta.renewal_tax || 'No'}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>
                                  Renewal total (Including Tax)
                                </TableCell>
                                <TableCell>
                                  {data.payment_plan_meta.renewal_total || '-'}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>Billed on</TableCell>
                                <TableCell>
                                  {/* {data.payment_plan_meta.renewal_billed_on ||
                                    '-'} */}
                                  {/* {getBilled(
                                    data.payment_plan_meta.renewal_billed_on
                                  )} */}
                                  {data.payment_plan_meta.renewal_billed_on}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      ) : null}
                    </>
                  )}

                  <TableContainer className={classes.PlanTerms}>
                    <Table className={classes.table}>
                      <TableBody>
                        <TableRow>
                          {data.payment_plan_meta.number_of_session ? (
                            <TableCell>Total payment (Including Tax)</TableCell>
                          ) : (
                            <TableCell>
                              Initial payment (due now) (Including Tax)
                            </TableCell>
                          )}
                          <TableCell>
                            <span
                              style={{ color: '#01847a', fontWeight: '700' }}
                            >
                              {data.payment_plan_meta.total_payment
                                || data.payment_plan_meta.intial_payment}
                            </span>
                          </TableCell>
                        </TableRow>
                        {!data.payment_plan_meta.number_of_session ? (
                          <TableRow>
                            <TableCell>
                              {' '}
                              Renewal payments (Including Tax)
                            </TableCell>
                            <TableCell>
                              <span
                                style={{ color: '#01847a', fontWeight: '700' }}
                              >
                                {data.payment_plan_meta.renewal_total || '-'}
                              </span>
                            </TableCell>
                          </TableRow>
                        ) : null}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Box
                    className={classes.termsagree}
                    style={{
                      marginTop: '5px'
                    }}
                  >
                    <FormControl
                      control="input"
                      label=""
                      disabled
                      value={data.payment_plan_meta.sign_initials}
                      required
                      style={{ margin: '0 0' }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            <Grid container direction="row" className={classes.mAgreement}>
              <Grid item xs={3}>
                <h3 className={classes.headingTitle}>Membership Agreement</h3>
              </Grid>
              <Grid item xs={9}>
                {/* <Typography variant="h5" className={classes.MasubTitle}>
                  Jungle Brothers Pty Ltd Membership Agreement
                </Typography> */}

                <Box>
                  {/* <Typography
                    variant="h5"
                    className={classes.MasubTitle}
                    style={{
                      marginBottom: '15px'
                    }}
                  >
                    General
                  </Typography> */}
                  <div
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: data.membership_policy_meta.policy_meta.policy
                    }}
                    className="cke_contents_ltr cke_editable cke_editable_themed cke_show_borders"
                  />
                  {/* <p> */}
                  {/*  This Agreement and the Rules represent the complete */}
                  {/*  understanding between Member and Jungle Brothers Pty Ltd. No */}
                  {/*  representations, written or oral, other than those contained */}
                  {/*  in this contract are authorized or binding upon Jungle */}
                  {/*  Brothers Pty Ltd. Member understands that he/she is */}
                  {/*  obligated to pay the membership fee regardless of whether */}
                  {/*  Member uses the facility. Member agrees to promptly notify */}
                  {/*  Jungle Brothers Pty Ltd in writing of any changes of */}
                  {/*  address, e-mail, phone, and/or billing information. At the */}
                  {/*  end of the term of this membership contract, it will be */}
                  {/*  auto-renewed unless new rates have been installed or the */}
                  {/*  member provides notice of cancellation to terminate this */}
                  {/*  contract. */}
                  {/* </p> */}
                  {/* <Box mt="30px" /> */}
                  {/* <Typography variant="h5" className={classes.MasubTitle}> */}
                  {/*  Services Provided */}
                  {/* </Typography> */}
                  {/* <p> */}
                  {/*  Jungle Brothers Pty Ltd obligations hereunder and the */}
                  {/*  undersigned Members membership are conditioned upon (i) */}
                  {/*  Member executing this Agreement and initializing as */}
                  {/*  designated and (ii) Member otherwise complying with this */}
                  {/*  Agreement (including, without limitation, the Rules defined */}
                  {/*  below) For purposes of the foregoing conditions, the term */}
                  {/*  "member" shall include each individual ( i.e., spouse and */}
                  {/*  children) included in a membership. Conditioned on the */}
                  {/*  foregoing, operating hours, as established from time to */}
                  {/*  time, and (b) participate in any one or more group classes */}
                  {/*  offered by Jungle Brothers Pty Ltd from time to time. */}
                  {/* </p> */}
                  {/* <p> */}
                  {/*  The facility is located at the address of 15 Underwood */}
                  {/*  Avenue, Botany NSW Australia. Hours of operation as follows: */}
                  {/* </p> */}
                  {/* <p className={classes.onelineP}> */}
                  {/*  Operating Hours: vary, see www.junglebrothers.com Weekly */}
                  {/* </p> */}
                  {/* <p className={classes.onelineP}> */}
                  {/*  Classes: vary, see www.junglebrothers.com */}
                  {/* </p> */}
                  {/* <p className={classes.onelineP}> */}
                  {/*  <ul> */}
                  {/*    <li> */}
                  {/*      <span className={classes.listpoint} /> */}
                  {/*      All schedules are subject to change without notice. */}
                  {/*    </li> */}
                  {/*  </ul> */}
                  {/* </p> */}

                  {/* <Box mt="30px" /> */}
                  {/* <Typography variant="h5" className={classes.MasubTitle}> */}
                  {/*  Membership Contract and Payment Details */}
                  {/* </Typography> */}
                  {/* <p className={classes.onelineP}> */}
                  {/*  <ul> */}
                  {/*    <li> */}
                  {/*      <span className={classes.listpoint} /> */}
                  {/*      All fees are subject to change without notice, with due */}
                  {/*      respect and honour to current valid contract agreements */}
                  {/*    </li> */}
                  {/*    <li> */}
                  {/*      <span className={classes.listpoint} /> There are no */}
                  {/*      refunds for membership fees */}
                  {/*    </li> */}
                  {/*    <li> */}
                  {/*      <span className={classes.listpoint} /> */}
                  {/*      All contracts allow unlimited access to all classes */}
                  {/*      (subject to class capacity) */}
                  {/*    </li> */}
                  {/*    <li> */}
                  {/*      <span className={classes.listpoint} /> */}
                  {/*      All contracts allow unlimited access to open gym periods */}
                  {/*      under an issued program or competency level approved by */}
                  {/*      Jungle Brothers Pty Ltd */}
                  {/*    </li> */}
                  {/*    <li> */}
                  {/*      <span className={classes.listpoint} /> */}
                  {/*      If you are entering your contract via an upfront payment */}
                  {/*      option, your contract auto-renew will be set to the same */}
                  {/*      payment option. In knowing that finances can change over */}
                  {/*      time, and as this is a lump sum, a duty of care will be */}
                  {/*      taken by Jungle Brothers Pty Ltd to notify members in */}
                  {/*      this situation of this upcoming debit */}
                  {/*    </li> */}
                  {/*  </ul> */}
                  {/* </p> */}

                  {/* <Box ml="65px"> */}
                  {/*  <p> */}
                  {/*    <Typography variant="h6" className={classes.MasubTitleH6}> */}
                  {/*      12 Month Contract is conditioned upon the following */}
                  {/*      terms: */}
                  {/*    </Typography> */}
                  {/*    <ul> */}
                  {/*      <li> */}
                  {/*        <span className={classes.listpoint} /> */}
                  {/*        A12 month contract period = 52 weeks */}
                  {/*      </li> */}
                  {/*      <li> */}
                  {/*        <span className={classes.listpoint} /> */}
                  {/*        entitled to 1 x 4 week membership pause */}
                  {/*      </li> */}
                  {/*    </ul> */}
                  {/*  </p> */}
                  {/*  <p> */}
                  {/*    <Typography variant="h6" className={classes.MasubTitleH6}> */}
                  {/*      3 Month Contract is conditioned upon the following */}
                  {/*      terms: */}
                  {/*    </Typography> */}
                  {/*    <ul> */}
                  {/*      <li> */}
                  {/*        <span className={classes.listpoint} />3 month contract */}
                  {/*        = 12 weeks */}
                  {/*      </li> */}
                  {/*      <li> */}
                  {/*        <span className={classes.listpoint} /> no membership */}
                  {/*        pauses permittedentitled to 1 x 4 week membership */}
                  {/*        pause */}
                  {/*      </li> */}
                  {/*    </ul> */}
                  {/*  </p> */}
                  {/*  <p> */}
                  {/*    <Typography variant="h6" className={classes.MasubTitleH6}> */}
                  {/*      Flexi-Membership is conditioned upon the following */}
                  {/*      terms: */}
                  {/*    </Typography> */}
                  {/*    <ul> */}
                  {/*      <li> */}
                  {/*        <span className={classes.listpoint} /> */}
                  {/*        contract is for a 2 week period */}
                  {/*      </li> */}
                  {/*      <li> */}
                  {/*        <span className={classes.listpoint} /> contract is */}
                  {/*        auto-renewed unless new rates have been installed or */}
                  {/*        the member provides notice of cancellation to */}
                  {/*        terminate this contract */}
                  {/*      </li> */}
                  {/*      <li> */}
                  {/*        <span className={classes.listpoint} /> */}
                  {/*        contract can be cancelled at any time */}
                  {/*      </li> */}
                  {/*      <li> */}
                  {/*        <span className={classes.listpoint} /> */}
                  {/*        12 month contract period = 52 weeks */}
                  {/*      </li> */}
                  {/*      <li> */}
                  {/*        <span className={classes.listpoint} /> */}
                  {/*        entitled to 1 x 4 week membership pause */}
                  {/*      </li> */}
                  {/*    </ul> */}
                  {/*  </p> */}
                  {/*  <p> */}
                  {/*    <Typography variant="h6" className={classes.MasubTitleH6}> */}
                  {/*      6 Month Contract is conditioned upon the following */}
                  {/*      terms: */}
                  {/*    </Typography> */}
                  {/*    <ul> */}
                  {/*      <li> */}
                  {/*        <span className={classes.listpoint} />6 month contract */}
                  {/*        = 26 weeks */}
                  {/*      </li> */}
                  {/*      <li> */}
                  {/*        <span className={classes.listpoint} /> entitled to 1 x */}
                  {/*        2 week membership pause */}
                  {/*      </li> */}
                  {/*    </ul> */}
                  {/*  </p> */}

                  {/*  <p> */}
                  {/*    <Typography variant="h6" className={classes.MasubTitleH6}> */}
                  {/*      3 Month Contract is conditioned upon the following */}
                  {/*      terms: */}
                  {/*    </Typography> */}
                  {/*    <ul> */}
                  {/*      <li> */}
                  {/*        <span className={classes.listpoint} />3 month contract */}
                  {/*        = 12 weeks */}
                  {/*      </li> */}
                  {/*      <li> */}
                  {/*        <span className={classes.listpoint} /> no membership */}
                  {/*        pauses permitted */}
                  {/*      </li> */}
                  {/*    </ul> */}
                  {/*  </p> */}

                  {/*  <p> */}
                  {/*    <Typography variant="h6" className={classes.MasubTitleH6}> */}
                  {/*      Flexi-Membership is conditioned upon the following */}
                  {/*      terms: */}
                  {/*    </Typography> */}
                  {/*    <ul> */}
                  {/*      <li> */}
                  {/*        <span className={classes.listpoint} /> */}
                  {/*        contract is for a 2 week period */}
                  {/*      </li> */}
                  {/*      <li> */}
                  {/*        <span className={classes.listpoint} /> contract is */}
                  {/*        auto-renewed unless new rates have been installed or */}
                  {/*        the member provides notice of cancellation to */}
                  {/*        terminate this contract */}
                  {/*      </li> */}
                  {/*      <li> */}
                  {/*        <span className={classes.listpoint} /> contract can be */}
                  {/*        cancelled at any time */}
                  {/*      </li> */}
                  {/*    </ul> */}
                  {/*  </p> */}
                  {/* </Box> */}

                  {/* <Box mt="30px" /> */}
                  {/* <Typography variant="h5" className={classes.MasubTitle}> */}
                  {/*  Compliance with Rules */}
                  {/* </Typography> */}
                  {/* <p> */}
                  {/*  Member shall abide by all membership and facility rules and */}
                  {/*  regulations established by Jungle Brothers Pty Ltd, which */}
                  {/*  may be posted at the facility, provided in writing, or */}
                  {/*  issued orally and which may be amended from time to time in */}
                  {/*  the sole discretion of Jungle Brothers Pty Ltd */}
                  {/*  (collectively, "Rules"). I agree that improper or */}
                  {/*  unauthorized use of the facility or violation of the Rules */}
                  {/*  may result in member suspension or cancellation at Jungle */}
                  {/*  Brothers Pty Ltd's discretion. */}
                  {/* </p> */}

                  {/* <Box mt="30px" /> */}
                  {/* <Typography variant="h5" className={classes.MasubTitle}> */}
                  {/*  Membership Hold Periods & Cancellation Rights */}
                  {/* </Typography> */}
                  {/* <p> */}
                  {/*  <ul> */}
                  {/*    <li> */}
                  {/*      <span className={classes.listpoint} /> */}
                  {/*      pauses requested outside of the hold allowance, if any, */}
                  {/*      will be charged at 50% of the contracts weekly rate. */}
                  {/*      Requesting for a pause in membership must be done via */}
                  {/*      email to admin@junglebrothers.com at a minimum of 10 */}
                  {/*      days before the pause initiation */}
                  {/*    </li> */}
                  {/*    <li> */}
                  {/*      <span className={classes.listpoint} /> */}
                  {/*      with the exception of Flexi Members, a member cannot */}
                  {/*      cancel this contract at any time; members are not */}
                  {/*      refunded for an unused time left on their purchased */}
                  {/*      membership */}
                  {/*    </li> */}
                  {/*    <li> */}
                  {/*      <span className={classes.listpoint} /> */}
                  {/*      for members in extraordinary circumstances, a */}
                  {/*      suspension, pause or cancellation without fee can be */}
                  {/*      requested, however, Jungle Brothers Pty Ltd are in no */}
                  {/*      way obliged to apply the request */}
                  {/*    </li> */}
                  {/*    <li> */}
                  {/*      <span className={classes.listpoint} /> */}
                  {/*      Flexi Members only can cancel contracts at anytime; this */}
                  {/*      must be done so with 10 days notice via email to */}
                  {/*      admin@junglebrothers.com */}
                  {/*    </li> */}

                  {/*    <li> */}
                  {/*      <span className={classes.listpoint} /> */}
                  {/*      to cancel or make changes to an upcoming auto-renew */}
                  {/*      contract, members must make requests via email to */}
                  {/*      admin@junglebrothers.com at a minimum of 10 days before */}
                  {/*      current contract period ends */}
                  {/*    </li> */}
                  {/*  </ul> */}
                  {/* </p> */}

                  {/* <Box mt="30px" /> */}
                  {/* <Typography variant="h5" className={classes.MasubTitle}> */}
                  {/*  Cancellation Fees */}
                  {/* </Typography> */}
                  {/* <p className={classes.onelineP}> */}
                  {/*  Contract cancellations that are subject to fees are charged */}
                  {/*  as follows: */}
                  {/* </p> */}
                  {/* <p> */}
                  {/*  <Typography variant="h6" className={classes.MasubTitleH6}> */}
                  {/*    3 Month Agreement */}
                  {/*  </Typography> */}
                  {/*  <p> */}
                  {/*    $150 cancellation fee + $5 for every week remaining in */}
                  {/*    contract from cancellation date */}
                  {/*  </p> */}

                  {/*  <Typography variant="h6" className={classes.MasubTitleH6}> */}
                  {/*    6 Month Agreement */}
                  {/*  </Typography> */}
                  {/*  <p> */}
                  {/*    $150 cancellation fee + $5 for every week remaining in */}
                  {/*    contract from cancellation date */}
                  {/*  </p> */}

                  {/*  <Typography variant="h6" className={classes.MasubTitleH6}> */}
                  {/*    12 Month Agreement */}
                  {/*  </Typography> */}
                  {/*  <p className={classes.onelineP}> */}
                  {/*    - If cancellation is after 12 weeks into contract: */}
                  {/*  </p> */}
                  {/*  <p className={classes.onelineP}> */}
                  {/*    $150 cancellation fee + $5 for every week remaining in */}
                  {/*    contract from cancellation date */}
                  {/*  </p> */}

                  {/*  <p className={classes.onelineP}> */}
                  {/*    - If cancellation is under 12 weeks into contract: */}
                  {/*  </p> */}
                  {/*  <p className={classes.onelineP}> */}
                  {/*    $180 cancellation fee + $5 for every week remaining in */}
                  {/*    contract from cancellation date */}
                  {/*  </p> */}
                  {/* </p> */}
                </Box>
                <Box className={classes.termsagree} mt="15px">
                  <FormControl
                    control="input"
                    label=""
                    disabled
                    value={data.membership_policy_meta.sign_initials}
                    required
                    style={{ margin: '0 0' }}
                  />
                </Box>
              </Grid>
            </Grid>

            <Grid
              container
              direction="row"
              lassName={classes.SignBox}
              style={{ marginTop: '50px' }}
            >
              <Grid item xs={3}>
                <h3
                  className={classes.headingTitle}
                  style={{
                    marginTop: '9px'
                  }}
                >
                  Sign
                </h3>
              </Grid>
              <Grid item xs={9}>
                <Grid
                  container
                  justify="flex-end"
                  alignItems="center"
                  direction="row"
                >
                  <Grid item xs={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="checkedB"
                          checked={data.signature}
                          style={{ color: '#949494' }}
                          disabled
                        />
                      }
                      label="I agree to use electronic records and signatures"
                      className={classes.SignLabel}
                      style={{
                        marginRight: '0'
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <div className={classes.tbDates}>
                      <label>Date Issued: </label>
                      <span>
                        {data.date_issued}
                        {/* {data.date_issued &&
                          moment(data.date_issued).format(`${dateformat}`)} */}
                      </span>
                      <label>Date Signed: </label>
                      <span>
                        {data.date_signed}
                        {/* {data.date_signed &&
                          moment(data.date_signed).format(`${dateformat}`)} */}
                      </span>
                    </div>
                  </Grid>
                </Grid>
                <div className={classes.tbsignatures}>
                  <img src={data.signature || ImageNotFound} alt="Signed" />
                </div>
              </Grid>
            </Grid>
          </Card>
        </div>
      ) : (
        <LoadingView />
      )}
    </div>
  );
};

MembershipContract.propTypes = {
  classes: PropType.object.isRequired,
  location: PropType.object.isRequired,
  openNotify: PropType.func.isRequired
};
// export default withStyles(styles)(MembershipContract);

const mapToDispatch = (dispatch) => ({
  openNotify: (msg, type) => dispatch(openNoti(msg, type))
});
// export default Missingtable;
export default connect(
  null,
  mapToDispatch
)(withStyles(styles)(MembershipContract));
