const types = {
  activeProgram: 'ACTIVE_PROGRAM',
  inActiveProgram: 'INACTIVE_PROGRAM',
  search: 'SEARCH',
  page: 'PAGE',
  sorting: 'SORTING',
  sortname: 'name',
  sortdesc: 'description',
  isFetching: 'PROGRAM_FETCHING_START',
  fetchSuccess: 'PROGRAM_FETCHED_SUCCESSFULLY',
  Errorfetch: 'PROGRAM_FETCH_ERROR',
  Activate: 1,
  DeActivate: 0
};

export default types;
