/* eslint-disable operator-linebreak */
import React, { useState, useCallback, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Grid,
  withStyles,
  Card,
  Breadcrumbs,
  Typography,
  Box,
  InputBase,
  Paper,
  ThemeProvider
} from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
import Pagination from '@material-ui/lab/Pagination';
import ApplyFilter from 'src/views/common/ApplyFilter/applyFilter';
import { useSelector, connect, useDispatch } from 'react-redux';
import { getrevenueCategory } from 'src/Redux/ReportsReducer/reports.reducer.actions';
import LoadingView from 'src/views/common/loading';
import filterType from 'src/Redux/Reducer.constants';
import { debounce } from 'lodash';
import { exportRevenueCategory } from 'src/services/reports.services';
import CancelDialogTwo from 'src/views/common/CommonDialogBox/CancelDialogTwo';
import useChangeTitle from 'src/views/common/CustomHooks/useTitleChange';
import JBButton from '../../common/JBButton/JBButton';
import ImportIcon from '../../../assets/image/upload.png';
import RevenueCategoryTable from './AllTables/RevenueCategoryTable/RevenueCategoryTable';
// import { DummyValues } from './AllTables/RevenueCategoryTable/dummyData';
import RevenueCategoryFilters from './Filters/RevenueCategoryFilters';
import { getRevenueChart } from '../Reports.utils';
import BarChart from '../chart/BarChart';
// import { chartData } from './chartData/RevenueCategoryData';
import styles from './FinancialReports.style';

const themeInstance = {
  overrides: {
    MuiBreadcrumbs: {
      root: {
        color: '#949494',
        textTransform: 'uppercase',
        fontSize: '20px',
        lineHeight: '24px',
        fontWeight: '700'
      },
      li: {
        '& a': {
          color: '#949494',
          textTransform: 'uppercase',
          fontSize: '20px',
          lineHeight: '24px'
        },
        '@media (max-width: 1440px)': {
          '& a': {
            fontSize: '18px'
          },
          '& h3': {
            fontSize: '18px'
          }
        },
        '& h3': {
          margin: '0 0'
        }
      },
      separator: {
        marginLeft: '12px',
        marginRight: '12px'
      }
    }
  }
};

const RevenueCategory = (props) => {
  const { data: data_reports } = useSelector(
    (state) => state.reportsReducer.reportList
  );
  useChangeTitle('Revenue by Revenue Category | Jungle Alliance');
  const [open, setOpen] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const dispatch = useDispatch();
  const {
    classes,
    RevenueCategoryFilter,
    RevenueTags,
    RevenueSearch,
    RevenueSort,
    customFilter
  } = props;
  const [search, setSearch] = useState(RevenueSearch);
  const { isFetching, data, error } = useSelector(
    (state) => state?.reportsReducer.financialReports.revenueCategory
  );
  const SearchQuery = useCallback(
    debounce((q) => RevenueCategoryFilter(filterType.search, q), 1500),
    []
  );
  const setDirection = (name) => {
    if (RevenueSort.sort_name === name) {
      return RevenueSort.sort_by === 'asc' ? 'asc' : 'dsc';
    }
    return 'asc';
  };

  const isActive = (sortlabel) => {
    if (RevenueSort.sort_name === sortlabel) return true;
    return false;
  };

  const setSort = (sortlabel) => {
    RevenueCategoryFilter(filterType.sorting, sortlabel);
  };
  const handleReset = () => {
    RevenueCategoryFilter(filterType.resetFilter);
    setSearch('');
  };
  const Paginate = (val) => {
    if (val !== data.current_page) {
      return RevenueCategoryFilter(filterType.page, val);
    }
    return null;
  };

  const isStarred = data_reports?.financial_report.filter(
    (report) => report.id === 12
  )[0].is_starred;
  useEffect(() => {
    return () => {
      handleReset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (!data_reports || !data) return <LoadingView />;
  return (
    <div className={classes.root}>
      <div className="title-bk">
        <Box css={{ marginBottom: '20px' }}>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={0}
          >
            <Grid item xs={9}>
              <Box display="inline-flex">
                <ThemeProvider theme={themeInstance}>
                  <Breadcrumbs aria-label="breadcrumb">
                    <div color="inherit">Reports</div>
                    <div
                      color="inherit"
                      onClick={() => setOpen(true)}
                      style={{
                        cursor: 'pointer'
                      }}
                    >
                      financial reports
                    </div>
                    <Typography variant="h3" color="Primary">
                      <span>Revenue by revenue category</span>
                    </Typography>
                  </Breadcrumbs>
                </ThemeProvider>
              </Box>
              <Box
                display="inline-flex"
                ml="10px"
                css={{ verticalAlign: 'text-bottom' }}
              >
                {isStarred === 1 && (
                  <StarIcon
                    style={{
                      color: '#FFC700'
                    }}
                  />
                )}
              </Box>
            </Grid>
            <Grid
              item
              xs={3}
              style={{
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              <JBButton
                type="outlined"
                isImg={ImportIcon}
                component={Link}
                style={{ marginLeft: '10px' }}
                disabled={data?.data?.data.length === 0}
                onClick={(e) => {
                  e.preventDefault();
                  exportRevenueCategory({
                    search: RevenueSearch,
                    sort_name: RevenueSort.sort_name,
                    sort_by: RevenueSort.sort_by,
                    ...customFilter
                  });
                }}
              >
                Export
              </JBButton>
            </Grid>
          </Grid>
        </Box>
      </div>

      <Card className={classes.mainCard}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={0}
        >
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={0}
          >
            <Grid
              item
              xs={12}
              justify="center"
              alignItems="center"
              textAlign="center"
            >
              {data?.revenue_category.length === 0 &&
              data?.net_revenue.length === 0 ? null : (
                <Box css={{ textAlign: 'center' }}>
                  <Box display="inline-block">
                    <Typography variant="h6" className={classes.BkChartTitle}>
                      <span>Revenue by Revenue Category</span>
                    </Typography>
                    <BarChart
                      data={getRevenueChart(
                        data.revenue_category,
                        data.net_revenue
                      )}
                      className={classes.BkChart}
                    />
                  </Box>
                </Box>
                // eslint-disable-next-line indent
              )}
            </Grid>
          </Grid>

          <Grid item xs={6}>
            <Grid container direction="row" alignItems="center" spacing={0}>
              <Grid item xs={9}>
                <div className={classes.search}>
                  <InputBase
                    fullWidth
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                      SearchQuery(e.target.value);
                    }}
                    placeholder="Search…"
                    classes={{
                      root: classes.inputRoot,
                      input: classes.searchInput
                    }}
                    InputProps={{
                      'aria-label': 'search'
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs>
                <JBButton
                  type="outlined"
                  onClick={handleReset}
                  style={{ marginLeft: '10px' }}
                >
                  reset
                </JBButton>
              </Grid>
              <Grid item xs={12}>
                <div>
                  {RevenueTags.map((tags) => (
                    <div
                      className={classes.DTags}
                      key={tags.id}
                      style={{ display: 'inline-block' }}
                    >
                      <Box display="inline-block" mr={1}>
                        <label>
                          {tags.name}
                          <span
                            className={classes.DTagsClose}
                            onClick={() =>
                              RevenueCategoryFilter(
                                filterType.removecustom,
                                tags
                              )
                            }
                          >
                            ×
                          </span>
                        </label>
                      </Box>
                    </div>
                  ))}
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} justify="flex-end" className="MuiGrid-container">
            <Box css={{ width: '240px' }}>
              <ApplyFilter>
                <RevenueCategoryFilters
                  getFilData={(val1, val2) =>
                    RevenueCategoryFilter(filterType.addcustom, [val1, val2])
                  }
                />
              </ApplyFilter>
            </Box>
          </Grid>
        </Grid>
        {isFetching || !data ? (
          <LoadingView />
        ) : error ? (
          <p>{error}</p>
        ) : (
          <Fragment>
            {' '}
            <Grid item xs={12}>
              <Box mt="25px" />

              <RevenueCategoryTable
                values={data?.data || []}
                summary={data.summary}
                totalSummary={data.total_summary}
                setDirection={setDirection}
                isActive={isActive}
                setSort={setSort}
              />
            </Grid>
            {data.data?.data.length !== 0 && (
              <Grid
                item
                xs={12}
                container
                direction="row"
                alignItems="center"
                style={{ marginTop: 24 }}
              >
                <Grid item xs={6} justify="flex-start">
                  <Paper elevation={0} style={{ color: '#A9A9A9' }}>
                    {data.data?.from && data.data?.to > 1 ? (
                      <Paper elevation={0} style={{ color: '#A9A9A9' }}>
                        {`${data.data?.from} - ${data.data?.to} of ${data.data?.total}`}
                      </Paper>
                    ) : null}
                  </Paper>
                </Grid>
                <Grid
                  item
                  xs={6}
                  justify="flex-end"
                  style={{ display: 'flex' }}
                >
                  <Pagination
                    count={data.data?.last_page}
                    color="primary"
                    variant="outlined"
                    page={data.data?.current_page}
                    onChange={(_, val) => Paginate(val)} //eslint-disable-line
                    shape="rounded"
                  />
                </Grid>
              </Grid>
            )}
          </Fragment>
        )}
      </Card>
      <CancelDialogTwo setOpen={open} handleClose={() => setOpen(false)} />
    </div>
  );
};
const mapToDispatch = (dispatch) => {
  return {
    RevenueCategoryFilter: (filter, data) =>
      dispatch(getrevenueCategory(filter, data))
  };
};
const mapToState = (state) => ({
  RevenueTags: state.reportsReducer.financialReports.revenueCategoryFil.tags,
  RevenueSearch:
    state.reportsReducer.financialReports.revenueCategoryFil.search,
  RevenueSort: state.reportsReducer.financialReports.revenueCategoryFil.sorting,
  customFilter:
    state.reportsReducer.financialReports.revenueCategoryFil.customFilter
});
RevenueCategory.propTypes = {
  classes: PropTypes.object.isRequired,
  RevenueCategoryFilter: PropTypes.func.isRequired,
  RevenueTags: PropTypes.array.isRequired,
  RevenueSearch: PropTypes.string.isRequired,
  RevenueSort: PropTypes.object.isRequired,
  customFilter: PropTypes.object.isRequired
};

export default connect(
  mapToState,
  mapToDispatch
)(withStyles(styles)(RevenueCategory));
