/* eslint-disable function-paren-newline */
/* eslint-disable operator-linebreak */
/* eslint-disable no-confusing-arrow */
/* eslint-disable indent */
import React, { useState, useEffect } from 'react';
import PropType from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import clsx from 'clsx';
import {
  Grid,
  Card,
  Typography,
  Box,
  Breadcrumbs,
  InputLabel,
  withStyles,
  FormControlLabel,
  Radio,
  RadioGroup,
  Button
} from '@material-ui/core';
// import JBquillEditor from 'src/views/common/JBQuillEditor/JBquillEditor';
import FormControl from 'src/views/common/FormControl/formControl';
import IOSSwitch from 'src/views/common/FormControl/IOSSwitch';
import AddIcon from '@material-ui/icons/Add';
import { getErrorMsg } from 'src/utils/ErrorHandling';
import LoadingView from 'src/views/common/loading';
import NotFoundView from 'src/views/errors/NotFoundView';
import { ROUTES } from 'src/constant/Constants';
import queryString from 'query-string';
import { connect } from 'react-redux';
import JBCKEditor from 'src/views/common/JBCKEditor';
import { openNoti } from 'src/Redux/global/global.actions';
import { getGymSetup } from 'src/Redux/GymSetupReducer/GymSetup.actions';
import { checkValidString, isValidQuill } from 'src/utils/validations';
import { checkQuillValid } from 'src/utils/someCommon';
import useChangeTitle from 'src/views/common/CustomHooks/useTitleChange';
import CancelDialog from '../../common/CommonDialogBox/CancelDialog';
import { ReactComponent as Btnup } from '../../../assets/image/Btnup.svg';
import { ReactComponent as Btndown } from '../../../assets/image/Btndown.svg';
import { ReactComponent as Btnclose } from '../../../assets/image/Btnclose.svg';
import DeleteBtn from '../../../assets/image/dDelete.png';
import JBButton from '../../common/JBButton/JBButton';
import DeleteDialog from './DeleteWaiverDialog';
import UpdateWaiversDialog from './UpdateWaiversDialog';
import CustomAutoComplete from '../../common/CustomAutocomplete/commonAutocomplete';
import {
  updateWaiverTemp,
  getEditWaiver
} from '../../../services/Contracts.services';
import styles from './Waivers.style';
import { AllQue } from './NewWaiver.data';
import { deleteMcqAns } from './newWaiver.utils';
import { getTempWav } from '../../../Redux/WaiverReducer/WaiverReducer.actions';
import CancelDialogTwo from '../../common/CommonDialogBox/CancelDialogTwo';
import WaiverTooltip from './WaiverTooltip';

const WaiversTemplates = ({
  classes,
  openNotify,
  location,
  WaivFilter,
  gymSetup,
  getGymSteps
}) => {
  const { waiverId } = queryString.parse(location.search);
  const history = useHistory();
  const [data, setData] = useState(null);
  const [errorFetch, seterrorFetch] = useState(false);
  const [que_id, setId] = useState(null);
  const [que, setQue] = useState(null);
  const [mcqOption, setMcqOption] = useState(3);
  const [cancelDialog, setcancelDialog] = useState(false);
  useChangeTitle('Edit Waiver | Jungle Alliance');
  useEffect(() => {
    getEditWaiver(waiverId)
      .then((res) => {
        // eslint-disable-next-line no-use-before-define
        setData(divideData(res));
      })
      .catch(() => seterrorFetch(true));
  }, [waiverId]);
  const handleQue = () => {
    if (que) {
      if (que === 'Yes/No with follow-up') {
        setData({
          ...data,
          questionnaire_meta: [
            ...data.questionnaire_meta,
            {
              id: que_id,
              que_type: que,
              que: null,
              required: 1,
              touchedfield: false,
              follow_up: null,
              touchedfieldfollow: false
            }
          ]
        });
      } else if (que === 'Multiple Choice') {
        setData({
          ...data,
          questionnaire_meta: [
            ...data.questionnaire_meta,
            {
              id: que_id,
              que_type: que,
              que: null,
              required: 1,
              // answer_choices: [
              //   { id: 1, value: null },
              //   { id: 2, value: null }
              // ],
              touchedfield: false,
              answer_choices: [
                { id: 1, value: null, touchedfield: false },
                { id: 2, value: null, touchedfield: false }
              ],
              allow_multiple_ans: 0
            }
          ]
        });
      } else {
        setData({
          ...data,
          questionnaire_meta: [
            ...data.questionnaire_meta,
            {
              id: que_id,
              que_type: que,
              que: '',
              touchedfield: false,
              required: 1
            }
          ]
        });
      }
      setId(que_id + 1);
    }
  };
  const handleRequired = (index) => {
    const newArray = [...data.questionnaire_meta];
    newArray[index] = {
      ...newArray[index],
      required: !data.questionnaire_meta[index].required * 1
    };
    setData({
      ...data,
      questionnaire_meta: newArray
    });
  };
  const handleDelete = (index) => {
    setData({
      ...data,
      questionnaire_meta: [...data.questionnaire_meta].filter(
        (list) => list.id !== index
      )
    });
  };
  const handleUp = (index) => {
    const newArray = [...data.questionnaire_meta];
    const newIndex = newArray.findIndex((x) => x.id === index);
    if (newIndex > 0) {
      console.warn(newArray[newIndex - 1], newArray[newIndex]);
      newArray[newIndex] = {
        ...newArray[newIndex],
        id: index - 1
      };
      console.warn(newArray[newIndex - 1], newArray[newIndex]);
      newArray[newIndex - 1] = {
        ...newArray[newIndex - 1],
        id: index
      };
      console.warn(newArray[newIndex - 1], newArray[newIndex]);
      newArray.sort((a, b) => {
        return a.id - b.id;
      });

      console.warn(newArray);
      setData({
        ...data,
        questionnaire_meta: newArray
      });
    }
  };
  const handleDown = (index) => {
    const newArray = [...data.questionnaire_meta];
    const newIndex = newArray.findIndex((x) => x.id === index);
    if (newIndex < data.questionnaire_meta.length - 1) {
      console.warn(newArray[newIndex + 1], newArray[newIndex]);
      newArray[newIndex] = {
        ...newArray[newIndex],
        id: index + 1
      };
      console.warn(newArray[newIndex + 1], newArray[newIndex]);
      newArray[newIndex + 1] = {
        ...newArray[newIndex + 1],
        id: index
      };
      console.warn(newArray[newIndex + 1], newArray[newIndex]);
      newArray.sort((a, b) => {
        return a.id - b.id;
      });
      console.warn(newArray);
      setData({
        ...data,
        questionnaire_meta: newArray
      });
    }
  };
  const createMcqOption = (index) => {
    const newArray = [...data.questionnaire_meta];
    let createMcq = [...data.questionnaire_meta[index].answer_choices];

    createMcq = [
      ...createMcq,
      {
        id: mcqOption,
        value: null,
        touchedfield: false
      }
    ];
    newArray[index] = {
      ...newArray[index],
      answer_choices: createMcq
    };
    setData({
      ...data,
      questionnaire_meta: newArray
    });
    setMcqOption(mcqOption + 1);
  };

  const divideData = (res) => {
    let result = res;
    if (Array.isArray(JSON.parse(res.questionnaire_meta))) {
      result = {
        ...result,
        need_resign: 0,
        waiver_terms_meta: {
          ...JSON.parse(res.waiver_terms_meta),
          terms: JSON.parse(res.waiver_terms_meta).terms?.replace(/\\/, '')
        },
        email_template: JSON.parse(res.email_template),
        questionnaire_meta: JSON.parse(res.questionnaire_meta)
      };
      if (result.questionnaire_meta.length) {
        setId(
          result.questionnaire_meta[result.questionnaire_meta.length - 1].id + 1
        );
      } else {
        setId(1);
      }
    } else {
      setId(1);
      result = {
        ...result,
        need_resign: 0,
        waiver_terms_meta: {
          ...JSON.parse(res.waiver_terms_meta),
          terms: JSON.parse(res.waiver_terms_meta).terms?.replace(/\\/, '')
        },
        email_template: JSON.parse(res.email_template),
        questionnaire_meta: []
      };
    }
    return result;
  };
  // const validateQues = (validateArray) => {
  //   for (let i = 0; i < validateArray.length; i++) {
  //     if (!validateArray[i].que || checkValidString(validateArray[i].que))
  //       return false;
  //     if (validateArray[i].que_type === 'Multiple Choice') {
  //       for (let j = 0; j < validateArray[i].answer_choices.length; j++) {
  //         if (
  //           !validateArray[i].answer_choices[j].value ||
  //           validateArray[i].answer_choices[j].value.trim().length === 0
  //         )
  //           return false;
  //       }
  //     }
  //   }
  //   return true;
  // };
  const validateQues = (validateArray) => {
    let flag = 0;
    const result = validateArray;

    for (let i = 0; i < validateArray.length; i += 1) {
      if (!validateArray[i].que || checkValidString(validateArray[i].que)) {
        flag = 1;
        result[i].touchedfield = true;
        document
          .getElementById(`waiversfieldform${validateArray[i].id}`)
          .focus();
      }
      if (typeof validateArray[i].follow_up !== 'undefined') {
        if (checkValidString(validateArray[i]?.follow_up)) {
          flag = 1;
          result[i].touchedfieldfollow = true;
          document
            .getElementById(`waiversfieldform${validateArray[i].id}`)
            .focus();
        }
      }
      if (validateArray[i].que_type === 'Multiple Choice') {
        for (let j = 0; j < validateArray[i].answer_choices.length; j += 1) {
          if (
            !validateArray[i].answer_choices[j].value ||
            validateArray[i].answer_choices[j].value.trim().length === 0
          ) {
            flag = 1;
            result[i].answer_choices[j].touchedfield = true;
            document
              .getElementById(
                `waiversmcqfield${validateArray[i].id}form${validateArray[i].answer_choices[j].id}`
              )
              .focus();
          }
        }
      }
    }
    if (flag === 1) {
      setData({ ...data, questionnaire_meta: result });
      return false;
    }
    return true;
  };
  const validateData = () => {
    if (!data.name) {
      openNotify('Waiver name is empty', 'error');
      return false;
    }
    if (checkValidString(data.name)) {
      openNotify('Please provide valid waiver name', 'error');
      return false;
    }
    if (data.is_published === 1) {
      if (
        !data.waiver_terms_meta.terms ||
        !checkQuillValid(data.waiver_terms_meta.terms)
      ) {
        openNotify('Membership Policy is required field', 'error');
        return false;
      }
      if (!validateQues(data.questionnaire_meta)) {
        openNotify('You may have an empty cell.', 'error');
        return false;
      }
      if (!data.email_template.subject || !data.email_template.body) {
        openNotify('Please provide email subject and body', 'error');
        return false;
      }
      if (
        checkValidString(data.email_template.subject) ||
        checkValidString(data.email_template.body)
      ) {
        openNotify('Email subject and body are not valid', 'error');
        return false;
      }
    }

    return true;
  };
  const updateWaiver = (value) => {
    let result = [];
    result = {
      name: value.name,
      terms: isValidQuill(value.waiver_terms_meta.terms)
        ? value.waiver_terms_meta.terms
        : `<p>${value.waiver_terms_meta.terms}</p>`,
      initial: value.waiver_terms_meta.initial,
      questionnaire_meta: JSON.stringify(value.questionnaire_meta),
      email_template: JSON.stringify(value.email_template),
      is_published: value.is_published,
      need_resign: value.need_resign
    };
    if (data.is_published) {
      if (validateData()) {
        updateWaiverTemp(result, waiverId) // pass id
          .then(() => {
            WaivFilter();
            history.push(ROUTES.DOCUMENTS_WAIVERS_TEMPLATES);
          })
          .then(() =>
            // openNotify('Successfully updated')
            result.is_published === 1
              ? openNotify(`Waiver "${result.name}" updated successfully`)
              : openNotify(`Waiver "${result.name}" saved as a draft`)
          )
          .then(() => {
            if (gymSetup.process_type.waiver === 0) {
              getGymSteps();
            }
          })
          .catch((err) => openNotify(getErrorMsg(err), 'error'));
      }
    } else {
      updateWaiverTemp(result, waiverId) // pass id
        .then(() => {
          WaivFilter();
          history.push(ROUTES.DOCUMENTS_WAIVERS_TEMPLATES);
        })
        .then(() =>
          // openNotify('Successfully updated')
          result.is_published === 1
            ? openNotify(`Waiver "${result.name}" updated successfully`)
            : openNotify(`Waiver "${result.name}" saved as a draft`)
        )
        .then(() => {
          if (gymSetup.process_type.waiver === 0) {
            getGymSteps();
          }
        })
        .catch((err) => openNotify(getErrorMsg(err), 'error'));
    }
  };

  const handleBlur = (id) => {
    setData({
      ...data,
      questionnaire_meta: data.questionnaire_meta.map((ques) =>
        ques.id === id
          ? { ...ques, touchedfield: true, touchedfieldfollow: true }
          : ques
      )
    });
  };

  const handleMCQBlur = (typeid, id) => {
    setData({
      ...data,
      questionnaire_meta: data.questionnaire_meta.map((ques) =>
        ques.id === typeid
          ? {
              ...ques,
              answer_choices: ques.answer_choices.map((subque) =>
                subque.id === id ? { ...subque, touchedfield: true } : subque
              )
            }
          : ques
      )
    });
  };

  if (errorFetch || !waiverId) return <NotFoundView />;
  if (!data) return <LoadingView />;
  return (
    <div className={classes.root}>
      <div className="title-bk">
        <Breadcrumbs aria-label="breadcrumb">
          <div color="inherit">documents</div>
          <div
            color="inherit"
            onClick={() => setcancelDialog(true)}
            style={{
              cursor: 'pointer'
            }}
          >
            waivers
          </div>
          <Typography variant="h3" color="Primary">
            <span>templates</span>
          </Typography>
        </Breadcrumbs>
      </div>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        spacing={0}
        style={{ margin: '20px 0 20px' }}
      >
        <Grid item xs={6}>
          <h1 style={{ margin: '0 0' }}>Edit Waiver</h1>
        </Grid>
        <Grid
          item
          xs={6}
          className={classes.btnBlock}
          style={{ justifyContent: 'flex-end', display: 'flex' }}
        >
          <JBButton
            type="outlined"
            style={{ marginRight: '15px' }}
            component={Link}
            // onClick={(e) => {
            //   history.push({
            //     pathname: ROUTES.DOCUMENTS_WAIVERS_COPY,
            //     state: { abc: 'abc' }
            //   });
            // }}
            to={{
              pathname: '/app/documents/waiver/copy',

              state: { data, id: que_id }
            }}
          >
            Copy waiver
          </JBButton>
          <DeleteDialog id={waiverId} name={data.name} />
        </Grid>
      </Grid>
      <Card className={clsx(classes.mainCard, classes.contractCard)}>
        <Grid container direction="row" className={classes.Cardwrapper}>
          <Grid item xs={3}>
            <h3 className={classes.headingTitle}>Basic Details</h3>
          </Grid>
          <Grid item xs={9}>
            <FormControl
              control="input"
              label="Waiver name"
              placeholder=""
              value={data.name}
              onChange={(e) => {
                setData({ ...data, name: e.target.value });
              }}
              required
              style={{ margin: '0 0' }}
            />
          </Grid>
        </Grid>
        <Box mt="20px" />
        <Grid container direction="row" className={classes.Cardwrapper}>
          <Grid item xs={3}>
            <h3 className={classes.headingTitle}>Waiver Terms</h3>
            <p className={clsx(classes.contrP, classes.mpP)}>
              Type or paste your current waiver terms into the text area below
              and format it appropriately.
            </p>
          </Grid>
          <Grid item xs={9}>
            <InputLabel
              htmlFor="age-required"
              className={classes.cutom_select_Label}
            >
              Waiver Terms
            </InputLabel>
            <JBCKEditor
              data={data?.waiver_terms_meta?.terms}
              onChange={(e) =>
                setData({
                  ...data,
                  waiver_terms_meta: {
                    ...data.waiver_terms_meta,
                    terms: e.editor.getData()
                  }
                })
              }
            />
            {/* <JBquillEditor
              value={data.waiver_terms_meta.terms}
              theme="snow"
              onChange={(value) => {
                setData({
                  ...data,
                  waiver_terms_meta: {
                    ...data.waiver_terms_meta,
                    terms: value
                  }
                });
              }}
              ErrorText="Waiver details must be fill valid values"
            /> */}
            <Box className={classes.Initialbox}>
              <FormControlLabel
                control={
                  <IOSSwitch
                    checked={data.waiver_terms_meta.initial}
                    onChange={() => {
                      setData({
                        ...data,
                        waiver_terms_meta: {
                          ...data.waiver_terms_meta,
                          initial: !data.waiver_terms_meta.initial * 1
                        }
                      });
                    }}
                  />
                }
                style={{ display: 'inline-block', margin: '0 0' }}
              />

              <InputLabel
                htmlFor="age-required"
                className={classes.cutom_Switch_Label}
              >
                Initial box
              </InputLabel>
              <WaiverTooltip style={{ display: 'inline-block' }} />
            </Box>
          </Grid>
        </Grid>

        <Box mt="20px" />
        <Grid container direction="row" className={classes.Cardwrapper}>
          <Grid item xs={3}>
            <h3 className={classes.headingTitle}>Questionnaire</h3>
            <p className={clsx(classes.contrP, classes.mpP)}>
              Create custom questions for your member to answer in regards to
              health history, exercise habits, injuries, etc.
            </p>
          </Grid>
          <Grid item xs={9}>
            <Box
              css={{
                marginTop: `${
                  data.questionnaire_meta.length > 0 ? '-15px' : '0px'
                }`
              }}
            />
            <Grid item xs={12}>
              {data.questionnaire_meta ? (
                data.questionnaire_meta.map((type, index) => {
                  if (type.que_type === 'Yes/No') {
                    return (
                      <Box className={classes.QuestionBox}>
                        <Box className={classes.QueBtnWrapper}>
                          <h5>Yes/No</h5>
                          <Box className={classes.QueBtnBk}>
                            <Button onClick={() => handleUp(type.id)}>
                              <Btnup />
                            </Button>
                            <Button onClick={() => handleDown(type.id)}>
                              <Btndown />
                            </Button>
                            <Button onClick={() => handleDelete(type.id)}>
                              <Btnclose />
                            </Button>
                          </Box>
                        </Box>
                        <FormControl
                          control="input"
                          label="Type a question"
                          placeholder=""
                          id={`waiversfieldform${type.id}`}
                          value={data.questionnaire_meta[index].que}
                          onBlur={() => handleBlur(type.id)}
                          error={Boolean(
                            type.touchedfield && checkValidString(type.que)
                          )}
                          helperText={
                            type.touchedfield && checkValidString(type.que)
                              ? 'Please enter valid question'
                              : null
                          }
                          onChange={(e) => {
                            const newArray = [...data.questionnaire_meta];
                            newArray[index] = {
                              ...newArray[index],
                              que: e.target.value
                            };
                            setData({ ...data, questionnaire_meta: newArray });
                          }}
                          style={{ margin: '0 0' }}
                          maxLength={500}
                          seterrors={!data.questionnaire_meta[index].que}
                        />
                        <Box mt="25px" />
                        <Box>
                          <FormControlLabel
                            control={
                              <IOSSwitch
                                checked={
                                  data.questionnaire_meta[index].required
                                }
                                onChange={() => handleRequired(index)}
                              />
                            }
                            style={{ display: 'inline-block', margin: '0 0' }}
                          />
                          <InputLabel
                            htmlFor="age-required"
                            className={classes.cutom_Switch_Label}
                          >
                            Required
                          </InputLabel>
                        </Box>
                      </Box>
                    );
                  }
                  if (type.que_type === 'Freeform Answer Box') {
                    return (
                      <Box className={classes.QuestionBox}>
                        <Box className={classes.QueBtnWrapper}>
                          <h5>Freeform Answer Box</h5>
                          <Box className={classes.QueBtnBk}>
                            <Button onClick={() => handleUp(type.id)}>
                              <Btnup />
                            </Button>
                            <Button onClick={() => handleDown(type.id)}>
                              <Btndown />
                            </Button>
                            <Button onClick={() => handleDelete(type.id)}>
                              <Btnclose />
                            </Button>
                          </Box>
                        </Box>
                        <FormControl
                          control="input"
                          label="Type a question"
                          placeholder=""
                          id={`waiversfieldform${type.id}`}
                          value={type.que}
                          onBlur={() => handleBlur(type.id)}
                          error={Boolean(
                            type.touchedfield && checkValidString(type.que)
                          )}
                          helperText={
                            type.touchedfield && checkValidString(type.que)
                              ? 'Please enter valid question'
                              : null
                          }
                          onChange={(e) => {
                            const newArray = [...data.questionnaire_meta];
                            newArray[index] = {
                              ...newArray[index],
                              que: e.target.value
                            };
                            setData({
                              ...data,
                              questionnaire_meta: newArray
                            });
                          }}
                          maxLength={500}
                          seterrors={!type.que}
                          style={{ margin: '0 0' }}
                        />
                        <Box mt="25px" />
                        <Box>
                          <FormControlLabel
                            control={
                              <IOSSwitch
                                checked={
                                  data.questionnaire_meta[index].required
                                }
                                onChange={() => handleRequired(index)}
                              />
                            }
                            style={{ display: 'inline-block', margin: '0 0' }}
                          />
                          <InputLabel
                            htmlFor="age-required"
                            className={classes.cutom_Switch_Label}
                          >
                            Required
                          </InputLabel>
                        </Box>
                      </Box>
                    );
                  }
                  if (type.que_type === 'Multiple Choice') {
                    return (
                      <Box className={classes.QuestionBox}>
                        <Box className={classes.QueBtnWrapper}>
                          <h5>Multiple Choice</h5>
                          <Box className={classes.QueBtnBk}>
                            <Button onClick={() => handleUp(type.id)}>
                              <Btnup />
                            </Button>
                            <Button onClick={() => handleDown(type.id)}>
                              <Btndown />
                            </Button>
                            <Button onClick={() => handleDelete(type.id)}>
                              <Btnclose />
                            </Button>
                          </Box>
                        </Box>
                        <Box>
                          <FormControl
                            control="input"
                            label="Type a question"
                            placeholder=""
                            value={type.que}
                            id={`waiversfieldform${type.id}`}
                            onBlur={() => handleBlur(type.id)}
                            error={Boolean(
                              type.touchedfield && checkValidString(type.que)
                            )}
                            helperText={
                              type.touchedfield && checkValidString(type.que)
                                ? 'Please enter valid question'
                                : null
                            }
                            onChange={(e) => {
                              const newArray = [...data.questionnaire_meta];
                              newArray[index] = {
                                ...newArray[index],
                                que: e.target.value
                              };
                              setData({
                                ...data,
                                questionnaire_meta: newArray
                              });
                            }}
                            style={{ margin: '0 0' }}
                            maxLength={500}
                            seterrors={!type.que}
                          />
                        </Box>
                        {type.answer_choices.map((blank, index2) => {
                          return (
                            <Box display="flex" alignItems="center">
                              <FormControl
                                control="input"
                                label=""
                                value={blank.value}
                                id={`waiversmcqfield${type.id}form${blank.id}`}
                                onBlur={() => handleMCQBlur(type.id, blank.id)}
                                error={Boolean(
                                  blank.touchedfield &&
                                    checkValidString(blank.value)
                                )}
                                helperText={
                                  blank.touchedfield &&
                                  checkValidString(blank.value)
                                    ? 'Please enter valid answers'
                                    : null
                                }
                                onChange={(e) => {
                                  const createMcq = [
                                    ...data.questionnaire_meta[index]
                                      .answer_choices
                                  ];
                                  createMcq[index2] = {
                                    ...createMcq[index2],
                                    value: e.target.value
                                  };
                                  const newArray = [...data.questionnaire_meta];
                                  newArray[index] = {
                                    ...newArray[index],
                                    answer_choices: createMcq
                                  };
                                  setData({
                                    ...data,
                                    questionnaire_meta: newArray
                                  });
                                }}
                                placeholder="Type answer here"
                                style={{ margin: '15px 0 0' }}
                                maxLength={500}
                                seterrors={!blank.value}
                              />
                              {type.answer_choices &&
                              type.answer_choices.length > 2 ? (
                                <Button
                                  onClick={() => {
                                    setData({
                                      ...data,
                                      questionnaire_meta: deleteMcqAns(
                                        data.questionnaire_meta,
                                        type.id,
                                        blank.id
                                      )
                                    });
                                  }}
                                  className={classes.QueBtnDel}
                                >
                                  <img src={DeleteBtn} alt="img" />
                                </Button>
                              ) : (
                                <></>
                              )}
                            </Box>
                          );
                        })}

                        <Box mt="20px" />
                        <JBButton
                          onClick={() => createMcqOption(index)}
                          type="outlined"
                        >
                          <AddIcon
                            className={classes.BtnAddIcon}
                            fontSize="small"
                          />
                          add an answer
                        </JBButton>
                        <Box mt="25px" />
                        <Box>
                          <FormControlLabel
                            control={
                              <IOSSwitch
                                checked={
                                  data.questionnaire_meta[index]
                                    .allow_multiple_ans
                                }
                                onChange={() => {
                                  const newArray = [...data.questionnaire_meta];
                                  newArray[index] = {
                                    ...newArray[index],
                                    allow_multiple_ans:
                                      !data.questionnaire_meta[index]
                                        .allow_multiple_ans * 1
                                  };
                                  setData({
                                    ...data,
                                    questionnaire_meta: newArray
                                  });
                                }}
                              />
                            }
                            style={{ display: 'inline-block', margin: '0 0' }}
                          />
                          <InputLabel
                            htmlFor="age-required"
                            className={classes.cutom_Switch_Label}
                          >
                            Allow multiple answers
                          </InputLabel>
                        </Box>
                        <Box>
                          <FormControlLabel
                            control={
                              <IOSSwitch
                                checked={
                                  data.questionnaire_meta[index].required
                                }
                                onChange={() => {
                                  const newArray = [...data.questionnaire_meta];
                                  newArray[index] = {
                                    ...newArray[index],
                                    required:
                                      !data.questionnaire_meta[index].required *
                                      1
                                  };
                                  setData({
                                    ...data,
                                    questionnaire_meta: newArray
                                  });
                                }}
                              />
                            }
                            style={{ display: 'inline-block', margin: '0 0' }}
                          />
                          <InputLabel
                            htmlFor="age-required"
                            className={classes.cutom_Switch_Label}
                          >
                            Required
                          </InputLabel>
                        </Box>
                      </Box>
                    );
                  }
                  if (type.que_type === 'Yes/No with follow-up') {
                    return (
                      <Box className={classes.QuestionBox}>
                        <Box className={classes.QueBtnWrapper}>
                          <h5>Yes/No with follow-up</h5>
                          <Box className={classes.QueBtnBk}>
                            <Button onClick={() => handleUp(type.id)}>
                              <Btnup />
                            </Button>
                            <Button onClick={() => handleDown(type.id)}>
                              <Btndown />
                            </Button>
                            <Button onClick={() => handleDelete(type.id)}>
                              <Btnclose />
                            </Button>
                          </Box>
                        </Box>
                        <Box>
                          <FormControl
                            control="input"
                            label="Type a question"
                            value={type.que}
                            id={`waiversfieldform${type.id}`}
                            onBlur={() => handleBlur(type.id)}
                            error={Boolean(
                              type.touchedfield && checkValidString(type.que)
                            )}
                            helperText={
                              type.touchedfield && checkValidString(type.que)
                                ? 'Please enter valid question'
                                : null
                            }
                            onChange={(e) => {
                              const newArray = [...data.questionnaire_meta];
                              newArray[index] = {
                                ...newArray[index],
                                que: e.target.value
                              };
                              setData({
                                ...data,
                                questionnaire_meta: newArray
                              });
                            }}
                            placeholder=""
                            style={{ margin: '0 0' }}
                            maxLength={500}
                            seterrors={!type.que}
                          />
                        </Box>
                        <Box>
                          <FormControl
                            control="input"
                            label="Type a follow-up question"
                            placeholder="Type answer here"
                            value={type.follow_up}
                            id={`waiversfieldform${type.id}`}
                            onBlur={() => handleBlur(type.id)}
                            error={Boolean(
                              type.touchedfield &&
                                checkValidString(type.follow_up)
                            )}
                            helperText={
                              type.touchedfield &&
                              checkValidString(type.follow_up)
                                ? 'Please enter valid FollowUps'
                                : null
                            }
                            onChange={(e) => {
                              const newArray = [...data.questionnaire_meta];
                              newArray[index] = {
                                ...newArray[index],
                                follow_up: e.target.value
                              };
                              setData({
                                ...data,
                                questionnaire_meta: newArray
                              });
                            }}
                            style={{ margin: '15px 0 0' }}
                            maxLength={500}
                            seterrors={!type.follow_up}
                          />
                        </Box>
                        <Box mt="25px" />
                        <Box>
                          <FormControlLabel
                            control={
                              <IOSSwitch
                                checked={
                                  data.questionnaire_meta[index].required
                                }
                                onChange={() => {
                                  const newArray = [...data.questionnaire_meta];
                                  newArray[index] = {
                                    ...newArray[index],
                                    required:
                                      !data.questionnaire_meta[index].required *
                                      1
                                  };
                                  setData({
                                    ...data,
                                    questionnaire_meta: newArray
                                  });
                                }}
                              />
                            }
                            style={{ display: 'inline-block', margin: '0 0' }}
                          />
                          <InputLabel
                            htmlFor="age-required"
                            className={classes.cutom_Switch_Label}
                          >
                            Required
                          </InputLabel>
                        </Box>
                      </Box>
                    );
                  }
                  return null;
                })
              ) : (
                <></>
              )}
            </Grid>
            <Box
              css={{
                marginTop: `${
                  data.questionnaire_meta.length > 0 ? '20px' : '0px'
                }`
              }}
            />
            <Grid item xs={12}>
              <Box className={classes.AddQuestion}>
                <InputLabel
                  htmlFor="age-required"
                  className={classes.cutom_select_Label}
                >
                  Add question
                </InputLabel>

                <CustomAutoComplete
                  holder={que || 'Add question type'}
                  data={que}
                  value={AllQue}
                  Options="name"
                  Change={(e) => {
                    // eslint-disable-next-line no-unused-expressions
                    e ? setQue(e.name) : setQue(null);
                  }}
                />
              </Box>
              <JBButton onClick={handleQue} type="primary">
                Add
              </JBButton>
            </Grid>
          </Grid>
        </Grid>
        <Box mt="20px" />
        <Grid container direction="row" className={classes.Cardwrapper}>
          <Grid item xs={3}>
            <h3 className={classes.headingTitle}>Email Template</h3>
            <p className={clsx(classes.contrP, classes.mpP)}>
              Customize the email template that will be sent to the members.
            </p>
          </Grid>
          <Grid item xs={9}>
            <FormControl
              control="input"
              label="Email subject"
              placeholder=""
              style={{ margin: '0 0' }}
              value={data.email_template.subject}
              onChange={(e) => {
                setData({
                  ...data,
                  email_template: {
                    ...data.email_template,
                    subject: e.target.value
                  }
                });
              }}
            />
            <Box mt="15px" />
            {/* <TextareaControl
              value={data.email_template.body}
              onChange={(e) => {
                setData({
                  ...data,
                  email_template: {
                    ...data.email_template,
                    body: e.target.value
                  }
                });
              }}
              inputProps={{
                maxlength: 1000
              }}
              rows={8}
              label="Text"
              className={classes.TextareaControlBox}
            /> */}
            <InputLabel
              htmlFor="age-required"
              className={classes.cutom_select_Label}
            >
              Text
            </InputLabel>
            <JBCKEditor
              data={data.email_template.body}
              onChange={(e) => {
                setData({
                  ...data,
                  email_template: {
                    ...data.email_template,
                    body: e.editor.getData()
                  }
                });
              }}
            />
          </Grid>
        </Grid>

        <Box mt="20px" />
        <Grid container direction="row">
          <Grid item xs={3} />
          <Grid item xs={9}>
            <Grid container direction="row">
              <Grid item xs={12}>
                <Box mt="30px">
                  <p className={clsx(classes.contrP)}>Visibility</p>
                  <Grid item xs={4}>
                    <RadioGroup
                      value={String(data.is_published)}
                      onChange={(e) => {
                        setData({ ...data, is_published: e.target.value * 1 });
                      }}
                      style={{
                        display: 'inline-block'
                      }}
                    >
                      <FormControlLabel
                        value="1"
                        control={<Radio color="primary" />}
                        label="Published"
                      />
                      <FormControlLabel
                        value="0"
                        control={<Radio color="primary" />}
                        label="Draft (not available for members to sign)"
                      />
                    </RadioGroup>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container direction="row" justify="flex-end" alignItems="center">
          <CancelDialog />

          {data.is_published && data.total_signed_users !== 0 ? (
            <UpdateWaiversDialog
              values={data}
              handleStatus={(id) => {
                setData({ ...data, need_resign: id });
              }}
              handleValidate={() => validateData()}
              handleSave={() => updateWaiver(data)}
            />
          ) : (
            <div style={{ marginLeft: '10px' }}>
              <JBButton type="primary" onClick={() => updateWaiver(data)}>
                Save waiver
              </JBButton>
            </div>
          )}
        </Grid>
        <CancelDialogTwo
          setOpen={cancelDialog}
          handleHistory={ROUTES.DOCUMENTS_WAIVERS_TEMPLATES}
          handleClose={() => setcancelDialog(false)}
        />
      </Card>
    </div>
  );
};

WaiversTemplates.propTypes = {
  classes: PropType.object.isRequired,
  WaivFilter: PropType.func.isRequired,
  openNotify: PropType.func.isRequired,
  location: PropType.object.isRequired,
  getGymSteps: PropType.func.isRequired,
  gymSetup: PropType.number.isRequired
};
const mapToState = (state) => ({
  gymSetup: state.GymSetupReducer.data
});
const mapToDispatch = (dispatch) => ({
  openNotify: (msg, type) => dispatch(openNoti(msg, type)),
  WaivFilter: (filter, data) => dispatch(getTempWav(filter, data)),
  getGymSteps: () => dispatch(getGymSetup())
});
export default connect(
  mapToState,
  mapToDispatch
)(withStyles(styles)(WaiversTemplates));
