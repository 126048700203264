import React, { useState, useContext } from 'react';
// import PropType from 'prop-types';
import { VanguardWaiverCtx } from 'src/Context/VanguardWaiverContext';
import { setQuestionnaire } from 'src/Context/VanguardWaiver/VanguardWaiver.actions';
// import clsx from 'clsx';
import {
  Typography,
  makeStyles,
  Grid,
  RadioGroup,
  Radio,
  FormControlLabel,
  Box,
  Checkbox
} from '@material-ui/core';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckIcon from '@material-ui/icons/Check';
import TextareaControl from 'src/views/common/FormControl/TextareaControl';

const useStyles = makeStyles(() => ({
  mainTitle: {
    fontWeight: '500',
    fontSize: '48px',
    lineHeight: '44px',
    color: '#FFFFFF',
    fontFamily: 'DDC Hardware',
    textAlign: 'center',
    margin: '38px 0 0 0',
    '@media (max-width: 992px)': {
      fontSize: '35px'
    }
  },

  VanCustomcheckbox: {
    '& .MuiFormControlLabel-root': {
      background: 'rgba(196, 196, 196, 0.05)',
      border: '2px solid #7B7B7B',
      borderRadius: '6px',
      height: '70px',
      margin: '0 0 15px',
      display: 'flex'
    },
    '& .MuiFormControlLabel-root.checked': {
      background: 'rgba(44, 233, 218, 0.15)',
      border: '2px solid #2CE9DA',
      borderRadius: '6px'
    },
    '& .MuiFormControlLabel-root svg.MuiSvgIcon-root': {
      width: '30px',
      height: '30px',
      fill: 'none',
      borderRadius: '6px',
      border: '2px solid #4B4B4B'
    },
    '& .MuiFormControlLabel-root.checked svg.MuiSvgIcon-root': {
      background: '#67e9da',
      padding: '4px',
      fill: '#000',
      color: '#060606',
      border: '0px solid #4B4B4B'
    },
    '& .MuiCheckbox-root': {
      padding: '20px'
    },
    '& .MuiFormControlLabel-label': {
      color: '#fff',
      fontSize: '20px',
      lineHeight: '26px',
      fontWeight: 'bold'
    }
  },
  customRadio: {
    '& .MuiFormControlLabel-root': {
      background: 'rgba(196, 196, 196, 0.05)',
      border: '2px solid #7B7B7B',
      borderRadius: '6px',
      height: '70px',
      margin: '0 0 15px'
    },
    '& .MuiFormControlLabel-root.checked': {
      background: 'rgba(44, 233, 218, 0.15)',
      border: '2px solid #2CE9DA',
      borderRadius: '6px'
    },
    '& .MuiFormControlLabel-root svg.MuiSvgIcon-root': {
      width: '30px',
      height: '30px',
      fill: 'none',
      borderRadius: '50%',
      border: '2px solid #4B4B4B'
    },
    '& .MuiFormControlLabel-root.checked svg.MuiSvgIcon-root': {
      background: '#67e9da',
      padding: '4px',
      fill: '#000',
      color: '#060606',
      border: '0px solid #4B4B4B'
    },
    '& .MuiRadio-root': {
      padding: '20px'
    },
    '& .MuiFormControlLabel-label': {
      color: '#fff',
      fontSize: '20px',
      lineHeight: '26px',
      fontWeight: 'bold'
    }
  },
  QnTitle: {
    color: '#fff',
    fontSize: '28px',
    lineHeight: '38px',
    fontWeight: 'bold',
    margin: '0 0 20px'
  },
  TextareaControlBox: {
    '& .MuiInput-root': {
      borderRadius: '6px',
      border: '2px solid #C8C8C8',
      margin: '0 0 0 !important'
    },
    '& textarea': {
      color: '#fff'
    },
    '& textarea::placeholder': {
      color: '#fff !important',
      fontWeight: '500',
      fontSize: '16px',
      opacity: '0.5'
    }
  },
  ScaleBox: {
    '& .MuiFormControlLabel-root': {
      width: '150px',
      margin: '0 16px 16px 0'
    }
  },
  DescTitle: {
    color: '#fff',
    fontSize: '20px',
    fontWeight: 400
  }
}));

const Questionnaire = () => {
  const classes = useStyles();
  const [state, dispatch] = useContext(VanguardWaiverCtx);
  const {
    personal_info: { questionnaire_meta }
  } = state;

  const [selected, setSelected] = useState([]);

  const handleMultipleChoiceClick = (name, index) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
    const newArray = [...questionnaire_meta];

    newArray[index] = {
      ...newArray[index],
      ans: newSelected
    };
    dispatch(setQuestionnaire(newArray));
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  return (
    <div>
      <Typography variant="h3" className={classes.mainTitle}>
        questionnaire
      </Typography>
      <Grid
        container
        xs={10}
        justify="center"
        style={{
          margin: '45px auto'
        }}
      >
        {/* eslint-disable */}
        {questionnaire_meta.length > 0 ? (
          questionnaire_meta.map((item, index) => {
            if (item.que_type == 'Multiple Choice') {
              if (item.allow_multiple_ans) {
                return (
                  <Grid
                    xs={12}
                    style={{
                      margin: '50px 0 0'
                    }}
                  >
                    <Box>
                      <h6 className={classes.QnTitle}>
                        {item.que}
                        {item.required ? '*' : ''}
                      </h6>
                    </Box>
                    <Box mt="10px" className={classes.VanCustomcheckbox}>
                      {item.answer_choices.map((item1) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={isSelected(item1.id)}
                              //  onChange={() => setcheck(!check)}
                              onClick={() =>
                                handleMultipleChoiceClick(item1.id, index)
                              }
                              name="checkedB"
                              color="primary"
                              checkedIcon={
                                <CheckIcon
                                  htmlColor="#2CE9DA"
                                  width="30px"
                                  height="30px"
                                />
                              }
                            />
                          }
                          label={item1.value}
                          className={`${
                            isSelected(item1.id) === true ? 'checked' : ''
                          }`}
                        />
                      ))}
                    </Box>
                  </Grid>
                );
              }

              return (
                <Grid
                  xs={12}
                  style={{
                    margin: '50px 0 0'
                  }}
                >
                  <Box>
                    <h6 className={classes.QnTitle}>
                      {item.que}
                      {item.required ? '*' : ''}
                    </h6>
                  </Box>
                  <RadioGroup
                    value={item.ans || null}
                    onChange={(e) => {
                      const newArray = [...questionnaire_meta];

                      newArray[index] = {
                        ...newArray[index],
                        ans: Number(e.target.value)
                      };
                      dispatch(setQuestionnaire(newArray));
                    }}
                    className={classes.customRadio}
                  >
                    {item.answer_choices.map((item1) => (
                      <FormControlLabel
                        value={item1.id}
                        control={
                          <Radio
                            color="primary"
                            checkedIcon={
                              <CheckIcon
                                htmlColor="#2CE9DA"
                                width="30px"
                                height="30px"
                              />
                            }
                            icon={
                              <RadioButtonUncheckedIcon
                                width="30px"
                                height="30px"
                              />
                            }
                          />
                        }
                        label={item1.value}
                        // checked
                        className={`${item.ans === item1.id ? 'checked' : ''}`}
                      />
                    ))}
                  </RadioGroup>
                </Grid>
              );
            }
            if (item.que_type === 'Yes/No') {
              return (
                <Grid
                  xs={12}
                  style={{
                    margin: '50px 0 0'
                  }}
                >
                  <Box>
                    <h6 className={classes.QnTitle}>
                      {item.que}
                      {item.required ? '*' : ''}
                    </h6>
                  </Box>
                  <RadioGroup
                    value={item.ans || null}
                    onChange={(e) => {
                      const newArray = [...questionnaire_meta];
                      newArray[index] = {
                        ...newArray[index],
                        ans: Number(e.target.value)
                      };
                      dispatch(setQuestionnaire(newArray));
                    }}
                    className={classes.customRadio}
                  >
                    <FormControlLabel
                      value="1"
                      control={
                        <Radio
                          color="primary"
                          checkedIcon={
                            <CheckIcon
                              htmlColor="#2CE9DA"
                              width="30px"
                              height="30px"
                            />
                          }
                          icon={
                            <RadioButtonUncheckedIcon
                              width="30px"
                              height="30px"
                            />
                          }
                        />
                      }
                      label="Yes"
                      // checked
                      className={`${item.ans === 1 ? 'checked' : ''}`}
                    />
                    <FormControlLabel
                      value="0"
                      control={
                        <Radio
                          color="primary"
                          icon={
                            <RadioButtonUncheckedIcon
                              width="30px"
                              height="30px"
                            />
                          }
                          checkedIcon={
                            <CheckIcon
                              htmlColor="#2CE9DA"
                              width="30px"
                              height="30px"
                            />
                          }
                          style={{}}
                        />
                      }
                      label="No"
                      className={`${item.ans === 0 ? 'checked' : ''}`}
                    />
                  </RadioGroup>
                </Grid>
              );
            }

            if (item.que_type == 'Yes/No with follow-up') {
              return (
                <>
                  <Grid
                    xs={12}
                    style={{
                      margin: '50px 0 0'
                    }}
                  >
                    <Box>
                      <h6 className={classes.QnTitle}>
                        {item.que}
                        {item.required ? '*' : ''}
                      </h6>
                    </Box>
                    <RadioGroup
                      value={item.ans || null}
                      onChange={(e) => {
                        const newArray = [...questionnaire_meta];
                        newArray[index] = {
                          ...newArray[index],
                          ans: Number(e.target.value)
                        };
                        dispatch(setQuestionnaire(newArray));
                      }}
                      className={classes.customRadio}
                    >
                      <FormControlLabel
                        value="1"
                        control={
                          <Radio
                            color="primary"
                            checkedIcon={
                              <CheckIcon
                                htmlColor="#2CE9DA"
                                width="30px"
                                height="30px"
                              />
                            }
                            icon={
                              <RadioButtonUncheckedIcon
                                width="30px"
                                height="30px"
                              />
                            }
                          />
                        }
                        label="Yes"
                        // checked
                        className={`${item.ans === 1 ? 'checked' : ''}`}
                      />
                      <FormControlLabel
                        value="0"
                        control={
                          <Radio
                            color="primary"
                            icon={
                              <RadioButtonUncheckedIcon
                                width="30px"
                                height="30px"
                              />
                            }
                            checkedIcon={
                              <CheckIcon
                                htmlColor="#2CE9DA"
                                width="30px"
                                height="30px"
                              />
                            }
                            style={{}}
                          />
                        }
                        label="No"
                        className={`${item.ans === 0 ? 'checked' : ''}`}
                      />
                    </RadioGroup>
                  </Grid>
                  <Grid
                    xs={12}
                    style={{
                      margin: '50px 0 0'
                    }}
                  >
                    <Box>
                      <h6 className={classes.QnTitle}>
                        {item.follow_up} {item.required ? '*' : ''}
                      </h6>
                    </Box>
                    <TextareaControl
                      value={item.followup_ans || ''}
                      onChange={(e) => {
                        const newArray = [...questionnaire_meta];

                        newArray[index] = {
                          ...newArray[index],
                          followup_ans: e.target.value
                        };
                        dispatch(setQuestionnaire(newArray));
                      }}
                      rows={8}
                      label=""
                      className={classes.TextareaControlBox}
                    />
                  </Grid>
                </>
              );
            }

            if (item.que_type == 'Freeform Answer Box') {
              return (
                <Grid
                  xs={12}
                  style={{
                    margin: '50px 0 0'
                  }}
                >
                  <Box>
                    <h6 className={classes.QnTitle}>
                      {item.que} {item.required ? '*' : ''}
                    </h6>
                  </Box>
                  <TextareaControl
                    value={item.ans || ''}
                    onChange={(e) => {
                      const newArray = [...questionnaire_meta];

                      newArray[index] = {
                        ...newArray[index],
                        ans: e.target.value
                      };
                      dispatch(setQuestionnaire(newArray));
                    }}
                    rows={8}
                    label=""
                    className={classes.TextareaControlBox}
                  />
                </Grid>
              );
            }
          })
        ) : (
          <Box className={classes.DescTitle}>
            There is no questionnaire for this waiver
          </Box>
        )}
        {/* eslint-enable */}
      </Grid>
    </div>
  );
};

Questionnaire.propTypes = {
  // setOpen: PropType.bool.isRequired,
  // handleClose: PropType.func.isRequired
  // handleBack: PropType.func,
  // handleHistory: PropType.string
};

export default Questionnaire;
