import React from 'react';
// import { ROUTES } from 'src/constant/Constants';
// import { Route, Switch } from 'react-router-dom';
import { makeStyles, Box, Dialog, DialogContent } from '@material-ui/core';
import PropTypes from 'prop-types';
import { ReactComponent as Successicon } from '../../assets/image/Vanguard/success_icon.svg';

const useStyles = makeStyles(() => ({
  successBox: {
    background: 'rgba(29, 29, 29, 0.94)',
    position: 'absolute',
    left: '0',
    right: '0',
    top: '0',
    height: '100%',
    width: '100%',
    margin: '0 auto',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    zIndex: '9999',
    // background: rgb(43 43 43 / 0.95);
    // opacity: 0.95;
    justifyContent: 'center'
  },
  successText: {
    color: '#fff',
    marginTop: '31px',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '32px',
    lineHeight: '42px'
  },
  DialogBox: {
    backgroundColor: 'transparent',
    '& .MuiDialog-paper': {
      minWidth: '100%',
      minHeight: '100%',
      backgroundColor: 'transparent'
    }
  }
}));

const SuccessPopup = (props) => {
  const { Dialogtitle, handleClose, setOpen } = props;
  const classes = useStyles();
  return (
    <Dialog
      fullScreen
      open={setOpen}
      onClose={() => handleClose()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.DialogBox}
    >
      <DialogContent
        style={{
          minWidth: '100%',
          minHeight: '100%',
          backgroundColor: 'transparent'
        }}
      >
        <div className={classes.successBox}>
          <Box>
            <Successicon />
            <h3 className={classes.successText}>{Dialogtitle}</h3>
          </Box>
        </div>
      </DialogContent>
    </Dialog>
  );
};

SuccessPopup.defaultProps = {
  Dialogtitle: 'created successfully'
};

SuccessPopup.propTypes = {
  Dialogtitle: PropTypes.string,
  setOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired
};
export default SuccessPopup;
