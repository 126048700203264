/* eslint-disable indent */
import React, { useEffect } from 'react';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, Hidden, ListItem, Popover } from '@material-ui/core';
import clsx from 'clsx';
import { get } from 'js-cookie';
import { ROUTES } from 'src/constant/Constants';
import { ReactComponent as Admin } from '../../../../assets/image/admin.svg';
import { ReactComponent as Chalkboardicon } from '../../../../assets/image/chalkboard.svg';
import { ReactComponent as OtherIcon } from '../../../../assets/image/otherIcon.svg';
import { ReactComponent as AffiliateHub } from '../../../../assets/image/AffiliateHub.svg';
import { ReactComponent as MasterHQ } from '../../../../assets/image/MasterHQ.svg';
import { ReactComponent as Vanguard } from '../../../../assets/image/V.svg';
import useStyles from '../navBar.style';

const DashboardNav = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const roleid = JSON.parse(get('jbrole') || '{}')?.role_id || 3;
  const [anchorEl, setAnchorEl] = React.useState(null);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    if (anchorEl) {
      setAnchorEl(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  // const CustomRouterLink = forwardRef((props, ref) => (
  //   <div ref={ref}>
  //     <RouterLink {...props} />
  //   </div>
  // ));

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };
  const miniMenu = localStorage.getItem('mini-menu');
  const open = Boolean(anchorEl);
  const id = open ? 'other-popover' : undefined;
  const isMaster = Boolean(JSON.parse(get('jbrole') || 'false')?.master);
  return (
    <div>
      <Hidden xsDown>
        <div
          className={clsx(
            'nav-end',
            miniMenu === 'false' ? 'miniMenu-wrapper' : null
          )}
          style={{
            display: window.location.pathname.includes('vanguard')
              ? 'none'
              : 'inline-flex',
            background: window.location.pathname.includes('masterhq')
              ? miniMenu === 'false'
                ? 'transparent'
                : '#27273b'
              : null
          }}
        >
          <ListItem className={classes.nested}>
            <Button
              activeClassName={classes.myactive}
              className={classes.button}
              component={RouterLink}
              to={
                // eslint-disable-next-line operator-linebreak
                !window.location.pathname.includes('chalkboard') &&
                !window.location.pathname.includes('masterhq')
                  ? ''
                  : roleid <= 2
                  ? ROUTES.WORKOUT_LIST
                  : ROUTES.CLASSES_LIST
              }
            >
              <Admin />
              Admin
            </Button>
          </ListItem>
          <ListItem className={classes.nested}>
            <Button
              activeClassName={classes.myactive}
              className={classes.button}
              component={RouterLink}
              to={ROUTES.CHALKBOARD}
              style={{
                fontFamily: 'Lato',
                fontWeight: 'bold',
                fontSize: '12px',
                lineHeight: '16px',
                padding: '10px 2px',
                display: 'block'
              }}
            >
              <Chalkboardicon />
              Chalkboard
            </Button>
          </ListItem>

          <ListItem className={classes.nested}>
            <Button
              activeClassName={classes.myactive}
              className={classes.button}
              component={RouterLink}
              to={ROUTES.VANGUARD}
              style={{
                fontFamily: 'Lato',
                fontWeight: 'bold',
                fontSize: '12px',
                lineHeight: '16px',
                padding: '10px 2px',
                display: 'block'
              }}
            >
              <Vanguard />
              Vanguard
            </Button>
          </ListItem>
          {isMaster && (
            <ListItem className={classes.nested}>
              <Button
                activeclassname={classes.myactive}
                className={clsx(
                  classes.button,
                  window.location.pathname.includes('masterhq')
                    ? classes.myactive
                    : ''
                )}
                // component={CustomRouterLink}
                // to="/#"
                style={{
                  fontFamily: 'Lato',
                  fontWeight: 'bold',
                  fontSize: '12px',
                  lineHeight: '16px',
                  padding: '10px 2px',
                  display: 'block'
                }}
                aria-describedby={id}
                onClick={handleClick}
              >
                <OtherIcon />
                Other
              </Button>
            </ListItem>
          )}

          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            className={clsx(
              classes.othermenuList,
              miniMenu === 'false' ? 'miniMenu-wrapper-open' : null,
              window.location.pathname.includes('masterhq')
                ? 'HQ-othermenuList'
                : null
            )}
            elevation={0}
            // elevation="0"
          >
            <div
              className={clsx(
                classes.othermenu,
                'sub-othermenu',
                window.location.pathname.includes('masterhq')
                  ? 'HQ-othermenu'
                  : null
              )}
            >
              <ListItem
                className={clsx(
                  classes.nested,
                  'sub-nested-menu',
                  window.location.pathname.includes('masterhq')
                    ? 'HQ-sub-menu'
                    : null
                )}
                disabled
                style={{
                  pointerEvents: 'none'
                }}
              >
                <Button
                  activeClassName={clsx(classes.myactive, 'sub-active-menu')}
                  className={classes.button}
                  component={RouterLink}
                  to={ROUTES.ALLYHUB}
                  disabled
                >
                  <AffiliateHub />
                  Ally Hub
                </Button>
              </ListItem>
              <ListItem
                // className={clsx(classes.nested, 'sub-nested-menu')}
                className={clsx(
                  classes.nested,
                  'sub-nested-menu',
                  window.location.pathname.includes('masterhq')
                    ? 'HQ-sub-menu'
                    : null
                )}
              >
                <Button
                  activeClassName={clsx(classes.myactive, 'sub-active-menu')}
                  className={classes.button}
                  component={RouterLink}
                  to={ROUTES.MASTER_HQ_AFFILIATES}
                >
                  <MasterHQ />
                  Master HQ
                </Button>
              </ListItem>
            </div>
          </Popover>
        </div>
      </Hidden>
    </div>
  );
};

DashboardNav.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

DashboardNav.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

export default DashboardNav;
