/* eslint-disable indent */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
// import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { openNoti } from 'src/Redux/global/global.actions';
import { getErrorMsg } from 'src/utils/ErrorHandling';
import { loginVanguardUser } from 'src/services/vanguard.services';
import {
  makeStyles,
  Box,
  Dialog,
  DialogContent,
  // FormControl,
  // InputLabel,
  // OutlinedInput,
  InputAdornment,
  IconButton,
  Button
} from '@material-ui/core';
import { getCurrentUser } from 'src/services/user.service';
import PropTypes from 'prop-types';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import FormControl from 'src/views/common/FormControl/formControl';
import { ReactComponent as CloseIcon } from '../../assets/image/Vanguard/icon_close_vanguard.svg';
import { ReactComponent as LockIcon } from '../../assets/image/Vanguard/lock_icon.svg';
import JBVanguardButton from '../common/JBVanguardButton/JBButton';

const useStyles = makeStyles(() => ({
  successBox: {
    background: 'rgba(29, 29, 29, 0.94)',
    position: 'absolute',
    left: '0',
    right: '0',
    top: '0',
    height: '100%',
    width: '100%',
    margin: '0 auto',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    zIndex: '9999',
    justifyContent: 'center'
  },
  successText: {
    color: '#fff',
    marginTop: '31px',
    marginBottom: '40px',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '32px',
    lineHeight: '42px'
  },
  DialogBox: {
    backgroundColor: 'transparent',
    '& .MuiDialog-paper': {
      minWidth: '100%',
      minHeight: '100%',
      backgroundColor: 'transparent'
    }
  },
  subText: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 'normal',
    color: '#fff',
    fontSize: '20px',
    lineHeight: '32px',
    marginTop: '8px'
  },
  cutom_vanguard_FormControl: {
    borderRadius: '6px',
    background: 'rgba(196, 196, 196, 0.05)',
    border: '2px solid #C8C8C8',
    '& .fd-input input': {
      color: '#fff',
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '20px',
      height: '45px',
      border: '0px solid #C8C8C8',
      padding: '8px 20px 8px',
      '&::placeholder ': {
        color: '#fff',
        fontWeight: 'bold',
        fontSize: '15px'
      }
    },
    '& .MuiIconButton-root': {
      padding: '20px'
    }
  },
  btnClose: {
    position: 'fixed',
    zIndex: '9999',
    padding: '20px',
    top: '0',
    right: '0'
  }
}));

const EnterPassword = (props) => {
  const dispatch = useDispatch();
  const {
    Dialogtitle,
    handleClose,
    setOpen,
    handleHistory,
    handleBack
  } = props;
  const history = useHistory();
  const classes = useStyles();
  const [email, setEmail] = useState('');

  const [values, setValues] = useState({
    password: '',
    showPassword: false
  });

  const [showcurrent, setshowcurrent] = useState(false);

  useEffect(() => {
    getCurrentUser().then((response) => setEmail(response.email));
  }, []);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleContinue = () => {
    if (values.password.length === 0) {
      return dispatch(openNoti('Please enter a password', 'error'));
    }
    return loginVanguardUser({
      email,
      password: values.password
    })
      .then(() => {
        return handleBack
          ? handleBack()
          : handleHistory
          ? history.push(`${handleHistory}`)
          : history.goBack();
      })
      .catch((error) => dispatch(openNoti(getErrorMsg(error), 'error')));
  };

  return (
    <>
      {/* <Button onClick={() => handleClose()}>close</Button> */}
      {setOpen && (
        <Button
          onClick={() => {
            handleClose();
            setValues({ password: '', showPassword: false });
          }}
          className={classes.btnClose}
        >
          <CloseIcon />
        </Button>
      )}
      <Dialog
        fullScreen
        open={setOpen}
        onClose={() => handleClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.DialogBox}
      >
        <DialogContent
          style={{
            minWidth: '100%',
            minHeight: '100%',
            backgroundColor: 'transparent'
          }}
        >
          <div className={classes.successBox}>
            <Box>
              <LockIcon />
              <h3 className={classes.successText}>{Dialogtitle}</h3>
              {/* <p className={classes.subText}>Please enter password</p> */}
              <Box className={classes.cutom_vanguard_FormControl}>
                <FormControl
                  control="input"
                  label=""
                  name="password"
                  // error={Boolean(touched.password && errors.password)}
                  // helperText={touched.password && errors.password}
                  // onBlur={handleBlur}
                  // onChange={handleChange}
                  type={showcurrent ? 'text' : 'password'}
                  value={values.password}
                  onChange={handleChange('password')}
                  autoComplete="current-password"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setshowcurrent(!showcurrent)}
                        >
                          {showcurrent ? (
                            <Visibility htmlColor="#aeadad" />
                          ) : (
                            <VisibilityOff htmlColor="#aeadad" />
                          )}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  maxLength={20}
                  style={{
                    margin: '0 0'
                  }}
                />
              </Box>
              <JBVanguardButton
                type="outlined"
                style={{
                  marginTop: '32px',
                  width: '250px',
                  minHeight: '70px'
                }}
                onClick={() => {
                  handleClose();
                  setValues({ password: '', showPassword: false });
                }}
              >
                Cancel
              </JBVanguardButton>
              <JBVanguardButton
                type="primary"
                style={{
                  marginTop: '32px',
                  marginLeft: '15px',
                  width: '250px',
                  minHeight: '70px'
                }}
                onClick={handleContinue}
              >
                Continue
              </JBVanguardButton>
            </Box>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

EnterPassword.defaultProps = {
  Dialogtitle: 'Are you sure?',
  handleHistory: null,
  handleBack: null
};

EnterPassword.propTypes = {
  Dialogtitle: PropTypes.string,
  setOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleBack: PropTypes.func,
  handleHistory: PropTypes.string
};

export default EnterPassword;
