// Do not update this text give by BE
const bodydata = `
<p>

Hi [memfirstname],

<br/>

<br/>

Welcome to [gymname]! We're so happy you are joining our community :).

<br/>

<br/>

Before you get started, you'll need to sign a membership contract. This contract is the terms and conditions of our gym. Please contact any of our coaches for the contract signing.

<br/>

<br/>

Once that's complete, you're ready to jump into classes.

<br/>

<br/>

Please reply directly to this email if you have any questions or concerns or call us on [gymphone].

<br/>

<br/>

See you in the Jungle!

<br/>

<br/>

[signature]

<br/>

[gymname]

</p>
`;

export const dummyDataNewContract = {
  name: null,
  membership_policy: { policy: null, initial: 1 },
  email_template: {
    subject: 'Welcome! Please sign your contract',
    body: bodydata
  },
  membership_ids: [],
  store_payment_info: 1,
  visibility: 1
};
