import React, { useState, useEffect, useContext, useMemo } from 'react';
import { VanguardWaiverCtx } from 'src/Context/VanguardWaiverContext';
import {
  setIsLoading,
  toggleSignStatus,
  setSignInitials,
  setQuestionnaire,
  setWaiverInitialBox
} from 'src/Context/VanguardWaiver/VanguardWaiver.actions';
// import PropType from 'prop-types';
import { getEditWaiver } from 'src/services/Contracts.services';
import {
  Typography,
  makeStyles,
  Grid,
  // RadioGroup,
  // Radio,
  FormControlLabel,
  Checkbox,
  Box,
  InputLabel
} from '@material-ui/core';
import VanguardTextLoadingView from 'src/views/common/loading/VanguardTextLoadingView';
import DOMPurify from 'dompurify';
// import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckIcon from '@material-ui/icons/Check';
import FormControl from '../../common/FormControl/formControl';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    background: 'rgba(29, 29, 29, 0.94)',
    boxShadow: '0px 0.33333px 0px rgb(255 255 255 / 15%)',
    backdropFilter: 'blur(54.3656px)'
  },
  appToolbar: {
    minHeight: '75px',
    justifyContent: 'space-between'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
  mainTitle: {
    fontWeight: '500',
    fontSize: '48px',
    lineHeight: '44px',
    color: '#FFFFFF',
    fontFamily: 'DDC Hardware',
    textAlign: 'center',
    margin: '38px 0 0 0',
    '@media (max-width: 992px)': {
      fontSize: '35px'
    }
  },
  MainCard: {
    height: 'calc(100vh - 75px )',
    background:
      'linear-gradient(180deg, rgba(41, 41, 41, 0.95) 0%, rgba(4, 4, 4, 0.95) 100%)',
    borderRadius: '0',
    flex: '1 1 auto',
    // height: '100%',
    overflow: 'auto'
  },
  HomeButton: {
    fontFamily: 'Lato',
    color: '#2CE9DA',
    fontWeight: 'bold',
    fontSize: '22px',
    lineHeight: '32px',
    textTransform: 'capitalize'
  },
  cutom_select_Label: {
    fontFamily: 'DDC Hardware',
    fontStyle: 'normal',
    fontWeight: '500;',
    fontSize: '22px',
    lineHeight: '32px',
    letterSpacing: '0.2px',
    color: '#FFFFFF',
    '&:focus ': {
      outline: '0'
    }
  },
  cutom_vanguard_FormControl: {
    '& .fd-input input': {
      background: 'rgba(196, 196, 196, 0.05)',
      color: '#fff',
      borderRadius: '6px',
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '20px',
      height: '45px',
      border: '2px solid #C8C8C8',
      padding: '8px 20px 8px',
      '&::placeholder ': {
        color: '#fff',
        fontWeight: 'bold',
        fontSize: '15px'
      }
    }
  },
  cutom_vanguard_Autocomplete: {
    background: 'rgba(196, 196, 196, 0.05)',
    border: '2px solid #C8C8C8',
    color: '#fff',
    borderRadius: '6px',
    '& .cutom_select div': {
      minHeight: '62px'
    },
    '& .cutom_select div input ': {
      color: '#ffffff !important',
      fontWeight: 'bold',
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontSize: '20px'
    },
    '& .MuiAutocomplete-endAdornment': {
      minHeight: ' auto !important',
      top: 'calc(50% - 12px)'
    }
  },
  customRadio: {
    '& .MuiFormControlLabel-root': {
      background: 'rgba(196, 196, 196, 0.05)',
      border: '2px solid #7B7B7B',
      borderRadius: '6px',
      height: '70px',
      margin: '0 0 15px'
    },
    '& .MuiFormControlLabel-root.checked': {
      background: 'rgba(44, 233, 218, 0.15)',
      border: '2px solid #2CE9DA',
      borderRadius: '6px'
    },
    '& .MuiFormControlLabel-root svg.MuiSvgIcon-root': {
      width: '30px',
      height: '30px',
      fill: 'none',
      borderRadius: '50%',
      border: '2px solid #4B4B4B'
    },
    '& .MuiFormControlLabel-root.checked svg.MuiSvgIcon-root': {
      background: '#67e9da',
      padding: '4px',
      fill: '#000',
      color: '#060606',
      border: '0px solid #4B4B4B'
    },
    '& .MuiRadio-root': {
      padding: '20px'
    },
    '& .MuiFormControlLabel-label': {
      color: '#fff',
      fontSize: '20px',
      lineHeight: '26px',
      fontWeight: 'bold'
    }
  },
  VanCustomcheckbox: {
    '& .MuiFormControlLabel-root svg.MuiSvgIcon-root': {
      width: '30px',
      height: '30px',
      fill: 'none',
      borderRadius: '6px',
      border: '2px solid #4B4B4B'
    },
    '& .MuiFormControlLabel-root.checked svg.MuiSvgIcon-root': {
      background: '#67e9da',
      padding: '4px',
      fill: '#000',
      color: '#060606',
      border: '0px solid #4B4B4B'
    },
    '& .MuiTypography-root': {
      padding: '20px 15px'
    },
    '& .MuiFormControlLabel-label': {
      color: '#fff',
      fontSize: '20px',
      lineHeight: '26px',
      fontWeight: 'bold'
    }
  }
}));

const Terms = () => {
  const classes = useStyles();
  const [state, dispatch] = useContext(VanguardWaiverCtx);
  const {
    isLoading,
    selectedWaiver,
    signStatus,
    personal_info: {
      waiver_terms_initial: { sign_initials }
    }
  } = state;
  const [data, setData] = useState(); // eslint-disable-line

  useEffect(() => {
    dispatch(setIsLoading(true));
    getEditWaiver(selectedWaiver)
      .then((response) => {
        dispatch(setWaiverInitialBox(JSON.parse(response.waiver_terms_meta)));
        dispatch(setQuestionnaire(JSON.parse(response.questionnaire_meta)));
        setData(JSON.parse(response.waiver_terms_meta));
      })
      .finally(() => dispatch(setIsLoading(false)));
  }, [selectedWaiver, dispatch]);

  const PurifiedTerms = useMemo(() => {
    if (data?.terms) {
      return DOMPurify.sanitize(data.terms);
    }
    return null;
  }, [data]);

  const handleInputChange = (event) => {
    dispatch(setSignInitials(event.target.value));
  };
  const handleChange = (e) => e.preventDefault();
  return (
    <div>
      <Typography variant="h3" className={classes.mainTitle}>
        terms
      </Typography>
      <Grid
        container
        xs={10}
        justify="center"
        style={{
          margin: '45px auto'
        }}
      >
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={2}
          // style={{ margin: '20px 0 20px' }}
        >
          {isLoading ? (
            <VanguardTextLoadingView />
          ) : (
            <Grid xs={12}>
              <Box
                dangerouslySetInnerHTML={{ __html: PurifiedTerms }}
                style={{
                  fontSize: 'inherit',
                  color: '#fff'
                }}
              />
            </Grid>
          )}

          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            spacing={0}
            // style={{ margin: '20px 0 20px' }}
          >
            <Box mt="10px" className={classes.VanCustomcheckbox}>
              <FormControlLabel
                // onChange={(e) => {
                //   setData({ ...data, value: e.target.value });
                // }}
                control={
                  <Checkbox
                    checked={signStatus}
                    onChange={() => dispatch(toggleSignStatus())}
                    name="checkedB"
                    color="primary"
                    checkedIcon={
                      <CheckIcon
                        htmlColor="#2CE9DA"
                        width="30px"
                        height="30px"
                      />
                    }
                  />
                }
                label="I agree to these terms."
                className={`${signStatus ? 'checked' : ''}`}
              />
            </Box>
            {data?.initial ? (
              <Grid
                item
                className={classes.cutom_vanguard_FormControl}
                style={{
                  margin: '10px 0',
                  width: '185px'
                }}
              >
                <InputLabel
                  htmlFor="age-required"
                  className={classes.cutom_select_Label}
                  // style={{ margin: '0px 0 5px' }}
                >
                  INITIALS HERE
                </InputLabel>
                <FormControl
                  control="input"
                  value={sign_initials}
                  onChange={handleInputChange}
                  onCut={handleChange}
                  onPaste={handleChange}
                  onCopy={handleChange}
                  maxLength={3}
                  autoComplete="off"
                  style={{
                    margin: '0 0'
                  }}
                />
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

Terms.propTypes = {
  // setOpen: PropType.bool.isRequired,
  // handleClose: PropType.func.isRequired
  // handleBack: PropType.func,
  // handleHistory: PropType.string
};

export default Terms;
