/* eslint-disable indent */
/* eslint-disable no-irregular-whitespace */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Button, Card, Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Stepper2 from './Stepper2';

const styles = () => ({
  root: {
    width: '90%'
  },
  gridRoot: {
    background: 'white'
  },
  mainCard: {
    boxShadow: '4px 0px 16px rgba(37, 40, 43, 0.08)',
    borderRadius: '5px',
    background: '#FFFF',
    padding: 30,
    overflow: 'visible'
  },
  button: {
    background: '#01847A',
    color: '#FFF',
    padding: '12px 24px',
    boxShadow: '0px 8px 16px rgba(0, 126, 255, 0.16);',
    float: 'right;',
    fontWeight: '700',
    '&:hover': {
      backgroundColor: '#01847A'
    }
  },
  btn_previou: {
    color: '#01847a',
    border: '1px solid #01847a',
    minHeight: '48px',
    padding: '11px 16px',
    fontWeight: '700'
  }
});

const JBStepper2 = (props) => {
  const {
    activeStep,
    handleNext,
    handleBack,
    handleReset,
    classes,
    steps,
    handleStepContent,
    prevLabel,
    nextLabel,
    CustComp,
    defaultButton,
    validateBtn,
    isprocessing,
    processingLabel
  } = props;
  const [disabled, setDisabled] = useState(false);
  const handleClick = () => {
    setDisabled(true);
    setTimeout(() => setDisabled(false), 500);
    handleNext();
  };

  return (
    <Card className={classes.mainCard}>
      <Grid className={classes.gridRoot} container spacing={6}>
        <Grid item xs={3}>
          <Stepper2 steps={steps} activeStep={activeStep} IconComp={CustComp} />
        </Grid>
        <Grid item xs={9}>
          <Box className="sp-text" style={{ marginTop: '-15px' }}>
            ​{handleStepContent(activeStep)}
          </Box>
          {defaultButton ? null : (
            <div className="step-btn">
              {activeStep === steps.length ? (
                <div>
                  <Button
                    onClick={handleReset}
                    variant="contained"
                    color="primary"
                    className={classes.button}
                  >
                    Reset
                  </Button>
                </div>
              ) : (
                <div>
                  <div>
                    {activeStep > 0 && (
                      <Button
                        disabled={isprocessing || disabled}
                        onClick={handleBack}
                        className={classes.btn_previou}
                      >
                        Previous
                      </Button>
                    )}
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleClick}
                      className={classes.button}
                      disabled={
                        validateBtn[activeStep] || isprocessing || disabled
                      }
                      style={{
                        display: 'block'
                      }}
                    >
                      {/* eslint-disable-next-line no-nested-ternary */}
                      {isprocessing
                        ? processingLabel
                        : activeStep === steps.length - 1
                        ? prevLabel
                        : nextLabel}
                    </Button>
                  </div>
                </div>
              )}
            </div>
          )}
        </Grid>
      </Grid>
    </Card>
  );
};
JBStepper2.defaultProps = {
  handleReset: null,
  // OnCloseRequest: null,
  // shouldDisableBtn: false,
  // stepperContentMaxHeight: 700,
  // noGrid: false,
  defaultButton: false,
  isprocessing: false,
  processingLabel: 'Saving...',
  validateBtn: []
};

JBStepper2.propTypes = {
  classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  steps: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  activeStep: PropTypes.number.isRequired,
  handleNext: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  handleReset: PropTypes.func,
  handleStepContent: PropTypes.func.isRequired,
  prevLabel: PropTypes.string.isRequired,
  nextLabel: PropTypes.string.isRequired,
  CustComp: PropTypes.func.isRequired,
  // CustComp: PropTypes.element.isRequired,
  defaultButton: PropTypes.bool,
  validateBtn: PropTypes.array,
  isprocessing: PropTypes.bool,
  processingLabel: PropTypes.string
};

export default withStyles(styles)(JBStepper2);
