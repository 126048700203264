import authhttp from './authHttp.services';

export const getClassesForVanguard = async (values) => {
  const classUrl = 'api/getClassesForVanguard';
  const {
    data: { data }
  } = await authhttp.post(classUrl, values);
  return data;
};

export const quickCreateNewLead = async (values) => {
  const newUrlLead = 'api/quick_lead_add_for_vg';
  const { data } = await authhttp.post(newUrlLead, values);
  return data;
};
export const createVanguardUser = async (values) => {
  const userUrl = 'api/store_user_vanguard';
  const {
    data: { data }
  } = await authhttp.post(userUrl, values);
  return data;
};

export const createVanguardUserWaiver = async (values) => {
  const userUrl = 'api/store_user_vanguard_waiver';
  const {
    data: { data }
  } = await authhttp.post(userUrl, values);
  return data;
};

export const searchUserVanguard = async (search) => {
  const userUrl = 'api/search_user_by_phone_name_email';
  const {
    data: { data }
  } = await authhttp.post(userUrl, { search });
  return data;
};

export const assignWaiverToUserVanguard = async (values) => {
  const endpoint = 'api/assign_waiver_to_user_for_vg';
  const {
    data: { data }
  } = await authhttp.post(endpoint, values);
  return data;
};

export const updateUserWaiverVanguard = async (values, id) => {
  const waiverUrl = `api/update_user_waiver_vg/${id}`;
  const {
    data: { data }
  } = await authhttp.post(waiverUrl, values);
  return data;
};

export const getWaiverWithUser = async (id) => {
  const endpoint = `api/get_waiver_with_user/${id}`;
  const {
    data: { data }
  } = await authhttp.get(endpoint);
  return data;
};

export const loginVanguardUser = async (values) => {
  const loginUrl = 'api/login';
  const { data } = await authhttp.post(loginUrl, values);
  return data;
};
export const updateVanguardUser = async (values) => {
  const userUrl = 'api/update_user_vanguard';
  const {
    data: { data }
  } = await authhttp.post(userUrl, values);
  return data;
};
export const searchVanguardUser = async (values) => {
  const userUlr = 'api/search_for_active_member'; // new updated API
  const {
    data: { data }
  } = await authhttp.post(userUlr, { search: values });
  return data;
};
export const searchLeads = async (values) => {
  const userUlr = 'api/search_for_onboarding_member'; // new updated API
  const {
    data: { data }
  } = await authhttp.post(userUlr, { search: values });
  return data;
};
export const searchInactiveMembers = async (values) => {
  const userUlr = 'api/search_for_inactive_member'; // new updated API
  const {
    data: { data }
  } = await authhttp.post(userUlr, { search: values });
  return data;
};
export const getUnsignedContracts = async (id) => {
  const url = `api/get_all_user_unsigned_contracts/${id}`;
  const {
    data: { data }
  } = await authhttp.get(url);
  return data;
};

export const createVanguardDropInUser = async (values) => {
  const userUrl = 'api/store_user_drop_in_vanguard';
  const {
    data: { data }
  } = await authhttp.post(userUrl, values);
  return data;
};
