const styles = (theme) => ({
  root: {
    marginTop: 0,
    margin: '0px 20px 20px',
    padding: '0px 20px 20px'
  },
  mainCard: {
    boxShadow: '4px 0px 16px rgba(37, 40, 43, 0.08)',
    borderRadius: '5px',
    background: '#FFFF',
    padding: 20
  },
  button: {
    background: '#01847A',
    color: '#FFF',
    padding: '15px 24px',
    borderRadius: '2px;',
    boxShadow: '0px 8px 16px rgba(0, 126, 255, 0.16);',
    float: 'right;',
    fontWeight: '700',
    '&:hover': {
      backgroundColor: '#01847A'
    }
  },
  p_tabs: {
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px'
  },
  table: {
    fontSize: '14px',
    lineHeight: '22px'
  },
  table_th: {
    backgroundColor: '#F9F9FA',
    color: '#292929'
  },
  TableCell: {
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px',
    borderBottom: '1px solid #A8A8A8',
    height: '56px',
    minHeight: '56px'
  },
  Table_td: {
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '22px',
    position: 'relative',
    padding: '12px 15px 13px 25px',
    height: '56px',
    minHeight: '56px'
  },
  colorcode: {
    width: '10px',
    height: '10px',
    position: 'absolute',
    left: '0',
    top: '21px',
    borderRadius: '50%'
  },
  button_status: {
    fontWeight: 'bold',
    fontSize: '11px'
  },
  button_status_act: {
    fontWeight: 'bold',
    fontSize: '11px',
    color: '#9B9B9B',
    border: '1px solid #949494',
    width: '100px',
    height: '24px',
    padding: '0 0',
    borderRadius: '2px',
    '&$hover': {
      backgroundColor: '#1976d2'
    }
  },
  button_status_deac: {
    fontWeight: 'bold',
    fontSize: '11px',
    color: '#01847A',
    border: '1px solid rgba(1, 132, 122, 1)',
    width: '100px',
    height: '24px',
    padding: '0 0',
    borderRadius: '2px'
  },
  selected: {
    backgroundColor: 'red !important'
  },
  search: {
    position: 'relative',
    height: '48px',
    marginLeft: 0,
    width: '100%',
    marginBottom: '21px'
  },
  searchInput: {
    padding: '6px 20px 6px !important;',
    paddingRight: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    height: '35px',
    border: '1px solid #a9a9a9'
  }
});
export default styles;
