import * as Yup from 'yup';

// eslint-disable-next-line import/prefer-default-export
export const chalkBoardValidation = Yup.object({
  class: Yup.object()
    .nullable()
    .required('Please select the class'),
  selectedMember: Yup.object()
    .nullable()
    .required('Please select the Member')
});
