/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react';
import PropType from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  withStyles,
  Grid,
  InputLabel,
  ThemeProvider
} from '@material-ui/core';
import dcloseicon from 'src/assets/image/closeicon.png';
import CustomAutocomplete from 'src/views/common/CustomAutocomplete/commonAutocomplete';
import { openNoti } from 'src/Redux/global/global.actions';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import {
  getDeactivationDates,
  deactivateAlly
} from 'src/services/masterhq.allies.services';
import { getErrorMsg } from 'src/utils/ErrorHandling';
import { ROUTES } from 'src/constant/Constants';
import moment from 'moment';

const styles = (theme) => ({
  dtitle: {
    color: '#fff',
    fontWeight: 'bold',
    fontSize: '33px',
    lineHeight: '26px'
  },
  cutom_select_Label: {
    fontSize: '12px',
    padding: '7px 0'
  },
  ptext: {
    fontSize: '14px',
    lineHeight: '18px',
    color: '#fff',
    fontWeight: 'normal'
  },
  Deletebtn: {
    background: '#FB4E4E',
    boxShadow: '0px 8px 16px rgba(251, 78, 78, 0.16)',
    borderRadius: '2px',
    height: '48px',
    padding: '10px 15px',
    color: '#fff',
    fontWeight: '700',
    '&:hover': {
      background: '#FB4E4E'
    }
  },
  DialogPopup: {
    '& .MuiPaper-root': {
      background: theme.masterhq.mainCard.background
    }
  },
  DialogCss: {
    background: theme.masterhq.mainCard.background
  },
  titlebk: {
    display: 'inline-block',
    padding: '25px 24px 0'
  }
});

function DeactivateAllyDialog(props) {
  const { classes, openNotify, handleClose, setOpen, subscription } = props;

  const [dates, setDates] = useState([]);
  const [data, setData] = useState(null);
  const [processing, setProcessing] = useState(() => false);

  const location = useLocation();
  const history = useHistory();

  const { affiliateId } = queryString.parse(location.search);

  useEffect(() => {
    getDeactivationDates(affiliateId)
      .then((res) => setDates(() => res?.map((l) => ({ date: l }))))
      .catch((err) => console.log(err));
  }, [affiliateId]);

  const handleDeactivate = (e) => {
    e.stopPropagation();
    const values = {
      affiliate_id: affiliateId,
      inactive_date: data?.date
    };
    setProcessing(() => true);
    deactivateAlly(values)
      .then(() => openNotify('Successfully deactivated the ally'))
      .then(() => handleClose())
      .then(() => history.push(ROUTES.MASTER_HQ_AFFILIATES))
      .catch((err) => openNotify(getErrorMsg(err), 'error'))
      .finally(() => setProcessing(() => false));
  };

  const ApplyFilterStyle = {
    overrides: {
      MuiPaper: {
        root: {
          backgroundColor: '#3c3d4f',
          color: '#ffffff'
        }
      },
      MuiAutocomplete: {
        endAdornment: {
          '& svg': {
            color: '#fff'
          }
        }
      },
      MuiFormControl: {
        root: {
          '&.cutom_select div input': {
            color: '#fff !important',
            '&::placeholder': {
              color: '#fff !important'
            }
          }
        }
      }
    }
  };

  return (
    <div>
      <Dialog
        open={setOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick
        className={classes.DialogPopup}
      >
        <div style={{ width: '548px' }} className={classes.DialogCss}>
          <Grid alignItems="center" container direction="row">
            <Grid item xs>
              <DialogTitle
                id="alert-dialog-title"
                className={classes.titlebk}
                // style={{
                //   display: 'inline-block',
                //   padding: '25px 24px 0'
                // }}
              >
                <h4 className={classes.dtitle}>Deactivate Ally</h4>
              </DialogTitle>
            </Grid>
            <Grid
              item
              xs={2}
              style={{ textAlign: 'right', marginRight: '10px' }}
            >
              <Button onClick={handleClose} className={classes.btns}>
                <img alt="img" src={dcloseicon} />
              </Button>
            </Grid>
          </Grid>

          <DialogContent>
            <Grid container spacing={2} style={{ marginTop: '5px' }}>
              <Grid item xs={12}>
                <p className={classes.ptext}>
                  Are you sure you want to deactivate this Ally? The ally's
                  current subscription expires on{' '}
                  {moment(subscription, 'DD/MM/YYYY').format('DD, MMMM YYYY')}.
                  You can not deactivate before then.
                </p>

                {/* If is Edit show this text */}

                {/* end */}
              </Grid>
              <Grid item xs={12}>
                <InputLabel
                  htmlFor="age-required"
                  required
                  className={classes.cutom_select_Label}
                  style={{ padding: '6px 0px' }}
                >
                  Deactivate Ally as of
                </InputLabel>
                <ThemeProvider theme={ApplyFilterStyle}>
                  <CustomAutocomplete
                    holder="Select the date"
                    data={data}
                    value={dates || []}
                    Change={(val) => setData(val)}
                  />
                </ThemeProvider>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions
            style={{
              padding: '20px 20px'
            }}
          >
            <Button
              variant="outlined"
              onClick={handleClose}
              style={{
                height: '48px',
                color: '#7F7F7F',
                fontWeight: 'bold',
                marginRight: '8px'
              }}
            >
              Cancel
            </Button>
            <Button
              className={classes.Deletebtn}
              disabled={!data || processing}
              onClick={handleDeactivate}
            >
              {processing ? 'Deactivating...' : 'deactivate'}
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}
// DeactivateAllyDialog.defaultProps = {
//   handleHistory: null
// };

DeactivateAllyDialog.propTypes = {
  classes: PropType.object.isRequired,
  openNotify: PropType.func.isRequired,
  setOpen: PropType.bool.isRequired,
  handleClose: PropType.func.isRequired,
  subscription: PropType.string.isRequired
  // handleDelete: PropType.func.isRequired,
  // handleHistory: PropType.string
};
const mapToDispatch = (dispatch) => ({
  openNotify: (msg, type) => dispatch(openNoti(msg, type))
});
export default connect(
  null,
  mapToDispatch
)(withStyles(styles)(DeactivateAllyDialog));
