/* eslint-disable operator-linebreak */
import React from 'react';
import { connect } from 'react-redux';
import PropType from 'prop-types';
import { Grid, InputLabel, withStyles } from '@material-ui/core';
import CurrencyInputs from 'src/views/common/CurrencyControlForm/CurrencyInputs';
import styles from './PaymentPlan.style';
import {
  chngeInitialpriceWeek,
  chngeInitialpricemem,
  chngeInitialFull,
  chngeInitialFullmem,
  chngeAutorenweek,
  chngeAutorenfull
} from './paymentReducer.actions';
import { validateFloat, validPrice } from '../../../utils/validations';
import { autorenewpayment, initialPayment } from './PaymentPlan.validation';

function PlanPricing(props) {
  const {
    classes,
    reducer: [state, dispatch],
    currencyrates: { data: currencies, error }
  } = props;

  if (error) return <p>Something went wrong</p>;

  const {
    week_setup_fees,
    week_membership_fees,
    full_setup_fees,
    full_membership_fees
  } = state.initial_commitment_pricing;

  const {
    week_membership_fees: weekfee,
    full_membership_fees: fullfee
  } = state.auto_renewal_pricing;

  return (
    <div>
      <h3 style={{ marginBottom: '10px' }}>
        {state.initial_commitment.initial}{' '}
        {state.initial_commitment.initial > 1 ? 'Weeks' : 'Week'} Initial
        Commitment
      </h3>
      <div className={classes.hr} />
      <Grid
        container
        spacing={0}
        xs={12}
        disableGutters
        direction="row"
        style={{
          margin: '15px 0'
        }}
      >
        <Grid
          item
          xs={2}
          style={{
            marginTop: '20px',
            paddingRight: '10px',
            display: 'flex'
          }}
        >
          <h4 style={{ alignSelf: 'center' }}>
            When paid every {state.initial_commitment.billed_every.name}{' '}
            {state.initial_commitment.billed_every.name > 1 ? 'weeks' : 'week'}
          </h4>
        </Grid>
        <Grid
          item
          xs={3}
          className={classes.time_box}
          style={{ marginTop: '0px', paddingRight: '15px' }}
        >
          {/* <FormControl
            control="input"
            label="One-time setup fee"
            placeholder={currencies.currency_symbol}
            value={week_setup_fees}
            maxLength={10}
            onChange={(e) =>
              dispatch(
                chngeInitialpriceWeek(validPrice(validateFloat(e.target.value)))
              )
            } // eslint-disable-line
            style={{
              margin: '0 0'
            }}
          /> */}
          <InputLabel
            htmlFor="age-required"
            className={classes.cutom_select_Label}
            style={{ padding: '0px 0 10px' }}
          >
            One-time setup fee
          </InputLabel>
          <CurrencyInputs
            placeholder={currencies.currency_symbol}
            value={week_setup_fees}
            maxLength={10}
            currencySymbol={currencies.currency_symbol}
            onChange={(e) =>
              dispatch(
                chngeInitialpriceWeek(validPrice(validateFloat(e.target.value)))
              )
            } // eslint-disable-line
            style={{
              margin: '0 0'
            }}
          />
        </Grid>
        <Grid
          item
          xs={3}
          className={classes.time_box}
          style={{ marginTop: '0px', paddingRight: '15px' }}
        >
          {/* <FormControl
            control="input"
            label={`${state.initial_commitment.billed_every.name} week membership fee`}
            placeholder="Member is billed every"
            value={week_membership_fees}
            maxLength={10}
            onChange={(e) =>
              dispatch(
                chngeInitialpricemem(validPrice(validateFloat(e.target.value)))
              )
            } // eslint-disable-line
            required
            style={{
              margin: '0 0'
            }}
          /> */}
          <InputLabel
            htmlFor="age-required"
            className={classes.cutom_select_Label}
            style={{ padding: '0px 0 10px' }}
            required
          >
            {`${state.initial_commitment.billed_every.name} ${
              state.initial_commitment.billed_every.name > 1 ? 'weeks' : 'week'
            } membership fee`}
          </InputLabel>
          <CurrencyInputs
            placeholder="Member is billed every"
            value={week_membership_fees}
            currencySymbol={currencies.currency_symbol}
            maxLength={10}
            onChange={(e) =>
              dispatch(
                chngeInitialpricemem(validPrice(validateFloat(e.target.value)))
              )
            } // eslint-disable-line
            required
            style={{
              margin: '0 0'
            }}
          />
        </Grid>
        <Grid
          item
          xs={3}
          className={classes.time_box}
          style={{
            marginTop: '20px',
            display: 'flex',
            flexDirection: 'column',
            alignSelf: 'center'
          }}
        >
          <h3 style={{ marginBottom: '0px' }}>
            {currencies.currency_symbol +
              ' ' +
              initialPayment(state).toLocaleString(undefined, {
                minimumFractionDigits: currencies.decimals
              })}
          </h3>
          <InputLabel
            htmlFor="age-required"
            className={classes.cutom_select_Label}
          >
            {state.initial_commitment.initial}-
            {state.initial_commitment.initial > 1 ? 'weeks' : 'week'} total cost
            (including tax)
          </InputLabel>
        </Grid>
      </Grid>
      <div className={classes.hr} />
      <Grid
        container
        spacing={0}
        xs={12}
        disableGutters
        direction="row"
        style={{
          margin: '15px 0'
        }}
      >
        <Grid
          item
          xs={2}
          style={{
            marginTop: '0px',
            paddingRight: '15px',
            display: 'flex'
          }}
        >
          <h4 style={{ alignSelf: 'center' }}>When paid in Full</h4>
        </Grid>
        <Grid
          item
          xs={3}
          className={classes.time_box}
          style={{ marginTop: '0px', paddingRight: '15px' }}
        >
          {/* <FormControl
            control="input"
            label="One-time setup fee"
            maxLength={10}
            value={full_setup_fees}
            onChange={(e) =>
              dispatch(
                chngeInitialFull(validPrice(validateFloat(e.target.value)))
              )
            } // eslint-disable-line
            placeholder={currencies.currency_symbol}
            style={{
              margin: '0 0'
            }}
          /> */}
          <InputLabel
            htmlFor="age-required"
            className={classes.cutom_select_Label}
            style={{ padding: '0px 0 10px' }}
          >
            One-time setup fee
          </InputLabel>
          <CurrencyInputs
            maxLength={10}
            currencySymbol={currencies.currency_symbol}
            value={full_setup_fees}
            onChange={(e) =>
              dispatch(
                chngeInitialFull(validPrice(validateFloat(e.target.value)))
              )
            } // eslint-disable-line
            placeholder={currencies.currency_symbol}
            style={{
              margin: '0 0'
            }}
          />
        </Grid>
        <Grid
          item
          xs={3}
          className={classes.time_box}
          style={{ marginTop: '0px', paddingRight: '15px' }}
        >
          <InputLabel
            htmlFor="age-required"
            className={classes.cutom_select_Label}
            style={{ padding: '0px 0 10px' }}
            required
          >
            Full membership fee
          </InputLabel>
          <CurrencyInputs
            value={full_membership_fees}
            currencySymbol={currencies.currency_symbol}
            required
            maxLength={10}
            onChange={(e) =>
              dispatch(
                chngeInitialFullmem(validPrice(validateFloat(e.target.value)))
              )
            } // eslint-disable-line
            style={{
              margin: '0 0'
            }}
          />
        </Grid>
        <Grid
          item
          xs={3}
          className={classes.time_box}
          style={{
            marginTop: '20px',
            display: 'flex',
            flexDirection: 'column',
            alignSelf: 'center'
          }}
        >
          <h3 style={{ marginBottom: '0px' }}>
            {currencies.currency_symbol +
              ' ' +
              parseFloat(
                parseFloat(state.initial_commitment_pricing.full_setup_fees) +
                  parseFloat(
                    state.initial_commitment_pricing.full_membership_fees
                  )
              ).toLocaleString(undefined, {
                minimumFractionDigits: currencies.decimals
              })}
          </h3>
          <InputLabel
            htmlFor="age-required"
            className={classes.cutom_select_Label}
          >
            {state.initial_commitment.initial}-
            {state.initial_commitment.initial > 1 ? 'weeks' : 'week'} total cost
            (including tax)
          </InputLabel>
        </Grid>
      </Grid>
      {state.auto_renew && (
        <div>
          <div className={classes.hr} />
          <h3 style={{ marginBottom: '10px', marginTop: '20px' }}>
            {state.auto_renew_meta.renewal}{' '}
            {state.auto_renew_meta.renewal > 1 ? 'Weeks' : 'Week'} Auto Renewal
          </h3>
          <div className={classes.hr} />
          <Grid
            container
            spacing={0}
            xs={12}
            disableGutters
            direction="row"
            style={{
              margin: '15px 0'
            }}
          >
            <Grid
              item
              xs={2}
              style={{
                marginTop: '0px',
                paddingRight: '15px',
                display: 'flex'
              }}
            >
              <h4 style={{ alignSelf: 'center' }}>
                When paid every {state.auto_renew_meta.billed_every.name}{' '}
                {state.auto_renew_meta.billed_every.name > 1 ? 'weeks' : 'week'}
              </h4>
            </Grid>
            <Grid
              item
              xs={6}
              className={classes.time_box}
              style={{ marginTop: '0px', paddingRight: '15px' }}
            >
              {/* <FormControl
                control="input"
                label={`${state.auto_renew_meta.billed_every.name} week membership fee`}
                value={weekfee}
                required
                placeholder={currencies.currency_symbol}
                maxLength={10}
                onChange={(e) =>
                  dispatch(
                    chngeAutorenweek(validPrice(validateFloat(e.target.value)))
                  )
                } // eslint-disable-line
                style={{
                  margin: '0 0'
                }}
              /> */}
              <InputLabel
                htmlFor="age-required"
                className={classes.cutom_select_Label}
                style={{ padding: '0px 0 10px' }}
                required
              >
                {`${state.auto_renew_meta.billed_every.name} ${
                  state.auto_renew_meta.billed_every.name > 1
                    ? 'weeks'
                    : ' week'
                } membership fee`}
              </InputLabel>
              <CurrencyInputs
                value={weekfee}
                required
                placeholder={currencies.currency_symbol}
                currencySymbol={currencies.currency_symbol}
                maxLength={10}
                onChange={(e) =>
                  dispatch(
                    chngeAutorenweek(validPrice(validateFloat(e.target.value)))
                  )
                } // eslint-disable-line
                style={{
                  margin: '0 0'
                }}
              />
            </Grid>
            <Grid
              item
              xs={3}
              className={classes.time_box}
              style={{
                marginTop: '20px',
                display: 'flex',
                flexDirection: 'column',
                alignSelf: 'center'
              }}
            >
              <h3 style={{ marginBottom: '0px' }}>
                {currencies.currency_symbol +
                  ' ' +
                  autorenewpayment(state).toLocaleString(undefined, {
                    minimumFractionDigits: currencies.decimals
                  })}
              </h3>
              <InputLabel
                htmlFor="age-required"
                className={classes.cutom_select_Label}
              >
                {state.auto_renew_meta.renewal}-
                {state.auto_renew_meta.renewal > 1 ? 'weeks' : 'week'} total
                cost (including tax)
              </InputLabel>
            </Grid>
          </Grid>
          <div className={classes.hr} />
          <Grid
            container
            spacing={0}
            xs={12}
            disableGutters
            direction="row"
            style={{
              margin: '15px 0'
            }}
          >
            <Grid
              item
              xs={2}
              style={{
                marginTop: '0px',
                paddingRight: '15px',
                display: 'flex'
              }}
            >
              <h4 style={{ alignSelf: 'center' }}>When paid in Full</h4>
            </Grid>
            <Grid
              item
              xs={6}
              className={classes.time_box}
              style={{ marginTop: '0px', paddingRight: '15px' }}
            >
              {/* <FormControl
                control="input"
                label="Full membership fee"
                required
                value={fullfee}
                placeholder={currencies.currency_symbol}
                maxLength={10}
                onChange={(e) =>
                  dispatch(
                    chngeAutorenfull(validPrice(validateFloat(e.target.value)))
                  )
                } // eslint-disable-line
                style={{
                  margin: '0 0'
                }}
              /> */}

              <InputLabel
                htmlFor="age-required"
                className={classes.cutom_select_Label}
                style={{ padding: '0px 0 10px' }}
                required
              >
                Full membership fee
              </InputLabel>
              <CurrencyInputs
                value={fullfee}
                placeholder={currencies.currency_symbol}
                currencySymbol={currencies.currency_symbol}
                maxLength={10}
                onChange={(e) =>
                  dispatch(
                    chngeAutorenfull(validPrice(validateFloat(e.target.value)))
                  )
                } // eslint-disable-line
                style={{
                  margin: '0 0'
                }}
              />
            </Grid>
            <Grid
              item
              xs={3}
              className={classes.time_box}
              style={{
                marginTop: '20px',
                display: 'flex',
                flexDirection: 'column',
                alignSelf: 'center'
              }}
            >
              <h3 style={{ marginBottom: '0px' }}>
                {currencies.currency_symbol +
                  ' ' +
                  parseFloat(
                    state.auto_renewal_pricing.full_membership_fees
                  ).toLocaleString(undefined, {
                    minimumFractionDigits: currencies.decimals
                  })}
              </h3>
              <InputLabel
                htmlFor="age-required"
                className={classes.cutom_select_Label}
              >
                {state.auto_renew_meta.renewal}-
                {state.auto_renew_meta.renewal > 1 ? 'weeks' : 'week'} total
                cost (including tax)
              </InputLabel>
            </Grid>
          </Grid>
          <div className={classes.hr} />
        </div>
      )}
    </div>
  );
}

PlanPricing.propTypes = {
  classes: PropType.object.isRequired,
  reducer: PropType.array.isRequired,
  currencyrates: PropType.object.isRequired
};

const mapToState = (state) => ({
  currencyrates: state.SettingReducer.currency
});

export default connect(mapToState)(withStyles(styles)(PlanPricing));
