import { backendDate, getValuefromObject } from 'src/utils/someCommon';

export const getFilter = (filter, data, select, endDate) => {
  switch (filter?.id) {
    case 1:
      return {
        invoice_status: getValuefromObject(data)
      };

    case 2: {
      if (select?.id === 'custom') {
        return {
          date_range: 'custom',
          custom: backendDate(data) + '-' + backendDate(endDate)
        };
        // eslint-disable-next-line no-else-return
      } else {
        return {
          date_range: select.id
        };
      }
    }
    default:
      return null;
  }
};

export const getfilterLabel = (filter, data, selected, endDate) => {
  switch (filter?.id) {
    case 1:
      return {
        id: filter.id,
        name: `Invoice status is ${Object.values(data)[1]}`,
        filter: 'invoice_status'
      };

    case 2: {
      if (selected?.id === 'custom') {
        return {
          id: filter?.id,
          name: `Payment Due is ${backendDate(data)
            + '-'
            + backendDate(endDate)}`,
          filter: 'payment_due'
        };
        // eslint-disable-next-line no-else-return
      } else {
        return {
          id: filter.id,
          name: `Payment Due is ${selected.filter}`,
          filter: 'payment_due'
        };
      }
    }

    default:
      return null;
  }
};

export const checkData = (filter, data, paymentdue, endDate) => {
  if (!filter) {
    return true;
  }
  if (filter?.id === 1 && !data) {
    return true;
  }
  if (filter?.id === 2) {
    if (!paymentdue) {
      return true;
    }
    if (paymentdue.id === 'custom' && !data) return true;
    if (paymentdue?.id === 'custom' && !endDate) {
      return true;
    }
  }
  return false;
};
