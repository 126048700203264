import authhttp from './authHttp.services';

// eslint-disable-next-line import/prefer-default-export
export const getAffiliateInvoice = async (values) => {
  const InvoiceUrl = 'api/get_all_affiliate_Invoice_for_master_ac';
  const {
    data: { data }
  } = await authhttp.get(InvoiceUrl, { params: values });
  return data;
};

export const getActiveAllies = async (search) => {
  const ActiveAlliesUrl = 'api/getAlliesListForInvoiceFilter';
  const {
    data: { data }
  } = await authhttp.get(ActiveAlliesUrl, {
    params: {
      search
    }
  });
  return data;
};
export const getAlliesNames = async () => {
  const url = 'api/getAllyName';
  const {
    data: { data }
  } = await authhttp.get(url);
  return data;
};
export const exportInvoicesWithId = async (ids) => {
  const exportUrl = 'api/affiliate_invoice_export_with_ids';
  const Values = {
    ids
  };
  authhttp
    .post(exportUrl, Values, {
      responseType: 'blob'
    })
    .then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'invoice.xlsx');
      document.body.appendChild(link);
      link.click();
    });
};

export const exportAllInvoices = async () => {
  const exportUrl = 'api/get_all_affiliate_Invoice_for_master_ac?export_all=1';

  authhttp
    .get(exportUrl, {
      responseType: 'blob'
    })
    .then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'all_invoice.xlsx');
      document.body.appendChild(link);
      link.click();
    });
};
export const exportExpireCreditCards = async () => {
  const exportUrl = 'api/get-expired-credit-cards-ally-export';
  authhttp
    .post(exportUrl, null, {
      responseType: 'blob'
    })
    .then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'expireCards.xlsx');
      document.body.appendChild(link);
      link.click();
    });
};
export const exportAllAlliesInvoices = async () => {
  const exportUrl = 'api/get-all-affiliate-invoice-report-export';
  authhttp
    .post(exportUrl, null, {
      responseType: 'blob'
    })
    .then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'all_invoices.xlsx');
      document.body.appendChild(link);
      link.click();
    });
};
export const exportRevenuePerMonth = async () => {
  const exportUrl = 'api/ally-revenue-per-month-report-export';
  authhttp
    .post(exportUrl, null, {
      responseType: 'blob'
    })
    .then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'revenue_per_month.xlsx');
      document.body.appendChild(link);
      link.click();
    });
};
export const exportOverdueAccount = async () => {
  const exportUrl = 'api/ally-overdue-invoice-report-export';
  authhttp
    .post(exportUrl, null, {
      responseType: 'blob'
    })
    .then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'overdue_invoice.xlsx');
      document.body.appendChild(link);
      link.click();
    });
};
export const createAffiliate = async (formData) => {
  const createAffiliateUrl = 'api/affiliate';
  const {
    data: { data }
  } = await authhttp.post(createAffiliateUrl, formData);
  return data;
};

export const getAlliesProfileById = async (id) => {
  const allieUrl = `api/affiliate/${id}/edit`;
  const {
    data: { data }
  } = await authhttp.get(allieUrl);
  return data;
};

export const updateAllieProfile = async (values, id) => {
  const updateAllieUrl = `api/affiliate/${id}`;
  const {
    data: { data }
  } = await authhttp.put(updateAllieUrl, values);
  return data;
};

export const checkEmailExistsAlly = async (email) => {
  const url = 'api/check_email_exists_or_not_for_ally';
  const value = {
    email
  };
  const { data } = await authhttp.post(url, value);
  return data;
};
export const getAffilatePlan = async () => {
  const url = 'api/getAffiliatePlans';
  const {
    data: { data }
  } = await authhttp.get(url);
  return data;
};
export const createAffilateDiscount = async (values) => {
  const url = 'api/create_affiliate_discount';
  const {
    data: { data }
  } = await authhttp.post(url, values);
  return data;
};
export const cancelAffilateDiscount = async (values) => {
  const url = 'api/cancel_affiliate_discount';
  const {
    data: { data }
  } = await authhttp.post(url, values);
  return data;
};
export const getAffiliateDiscounts = async (id) => {
  const url = `api/get_discount_start_date/${id}`;
  const {
    data: { data }
  } = await authhttp.get(url);
  return data;
};
export const getAffilateTransactionCharges = async (id) => {
  const url = 'api/get_transaction_charges';
  const {
    data: { data }
  } = await authhttp.post(url, { affiliate_id: id });
  return data;
};
export const editAffiliateTransactionCharges = async (id, values) => {
  const url = `api/edit_affiliate_trans_charges/${id}`;
  const {
    data: { data }
  } = await authhttp.post(url, values);
  return data;
};
export const getAffiliateInvoiceSettings = async () => {
  const url = 'api/retriveAffiliateInvoiceSetting/2';
  const {
    data: {
      data: { meta }
    }
  } = await authhttp.get(url);
  return JSON.parse(meta || '{}');
};
export const updateAffiliateInvoiceSettings = async (values) => {
  const url = 'api/affiliateInvoiceSetting';
  const {
    data: { data }
  } = await authhttp.post(url, {
    module_name: 'invoice',
    meta: JSON.stringify(values)
  });
  return data;
};
export const getAffiliateTax = async () => {
  const url = 'api/affiliate_tax_controller';
  const {
    data: { data }
  } = await authhttp.get(url);
  return data;
};

export const getAffiliateallinvoicereports = async (values) => {
  const invoiceurl = 'api/get-all-affiliate-invoice-report';
  const {
    data: { data }
  } = await authhttp.post(invoiceurl, values);
  return data;
};

export const getExpiredCreditCardsAllyreports = async (values) => {
  const invoiceurl = 'api/get-expired-credit-cards-ally';
  const {
    data: { data }
  } = await authhttp.post(invoiceurl, values);
  return data;
};

export const getAllyOverdueaccountsrecieve = async (values) => {
  const invoiceurl = 'api/ally-overdue-invoice-report';
  const {
    data: { data }
  } = await authhttp.post(invoiceurl, values);
  return data;
};

export const getrevenuePerMonthReports = async (values) => {
  const ReportsURL = 'api/ally-revenue-per-month-report';
  const {
    data: { data }
  } = await authhttp.post(ReportsURL, values);
  return data;
};

export const getAffiliateCurrency = async () => {
  const url = 'api/getAffiliateCurrency';
  const {
    data: { data }
  } = await authhttp.get(url);
  return data;
};
