import constType from './class.constant';

export const filterClass = (state, type, data) => {
  switch (type) {
    case constType.page:
      return {
        ...state,
        page: data
      };
    case constType.search:
      return {
        ...state,
        search: data
      };
    default:
      return state;
  }
};

export const getClassData = (type, action, state, data) => {
  switch (type) {
    case constType.futureClass: {
      const Data = () => {
        switch (action) {
          case constType.isFetching:
            return {
              ...state,
              isFetching: true
            };
          case constType.fetchSucess:
            return {
              ...state,
              isFetching: false,
              error: null,
              data
            };
          case constType.Errorfetch:
            return {
              ...state,
              isFetching: false,
              error: data
            };
          default:
            return state;
        }
      };
      return Data();
    }
    case constType.allClass: {
      const Data = () => {
        switch (action) {
          case constType.isFetching:
            return {
              ...state,
              isFetching: true
            };
          case constType.fetchSucess:
            return {
              ...state,
              isFetching: false,
              error: null,
              data
            };
          case constType.Errorfetch:
            return {
              ...state,
              isFetching: false,
              error: data
            };
          default:
            return state;
        }
      };
      return Data();
    }
    case constType.recurrClass: {
      const Data = () => {
        switch (action) {
          case constType.isFetching:
            return {
              ...state,
              isFetching: true
            };
          case constType.fetchSucess:
            return {
              ...state,
              isFetching: false,
              error: null,
              data
            };
          case constType.Errorfetch:
            return {
              ...state,
              isFetching: false,
              error: data
            };
          default:
            return state;
        }
      };
      return Data();
    }
    default:
      return state;
  }
};
