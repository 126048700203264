import React from 'react';
import { ROUTES } from 'src/constant/Constants';
import { VanguardMembershipContext } from 'src/Context/VanguardMembershipContext';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { VanguardWaiverContext } from 'src/Context/VanguardWaiverContext';
import StripeElement from 'src/views/common/StripeComponent/StripeElement';
import VanguardIndex from './vanguardIndex';
import WaiverIndex from './waiver/waiverIndex';
import WaiverNew from './waiver/waiverNew';
import Returning from './waiver/returning';
import MembershipIndex from './membership/membershipIndex';
import MembershipNew from './membership/membershipNew';
import MembershipReturning from './membership/Returning/Returning';
import ChalkboardIndex from './chalkboard/chalkboardIndex';
import ChalkboardWorkout from './chalkboard/chalkboardWorkout';
import ChalkboardSelectmember from './chalkboard/chalkboardSelectMember';
import SelectContract from './membership/SelectContract/SelectContract';
import SignContract from './membership/SignContract/SignContract';
import ConvertMember from './membership/ConvertMember/ConvertMember';
import DropinMember from './DropinMember/DropinMember';

const Vanguard = (props) => {
  const { currentUserData } = props;

  return (
    <div>
      <Switch>
        <Route
          path={ROUTES.VANGUARD_MEMBERSHIP_DROPIN}
          render={() => (
            <VanguardWaiverContext>
              <VanguardMembershipContext>
                <StripeElement>
                  <DropinMember />
                </StripeElement>
              </VanguardMembershipContext>
            </VanguardWaiverContext>
          )}
        />
        <Route
          path={ROUTES.VANGUARD_CONVERT_MEMBER}
          render={() => (
            <VanguardMembershipContext>
              <ConvertMember />
            </VanguardMembershipContext>
          )}
        />
        <Route
          path={ROUTES.VANGUARD_MEMBERSHIP_SIGN_CONTRACT}
          render={() => (
            <VanguardMembershipContext>
              <SignContract />
            </VanguardMembershipContext>
          )}
        />
        <Route
          path={ROUTES.VANGUARD_MEMBERSHIP_SELECT_CONTRACT}
          render={() => (
            <VanguardMembershipContext>
              <SelectContract />
            </VanguardMembershipContext>
          )}
        />
        <Route
          path={ROUTES.VANGUARD_CHALKBOARD_WORKOUT_SIGN_IN_MEMBER}
          render={() => <ChalkboardSelectmember />}
        />
        <Route
          path={ROUTES.VANGUARD_CHALKBOARD_WORKOUT}
          render={() => <ChalkboardWorkout />}
        />
        <Route
          path={ROUTES.VANGUARD_CHALKBOARD}
          render={() => <ChalkboardIndex />}
        />
        <Route
          path={ROUTES.VANGUARD_MEMBERSHIP_RETURNING}
          render={() => (
            <VanguardMembershipContext>
              <StripeElement>
                <MembershipReturning />
              </StripeElement>
            </VanguardMembershipContext>
          )}
        />
        <Route
          path={ROUTES.VANGUARD_MEMBERSHIP_NEW}
          render={() => (
            <VanguardMembershipContext>
              <StripeElement>
                <MembershipNew />
              </StripeElement>
            </VanguardMembershipContext>
          )}
        />
        <Route
          path={ROUTES.VANGUARD_MEMBERSHIP}
          render={() => <MembershipIndex />}
        />
        <Route
          path={ROUTES.VANGUARD_WAIVER_RETURNING}
          render={() => (
            <VanguardWaiverContext>
              <Returning />
            </VanguardWaiverContext>
          )}
        />
        <Route
          path={ROUTES.VANGUARD_WAIVER_NEW}
          render={() => (
            <VanguardWaiverContext>
              <WaiverNew />
            </VanguardWaiverContext>
          )}
        />
        <Route path={ROUTES.VANGUARD_WAIVER_NEW} render={() => <WaiverNew />} />
        <Route path={ROUTES.VANGUARD_WAIVER} render={() => <WaiverIndex />} />
        <Route
          path={ROUTES.VANGUARD}
          render={() => <VanguardIndex currentUser={currentUserData} />}
        />
      </Switch>
    </div>
  );
};

Vanguard.propTypes = {
  currentUserData: PropTypes.object.isRequired
};
export default Vanguard;
