import Types from './lead.Types';
import { getLeads, getFilters } from './leadReducer.utils';
import filterLeads from '../Reducers.utils';

const initialValue = {
  DailylistLead: { isFetching: true, data: null, error: null },
  NewLead: { isFetching: false, data: null, error: null },
  FollowupLead: { isFetching: false, data: null, error: null },
  ConsultbookedLead: { isFetching: false, data: null, error: null },
  OnboardbookedLead: { isFetching: false, data: null, error: null },
  ConvertedLead: { isFetching: false, data: null, error: null },
  AllLead: { isFetching: false, data: null, error: null },
  ArchievedLead: { isFetching: false, data: null, error: null },
  lead_source: { isFetching: false, data: null, error: null },
  lead_status: { isFetching: false, data: null, error: null },
  tags: { isFetching: false, data: null, error: null },
  LeadDefaultMemberStatus: { isFetching: false, data: null, error: null },
  DailylistLeadFilter: {
    page: 1,
    search: '',
    customFilter: {},
    tags: [],
    sorting: {},
    default: {}
  },
  NewLeadFilter: {
    page: 1,
    search: '',
    customFilter: {},
    tags: [],
    sorting: {},
    default: {
      tab_data: 1
    }
  },
  FollowupLeadFilter: {
    page: 1,
    search: '',
    customFilter: {},
    tags: [],
    sorting: {},
    default: {
      tab_data: 2
    }
  },
  ConsultbookedLeadFilter: {
    page: 1,
    search: '',
    customFilter: {},
    tags: [],
    sorting: {},
    default: {
      tab_data: 3
    }
  },
  OnboardbookedLeadFilter: {
    page: 1,
    search: '',
    customFilter: {},
    tags: [],
    sorting: {},
    default: {
      tab_data: 4
    }
  },
  ConvertedLeadFilter: {
    page: 1,
    search: '',
    customFilter: {},
    tags: [],
    sorting: {},
    default: {
      tab_data: 5
    }
  },
  AllLeadFilter: {
    page: 1,
    search: '',
    customFilter: {},
    tags: [],
    sorting: {},
    default: {
      tab_data: 0
    }
  },
  ArchievedLeadFilter: {
    page: 1,
    search: '',
    customFilter: {},
    tags: [],
    sorting: {},
    default: {
      tab_data: 6
    }
  },
  areAllSelected: false
};

export default (state = initialValue, action) => {
  switch (action.type) {
    case Types.getDailyListLead:
      return {
        ...state,
        DailylistLead: getLeads(
          Types.getDailyListLead,
          action.actionType,
          state.DailylistLead,
          action.payload
        )
      };
    case Types.getNewLead:
      return {
        ...state,
        NewLead: getLeads(
          Types.getNewLead,
          action.actionType,
          state.NewLead,
          action.payload
        )
      };
    case Types.getfollowuplist:
      return {
        ...state,
        FollowupLead: getLeads(
          Types.getDailyListLead,
          action.actionType,
          state.FollowupLead,
          action.payload
        )
      };
    case Types.getConsultBooked:
      return {
        ...state,
        ConsultbookedLead: getLeads(
          Types.getConsultBooked,
          action.actionType,
          state.ConsultbookedLead,
          action.payload
        )
      };
    case Types.getOnboardbooked:
      return {
        ...state,
        OnboardbookedLead: getLeads(
          Types.getOnboardbooked,
          action.actionType,
          state.OnboardbookedLead,
          action.payload
        )
      };
    case Types.getConvertedLead:
      return {
        ...state,
        ConvertedLead: getLeads(
          Types.getConvertedLead,
          action.actionType,
          state.ConvertedLead,
          action.payload
        )
      };
    case Types.getAllLead:
      return {
        ...state,
        AllLead: getLeads(
          Types.getAllLead,
          action.actionType,
          state.AllLead,
          action.payload
        )
      };

    case Types.getArchievedLead:
      return {
        ...state,
        ArchievedLead: getLeads(
          Types.getArchievedLead,
          action.actionType,
          state.ArchievedLead,
          action.payload
        )
      };

    case Types.dailylistfilter:
      return {
        ...state,
        DailylistLeadFilter: filterLeads(
          state.DailylistLeadFilter,
          action.filter,
          action.payload
        )
      };
    case Types.newleadsfilter:
      return {
        ...state,
        NewLeadFilter: filterLeads(
          state.NewLeadFilter,
          action.filter,
          action.payload
        )
      };
    case Types.followupfilter:
      return {
        ...state,
        FollowupLeadFilter: filterLeads(
          state.FollowupLeadFilter,
          action.filter,
          action.payload
        )
      };
    case Types.ConsultBookedfilter:
      return {
        ...state,
        ConsultbookedLeadFilter: filterLeads(
          state.ConsultbookedLeadFilter,
          action.filter,
          action.payload
        )
      };
    case Types.Onboardbookedfilter:
      return {
        ...state,
        OnboardbookedLeadFilter: filterLeads(
          state.OnboardbookedLeadFilter,
          action.filter,
          action.payload
        )
      };
    case Types.ConvertedLeadfilter:
      return {
        ...state,
        ConvertedLeadFilter: filterLeads(
          state.ConvertedLeadFilter,
          action.filter,
          action.payload
        )
      };
    case Types.AllLeadfilter:
      return {
        ...state,
        AllLeadFilter: filterLeads(
          state.AllLeadFilter,
          action.filter,
          action.payload
        )
      };
    case Types.ArchievedLeadFilter:
      return {
        ...state,
        ArchievedLeadFilter: filterLeads(
          state.ArchievedLeadFilter,
          action.filter,
          action.payload
        )
      };
    case Types.getLeadSource:
      return {
        ...state,
        lead_source: getFilters(
          Types.getLeadSource,
          action.actionType,
          state.lead_source,
          action.payload
        )
      };
    case Types.getLeadStatus: {
      return {
        ...state,
        lead_status: getFilters(
          Types.getLeadStatus,
          action.actionType,
          state.lead_status,
          action.payload
        )
      };
    }
    case Types.getTags: {
      return {
        ...state,
        tags: getFilters(
          Types.getTags,
          action.actionType,
          state.tags,
          action.payload
        )
      };
    }
    case Types.getLeadDefaultMemberStatus: {
      return {
        ...state,
        LeadDefaultMemberStatus: getFilters(
          Types.getLeadDefaultMemberStatus,
          action.actionType,
          state.LeadDefaultMemberStatus,
          action.payload
        )
      };
    }
    case Types.selectAll:
      return {
        ...state,
        areAllSelected: action.payload
      };
    default:
      return state;
  }
};
