import authhttp from './authHttp.services';

export const getInvoice = async (id) => {
  const url = `api/editAffiliateInvoice/${id}`;
  const {
    data: { data }
  } = await authhttp.get(url);
  return data;
};

export const updateInvoice = async (id, values) => {
  const url = `api/updateAffiliateInvoice/${id}`;
  const {
    data: { data }
  } = await authhttp.post(url, values);
  return data;
};
export const voidInvoice = async (value) => {
  const url = 'api/affiliate_void_invoice';
  const {
    data: { data }
  } = await authhttp.post(url, value);
  return data;
};
export const getTransactionHistory = async (id) => {
  const url = `api/ally_transaction/${id}`;
  const {
    data: { data }
  } = await authhttp.get(url);
  return data;
};
export const payInvoice = async (values) => {
  const url = 'api/affiliate_paymanual_invoice';
  const { data } = await authhttp.post(url, values);
  return data;
};
export const retrivePaymentMethod = async (id) => {
  const url = `api/get_ally_payment_methods/${id}`;
  const {
    data: { data }
  } = await authhttp.get(url);
  return data;
};
export const addInvoiceDiscount = async (values) => {
  const url = 'api/add_affiliate_invoice_discount';
  const {
    data: { data }
  } = await authhttp.post(url, values);
  return data;
};
export const removeInvoiceDiscount = async (id) => {
  const url = `api/remove_applied_discount/${id}`;
  const {
    data: { data }
  } = await authhttp.get(url);
  return data;
};
export const refundInvoice = async (id) => {
  const url = `api/refundAllyInvoice/${id}`;
  const {
    data: { data }
  } = await authhttp.get(url);
  return data;
};

export const emailInvoice = async (values) => {
  const url = 'api/email_invoice_to_ally';
  const {
    data: { data }
  } = await authhttp.post(url, values);
  return data;
};

export const getEmailInvoice = async (values) => {
  const emailUrl = `api/getEmailInvoiceTemplateForAlly/${values}`;
  const { data } = await authhttp.get(emailUrl);
  return data;
};
