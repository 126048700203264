// eslint-disable-next-line import/prefer-default-export
export const VanguardMembershipType = {
  plan: 0,
  session: 1,
  dropIn: 2
};
export const Expirationtypes = [
  { id: 0, name: 'No Expiration' },
  { id: 1, name: 'Expiration' }
];
export const AllDayType = [
  { id: 'day', name: 'Days' },
  { id: 'week', name: 'Weeks' },
  { id: 'month', name: 'Months' },
  { id: 'year', name: 'Years' }
];
export const billedWeek = [
  { id: 2, name: '1' },
  { id: 0, name: '2' },
  { id: 1, name: '4' }
];
export const Genders = [
  // { id: 0, label: 'Male' },
  // { id: 1, label: 'Female' },
  { id: 0, label: 'Male', name: 'Male' },
  { id: 1, label: 'Female', name: 'Female' },
  { id: 2, label: 'Non_binary', name: 'Non Binary' },
  { id: 3, label: 'Prefer_not_to_say', name: 'Prefer Not To Say' }
];
export const AttendanceTypes = [
  { id: 0, name: 'Unlimited' },
  { id: 1, name: 'Limited' }
];
