import types from './ContractReducer.types';
import { getErrorMsg } from '../../utils/ErrorHandling';
import { store } from '../rootReducer';
import {
  getConTemplate,
  getContracts
} from '../../services/Contracts.services';

const fetchingContracts = (type) => ({
  type,
  actionType: types.isFetching
});

const fetchComplete = (type, payload) => ({
  type,
  actionType: types.fetchSucess,
  payload
});

const ErrorFetching = (type, payload) => ({
  type,
  actionType: types.Errorfetch,
  payload
});

const ActiveMisFilter = (filter, payload) => ({
  type: types.activeMissFilter,
  filter,
  payload
});

const InActiveMisFilter = (filter, payload) => ({
  type: types.inactiveMissFilter,
  filter,
  payload
});

const AllMisFilter = (filter, payload) => ({
  type: types.allMissFilter,
  filter,
  payload
});

const SignedFilter = (filter, payload) => ({
  type: types.SignedFilter,
  filter,
  payload
});

const getActiveMiss = (val) => {
  const type = types.activeMiss;
  return (dispatch) => {
    dispatch(fetchingContracts(type));
    getContracts(val)
      .then((resp) => dispatch(fetchComplete(type, resp)))
      .catch((err) => dispatch(ErrorFetching(type, getErrorMsg(err))));
  };
};

const getInActiveMiss = (val) => {
  const type = types.inactiveMiss;
  return (dispatch) => {
    dispatch(fetchingContracts(type));
    getContracts(val)
      .then((resp) => dispatch(fetchComplete(type, resp)))
      .catch((err) => dispatch(ErrorFetching(type, getErrorMsg(err))));
  };
};

const getAllMiss = (val) => {
  const type = types.allMiss;
  return (dispatch) => {
    dispatch(fetchingContracts(type));
    getContracts(val)
      .then((resp) => dispatch(fetchComplete(type, resp)))
      .catch((err) => dispatch(ErrorFetching(type, getErrorMsg(err))));
  };
};

export const getSigned = (val) => {
  const type = types.Signed;
  return (dispatch) => {
    dispatch(fetchingContracts(type));
    getContracts(val)
      .then((resp) => dispatch(fetchComplete(type, resp)))
      .catch((err) => dispatch(ErrorFetching(type, getErrorMsg(err))));
  };
};

const TempContFilter = (filter, payload) => ({
  type: types.contTempFil,
  filter,
  payload
});

const getTemplates = (val) => {
  const type = types.contTemp;
  return (dispatch) => {
    dispatch(fetchingContracts(type));
    getConTemplate(val)
      .then((res) => dispatch(fetchComplete(type, res)))
      .catch((err) => dispatch(ErrorFetching(type, getErrorMsg(err))));
  };
};

export const getActiveMissCont = (filter, data) => {
  return (dispatch) => {
    dispatch(ActiveMisFilter(filter, data));
    const filData = store.getState().ContractReducer.ActiveMisFilter;
    let updatedValue = {
      page: filData.page,
      ...filData.default,
      ...filData.sorting,
      ...filData.customFilter
    };
    if (filData.search.length > 0) {
      updatedValue = {
        ...updatedValue,
        search: filData.search
      };
    }
    dispatch(getActiveMiss(updatedValue));
  };
};

export const getInActiveMissCont = (filter, data) => {
  return (dispatch) => {
    dispatch(InActiveMisFilter(filter, data));
    const filData = store.getState().ContractReducer.inActiveMisFilter;
    let updatedValue = {
      page: filData.page,
      ...filData.default,
      ...filData.sorting,
      ...filData.customFilter
    };
    if (filData.search.length > 0) {
      updatedValue = {
        ...updatedValue,
        search: filData.search
      };
    }
    dispatch(getInActiveMiss(updatedValue));
  };
};

export const getAllMissCont = (filter, data) => {
  return (dispatch) => {
    dispatch(AllMisFilter(filter, data));
    const filData = store.getState().ContractReducer.AllMisFilter;
    let updatedValue = {
      page: filData.page,
      ...filData.default,
      ...filData.sorting,
      ...filData.customFilter
    };
    if (filData.search.length > 0) {
      updatedValue = {
        ...updatedValue,
        search: filData.search
      };
    }
    dispatch(getAllMiss(updatedValue));
  };
};

export const getSignedCont = (filter, data) => {
  return (dispatch) => {
    dispatch(SignedFilter(filter, data));
    const filData = store.getState().ContractReducer.SignedFilters;
    let updatedValue = {
      page: filData.page,
      ...filData.default,
      ...filData.sorting,
      ...filData.customFilter
    };
    if (filData.search.length > 0) {
      updatedValue = {
        ...updatedValue,
        search: filData.search
      };
    }
    dispatch(getSigned(updatedValue));
  };
};

export const getTempCont = (filter, data) => {
  return (dispatch) => {
    dispatch(TempContFilter(filter, data));
    const filData = store.getState().ContractReducer.tempFilter;
    const updatedValue = {
      ...filData.sorting,
      ...filData.default
    };
    dispatch(getTemplates(updatedValue));
  };
};
