import React, { useState, useCallback, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Grid,
  withStyles,
  Card,
  Breadcrumbs,
  Typography,
  Box,
  Paper,
  InputBase
} from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
import ApplyFilter from 'src/views/common/ApplyFilter/applyFilter';
import { debounce } from 'lodash';
import filterType from 'src/Redux/Reducer.constants';
import Pagination from '@material-ui/lab/Pagination';
// import CustomAutoComplete from 'src/views/common/CustomAutocomplete/commonAutocomplete';
import { useSelector, connect } from 'react-redux';
import { getinvoicereports } from 'src/Redux/MasterHQ/ReportsReducer/reports.reducer.actions';
import { exportAllAlliesInvoices } from 'src/services/masterHQ.services';
import CancelDialogTwo from 'src/views/common/CommonDialogBox/CancelDialogTwo';
import JBButton from 'src/views/common/JBButton/JBButton';
import LoadingView from 'src/views/common/loading';
import ImportIcon from 'src/assets/image/upload.png';
import useChangeTitle from 'src/views/common/CustomHooks/useTitleChange';
import AllInvoicesTable from './AllTables/AllInvoicesTable/AllInvoicesTable';
import AllinvoicesFilters from './Filters/AllInvoiceFilter/Filters';
import styles from './FinancialReports.style';

const AllInvoices = (props) => {
  const {
    AllInvoiceFilter,
    AllInvoicesSort,
    AllInvoicesSearch,
    classes,
    filteredTags,
    customFilter
    // allprogramsData,
    // programSelected
  } = props;
  useChangeTitle('All invoices | Jungle Alliance');
  const [search, setSearch] = useState(AllInvoicesSearch);
  const { isFetching, data, error } = useSelector(
    (state) => state.masterHqReducer.reports.financialReports.allInvoice
  );
  const [open, setOpen] = useState(false);
  const { data: data_reports } = useSelector(
    (state) => state.masterHqReducer.reports.reportList
  );

  const isStarred = data_reports?.financial_report.filter(
    (report) => report.id === 10
  )[0].is_starred;

  const SearchQuery = useCallback(
    debounce((q) => AllInvoiceFilter(filterType.search, q), 1500),
    []
  );

  const setDirection = (name) => {
    if (AllInvoicesSort.sort_name === name) {
      return AllInvoicesSort.sort_by === 'asc' ? 'asc' : 'dsc';
    }
    return 'asc';
  };

  const isActive = (sortlabel) => {
    if (AllInvoicesSort.sort_name === sortlabel) return true;
    return false;
  };

  const setSort = (sortlabel) => {
    AllInvoiceFilter(filterType.sorting, sortlabel);
  };

  const Paginate = (val) => {
    if (val !== data.current_page) {
      return AllInvoiceFilter(filterType.page, val);
    }
    return null;
  };
  const handleReset = () => {
    AllInvoiceFilter(filterType.resetFilter);
    setSearch('');
  };
  useEffect(() => {
    return () => {
      handleReset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (!data_reports || !data) return <LoadingView />;
  return (
    <div className={classes.root}>
      <div className="title-bk">
        <Box css={{ marginBottom: '20px' }}>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={0}
          >
            <Grid item xs={9}>
              <Box display="inline-flex">
                <Breadcrumbs aria-label="breadcrumb">
                  <div color="inherit">Reports</div>
                  <div
                    color="inherit"
                    onClick={() => setOpen(true)}
                    style={{
                      cursor: 'pointer'
                    }}
                  >
                    financial reports
                  </div>
                  <Typography variant="h3" color="Primary">
                    <span>All invoices</span>
                  </Typography>
                </Breadcrumbs>
              </Box>
              <Box
                display="inline-flex"
                ml="10px"
                css={{ verticalAlign: 'text-bottom' }}
              >
                {isStarred === 1 && (
                  <StarIcon
                    style={{
                      color: '#FFC700'
                    }}
                  />
                )}
              </Box>
            </Grid>
            <Grid
              item
              xs={3}
              style={{
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              <JBButton
                type="outlined"
                isImg={ImportIcon}
                component={Link}
                style={{ marginLeft: '10px' }}
                disabled={data.invoice_data.data.length === 0}
                onClick={(e) => {
                  e.preventDefault();
                  exportAllAlliesInvoices({
                    search: AllInvoicesSearch,
                    sort_name: AllInvoicesSort.sort_name,
                    sort_by: AllInvoicesSort.sort_by,
                    ...customFilter
                  });
                }}
              >
                Export
              </JBButton>
            </Grid>
          </Grid>
        </Box>
      </div>

      <Card className={classes.mainCard}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={0}
        >
          <Grid item xs={12}>
            <Grid container direction="row" alignItems="center" spacing={0}>
              <Box
                css={{ width: '240px', marginRight: '5px' }}
                className={classes.Filtermenu}
              >
                <ApplyFilter>
                  <AllinvoicesFilters
                    getFilData={(val, val2) =>
                      AllInvoiceFilter(filterType.addcustom, [val, val2])
                    }
                  />
                </ApplyFilter>
              </Box>
              <Grid item xs={5}>
                <div className={classes.search}>
                  <InputBase
                    fullWidth
                    placeholder="Search…"
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                      SearchQuery(e.target.value);
                    }}
                    classes={{
                      root: classes.inputRoot,
                      input: classes.searchInput
                    }}
                    InputProps={{
                      'aria-label': 'search'
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs>
                <JBButton
                  type="outlined"
                  style={{ marginLeft: '10px' }}
                  onClick={() => handleReset()}
                >
                  reset
                </JBButton>
              </Grid>
              <Grid item xs={12}>
                <div>
                  {filteredTags.map((tags) => (
                    <div
                      className={classes.DTags}
                      key={tags.id}
                      style={{ display: 'inline-block' }}
                    >
                      <Box display="inline-block" mr={1}>
                        <label>
                          {tags.name}
                          <span
                            className={classes.DTagsClose}
                            onClick={() =>
                              AllInvoiceFilter(filterType.removecustom, tags)
                            }
                          >
                            ×
                          </span>
                        </label>
                      </Box>
                    </div>
                  ))}
                </div>
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid item xs={4} justify="flex-end" className="MuiGrid-container">
            <Box css={{ width: '240px' }}>
              <CustomAutoComplete holder="Payment due: this year" />
            </Box>
          </Grid> */}
        </Grid>
        {isFetching || !data ? (
          <LoadingView />
        ) : error ? (
          <p>{error}</p>
        ) : (
          <Fragment>
            <Grid item xs={12}>
              <Box mt="25px" />
              <AllInvoicesTable
                values={data?.invoice_data?.data || []}
                summaryData={data?.summary || []}
                setDirection={setDirection}
                isActive={isActive}
                setSort={setSort}
              />
            </Grid>
            {data?.invoice_data?.data.length !== 0 && (
              <Grid
                item
                xs={12}
                container
                direction="row"
                alignItems="center"
                style={{ marginTop: 24 }}
              >
                <Grid
                  item
                  xs={6}
                  justify="flex-start"
                  className={classes.PaginateData}
                >
                  <Paper elevation={0} style={{ color: '#A9A9A9' }}>
                    {data.invoice_data.from && data.invoice_data.to > 1 ? (
                      <Paper elevation={0} style={{ color: '#A9A9A9' }}>
                        {`${data.invoice_data.from} - ${data.invoice_data.to} of ${data.invoice_data.total}`}
                      </Paper>
                    ) : null}
                  </Paper>
                </Grid>
                <Grid
                  item
                  xs={6}
                  justify="flex-end"
                  style={{ display: 'flex' }}
                  className={classes.Pagination}
                >
                  <Pagination
                    count={data.invoice_data.last_page}
                    color="primary"
                    variant="outlined"
                    page={data.invoice_data.current_page}
                    // onChange={(_, val) => console.log(val)}
                    onChange={(__, val) => Paginate(val)}
                    shape="rounded"
                  />
                </Grid>
              </Grid>
            )}
          </Fragment>
        )}
      </Card>
      <CancelDialogTwo setOpen={open} handleClose={() => setOpen(false)} />
    </div>
  );
};

AllInvoices.propTypes = {
  classes: PropTypes.object.isRequired,
  AllInvoiceFilter: PropTypes.func.isRequired,
  AllInvoicesSearch: PropTypes.string.isRequired,
  AllInvoicesSort: PropTypes.object.isRequired,
  filteredTags: PropTypes.array.isRequired,
  customFilter: PropTypes.object.isRequired
  // allprogramsData: PropTypes.any.isRequired,
  // programSelected: PropTypes.array.isRequired
};

// export default withStyles(styles)(AllInvoices);
const mapToState = (state) => ({
  // AllListData: state.LeadReducer.AllLead,
  AllInvoicesSearch:
    state.masterHqReducer.reports.financialReports.allInvoiceFil.search,
  AllInvoicesSort:
    state.masterHqReducer.reports.financialReports.allInvoiceFil.sorting,
  filteredTags:
    state.masterHqReducer.reports.financialReports.allInvoiceFil.tags,
  customFilter:
    state.masterHqReducer.reports.financialReports.allInvoiceFil.customFilter
  // programSelected:
  //   state.reportsReducer.attendanceReports?.lastAttendanceFil.tags,
  // allprogramsData: state.allprograms.programs
});

const mapToDispatch = (dispatch) => ({
  AllInvoiceFilter: (filter, data) => dispatch(getinvoicereports(filter, data))
});

export default connect(
  mapToState,
  mapToDispatch
)(withStyles(styles)(AllInvoices));
