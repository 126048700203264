import authhttp from './authHttp.services';

// eslint-disable-next-line
export const getWorkoutDetails = async (id) => {
  const WODUrl = 'api/get_workout_details';
  const {
    data: { data }
  } = await authhttp.get(WODUrl, {
    params: {
      class_id: id
    }
  });
  return data[0];
};

export const getClassLogin = async (values) => {
  const classUrl = 'api/login_class';
  const {
    data: { data }
  } = await authhttp.post(classUrl, values);
  return data;
};
export const getClassLoginVanguard = async (values) => {
  const classUrl = 'api/login_class';
  const { data } = await authhttp.post(classUrl, values);
  return data;
};

export const getAttendanceByClass = async (class_id) => {
  const attendUrl = 'api/getAttendacelistbyClass';
  const {
    data: { data }
  } = await authhttp.get(attendUrl, { params: { class_id } });
  return data;
};

export const removeUserFromClass = async (id) => {
  const removeUserUrl = `api/cancel_class_login/${id}`;
  const { data } = await authhttp.del(removeUserUrl);
  return data;
};
