import React, { useContext } from 'react';
import { connect } from 'react-redux';
import { Grid, InputLabel, withStyles } from '@material-ui/core';
import PropType from 'prop-types';
import '../CutomSelect.css';
import { SessionTemplateContext } from 'src/Context/MembershipTemplate/SessionTemplate.Context';
import TextareaControl from 'src/views/common/FormControl/TextareaControl';
import FormControl from 'src/views/common/FormControl/formControl';
import {
  templatechangename,
  changeattlmt,
  changeattlmtuses,
  changeattlmttype,
  changerevencat,
  changetempdesc,
  changeSessions
} from 'src/Context/MembershipTemplate/membershiptemplate.actions';
import CustomAutoComplete from '../../../calendar/commonAutocomplete';
import styles from '../MembershipTemplate.style';
import { AllSessionlmt, SessionDay } from '../Membership.data';
import { openNoti } from '../../../../Redux/global/global.actions';
import { validateInt } from '../../../../utils/validations';

const SessionDetailsStep = (props) => {
  const {
    classes,
    allrevenCat: { data: revenCats }
  } = props;
  const [state, dispatch] = useContext(SessionTemplateContext);
  const activeRevenCat = revenCats.filter((cat) => cat.status === 1);
  return (
    <div>
      <h3>
        Name this Session Membership, set the attendance limit and revenue
        category.
      </h3>
      <Grid container spacing={0} direction="column">
        <Grid item xs={12}>
          <FormControl
            control="input"
            label="Session name"
            placeholder="e.g. 10 Session Pack "
            required
            value={state?.name}
            // onChange={(e) => setdata({ ...data, name: e.target.value })}
            onChange={(e) => dispatch(templatechangename(e.target.value))}
          />
        </Grid>
        <Grid container spacing={0} xs={12} disableGutters direction="row">
          <Grid container spacing={0} xs={12} disableGutters direction="row">
            <Grid
              id
              item
              xs={6}
              // className={classes.time_box}
              style={{ marginTop: '5px', paddingRight: '15px' }}
            >
              <FormControl
                control="input"
                label="Number of sessions"
                placeholder=""
                required
                maxLength={10}
                value={state?.session}
                onChange={(e) =>
                  dispatch(changeSessions(validateInt(e.target.value)))
                }
                style={{
                  margin: '4px 0'
                }}
              />
            </Grid>
            <Grid
              item
              xs={6}
              className={classes.time_box}
              style={{ marginTop: '5px' }}
            >
              <InputLabel
                htmlFor="age-required"
                className={classes.cutom_select_Label}
                required
              >
                Revenue category
              </InputLabel>
              <CustomAutoComplete
                holder="Select Revenue Category"
                value={activeRevenCat || []}
                Options="label"
                data={state?.reveCat}
                style={{ marginbottom: '0' }}
                // Change={(val) => setdata({ ...data, reveCat: val })}
                Change={(val) => dispatch(changerevencat(val))}
              />
            </Grid>

            <Grid container spacing={0}>
              <Grid
                item
                xs={6}
                // className={classes.time_box}
                style={{ paddingRight: '15px', marginTop: '5px' }}
              >
                <InputLabel
                  htmlFor="age-required"
                  className={classes.cutom_select_Label}
                  required
                >
                  Expiration
                </InputLabel>
                <CustomAutoComplete
                  holder="Select Expiration"
                  value={AllSessionlmt}
                  data={state?.attLmt}
                  Options="name"
                  style={{ marginbottom: '0' }}
                  // Change={(val) =>
                  //   setdata({
                  //     ...data,
                  //     attLmt: val,
                  //     attendance_limited_meta: { uses: 0, type: null }
                  //   })
                  // }
                  // Change={(val) => dispatch(changeattlmttype(val))}
                  Change={(val) => dispatch(changeattlmt(val))}
                />
              </Grid>

              <Grid
                item
                xs={6}
                className={classes.time_box}
                style={{ marginTop: '5px' }}
              >
                {state?.attLmt && state?.attLmt.id === 1 && (
                  <Grid container spacing={0}>
                    <Grid
                      item
                      style={{
                        marginTop: '0px',
                        textAlign: 'center',
                        padding: '11px 20px 0 10px',
                        fontWeight: 'bold',
                        width: '100px'
                      }}
                    >
                      <InputLabel
                        htmlFor="age-required"
                        className={classes.cutom_select_Label}
                      >
                        &nbsp;
                      </InputLabel>
                      <label style={{ color: '#000' }}>after</label>
                    </Grid>

                    <Grid
                      item
                      xs
                      // lg
                      className={classes.time_box}
                      style={{ marginTop: '4px', paddingRight: '15px' }}
                    >
                      <FormControl
                        control="input"
                        label=""
                        placeholder="0"
                        required
                        maxLength={10}
                        value={state?.attendance_limited_meta.uses}
                        // onChange={(e) =>
                        //   setdata({
                        //     ...data,
                        //     attendance_limited_meta: {
                        //       ...data.attendance_limited_meta,
                        //       uses: validateInt(e.target.value)
                        //     }
                        //   })
                        // } // eslint-disable-line
                        onChange={(e) =>
                          dispatch(
                            changeattlmtuses(validateInt(e.target.value))
                          )
                        }
                      />
                    </Grid>

                    <Grid
                      item
                      xs
                      // lg
                      className={classes.time_box}
                      style={{ marginTop: '0px' }}
                    >
                      <InputLabel
                        htmlFor="age-required"
                        className={classes.cutom_select_Label}
                      >
                        &nbsp;
                      </InputLabel>
                      <CustomAutoComplete
                        holder="Select Type"
                        value={SessionDay}
                        data={state?.attendance_limited_meta.type}
                        Options="name"
                        style={{ marginbottom: '0' }}
                        // Change={(val) =>
                        //   setdata({
                        //     ...data,
                        //     attendance_limited_meta: {
                        //       ...data.attendance_limited_meta,
                        //       type: val
                        //     }
                        //   })
                        // }
                        Change={(val) => dispatch(changeattlmttype(val))}
                      />
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={0}
          xs={12}
          disableGutters
          direction="row"
          className="stap-3"
        >
          <Grid
            item
            xs
            className={classes.time_box}
            style={{ marginTop: '8px' }}
          >
            <TextareaControl
              rows={8}
              value={state?.desc}
              // onChange={(e) => setdata({ ...data, desc: e.target.value })}
              onChange={(e) => dispatch(changetempdesc(e.target.value))}
              label="Brief description (may be used for public sales)"
              inputProps={{
                maxlength: 1000
              }}
              className={classes.TextareaControlBox}
              placeholder="e.g. Access to any 10 sessions across all our classes and programs"
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

SessionDetailsStep.propTypes = {
  classes: PropType.object.isRequired,
  allrevenCat: PropType.object.isRequired
};

const mapToState = (state) => ({
  allrevenCat: state.SettingReducer.revenueCtgry
});

const mapToDispatch = (dispatch) => ({
  OpenNoti: (msg, type) => dispatch(openNoti(msg, type))
});

export default connect(
  mapToState,
  mapToDispatch
)(withStyles(styles)(SessionDetailsStep));
