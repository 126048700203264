import React, { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { ROUTES } from 'src/constant/Constants';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getReportsListData } from 'src/Redux/ReportsReducer/reports.reducer.actions';
import Reports from './reports/reports';
import ExpiringCreditCards from './reports/FinancialReports/ExpiringCreditCards';
import OverdueAccountReceivable from './reports/FinancialReports/OverdueAccountReceivable';
import AllInvoices from './reports/FinancialReports/AllInvoices';
import RevenuePerMonth from './reports/FinancialReports/RevenuePerMonth';
import ReportsPriority from './reports/ReportsPriority';

function ReportsContainer({ location }) {
  const dispatch = useDispatch();
  const {
    allInvoice,
    expireCards,
    overdueAccounts,
    revenuePerMonth
  } = useSelector((state) => state.masterHqReducer.reports.financialReports);

  const isTabFetched = (tab) => {
    if (tab === 'financial') {
      return (
        (allInvoice.data ||
          expireCards.data ||
          overdueAccounts.data ||
          revenuePerMonth.data) === null
      );
    }
    return false;
  };
  useEffect(() => {
    dispatch(getReportsListData());

    function fetchReports(pathname) {
      for (let i = 0; i < ReportsPriority.length; i += 1) {
        if (
          ReportsPriority[i].url.includes(pathname) &&
          isTabFetched(ReportsPriority[i].tab)
        ) {
          ReportsPriority[i].restfunc.forEach((func) => {
            dispatch(func());
          });
        }
      }
    }
    fetchReports(location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);
  return (
    <Switch>
      <Route
        path={ROUTES.MASTER_HQ_REPORTS_FINANCIAL_REVENUE_PER_MONTH}
        component={RevenuePerMonth}
      />
      <Route
        path={ROUTES.MASTER_HQ_REPORTS_FINANCIAL_OVERDUE_ACCOUNT_RECEIVABLE}
        component={OverdueAccountReceivable}
      />
      <Route
        path={ROUTES.MASTER_HQ_REPORTS_FINANCIAL_EXPIRED_CARDS}
        component={ExpiringCreditCards}
      />
      <Route
        path={ROUTES.MASTER_HQ_REPORTS_FINANCIAL_ALLINVOICES}
        component={AllInvoices}
      />
      <Route path={ROUTES.MASTER_HQ_REPORTS} component={Reports} />
    </Switch>
  );
}
ReportsContainer.PropType = {
  location: PropTypes.object.required
};

export default ReportsContainer;
