import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropType from 'prop-types';
import Button from '@material-ui/core/Button';
import { Breadcrumbs, Grid, InputLabel, Typography } from '@material-ui/core';
import JBDatePicker from 'src/views/common/FormControl/JBDatePicker';
import FormControl from 'src/views/common/FormControl/formControl';
import { withStyles } from '@material-ui/core/styles';
import { get, remove, set } from 'js-cookie';
import { checkValidString } from 'src/utils/validations';
import {
  changeDatereverse,
  checkQuillValid,
  checkValid,
  getValuefromObject
} from 'src/utils/someCommon';
import moment from 'moment';
import { createWOD, getClassWOD } from 'src/services/WODmodule.services';
import LoadingView from 'src/views/common/loading';
import { getErrorMsg } from 'src/utils/ErrorHandling';
import { openNoti } from 'src/Redux/global/global.actions';
import { ReactComponent as BasicInformation } from 'src/assets/image/JBSteppericon/BasicInformation.svg';
import { ReactComponent as TagsandNotes } from 'src/assets/image/JBSteppericon/Workout/TagsNotes.svg';
import JBCKEditor from 'src/views/common/JBCKEditor';
import {
  getCreData,
  getUnisuggest,
  getValidStep1,
  getValidStep2,
  setPriTags,
  setSecTags
} from '../WOD.utils';
import CustomTags from '../../common/CustomTags/CustomTags';
import { getWOD } from '../../../Redux/WODReducers/wodReducer.actions';
import CustomAutoComplete from '../../calendar/commonAutocomplete';
import JBStepper from '../../common/JBGridStepper';
import ColorlibStepIcon from '../../common/JBGridStepper/ColorlibStepIcon';
import CancelDialog from '../../common/CommonDialogBox/CancelDialog';
import Table from '../Table';
import styles from './Wod.style';
import { ROUTES } from '../../../constant/Constants';
import '../style.css';
import CancelDialogTwo from '../../common/CommonDialogBox/CancelDialogTwo';
// import BasicInfo from './basicInfo';
import useTitleChange from '../../common/CustomHooks/useTitleChange';

function getSteps() {
  return ['Basic Information', 'Tags & Notes'];
}

const Wod = (props) => {
  const {
    classes,
    programs: { isFetching, programs },
    history,
    openNotify,
    reloadWOD,
    wodTags
  } = props;

  useTitleChange('Create New Workout | Jungle Alliance');
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  let cookiewod = get('create_workout');
  if (cookiewod) {
    cookiewod = JSON.parse(cookiewod || '{}');
  }
  const [pid, setPid] = useState(cookiewod?.pid || null);
  const [baseinfo, setBaseinfo] = useState({
    name: cookiewod?.name || '',
    classes_id: null
  });
  const [program, setProgram] = useState({
    data: null,
    err: false
  });
  const [Notes, setNotes] = useState({
    tags: [],
    secondary_tags: [],
    workout_details: '',
    coach_notes: ''
  });
  const [date, setDate] = useState(
    cookiewod?.date ? new Date(cookiewod.date) : new Date()
  );
  const [isprocessing, setprocessing] = useState(false);
  // const [validErrors,seErrors] = useState(false);
  const [cancelDialog, setcancelDialog] = useState(false);
  useEffect(() => {
    if (date && pid) {
      getClassWOD(pid, changeDatereverse(date))
        .then((res) => setProgram({ data: res }))
        .catch(() => {
          setProgram({ err: true });
          setBaseinfo({ ...baseinfo, classes_id: null });
        });
    }
  }, [date, pid]); // eslint-disable-line

  if (isFetching || !programs) return <LoadingView />;

  const progVal = pid ? programs.filter((prog) => prog.id === pid)[0] : '';

  const handleCreateWOD = () => {
    const data = getCreData(baseinfo, date, pid, Notes);
    setprocessing(true);
    createWOD(data)
      .then(() => reloadWOD())
      .then(() => history.push(ROUTES.WORKOUT_LIST))
      .then(() => remove('create_workout'))
      .then(() => {
        openNotify('Your Workout is been created successfully', 'info');
        setprocessing(false);
      })
      .catch((err) => {
        openNotify(getErrorMsg(err), 'error');
        setprocessing(false);
      });
  };

  const handleNext = () => {
    if (activeStep === 0 && (!checkValid(baseinfo) || !date || !pid)) {
      openNotify('Fill all the fields', 'error');
    } else if (checkValidString(baseinfo.name)) {
      remove('create_workout');
      openNotify('Please provide valid workout name', 'error');
    } else if (activeStep === 1) {
      if (!checkQuillValid(Notes.workout_details)) {
        openNotify('Workout Details is required field', 'error');
      } else handleCreateWOD();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleAddition = (values, type) => {
    if (type === 'primary') {
      const { error, data } = setPriTags(
        Notes.tags,
        Notes.secondary_tags,
        values
      );
      if (error) {
        openNotify(error, 'error');
      } else {
        setNotes({
          ...Notes,
          tags: data
        });
      }
    } else {
      const { error, data } = setSecTags(
        Notes.secondary_tags,
        Notes.tags,
        values
      );
      if (error) {
        openNotify(error, 'error');
      } else {
        setNotes({
          ...Notes,
          secondary_tags: data
        });
      }
    }
  };

  const handleDeletion = (i, type) => {
    if (type === 'primary') {
      setNotes({
        ...Notes,
        tags: Notes.tags.filter((tag, index) => index !== i)
      });
    } else {
      setNotes({
        ...Notes,
        secondary_tags: Notes.secondary_tags.filter((tag, index) => index !== i)
      });
    }
  };

  const handleCreateClass = () => {
    const values = {
      name: baseinfo.name,
      pid,
      date: moment(date).format('MM/DD/YYYY')
    };
    set('create_workout', values, {
      expires: moment()
        .add(10, 'minutes')
        .toDate()
    });
    history.push(ROUTES.CLASSES_CREATE_NEW_CLS);
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Grid
            item
            xs
            container
            spacing={0}
            // disableGutters
            direction="column"
            style={{ margin: '0 0' }}
          >
            <h3 style={{ lineHeight: 'normal' }}>
              Enter basic information about the workout.
            </h3>
            <form className="stap-form" noValidate autoComplete="off">
              <Grid item xs={12} style={{ margin: '0 0' }}>
                <Grid item xs={12}>
                  <FormControl
                    control="input"
                    seterrors={checkValidString(baseinfo.name)}
                    seterrortext="Please enter valid Workout name"
                    label="Workout name"
                    placeholder="Workout"
                    value={baseinfo.name}
                    required
                    onChange={(e) =>
                      setBaseinfo({
                        ...baseinfo,
                        name: e.target.value
                      })
                    }
                  />
                </Grid>
              </Grid>

              <div className="clearfix" />
              <Grid
                container
                spacing={0}
                // disableGutters
                style={{ margin: '0 0' }}
              >
                <Grid
                  item
                  xs={6}
                  style={{ marginTop: '4px' }}
                  className="program-input"
                >
                  <JBDatePicker
                    label="Date"
                    disablePast
                    value={date}
                    required
                    onChange={(val) => setDate(val)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputLabel
                    htmlFor="age-required"
                    className={classes.cutom_select_Label}
                    required
                  >
                    Program
                  </InputLabel>
                  <CustomAutoComplete
                    holder="Select Program"
                    value={programs}
                    data={progVal}
                    Options="name"
                    style={{ marginbottom: '0' }}
                    Change={(val) => setPid(getValuefromObject(val))}
                    seterrors={!progVal}
                    seterrortext="Please Select the Program"
                  />
                </Grid>

                {program.data && (
                  <div style={{ width: '100%' }}>
                    <h3
                      style={{
                        marginBottom: '20px',
                        marginTop: '20px',
                        lineHeight: 'normal'
                      }}
                    >
                      Select classes for this workout
                    </h3>
                    <Table
                      values={program.data.data}
                      initialId={cookiewod?.class_id}
                      getSelected={(val) =>
                        setBaseinfo({ ...baseinfo, classes_id: val.join(',') })
                      }
                    />
                  </div>
                )}
                {program.err && (
                  <Grid item xs={12} style={{ marginTop: '30px' }}>
                    <h3>There are no classes for this program for this date</h3>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={handleCreateClass}
                      style={{
                        width: '131px',
                        height: '48px',
                        marginTop: '30px',
                        fontWeight: '700'
                      }}
                    >
                      Create class
                    </Button>
                  </Grid>
                )}
              </Grid>
            </form>
          </Grid>
        );
      case 1:
        return (
          <div>
            <h3 style={{ marginBottom: '15px' }}>
              Add tags and notes to the workout.
            </h3>
            <form className="stap-form" noValidate autoComplete="off">
              <Grid item xs={12} style={{ marginBottom: '20px' }}>
                <InputLabel
                  htmlFor="age-required"
                  className={classes.cutom_select_Label}
                >
                  Primary tags
                </InputLabel>
                <CustomTags
                  autofocus
                  tags={Notes.tags}
                  suggestions={getUnisuggest(
                    wodTags,
                    Notes.secondary_tags,
                    Notes.tags
                  )}
                  handleAddition={(val) => handleAddition(val, 'primary')} // eslint-disable-line
                  handleDelete={(i) => handleDeletion(i, 'primary')} // eslint-disable-line
                />
              </Grid>
              <Grid item xs={12} style={{ marginBottom: '20px' }}>
                <InputLabel
                  htmlFor="age-required"
                  className={classes.cutom_select_Label}
                >
                  Secondary tags
                </InputLabel>
                <CustomTags
                  autofocus={false}
                  tags={Notes.secondary_tags}
                  suggestions={getUnisuggest(
                    wodTags,
                    Notes.tags,
                    Notes.secondary_tags
                  )}
                  handleAddition={(val) => handleAddition(val, 'secondary')} // eslint-disable-line
                  handleDelete={(i) => handleDeletion(i, 'secondary')} // eslint-disable-line
                />
              </Grid>
              <div className="clearfix" />
              <Grid item xs={12} style={{ marginBottom: '20px' }}>
                <InputLabel
                  htmlFor="age-required"
                  className={classes.cutom_select_Label}
                  required
                >
                  Workout details
                </InputLabel>
                <JBCKEditor
                  data={Notes.workout_details}
                  onChange={(e) =>
                    setNotes({
                      ...Notes,
                      workout_details: e.editor.getData()
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} style={{ marginBottom: '20px' }}>
                <InputLabel
                  htmlFor="age-required"
                  className={classes.cutom_select_Label}
                >
                  Coaches note
                </InputLabel>
                <JBCKEditor
                  data={Notes.coach_notes}
                  onChange={(e) =>
                    setNotes({ ...Notes, coach_notes: e.editor.getData() })
                  }
                />
              </Grid>
            </form>
          </div>
        );
      default:
        return 'Unknown step';
    }
  };

  return (
    <div className={classes.root}>
      <div className="title-bk">
        <Breadcrumbs
          aria-label="breadcrumb"
          style={{
            margin: '0px 0 21px'
          }}
        >
          <div color="inherit">Workouts</div>
          <div
            color="inherit"
            onClick={() => setcancelDialog(true)}
            style={{
              cursor: 'pointer'
            }}
          >
            List
          </div>
          <Typography variant="h3" color="primary">
            <span>Create new workout</span>
          </Typography>
        </Breadcrumbs>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={0}
          style={{ margin: '0 0 20px' }}
        >
          <Grid item xs={6}>
            <h1 style={{ margin: '0 0' }}>Create New Workout</h1>
          </Grid>
          <Grid item xs={6} className={classes.btnBlock}>
            <CancelDialog />
          </Grid>
        </Grid>
      </div>
      <JBStepper
        steps={steps}
        activeStep={activeStep}
        handleBack={handleBack}
        handleNext={handleNext}
        handleReset={handleReset}
        handleStepContent={getStepContent}
        isLoading={false}
        hasErrored={false}
        validateBtn={[
          getValidStep1(baseinfo, date, pid, Notes),
          getValidStep2(Notes)
        ]}
        prevLabel="CREATE"
        nextLabel="NEXT STEP"
        isprocessing={isprocessing}
        processingLabel="Creating..."
        CustComp={(prop) => (
          <ColorlibStepIcon
            FirstIcon={BasicInformation}
            SecondIcon={TagsandNotes}
            // new added
            ThirdIcon={TagsandNotes}
            FourthIcon={TagsandNotes}
            FifthIcon={TagsandNotes}
            {...prop} // eslint-disable-line
          />
        )}
      />
      <CancelDialogTwo
        setOpen={cancelDialog}
        handleClose={() => setcancelDialog(false)}
        handleBack={() => {
          remove('create_workout');
          history.goBack();
        }}
      />
    </div>
  );
};

Wod.defaultProps = {
  wodTags: []
  // data: null
};

Wod.propTypes = {
  classes: PropType.object.isRequired,
  programs: PropType.any.isRequired,
  history: PropType.any.isRequired,
  openNotify: PropType.func.isRequired,
  reloadWOD: PropType.func.isRequired,
  wodTags: PropType.array
  // wodTags: PropType.array.isRequired
};

const mapToState = (state) => ({
  programs: state.allprograms,
  wodTags: state.wodList.tags
});
const mapToDispatch = (dispatch) => ({
  openNotify: (mess, type) => dispatch(openNoti(mess, type)),
  reloadWOD: () => dispatch(getWOD())
});

export default connect(mapToState, mapToDispatch)(withStyles(styles)(Wod));
