const styles = (theme) => ({
  root: {
    marginTop: 0,
    margin: '0px 20px 20px',
    padding: '0px 20px 20px'
    // Do not remove below media css
    // '@media (max-width: 1440px)': {
    //   '& .title-bk h3': {
    //     fontSize: '18px'
    //   }
    // }
  },

  mainCard: {
    boxShadow: '4px 0px 16px rgba(37, 40, 43, 0.08)',
    borderRadius: '5px',
    background: theme.masterhq.mainCard.background,
    padding: 20
  },
  ptext: {
    fontSize: '14px',
    lineHeight: '24px',
    color: '#7F7F7F',
    fontWeight: 'normal'
  },

  p_tabs: {
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px',
    color: '#ffffff'
  },
  table: {
    fontSize: '14px',
    lineHeight: '22px'
  },
  table_th: {
    backgroundColor: '#F9F9FA',
    color: '#292929'
  },

  cutom_select_Label: {
    fontSize: '12px',
    '&:focus ': {
      outline: '0'
    }
  },
  wrapperBox: {
    border: '1px solid #EAEAEA',
    borderRadius: '8px',
    padding: '25px',
    minHeight: '132px',
    [theme.breakpoints.down('lg')]: {
      padding: '20px'
    }
  },
  InactiveBox: {
    border: '1px solid #EAEAEA',
    color: '#292929',
    width: 'auto',
    maxWidth: '96px',
    height: '24px',
    fontWeight: 'bold',
    fontSize: '11px',
    lineHeight: '16px',
    padding: '4px 12px',
    borderRadius: '8px',
    display: 'inline-table',
    textAlign: 'center',
    background: '#F9F9FA'
  },
  wraBoxTitle: {
    display: 'inline-flex',
    width: '100%',
    justifyContent: 'space-between'
  },
  ArrowLeft: {
    borderRadius: '50%',
    padding: '0 0',
    minWidth: 'auto',
    marginRight: '15px'
  },
  ckControlLabel: {
    '& span': {
      color: '#949494'
    }
  },
  renewaldayBox: {
    margin: '0 0',
    display: 'inline-flex',
    width: '77px',
    verticalAlign: 'middle',
    marginRight: '10px'
  },
  masterhqAutocomplete: {
    '& .MuiAutocomplete-root': {
      color: '#ffffff',
      background: 'rgba(255, 255, 255, 0.1)',
      borderRadius: '2px',
      '& .MuiIconButton-root': {
        color: 'rgb(255 255 255 / 100%)'
      }
    }
  }
});
export default styles;
