export const GymdateFormat = [
  { id: 1, format: 'd/m/Y', value: 'DD/MM/YYYY' },
  { id: 2, format: 'd-m-Y', value: 'DD-MM-YYYY' },
  { id: 3, format: 'm/d/Y', value: 'MM/DD/YYYY' },
  { id: 4, format: 'm-d-Y', value: 'MM-DD-YYYY' },
  { id: 5, format: 'Y/m/d', value: 'YYYY/MM/DD' },
  { id: 6, format: 'Y-m-d', value: 'YYYY-MM-DD' }
];

export const GymweekDay = [
  { id: 1, value: 'Monday' },
  { id: 2, value: 'Tuesday' },
  { id: 3, value: 'Wednesday' },
  { id: 4, value: 'Thursday' },
  { id: 5, value: 'Friday' },
  { id: 6, value: 'Saturday' },
  { id: 7, value: 'Sunday' }
];

export const Gymagelimit = [
  { id: 15, value: '15' },
  { id: 16, value: '16' },
  { id: 17, value: '17' },
  { id: 18, value: '18' },
  { id: 19, value: '19' },
  { id: 20, value: '20' },
  { id: 21, value: '21' }
];

export const GymTimeFormat = {
  twelve: '12',
  twentyfour: '24'
};
