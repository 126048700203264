import React from 'react';
import PropType from 'prop-types';

const Customevent = (props) => {
  const { event } = props;
  return (
    <div className="customevent">
      <div className="eventclassname">{event.class_name}</div>
      <div className="eventcoachname">
        {event.head_coach ? event.head_coach.name : null}
      </div>
    </div>
  );
};

Customevent.propTypes = {
  event: PropType.object.isRequired
};

export default Customevent;
