import React from 'react';
import PropType from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  DialogTitle,
  withStyles,
  Box
} from '@material-ui/core';
import FormControl from 'src/views/common/FormControl/formControl';
import { useDispatch } from 'react-redux';
import { openNoti } from 'src/Redux/global/global.actions';
import JBButton from '../../common/JBButton/JBButton';

const styles = () => ({
  Deletebtn: {
    background: '#FB4E4E',
    boxShadow: '0px 8px 16px rgba(251, 78, 78, 0.16)',
    borderRadius: '2px',
    height: '48px',
    padding: '10px 15px',
    color: '#fff',
    fontWeight: '700',
    '&:hover': {
      background: '#FB4E4E'
    }
  },
  Dialogtitle: {
    padding: '16px 24px 10px'
  },
  DialogActionsBk: {
    padding: '20px 20px 20px'
  }
});

function EditReason({
  values,
  classes,
  editlabel,
  seteditlabel,
  handleEdit,
  handleClose,
  setOpen
}) {
  // const [editlabel, seteditlabel] = useState('');
  const reduxDispatch = useDispatch();
  return (
    <div>
      <Dialog
        open={setOpen}
        onClose={() => handleClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick
      >
        <div style={{ width: '394px' }}>
          <DialogTitle id="alert-dialog-title" className={classes.Dialogtitle}>
            <Typography
              color="#000"
              variant="h5"
              component="div"
              className={classes.title}
              style={{ display: 'inline-block' }}
            >
              Edit Reason
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Box>
              <FormControl
                control="input"
                placeholder=""
                label="Reason label"
                margin="none"
                value={values.editlabel}
                onChange={(e) => {
                  seteditlabel(e.target.value);
                }}
                // style={{ margin: '0 0' }}
              />
            </Box>
          </DialogContent>
          <DialogActions className={classes.DialogActionsBk}>
            <Button
              variant="outlined"
              onClick={handleClose}
              style={{
                height: '48px',
                color: '#7F7F7F',
                fontWeight: 'bold'
              }}
            >
              Cancel
            </Button>
            <JBButton
              disabled={!values.editlabel}
              onClick={() => {
                handleEdit(editlabel, values.id);
                reduxDispatch(openNoti('Updated Successfully'));
                handleClose();
              }}
              type="primary"
            >
              Save
            </JBButton>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}

EditReason.propTypes = {
  classes: PropType.object.isRequired,
  values: PropType.object.isRequired,
  setOpen: PropType.bool.isRequired,
  editlabel: PropType.object.isRequired,
  seteditlabel: PropType.object.isRequired,
  handleEdit: PropType.func.isRequired,
  handleClose: PropType.func.isRequired
};
export default withStyles(styles)(EditReason);
