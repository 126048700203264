import { DataObj, FilterObj } from 'src/Redux/reducer.default.data';
import { getMinDate } from 'src/utils/someCommon';

export default {
  activeTab: 0,
  userdetails: DataObj,
  paymentBilling: {
    stripe_connect: {
      status: null
    },
    payment_method: DataObj,
    past_invoices: DataObj,
    past_invoices_filter: FilterObj()
  },
  subscription_details: {
    plan_details: {
      real_time_users: 20,
      overall_max_usres: 20,
      all_plans: DataObj,
      all_plans_filter: FilterObj()
    },
    discounts: DataObj,
    discount_filter: FilterObj(),
    discount: {
      discount_type: 'percentage',
      discount_value: 0,
      discount_amount: 0,
      discount_valid_type: null,
      no_of_month: 0,
      end_date: getMinDate(new Date())
    },
    transactions: DataObj
  }
};
