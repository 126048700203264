import React from 'react';
import PropType from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import { openNoti } from 'src/Redux/global/global.actions';
import { DelMembership } from 'src/services/Membersip.services';
import { getErrorMsg } from 'src/utils/ErrorHandling';
import {
  getplanMem,
  getSession
} from 'src/Redux/MembershipReducer/MembershipReducer.actions';
import JBButton from '../common/JBButton/JBButton';
import X from '../../assets/image/x.png';

const styles = () => ({
  Deletebtn: {
    background: '#FB4E4E',
    boxShadow: '0px 8px 16px rgba(251, 78, 78, 0.16)',
    borderRadius: '2px',
    height: '48px',
    padding: '10px 15px',
    color: '#fff',
    fontWeight: 'bold',
    '&:hover': {
      background: '#FB4E4E'
    }
  },
  Dialogtitle: {
    padding: '16px 15px 0'
  },
  DialogActionsBk: {
    padding: '5px 20px 20px'
  }
});

function DeleteDialog(props) {
  const { classes, id, name } = props;
  const [open, setOpen] = React.useState(false);
  const history = useHistory();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    const { OpenNoti, sessMemReload, planMemReload } = props;

    DelMembership(id)
      .then(() => OpenNoti('Successfully delete Membership', 'info'))
      .then(() => history.goBack())
      .then(() => sessMemReload())
      .then(() => planMemReload())
      .catch((err) => OpenNoti(getErrorMsg(err), 'error'));
  };

  return (
    <div>
      <JBButton type="delete" onClick={handleClickOpen}>
        DELETE
      </JBButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div style={{ width: 394 }}>
          <DialogTitle id="alert-dialog-title" className={classes.Dialogtitle}>
            <img
              alt="Plusicon"
              src={X}
              style={{ display: 'inline-block', verticalAlign: 'sub' }}
            />
            <Typography
              color="#000"
              variant="h5"
              component="div"
              className={classes.title}
              style={{ display: 'inline-block', marginLeft: '10px' }}
            >
              {/* Delete payment plan */}
              {name === 'plan'
                ? 'Delete plan membership template'
                : 'Delete session membership template'}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <p
              style={{
                fontWeight: 'normal',
                fontSize: '14px',
                lineHeight: '18px',
                letterSpacing: '0.002em',
                color: '#7F7F7F',
                padding: '10px 0'
              }}
            >
              Are you sure you want to permanently delete this {name} membership
              template?
            </p>
          </DialogContent>
          <DialogActions className={classes.DialogActionsBk}>
            <Button
              variant="outlined"
              onClick={handleClose}
              style={{
                height: '48px',
                color: '#7F7F7F',
                fontWeight: 'bold'
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleDelete}
              autoFocus
              className={classes.Deletebtn}
            >
              delete
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}

DeleteDialog.propTypes = {
  classes: PropType.object.isRequired,
  id: PropType.number.isRequired,
  OpenNoti: PropType.func.isRequired,
  sessMemReload: PropType.func.isRequired,
  planMemReload: PropType.func.isRequired
};

const mapToDispatch = (dispatch) => ({
  OpenNoti: (msg, type) => dispatch(openNoti(msg, type)),
  planMemReload: () => dispatch(getplanMem()),
  sessMemReload: () => dispatch(getSession())
});

export default connect(null, mapToDispatch)(withStyles(styles)(DeleteDialog));
