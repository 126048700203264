import React, { useEffect, useState } from 'react';
import PropType from 'prop-types';
import { connect } from 'react-redux';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  withStyles,
  Grid,
  Box,
  OutlinedInput,
  InputAdornment,
  Radio,
  RadioGroup,
  FormControlLabel
} from '@material-ui/core';
import DiscounFormControl from '@material-ui/core/FormControl';
import { validateInt } from 'src/utils/validations';
import { openNoti } from 'src/Redux/global/global.actions';
import { addInvDiscnt } from 'src/services/Invoice.services';
import { getErrorMsg } from 'src/utils/ErrorHandling';
import dcloseicon from '../../../../assets/image/closeicon.png';
import JBButton from '../../../common/JBButton/JBButton';

const styles = () => ({
  dtitle: {
    color: '#292929',
    fontWeight: 'bold',
    fontSize: '33px',
    lineHeight: '42px'
  },
  titlebk: {
    display: 'inline-block',
    padding: '25px 24px 5px'
  },
  cutom_select_Label: {
    fontSize: '12px',
    padding: '7px 0'
  },
  Discountinput: {
    margin: '0px',
    display: 'inline-flex',
    width: '97px',
    marginLeft: '15px',
    border: '1px solid #a9a9a9',
    height: '48px',
    padding: '9px 15px 7px'
  },
  DiscounFormControl: {
    width: '95px',
    height: '48px',
    marginLeft: '20px',
    '& .MuiOutlinedInput-input': {
      padding: '14.5px 8px'
    }
  },
  Discounsign: {
    '& p': {
      color: 'rgb(41 41 41 / 0.4)'
    }
  }
});

function DiscountDiag(props) {
  const {
    classes,
    handleClose,
    setOpen,
    invoiceId,
    // currency,
    initials
  } = props;
  const [data, setdata] = useState({
    discount: 0,
    discount_type: '2'
  });
  useEffect(() => {
    if (initials) {
      setdata(initials);
    }
  }, [initials]);
  const handleSave = () => {
    const { updateData, OpenNoti } = props;
    if (data.discount === '') {
      OpenNoti('Discount cant be empty', 'error');
    } else {
      const val = {
        invoice_discount: data.discount,
        discount_type: data.discount_type === '2' ? 'percentage' : 'fixed',
        invoice_id: invoiceId
      };
      addInvDiscnt(val)
        .then((res) => {
          updateData(res);
        })
        .then(() => handleClose())
        .catch((err) => {
          console.log(err);
          OpenNoti(getErrorMsg(err), 'error');
        });
    }
  };

  return (
    <div>
      <Dialog
        open={setOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div style={{ width: '548px' }}>
          <Grid alignItems="center" container direction="row">
            <Grid item xs>
              <DialogTitle id="alert-dialog-title" className={classes.titlebk}>
                <h4 className={classes.dtitle}>
                  Apply a Discount to Invoice #
                  {String(invoiceId).padStart(7, '0')}
                </h4>
              </DialogTitle>
            </Grid>
            <Grid
              item
              xs={2}
              style={{ textAlign: 'right', marginRight: '10px' }}
            >
              <Button className={classes.btns} onClick={handleClose}>
                <img alt="img" src={dcloseicon} />
              </Button>
            </Grid>
          </Grid>

          <DialogContent>
            <Grid container spacing={2} style={{ marginTop: '5px' }}>
              <RadioGroup
                value={data.discount_type}
                onChange={(e) =>
                  setdata({ ...data, discount_type: e.target.value })
                }
                style={{
                  padding: '0px 10px'
                }}
              >
                {/* <Box display="inline-flex" mb="15px">
                  <FormControlLabel
                    value="1"
                    control={<Radio color="primary" />}
                    label="Fix value"
                    disabled
                    className={classes.LabelControl}
                  />
                  <DiscounFormControl
                    className={classes.DiscounFormControl}
                    variant="outlined"
                    control="input"
                  >
                    <OutlinedInput
                      id="outlined-adornment-weight"
                      value={data.discount_type === '1' ? data.discount : 0}
                      // disabled={data.discount_type !== '1'}
                      disabled
                      onChange={(e) =>
                        setdata({ ...data, discount: e.target.value })
                      }
                      endAdornment={
                        <InputAdornment
                          position="end"
                          className={classes.Discounsign}
                        >
                          {currency ? currency.currency_symbol : 'A$'}
                        </InputAdornment>
                      }
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        'aria-label': 'weight'
                      }}
                      labelWidth={0}
                    />
                  </DiscounFormControl>
                </Box> */}
                <Box display="inline-flex">
                  <FormControlLabel
                    value="2"
                    control={<Radio color="primary" />}
                    label="Percentage"
                    className={classes.LabelControl}
                  />
                  <DiscounFormControl
                    className={classes.DiscounFormControl}
                    variant="outlined"
                    control="input"
                    style={{
                      marginLeft: '5px'
                    }}
                  >
                    <OutlinedInput
                      id="outlined-adornment-weight"
                      value={data.discount_type === '2' ? data.discount : 0}
                      disabled={data.discount_type !== '2'}
                      onChange={(e) =>
                        setdata({
                          ...data,
                          discount: validateInt(e.target.value)
                        })
                      }
                      endAdornment={
                        <InputAdornment
                          position="end"
                          className={classes.Discounsign}
                        >
                          %
                        </InputAdornment>
                      }
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        'aria-label': 'weight'
                      }}
                      labelWidth={0}
                    />
                  </DiscounFormControl>
                </Box>
              </RadioGroup>
            </Grid>
          </DialogContent>
          <DialogActions
            style={{
              padding: '20px 20px'
            }}
          >
            <JBButton type="primary" onClick={handleSave}>
              Save Discount
            </JBButton>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}

DiscountDiag.defaultProps = {
  initials: null
};

DiscountDiag.propTypes = {
  classes: PropType.object.isRequired,
  setOpen: PropType.bool.isRequired,
  handleClose: PropType.func.isRequired,
  invoiceId: PropType.number.isRequired,
  updateData: PropType.func.isRequired,
  OpenNoti: PropType.func.isRequired,
  // currency: PropType.object.isRequired,
  initials: PropType.object
};

const mapToState = (state) => ({
  currency: state.SettingReducer.currency.data
});

const mapToDispatch = (dispatch) => ({
  OpenNoti: (msg, type) => dispatch(openNoti(msg, type))
});

export default connect(
  mapToState,
  mapToDispatch
)(withStyles(styles)(DiscountDiag));
