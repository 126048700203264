import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& .MuiTable-stickyHeader': {
      borderCollapse: 'collapse'
    }
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: '100%',
    width: '100%'
  },
  table_th: {
    backgroundColor: '#F9F9FA',
    color: '#292929'
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  },
  TabCell: {
    color: '#000',
    fontWeight: 'bold',
    borderBottom: '1px solid rgb(168, 168, 168)'
  },
  searchInput: {
    padding: '6px 20px 6px !important;',
    paddingRight: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    height: '35px',
    border: '1px solid #a9a9a9'
  },
  Tablemian: {
    maxHeight: '350px'
  }
}));
export default useStyles;
