import {
  validStringRegEx,
  validPhoneRegEx,
  isPhoneNumberInvalid,
  validateEmail,
  postalRegEx
} from 'src/utils/validations';
import moment from 'moment';

export const validateVanguardWaiver = (data) => {
  if (!data.first_name.trim().length) {
    throw Error('First Name is Required');
  } else if (!data.last_name.trim().length) {
    throw Error('Last Name is Required');
  } else if (!data.email) {
    throw Error('Email is required');
  } else if (data?.email && !validateEmail(data?.email)) {
    throw Error('Please enter a valid Email');
  } else if (!data.gender) {
    throw Error('Gender is required');
  } else if (!data.mobile_no) {
    throw Error('Phone Number is required');
  } else if (data?.mobile_no && !validPhoneRegEx.test(data?.mobile_no)) {
    throw Error('Please enter a valid Mobile number');
  } else if (isPhoneNumberInvalid(data?.mobile_no)) {
    throw Error('Mobile number must be between 10 and 15 digits');
  } else if (!data?.dob) {
    throw Error('Date of birth is required');
  } else if (!data.address) {
    throw Error('Street address is required');
  } else if (!data.city) {
    throw Error('City is required');
  } else if (data.city.length > 0 && !validStringRegEx.test(data?.city)) {
    throw Error('Please enter a valid City');
  } else if (!data.province) {
    throw Error('Province/Region is required');
  } else if (
    data.province.length > 0 && // eslint-disable-line
    !validStringRegEx.test(data?.province)
  ) {
    throw Error('Please enter a valid Province/Region');
  } else if (!data.postal_code) {
    throw Error('Postal code is required');
  } else if (!data.country) {
    throw Error('Country is required');
  } else if (!postalRegEx.test(data.postal_code)) {
    throw Error('Please enter a valid Postal Code');
  } else if (data.country && !validStringRegEx.test(data?.country)) {
    throw Error('Please enter a valid Country');
  } else if (!data.eme_contact_name || data.eme_contact_name.length === 0) {
    throw Error('Emergency Contact Name is required');
  } else if (!data.eme_contact_phone) {
    throw Error('Emergency Contact Phone is required');
  } else if (
    data?.eme_contact_phone && //eslint-disable-line
    !validPhoneRegEx.test(data?.eme_contact_phone)
  ) {
    throw Error('Please enter a valid Emergency Contact Phone');
  } else if (isPhoneNumberInvalid(data?.eme_contact_phone)) {
    throw Error('Emergency Contact Phone must be between 10 and 15 digits');
  }
};

export const validateQues = (array) => {
  let isError = false;
  for (let i = 0; i < array.length; i += 1) {
    if (
      // eslint-disable-next-line operator-linebreak
      array[i].required === 1 &&
      (Number.isInteger(array[i].ans) || array[i]?.ans?.length > 0)
    ) {
      isError = false;
    } else {
      isError = true;
    }
  }
  return isError;
};

export const getVanguardUserData = (data) => {
  return {
    ...data,
    emergency_contact_name: data.eme_contact_name,
    emergency_contact_phone: data.eme_contact_phone,
    dob: moment(data.dob).format('YYYY-MM-DD')
  };
};

export const getWaiverData = (data) => {
  return {
    user_id: data.id,
    first_name: data.first_name,
    last_name: data.last_name,
    email: data.email,
    gender: data.gender,
    address: data.address,
    city: data.city,
    province: data.province,
    country: data.country,
    postal_code: data.postal_code,
    mobile_no: data.mobile_no,
    dob: moment(data.dob).format('YYYY-MM-DD'),
    eme_contact_name: data.eme_contact_name,
    eme_contact_phone: data.eme_contact_phone,
    waiver_terms_initial: JSON.stringify(data.waiver_terms_initial),
    questionnaire_meta: JSON.stringify(data.questionnaire_meta),
    signature: data.signature
  };
};

export const updateVanguardUserData = (data) => ({
  user_id: data.id,
  first_name: data.first_name,
  last_name: data.last_name,
  email: data.email,
  gender: data.gender,
  address: data.address,
  city: data.city,
  province: data.province,
  country: data.country,
  postal_code: data.postal_code,
  mobile_no: data.mobile_no,
  dob: moment(data.dob).format('YYYY-MM-DD'),
  eme_contact_name: data.eme_contact_name,
  eme_contact_phone: data.eme_contact_phone
});
