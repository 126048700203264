const styles = (theme) => ({
  root: {
    marginTop: 0,
    margin: '0px 20px 20px',
    padding: '0px 20px 20px'
  },
  mainCard: {
    boxShadow: '4px 0px 16px rgba(37, 40, 43, 0.08)',
    borderRadius: '5px',
    background: '#FFFF',
    padding: 30
  },
  cutom_select_Label: {
    fontSize: '12px',
    padding: '7px 0'
  },
  ptext: {
    fontSize: '16px',
    lineHeight: '24px',
    color: '#7F7F7F',
    fontWeight: 'normal'
  },
  p_tabs: {
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px'
  },
  table: {
    fontSize: '14px',
    lineHeight: '22px'
  },
  table_th: {
    backgroundColor: '#F9F9FA',
    color: '#292929'
  },
  TableCell: {
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px',
    borderBottom: '1px solid #A8A8A8',
    height: '56px',
    minHeight: '56px'
  },
  Table_td: {
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '22px',
    position: 'relative',
    padding: '12px 15px 13px 25px',
    height: '56px',
    minHeight: '56px'
  },
  colorcode: {
    width: '10px',
    height: '10px',
    position: 'absolute',
    left: '0',
    top: '21px',
    borderRadius: '50%'
  },
  button_status: {
    fontWeight: 'bold',
    fontSize: '11px'
  },
  button_status_act: {
    fontWeight: 'bold',
    fontSize: '11px',
    color: '#9B9B9B',
    border: '1px solid #949494',
    width: '100px',
    height: '24px',
    padding: '0 0',
    borderRadius: '2px',
    '&$hover': {
      backgroundColor: '#1976d2'
    }
  },
  button_status_deac: {
    fontWeight: 'bold',
    fontSize: '11px',
    color: '#01847A',
    border: '1px solid rgba(1, 132, 122, 1)',
    width: '100px',
    height: '24px',
    padding: '0 0',
    borderRadius: '2px'
  },
  selected: {
    backgroundColor: 'red !important'
  },
  search: {
    position: 'relative',
    height: '48px',
    marginLeft: 0,
    width: '100%',
    marginBottom: '21px'
  },
  searchInput: {
    padding: '6px 20px 6px !important;',
    paddingRight: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    height: '35px',
    border: '1px solid #a9a9a9'
  },
  reminderNoti: {
    background: 'rgb(1 132 122 / 0.1)',
    borderRadius: '2px',
    color: '#01847A',
    padding: '15px',
    display: 'inline-block',
    border: '1px solid #01847A',
    fontSize: '14px',
    fontWeight: '700'
  },
  checkBoxTable: {
    '& thead th': {
      padding: '7px 5px'
    },
    '& thead th:nth-child(2)': {
      paddingLeft: '15px'
    }
  },
  cutom_input_css: {
    '& .fd-input input': {
      marginTop: '0px'
    }
  },
  UpdateInfo: {
    marginLeft: '40px',
    marginTop: '10px',
    marginBottom: '10px',
    '& ::marker': {
      color: '#37847a',
      fontSize: '18px'
    },
    '& li': {
      padding: '3px 0'
    }
  },
  disabledSwitch: {
    '& .MuiSwitch-track': {
      backgroundColor: 'rgb(189 189 189) !important'
    }
  },
  Avataruname: {
    fontWeight: 'bold',
    backgroundColor: '#CCE6E4',
    color: '#37847a'
  },
  Deletebtn: {
    background: '#FB4E4E',
    boxShadow: '0px 8px 16px rgba(251, 78, 78, 0.16)',
    borderRadius: '2px',
    height: '48px',
    padding: '10px 15px',
    color: '#fff',
    fontWeight: '700',
    border: '1px solid #f44336',
    '&:hover': {
      background: '#FB4E4E'
    }
  },
  Dialogtitle: {
    padding: '16px 24px 0'
  },
  DialogActionsBk: {
    padding: '5px 20px 20px'
  }
});
export default styles;
