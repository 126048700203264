const getClass = (type, classes) => {
  switch (type) {
    case 'primary':
      return classes.buttonprimary;
    case 'delete':
      return classes.deletebutton;
    case 'outlined':
      return classes.Outlinebutton;
    default:
      return null;
  }
};
export default getClass;
