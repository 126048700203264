import React, { PureComponent } from 'react';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import PropType from 'prop-types';
import {
  ActiveProgram,
  InActiveProgram
} from 'src/Redux/programReducers/progranReducer.actions';
import Program from '../views/program/Program';
import ProgramStatus from '../views/program/Programstatus';
import EditProgram from '../views/program/EditProgram';

class ProgramContainer extends PureComponent {
  componentWillMount() {
    const { getActiveProgram, getInActive } = this.props;
    getActiveProgram();
    getInActive();
  }

  render() {
    const {
      match: { url }
    } = this.props;
    return (
      <Switch>
        <Route exact path={`${url}/createnew`} component={Program} />
        <Route path={`${url}/edit`} component={EditProgram} />
        <Route path={`${url}/`} component={ProgramStatus} />
      </Switch>
    );
  }
}

ProgramContainer.propTypes = {
  match: PropType.object.isRequired,
  getActiveProgram: PropType.func.isRequired,
  getInActive: PropType.func.isRequired
};

const mapToDispatch = (dispatch) => ({
  getActiveProgram: () => dispatch(ActiveProgram()),
  getInActive: () => dispatch(InActiveProgram())
});

export default connect(null, mapToDispatch)(ProgramContainer);
