/* eslint-disable indent */
import moment from 'moment';
import _ from 'lodash';
import {
  AllAttendanceLmt,
  AllDayType,
  AllSessionlmt,
  SessionDay
} from 'src/views/memberships/MembershipTemplate/Membership.data';

export const updatemem = (data) => {
  const b = {
    discount: {
      ...data.discount
    },
    type: data.type,
    membership_id: data.membership_id,
    membership_payment_id: data.membership_payment_id,
    createdon:
      data.created_by_str + ' ' + moment(data.created_on).format('lll'),
    updateddon:
      data.updated_by_str + ' ' + moment(data.updated_on).format('lll'),
    start_date: moment(data.start_date, 'YYYY-MM-DD').toDate(),
    expires_date: moment(data.expires_date, 'YYYY-MM-DD').format(
      'DD, MMMM YYYY'
    ),
    status: data.status,
    sign_status: data.sign_status,
    check_paid_invoice: data.check_paid_invoice,
    initial_pricing: data.initial_pricing,
    user_contract_id: data.user_contract_id,
    renewal_pricing: data.renewal_pricing,
    is_stop_autorenew: data.is_stop_autorenew,
    usedsession: data.used_session,
    program_access: data.programe_access.split(',').map((i) => Number(i)),
    is_editable: data.is_editable,
    auto_renew_date: moment(data.auto_renew_date, 'YYYY-MM-DD').format(
      'DD/MM/YYYY'
    ),
    deactivate_user: data.deactivate_user,
    membership_contract: 'keep_contract',
    payment_data: {
      ...data.membership_payment_plans,
      auto_renew: data.auto_renew === 0 ? 0 : true,
      auto_renew_meta: data.auto_renew
        ? {
            renewal: data.membership_payment_plans.auto_renew_meta.renewal,
            billed_every: {
              id: data.membership_payment_plans.auto_renew_meta.billed_every,
              name: String(
                data.membership_payment_plans.auto_renew_meta.billed_every
              )
            }
          }
        : {},
      initial_commitment:
        data.type === 'plan'
          ? {
              ...data.membership_payment_plans.initial_commitment,
              billed_every: {
                id:
                  data.membership_payment_plans.initial_commitment.billed_every,
                name: String(
                  data.membership_payment_plans.initial_commitment.billed_every
                )
              }
            }
          : {},
      programes: data.programes,
      membership: {
        contract_id: data.contract_id,
        no_of_sessions: data.no_of_session,
        name: data.membership_data.name,
        attendance_limit:
          data.type === 'plan'
            ? AllAttendanceLmt.filter(
                (res) => res.id === data.attendance_limit
              )[0]
            : AllSessionlmt.filter(
                (res) => res.id === data.attendance_limit
              )[0],
        // eslint-disable-next-line no-nested-ternary
        attendance_limited_meta: data.attendance_limited_meta
          ? data.type === 'plan'
            ? {
                uses: data.attendance_limited_meta.uses,
                type:
                  AllDayType.filter(
                    (res) => res.id === data.attendance_limited_meta.type
                  )[0] || null
              }
            : {
                uses: data.attendance_limited_meta.uses,
                type:
                  SessionDay.filter(
                    (res) => res.id === data.attendance_limited_meta.type
                  )[0] || null
              }
          : {}
      }
    }
  };
  return b;
};

export const getChangeData = (data, anotherObj) => {
  const result = [];
  if (!_.isEqual(data.start_date, anotherObj.start_date)) {
    result.push('Start Date');
  }

  if (data.type === 'plan') {
    if (
      !_.isEqual(
        data.payment_data.initial_commitment.billed_every,
        anotherObj.payment_data.initial_commitment.billed_every
      )
      && data.initial_pricing === 'weekly'
    ) {
      result.push('Initial billed every');
    }

    if (
      !_.isEqual(
        data.payment_data.initial_commitment.initial,
        anotherObj.payment_data.initial_commitment.initial
      )
    ) {
      result.push('Initial Commitment');
    }

    if (
      !_.isEqual(
        data.payment_data.auto_renew,
        anotherObj.payment_data.auto_renew
      )
    ) {
      result.push('Auto renew');
    }

    if (data.payment_data.auto_renew) {
      if (!_.isEqual(data.renewal_pricing, anotherObj.renewal_pricing)) {
        result.push('Renewal Pricing Option');
      }
      if (
        !_.isEqual(
          data.payment_data.auto_renew_meta.billed_every,
          anotherObj.payment_data.auto_renew_meta.billed_every
        )
        && data.renewal_pricing === 'weekly'
      ) {
        result.push('Renewal Billed every');
      }
      if (
        !_.isEqual(
          data.payment_data.auto_renew_meta.renewal,
          anotherObj.payment_data.auto_renew_meta.renewal
        )
      ) {
        result.push('Renewal Options');
      }
    }
  }

  if (
    !_.isEqual(
      data.payment_data.membership.attendance_limit,
      anotherObj.payment_data.membership.attendance_limit
    )
  ) {
    result.push('Membership Attendance limit');
  }

  if (data.payment_data.membership.attendance_limit?.id === 1) {
    if (
      !_.isEqual(
        data.payment_data.membership.attendance_limited_meta.uses,
        anotherObj.payment_data.membership.attendance_limited_meta.uses
      )
    ) {
      result.push('Attendance limit uses');
    }

    if (
      !_.isEqual(
        data.payment_data.membership.attendance_limited_meta.type,
        anotherObj.payment_data.membership.attendance_limited_meta.type
      )
    ) {
      result.push('Attendance limit type');
    }
  }

  if (!_.isEqual(data.initial_pricing, anotherObj.initial_pricing)) {
    result.push('Initial Pricing Option');
  }

  if (!_.isEqual(data.discount, anotherObj.discount)) {
    result.push('Discounts & Promo Codes');
  }

  if (
    !_.isEqual(
      data.payment_data.initial_commitment_pricing.week_setup_fees,
      anotherObj.payment_data.initial_commitment_pricing.week_setup_fees
    )
    || !_.isEqual(
      data.payment_data.initial_commitment_pricing.full_setup_fees,
      anotherObj.payment_data.initial_commitment_pricing.full_setup_fees
    )
  ) {
    result.push('Inital Setup fees');
  }

  if (
    !_.isEqual(
      data.payment_data.initial_commitment_pricing.week_membership_fees,
      anotherObj.payment_data.initial_commitment_pricing.week_membership_fees
    )
    || !_.isEqual(
      data.payment_data.initial_commitment_pricing.full_membership_fees,
      anotherObj.payment_data.initial_commitment_pricing.full_membership_fees
    )
  ) {
    result.push('Inital Membership fees');
  }

  if (data.type === 'plan') {
    if (
      !_.isEqual(
        data.payment_data.auto_renewal_pricing.full_membership_fees,
        anotherObj.payment_data.auto_renewal_pricing.full_membership_fees
      )
      && data.renewal_pricing === 'full'
    ) {
      result.push('Auto renewal membership fees');
    }
    if (
      !_.isEqual(
        data.payment_data.auto_renewal_pricing.week_membership_fees,
        anotherObj.payment_data.auto_renewal_pricing.week_membership_fees
      )
      && data.renewal_pricing === 'weekly'
    ) {
      result.push('Auto renewal week membership fees');
    }
  }

  if (!_.isEqual(data.program_access, anotherObj.program_access)) {
    result.push('Program Access');
  }

  if (data.type === 'session') {
    if (
      !_.isEqual(
        data.payment_data.membership.no_of_sessions,
        anotherObj.payment_data.membership.no_of_sessions
      )
    ) {
      result.push('No of Session');
    }

    if (!_.isEqual(data.usedsession, anotherObj.usedsession)) {
      result.push('Used Session');
    }
  }

  return result;
};
