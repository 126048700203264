import React, { createContext, useReducer } from 'react';
import PropType from 'prop-types';
import {
  chalkboardReducer,
  initialValue
} from './ChalkBoardReducer/chalkBoard.reducer';

export const Chalk = createContext();

export const ChalkBoardContext = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(chalkboardReducer, initialValue);
  return <Chalk.Provider value={[state, dispatch]}>{children}</Chalk.Provider>;
};
ChalkBoardContext.propTypes = {
  children: PropType.node.isRequired
};
