import React from 'react';
import { withStyles, Grid, Typography, Box } from '@material-ui/core';
import PropType from 'prop-types';
import { connect } from 'react-redux';
import LoadingView from 'src/views/common/loading';
import NotFoundView from 'src/views/errors/NotFoundView';
import useChangeTitle from 'src/views/common/CustomHooks/useTitleChange';
import CategoryTable from './CategoryTable/CategoryTable';
import styles from './FinancialSettings.style';

const RevenueCategorys = ({ classes, revenueCat }) => {
  const { data, isFetching, error } = revenueCat;
  /* useEffect(() => {
    setCategorydata(data);
  }, []); */
  useChangeTitle('Revenue Category | Jungle Alliance');
  if (error) return <NotFoundView />;
  if (isFetching) return <LoadingView />;

  return (
    <Box p="15px">
      <Grid container direction="row" spacing={0}>
        <Grid
          container
          direction="row"
          spacing={0}
          style={{ margin: '10px 0 20px' }}
        >
          <Grid item xs={12}>
            <Typography
              variant="h3"
              component="h3"
              style={{ color: '#292929' }}
            >
              Revenue Category Picklist Values
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box mt="15px">
              <p className={classes.ptext}>
                Customize your Revenue Categories.
              </p>
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          spacing={0}
          style={{ margin: '10px 0 20px' }}
        >
          <Grid item xs={12}>
            <CategoryTable values={data} />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
const mapToState = (state) => ({
  revenueCat: state.SettingReducer.revenueCtgry
});
RevenueCategorys.propTypes = {
  classes: PropType.object.isRequired,
  revenueCat: PropType.object.isRequired
};

export default connect(mapToState)(withStyles(styles)(RevenueCategorys));
