/* eslint-disable arrow-parens */
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
// import MembershipList from './List';
import styles from './ClassesSettings.style';
import SettingTabs from './Tabs/SettingTabs';
// import { ROUTES } from '../../../constant/Constants';

class ClassesSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <div className="title-bk">
          <div className={classes.new_programs} style={{ marginBottom: 15 }}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={0}
            >
              <Grid item xs={6}>
                <h3 style={{ marginBottom: 0 }}>
                  Classes <label>/</label>
                  <span>Settings</span>
                </h3>
              </Grid>
              <Grid item xs={6} alignItems="center" />
            </Grid>
          </div>
        </div>

        <SettingTabs />

        {/* <Tabulation
          label1="Plan templates"
          label2="session templates"
          value={value}
          Change={(val) => this.setState({ value: val })}
          style={{ padding: '0 0' }}
        >
          <MembershipList starred={value} />
        </Tabulation> */}
      </div>
    );
  }
}

ClassesSettings.propTypes = {
  classes: PropTypes.object.isRequired
  // eslint-disable-next-line react/no-unused-prop-types
  // programListObject: PropTypes.any
};

export default withStyles(styles, { withTheme: true })(ClassesSettings);
