import filterType from './wod.reducer.constants';

export const FavWOD = (values, id) => {
  const result = {
    ...values,
    // eslint-disable-next-line
    data: values.data.map((wod) =>
      wod.id === id
        ? {
          ...wod,
          starred: 1
        }
        : wod)
  };
  return result;
};

export const removeFavWod = (values, id) => {
  const result = {
    ...values,
    // eslint-disable-next-line
    data: values.data.map((wod) =>
      wod.id === id
        ? {
          ...wod,
          starred: 0
        }
        : wod)
  };
  return result;
};
const dupTag = (Objtag, tag) => {
  const result = Objtag;
  for (let i = 0; i < Objtag.length; i += 1) {
    if (Objtag[i].id === tag.id) {
      result[i] = tag;
    }
  }
  return result;
};

export const removeFav = (values, id) => {
  const result = {
    ...values,
    // eslint-disable-next-line
    data: values.data.filter((wod) => wod.id !== id)
  };
  return result;
};

export const addWODFilter = (obj, filter, tag) => {
  let flag = 0;
  // eslint-disable-next-line
  const filterVal = Object.keys(filter);
  const mainObj = Object.keys(obj.customFilter);
  for (let i = 0; i < mainObj.length; i += 1) {
    for (let j = 0; j < filterVal.length; j += 1) {
      if (mainObj[i] === filterVal[j]) {
        if (
          (obj.customFilter[mainObj[i]] >= 0 && filter[filterVal[j]] >= 0)
          || typeof filter[filterVal[j]] === 'string'
        ) {
          if (obj.customFilter[mainObj[i]] !== filter[filterVal[j]]) {
            return {
              ...obj,
              customFilter: { ...obj.customFilter, ...filter },
              tags: dupTag(obj.tags, tag),
              page: 1
            };
          }
        }
      }
    }
  }
  for (let i = 0; i < obj.tags.length; i += 1) {
    if (obj.tags[i].id === tag.id) {
      flag = 1;
    }
  }
  if (flag === 1) {
    return {
      ...obj
    };
  }
  const result = {
    ...obj,
    customFilter: { ...obj.customFilter, ...filter },
    tags: [...obj.tags, tag],
    page: 1
  };
  return result;
};

export const removeWODFilter = (obj, tag) => {
  const updatedVal = {
    ...obj.customFilter
  };
  delete updatedVal[tag.filter];
  delete updatedVal[tag.connetor];
  const result = {
    ...obj,
    customFilter: updatedVal,
    tags: obj.tags.filter((wod) => wod.id !== tag.id),
    page: 1
  };
  return result;
};

export const filterList = (state, type, data) => {
  switch (type) {
    case filterType.page:
      return {
        ...state,
        page: data
      };
    case filterType.search:
      return {
        ...state,
        search: data,
        page: 1
      };
    case filterType.sorting: {
      const getdata = () => {
        switch (data) {
          case state.sorting.sort_name: {
            const getSortby = () => {
              switch (state.sorting.sort_by) {
                case 'asc':
                  return {
                    ...state,
                    sorting: { ...state.sorting, sort_by: 'desc' }
                  };
                case 'desc':
                  return {
                    ...state,
                    sorting: { ...state.sorting, sort_by: 'asc' }
                  };
                default:
                  return { ...state };
              }
            };
            return getSortby();
          }
          default:
            return {
              ...state,
              sorting: {
                sort_name: data,
                sort_by: 'asc'
              }
            };
        }
      };
      return getdata();
    }
    case filterType.addcustom:
      return addWODFilter(state, data[0], data[1]);
    case filterType.removecustom:
      return removeWODFilter(state, data);
    default:
      return state;
  }
};

export const getData = (type, action, state, data) => {
  switch (type) {
    case filterType.WODLIST: {
      const Data = () => {
        switch (action) {
          case filterType.isFetching:
            return {
              ...state,
              isFetching: true
            };
          case filterType.fetchSucess:
            return {
              ...state,
              isFetching: false,
              error: null,
              data
            };
          case filterType.Errorfetch:
            return {
              ...state,
              isFetching: false,
              error: data
            };
          default:
            return state;
        }
      };
      return Data();
    }
    case filterType.WODLISTFAV: {
      const Data = () => {
        switch (action) {
          case filterType.isFetching:
            return {
              ...state,
              isFetching: true
            };
          case filterType.fetchSucess:
            return {
              ...state,
              isFetching: false,
              error: null,
              data
            };
          case filterType.Errorfetch:
            return {
              ...state,
              isFetching: false,
              error: data
            };
          default:
            return state;
        }
      };
      return Data();
    }
    default:
      return state;
  }
};
