import React from 'react';
import PropType from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  DialogTitle,
  withStyles
} from '@material-ui/core';
import JBButton from 'src/views/common/JBButton/JBButton';
import questionmark from '../../../assets/image/questionmark.png';

const styles = () => ({
  Dialogtitle: {
    padding: '16px 24px 0'
  },
  DialogActionsBk: {
    padding: '5px 20px 20px'
  }
});

function ResettemplateDialog({ classes, handleHistory, onReset }) {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <JBButton type="outlined" onClick={handleClickOpen}>
        reset template
      </JBButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div style={{ width: 394 }}>
          <DialogTitle id="alert-dialog-title" className={classes.Dialogtitle}>
            <img
              alt="Plusicon"
              src={questionmark}
              style={{ display: 'inline-block', verticalAlign: 'sub' }}
            />
            <Typography
              color="#000"
              variant="h5"
              component="div"
              className={classes.title}
              style={{ display: 'inline-block', marginLeft: '10px' }}
            >
              Reset template
            </Typography>
          </DialogTitle>
          <DialogContent>
            <p
              style={{
                fontWeight: 'normal',
                fontSize: '14px',
                lineHeight: '18px',
                letterSpacing: '0.002em',
                color: '#7F7F7F',
                padding: '10px 0'
              }}
            >
              Are you sure you want to reset this template? This will reset all
              the changes done in the template.
            </p>
          </DialogContent>
          <DialogActions className={classes.DialogActionsBk}>
            <Button
              variant="outlined"
              onClick={handleClose}
              style={{
                height: '48px',
                color: '#7F7F7F',
                fontWeight: 'bold'
              }}
            >
              Cancel
            </Button>
            <JBButton
              type="primary"
              onClick={() => {
                onReset();
                handleClose();
                // eslint-disable-next-line
                // handleHistory
                //   ? history.push(`${handleHistory}`)
                //   : history.goBack();
              }}
            >
              Confirm
            </JBButton>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}
ResettemplateDialog.defaultProps = {
  handleHistory: null
};

ResettemplateDialog.propTypes = {
  classes: PropType.object.isRequired,
  // handleDelete: PropType.func.isRequired,
  handleHistory: PropType.string
};
export default withStyles(styles)(ResettemplateDialog);
