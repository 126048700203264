/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react';
// import { useHistory } from 'react-router-dom';
import PropType from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  withStyles,
  Grid,
  InputLabel
} from '@material-ui/core';
import JBDatePicker from 'src/views/common/FormControl/JBDatePicker';
import Questionmark from 'src/assets/image/questionmarkPrimary.png';
import dcloseicon from 'src/assets/image/closeicon.png';
import JBButton from 'src/views/common/JBButton/JBButton';
import CustomAutocomplete from 'src/views/common/CustomAutocomplete/commonAutocomplete';
import { getMemholdType } from 'src/services/Membersip.services';
import NotFoundView from 'src/views/errors/NotFoundView';
import LoadingView from 'src/views/common/loading';
// import { data } from 'src/views/reports/AttendanceReports/chartData/AttendanceTrendData';
import moment from 'moment';
import { openNoti } from 'src/Redux/global/global.actions';
import { connect } from 'react-redux';
import { createHold, getHoldList } from 'src/services/Members.module';
import { getusermembership } from 'src/services/user.service';
import { getErrorMsg } from 'src/utils/ErrorHandling';
import { updateAllData } from '../Memberships/NewMembership/NewMembership.actions';
import { updatemem } from './EditMembership.utils';
// import {
//   membershipReducer,
//   initialValue
// } from '../Memberships/NewMembership/NewMemship.reducer';

const styles = () => ({
  dtitle: {
    color: '#292929',
    fontWeight: 'bold',
    fontSize: '33px',
    lineHeight: '26px'
  },
  cutom_select_Label: {
    fontSize: '12px',
    padding: '7px 0'
  },
  ptext: {
    fontSize: '14px',
    lineHeight: '24px',
    color: '#7F7F7F',
    fontWeight: 'normal'
  },
  DialogActionsBk: {
    padding: '30px 30px'
  },
  dateCss: {
    '& .MuiInput-root': {
      color: '#292929'
    }
  }
});

function AddNewMemberPlanHold({
  classes,
  value,
  openNotify,
  open1,
  onClose,
  updateUi,
  reducer
}) {
  const [data, setData] = useState({
    // hold_start_date: value.started_date,
    hold_start_date: new Date(), // JB-1349 Client feedback
    hold_end_date: null,
    hold_reason: null
  });
  // eslint-disable-next-line no-unused-vars
  const [state, dispatch] = reducer;
  const [open, setOpen] = useState(open1);
  const [options, setoptions] = useState(null);
  const [error, setError] = useState(false);
  // const history = useHistory();
  useEffect(() => {
    getMemholdType()
      .then((res) => {
        setoptions(res);
      })
      .catch(() => setError(true));
  }, []);
  const handleClose = () => {
    setOpen(false);
    setData({
      // hold_start_date: value.started_date,
      hold_start_date: new Date(), // JB-1349 Client feedback
      hold_end_date: null,
      hold_reason: null
    });
    onClose();
  };
  const handleCreate = () => {
    const result = {
      user_member_id: Number(value.user_id),
      user_id: Number(value.user_member_id),
      hold_start_date: moment(data.hold_start_date).format('YYYY-MM-DD'),
      hold_end_date: moment(data.hold_end_date).format('YYYY-MM-DD'),
      hold_reason: data.hold_reason.id
    };

    createHold(result)
      .then(() => {
        getHoldList({ user_id: value.user_member_id })
          .then((res) => {
            getusermembership(value.user_id)
              .then((res1) => {
                openNotify('Successfully created hold ');
                dispatch(
                  updateAllData({ ...updatemem(res1), reset: updatemem(res1) })
                );
              })
              .catch((err) => openNotify(getErrorMsg(err), 'error'));
            updateUi(res);
            handleClose();
          })
          .catch((err) => openNotify(getErrorMsg(err), 'error'));

        handleClose();
      })

      .catch((err) => openNotify(getErrorMsg(err), 'error'));
  };
  const handleSave = () => {
    if (!data.hold_end_date) {
      openNotify('End date is not valid ', 'error');
    } else if (!data.hold_reason) {
      openNotify('Hold reason is not valid ', 'error');
    } else {
      handleCreate();
    }
  };
  if (error) {
    return (
      <Dialog>
        <NotFoundView />
      </Dialog>
    );
  }
  if (!options) {
    return (
      <Dialog>
        <LoadingView />
      </Dialog>
    );
  }
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick
      >
        <div style={{ width: '548px' }}>
          <Grid alignItems="center" container direction="row">
            <Grid item xs>
              <DialogTitle
                id="alert-dialog-title"
                className={classes.titlebk}
                style={{
                  display: 'inline-block',
                  padding: '35px 15px 25px 28px'
                }}
              >
                <h4 className={classes.dtitle}> Add New Member Plan Hold</h4>
              </DialogTitle>
            </Grid>
            <Grid
              item
              // xs={2}
              style={{
                textAlign: 'right',
                marginRight: '20px',
                marginTop: '10px'
              }}
            >
              <Button onClick={handleClose} className={classes.btns}>
                <img alt="img" src={dcloseicon} />
              </Button>
            </Grid>
          </Grid>

          <DialogContent>
            <Grid
              container
              spacing={2}
              style={{ marginTop: '5px', padding: '0 15px' }}
            >
              <Grid
                item
                xs={12}
                style={{ padding: '0 0', marginBottom: '20px' }}
              >
                <p className={classes.ptext} style={{ marginBottom: '15px' }}>
                  Adding a hold to a membership will change it's billing date,
                  after the hold period is over. We recommend you check the new
                  due date for the membership invoices, after adding the hold,
                  and communicate the change to the member.
                </p>
                <p className={classes.ptext}>
                  If the member's hold start date is the same as their billing
                  date, the member will be charged and their next invoice due
                  date will be pushed back to account for the time missed.
                </p>
              </Grid>
              <Grid alignItems="center" spacing={2} container direction="row">
                <Grid item xs={6} className={classes.dateCss}>
                  <JBDatePicker
                    label="Start date"
                    minDate={value.started_date}
                    value={data.hold_start_date}
                    onChange={(val) => {
                      setData({ ...data, hold_start_date: val });
                    }}
                    required
                  />
                </Grid>
                <Grid item xs={6} className={classes.dateCss}>
                  <JBDatePicker
                    label="End date"
                    minDate={data.hold_start_date}
                    value={data.hold_end_date}
                    onChange={(val) => {
                      setData({ ...data, hold_end_date: val });
                    }}
                    required
                  />
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                style={{
                  marginTop: '15px',
                  marginBottom: '15px',
                  padding: '0 0'
                }}
              >
                <InputLabel
                  htmlFor="age-required"
                  required
                  className={classes.cutom_select_Label}
                  style={{ padding: '5px 0' }}
                >
                  Hold reason
                </InputLabel>
                <CustomAutocomplete
                  holder="-"
                  data={data.hold_reason}
                  Options="label"
                  value={options}
                  Change={(val) => {
                    setData({ ...data, hold_reason: val });
                  }}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions className={classes.DialogActionsBk}>
            <JBButton onClick={handleSave} type="primary">
              Save
            </JBButton>
            <img src={Questionmark} alt="icon" />
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}
// AddNewMemberPlanHold.defaultProps = {
//   handleHistory: null
// };

AddNewMemberPlanHold.propTypes = {
  classes: PropType.object.isRequired,
  // handleDelete: PropType.func.isRequired,
  value: PropType.object.isRequired,
  open1: PropType.bool.isRequired,
  openNotify: PropType.func.isRequired,
  onClose: PropType.func.isRequired,
  updateUi: PropType.func.isRequired,
  reducer: PropType.func.isRequired
};
const mapToDispatch = (dispatch) => ({
  openNotify: (msg, type) => dispatch(openNoti(msg, type))
});
export default connect(
  null,
  mapToDispatch
)(withStyles(styles)(AddNewMemberPlanHold));
