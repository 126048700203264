import React, { useState, useContext } from 'react';
import { connect } from 'react-redux';
import { Dialog, Grid, withStyles } from '@material-ui/core';
import '../CutomSelect.css';
import {
  addpaymentmethod,
  changepaymentmethod
} from 'src/Context/MembershipTemplate/membershiptemplate.actions';
import { SessionTemplateContext } from 'src/Context/MembershipTemplate/SessionTemplate.Context';
import PaymentPlanTable from '../PlanTemplates/paymentplanTable';
import PaymentPlan from '../../PaymentPlan/SessionPaymentPlan/Sessionpaymentplan';
import styles from '../MembershipTemplate.style';
import JBButton from '../../../common/JBButton/JBButton';
import Plusicon from '../../../../assets/image/plusicon.png';
import {
  Delpayment,
  Editpayment
} from '../../PaymentPlan/PaymentPlan.validation';
import { openNoti } from '../../../../Redux/global/global.actions';

const PaymentPlansStep = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [state, dispatch] = useContext(SessionTemplateContext);

  return (
    <div>
      <h3 style={{ marginBottom: '20px' }}>
        Create payment plans for different commitments, payment options and
        locations
      </h3>

      <Grid container spacing={0} xs={12} disableGutters direction="row">
        <Grid item xs={12} style={{ marginTop: '0px', marginBottom: '20px' }}>
          <JBButton
            type="primary"
            isImg={Plusicon}
            onClick={() => setOpenDialog(true)}
          >
            new payment plan
          </JBButton>
          <Dialog
            open={openDialog}
            fullWidth
            maxWidth="lg"
            onClose={() => setOpenDialog(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            disableBackdropClick
          >
            <PaymentPlan
              getData={(val) => dispatch(addpaymentmethod(val))}
              handleClose={() => setOpenDialog(false)}
              allpayments={state?.payment_plans}
            />
          </Dialog>
        </Grid>

        <Grid item xs={12} style={{ marginTop: '0px' }}>
          {state?.payment_plans.length > 0 && (
            <PaymentPlanTable
              values={state?.payment_plans}
              session
              Delpay={(index) =>
                dispatch(
                  changepaymentmethod(Delpayment(state?.payment_plans, index))
                )
              }
              Editpay={(val, index) =>
                dispatch(
                  changepaymentmethod(
                    Editpayment(state?.payment_plans, index, val)
                  )
                )
              } // eslint-disable-line
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
};

const mapToDispatch = (dispatch) => ({
  OpenNoti: (msg, type) => dispatch(openNoti(msg, type))
});

export default connect(
  null,
  mapToDispatch
)(withStyles(styles)(PaymentPlansStep));
