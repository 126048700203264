import React from 'react';
import PropType from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  DialogTitle,
  withStyles
} from '@material-ui/core';
import X from '../../../../assets/image/x.png';

const styles = () => ({
  Deletebtn: {
    background: '#FB4E4E',
    boxShadow: '0px 8px 16px rgba(251, 78, 78, 0.16)',
    borderRadius: '2px',
    height: '48px',
    padding: '10px 15px',
    color: '#fff',
    fontWeight: '700',
    '&:hover': {
      background: '#FB4E4E'
    }
  },
  bodytxt: {
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '0.002em',
    color: '#7F7F7F',
    padding: '10px 0'
  },
  titlebk: {
    padding: '16px 24px 5px'
  }
});

function DeleteDropInDialog(props) {
  const { classes, handleDelete, handleClose, setOpen, name } = props;

  return (
    <div>
      <Dialog
        open={setOpen}
        onClose={() => handleClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div style={{ minWidth: 394 }}>
          <DialogTitle id="alert-dialog-title" className={classes.titlebk}>
            <img
              alt="Plusicon"
              src={X}
              style={{ display: 'inline-block', verticalAlign: 'sub' }}
            />
            <Typography
              color="#000"
              variant="h5"
              component="div"
              className={classes.title}
              style={{ display: 'inline-block', marginLeft: '10px' }}
            >
              Delete "{name}” {/* eslint-disable-line */}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <p className={classes.bodytxt}>
              Are you sure you want to delete item "{name}”?
            </p>
          </DialogContent>
          <DialogActions
            style={{
              padding: '5px 20px 20px'
            }}
          >
            <Button
              variant="outlined"
              onClick={handleClose}
              style={{
                height: '48px',
                color: '#7F7F7F',
                fontWeight: 'bold'
              }}
            >
              Cancel
            </Button>
            <Button className={classes.Deletebtn} onClick={handleDelete}>
              DELETE
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}

DeleteDropInDialog.propTypes = {
  classes: PropType.object.isRequired,
  handleDelete: PropType.func.isRequired,
  setOpen: PropType.bool.isRequired,
  handleClose: PropType.func.isRequired,
  name: PropType.string.isRequired
};
export default withStyles(styles)(DeleteDropInDialog);
