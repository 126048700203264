import React, { useContext } from 'react';
import { VanguardMem } from 'src/Context/VanguardMembershipContext';
import {
  setDiscountType,
  setDiscountAmount,
  setSetupFee,
  setMemFee,
  setWeekFee,
  setWeekSetup,
  setRenewalFee,
  setRenewalWeek
} from 'src/Context/VanguardMembership/VanguardMembership.actions';
import GetPrice from 'src/views/common/CurrencySymbol/GetPrice';
import PropType from 'prop-types';
import clsx from 'clsx';
import {
  Typography,
  makeStyles,
  Grid,
  RadioGroup,
  Radio,
  FormControlLabel,
  Input,
  InputAdornment,
  Box,
  TableRow,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  InputLabel
} from '@material-ui/core';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CurrencyInputs from 'src/views/common/CurrencyControlForm/CurrencyInputs';
import PercentageFormControl from '@material-ui/core/FormControl';
import FormControl from 'src/views/common/FormControl/formControl';
import { validateFloat, validPrice } from 'src/utils/validations';
import { useSelector } from 'react-redux';
import CheckIcon from '@material-ui/icons/Check';
import {
  getmemdiscount,
  getsetupdiscount,
  // getTotal,
  getTaxTotal,
  RenewalTotal,
  getRenewalDiscount,
  getRenewalTax,
  getTotalDue,
  getRenTotalDiscount,
  getIniTotaldiscount
} from '../Vanguard.utills';

const DiscountsPricing = ({ title, subtitle }) => {
  const [state, dispatch] = useContext(VanguardMem);
  // eslint-disable-next-line no-shadow
  const taxRates = useSelector((state) => state.SettingReducer.taxRates.data);
  const {
    memebership_data: {
      membershipdata: { discount, payment_data }
    }
  } = state;
  const tax = taxRates.filter((i) => i.id === payment_data.tax_id);
  const handleChange = (event) => {
    dispatch(
      setDiscountType({ name: event.target.name, value: event.target.value })
    );
  };
  // const [PayFulldata, setPayFulldata] = useState({
  //   value: ''
  // });

  const useStyles = makeStyles(() => ({
    mainTitle: {
      fontWeight: '500',
      fontSize: '48px',
      lineHeight: '44px',
      color: '#FFFFFF',
      fontFamily: 'DDC Hardware',
      textAlign: 'center',
      margin: '38px 0 0 0',
      '@media (max-width: 992px)': {
        fontSize: '35px'
      }
    },
    subTitle: {
      fontWeight: 'bold',
      fontSize: '24px',
      lineHeight: '38px',
      color: '#FFFFFF',
      fontFamily: 'Lato',
      textAlign: 'center',
      margin: '38px 0 0 0',
      letterSpacing: '0.1px'
    },

    customRadio: {
      '& .MuiFormControlLabel-root': {
        marginLeft: '-11px',
        marginRight: '5px'
      },
      // '& .MuiFormControlLabel-root': {
      //   background: 'rgba(196, 196, 196, 0.05)',
      //   border: '2px solid #7B7B7B',
      //   borderRadius: '6px',
      //   height: '70px',
      //   margin: '0 0 15px'
      // },
      // '& .MuiFormControlLabel-root.checked': {
      //   background: 'rgba(44, 233, 218, 0.15)',
      //   border: '2px solid #2CE9DA',
      //   borderRadius: '6px'
      // },
      '& .MuiFormControlLabel-root svg.MuiSvgIcon-root': {
        width: '30px',
        height: '30px',
        fill: 'none',
        borderRadius: '50%',
        border: '2px solid #4B4B4B'
      },
      '& .MuiFormControlLabel-root.checked svg.MuiSvgIcon-root': {
        background: '#67e9da',
        padding: '4px',
        fill: '#000',
        color: '#060606',
        border: '0px solid #4B4B4B'
      },
      '& .MuiRadio-root': {
        padding: '20px'
      },
      '& .MuiFormControlLabel-label': {
        color: '#fff',
        fontSize: '20px',
        lineHeight: '26px',
        fontWeight: 'bold',
        WebkitLineClamp: '2',
        WebkitBoxOrient: 'vertical',
        display: '-webkit-box',
        whiteSpace: 'normal',
        overflow: 'hidden',
        height: 'auto',
        minHeight: 'auto',
        width: 'auto',
        textAlign: 'left',
        '@media (max-width: 850px)': {
          width: 'auto'
        }
      }
    },
    customGridItem: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      width: '130px',
      color: '#fff',
      fontWeight: 'bold',
      fontSize: '14px',
      lineHeight: '26px',
      alignItems: 'flex-start',
      '& h5 ': {
        fontFamily: 'Lato',
        color: 'rgb(255 255 255 / 60%)',
        fontWeight: 'normal',
        fontSize: '20px',
        lineHeight: '32px'
      }
    },
    ItemText: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      width: '130px',
      color: '#fff',
      fontWeight: 'bold',
      fontSize: '20px',
      lineHeight: '32px',
      alignItems: 'flex-start',
      fontFamily: 'Lato',
      paddingLeft: '20px',
      '@media (max-width: 850px)': {
        width: '115px'
      }
    },
    customGrid: {
      '& .customGridData': {
        background: 'rgba(196, 196, 196, 0.05)',
        border: '2px solid #7B7B7B',
        borderRadius: '6px',
        height: '70px',
        margin: '0 0 15px',
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        textTransform: 'capitalize'
      },
      '& .customGridData.checked': {
        background: 'rgba(44, 233, 218, 0.15)',
        border: '2px solid #2CE9DA',
        borderRadius: '6px'
      }
    },
    PercentageFormControl: {
      // color: '#292929',
      // border: '1px solid #a9a9a9',
      // padding: '8px 15px 8px',
      fontSize: '16px',
      width: '123px',
      color: '#ffffff',
      background: 'rgba(196, 196, 196, 0.05)',
      border: '2px solid #C8C8C8',
      borderRadius: '6px',
      padding: '8px 15px 8px',
      minHeight: '52',
      '&.MuiInput-underline.Mui-error': {
        border: '1px solid #f44336'
      },
      '&.MuiInput-underline.Mui-error input[type="text"]': {
        border: '0px solid #f44336'
      },
      '& .MuiInput-underline.Mui-disabled:before': {
        borderBottomStyle: 'none'
      },
      '& .MuiInput-root': {
        color: '#ffffff',
        background: 'transparent !important',
        border: 'none !important',
        borderRadius: '0 !important',
        minHeight: 'auto !important'
      }
    },
    InputControl: {
      flexDirection: 'row',
      justifyContent: 'left',
      flexWrap: 'nowrap',
      display: 'flex',
      alignItems: 'center',
      '& .MuiInput-root': {
        color: '#ffffff',
        background: 'rgba(196, 196, 196, 0.05)',
        border: '2px solid #C8C8C8',
        borderRadius: '6px',
        minHeight: '52px'
      },
      '& .MuiInputAdornment-positionStart': {
        color: '#ffffff'
      },
      '& .MuiInputBase-input': {
        color: '#ffffff'
      }
    },
    finalPricing: {},
    PlanTerms: {
      // border: '2px solid #7B7B7B',
      // borderRadius: '6px',
      // marginBottom: '15px',
      '& tr': {
        border: '2px solid #7B7B7B',
        borderRadius: '6px',
        marginBottom: '15px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        maxHeight: '76px',
        '& td': {
          fontSize: '20px',
          lineHeight: 'normal',
          letterSpacing: '0.2px',
          borderBottom: '0',
          width: '200px',
          fontWeight: '700',
          color: '#fff',
          '&:nth-of-type(odd)': {
            color: '#ffffff',
            minWidth: '222px',
            fontWeight: '700',
            width: '222px'
          }
        }
      }
    },
    cutom_vanguard_FormControl: {
      '& .fd-input input': {
        background: 'rgba(196, 196, 196, 0.05)',
        color: '#fff',
        borderRadius: '6px',
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '20px',
        height: '36px',
        marginTop: '0',
        border: '2px solid #C8C8C8',
        padding: '8px 20px 8px',
        '&::placeholder ': {
          color: '#fff',
          fontWeight: 'bold',
          fontSize: '15px'
        }
      },
      '&  .MuiPaper-root': {
        backgroundColor: '#2d2d2d',
        marginTop: '0',
        marginBottom: '0',
        border: '2px solid #C8C8C8',
        borderTop: 'transparent',
        boxShadow: 'none',
        // borderRadius: '0',
        borderTopLeftRadius: '0',
        borderTopRightRadius: '0'
      },
      '& .MuiAutocomplete-option': {
        color: '#fff',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '20px',
        height: '66px'
      },
      '& .MuiAutocomplete-popper': {
        width: '100% !important',
        marginTop: '-2px'
      },
      '& .SelectDrop[aria-expanded="true"]': {
        borderBottom: '2px solid transparent',
        borderBottomLeftRadius: '0',
        borderBottomRightRadius: '0'
      }
    },
    cutom_select_Label: {
      fontStyle: 'normal',
      fontWeight: '700;',
      fontSize: '20px',
      lineHeight: '32px',
      letterSpacing: '0.2px',
      color: '#FFFFFF',
      '&:focus ': {
        outline: '0'
      },
      '& .MuiFormLabel-asterisk': {
        color: 'transparent'
      },
      '& .MuiFormLabel-asterisk:after': {
        content: '""',
        background: '#2CE9DA',
        display: 'inline-block',
        position: 'relative',
        width: '8px',
        height: '8px',
        borderRadius: '50%',
        top: '-14px',
        right: '10px'
      },
      '@media (max-width: 850px)': {
        fontSize: '19px'
      }
    },
    TableHeadCell: {
      background: '#67e9da',
      border: '0 !important',
      '& td': {
        color: '#000 !important'
      }
    }
  }));
  const classes = useStyles();
  const handleDiscountAmount = (e) => {
    dispatch(
      setDiscountAmount({
        name: e.target.name,
        value: validateFloat(e.target.value)
      })
    );
  };
  return (
    <div>
      <Typography variant="h3" className={classes.mainTitle}>
        Discounts & Pricing
      </Typography>
      <Typography variant="h5" className={classes.subTitle}>
        {title}
      </Typography>
      <Grid
        container
        xs={10}
        justify="center"
        style={{
          margin: '45px auto'
        }}
      >
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={2}
          // style={{ margin: '20px 0 20px' }}
        >
          <Grid xs={12}>
            <Grid xs={12} className={classes.customGrid}>
              <RadioGroup
                onChange={handleChange}
                value={discount.initial.type}
                className={classes.customRadio}
              >
                <Box
                  className={clsx(
                    'customGridData'
                    // `${PayFulldata.value === '1' ? 'checked' : ''}`
                  )}
                  // onClick={() => {
                  //   setPayFulldata({
                  //     value: '1'
                  //   });
                  // }}
                >
                  <Box className={classes.ItemText}>Initial</Box>
                  <Box
                    style={{
                      flexDirection: 'row',
                      justifyContent: 'left',
                      flexWrap: 'nowrap',
                      display: 'flex'
                    }}
                  >
                    <FormControlLabel
                      // value="1"
                      value="percentage"
                      name="initial"
                      control={
                        <Radio
                          color="primary"
                          checkedIcon={
                            <CheckIcon
                              htmlColor="#2CE9DA"
                              width="30px"
                              height="30px"
                            />
                          }
                          icon={
                            <RadioButtonUncheckedIcon
                              width="30px"
                              height="30px"
                            />
                          }
                        />
                      }
                      label="Percentage"
                      className={`${
                        discount.initial.type === 'percentage' ? 'checked' : ''
                      }`}
                    />
                    <FormControlLabel
                      // value="1"
                      value="fixed"
                      name="initial"
                      control={
                        <Radio
                          color="primary"
                          checkedIcon={
                            <CheckIcon
                              htmlColor="#2CE9DA"
                              width="30px"
                              height="30px"
                            />
                          }
                          icon={
                            <RadioButtonUncheckedIcon
                              width="30px"
                              height="30px"
                            />
                          }
                        />
                      }
                      label="Fixed"
                      className={`${
                        discount.initial.type === 'fixed' ? 'checked' : ''
                      }`}
                    />
                  </Box>
                  <Box className={classes.InputControl}>
                    {discount.initial.type === 'fixed' ? (
                      <CurrencyInputs
                        // placeholder={currencies.currency_symbol}
                        value={discount.initial.amount}
                        name="initial"
                        onChange={handleDiscountAmount}
                        placeholder=""
                        maxLength={10}
                        currencySymbol="A$"
                        style={{
                          margin: '0 0'
                          // width: '80px',
                          // maxWidth: '80px'
                        }}
                      />
                    ) : (
                      <PercentageFormControl
                        className={clsx(
                          classes.PercentageFormControl,
                          discount.initial.amount <= 100
                            ? null
                            : 'MuiInput-underline Mui-error'
                        )}
                        style={{
                          width: '123px'
                        }}
                      >
                        <Input
                          id="standard-adornment-weight"
                          value={discount.initial.amount}
                          name="initial"
                          onChange={handleDiscountAmount}
                          endAdornment={
                            <InputAdornment
                              position="end"
                              style={{
                                color: '#fff',
                                margin: '-1px 0 0 0px'
                              }}
                            >
                              <span>%</span>
                            </InputAdornment>
                          }
                          aria-describedby="standard-weight-helper-text"
                          inputProps={{
                            'aria-label': 'weight'
                          }}
                          style={{
                            color: '#fff'
                          }}
                        />
                      </PercentageFormControl>
                    )}
                  </Box>
                  <Box className={classes.customGridItem}>
                    Total
                    <h5>
                      <GetPrice
                        value={getmemdiscount(discount, payment_data)}
                      />
                    </h5>
                  </Box>
                </Box>
              </RadioGroup>
              <RadioGroup
                onChange={handleChange}
                value={discount.setup.type}
                className={classes.customRadio}
              >
                <Box
                  className={clsx(
                    'customGridData'
                    // `${PayFulldata.value === '1' ? 'checked' : ''}`
                  )}
                  // onClick={() => {
                  //   setPayFulldata({
                  //     value: '1'
                  //   });
                  // }}
                >
                  <Box className={classes.ItemText}>Setup fee</Box>
                  <Box
                    style={{
                      flexDirection: 'row',
                      justifyContent: 'left',
                      flexWrap: 'nowrap',
                      display: 'flex'
                    }}
                  >
                    <FormControlLabel
                      // value="1"
                      value="percentage"
                      name="setup"
                      control={
                        <Radio
                          color="primary"
                          checkedIcon={
                            <CheckIcon
                              htmlColor="#2CE9DA"
                              width="30px"
                              height="30px"
                            />
                          }
                          icon={
                            <RadioButtonUncheckedIcon
                              width="30px"
                              height="30px"
                            />
                          }
                        />
                      }
                      label="Percentage"
                      className={`${
                        discount.setup.type === 'percentage' ? 'checked' : ''
                      }`}
                    />
                    <FormControlLabel
                      // value="1"
                      value="fixed"
                      name="setup"
                      control={
                        <Radio
                          color="primary"
                          checkedIcon={
                            <CheckIcon
                              htmlColor="#2CE9DA"
                              width="30px"
                              height="30px"
                            />
                          }
                          icon={
                            <RadioButtonUncheckedIcon
                              width="30px"
                              height="30px"
                            />
                          }
                        />
                      }
                      label="Fixed"
                      className={`${
                        discount.setup.type === 'fixed' ? 'checked' : ''
                      }`}
                    />
                  </Box>
                  <Box className={classes.InputControl}>
                    {discount.setup.type === 'fixed' ? (
                      <CurrencyInputs
                        // placeholder={currencies.currency_symbol}
                        value={discount.setup.amount}
                        name="setup"
                        onChange={handleDiscountAmount}
                        placeholder=""
                        maxLength={10}
                        currencySymbol="A$"
                        style={{
                          margin: '0 0'
                          // width: '80px',
                          // maxWidth: '80px'
                        }}
                      />
                    ) : (
                      <PercentageFormControl
                        className={clsx(
                          classes.PercentageFormControl,
                          discount.setup.amount <= 100
                            ? null
                            : 'MuiInput-underline Mui-error'
                        )}
                        style={{
                          width: '123px'
                        }}
                      >
                        <Input
                          id="standard-adornment-weight"
                          value={discount.setup.amount}
                          name="setup"
                          onChange={handleDiscountAmount}
                          endAdornment={
                            <InputAdornment
                              position="end"
                              style={{
                                color: '#fff',
                                margin: '-1px 0 0 0px'
                              }}
                            >
                              <span>%</span>
                            </InputAdornment>
                          }
                          aria-describedby="standard-weight-helper-text"
                          inputProps={{
                            'aria-label': 'weight'
                          }}
                          style={{
                            color: '#fff'
                          }}
                        />
                      </PercentageFormControl>
                    )}
                  </Box>
                  <Box className={classes.customGridItem}>
                    Total
                    <h5>
                      <GetPrice
                        value={getsetupdiscount(discount, payment_data)}
                      />
                    </h5>
                  </Box>
                </Box>
              </RadioGroup>
              {state.membership_type === 0 && payment_data.auto_renew === 1 ? (
                <RadioGroup
                  onChange={handleChange}
                  value={discount.renewal.type}
                  className={classes.customRadio}
                >
                  <Box
                    className={clsx(
                      'customGridData'
                      // `${PayFulldata.value === '1' ? 'checked' : ''}`
                    )}
                    // onClick={() => {
                    //   setPayFulldata({
                    //     value: '1'
                    //   });
                    // }}
                  >
                    <Box className={classes.ItemText}>Renewal</Box>
                    <Box
                      style={{
                        flexDirection: 'row',
                        justifyContent: 'left',
                        flexWrap: 'nowrap',
                        display: 'flex'
                      }}
                    >
                      <FormControlLabel
                        // value="1"
                        value="percentage"
                        name="renewal"
                        control={
                          <Radio
                            color="primary"
                            checkedIcon={
                              <CheckIcon
                                htmlColor="#2CE9DA"
                                width="30px"
                                height="30px"
                              />
                            }
                            icon={
                              <RadioButtonUncheckedIcon
                                width="30px"
                                height="30px"
                              />
                            }
                          />
                        }
                        label="Percentage"
                        className={`${
                          discount.renewal.type === 'percentage'
                            ? 'checked'
                            : ''
                        }`}
                      />
                      <FormControlLabel
                        // value="1"
                        value="fixed"
                        name="renewal"
                        control={
                          <Radio
                            color="primary"
                            checkedIcon={
                              <CheckIcon
                                htmlColor="#2CE9DA"
                                width="30px"
                                height="30px"
                              />
                            }
                            icon={
                              <RadioButtonUncheckedIcon
                                width="30px"
                                height="30px"
                              />
                            }
                          />
                        }
                        label="Fixed"
                        className={`${
                          discount.renewal.type === 'fixed' ? 'checked' : ''
                        }`}
                      />
                    </Box>
                    <Box className={classes.InputControl}>
                      {discount.renewal.type === 'fixed' ? (
                        <CurrencyInputs
                          // placeholder={currencies.currency_symbol}
                          value={discount.renewal.amount}
                          name="renewal"
                          onChange={handleDiscountAmount}
                          placeholder=""
                          maxLength={10}
                          currencySymbol="A$"
                          style={{
                            margin: '0 0'
                            // width: '80px',
                            // maxWidth: '80px'
                          }}
                        />
                      ) : (
                        <PercentageFormControl
                          className={clsx(
                            classes.PercentageFormControl,
                            discount.renewal.amount <= 100
                              ? null
                              : 'MuiInput-underline Mui-error'
                          )}
                          style={{
                            width: '123px'
                          }}
                        >
                          <Input
                            id="standard-adornment-weight"
                            value={discount.renewal.amount}
                            name="renewal"
                            onChange={handleDiscountAmount}
                            endAdornment={
                              <InputAdornment
                                position="end"
                                style={{
                                  color: '#fff',
                                  margin: '-1px 0 0 0px'
                                }}
                              >
                                <span>%</span>
                              </InputAdornment>
                            }
                            aria-describedby="standard-weight-helper-text"
                            inputProps={{
                              'aria-label': 'weight'
                            }}
                            style={{
                              color: '#fff'
                            }}
                          />
                        </PercentageFormControl>
                      )}
                    </Box>
                    <Box className={classes.customGridItem}>
                      Total
                      <h5>
                        <GetPrice
                          value={getRenewalDiscount(discount, payment_data)}
                        />
                      </h5>
                    </Box>
                  </Box>
                </RadioGroup>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
        <Typography variant="h5" className={classes.subTitle}>
          {subtitle}
        </Typography>
        <Box
          mt="25px"
          css={{
            width: '100%'
          }}
        />
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={2}
          // style={{ margin: '20px 0 20px' }}
        >
          <Grid xs={12} className={classes.finalPricing}>
            <TableContainer className={classes.PlanTerms}>
              <Table className={classes.table}>
                {(() => {
                  switch (payment_data.paymentOpt) {
                    case 1:
                      return (
                        <TableBody>
                          <TableRow className={classes.TableHeadCell}>
                            <TableCell className={classes.TableCell}>
                              Name
                            </TableCell>
                            <TableCell className={classes.TableCell}>
                              Payment plan
                            </TableCell>
                            {payment_data.auto_renew !== 0 && (
                              <TableCell className={classes.TableCell}>
                                Payment renewal
                              </TableCell>
                            )}
                          </TableRow>
                          <TableRow>
                            <TableCell className={classes.TableCell}>
                              Payment option type
                            </TableCell>
                            <TableCell className={classes.TableCell}>
                              Pay in Full
                            </TableCell>
                            {payment_data.auto_renew !== 0 && (
                              <TableCell className={classes.TableCell}>
                                {payment_data.renewOpt === 1
                                  ? 'Pay in full'
                                  : 'Weekly'}
                              </TableCell>
                            )}
                          </TableRow>
                          <TableRow>
                            <TableCell className={classes.TableCell}>
                              <InputLabel
                                htmlFor="age-required"
                                required
                                className={classes.cutom_select_Label}
                                // style={{ margin: '0px 0 5px' }}
                              >
                                Setup fee
                              </InputLabel>
                            </TableCell>
                            <TableCell
                              className={clsx(
                                classes.TableCell,
                                classes.cutom_vanguard_FormControl
                              )}
                            >
                              <FormControl
                                control="input"
                                value={
                                  payment_data.initial_commitment_pricing
                                    .full_setup_fees
                                }
                                onChange={(e) =>
                                  dispatch(
                                    setSetupFee(
                                      validPrice(validateFloat(e.target.value))
                                    )
                                  )
                                }
                                // maxLength={50}
                                style={{
                                  margin: '0 0'
                                }}
                              />
                            </TableCell>
                            {payment_data.auto_renew !== 0 && (
                              <TableCell className={clsx(classes.TableCell)}>
                                &nbsp;
                              </TableCell>
                            )}
                          </TableRow>
                          <TableRow>
                            <TableCell className={classes.TableCell}>
                              <InputLabel
                                htmlFor="age-required"
                                required
                                className={classes.cutom_select_Label}
                                // style={{ margin: '0px 0 5px' }}
                              >
                                Fee
                              </InputLabel>
                            </TableCell>
                            <TableCell
                              className={clsx(
                                classes.TableCell,
                                classes.cutom_vanguard_FormControl
                              )}
                            >
                              <FormControl
                                control="input"
                                value={
                                  payment_data.initial_commitment_pricing
                                    .full_membership_fees
                                }
                                onChange={(e) =>
                                  dispatch(
                                    setMemFee(
                                      validPrice(validateFloat(e.target.value))
                                    )
                                  )
                                }
                                // maxLength={50}
                                style={{
                                  margin: '0 0'
                                }}
                              />
                            </TableCell>
                            {payment_data.auto_renew !== 0 && (
                              <TableCell
                                className={clsx(
                                  classes.TableCell,
                                  classes.cutom_vanguard_FormControl
                                )}
                              >
                                <FormControl
                                  control="input"
                                  value={
                                    payment_data.auto_renewal_pricing
                                      .full_membership_fees
                                  }
                                  onChange={(e) =>
                                    dispatch(
                                      setRenewalFee(
                                        validPrice(
                                          validateFloat(e.target.value)
                                        )
                                      )
                                    )
                                  }
                                  // maxLength={50}
                                  style={{
                                    margin: '0 0'
                                  }}
                                />
                              </TableCell>
                            )}
                          </TableRow>
                          <TableRow>
                            <TableCell className={classes.TableCell}>
                              Subtotal
                            </TableCell>
                            <TableCell className={classes.TableCell}>
                              <GetPrice
                                value={getTotalDue(payment_data, discount)}
                              />
                            </TableCell>
                            {payment_data.auto_renew !== 0 && (
                              <TableCell className={classes.TableCell}>
                                <GetPrice
                                  value={RenewalTotal(payment_data, discount)}
                                />
                              </TableCell>
                            )}
                          </TableRow>
                          <TableRow>
                            <TableCell className={classes.TableCell}>
                              Total discount
                            </TableCell>
                            <TableCell className={classes.TableCell}>
                              <GetPrice
                                value={getIniTotaldiscount(
                                  payment_data,
                                  discount
                                )}
                              />
                            </TableCell>
                            {payment_data.auto_renew !== 0 && (
                              <TableCell className={classes.TableCell}>
                                <GetPrice
                                  value={getRenTotalDiscount(
                                    payment_data,
                                    discount
                                  )}
                                />
                              </TableCell>
                            )}
                          </TableRow>
                          <TableRow>
                            <TableCell className={classes.TableCell}>
                              Total Due
                            </TableCell>
                            <TableCell className={classes.TableCell}>
                              {' '}
                              <GetPrice
                                value={getTotalDue(payment_data, discount)}
                              />
                            </TableCell>
                            {payment_data.auto_renew !== 0 && (
                              <TableCell className={classes.TableCell}>
                                {' '}
                                <GetPrice
                                  value={RenewalTotal(payment_data, discount)}
                                />
                              </TableCell>
                            )}
                          </TableRow>
                          <TableRow>
                            <TableCell className={classes.TableCell}>
                              GST
                            </TableCell>
                            <TableCell className={classes.TableCell}>
                              {' '}
                              <GetPrice
                                value={getTaxTotal(
                                  payment_data,
                                  tax[0],
                                  discount
                                )}
                              />
                            </TableCell>
                            {payment_data.auto_renew !== 0 && (
                              <TableCell className={classes.TableCell}>
                                {' '}
                                <GetPrice
                                  value={getRenewalTax(
                                    payment_data,
                                    tax[0],
                                    discount
                                  )}
                                />
                              </TableCell>
                            )}
                          </TableRow>
                        </TableBody>
                      );
                    case 2:
                      return (
                        <TableBody>
                          <TableRow className={classes.TableHeadCell}>
                            <TableCell className={classes.TableCell}>
                              Name
                            </TableCell>
                            <TableCell className={classes.TableCell}>
                              Payment plan
                            </TableCell>
                            {payment_data.auto_renew !== 0 && (
                              <TableCell className={classes.TableCell}>
                                Payment renewal
                              </TableCell>
                            )}
                          </TableRow>
                          <TableRow>
                            <TableCell className={classes.TableCell}>
                              Payment option type
                            </TableCell>
                            <TableCell className={classes.TableCell}>
                              Weekly
                            </TableCell>
                            {payment_data.auto_renew !== 0 && (
                              <TableCell className={classes.TableCell}>
                                {payment_data.renewOpt === 1
                                  ? 'Pay in full'
                                  : 'Weekly'}
                              </TableCell>
                            )}
                          </TableRow>
                          <TableRow>
                            <TableCell className={classes.TableCell}>
                              <InputLabel
                                htmlFor="age-required"
                                required
                                className={classes.cutom_select_Label}
                                // style={{ margin: '0px 0 5px' }}
                              >
                                Setup fee
                              </InputLabel>
                            </TableCell>
                            <TableCell
                              className={clsx(
                                classes.TableCell,
                                classes.cutom_vanguard_FormControl
                              )}
                            >
                              <FormControl
                                control="input"
                                value={
                                  payment_data.initial_commitment_pricing
                                    .week_setup_fees
                                }
                                onChange={(e) =>
                                  dispatch(
                                    setWeekSetup(
                                      validPrice(validateFloat(e.target.value))
                                    )
                                  )
                                }
                                // maxLength={50}
                                style={{
                                  margin: '0 0'
                                }}
                              />
                            </TableCell>
                            {payment_data.auto_renew !== 0 && (
                              <TableCell className={clsx(classes.TableCell)}>
                                &nbsp;
                              </TableCell>
                            )}
                          </TableRow>
                          <TableRow>
                            <TableCell className={classes.TableCell}>
                              <InputLabel
                                htmlFor="age-required"
                                required
                                className={classes.cutom_select_Label}
                                // style={{ margin: '0px 0 5px' }}
                              >
                                Fee
                              </InputLabel>
                            </TableCell>
                            <TableCell
                              className={clsx(
                                classes.TableCell,
                                classes.cutom_vanguard_FormControl
                              )}
                            >
                              <FormControl
                                control="input"
                                value={
                                  payment_data.initial_commitment_pricing
                                    .week_membership_fees
                                }
                                onChange={(e) =>
                                  dispatch(
                                    setWeekFee(
                                      validPrice(validateFloat(e.target.value))
                                    )
                                  )
                                }
                                // maxLength={50}
                                style={{
                                  margin: '0 0'
                                }}
                              />
                            </TableCell>
                            {payment_data.auto_renew !== 0 && (
                              <TableCell
                                className={clsx(
                                  classes.TableCell,
                                  classes.cutom_vanguard_FormControl
                                )}
                              >
                                <FormControl
                                  control="input"
                                  value={
                                    payment_data.auto_renewal_pricing
                                      .week_membership_fees
                                  }
                                  onChange={(e) =>
                                    dispatch(
                                      setRenewalWeek(
                                        validPrice(
                                          validateFloat(e.target.value)
                                        )
                                      )
                                    )
                                  }
                                  // maxLength={50}
                                  style={{
                                    margin: '0 0'
                                  }}
                                />
                              </TableCell>
                            )}
                          </TableRow>
                          <TableRow>
                            <TableCell className={classes.TableCell}>
                              Subtotal
                            </TableCell>
                            <TableCell className={classes.TableCell}>
                              <GetPrice
                                value={getTotalDue(payment_data, discount)}
                              />
                            </TableCell>
                            {payment_data.auto_renew !== 0 && (
                              <TableCell className={classes.TableCell}>
                                <GetPrice
                                  value={RenewalTotal(payment_data, discount)}
                                />
                              </TableCell>
                            )}
                          </TableRow>
                          <TableRow>
                            <TableCell className={classes.TableCell}>
                              Total discount
                            </TableCell>
                            <TableCell className={classes.TableCell}>
                              <GetPrice
                                value={getIniTotaldiscount(
                                  payment_data,
                                  discount
                                )}
                              />
                            </TableCell>
                            {payment_data.auto_renew !== 0 && (
                              <TableCell className={classes.TableCell}>
                                <GetPrice
                                  value={getRenTotalDiscount(
                                    payment_data,
                                    discount
                                  )}
                                />
                              </TableCell>
                            )}
                          </TableRow>
                          <TableRow>
                            <TableCell className={classes.TableCell}>
                              Total Due
                            </TableCell>
                            <TableCell className={classes.TableCell}>
                              {' '}
                              <GetPrice
                                value={getTotalDue(payment_data, discount)}
                              />
                            </TableCell>
                            {payment_data.auto_renew !== 0 && (
                              <TableCell className={classes.TableCell}>
                                {' '}
                                <GetPrice
                                  value={RenewalTotal(payment_data, discount)}
                                />
                              </TableCell>
                            )}
                          </TableRow>
                          <TableRow>
                            <TableCell className={classes.TableCell}>
                              GST
                            </TableCell>
                            <TableCell className={classes.TableCell}>
                              {' '}
                              <GetPrice
                                value={getTaxTotal(
                                  payment_data,
                                  tax[0],
                                  discount
                                )}
                              />
                            </TableCell>
                            {payment_data.auto_renew !== 0 && (
                              <TableCell className={classes.TableCell}>
                                {' '}
                                <GetPrice
                                  value={getRenewalTax(
                                    payment_data,
                                    tax[0],
                                    discount
                                  )}
                                />
                              </TableCell>
                            )}
                          </TableRow>
                        </TableBody>
                      );
                    default:
                      return null;
                  }
                })()}
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

DiscountsPricing.defaultProps = {
  subtitle: '',
  title: ''
};

DiscountsPricing.propTypes = {
  // setOpen: PropType.bool.isRequired,
  // handleClose: PropType.func.isRequired
  // handleBack: PropType.func,
  title: PropType.string,
  subtitle: PropType.string
};

export default DiscountsPricing;
