/* eslint-disable operator-linebreak */
/* eslint-disable indent */
import React, { memo, useState, useCallback, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';

import { connect, useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Grid,
  withStyles,
  Card,
  Breadcrumbs,
  Typography,
  Box,
  InputBase,
  Paper,
  ThemeProvider
} from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
import Pagination from '@material-ui/lab/Pagination';
import LoadingView from 'src/views/common/loading';
import ApplyFilter from 'src/views/common/ApplyFilter/applyFilter';
import { debounce } from 'lodash';
import CancelDialogTwo from 'src/views/common/CommonDialogBox/CancelDialogTwo';
import { getMembershipTypeReports } from 'src/Redux/ReportsReducer/reports.reducer.actions';
import filterType from 'src/Redux/Reducer.constants';
import {
  getplanMem,
  getSession
} from 'src/Redux/MembershipReducer/MembershipReducer.actions';
import { exportMembershipType } from 'src/services/reports.services';
import useChangeTitle from 'src/views/common/CustomHooks/useTitleChange';
import JBButton from '../../common/JBButton/JBButton';
import {
  getmembershipPieData,
  getMembershipTypePieData
} from '../Reports.utils';
import ImportIcon from '../../../assets/image/upload.png';
import MembershipTypeTable from './AllTables/MembershipTypeTable/MembershipTypeTable';
import MembershipTypeFilters from './Filters/MembershipTypeFilters';
import PieChart from '../chart/PieChart';
import styles from './MembersReports.style';

const themeInstance = {
  overrides: {
    MuiBreadcrumbs: {
      root: {
        color: '#949494',
        textTransform: 'uppercase',
        fontSize: '20px',
        lineHeight: '24px',
        fontWeight: '700'
      },
      li: {
        '& a': {
          color: '#949494',
          textTransform: 'uppercase',
          fontSize: '20px',
          lineHeight: '24px'
        },
        '@media (max-width: 1440px)': {
          '& a': {
            fontSize: '18px'
          },
          '& h3': {
            fontSize: '18px'
          }
        },
        '& h3': {
          margin: '0 0'
        }
      },
      separator: {
        marginLeft: '12px',
        marginRight: '12px'
      }
    }
  }
};
const MembershipType = memo((props) => {
  const { data: data_reports } = useSelector(
    (state) => state.reportsReducer.reportList
  );
  const [open, setOpen] = useState(false);
  const {
    classes,
    membersReports: { isFetching, data, error },
    MembersFilter,
    membersSearch,
    membersSort,
    membersTags,
    customFilter
  } = props;
  const dispatch = useDispatch();
  const [search, setSearch] = useState(membersSearch);
  const SearchQuery = useCallback(
    debounce((q) => MembersFilter(filterType.search, q), 1500),
    []
  );
  useChangeTitle('Members by Membership Type | Jungle Alliance');
  const isStarred = data_reports?.members_report.filter(
    (report) => report.id === 3
  )[0].is_starred;

  const setDirection = (name) => {
    if (membersSort.sort_name === name) {
      return membersSort.sort_by === 'asc' ? 'asc' : 'dsc';
    }
    return 'asc';
  };

  const isActive = (sortlabel) => {
    if (membersSort.sort_name === sortlabel) return true;
    return false;
  };

  const setSort = (sortlabel) => {
    MembersFilter(filterType.sorting, sortlabel);
  };
  const Paginate = (val) => {
    if (val !== data.current_page) {
      return MembersFilter(filterType.page, val);
    }
    return null;
  };
  const handleReset = () => {
    MembersFilter(filterType.resetFilter);
    setSearch('');
  };

  // eslint-disable-next-line operator-linebreak
  const { avg_membership, avg_membership_type, list_membership_type } =
    data || {};
  useEffect(() => {
    dispatch(getplanMem());
    dispatch(getSession());
    return () => {
      handleReset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (!data_reports || !data) return <LoadingView />;
  return (
    <div className={classes.root}>
      <div className="title-bk">
        <Box css={{ marginBottom: '20px' }}>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={0}
          >
            <Grid item xs={9}>
              <Box display="inline-flex">
                <ThemeProvider theme={themeInstance}>
                  <Breadcrumbs aria-label="breadcrumb">
                    <div color="inherit">Reports</div>
                    <div
                      color="inherit"
                      onClick={() => setOpen(true)}
                      style={{
                        cursor: 'pointer'
                      }}
                    >
                      members reports
                    </div>
                    <Typography variant="h3" color="Primary">
                      <span>Members by Membership Type</span>
                    </Typography>
                  </Breadcrumbs>
                </ThemeProvider>
              </Box>
              <Box
                display="inline-flex"
                ml="10px"
                css={{ verticalAlign: 'text-bottom' }}
              >
                {isStarred === 1 && (
                  <StarIcon
                    style={{
                      color: '#FFC700'
                    }}
                  />
                )}
              </Box>
            </Grid>
            <Grid
              item
              xs={3}
              style={{
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              <JBButton
                type="outlined"
                isImg={ImportIcon}
                component={Link}
                style={{ marginLeft: '10px' }}
                disabled={list_membership_type.data.length === 0}
                onClick={(e) => {
                  e.preventDefault();
                  exportMembershipType({
                    search: membersSearch,
                    sort_name: membersSort.sort_name,
                    sort_by: membersSort.sort_by,
                    ...customFilter
                  });
                }}
              >
                Export
              </JBButton>
            </Grid>
          </Grid>
        </Box>
      </div>

      <Card className={classes.mainCard}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={0}
        >
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={0}
          >
            <Grid
              item
              xs={12}
              justify="center"
              alignItems="center"
              textAlign="center"
            >
              {avg_membership.length === 0 &&
              avg_membership_type.length === 0 ? null : (
                <Box css={{ textAlign: 'center', marginBottom: '25px' }}>
                  <Box display="inline-block">
                    <Typography variant="h6" className={classes.BkChartTitle}>
                      <span>Membership types</span>
                    </Typography>
                    <PieChart
                      className={classes.BkChart}
                      data={getmembershipPieData(avg_membership)}
                    />
                  </Box>
                  <Box display="inline-block">
                    <Typography variant="h6" className={classes.BkChartTitle}>
                      <span>Memberships</span>
                    </Typography>
                    <PieChart
                      className={classes.BkChart}
                      data={getMembershipTypePieData(avg_membership_type)}
                    />
                  </Box>
                </Box>
              )}
            </Grid>
          </Grid>

          <Grid item xs={6}>
            <Grid container direction="row" alignItems="center" spacing={0}>
              <Grid item xs={9}>
                <div className={classes.search}>
                  <InputBase
                    fullWidth
                    placeholder="Search…"
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                      SearchQuery(e.target.value);
                    }}
                    classes={{
                      root: classes.inputRoot,
                      input: classes.searchInput
                    }}
                    InputProps={{
                      'aria-label': 'search'
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs>
                <JBButton
                  type="outlined"
                  onClick={handleReset}
                  style={{ marginLeft: '10px' }}
                >
                  reset
                </JBButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} justify="flex-end" className="MuiGrid-container">
            <Box css={{ width: '240px' }}>
              <ApplyFilter>
                <MembershipTypeFilters
                  getFilData={(val1, val2) =>
                    MembersFilter(filterType.addcustom, [val1, val2])
                  }
                />
              </ApplyFilter>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <div>
              {membersTags?.map((tags) => (
                <div
                  className={classes.DTags}
                  key={tags.id}
                  style={{ display: 'inline-block' }}
                >
                  <Box display="inline-block" mr={1}>
                    <label>
                      {tags.name}
                      <span
                        className={classes.DTagsClose}
                        onClick={() =>
                          MembersFilter(filterType.removecustom, tags)
                        }
                      >
                        ×
                      </span>
                    </label>
                  </Box>
                </div>
              ))}
            </div>
          </Grid>
        </Grid>
        {isFetching || !data ? (
          <LoadingView />
        ) : error ? (
          <p>{error}</p>
        ) : (
          <Fragment>
            <Grid item xs={12}>
              <Box mt="25px" />
              <MembershipTypeTable
                values={list_membership_type?.data}
                setDirection={setDirection}
                isActive={isActive}
                setSort={setSort}
              />
            </Grid>
            {list_membership_type?.data.length !== 0 && (
              <Grid
                item
                xs={12}
                container
                direction="row"
                alignItems="center"
                style={{ marginTop: 24 }}
              >
                <Grid item xs={6} justify="flex-start">
                  {list_membership_type?.from &&
                  list_membership_type?.to > 1 ? (
                    <Paper elevation={0} style={{ color: '#A9A9A9' }}>
                      {`${list_membership_type?.from} - ${list_membership_type?.to} of ${list_membership_type?.total}`}
                    </Paper>
                  ) : null}
                </Grid>
                <Grid
                  item
                  xs={6}
                  justify="flex-end"
                  style={{ display: 'flex' }}
                >
                  <Pagination
                    count={list_membership_type?.last_page}
                    color="primary"
                    variant="outlined"
                    page={list_membership_type?.current_page}
                    onChange={(_, val) => Paginate(val)}
                    shape="rounded"
                  />
                </Grid>
              </Grid>
            )}
          </Fragment>
        )}
      </Card>
      <CancelDialogTwo setOpen={open} handleClose={() => setOpen(false)} />
    </div>
  );
});

MembershipType.propTypes = {
  classes: PropTypes.object.isRequired,
  MembersFilter: PropTypes.func.isRequired,
  membersReports: PropTypes.object.isRequired,
  membersSearch: PropTypes.string.isRequired,
  membersSort: PropTypes.object.isRequired,
  membersTags: PropTypes.array.isRequired,
  customFilter: PropTypes.object.isRequired
};

const mapToState = (state) => ({
  membersReports: state.reportsReducer.membersReports.byMemshipType,
  membersSearch: state.reportsReducer.membersReports.byMembershipTypeFil.search,
  membersSort: state.reportsReducer.membersReports.byMembershipTypeFil.sorting,
  membersTags: state.reportsReducer.membersReports.byMembershipTypeFil.tags,
  customFilter:
    state.reportsReducer.membersReports.byMembershipTypeFil.customFilter
});
const mapToDispatch = (dispatch) => ({
  MembersFilter: (filter, data) =>
    dispatch(getMembershipTypeReports(filter, data))
});
export default connect(
  mapToState,
  mapToDispatch
)(withStyles(styles)(MembershipType));
