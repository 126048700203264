import React, { useState, useContext, useEffect } from 'react';
import PropType from 'prop-types';
import { connect } from 'react-redux';
import JBButton from 'src/views/common/JBButton/JBButton';
import { InputLabel, withStyles } from '@material-ui/core';
import { FilterContext } from 'src/views/common/ApplyFilter/applyFilter';
import FormControl from 'src/views/common/FormControl/formControl';
import { TobeFilter, TrendFilter } from './static.Data';
import CustomAutoComplete from '../../../../common/CustomAutocomplete/commonAutocomplete';
import styles from './filters.style';
import { getFilter, getfilterLabel } from './Filters.utils';

const AttendanceTrendFilter = (props) => {
  const { classes, getFilData } = props;
  const [data, setData] = useState(null);
  const [filter, setFilter] = useState(null);
  const [open, setOpen] = useContext(FilterContext);
  useEffect(() => {
    if (filter?.id === 2) {
      setData('10');
    } else {
      setData(null);
    }
  }, [filter]);
  return (
    <div>
      <CustomAutoComplete
        holder="Select Filter"
        style={{ marginBottom: '15px' }}
        value={TobeFilter}
        Options="filter"
        data={filter}
        Change={(val) => setFilter(val)}
      />

      <div>
        {(() => {
          switch (filter?.id) {
            case 1:
              return (
                <div>
                  <InputLabel
                    htmlFor="age-required"
                    className={classes.cutom_select_Label}
                    style={{ margin: '0px 0 5px' }}
                  >
                    Trend
                  </InputLabel>
                  <CustomAutoComplete
                    holder="All"
                    Options="name"
                    Change={(val) => {
                      setData(val);
                    }}
                    value={TrendFilter}
                    data={data || null}
                    style={{ marginBottom: '15px', marginTop: '0px' }}
                  />
                </div>
              );
            case 2:
              return (
                <div>
                  <InputLabel
                    htmlFor="age-required"
                    className={classes.cutom_select_Label}
                    style={{ margin: '0px 0 5px' }}
                  >
                    Average Weekly Attendance Less Than
                  </InputLabel>

                  <FormControl
                    control="input"
                    label=""
                    placeholder=""
                    value={data}
                    defaultValue="10"
                    onChange={(e) => {
                      setData(e.target.value);
                    }}
                    style={{ margin: '0 0' }}
                    maxLength="10"
                  />
                </div>
              );
            default:
              return null;
          }
        })()}
      </div>

      <JBButton
        type="primary"
        style={{
          width: '100%',
          minHeight: '22px',
          padding: '7px 25px',
          marginTop: '10px'
        }}
        disabled={!data}
        // disabled={checkData(filter, data)}
        onClick={() => {
          getFilData(getFilter(filter, data), getfilterLabel(filter, data));
          setOpen(!open);
        }} // eslint-disable-line
      >
        Apply Filter
      </JBButton>
    </div>
  );
};
AttendanceTrendFilter.propTypes = {
  getFilData: PropType.func.isRequired,
  classes: PropType.object.isRequired
  // memFilter: PropType.func.isRequired
};

export default connect()(withStyles(styles)(AttendanceTrendFilter));
